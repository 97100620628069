﻿angular
    .module('DigiLean')
    .directive("fileinfo", ['$filter', '$uibModal', 'documentService',
        function ($filter, $uibModal, documentService) {
            return {
                templateUrl: 'fileinfo.html',
                restrict: 'E',
                scope: {
                    'file': '<',
                },
                link: function (scope, elem, attrs) {

                    var viewerFileExtensions = [
                        { type: 'image', extension: '.jpg' },
                        { type: 'image', extension: '.jpeg' },
                        { type: 'image', extension: '.png' },
                        { type: 'pdf', extension: '.pdf' },
                        { type: 'excel', extension: '.xlsx' },
                        { type: 'excel', extension: '.xls' },
                        { type: 'word', extension: '.doc' },
                        { type: 'word', extension: '.docx' },
                        { type: 'video', extension: '.mp4' },
                        { type: 'powerpoint', extension: '.ppt' },
                        { type: 'powerpoint', extension: '.pptx' },
                    ];
                    scope.fileClone = {}
                    scope.$watch('file', function (file) {
                        // apply
                        if (!file) return;
                        var fileType = $filter('filter')(viewerFileExtensions, { extension: file.fileExtension.toLowerCase() });
                        if (fileType.length > 0) {
                            file.fileType = fileType[0].type;
                        }

                        scope.fileClone = _.clone(file)
                        documentService.getFileUrl(file.fileName, file.id).then(function (result) {
                            scope.fileClone.fileUrl = result;
                            scope.fileClone.validFileUrl = result;
                        });
                    });
                }
            }
        }]);
