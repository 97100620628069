angular
    .module('DigiLean')
    .directive("smartActionListTitleCell", ['$filter', '$uibModal','boardTaskService','suggestionService', 'deviationService',
        function ($filter, $uibModal, boardTaskService, suggestionService, deviationService) {
            return {
                templateUrl: 'smartActionListTitleCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'users': '<',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.commentCount = 0;
                    scope.attachmentCount = 0;
                    scope.hasDescription = false;
                    scope.active = false;

                    scope.$watch('model', function (model) {
                        scope.title = model.task.title;
                        scope.commentCount = model.task.commentCount;
                        scope.attachmentCount = model.task.attachmentCount;
                        if(model.task.text !== ""){
                            scope.hasDescription = true; 
                        }  
                        model.getSortValue = function() {
                            return scope.title;
                        }
                    });

            
                    scope.subscribe("TaskTitleChanged", function (data) {
                        if (data.boardTaskId == scope.model.task.id) {
                            if (data.title) {
                                scope.title = data.title;
                            } else {
                                scope.title = "";
                            }
                        }
                    });

                    scope.subscribe('TaskUpdated', function (task) {
                        if (task.id == scope.model.task.id) {
                            if (task.title) {
                                scope.title = task.title;
                            } else {
                                scope.title = "";
                            }

                            if(task.text == ""){
                                scope.hasDescription = false; 
                            } else {
                                scope.hasDescription = true;
                            }
                        }
                    });
                    scope.subscribe("TaskCommentAdded", function (comment) {
                        if (comment.boardTaskId == scope.model.cell.boardTaskId) {
                            scope.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe("TaskCommentDeleted", function (comment) {
                        if (comment.boardTaskId == scope.model.cell.boardTaskId) {
                            scope.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe('TaskAttachmentCountChanged', function (attachment) {
                        if (attachment.taskId == scope.model.cell.boardTaskId) {
                            scope.attachmentCount = attachment.attachmentCount;
                        }
                    });
/*
                    scope.setActive = function() {
                        scope.active = true;   
                        setTimeout(function() {
                            $(".project-name").focus();
                        }, 50 );                        
                    }
                    scope.titleChanged = function(title) {
                        scope.active = false;  
                        if(title !== scope.title){
                            var taskTitle = {
                                id: scope.model.task.id,
                                title: title 
                            };
                            boardTaskService().updateTitle(scope.model.task.id, taskTitle).then(function (result) {                         
                            });
                        }

                    }*/
                    scope.openImprovement = function (improvementid) {
                        suggestionService().get(improvementid).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    }
                    scope.openDeviation = function (deviationid) {
                        deviationService().get(deviationid).then(function (deviation) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    }
                    scope.openCloneInfo = function(task) {
                       
                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'cloneInfo.html',
                            controller: 'cloneInfoController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    }
                    scope.openTask = function (taskId, mode) {
                        if (!mode) mode = "Info";
                        boardTaskService().get(taskId).then(function (task) {
                            
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return mode;
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: true,
                                            users: scope.users
                                        }
                                        if(task.a3Id){
                                            options.canChangeBoard = false;
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }]);
