﻿var DigiLean = angular.module('DigiLean'); DigiLean.controller('trialsController', ['$scope', 'signupService',
    function ($scope, signupService) {

        $scope.getTrialStatus = function(){
            signupService().getTrialStatus().then(function (trialinfo) {
                $scope.trials = trialinfo.trials;
                $scope.trialinfo = trialinfo;
            });
        }
        $scope.getTrialStatus();

        $scope.getActiveTrialStatus = function(){
            signupService().getActiveTrialStatus().then(function (trialinfo) {
                $scope.trials = trialinfo.trials;
            });
        }

        $scope.getConvertedTrialStatus = function(){
            signupService().getConvertedTrialStatus().then(function (trialinfo) {
                $scope.trials = trialinfo.trials;
            });
        }
        
        $scope.loadBoards = function (trial) {
            trial.showBoards = !trial.showBoards;
            if (trial.hasLoadedBoards) return;
            trial.hasLoadedBoards = true;
            trial.isLoadingBoards = true;
            boardService.getBoardsByCustomer(trial.customerId).then(function (values) {
                trial.boards = values;
                trial.isLoadingBoards = false;
            });
        }

    }]);