angular
    .module('DigiLean')
    .directive("smartCommonNumberCell", ['$filter', 'debounce', 'projectService',
        function ($filter, debounce, projectService) {
            return {
                templateUrl: 'smartCommonNumberCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.number = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";
                    scope.projectAttributeId = null;
                    scope.projectId = null;
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    var settings = null;

                    var rowId = null;
                    var columnId = null;
                    scope.$watch('model', function (model) {
                        var value = null;
                        
                        if (scope.type == 'project') {
                            columnId = model.boardAttributeId;
                            rowId = model.project.id;
                            value = model.projectAttribute.value;              
                            
                        } else if (scope.type == 'a3') {
                            columnId = model.columnId;
                            rowId = model.a3.id;
                            if(model.a3TableCell) {
                                value = model.a3TableCell.value;             
                            }
                        }
                        if (value) {
                            setValue(value);
                        }
                        settings = model.column.settings;
                        if (settings) {
                            if (settings.unit) {
                                scope.unit = settings.unit;
                                if (settings.unitPlacement) {
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                        }
                        model.getSortValue = function () {
                            return scope.number;
                        }
                    });

                    scope.autofocus = function () {
                        setTimeout(function () {
                            //document.querySelector( "input" ).focus();
                            $(".project-text-cell").focus();
                        }, 50);
                    }

                    var setValue = function (cell) {
                        scope.background = cell.background;
                        scope.number = cell.number;

                        scope.updateAggregateCell();

                        scope.color = cell.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = cell.background;
                        }
                    }


                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });
                    scope.subscribe("SmartCommonNumberCellUnitUpdated", function (value) {
                        if (scope.type == value.type && columnId === value.columnId) {
                            scope.unit = value.unit;
                        }
                    });

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default') {
                            scope.background = "inherit";
                            scope.color = "#676a6c";
                        } else {
                            scope.background = postIt.background;
                            scope.color = "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.numberChanged = function (number) {
                        if (scope.number === number) return;
                        scope.number = number;
                        scope.updateAggregateCell();
                        scope.commitChangesDebounced();
                    }
                    scope.saveChanges = function () {
                        var newvalue = {
                            background: scope.background,
                            color: scope.color,
                            number: scope.number
                        }
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = newvalue;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = newvalue;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: newvalue
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    scope.sendEvent = function () {
                        var payload = {
                            type: scope.type,
                            columnId: columnId,
                            rowId: rowId,
                            value: scope.number
                        }
                        scope.publish("SmartCommonNumberCellUpdated", payload);
                    }

                    scope.updateAggregateCell = debounce(scope.sendEvent, 1000, false);

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
