﻿import CountUp from './../../../../thirdparty/countUp/countUp.min'
import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("licensesChanges", ['$filter','$timeout',
    function ($filter, $timeout) {
        return {
            templateUrl: 'licensesChanges.html',
            restrict: 'E',
            scope: {
                'datasourceId': '<',
            },
            link: function (scope, elem, attrs) {
                scope.actionResults = []
                
                scope.total = 0;
                scope.mobileCount = 0;

                scope.emailCount = 0;
                scope.options = {
                    timePeriod: {
                        timeframe: "30",
                        useDefaultTimeframes: true
                    }
                };
                scope.timePeriod = timeService.getTimePeriod('30');

                customerService.getLicensesChanges(scope.timePeriod).then(function (result) {
                    scope.actionResults = result;
                });

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    scope.timePeriod = timePeriod.period;
                    scope.isLoading = true;
                    customerService.getLicensesChanges(scope.timePeriod).then(function (result) {
                        scope.actionResults = result;
                        scope.isLoading = false;
                    });

                };

                var countUpOptions = {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                };
                var updateCounters = function () {
                    var numAnim = new CountUp("totaNumberOfNotifications", 0, scope.total, 0, 3, countUpOptions);
                    numAnim.start();
                    var emailAnim = new CountUp("totaNumberOfEmailNotifications", 0, scope.emailCount, 0, 3, countUpOptions);
                    emailAnim.start();
                    var mobileAnim = new CountUp("totaNumberOfMobileNotifications", 0, scope.mobileCount, 0, 3, countUpOptions);
                    mobileAnim.start();
                }

                scope.subscribe('NotificationSent', function (notification) {
                    if(notification){
                        scope.total += 1;
                        if (notification.mobile) {
                            scope.mobileCount += 1;
                        }
                        if (notification.email) {
                            scope.emailCount += 1;
                        }
                        var hasAction = $filter('filter')(scope.actionResults, { action: notification.action }, true);
                        if (hasAction.length > 0) {
                            var action = hasAction[0];
                            action.hasChanged = true;
                            action.total += 1;
                            if (notification.mobile) {
                                action.mobileCount += 1;
                            }
                            if (notification.email) {
                                action.emailCount += 1;
                            }
                            removeAnmiation(action);
                        
                        } else {
                            var newAction = {
                                action: notification.action,
                                total: 1,
                                mobileCount: 0,
                                emailCount: 0,
                            }
                            if (notification.mobile) {
                                newAction.mobileCount += 1;
                            }
                            if (notification.email) {
                                newAction.emailCount += 1;
                            }
                            scope.actionResults.push(newAction);
                            newAction.hasChanged = true;
                            removeAnmiation(newAction);
                        }
                        updateCounters();
                    }
                //    scope.$apply();
                });

                function removeAnmiation(action) {
                    $timeout(function () {
                        action.hasChanged = false;
                    }, 5000);
                }
            }


        }
    }]);
