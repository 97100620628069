import { getCompanySetting } from "@common/stores/companySettingsStore"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('dataSourceGroupSelector', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'dataSourceService', 'hasDataSerie', 'dataSource', 'groupedBy', 'groupingIntervall','filters', 'deviationService',
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, dataSourceService, hasDataSerie, dataSource, groupedBy, groupingIntervall,filters, deviationService) {
        $scope.selectedDataSource = dataSource;
        $scope.selectedGroupedBy = groupedBy;
        if(groupingIntervall){
            $scope.selectedGroupingIntervall = groupingIntervall.toUpperCase();
        }/* else {
            $scope.selectedGroupingIntervall = 'month';
        }*/
        $scope.selectedFilters = filters;
        $scope.steps = [];
        $scope.hasDataSerie = hasDataSerie;

        $scope.data = {
            query: null
        };

        $scope.datasourcefilters = null;
        $scope.dataSources = {
            list: [],
            filteredList: []
        };

        function init() {
            dataSourceService().getList().then(function (list) {
                $scope.dataSources = list;
                
                getCompanySetting("currency").then(function (setting) {
                    var currency = null;
                    if (setting) {
                        currency = setting
                    }
                    var internalDataSources = dataSourceService().getModuleList(currency);
                    var allDataSources = $scope.dataSources.concat(internalDataSources);
                    $scope.dataSources = removeNonGroupable(allDataSources);
                    $scope.resultList = $scope.dataSources.sort((a, b) => {
                        if (a.title > b.title) return 1;
                        if (a.title < b.title) return -1;
                        return 0;
                    });

                      // map selectedDataSource to updated source
                      if (dataSource) {
                        var id = dataSource.id;
                        if (!isNaN(dataSource.id) && parseInt(dataSource.id) != "NaN") {
                            id = parseInt(dataSource.id);
                        }
                        var updatedSource = $filter('filter')($scope.dataSources, { id: id }, true);
                        if (updatedSource.length > 0) {
                            $scope.selectedDataSource = updatedSource[0];
                        } else {
                            $scope.selectedDataSource = dataSource;
                        }
                        // If internal, we need to sync targets from settings
                        if (!$scope.selectedDataSource.targets && dataSource.targets) {
                            $scope.selectedDataSource.targets = dataSource.targets;
                        }
                    }
                    $scope.dataSources.list = $scope.dataSources;
                    $scope.dataSources.filteredList = $scope.dataSources;
                });
            });
        }
        init();

        $scope.$watch('data.query', function (q, old) {
            if (q !== null)
                $scope.searchQuery = angular.copy(q);
        });


        function removeNonGroupable(datasources) {
            var groupable = [];
            for (var i = 0; i < datasources.length; i++) {
                if (datasources[i].elements && datasources[i].elements.length > 0) {
                    groupable.push(datasources[i]);
                }
            }
            return groupable;
        }

        $translate(['DASHBOARD_DATA_SOURCE', 'COMMON_FILTER', 'DASHBOARD_DATA_GROUPING']).then(function (translations) {
            $scope.steps.push({ id: 'DASHBOARD_DATA_SOURCE', title: translations.DASHBOARD_DATA_SOURCE });
            $scope.steps.push({ id: 'DASHBOARD_DATA_GROUPING', title: translations.DASHBOARD_DATA_GROUPING });
            $scope.steps.push({ id: 'COMMON_FILTER', title: translations.COMMON_FILTER });
            $scope.currentStep = $scope.steps[0];
        });
        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('DASHBOARD_DATA_SOURCE');
            $scope.steps[1].title = $translate.instant('DASHBOARD_DATA_GROUPING');
            $scope.steps[2].title = $translate.instant('COMMON_FILTER');
            $scope.currentStep = $scope.steps[0];
        });

        $scope.selectStep = function (step) {
            $scope.currentStep = step;
        }
        $scope.nextStep = function () {
            var currentIndex = $scope.steps.indexOf($scope.currentStep);
            if ((currentIndex + 1) < $scope.steps.length) {
                $scope.selectStep($scope.steps[currentIndex + 1]);
            }
        }
        $scope.previousStep = function () {
            var currentIndex = $scope.steps.indexOf($scope.currentStep);
            if (currentIndex > 0) {
                $scope.selectStep($scope.steps[currentIndex - 1]);
            }
        }
        $scope.selectGroupedBy = function (element) {
            $scope.selectedGroupedBy = element.sourceColumn;
        };
        $scope.selectGroupingIntervall = function (timeintervall) {
            $scope.selectedGroupingIntervall = timeintervall;
        };
        

        $scope.selectDataSource = function (dataSource) {
            $scope.selectedDataSource = dataSource;
            $scope.selectedGroupedBy = "";
            if($scope.selectedDataSource && $scope.selectedDataSource.elements && $scope.selectedDataSource.elements.length >0){
                $scope.selectedGroupedBy = $scope.selectedDataSource.elements[0].sourceColumn;
            }
            $scope.selectedFilters = [];
            $scope.currentStep = $scope.steps[1];
            // Since the grouping interval already is set then we want to update this to new datasource
            if ($scope.selectedGroupingIntervall && $scope.selectedDataSource.unitOfTime != null) {
                $scope.selectedGroupingIntervall = $scope.selectedDataSource.unitOfTime;
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            if ($scope.selectedDataSource.id == 'DeviationConsequence' ||
                $scope.selectedDataSource.id == 'DeviationNew' ||
                $scope.selectedDataSource.id == 'DeviationCompleted') {
                deviationService().getAllCustomFields().then(function (list) {
                    $scope.selectedDataSource.elements = $scope.selectedDataSource.elements.concat(list);
                    $scope.selectedDataSource.elements = arrayUniqueElements($scope.selectedDataSource.elements);
                    var result = {
                        dataSource: $scope.selectedDataSource,
                        groupedBy: $scope.selectedGroupedBy,
                        groupingIntervall: $scope.selectedGroupingIntervall,
                        filters: $scope.selectedFilters
                    };
                    $uibModalInstance.close(result);
                });
            } else {
                var result = {
                    dataSource: $scope.selectedDataSource,
                    groupedBy: $scope.selectedGroupedBy,
                    groupingIntervall: $scope.selectedGroupingIntervall,
                    filters: $scope.selectedFilters
                };
                $uibModalInstance.close(result);
            }

        };





        function arrayUniqueElements(array) {
            var a = array.concat();
            for (var i = 0; i < a.length; ++i) {
                for (var j = i + 1; j < a.length; ++j) {
                    if (a[i].label.toUpperCase() === a[j].label.toUpperCase() && a[i].type.toUpperCase() === a[j].type.toUpperCase())
                        a.splice(j--, 1);
                }
            }

            return a;
        }

        $scope.filterChangedHandler = function (filters) {
        };
    }]);