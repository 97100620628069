import backend from "@common/services/backendHttp"
import { PublicApiClientPostModel, PublicApiClientViewModel, PublicApiGeneratedSecretViewModel, ScopeInfo } from "@api"

const resourceUrl = "api/adminclient"

export const getClientList = async () => {
    const clients = await backend.get<PublicApiClientViewModel[]>(resourceUrl)
    return clients
} 
export const getClient = async (clientId: string) => {
    const url = `${resourceUrl}/${clientId}`
    const client = await backend.get<PublicApiClientViewModel>(url)
    return client
}
export const createClient = async (client: PublicApiClientPostModel) => {
    const cm = await backend.post<PublicApiClientViewModel>(resourceUrl, client)
    return cm
}
export const saveClient = async (client: PublicApiClientPostModel) => {
    const cm = await backend.put<PublicApiClientViewModel>(resourceUrl, client)
    return cm
}
export const generateSecret = async (clientId: string) => {
    const url = `${resourceUrl}/generate/${clientId}`
    const secret = await backend.get<PublicApiGeneratedSecretViewModel>(url)
    return secret
}
export const expireSecret = async (clientId: string, secretId: number) => {
    const url = `${resourceUrl}/expire/${clientId}`
    const client = await backend.post<PublicApiClientViewModel>(url, secretId)
    return client
}

export const getAllScopes = async () => {
    const url = `${resourceUrl}/allscopes`
    const scopes = await backend.get<ScopeInfo[]>(url)
    return scopes
}

export const updateScopes = async (clientId: string, scopes: string[]) => {
    const url = `${resourceUrl}/scopes/${clientId}`
    const client = await backend.put<PublicApiClientViewModel[]>(url, scopes)
    return client
}