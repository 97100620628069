angular.module('DigiLean').controller('languageLabelAdmin', ['$scope', '$filter', '$translate', 'languageAdminService', 'modalService','debounce',
    function ($scope, $filter, $translate, languageAdminService, modalService, debounce) {
        $scope.data = {
            query: null
        };
        $scope.$watch('data.query', function (q, old) {
            if (q !== null)
                $scope.searchQuery = angular.copy(q);
        });

        $scope.currentPage = 0;
        $scope.pageSize = 15;

        $scope.languages = languageAdminService().getSupportedLanguages();

        function findMissingTranslations(item) {
            let missing = []
            $scope.languages.forEach(lang => {
                if (lang.languageCode != "en") {
                    const translation = item.translations.find(t => t.languageCode == lang.languageCode)
                    if (translation && !translation.label)
                    missing.push(lang.languageCode)
                }
            })
            return missing
        }
        $scope.translate = function (item) {
            // needed for the pagination calc
            const englishText = item.translations.find(t => t.languageCode == "en")
            if (!englishText || !englishText.label) {
                toastr.error("Got no english text for "+ item.labelCode + ", can't translate")
                return
            }

            const missingLangs = findMissingTranslations(item)
            if (missingLangs.length == 0) {
                toastr.info("No missing language translation for label " + item.labelCode)
                return
            }

            
            const options = {
                fromLanguageCode: "en",
                textToTranslate: englishText.label,
                targetLanguagesCodes: missingLangs
            }
            
            languageAdminService().translate(options).then(function(result){
                // Add result to item translations;
                // Map result to language
                result.results.forEach(translationResult => {
                    const translation = item.translations.find(t => t.languageCode == translationResult.languageCode)
                    if (translation && !translation.label) { // don't override those who already have translation, must be blank
                        translation.label = translationResult.value
                    }
                })

                toastr.success("Translated " + item.labelCode + " for languages: " + missingLangs.join(", "), "Translation OK", {timeOut: 10000, extendedTimeOut: 25000 })
                $scope.saveItem(item)
            })
        }

        $scope.getData = function () {
            // needed for the pagination calc
            return $filter('filter')($scope.items, $scope.data.query)
        }
        $scope.removeItem = function (item) {
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + item.labelCode + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_CONFIRMATION
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    languageAdminService().delete(item.id).then(function (data) {
                        var index = $scope.items.indexOf(item);
                        $scope.items.splice(index, 1);
                        $translate('COMMON_DELETED').then(function (msg) {
                            toastr.info(msg);
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    }, function (err) {
                    });
                });
            });
        }

        $scope.getLabel = function (item, languageCode) {
            var haslanguage = item.translations.find(t => t.languageCode == languageCode);
            if (haslanguage) {
                return haslanguage
            } else {
                var newLabel = { languageCode: languageCode, label: "" };
                return newLabel;
            }

        }

        $scope.setItemViewMode = function (item) {
            item.isEditMode = false;
        }
        $scope.setItemEditMode = function (item) {
            item.isEditMode = true;
        }

        $scope.addItem = function () {
            var item = {
                id: 0,
                labelCode: "",
                isWeb: true,
                isBackend: true,
                isMobile: true
            }
            item.translations = [];
            $scope.languages.forEach(lang => {
                var newLabel = { languageCode: lang.languageCode, label: "" };
                item.translations.push(newLabel);
            });
            item.isEditMode = true;
            $scope.data = {
                query: null
            };
            $scope.searchQuery = "";
            $scope.items.unshift(item);
            setTimeout(function () {
                $(".project-name").focus();
            }, 50);
        }

        $scope.items = [];

        var load = function () {

            languageAdminService().getList().then(function (items) {

                // map items to languages
                items.forEach(item => {
                    var translations = [];
                    $scope.languages.forEach(lang => {
                        // check if item  has language
                        var label = $scope.getLabel(item, lang.languageCode);
                        translations.push(label);
                    });
                    item.translations = translations;
                });
                $scope.items = items;
            });

        }
        load();

        

        $scope.saveItemDebounce = function (item) {
            if (item.labelCode == "") return;
            var languagecode = $scope.items.filter(i => i.labelCode == item.labelCode);
            if (languagecode.length > 1) {
                $translate('DASHBOARD_VISUALIZATION_ID_EXISTING').then(function (msg) {
                    toastr.error(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                return;
            }
            if (item.id == 0) {
                if (hasValidTranslations(item)) {
                    // Valid new language code
                    languageAdminService().add(item).then(function (savedItem) {
                        $translate('COMMON_SAVE_CUSTOM_FIELD').then(function (msg) {
                            toastr.success(msg);
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                        if(savedItem && savedItem.id){
                            item.id= savedItem.id;
                        }
                    });
                } else {
                    $translate('ADMIN_DATA_FIELD_MISSING').then(function (msg) {
                        toastr.error(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });

                }
            } else {
                languageAdminService().update(item).then(function () {
                });
            }
        }
        $scope.saveItem = debounce($scope.saveItemDebounce, 1000, false);

        function hasValidTranslations(item) {
            var result = true;
            $scope.languages.forEach(lang => {
                // check if item  has language
                var labelObj = $scope.getLabel(item, lang.languageCode);
                if (!labelObj || labelObj.label == "") {
                    result = false;
                }
            });
            return result;

        }

        $scope.numberOfPages = function () {
            if ($scope.searchQuery)
                return Math.ceil($scope.getData().length / $scope.pageSize);
            else
                return Math.ceil($scope.items.length / $scope.pageSize);

        }


    }]);


