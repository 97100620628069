angular.module("DigiLean").factory('layoutService', ['$filter',
    function ($filter) {

        function layoutService() {
            return {

                getColors: function () {
                    return ['#0099DE', 
                            '#23c6c8', 
                            '#0077B5', 
                            '#5e5e5e', 
                            '#f8ac59', 
                            '#ed5565', 
                            '#9E78B8',
                            '#2A2E36',  
                            '#84B5E3', 
                            '#23B2C7',
                            '#759296',
                            '#188687',
                            '#004569',
                            '#004569',
                        
                            '#7fccee',
                            '#91e2e3',
                            '#7fbbda',
                            '#aeaeae',
                            '#f8ac59',
                            '#f499a2',
                            '#c4aed4',
                            '#94969a',
                            '#c1daf1',
                            '#91d8e3',
                            '#bac8ca',
                            '#8bc2c3',
                            '#7fa2b4'
                            ];
                },
            };
        }
        return layoutService;
    }]);
