import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as boardService from "@common/services/boardService"
import * as timeService from "@common/services/timeService"

import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("digileanPieChart", ['$filter', 'dataService', '$uibModal', '$translate', 'a3Service', 'a3templateService', 'projectService', 'strategyService', 'suggestionService', 'deviationService', 'areaService', 'dataListService', 'debounce',
    function ($filter, dataService, $uibModal, $translate, a3Service, a3templateService, projectService, strategyService, suggestionService, deviationService, areaService, dataListService, debounce) {
        return {
            templateUrl: 'piechart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                var pieChart = null;
                
                var groupedBy = "";
                var listGroupedElements = null;

                var grafcolor = ['#2A2E36', '#0099DE', '#d9534f', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#17987e']
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }

                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                
                
                var problemCategories = null;
                var deviationTypes = null;
                scope.areas = null;
                scope.projects = null;
                scope.strategygoals = null;
                var assets = null;
                scope.a3templates = null;
                scope.boards = null;
                scope.imageUrl = staticFileService.image("piechart.jpg");
                scope.decimals = 0;

                scope.configurationChallenges = false;
                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                scope.dataSource = null;
                scope.filters = [];
                var drillDownSeries = [];
                var dataSerie = {};

                // Default options
                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);


                scope.$watch('settings', function (settings) {
                    if (settings) {
                        scope.dataSource = settings.dataSource;
                        if (settings.groupedBy) {
                            groupedBy = settings.groupedBy;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (settings.title) {
                            scope.title = settings.title;
                        }
                        if (settings.decimals) {
                            scope.decimals = settings.decimals;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        scope.getDataAndDrawChart();
                    }

                    if (!scope.dataSource && scope.isAdminMode && selectDataSourceDebounced) {
                        selectDataSourceDebounced()
                    }

                });

                scope.subscribe("DataValueAdded", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            scope.getDataAndDrawChart();
                        }
                    }
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            scope.getDataAndDrawChart();
                        }
                    }
                });

                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                scope.registerData = function () {
                    var settings = {
                        dataSource: scope.dataSource
                    };

                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'registrationDialog.html',
                        controller: 'registrationDialogController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            componentSettings: function () {
                                return settings;
                            }
                        }
                    });
                    modalInstance.result.then(function (result) {
                    });
                };

                scope.subscribe("DataValueDeleted", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            scope.getDataAndDrawChart();
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                });

                function dateInSelectedTimeFrame(utcDate) {
                    if (!scope.timePeriod) {
                        return true;
                    }
                    if (moment(utcDate).isBefore(scope.timePeriod.period.from) ||
                        moment(utcDate).isAfter(scope.timePeriod.period.to)) {
                        return false;
                    } else {
                        return true;
                    }
                }

                function getNameById(source, id) {
                    if (id && source) {
                        for (var k = 0; k < source.length; k++) {
                            if (source[k].id.toString() === id.toString()) {
                                if (source[k].name) {
                                    return source[k].name;
                                } else if (source[k].title) {
                                    return source[k].title;
                                } else {
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                                }
                            }
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                }

                // Used when grouped by user
                function getNameByUserId(source, id) {
                    if (id && source) {
                        for (var k = 0; k < source.length; k++) {
                            if (source[k].userId.toString() === id.toString()) {
                                if (source[k].fullName) {
                                    return source[k].fullName;
                                } else if (source[k].userName) {
                                    return source[k].userName;
                                } else {
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                                }
                            }
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                }

                function getPointName(element) {
                    if(!element){
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                    else if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                        return getNameById(assets, element);
                    } else if (groupedBy === "priorityStatus") {
                        return suggestionService().getPriorityStatusText(parseInt(element));
                    } else if (groupedBy === "problemCategoryId") {
                        return getNameById(problemCategories, element);
                    } else if (groupedBy === "deviationTypeId") {
                        return getNameById(deviationTypes, element);
                    }
                    else if (groupedBy === "lossTypeId") {
                        return getNameById(scope.consequences, element);
                    } else if (groupedBy === "a3TemplateId") {
                        return getNameById(scope.a3templates, element);
                    } else if (groupedBy === "categoryId") {
                        if (scope.dataSource.id.startsWith("Improvement")) {
                            return getNameById(scope.suggestionCategories, element);
                        } else {    // Only A3
                            return getNameById(scope.a3Categories, element);
                        }
                    } else if (groupedBy === "areaId") {
                        return getNameById(scope.areas, element);
                    } else if (groupedBy === "projectId") {
                        return getNameById(scope.projects, element);
                    } else if (groupedBy === "strategyGoalId") {
                        return getNameById(scope.goals, element); 
                    } else if (groupedBy === "boardId") {
                        return getNameById(scope.boards, element);
                    } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                        return getNameByUserId(scope.users, element);
                    } else if (groupedBy === "dimension" || groupedBy === "dimension2" || groupedBy === "dimension3" || groupedBy === "dimension4") {
                        if (checkElementType(groupedBy, 'user') == 1) {
                            return getNameByUserId(scope.users, element);
                        } else if (checkElementType(groupedBy, 'list') !== -1) {
                            return getNameById(listGroupedElements, element);
                        }
                        else {
                            return element;
                        }
                    } else if (groupedBy === "severity") {
                        switch (element) {
                            case "1":
                                return $translate.instant('COMMON_LOW');
                            case "2":
                                return $translate.instant('COMMON_MEDIUM');
                            case "3":
                                return $translate.instant('COMMON_HIGH');
                            default:
                                return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    } else if (groupedBy === "status") {
                        switch (element) {
                            case "unacceptable":
                                return $translate.instant('BOARD_INCOMPLETED');
                            case "blank":
                                return $translate.instant('BOARD_OPEN');
                            case "OK":
                                return $translate.instant('DASHBOARD_COMPLETED');
                            default:
                                return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    } else {
                        return element;
                    }
                }

                function checkElementType(sourceCol, typeChecking) {
                    var ref = -1;
                    var element = $filter('filter')(scope.dataSource.elements, { sourceColumn: sourceCol }, true);
                    if (element.length > 0) {
                        var type = element[0].type;
                        if (type === typeChecking && typeChecking === 'list') {
                            return element[0].dataListId;
                        } else if (type === typeChecking) {
                            return 1;
                        }
                    }
                    return ref;
                }

                function getData(groupingElement) {
                    if (!scope.timePeriod) return;
                    if (!groupingElement || groupingElement.sourceColumn == "") {
                        scope.configurationChallenges = true;
                        return;
                    }
                    var options = {
                        groupByInterval: "month", // Foreløpig kun støtte for month, kan tenkes utvides.
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: scope.timePeriod.period,
                        filters: scope.filters,
                        groupingElement: groupingElement // Hvilken dimensjon man ønsker gruppert på. Eksempelvis AssetId + de eventuell AssetId verdiene man ønsker svaret begrenset til.
                    };

                    if (!scope.dataSource || !scope.dataSource.id)
                        return
                    dataService().getGroupedSumForPeriod(scope.dataSource.id, options).then(function (values) {
                        if (values) {
                            scope.values = values.results;
                            var unit = "";
                            if (scope.dataSource.unit) {
                                unit = scope.dataSource.unit;
                            } else if (scope.dataSource.valueElement.unit) {
                                unit = scope.dataSource.valueElement.unit;
                            }
                            dataSerie = {
                                name: scope.dataSource.title,
                                unit: unit,
                                data: []
                            };

                            for (var i = 0; i < scope.values.length; i++) {
                                var pointName = getPointName(scope.values[i].element);
                                var dataPoint = {
                                    name: pointName,
                                    y: scope.values[i].totalForPeriod,
                                    drilldown: pointName,
                                    color: getColor(i, scope.values[i].element)
                                };
                                dataSerie.data.push(dataPoint);
                                var drillDownSerie = {
                                    name: pointName,
                                    id: pointName,
                                    data: []
                                };

                                angular.forEach(scope.values[i].results, function (result) {
                                    var drillDownPoint = [result.month.toString() + "-" + result.year.toString(), result.value];
                                    drillDownSerie.data.push(drillDownPoint);
                                });
                                drillDownSeries.push(drillDownSerie);

                            }

                            drawPieChart([dataSerie], drillDownSeries);
                            resizeChart();
                        }
                    });
                }

                function getColor(index, value){
                    if(groupedBy == "severity"){
                        return deviationService().getSeverityColor(parseInt(value));
                    } else if (groupedBy == "strategyGoalId"){
                        for (var k = 0; k < scope.goals.length; k++) {
                            if (scope.goals[k].id.toString() === value.toString()) {
                                if (scope.goals[k].color) {
                                    return scope.goals[k].color;
                                
                                } else {
                                    return null;
                                }
                            }
                        }
                    } else {
                        return grafcolor[index];
                    }
                }

                scope.updateDecimals = function (newDecimal) {
                    scope.decimals = newDecimal;
                    drawPieChart([dataSerie], drillDownSeries);
                    scope.updateSettings();
                };

                scope.getDataAndDrawChart = function () {
                    if (!scope.dataSource) return;
                    if (!scope.timePeriod) return;
                    scope.configurationChallenges = false;
                    var groupingElement = {
                        sourceColumn: groupedBy // e.g. "assetId"
                    };
                    if (groupedBy === "areaId") {
                        areaService().getList(true).then(function (areas) {
                            scope.areas = areas;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                        assetService.getAllAssetsIncludingSystem().then(function (data) {
                            assets = data;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "projectId") {
                        projectService().getList(true).then(function (data) {
                            scope.projects = data;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "strategyGoalId") {
                        strategyService().getBreakthroughObjectives().then(function (goals) {
                            scope.goals = goals;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "lossTypeId") {
                        deviationService().getConsequences().then(function (data) {
                            scope.consequences = data;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "problemCategoryId") {
                        deviationService().getProblemCategories().then(function (categories) {
                            problemCategories = categories;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "deviationTypeId") {
                        deviationService().getAllDeviationTypes().then(function (types) {
                            deviationTypes = types;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "categoryId") {
                        if (scope.dataSource.id.startsWith("Improvement")) {
                            suggestionService().getCategories().then(function (categories) {
                                scope.suggestionCategories = categories;
                                getData(groupingElement);
                            });
                        } else {//assue A3 categories
                            a3Service().getCategories().then(function (categories) {
                                scope.a3Categories = categories;
                                getData(groupingElement);
                            });
                        }
                    } else if (groupedBy === "boardId") {
                        boardService.getAll().then(function (boards) {
                            scope.boards = boards;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                        customerService.getAllUsers().then(function (users) {
                            scope.users = users;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "a3TemplateId") {
                        a3templateService().getList().then(function (templates) {
                            scope.a3templates = templates;
                            getData(groupingElement);
                        });
                    } else if (groupedBy === "dimension" || groupedBy === "dimension2" || groupedBy === "dimension3" || groupedBy === "dimension4") {

                        var listId = checkElementType(groupedBy, 'list');
                        if (listId > 0) {
                            var listOptions = { includeDeletedItems: true };
                            dataListService().getItems(listId, listOptions).then(function (data) {
                                listGroupedElements = data;
                                getData(groupingElement);
                            });
                        } else if (checkElementType(groupedBy, 'user') == 1) {
                            customerService.getAllUsers().then(function (users) {
                                scope.users = users;
                                getData(groupingElement);
                            });
                        } else {
                            getData(groupingElement);
                        }
                    }
                    else {
                        getData(groupingElement);
                    }
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.timePeriod,
                        dataSource: scope.dataSource,
                        groupedBy: groupedBy,
                        filters: scope.filters,
                        decimals: scope.decimals,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                /* This method will be called also when component is first initialized */
                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe)
                        return;
                    
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to)
                        return;

                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    scope.getDataAndDrawChart();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                // Select dataSource to use.
                let selectDataSourceModal
                function selectDataSource() {
                    if (selectDataSourceModal)
                        return
                    
                    selectDataSourceModal = true
                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceGroupSelector.html',
                        controller: 'dataSourceGroupSelector',
                        windowClass: 'dataserie-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            groupedBy: function () {
                                return groupedBy;
                            },
                            groupingIntervall: function(){
                                return null;
                            },
                            filters: function () {
                                return scope.filters;
                            }
                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        groupedBy = result.groupedBy;
                        scope.filters = result.filters;
                        scope.updateSettings();
                        scope.getDataAndDrawChart();
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                }
                scope.selectDataSource = selectDataSource
                const selectDataSourceDebounced = debounce(selectDataSource, 200, false)
                
                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight
                    
                    var chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (pieChart)
                    pieChart.setSize(width, height, true)
                }

                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

                function drawPieChart(dataSeries, drilldownSeries) {
                    var renderElement = $(elem).find(".piechart")[0];
                    pieChart = new Highcharts.Chart({
                        chart: {
                            renderTo: renderElement,
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: 'pie',
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false //remove link to highcharts.com
                        },
                        exporting: {
                            enabled: false //remove context menu
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.percentage:.' + scope.decimals + 'f} %</b>'
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}: {point.y:,.' + scope.decimals + 'f}',
                                    style: {
                                        fontSize: "0.9em"
                                    }
                                }
                            }
                        },
                        series: dataSeries,
                        drilldown: {
                            series: drilldownSeries
                        },
                        legend: {
                            itemStyle: {
                                fontSize: "0.9em"
                            }
                        }
                    });
                }
            }
        }
    }]);
