angular
    .module('DigiLean')
    .directive("smartCheckCell", ['debounce',
        function (debounce) {
            return {
                templateUrl: 'smartCheckCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.checked = 0;
                    var rowId = null;
                    var columnId = null;

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == rowId && attribute.projectBoardAttributeId == columnId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });

                    scope.$watch('model', function (model) {

                        var value = null;
                        if (scope.type == 'project') {  
                            rowId = scope.model.project.id;
                            columnId = scope.model.boardAttributeId;
                            value = model.projectAttribute.value;
                        } else if (scope.type == 'a3') {
                            rowId = scope.model.a3.id;
                            columnId = scope.model.columnId;
                            if(model.a3TableCell){ 
                                value = model.a3TableCell.value;
                            }             
                        }
                        if (value) {
                            setValue(value);
                        }
                        model.getSortValue = function() {
                            return scope.checked;
                        }
                    });

                    var setValue = function (cell) {
                        if(cell && cell.check == 1){
                            scope.check = 1;
                        } else {
                            scope.check = 0;
                        }
                        
                    }



                    scope.cellChanged = function () {
                        if(scope.check == 1){
                            scope.check = 0;
                        }else {
                            scope.check = 1;
                        }
                        scope.commitChangesDebounced();
                        
                    }

                    scope.saveChanges = function() {
                        var value = {
                            check: scope.check
                        }
                        if(scope.type == 'project'){
                            scope.model.projectAttribute.value = value;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        }else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = value;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: value
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);


                }
            }
        }]);
