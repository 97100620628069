import "@common/components/users/userBadge.css"
import angular from "angular"

const digiLean = angular.module('DigiLean')
digiLean.component("userBadge", {
    templateUrl: 'userBadge.html',
    bindings: {
        user: '<'
    },
    controller: function() {

    }
})