import { KPI, StrategyGoal } from "@api"
import backend from "@common/services/backendHttp"

const resourceUrl = "api/strategy"

export const updateKPI = async (kpiId: number, kpi: KPI) => {
    const url = `${resourceUrl}/UpdateKPI/${kpiId}`
    const res = await backend.put<KPI>(url, kpi)
    return res
}

export const addKPI = async (kpi: KPI) => {
    const url = `${resourceUrl}/addKPI/`
    const res = await backend.post<KPI>(url, kpi)
    return res
}

export const getGoalsWithKPIsByAsset = async (assetId: number, year: number) => {
    const strategyAssetYear = {
        assetId: assetId,
        year: year
    }
    const url = `${resourceUrl}/GetGoalsWithKPIsByAsset/`
    const res = await backend.post<StrategyGoal[]>(url,strategyAssetYear)
    return res
}