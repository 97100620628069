import { DirectiveBinding } from "vue"
export class CustomEventsEmitter {
    private element: HTMLElement | null = null
    
    created(el: HTMLElement, binding: DirectiveBinding) {
        const self = binding.dir as CustomEventsEmitter
        self.element = el
    }
    emit(eventName: string, value: any) {
        const rootEl = this.findRoot()
        const options = {
            detail: value,
            bubbles: true,
            composed: true
        }
        if (rootEl)
            rootEl.dispatchEvent(new CustomEvent(eventName, options))
    }
    findRoot() {
        if (!this.element)
            return
        const shadowRoot = this.element.getRootNode() as ShadowRoot
        if (shadowRoot) {
            const root = shadowRoot.host
            return root
        }
        return this.element
    }
}
