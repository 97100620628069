angular
    .module('DigiLean').directive("imageSection", ['$filter', '$translate', 'learningService', 'documentService', 'debounce','Lightbox',
        function ($filter, $translate, learningService, documentService, debounce, Lightbox) {
            return {
                templateUrl: 'imageSection.html',
                restrict: 'E',
                scope: {
                    'section': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.update = function () {
                        
                        learningService().updateSection(scope.section);
                    }
                    scope.commitChangesDebounced = debounce(scope.update, 2000, false);
                    scope.imageUrl = null;
                    scope.description = null;
                    scope.$watch("section", function (section) {
                        if (section) {
                            if (section.url) {
                                scope.imageUrl = section.url;
                            }
                            scope.description = section.title;
                        }
                    });
                    scope.descriptionChanged = function() {
                        scope.section.title = scope.description;
                        scope.commitChangesDebounced();
                    }
                    scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
                    scope.fileUploaded = function (file) {
                        if(scope.isEditMode){
                            scope.section.fileId = file.id;
                            scope.update();
                            documentService.getFileUrl(file.fileName).then(function (result) {
                                scope.imageUrl = result;
                            });
                        }
                    };

                    scope.openLightboxModal = function () {
                        var images = [
                            {
                                'url': scope.imageUrl,
                                'thumbUrl': scope.imageUrl
                            }
                        ];
                        Lightbox.openModal(images, 0);
                    };
                }

            }
        }]);
