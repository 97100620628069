import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import config from "@common/config"

export let appInsights:  ApplicationInsights | null = null
if (config.appInsightsConnectionString) {
    appInsights = new ApplicationInsights({
        config: {
            connectionString: config.appInsightsConnectionString,
            disableAjaxTracking: true,
            disableFetchTracking: true
        }
    })
    appInsights.loadAppInsights()
}
