import { NgComponent } from "@common/model/angularModel"
import angular from "angular"
const DigiLean = angular.module('DigiLean')
DigiLean.component('apiClientTabs', {
    templateUrl: "apiClientsTabs.html",
    controller: ['$stateParams','$location', 'authService',
        function(this: NgComponent, $stateParams,$location, authService) {
            let $ctrl = this
            $ctrl.connectors = $location.search().connectors
            
            $ctrl.isKaizenAdmin = authService.hasRole('KaizenAdmin')

            // Select tab based on input or default first.
            const tabs = ["apiClients", "legacyApiUsers"]
            $ctrl.selectTab = function (tab: string) {
                $ctrl.indexTab = tabs.indexOf(tab)
            }
            if ($stateParams.tab) {
                let tab = $stateParams.tab
                $ctrl.selectTab(tab)
            } else {
                $ctrl.selectTab(tabs[0])
            }
        }
    ]
})
