import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"

var DigiLean = angular.module('DigiLean')
DigiLean.controller('productionBoardMandatoryFieldsController', ['$scope','$filter', '$uibModalInstance', 'areaService', 'projectService', 'dataListService', 'configuration',
                                                        function ($scope,  $filter,   $uibModalInstance,   areaService,   projectService,   dataListService,   configuration) {
        $scope.settings = configuration;
        var registration = null;

        function init() {
            if ($scope.settings) {
                $scope.dataSource = $scope.settings.dataSource;
                $scope.filters = $scope.settings.filters;
                registration = $scope.settings.registration;
                initRegistration();
            }
        }
        init();

        $scope.assetChangeHandler = function (assetinfo) {
            if (assetinfo) {
                assetinfo.model.item.value = assetinfo.asset.id;
                $scope.selectedAsset = assetinfo.asset;
            }       
        }
        
        $scope.selectArea = function (area, item) {
            $scope.selectedArea = area;
            item.value = area.id;
        }
        $scope.selectProject = function (project, item) {
            $scope.selectedProject = project;
            item.value = project.id;
        }
        $scope.selectUser = function (user, item) {
            item.user = user;
            item.value = user.userId;
        }
        $scope.selectListItem = function (selectedDataListItem, item) {
            if (selectedDataListItem.name) {
                $scope.selectedDataListItem = selectedDataListItem;
                item.value = selectedDataListItem.id;
            } else { // blank list element
                $scope.selectedDataListItem = {};
                item.value = null;
            }

        }

        function initRegistration() {
            $scope.isProcessing = false;
            $scope.selectedDataListItem = null;
            angular.forEach($scope.dataSource.elements, function (element) {
                var hasFilter = $filter('filter')($scope.filters, { sourceColumn: element.sourceColumn }, true);
                if (hasFilter && hasFilter.length > 0) {
                    if(hasFilter[0].items.length == 1){
                        element.value = hasFilter[0].items[0]; //preselect the first filtered item of this filterelement
                        element.registerValue = false;
                    } else { //multiple selected elements
                        setFilteredSelectors(element, hasFilter[0].items)
                    }
                }
                else {
                    element.registerValue = true;
                    switch (element.type) {
                        case "asset":
                            assetService.getAllAssets(true).then(function (data) {
                                $scope.assets = data;
                                for (var k = 0; k < $scope.assets.length; k++) {
                                    $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                                }
                                $scope.assetmodel = { assetId:null,
                                    assets: $scope.assets,
                                    item:element};
                            });
                            break;
                        case "area":
                            areaService().getList(true).then(function (data) {
                                $scope.areas = data;
                            });
                            break;
                        case "project":
                            projectService().getList(true).then(function (data) {
                                $scope.projects = data;
                            });
                            break;
                        case "user":
                            customerService.getAllUsers().then(function (users) {
                                $scope.allUsers = users;
                            });
                            break;
                        case "list":
                            var listOptions = {includeDeletedItems:false};
                            dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                if (list) {
                                    element.dataList = list;
                                    element.selectedDataListItem = {};
                                }
                            });
                        break;
                        default:
                            element.value = null;

                    }
                }
            });

        }

        function setFilteredSelectors(element, filteritems){
            element.registerValue = true;
            switch (element.type) {
                case "asset":
                    assetService.getAllAssets(true).then(function (data) {
                        $scope.assets = [];
                        for(var i=0; i<filteritems.length; i++){
                            var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                            if (hasElement.length > 0) {
                                $scope.assets.push(hasElement[0]);
                            }
                        }
                        var selectedAsssetId = null;
                        if($scope.assets.length == 1) selectedAsssetId = $scope.assets[0].id;
                        $scope.assetmodel = { assetId:selectedAsssetId,
                        assets: $scope.assets,
                        item: element};
                    });
                    break;
                case "area":
                    areaService().getList(true).then(function (data) {
                        $scope.areas = [];
                        for(var i=0; i<filteritems.length; i++){
                            var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                            if (hasElement.length > 0) {
                                scope.areas.push(hasElement[0]);
                            }
                        }
                    });
                    break;
                case "project":
                    projectService().getList(true).then(function (data) {
                        $scope.projects = [];
                        for(var i=0; i<filteritems.length; i++){
                            var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                            if (hasElement.length > 0) {
                                $scope.projects.push(hasElement[0]);
                            }
                        }
                    });
                    break;
                case "user":
                    customerService.getAllUsers().then(function (data) {
                        $scope.allUsers = [];
                        for(var i=0; i<filteritems.length; i++){
                            var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                            if (hasElement.length > 0) {
                                $scope.allUsers.push(hasElement[0]);
                            }
                        }
                    });
                    break;
                case "list":
                    var listOptions = {includeDeletedItems:false};
                    dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                        if (list) {
                            element.dataList = [];
                            for(var i=0; i<filteritems.length; i++){
                                var hasElement = $filter('filter')(list, { id: filteritems[i] }, true);
                                if (hasElement.length > 0) {
                                    element.dataList.push(hasElement[0]);
                                }
                            }
                            element.selectedDataListItem = {};
                        }
                    });
                    break;
                default:
                    element.value = null;
                }
        }

/*

        $scope.save = function () {
            scope.isProcessing = true;
            var utcDate = moment.utc(scope.selectedDate).format();
            var valueString = scope.value.toString();
            var valuereplacecomma = valueString.replace(/,/g, '.');
            var registration = {
                valueDate: utcDate,
                value: valuereplacecomma,
                description: scope.description
            }
            angular.forEach(scope.dataSource.elements, function (element) {
                registration[element.sourceColumn] = element.value;
            });


            dataService().add(scope.dataSource.id, registration).then(function (savedRegistration) {
                scope.isPopupOpen = false;
                initRegistration();
            });

        }*/

        function isInputOk(){
            var isOk = true;
            angular.forEach($scope.dataSource.elements, function (element) {
                if(element.isMandatory && !element.value){
                    return false;
                }
            });
            return isOk;
        }

        function checkMandatoryField() {
            angular.forEach($scope.dataSource.elements, function (element) {
                if (element.type == 'project') {
                    if ($scope.selectedProject && $scope.selectedProject.id) {
                        element.dataMissing = false;
                        element.value = scope.selectedProject.id;
                    } else {
                        element.dataMissing = true;
                    }
                }
                if (element.type !== 'bool' && !element.value && element.isMandatory) {
                    element.dataMissing = true;
                } else {
                    element.dataMissing = false;
                }
            });
        }
        $scope.ok = function () {
            var saveOK = true;
            angular.forEach($scope.dataSource.elements, function (element) {
                if (element.type == 'project') {
                    if ($scope.selectedProject && $scope.selectedProject.id) {
                        element.value = $scope.selectedProject.id;
                        element.dataMissing = false;
                    } else if (element.value) { //filter value is used
                        element.dataMissing = false;
                    } else if (element.isMandatory) {
                        element.dataMissing = true;
                        saveOK = false;
                        element.value = null;
                    }
                }
                registration[element.sourceColumn] = element.value;

                if (element.type !== 'bool' && !element.value && element.isMandatory) {
                    element.dataMissing = true;
                    saveOK = false;
                } else {
                    element.dataMissing = false;
                }
            });

            if (saveOK) {
                var configuration = {
                    filters: $scope.filters,
                    registration: registration
                }
    
                $uibModalInstance.close(configuration);
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);