angular
    .module('DigiLean')
    .controller("newProjectController", ['$scope', '$state', '$uibModalInstance', '$filter', 'projectService',
        function ($scope, $state, $uibModalInstance, $filter, projectService) {

            $scope.template = null;
            $scope.project = {};

            $scope.templates = [
                {name: "Forbedringsprosjekt", dbId: "A3"},
                {name: "Last Planner", dbId: "LastPlanner"}
            ];

            $scope.changeType = function (type) {
                $scope.project.category = type.dbId;
            };

            $scope.ok = function () {
                projectService().addProject($scope.project).then(function (createdProject) {
                    $uibModalInstance.close(createdProject);
                });
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
