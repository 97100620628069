var DigiLean = angular.module('DigiLean');
DigiLean.directive("projectResourceLinks", ['$filter', '$state','projectService', 'navigationService', '$uibModal',
    function ($filter,$state, projectService, navigationService, $uibModal) {
        return {
            templateUrl: 'projectResourceLinks.html',
            restrict: 'E',
            scope: {
                'projectId': '<',
                'assetId': '<',
            },
            link: function (scope, elem, attrs) {

                scope.hasA3Module = navigationService().hasModule("A3");
                scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                    scope.hasA3Module = navigationService().hasModule("A3");
                });

                scope.boards = [];
                scope.dashboards = [];
                scope.a3s = [];
                scope.isLoading = true;
                scope.$watch("projectId", function () {
                    if (scope.projectId) {
                        projectService().getBoardsForProject(scope.projectId).then(function (boards) {
                            scope.boards = boards;
                            scope.isLoading = false;
                        });
                        projectService().getDashboardsForProject(scope.projectId).then(function (dashboards) {
                            scope.dashboards = dashboards;
                            scope.isLoading = false;
                        });
                        projectService().getA3ForProject(scope.projectId).then(function (a3s) {
                            scope.a3s = a3s;
                            scope.isLoading = false;
                        });
                    }
                })

                scope.createNewBoard = function () {
                    if(scope.assetId){
                        $state.go('newFlexiboard', { assetid: scope.assetId,  source: "project" }, { reload: false });
                    }
                }

                scope.createNewA3 = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'newA3Form.html',
                        controller: 'newA3ModalController',
                        resolve: {
                            a3: function () {
                                return {
                                    id: 0,
                                    title: "",
                                    assetId: scope.assetId,
                                    category: "",
                                    goal: ""
                                }
                            }

                        }
                    });

                };
            }

        }
    }]);
