angular
    .module('DigiLean')
    .controller("allowedModulesDialogController", ['$scope', '$uibModalInstance', 'modulesService', 'customer',
        function ($scope, $uibModalInstance, modulesService, customer) {


            if (!customer || !customer.id) {
                toastr.error("Missing customer Id", "Error", {timeOut: 5000})
                setTimeout(() => {
                    $uibModalInstance.dismiss('cancel')
                }, 500)
            }
            else {
                $scope.account = customer.account

                let promises = []
                
                promises.push(modulesService.getModulesSetupForKaizenAdmin(customer.id))
                promises.push(modulesService.getCustomerAllowedModulesForCustomer(customer.id))
    
                Promise.all(promises).then(all => {
                    const setup = all[0]
                    $scope.defaultAllowedModules = setup.defaultAllowed

                    const allAllowedModules = all[1]
                    
                    const extraModules = setup.otherModules.map(m => {
                        m.isAllowed = allAllowedModules.some(a => a.name == m.name)
                        return m
                    })
                    $scope.extraModules = extraModules
                })
            }
            
            $scope.toggleModule = function(module) {
                if (module.isAllowed)
                    modulesService.disallowModule(customer.id, module.name)
                else
                    modulesService.allowModule(customer.id, module.name)
            }

            $scope.close = function () {
                $uibModalInstance.close('close')
            }
        }])