var DigiLean = angular.module('DigiLean');
DigiLean.controller('graphConfigurationController', ['$scope', '$uibModalInstance', 'configuration',
    function ($scope, $uibModalInstance, configuration) {
        $scope.configuration = configuration;

        function init() {
            if (!$scope.configuration) {
                $scope.configuration.selectedGrouping = "none";
                $scope.configuration.showNavigation = false;
                $scope.configuration.showRangeSelector = false;
            }
        }
        init();

        $scope.ok = function () {
            $uibModalInstance.close($scope.configuration);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);