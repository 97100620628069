import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module("DigiLean");
DigiLean.controller('systemactivityfeedController', ['$scope', '$interval', '$filter','$element', 'activityService', 'authService',
    function ($scope, $interval, $filter,$element, activityService, authService) {

        $scope.feed = [];
        $scope.customers = [];

        $scope.translationData = {
            noOfFeeds: $scope.feed.length
        };

        var $translate = $filter('translate');
        var translateOptions = {
            month: $translate('COMMON_TIMEELAPSED_MONTH'),
            days: $translate('COMMON_TIMEELAPSED_DAYS'),
            hours: $translate('COMMON_TIMEELAPSED_HOURS'),
            mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
            seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
            now: $translate('COMMON_TIMEELAPSED_NOW')
        }
        // This is only for Kaizen so return if not in role.
        if (!authService.hasRole("KaizenAdmin")) return;


        // Subscribe to Events
        var subscribeToEvents = function () {
            $scope.subscribe('SystemActivityOccured', function (activity) {
                // Handle activity and add to list. Need some filter for what is relevant.
                activity.cssState = "slideInDown";
                $scope.feed.unshift(activity);
                // Recalculate time elapsed
                $scope.feed.forEach(function (activity) {
                    activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                    if (activity.payLoad && typeof activity.payLoad === "string") {
                        activity.payLoad = JSON.parse(activity.payLoad);

                    }
                    var customer = $filter('filter')($scope.customers, { id: activity.customerId }, true);
                    activity.customer = customer[0].name;
                });
                $scope.$apply();
                $scope.translationData.noOfFeeds = $scope.feed.length;
            });
        }
        subscribeToEvents();
        var getLatestActivity = function () {
            // Handle activity and add to list. Need some filter for what is relevant.
            activityService().getLog().then(function (log) {
                log.forEach(function (activity) {
                    updateActivityTimeSpan(activity);
                    var customer = $filter('filter')($scope.customers, { id: activity.customerId }, true);
                    activity.customer = customer[0].name;
                });
                $scope.feed = log;
                $scope.translationData.noOfFeeds = $scope.feed.length;
            });
        }

        var updateActivityTimeSpan = function (activity) {
            activity.occured = timeService.getTimeElapsed(activity.activityDate);
        }

        var updateFeedTimeStamp = function () {
            $scope.feed.forEach(function (activity) {
                updateActivityTimeSpan(activity);
            });
        }

        // refresh timestamp

        var updateTimer = $interval(updateFeedTimeStamp, 5000);
        // listen on DOM destroy (removal) event, and cancel the next UI update
        // to prevent updating time after the DOM element was removed.
        $element.on('$destroy', function () {
            $interval.cancel(updateTimer);
        });

        customerService.getCustomersSimpleForAdmin().then(function (customers) {
            $scope.customers = customers;
            getLatestActivity();
        });
    }]);