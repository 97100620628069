import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("yearlyBoardPreview",
        function () {
            return {
                templateUrl: 'yearlyBoardPreview.html',
                restrict: 'E',
                scope: {
                    template: '='
                },
                link: function (scope, elem, attrs) {
                    // Default week
                    scope.months = timeService.getMonths();
                    

                    scope.$watch('template', function (template) {
                        if (template) {
                            if (template.settings) {
                                var settings = JSON.parse(template.settings);
                                template.isWeeklyBoard = settings.IsWeeklyBoard;
                            } else {
                                template.isWeeklyBoard = false;
                            }
                            parseStyle(template);
                            if (template.id) {
                                boardService.getTemplate(template.id).then(function(model) {
                                    scope.template.rows = model.rows;
                                    scope.template.columns = model.columns;
                                    parseStyle(template);
                                })
                            }   
                        }  
                    });

                    function parseStyle(template) {
                        angular.forEach(template.columns, function (col) {
                            col.style = {
                                "background": col.categoryStyle.backgroundColor,
                                "color": col.categoryStyle.foregroundColor
                            }
                        });
                        angular.forEach(template.rows, function (row) {
                            row.style = {
                                "background": row.categoryStyle.backgroundColor,
                                "color": row.categoryStyle.foregroundColor
                            }
                        });
                    }

                }
            }
        }
    );
