angular.module("DigiLean").factory('improvementCommonService', ['suggestionService', '$uibModal',
    function (suggestionService, $uibModal) {
        const suggestionOpenForEdit = function (suggestionId) {
            return suggestionService().get(suggestionId).then(function (suggestion) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: false,
                    templateUrl: 'fullImprovementInfoForm.html',
                    controller: 'fullImprovementModalController',
                    windowClass: 'fullSuggestion-modal-window',
                    resolve: {
                        suggestion: function () {
                            return suggestion
                        },
                        activeTab: function () {
                            return "Info"
                        }
                    }
                })
                return modalInstance.result.then(function (updatedSuggestion) {
                    return updatedSuggestion
                })
            })
        }

        return {
            suggestionOpenForEdit
        }
    }
])