export function translateOldFilter(filter, userid) {
    const newFilter = [];
    if(!filter) return newFilter;
    // status should not be translated, only ignored
    if (filter.goal || filter.priority || filter.asset || filter.category || filter.project || filter.myResponsible || filter.status) {
        // old filter detected
        if (filter.priority) {
            const itemFilter = generateItemFilter("priorityStatus", filter.priority)
            if (itemFilter)
                newFilter.push(itemFilter)
        }
        if (filter.asset) {
            const itemFilter = generateItemFilter("assetId", filter.asset)
            if (itemFilter)
                newFilter.push(itemFilter)
        }
        if (filter.category) {
            const itemFilter = generateItemFilter("categoryId", filter.category)
            if (itemFilter)
                newFilter.push(itemFilter)
        }
        if (filter.project) {
            const itemFilter = generateItemFilter("S.projectId", filter.project)
            if (itemFilter)
                newFilter.push(itemFilter)
        }
        if (filter.goal) {
            const itemFilter = generateItemFilter("Goal.Id", filter.goal)
            if (itemFilter)
                newFilter.push(itemFilter)
        }
        if (filter.myResponsible) {
            const itemFilter = generateItemFilter("responsibleUserId", [userid])
            if (itemFilter)
                newFilter.push(itemFilter)
        }
    } else
        return filter
    
    return newFilter
}

function generateItemFilter(sourceColumn, items) {
    if (!items || !Array.isArray(items) || items.length === 0)
        return null
    
    let itemFilter = {
        sourceColumn: sourceColumn,
        operator: "InList",
        items: []
    }
    // const itemsString = items.map(i => `${i}`)
    itemFilter.items = items
    return itemFilter
}