angular
    .module('DigiLean')
    .controller("targetProjectVisualizationController", ['$scope', '$state', '$uibModalInstance', '$filter', 'qualityService', 'projectService', 'projectId', 'visualizationType',
        function ($scope, $state, $uibModalInstance, $filter, qualityService, projectService, projectId, visualizationType) {

            $scope.newtype = null;//visualizationType;
            $scope.projectId = projectId;
            $scope.selectedProject = null;
            
            $scope.existingtype = null;

            $scope.choosentype = visualizationType;
            
            /*
            qualityService().getVisualizationTypesForProjects().then(function (result) {
                $scope.types = result;
            });*/
            projectService().getActiveProjects().then(function (projects) {
                $scope.projects = projects;
                if($scope.projectId){
                    var projects = $filter('filter')($scope.projects, { id: $scope.projectId }, true);
                    if (projects.length === 1) {
                        $scope.selectedProject = projects[0];
                    }
                }   
            });
            qualityService().getVisualizationTypesForProjects().then(function (result) {
                $scope.types = result;
            });
            $scope.setVisualizationType = function (type) {
                $scope.type = null;
                $scope.choosentype = type;
            };

            $scope.newType = function (type) {
                $scope.existingtype = null;
                $scope.choosentype = type;
            };
            $scope.setProject = function (project) {
                $scope.projectId = project.id;
            };

            $scope.ok = function () {
                var result = {
                    type: $scope.choosentype,
                    projectId : $scope.projectId
                }
                $uibModalInstance.close(result);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
