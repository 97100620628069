angular
    .module('DigiLean')
    .directive("numberCell", ['$filter', 'debounce', 'projectService', 'mathService',
        function ($filter, debounce, projectService, mathService) {
            return {
                templateUrl: 'numberCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.number = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";
                    scope.projectAttributeId = null;
                    scope.projectId = null;
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    var settings = null;
                    scope.$watch('model', function (model) {
                        if (model) {
                            scope.projectAttributeId = model.boardAttributeId;
                            scope.projectId = model.project.id;
                            var value = model.projectAttribute.value;
                            settings = model.column.settings;
                            if (settings) {
                                if (settings.unit) {
                                    scope.unit = settings.unit;
                                    if (settings.unitPlacement) {
                                        scope.unitPlacement = settings.unitPlacement;
                                    }
                                }
                                if (settings.decimals !== null || settings.decimals !== undefined) {
                                    scope.decimals = settings.decimals;
                                }
                            }
                            if (value) {
                                setValue(value);
                            }

                            model.getSortValue = function () {
                                return scope.number;
                            }
                        }

                    });

                    scope.autofocus = function () {
                        setTimeout(function () {
                            //document.querySelector( "input" ).focus();
                            $(".project-text-cell").focus();
                        }, 50);
                    }

                    var setValue = function (cell) {
                        scope.background = cell.background;
                        var number = cell.number;

                        if (scope.decimals !== null && scope.decimals !== undefined) {
                            scope.number = mathService().roundNumber(number, scope.decimals);
                        }

                        // if (scope.number > 1000) {
                        //     number = $filter('number')(number, fractionSize)
                        // }
                        scope.number = number;
                        scope.updateAggregateCell();

                        scope.color = cell.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = cell.background;
                        }
                    }
                    scope.subscribe("SmartActionListNumberCellDecimalUpdated", function (value) {
                        if (scope.model.boardAttributeId == value.smartActionListColumnId) {
                            scope.decimals = value.decimals;
                            var value = {
                                background: scope.background,
                                number: scope.number
                            }
                            setValue(value);
                        }
                    });

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("ProjectNumberCellDecimalUpdated", function (value) {
                        if (scope.projectAttributeId === value.projectAttributeId) {
                            scope.decimals = value.decimals;
                            var value = {
                                background: scope.background,
                                number: scope.number
                            }
                            setValue(value);
                        }
                    });
                    scope.subscribe("ProjectNumberCellUnitUpdated", function (value) {
                        if (scope.projectAttributeId === value.projectAttributeId) {
                            scope.unit = value.unit;
                        }
                    });

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default') {
                            scope.background = "inherit";
                            scope.color = "#676a6c";
                        } else {
                            scope.background = postIt.background;
                            scope.color = "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.numberChanged = function (number) {
                        if (scope.number === number) return;
                        scope.number = number;
                        scope.updateAggregateCell();
                        scope.commitChangesDebounced();
                    }
                    scope.saveChanges = function () {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            number: scope.number
                        }
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({ projectAttribute: scope.model.projectAttribute })
                    }

                    scope.sendEvent = function () {
                        var payload = {
                            projectAttributeId: scope.projectAttributeId,
                            projectId: scope.projectId,
                            value: scope.number
                        }
                        scope.publish("ProjectNumberCellUpdated", payload);
                    }

                    scope.updateAggregateCell = debounce(scope.sendEvent, 1000, false);

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
