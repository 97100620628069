﻿var DigiLean = angular.module('DigiLean');

DigiLean.directive("deviationConsequenceSummary", ['$translate', 'deviationService','debounce',
    function ($translate, deviationService, debounce) {
        return {
            templateUrl: 'deviationConsequenceSummary.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'timeframe': '@',
                'settings': '=',
                'settingsChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {

                scope.filters = null;
                var causeSummaryData = null;
                var drilledDown = false;
                var countTranslated = $translate.instant('COMMON_COUNT');
                var accumulatedTranslated = $translate.instant('DASHBOARD_ACCUMULATED');
                var recordingTranslated = $translate.instant('DEVIATION_NUMBER_OF_DEVIATIONS');

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    loadData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        timePeriod: scope.options.timePeriod,
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                scope.$watch('timeframe', function (timeframe) {
                    scope.setTimePeriodDebounced(timeframe, null);
                });


                // Default options
                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        };
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                function loadData() {
                    if (!scope.timePeriod || !scope.timePeriod.period) return;
                    var options = {TimePeriod:scope.timePeriod.period,
                        Filters: scope.filters}
                    deviationService().getSummaryOfConsequences(options).then(function (data) {
                        causeSummaryData = data;
                        drawChart(data);
                        resizeChart();
                    });
                }

                scope.subscribe("DeviationCreated", function (deviation) {
                    loadData();
                });

                
                scope.$watch('settings', function (settings) {
                    scope.filters = null;
                    if (settings) {
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        scope.filters = settings.filters;
                        loadData();
                    } else {
                        loadData();
                    }
                });


                function getDeviations(columnOrderNo){
                    //TODO implement
                }

                // Draw options
                var categoryChart;
                var paddingRight = 30;
                var paddingBottom = 100;
                function resizeChart() {
                    var host = $(elem).closest(".grid-stack-item-content");
                    if (host.length == 0)
                        host = $(elem).closest(".modal-body")
                    
                    if (host.length == 0) return;
                    var width = host.width();
                    var height = host.height();
                    if (categoryChart)
                        categoryChart.setSize(width - paddingRight, height - paddingBottom, true);
                }
                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

                function drawChart(data) {

                    var categories = [];
                    var count = [];
                    var accumulated = [];

                    for (var i = 0; i < data.length; i++) {
                        var currentItem = data[i];
                        categories.push(currentItem.title);
                        count.push(currentItem.count);
                        accumulated.push(currentItem.accumulated);

                    }

                    var renderElement = $(elem).find(".consequence-summary-chart")[0];
                    categoryChart = Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        },
                        legend: {
                            layout: 'horizontal',
                            verticalAlign: 'bottom'
                        },
                        exporting: { enabled: false },
                        tooltip: {
                            formatter: function () {
                                if (this.series.name === accumulatedTranslated) {
                                    return this.y + '%';
                                }
                                //return this.x + ':<br>' + '<b># ' + this.y.toString().replace('.', ',') + ' </b>' + '<br>' + '<b>Percent ' + Math.round((this.y / 28) * 100) + '% </b>';
                                return this.x + ':<br>' + '<b># ' + this.y + '</b>';
                            }
                        },
                        xAxis: {
                            categories: categories,
                            crosshair: true
                        },
                        yAxis: [{
                            title: {
                                text: recordingTranslated
                            }
                        }, {
                            labels: {
                                formatter: function () {
                                    return this.value + '%';
                                }
                            },
                            max: 100,
                            min: 0,
                            opposite: true,
                            plotLines: [{
                                color: 'red',
                                dashStyle: 'shortdash',
                                value: 80,
                                width: 3,
                                zIndex: 10
                            }],
                            title: {
                                text: ''
                            }
                        }],
                        series: [{
                            name: countTranslated,
                            data: count,
                            color: '#3bafda',
                            type: 'column',
                            point: {
                                events: {
                                    click: function () {
                                        getDeviations(this.x);
                                    }
                                }
                            }
                        }, {
                            data: accumulated,
                            name: accumulatedTranslated,
                            type: 'spline',
                            yAxis: 1,
                            id: 'accumulated'
                        }
                        ]
                    });
                }

            }
        }
    }]);
