angular
    .module('DigiLean')
    .directive("monthlyStatusDeviationSeverity", ['$filter', '$translate', 'deviationService',
        function ($filter, $translate, deviationService) {
            return {
                templateUrl: 'monthlyStatusDeviationSeverity.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {

                    var graphtextcolor = '#ffffff';

                    var xfirstlinecategories = [],
                        xmonthcategories = [],
                        browserDataSuggestionByAsset = [];

                    function getSeverityDescription(severity) {
                        switch (severity) {
                            case 1:
                                return $translate.instant('COMMON_LOW');
                            case 2:
                                return $translate.instant('COMMON_MEDIUM');
                            case 3:
                                return $translate.instant('COMMON_HIGH');
                            default:
                                return $translate.instant('COMMON_UNDEFINED');
                        }
                    }

                    /*
                    function getSeverityColor(severity) {
                        switch (severity) {
                            case 1:
                                return "#s1872ab";
                            case 2:
                                return "#FFCE54";
                            case 3:
                                return "#DA4453";
                            default:
                                return "#2A2E36";
                        }
                    }*/

                    function getStatusData() {
                        var endDate = moment().utc().endOf('month');
                        var startDate = moment().utc().add(-3, 'month').startOf('month');
                        // Monthly suggested, need to find start and end of month
                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };
                        deviationService().getSeverityMonthCountOnStatus(timePeriod).then(function (result) {

                            var newBySeverityData = result.new;
                            var implementedBySeverityData = result.implemented;

                            if (newBySeverityData) {
                                // Build the data arrays
                                for (var i = 0; i < newBySeverityData.length; i++) {
                                    var severity = getSeverityDescription(newBySeverityData[i].severity);
                                    if (severity === null) {
                                        severity = $translate.instant('COMMON_UNDEFINED');
                                    }
                                    // add new browser data
                                    browserDataSuggestionByAsset.push({
                                        name: severity,
                                        data: newBySeverityData[i].suggestionCount,
                                        color: deviationService().getSeverityColor(newBySeverityData[i].severity),
                                        stack: firstlinecategorynew,
                                        xAxis: 1
                                    });

                                    // add implemented browser data
                                    if (implementedBySeverityData.length > i) {
                                        var severityImpl = getSeverityDescription(implementedBySeverityData[i].severity);
                                        if (severityImpl === null) {
                                            severityImpl = $translate.instant('COMMON_UNDEFINED');
                                        }
                                        browserDataSuggestionByAsset.push({
                                            name: severityImpl,
                                            data: implementedBySeverityData[i].suggestionCount,
                                            color: deviationService().getSeverityColor(implementedBySeverityData[i].severity),
                                            showInLegend: false,
                                            stack: firstlinecategoryimpl,
                                            xAxis: 1
                                        });
                                    }

                                }

                                xmonthcategories = newBySeverityData[0].monthNumbers;

                                for (var i = 0; i < xmonthcategories.length; i++) {
                                    xfirstlinecategories.push('');
                                    xfirstlinecategories.push(firstlinecategorynew);
                                    xfirstlinecategories.push(firstlinecategoryimpl);
                                    xfirstlinecategories.push('');
                                }

                                var zeroes = [];
                                xfirstlinecategories.forEach(function () { zeroes.push(0); });


                                browserDataSuggestionByAsset.push({
                                    name: '',
                                    data: zeroes,
                                    showInLegend: false,
                                    stack: firstlinecategoryimpl,
                                });

                                var monthly = $(elem).find(".monthlystatusdeviationseverity")[0];
                                Highcharts.chart({
                                    chart: {
                                        renderTo: monthly,
                                        type: 'column'
                                    },

                                    title: {
                                        text: ''
                                    },

                                    credits: {
                                        enabled: false
                                    },
                                    exporting: { enabled: false },

                                    xAxis: [
                                        {
                                            categories: xfirstlinecategories,
                                            labels: { autoRotation: false, style: { textOverflow: 'none' } },
                                            tickWidth: 0,
                                        },
                                        {
                                            categories: xmonthcategories,
                                            lineColor: graphtextcolor,
                                            tickWidth: 1,
                                            tickLength: 30
                                        },
                                    ],

                                    yAxis: {
                                        allowDecimals: false,
                                        min: 0,
                                        title: {
                                            text: noOfSuggestions
                                        }
                                    },

                                    tooltip: {
                                        formatter: function () {
                                            return '<b>' + this.x + ' ' + this.series.options.stack + '</b><br>' +
                                                this.series.name + ': ' + this.y + '<br>' +
                                                'Total: ' + this.point.stackTotal;
                                        }
                                    },
                                    plotOptions: {
                                        series: {
                                            events: {
                                                legendItemClick: function () {
                                                    var myname = this.name;
                                                    var myvis = !this.visible;
                                                    this.chart.series.forEach(function (elem) {
                                                        if (elem.name == myname) {
                                                            elem.setVisible(myvis);
                                                        }
                                                    });
                                                    return false;
                                                }
                                            }
                                        },
                                        column: {
                                            stacking: 'normal',
                                            groupPadding: 0.20,
                                        }
                                    },
                                    series: browserDataSuggestionByAsset
                                });
                                var onLegendClick = function (event) {
                                    var myname = this.name;
                                    var myvis = !this.visible;
                                    this.chart.series.forEach(function (elem) {
                                        if (elem.name == myname) {
                                            elem.setVisible(myvis);
                                        }
                                    });
                                    return false;
                                }
                            }
                        });


                    }

                    var firstlinecategorynew = "";
                    var firstlinecategoryimpl = "";
                    var noOfSuggestions = "";
                    var fromInProgressToImpl = "";
                    var fromPlannedToInProgress = "";


                    $translate(['COMMON_NEW', 'IMPROVEMENT_IMPL', 'DEVIATION_NUMBER_OF_DEVIATIONS', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'DEVIATION_FROM_IN_PROGRESS_TO_RESOLVED']).then(function (translations) {
                        firstlinecategorynew = translations.COMMON_NEW;
                        firstlinecategoryimpl = translations.IMPROVEMENT_IMPL;
                        noOfSuggestions = translations.DEVIATION_NUMBER_OF_DEVIATIONS;
                        fromInProgressToImpl = translations.DEVIATION_FROM_IN_PROGRESS_TO_RESOLVED;
                        fromPlannedToInProgress = translations.IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS;


                        getStatusData();

                    });
                }

            }
        }]);



















