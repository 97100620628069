﻿angular
    .module('DigiLean')
    .directive("iboxExpandButton", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'iboxExpandButton.html',
                restrict: 'E',
                scope: {
                    'isClosed': '@'
                },
                link: function (scope, element, attrs) {    
                    if(scope.isClosed && scope.isClosed === "true"){                  
                        var ibox = $(element).closest('div.ibox');
                        var button = $(element).find('i');
                        var content = ibox.find('div.ibox-content').first();
                        content.slideToggle(0);
                        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                        ibox.toggleClass('').toggleClass('border-bottom');
                        /*setTimeout(function () {
                            ibox.resize();
                            ibox.find('[id^=map-]').resize();
                        }, 50);  */                      
                    }

                    scope.$watch("isClosed", function () {
                        if (scope.isClosed && scope.isClosed === "false") {
                            var ibox = $(element).closest('div.ibox');
                            var button = $(element).find('i');
                            var content = ibox.find('div.ibox-content').first();
                            content.slideToggle(200);
                            button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                            ibox.toggleClass('').toggleClass('border-bottom');
                            setTimeout(function () {
                                ibox.resize();
                                ibox.find('[id^=map-]').resize();
                            }, 50);
                        
                        }
                    });

                    // Collapse ibox function
                    $('.collapse-link').off();
                    $('.collapse-link').click(function () {
                        var ibox = $(this).closest('div.ibox');
                        var button = $(this).find('i');
                        var content = ibox.find('div.ibox-content');
                        content.slideToggle(200);
                        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                        ibox.toggleClass('').toggleClass('border-bottom');
                        setTimeout(function () {
                            ibox.resize();
                            ibox.find('[id^=map-]').resize();
                        }, 50);
                    });
                }
        }
    }]);
