import * as timeService from "@common/services/timeService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

const DigiLean = angular.module('DigiLean')
DigiLean.directive("trendbarchart", ['$uibModal', '$translate', 'dataService', 'debounce',
    function ($uibModal, $translate, dataService, debounce) {
        return {
            templateUrl: 'trendbarchart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }

                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)

                var intervall = "month";
                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                scope.imageUrl = staticFileService.image('trendbarchart.jpg');
                scope.seriesData = [];
                scope.yAxisPlotLines = [];
                scope.xAxisPlotLines = [];
                scope.decimals = 0;
                scope.showRegression = true;
                scope.formula = 'sum';
                scope.graphcolor = "7cb5ec";
                
                var timeFrames = [   
                    'all', 
                    'last3years', 
                    'lastyear', 
                    'thisyear',
                    'fiscalyear',
                    'YTD',
                    'last12mth', 
                    'month', 
                    'previousmonth',
                    'custom'    
                ];
                var timeSettings = {
                    timeframe: "YTD",
                    timeframes: timeFrames,
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.settings = settings;
                    // apply
                    if (scope.settings) {
                        scope.dataSource = scope.settings.dataSource;
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (scope.settings.title) {
                            scope.title = scope.settings.title;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.showRegression === false) {
                            scope.showRegression = settings.showRegression;
                        }
                        if (settings.graphcolor) {
                            scope.graphcolor = settings.graphcolor;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        if (settings.formula) {
                            scope.formula = settings.formula;
                        }
                    } 
                    if (!scope.dataSource && scope.isAdminMode){
                        setTimeout(function() {
                            scope.selectDataSource();
                        }, 200); 
                    }
                });

                // Subscribe to component related event
                scope.subscribe("DataValueAdded", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            refreshData();
                        }
                    }
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            refreshData();
                        }
                    }
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            refreshData();
                        }
                    }
                });

                function dateInSelectedTimeFrame(utcDate) {
                    if (!scope.timePeriod) {
                        return true;
                    }
                    if (moment(utcDate).isBefore(scope.timePeriod.period.from) ||
                        moment(utcDate).isAfter(scope.timePeriod.period.to)) {
                        return false;
                    } else {
                        return true;
                    }
                }
                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                scope.changeColor = function () {
                    refreshData();
                    scope.updateSettings();
                }

                scope.toggleShowRegression = function () {
                    refreshData();
                    scope.updateSettings();
                }
                scope.changeFormula = function(newFormula){
                    scope.formula = newFormula;
                    scope.updateSettings();
                    refreshData();
                }
                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.timePeriod,
                        dataSource: scope.dataSource,
                        filters: scope.filters,
                        showRegression: scope.showRegression,
                        formula: scope.formula,
                        graphcolor: scope.graphcolor,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                /* This method will be called also when component is first initialized */
                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    refreshData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                function refreshData() {
                    scope.seriesData = [];
                    scope.yAxisPlotLines = [];
                    scope.xAxisPlotLines = [];
                    if (scope.dataSource) {
                        getValuesForSerie(scope.dataSource);
                    }
                }

                function setupChartData(values) {
                    scope.xAxisData = [];

                    if (values) {
                        for (var i = 0; i < values.length; i++) {
                            var xAxisDataLabel = "";
                            // Assume every groupingElement has data for every bar
                            if(scope.dataSource.unitOfTime == "DAY"){
                                xAxisDataLabel = moment(values[i].valueDate).format("D.MMM");
                            } else if(scope.dataSource.unitOfTime == "WEEK"){
                                xAxisDataLabel = $translate.instant('COMMON_TIMEPERIOD_WEEK_PREFIX') + values[i].week;
                            } else {
                            
                                if (values[i].month) {
                                    xAxisDataLabel = values[i].month + ".";
                                }
                                xAxisDataLabel = xAxisDataLabel + values[i].year;
                            }
                            scope.xAxisData.push(xAxisDataLabel);
                        }
                    } else {
                        // alert("No values for defined component");
                    }

                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                        var color = scope.graphcolor;
                        var currentValue = values[i].value;
                        
                        if(scope.formula == "average" && values[i].count){
                            currentValue = values[i].value/values[i].count;
                        }
                    
                        var element = {
                            x: i,
                            color: color,
                            y: currentValue
                        };
                        data.push(element);
                    }

                    if(scope.showRegression){
                        scope.seriesData = [{
                            regression: true ,
                            regressionSettings: {
                                name : 'Trend'
                            },
                            data: data,
                            type: 'column'
                        }];
                    } else {
                        scope.seriesData = [{
                            data: data,
                            type: 'column'
                        }];
                    }
                }

                function setNullValues(array, no){
                    for(var i=0; i<no; i++){
                        array.push(null);
                    }
                    return array;
                }

                function getValuesForSerie(datasource) {
                    if (!scope.timePeriod) return;

                    intervall = "month";
                    if(datasource.unitOfTime){
                        intervall = datasource.unitOfTime;
                    }
                    var options = {
                        groupByInterval: intervall,
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: scope.timePeriod.period,
                        filters: scope.filters,
                    };

                    dataService().getTargetValuesForPeriod(datasource.id, options).then(function (values) {
                        if (values) {
                            if(scope.dataSource.objectSource =='internal'){
                                for(var v=0; v<values.length; v++) {
                                    var options = {
                                        timePeriod: scope.options.timePeriod.period,
                                        value: values[v],
                                        valueUnit: datasource.valueUnit,
                                        filters: scope.filters,
                                        decimals : scope.decimals,
                                        unitOfTime: datasource.unitOfTime
                                    };
                                }
                            }
                            setupChartData(values);
                            drawChart();
                        }
                    });                        
                }


                var currentChart = null;
                function drawChart() {

                    var renderElement = $(elem).find(".trendbarchart")[0];
                    currentChart = new Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'column',
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            categories: scope.xAxisData,
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: scope.dataSource.valueElement.unit
                            },
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>{point.x}</b><br>',
                            pointFormat: $translate.instant('COMMON_TOTAL') + ': {point.stackTotal}'
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    style: {
                                        fontSize: "0.9em"
                                    },
                                    enabled: true,
                                    formatter: function() {
                                        var value = this.point.y;
                                
                                        if (value % 1) {
                                          return Highcharts.numberFormat(value, 2);
                                        }
                                        return Highcharts.numberFormat(value, -1);
                                      }
                                }
                            }
                        },
                        legend: {
                            enabled: false    
                        },
                        series: scope.seriesData

                    });
                    resizeChart();
                }

                scope.registerData = function () {
                    var settings = {
                        dataSource: scope.dataSource
                        };   
                    
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'registrationDialog.html',
                            controller: 'registrationDialogController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                componentSettings: function () {
                                    return settings;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {      
                        });
                    }; 

                let selectDataSourceModal
                scope.selectDataSource = function () {
                    if (selectDataSourceModal)
                        return

                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return false;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return false;
                            }
                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        scope.filters = result.filters;
                        scope.options.unitOfTime = scope.dataSource.unitOfTime;
                        scope.updateSettings();
                        refreshData();
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                };

                
                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight
                    
                    var chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (currentChart)
                        currentChart.setSize(width, height, true)
                }

                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

            }
        }
    }]);
