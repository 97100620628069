import { Asset, AssetBusinessTree, AssetBusinessUnit, AssetLeaf, AssetUser, GroupType } from "@api"
import dialog from "@common/components/digilean/DigileanDialog"
import * as customerService from "@common/services/customers/customerService"
import angular from "angular"
import type { DigiLeanNgScope } from "@common/model/angularModel"
import * as assetService from "@common/services/assetService"
import { reloadWithCollapsedState } from "./assetsHelper"
import { cloneDeep } from "lodash"
import { getUserProfile } from "@common/stores/userStore"

interface IRegistrationAsset extends AssetBusinessUnit {
    isNewAsset?: boolean
}

interface IAssetsController extends DigiLeanNgScope {
    registration: IRegistrationAsset
    headline: string
    message: string
    customer: string
    assetsTree: AssetBusinessTree[]
    
    newAsset: boolean
    usersWithNoAssets: AssetUser[]
    showUsersWithNoAssets: boolean
    isLoading: boolean
    animationsEnabled: boolean
    treeOptions: any

    openAssetParticipantsForEdit: (assetId: number) => void
    openAccessForEdit: (user: AssetUser) => void
    toogleUsersWithNoAssets: () => void
    assetChangeHandler: (assetinfo: any) => void
    createNewAsset: () =>  void
    delete: (asset: Asset) => void
    edit: (assetTree: AssetBusinessTree) => void
    save: () => void
} 

const DigiLean = angular.module('DigiLean')
DigiLean.controller('assetsController', ['$scope', '$uibModal','authService',
    function ($scope: IAssetsController, $uibModal, authService) {

        $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
        var businessUnitId = 0;

        let allAssets: AssetBusinessUnit[] = []
        $scope.message = ""
        $scope.customer = ""
        $scope.assetsTree = []
        $scope.newAsset = true
        $scope.usersWithNoAssets = []
        $scope.showUsersWithNoAssets = false
        $scope.isLoading = true

        // AssetType and BusinessUnit
        $scope.assetType = "GENERAL";
        $scope.setAssetType = function () {

        }

        $scope.createNewAsset = function () {
            resetRegistration()
        }

        $scope.toogleUsersWithNoAssets = function () {
            $scope.showUsersWithNoAssets = !$scope.showUsersWithNoAssets
        }
        
        $scope.onAssetsUpdated = function($event: JQuery.Event) {
            console.log("assetId updated:", $event.detail)
            saveAssetTree()
        }

        $scope.openAccessForEdit = function (user) {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: $scope.animationsEnabled,
                templateUrl: 'useraccessform.html',
                controller: 'userAccessController',
                resolve: {
                    user: function () {
                        return user
                    }
                }
            })

            modalInstance.result.then(function (user) {
                loadAssets()
            }, function () {})
        }

        function openProjectParticipants(id){

            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: $scope.animationsEnabled,
                templateUrl: 'projectParticipantModal.html',
                controller: 'projectParticipantModalController',
                windowClass: 'assetparticipants-modal-window',
                resolve: {
                    projectId: function () {
                        return id
                    }
                }
            })

            modalInstance.result.then(function (asset) {
                loadAssets()
            }, function () {

            })
        }

        $scope.treeOptions = {
            accept: function(sourceNodeScope, destNodesScope, destIndex) {

                const draggedAsset = sourceNodeScope.$modelValue as AssetBusinessTree

                const droppingToParent = destNodesScope.$parent.$modelValue as AssetBusinessTree

                if (droppingToParent && draggedAsset.item?.parentId !== droppingToParent.item?.id)
                    return false // prevent moving to other parent for now

                if (!droppingToParent && draggedAsset.item?.parentId)
                    return false

                return true
            },
            dropped: function(event) {

                const newIndex = event.dest.index as number

                const movedAsset = event.source?.nodeScope?.$modelValue as AssetBusinessTree
                if (movedAsset) {
                    console.log("movedAsset", movedAsset)

                    const assetOrderUpdate: AssetLeaf = {
                        id: movedAsset.item?.id,
                        parentId: movedAsset.item?.parentId,
                        sortOrder: newIndex
                    }

                    assetService.updateAssetOrder(assetOrderUpdate)
                }
            },
            removed: function(event) {
                console.log("removed")
            }
        }

        $scope.onClickAssetMembers = function (asset: AssetBusinessTree, $event) {
            $event.preventDefault()
            $event.stopPropagation()
            if(!asset || !asset.item)
                return
            if (asset.item.assetType == GroupType.GENERAL)
                return openAssetParticipantsForEdit(asset.item.id!)
            
            if (asset.item.assetType == GroupType.BUSINESSUNIT)
                dialog.openHtml({
                    title: "ADMIN_BUSINESSUNIT_MEMBERS",
                    cancelBtnText: "COMMON_CLOSE",
                    hideOkBtn: true,
                    okBtnText: "Ok"
                },
                `<business-unit-members id="${asset.item.businessUnitId}" name="${asset.item.name}"></business-unit-members>`)
        }

        function openAssetParticipantsForEdit (assetId: number) {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: $scope.animationsEnabled,
                templateUrl: 'assetparticipantsform.html',
                controller: 'assetParticipantsController',
                windowClass: 'assetparticipants-modal-window',
                resolve: {
                    assetId: function () {
                        return assetId
                    },
                    boardId: function() {
                        return null
                    }
                }
            })
            modalInstance.result.then(function (asset) {
                loadAssets()
            }, function () {})
        }
        
        $scope.edit = function (assetTree: AssetBusinessTree) {
            assetTree.isCollapsed = !assetTree.isCollapsed
            this.toggle() // treeview-special to display toggle instantly

            if (!assetTree.item)
                return

            const asset = assetTree.item
            if (asset.assetType == 'PROJECT') {
                openProjectParticipants(asset.projectId)
            } else {
                let assetCloneForEdit = cloneDeep(asset)
                $scope.registration = assetCloneForEdit
                $scope.registration.isNewAsset = false
            }
        }

        const resetRegistration = function () {
            $scope.registration = {
                isNewAsset: true,
                id: 0,
                name: "",
                description: "",
                parentId: null,
            }
            if($scope.isBusinessunitAdmin && $scope.assetsTree && $scope.assetsTree.length > 0){
                $scope.registration.parentId = $scope.assetsTree[0].item?.id // set businessunit asset as default parent
            }
        }

        const loadAssets = function () {
            $scope.usersWithNoAssets = []
            if($scope.isBusinessunitAdmin){
                assetService.getAssetBusinessTree(businessUnitId).then(updatedAssetTree => {
                    refreshAssetTree(updatedAssetTree)
                    $scope.isLoading = false
                    resetRegistration()
                })
            }
            else{
                customerService.getUsersWithOutAsset().then(function (userList) {
                    if(userList && userList.length>0){
                        $scope.usersWithNoAssets = userList
                    }
                })
                assetService.getAssetBusinessTree().then(updatedAssetTree => {
                    refreshAssetTree(updatedAssetTree)
                    $scope.isLoading = false
                })
            }        
        }

        function refreshAssetTree(updatedAssetTree: AssetBusinessTree[]) {
            if (!$scope.assetsTree || $scope.assetsTree.length == 0) {
                $scope.assetsTree = updatedAssetTree.map(a => {
                    a.isCollapsed = true
                    return a
                })
            }
            else {
                $scope.assetsTree = reloadWithCollapsedState(updatedAssetTree, $scope.assetsTree)
            }
        }
        async function saveAssetTree() {
            var updatedAssetTree;
            if($scope.isBusinessunitAdmin){
                $scope.isLoading = true
                updatedAssetTree = await assetService.saveAssetBusinessTree(businessUnitId)
                refreshAssetTree(updatedAssetTree) 
                resetRegistration()
            } else {
                updatedAssetTree = await assetService.saveAssetBusinessTree()
            }
            refreshAssetTree(updatedAssetTree) 
            $scope.isLoading = false
        }

        var init = async function () {
            if($scope.isBusinessunitAdmin){
                const currentUser = getUserProfile()
                businessUnitId = currentUser.businessUnitId || 0; // Set a default value of 0 if businessUnitId is undefined      
            }
            loadAssets()
            resetRegistration()
        }

        init()

    }])