import angular from "angular"
import { NgComponent } from "@common/model/angularModel"
import "./appHeaderTitle.css"

const digiLean = angular.module("DigiLean")

interface layoutStyle {
    "font-size": string
}

interface IndexPageScope extends NgComponent {
    layoutFactor: number
    isAdminMode: boolean
    title: string
    icon?: string
    iconShow?: string
    titleEdit: string
    titleStyle: layoutStyle
}

digiLean.component("appHeaderTitle", 
{
    transclude: true,
    bindings: {
        'layoutFactor': '<',
        'isAdminMode': '<',
        'title': '<'
    },
    templateUrl: "appHeaderTitle.html",
    controller: ['$element', function(this: IndexPageScope, $element: JQuery) {
        
        let $ctrl = this

        const defaultWrapperStyle = {
            "font-size": "1.6rem",
            "padding": "1rem 1rem 0 1rem"
        }
        $ctrl.wrapperStyle = defaultWrapperStyle

        $ctrl.titleEdit = ""
        
        function calcStyle() {
            if ($ctrl.layoutFactor) {

                const paddingStyle = $ctrl.layoutFactor + "rem"
                $ctrl.wrapperStyle = {
                    "font-size": 1.4 * $ctrl.layoutFactor + "rem",
                    "padding": `${paddingStyle} ${paddingStyle} 0 ${paddingStyle}`,
                }

            } else {
                $ctrl.wrapperStyle = defaultWrapperStyle
            }
        }

        $ctrl.$onInit = function() {
            calcStyle()
            if ($ctrl.title)
                $ctrl.titleEdit = $ctrl.title
        }
        $ctrl.$onChanges = function (changes) {
            if (changes.layoutFactor && changes.layoutFactor.currentValue)
                calcStyle()
            
            if (changes.title && changes.title.currentValue)
                $ctrl.titleEdit = changes.title.currentValue
        }
        $ctrl.titleUpdated = function() {
            const detail = $ctrl.titleEdit
            const options = {detail, bubbles: true, composed: true}
            $element[0].dispatchEvent(new CustomEvent("title-changed", options))
        }
        
    }]
})