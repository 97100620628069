/* Create attachments component for task */
angular
    .module('DigiLean')
    .directive("taskAttachments", ['$filter', '$uibModal','documentService',
        function ($filter, $uibModal, documentService) {
            return {
                templateUrl: 'taskattachments.html',
                restrict: 'E',
                scope: {
                    'files': '<',
                },
                link: function (scope, elem, attrs) {
                    var viewerFileExtensions = [
                        { type: 'image', extension: '.jpg' },
                        { type: 'image', extension: '.jpeg' },
                        { type: 'image', extension: '.png' },
                        { type: 'pdf', extension: '.pdf' },
                        { type: 'excel', extension: '.xlsx' },
                        { type: 'excel', extension: '.xls' },
                        { type: 'word', extension: '.doc' },
                        { type: 'word', extension: '.docx' },
                        { type: 'powerpoint', extension: '.ppt' },
                        { type: 'powerpoint', extension: '.pptx' },
                    ];

                    scope.filesToShow = [];
                    scope.$watch('files', function (files) {
                        // apply
                        
                        scope.filesToShow.splice(0, scope.filesToShow.length);
                        if (!files) return;
                        for (let i = 0; i < files.length; i++) {
                            var file = files[i];
                            var fileType = $filter('filter')(viewerFileExtensions, { extension: file.fileExtension.toLowerCase() });
                            if (fileType.length > 0) {
                                file.fileType = fileType[0].type;
                            }                            
                            // We are only going to show files that are not image, because image will be rendered as preview
                            if (fileType.length > 0 && fileType[0].type != "image") {
                                scope.filesToShow.push(file)
                            }
                        }
                   
                    });
                }
            }
        }]);
