import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("improvementActionList", ['$filter', '$translate', '$uibModal', 'suggestionService', 'actionlistService', 'taskDrawingService', 'boardTaskService',
        function ($filter, $translate, $uibModal, suggestionService, actionlistService, taskDrawingService, boardTaskService) {
            return {
                templateUrl: 'improvementActionList.html',
                restrict: 'E',
                scope: {
                    'suggestionid': '=',
                    'assetId': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.users = [];
                    scope.allusers = [];
                    scope.items = [];
                    scope.boards = [];
                    scope.canEdit = true;
                    scope.showMoreButton = true;

                    scope.boardoptions = {
                        mandatory: false
                    }

                    scope.$watch("suggestionid", function () {
                        if (!scope.suggestionid) return;

                        // Get actionlist
                        actionlistService().getActionListForImprovementSuggestion(scope.suggestionid).then(function (items) {
                            if (items.length !== 0) {
                                scope.items = items;
                                setResponsible();
                            }
                        });

                        boardService.getAllBoardNames().then(function (data) {
                            scope.boards = data;
                        });
                    });

                    scope.$watch("assetId", function () {
                        if (!scope.suggestionid) return;
                        suggestionService().getUserListForSuggestion(scope.suggestionid).then(function (users) {
                            scope.users = users;
                            if (scope.users && scope.users.length > 0) {
                                setResponsible();
                            } else {
                                getAllUsers();
                            }
                        });
                    });

                    function setBoardDate(item, date) {
                        if (date) {
                            item.boardDate = moment(date).toDate();
                        } else {
                            item.boardDate = null;
                        }
                    }

                

                    var profileImageUsers = [];
                    scope.loadMoreUsers = function () {
                        if (scope.allusers.length > 0) {
                            scope.users = scope.allusers;
                            scope.showMoreButton = false;
                        } else {
                            profileService.getUsersWithProfileImage().then(function (imageUsers) {
                                profileImageUsers = imageUsers;
                                customerService.getAllUsers().then(function (users) {
                                    scope.allusers = users;
                                    for (var i = 0; i < scope.allusers.length; i++) {
                                        var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(scope.allusers[i].userId, "ExtraSmall", profileImageUsers);
                                        if (imageUrl) {
                                            scope.allusers[i].profileImage = true;
                                            scope.allusers[i].profileImageUrl = imageUrl;
                                        } else {
                                            scope.allusers[i].profileImage = false;
                                        }
                                    }
                                    scope.users = scope.allusers;
                                    scope.showMoreButton = false;
                                });
                            });
                        }
                    }

                    function setResponsible() {
                        if (scope.items && scope.users) {
                            scope.items.forEach(function (item) {
                                item.user = $filter('filter')(scope.users, { id: item.responsibleUserId }, false)[0];
                                if (item.responsibleUserId && !item.user) {
                                    setResponsibleFromAllUsers(item);
                                }
                                setBoardDate(item, item.boardDate);
                                item.datePicker = {
                                    opened: false
                                }
                            });
                        }
                    }

                    function setResponsibleFromAllUsers(task) {
                        if (scope.allusers.length > 0) {
                            task.user = $filter('filter')(scope.allusers, { id: task.responsibleUserId }, false)[0];
                        } else {
                            profileService.getUsersWithProfileImage().then(function (imageUsers) {
                                profileImageUsers = imageUsers;
                                customerService.getAllUsers().then(function (users) {
                                    scope.allusers = users;
                                    for (var i = 0; i < scope.allusers.length; i++) {
                                        var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(scope.allusers[i].userId, "ExtraSmall", profileImageUsers);
                                        if (imageUrl) {
                                            scope.allusers[i].profileImage = true;
                                            scope.allusers[i].profileImageUrl = imageUrl;
                                        } else {
                                            scope.allusers[i].profileImage = false;
                                        }
                                    }
                                    task.user = $filter('filter')(scope.allusers, { id: task.responsibleUserId }, false)[0];
                                });
                            });
                        }
                    }

                    function getAllUsers() {
                        if (scope.allusers.length > 0) {
                            return scope.allusers;
                        } else {
                            profileService.getUsersWithProfileImage().then(function (imageUsers) {
                                profileImageUsers = imageUsers;
                                customerService.getAllUsers().then(function (users) {
                                    scope.allusers = users;
                                    for (var i = 0; i < scope.allusers.length; i++) {
                                        var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(scope.allusers[i].userId, "ExtraSmall", profileImageUsers);
                                        if (imageUrl) {
                                            scope.allusers[i].profileImage = true;
                                            scope.allusers[i].profileImageUrl = imageUrl;
                                        } else {
                                            scope.allusers[i].profileImage = false;
                                        }
                                    }
                                    return scope.allusers;
                                });
                            });
                        }
                    }

                    scope.setActive = function (task) {
                        task.active = true;
                        setTimeout(function () {
                            $(elem).find(".project-name").focus();
                        }, 50);
                    }

                    scope.add = function () {
                        var item = {
                            title: "",
                            boardDate: moment().utc().toDate(),
                            user: null
                        }
                        actionlistService().createImprovementActionItem(scope.suggestionid, item).then(function (actionItem) {
                            if (actionItem.boardDate) {
                                actionItem.boardDate = moment(actionItem.boardDate).toDate();
                            }
                            actionItem.datePicker = {
                                opened: false
                            }
                            scope.items.unshift(actionItem);
                            scope.setActive(actionItem);
                            scope.publish("SendCreateActionListItem", actionItem);
                        });
                    }

                    // Datepicker setup
                    scope.formats = ['dd.MM.yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'shortDate']
                    scope.format = scope.formats[0];
                    scope.altInputFormats = ['M!/d!/yyyy'];
                    scope.opened = false;
                    scope.datepickerOpen = function (task) {
                        task.datePicker.opened = true;
                    }
                    scope.dateOptions = timeService.globalDatePickerOptions();


                    scope.delete = function (item) {
                        actionlistService().deleteActionItem(item.id).then(function () {
                        });
                    };

                    scope.saveItemDescription = function (task) {
                        task.active = false;
                        var taskDescription = {
                            taskId: task.id,
                            description: task.title
                        };
                        actionlistService().updateItemDescription(task.id, taskDescription).then(function () {
                            $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                toastr.success(msg);
                            });
                            scope.publish("SendTaskDescriptionUpdated", taskDescription);
                        });
                    }

                    scope.userChangeHandler = function (userinfo) {
                        if (userinfo) {
                            var user = userinfo.user;
                            var item = userinfo.model;
                            item.user = user;
                            // 
                            actionlistService().assignActionListItemUser(item.id, user).then(function () {
                            });
                        }
                    }

                    scope.openTask = function (activeTab, subtask) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            canChangeBoard: false
                        };

                        boardTaskService().get(subtask.id).then(function (task) {
                            $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        return options;
                                    }
                                }
                            });
                        })

                    }

                    scope.changeActionDate = function (item, event) {
                        const date = event.originalEvent.detail
                        item.boardDate = date
                        var actionDate = {
                            taskId: item.id,
                            actionDate: item.boardDate
                        };
                        if (item.boardId) {
                            boardTaskService().updateDate(item.id, item.boardDate).then(function (taskMoved) {
                                //Subtask will not be updated if any...
                                $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                    toastr.success(msg);
                                });
                                // publish event on change
                                scope.publish("SendTaskDateUpdated", actionDate);
                            });
                        } else {
                            actionlistService().updateItemActionDate(actionDate).then(function () {
                                $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                    toastr.success(msg);
                                });
                                // publish event on change
                                scope.publish("SendTaskDateUpdated", actionDate);
                            });
                        }
                    }

                    scope.updateResponsible = function (user, item) {
                        item.user = user;
                        actionlistService().assignActionListItemUser(item.id, user).then(function () {
                        });
                    }


                    scope.changeStatus = function (item) {
                        if (!scope.canEdit) return;
                        item.status = taskDrawingService().getNextStatus(item.status).status;
                        var status = {
                            taskId: item.id,
                            status: item.status
                        };
                        actionlistService().updateStatus(status).then(function () {

                        });
                    }

                    scope.clearSelectedUser = function (task) {
                        var emptyUser = {
                            userId: null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.selectUser(task, emptyUser);

                    };
                    scope.selectUser = function (task, user) {
                        task.user = user;
                        task.ResponsibleUserId
                        var userDetails = {
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName,
                            previousUserId: task.responsibleUserId,
                            previousUserName: task.responsibleUser
                        };
                        // save
                        var options = {
                            taskId: task.id,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);
                        task.responsibleUserId = user.userId;
                        task.responsibleDisplayName = user.displayName;
                        task.responsibleUser = user.fullName;
                        //mapResponsibleToColumn(task);
                        //mapResponsibleToRow(task);
                    };

                    scope.autoGrow = function (element) {
                        element.style.height = "5px";
                        element.style.height = (element.scrollHeight) + "px";
                    }

                    setTimeout(function () {
                        // Applied globally on all textareas with the "autoExpand" class
                        $(document)
                            .one('focus.autoExpand', 'textarea.autoExpand', function () {
                                var savedValue = this.value;
                                this.value = '';
                                this.baseScrollHeight = this.scrollHeight;
                                this.value = savedValue;
                            })
                            .on('input.autoExpand', 'textarea.autoExpand', function () {
                                var minRows = this.getAttribute('data-min-rows') | 0, rows;
                                this.rows = minRows;
                                rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 17);
                                this.rows = minRows + rows;
                            });
                    }, 1500);
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        var task = $filter('filter')(scope.items, { id: status.taskId });
                        if (task.length > 0) {
                            task[0].status = status.status;
                        }
                    });
                    scope.subscribe('TaskDeleted', function (id) {
                        var task = $filter('filter')(scope.items, { id: id });
                        if (task.length > 0) {
                            var item = task[0];
                            var index = scope.items.indexOf(item);
                            if (index > -1) {
                                item.cssState = "animated slideOutLeft"; // Add animation
                                setTimeout(function () {
                                    scope.items.splice(index, 1);
                                }, 300);
                            }
                        }
                    });
                    scope.subscribe('TaskCommentAdded', function (comment) {
                        var task = $filter('filter')(scope.items, { id: comment.boardTaskId });
                        if (task.length > 0) {
                            task[0].commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe('TaskUpdated', function (updatedTask) {
                        var task = $filter('filter')(scope.items, { id: updatedTask.id });
                        if (task.length > 0) {
                            task[0].status = updatedTask.status;
                            task[0].title = updatedTask.title;
                            task[0].text = updatedTask.text;
                            task[0].description = updatedTask.description;
                            task[0].attachmentCount = updatedTask.attachmentCount;
                            task[0].commentCount = updatedTask.commentCount;
                            setBoardDate(task[0], updatedTask.boardDate);
                            task[0].responsibleUserId = updatedTask.responsibleUserId;
                            setResponsible();
                        }
                    });
                }
            }
        }]);
