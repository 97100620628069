angular
    .module('DigiLean')
    .directive("a3TemplatePreview", ['$window', 'a3templateService', 'a3Service',
        function ($window, a3templateService, a3Service) {
            return {
                templateUrl: 'a3TemplatePreview.html',
                restrict: 'E',
                scope: {
                    'id': '<',
                    'zoom': '<?',
                    'public': '<',
                },
                link: function (scope, elem, attrs) {

                    scope.a3templateId = scope.id;
                    scope.a3info = null;
                    scope.versionNumber = 1;
                    scope.canEdit = false;
                    scope.width = 2338;
                    scope.height = 1654;
                    // scope.zoom = 0.1;
                    scope.version = {};
                    scope.defaultLayout = null;
                    scope.widgets = [];

                    function getTemplate() {
                        if (scope.public)
                            return a3templateService().getPublicTemplate(scope.a3templateId)
                        return a3templateService().get(scope.a3templateId)
                    }
                    function loadTemplate() {
                        getTemplate().then(function (template) {
                            scope.template = template;
                            scope.widgets = [];
                            var withoutProjectInfo = scope.template.steps.filter(s => !s.isProjectInfo);
                            var layout = scope.defaultLayout[withoutProjectInfo.length - 1];

                            scope.a3info = getStepWidget("a3info-template", template, [0, 0, 24, 0]);
                            var info = {
                                component: 'a3info-template',
                                name: 'A3 Info',
                                model: template.id,
                                sizeY: 0
                            };

                            var widgets = [];
                            widgets.push(info);
                            var stepIndex = 0;
                            scope.template.steps.forEach(step => {
                                var widget = {
                                    component: "a3step-template",
                                    model: {
                                        id: step.id,
                                        style: step.style,
                                        name: step.name,
                                        content: step.defaultContent
                                    },
                                    row: step.row != null ? step.row : layout[stepIndex][0],
                                    col: step.col != null ? step.col : layout[stepIndex][1],
                                    sizeX: step.sizeX != null ? step.sizeX : layout[stepIndex][2],
                                    sizeY: step.sizeY != null ? step.sizeY : layout[stepIndex][3]
                                };

                                if (step.isProjectInfo) {
                                    widget.component = "cost-widget";
                                }
                                else {
                                    stepIndex++
                                }

                                widgets.push(widget);



                            });
                            scope.widgets = widgets;
                        });
                    }

                    scope.$watch("zoom", function(zoom) {
                        if (!scope.zoom) return; 
                    });
                    a3Service().getDefaultLayout().then(function (result) {
                        scope.defaultLayout = result;
                            loadTemplate();
                    });


                    var w = angular.element($window);
                    adjustZoom(w[0].innerWidth);
                    w.bind('resize', function (event) {
                        adjustZoom(event.currentTarget.window.innerWidth)
                    });

                    function adjustZoom(windowWidth) {

                        // scope.zoom = windowWidth / (scope.width + 2000);
                    }



                    scope.gridsterOptions = {
                        margins: [25, 25],
                        columns: 24,
                        sparse: true,
                        resizable: {
                            enabled: false,
                        },
                        draggable: {
                            enabled: false
                        }
                    };


                    function getStepWidget(component, model, position) {
                        model.versionNumber = scope.versionNumber;
                        var widget = {
                            model: model,
                            header: model.name,
                            component: component,
                            row: position[0],
                            col: position[1],
                            sizeX: position[2],
                            sizeY: position[3],
                            a3StepId: model.id
                        }
                        if (component === "a3info-template" || component === "cost-widget") { // a3info and cost is not linked to step so we need to set it to null
                            widget.a3StepId = null;
                        }
                        return widget;
                    }






                }
            }
        }]);

