import type { ApplicationUser, UserModel } from "@api"
import backend from "@common/services/backendHttp"

let resourceUrl = "api/user"

export const createNewUser = async (user: UserModel) => {
    const res = await backend.post<ApplicationUser>(resourceUrl, user)
    return res
}

export const updateUser = async (userId: string, user: UserModel) => {
    const res = await backend.put<ApplicationUser>(`${resourceUrl}/${userId}`, user)
    return res
}

export const deleteUser = async (userId: string) => {
    const res = await backend.delete(`${resourceUrl}/${userId}`)
    return res
}