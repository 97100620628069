﻿import CountUp from '@common/thirdparty/countUp/countUp.min'
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("usersOnline", ['$filter', 'authService',
        function ($filter, authService) {
            return {
                templateUrl: 'usersonline.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },

                link: function ($scope, elem, attrs) {
                    $scope.title = 'Users online';
                    // This is only for Kaizen so return if not in role.
                    if (!authService.hasRole("KaizenAdmin")) return;
                    $scope.customerEvents = [];
                    $scope.customersOnline = [];
                    $scope.customers = [];
                    $scope.totalUsersOnline = 0;
                    $scope.showClients = function (customer) {
                        customer.showClients = !customer.showClients;
                    }

                    customerService.getCustomersSimpleForAdmin().then(function (customers) {
                        angular.forEach(customers, function (customer, key) {
                            var currentCustomer = {
                                customerId: customer.id,
                                customer: customer,
                                clients: [],
                                cssState: ""
                            }
                            $scope.customers.push(currentCustomer);
                        });
                        $scope.publish("GetAllUsersOnline");
                    });

                    var hasLoadedAllUsers = false;
                    $scope.subscribe("CustomerUsersConnectedChanged", function (customer) {
                        if (!hasLoadedAllUsers) {
                            $scope.publish("GetAllUsersOnline");
                            hasLoadedAllUsers = true;
                        }

                        updateCustomerOnlineCount(customer);


                        $scope.$apply();
                    });

                    $scope.translationData = {
                        totalUsersOnline: $scope.totalUsersOnline
                    };

                    $scope.subscribe("AllUsersOnline", function (usersOnline) {
                        angular.forEach(usersOnline, function (companyOnline, key) {
                            updateCustomerOnlineCount(companyOnline);
                        });
                        $scope.$apply();

                    });

                    function updateCustomerOnlineCount(customerOnline) {

                        var customer = $filter('filter')($scope.customers, { customerId: customerOnline.customerId }, true);
                        if (customer.length > 0) {
                            customer[0].clients = customerOnline.clients;
                            customer[0].cssState = "animated bounce";
                        }
                        $scope.customersOnline = $filter('filter')($scope.customers, function (customer) {
                            return customer.clients.length > 0;
                        });
                        updateTotalUsersOnline();

                    }

                    function updateTotalUsersOnline() {
                        var count = 0;
                        angular.forEach($scope.customers, function (customer, key) {
                            count += customer.clients.length;
                        });
                        var previousCount = $scope.totalUsersOnline;
                        var options = {
                            useEasing: true,
                            useGrouping: true,
                            separator: ',',
                            decimal: '.',
                            prefix: '',
                            suffix: ''
                        };
                        var numAnim = new CountUp("onlineCount", 0, count, 0, 3, options);
                        numAnim.start();
                        $scope.totalUsersOnline = count;
                        $scope.translationData.totalUsersOnline = count;
                    }

                    function resizeChart() {
                        var host = $(elem).closest(".widget-content");
                        var width = host.width() -40;
                        var height = host.height();
                        if (height > 200) height = 300;
                        var chartContainer = $(elem).find(".highcharts-container").parent();
                        if (chartContainer.length === 0) return;
                        var chart = $(chartContainer).highcharts();
                        chart.setSize(width, height, true);
                    }
                    $scope.$on("widget-resized", function (event, args) {
                        resizeChart();
                    });

                    let intervalId = 0
                    var chart;
                    function loadChart() {
                        chart = $(elem).find(".usersonline").highcharts({
                            chart: {
                                defaultSeriesType: 'spline',
                                height: 300
                            },
                            title: {
                                text: ''
                            },
                            credits: {
                                enabled: false //remove link to highcharts.com
                            },
                            exporting: {
                                enabled: false //remove context menu
                            },
                            xAxis: {
                                type: 'datetime',
                                tickPixelInterval: 150,
                                maxZoom: 20 * 1000
                            },
                            yAxis: {
                                minPadding: 0.2,
                                maxPadding: 0.2,
                                title: {
                                    text: ''
                                }
                            },
                            series: [{
                                name: 'Users online',
                                data: []
                            }]
                        });

                        // Timer that updates the chart
                        intervalId = setInterval(function () {
                            var currentChart = $(elem).find(".usersonline").highcharts();
                            var series = currentChart.series[0],
                               shift = series.data.length > 1000; // shift if the series is 
                            // longer than 20
                            var point = [new Date().getTime(), $scope.totalUsersOnline];
                            // add the point
                            currentChart.series[0].addPoint(point, true, shift);
                        }, 5000);
                    };

                    loadChart();
                    $scope.$on("$destroy", () => {
                        clearInterval(intervalId)
                    })
                }
            }
        }]);
