import { cloneDeep } from "lodash"
export function translateOldImprovementSettings(settings, currentUserId, dueDate) {

    if (!settings)
        return {}
    
    

    // old filter had not the filter dimention
    if (Array.isArray(settings)){
        let converted = {
            filter: []
        };
        converted.filter = cloneDeep(settings);
        return converted
    } else if(!settings.filter) {
        let converted = {
            filter: []
        };

        // asset -> assetId
        if (settings.asset && Array.isArray(settings.asset)) {
            const itemFilter = generateItemFilter("assetId", settings.asset)
            converted.filter.push(itemFilter)
        }
        // category -> categoryId
        if (settings.category && Array.isArray(settings.category)) {
            const itemFilter = generateItemFilter("categoryId", settings.category)
            converted.filter.push(itemFilter)
        }

        // project -> projectId
        if (settings.project && Array.isArray(settings.project)) {
            const itemFilter = generateItemFilter("projectId", settings.project)
            converted.filter.push(itemFilter)
        }
        // priority -> priority
        if (settings.priority && Array.isArray(settings.priority)) {
            const itemFilter = generateItemFilter("priority", settings.priority)
            converted.filter.push(itemFilter)
        }
        // status -> status
        if (settings.status && Array.isArray(settings.status)) {
            const itemFilter = generateItemFilter("status", settings.status)
            converted.filter.push(itemFilter)
        }
        // mySuggestion -> suggestedByUserId
        if (settings.myRegistration) {
            const itemFilter = generateItemFilter("suggestedByUserId", [currentUserId])
            converted.filter.push(itemFilter)
        }
        //myResponsible -> mySuggestionFilterActive
        if (settings.myResponsible) {
            const itemFilter = generateItemFilter("responsibleUserId", [currentUserId])
            converted.filter.push(itemFilter)
        }

        // overdueFilterActive
        if (settings.overdueFilterActive) {
            const dueDateFilter = {
                sourceColumn: "dueDate",
                operator: "LessThanDate",
                items: [dueDate]
            }
            converted.filter.push(dueDateFilter)
            const statusImplementedFilter = {
                sourceColumn: "statusImplementedDate",
                operator: "IsNull",
                items: []
            }
            converted.filter.push(statusImplementedFilter)
        }
        return converted;

        // timeframe 
        /*if (settings.filter.timeframe) {
            converted.timePeriod = {
                timeframe: settings.filter.timeframe,
                period: settings.filter.timePeriod
            }
        }*/
    } else {
        return settings //cloneDeep(settings)
    }
    
}

function generateItemFilter(sourceColumn, items) {
    if (!items || !Array.isArray(items) || items.length === 0)
        return null
    
    let itemFilter = {
        sourceColumn: sourceColumn,
        operator: "InList",
        items: []
    }

    itemFilter.items = items
    return itemFilter
}