import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("projectEndDateCell", ['projectService',
        function (projectService) {
            return {
                templateUrl: 'projectEndDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.dateMissed = false;
                    scope.date = null;
                    scope.value = null;

                    scope.changeColor = false,
                    scope.changeColorXDaysBefore = 1;

                    scope.updateEndDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.date.endDate = date
                        projectService().updateEndDate(scope.model.project.id,scope.date.endDate).then(function (savedDate) { 
                            checkDate();
                        });
                    }

                    scope.updateEstimatedEndDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.date.estimatedEndDate = date
                        projectService().updateEstimatedEndDate(scope.model.project.id,scope.date.estimatedEndDate).then(function (savedDate) { 
                            checkDate()
                        })
                    }
                    

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {

                            var settings = model.column.settings;
                            if (settings) {
                                if(settings.changeColor && settings.changeColorXDaysBefore){
                                    scope.changeColor = true;
                                    scope.changeColorXDaysBefore = settings.changeColorXDaysBefore;
                                }
                            }
                            
                            projectService().getProjectInfo(scope.model.project.id).then(function (date) { 
                                
                                if(date){
                                    scope.date = date;
                                    if(scope.date.estimatedEndDate){
                                        scope.date.estimatedEndDate = moment(date.estimatedEndDate).toDate();
                                    }
                                    if(scope.date.endDate){
                                        scope.date.endDate = moment(date.endDate).toDate();
                                    }
                                    
                                    scope.date.datePicker = {
                                        opened: false
                                    },
                                    scope.date.estimatedDatePicker = {
                                        opened: false
                                    }
                                    checkDate();
                                } else {
                                    createTempDate();
                                }
                            });
                        } else {
                            createTempDate();
                        }
                        if(scope.model){
                            model.getSortValue = function() {
                                if (scope.date) {
                                    if(scope.date.endDate){
                                        return scope.date.endDate;
                                    } else {
                                        return scope.date.estimatedEndDate;
                                    }        
                                } else {
                                    if(scope.model.project && scope.model.project.endDate){
                                        return moment(scope.model.project.endDate).toDate();
                                    } else if (scope.model.project && scope.model.project.estimatedEndDate){
                                        return moment(scope.model.project.estimatedEndDate).toDate();
                                    } else {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        }
                    });

                    scope.subscribe("ProjectDateColorCellUpdated", function (value) {
                        if(scope.model.boardAttributeId === value.projectAttributeId){
                            scope.changeColor = value.changeColor;
                            scope.changeColorXDaysBefore = value.changeColorXDaysBefore;
                        }
                    });

                    scope.subscribe("ProjectEndDateUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            scope.date.endDate = moment(project.endDate).toDate();
                            checkDate();
                        }
                    });
                    scope.subscribe("ProjectEstimatedEndDateUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            scope.date.estimatedEndDate = moment(project.estimatedEndDate).toDate();
                            checkDate();
                        }
                    });

                    function createTempDate(){
                        var projectid = null;
                        if(scope.model && scope.model.project){
                            projectid = scope.model.project.id;
                        }
                        scope.date = {
                            id: projectid,
                            endDate: null,
                            estimatedEndDate: null,
                            datePicker : {
                                opened: false
                            },
                            estimatedDatePicker : {
                                opened: false
                            }
                        };
                    }

                    function checkDate() {
                        scope.date.status = "";
                        if (scope.date) {
                            scope.date.endDate = timeService.validateDate(scope.date.endDate);
                            scope.date.estimatedEndDate = timeService.validateDate(scope.date.estimatedEndDate);
                            scope.dateMissed = false;
                            
                            if (scope.date.endDate) {
                                scope.date.status = "completed";
                                var diff =  moment.duration(moment(scope.date.endDate).diff(moment(scope.date.estimatedEndDate)));
                                var days = diff.asDays();
                                if(!isNaN(days)){
                                    scope.date.diffPlannedVSActual = Math.round(days);
                                }else {
                                    scope.date.diffPlannedVSActual = null;
                                }
                            } else if (scope.date.estimatedEndDate) {
                                //check if overdue/missed
                 
                                var duration = moment.duration(moment().endOf('day').diff(moment(scope.date.estimatedEndDate).endOf('day')));
                                var days = duration.asDays();
                                if (days > 1) {
                                    scope.date.status = "missed";
                                    scope.date.diffToPlannedDate = Math.trunc(days);
                                    scope.dateMissed = true;
                                } else {
                                    scope.date.status = "future";
                                    scope.date.diffToPlannedDate = Math.round(days)*-1;
                                }
                            }
                        }
                    }
                }
            }
        }]);
