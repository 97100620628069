angular
    .module('DigiLean')
    .controller("projectParticipantModalController", ['$scope', '$uibModalInstance', 'projectId',
        function ($scope, $uibModalInstance, projectId) {

            $scope.projectId = projectId;
  
            $scope.close = function () {
                //$uibModalInstance.dismiss('cancel');
                $uibModalInstance.close();
            };

        }]);
