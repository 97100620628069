var DigiLean = angular.module('DigiLean');
DigiLean.controller('excelMultiImportController', ['$scope', '$uibModal', 'dataSourceService', 'dataService', 'projectService',
    function ($scope, $uibModal, dataSourceService, dataService, projectService) {

        $scope.existingDataSources = [];
        $scope.dataSource = {};
        $scope.dataSourceDescription = null;
        $scope.allDataSources = null;
        $scope.usedColumns = null;
        $scope.uploadedFile = null;
        $scope.allProjects = null;
        $scope.missingProjects = [];
        $scope.commonProperties = [];
        $scope.sources = [];
        var dimensionCount = 1;

        $scope.fileParsed = function (data) {
            $scope.data = null;
            $scope.existingDataSources = [];
            $scope.data = data;
            setDefaults();
            loadDataSources();
            loadProjects();

            // localStorage["exceldata"] = JSON.stringify($scope.data);
        };


        $scope.options = [
            { value: "valueDate", display: "COMMON_DATE" },
            { value: "value", display: "COMMON_VALUE" },
            { value: "assetId", display: "COMMON_ASSET" },
            { value: "areaId", display: "COMMON_AREA" },
            { value: "projectId", display: "COMMON_PROJECT" },
            { value: "dimension", display: "COMMON_DIMENSION" },
            { value: "dimension2", display: "COMMON_DIMENSION2" },
            { value: "dimension3", display: "COMMON_DIMENSION3" },
            { value: "dimension4", display: "COMMON_DIMENSION4" },
            // { value: "newDataSource", display: "ADMIN_DATA_ADD_DATASOURCE" }
        ];

        $scope.getOptions = function (selectedRef) {
            $scope.options.forEach(element => {
                if (element.value == selectedRef)
                    element.inUse = false;
            });
            return $scope.options;
        }

        $scope.findExisting = function () {
            $scope.existingDataSources = [];
            $scope.sources.forEach(s => {
                var datasource = getDataSource(s);

                dataSourceService().match(datasource.title).then(function (result) {
                    $scope.existingDataSources.push(result);
                    readValues();
                });
            });

        }

        function getDataSource(dataSource) {

            return {
                title: dataSource.name,
                description: dataSource.description,
                objectSource: "external",
                unitOfTime: "MONTH",
                assetId: getRefId("assetId"),
                areaId: getRefId("areaId"),
                projectId: getRefId("projectId"),
                dateElement: getDate(),
                valueElement: getValue(dataSource),
                elements: getElements()
            };
        }

        $scope.drop = function (el) {
            console.log(el);
        };

        var loadProjects = function () {
            projectService().getList().then(function (result) {
                $scope.allProjects = result;
            });
        }

        var loadDataSources = function () {
            dataSourceService().getList().then(function (result) {
                $scope.allDataSources = result;
            });
        }

        $scope.checkIfAlreadyExists = function (title) {

            if (!$scope.allDataSources)
                return false;
            let found = $scope.allDataSources.filter(x => x.title == title);
            return found.length > 0;

        };

        $scope.canCreateNewDataSource = function () {

            let allExists = true;

            $scope.sources.forEach(d => {
                if (!$scope.checkIfAlreadyExists(d.name))
                    allExists = false;
            });
            return allExists;
        }

        $scope.createDataSources = function () {

            $scope.existingDataSources = [];
            findMissingProjects();
            projectService().addProjects($scope.missingProjects).then(function (result) {  // first add any missing projects
                let newProjects = result;
                newProjects.forEach(p => {
                    $scope.allProjects.push(p);
                });



                $scope.sources.forEach(s => {

                    if (s && !$scope.checkIfAlreadyExists(s.name)) {
                        var datasource = getDataSource(s);
                        dataSourceService().add(datasource).then(function (result) {
                            $scope.existingDataSources.push(result);
                            $scope.writeValuesToDataSource(s);
                            loadDataSources();
                        });
                    }
                });
            });
        };


        $scope.writeValuesToDataSource = function (dataSource) {

            var values = [];

            $scope.data.series[0].rows.forEach(row => {
                var value = {}
                $scope.data.series[0].ref.forEach((ref, index) => {
                    if (ref != null && $scope.data.series[0].include[index]) {  //has value and is selected
                        var readValue = row.values[index];
                        value[ref] = ref == "valueDate" ? convertTimestamp(readValue) :
                            ref == "projectId" ? getProjectId(readValue) : readValue;
                    }
                });
                $scope.data.series[0].sources.forEach((s, index) => {  //actual value of datasource
                    if (s && s.name == dataSource.name) {
                        value.value = row.values[index];
                    }
                });
                values.push(value);
            });
            let existingDataSource = $scope.existingDataSources.filter(e => e.title === dataSource.name)[0];
            if (existingDataSource) {
                dataService().addList(existingDataSource.id, values).then(function (result) {
                    // $scope.findExisting();
                    readValues();
                });
            }
        }

        function getProjectId(projectNumber) {
            let projects = $scope.allProjects.filter(p => p.projectNumber && p.projectNumber.toLowerCase() == projectNumber.toString().toLowerCase());
            if (projects && projects.length > 0)
                return projects[0].id;
            return null;
        }

        function findMissingProjects() {
            var indexData = $scope.data.series[0].index;
            var projectIndex = indexData.ref.indexOf("projectId");
            $scope.missingProjects = [];

            if (projectIndex > -1) {
                $scope.data.series[0].rows.forEach(r => {
                    var projectNumber = r.values[projectIndex].toString().toLowerCase();
                    if ($scope.allProjects.filter(p => p.projectNumber && p.projectNumber.toLowerCase() == projectNumber).length == 0) {
                        if ($scope.missingProjects.filter(m => m.projectNumber == projectNumber).length == 0) { //check if already added 
                            $scope.missingProjects.push({ name: projectNumber, projectNumber: projectNumber });
                        }
                    }
                });

                console.log("missing:")
                console.log($scope.missingProjects);
            }
        }


        function convertTimestamp(value) {

            var timeStamp = moment(value, $scope.dataSource.timeStampFormat);
            return timeStamp.format();
        }

        function determineTimeFormat(nameIndex) {
            let dates = $scope.data.series[0].rows.map(r => r.values[nameIndex]).filter(d => d);
            $scope.dataSource.timeStampFormat = 'YYYY.MM.DD HH.mm.ss';

            let dateSeparator = '.';
            let timeSeparator = '.';

            let firstPart = [];
            let secondPart = [];
            let thirdPart = [];

            dates.forEach(d => {
                if (d.includes('/'))
                    dateSeparator = '/';
                if (d.includes('-'))
                    dateSeparator = '-';

                let dateStrings = d.substring(0, 10).split(dateSeparator);
                firstPart.push(dateStrings[0].trim());
                secondPart.push(dateStrings[1].trim());
                thirdPart.push(dateStrings[2].trim());

                if (d.includes(':'))
                    timeSeparator = ':';

            });

            let firstVals = firstPart.map(f => +f);
            let firstMax = Math.max(...firstVals);

            let secondVals = secondPart.map(s => +s);
            let secondMax = Math.max(...secondVals);

            let thirdVals = thirdPart.map(t => +t);
            let thirdMax = Math.max(...thirdVals);

            let maxValues = [{ part: "first", value: firstMax }, { part: "second", value: secondMax }, { part: "third", value: thirdMax }];
            let maxValuesSorted = maxValues.sort((a, b) => {
                return a.value - b.value;
            });

            let format = {};
            let components = ["MM", "DD", "YYYY"];
            maxValuesSorted.forEach((v, index) => {
                format[v.part] = components[index];
            });

            $scope.dataSource.timeStampFormat = format.first + dateSeparator + format.second + dateSeparator + format.third + " HH" + timeSeparator + "mm" + timeSeparator + "ss";

        }

        $scope.convertToLocal = function (utcDateString) {
            return moment(utcDateString).format($scope.dataSource.timeStampFormat);
        }

        $scope.init = function (nameIndex) {
            let fullName = $scope.data.series[0].name[nameIndex];
            let nameWithUnit = fullName.split('[')
            let unit = nameWithUnit.length == 2 ? nameWithUnit[1].replace(']', '').trim() : null;
            let sourceName = nameWithUnit[0].trim();
            let name = sourceName.toLowerCase();
            let key = null;
            if (name.includes("date")
                || name.includes("dato")
                || name.includes("timestamp")
                || name.includes("tid")
            ) {
                key = "valueDate";
                determineTimeFormat(nameIndex);
            }
            else if (name.includes("name")
                || name.includes("navn")
                || name.includes("person")
                || name.includes("who")) {
                key = "dimension";
                dimensionCount++;
            }
            else if (name.includes("area")
                || name.includes("område")
                || name.includes("avdeling")
                || name.includes("department"))
                key = "areaId";
            else if (name.length <= "prosjektnummer".length && (name.includes("project")
                || name.includes("prosjekt")
                || name.includes("product")
                || name.includes("produkt")))
                key = "projectId";

            else if (unit) {
                $scope.data.series[0].sources[nameIndex] = { name: sourceName, description: "", unit: unit }
            }
            else if (dimensionCount <= 4) {
                key = "dimension";
                key += dimensionCount > 1 ? dimensionCount : "";
                dimensionCount++;
            }

            var option = $scope.options.filter(x => x.value == key)[0];
            if (option)
                $scope.data.series[0].ref[nameIndex] = option.value;
        }

        function readValues() {
            $scope.usedColumns = {};
            var options = {
                numberOfValues: 500,
                sortOrder: "ASC"
            };
            $scope.existingDataSources.forEach(e => {
                dataService().getLatestValues(e.id, options).then(function (result) {

                    result.forEach(record => {
                        if (record.assetId)
                            $scope.usedColumns['assetId'] = true;
                        if (record.areaId)
                            $scope.usedColumns['areaId'] = true;
                        if (record.projectId)
                            $scope.usedColumns['projectId'] = true;
                        if (record.dimension)
                            $scope.usedColumns['dimension'] = true;
                        if (record.dimension2)
                            $scope.usedColumns['dimension2'] = true;
                        if (record.dimension3)
                            $scope.usedColumns['dimension3'] = true;
                        if (record.dimension4)
                            $scope.usedColumns['dimension4'] = true;
                    }
                    );

                    e.existingValues = result;
                    setProjectNumbers();

                });
            });
        }

        function setProjectNumbers() {
            $scope.existingDataSources.forEach(s => {
                if (s.existingValues)
                    s.existingValues.forEach(v => {
                        let project = $scope.allProjects.filter(p => p.id == v.projectId)[0];
                        if (project) {
                            v.projectNumber = project.projectNumber;
                        }
                    });
            });
        }

        $scope.selectedHeader = function (header) {
            if ($scope.excelData)
                localStorage["exceldata"] = $scope.excelData;

        }

        $scope.selectedRef = function (index) {
            if ($scope.excelData)
                localStorage["exceldata"] = $scope.excelData;
            var ref = $scope.data.series[0].ref[index];
            var refObject = $scope.options.filter(x => x.value == ref)[0];
            if (refObject)
                refObject.inUse = refObject.inUse != true ? true : false;

            // if (refObject.value == "newDataSource"){
            //     $scope.data.series[0].sources[index] = $scope.data.series[0].name[index];
            //     $scope.data.series[0].ref[index] = refObject.value;
            //     setDefaults();

            // }
        }

        function getRefId(ref) {
            var indexData = $scope.data.series[0].index;
            var refIndex = indexData.ref.indexOf(ref);
            if (refIndex > -1 && indexData.include[refIndex])
                return indexData.name[refIndex];
            return null;
        }

        function getDate() {
            return {
                label: "date",
                type: "DAY",
                isMandatory: true
            }
        }

        function getValue(dataSource) {
            return {
                label: "value",
                type: "number",
                unit: dataSource.unit,
                isMandatory: true
            }
        }

        function setDefaults() {
            dimensionCount = 1;
            $scope.dataSource.name = $scope.data.series[0].name.toString();
            $scope.dataSource.description = "";

            $scope.data.series[0].name.forEach((element, index) => {
                $scope.init(index);
            });

            $scope.sources = $scope.data.series[0].sources.filter(x => x);
            let sourceNames = $scope.sources.map(s => { return s.name; });
            $scope.sources.forEach(s => s.exists = $scope.checkIfAlreadyExists(s.name));
            $scope.commonProperties = $scope.data.series[0].name.filter(n => {
                if (n.includes('['))
                    n = n.split('[')[0].trim();
                return !sourceNames.includes(n)
            });

        }

        function getElements() {
            var indexData = $scope.data.series[0].index;
            var elements = []
            for (var i = 0; i < indexData.ref.length; i++) {
                if (indexData.ref[i]) {
                    if (indexData.ref[i].toLowerCase().includes("dimension") && indexData.include[i])
                        elements.push({
                            label: indexData.name[i],
                            sourceColumn: indexData.ref[i],
                            type: "text",
                            isMandatory: true
                        });
                    if (indexData.ref[i].toLowerCase().includes("area") && indexData.include[i])
                        elements.push({
                            label: indexData.name[i],
                            sourceColumn: indexData.ref[i],
                            type: "area",
                            isMandatory: true
                        });

                    if (indexData.ref[i].toLowerCase().includes("asset") && indexData.include[i])
                        elements.push({
                            label: indexData.name[i],
                            sourceColumn: indexData.ref[i],
                            type: "asset",
                            isMandatory: true
                        });
                    if (indexData.ref[i].toLowerCase().includes("project") && indexData.include[i])
                        elements.push({
                            label: indexData.name[i],
                            sourceColumn: indexData.ref[i],
                            type: "project",
                            isMandatory: true
                        });
                }
            }

            return elements;
        }

        $scope.addExcelJob = function () {
            $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newJob.html',
                controller: 'newJobController',
                resolve: {
                    job: function () {
                        var config = {};
                        Object.assign(config, $scope.data.series[0].index);
                        config.sources.forEach(s => {
                            if (s) {
                                delete s.$$hashKey;
                                delete s.exists;
                            }
                        });

                        return {
                            jobType: 'Excel Multi',
                            dataSource: null,
                            mapping: JSON.stringify(config),
                            fileName: $scope.data.fileName
                        };
                    }
                }
            });
        }

        loadDataSources();


    }]);