import { ApplicationUser, User } from "@api"
import backend from "@common/services/backendHttp"

const resourceUrl = "api/CustomerAdmin"

export const disable = (customerId: number) => {
    const url = resourceUrl + "/disable/" + customerId
    return backend.put(url)
}

export const getMasterDbUsers = (customerId: number) => {
    const url = resourceUrl + "/masterDbUsers/" + customerId
    return backend.get<ApplicationUser[]>(url)
}

export const getcustomerDbUsers = (customerId: number) => {
    const url = resourceUrl + "/customerDbUsers/" + customerId
    return backend.get<User[]>(url)
}

export function disableUsers(customerId: number) {
    const url = resourceUrl + "/disableUsers/" + customerId
    return backend.put(url)
}
