﻿angular
    .module('DigiLean')
    .controller("improvementController", ['$scope', '$document', '$filter', 'authService', '$uibModal',  'suggestionService',
        function ($scope, $document, $filter, authService, $uibModal, suggestionService) {
            $scope.userName = authService.getCurrentUser().userName;

            // Get all 3 years back
            var startDate = moment().subtract(36, 'month').startOf("month");
            var endDate = moment().endOf('month');
            $scope.timePeriod = {
                from: startDate.utc().toDate().toJSON(),
                to: endDate.utc().toDate().toJSON()
            }

            $scope.new = {
                list: [],
                filteredList: []
            };
            $scope.suggestionStatus1 = {
                list: [],
                filteredList: []
            };
            $scope.suggestionStatus2 = {
                list: [],
                filteredList: []
            };
            $scope.suggestionStatus3 = {
                list: [],
                filteredList: []
            };
            $scope.suggestionStatus4 = {
                list: [],
                filteredList: []
            };


            // UI SORTABLE => Drag and drop, SuggestionList
            $scope.suggestionSortableOptions = {
                connectWith: ".suggestioncontainer",
                receive: changePriorityStatus
            };
            // Priority matrix options pr 
            $scope.matrixSortableOptions = {
                connectWith: "#suggestionList, .suggestioncontainer",
                receive: changePriorityStatus
            };

            function changePriorityStatus (e, ui) {
                var target = ui.item.sortable.droptarget[0];
                var prioritystatus = $(target).attr("data-prioritystatus");
                var source = ui.item.sortable.source[0];
                var sourceStatus = $(source).attr("data-prioritystatus");
                var suggestion = ui.item.sortable.model;
                // alert("from priority: " + sourceStatus + " to: "  + prioritystatus);
                var status = "Suggested";
                if (prioritystatus > 0) status = "Planned";
                var statusData = {
                    suggestionId: suggestion.id,
                    status: status,
                    priorityStatus: prioritystatus
                };

                suggestionService().changePriorityStatus(statusData).then(function () {
                    // if status also has changed we need to notify
                    if (suggestion.status !== statusData.status) {
                        suggestionService().updateStatus(statusData).then(function () {

                        });

                    }
                });
            };

            
            $scope.subscribe("ImprovementLikeAdded", function (like) {
                var existingSuggestions = $filter('filter')($scope.new.list, { id: like.improvementSuggestionId }, true);
                if (existingSuggestions.length === 1) {
                    var index = $scope.new.list.indexOf(existingSuggestions[0]);
                    $scope.new.list[index].likes.push(like);
                }
            });

            $scope.subscribe("ImprovementTitleChanged", function (improvementTitle) {
                var existingSuggestions = $filter('filter')($scope.new.list, { id: improvementTitle.suggestionId }, true);
                if (existingSuggestions.length === 1) {
                    var index = $scope.new.list.indexOf(existingSuggestions[0]);
                    $scope.new.list[index].title = improvementTitle.newTitle;
                    $scope.new.list[index].cssClass = "animated bounce";

                    // check filtered list
                    var existingfilteredSuggestions = $filter('filter')($scope.new.filteredList, { id: improvementTitle.suggestionId }, true);
                    if (existingfilteredSuggestions.length === 1) {
                        var index = $scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                        $scope.new.filteredList[index].title = improvementTitle.newTitle;
                        $scope.new.filteredList[index].cssClass = "animated bounce";                        
                    }
                }
            });

            


            $scope.subscribe("ImprovementResponsibleChanged", function (improvementResponsible) {
                // find suggestion by id
                var existingSuggestions = $filter('filter')($scope.new.list, { id: improvementResponsible.suggestionId }, true);
                if (existingSuggestions.length === 1) {
                    var index = $scope.new.list.indexOf(existingSuggestions[0]);
                    $scope.new.list[index].responsibleUserId = improvementResponsible.newResponsibleUserId;
                    $scope.new.list[index].responsibleUser = improvementResponsible.newResponsibleUser;
                    $scope.new.list[index].cssClass = "animated bounce"; // Add animation

                    var existingfilteredSuggestions = $filter('filter')($scope.new.filteredList, { id: improvementResponsible.suggestionId }, true);
                    if (existingfilteredSuggestions.length === 1) {
                        var index = $scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                        $scope.new.filteredList[index].responsibleUserId = improvementResponsible.newResponsibleUserId;
                        $scope.new.filteredList[index].responsibleUser = improvementResponsible.newResponsibleUser;
                        $scope.new.filteredList[index].cssClass = "animated bounce"; // Add animation
                    }
                }
            });

            $scope.subscribe("ImprovementUpdated", function (suggestion) {
                suggestion.cssClass = "animated bounce"; // Add animation
                // find suggestion by id
                suggestionService().get(suggestion.id).then(function (fullSuggestion) {
                    var existingSuggestions = $filter('filter')($scope.new.list, { id: fullSuggestion.id }, true);
                    if (existingSuggestions.length === 1) {
                        var index = $scope.new.list.indexOf(existingSuggestions[0]);
                        fullSuggestion.cssClass = "animated bounce";
                        $scope.new.list[index] = fullSuggestion;
                    }

                    var existingfilteredSuggestions = $filter('filter')($scope.new.filteredList, { id: fullSuggestion.id }, true);
                    if (existingfilteredSuggestions.length === 1) {
                        var index2 = $scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                        fullSuggestion.cssClass = "animated bounce";
                        $scope.new.filteredList[index2] = fullSuggestion;
                    }
                });

            });


            function removeAnmiation(suggestion) {
                setTimeout(function () {
                    suggestion.cssState = "";
                }, 1000);
            }

            $scope.filterById = function (value) {
                return ($scope.filterValues.indexOf(value.id) !== -1);
            };


            $scope.suggestionOpenForEdit = function (suggestion) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: $scope.animationsEnabled,
                    templateUrl: 'fullImprovementInfoForm.html',
                    controller: 'fullImprovementModalController',
                    windowClass: 'fullSuggestion-modal-window',
                    resolve: {
                        suggestion: function () {
                            return suggestion;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
                modalInstance.result.then(function (updatedSuggestion) {
                    suggestion.roi = updatedSuggestion.roi;
                });
            }


            $scope.showComments = function (suggestion) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: $scope.animationsEnabled,
                    templateUrl: 'fullImprovementInfoForm.html',
                    controller: 'fullImprovementModalController',
                    windowClass: 'fullSuggestion-modal-window',
                    resolve: {
                        suggestion: function () {
                            return suggestion;
                        },
                        activeTab: function () {
                            return "Comments";
                        }
                    }
                });
                modalInstance.result.then(function (updatedSuggestion) {
                    suggestion.roi = updatedSuggestion.roi;
                });

            }

            $scope.likedByMe = function (suggestion) {
                if (suggestion.likes) {
                    for (var i = 0; i < suggestion.likes.length; i++) {
                        if (suggestion.likes[i].likedBy === $scope.userName) {
                            return true;
                        }
                    }
                }
                return false;
            }

            $scope.commentedByMe = function (suggestion) {
                if (suggestion.comments) {
                    for (var i = 0; i < suggestion.comments.length; i++) {
                        if (suggestion.comments[i].commentedBy === $scope.userName) {
                            return true;
                        }
                    }
                }
                return false;
            }

            $scope.addComment = function (suggestion) {
                if (typeof suggestion.comments === 'undefined') {
                    suggestion.comments = [];
                }

                var currentComment = {
                    suggestionId: suggestion.id,
                    comment: suggestion.activeComment
                };
                suggestionService().addComment(currentComment).then(function (savedComment) {
                    suggestion.comments.push(savedComment);
                });
                suggestion.activeComment = "";
            }

            $scope.like = function (suggestion) {

                if (typeof suggestion.likes === 'undefined' || suggestion.likes === null) {
                    suggestion.likes = [];
                }
                // TODO: Should check if current user has already liked, then remove like
                suggestionService().addLike(suggestion.id).then(function (like) {
                    // Like event will increase counter
                });
            }


            $scope.newItem = function () {
                const modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newSuggestionForm.html',
                    controller: 'newSuggestionModalController',
                    resolve: {
                        suggestion: function () {
                            return {
                                id: 0,
                                title: "",
                                text: "",
                                suggestedBy: "",
                                suggestionDate: new Date(),
                                showComments: false,
                                likes: [],
                                comments: [],
                            }
                        }
                    }
                })

                modalInstance.result.then(function (suggestion) {
                    suggestionService().addSuggestion(suggestion)
                }, () => {})
            }
        }]);