import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("projectBoardPreview", ['$scope',
        function ($scope) {
            return {
                templateUrl: 'projectBoardPreview.html',
                restrict: 'E',
                scope: {
                    template: '='
                },
                link: function (scope, elem, attrs) {
                    
                    scope.imageUrl = staticFileService.image("projectboardpreview.jpg");

                }
            }
        }]);
