﻿import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("strategyMap", ['authService',
        function (authService) {
            return {
                templateUrl: 'strategyMap.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {

                    
            
                    getAssetList();
                    scope.subscribe("UserAuthenticatedAndReady", function(profile) {  
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");   
                        getAssetList();
                    });

                    function getAssetList() {
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                        scope.selectAsset = null;
                        if(scope.isAdmin){
                            assetService.getAllAssets(true).then(function (assets) {
                                scope.assets = assets;    
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                                } 
                                scope.selectAssetId = null;
                               // loadStrategyMap();
                            });
                        } else {
                            assetService.getList().then(function (assets) {
                                scope.assets = assets;  
                                if(scope.assets.length>0){
                                    scope.selectAsset = scope.assets[0];
                                    scope.selectAssetId = scope.selectAsset.id;
                                }
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level; 
                                                     
                                }   
                                //scope.loadStrategyMapByAsset(scope.selectAsset);
                                     
                            });
                        }
                        
                        
                    };
                    scope.toplevel = function () {
                        scope.selectAsset = null;
                        scope.selectAssetId = null;
                        //loadStrategyMap();
                    };
                    // Live styling / to use to pick up our colors on goals
                    var sheet = window.document.styleSheets[0];

                    scope.loadStrategyMapByAsset = function (asset) {
                        scope.selectAssetId = asset.id;
                        scope.selectAsset = asset;
                    }

                    
         /*           // Events
                    $rootScope.subscribe('StrategyGoalColorUpdated', function (goal) {
                        if (!goal.parentId) goal.parentId = 0;
                        sheet.insertRule("[node-id='" + goal.id + "'] rect { fill: " + goal.color + "; }", sheet.cssRules.length);
                    });

                    $rootScope.subscribe('StrategyGoalAssetUpdated', function (goal) {
                        var node = chart.get(goal.strategyGoalId);
                        chart.updateNode({
                            id: goal.strategyGoalId,
                            pid: node.pid,
                            name: node.name,
                            title: goal.assetName,
                            // img: node.img
                        });
                    });

                    $rootScope.subscribe('StrategyGoalNameUpdated', function (goal) {
                        if (!goal.parentId) goal.parentId = 0;
                        var node = chart.get(goal.id);
                        chart.updateNode({
                            id: goal.id,
                            pid: goal.parentId,
                            name: goal.name,
                            title: node.title,
                            // img: node.img
                        });
                    });
                    
                    $rootScope.subscribe('StrategyGoalCreated', function (goal) {
                        if (!goal.parentId) goal.parentId = 0;
                        if (goal.asset) {
                            goal.assetName = goal.asset.name;
                        }
                        sheet.insertRule("[node-id='" + goal.id + "'] rect { fill: " + goal.color + "; }", sheet.cssRules.length);
                        chart.addNode(
                            {
                                id: goal.id,
                                pid: goal.parentId,
                                name: goal.name,
                                title: goal.assetName,
                                // img: "https://digileanfiles.blob.core.windows.net/profile-images/Medium-d58549fd-5e91-4d48-84c1-770803a84584-cropped.jpg"
                            });
                    });
                    */
                    /*
                    $rootScope.subscribe('StrategyGoalDeleted', function (goal) {
                        chart.removeNode(goal.id);
                    });

                    // Load strategy
                    function loadStrategyMap() {
                        strategyService().getStrategyMap().then(function (strategy) {
                            var topNode = {
                                id: 0,
                                name: strategy.strategy.vision,
                                childGoals: strategy.goals
                            }
                            // Set strategy as topNode
                            topNode.childGoals.forEach(goal => {
                                goal.parent = topNode;
                            });

                            scope.strategy = topNode;
                            drawChart(scope.strategy); // OrgChart.js
                        });
                    }


                    scope.loadStrategyMapByAsset = function (asset) {
                        scope.selectAsset = asset;
                        strategyService().getStrategyMapByAsset(asset.id).then(function (strategy) {
                            var topNode = {
                                id: 0,
                                name: strategy.strategy.vision,
                                childGoals: strategy.goals
                            }
                            // Set strategy as topNode
                            topNode.childGoals.forEach(goal => {
                                goal.parent = topNode;
                            });

                            scope.strategy = topNode;
                            drawChart(scope.strategy); // OrgChart.js
                        });
                    }

                    // DEBUG - Should remove.
                    strategyService().fixStrategy().then(function () {

                    });
*/

/*

                    // Custom template for vision topnode
                    OrgChart.templates.visionTemplate = Object.assign({}, OrgChart.templates.olivia);
                    OrgChart.templates.visionTemplate.size = [500, 120];
                    OrgChart.templates.visionTemplate.node =
                        '<rect rx="15" height="130" width="500" fill="#2f4050" stroke-width="1" stroke="#1C1C1C"></rect>';
                    OrgChart.templates.visionTemplate.ripple = {
                        radius: 100,
                        color: "#2f4050",
                        rect: null
                    };
                    OrgChart.templates.visionTemplate.field_0 = '<text style="font-size: 14px;" text-overflow="multiline" fill="#FFFFFF" x="20" y="50" text-anchor="left" width="480">{val}</text>';
                    OrgChart.templates.visionTemplate.nodeMenuButton = '';//'<g style="cursor:pointer;" transform="matrix(1,0,0,1,93,15)" control-node-menu-id="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><line x1="0" y1="0" x2="0" y2="10" stroke-width="2" stroke="#0890D3" /><line x1="7" y1="0" x2="7" y2="10" stroke-width="2" stroke="#0890D3" /><line x1="14" y1="0" x2="14" y2="10" stroke-width="2" stroke="#0890D3" /></g>';

                    OrgChart.templates.goalTemplate = Object.assign({}, OrgChart.templates.ana);
                    OrgChart.templates.goalTemplate.field_0 = '<text style="font-size: 14px;" text-overflow="multiline" fill="#FFFFFF" x="20" y="50" text-anchor="left" width="210">{val}</text>';
                    OrgChart.templates.goalTemplate.field_1 =  '<text width="130" style="font-size: 10px;" fill="#ffffff" x="230" y="30" text-anchor="end" class="field_1">{val}</text>';

                    function createNodes(chart, strategy) {
                        createNode(chart, strategy, null);
                        chart.draw(OrgChart.action.init);
                    }

                    function createNode(chart, goal, parent) {
                        // Create style rule so each goal is assigned correct color.
                        sheet.insertRule("[node-id='" + goal.id + "'] rect { fill: " + goal.color + "; }", sheet.cssRules.length);
                        if (!parent) {
                            chart.add(
                                {
                                    id: goal.id,
                                    name: goal.name,
                                    tags: ["StrategicVision"],
                                    title: ""
                                });
                        } else {
                            chart.add(
                                {
                                    id: goal.id,
                                    pid: parent.id,
                                    name: goal.name,
                                    title: goal.assetName,
                                    tags: ["GoalNode"],
                                    // img: "https://digileanfiles.blob.core.windows.net/profile-images/Medium-d58549fd-5e91-4d48-84c1-770803a84584-cropped.jpg"
                                });

                        }

                        if (!goal.childGoals) return;
                        goal.childGoals.forEach(child => {
                            createNode(chart, child, goal);
                        });
                    }

                    var chart;
                    function drawChart(strategy) {
                        chart = new OrgChart(document.getElementById("strategy-org-chart"), {
                            enableTouch: false, // Enable touch instead of mouse for particular devices with touchscreen/touchpad/trackpad.
                            nodeBinding: {
                                field_0: "name",
                                field_1: "title",
                                img_0: "img"
                            },
                            nodeMenu: createMenu(),
                            tags: {
                                "StrategicVision": {
                                    template: "visionTemplate"
                                },
                                "GoalNode": {
                                    template: "goalTemplate"
                                }
                            },

                            toolbar: {
                                fullScreen: true,
                                zoom: true,
                                fit: true,
                                expandAll: true
                            },
                        });
                        chart.on('click', function (sender, args) {
                            // args.node is the goal that is clicked..
                           if(args.node.id != 0){
                                strategyService().canEditGoal(args.node.id).then(function (canEdit) {
                                    if(canEdit){
                                        var modalInstance = $uibModal.open({
                                            backdrop: 'static',
                                            animation: true,
                                            templateUrl: 'strategyCardPopup.html',
                                            controller: 'strategyCardPopup',
                                            resolve: {
                                                goalId: function () {
                                                    return args.node.id;
                                                }

                                            }
                                        });

                                        modalInstance.result.then(function (result) {

                                        });
                                    }
                                });
                            
                        }
                            return false;
                            // return false; to cancel the operation
                        });
                        createNodes(chart, strategy);
                        chart.fit();
                    }

                    var emptyIcon = '<svg width="0" height="0"></svg>'
                    // Our custom node menu
                    function createMenu() {
                        return {
                            editGoal: {
                                icon: emptyIcon, // Needs an icon, so we just going to provide an empty svg for now
                                text: $translate.instant("STRATEGY_EDIT_GOAL"), 
                                onClick: editGoal
                            },
                            addGoal: {
                                icon: emptyIcon, // Needs an icon, so we just going to provide an empty svg for now
                                text: $translate.instant("STRATEGY_SUB_GOAL_NEW"), 
                                onClick: addGoal
                            },
                            deleteGoal: {
                                icon: emptyIcon, // Needs an icon, so we just going to provide an empty svg for now
                                text: $translate.instant("STRATEGY_DELETE_GOAL"), 
                                onClick: deleteGoal
                            }
                        }
                    }

                    function editGoal(sender) {
                        var goalId = sender;
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'goalEditPopup.html',
                            controller: 'goalEditPopup',
                            resolve: {
                                goalId: function () {
                                    return goalId;
                                }

                            }
                        });
                        modalInstance.result.then(function (result) {

                        });
                    }


                    function addGoal(sender) {
                        var parentGoalId = sender;
                        if (parentGoalId == 0) { // Means it's directly under strategy

                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'goalCreatePopup.html',
                            controller: 'goalCreatePopup',
                            resolve: {
                                goalId: function () {
                                    return parentGoalId;
                                }

                            }
                        });
                        modalInstance.result.then(function (result) {

                        });
                    }

                    function deleteGoal(sender) {
                        var goalId = sender;
                        if (goalId == 0) return;
                        swal({
                            title: $translate.instant("STRATEGY_GOAL_DELETE_QUESTION"),
                            text: $translate.instant("STRATEGY_GOAL_DELETE_DESCRIPTION"),
                            icon: "error",
                            buttons: [$translate.instant("COMMON_CANCEL"),
                            $translate.instant("STRATEGY_GOAL_DELETE_BUTTON")],
                            dangerMode: true,
                            closeOnConfirm: false
                        }).then((value) => {
                            switch (value) {
                                case true: // Yes to delete
                                    strategyGoalService().deleteGoal(goalId).then(function () {

                                    });
                                    break;
                                default:
                            }
                        });
                    }
*/
                }

            }
        }]);