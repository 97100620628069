angular
    .module('DigiLean')
    .directive("datasourceDailyKPITarget", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'datasourceDailyKPITarget.html',
                restrict: 'E',
                scope: {
                    'datasource': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.operators = [
                        { dbCode: ">=", html: "&gt;=" },
                        { dbCode: ">", html: "&gt;" },
                        { dbCode: "<=", html: "&lt;=" },
                        { dbCode: "<", html: "&lt;" },
                    ];

                    scope.notValidExpression = false;
                    scope.cannotDeleteTarget = false;

                    scope.$watch('datasource', function (datasource) {
                        // apply
                        if (datasource) {
                            if (datasource.targets) {
                                scope.targets = datasource.targets;
                                scope.orderedTargets = datasource.targets;
                            } else {
                                setDefaultTargets();
                            }
                            configureTargets();
                            setupProgressBar();
                        }
                    });

                    function configureTargets(){
                        angular.forEach(scope.targets, function (target) {
                            var operator = $filter('filter')(scope.operators, { dbCode: target.operator.dbCode }, true);
                            if (operator.length > 0) {
                                target.selectedOperator = operator[0];
                            }
                        });
                    }
                    function setDefaultTargets() {
                        scope.targets = [
                            {colorStyleClass: 'greencross-green',
                            operator: { dbCode: "<=", html: "&lt;=" },
                            targetValue: 0},
                            {colorStyleClass: 'greencross-yellow',
                            operator: { dbCode: "<=", html: "&gt;=" },
                            targetValue: 2},
                            {colorStyleClass: 'greencross-red',
                            operator: { dbCode: ">", html: "&gt;" },
                            targetValue: 2}
                        ]
                        scope.orderedTargets = scope.targets;
                    }

                    scope.targetValueChanged = function (target) {
                        target.targetValue = target.targetValue.toString().replace(',', '.');
                        reorderTargets();
                        connectTargetToDatasource();
                        setupProgressBar();
                    }

                    scope.operatorChanged = function (target, operator) {
                        target.operator = operator;
                        reorderTargets();
                        setupProgressBar();
                    }

                    scope.colorChanged = function (target, colorStyleClass) {
                        target.colorStyleClass = colorStyleClass;
                        reorderTargets();
                        setupProgressBar();
                    }
                    function connectTargetToDatasource() {
                        scope.datasource.targets = scope.orderedTargets;
                    }

                    scope.addTarget = function(){
                        scope.cannotDeleteTarget = false;
                        var newTarget = 
                            {colorStyleClass: 'greencross-blue',
                            operator: { dbCode: "<=", html: "&gt;=", sortOrder: 3},
                            targetValue: 1};
                        var operator = $filter('filter')(scope.operators, { dbCode: newTarget.operator.dbCode }, true);
                        if (operator.length > 0) {
                            newTarget.selectedOperator = operator[0];
                        }
                        scope.targets.push(newTarget);
                        reorderTargets();
                        setupProgressBar();
                    }

                    function getUndefinedTarget(){
                        return {helpertarget:true, colorStyleClass: 'daily-kpi-gray',
                        operator: { dbCode: "", html: "", sortOrder: 0},
                        targetValue: ''};
                    }
                    function setupProgressBar(){
                        scope.progressbarItems = [];
                        var temp = [];

                        // set undefined targets before and after all registered targets
                        for(let i=0; i<scope.orderedTargets.length; i++){
                            temp.push(getUndefinedTarget());
                            temp.push(scope.orderedTargets[i]);
                        }
                        temp.push(getUndefinedTarget());

                        // color undefined targets to the left if applicable j=5
                        for(let j=1; j<temp.length; j++){
                            if(temp[j].operator.dbCode == '<'){
                                for(let z=j-1; z>=0; z--){
                                    if(temp[z].colorStyleClass == 'daily-kpi-gray'){
                                        temp[z].colorStyleClass = temp[j].colorStyleClass;
                                    }                
                                }
                            } else if(temp[j].operator.dbCode == '<='){
                                if(j+2 < temp.length && parseFloat(temp[j].targetValue) == parseFloat(temp[j+2].targetValue)){
                                    temp[j+1].colorStyleClass = temp[j].colorStyleClass;
                                }
                                for(let z=j-1; z>=0; z--){
                                    if(temp[z].colorStyleClass == 'daily-kpi-gray'){
                                        temp[z].colorStyleClass = temp[j].colorStyleClass;
                                    }                
                                }
                            }
                        }

                        // color undefined targets to the right if applicable
                        for(let k=temp.length-1; k>0; k--){
                            if(temp[k].operator.dbCode == '>'){         
                                for(let x=k; x<temp.length; x++){
                                    if(temp[x].colorStyleClass == 'daily-kpi-gray'){
                                        temp[x].colorStyleClass = temp[k].colorStyleClass;
                                    }
                                }
                            } else if(temp[k].operator.dbCode == '>='){
                                if(k-2 >= 0 && parseFloat(temp[k].targetValue) == parseFloat(temp[k-2].targetValue)){
                                    temp[k-1].colorStyleClass = temp[k].colorStyleClass;
                                }
                                for(let w=k+1; w<temp.length; w++){
                                    if(temp[w].colorStyleClass == 'daily-kpi-gray'){
                                        temp[w].colorStyleClass = temp[k].colorStyleClass;
                                    }                
                                }
                            }
                        }

                        // build correct visualization bar 
                        for(let l=0; l<temp.length; l++){
                            if(temp[l].helpertarget){
                                if(l == 0){
                                    if(temp[0].colorStyleClass != temp[1].colorStyleClass){
                                        scope.progressbarItems.push(temp[l]);
                                    }
                                } else if (l == temp.length-1){
                                    if(temp[l].colorStyleClass != temp[l-1].colorStyleClass){
                                        scope.progressbarItems.push(temp[l]);
                                    } 
                                } else {
                                    if(temp[l].colorStyleClass != temp[l-1].colorStyleClass &&
                                        temp[l].colorStyleClass != temp[l+1].colorStyleClass ){
                                        scope.progressbarItems.push(temp[l]);
                                    }
                                }
                            } else{
                                scope.progressbarItems.push(temp[l]); 
                            }
                        }
                        scope.progresswidth = 100/scope.progressbarItems.length;
                    }

                    function getOperatorSortOrder(operator){
                        if(operator.dbCode == "<") return 1;
                        if(operator.dbCode == "<=") return 2;
                        if(operator.dbCode == ">=") return 3;
                        return 4;
                    }

                    function reorderTargets(){
                        scope.notValidExpression = false;
                        scope.orderedTargets = JSON.parse(JSON.stringify(scope.targets)); //Deep copy
                        //scope.orderedTargets = scope.targets;
                        scope.orderedTargets.sort(function (a, b) {
                            if(a.targetValue == b.targetValue){
                                var aOp = getOperatorSortOrder(a.operator);
                                var bOp = getOperatorSortOrder(b.operator);
                                if(aOp == bOp || (a.operator.dbCode == '<=' && b.operator.dbCode == '>=') || (a.operator.dbCode == '>=' && b.operator.dbCode == '<=')){
                                    scope.notValidExpression = true;
                                }
                                return aOp - bOp;
                            } else {
                                return a.targetValue - b.targetValue;
                            }
                        });
                        connectTargetToDatasource(); 
                    }

                    scope.delete = function (target) {
                        if (scope.targets.length == 1) {
                            // Must have at least one target
                            scope.cannotDeleteTarget = true;
                            
                        } else {
                            var index = scope.targets.indexOf(target);
                            if (index > -1) {
                                scope.targets.splice(index, 1);
                                reorderTargets();
                                connectTargetToDatasource();
                                setupProgressBar();
                            }                            
                        }
                    }

                }

            }
        }]);