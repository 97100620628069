angular
    .module('DigiLean').directive("learningFrontPage", ['$filter', '$translate', 'learningService', 'debounce',
        function ($filter, $translate, learningService, debounce) {
            return {
                templateUrl: 'learningFrontPage.html',
                restrict: 'E',
                scope: {
                    'material': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    
                    scope.pageTitle = {
                        isEditMode : false
                    }

                    scope.$watch("material", function (pageId) {
                        if (!scope.material) return;
                       
                    });

                    scope.$watch("isEditMode", function (isEditMode) {
                        // if (!scope.isEditMode) {
                        //     setAllSectionsEditMode(false);
                        //     scope.pageTitle.isEditMode = false;
                        // };
                    });


                    scope.pageTitleChanged = function (page) {
                        learningService().updateItemTitle(page.id, page.title).then(function (itemTitle) {

                        });
                    }



                    function loadPage(pageId) {
                        learningService().getLearningMaterial(pageId).then(function (page) {
                            scope.material = page;
                        });
                    }

                  
                }

            }
        }]);
