var DigiLean = angular.module('DigiLean');  
DigiLean.controller('mysuggestionsController', ['$scope', '$filter', '$uibModal', '$uibModalInstance', 'suggestionService','navigationService',
    function ($scope, $filter, $uibModal,$uibModalInstance, suggestionService, navigationService) {
        $scope.isLoading = true;
        $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
        $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
            var hasAccess = $filter('filter')(profile.modules, { name: "IMPROVEMENT" });
            $scope.hasImprovementModule = hasAccess.length > 0;
        });

        $scope.items = [];
        $scope.suggestions = [];
        $scope.subscribe("ImprovementChangedStatus", function (improvementStatus) {
            // Check if comment is related to one of users
            var improvement = $filter('filter')($scope.suggestions, { id: improvementStatus.suggestionId }, true);
            if (improvement.length > 0) {
                improvement[0].status = improvementStatus.status;
                improvement[0].changed = true;
            }
        });
        $scope.subscribe("ImprovementUpdated", function (suggestion) {
            suggestion.cssClass = "animated bounce"; // Add animation
            suggestion.changed = true;
            // find suggestion by id
            var existingSuggestions = $filter('filter')($scope.suggestions, { id: suggestion.id }, true);
            if (existingSuggestions.length === 1) {
                var index = $scope.suggestions.indexOf(existingSuggestions[0]);
                $scope.suggestions[index] = suggestion;
                $scope.$apply();
            }
        });
        $scope.subscribe("ImprovementTitleChanged", function (suggestion) {
            var existingSuggestions = $filter('filter')($scope.suggestions, { id: suggestion.suggestionId }, true);
            if (existingSuggestions.length === 1) {
                existingSuggestions[0].title = suggestion.newText;
                existingSuggestions[0].cssClass = "animated bounce"; // Add animation
                existingSuggestions[0].changed = true;
                $scope.$apply();
            }
        });
        
        $scope.subscribe("SuggestionCommentedOn", function (suggestionComment) {
            var improvement = $filter('filter')($scope.suggestions, { id: suggestionComment.improvementSuggestionId }, true);
            if (improvement.length > 0) {
                improvement[0].changed = true;
                improvement[0].comments.push(suggestionComment);
            }
        });
        // Get suggestions
        var getSuggestions = function () {
            suggestionService().getMySuggestions().then(function (suggestions) {
                suggestions.forEach(function (suggestion) {
                    suggestion.activeComment = "";
                    suggestion.changed = false;
                });
                $scope.suggestions = suggestions;
                $scope.isLoading = false;
            });
        }
        getSuggestions();

        $scope.showComments = function (suggestion) {
            suggestion.showComments = !suggestion.showComments;
            suggestion.showLikes = false;
        }

        $scope.showLikes = function (suggestion) {
            suggestion.showComments = false;
            suggestionService().getLikesForSuggestion(suggestion.id).then(function(likes) {
                suggestion.likes = likes;
                suggestion.showLikes = !suggestion.showLikes;
            });
            
        }

        

        $scope.addComment = function (suggestion) {
            if (typeof suggestion.comments === 'undefined') {
                suggestion.comments = [];
            }

            var currentComment = {
                suggestionId: suggestion.id,
                comment: suggestion.activeComment
            };
            suggestionService().addComment(currentComment).then(function (savedComment) {

            });
            suggestion.activeComment = "";

        }

        $scope.openImprovementSuggestion = function (improvementsuggestionid) {
            suggestionService().get(improvementsuggestionid).then(function (suggestion) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'fullImprovementInfoForm.html',
                    controller: 'fullImprovementModalController',
                    windowClass: 'fullSuggestion-modal-window',
                    resolve: {
                        suggestion: function () {
                            return suggestion;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
                // When user has created new suggestion
                modalInstance.result.then(function (suggestion) {
                    var existingSuggestions = $filter('filter')($scope.suggestions, { id: suggestion.id }, true);
                    if (existingSuggestions.length === 1) {
                        var index = $scope.suggestions.indexOf(existingSuggestions[0]);
                        $scope.suggestions[index] = suggestion;
                        $scope.$apply();
                    }
                }, function () {
                    //$log.info('Modal dismissed at: ' + new Date());
                });
            });
        }

        $scope.close = function () {
            $uibModalInstance.close();      
        };

    }]);