﻿angular
    .module('DigiLean')
    .directive("versionChecker", ['$translate', 'versionService',
        function ($translate, versionService) {
            return {
                restrict: 'E',
                link: function (scope, elem, attrs) {

                    // title
                    scope.updateTitle = "";
                    scope.updateText = "";
                    // subscribe to version event + check local version vs new.
                    $translate(['SYSTEM_UPDATE_TITLE', 'SYSTEM_UPDATE_TEXT']).then(function (translations) {

                        scope.updateTitle = translations.SYSTEM_UPDATE_TITLE;
                        scope.updateText = translations.SYSTEM_UPDATE_TEXT;
                        checkVersion();
                    });

                    var displayUpdate = function (version) {
                        toastr.info(scope.updateText, scope.updateTitle,
                            {
                                closeButton: false,
                                tapToDismiss: true,
                                timeOut: 0,
                                positionClass: "toast-top-center",
                                extendedTimeOut: 0,
                                onclick: function () {
                                    // Update local cache with updated version number.
                                    localStorage.remove('versionNumber');
                                    localStorage.set('versionNumber', version.versionNumber);
                                    // Then refresh with clearing of cache that makes sure we have the latest version
                                    location.reload();
                                }
                            });

                    }

                    // If a "live" version is published
                    scope.subscribe("NewVersion", function (version) {
                        displayUpdate(version);
                    });

                    var checkVersion = function () {
                        // Get latest version and check if matches our cached version number
                        versionService().getLatestVersion().then(function (version) {
                            if (!version) return;
                            scope.version = version;
                            // Get local version and compare.
                            var currentVersion = localStorage.get("versionNumber");
                            if (!currentVersion || currentVersion !== version.versionNumber) {
                                displayUpdate(version);
                            }
                        });
                    }
                }
            }
        }]);
