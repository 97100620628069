angular
    .module('DigiLean')
    .directive("smartactionlistResponsibleCell", ['$filter', 'boardTaskService',
        function ($filter, boardTaskService) {
            return {
                templateUrl: 'smartactionlistResponsibleCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.user = null;
    

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.task) {
                            setResponsible();
                            scope.model.setValue = function (user) {
                                scope.selectUser(user);
                            }
                        }
                        model.getSortValue = function () {
                            if (scope.user) {
                                return scope.user.fullName;
                            }
                            return scope.displayName;
                        }
                    });

                    function setResponsible(){
                        if (scope.model.task.responsibleUserId) {
                            if (scope.users) {
                                var matchingUser = $filter('filter')(scope.users, { userId: scope.model.task.responsibleUserId }, true);
                                if (matchingUser.length > 0) {
                                    scope.displayName = matchingUser[0].displayName;
                                    scope.user = matchingUser[0];
                                } else {
                                    var userDetails = {
                                        userId: scope.model.task.responsibleUserId,
                                        lastName: scope.model.task.responsibleUser, /* responsibleUser shows full name, use lastName such that it can be displayed as tooltip */
                                        displayName: scope.model.task.responsibleDisplayName
                                    };
                                    scope.displayName = scope.model.task.responsibleDisplayName;
                                    scope.user = userDetails;
                                }
                            } 
                        }
                    }

                    scope.$watch("users", function (users) {
                        if (!users) return;
                        setResponsible();
                        /*if (scope.user === null && scope.model.task.responsibleUserId) {
                            var matchingUser = $filter('filter')(scope.users, { userId: scope.model.task.responsibleUserId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                            }
                        }*/
                    });


                    scope.subscribe('TaskAssigned', function (status) {
                        if (status.taskId == scope.model.task.id) {
                            var matchingUser = $filter('filter')(scope.users, { userId: status.userId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                                scope.model.task.responsibleUserId = scope.user.userId;
                                scope.model.task.responsibleUser= scope.user.fullName;
                                scope.model.task.responsibleDisplayName = scope.user.displayName;
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });

                    scope.clearSelectedUser = function () {
                        var emptyUser = {
                            userId: null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.user = null;
                        scope.selectUser(emptyUser);

                    };
                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.displayname = user.displayName;
                        var userDetails = {
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName
                        };
                        var options = {
                            taskId: scope.model.task.id,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);

                    };
                }
            }
        }]);