import { createGuid } from "@common/services/helperFunctions"

angular
    .module('DigiLean')
    .controller("newApiClientController", ['$scope', '$uibModalInstance', 'client',
        function ($scope, $uibModalInstance, client) {

            $scope.client = client
            $scope.client.clientId = createGuid()

            $scope.ok = function () {
                console.log("ok")
                $uibModalInstance.close($scope.client)
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel')
            }

            $scope.onCustomerChange = function($event) {
                const customer = $event.detail
                $scope.client.customerId = customer.id
            }
            
        }])
