angular
    .module('DigiLean')
    .directive("monthlyStatusDeviationArea", ['$filter', '$translate', 'deviationService',
        function ($filter, $translate, deviationService) {
            return {
                templateUrl: 'monthlyStatusDeviationArea.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {

                    var grafcolor = ['#2A2E36', '#0099DE', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'],
                        graphtextcolor = '#ffffff';

                    var leadtimecategories = [],
                        xfirstlinecategories = [],
                        xmonthcategories = [],
                        browserDataSuggestionByArea = [],
                        dataFromNewToPlanned = [];

                    function getStatusData() {
                        var endDate = moment().utc().endOf('month');
                        var startDate = moment().utc().add(-3, 'month').startOf('month');
                        // Monthly suggested, need to find start and end of month
                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };
                        deviationService().getAreaMonthCountOnStatus(timePeriod).then(function (result) {

                            var newSuggestionByData = result.new;
                            var implementedSuggestionByData = result.implemented;

                            if (newSuggestionByData) {
                                // Build the data arrays
                                for (var i = 0; i < newSuggestionByData.length; i++) {
                                    var areaName = newSuggestionByData[i].area;
                                    if (areaName === null) {
                                        areaName = $translate.instant('COMMON_UNDEFINED');
                                    }
                                    // add new browser data
                                    browserDataSuggestionByArea.push({
                                        name: areaName,
                                        data: newSuggestionByData[i].suggestionCount,
                                        color: grafcolor[i],
                                        stack: firstlinecategorynew,
                                        xAxis: 1
                                    });

                                    // add implemented browser data
                                    if (implementedSuggestionByData.length > i) {
                                        var areaName = implementedSuggestionByData[i].area;
                                        if (areaName === null) {
                                            areaName = $translate.instant('COMMON_UNDEFINED');
                                        }
                                        browserDataSuggestionByArea.push({
                                            name: areaName,
                                            data: implementedSuggestionByData[i].suggestionCount,
                                            color: grafcolor[i],
                                            showInLegend: false,
                                            stack: firstlinecategoryimpl,
                                            xAxis: 1
                                        });
                                    }

                                }

                                xmonthcategories = newSuggestionByData[0].monthNumbers;

                                for (var i = 0; i < xmonthcategories.length; i++) {
                                    xfirstlinecategories.push('');
                                    xfirstlinecategories.push(firstlinecategorynew);
                                    xfirstlinecategories.push(firstlinecategoryimpl);
                                    xfirstlinecategories.push('');
                                }

                                var zeroes = [];
                                xfirstlinecategories.forEach(function () { zeroes.push(0); });


                                browserDataSuggestionByArea.push({
                                    name: '',
                                    data: zeroes,
                                    showInLegend: false,
                                    stack: firstlinecategoryimpl,
                                });

                                var monthly = $(elem).find(".monthlystatusarea")[0];
                                Highcharts.chart({
                                    chart: {
                                        renderTo: monthly,
                                        type: 'column'
                                    },

                                    title: {
                                        text: ''
                                    },

                                    credits: {
                                        enabled: false
                                    },
                                    exporting: { enabled: false },

                                    xAxis: [
                                        {
                                            categories: xfirstlinecategories,
                                            labels: { autoRotation: false, style: { textOverflow: 'none' } },
                                            tickWidth: 0,
                                        },
                                        {
                                            categories: xmonthcategories,
                                            lineColor: graphtextcolor,
                                            tickWidth: 1,
                                            tickLength: 30
                                        },
                                    ],

                                    yAxis: {
                                        allowDecimals: false,
                                        min: 0,
                                        title: {
                                            text: noOfSuggestions
                                        }
                                    },

                                    tooltip: {
                                        formatter: function () {
                                            return '<b>' + this.x + ' ' + this.series.options.stack + '</b><br>' +
                                                this.series.name + ': ' + this.y + '<br>' +
                                                'Total: ' + this.point.stackTotal;
                                        }
                                    },
                                    plotOptions: {
                                        series: {
                                            events: {
                                                legendItemClick: function () {
                                                    var myname = this.name;
                                                    var myvis = !this.visible;
                                                    this.chart.series.forEach(function (elem) {
                                                        if (elem.name == myname) {
                                                            elem.setVisible(myvis);
                                                        }
                                                    });
                                                    return false;
                                                }
                                            }
                                        },
                                        column: {
                                            stacking: 'normal',
                                            groupPadding: 0.20,
                                        }
                                    },
                                    series: browserDataSuggestionByArea
                                });
                                var onLegendClick = function (event) {
                                    var myname = this.name;
                                    var myvis = !this.visible;
                                    this.chart.series.forEach(function (elem) {
                                        if (elem.name == myname) {
                                            elem.setVisible(myvis);
                                        }
                                    });
                                    return false;
                                }
                            }
                        });


                    }

                    var firstlinecategorynew = "";
                    var firstlinecategoryimpl = "";
                    var noOfSuggestions = "";
                    var fromInProgressToImpl = "";
                    var fromPlannedToInProgress = "";


                    $translate(['COMMON_NEW', 'IMPROVEMENT_IMPL', 'DEVIATION_NUMBER_OF_DEVIATIONS', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'DEVIATION_FROM_IN_PROGRESS_TO_RESOLVED']).then(function (translations) {
                        firstlinecategorynew = translations.COMMON_NEW;
                        firstlinecategoryimpl = translations.IMPROVEMENT_IMPL;
                        noOfSuggestions = translations.DEVIATION_NUMBER_OF_DEVIATIONS;
                        fromInProgressToImpl = translations.DEVIATION_FROM_IN_PROGRESS_TO_RESOLVED;
                        fromPlannedToInProgress = translations.IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS;


                        getStatusData();

                    });
                }

            }
        }]);



















