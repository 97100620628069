import * as customerService from "@common/services/customers/customerService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('customersController', ['$scope', '$translate', '$filter', 'languageAdminService', 'modulesService', '$uibModal',
    function ($scope, $translate, $filter, languageAdminService, modulesService, $uibModal) {

        $scope.savedSuccessfully = false;
        $scope.message = "";

        $scope.date = {
            startDate: null,
            endDate: null,
            datePicker : {
                opened: false
            },
            endDatePicker : {
                opened: false
            }
        };

        $scope.languages = languageAdminService().getSupportedLanguages();


        $scope.showArchived = false;
        $scope.showEditCustomer = false;
        $scope.authOptions = [
            { value: 0, name: $translate.instant('COMMON_PASSWORD') },
            { value: 1, name: $translate.instant('COMMON_AZURE_AD') },
            { value: 2, name: $translate.instant('COMMON_MIXED_AUTH_MODE')}
        ]

        var now = moment().toDate();
        $scope.orders = [];
        
        $translate('ADMIN_REGISTER_NEW_USER').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        })

        $scope.toggleArchived = async function(){
            $scope.showArchived = !$scope.showArchived;
            if($scope.closedAccounts && $scope.closedAccounts.length > 0) return;
            const archivedCustomersinfo = await customerService.getArcivedCustomersForAdmin()
            $scope.closedAccounts = archivedCustomersinfo;     
        }

        $scope.back = function () {
            $scope.showEditCustomer = false;
            $scope.orders = [];
        };

        $scope.clearActiveCustomer = function () {
            $scope.showEditCustomer = true;
            $scope.orders = [];
            $scope.allowedModules = null
            $translate('ADMIN_NEW_CUSTOMER').then(function (title) {
                $scope.actionTitle = title;
            }, function (translationId) {
                $scope.headline = translationId;
            });
            $scope.date = {
                startDate: null,
                endDate: null
            };
            $scope.registration = {
                isDeployed: true,
                isNewCustomer: true,
                id: 0,
                name: "",
                customDomain: "",
                authenticationMode: 0,
                isPaying: false,
                pricePerUser: 0,
                adminUser: {
                    email: "@digilean.tools",
                    password: "",
                },
                account: {
                    subscription : 'STANDARD',
                    billing: 'MONTHLY',
                    isTrial: true,
                    userLicenses : 10,
                    mobileLicenses: 0
                }
            };

        }

        function setDate(account){
            if(account && account.startDate){
                $scope.date.startDate = moment(account.startDate).toDate();
            } else {
                $scope.date.startDate = null;
            }
            if(account && account.endDate){
                $scope.date.endDate = moment(account.endDate).toDate();
            } else {
                $scope.date.endDate = null;
            }
        }
        
        var loadCustomers = async function () {
            $scope.customers = [];
            $scope.openAccount = [];
            $scope.closedAccounts = [];
            $scope.monthlycustomers = 0;
            $scope.yearlycustomers = 0;
            $scope.othercustomers = 0;
            $scope.freeaccounts = 0;
            $scope.trial = 0;
            $scope.resignation = 0;

            const customersinfo = await customerService.getCustomersForAdmin()
            $scope.customers = customersinfo
            for (var i = 0; i < $scope.customers.length; i++) {

                if($scope.customers[i].account && $scope.customers[i].account.endDate){
                    $scope.customers[i].showEndDate = true;
                    if($scope.customers[i].customer.isPaying){  
                        $scope.resignation = $scope.resignation + 1;
                    }
                    $scope.openAccount.push($scope.customers[i]); 
                    statusCounter($scope.customers[i].account);
                } else {
                    $scope.openAccount.push($scope.customers[i]); 
                    statusCounter($scope.customers[i].account);
                }
                
            }
            $scope.freeaccounts = $scope.openAccount.length - $scope.yearlycustomers - $scope.monthlycustomers - $scope.othercustomers;
        }

        loadCustomers();
        function statusCounter(account){
            if(account.isTrial){
                $scope.trial = $scope.trial + 1;
            } else {
                if(account.billing == 'MONTHLY'){
                    $scope.monthlycustomers = $scope.monthlycustomers+1;
                } else if (account.billing == 'YEARLY'){
                    $scope.yearlycustomers = $scope.yearlycustomers +1; 
                } else if (account.billing == 'OTHER'){
                    $scope.othercustomers = $scope.othercustomers +1; 
                }
            }
        }

        $scope.editCustomer = async function (customerinfo) {
            $scope.showEditCustomer = true;
            var customerId = customerinfo.customer.id
            $scope.supportUser = null
            const customerEdit = await customerService.getCustomerForAdmin(customerId)
            const customer = customerEdit.customer

            setDate(customerEdit.account)

            $scope.registration = {
                isNewCustomer: false,
                isDeployed: customer.isDeployed,
                id: customer.id,
                name: customer.name,
                customDomain: customer.customDomain,
                authenticationMode: customer.authenticationMode,
                azureAdTenantId: customer.azureAdTenantId,
                azureAutoSignup: customer.azureAutoSignup,
                isPaying: customer.isPaying,
                pricePerUser: customer.pricePerUser,
                account: customerEdit.account,
                connectionStringName: customer.connectionStringName,
                dataConnectionStringName: customer.dataConnectionStringName,
                storageConnectionStringName: customer.storageConnectionStringName
            }
            if(customerEdit.account && customerEdit.account.customerProductOrders){
                $scope.orders = customerEdit.account.customerProductOrders;
            }
            
            $scope.supportUser = customerEdit.supportUser
            
            modulesService.getCustomerAllowedModulesForCustomer(customerId).then(mods => {
                $scope.allowedModules = mods
            })
            $translate('ADMIN_EDIT_CUSTOMER').then(function (title) {
                $scope.actionTitle = title
            }, function (translationId) {
                $scope.headline = translationId
            })
        }

        $scope.changeSubscription = function (sub) {
            $scope.registration.account.subscription = sub;
        }
        $scope.changeBilling = function (bill) {
            $scope.registration.account.billing = bill;
        }

        $scope.save = function () {
            if ($scope.registration.isPaying && $scope.registration.account) {
                $scope.registration.account.isTrial = false;
            }

            if ($scope.registration.isNewCustomer) {

                var newCustomerAccount =
                {
                    subscription: $scope.registration.account.subscription,
                    billing: $scope.registration.account.billing,
                    isTrial: $scope.registration.account.isTrial,
                    userLicenses: $scope.registration.account.userLicenses,
                    mobileLicenses: $scope.registration.account.mobileLicenses,
                    startDate:$scope.date.startDate,
                    endDate:$scope.date.endDate
                }
                var createCustomer = {
                    customer: $scope.registration,
                    account: newCustomerAccount,
                    adminUser: $scope.registration.adminUser
                };
                customerService.createCustomer(createCustomer).then(function (createdCustomer) {
                    $translate('ADMIN_MESSAGE_CUSTOMER_CREATED').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    loadCustomers();
                    $scope.editCustomer({customer: createdCustomer})
                });
            } else {

                var customerAccount =
                {
                    subscription: $scope.registration.account.subscription,
                    billing: $scope.registration.account.billing,
                    isTrial: $scope.registration.account.isTrial,
                    userLicenses: $scope.registration.account.userLicenses,
                    mobileLicenses: $scope.registration.account.mobileLicenses,
                    startDate:$scope.date.startDate,
                    endDate:$scope.date.endDate
                }
                var customer = {
                    customer: $scope.registration,
                    account: customerAccount,
                };
                customerService.updateCustomer(customer).then(function () {
                    $translate('ADMIN_MESSAGE_CUSTOMER_UPDATED').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    loadCustomers();
                });
            }
        }

        $scope.openAllowedModulesDialog = function (customer) {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'allowedModulesDialog.html',
                controller: 'allowedModulesDialogController',
                resolve: {
                    customer: () => customer
                }
            })
            modalInstance.result.then(function () {
                modulesService.getCustomerAllowedModulesForCustomer(customer.id).then(mods => {
                    $scope.allowedModules = mods
                })
            }, function (er) {
                console.log(er)
            })
        }

        $scope.openCreateSupportUserDialog = function (customer) {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'supportUserDialog.html',
                controller: 'supportUserDialogController',
                resolve: {
                    customer: () => customer
                }
            })
            modalInstance.result.then(function (supportUser) {
                if(supportUser){
                    $scope.supportUser = supportUser
                }
            }, function (er) {
                console.log(er)
            })
        }
        
        $scope.openDeployDialog = function (customer) {
            if (customer.isDeployed)
                return
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'deployCustomerDialog.html',
                controller: 'deployCustomerDialogController',
                resolve: {
                    customer: () => customer,
                    languages: () => $scope.languages
                }
            })
            modalInstance.result.then(function (deployedCustomer) {
                if(deployedCustomer){
                    loadCustomers()
                    $scope.clearActiveCustomer()
                }
            }, function (er) {
                console.log(er)
            })
        }

        $scope.openDisableDialog = function(customer) {
            if (!customer || !customer.id)
                return
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'disableCustomerDialog.html',
                controller: 'disableCustomerDialogController',
                resolve: {
                    customer: () => customer
                }
            })
            modalInstance.result.then(function () {
                loadCustomers()
                $scope.clearActiveCustomer()
            }, function (er) { console.log(er) })
        }

        $scope.openCustomerOrderDialog = function(order){
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'customerOrderDialog.html',
                controller: 'customerOrderDialogController',
                resolve: {
                    order: () => order,
                    customerId: () => $scope.registration.id
                }
            })
            modalInstance.result.then(function (savedOrder) {
                if(savedOrder){
                    var updated = $filter('filter')($scope.orders, { id: savedOrder.id }, true)[0];
                    if(updated){
                        var index = $scope.order.indexOf(updated);
                        $scope.order[index] = savedOrder;
                    } else {
                        $scope.orders.unshift(savedOrder);
                    }
                }
            }, function (er) {
                console.log(er)
            }) 
        }

        $scope.updateStartDate = function($event) {
            const date = $event.originalEvent.detail
            $scope.date.startDate = date
        }
        $scope.updateEndDate = function($event) {
            const date = $event.originalEvent.detail
            $scope.date.endDate = date
        }
    }])