angular
    .module('DigiLean')
    .controller("projectGridController", ['$state', '$scope', 'employeeService', '$filter', 'authService', 'projectService', 'orderByFilter', '$uibModal',
        function ($state, $scope, employeeService, $filter, authService, projectService,orderByFilter, $uibModal) {

            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
            // if page is refreshed we need to refresh
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
            });
            $scope.otherprojects = [];

            function getStatusOrder(dbstatus) {
                switch (dbstatus) {
                    case "New":
                        return 1;
                    case "Plan":
                        return 2;
                    case "Do":
                        return 3;
                    case "Check":
                        return 4;
                    case "Act":
                        return 5;
                    case "Closed":
                        return 6;
                    default:
                        return 0;
                }
            }

            function getProjects() {
                employeeService().getUserPreference("ProjectFavorites").then(function (settings) {
                    if (settings && settings.value) {
                        angular.forEach(settings.value, function (board) {
                            if (board) {
                                board.isStar = true;
                                board.hasAccess = false;
                                $scope.quickAccess.push(board);
                            }
                        });
                    }
                    projectService().getMyProjectsInfo().then(function (projects) {
                        $scope.projects = projects;
                        for (var i = 0; i < $scope.projects.length; i++) {
                            checkMilestones($scope.projects[i]);
                            angular.forEach($scope.projects, function (project) {
                                var isFavorite = $filter('filter')($scope.quickAccess, { id: project.id })
                                if (isFavorite.length > 0) {
                                    project.isStar = true;
                                    isFavorite[0].asset = project.asset;
                                    isFavorite[0].name = project.name;
                                    isFavorite[0].hasAccess = true;
                                } else {
                                    project.isStar = false;
                                }
                            });
                        }

                        // Remove quick access board no longer available
                        var quickAccessBoardsToRemove = $filter('filter')($scope.quickAccess, { hasAccess: false });
                        if (quickAccessBoardsToRemove.length > 0) {
                            quickAccessBoardsToRemove.forEach(x => {
                                var index = $scope.quickAccess.indexOf(x);
                                $scope.quickAccess.splice(index, 1);
                            });
                            saveQuickAccess();
                        } else {
                            sortProjects();
                        }
                        
                        getOtherProjects();
                    });

                });

            }

            getProjects();



            $scope.addProject = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'projectNewModal.html',
                    controller: 'projectNewController',
                    resolve: {
                        existing: function () {
                            return $scope.projects;
                        }
                    }
                });

                modalInstance.result.then(function (newproject) {

                    if (newproject) {

                        projectService().addProject(newproject).then(function (createdProject) {

                            $state.go('selectedProject', { id: createdProject.id }, { reload: false });
                        });
                    }
                }, function () {
                });
            }

            function sortProjects() {
                $scope.projects = orderByFilter($scope.projects, 'isStar', true);
            }

            // Favoriotes / Quick access
            $scope.quickAccess = [];
            $scope.addToQuickAccess = function (project) {
                project.isStar = true;
                $scope.quickAccess.push(project);
                saveQuickAccess();
            }



            $scope.removeFromQuickAccess = function (project) {
                project.isStar = false;
                var isFavorite = $filter('filter')($scope.quickAccess, { id: project.id })
                if (isFavorite.length > 0) {
                    var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                    $scope.quickAccess.splice(currentIndex, 1);
                    saveQuickAccess();
                }
                var project = $filter('filter')($scope.projects, { id: project.id })
                if (project.length > 0) {
                    project[0].isStar = false;
                }
             
            }
            function saveQuickAccess() {

                employeeService().updateUserPreference("ProjectFavorites", $scope.quickAccess).then(function (settings) {
                    $scope.publish("ProjectFavoritesChanged", $scope.quickAccess);
                });
                sortProjects();
            }

            function getOtherProjects() {
                if ($scope.isAdmin) {
                    projectService().getProjectsInfo().then(function (projects) {
                        var allActiveProjects = projects;

                        for (var i = 0; i < allActiveProjects.length; i++) {
                            var inMyProjectList = $filter('filter')($scope.projects, { id: allActiveProjects[i].id }, true);
                            if (inMyProjectList.length == 0) {
                                checkMilestones(allActiveProjects[i]);
                                $scope.otherprojects.push(allActiveProjects[i]);
                            }
                        }
                    });

                }
            }





            function checkMilestones(project) {
                if (project.milestones && project.milestones.length > 0) {
                    project.milestoneMissed = false;
                    for (var i = 0; i < project.milestones.length; i++) {
                        if (project.milestones[i].actualDate) {
                            project.milestones[i].status = "completed";
                        } else if (project.milestones[i].plannedDate) {
                            //check if overdue/missed

                            var today = moment().startOf('day');
                            var target = moment(project.milestones[i].plannedDate).startOf('day');;
                            var days = today.diff(target, 'days');
                            //var duration = moment.duration(moment().diff(moment(project.milestones[i].plannedDate)));
                            //var days = duration.asDays().toFixed(0);  
                            if (days >= 1) {
                                project.milestones[i].status = "missed";
                                project.milestoneMissed = true;
                            } else {
                                project.milestones[i].status = "future";
                            }
                            addNextMilestone(project, project.milestones[i], days);
                        }
                    }
                }
            }

            function addNextMilestone(project, milestone, days) {

                if (project.nextMilestone) {
                    if (moment(project.nextMilestone.milestone.plannedDate).isAfter(moment(milestone.plannedDate))) {
                        project.nextMilestone = {
                            milestone: milestone,
                            days: Math.abs(days)
                        }
                    }
                } else {
                    project.nextMilestone = {
                        milestone: milestone,
                        days: Math.abs(days)
                    }
                }
            }
        }]);



