angular
    .module('DigiLean')
    .directive("timeboardPostits", ['$filter', '$translate', 'taskDrawingService', 'dragulaService',
        function ($filter, $translate, taskDrawingService, dragulaService) {
            return {
                templateUrl: 'timeboardPostits.html',
                restrict: 'E',
                // DO NOT USE SCOPE!! This gets scope from yearlyboard and must not be touched!!
                // scope: {

                // },
                link: function (scope, elem, attrs) {

                    // Note!! This share scioe wi
                    scope.$watch('settings', function (settings) {
                        if (settings && settings.PostIts) {
                            settings.PostIts.forEach(postit => {
                                // compensate for custom post-it missing fill color
                                if (postit.fillColor == "#ffffff" && postit.background != "#f1f1f1") {
                                    postit.fillColor = postit.background;
                                }
                            });
                            scope.postIts = settings.PostIts;
                            scope.postIts.forEach(color => {
                                color.isSelected = false;
                                if(color.tags && color.tags.length >0){
                                    color.tooltip = getTagsDescription(color);
                                } else {
                                    color.tooltip = "";
                                }
                            });
                        } else {
                            scope.postIts = taskDrawingService().getPostIts();
                        }
                    });

                    function getTagsDescription(postIt){
                        var tooltip = "";
                        if(postIt.tags){
                            for (var i = 0; i < postIt.tags.length; i++) {
                                if(i>0){
                                    tooltip = tooltip.concat(', ');
                                }
                                tooltip = tooltip.concat(postIt.tags[i]);
                            }
                        }
                        return tooltip;
                    }

                }
            }
        }]);
