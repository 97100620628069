angular
    .module('DigiLean')
    .directive("projectCustomerCell", ['$filter', '$translate','projectService','modalService','authService',
        function ($filter, $translate, projectService,modalService, authService) {
            return {
                templateUrl: 'projectCustomerCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.customers = null;
                    scope.customer = null;
                    scope.q = null;
                    
                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
                    // if page is refreshed we need to refresh
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
                    });

                    scope.$watch('model', function (projectModel) {
                        var value = scope.model.projectAttribute.value;
                        if (value) {
                            setValue(value);
                        }
                    });

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            
                            projectService().getProjectInfo(scope.model.project.id).then(function (projectInfo) { 
                                
                                if(projectInfo){
                                    scope.projectInfo = projectInfo;
                                    if(scope.projectInfo.projectCustomerId){
                                        setProjectCustomer();
                                    }
                                }
                            });
                        } 
                        model.getSortValue = function() {
                            if (scope.customer) {
                                return scope.customer.name;
                            }
                            return "";
                        }
                    });

                    scope.subscribe("ProjectCustomerUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            scope.customer = project.projectCustomer;
                        }
                    });

                    scope.subscribe("ProjectCustomerCreated", function (newCustomer) {
                        projectService().getProjectCustomers().then(function (customers) {
                            scope.customers = customers;    
                        });
                    });


                    var setValue = function (value) {
                        scope.customer = value;
                    } 


                    projectService().getProjectCustomers().then(function (customers) {
                        scope.customers = customers;
                        setProjectCustomer();    
                    });

                    scope.newCustomer = function (newname) {
                        $translate(['COMMON_CANCEL', 'COMMON_SUBMIT', 'ADMIN_NEW_CUSTOMER']).then(function (translations) {
                        
                        swal({
                            text: translations.ADMIN_NEW_CUSTOMER,
                            content: "input",
                            button: {
                              text: translations.COMMON_SUBMIT,
                              closeModal: true,
                              closeOnClickOutside: true,
                              closeOnEsc: true
                            },
                          }).then(name => {
                            if (!name) throw null;
                           
                            var registration = {
                                id: 0,
                                name: name
                            };
                            var matching = $filter('filter')(scope.customers, { name: name }, true);
                            if (matching.length > 0) {
                                scope.selectCustomer(matching[0]);
                                scope.q = null;
                            } else {
                                projectService().createNewCustomer(registration).then(function (customer) {
                                    $translate('ADMIN_MESSAGE_CUSTOMER_CREATED').then(function (msg) {
                                        toastr.success(msg);
                                        projectService().getProjectCustomers().then(function (customers) {
                                            scope.customers = customers;
                                            var matching = $filter('filter')(scope.customers, { id: customer.id }, true);
                                            if (matching.length > 0) {
                                                scope.selectCustomer(matching[0]);
                                                scope.q = null;
                                            }
                                        });
                                    }, function (translationId) {
                                        $scope.headline = translationId;
                                    });
                                });
                            }
                          })
                        }); 

                    };

                    /*
                    scope.newCustomer = function (newname) {
                        $translate(['COMMON_CANCEL', 'COMMON_SUBMIT', 'ADMIN_MESSAGE_NEW_CUSTOMER']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.COMMON_SUBMIT,
                                headerText: translations.COMMON_SUBMIT + ' ' + newname + '?',
                                bodyText: translations.ADMIN_MESSAGE_NEW_CUSTOMER
                            };
                
                            modalService.showModal({}, modalOptions).then(function (result) {
                                var registration = {
                                    id: 0,
                                    name: newname
                                };
                                projectService().createNewCustomer(registration).then(function (customer) {
                                    $translate('ADMIN_MESSAGE_CUSTOMER_CREATED').then(function (msg) {
                                        toastr.success(msg);
                                        projectService().getProjectCustomers().then(function (customers) {
                                            scope.customers = customers;
                                            var matching = $filter('filter')(scope.customers, { id: customer.id }, true);
                                            if (matching.length > 0) {
                                                scope.selectCustomer(matching[0]);
                                                scope.q = null;
                                            }
                                        });
                                    }, function (translationId) {
                                        $scope.headline = translationId;
                                    });
                                });
                            });
                
                        }); 
                    };*/
                    scope.selectCustomer = function (customer) {
                        scope.customer = customer;
                        projectService().updateCustomer(scope.model.project.id, customer.id).then(function (registeredcustomer) {
                        }); 
                    };

                    function setProjectCustomer(){
                        if (scope.projectInfo && scope.projectInfo.projectCustomerId && scope.customers) {
                            var matching = $filter('filter')(scope.customers, { id: scope.projectInfo.projectCustomerId }, true);
                            if (matching.length > 0) {
                                scope.customer = matching[0];
                            }    
                        }
                    
                    } 

                }
            }
        }]);
