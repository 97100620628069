angular
    .module('DigiLean')
    .component("simpleReply", {
        templateUrl: 'simpleReply.html',
        bindings: {
            reply: '<'
        },
        controller: function () {
            var $ctrl = this;
            
            $ctrl.$onInit = function() {
                console.log("simpleReply");
            };
        }
    });