import { ComponentSettings, DashboardPage, DashboardWidget, UserAccessLevel } from "@api"
import { DigiLeanNgScope } from "@common/model/angularModel"
import { ICompileService } from "angular"
import { GridItemHTMLElement, GridStack, GridStackNode, GridStackWidget } from "gridstack"
import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"
import { DashboardApp } from "@common/model/types"
import { createGuid } from "@common/services/helperFunctions"

import widgetTemplate from "./pageWidget.html"
import { isObjectEqual } from "@common/services/helperLib"

/**
 * Responsible for updating the grid UI, using gridstack as a framework
 * and the state of page widgets
 * 
 * docs: https://github.com/gridstack/gridstack.js/tree/master/doc
 */

interface GridItemScope extends DigiLeanNgScope {
    widget: DashboardWidget
}

export class PageService {
    private $pageContainerScope: DigiLeanNgScope
    private $compile: ICompileService
    private gridstack: GridStack

    // each visible widget $scope, used for calling $destroy when removed
    private pageWidgetScopes: GridItemScope[] = []

    // holder of state, shared by reference with flexiboardcontroller
    currentPage: DashboardPage | null = null

    constructor(scope: DigiLeanNgScope, compile: ICompileService, gs: GridStack) {
        this.$pageContainerScope = scope
        this.$compile = compile
        this.gridstack = gs
        this.setupEvents()
        this.pageWidgetScopes = []
    }

    setCurrentPage(page: DashboardPage) {
        this.currentPage = page
        this.refreshGridFromPageState()
    }

    /**
     * This is called when another user works with same page and and event is received
     */
    addWidgetFromOtherUser(widget: DashboardWidget) {
        this.addWidgetToPageStateOnly(widget)
        this.refreshGridFromPageState()
    }
    /**
     * This is called when another user deletes widget and event is received
     */
    removeWidgetFromOtherUser(uid: string) {
        this.removeWidgetFromStateOnly(uid)
        this.refreshGridFromPageState()
    }

    raiseWidgetSettingsChangedEvent(id: number, settings?: string | null) {
        // raise settings change event
        const settingsChanged: ComponentSettings = { id, settings }
        console.log("pageService: DashboardWidgetSettingsChanged publish", settingsChanged)
        this.$pageContainerScope.publish("DashboardWidgetSettingsChanged", settingsChanged)
    }

    // add new app from app store
    addApp(app: DashboardApp) {
        let sizeX = 12
        let sizeY = 12

        if (app.defaultXSize)
            sizeX = app.defaultXSize

        if (app.defaultYSize)
            sizeY = app.defaultYSize;

        if (!this.currentPage)
            throw new Error("No current page set")


        const newWidget: DashboardWidget = {
            id: 0,
            uId: createGuid(), // used for assigning and id client side to identify the widget
            header: app.name,
            component: app.component,
            sizeX: sizeX,
            sizeY: sizeY,
            dashboardPageId: this.currentPage.id
        }

        const canAdd = this.canAddNewWidget(newWidget)
        if (!canAdd)
            throw new Error("FLEXIBOARD_NO_SPACE_FOR_APP")


        const gridItem = this.addWidgetToGrid(newWidget)
        // get x,y that gridstack selected
        newWidget.col = gridItem.gridstackNode?.x
        newWidget.row = gridItem.gridstackNode?.y
        // save to backend
        dashboardService.addWidget(newWidget).then((savedWidget) => {
            newWidget.id = savedWidget.id // set db id, used for delete, clone etc
            this.addWidgetToPageStateOnly(newWidget)
        })
    }
    /**
     * This is called only internally when widget is already in Grid, used when app is added from app store
     */
    private addWidgetToPageStateOnly(widget: DashboardWidget) {
        if (!this.currentPage) return
        if (!this.currentPage.widgets) this.currentPage.widgets = []

        const exists = this.currentPage.widgets.find(w => w.uId == widget.uId)
        if (exists) {
            console.warn(`WARNING: Widget ${widget.uId} already existed in internal state. Code smell`)
            return
        }
        this.currentPage.widgets.push(widget)
    }

    canAddNewWidget(dashboardWidget: DashboardWidget) {
        const gridItem = convertWidgetToGridStack(dashboardWidget, true)
        const willFit = this.gridstack.willItFit(gridItem)
        return willFit
    }

    private loadManyWidgetsToGrid(widgets: DashboardWidget[]) {
        widgets.forEach(w => this.addWidgetToGrid(w, false))
    }

    addWidgetToGrid(widget: DashboardWidget, isNew?: boolean) {
        const widgetOptions = convertWidgetToGridStack(widget, isNew)

        let newScope = this.$pageContainerScope.$new() as GridItemScope
        newScope.widget = widget
        if (newScope.isDebugMode)
            newScope.widgetOptions = widgetOptions

        const compiled = this.$compile(widgetTemplate)(newScope) as JQuery

        const wrappedEl = createWrapperElements(compiled[0])
        const gridItem = this.gridstack.addWidget(wrappedEl, widgetOptions)

        this.pageWidgetScopes.push(newScope)
        return gridItem
    }

    findWidgetUid(uid: string) {
        const widget = this.currentPage?.widgets?.find(w => w.uId == uid)
        return widget
    }
    updateWidgetSettings(incomingComponentSettings: ComponentSettings) {
        if (!this.currentPage || !this.currentPage.widgets || !Array.isArray(this.currentPage.widgets))
            return

        const widgetToUpdate = this.currentPage.widgets.find(w => w.id == incomingComponentSettings.id)

        if (!widgetToUpdate)
            return

        if (!widgetToUpdate.settings && !incomingComponentSettings.settings)
            return

        const incomingSettings = JSON.parse(incomingComponentSettings.settings!)
        if (isObjectEqual(incomingSettings, widgetToUpdate.settings))
            console.log("settings equality = no changes")
        else {
            console.log("settings equality = changed")
            widgetToUpdate.settings = incomingSettings
        }
    }
    removeWidgetFromStateOnly(uid: string) {
        if (this.currentPage && this.currentPage.widgets) {
            const index = this.currentPage.widgets.findIndex(w => w.uId == uid)
            if (index > -1) {
                this.currentPage.widgets.splice(index, 1)
                this.refreshGridFromPageState()
            }
        }
    }

    async removeWidgetBackend(widget: DashboardWidget, deleteBoard: boolean) {
        await dashboardService.deleteWidget(widget.id)

        this.removeWidgetFromStateOnly(widget.uId)
        // All boards component also needs to be deleted? Esp smartactionlist
        if (deleteBoard || widget.component == 'a3table' || widget.component == "3ctable") {
            ///@ts-ignore
            const boardId = widget.settings.boardId
            boardService.deleteBoard(boardId)
        }
    }
    private removeWidgetFromGrid(uid: string) {
        const gridItems = this.gridstack.getGridItems()
        const gridItem = gridItems.find(g => g.gridstackNode?.id === uid)
        if (gridItem)
            this.gridstack.removeWidget(gridItem)
        
        const scope = this.pageWidgetScopes.find(s => s.widget.uId == uid)
        if (scope) {
            scope.$destroy() // important, will not happen by removing DOM!
            const index = this.pageWidgetScopes.indexOf(scope)
            this.pageWidgetScopes.splice(index, 1)
        }
    }
    // cleanup
    private removeAllWidgetsFromGrid() {
        this.gridstack.removeAll()
        this.pageWidgetScopes.forEach(scope => scope.$destroy())
        this.pageWidgetScopes = []
    }

    /**
     * When we know state has changed, check for updates and update grid view
     */
    refreshGridFromPageState() {
        if (!this.currentPage || !this.currentPage.widgets)
            return

        const widgetsNewState = this.currentPage.widgets
        const gridItems = this.gridstack.getGridItems()
        
        const currentWidgetIdsInGrid = gridItems.map(gi => gi.gridstackNode?.id!)

        this.gridstack.batchUpdate();

        const deletedWidgetIds = currentWidgetIdsInGrid.filter(id => !widgetsNewState.find(wNew => wNew.uId === id))
        if (deletedWidgetIds.length > 0) {
            deletedWidgetIds.forEach(id => {
                this.removeWidgetFromGrid(id)
            })
        }
        const addedWidgets = widgetsNewState.filter(w => !currentWidgetIdsInGrid.find(id => id === w.uId))
        if (addedWidgets.length > 0)
            this.loadManyWidgetsToGrid(addedWidgets)

        // this is needed when reload flexiboard occours
        const neitherAddedNorRemoved = widgetsNewState.filter(w => currentWidgetIdsInGrid.find(id => id === w.uId))
        neitherAddedNorRemoved.forEach(w => this.updateWidgetFromState(w))

        this.gridstack.batchUpdate(false);
    }
    private updateWidgetFromState(widget: DashboardWidget) {
        if (!widget)
            return

        const scope = this.pageWidgetScopes.find(ws => ws.widget.uId == widget.uId)
        if (scope)
            scope.widget = widget

        const gridItem = this.gridstack.getGridItems().find(g => g.gridstackNode?.id === widget.uId)

        if (!gridItem || !gridItem.gridstackNode)
            return

        const updateGs = convertWidgetToGridStack(widget) // to update positions and size
        this.gridstack.update(gridItem.gridstackNode.el!, updateGs)
    
    }
    setupEvents() {
        
        this.gridstack.on('resizestop', (event: Event, el: GridItemHTMLElement) => {
            if (el.gridstackNode)
              
                this.broadcastWidgetResizeEvent(el.gridstackNode)
        })

        this.gridstack.on('change', (e: Event, nodes: GridStackNode[]) => {
            if (!this.$pageContainerScope.isAdminMode) return;
            if (!nodes)
                return
            nodes.forEach(node => {
                if (!node.id)
                    return

                this.checkNodeUpdatesAndSave(node.id, node)
            })
        })
    }

    private checkNodeUpdatesAndSave(uid: string, node: GridStackNode) {
        const widget = this.findWidgetUid(uid)
        if (!widget)
            return
        if (widget.col != node.x || widget.row != node.y || widget.sizeX != node.w || widget.sizeY != node.h) {
            widget.col = node.x
            widget.row = node.y
            widget.sizeX = node.w!,
                widget.sizeY = node.h!
            dashboardService.updateWidget(widget)
        }
    }

    broadcastWidgetResizeEvent(node: GridStackNode) {
        const widget = this.findWidgetUid(node.id!)
        this.$pageContainerScope.$broadcast("widget-resized", widget)
    }

    getGridItem(uid: string) {
        const gridItems = this.gridstack.getGridItems()
        const gridItem = gridItems.find(g => g.gridstackNode?.id === uid)
        return gridItem
    }

    /**
     * Clone
     */

    async cloneWidget(uid: string) {

        const widget = this.findWidgetUid(uid)
        if (!widget)
            throw new Error("WIDGET_NOT_FOUND")

        if (!this.canAddNewWidget(widget))
            throw new Error("FLEXIBOARD_NO_SPACE_FOR_APP")

        // add blank widget first time, avoid all sort of things to happen before it's cloned in backend
        // but use it to get grid placement
        const placementWidget: DashboardWidget = {
            id: 0,
            uId: createGuid(),
            sizeX: widget.sizeX,
            sizeY: widget.sizeY,
            component: "blank"
        }
        const gridItem = this.addWidgetToGrid(placementWidget)
        // get x,y that gridstack selected
        placementWidget.col = gridItem.gridstackNode?.x
        placementWidget.row = gridItem.gridstackNode?.y
        
        // pass on the placement to copy method
        const savedWidget = await dashboardService.copyWidget(widget.id, placementWidget)
        if (savedWidget.settings && typeof savedWidget.settings === "string")
            savedWidget.settings = JSON.parse(savedWidget.settings)
        else
            savedWidget.settings = null

        // remove placement widget
        this.removeWidgetFromGrid(placementWidget.uId)
        // draw real widget with component updated settings
        this.addWidgetToGrid(savedWidget)
        
        this.addWidgetToPageStateOnly(savedWidget) // to keep in state when switching pages back and forth
    }
    changeWidget(uid: string, component: string, name: string) {
        const widget = this.findWidgetUid(uid)
        if (!widget)
            return
        widget.component = component
        widget.header = name
        
        widget.settings = null
        dashboardService.updateWidget(widget)
    }
    destroy() {
        console.log("pageService: Destroy")
        this.removeAllWidgetsFromGrid()
        ///@ts-ignore
        this.$pageContainerScope = null
        ///@ts-ignore
        this.$compile = null
        ///@ts-ignore
        this.gridstack = null
        ///@ts-ignore
        this.pageWidgetScopes = null
    }
}

export function convertWidgetToGridStack(dWidget: DashboardWidget, isNew?: boolean) {
    const widget: GridStackWidget = {
        w: dWidget.sizeX,
        h: dWidget.sizeY,
        id: dWidget.uId
    }
    if (!isNew) {
        widget.x = dWidget.col
        widget.y = dWidget.row
    }

    return widget
}

function createWrapperElements(widgetEl: HTMLElement) {
    const wrapper = document.createElement("div")
    wrapper.classList.add("grid-stack-item")
    wrapper.appendChild(widgetEl)
    return wrapper
}

export function getWidgetCordsFromElement(gridItem: HTMLElement) {
    let x = 0
    let y = 0
    let w = 0
    let h = 0
    const yStr = gridItem.getAttribute("gs-y")
    const xStr = gridItem.getAttribute("gs-x")
    const wStr = gridItem.getAttribute("gs-w")
    const hStr = gridItem.getAttribute("gs-h")
    try {
        if (yStr)
            y = parseInt(yStr)
        if (xStr)
            x = parseInt(xStr)
        if (wStr)
            w = parseInt(wStr)
        if (hStr)
            h = parseInt(hStr)
    }
    catch (err) {
        console.error(err)
    }

    return { x, y, w, h }
}
