var DigiLean = angular.module('DigiLean');
DigiLean.target = {

    getTargetIndicatorColor: function (targetindicator) {
        if(targetindicator == "green"){
            return "#5cb85c";
        } else if(targetindicator == "yellow"){
            return "#f8ac59";
        } else if(targetindicator == "red"){
            return "#ed5565";
        } else return "#676a6c";
    },

    getInternalTargetResultForPeriod: function (options, dataSource) { 
            
        var validTargetsInPeriod = dataSource.targets;

        if (validTargetsInPeriod == null || validTargetsInPeriod.length == 0) return;
        if (dataSource.unitOfTime == null) return;

        var fromdate = moment(options.timePeriod.from).toDate(); 
        var todate = moment(options.timePeriod.to).toDate(); 
        var greenvalue = 0;
        var redvalue = 0;
        if (dataSource.unitOfTime == "YEAR"){
            var totalNumOfYears = moment(todate).get('year') - moment(fromdate).get('year');//todate.Year - fromdate.Year;

            for (var m = 0; m < totalNumOfYears; m++){
                angular.forEach(validTargetsInPeriod, function (target) {
                    if (moment(target.validFrom).toDate() <= fromdate){
                        greenvalue = greenvalue + Number(target.redValue);
                        if (target.redValue != null){
                            redvalue = redvalue + Number(target.redValue);
                        }
                    }

                });
                fromdate = moment(fromdate).add(1, 'year');//fromdate.AddYears(1);
                var daysOfNewMonth = moment(fromdate).daysInMonth();//DateTime.DaysInMonth(fromdate.Year, fromdate.Month);
                fromdate = moment(fromdate).add(daysOfNewMonth + moment(fromdate).get('day'));//fromdate.AddDays(daysOfNewMonth + fromdate.Day);
            }
            if (dataSource.valueUnit == "%"){
                redvalue = redvalue / totalNumOfYears;
                greenvalue = greenvalue / totalNumOfYears;
                options.value = options.value / options.numberOfValues;
            }

        }
        if (dataSource.unitOfTime == "MONTH"){

            var totalNumOfMonths = (moment(todate).get('year') - moment(fromdate).get('year')) * 12 + moment.utc(todate).get('month') - moment.utc(fromdate).get('month');//(todate.Year - fromdate.Year) * 12 + todate.Month - fromdate.Month;

            for (var m = 0; m < totalNumOfMonths; m++){
                angular.forEach(validTargetsInPeriod, function (target) {
        
                    if (moment(target.validFrom).toDate() <= fromdate){
                        greenvalue = greenvalue + Number(target.greenValue);
                        if (target.redValue != null){
                        redvalue = redvalue + Number(target.redValue);
                        }
                    }

                });
                fromdate = moment(fromdate).add(1, 'month');//fromdate.AddMonths(1);
                var daysOfNewMonth = moment(fromdate).daysInMonth();//DateTime.DaysInMonth(fromdate.Year, fromdate.Month);
                if (moment(fromdate).get('day') < daysOfNewMonth){ // Handles e.g when february adds one but "truncates" days to 28. Must add missing days in next month
                    fromdate = moment(fromdate).add(daysOfNewMonth-moment(fromdate).get('day'));//fromdate.AddDays(daysOfNewMonth-fromdate.Day);
                }
            }
            if (dataSource.valueUnit == "%"){
                redvalue = redvalue / totalNumOfMonths;
                greenvalue = greenvalue / totalNumOfMonths;
                options.value = options.value / options.numberOfValues;
            }
        }
        if (scope.dataSource.unitOfTime == "WEEK") {

            var isoYearStartDate = moment.utc(fromdate).isoWeekYear();
            var isoYearEndDate = moment.utc(todate).isoWeekYear();
            var totalNumOfWeeks = 0;
            while(isoYearStartDate<isoYearEndDate){
                totalNumOfWeeks = totalNumOfWeeks + moment(new Date(isoYearStartDate,moment.utc(fromdate).get('month'),moment.utc(fromdate).get('day'))).isoWeek();
                isoYearStartDate = isoYearStartDate + 1;
            }    
            totalNumOfWeeks = totalNumOfWeeks + moment(new Date(isoYearStartDate,moment.utc(fromdate).get('month'),moment.utc(fromdate).get('day'))).isoWeek()- moment.utc(fromdate).isoWeek();

            for (var m = 0; m < totalNumOfWeeks; m++) {
                for (var t = validTargetsInPeriod.length; t > 0; t--) {
                    var target = validTargetsInPeriod[t - 1];
                    if (moment(target.validFrom).toDate() <= fromdate) {
                        greenvalue = greenvalue + Number(target.greenValue);
                        if (target.redValue != null) {
                            redvalue = redvalue + Number(target.redValue);
                        }
                        break;
                    }

                }
                fromdate = moment(fromdate).add(7, 'day');
                /*var daysOfNewMonth = moment(fromdate).daysInMonth();//DateTime.DaysInMonth(fromdate.Year, fromdate.Month);
                if (moment(fromdate).get('day') < daysOfNewMonth) { // Handles e.g when february adds one but "truncates" days to 28. Must add missing days in next month
                    fromdate = moment(fromdate).add(daysOfNewMonth - moment(fromdate).get('day'));//fromdate.AddDays(daysOfNewMonth-fromdate.Day);
                }*/
            }
            if (scope.dataSource.valueUnit == "%" || scope.formula == "average") {
                redvalue = redvalue / totalNumOfWeeks;
                greenvalue = greenvalue / totalNumOfWeeks;
                options.value = options.value / options.numberOfValues;
            }
        }
        if (dataSource.unitOfTime == "DAY"){
            var totalNumOfDays = 0;
            while (moment(options.timePeriod.to).toDate() >= fromdate){
                totalNumOfDays = totalNumOfDays + 1;
                angular.forEach(validTargetsInPeriod, function (target) {
                    if (moment(target.validFrom).toDate() <= fromdate){
                        greenvalue = greenvalue + Number(target.greenValue);
                        if (target.redValue != null){
                            redvalue = redvalue + Number(target.redValue);
                        }
                    }

                });
                fromdate = moment(fromdate).add(1, 'day'); //fromdate.AddDays(1);
            }
            if (dataSource.valueUnit == "%"){
                redvalue = redvalue / totalNumOfDays;
                greenvalue = greenvalue / totalNumOfDays;
                options.value = options.value / options.numberOfValues;
            }
        }

        var result = {
            greenOperator: validTargetsInPeriod[0].greenOperator,
            greenValue: greenvalue,
            redOperator: validTargetsInPeriod[0].redOperator,
            redValue: redvalue,
            targetIndicator: ""
        }

        if(options.value == null){
            result.targetIndicator = "";
        } else{
            result.targetIndicator = "yellow";

            if (validTargetsInPeriod[0].greenOperator == "<"){
                if (options.value < result.greenValue){
                    result.targetIndicator = "green";
                }
                if (validTargetsInPeriod[0].RedOperator == ">="){
                    if (options.value >= result.redValue){
                        result.targetIndicator = "red";
                    }
                }else if (validTargetsInPeriod[0].redOperator == ">"){
                    if (options.value > result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
            if (validTargetsInPeriod[0].greenOperator == "<="){
                if (options.value <= result.greenValue){
                    result.targetIndicator = "green";
                }
                if (validTargetsInPeriod[0].redOperator == ">="){
                    if (options.value >= result.redValue){
                        result.targetIndicator = "red";
                    }
                } else if (validTargetsInPeriod[0].redOperator == ">"){
                    if (options.value > result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
            if (validTargetsInPeriod[0].greenOperator == ">"){
                if (options.value > result.greenValue) {
                    result.targetIndicator = "green";
                }
                if (validTargetsInPeriod[0].redOperator == "<="){
                    if (options.value <= result.redValue){
                        result.targetIndicator = "red";
                    }
                } else if (validTargetsInPeriod[0].redOperator == "<"){
                    if (options.value < result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
            if (validTargetsInPeriod[0].greenOperator == ">="){
                if (options.value >= result.greenValue){
                    result.targetIndicator = "green";
                }
                if (validTargetsInPeriod[0].redOperator == "<="){
                    if (options.value <= result.redValue){
                        result.targetIndicator = "red";
                    }
                } else if (validTargetsInPeriod[0].redOperator == "<"){
                    if (options.value < result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
        }
        result.targetDescription = result.greenOperator + " " + result.greenValue.toFixed(options.decimals);
        return result;

    },
    getTargetResultByValueAndOperator: function(value, targetOperator, targetValue){
        var result = {
            targetIndicator: ""
        }
        if(value === null || targetValue === null || targetValue === "" || targetOperator == null){
            result.targetIndicator = "";
        } else{
            result.targetIndicator = "red";

            if (targetOperator == "<"){
                if (value < targetValue){
                    result.targetIndicator = "green";
                }
            }
            if (targetOperator == "<="){
                if (value <= targetValue){
                    result.targetIndicator = "green";
                }
            }
            if (targetOperator == ">"){
                if (value > targetValue) {
                    result.targetIndicator = "green";
                }
            }
            if (targetOperator == ">="){
                if (value >= targetValue){
                    result.targetIndicator = "green";
                }
            }
        }
        return result;
    },
    getTargetResultForUnitOfTime: function (options, targets) {           

        if (targets == null || targets.length == 0) return;
        if (options.unitOfTime == null) return;

        var fromdate = moment(options.timePeriod.from).toDate(); 
        var todate = moment(options.timePeriod.to).toDate(); 
        var greenvalue = 0;
        var redvalue = 0;
        var validTargetPeriod = false;
        if (options.unitOfTime == "YEAR"){
            fromdate = moment().year(options.value.year).month(0).date(1);  

            angular.forEach(targets, function (target) {
                if (moment(target.validFrom).toDate() <= fromdate){
                    validTargetPeriod = true;
                    greenvalue = greenvalue + Number(target.greenValue);
                    if (target.redValue != null){
                        redvalue = redvalue + Number(target.redValue);
                    }
                }

            });
            if (options.valueUnit == "%"){
                options.value = options.value / options.value.count;
            }

        }
        if (options.unitOfTime == "MONTH"){
            fromdate = moment().year(options.value.year).month(options.value.month-1).date(1);

                angular.forEach(targets, function (target) {
                    if (moment(target.validFrom).toDate() <= fromdate){
                        validTargetPeriod = true;
                        greenvalue = greenvalue + Number(target.greenValue);
                        if (target.redValue != null){
                        redvalue = redvalue + Number(target.redValue);
                        }
                    }
                });
 
            if (options.valueUnit == "%"){
                options.value.value = options.value.value / options.value.numberOfValues;
            }
        }
        if (options.unitOfTime == "WEEK"){
            //fromdate = moment(options.value.valueDate).toDate().startOf("isoweek");
            fromdate = moment().isoWeekYear(options.value.year).isoWeekday(1).isoWeek(options.value.week).utc().toDate();

                angular.forEach(targets, function (target) {
                    if (moment(target.validFrom).toDate() <= fromdate){
                        validTargetPeriod = true;
                        greenvalue = greenvalue + Number(target.greenValue);
                        if (target.redValue != null){
                        redvalue = redvalue + Number(target.redValue);
                        }
                    }
                });
 
            if (options.valueUnit == "%"){
                options.value.value = options.value.value / options.value.numberOfValues;
            }
        }
        if (options.unitOfTime == "DAY"){
            fromdate = moment(options.value.valueDate).toDate();
            angular.forEach(targets, function (target) {
                validTargetPeriod = true;
                if (moment(target.validFrom).toDate() <= fromdate){
                    greenvalue = greenvalue + Number(target.greenValue);
                    if (target.redValue != null){
                        redvalue = redvalue + Number(target.redValue);
                    }
                }
            });
            if (options.valueUnit == "%"){
                options.value.value = options.value.value / options.count;
            }
        }

        var result = {
            greenOperator: targets[0].greenOperator,
            greenValue: greenvalue,
            redOperator: targets[0].redOperator,
            redValue: redvalue,
            targetIndicator: ""
        }

        // No valid target for given period found
        if(!validTargetPeriod){
            return result;
        }    

        if(options.value == null){
            result.targetIndicator = "";
        } else{
            result.targetIndicator = "yellow";

            if (targets[0].greenOperator == "<"){
                if (options.value.value < result.greenValue){
                    result.targetIndicator = "green";
                }
                if (targets[0].redOperator == ">="){
                    if (options.value.value >= result.redValue){
                        result.targetIndicator = "red";
                    }
                }else if (targets[0].redOperator == ">"){
                    if (options.value.value > result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
            if (targets[0].greenOperator == "<="){
                if (options.value.value <= result.greenValue){
                    result.targetIndicator = "green";
                }
                if (targets[0].redOperator == ">="){
                    if (options.value.value >= result.redValue){
                        result.targetIndicator = "red";
                    }
                } else if (targets[0].redOperator == ">"){
                    if (options.value.value > result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
            if (targets[0].greenOperator == ">"){
                if (options.value.value > result.greenValue) {
                    result.targetIndicator = "green";
                }
                if (targets[0].redOperator == "<="){
                    if (options.value.value <= result.redValue){
                        result.targetIndicator = "red";
                    }
                } else if (targets[0].redOperator == "<"){
                    if (options.value.value < result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
            if (targets[0].greenOperator == ">="){
                if (options.value.value >= result.greenValue){
                    result.targetIndicator = "green";
                }
                if (targets[0].redOperator == "<="){
                    if (options.value.value <= result.redValue){
                        result.targetIndicator = "red";
                    }
                } else if (targets[0].redOperator == "<"){
                    if (options.value.value < result.redValue){
                        result.targetIndicator = "red";
                    }
                }
            }
        }
        result.targetDescription = result.greenOperator + " " + result.greenValue.toFixed(options.decimals);
        return result;

    }
}
