import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .controller("supportUserDialogController", ['$scope', '$uibModalInstance', 'customer',
        function ($scope, $uibModalInstance, customer) {

            if (!customer || !customer.id) {
                toastr.error("Missing customer Id", "Error", {timeOut: 5000})
                setTimeout(() => {
                    $uibModalInstance.dismiss('cancel')
                }, 500)
            }

            $scope.supportUser = {
                email: `${customer.name.toLowerCase()}@digilean.tools`,
                password: ""
            }

            $scope.createSupportUser = function () {
                customerService.createSupportUser(customer.id, $scope.supportUser).then(function (supportUser) {
                    $uibModalInstance.close(supportUser)
                }, function(error) {
                    if (error.data) {
                        if (error.data.title)
                            toastr.error(error.data.title, "Error", {timeOut: 15000})
                    }
                    toastr.error("something went wrong!")
                })
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel')
            }
        }])