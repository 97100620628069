﻿angular.module("DigiLean").factory('favoriteService', ['$http', 'webApiUrl',
function ($http, webApiUrl) {
    function favoriteService() {
        var resource = "/favorite";
        var resourceUrl = webApiUrl + resource;
        return {
            hasPageAsFavorite: function(pageId) {   
                var url = resourceUrl + "/hasPageAsFavorite/" + pageId;
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
            getMyFavoritePages: function() {   
                var url = resourceUrl + "/getMyFavoritePages";
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
            getPageFavoriteStatus: function(pageId) {   
                var url = resourceUrl + "/getPageFavoriteStatus/" + pageId;
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
            addPageAsFavorite: function (source, pageId) {
                var url = resourceUrl + "/addPageAsFavorite/" + source + "/"  + pageId;
                return $http.put(url).then(function (result) {
                    return result.data;
                });
            },
            removePageAsFavorite: function (source, pageId) {
                var url = resourceUrl + "/removePageAsFavorite/" + source + "/"  + pageId;
                return $http.delete(url).then(function (result) {
                    return result.data;
                });
            },
        }
    }
    return favoriteService;
}]);