angular
    .module('DigiLean')
    .directive("smartactionlistStartDateCell", ['boardTaskService',
        function (boardTaskService) {
            return {
                templateUrl: 'smartactionlistStartDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.dateMissed = false;
                    scope.date = null;
                    

                    scope.changeColor = false,
                    scope.changeColorXDaysBefore = 1;


                    scope.updateDate = function($event){
                        const newdate = $event.originalEvent.detail
                        
                        var endDate = moment(scope.model.task.boardDate).toDate()
 
                        var duration = {
                            startTime: newdate,
                            endTime: endDate
                        }
                        boardTaskService().updateDuration(scope.model.task.id, duration).then(function (updateTask) {

                        });
                    }                    

                    scope.$watch("model", function (model) {
                        if (scope.model) {
                            if(scope.model.task && scope.model.task.startTime){
                                scope.date = moment(scope.model.task.startTime).toDate();
                                
                                checkDate();
                            }
                            scope.datePicker = {
                                opened: false
                            }
                            var settings = model.column.settings;
                            if (settings) {
                                if(settings.changeColor && settings.changeColorXDaysBefore){
                                    scope.changeColor = true;
                                    scope.changeColorXDaysBefore = settings.changeColorXDaysBefore;
                                }
                            }
                        } 
                        model.getSortValue = function() {
                            return scope.date;
                        }
                    });
                    /*
                    scope.subscribe('TaskDateMoved', function (moveSettings) {
                        if (moveSettings.taskId == scope.model.task.id) {
                            scope.date = moment(moveSettings.boardDate).toDate();
                            checkDate();
                        }
                    });*/

                    scope.subscribe("SmartDateColorCellUpdated", function (value) {
                        if(scope.model.column.id === value.columnId){
                            scope.changeColor = value.changeColor;
                            scope.changeColorXDaysBefore = value.changeColorXDaysBefore;
                        }
                    });

                    scope.subscribe('TaskDurationUpdated', function (durationUpdated) {
                        if (durationUpdated.taskId == scope.model.task.id) {
                            scope.date = moment(durationUpdated.startTime).toDate();
                            checkDate();
                        }
                    });
                    function checkDate() {
                        scope.status = "";
                        if (scope.date) {

                            var duration = moment.duration(moment().endOf('day').diff(moment(scope.date).endOf('day')));
                            var days = duration.asDays();
                            if (days > 1) {
                                scope.status = "missed";
                                scope.diffToPlannedDate = Math.trunc(days);
                                scope.dateMissed = true;
                            } else {
                                scope.status = "future";
                                scope.diffToPlannedDate = Math.round(days)*-1;
                            }                      
                        }
                    }
                }
            }
        }]);

