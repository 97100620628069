angular
    .module('DigiLean')
    .directive("smartCommonTextCell", ['$filter', 'debounce', 'projectService',
        function ($filter, debounce, projectService) {
            return {
                templateUrl: 'smartCommonTextCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.text = "";
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    scope.usePredefinedLabels = false;
                    scope.labels = [];

                    var rowId = null;
                    var columnId = null;

                    scope.$watch('model', function (model) {
                        var value = null;
                        var settings = null;
                        
                        if (scope.type == 'project') {
                            columnId = model.boardAttributeId;
                            rowId = model.project.id;
                            value = model.projectAttribute.value;
                            settings = model.column.settings;              
                            
                        }  else if (scope.type == 'smarttable') {
                            columnId = model.cell.smartTableColumnId;
                            rowId = model.cell.smartTableRowId;
                            value = model.cell.value;
                            settings = model.column.settings;              
                        } else if (scope.type == 'a3') {
                            columnId = model.columnId;
                            rowId = model.a3.id;
                            if(model.a3TableCell){
                                value = model.a3TableCell.value;
                            }
                            settings = model.column.settings;              
                        }
                        if (value) {
                            setValue(value);
                        } 
                        if (settings) {
                            if(settings.usePredefinedLabels && settings.predefinedLabels){
                                scope.labels = settings.predefinedLabels;
                                scope.usePredefinedLabels = true;
                            }
                        }
                        model.getSortValue = function () {
                            if (scope.usePredefinedLabels && scope.labels) {
                                var order = scope.labels.length;
                                if(scope.selectedPostIt){
                                    var match = scope.labels.find(a => a.title == scope.text || a.text == scope.text)
                                    if (match) {
                                        order = scope.labels.indexOf(match);
                                    }
                                }
                                return order;
                            }
                            return scope.text;
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            $(".project-text-cell").focus();
                        }, 50 );
                    }
                    
                    var setValue = function(value) {
                        scope.background = value.background;
                        scope.text = value.text;
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }

                
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });

                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        }
                    });

                    scope.subscribe("SmartCommonTextCellLabelUpdated", function (value) {
                        if (value.type == scope.type && columnId === value.columnId) {
                            scope.labels = value.predefinedLabels;
                            scope.usePredefinedLabels = value.usePredefinedLabels;
                            if(scope.usePredefinedLabels){
                                scope.labels = value.predefinedLabels;
                            }
                        }
                    });
                    

                    scope.selectTextAndColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.text = "";
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  postIt.color;    
                        scope.text = postIt.title;
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var newvalue = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text
                        }
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = newvalue;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'smarttable') {
                            scope.model.cell.value = newvalue;
                            scope.changeHandler({cell: scope.model.cell})
                        }else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = newvalue;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: newvalue
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
