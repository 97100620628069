﻿import * as dashboardService from "@common/services/dashboardService"

const DigiLean = angular.module('DigiLean');
DigiLean.controller('newFlexiboardTemplateController', ['$scope', '$uibModalInstance', 'options',
    function ($scope, $uibModalInstance, options) {
        $scope.title = options.title;
        $scope.description = options.description;

        $scope.includeTasks = false;
        $scope.isProcessing = false;

        $scope.ok = function () {
            $scope.isProcessing = true;
            var templateOptions = {
                title: $scope.title,
                description: $scope.description,
            };
            dashboardService.createTemplate(options.id, templateOptions).then(function (template) {
                $scope.isProcessing = false;
                $scope.publish("SendBoardTemplateCreated", template);
                $uibModalInstance.close(template);
            });
        };

        $scope.toggleIncludeTasks = function (include) {
            $scope.includeTasks = include;
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);