angular
    .module('DigiLean')
    .controller("projectDeleteController", ['$scope', '$uibModalInstance', 'projectService', 'board', 'projectInfo',
        function ($scope, $uibModalInstance,  projectService, board, projectInfo) {

            $scope.project = projectInfo.project;
            $scope.board = board;
            var projectBoardId = projectInfo.projectBoardId;
            
            $scope.permanentDelete = function () {
                projectService().deleteProject($scope.project.id).then(function (deletedProject) {
                    $uibModalInstance.close(deletedProject);
                });
            };

            $scope.removeProjectFromBoard = function () {
                projectService().removeProjectFromBoard($scope.project.id, $scope.board.id, projectBoardId).then(function (deletedProject) {
                    $uibModalInstance.close(deletedProject);
                });
            };

            

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);