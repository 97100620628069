angular
    .module('DigiLean')
    .controller("a3slideshowController", ['$scope', '$filter', '$uibModal', '$state', '$stateParams', 'a3Service', 'pdfService',
        function ($scope, $filter, $uibModal, $state, $stateParams, a3Service, pdfService) {

            $scope.a3Id = parseInt($stateParams.id);
            $scope.a3 = {};
            $scope.versionNumber = parseInt($stateParams.version);
            $scope.canEdit = false;
            $scope.version = {};
           
            $scope.widgets = [];
            setTimeout(function() {
                // Reveal.initialize();
            }, 200);
    
            a3Service().get($scope.a3Id).then(function (a3) {
                $scope.a3 = a3;
                $scope.version = $filter('filter')(a3.versions, { version: $scope.versionNumber })[0];
                a3Service().getVersion($scope.version.id).then(function (version) {
                    if (version.layout.length === 0) {
                        //defaultLayout(a3);
                        $scope.save();
                        return;
                    }
                    angular.forEach(version.layout, function (widget, key) {
                        // TODO need to resolve the correct step, maybe som database changes too....
                        switch (widget.component) {
                            case "a3info":
                                widget.model = a3;
                                widget.model.versionNumber = $scope.versionNumber;
                                break;
                            case "a3step":
                                widget.model = getStepForId(widget.a3StepId);
                                break;
                            case "a3step-action-plan":
                                var step = getStepForId(widget.a3StepId);
                                var actionPlan = {
                                    title: step.title,
                                    a3: a3,
                                    step: getStepForId(widget.a3StepId)
                                }
                                widget.model = actionPlan;
                                break;
                        }
                        $scope.widgets.push(widget);
                    });
                });
            });

            function getStepForId(stepid) {
                var step = $filter('filter')($scope.version.steps, { id: stepid })[0];
                return step;
            }

            function getStepWidget(component, model, position) {
                model.versionNumber = $scope.versionNumber;
                var widget = {
                    model: model,
                    header: model.title,
                    component: component,
                    row: position[0],
                    col: position[1],
                    sizeX: position[2],
                    sizeY: position[3],
                    a3StepId: model.id
                }
                if (component === "a3info") { // a3info is not linked to step so we need to set it to null
                    widget.a3StepId = null;
                }
                return widget;
            }

        }]);

