var DigiLean = angular.module('DigiLean');
DigiLean.directive("newDeviationButton", ['$filter','$translate', 'deviationService','navigationService','$uibModal',
        function ($filter,$translate, deviationService, navigationService, $uibModal) {
            return {
                templateUrl: 'newDeviationButton.html',
                restrict: 'E',
                scope: {
                    'color': '@',
                },
                link: function (scope, elem, attrs) {
                    //scope.buttonColor = "blue";

       
                    scope.$watch("color", function () {
                        if (!scope.color) return;
                        loadSettings();
                    });
                    
                    function loadSettings(){
                        deviationService().getDeviationTypes().then(function (types) {
                            scope.types = types;
                        });
                    }

                    scope.createNew = function (typeId) {
                        if(typeId){
                            var settings = {deviationTypeId:typeId};
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'newDeviation.html',
                                controller: 'newDeviationController',
                                windowClass: 'newdeviation-modal-window',
                                resolve: {
                                    settings: function () {
                                        return settings;
                                    }
                                }
                            });
                        }
                        
                    }

                }

            }
        }]);
