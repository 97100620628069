var DigiLean = angular.module('DigiLean');  
DigiLean.controller('myRegisteredIncidentsController', ['$scope','$filter', '$uibModal', 'deviationService',
    function ($scope, $filter, $uibModal, deviationService) {
        $scope.items = [];
        $scope.resolved = 0;

        $scope.translationData = {
            noOfIncidents: $scope.items.length
        };

        $scope.subscribe("DeviationChangedStatus", function (changed) {
            // Check if comment is related to one of users
            var item = $filter('filter')($scope.items, { id: changed.deviationId }, true);
            if (item.length > 0) {
                if(item[0].status != changed.status){
                    if(changed.status == 20){
                        $scope.resolved++;
                    } else if (item[0].status == 20 && changed.status != 20){
                        $scope.resolved--;
                    }
                }
                item[0].status = changed.status
                item[0].changed = true;
            }
        });
        $scope.subscribe("DeviationCreated", function (item) {
            item.changed = true;
            // Make sure it's created by current user
            if (userid === item.createdByUserId) {
                getDeviations();
            }
        });
        $scope.subscribe("DeviationDeleted", function (deviation) {
            // find by id
            var deletedid = deviation.id;
            var existingDeviations = $filter('filter')($scope.items, { id: deletedid }, true);
            if (existingDeviations.length === 1) {
                var index = $scope.items.indexOf(existingDeviations[0]);
                $scope.items.splice(index, 1);
            }
        });

 
        function getDeviations() {
            $scope.isLoading = true;
            deviationService().getMyDeviations().then(function (items) {
                $scope.items = items;
                $scope.translationData = {
                    noOfIncidents: $scope.items.length
                };
                items.forEach(function (deviation) {
                    
                    if(deviation.status == 20){
                        $scope.resolved = $scope.resolved + 1;
                    } 
                });
                $scope.isLoading = false;
            });
        }
        getDeviations();

        $scope.openDeviations = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'myRegisteredIncidentsForm.html',
                    controller: 'myDeviationsController',
                    windowClass: 'fullDeviation-modal-window',
                    resolve: {
                    }
                });
        }

    }]);



