import * as assetService from "@common/services/assetService"
import * as dashboardService from "@common/services/dashboardService"

angular
    .module('DigiLean')
    .directive("strategyMapChartApp", ['$translate', 'authService',
        function ($translate, authService) {
            return {
                templateUrl: 'strategyMapChartApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.identificationName = $translate.instant('STRATEGY_TOP_LEVEL');

                    scope.theme = {
                        fillColor: "#ffffff",
                        background: "#676a6c"
                    };
                    scope.postIts = dashboardService.getThemes();

                    scope.assetId =null;

                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                    // if page is refreshed we need to refresh
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                    });

                    scope.loadAssets = function(){
                        if(!scope.assets){        
                            scope.selectAsset = null;
                            if(scope.isAdmin){
                                assetService.getAllAssets(true).then(function (assets) {
                                    scope.assets = assets;    
                                    for (var k = 0; k < scope.assets.length; k++) {
                                        scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                                    } 
                                    //scope.assetId = null;
                                });
                            } else {
                                assetService.getList().then(function (assets) {
                                    scope.assets = assets;  
                                    if(scope.assets.length>0){
                                        if(!scope.assetId){
                                            scope.loadStrategyMapByAsset(scope.assets[0]);
                                        }
                                    }
                                    for (var k = 0; k < scope.assets.length; k++) {
                                        scope.assets[k].cssLevel = "level-" + scope.assets[k].level; 
                                                        
                                    }    
                                });
                            }
                        }
                    }

                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        checkSettings();
                        setTargetHeight();
                    });

                    scope.toplevel = function (){             
                        scope.assetId = null;
                        scope.identificationName = $translate.instant('STRATEGY_TOP_LEVEL');
                        scope.updateSettings();
                        scope.initialize();
                    };

                    scope.loadStrategyMapByAsset = function (asset){
                        scope.selectAsset = asset;
                        scope.assetId = asset.id;
                        scope.identificationName = asset.name;
                        scope.updateSettings();
                    };
                    
                    function checkSettings() {
                        if (scope.settings) {
                            if (scope.settings.assetId) {
                                scope.assetId = scope.settings.assetId;
                            }
                            if (scope.settings.identificationName) {
                                scope.identificationName = scope.settings.identificationName;
                            }
                            if (scope.settings.theme) {
                                scope.theme = scope.settings.theme;
                            }
                            if (scope.settings.title) {
                                scope.title = scope.settings.title;
                            } else {
                                $translate(['COMMON_EDIT_TITLE']).then(function (translations) {
                                    scope.title = translations.COMMON_EDIT_TITLE;
                                });
                            }
                        }                  
                    }

                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }

                    scope.updateSettings = function(){
                        var componentSettings = {
                            title: scope.title,
                            assetId: scope.assetId,
                            identificationName: scope.identificationName,
                            theme: scope.theme
                        };

                        scope.settingsChangeHandler({ settings: componentSettings });
                    };    

                    var setTargetHeight = function() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();
                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 60;
                        var container = $(elem).find(".ibox-content");
                        if (container.length === 0) return;
                        container.height(height - headerHeight);
                        scope.targetHeight = (height - headerHeight);
                    }
                    
                    scope.$on("widget-resized", function (event, args) {
                       setTargetHeight();
                    });
                }
            }
        }]);
