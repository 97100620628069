import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("personalboardFilter", ['$filter', '$translate', 'boardTaskService',
        function ($filter, $translate, boardTaskService) {
            return {
                templateUrl: 'personalboardFilter.html',
                restrict: 'E',
                scope: {
                    'new': '=',
                    'inprogress': '=',
                    'implemented': '=',
                    'waiting': '=',
                    'timePeriod': '='
                },
                link: function (scope, elem, attrs) {
                    scope.isFilterActive = false;
                    scope.boards = [];

                    scope.$watch('timePeriod', function (value) {
                        /*Checking if the given value is not undefined*/
                        if (value) {
                            getAllData();
                        }
                    });

                    function getDescription(item){
                        if(item.boardId){
                            //var board = $filter('filter')(scope.boards, { id: item.boardId }, true)[0];
                            if(item.board){
                                return item.board.name;
                            } else return ""; 
                        } else if (item.a3Id){
                            return "A3";
                        } else if (item.deviationId){
                            return $translate.instant('COMMON_DEVIATION');
                        }else if (item.improvementSuggestionId){
                            return $translate.instant('COMMON_SUGGESTION');
                        } else if (item.taskType == 'SubTask'){
                            return $translate.instant('COMMON_SUBTASK');
                        } else return $translate.instant('DASHBOARD_PERSONAL');
                        
                    }

                    function getData(status) {
                        boardTaskService().getMyTasksByStatus(status.status, scope.timePeriod).then(function (result) {
                            if(result){
                                for (var s = 0; s < result.length; s++) {
                                    result[s].dateStyleClass = getDateStyle(result[s].boardDate);
                                    //result[s].board = $filter('filter')(scope.boards, { id: result[s].boardId }, true)[0];
                                    result[s].contentDescription = getDescription(result[s]);
                                    loadImage(result[s]);
                                }

                                status.list = result;
                                status.filteredList = result;
                                if (scope.isFilterActive) {
                                    filterTasks();
                                }
                            }
                        });
                    }


                    function getAllData() {
                        if(scope.boards.length == 0){
                            boardService.getMemberBoards().then(function (data) {
                                scope.boards = data;
                                resetBoardsFilter();
                                getData(scope.new);
                                getData(scope.inprogress);
                                getData(scope.implemented);
                                getData(scope.waiting);
                            });
                        } else {
                            getData(scope.new);
                            getData(scope.inprogress);
                            getData(scope.implemented);
                            getData(scope.waiting);
                        }
                    }

                   // getAllData();

                   function loadImage(task){
                        if (task.attachmentCount > 0) {
                            boardTaskService().getAttachments(task.id).then(function (attachments) {
                                task.attachments = attachments;
                            });
                        }
                    }


                    function getDateStyle(date) {
                        if (!date) return "";
                    
                        var iscurrentDate = moment(date).isSame(new Date(), "day");
                        if(iscurrentDate)
                        {
                            return "today";
                        } else {  
                            var overdue = "";          
                            var duration = moment.duration(moment().diff(moment(date)));
                            var days = duration.asDays();
                            if (days > 1) {
                                overdue = "overdue";
                            }
                            return overdue;
                        }
                    }


                    scope.statuses = [scope.new, scope.inprogress, scope.implemented, scope.waiting];
                    scope.hideStatus = function (status) {
                        status.hide = !status.hide;
                    };
                    scope.priorityOptions = [
                        {
                            priorityDB: -10,
                            translated: $translate.instant('COMMON_LOW'),
                            cssClass: "digilean-label-historical",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 0,
                            translated: $translate.instant('COMMON_MEDIUM'),
                            cssClass: "btn-default",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 10,
                            translated: $translate.instant('COMMON_HIGH'),
                            cssClass: "btn-danger",
                            filteredAndInResultList: false
                        }
                    ];

                    function loadBoards() {
                        boardService.getMemberBoards().then(function (data) {
                            scope.boards = data;
                            resetBoardsFilter();
                        });
                    }

                    function resetBoardsFilter() {
                        angular.forEach(scope.boards, function (board) {
                            board.filteredAndInResultList = false;
                        });
                    }


                    function resetPriorityFilter() {
                        angular.forEach(scope.priorityOptions, function (item) {
                            item.filteredAndInResultList = false;
                        });
                    }
                    scope.filterPriority = function (priority) {
                        priority.filteredAndInResultList = !priority.filteredAndInResultList;
                        filterTasks();
                    }

                    scope.filterBoard = function (board) {
                        board.filteredAndInResultList = !board.filteredAndInResultList;
                        filterTasks();
                    }

                    function resetStatusFilter() {
                        angular.forEach(scope.statuses, function (status) {
                            status.hide = false;
                        });
                    }
                    scope.removeFilter = function () {
                        resetStatusFilter();
                        resetPriorityFilter();
                        resetBoardsFilter();
                        filterTasks();
                    };

                    function filterTasks() {
                        scope.new.filteredList = scope.new.list;
                        scope.inprogress.filteredList = scope.inprogress.list;
                        scope.implemented.filteredList = scope.implemented.list;
                        scope.waiting.filteredList = scope.waiting.list;

                        var boardsToShow = $filter('filter')(scope.boards, { filteredAndInResultList: true }, true);
                        if (boardsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByBoards(scope.new.filteredList, boardsToShow);
                            scope.inprogress.filteredList = getFilteredListByBoards(scope.inprogress.filteredList, boardsToShow);
                            scope.implemented.filteredList = getFilteredListByBoards(scope.implemented.filteredList, boardsToShow);
                            scope.waiting.filteredList = getFilteredListByBoards(scope.waiting.filteredList, boardsToShow);
                        }

                        var activePriorityFilter = priorityFilterActive();
                        if (activePriorityFilter.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.waiting.filteredList = $filter('filter')(scope.waiting.filteredList, valueInList('priorityStatus', activePriorityFilter));
                        }


                    }
                    function valueInList(property, list) {
                        return function (item) {
                            for (var i = 0; i < list.length; i++) {
                                if (item[property] === list[i]) return true;
                            }
                            return false;
                        }
                    }
                    function hasValuesInListFilter(property) {
                        return function (item) {
                            if (item[property] && item[property].length > 0) return true;
                            return false;
                        }
                    }
                    function getFilteredListByBoards(list, boards) {
                        if (boards.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var task = list[i];
                            for (var j = 0; j < boards.length; j++) {
                                var board = boards[j];
                                if (task.boardId === board.id) {
                                    filteredList.push(task);
                                }
                            }
                        }
                        return filteredList;
                    }

                    function priorityFilterActive() {
                        var filteredPriority = [];
                        angular.forEach(scope.priorityOptions, function (priority) {
                            if (priority.filteredAndInResultList) {
                                filteredPriority.push(priority.priorityDB);
                            }
                        });
                        return filteredPriority;
                    }
                }
            }
        }]);
