import swal from 'sweetalert';
angular.module("DigiLean").service('errorHandling', ['$translate',
    function($translate) {

        let noAccessLabel = "No access"

        this.handleForbidden = function(rejection) {
            let url = ""
            noAccessLabel = $translate.instant('COMMON_NO_ACCESS')
            if (rejection.config && rejection.config.url)
                url = rejection.config.url
            if (url.includes("board"))
                alertNoAccess()
            else
                toastr.error(noAccessLabel);
        };

        function alertNoAccess() {
            swal({
                title: noAccessLabel,
                text: $translate.instant('COMMON_NO_ACCESS_BOARD'),
                icon: "warning",
                dangerMode: true,
                closeOnClickOutside: false,
                closeOnEsc: false
            })
        }
    }]);