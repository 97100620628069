angular
    .module('DigiLean')
    .directive("assetCell", ['$filter', '$translate', 'strategyGoalService',
        function ($filter, $translate, strategyGoalService) {
            return {
                templateUrl: 'assetCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                    'assets': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.name = "";
                    scope.asset = null;

                    
                    var map = function() {
                        if (scope.model && scope.model.goal.assetId && scope.assets) {
                            var matching = $filter('filter')(scope.assets, { id: scope.model.goal.assetId }, true);
                            if (matching.length > 0) {
                                scope.name = matching[0].name;
                                scope.asset = matching[0];
                            }
                        }
                    }
                    scope.$watch("assets", function (assets) {
                        if (!assets) return;
                        map();
                    });
                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.goal) {
                            if (scope.model.goal.assetId) {
                                map();
                            }
                        }
                        if(scope.model){
                            model.getSortValue = function () {
                                if (scope.name) {
                                    return scope.name;
                                }
                                return "";
                            }
                        }
                    });

                    scope.subscribe("StrategyGoalAssetUpdated", function (goalInfo) {
                        if (goalInfo.strategyGoalId == scope.model.goal.id) {
                            scope.model.goal.assetId = goalInfo.assetId;
                            map();
                        }
                    });
                    scope.clearAsset = function () {
                        var emptyAsset = {
                            id : "",
                            name: $translate.instant('STRATEGY_TOP_LEVEL')
                        }
                        scope.asset = null;
                        scope.selectAsset(emptyAsset);
                    };
                    scope.selectAsset = function (asset) {
                        scope.asset = asset;
                        scope.name = asset.name;
                        strategyGoalService().updateAsset(scope.model.goal.id, asset.id).then(function () {
                        });
                    };
                }
            }
        }]);