// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('teamTaskConfiguration', ['$scope', '$filter', '$uibModalInstance', '$translate', 'daysBetweenMeetings',
function ($scope, $filter, $uibModalInstance, $translate, daysBetweenMeetings) {
    $scope.daysBetweenMeetings = daysBetweenMeetings;

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = function () {
        $uibModalInstance.close($scope.daysBetweenMeetings);
    };
}]);