// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('dataseriesselectorModalController', ['$scope', '$uibModalInstance', 'layoutService', 'dataSeries',
    function ($scope, $uibModalInstance, layoutService, dataSeries) {

        $scope.grafcolor = layoutService().getColors();
        $scope.dataSeries = dataSeries;
        $scope.selectedDataSource = null;

        $scope.selectedSerie = null;
        $scope.edit = false;

        $scope.selectedIndex = -1; // Whatever the default selected index is, use -1 for no selection

        $scope.selectedManualValue = null;
        $scope.selectedName = "";
        $scope.selectedManualType = "integer";
        $scope.editManual = false;


        $scope.opened = [];

        $scope.changeDate = function(selectedSerie, $event) {
            const date = $event.originalEvent.detail
            selectedSerie.datavalue = date
        }

        $scope.itemClicked = function ($index) {
            $scope.selectedIndex = $index;
        };

        function setPreSelectedGraphColor() {
            $scope.selectedGraphColor = $scope.grafcolor[0];
            if (!$scope.dataSeries) {
                $scope.dataSeries = [];
            } else if ($scope.dataSeries.length > 0) {
                $scope.selectedGraphColor = $scope.grafcolor[$scope.dataSeries.length];
            }
        }
        setPreSelectedGraphColor();

        $scope.resetRegistration = function () {
            setPreSelectedGraphColor();
            $scope.selectedDataSource = null;

            $scope.selectedSerie = {
                datasource: null,
                name: "",
                graphtype: "column",
                width: 0,
                graphcolor: $scope.selectedGraphColor,
                accumulated: false,
                filters: []                
            }     

            $scope.selectedIndex = -1;

            //Manual serie variables
            $scope.selectedManualValue = null;
            $scope.selectedName = "";
            $scope.selectedManualType = "integer";

        }
        $scope.resetRegistration();

        $scope.sortableOptions = {
                clone: false,
                itemMoved: function (event) {
                }
            };

        $scope.newSerie = function () {
            $scope.edit = true;
            $scope.editManual = false;
            setPreSelectedGraphColor();
            $scope.selectedDataSource = null;

            $scope.selectedSerie = {
                datasource: null,
                name: "",
                graphtype: "column",
                graphcolor: $scope.selectedGraphColor,
                accumulated: false,
                filters: []                
            }
           
            $scope.dataSeries.unshift($scope.selectedSerie);
            
            $scope.selectedIndex = 0; //first element in "Chosen dataseries"

        }        

        $scope.modifyManualDatatype = function (serie){
            if (serie.datatype === "date") {
                serie.axis = "x";
                serie.graphtype = "shortdash-x";
            } else {
                serie.axis = "y";
                serie.graphtype = "shortdash-y";
            }
        }

        $scope.addManualSerie = function () {
            $scope.editManual = true;
            $scope.edit = false;
            var datavalue = $scope.selectedManualValue;
            var name = $scope.selectedName;
            var graphtype = "shortdash-y";
            var graphcolor = $scope.selectedGraphColor;
            var axis = "y";
            if ($scope.selectedManualType === "date") {
                axis = "x";
                graphtype = "shortdash-x";
            }

            $scope.selectedSerie = {
                manual: true,
                datatype: $scope.selectedManualType,
                datavalue: datavalue,
                axis: axis,
                name: name,
                graphtype: graphtype,
                graphcolor: graphcolor,
                accumulated: $scope.accumulated
            }
            $scope.dataSeries.unshift($scope.selectedSerie);
            
            $scope.selectedIndex = 0; //first element in "Chosen dataseries"
        };

        $scope.deleteSerie = function (serie) {
            var index = $scope.dataSeries.indexOf(serie);
            if (index > -1) {
                $scope.dataSeries.splice(index, 1);
                if(serie === $scope.selectedSerie){
                    $scope.edit = false;
                    $scope.editManual = false;
                    $scope.selectedIndex = -1;
                }
            }
        }

        $scope.modifySelectedSerie = function (serie) {
            if(serie.manual){
                $scope.editManual = true;
                $scope.edit = false;
            } else {
                $scope.edit = true;
                $scope.editManual = false;
            }            
            $scope.selectedSerie = serie;
        }

        $scope.ok = function () {
            $uibModalInstance.close($scope.dataSeries);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);