var DigiLean = angular.module('DigiLean');
DigiLean.controller('predefinedLabels', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'labels','projectService',
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, labels, projectService) {
        $scope.defaultLabels = projectService().getPostIts();
        $scope.labels = labels;
        if(!$scope.labels){
            $scope.labels = $scope.defaultLabels;               
            }

        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('DASHBOARD_DATA_SOURCE');
            $scope.steps[1].title = $translate.instant('COMMON_FILTER');
            $scope.currentStep = $scope.steps[0];
        });

        
        $scope.createNew = function () {
            var newLabel = {
                title: "",
                fillColor: "#ffffff",
                background: "#E6E9ED",
                color: "#ffffff"
            }
            $scope.labels.push(newLabel);
        };

        $scope.delete = function (label) {
            var index = $scope.labels.indexOf(label);
            $scope.labels.splice(index, 1);    
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            $uibModalInstance.close($scope.labels);
        };

    }]);