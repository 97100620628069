﻿
angular.module('DigiLean')
    .component("imagefileinfo", {
        templateUrl: 'imagefileinfo.html',
        bindings: {
            'file': '<',
        },
        controller: ['$element', 'documentService', 'Lightbox', 
            function ($element, documentService, Lightbox) {
                const $ctrl = this;
                let observer;
                
                const handleIntersect = (entries, observer) => {
                    for (const entry of entries) {
                        if (entry.isIntersecting) {
                            applyFile($ctrl.file);
                            observer.unobserve(entry.target);
                        }
                    }
                };
                $ctrl.$onInit = function() {
                    observer = new IntersectionObserver(handleIntersect);
                    observer.observe($element[0]);
                };
                
                var viewerFileExtensions = [
                    { type: 'image', extension: '.jpg' },
                    { type: 'image', extension: '.jpeg' },
                    { type: 'image', extension: '.jfif' },
                    { type: 'image', extension: '.png' },
                    { type: 'pdf', extension: '.pdf' },
                    { type: 'excel', extension: '.xlsx' },
                    { type: 'excel', extension: '.xls' },
                    { type: 'word', extension: '.doc' },
                    { type: 'word', extension: '.docx' },
                    { type: 'powerpoint', extension: '.ppt' },
                    { type: 'powerpoint', extension: '.pptx' },
                ];

                function applyFile(file) {
                    if (!file)
                        return;

                    file.validFileUrl = "https://digileanfiles.blob.core.windows.net/public-assets/images/Loading_icon.gif" // Default loading image, will be replaced with actual image

                    const fileType = viewerFileExtensions.find(ve => ve.extension == file.fileExtension.toLowerCase())

                    if (fileType && fileType.type == "image") {
                        file.fileType = fileType.type
                        documentService.getFileUrl("tn_" + file.fileName, file.id).then(function (result) {
                            if (result) {
                                file.fileUrl = result;
                                file.validFileUrl = result;
                            } else { // Doesn't have thumbnail so try to load original
                                documentService.getFileUrl(file.fileName, file.id).then(function (result) {
                                    file.fileUrl = result;
                                    file.validFileUrl = result;
                                });
                            }
                        });
                    }

                }

                $ctrl.openLightboxModal = function () {
                    documentService.getFileUrl($ctrl.file.fileName, $ctrl.file.id).then(function (result) {
                        var images = [
                            {
                                'url': result,
                                'thumbUrl': $ctrl.file.validFileUrl
                            }
                        ];
                        Lightbox.openModal(images, 0);
                    });
                };

                $ctrl.$onDestroy = function() {
                    if (observer)
                        observer.disconnect();
                };
            }]
    });
