/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PublishState {
    NotPublished = 0,
    Organization = 1,
    HasLearningModule = 2,
    Public = 3,
}
