﻿angular.module("DigiLean").factory('documentService', ['$http', 'webApiUrl', '$q', 
    function ($http, webApiUrl, $q) {
        var cachedFileUrls = {};
        const apiControllerUrl = webApiUrl + "/document";

        function tryGetCachedUrl(name, id) {
            const key = name + id;
            const cache = cachedFileUrls[key];
            if (!cache)
                return null;
            let expireDate = new Date(cache.expire);
            let now = new Date();
            if (expireDate > now)
                return cache.data;
            
            Reflect.deleteProperty(cachedFileUrls, key);
            return null;
        }
        function addToCache(name, id, data) {
            if (!name || !id || !data) return;
            const key = name + id;
            const expire = getExpireDateFromUrl(data);
            cachedFileUrls[key] = { expire, data };
        }
        function getExpireDateFromUrl(url) {
            try {
                const urlObj = new URL(url);
                if (urlObj.searchParams && urlObj.searchParams.has("se")) {
                    let expireDate = urlObj.searchParams.get("se");
                    return expireDate;
                }
            } catch(e) {
                console.log(e);
            }
            
            let nowPlus9 = new Date();
            nowPlus9.setHours(nowPlus9.getHours() + 9);
            return nowPlus9.toISOString();
        }
        return {
            getFileUrl: function (fileName, fileId) {
                const apiMethodUrl = apiControllerUrl + "/getFileUrl";

                const cachedUrl = tryGetCachedUrl(fileName, fileId);
                if (cachedUrl) {
                    return $q.when(cachedUrl);
                }

                const blobInfo = {
                    blobName: fileName,
                    fileInfoId: fileId
                };
                return $http.post(apiMethodUrl, blobInfo).then(function (result) {
                    addToCache(fileName, fileId, result.data);
                    return result.data;
                });
            },

            getFile: function (id) {
                const url = apiControllerUrl + "/getFile/" + id;
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },

            getAll: function () {
                return $http.get(apiControllerUrl).then(function (result) {
                    return result.data;
                });
            }
            ,

            uploadImageToBlobStorage: function (file) {
                const url = apiControllerUrl + "/PostImageBlobUploadNoPreview"
                let fd = new FormData();
                fd.append('file', file);
                return $http.post(url, fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                }).then(function (result) {
                    return result.data;
                });
            }



        };
    }]);
