import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('personalboardController', [
    '$rootScope', '$scope', '$stateParams', '$state', '$filter', '$translate', '$uibModal', 'authService', 'boardTaskService', 'deviationService', 'suggestionService','employeeService','isTeamsMode',
    function ($rootScope, $scope, $stateParams, $state, $filter, $translate, $uibModal, authService, boardTaskService, deviationService, suggestionService,employeeService, isTeamsMode) {
        $scope.isInTeams = false;
        if(isTeamsMode){
            $scope.isInTeams = true; 
        }
        $scope.new = {
            status: "blank",
            list: [],
            filteredList: [],
            translated: $translate.instant('BOARD_INBOX'),
            hide: false
        };
        $scope.inprogress = {
            status: "inprogress",
            list: [],
            filteredList: [],
            translated: $translate.instant('COMMON_IN_PROGRESS'),
            hide: false
        };
        $scope.implemented = {
            status: "OK",
            list: [],
            filteredList: [],
            translated: $translate.instant('IMPROVEMENT_IMPLEMENTED'),
            hide: false
        };
        $scope.waiting = {
            status: "unacceptable",
            list: [],
            filteredList: [],
            translated: $translate.instant('BOARD_WAITING'),
            hide: false
        };
        var userId = authService.getCurrentUser().userId;
        var boards = [];
        boardService.getMemberBoards().then(function (data) {
            boards = data;
            checkFavorite();
        });

        $scope.isStar = false;
        $scope.quickAccess = [];

        $scope.options = {
            timePeriod: {
                timeframe: "thisandnextweek",
                useDefaultTimeframes: true,
                timeframes: []
            }
        };

        $scope.showFilter = false;
        $scope.toggleFilter = function () {
            $scope.showFilter = !$scope.showFilter;
        }

        $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
            userId = profile.user.id;
        });
        $scope.subscribe('TaskStatusUpdated', function (status) {
            if (status.responsibleUserId === userId) {
                removeFromList($scope.new, status);
                removeFromList($scope.inprogress, status);
                removeFromList($scope.implemented, status);
                removeFromList($scope.waiting, status);
                addToList($scope.new, status);
                addToList($scope.inprogress, status);
                addToList($scope.implemented, status);
                addToList($scope.waiting, status);
                $scope.$apply();
            }
        });
        $scope.subscribe('TaskMoved', function (task) {
            if (task.responsibleUserId === userId) {
                updateTask(task.id);
            }
        });
        $scope.subscribe('TaskDateMoved', function (task) {
            if (task.responsibleUserId === userId) {
                updateTask(task.taskId);
            }
        });

        $scope.subscribe('TaskUpdated', function (task) {
            if (task.responsibleUserId === userId) {
                updateTask(task.id);
            }
        });
        $scope.subscribe('TaskCommentAdded', function (comment) {
            updateTask(comment.boardTaskId);
        });
        $scope.subscribe('TaskCommentDeleted', function (comment) {
            updateTask(comment.boardTaskId);
        });
        $scope.subscribe('TaskChangedBoard', function (board) {
            updateTask(board.taskId);
        });
        $scope.subscribe('TaskAssigned', function (taskAssigned) {
            var status = {
                taskId: taskAssigned.taskId
            }
            if (taskAssigned.previousUserId === userId) {
                removeFromList($scope.new, status);
                removeFromList($scope.inprogress, status);
                removeFromList($scope.implemented, status);
                removeFromList($scope.waiting, status);
                $scope.$apply();
            }
            else if (taskAssigned.userId === userId) {
                boardTaskService().get(taskAssigned.taskId).then(function (task) {
                    task.cssState = "animated slideInDown"; // Add animation
                    task.dateStyleClass = getDateStyle(task.boardDate);
                    task.contentDescription = getDescription(task);
                    if (task.status == "blank") {
                        if (task.progressStatus == "inprogress") {
                            if (isPartOfList($scope.inprogress.list, task.id)) return;
                            $scope.inprogress.list.unshift(task);
                        } else {
                            if (isPartOfList($scope.new.list, task.id)) return;
                            $scope.new.list.unshift(task);
                        }
                    } else if (task.status == "OK") {
                        if (isPartOfList($scope.implemented.list, task.id)) return;
                        $scope.implemented.list.unshift(task);
                    } else {
                        if (isPartOfList($scope.new.list, task.id)) return;
                        $scope.new.list.unshift(task);
                    }
                    removeAnmiation(task);
                });
            }
        });
        $scope.subscribe('NewTask', function (task) {
            // If current user is involved in task.
            if (!task.responsibleUserId) return;
            if (task.responsibleUserId === userId) {
                if (!isPartOfList($scope.new.list, task.id)) {
                    boardTaskService().get(task.id).then(function (task) {
                        task.dateStyleClass = getDateStyle(task.boardDate);
                        task.contentDescription = getDescription(task);
                        task.cssState = "animated slideInDown"; // Add animation
                        if (!isPartOfList($scope.new.list, task.id)) {
                            $scope.new.list.unshift(task);
                        }
                        removeAnmiation(task);
                    });
                }
            }
        });

        function isPartOfList(list, taskId) {
            var isPartOfContainer = $filter('filter')(list, { id: taskId });
            return isPartOfContainer.length > 0;
        }
        $scope.subscribe('TaskDeleted', function (id) {
            var status = {
                taskId: id
            }
            // suggestionId, status
            removeFromList($scope.new, status);
            removeFromList($scope.inprogress, status);
            removeFromList($scope.implemented, status);
            removeFromList($scope.waiting, status);
            $scope.$apply();

        });
        $scope.subscribe('TaskArchived', function (id) {
            var status = {
                taskId: id
            }
            // suggestionId, status
            removeFromList($scope.new, status);
            removeFromList($scope.inprogress, status);
            removeFromList($scope.implemented, status);
            removeFromList($scope.waiting, status);
            $scope.$apply();
        });
        $scope.subscribe('RepeatingTasksDeleted', function (taskIds) {
            // If current user is involved in task.
            angular.forEach(taskIds, function (id, key) {
                var status = {
                    taskId: id
                }
                // suggestionId, status
                removeFromList($scope.new, status);
                removeFromList($scope.inprogress, status);
                removeFromList($scope.implemented, status);
                removeFromList($scope.waiting, status);
                $scope.$apply();
            });

        });


        // Time handling
        $scope.timeframe = "thisandnextweek"
        $scope.timePeriod = timeService.getTimePeriod($scope.timeframe);
        $scope.periodChangedHandler = function (timePeriod) {
            if ($scope.timePeriod && $scope.timePeriod.from === timePeriod.period.from && $scope.timePeriod.to === timePeriod.period.to) {
                return;
            }
            $scope.timePeriod = timePeriod.period;

        }

        $scope.noOfActiveStatusColumn = function () {
            var number = 0;
            if (!$scope.new.hide) { number++; }
            //if(!$scope.planned.hide) { number++;}
            if (!$scope.inprogress.hide) { number++; }
            if (!$scope.implemented.hide) { number++; }
            if (!$scope.waiting.hide) { number++; }
            return number;
        };

        $scope.newItem = function () {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newtasktopersonaltasklist.html',
                controller: 'newtasktopersonaltasklistController',
                resolve: {
                    options: function () {
                        return null;
                    }
                }
            });
        };

        $scope.openTask = function(taskId, activeTab) {
            boardTaskService().get(taskId).then(function (task) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'boardTask.html',
                    controller: 'boardTaskController',
                    resolve: {
                        task: function () {
                            return task;
                        },
                        activeTab: function () {
                            return activeTab;
                        },
                        options: function () {
                            var options = {
                                archiveAvailable: false,
                                canChangeBoard: true
                            }
                            return options;
                        }
                    }
                });
            });
        }

        $scope.openForEdit = function (task) {
            if (task.improvementSuggestionId) {
                openImprovement(task.improvementSuggestionId);
            } else if (task.deviationId) {
                openDeviation(task.deviationId);
            } else if (task.a3Id) {
                navigateToA3(task.a3Id);
            } else { //both personal and board tasks
                $scope.openTask(task.id, "Info");   
            }
        }

        if ($stateParams.id) {
            var taskid = parseInt($stateParams.id);
            // TODO could this input also be actionlistitem for improvement, deviation or a3?
            boardTaskService().get(taskid).then(function (task) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'boardTask.html',
                    controller: 'boardTaskController',
                    resolve: {
                        task: function () {
                            return task;
                        },
                        activeTab: function () {
                            return "Info";
                        },
                        options: function () {
                            var options = {
                                archiveAvailable: false
                            }
                            return options;
                        }
                    }
                });
            });
        }

        $scope.suggestionSortableOptions = {
            connectWith: ".task-list",
            receive: function (e, ui) {
                var target = ui.item.sortable.droptarget[0];
                var status = $(target).attr("data-status");
                var source = ui.item.sortable.source[0];
                var task = ui.item.sortable.model;
                var statusOptions = {
                    taskid: task.id,
                    status: status
                };
                if (status == "inprogress") {
                    boardTaskService().updateProgressStatus(task.id, statusOptions).then(function (result) {
                    });
                } else {
                    boardTaskService().updateStatus(task.id, statusOptions).then(function (result) {
                    });
                }
            }
        };




        $scope.navigateToBoard = function ($event, boardId) {
            $state.go('board', { id: boardId });
            $event.preventDefault();
        }

        function navigateToA3(a3Id) {
            $state.go('a3wizard', { id: a3Id }, { reload: false });
        }

        function openImprovement(improvementid) {
            suggestionService().get(improvementid).then(function (suggestion) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'fullImprovementInfoForm.html',
                    controller: 'fullImprovementModalController',
                    windowClass: 'fullSuggestion-modal-window',
                    resolve: {
                        suggestion: function () {
                            return suggestion;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
            });
        }

        function openDeviation(id) {
            deviationService().get(id).then(function (deviation) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'fullDeviation.html',
                    controller: 'fullDeviationController',
                    windowClass: 'full-screen',
                    resolve: {
                        deviation: function () {
                            return deviation;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
            });
        }

        $scope.hasDueDate = function (item) {

            if (item.boardDate) {
                return true;
            } else return false;
        };


        /*
                $scope.getDescription = function(item){
                    if(item.boardId){
                        if(item.board){
                            return item.board.name;
                        } else return "";
                        return 
                    } else if (item.a3Id){
                        return "A3";
                    } else if (item.deviationId){
                        return $translate.instant('COMMON_DEVIATION');
                    }else if (item.improvementSuggestionId){
                        return $translate.instant('COMMON_SUGGESTION');
                    } else return $translate.instant('DASHBOARD_PERSONAL');
                }*/


        $scope.openA3 = function (a3Id) {
            $state.go('a3wizard', { id: a3Id }, { reload: false });
        };

        function updateTask(taskId) {
            var isPartOfContainer = $filter('filter')($scope.new.list, { id: taskId });
            if (isPartOfContainer.length > 0) {
                replaceTask($scope.new.list, taskId, isPartOfContainer[0]);
            } else {
                isPartOfContainer = $filter('filter')($scope.inprogress.list, { id: taskId });
                if (isPartOfContainer.length > 0) {
                    replaceTask($scope.inprogress.list, taskId, isPartOfContainer[0]);
                } else {
                    isPartOfContainer = $filter('filter')($scope.implemented.list, { id: taskId });
                    if (isPartOfContainer.length > 0) {
                        replaceTask($scope.implemented.list, taskId, isPartOfContainer[0]);
                    } else {
                        isPartOfContainer = $filter('filter')($scope.waiting.list, { id: taskId });
                        if (isPartOfContainer.length > 0) {
                            replaceTask($scope.waiting.list, taskId, isPartOfContainer[0]);
                        }
                    }
                }

            }
        }
        function replaceTask(list, updatedTaskId, existingTask) {
            boardTaskService().get(updatedTaskId).then(function (db) {
                var currentTask = existingTask;

                var index = list.indexOf(currentTask);
                list[index] = db;
                db.dateStyleClass = getDateStyle(db.boardDate);
                db.contentDescription = getDescription(db);
                db.cssState = "animated bounce"; // Add animation
                removeAnmiation(db);
            });
        }

        function getDateStyle(date) {
            if (!date) return "";

            var iscurrentDate = moment(date).isSame(new Date(), "day");
            if (iscurrentDate) {
                return "today";
            } else {
                var overdue = "";
                var duration = moment.duration(moment().diff(moment(date)));
                var days = duration.asDays();
                if (days > 1) {
                    overdue = "overdue";
                }
                return overdue;
            }
        }

        function removeFromList(statusContainer, status) {
            var isPartOfContainer = $filter('filter')(statusContainer.list, { id: status.taskId }, true);
            if (isPartOfContainer.length > 0) {
                var current = isPartOfContainer[0];
                var index = statusContainer.list.indexOf(current);
                statusContainer.list.splice(index, 1);
                console.log("splice: " + statusContainer.status);
            }
        }
        function addToList(statusContainer, status) {
            // is part of list
            var isPartOfContainer = $filter('filter')(statusContainer.list, { id: status.taskId }, true);
            if (isPartOfContainer.length === 0) {
                if (statusContainer.status === status.status) {
                    boardTaskService().get(status.taskId).then(function (task) {
                        task.dateStyleClass = getDateStyle(task.boardDate);
                        task.contentDescription = getDescription(task);
                        task.cssState = "animated slideInDown"; // Add animation
                        statusContainer.list.unshift(task);
                        removeAnmiation(task);
                    });

                }
            }
        }

        $scope.addToFavorites = function () {
            $scope.isStar = true;
            var board = {
                id: "PERSONAL",
                name: $translate.instant("BOARD_PERSONAL_BOARD"),
                boardType: "PERSONAL"
            };
            $scope.quickAccess.push(board);
            saveQuickAccess();
        }
        $scope.removeFromFavorites = function () {
            $scope.isStar = false;
            var isFavorite = $filter('filter')($scope.quickAccess, { id: "PERSONAL" })
            if (isFavorite.length > 0) {
                var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                $scope.quickAccess.splice(currentIndex, 1);
                saveQuickAccess();
            }
        }
        function saveQuickAccess() {
            employeeService().updateUserPreference("FlexiboardFavoriteBoards", $scope.quickAccess).then(function (settings) {
                $scope.publish("FlexiboardFavoriteBoardsChanged", $scope.quickAccess);
            });
        }

        function checkFavorite(){
            employeeService().getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                if (settings && settings.value) {
                    angular.forEach(settings.value, function (board) {
                        if (board) {
                            if(board.id == "PERSONAL"){
                                $scope.isStar = true;
                            }
                            $scope.quickAccess.push(board);
                        }
                    });
                }
            });
        }

        function getDescription(item) {
            if (item.boardId) {
                if (boards.length == 0) {
                    boardService.getMemberBoards().then(function (data) {
                        boards = data;
                        var selectedBoard = $filter('filter')(boards, { id: item.boardId }, true)[0];
                        if (selectedBoard) {
                            return selectedBoard.name;
                        } else return "";
                    });
                } else {
                    var selectedBoard = $filter('filter')(boards, { id: item.boardId }, true)[0];
                    if (selectedBoard) {
                        return selectedBoard.name;
                    } else return "";
                }
            } else if (item.a3Id) {
                return "A3";
            } else if (item.deviationId) {
                return $translate.instant('COMMON_DEVIATION');
            } else if (item.improvementSuggestionId) {
                return $translate.instant('COMMON_SUGGESTION');
            } else if (item.taskType == 'SubTask') {
                return $translate.instant('COMMON_SUBTASK');
            } else return $translate.instant('DASHBOARD_PERSONAL');
        }
        function removeAnmiation(task) {
            setTimeout(function () {
                task.cssState = "";
            }, 1000);
        }

    }]);
