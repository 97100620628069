import "./singlevaluetarget.css"
import CountUp from './../../../../../thirdparty/countUp/countUp.min';
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

const DigiLean = angular.module('DigiLean')
DigiLean.directive("singleValueTarget", ['dataService', 'dataSourceService', '$uibModal','debounce',
    function (dataService, dataSourceService, $uibModal, debounce) {
        return {
            templateUrl: 'singlevaluetarget.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                // 
                scope.title = "";
                scope.filters = [];
                scope.decimals = 0;
                scope.formula = 'sum';
                scope.showTargetGoal = true;
                scope.targetDescription = "";
                scope.targetIndicator;
                var value = 0;
                scope.static = staticFileService;

                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                // default timeperiod
                var timeFrames = ['all', 'lastyear', 'thisyear', 'fiscalyear','previousmonth', 'month', 'custom'];
                var timeFramesYear = ['all', 'last3years', 'lastyear', 'thisyear', 'fiscalyear','custom'];
                var timeFramesMonth = ['all', 'last12mth', 'lastyear', 'thisyear', 'fiscalyear','YTM', 'previousmonth', 'month', 'custom'];
                var timeFramesWeek = ['all', 'last12mth', 'lastyear', 'thisyear', 'fiscalyear','YTW', 'previousmonth', 'month','previousisoweek','isoweek', 'custom'];
                var timeFramesDay = ['all', 'last12mth', 'thisyear', 'fiscalyear','YTD', 'previousmonth', 'month', '30','previousisoweek', 'isoweek','yesterday', 'day', 'number', 'custom'];

                var timeSettings = {
                    timeframe: "thisyear",
                    useDefaultTimeframes: false,
                    timeframes: timeFrames,
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    },

                    setTimeframes: function () {
                        if (!scope.dataSource) return;
                        if (scope.dataSource.unitOfTime === 'DAY') {
                            timeSettings.timeframes = timeFramesDay;
                        } else if (scope.dataSource.unitOfTime === 'WEEK') {
                            timeSettings.timeframes = timeFramesWeek;
                        } else if (scope.dataSource.unitOfTime === 'MONTH') {
                            timeSettings.timeframes = timeFramesMonth;
                        } else if (scope.dataSource.unitOfTime === 'YEAR') {
                            timeSettings.timeframes = timeFramesYear;
                        }
                        if(scope.options){
                            scope.options.timePeriod.timeframes = timeSettings.timeframes;
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);


                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    // apply
                    if (settings) {
                        if (settings.dataSource) {
                            scope.dataSource = settings.dataSource;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        timeSettings.setTimeframes();

                        if (settings.title) {
                            scope.title = settings.title;
                        }
                        if (settings.decimals) {
                            scope.decimals = settings.decimals;
                        }
                        if (settings.showTargetGoal === false) {
                            scope.showTargetGoal = settings.showTargetGoal;
                        }
                        if (settings.formula) {
                            scope.formula = settings.formula;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                    }

                    if (!scope.dataSource && scope.isAdminMode){
                        // debounce to prevent double dialog
                        selectDataSourceDebounced()
                    }
                });


                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                function resizeChart() {
                    var host = $(elem).closest(".grid-stack-item-content");
                    if (host.length == 0)
                        host = $(elem).closest(".modal-body")
                    
                    if (host.length == 0) return;
                    var height = host.height();
                    height = height - 100;
                    if (height < 40) height = 40;
                    if (height > 80) height = 80;
                    // var targetSymbolHeight = height;
                    var valueElement = $(host).find(".value-symbol");
                    $(valueElement).css("font-size", height);
                }

                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });



                // Subscribe to component related event
                scope.subscribe("DataSourceUpdated", function (value) {
                    scope.updateValue(value.dataSource);
                });

                scope.subscribe("DataValueAdded", function (value) {
                    if (!value) return;
                    if(shouldDataBeRefreshed(value.dataSourceId, value.valueDate)) {
                        if(scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id == value.dataSourceId) {
                            updateTargetValue();
                        } else {
                            scope.updateValue(value.dataSourceId);
                        }
                    }
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (!value) return;
                    if(shouldDataBeRefreshed(value.dataSourceId, value.valueDate)) {
                        if(scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id == value.dataSourceId) {
                            updateTargetValue();
                        } else {
                            scope.updateValue(value.dataSourceId);
                        }
                    }
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    if (!value) return;
                    if(shouldDataBeRefreshed(value.dataSourceId, value.valueDate)) {
                        if(scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id == value.dataSourceId) {
                            updateTargetValue();
                        } else {
                            scope.updateValue(value.dataSourceId);
                        }
                    }
                });

                function dateInSelectedTimeFrame(utcDate) {
                    if (!scope.timePeriod) {
                        return true;
                    }
                    if (moment(utcDate).isBefore(scope.timePeriod.period.from) ||
                        moment(utcDate).isAfter(scope.timePeriod.period.to)) {
                        return false;
                    } else {
                        return true;
                    }
                }
                function shouldDataBeRefreshed(dataSourceId, valueDate){
                    if (!scope.dataSource) return false;
                    if (scope.dataSource.id === dataSourceId) {
                        if (dateInSelectedTimeFrame(valueDate)) {
                            return true;
                        } 
                    } else if (scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id === dataSourceId) {
                        if (dateInSelectedTimeFrame(valueDate)) {
                            return true;
                        } 
                    }
                    return false;
                }


                scope.changeFormula = function(newFormula){
                    scope.formula = newFormula;
                    scope.updateSettings();
                    scope.updateValue(scope.dataSource.id);
                }

                scope.toggleShowTargetGoal = function () {
                    scope.updateSettings();
                }

                scope.registerData = function () {
                    var settings = {
                        dataSource: scope.dataSource
                    };

                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'registrationDialog.html',
                        controller: 'registrationDialogController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            componentSettings: function () {
                                return settings;
                            }
                        }
                    });
                    modalInstance.result.then(function (result) {
                    });
                };

                let dataSeriesModalInstance = null
                // Select defintion to use.
                scope.selectDataSource = function () {
                    console.log("scope.selectDataSource func")
                    if (dataSeriesModalInstance)
                        return //prevent double

                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    dataSeriesModalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return true;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return false;
                            }
                        }
                    });
            
                    dataSeriesModalInstance.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        if (result.dataSource.valueElement.unit == "%") {
                            scope.formula = "average";
                        }
                        scope.filters = result.filters;
                        timeSettings.setTimeframes();
                        scope.setTimePeriodDebounced(scope.options.timePeriod.timeframe, scope.options.timePeriod);
                        scope.updateSettings();
                        scope.updateValue(scope.dataSource.id);

                    }, ()=>{ /*cancel*/}).finally(() => {
                        dataSeriesModalInstance = null
                    });
                }

                const selectDataSourceDebounced = debounce(scope.selectDataSource, 200, false)

                function updateTargetValue(){
                   
                        var timePeriod = {
                            from: scope.options.timePeriod.period.from,
                            to: scope.options.timePeriod.period.to
                        };
                        var options = {
                            timePeriod: timePeriod,
                            value: value,
                            formula: scope.formula
                        };
                        setOtherDatasourceTargetResultForPeriod(options);
                }

                // element to update
                var valueElement = $(elem).find(".value")[0];
                scope.updateValue = function (id) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id !== id) return;
                    var timePeriod = {
                        from: scope.options.timePeriod.period.from,
                        to: scope.options.timePeriod.period.to
                    };
                    var options = {
                        timePeriod: timePeriod,
                        filters: scope.filters
                    };

                    dataService().getSumForPeriod(scope.dataSource.id, options).then(function (sum) {

                        var options = {
                            timePeriod: timePeriod,
                            value: sum,
                            filters: scope.filters,
                            formula: scope.formula
                        };
                        scope.targetDescription = ""
                        scope.targetIndicator = "";
                        dataService().getNumberOfValuesForPeriod(scope.dataSource.id, options).then(function (numberOfValues) {
                            options.numberOfValues = numberOfValues;
                            // If average, adjust sum to average calculation
                            if (scope.formula == "average") {
                                sum = sum / numberOfValues;
                                options.value = sum;
                            }
                            value = sum; // save for target datasource calculation
                            if(scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id){
                                setOtherDatasourceTargetResultForPeriod(options);
                            } else if (scope.dataSource.objectSource == 'internal') {
                                setInternalTargetResultForPeriod(options);
                            } else {
                                if (scope.dataSource.targets) {
                                    options.targets = scope.dataSource.targets;
                                }
                                dataSourceService().getTargetSumForPeriod(scope.dataSource.id, options).then(function (targetresult) {
                                    if (targetresult) {
                                        scope.targetDescription = targetresult.greenOperator + " " + targetresult.greenValue.toFixed(scope.decimals);
                                        scope.targetIndicator = targetresult.targetIndicator;

                                    }
                                });
                            }
                            
                            var animoptions = {
                                decimal: '.',
                            };
                            var numAnim = new CountUp(valueElement, 0, sum, scope.decimals, 1, animoptions);
                            numAnim.start();
                            resizeChart();
                        });
                    });
                };

                function setOtherDatasourceTargetResultForPeriod(options) {
                    var targetOptions = {
                        timePeriod: options.timePeriod,
                        filters: scope.dataSource.targetFilters,
                        formula: scope.formula
                    };
                    dataService().getSumForPeriod(scope.dataSource.targetDataSource.id, targetOptions).then(function (targetsum) {
                        var targetResult = DigiLean.target.getTargetResultByValueAndOperator(options.value, scope.dataSource.targetGreenOperator, targetsum);
                        if(targetResult){
                            scope.targetIndicator = targetResult.targetIndicator;
                            scope.targetDescription = scope.dataSource.targetGreenOperator + " " + targetsum.toFixed(scope.decimals);
                        }
                    });
                }

                scope.updateDecimals = function () {
                    scope.updateValue(scope.dataSource.id);
                    scope.updateSettings();
                };

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.options.timePeriod,
                        dataSource: scope.dataSource,
                        filters: scope.filters,
                        decimals: scope.decimals,
                        showTargetGoal: scope.showTargetGoal,
                        formula: scope.formula,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                };

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    scope.options.timePeriod = timePeriod;

                    if (scope.dataSource && scope.dataSource.id) {
                        scope.updateValue(scope.dataSource.id);
                    }

                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                };


                function setInternalTargetResultForPeriod(options) {

                    var validTargetsInPeriod = scope.dataSource.targets;

                    if (validTargetsInPeriod == null || validTargetsInPeriod.length == 0) return;
                    if (scope.dataSource.unitOfTime == null) return;

                    var fromdate = moment(scope.options.timePeriod.period.from).toDate();
                    var todate = moment(scope.options.timePeriod.period.to).toDate();
                    var greenvalue = 0;
                    var redvalue = 0;
                    if (scope.dataSource.unitOfTime == "YEAR") {
                        var totalNumOfYears = moment(todate).get('year') - moment(fromdate).get('year');
                        if(totalNumOfYears == 0) totalNumOfYears = 1;

                        for (var m = 0; m < totalNumOfYears; m++) {
                            for (var t = validTargetsInPeriod.length; t > 0; t--) {
                                var target = validTargetsInPeriod[t - 1];

                                if (moment(target.validFrom).toDate() <= fromdate) {
                                    greenvalue = greenvalue + Number(target.greenValue);
                                    if (target.redValue != null) {
                                        redvalue = redvalue + Number(target.redValue);
                                    }
                                    break;
                                }

                            }
                            fromdate = moment(fromdate).add(1, 'year');
                            var daysOfNewMonth = moment(fromdate).daysInMonth();
                            fromdate = moment(fromdate).add(daysOfNewMonth + moment(fromdate).get('day'));
                        }
                        if (scope.dataSource.valueUnit == "%" || scope.formula == "average") {
                            redvalue = redvalue / totalNumOfYears;
                            greenvalue = greenvalue / totalNumOfYears;
                            options.value = options.value / options.numberOfValues;
                        }

                    }
                    if (scope.dataSource.unitOfTime == "MONTH") {

                        var totalNumOfMonths = (moment.utc(todate).get('year') 
                                                - moment.utc(fromdate).get('year')) * 12 
                                                + moment.utc(todate).get('month') 
                                                - moment.utc(fromdate).get('month');//(todate.Year - fromdate.Year) * 12 + todate.Month - fromdate.Month;

                        for (var m = 0; m < totalNumOfMonths; m++) {
                            //angular.forEach(validTargetsInPeriod, function (target) {
                            for (var t = validTargetsInPeriod.length; t > 0; t--) {
                                var target = validTargetsInPeriod[t - 1];
                                if (moment(target.validFrom).toDate() <= fromdate) {
                                    greenvalue = greenvalue + Number(target.greenValue);
                                    if (target.redValue != null) {
                                        redvalue = redvalue + Number(target.redValue);
                                    }
                                    break;
                                }

                            }//);
                            fromdate = moment(fromdate).add(1, 'month');//fromdate.AddMonths(1);
                            var daysOfNewMonth = moment(fromdate).daysInMonth();//DateTime.DaysInMonth(fromdate.Year, fromdate.Month);
                            if (moment(fromdate).get('day') < daysOfNewMonth) { // Handles e.g when february adds one but "truncates" days to 28. Must add missing days in next month
                                fromdate = moment(fromdate).add(daysOfNewMonth - moment(fromdate).get('day'));//fromdate.AddDays(daysOfNewMonth-fromdate.Day);
                            }
                        }
                        if (scope.dataSource.valueUnit == "%" || scope.formula == "average") {
                            redvalue = redvalue / totalNumOfMonths;
                            greenvalue = greenvalue / totalNumOfMonths;
                            options.value = options.value / options.numberOfValues;
                        }
                    }
                    if (scope.dataSource.unitOfTime == "WEEK") {

                        var isoYearStartDate = moment.utc(fromdate).isoWeekYear();
                        var isoYearEndDate = moment.utc(todate).isoWeekYear();
                        var totalNumOfWeeks = 0;
                        if(isoYearStartDate<isoYearEndDate){
                            while(isoYearStartDate<isoYearEndDate){
                                var e = moment().set('year', isoYearStartDate).endOf("year").isoWeeks();
                                var f = moment(new Date(isoYearStartDate,moment.utc(fromdate).get('month'),moment.utc(fromdate).date())).isoWeek();
                                totalNumOfWeeks = totalNumOfWeeks + 
                                moment().set('year', isoYearStartDate).endOf("year").isoWeeks()
                                - moment(new Date(isoYearStartDate,moment.utc(fromdate).get('month'),moment.utc(fromdate).date())).isoWeek();
                                isoYearStartDate = isoYearStartDate + 1;
                            }
                            totalNumOfWeeks = totalNumOfWeeks + moment.utc(todate).isoWeek();
                        } else { //same year
                            totalNumOfWeeks = totalNumOfWeeks + moment.utc(todate).isoWeek() -
                            moment(new Date(isoYearStartDate,moment.utc(fromdate).get('month'),moment.utc(fromdate).date())).isoWeek();
                        }

                        for (var m = 0; m < totalNumOfWeeks; m++) {
                            for (var t = validTargetsInPeriod.length; t > 0; t--) {
                                var target = validTargetsInPeriod[t - 1];
                                if (moment(target.validFrom).toDate() <= fromdate) {
                                    greenvalue = greenvalue + Number(target.greenValue);
                                    if (target.redValue != null) {
                                        redvalue = redvalue + Number(target.redValue);
                                    }
                                    break;
                                }

                            }
                            fromdate = moment(fromdate).add(7, 'day');
                            /*var daysOfNewMonth = moment(fromdate).daysInMonth();//DateTime.DaysInMonth(fromdate.Year, fromdate.Month);
                            if (moment(fromdate).get('day') < daysOfNewMonth) { // Handles e.g when february adds one but "truncates" days to 28. Must add missing days in next month
                                fromdate = moment(fromdate).add(daysOfNewMonth - moment(fromdate).get('day'));//fromdate.AddDays(daysOfNewMonth-fromdate.Day);
                            }*/
                        }
                        if (scope.dataSource.valueUnit == "%" || scope.formula == "average") {
                            redvalue = redvalue / totalNumOfWeeks;
                            greenvalue = greenvalue / totalNumOfWeeks;
                            options.value = options.value / options.numberOfValues;
                        }
                    }
                    if (scope.dataSource.unitOfTime == "DAY") {
                        var totalNumOfDays = 0;
                        while (moment(options.timePeriod.to).toDate() >= fromdate) {
                            totalNumOfDays = totalNumOfDays + 1;
                            for (var t = validTargetsInPeriod.length; t > 0; t--) {
                                var target = validTargetsInPeriod[t - 1];
                                if (moment(target.validFrom).toDate() <= fromdate) {
                                    greenvalue = greenvalue + Number(target.greenValue);
                                    if (target.redValue != null) {
                                        redvalue = redvalue + Number(target.redValue);
                                    }
                                    break;
                                }

                            }
                            fromdate = moment(fromdate).add(1, 'day');
                        }
                        if (scope.dataSource.valueUnit == "%" || scope.formula == "average") {
                            redvalue = redvalue / totalNumOfDays;
                            greenvalue = greenvalue / totalNumOfDays;
                            options.value = options.value / options.numberOfValues;
                        }
                    }

                    var result = {
                        greenOperator: validTargetsInPeriod[0].greenOperator,
                        greenValue: greenvalue,
                        redOperator: validTargetsInPeriod[0].redOperator,
                        redValue: redvalue,
                        targetIndicator: ""
                    }

                    if (options.value == null) {
                        result.targetIndicator = "";
                    } else {
                        result.targetIndicator = "yellow";

                        if (validTargetsInPeriod[0].greenOperator == "<") {
                            if (options.value < result.greenValue) {
                                result.targetIndicator = "green";
                            }
                            if (validTargetsInPeriod[0].RedOperator == ">=") {
                                if (options.value >= result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            } else if (validTargetsInPeriod[0].redOperator == ">") {
                                if (options.value > result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            }
                        }
                        if (validTargetsInPeriod[0].greenOperator == "<=") {
                            if (options.value <= result.greenValue) {
                                result.targetIndicator = "green";
                            }
                            if (validTargetsInPeriod[0].redOperator == ">=") {
                                if (options.value >= result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            } else if (validTargetsInPeriod[0].redOperator == ">") {
                                if (options.value > result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            }
                        }
                        if (validTargetsInPeriod[0].greenOperator == ">") {
                            if (options.value > result.greenValue) {
                                result.targetIndicator = "green";
                            }
                            if (validTargetsInPeriod[0].redOperator == "<=") {
                                if (options.value <= result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            } else if (validTargetsInPeriod[0].redOperator == "<") {
                                if (options.value < result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            }
                        }
                        if (validTargetsInPeriod[0].greenOperator == ">=") {
                            if (options.value >= result.greenValue) {
                                result.targetIndicator = "green";
                            }
                            if (validTargetsInPeriod[0].redOperator == "<=") {
                                if (options.value <= result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            } else if (validTargetsInPeriod[0].redOperator == "<") {
                                if (options.value < result.redValue) {
                                    result.targetIndicator = "red";
                                }
                            }
                        }
                    }
                    scope.targetDescription = result.greenOperator + " " + result.greenValue.toFixed(scope.decimals);
                    scope.targetIndicator = result.targetIndicator;

                }

            }
        }
    }]);
