import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("smarttableEndDateCell", ['smarttableService',
        function (smarttableService) {
            return {
                templateUrl: 'smarttableEndDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE"
                    scope.dateMissed = false
                    scope.date = {}
                    scope.value = null

                    scope.updateEndDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.date.endDate = date
                        smarttableService().updateEndTime(scope.model.cell.smartTableRowId,scope.date.endDate).then(function (savedDate) {
                            checkDate()
                        })
                    }

                    scope.updateEstimatedEndDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.date.estimatedEndDate = date
                        var value = {
                            estimatedEndDate: scope.date.estimatedEndDate
                        }
                        scope.model.cell.value = value
                        scope.cellChangeHandler({cell: scope.model.cell})
                        checkDate()
                    }

                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                scope.date.estimatedEndDate = moment(cell.value.estimatedEndDate).toDate()
                            }
                        }
                    })
                    

                    scope.$watch("model", function (model) {
                        if (scope.model) {
                            if(scope.model.cell && scope.model.cell.value && scope.model.cell.value.estimatedEndDate){
                                scope.date.estimatedEndDate = moment(scope.model.cell.value.estimatedEndDate).toDate();
                            }
                            if (scope.model.row) {
                                scope.row = scope.model.row;
                                
                                if(scope.row.endTime){
                                    scope.date.endDate = moment(scope.row.endTime).toDate();
                                }
                                
                                scope.date.datePicker = {
                                    opened: false
                                },
                                scope.date.estimatedDatePicker = {
                                    opened: false
                                }
                                checkDate();
                            } else {
                                createTempDate();
                            }
                        } 
                        model.getSortValue = function() {
                            if (scope.date) {
                                if(scope.date.endDate){
                                    return scope.date.endDate;
                                } else {
                                    return scope.date.estimatedEndDate;
                                }              
                            }
                            return null;
                        }
                    });

                    scope.subscribe("SmartTableRowEndTimeUpdated", function (cell) {
                        if (cell.id == scope.model.cell.smartTableRowId) {
                            scope.date.endDate = moment(cell.endTime).toDate();
                            checkDate();
                        }
                    });

                    function createTempDate(){
                        scope.date = {
                            id: scope.model.cell.smartTableRowId,
                            endDate: null,
                            estimatedEndDate: null,
                            datePicker : {
                                opened: false
                            },
                            estimatedDatePicker : {
                                opened: false
                            }
                        };
                    }

                    function checkDate() {
                        scope.date.status = "";
                        if (scope.date) {
                            scope.date.endDate = timeService.validateDate(scope.date.endDate);
                            scope.date.estimatedEndDate = timeService.validateDate(scope.date.estimatedEndDate);
                            scope.dateMissed = false;
                            
                            if (scope.date.endDate) {
                                scope.date.status = "completed";
                                var diff =  moment.duration(moment(scope.date.endDate).diff(moment(scope.date.estimatedEndDate)));
                                var days = diff.asDays();
                                if(!isNaN(days)){
                                    scope.date.diffPlannedVSActual = Math.round(days);
                                }else {
                                    scope.date.diffPlannedVSActual = null;
                                }
                            } else if (scope.date.estimatedEndDate) {
                                //check if overdue/missed
                 
                                var duration = moment.duration(moment().endOf('day').diff(moment(scope.date.estimatedEndDate).endOf('day')));
                                var days = duration.asDays();
                                if (days > 1) {
                                    scope.date.status = "missed";
                                    scope.date.diffToPlannedDate = Math.trunc(days);
                                    scope.dateMissed = true;
                                } else {
                                    scope.date.status = "future";
                                    scope.date.diffToPlannedDate = Math.round(days)*-1;
                                }
                            }
                        }
                    }
                }
            }
        }]);
