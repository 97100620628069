import type { CustomerListModel, PublicApiClientViewModel, PublicApiGeneratedSecretViewModel } from "@api"
import { DigiLeanNgScope } from "@common/model/angularModel"
import angular from "angular"
import * as apiClientsService from "./apiClientsService"
import dialog from "@common/components/digilean/DigileanDialog"
import * as customerService from "@common/services/customers/customerService"
import { cloneDeep } from "lodash"

interface IApiClientScope extends DigiLeanNgScope {
    client: PublicApiClientViewModel
    indexTab: number
    newSecret: PublicApiGeneratedSecretViewModel
    customers: CustomerListModel[]
    customerSelected: CustomerListModel 
    selectedCustomerChanged: (customer: CustomerListModel) => void
    selectTab: (tabIndex: number) => void
    generateNewSecret: () => void
    expireSecret: (secretId: number) => void
    ok: () => void
    close: () => void
    changeScopesDialog: () => void
}

angular
    .module('DigiLean')
    .controller("editApiClientController", ['$scope', '$uibModalInstance', 'client', 'modalService',
        function ($scope: IApiClientScope, $uibModalInstance, client: PublicApiClientViewModel, modalService) {

            
            $scope.client = client

            $scope.customerSelected = {}
            if (client.customerId)
                $scope.customerSelected = {id: client.customerId, name: client.customerName}

            $scope.ok = function () {
                apiClientsService.saveClient($scope.client).then(client => {
                    console.log(client)
                })
                $uibModalInstance.close($scope.client);
            }

            $scope.close = () => {
                $uibModalInstance.dismiss('cancel')
            }

            $scope.indexTab = 0
            $scope.selectTab = (tabIndex: number) => {
                $scope.indexTab = tabIndex
            }

            $scope.newSecret = {}
            $scope.generateNewSecret = () => {
                apiClientsService.generateSecret($scope.client.clientId!).then(secret => {
                    $scope.newSecret = secret
                })
            }

            $scope.expireSecret = (secretId: number) => {
                const modalOptions = {
                    closeButtonText: "cancel",
                    actionButtonText: "delete",
                    headerText: "confirm retire secret secret",
                    bodyText: "The secret will now be marked as expired and not work any more"
                }

                modalService.showModal({}, modalOptions).then(() => {
                    apiClientsService.expireSecret($scope.client.clientId!, secretId).then(client => {
                        $scope.client = client
                    })
                }, () => {})
            }
            
            $scope.selectedCustomerChanged = function(customer: CustomerListModel) {
                $scope.client.customerId = customer.id
            }
            $scope.customers = []
            function getCustomers() {
                customerService.getCustomersSimpleForAdmin().then((customers) => {
                    $scope.customers = customers
                })
            }
            getCustomers()
            
            $scope.changeScopesDialog = async function() {
                let scopes = cloneDeep(client.allowedScopes)
                const scopesDialog = document.createElement("api-scopes-dialog")
                scopesDialog.clientScopes = scopes
                const scopesChange = ((e: CustomEvent) => scopes = e.detail.scopes) as EventListener
                scopesDialog.addEventListener("api-scopes-changed", scopesChange)
                const res = await dialog.openElement({title: "Scopes"}, scopesDialog)
                if (res && scopes) {
                    client.allowedScopes = scopes
                    await apiClientsService.updateScopes(client.clientId!, scopes)
                }
                scopesDialog.removeEventListener("api-scopes-changed", scopesChange)
            }
        }])
