﻿2
angular
    .module('DigiLean')
    .directive("learningPageEvaluationStatus", ['evaluationService','favoriteService',
        function (evaluationService,favoriteService) {
            return {
                templateUrl: 'learningPageEvaluationStatus.html',
                restrict: 'E',
                scope: {
                    'pageId': '<',
                    'fileName': '<',
                    'isExternal': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.isUseful = false
                    scope.isNotUseful = false
                    scope.$watch('pageId', function (pageId) {
                        if (!pageId) return
                        scope.status = {}
                        evaluationService().getEvaluationsForPage(pageId).then(function(status) {
                            scope.status = status
                        })
                        favoriteService().getPageFavoriteStatus(pageId).then(function(status) {
                            scope.favoriteStatus = status
                        })
                    })
                
                }
            }
        }])
