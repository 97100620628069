angular.module('DigiLean').component('learningSummaryViewer', {
    templateUrl: "learningSummaryViewer.html",
    bindings: {
        'items': '<',
        'selectedPageId': '<',
        'pageSelectHandler': '&',
    },
    controller: [
        function() {
            let $ctrl = this

            $ctrl.items = []
            
            $ctrl.$onChanges = function (changes) {
                if (changes.selectedPageId) {
                    if (changes.selectedPageId.currentValue) {
                        setSelectPageId($ctrl.selectedPageId);
                    }
                }
            }

            let currentPageId = null;
            const setSelectPageId = function(selectedPageId) {
                if (!selectedPageId) {
                    $ctrl.selectedPage = null;
                    return;
                } 
                if (currentPageId == selectedPageId)
                    return
                if (!$ctrl.summary)
                    return

                $ctrl.summary.forEach(element => {
                    element.pages.forEach(page => {
                        if(page.id == selectedPageId) {
                            $ctrl.selectedPage = page
                        }
                    })  
                })
            }
            
            $ctrl.selectedPage = null
            $ctrl.selectPage = function (page) {
                var payLoad = {
                    page: page
                }
                $ctrl.selectedPage = page
                currentPageId = page.id
                $ctrl.pageSelectHandler(payLoad)
            }
        }]
    })
