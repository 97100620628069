angular
    .module('DigiLean')
    .directive("projectTasksCell", ['$filter', '$translate', '$uibModal', 'boardTaskService',
        function ($filter, $translate, $uibModal, boardTaskService) {
            return {
                templateUrl: 'projectTasksCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.taskCount = 0;
                    scope.taskCompletedCount = 0;
                    var projectId = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            if (scope.model.project.id) {
                                projectId = scope.model.project.id;
                                refresh();
                            }
                        }
                        model.getSortValue = function() {
                            return scope.taskCount;
                        }
                    });

                    function refresh(){
                        boardTaskService().getNoProjectTaskPerStatus(projectId).then(function (statusdata) {
                            scope.taskCompletedCount = statusdata.ok;
                            scope.taskCount = statusdata.blank + statusdata.ok + statusdata.unacceptable;
                        });
                    }

                    scope.subscribe('TaskStatusUpdated', function (statustask) {
                        if (statustask.status  === 'OK') {
                            refresh();
                        }
                    });

                    scope.subscribe('TaskDeleted', function (id) {
                        refresh();
                    });
                    scope.subscribe('NewTask', function (task) {
                        refresh();
                    });
           
                    
                scope.openTasks = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'taskListForm.html',
                        controller: 'taskListModalController',
                        windowClass: 'fullDeviation-modal-window',
                        resolve: {
                            projectId: function () {
                                return projectId;
                            }
                        }
                    });
                };
            }        
        }
    }]);