import { getCompanySetting } from "@common/stores/companySettingsStore"

angular
    .module('DigiLean')
    .directive("dataseriesselectorSteps", ['$rootScope', '$translate', '$filter', 'dataSourceService',
        function ($rootScope, $translate, $filter, dataSourceService) {
            return {
                templateUrl: 'dataseriesselectorSteps.html',
                restrict: 'E',
                scope: {
                    'serie': '='
                },
                link: function (scope, elem, attrs) {
                    scope.steps = [];
                    scope.currentStep = {};
                    scope.dataSources = null;


                    function loadDataSources(){
                        dataSourceService().getList().then(function(list) {
                            scope.dataSources = list;
                            getCompanySetting("currency").then(function (setting) {
                                var currency = null;
                                if (setting) {
                                    currency = setting
                                }
                                var internalDataSources = dataSourceService().getModuleList(currency);
                                scope.dataSources = scope.dataSources.concat(internalDataSources);
                                scope.dataSources = scope.dataSources.sort((a,b) => {
                                    if (a.title > b.title) return 1;
                                    if (a.title < b.title) return -1;
                                    return 0;
                                });
                            });
                            
                        });
                    }
                    loadDataSources();


                    scope.$watch('serie', function () {
                        reset();
                        if (scope.serie && scope.serie.datasource) {
                            setFormula(scope.serie);
                            setGraphWidth(scope.serie);
                            if(!scope.dataSources) loadDataSources();
                            var validDataSource = $filter('filter')(scope.dataSources, { id: scope.serie.datasource.id });
                            if (validDataSource.length > 0) {
                                scope.serie.datasource = validDataSource[0];
                            }
                        }
                    });

                    function setFormula(serie) {
                        serie.formula = "sum";
                        if(serie.average){
                            serie.formula = "average";
                        } else if(serie.accumulated){
                            serie.formula = "accumulated";
                        }
                    }

                    function setGraphWidth(serie) {
                        if(serie.width > 0){
                            serie.widthType = "input";
                        } else {
                            serie.widthType = "manual";
                        }
                    }

                    

                    function reset() {
                        angular.forEach(scope.dataSources, function (dataSource) {
                            angular.forEach(dataSource.elements, function (element) {
                                element.filter = null;
                            });
                        });
                        scope.currentStep = scope.steps[0];
                    }

                    $translate(['DASHBOARD_DATA_SOURCE', 'COMMON_FILTER', 'COMMON_LAYOUT', 'COMMON_PREVIEW']).then(function (translations) {
                        scope.steps.push({ id: 'DASHBOARD_DATA_SOURCE', title: translations.DASHBOARD_DATA_SOURCE });
                        scope.steps.push({ id: 'COMMON_FILTER', title: translations.COMMON_FILTER });
                        scope.steps.push({ id: 'COMMON_LAYOUT', title: translations.COMMON_LAYOUT });
                        //scope.steps.push({ id: 'COMMON_PREVIEW', title: translations.COMMON_PREVIEW }); TO BE IMPLEMENTED
                        scope.currentStep = scope.steps[0];
                    });
                    $rootScope.$on('$translateChangeSuccess', function () {
                        scope.steps[0].title = $translate.instant('DASHBOARD_DATA_SOURCE');
                        scope.steps[1].title = $translate.instant('COMMON_FILTER');
                        scope.steps[2].title = $translate.instant('COMMON_LAYOUT');
                        //scope.steps[3].title = $translate.instant('COMMON_PREVIEW'); TO BE IMPLEMENTED
                    });
                    scope.selectStep = function (step) {
                        scope.currentStep = step;
                    }
                    scope.nextStep = function () {
                        var currentIndex = scope.steps.indexOf(scope.currentStep);
                        if ((currentIndex + 1) < scope.steps.length) {
                            scope.selectStep(scope.steps[currentIndex + 1]);
                        }
                    }
                    scope.previousStep = function () {
                        var currentIndex = scope.steps.indexOf(scope.currentStep);
                        if (currentIndex > 0) {
                            scope.selectStep(scope.steps[currentIndex - 1]);
                        }
                    }

                    scope.setSerie = function (datasource) {
                        scope.serie.name = datasource.title;
                        scope.serie.formula = "sum"; //default configuration
                    }

                    scope.changeGraphWidth= function(){   
                        scope.serie.width = 0;
                    }

                    scope.changeFormula= function(serie){ 
                        if(serie.formula == 'average'){
                            scope.serie.average = true;
                            scope.serie.accumulated = false;
                        }  else if(serie.formula == 'accumulated'){
                            scope.serie.average = false;
                            scope.serie.accumulated = true;
                        } else {
                            scope.serie.average = false;
                            scope.serie.accumulated = false;
                        }
                    }

                    scope.filterChangedHandler = function (filters) {
                    };
                }
            }
        }]);