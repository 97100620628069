﻿import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('payingCustomersController', ['$scope', '$stateParams',
    function ($scope, $stateParams) {
        

        // Select tab based on input or default first.
         $scope.selectTab = function (tab) {
            $scope.indexTab = tabs.indexOf(tab);
            if (tab == "billinglist" && !hasLoadedCustomerStatus) {
                loadCustomerStatus();
            }
        }

        var tabs = ["billinglist", "invoicing", "invoices"];
        var activeTab = tabs[0];
        if ($stateParams.tab) {
            activeTab = $stateParams.tab;
        }
        $scope.selectTab(activeTab);
        
       
       var hasLoadedCustomerStatus = false; 
        function loadCustomerStatus() {
            hasLoadedCustomerStatus = true;
            customerService.getCustomerStatus().then(function (accounts) {
                $scope.accounts = accounts
                for (var i = 0; i < $scope.accounts.length; i++) {
                    $scope.accounts[i].due = calculateDue($scope.accounts[i]);
                    if ($scope.accounts[i].endDate) {
                        var daysLeft = timeService.getDurationInDays(moment(), $scope.accounts[i].endDate);
                        if ($scope.accounts[i].due >= daysLeft - 1) {
                            $scope.accounts[i].due = null;
                        }
                    }
                    if ($scope.accounts[i].due < 11) {
                        $scope.accounts[i].soonPaymentTime = true;
                    }
                    if ($scope.accounts[i].billing == 'YEARLY' && $scope.accounts[i].due < 46) {
                        $scope.accounts[i].soonPaymentTime = true;
                    }
                }
                
            });
        }



        function calculateDue(account) {
            var due = 0;
            var startDate = moment(account.startDate);
            if (account.billing == 'YEARLY') {

                var dayOfYear = startDate.dayOfYear();
                var currentDayOfYear = moment().dayOfYear();
                var diff = dayOfYear - currentDayOfYear;
                if (diff >= 0) {
                    due = diff;
                } else {
                    var totalDayInThisYear = moment().endOf("year").dayOfYear();
                    due = totalDayInThisYear - currentDayOfYear + dayOfYear;
                }
            } else {
                var dayOfMonth = startDate.get('date');
                var currentDayOfMonth = moment().get('date');
                var diff = dayOfMonth - currentDayOfMonth;
                if (diff >= 0) {
                    due = diff;
                } else {
                    var totalDayInThisMonth = moment().daysInMonth();
                    due = totalDayInThisMonth - currentDayOfMonth + dayOfMonth;
                }
            }
            return due;
        }
        function statusCounter(account) {
            if (account.isTrial) {
                $scope.trial = $scope.trial + 1;
            } else {
                if (account.billing == 'MONTHLY') {
                    $scope.monthlycustomers = $scope.monthlycustomers + 1;
                } else if (account.billing == 'YEARLY') {
                    $scope.yearlycustomers = $scope.yearlycustomers + 1;
                }
            }
        }





    }]);