import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("mySpaceCard", 
        function () {
            return {
                templateUrl: 'mySpaceCard.html',
                restrict: 'E',
                scope: {
                },
                link: function (scope, elem, attrs) {
                    
                    scope.url = staticFileService.image("myspace.jpg");

                }
            };
        });
