angular
    .module('DigiLean')
    .directive("a3OwnerCell", ['$filter', '$translate', 'a3Service', 'taskDrawingService', 'projectService',
        function ($filter, $translate, a3Service, taskDrawingService, projectService) {
            return {
                templateUrl: 'a3OwnerCell.html',
                restrict: 'E',
                scope: { 
                    'model': '=',
                    'editable': '=',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.user = null;

                    scope.subscribe("A3OwnerChanged", function (a3) {
                        if (a3.a3Id == scope.model.a3.id) {
                            var matchingUser = $filter('filter')(scope.users, { userId: a3.ownerUserId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });
                    scope.$watch("users", function (users) {
                        if (!users) return;
                        mapUser();
                    });

                    var mapUser = function() {
                        if (scope.user === null && scope.model.a3.ownerUserId && scope.users) {
                            var matchingUser = $filter('filter')(scope.users, { userId: scope.model.a3.ownerUserId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                            }
                        }
                    }

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.a3) {
                            if (scope.model.a3.ownerUserId) {
                                mapUser();
                            }
                        }
                        model.getSortValue = function () {
                            if (scope.user) {
                                return scope.user.fullName;
                            }
                            return "";
                        }
                    });

                    
                    scope.clearSelectedUser = function () {
                        var emptyUser = {
                            userId : null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.user = null;
                        scope.selectUser(emptyUser);
                        
                    };
                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.displayname = user.displayName;
        
                        var a3Owner = 
                        {
                            a3Id : scope.model.a3.id,
                            ownerUserId : user.userId,
                            fullName : user.fullName
                        };
                        a3Service().updateOwner(a3Owner).then(function (registeredowner) {
                        });
                    };
                }
            }
        }]);