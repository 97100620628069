﻿import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"

var DigiLean = angular.module('DigiLean')
DigiLean.directive("splitPackedBubbleChart", ['$filter', '$translate', '$uibModal', 'dataService', 'projectService', 'strategyService','deviationService', 'areaService', 'suggestionService', 'a3Service', 'a3templateService','dataListService','debounce',
    function ($filter, $translate, $uibModal, dataService, projectService, strategyService,deviationService, areaService, suggestionService, a3Service, a3templateService, dataListService, debounce) {
        return {
            templateUrl: 'splitPackedBubbleChart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'globalTimePeriod': '@',
                'settings': '=',
                'settingsChangeHandler': '&',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                
                scope.title = ""
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                scope.projects = null;
                scope.strategygoals = null;
                var assets = null;
                var deviationTypes = null;
                var problemCategories = null;
                var groupColumn = "projectId";
                var stackingColumn = "status";
                var categorySummaryData = null;
               
                var groupingElementFound = false;
                
                var listGroupedElements = null;

                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);


                /* This method will be called also when component is first initialized */
                scope.periodChangedHandler = function (timePeriod) {

                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    if (timePeriod.timeframe === "all" && scope.datasource) {
                        dataService().getFirstDate(scope.dataSource.id, scope.timePeriod.period, null).then(function (fromDate) {
                            scope.timePeriod.period.from = fromDate;
                            initialize(groupColumn);
                        });
                    }
                    else {
                        initialize(groupColumn);
                    }

                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }

                }

                function capitalizeFirstLetter(string) {
                    if (!string) return string;
                    return string[0].toUpperCase() + string.slice(1);
                }

                function loadData() {
                    var grouping = {
                        sourceColumn: capitalizeFirstLetter(groupColumn) //"ProjectId",       
                    };
                    var stacking = {
                        sourceColumn: capitalizeFirstLetter(stackingColumn) //"Status",       
                    };

                    var options = {
                        groupingElement: grouping,
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: scope.timePeriod.period,
                        filters: scope.filters,
                        stackingElement: stacking
                    };
                    scope.optimizationChallenges = false;
                    dataService().getStackedGroupedSum(scope.dataSource.id, options).then(function (data) {
                        if (!data) {
                            console.log("No values for data source:" + scope.dataSource.id);
                            return;
                        } else {
                            var noOfBubbles = data.length;
                            if(noOfBubbles > 150){
                                scope.optimizationChallenges = true;
                            } else {
                                categorySummaryData = data;
                                drawChart(data);
                                resizeChart();
                            }
                        }     
                    });
                }

                // Subscribe to component related event
                scope.subscribe("DataValueAdded", function (value) {
                    if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                    initialize(groupColumn);
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                    initialize(groupColumn);
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                    initialize(groupColumn);
                });

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    if (settings) {

                        scope.settings = settings;
                        if (settings.title) {
                            scope.title = settings.title;
                        }
                        if (settings.dataSource) {
                            scope.dataSource = settings.dataSource;
                        }
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (settings.groupColumn) {
                            groupColumn = settings.groupColumn;
                        }
                        if (settings.stackingColumn) {
                            stackingColumn = settings.stackingColumn;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        initialize(groupColumn);
                    }
                    if (!scope.dataSource && scope.isAdminMode) {
                        setTimeout(function () {
                            scope.selectDataSource();
                        }, 200);
                    }
                });

                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                function initialize(groupedBy) {
                    if (!scope.dataSource) return;
                    if (groupedBy === "areaId") {
                        areaService().getList(true).then(function (areas) {
                            scope.areas = areas;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                        assetService.getAllAssetsIncludingSystem().then(function (data) {
                            assets = data;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "projectId") {
                        projectService().getList(true).then(function (data) {
                            scope.projects = data;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "strategyGoalId") {
                        strategyService().getBreakthroughObjectives().then(function (goals) {
                            scope.goals = goals;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    }
                    else if (groupedBy === "lossTypeId") {
                        deviationService().getConsequences().then(function (data) {
                            scope.consequences = data;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    }
                    else if (groupedBy === "problemCategoryId") {
                        deviationService().getProblemCategories().then(function (categories) {
                            problemCategories = categories;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "deviationTypeId") {
                        deviationService().getAllDeviationTypes().then(function (types) {
                            deviationTypes = types;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "categoryId") {
                        if (scope.dataSource.id.startsWith("Improvement")) {
                            suggestionService().getCategories().then(function (categories) {
                                scope.suggestionCategories = categories;
                                if (!groupingElementFound) {
                                    groupingElementFound = true;
                                    initialize(stackingColumn);
                                } else {
                                    loadData();
                                }
                            });
                        } else {//assue A3 categories
                            a3Service().getCategories().then(function (categories) {
                                scope.a3Categories = categories;
                                if (!groupingElementFound) {
                                    groupingElementFound = true;
                                    initialize(stackingColumn);
                                } else {
                                    loadData();
                                }
                            });
                        }
                    } else if (groupedBy === "boardId") {
                        boardService.getAll().then(function (boards) {
                            scope.boards = boards;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                        customerService.getAllUsers().then(function (users) {
                            scope.users = users;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "a3TemplateId") {
                        a3templateService().getList().then(function (templates) {
                            scope.a3templates = templates;
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        });
                    } else if (groupedBy === "value") {
                        if (!groupingElementFound) {
                            groupingElementFound = true;
                            initialize(stackingColumn);
                        } else {
                            loadData();
                        }
                    } else if (groupedBy === "dimension" || groupedBy === "dimension2" || groupedBy === "dimension3" || groupedBy === "dimension4") {
                        var listId = checkElementType(groupedBy, 'list');
                        if(listId > 0){
                            var listOptions = {includeDeletedItems:true};
                            dataListService().getItems(listId,listOptions).then(function (data) {
                                listGroupedElements = data;
                                if (!groupingElementFound) {
                                    groupingElementFound = true;
                                    initialize(stackingColumn);
                                } else {
                                    loadData();
                                }
                            });
                        }  else if (checkElementType(groupedBy, 'user') == 1) {
                            customerService.getAllUsers().then(function (users) {
                                scope.users = users;
                                if (!groupingElementFound) {
                                    groupingElementFound = true;
                                    initialize(stackingColumn);
                                } else {
                                    loadData();
                                }
                            });
                        } else {
                            if (!groupingElementFound) {
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else {
                                loadData();
                            }
                        }
                    }
                }

                function checkElementType(sourceCol, typeChecking){
                    var ref = -1;
                    var element = $filter('filter')(scope.dataSource.elements, { sourceColumn: sourceCol }, true);
                    if(element.length>0){
                        var type = element[0].type;
                        if(type === typeChecking && typeChecking === 'list'){
                            return  element[0].dataListId;
                        } else if (type === typeChecking){
                            return  1;
                        }
                    }
                    return ref;
                } 

                function getPointName(element, groupedBy) {
                    if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                        return getNameById(assets, element);
                    } else if (groupedBy === "priorityStatus") {
                        return suggestionService().getPriorityStatusText(parseInt(element));
                    } else if (groupedBy === "problemCategoryId") {
                        return getNameById(problemCategories, element);
                    } else if (groupedBy === "a3TemplateId") {
                        return getNameById(scope.a3templates, element);
                    } else if (groupedBy === "categoryId") {
                        if (scope.dataSource.id.startsWith("Improvement")) {
                            return getNameById(scope.suggestionCategories, element);
                        } else {    // Only A3
                            return getNameById(scope.a3Categories, element);
                        }
                    } else if (groupedBy === "areaId") {
                        return getNameById(scope.areas, element);
                    } else if (groupedBy === "lossTypeId") {
                        return getNameById(scope.consequences, element);
                    } else if (groupedBy === "projectId") {
                        return getNameById(scope.projects, element);
                    } else if (groupedBy === "strategyGoalId") {
                        return getNameById(scope.goals, element);   
                    } else if (groupedBy === "deviationTypeId") {
                        return getNameById(deviationTypes, element);
                    } else if (groupedBy === "boardId") {
                        return getNameById(scope.boards, element);
                    } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                        return getNameByUserId(scope.users, element);
                    } else if (groupedBy === "dimension"  || groupedBy === "dimension2" || groupedBy === "dimension3" || groupedBy === "dimension4") {
                        if (checkElementType(groupedBy, 'user') == 1) {
                            return getNameByUserId(scope.users, element);
                        } else if (checkElementType(groupedBy, 'list') !== -1){
                            return getNameById(listGroupedElements, element);
                        }    
                        else {    
                            return element;
                        }     
                    } else if (groupedBy === "severity") {
                        switch (element) {
                            case "1":
                                return $translate.instant('COMMON_LOW');
                            case "2":
                                return $translate.instant('COMMON_MEDIUM');
                            case "3":
                                return $translate.instant('COMMON_HIGH');
                            default:
                                return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    } else if (groupedBy === "status") {
                        switch (element) {
                            case "0":
                                return $translate.instant('COMMON_NEW');
                            case "10":
                                return $translate.instant('DEVIATION_IN_PROGRESS');
                            case "20":
                                return $translate.instant('DEVIATION_RESOLVED');
                            case "30":
                                return $translate.instant('COMMON_REJECTED');
                            case "unacceptable":
                                return $translate.instant('BOARD_INCOMPLETED');
                            case "blank":
                                return $translate.instant('BOARD_OPEN');
                            case "OK":
                                return $translate.instant('DASHBOARD_COMPLETED');
                            default:
                                return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    } else {
                        return element;
                    }
                }

                let selectDataSourceModal
                scope.selectDataSource = function () {
                    if (selectDataSourceModal)
                        return

                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceStackedGroupSelector.html',
                        controller: 'dataSourceStackedGroupSelector',
                        windowClass: 'dataserie-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            groupedBy: function () {
                                return stackingColumn;
                            },
                            stackedColumn: function () {
                                return groupColumn;
                            },
                            filters: function () {
                                return scope.filters;
                            }
                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        groupColumn = result.stackedColumn;
                        stackingColumn = result.groupedBy,
                        scope.filters = result.filters;
                        scope.updateSettings();
                        initialize(groupColumn);
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                };

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.timePeriod,
                        dataSource: scope.dataSource,
                        groupColumn: groupColumn,
                        stackingColumn: stackingColumn,
                        filters: scope.filters,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                function getColorById(id, source) {
                    if (id && source) {
                        if (source === "strategyGoalId") {
                            for (var k = 0; k < scope.goals.length; k++) {
                                if (scope.goals[k].id.toString() === id.toString()) {
                                    if (scope.goals[k].color) {
                                        return scope.goals[k].color;
                                    
                                    } else {
                                        return null;
                                    }
                                }
                            }
                        }
                    }
                }

                function getNameById(source, id) {
                    if (id && source) {
                        for (var k = 0; k < source.length; k++) {
                            if (source[k].id.toString() === id.toString()) {
                                if (source[k].name) {
                                    return source[k].name;
                                } else if (source[k].title) {
                                    return source[k].title;
                                } else {
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                                }
                            }
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                }

                // Used when grouped by user
                function getNameByUserId(source, id) {
                    if (id && source) {
                        for (var k = 0; k < source.length; k++) {
                            if (source[k].userId.toString() === id.toString()) {
                                if (source[k].fullName) {
                                    return source[k].fullName;
                                } else if (source[k].userName) {
                                    return source[k].userName;
                                } else {
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                                }
                            }
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                }
                // Draw options
                var splitPackedBubbleChart;

                
                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight
                    
                    var chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (splitPackedBubbleChart)
                        splitPackedBubbleChart.setSize(width, height, true)
                }
                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

                function drawChart(data) {
                    scope.xAxisData = [];

                    if (data) {
                        var xAxisId = [];
                        var distinctStackedElements = [];
                        var totalSerie = [];
                        for (var i = 0; i < data.length; i++) {
                            var index = xAxisId.indexOf(data[i].element);
                            if (index < 0) {
                                xAxisId.push(data[i].element);
                                var xAxisDataLabel = getPointName(data[i].element, groupColumn);
                                scope.xAxisData.push(xAxisDataLabel);
                            }

                            var stackedindex = distinctStackedElements.indexOf(data[i].stackedElement);
                            if (stackedindex < 0) {
                                distinctStackedElements.push(data[i].stackedElement);
                                totalSerie.push(0);
                            }
                        }
                    }

                    scope.seriesData = [];
                    var i = distinctStackedElements.length - 1;

                    for (i; i >= 0; i--) {
                        var name = getPointName(distinctStackedElements[i], stackingColumn);
                        //var color = grafcolor[i];
                        var dataValues = [];
                        var stackedidtofilter = distinctStackedElements[i];
                        var elementsWithGivenStackedValue = $filter('filter')(data, { stackedElement: distinctStackedElements[i] }, true);
                        
                        for (var y = 0; y < elementsWithGivenStackedValue.length; y++) {
                            
                            var label = getPointName(elementsWithGivenStackedValue[y].element, groupColumn);
                            var miniboble = {
                                name: label,
                                value: elementsWithGivenStackedValue[y].value
                            }
                            dataValues.push(miniboble);
                            totalSerie[i] = totalSerie[i] + elementsWithGivenStackedValue[y].value;
                            
                        }
                        var element = {
                            name: name,
                            data: dataValues,
                            color: getColorById(distinctStackedElements[i], stackingColumn)
                        };
                        scope.seriesData.push(element);
                        resizeChart();
                    }
                    var maxSize = Math.max.apply(null, totalSerie)*1.5;

                    var renderElement = $(elem).find(".split-packed-bubble-chart")[0];
                    splitPackedBubbleChart = new Highcharts.Chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'packedbubble',
                            height: '100%',
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        title: {
                            text: ''
                        }, credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },/*
                        tooltip: {
                            headerFormat: '<b>{point.x}</b><br>',
                            pointFormat: '{series.name}: {point.y}<br>Totalt: {point.stackTotal}'
                        },*/
                        tooltip: {
                            useHTML: true,
                            pointFormat: '<b>{point.name}</b>: {point.value}' //'<b>{point.name}</b>: {point.value}<br>Totalt: {point.z}'
                        },/*
                         pointFormat: '<b>{point.name}:</b> {point.value}m CO<sub>2</sub>'
                        tooltip: {
                            useHTML: true,
                            pointFormat: '<b>{point.name}</b> {point.y} {point.x}: {point.value}'
                        },*/
                        plotOptions: {
                            packedbubble: {
                                minSize: '20%',
                                maxSize: '100%',
                                zMin: 0,
                                zMax: maxSize,
                                layoutAlgorithm: {
                                    gravitationalConstant: 0.05,
                                    splitSeries: true,
                                    seriesInteraction: false,
                                    dragBetweenSeries: false,
                                    parentNodeLimit: true
                                },
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}',
                                    /*filter: {
                                        property: 'y',
                                        operator: '>',
                                        value: 4
                                    },*/
                                    style: {
                                        color: 'black',
                                        textOutline: 'none',
                                        fontWeight: 'normal',
                                        fontSize: "0.9em"
                                    }
                                }
                            }
                        },
                        series: scope.seriesData,
                        legend: {
                            itemStyle: {
                                fontSize: "0.9em"
                            }
                        }
                    });
                }

            }
        }
    }]);
