import backend from "@common/services/backendHttp"
import type { Connector, DataValue, Job, JobType, LogMessage } from "@api"

const resourceUrl = "api/connector"

export const addConnector = async (connector: Connector) => {
    const url = `${resourceUrl}/add`
    return await backend.post<Connector>(url, connector)
}
export const getList = async () => {
    const url = `${resourceUrl}/getList`
    return await backend.get<Connector[]>(url)
}
export const get = async (id: string) => {
    const url = `${resourceUrl}/get/${id}`
    return await backend.get<Connector>(url)
}
export const update = async (connector: Connector) => {
    const url = `${resourceUrl}/update`
    return await backend.put<Connector>(url, connector)
}
export const deleteConnector = async (id: string) => {
    const url = `${resourceUrl}/delete/${id}`
    return await backend.delete<Connector>(url)
}
export const getJobs = async (connectorId: string) => {
    const url = `${resourceUrl}/jobs/getList/${connectorId.toLowerCase()}`
    return await backend.get<Job[]>(url)
}
export const getJob = async (id: number) => {
    const url = `${resourceUrl}/jobs/get/${id}`
    return await backend.get<Job>(url)
}
export const addJob = async (job: Job) => {
    const url = `${resourceUrl}/jobs/add`
    return await backend.post<Job>(url, job)
}
export const updateJob = async (job: Job) => {
    const url = `${resourceUrl}/jobs/update`
    return await backend.put<Job>(url, job)
}
export const deleteJob = async (id: number) => {
    const url = `${resourceUrl}/jobs/delete/${id}`
    return await backend.delete<Job>(url)
}
export const getJobTypes = async () => {
    const url = `${resourceUrl}/jobTypes/getList`
    return await backend.get<JobType[]>(url)
}
export const getLastValues = async (job: Job) => {
    const url = `/values/getLatestValues/${job.dataSourceId}`
    const options = { numberOfValues: 10, sortOrder: "Desc" }
    return await backend.post<DataValue[]>(url, options)
}
export const getLastErrors = async (job: Job) => {
    const url = `${resourceUrl}/jobs/getErrors/${job.id}`
    var options = { skip: 0, take: 10 }
    return await backend.post<LogMessage[]>(url, options)
}
export const getLastLogs = async (job: Job) => {
    const url = `${resourceUrl}/jobs/getLogs/${job.id}`
    var options = { skip: 0, take: 20 }
    return await backend.post(url, options)
}