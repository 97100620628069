import * as profileService from "@common/services/user/profileService"

var DigiLean = angular.module('DigiLean');  
DigiLean.controller('excelImporterController', ['$scope', '$uibModalInstance', 'dataSourceId',
    function ($scope, $uibModalInstance, dataSourceId) {
    
        $scope.fileUrl = "";
        $scope.dataSourceId = dataSourceId;
        $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
        $scope.fileUploaded = function (file) {
            $scope.fileUrl = "";
            profileService.updateProfileImage(file.id).then(function (profile) {
                getProfile();
            });
        };

        $scope.isProcessing = false;
        $scope.ok = function () {
            $scope.isProcessing = true;
            $uibModalInstance.close('ok');
        };



        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);