import * as boardService from "@common/services/boardService"

angular
    .module("DigiLean")
    .directive('weeklyBoard', ['$filter', '$uibModal','debounce',
        function ($filter, $uibModal, debounce) {
            return {
                templateUrl: 'weeklyboard.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'assetId': '<',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.boards = [];
                    scope.selectedBoard = null;

                    scope.boardData = null;
                    scope.hasBoard = false;
                    scope.options = {
                        fixedHeader: false
                    }
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            loadBoard(settings.boardId);
                        }
                        boardService.getMemberBoards().then(function (boards) {
                            for (let index = 0; index < boards.length; index++) {
                                const board = boards[index];
                                if (isWeeklyBoard(board)) {
                                   scope.boards.push(board);
                                }
                            }
                            // Set selected 
                            if (scope.settings && scope.settings.boardId) {
                                var selected = $filter('filter')(scope.boards, { id: settings.boardId });
                                if (selected.length > 0) {
                                    scope.selectedBoard = selected[0];
                                }
                            }
                        });
                        
                    });

                    var isWeeklyBoard = function(board) {
                        if (board.boardType) {
                            if (board.boardType == "Weekly") return true;
                        }
                        if (board.settings) {
                            var settings = JSON.parse(board.settings);
                            if (settings.IsWeeklyBoard) {
                                return true;
                            } 
                        } else {
                            scope.isWeeklyBoard = false;
                        }
                    }
                    scope.boards = [];
                    scope.boardChanged = function (selectedBoard) {
                        loadBoard(selectedBoard.id);
                        scope.selectedBoard = selectedBoard;
                        updateSettings();
                    }

                    var currentBoardId = 0;
                    var loadBoard = function (boardId) {
                        if (currentBoardId == boardId) return;
                        currentBoardId = boardId;
                        if (boardId == 0) {
                            scope.hasBoard = false;
                            return;    
                        }
                        scope.isLoading = true;
                        scope.hasBoard = true;
                        boardService.get(boardId).then(function (board) {
                            scope.boardData = board;
                            setTimeout(function () {
                                scope.isLoading = false;
                                updateContainerHeightDebounced();
                            }, 500);
                        })
                    }

                    function updateSettings() {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                boardId: scope.selectedBoard.id,
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }
                   

                    scope.createBoard = function(){
                        var template = boardService.createWeeklyBoardTemplate();
                        var savetemplate = {
                            board: {
                                name: template.template.name,
                                boardType: template.template.boardType,
                                settings: template.template.settings
                            },
                            columns: [],
                            rows: []
                        };
                        // set attributes
                        if (template.attributes) savetemplate.attributes = template.attributes;
                        // Parse columns
                        template.columns.forEach(function (col) {
                            savetemplate.columns.push(
                                {
                                    name: col.name,
                                    weekdayName: col.weekdayName,
                                    sortOrder: col.sortOrder,
                                    categoryStyle: JSON.stringify(col.categoryStyle)
                                });
                        });
                        template.rows.forEach(function (row) {
                            savetemplate.rows.push(
                                {
                                    name: row.name,
                                    sortOrder: row.sortOrder,
                                    categoryStyle: JSON.stringify(row.categoryStyle),
                                    weekdayName: row.weekdayName
                                });
                        });
                        var options = {
                            dashboardAsTemplateId: null,
                            assetId: scope.assetId,
                            operation: "newTaskBoard",
                            template: savetemplate,
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newFlexiboardConfiguration.html',
                            controller: 'newFlexiboardConfigurationController',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                            scope.publish("SendBoardCreated", result);
                            scope.boards.push(result.board);
                            scope.boardChanged(result.board);
                        });
                    }

                    function updateContainerHeight() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 100;
                        var container = $(elem).find(".weeklyboard");
                        if (container.length === 0) return;
                        var targetHeight = height - headerHeight;
                        $(container).css("height", targetHeight);
                        scope.options = {
                            targetHeight: targetHeight
                        }
                    }
                    const updateContainerHeightDebounced = debounce(updateContainerHeight, 200, false)
                    updateContainerHeightDebounced()
                    scope.$on("widget-resized", function (event, args) {
                        updateContainerHeightDebounced();
                    });

                }
            }
        }]);

