angular.module('DigiLean').factory('themeService', [
    function () {
        function themeService() {

            return {

                getColors: function(){
                   return ["#FCBB42", "#3BAFDA", "#8CC152", "#ED5565", "#E9573F", "#D770AD", "#c18852", "#5254c1",
                   "#fcea42", "#52c1c0", "#c1bf52", "#449d44", "#8b8d92", "#202020", "#13105e", "#AC92EC"];
                }
            };
        }
        return themeService;
    }]);

