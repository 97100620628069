import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("yearlyTask", ['$filter', '$translate', 'modalService', 'debounce', 'boardTaskService','$uibModal','suggestionService','deviationService',
        function ($filter, $translate, modalService, debounce, boardTaskService, $uibModal, suggestionService, deviationService) {
            return {
                templateUrl: 'yearlyTask.html',
                restrict: 'E',
                scope: {
                    'canEdit': '<',
                    'task': '<',
                    'users': '<',
                    'detailLevel': '<',
                    'postits': '<',
                    'taskDurationChangedHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.text = "";
                    scope.borderColor = "#337ab7";  // borderColor
                    scope.fillColor = "white";
                    scope.editable = true;
                    scope.subTaskCount = 0;
                    scope.subTaskCompletedCount = 0;
                    // Dates

                    scope.enddateBeforeStartdate = false;

                   
                    scope.$watch('task', function (task) {
                        // var value = model.projectAttribute.value;
                        if (task) {
                            setValue(task);
                        }
                    });
                    scope.$watch('detailLevel', function (level) {
                        // var value = model.projectAttribute.value;
                        var l = level;
                    });
                    scope.$watch('users', function (users) {
                        // var value = model.projectAttribute.value;
                        if (users) {
                            setProfileImage();
                        }
                    });
                    scope.$watch('postits', function (postits) {
                        // var value = model.projectAttribute.value;
                        if (postits) {
                            scope.postIts = postits
                            // Set color when we know postits has been set.
                            if (scope.task) {
                                setColor(scope.task.color);
                            }
                        }
                    });

                    var setValue = function (task) {
                        if (task.startTime) {
                            // Make sure boardDate is date object 
                            task.startTime = moment(task.startTime).toDate();
                        } else {
                            if(task.boardDate){
                                task.startTime = moment(task.boardDate).toDate();
                            }
                        }
                        if (task.endTime) {
                            // Make sure boardDate is date object 
                            task.endTime = moment(task.endTime).toDate();
                        } else {
                            if(task.boardDate){
                                task.endTime = moment(task.boardDate).toDate();
                            }
                        }
                        task.isVisible = true;
                        task.duration = timeService.getDurationInDays(task.startTime, task.endTime);
                        if (task.responsibleUserId) {
                            setProfileImage();
                        }

                        setColor(task.color);
                        scope.subTaskCount = task.subTaskCount;
                        scope.subTaskCompletedCount = task.subTaskCompletedCount;

                    }

                    var setColor = function (taskColor) {
                        // Try to match fillColor
                        if (!scope.postIts) return;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { fillColor: taskColor });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                            setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                        } else {
                            // Try to match background color
                            var backgroundPostIt = $filter('filter')(scope.postIts, { background: taskColor });
                            if (backgroundPostIt.length > 0) {
                                scope.selectedPostIt = backgroundPostIt[0];
                                setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                            } else if (taskColor == "#ffffff"){
                                setStyle(scope.fillColor, scope.borderColor);
                            } else {
                                // custom color or previous predefined labels
                                setStyle(taskColor, taskColor);
                            }
                        }

                    }

                    var setProfileImage = function () {
                        if (!scope.task || !scope.task.responsibleUserId || !scope.users) return;
                        
                        for (var i = 0; i < scope.users.length; i++) {
                            var user = scope.users[i];
                            if (user.profileImageUrl && user.userId == scope.task.responsibleUserId) {
                                scope.task.responsibleImageUrl = user.profileImageUrl;
                            }
                        }
                    }

                    var setStyle = function (fillColor, borderColor) {
                        var task = scope.task;
                        if (!task.style) {
                            task.style = {}
                        }
                        if (fillColor == "#ffffff") {
                            borderColor = scope.borderColor;
                        }
                        task.style.fillColor = fillColor;
                        task.style.borderColor = borderColor;
                    }

                    function checkIsenddateBeforeStartdate(){
                        scope.enddateBeforeStartdate = false;
                        if(moment(scope.task.endTime).isBefore(scope.task.startTime)){
                            scope.enddateBeforeStartdate = true;
                        }
                        return scope.enddateBeforeStartdate
                    }

                    scope.cloneTask = function () {
                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'clonetask.html',
                            controller: 'clonetaskController',
                            resolve: {
                                task: function () {
                                    return scope.task;
                                }
                            }
                        });
                    }

                    scope.repeatTask = function () {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'repeattask.html',
                            controller: 'repeattaskController',
                            resolve: {
                                task: function () {
                                    return scope.task;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                            // publish update
                            scope.publish('SendTaskRepeated', result);
                        });
                    }

                    scope.moveTask = function () {
                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'movetask.html',
                            controller: 'movetaskController',
                            resolve: {
                                task: function () {
                                    return scope.task;
                                }
                            }
                        });
                    }
                    
                    scope.openImprovement = function (improvementid) {
                        suggestionService().get(improvementid).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    }
                    scope.openDeviation = function (deviationid) {
                        deviationService().get(deviationid).then(function (deviation) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    }
                    scope.openCloneInfo = function(task) {
                       
                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'cloneInfo.html',
                            controller: 'cloneInfoController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    }

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        setStyle(postIt.fillColor, postIt.background)
                        if(postIt.tags){
                            var tagArray = postIt.tags;
                            for (var i = 0; i < tagArray.length; i++) {
                                $("#taskTags").tagit('createTag', tagArray[i]);
                            }
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        scope.commitChangesDebounced();
                    }

                    scope.emptyUser = {
                        id: '',
                        userName: null,
                        profileImageUrl: null,
                        fullName: null,
                        displayName: null
                    }
                    scope.selectUser = function (user) {

                        var assignUser = {
                            taskId: scope.task.id,
                            previousUserId: scope.task.responsibleUserId,
                            previousUserName: scope.task.responsibleUser,
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName
                        }
                        var options = {
                            taskId: scope.task.id,
                            user: assignUser
                        };

                        scope.task.responsibleImageUrl = user.profileImageUrl;
                        scope.task.responsibleUserId = user.id;
                        scope.task.responsibleUser = user.fullName;
                        scope.task.responsibleDisplayName = user.displayName;
                        boardTaskService().assignUser(options).then(function () {

                        });
                        //boardTaskService()
                    }

                    scope.startTimeUpdated = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.task.startTime = date
                        if(checkIsenddateBeforeStartdate()) {
                            scope.startTimeOpened = false;
                            scope.endTimeOpened = false;
                            return;
                        }
                        scope.taskDurationChangedHandler({ task: scope.task })
                        scope.commitDurationChanged();
                        
                    }
                    scope.endTimeUpdated = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.task.endTime = date
                        if(checkIsenddateBeforeStartdate()) {
                            scope.startTimeOpened = false
                            scope.endTimeOpened = false
                            return
                        }
                        scope.taskDurationChangedHandler({ task: scope.task })
                        scope.commitDurationChanged()
                    }

                    scope.saveChanges = function () {
                        var task = {
                            id: scope.task.id,
                            title: scope.task.title,
                            text: scope.task.text,
                            color: scope.task.style.borderColor,
                            tags: scope.tags
                        };
                        boardTaskService().updateTask(task).then(function (updateTask) {

                        });
                    }
                    scope.saveDuration = function () {
                        if(!scope.enddateBeforeStartdate){
                            var task = scope.task;
                            var duration = {
                                startTime: task.startTime,
                                endTime: task.endTime
                            }
                            boardTaskService().updateDuration(task.id, duration).then(function (updateTask) {

                            });
                        }
                    }

                    scope.setStatus = function (status) {
                        scope.task.status = status;
                        var taskStatus = {
                            status: status,
                            taskId: scope.task.id,
                            responsibleUserId: scope.task.responsibleUserId
                        }
                        boardTaskService().updateStatus(scope.task.id, taskStatus).then(function (result) {

                        });
                    }


                    scope.delete = function () {
                        var taskId = scope.task.id;
                        var repeatParentId = scope.task.repeatParentId;
                        var siblingId = scope.task.siblingId;
            
                        if (!repeatParentId && !siblingId) {
                            var modalOptions = {
                                closeButtonText: $translate.instant("COMMON_CANCEL"),
                                actionButtonText: $translate.instant("COMMON_DELETE"),
                                headerText: $translate.instant("COMMON_DELETE_ITEM"),
                                bodyText: $translate.instant("COMMON_DELETE_ITEM_DESCRIPTION"),
                            };
    
                            modalService.showModal({}, modalOptions).then(function (result) {
                                boardTaskService().deleteTask(scope.task.id).then(function () {
    
                                });
                            });
                        }
            
                        if (repeatParentId) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'repeatdelete.html',
                                controller: 'repeatdeleteController',
                                windowClass: 'newdeviation-modal-window',
                                resolve: {
                                    taskId: function () {
                                        return taskId;
                                    },
                                    repeatParentId: function () {
                                        return repeatParentId;
                                    }
                                }
                            });
                            modalInstance.result.then(function (result) {
                                //$uibModalInstance.close(scope.task);
                            });
                        }
            
                        if (siblingId) {
            
                            $translate(['COMMON_CANCEL', 'BOARD_DELETE_TASKS', 'BOARD_DELETE_CLONED_TASK', 'BOARD_DELETE_CLONED_TASK_DESCRIPTION']).then(function (translations) {
                                var modalOptions = {
                                    closeButtonText: translations.COMMON_CANCEL,
                                    actionButtonText: translations.BOARD_DELETE_TASKS,
                                    headerText: translations.BOARD_DELETE_CLONED_TASK,
                                    bodyText: translations.BOARD_DELETE_CLONED_TASK_DESCRIPTION
                                };
            
                                modalService.showModal({}, modalOptions).then(function (result) {
                                    boardTaskService().deleteClonedTask(siblingId).then(function (result) {
                                        scope.publish("SendTasksDeleted", result);
                                        //$uibModalInstance.dismiss('cancel');
                                    });
                                });
                            });
                        }
                    }

                    scope.copyTask = function (task) {
                        boardTaskService().copyTask(task.id);
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);
                    scope.commitDurationChanged = debounce(scope.saveDuration, 1000, false);

                    scope.subscribe('TaskUpdated', function (task) {
                        if (task.id == scope.task.id) {
                            scope.task.title = task.title;
                            scope.task.description = task.description;
                            scope.task.tags = task.tags;
                            scope.task.color = task.color;
                            scope.task.siblingId = task.siblingId;
                            setColor(scope.task.color);
                            scope.task.animate("pulse");
                        }
                    });
                    scope.subscribe('TaskAssigned', function (taskAssigned) {
                        if (taskAssigned.taskId == scope.task.id) {
                            scope.task.responsibleUserId = taskAssigned.userId;
                            scope.task.responsibleUser = taskAssigned.userName;
                            scope.task.responsibleDisplayName = taskAssigned.userDisplayName;
                            setProfileImage();
                            scope.task.animate("pulse");
                        }
                    });
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        if (status.taskId == scope.task.id && scope.task.status != status.status) {
                            scope.task.status = status.status;
                            if (status.status == "OK") {
                                scope.task.animate("tada");
                            } else {
                                scope.task.animate("bounce");
                            }

                        }
                    });

                    scope.subscribe("SubTaskCountChanged", function (count) {
                        if (count.taskId == scope.task.id || count.taskId == scope.task.siblingId) {
                            scope.subTaskCount = count.subTaskCount;
                            scope.subTaskCompletedCount = count.subTaskCompletedCount;
                        }
                    });

                    scope.openTask = function (taskId, tab) {
                        var options = {
                            archiveAvailable: false
                        }
                        if(taskId == scope.task.id){
                            options = {
                                archiveAvailable: false,
                                postIts: scope.postIts,
                                startdateAvailable: true,
                                users: scope.users
                            }
                        }
                        boardTaskService().get(taskId).then(function (task) {
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return tab;
                                    },
                                    options: function () {
                                        return options;
                                    }
                                }
                            });
                        });
                    };
                }


            }
        }]);
