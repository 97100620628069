import * as staticFileService from "@common/shared/staticFileService"

angular
.module('DigiLean')
    .directive("customerKSCard", 
        function () {
            return {
                templateUrl: 'customerKSCard.html',
                restrict: 'E',
                scope: {
                },
                link: function (scope, elem, attrs) {
                    
                    scope.url = staticFileService.image("customerKS.jpg");

                }
            };
        });
