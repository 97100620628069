import { Subscription } from "rxjs"
var DigiLean = angular.module('DigiLean');
DigiLean.controller('deviationTypeAdminController', ['$scope', '$filter', 'deviationService','$uibModal','$state','$translate','modalService',
    function ($scope, $filter, deviationService, $uibModal, $state, $translate, modalService) {
        $scope.types = []

        var load = function() {
            deviationService().getAllDeviationTypes().then(function(types) {
                $scope.types = types;
            });
        }

        load();

        $scope.editSettings = function(type) {
            $state.go('deviationType', { id: type.id }, { reload: false });
        }

        $scope.edit = function (type) {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'editDeviationType.html',
                controller: 'editDeviationTypeController',
                resolve: {
                    deviationTypeId: function () {
                        return type.id;
                    }
                }
            });
            modalInstance.result.then(function (type) {
                var found = $filter('filter')($scope.types, { id: type.id });
                    if (found.length == 1) {
                        var index = $scope.types.indexOf(found[0]);
                        $scope.types[index] = type;
                    }
            }, function () {
            });
        }

        $scope.permission = function (type) {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'editDeviationTypePermission.html',
                controller: 'editDeviationTypePermissionController',
                resolve: {
                    deviationTypeId: function () {
                        return type.id;
                    }
                }
            });
            modalInstance.result.then(function (type) {
            }, function () {
            });
        }
        

        $scope.addType = function () {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newDeviationType.html',
                controller: 'newDeviationTypeController'
            });
            modalInstance.result.then(function (type) {
                $scope.types.push(type);
            }, function () {
            });
        }

        $scope.deleteType = function (item) {
                var modalOptions = {
                    closeButtonText: $translate.instant("COMMON_CANCEL"),
                    actionButtonText: $translate.instant("COMMON_DELETE"),
                    headerText: $translate.instant("COMMON_DELETE")  + ' ' + item.title + '?',
                    bodyText: $translate.instant("ADMIN_MESSAGE_DELETE_DEVIATION_TYPE_CONFIRMATION") 
                };
    
                modalService.showModal({}, modalOptions).then(function (result) {
                    deviationService().deleteDeviationType(item.id).then(function (data) {  
                        load();
                    },function (err) {
                    });
                });
        }
    }]);