angular
    .module('DigiLean')
    .controller("radarchartRegistrationController", ['$scope', '$uibModalInstance', 'data',
        function ($scope, $uibModalInstance, data) {

            $scope.isAdminMode = false;
            $scope.data = data;

            $scope.setGoal = function (index, target) {
                $scope.data.goalData.data[index] = target;
            };
        
            $scope.setAssesment = function (index, target) {
                $scope.data.assesmentData.data[index] = target;
            };

            $scope.categoryNameChanged = function (index, name) {
                $scope.data.categories[index] = name;
            };

            $scope.deleteCategory = function (index) {
                $scope.data.categories.splice(index, 1);
                $scope.data.goalData.data.splice(index, 1);
                $scope.data.assesmentData.data.splice(index, 1);
            };

            $scope.addCategory = function () {
                $scope.data.categories.push(""); //COMMON_CATEGORY
                $scope.data.goalData.push("10");
                $scope.data.assesmentData.push("1");
            };

            $scope.save = function () {
                $uibModalInstance.close($scope.data);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }]);