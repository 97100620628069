angular
    .module('DigiLean')
    .directive("smarttableNameCell", ['$filter', '$translate','smarttableService',
        function ($filter, $translate, smarttableService) {
            return {
                templateUrl: 'smarttableNameCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.name = "";
                    scope.active = false;

                    scope.$watch('model', function (model) {
                        scope.name = model.row.name;
                        model.getSortValue = function() {
                            return scope.name;
                        }
                    });

                    scope.subscribe("SmartTableRowNameUpdated", function (row) {
                        if (row.id == scope.model.cell.smartTableRowId) {
                            if (row.name) {
                                scope.name = row.name;
                            } else {
                                scope.name = "";
                            }
                        }
                    });

                    scope.setActive = function() {
                        scope.active = true;   
                        setTimeout(function() {
                            $(".smarttable-name").focus();
                        }, 50 );                        
                    }
                    scope.nameChanged = function(name) {
                        scope.active = false;  
                        if(name !== scope.name){
                            smarttableService().updateName(scope.model.cell.smartTableRowId, name).then(function (result) {                         
                            });
                        }

                    }
                }
            }
        }]);
