import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("roiperassetImprovement", ['$translate', 'suggestionService',
        function ($translate, suggestionService) {
            return {
                templateUrl: 'roiperassetImprovement.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {
                    var roicategories = [],
                        assetCost = [],
                        assetGain = [],
                        assetROI = [];
                    var grafcolor = ['#2A2E36', '#0099DE', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'];

                    scope.timeframe = "thisyear";

                    scope.options = {
                        timePeriod: {
                            timeframe: "thisyear",
                            useDefaultTimeframes: true,
                            timeframes:[]
                        }
                    };
                    scope.periodChangedHandler = function (timePeriod) {
                        scope.timePeriod = timePeriod.period;
                        scope.timeframe = timePeriod.timeframe;
                        getData();
                    }
                    
                    function getData() {
                        if (!scope.timePeriod) return;
                        var roicategories = [],
                        assetCost = [],
                        assetGain = [],
                        assetROI = [];
                        suggestionService().getRoiForAssets(scope.timePeriod).then(function (result) {
                            var currency = customerService.getDefaultCurrency(); 
                            getCompanySetting("currency").then(function (setting) {
                                if (setting) {
                                    currency = setting
                                }
                                
                           
                            var roiperassetdata = result;
                            // Build the data arrays
                            for (var i = 0; i < roiperassetdata.length; i++) {
                                if(roiperassetdata[i].asset){
                                    roicategories.push(roiperassetdata[i].asset);
                                } else {
                                    roicategories.push($translate.instant("COMMON_UNDEFINED"));
                                }
                                
                                assetCost.push(roiperassetdata[i].cost);
                                assetGain.push(roiperassetdata[i].gain);
                                assetROI.push(roiperassetdata[i].roi * 100); // We are going to show ROI in percentage so multiple factor with 100
                            }

                            var roi = $(elem).find(".roiperasset");
                            Highcharts.chart({
                                chart: {
                                    renderTo: roi[0],
                                    type: 'column'
                                },
                                title: {
                                    text: ''
                                },
                                credits: {
                                    enabled: false
                                },
                                exporting: { enabled: false },
                                yAxis: [{
                                    title: {
                                        text: currency
                                }, 
                                }, {
                                    title: {
                                        text: 'ROI (%)'
                                    },
                                    labels: {
                                        format: '{value} %'
                                    },
                                    
                                    opposite: true, //støtter negativ roi
                                    plotLines: [{ // zero plane
                                        value: 0,
                                        color: '#ccd6eb',
                                        width: 1,
                                        zIndex: 0
                                    }]
                                    
                                }],
                                xAxis: {
                                    categories: roicategories
                                },
                                tooltip: {
                                    valueSuffix: ' ' + currency
                                },
                                plotOptions: {
                                    column: {
                                        borderWidth: 0
                                    }
                                },
                                series: [
                                    {
                                        name: cost,
                                        data: assetCost,
                                        yAxis: 0,
                                        color: '#ed5565'
                                    }, {
                                        name: gain,
                                        data: assetGain,
                                        yAxis: 0,
                                        color: '#5db85d' //'#23c6c8'
                                    }, {
                                        name: 'ROI',
                                        data: assetROI,
                                        yAxis: 1,
                                        color: '#0099de',
                                        tooltip: {
                                            valueSuffix: ' %'
                                        }
                                    }
                                ]
                            });
                        });
                    })

                    }
                    var cost = "";
                    var gain = "";
                    $translate(['COMMON_COST', 'COMMON_GAIN']).then(function (translations) {
                        cost = translations.COMMON_COST;
                        gain = translations.COMMON_GAIN;
                        getData();
                    });
                }
            }
        }]);
