import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("initiativeRealizationPerGroup", ['$translate', 'suggestionService',
        function ($translate, suggestionService) {
            return {
                templateUrl: 'initiativeRealizationPerGroup.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {
                    scope.timeframe = "thisyear";

                    scope.options = {
                        timePeriod: {
                            timeframe: "thisyear",
                            useDefaultTimeframes: true,
                            timeframes:[]
                        }
                    };
                    
                    var chartHeight = 400;
                    var grafcolor = ['#2A2E36', '#0099DE', '#5cb85c', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'],
                        graphtextcolor = '#ffffff';

                    var initiatives = [];
                        

                    var yAxisText = "";
                    var unrealizedLabel = "";
                    var realizedLabel = "";
                    var currency = customerService.getDefaultCurrency()
                    getCompanySetting("currency").then(function (setting) {
                        if (setting) {
                            currency = setting
                        }
                        $translate(['A3_REALIZED', 'COMMON_ESTIMATED']).then(function (translations) {
                        
                            yAxisText = currency;
                            realizedLabel = translations.A3_REALIZED;
                            unrealizedLabel = translations.COMMON_ESTIMATED;
                            getData();
                        });
                    });
                    

                    scope.periodChangedHandler = function (timePeriod) {
                        scope.timePeriod = timePeriod.period;
                        scope.timeframe = timePeriod.timeframe;
                        getData();
                    }

                    function getData() {
                        if (!scope.timePeriod) return;
                        var realized = [],
                            estimated = [],
                            labelcategories = [];
                        suggestionService().getRealizationForAssets(scope.timePeriod).then(function (result) {
                            initiatives = result;

                            if(result && result.length > 10){     
                                chartHeight = 400 + 30*(result.length -10);
                            }
                            // Build the data arrays
                            for (var i = 0; i < result.length; i++) {
                                var estimatedValue = 0;
                                if(result[i].estimated){
                                    estimatedValue = result[i].estimated;
                                }
                                var realizedValue = 0;
                                if(result[i].realized){
                                    realizedValue = result[i].realized;
                                } 
                                if(result[i].assetName){
                                    labelcategories.push(result[i].assetName);
                                } else {
                                    labelcategories.push($translate.instant('COMMON_UNDEFINED')); 
                                }
                                
                                estimated.push(estimatedValue); 
                                realized.push(realizedValue);
                            }

                            var renderElement = $(elem).find(".initiativeRealizationPerGroup")[0];
                            if (renderElement)
                                Highcharts.chart({
                                    chart: {
                                        renderTo: renderElement,
                                        type: 'bar',
                                        height: chartHeight,
                                    },
                                    title: {
                                        text: ''
                                    },
                                    xAxis: {
                                        categories: labelcategories            
                                    },
                                    yAxis: {
                                        min: 0,
                                        title: {
                                            text: yAxisText  
                                        }
                                    },
                                    credits: {
                                        enabled: false
                                    },
                                    exporting: { enabled: false },
                                    legend: {
                                        reversed: true
                                    },
                                    plotOptions: {
                                        series: {
                                            stacking: 'normal',
                                        },bar: {
                                            dataLabels: {            
                                        formatter: function(){
                                        return this.point.name
                                        },
                                              enabled: true
                                          }
                                        }
                                    },
                                    series: [
                                        {
                                            name: unrealizedLabel,
                                            data: estimated,
                                            color: grafcolor[5] 
                                        },
                                        {
                                            name: realizedLabel, 
                                            data: realized,
                                            color: grafcolor[2]
                                        }]
                                });
                        });
                    }
                }
            }
        }]);
