﻿angular.module("DigiLean").service('activityCenter', ['activityService', function (activityService) {
    return { Initialize: Initialize };
    function Initialize(scope) {
        console.log('activtycenter init');
        // We should only subscribe to Send events so we only log events once.
        scope.subscribe("SendBoardCreated", function (board) {
            var activity = {
                action: "BoardCreation",
                activity: "Board",
                activityId: board.id,
                title: "created board",
                description: board.name,
                payLoad: JSON.stringify(board)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendBoardDeleted", function (id) {
            var activity = {
                action: "BoardDeleted",
                activity: "Board",
                activityId: id,
                title: "deleted board",
                description: "",
                payLoad: JSON.stringify(id)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendSuggestionAttachmentAdded", function (attachment) {
            var activity = {
                action: "NewAttachment",
                activity: "ImprovementSuggestion",
                activityId: attachment.improvementSuggestionId,
                title: "added attachment to suggestion",
                parentId: attachment.improvementSuggestionId,
                payLoad: JSON.stringify(attachment)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendSuggestionAttachmentDeleted", function (attachment) {
            var activity = {
                action: "DeleteAttachment",
                activity: "ImprovementSuggestion",
                activityId: attachment.improvementSuggestionId,
                title: "deleted attachment on suggestion",
                parentId: attachment.improvementSuggestionId,
                payLoad: JSON.stringify(attachment)
            }
            saveActivity(activity, true);
        });
        
        scope.subscribe("SendImprovementChangedCategory", function (improvement) {
            var activity = {
                action: "UpdatedCategorySuggestion",
                activity: "ImprovementSuggestion",
                activityId: improvement.suggestionId,
  
                title: "updated improvement category to: " + improvement.categoryName,
                payLoad: JSON.stringify(improvement)
            }
            saveActivity(activity, true);
        });  

        scope.subscribe("SendDeviationDueDateChanged", function (duedate) {
            var activity = {
                action: "UpdatedDueDateDeviation",
                activity: "Deviation",
                activityId: duedate.deviationId,
                title: "updated deviation due date from: " + duedate.oldDueDate + " to " + duedate.newDueDate,
                payLoad: JSON.stringify(duedate)
            }
            saveActivity(activity, true);
        });

        
        scope.subscribe("SendDeviationProblemReasonChanged", function (problemreasons) {
            var activity = {
                action: "UpdatedProblemReasonDeviation",
                activity: "Deviation",
                activityId: problemreasons.deviationId,
                title: "updated deviation problem reasons",
                payLoad: JSON.stringify(problemreasons)
            }
            saveActivity(activity, true);
        });

        scope.subscribe("SendDeviationSeverityChanged", function (severity) {
            var activity = {
                action: "UpdatedSeverityDeviation",
                activity: "Deviation",
                activityId: severity.deviationId,
                title: "updated deviation severity from: " + severity.oldSeverity + " to " + severity.newSeverity,
                payLoad: JSON.stringify(severity)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendDeviationChangedCategory", function (category) {
            var activity = {
                action: "DeviationChangedCategory",
                activity: "Deviation",
                activityId: category.deviationId,
                title: "updated deviation category to: " + category.categoryText,
                payLoad: JSON.stringify(category)
            }
            saveActivity(activity, true);
        });

        scope.subscribe("SendImprovementUpdated", function (improvement) {
            var activity = {
                action: "UpdatedSuggestion",
                activity: "ImprovementSuggestion",
                activityId: improvement.id,
                activityOwner: improvement.suggestedBy,
                activityOwnerUserId: improvement.suggestedByUserId,
                title: "updated improvement suggestion ",
                description: improvement.title,
                payLoad: JSON.stringify(improvement)
            }
            saveActivity(activity, true);
        });

        scope.subscribe("SendTaskAssigned", function (taskAssigned) {
            var activity = {};
            var title = "cleared task responsible";
            if (taskAssigned.userId) {
                title = "assigned task";
            };
                activity = {
                    action: "TaskAssigned",
                    activity: "BoardTask",
                    activityId: taskAssigned.taskId,
                    activityOwner: taskAssigned.userDisplayName,
                    activityOwnerId: taskAssigned.userId,
                    title: title,
                    description: "",
                    payLoad: JSON.stringify(taskAssigned)
                }
            
            saveActivity(activity, true);
        });
        
        scope.subscribe("SendTaskDeviationCreated", function (task) {
            var activity = {
                action: "TaskDeviationCreated",
                activity: "BoardTask",
                activityId: task.taskId,
                activityOwner: "",
                title: task.title,
                description: "",
                payLoad: JSON.stringify(task)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendTaskMovedToActionList", function (task) {
            var activity = {
                action: "TaskMovedToActionList",
                activity: "BoardTask",
                activityId: task.id,
                activityOwner: "",
                title: "moved task to actionlist",
                description: "",
                payLoad: JSON.stringify(task)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendActionItemMovedToTask", function (task) {
            var activity = {
                action: "TaskMovedFromActionListToBoard",
                activity: "BoardTask",
                activityId: task.id,
                activityOwner: "",
                title: "moved task from actionlist to board",
                description: "",
                payLoad: JSON.stringify(task)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendCreateActionListItem", function (task) {
            var activity = {
                action: "TaskCreatedToActionList",
                activity: "BoardTask",
                activityId: task.id,
                activityOwner: "",
                title: "created new item to actionlist",
                description: task.title,
                payLoad: JSON.stringify(task)
            }
            saveActivity(activity, true);
        });
        
        scope.subscribe("SendTaskRepeated", function (repeatResult) {
            // Save original
            var task = repeatResult.original;
            var activity = {
                action: "TaskRepeated",
                activity: "BoardTask",
                activityId: task.id,
                activityOwner: "",
                title: "repeated task",
                description: task.title,
                payLoad: JSON.stringify(task)
            }
            saveActivity(activity, true);
        });
        
        scope.subscribe("SendTaskAttachmentAdded", function (attachment) {
            var activity = {
                action: "TaskAttachmentAdded",
                activity: "BoardTask",
                activityId: attachment.boardTaskId,
                title: "added attachment to task",
                parentId: attachment.boardTaskId,
                payLoad: JSON.stringify(attachment)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendTaskAttachmentDeleted", function (attachment) {
            var activity = {
                action: "TaskAttachmentDeleted",
                activity: "BoardTask",
                activityId: attachment.boardTaskId,
                title: "deleted attachment on task",
                parentId: attachment.boardTaskId,
                payLoad: JSON.stringify(attachment)
            }
            saveActivity(activity, true);
        });
        scope.subscribe("SendA3sDeleted", function (a3Ids) {
            angular.forEach(a3Ids, function(id) {
                var activity = {
                    action: "A3Deleted",
                    activity: "A3",
                    activityId: id,
                    activityOwner: "",
                    title: "deleted A3",
                    payLoad: JSON.stringify(id)
                }
                saveActivity(activity, true);
            });
        });
        scope.subscribe("SendA3Created", function (a3) {
            var activity = {
                action: "A3Created",
                activity: "A3",
                activityId: a3.id,
                activityOwner: "",
                title: "created A3",
                description: a3.title,
                payLoad: JSON.stringify(a3)
            }
            saveActivity(activity, true);
        });
        
        scope.subscribe("SendA3VersionCreated", function (a3Version) {
            var activity = {
                action: "A3VersionCreated",
                activity: "A3Version",
                activityId: a3Version.id,
                activityOwner: "",
                parentId: a3Version.a3Id,
                title: "created new A3 version",
                description: "",
                payLoad: JSON.stringify(a3Version)
            }
            saveActivity(activity, true);
        });

        function saveActivity(activity, publish) {
            
            activityService().add(activity).then(function (activity) {
                if(publish){
                    scope.publish("SendActivityOccured", activity);
                }
            });
        }
    }
}]).run(['$rootScope', 'activityCenter', function ($rootScope, activityCenter) {
    activityCenter.Initialize($rootScope);
}]);