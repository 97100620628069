﻿import * as profileService from "@common/services/user/profileService"

angular.module("DigiLean").service('profileImageService', ['$rootScope', 
function ($rootScope) {
    var service = {};
    service.users = [];
    
    $rootScope.subscribe("userLoggedIn", function(){
        service.loadProfiles()
    })

    // factory function body that constructs shinyNewServiceInstance
    service.loadProfiles = function() {
        profileService.getUsersWithProfileImage().then(function(users){
            service.users = users;
        });
    };
    service.loadProfiles();
    return service;
}]);