angular
    .module('DigiLean')
    .directive("widgetImprovementStatus", ['$filter', 'suggestionService',
        function ($filter, suggestionService) {
            return {
                templateUrl: 'widgetImprovementStatus.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {
                    // Get data from previous period 
                    scope.noNew = 0;
                    scope.noPlanned = 0;
                    scope.noInProgress =0;
                    scope.noImplemented = 0;
                    scope.noEvaluated = 0;
                    scope.noArchived = 0;

                    suggestionService().getNoImprovementPerStatus(null).then(function (statusdata) {
                        scope.noNew = statusdata.new;
                        scope.noPlanned = statusdata.planned;
                        scope.noInProgress = statusdata.inProgress;
                        scope.noImplemented = statusdata.implemented;
                        scope.noEvaluated = statusdata.evaluated;
                        scope.noArchived = statusdata.archived;
                    });

                }
            };
        }]);
