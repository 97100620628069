﻿import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

const DigiLean = angular.module('DigiLean')
DigiLean.directive("timeframeSelector", ['$translate',
    function ($translate) {
        return {
            templateUrl: 'timeframeSelector.html',
            restrict: 'E',
            scope: {
                'options': '<',
                'periodChanged': '&',
                'globalTimePeriod': '@'
            },
            link: function (scope, elem, attrs) {
                scope.timePeriod = null;
                scope.toDayStartDate = false;
                scope.toDayEndDate = false;
                scope.timeframe = null;
                scope.timeframes = null;
                var companyfiscalmonth = customerService.getDefaultFiscalmonth()
                       

                var defaultTimeframes = ['all', 'lastyear', 'last12mth', 'thisyear','fiscalyear', 'YTD', 
                    '30', 'previousmonth', 'month', 'nextfourweeks','previousisoweek', 'thisandnextweek', 'isoweek', 
                    'yesterday','day', 'number','custom'];

                /* also defined in timeService.js */
                scope.timeFrameMapping = {
                    all: ['COMMON_ALL', 'digilean-label-dark', 0], //translation key, css-class, sort index
                    last3years: ['DASHBOARD_LAST_3_YEARS', 'digilean-label-historical', 1],
                    last3fiscalyears: ['DASHBOARD_LAST_3_FISCAL_YEAR', 'digilean-label-historical', 2],
                    lastyear: ['DASHBOARD_LAST_YEAR', 'digilean-label-historical', 3],
                    last12mth: ['DASHBOARD_LAST_12_MONTH', 'label-primary', 4],
                    thisvslastyear: ['DASHBOARD_THIS_YEAR_VS_LAST_YEAR', 'label-primary', 5],  
                    thisfiscalvslastfiscalyear: ['DASHBOARD_THIS_FY_VS_LAST_FY', 'label-primary',6],
                    year: ['DASHBOARD_YTD', 'label-primary', 7],
                    fiscalyear: ['DASHBOARD_FISCAL_YEAR', 'label-primary', 8],
                    YTD: ['DASHBOARD_YTD', 'label-primary', 9],
                    YTW: ['DASHBOARD_YTD', 'label-primary', 10],
                    YTM: ['DASHBOARD_YTD', 'label-primary', 11],
                    thisyear: ['DASHBOARD_THIS_YEAR', 'label-primary', 12],       
                    previousmonth: ['DASHBOARD_PREVIOUS_MONTH', 'digilean-label-historical', 13],
                    30: ['COMMON_LAST_30_DAYS', 'label-info', 14],
                    month: ['DASHBOARD_CURRENT_MONTH', 'label-info', 15], 
                    nextfourweeks: ['DASHBOARD_NEXT_FOUR_WEEKS', 'label-info', 16],        
                    thisandnextweek: ['DASHBOARD_THIS_AND_NEXT_WEEK', 'label-info-light-digilean', 17],
                    previousisoweek: ['DASHBOARD_PREVIOUS_WEEK', 'digilean-label-historical', 18],
                    isoweek: ['DASHBOARD_THIS_WEEK', 'label-danger', 19],
                    thisweek: ['DASHBOARD_THIS_WEEK', 'label-danger', 20],
                    yesterday: ['COMMON_YESTERDAY', 'digilean-label-historical', 21],
                    day: ['COMMON_TODAY', 'label-warning', 22],
                    custom: ['COMMON_CUSTOM', '', 23],
                    customStartDate: ['COMMON_CUSTOM', '', 24],
                    customEndDate: ['COMMON_CUSTOM', '', 25],
                    none: ['COMMON_NONE', '', 26],
                    number: ['', 'digilean-label-historical', 27],
/*                    numberDays: ['', 'digilean-label-historical', 28],*/
                };

                scope.mappings = function () {
                    var array = valuesToArray(scope.timeFrameMapping);
                    var sorted = array.sort((a, b) => {
                        return a.value[2] - b.value[2];
                    });
                    var keys = sorted.map(x => x.name);
                    keys.splice(keys.indexOf('custom'), 1);
                    return keys;
                };

                function valuesToArray(obj) {
                    return Object.keys(obj).map(function (key) { return { name: key, value: obj[key] }; });
                }

                scope.getTimeframeClass = function (timeframe) {
                    if (scope.timeFrameMapping && timeframe && scope.timeFrameMapping[timeframe])
                        return scope.timeFrameMapping[timeframe][1];
                };

                scope.toggleToDayStartDate = function () {
                    scope.toDayStartDate = !scope.toDayStartDate;
                    updateHandler();
                }
                scope.toggleToDayEndDate = function () {
                    scope.toDayEndDate = !scope.toDayEndDate;
                    updateHandler();
                }


                function updateHandler() {
                    
                    if (scope.hasCustomTimeframe) {
                        var from = moment(scope.fromDate).startOf('day');
                        var to = moment(scope.toDate).endOf('day');
                        if (scope.toDayStartDate) {
                            from = moment().startOf('day');
                            scope.timeframe = "customEndDate";
                        }
                        if (scope.toDayEndDate) {
                            to = moment().endOf('day');
                            scope.timeframe = "customStartDate";
                        }

                        scope.timePeriod = {
                            from: from.utc().toDate().toJSON(),
                            to: to.utc().toDate().toJSON()
                        }
                    } else if(scope.hasNumberTimeframe){
                        scope.timePeriod = timeService.getTimePeriod(scope.numberDays);
                        scope.timePeriod.days = scope.numberDays;
                    } else if(scope.hasFiscalyear){
                        scope.timePeriod = timeService.getTimePeriod(scope.timeframe,companyfiscalmonth);
                        
                    } else {
                        scope.timePeriod = timeService.getTimePeriod(scope.timeframe);
                        
                    }
                    
                    scope.fromDate = moment(scope.timePeriod.from).toDate();
                    scope.toDate = moment(scope.timePeriod.to).toDate();
                    
                    scope.periodChanged({
                        timePeriod: {
                            period: scope.timePeriod,
                            timeframe: scope.timeframe,
                            timeframes: scope.timeframes
                        }
                    });
                }

                scope.$watch('options', function (options) {
                    if (options && options.timePeriod) {
                        getCompanySetting("fiscalmonth").then(function (setting) {
                            if (setting) {
                                companyfiscalmonth = parseInt(setting)
                            }
                        
                        scope.timeframe= options.timePeriod.timeframe;
                        if (options.timePeriod.timeframe === "number") {
                            scope.hasNumberTimeframe = true;
                            if(options.timePeriod.period.days){
                                scope.numberDays = options.timePeriod.period.days;
                            }
                            var period = timeService.getTimePeriod(scope.numberDays);
                            scope.fromDate = moment(period.from).toDate();
                            scope.toDate = moment(period.to).toDate();
                        } else if (options.timePeriod.timeframe === "custom") {
                            scope.hasCustomTimeframe = true;
                            var period = options.timePeriod.period;
                            scope.fromDate = moment(period.from).toDate();
                            scope.toDate = moment(period.to).toDate();
                        } else if (options.timePeriod.timeframe === "customStartDate") { // manual start date and current day as end date
                            scope.hasCustomTimeframe = true;
                            scope.toDayEndDate = true;
                            var period = options.timePeriod.period;
                            scope.fromDate = moment(period.from).toDate();
                            scope.toDate = moment().toDate();
                        } else if (options.timePeriod.timeframe === "customEndDate") { // current day as start date and manual day as end date
                            scope.hasCustomTimeframe = true;
                            scope.toDayStartDate = true;
                            var period = options.timePeriod.period;
                            scope.fromDate = moment().toDate();
                            scope.toDate = moment(period.to).toDate();
                        } else if (options.timePeriod.timeframe === "fiscalyear" || 
                                options.timePeriod.timeframe === "thisfiscalvslastfiscalyear" ||
                                options.timePeriod.timeframe === "last3fiscalyears") { 
                            scope.hasFiscalyear = true;
                        }
                        if (options.timePeriod.useDefaultTimeframes || !options.timePeriod.timeframes || options.timePeriod.timeframes.length === 0) {
                            scope.timeframes = defaultTimeframes;
                        } else {
                            scope.timeframes = options.timePeriod.timeframes;
                        }
                           
                        scope.updateDisplayPeriod(scope.timeframe);
                    })
                    }
                });

                // scope.$watch('globalTimePeriod', function () {
                //     // console.log(scope.globalTimePeriod);

                //     if (scope.globalTimePeriod && scope.globalTimePeriod.length > 1) {
                //         scope.timePeriod = JSON.parse(scope.globalTimePeriod);
                //         if (scope.displayTimeframe(scope.timeframe)) {
                //             // showOption(scope.timePeriod.timeframe);
                //             scope.updateDisplayPeriod(scope.timeframe);
                //         }
                //     }
                // });

                // Custom option
                
                scope.hasCustomTimeframe = false;
                scope.fromDate = moment().toDate();
                scope.toDate = moment().toDate();

                scope.numberDays = 7;

                scope.displayTimeframe = function (timeframe) {
                    return scope.timeframes && scope.timeframes.includes(timeframe);
                };

                
                scope.updateNumberDays = function (days) {
                    scope.numberDays = days;
                    scope.updateDisplayPeriod("number");
                    updateHandler()
                }

                
                scope.selectNumberPeriod = function () {
                    scope.hasNumberTimeframe = true;
                    scope.updateDisplayPeriod("number");
                    $event.preventDefault();
                }

                scope.selectCustomPeriod = function ($event) {
                    scope.hasCustomTimeframe = true;
                    scope.updateDisplayPeriod("custom");
                    $event.preventDefault();
                }

                scope.fromDateChanged = function ($event) {
                    const date = $event.originalEvent.detail
                    scope.fromDate = date
                    updateHandler()
                }
                scope.toDateChanged = function ($event) {
                    const date = $event.originalEvent.detail
                    scope.toDate = date
                    updateHandler()
                }

                var showOption = function (name) {
                    if (scope.timeFrameMapping[name]) {
                        scope.displayPeriod = scope.timeFrameMapping[name][0];
                        updateHandler();
                    }
                };

                scope.getTranslationKey = function (timeframe) {
                    if (!timeframe) return null;
                    return scope.timeFrameMapping[timeframe][0];
                };

                scope.updateDisplayPeriod = function (timeframe) {
                    if (!timeframe) return;
                    scope.hasCustomTimeframe = false;
                    scope.hasNumberTimeframe = false;
                    scope.hasFiscalyear = false;
                    if (timeframe === "custom" || timeframe === "customStartDate" || timeframe === "customEndDate")
                        scope.hasCustomTimeframe = true;
                    scope.timeframe = timeframe;
                    if(timeframe === "number"){
                        scope.hasNumberTimeframe = true;
                        scope.displayPeriod = $translate.instant('COMMON_LAST_X_DAYS', {days: scope.numberDays}); // "Last " +  + "days";
                        updateHandler();
                    } else if (timeframe === "last") {
                        scope.displayPeriod = $translate.instant("COMMON_LAST_VALUE");
                        updateHandler()
                    } else if (timeframe === "fiscalyear" || timeframe === 'thisfiscalvslastfiscalyear'
                    || timeframe === "last3fiscalyears") {
                        scope.hasFiscalyear = true;
                        scope.displayPeriod = $translate.instant(scope.timeFrameMapping[timeframe][0])
                        showOption(timeframe)
                    } else {
                        scope.displayPeriod = $translate.instant(scope.timeFrameMapping[timeframe][0])
                        showOption(timeframe)
                    }                
                }
                

                if (scope.timeframe) {
                    scope.updateDisplayPeriod(scope.timeframe);
                }
            }
        }
    }]);
