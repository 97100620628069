angular
    .module('DigiLean')
    .directive("smartKanbanCard", ['$filter','$uibModal','taskDrawingService','boardTaskService',
        function ($filter,$uibModal, taskDrawingService, boardTaskService) {
            return {
                templateUrl: 'smartKanbanCard.html',
                restrict: 'E',
                scope: {
                    'task': '<',
                    'users': '<?',
                    'column': '<',
                    'editable': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.postIts = taskDrawingService().getPostIts();
                    scope.editable = false;
                    scope.$watch("users", function (users) {
                        if (!users) return;
                    });
                    scope.$watch("editable", function (editable) {
                        if (editable) scope.editable = true;
                    });
                    scope.$watch("task", function (task) {
                        if (!task) return;
                        setColor(task.color);
                        // task.displayColor = "silver";
                    });
                    var setColor = function (taskColor) {
                        // Try to match fillColor
                        var predefinedPostIt = $filter('filter')(scope.postIts, { fillColor: taskColor });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                            setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                        } else {
                            // Try to match background color
                            var backgroundPostIt = $filter('filter')(scope.postIts, { background: taskColor });
                            if (backgroundPostIt.length > 0) {
                                scope.selectedPostIt = backgroundPostIt[0];
                                setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                            } else {
                                setStyle(scope.fillColor, scope.borderColor);
                            }
                        }
                    }

                    var setStyle = function (fillColor, borderColor) {
                            
                        if (fillColor == "#ffffff") {
                            borderColor = scope.borderColor;
                        }
                        
                        scope.task.displayColor = borderColor;
                    }

                    scope.openTask = function () {
                        boardTaskService().get(scope.task.id).then(function (task) {
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: true,
                                            users: scope.users
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }]);