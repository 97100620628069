/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LogMessageType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Information = 'information',
    JobStarted = 'jobStarted',
    JobFinished = 'jobFinished',
    JobFailed = 'jobFailed',
}
