﻿import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as displayService from "@common/services/displayService"

angular.module('DigiLean').factory('deviationService', ['$http', '$translate', 'webApiUrl', 'userProvider',
    function ($http, $translate, webApiUrl, userProvider) {

        function deviationService() {
            var resource = "/deviation";
            var resourceUrl = webApiUrl + resource;

            return {
                getNextStatus: function (currentStatus) {
                    currentStatus = currentStatus.toString();
                    if (currentStatus === "0") return "20"; // New to Resolved
                    if (currentStatus === "10") return "20"; // IN progress to Resolved
                    if (currentStatus === "20") return "30"; // Resolved to Rejected
                    if (currentStatus === "30") return "10"; // Rejected to New
                    return "0";
                },
                getSeverityColor(severity) {
                    switch (severity) {
                        case 1:
                            return "#1872ab";
                        case 2:
                            return "#FFCE54";
                        case 3:
                            return "#DA4453";
                        default:
                            return "#2A2E36";
                    }
                },
                /* If the sortable identificator is modified, remember to update the OngoingDeviationFilterSettings.cs in backend */
                getAllColumns: function (renderValue) {
                    return [
                        {
                            title: $translate.instant("COMMON_STATUS"),
                            field: 'status',
                            visible: true,
                            sortable: 'status',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_TITLE"),
                            field: 'title',
                            visible: true,
                            sortable: 'title',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("DEVIATION_SEVERITY"),
                            field: 'severity',
                            visible: true,
                            sortable: 'severity',
                            getValue: renderValue,
                            dataType: "int"
                        },
                        {
                            title: $translate.instant("COMMON_DEVIATION_TYPE"),
                            field: 'deviationType',
                            visible: true,
                            sortable: 'deviationType',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_REPORTED_BY"),
                            field: 'assetName',
                            sortable: 'assetName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("DEVIATION_FOLLOW_UP_GROUP"),
                            field: 'followupAssetName',
                            sortable: 'followupAssetName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_RESPONSIBLE"),
                            field: 'owner',
                            sortable: 'owner',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_DUE_DATE"),
                            field: 'dueDate',
                            visible: true,
                            sortable: 'dueDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("DEVIATION_INCIDENT_DATE"),
                            field: 'incidentDate',
                            visible: true,
                            sortable: 'incidentDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("DEVIATION_RESOLVED"),
                            field: 'statusImplementedDate',
                            visible: true,
                            sortable: 'statusImplementedDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("COMMON_LAST_CHANGED"),
                            field: 'lastModified',
                            visible: true,
                            sortable: 'lastModified',
                            getValue: renderValue,
                            dataType: "date"
                        },
                    ];
                },
                getTableValues: function (tableParams) {
                    var url = resourceUrl + "/getTableValues/";
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },

                getOngoingByFilter: function (filter) {
                    var url = resourceUrl + "/getOngoingByFilter/";
                    return $http.post(url, filter).then(function (result) {
                        return result.data;
                    });
                },

                getActionTableValues: function (tableParams) {
                    var url = resourceUrl + "/getActionTableValues/";
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },

                getTotalNumberOfDeviations: function (includeArchived) {
                    if (!includeArchived) includeArchived = false;
                    var url = resourceUrl + "/getTotalNumberOfDeviations?archived=" + includeArchived;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getConsequenceUnit: function () {
                    getCompanySetting("currency").then(function (setting) {
                        var currency = customerService.getDefaultCurrency()
                        if (setting) {
                            currency = setting
                        }
                        var units = [
                            {
                                id: 'hour',
                                name: $translate.instant('COMMON_HOURS')
                            },
                            {
                                id: currency,
                                name: currency
                            }
                        ];
                        return units;
                    });

                },
                getConsequenceUnitLabel: function (id) {
                    id = id.toString().toUpperCase();
                    if (id === "HOUR") return $translate.instant('COMMON_HOURS');
                    return id;
                },

                getNoEvaluationStatus: function (options) {
                    var url = resourceUrl + "/GetNoEvaluationStatus";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                canEdit: function (id) {
                    var url = resourceUrl + "/canedit/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getNoProjectDeviationPerStatus: function (projectId) {
                    var url = resourceUrl + "/getNoProjectDeviationPerStatus/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getDeviationsForAssetWithActionItems: function (assetId) {
                    var url = resourceUrl + "/getDeviationsForAssetWithActionItems/" + assetId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                updateResponsible: function (data) {
                    resourceUrl = resourceUrl + "/UpdateResponsible/";
                    return $http.put(resourceUrl, data).then(function (result) {
                        return result.data;
                    });
                },

                getPermissionsForType: function(deviationTypeId) {
                    var url = webApiUrl + "/deviationadmin/GetPermissionsForType/" + deviationTypeId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                updateDeviationTypePermissions: function (fieldId, units) {
                    var url = webApiUrl + "/deviationadmin/updateDeviationTypePermissions/" + fieldId;
                    return $http.put(url, units).then(function (result) {
                        return result.data;
                    });
                },
                getFieldsForType: function(deviationTypeId) {
                    var url = webApiUrl + "/deviationadmin/getFieldsForType/" + deviationTypeId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                updateCustomFieldTranslationLabel: function (fieldId, item) {
                    var url = webApiUrl + "/deviationadmin/UpdateCustomFieldTranslationLabel/" + fieldId;
                    return $http.put(url, item).then(function (result) {
                        return result.data;
                    });
                },
                getAllCustomFields: function() {

                    var url = webApiUrl + "/deviationadmin/getAllCustomFields/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                addDeviationTypeField: function (field) {
                    var url = webApiUrl + "/deviationadmin/AddDeviationTypeField/" + field.deviationTypeId;
                    return $http.post(url, field).then(function (result) {
                        return result.data;
                    });
                },
                addDeviationCustomField: function (field) {
                    var url = webApiUrl + "/deviationadmin/AddDeviationCustomField/";
                    return $http.post(url, field).then(function (result) {
                        return result.data;
                    });
                },
                deleteDeviationTypeField: function (fieldId) {
                    var url = webApiUrl + "/deviationadmin/deleteDeviationTypeField/" + fieldId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                addCustomFields(deviationId, deviationtypefieldvalues) {
                    var url = resourceUrl + "/" + deviationId + "/addCustomFields/";
                    return $http.post(url, deviationtypefieldvalues).then(function (result) {
                        return result.data;
                    });
                },
                deleteDeviationCustomField: function (id) {
                    var url = webApiUrl + "/deviationadmin/DeleteDeviationCustomField/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getCustomFields: function (deviationId) {
                    var url = resourceUrl + "/getCustomFields/" + deviationId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                updateCustomFields: function (id, fields) {
                    var url = resourceUrl + "/updateCustomFields/" + id;
                    return $http.put(url, fields).then(function (result) {
                        return result.data;
                    });
                },
                updateDeviationTypeField: function (id, field) {
                    var url = webApiUrl + "/deviationadmin/UpdateDeviationTypeField/" + id;
                    return $http.put(url, field).then(function (result) {
                        return result.data;
                    });
                },
                updateDeviationCustomField: function (id, field) {
                    var url = webApiUrl + "/deviationadmin/UpdateDeviationCustomField/" + id;
                    return $http.put(url, field).then(function (result) {
                        return result.data;
                    });
                },
                updateItemsSortOrder: function (id, listSorting) {
                    var url = webApiUrl + "/deviationadmin/updateItemsSortOrder/" + id;
                    return $http.put(url, listSorting).then(function (result) {
                        return result.data;
                    });
                },
                getConsequencesForType: function (deviationTypeId) {
                    var url = webApiUrl + "/deviationadmin/getConsequencesForType/" + deviationTypeId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getSeveritiesForType: function (deviationTypeId) {
                    var url = webApiUrl + "/deviationadmin/getSeveritiesForType/" + deviationTypeId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                updateDeviationSeverityTranslation: function (item) {
                    var url = webApiUrl + "/deviationadmin/updateDeviationSeverityTranslation/";
                    return $http.put(url, item).then(function (result) {
                        return result.data;
                    });
                },
                addDeviationTypeConsequence: function (field) {
                    var url = webApiUrl + "/deviationadmin/AddDeviationTypeConsequence";
                    return $http.post(url, field).then(function (result) {
                        return result.data;
                    });
                },
                deleteDeviationTypeConsequence: function (fieldId) {
                    var url = webApiUrl + "/deviationadmin/deleteDeviationTypeConsequence/" + fieldId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                getCategoriesForType: function (deviationTypeId) {
                    var url = webApiUrl + "/deviationadmin/getCategoriesForType/" + deviationTypeId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                addDeviationTypeCategory: function (item) {
                    var url = webApiUrl + "/deviationadmin/AddDeviationTypeCategory";
                    return $http.post(url, item).then(function (result) {
                        return result.data;
                    });
                },
                deleteDeviationTypeCategory: function (id) {
                    var url = webApiUrl + "/deviationadmin/deleteDeviationTypeCategory/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                getCausesForType: function (deviationTypeId) {
                    var url = webApiUrl + "/deviationadmin/getCausesForType/" + deviationTypeId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                addDeviationTypeCause: function (field) {
                    var url = webApiUrl + "/deviationadmin/AddDeviationTypeCause";
                    return $http.post(url, field).then(function (result) {
                        return result.data;
                    });
                },
                deleteDeviationTypeCause: function (fieldId) {
                    var url = webApiUrl + "/deviationadmin/deleteDeviationTypeCause/" + fieldId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                getSeverityText: function (severityId) {
                    switch (severityId) {
                        case "1":
                            return $translate.instant('COMMON_LOW');
                        case "2":
                            return $translate.instant('COMMON_MEDIUM');
                        case "3":
                            return $translate.instant('COMMON_HIGH');
                        default:
                            return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                },

                addDeviation: function (deviation) {
                    var url = resourceUrl;
                    return $http.post(url, deviation).then(function (result) {
                        return result.data;
                    });
                },
                archive: function (id) {
                    var url = resourceUrl + "/" + id + "/archive";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                unArchive: function (id) {
                    var url = resourceUrl + "/" + id + "/unArchive";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                updateStatus: function (status) {
                    var url = resourceUrl + "/" + status.deviationId + "/updatestatus";
                    return $http.put(url, status).then(function () {
                        return;
                    });
                },
                getTranslationsWithConsequences: function () {
                    var url = webApiUrl + "/deviationadmin/getTranslationsWithConsequences";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                updateConsequences: function (consequences) {
                    var url = resourceUrl + "/" + consequences.deviationId + "/updateConsequences";
                    return $http.put(url, consequences).then(function () {
                        return;
                    });
                },

                get: function (id, showNoAccessPopup = true) {
                    var url = resourceUrl + "/" + id;
                    if (showNoAccessPopup) {
                        return $http.get(url).then(function (result) {
                            return result.data;
                        },
                            function (response) {
                                if (response.status === 404) {
                                    displayService.showNoAccessPopup();
                                }
                            });
                    }
                        
                    else {
                        return $http.get(url).then(function (result) {
                            return result.data;
                        });
                    }
                },

                getUserListForDeviation: function (id) {
                    var url = resourceUrl + "/getUserListForDeviation/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                delete: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                addComment: function (deviationId, comment) {
                    var url = resourceUrl + "/" + deviationId + "/AddComment";
                    return $http.post(url, comment).then(function (result) {
                        return result.data;
                    });
                },

                getComments: function (deviationId) {
                    var url = resourceUrl + "/getComments/" + deviationId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                deleteComment: function (deviationId, commentId) {
                    var url = resourceUrl + "/" + deviationId + "/deleteComment/" + commentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                addAttachment: function (deviationId, fileInfo) {
                    var url = resourceUrl + "/" + deviationId + "/addAttachment";
                    return $http.post(url, fileInfo).then(function (result) {
                        return result.data;
                    });
                },

                deleteAttachment: function (deviationId, attachmentId) {
                    var url = resourceUrl + "/" + deviationId + "/deleteAttachment/" + attachmentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                getList: function () {
                    var url = resourceUrl;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getListByStatus: function (status, timePeriod) {
                    var url = resourceUrl + "/getbystatus/" + status;
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getTranslationsWithProblemCategories: function () {
                    var url = webApiUrl + "/deviationadmin/getTranslationsWithProblemCategories";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getProblemCategories: function () {
                    var url = resourceUrl + "/getProblemCategories";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getProblemCategoriesWithDeviation: function () {
                    var url = resourceUrl + "/getProblemCategoriesWithDeviation";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                saveCategoryTree: function (categorytree) {
                    var url = webApiUrl + "/deviationadmin/saveCategoryTree";
                    return $http.put(url, categorytree).then(function (result) {
                        return result.data;
                    });
                },

                createProblemCategory: function (problemCategory) {
                    var url = webApiUrl + "/deviationadmin/addProblemCategory";
                    return $http.post(url, problemCategory).then(function (result) {
                        return result.data;
                    });
                },
                updateProblemCategory: function (problemCategory) {
                    var url = webApiUrl + "/deviationadmin/updateProblemCategory";
                    return $http.put(url, problemCategory).then(function (result) {
                        return result.data;
                    });
                },

                deleteProblemCategory: function (id) {
                    var url = webApiUrl + "/deviationadmin/deleteProblemCategory/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                getTranslationsWithCauses: function () {
                    var url = webApiUrl + "/deviationadmin/getTranslationsWithCauses";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getCauses: function () {
                    var url = resourceUrl + "/getProblemReasons";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createCause: function (cause) {
                    var url = webApiUrl + "/deviationadmin/addProblemReason";
                    return $http.post(url, cause).then(function (result) {
                        return result.data;
                    });
                },
                updateCause: function (cause) {
                    var url = webApiUrl + "/deviationadmin/updateProblemReason";
                    return $http.put(url, cause).then(function (result) {
                        return result.data;
                    });
                },

                deleteCause: function (id) {
                    var url = webApiUrl + "/deviationadmin/deleteProblemReason/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },


                createDeviationType: function (type) {
                    var url = webApiUrl + "/deviationadmin/createDeviationType";
                    return $http.post(url, type).then(function (result) {
                        return result.data;
                    });
                },

                getTranslationsWithDeviationType: function (id) {
                    var url = webApiUrl + "/deviationadmin/getTranslationsWithDeviationType/" + id;
                    return $http.get(url, id).then(function (result) {
                        return result.data;
                    });
                },

                deleteDeviationType: function (id) {
                    var url = webApiUrl + "/deviationadmin/DeleteDeviationType/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                updateDeviationType: function (type) {
                    var url = webApiUrl + "/deviationadmin/updateDeviationType";
                    return $http.put(url, type).then(function (result) {
                        return result.data;
                    });
                },

                getDeviationTypes: function () {
                    var options = {
                        LanguageCode: userProvider().getUserLanguage()
                    }
                    var url = resourceUrl + "/getDeviationTypes";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getAllDeviationTypes: function () {
                    var options = {
                        LanguageCode: userProvider().getUserLanguage()
                    }
                    var url = resourceUrl + "/GetAllDeviationTypes";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getDeviationsByFilter: function (options) {
                    var url = resourceUrl + "/getDeviationsByFilter";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getDeviationsForPeriod: function (options) {
                    var url = resourceUrl + "/getDeviationsForPeriod";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },


                getDeviationType: function (id) {
                    var url = resourceUrl + "/getDeviationType/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getDeviationTypeConfiguration: function (id) {
                    var options = {
                        LanguageCode: userProvider().getUserLanguage()
                    }
                    var url = resourceUrl + "/getDeviationTypeConfiguration/" + id;
                    return $http.put(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getConsequences: function () {
                    var url = resourceUrl + "/getLossTypes";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createConsequence: function (lossType) {
                    var url = webApiUrl + "/deviationadmin/addLossType";
                    return $http.post(url, lossType).then(function (result) {
                        return result.data;
                    });
                },
                updateConsequence: function (lossType) {
                    var url = webApiUrl + "/deviationadmin/updateLossType";
                    return $http.put(url, lossType).then(function (result) {
                        return result.data;
                    });
                },

                deleteConsequence: function (id) {
                    var url = webApiUrl + "/deviationadmin/deleteLossType/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },


                /* returns summary of number of deviation pr category in period (including percentage and accumulated */
                getSummaryOfCategories: function (timePeriod) {
                    var url = resourceUrl + "/GetSummaryOfCategories";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getSummaryOfCategoriesWithFilter: function (options) {
                    var url = resourceUrl + "/getSummaryOfCategoriesWithFilter";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },


                getEvaluationSummaryList: function (options) {
                    var url = resourceUrl + "/GetEvaluationSummaryList";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getSummaryOfCauses: function (options) {
                    var url = resourceUrl + "/GetSummaryOfCauses";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getSummaryOfConsequences: function (options) {
                    var url = resourceUrl + "/GetSummaryOfConsequences";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },
                getAssetMonthCountOnStatus: function (timePeriod) {
                    var url = resourceUrl + "/GetAssetMonthCountOnStatus";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getSeverityMonthCountOnStatus: function (timePeriod) {
                    var url = resourceUrl + "/GetSeverityMonthCountOnStatus";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getAreaMonthCountOnStatus: function (timePeriod) {
                    var url = resourceUrl + "/GetAreaMonthCountOnStatus";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getLeadTimeForAssets: function (timePeriod) {
                    var url = resourceUrl + "/getLeadTimeForAssets";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                updateDeviation: function (deviation) {
                    var url = resourceUrl + "/" + deviation.id;
                    return $http.put(url, deviation).then(function (result) {
                        return result.data;
                    });
                },

                getUserlist: function (id) {
                    var url = resourceUrl + "/getUserListForDeviation/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getMyDeviations: function () {
                    var url = resourceUrl + "/getMyDeviations/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getStackedDeviationGroupedSum: function (options) {
                    var url = resourceUrl + "/GetStackedDeviationGroupedSum/";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

            }
        }
        return deviationService;
    }]);