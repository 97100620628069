var DigiLean = angular.module('DigiLean');
DigiLean.controller('strategyController', ['$scope', 'strategyService', 'authService',
    function ($scope, strategyService, authService) {

        $scope.vision = ""; //"Å være den foretrukne forbedringsplattformen i verden";
        $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
        // if page is refreshed we need to refresh
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
        });

        $scope.data = null;
        var strategy = null;



        $scope.subscribe("StrategyGoalCreated", function (goal) {
            if (!goal.parentId) {
                loadStrategyBoard();
            }
        });

        strategyService().getVision().then(function (visioninfo) {
            if (visioninfo) {
                $scope.vision = visioninfo.vision;
                strategy = visioninfo;

            }

        })

        function loadStrategyBoard() {
            strategyService().getStrategyBoard().then(function (board) {
                $scope.data = board;
                $scope.hasBoard = true;
            })
        }
        loadStrategyBoard();

        $scope.updateStrategy = function (vision) {

            $scope.vision = vision;
            if (strategy) {
                if (strategy.vision == vision) return;
                strategy.vision = vision;
                strategyService().updateStrategy(strategy).then(function (result) {
                    strategy = result
                })
            } else {
                strategy = {
                    Id: 0,
                    Vision: vision
                }
                strategyService().updateStrategy(strategy).then(function (result) {
                    strategy = result
                })
            }
        };



    }]);


