import { getUserProfile } from "@common/stores/userStore"

angular
    .module('DigiLean')
    .component("topLevelComment", {
        templateUrl: 'topLevelComment.html',
        bindings: {
            currentUser: '<',
            allUsers: '<',
            addFn: '&'
        },
        controller: ['$element',
            function ($element) {
            
                const $ctrl = this
                $ctrl.showAddComment = true
                $ctrl.text = ""
                $ctrl.mentions = []
                $ctrl.isSaving = false
                $ctrl.updateText = function(val, mentions) {
                    $ctrl.text = val
                    $ctrl.mentions = mentions
                }
                $ctrl.showAddCommentVisible = function() {
                    $ctrl.showAddComment = true
                }
                $ctrl.add = function() {
                    $ctrl.isSaving = true
                    var comment = {
                        comment: $ctrl.text,
                        commentedBy: $ctrl.currentUser.fullName,
                        commentedByUserId: $ctrl.currentUser.userId,
                        level: 0,
                        mentions: ''
                    }
                    if ($ctrl.mentions && $ctrl.mentions.length > 0) 
                        comment.mentions = JSON.stringify($ctrl.mentions)
                    
                    const promise = $ctrl.addFn({comment})
                    if (promise && promise.then) {
                        promise.then(function() {
                            $ctrl.text = ""
                            $ctrl.showAddComment = false
                            $ctrl.isSaving = false
                        })
                    } else {
                        dispatchCustomEvent("addcomment", comment)
                        $ctrl.text = ""
                        $ctrl.showAddComment = false
                        $ctrl.isSaving = false
                    }
                }
                function dispatchCustomEvent(name, detail) {
                    const options = {
                        detail,
                        bubbles: true,
                        composed: true
                    }
                    $element[0].dispatchEvent(new CustomEvent(name, options))
                }
                $ctrl.$onInit = function() {
                    $ctrl.currentUser = getUserProfile();
                }
            }]
    })
