// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('editDataListController', ['$scope',  '$uibModalInstance', 'dataListService', 'dataList',
    function ($scope, $uibModalInstance, dataListService, dataList) {
        $scope.dataList = {
            id: dataList.id,
            name: dataList.name,
            description: dataList.description
        };
        $scope.ok = function () {     
            dataListService().updateList($scope.dataList.id, $scope.dataList).then(function (dataList) {
                $uibModalInstance.close(dataList);
            });

        }
        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);