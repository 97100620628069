angular
    .module('DigiLean')
    .controller("projectController", ['$rootScope', '$scope', '$translate', '$uibModal', 'NgTableParams', 'projectService', 'planService', 'authService', 
    function ($rootScope, $scope, $translate,$uibModal, NgTableParams, projectService, planService, authService) {

        $scope.showTeamBoards = false;
        $scope.title = 'loading plan..';
        $scope.yearlyTimespan = true;
        $scope.editable = true;
        $scope.totalProjects = 0;

        $scope.options = {

            fromDate: getDateFromCurrentDate(-7),
            toDate:  getDateFromCurrentDate(14),
            currentDate: 'column',
            currentDateValue: new Date()
        }

        function getDateFromCurrentDate(numberOfDaysToAdd){
            var someDate = new Date();
            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            return someDate;
        }

        $scope.getToday = new Date();
        $scope.customerId = authService.getCurrentUser().customerId;

        $scope.boardPlans = [];

        $scope.handleRowIconClick = function (rowModel) {
            alert('Icon from ' + rowModel.name + ' row has been clicked.');
        };

    
        // Reload data action
        $scope.load = function () {
    
            $scope.showTeamBoards = false;
            var planData = planService.getPlanData($scope.customerId);
            $scope.data = planData.data;
            setIsYearlyTimespan(planData.duration);
            
        };

        function setIsYearlyTimespan(days) {
            if(days > 365) {
                $scope.yearlyTimespan = true;
            } else {
                $scope.yearlyTimespan = false;
            }
        };

        $scope.load();

        $translate(['COMMON_ALL', 'COMMON_NEW', 'COMMON_IN_PROGRESS', 'IMPROVEMENT_IMPLEMENTED', 'IMPROVEMENT_EVALUATED']).then(function (translations) {
            $scope.status = [
                { id:'All', name: translations.COMMON_ALL },
                { id:'New', name: translations.COMMON_NEW },
                { id:'In Progress', name: translations.COMMON_IN_PROGRESS },
                { id:'Implemented', name: translations.IMPROVEMENT_IMPLEMENTED },
                { id:'Evaluated', name: translations.IMPROVEMENT_EVALUATED }
            ];
        });

        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.status = [
                { id:'All', name: $translate.instant('COMMON_ALL') },
                { id:'New', name: $translate.instant('COMMON_NEW') },
                { id:'In Progress', name: $translate.instant('COMMON_IN_PROGRESS') },
                { id:'Implemented', name: $translate.instant('IMPROVEMENT_IMPLEMENTED')  },
                { id:'Evaluated', name: $translate.instant('IMPROVEMENT_EVALUATED') }
            ];
        });

        $scope.newPieChart = {
            data: [0, 0, 0, 0],
            options: {
                radius: 18,
                fill: ["#ffffff"],
            }
        };
        $scope.planPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#5cb85c", "#ffffff", "#ffffff", "#ffffff"],
            }
        };
        $scope.doPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#5cb85c", "#5cb85c", "#ffffff", "#ffffff"],
            }
        };
        $scope.checkPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#ffffff"]
            }
        };
        $scope.actPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"]
            }
        };
        function getStatusOrder(dbstatus) {
            switch (dbstatus) {
                case "New":
                    return 1;
                case "Plan":
                    return 2;
                case "Do":
                    return 3;
                case "Check":
                    return 4;
                case "Act":
                    return 5;
                case "Closed":
                    return 6;
                default:
                    return 0;
            }
        }
        projectService().getList().then(function (projects) {
            $scope.totalProjects = projects.length;
            angular.forEach(projects, function (project) {
                project.statusorder = getStatusOrder(project.status);
                checkMilestones(projects);
                calculateRealization(project);
            })
             $scope.tableParams = new NgTableParams({}, { dataset: projects });
        });
        /*
        projectService().getProjectInfoForA3s().then(function (a3s) {
            angular.forEach(a3s, function (a3) {
                a3.statusorder = getStatusOrder(a3.status);
                checkMilestones(a3);
                calculateRealization(a3);
            })
             $scope.tableParams = new NgTableParams({}, { dataset: a3s });
        });*/


        

        function calculateRealization(a3) {
            if (a3.estimatedSavings && a3.estimatedTotalCost) {
                a3.estimated = a3.estimatedSavings - a3.estimatedTotalCost;
            }
            if (a3.actualSavings && a3.actualTotalCost) {
                a3.realized = a3.actualSavings - a3.actualTotalCost;
            }
        }

        function checkMilestones(a3) {
            if (a3.milestones && a3.milestones.length > 0) {
                a3.milestoneMissed = false;
                for (var i = 0; i < a3.milestones.length; i++) {
                    if (a3.milestones[i].actualDate) {
                        a3.milestones[i].status = "completed";
                    } else if (a3.milestones[i].plannedDate) {
                        //check if overdue/missed
                        var duration = moment.duration(moment().diff(moment(a3.milestones[i].plannedDate)));
                        var days = duration.asDays();
                        if (days > 1) {
                            a3.milestones[i].status = "missed";
                            a3.milestoneMissed = true;
                        } else {
                            a3.milestones[i].status = "future";
                        }
                    }
                }
            }
        }

        $scope.search = function () {
            
            $scope.showTeamBoards = false;
        }

        $scope.selectStatus = function (status) {
            
            $scope.showTeamBoards = false;
            //TODO: hent prosjekt med gitt status
        }


        $scope.selectTeamBoards = function () {
           var period = {
                from: new Date(2017, 11, 1).toJSON(),
                to: new Date(2019, 1, 1).toJSON()
            }
            planService.getBoardPlansForPeriod(period).then(function (boardPlans) {
                $scope.boardPlans = boardPlans;
                $scope.title = boardPlans[0].name;
            });
            $scope.showTeamBoards = true;
        }

        $scope.new = function () {

            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newProject.html',
                controller: 'newProjectController'
            });
        };
    }]);



