﻿import { getUserProfile } from "@common/stores/userStore"
import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .component("replyableComment", {
        templateUrl: 'replyableComment.html',
        bindings: {
            comment: '<',
            selectedCommentId: '<?',
            currentUser: '<',
            allUsers: '<',
            saveFn: '&',
            deleteFn: '&'
        },
        controller: ['$translate',
            function ($translate) {
                const $ctrl = this;

                $ctrl.text = "";
                $ctrl.isSaving = false;
                $ctrl.isDeleting = false;
                $ctrl.$onInit = function () {
                    $ctrl.showAddReply = false;
                    if ($ctrl.comment && $ctrl.comment.id == $ctrl.selectedCommentId) {
                        $ctrl.isSelected = true;
                    } else {
                        $ctrl.isSelected = false;
                    }
                    calculateTimeElapsed();
                    $ctrl.currentUser = getUserProfile();
                };

                $ctrl.updateText = function (val, mentions) {
                    $ctrl.text = val;
                    $ctrl.mentions = mentions;
                };

                $ctrl.$onChanges = function (changes) {
                    if (changes.comment) {
                        calculateTimeElapsed();
                    }
                };

                $ctrl.replyToComment = function () {
                    $ctrl.showAddReply = !$ctrl.showAddReply;
                };

                $ctrl.deleteComment = function () {
                    $ctrl.isDeleting = true;
                    return $ctrl.deleteFunction($ctrl.comment).then(function () {
                        $ctrl.comment.isDeleted = true;
                        $ctrl.isDeleting = true;
                        calculateTimeElapsed();
                    });
                };
                $ctrl.hasChildren = function () {
                    if ($ctrl.comment.replies && $ctrl.comment.replies.length > 0)
                        return true;
                    return false;
                };
                $ctrl.addReply = function () {
                    var reply = {
                        parentId: $ctrl.comment.id,
                        level: $ctrl.comment.level + 1,
                        comment: $ctrl.text,
                        commentedBy: $ctrl.currentUser.fullName,
                        commentedByUserId: $ctrl.currentUser.userId,
                        mentions: ''
                    };
                    if ($ctrl.mentions && $ctrl.mentions.length > 0)
                        reply.mentions = JSON.stringify($ctrl.mentions);

                    $ctrl.isSaving = true;
                    
                    $ctrl.saveFunction(reply).then(function (savedReply) {
                        if (!$ctrl.comment.replies || !Array.isArray($ctrl.comment.replies))
                            $ctrl.comment.replies = [];
                        $ctrl.comment.replies.push(savedReply);
                        $ctrl.showAddReply = false;
                        $ctrl.text = "";
                        $ctrl.isSaving = false;
                        calculateTimeElapsed();
                    }).catch(function (ex) {
                        console.log(ex);
                    });

                };

                $ctrl.saveFunction = function (comment) {
                    return $ctrl.saveFn({ comment });
                };
                $ctrl.deleteFunction = function (comment) {
                    return $ctrl.deleteFn({ comment });
                };
                var translateOptions = {
                    month: $translate.instant('COMMON_TIMEELAPSED_MONTH'),
                    days: $translate.instant('COMMON_TIMEELAPSED_DAYS'),
                    hours: $translate.instant('COMMON_TIMEELAPSED_HOURS'),
                    mins: $translate.instant('COMMON_TIMEELAPSED_MINUTES'),
                    seconds: $translate.instant('COMMON_TIMEELAPSED_SECONDS'),
                    now: $translate.instant('COMMON_TIMEELAPSED_NOW')
                };
                var calculateTimeElapsed = function () {
                    $ctrl.comment.timeSinceComment = timeService.getTimeElapsed($ctrl.comment.commentDate, translateOptions);
                };
            }]
    });
