﻿import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean
    .directive("a3SmartActionList", ['a3Service', 'taskDrawingService', 'orderByFilter','boardTaskService', 'smartActionListService','translationService','$uibModal',
        function (a3Service, taskDrawingService, orderByFilter,boardTaskService, smartActionListService, translationService, $uibModal) {
            return {
                templateUrl: 'a3SmartActionList.html',
                restrict: 'E',
                scope: {
                    'a3id': '=',
                    'a3StepId': '=',
                    'assetid': '=',
                    'boardid': '=',
                    'readonly': '<'
                },
                link: function (scope, element, attrs) {
                    scope.editable = true;
                    scope.title = "";
                    var boardId = null;
                    scope.smartActionList = null;
                    scope.isStandAlone = true;
                    scope.useResizing = false;
                    scope.isAdminMode = false;
                    scope.viewMode = "List";
                    scope.showAddButton = true;
                    scope.columns = [];
                    scope.tasks = [];

                    scope.showArchive = false;
                    scope.taskAdded = null;
                    scope.taskDeleted = null;

                    scope.theme = {
                        background: "transparent",
                        color: "inherit"
                    };
                    /* Task Styling */
                    scope.postIts = taskDrawingService().getPostIts();
                    scope.borderColor = "#337ab7";  // borderColor
                    scope.fillColor = "white";

                    

                    scope.$watch("boardid", function () {
                        if (!scope.boardid) return;
                        boardId = scope.boardid;

                        loadsmartActionList(boardId);
                        getAllUsers();

                    });

                    scope.$watch("readonly", function () {
                        if (scope.readonly) {
                            scope.showAddButton = false;
                        } else {
                            scope.showAddButton = true;
                        }
                    });

                    function getAllUsers() {
                        a3Service().getUserlistForA3(scope.a3id).then(function (users) {
                            scope.users = users;
                            for (var i = 0; i < scope.users.length; i++) {
                                var imageUrl = taskDrawingService().getProfileImageUrl(scope.users[i].userId, "ExtraSmall");
                                if (imageUrl) {
                                    scope.users[i].profileImage = true;
                                    scope.users[i].profileImageUrl = imageUrl;
                                } else {
                                    scope.users[i].profileImage = false;
                                }
                            }
                        });
                    }

                    // Sorting
                    scope.sortColumnIndex = null;
                    scope.isAscedning = true;
                    scope.sortColumn = null;
                    scope.orderBy = function (column) {
                        if (scope.sortColumn && scope.sortColumn != column) {
                            scope.sortColumn.sort = "none";
                        }
                        if (!column.sort) column.sort = "none";
                        column.sort = getNextSortOrder(column.sort);
                        scope.sortColumn = column;
                        scope.sortColumnIndex = scope.columns.indexOf(column);
                        if (column.sort == "none") {
                            scope.tasks = orderByFilter(scope.tasks, 'sortOrder');
                        }
                        if (column.sort == "asc") {
                            scope.tasks = orderByFilter(scope.tasks, getSortValue, false);
                        }
                        if (column.sort == "desc") {
                            scope.tasks = orderByFilter(scope.tasks, getSortValue, true);
                        }
                    }

                    var getNextSortOrder = function (currentSortOrder) {
                        if (currentSortOrder == "none") return "asc";
                        if (currentSortOrder == "asc") return "desc";
                        if (currentSortOrder == "desc") return "none";
                    }

                    var getSortValue = function (task) {
                        if (task.cellModels) {
                            var cell = task.cellModels[scope.sortColumnIndex];
                            return cell.getSortValue();
                        }
                        return task.title;

                    }
                    
                    scope.subscribe("TaskUpdated", function (task) {
                        let existingTask = scope.tasks.find(t => t.id == task.id);
                        if (existingTask) {
                            existingTask.color = task.color;
                            setTaskStyle(existingTask);
                        }
                    });

                    scope.subscribe('TaskArchived', function (archivedtaskid) {
                        const existingTask = scope.tasks.find(t => t.id == archivedtaskid);
                        if (existingTask) {
                            var index = scope.tasks.indexOf(existingTask);
                            scope.tasks.splice(index, 1);
                            scope.taskDeleted = existingTask;
                        }
                    });

                    scope.subscribe('TaskMovedFromArchive', function (unarchivedtask) {
                        const existingTask = scope.tasks.find(t => t.id == unarchivedtask.id);
                        if (existingTask) {
                            var index = scope.tasks.indexOf(existingTask);
                            scope.tasks.splice(index, 1);
                        }
                    });

                    var loadsmartActionList = function (boardId) {
                        scope.hasBoard = true;
                        scope.boardId = boardId;
                        scope.isLoading = true;
                        smartActionListService().get(boardId).then(function (smartActionList) {
                            scope.smartActionList = smartActionList;
                            ensureModel()
                            setupTable();
                            setTimeout(function () {
                                scope.isLoading = false;
                               // resize();
                            }, 500);

                            // Trigger time view
                            // include this later   setTimeBoardModel();
                        })

                    }

                    function ensureModel() {
                        if (!scope.smartActionList || !scope.smartActionList.columns)
                            return

                        scope.smartActionList.columns.forEach(c => {
                            if (!c.settings)
                                c.settings = {}
                            if (typeof(c.settings) === "string")
                                c.settings = JSON.parse(c.settings)

                            if (!c.settings.widthSetting)
                                c.settings.widthSetting = ""
                            if (!c.settings.width)
                                c.settings.width = 100
                        })
                        
                    }
                    scope.columnWidthChangeHandler = function(col){
                        if(col){
                            smartActionListService().updateColumn(col).then(function (attribute) {
                            })
                        }
                    }
                    scope.changeDateColor = function (col) {

                        if (col.settings.changeColor) {
                            if (!col.settings.changeColorXDaysBefore) {
                                col.settings.changeColorXDaysBefore = 1;
                            }
                            var payload = {
                                columnId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            scope.publish("SmartDateColorCellUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                            })
                        } else {
                            col.settings.changeColor = false;
                            var payload = {
                                columnId: col.id,
                                changeColor: col.settings.changeColor,
                                changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                            }
                            scope.publish("SmartDateColorCellUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                            })
                        }
                    }

                    scope.updateChangeColorXDaysBefore = function (col) {
                        col.settings.changeColor = true;
                        var payload = {
                            columnId: col.id,
                            changeColor: col.settings.changeColor,
                            changeColorXDaysBefore: col.settings.changeColorXDaysBefore
                        }
                        scope.publish("SmartDateColorCellUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })

                    }


                    var getArchivedTasks = function () {
                        smartActionListService().getArchivedTasks(boardId).then(function (archivedtasks) {
                            scope.tasks = archivedtasks;

                            if (scope.tasks) {
                                for (var i = 0; i < scope.tasks.length; i++) {
                                    var task = scope.tasks[i];
                                    calculateCells(task, scope.columns);
                                    scope.publish("SmartActionListArchiveChanged", boardId);
                                }
                            }
                        })

                    }

                    scope.toggleArchive = function () {
                        scope.showArchive = !scope.showArchive;
                        if (scope.showArchive) {
                            scope.showAddButton = false;
                            getArchivedTasks()
                        } else {
                            scope.showAddButton = true;
                            loadsmartActionList(scope.boardId);
                        }
                    }

                    //col.settings.widthSetting == 'MANUAL' && {'width':col.settings.width +'px','min-width':col.settings.width +'px', 'max-width':col.settings.width +'px'}
                    scope.getColStyle = function(col) {
                        if (!col.settings || !col.settings.width)
                            return {}

                        if (col.settings.widthSetting == 'MANUAL')
                            return {'width':col.settings.width +'px','min-width':col.settings.width +'px', 'max-width':col.settings.width +'px'}
                        
                        return {}
                    }

                    function setupTable() {
                        scope.columns = scope.smartActionList.columns;
                        scope.tasks = scope.smartActionList.tasks;
                        if (scope.tasks) {
                            for (var i = 0; i < scope.tasks.length; i++) {
                                var task = scope.tasks[i];
                                setTaskStyle(task);
                                calculateCells(task, scope.columns)
                            }
                        }
                    }

                    function setTaskStyle(task) {
                        // Try to match fillColor
                        var predefinedPostIt = scope.postIts.find(p => p.fillColor == task.color);
                        if (predefinedPostIt) {
                            task.borderColor = predefinedPostIt.background;
                        } else {
                            // Try to match background color
                            var backgroundPostIt = scope.postIts.find(p => p.background == task.color);
                            if (backgroundPostIt) {
                                task.borderColor = backgroundPostIt.background;
                            } else {
                                task.borderColor = scope.borderColor;
                            }
                        }
                    }

                    var calculateCells = function (task, columns) {
                        task.cellModels = [];
                        for (let index = 0; index < columns.length; index++) {
                            const column = columns[index];
                            var cellModel = {
                                task: task,
                                column: column,
                                cell: null
                            }
                            var cell = task.cells.find(c => c.smartTableColumnId == column.id);
                            if (cell) {
                                if (cell.value) {
                                    cell.value = JSON.parse(cell.value);
                                }
                                cellModel.cell = cell;
                            } else {
                                // Create default cell
                                cellModel.cell = {
                                    id: 0,
                                    boardTaskId: task.id,
                                    smartTableColumnId: column.id,
                                    value: null
                                }
                            }
                            if (cellModel.column.settings && typeof (cellModel.column.settings) === "string") {
                                cellModel.column.settings = JSON.parse(cellModel.column.settings);
                            }
                            task.cellModels.push(cellModel)
                        }
                    }
                    var addColumnTotasks = function (column) {
                        for (let index = 0; index < scope.tasks.length; index++) {
                            var task = scope.tasks[index];
                            var cellModel = {
                                task: task,
                                column: column,
                                cell: null
                            }
                            // Create default attribute
                            cellModel.cell = {
                                id: 0,
                                boardTaskId: task.id,
                                smartTableColumnId: column.id,
                                value: null
                            }
                            task.cellModels.push(cellModel)
                        }
                    }

                    scope.columnNameUpdated = function (column) {
                        smartActionListService().updateColumn(column).then(function (attribute) {
                        })
                    }

                    scope.predefinedLabels = function (col) {
                        if (col.settings.usePredefinedLabels) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'predefinedLabels.html',
                                controller: 'predefinedLabels',
                                resolve: {
                                    labels: function () {
                                        return col.settings.predefinedLabels;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                if (!col.settings) {
                                    col.settings = {};
                                }
                                col.settings.predefinedLabels = result;
                                var payload = {
                                    columnId: col.id,
                                    predefinedLabels: col.settings.predefinedLabels,
                                    usePredefinedLabels: col.settings.usePredefinedLabels
                                }
                                scope.publish("SmartActionListTextCellLabelUpdated", payload);
                                smartActionListService().updateColumn(col).then(function (attribute) {
                                    //refreshTimeBoardModel();
                                })

                            });
                        } else {
                            col.settings.usePredefinedLabels = false;
                            var payload = {
                                columnId: col.id,
                                predefinedLabels: col.settings.predefinedLabels,
                                usePredefinedLabels: col.settings.usePredefinedLabels
                            }
                            scope.publish("SmartActionListTextCellLabelUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {
                                //refreshTimeBoardModel();
                            })
                        }
                    }


                    scope.newTask = function () {

                        var task = {
                            id: 0,
                            boardId: scope.boardId,
                            a3Id: scope.a3id,
                            a3StepId: scope.a3StepId,
                            isActionItem : true,
                            title: "",
                            text: "",
                            status: 'blank',
                            tags: "",
                            sortOrder: 0
                        }

                        var options = {
                            users: scope.users,
                            showDueDate: true,
                        }

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            updateSortorderTask();
                        });

                    }

                    
                    scope.addColumn = function (type) {
                        if (type === "numberAutomatic") {
                            addNumberAutomaticColumn(type);
                        } else {
                            var headertext = translationService().getTranslatedSmartColumnName(type);
                            if (headertext === "") {
                                headertext = type.charAt(0).toUpperCase() + type.slice(1);
                            }

                            var column = {
                                boardId: scope.boardId,
                                name: headertext,
                                attributeType: type,
                                sortOrder: scope.columns.length
                            }
                            smartActionListService().addColumn(column).then(function (savedAttribute) {

                            })

                        }
                    }
                    scope.editTimeframe = function (col) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'timeframeSelectorModal.html',
                            controller: 'timeframeSelectorModal',
                            resolve: {
                                timePeriod: function () {
                                    return col.settings.timePeriod;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            col.settings.timePeriod = result; //result = timePeriod.timeframe e.g."all"
                            var payload = {
                                columnId: col.id,
                                timePeriod: col.settings.timePeriod
                            }
                            scope.publish("SmartActionListNumberCellAutomaticTimeframeUpdated", payload);

                            smartActionListService().updateColumn(col).then(function (attribute) {

                            })

                        });

                    }
                    scope.getTimeframeLabel = function (timeframe) {
                        return timeService.getTimeframeLabel(timeframe);
                    }
                    scope.delete = function (task) {
                        boardTaskService().deleteTask(task.id).then(function (task) {
                        });
                    }

                    scope.deleteColumn = function (column) {
                        smartActionListService().deleteColumn(column.id);
                    }

                    scope.calculateNumberCellTotal = function (colDef) {
                        var total = 0;
                        for (var i = 0; i < colDef.projectAttributes.length; i++) {
                            var value = colDef.projectAttributes[i].value;
                            var valueObject = JSON.parse(value);
                            var cellNumber = valueObject.number;
                            if (cellNumber) {
                                total = total + cellNumber;
                            }
                        }
                        return total;
                    }

                    function addNumberAutomaticColumn(type) {

                        var dataSource = null;
                        var filters = null;
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return false;
                                },
                                dataSource: function () {
                                    return dataSource;
                                },
                                filters: function () {
                                    return filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                            var timePeriod = {
                                timeframe: "all",
                                period: timeService.getTimePeriod("all")
                            }

                            var unit = null;
                            if (result.dataSource.valueElement) {
                                unit = result.dataSource.valueElement.unit;
                            }
                            var cellSettings = JSON.stringify({
                                dataSource: result.dataSource,
                                timePeriod: timePeriod,
                                filters: result.filters,
                                aggregateType: "SUM",
                                unit: unit,
                                unitPlacement: "right"
                            })
                            var column = {
                                boardId: scope.boardId,
                                name: result.dataSource.title,
                                attributeType: type,
                                sortOrder: scope.columns.length - 1,
                                settings: cellSettings,
                            }
                            smartActionListService().addColumn(column).then(function (savedAttribute) {

                            })

                        });
                    };

                    scope.updateAggregateType = function (col) {
                        var payload = {
                            columnId: col.id,
                            aggregateType: col.settings.aggregateType
                        }
                        scope.publish("SmartActionListNumberCellAggregateTypeUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    scope.updateUnit = function (col) {
                        var payload = {
                            columnId: col.id,
                            unit: col.settings.unit
                        }
                        scope.publish("SmartActionListNumberCellUnitUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    scope.updateUnitPlacement = function (col) {
                        var payload = {
                            columnId: col.id,
                            unitPlacement: col.settings.unitPlacement
                        }
                        scope.publish("SmartActionListNumberCellUnitPlacementUpdated", payload);

                        smartActionListService().updateColumn(col).then(function (attribute) {
                        })
                    };

                    // TODO: FIX THIS Bakend/Frontend
                    scope.updateDecimals = function (col) {
                        var payload = {
                            decimals: col.settings.decimals,
                            smartActionListColumnId: col.id
                        }
                        // Use this event in order to reuse the project elements components
                        scope.publish("SmartActionListNumberCellDecimalUpdated", payload);


                        smartActionListService().updateColumn(col).then(function (attribute) {

                        })

                    };

                    scope.cellChangeHandler = function (cell) {
                        if (cell.value) {
                            cell.value = JSON.stringify(cell.value);
                        }

                        smartActionListService().updateCell(cell).then(function (savedCell) {
                            // Parse value again
                            cell.id = savedCell.id;
                            if (savedCell.value) {
                                cell.value = JSON.parse(savedCell.value);
                            }
                            // We need to notify yearlyboard
                            if (scope.timeboardEnabled) {
                                scope.cellUpdated = cell;
                            }

                        });
                    }

                    scope.subscribe("TaskDeleted", function (taskId) {
                        const existingTask = scope.tasks.find(t => t.id == taskId);
                        if (existingTask) {
                            const index = scope.tasks.indexOf(existingTask);
                            scope.tasks.splice(index, 1);
                            scope.taskDeleted = existingTask;
                        }
                    });

                    scope.subscribe("SmartActionListColumnUpdated", function (updatedColumn) {
                        if (updatedColumn.boardId === scope.boardId) {
                            const existingColumn = scope.columns.find(c => c.id == updatedColumn.id);
                            if (existingColumn) {
                                if (existingColumn.name !== updatedColumn.name) {
                                    existingColumn.name = updatedColumn.name;
                                }
                                if (updatedColumn.settings) {
                                    existingColumn.settings = JSON.parse(updatedColumn.settings);
                                }
                            }
                        }
                    });
                    scope.subscribe("SmartActionListColumnAdded", function (column) {
                        if (column.boardId === scope.boardId) {
                            const existingColumn = scope.columns.find(c => c.id == column.id);
                            if (!existingColumn) {
                                addColumnTotasks(existingColumn);
                                scope.columns.push(existingColumn);
                            }
                        }
                    });
                    scope.subscribe("SmartActionListColumnDeleted", function (column) {
                        if (column.boardId === scope.boardId) {
                            loadsmartActionList(scope.boardId);
                        }
                    });
                    scope.subscribe("SmartActionListtaskDeleted", function (task) {
                        const isPartOfBoard = $scope.tasks.filter(t => t.id == task.id);
                        if (isPartOfBoard && isPartOfBoard.length === 1) {
                            var tabletask = isPartOfBoard[0];
                            tabletask.cssState = "animated slideOutRight"; // Add animation
                            var index = scope.tasks.indexOf(tabletask);
                            if (index > -1) {
                                scope.tasks.splice(index, 1);
                            }

                        }
                    });

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        const existingTask = scope.tasks.find(t => t.id == cell.boardTaskId);
                        if (existingTask) {
                            const existingCell = existingTask.cells.find(c => c.smartTableColumnId == cell.smartTableColumnId);
                            if (existingCell) {
                                existingCell.value = cell.value;
                                // We need to notify yearlyboard
                                if (scope.timeboardEnabled) {
                                    scope.cellUpdated = cell;
                                }
                            }
                        }
                    });

                    
                    scope.subscribe("NewTask", function (task) {
                        if (task.boardId === scope.boardId) {
                            const existingTask = scope.tasks.find(t => t.id == task.id);
                            if (!existingTask) {
                                calculateCells(task, scope.columns);
                                scope.tasks.push(task);
                                if (scope.timeboardEnabled) {
                                    scope.taskAdded = task;
                                }
                            }
                        }
                    });


                    scope.subscribe("SmartActionListSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === scope.boardId) {
                            loadsmartActionList(scope.boardId);
                        }
                    });
                    scope.subscribe("SmartActionListRowSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === scope.boardId) {
                            for (var i = 0; i < scope.tasks.length; i++) {
                                var task = scope.tasks[i];
                                var updatedSortOrder = payLoad.sortOrders.find(s => s.boardTaskId == task.id);
                                if (updatedSortOrder) {
                                    task.sortOrder = updatedSortOrder.sortOrder;
                                }
                            }
                            scope.tasks = orderByFilter(scope.tasks, 'sortOrder');
                        }
                    });

                    scope.toggleAdminMode = function (isAdminMode) {
                        scope.isAdminMode = isAdminMode;
                    }

                    

                    // Drag and drop of columns events
                    scope.$on('column-header-bag.drop-model', function (e, el) {
                        var columnOrder = scope.columns;
                        var sortOrder = [];
                        for (var i = 0; i < scope.columns.length; i++) {
                            var column = scope.columns[i];
                            var columnOrder = {
                                smartActionListColumnId: column.id,
                                sortOrder: i
                            }
                            sortOrder.push(columnOrder)
                        }
                        smartActionListService().updateColumnSortOrder(scope.boardId, sortOrder).then(function () {

                        });

                    });
                    scope.$on('column-header-bag.drag', function (e, el) {
                        $(el).find(".drag-drop-icon").hide();
                        $(el).find(".option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    scope.$on('column-header-bag.drop', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                        el.removeClass('drag-moved');
                    });
                    scope.$on('column-header-bag.dragend', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                    });

                    scope.$on('column-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    scope.$on('column-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });


                    function updateSortorderTask(){
                        var taskOrder = scope.tasks;
                         var sortOrder = [];
                         for (var i = 0; i < scope.tasks.length; i++) {
                             var task = scope.tasks[i];
                             var taskOrder = {
                                 boardTaskId: task.id,
                                 sortOrder: i
                             }
                             sortOrder.push(taskOrder)
                         }
                         smartActionListService().updateTaskSortOrder(scope.boardId, sortOrder).then(function () {

                         });
                    }
                    // Drag and drop of tasks events
                     scope.$on('task-header-bag.drop-model', function (e, el) {
                        updateSortorderTask();

                     });
                }
            }
        }]);
