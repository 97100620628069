import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import cronTime from "cron-time-generator"
import { isValidCron } from 'cron-validator'


@customElement('cron-input-generator')
export class CronInputGenerator extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
    `
    
    onChange(e) {
        console.log(e)
    }
    render() {
        return html`
            <input type="text" @change=${(e) => this.onChange(e)}/>
        `
    }
}
