
angular
    .module('DigiLean')
    .component("deviationColumn", {
        templateUrl: 'deviationColumn.html',
        bindings: {
            context: '@',
            label: '@',
            labelNew: '@',
            newClicked: '&',
            name: '@',
            data: '<',
            columnLoaded: '&'
        },
        controller: ['$element', '$translate', 'deviationCommonService',
            function ($element, $translate, deviationCommonService) {
                var $ctrl = this
                $ctrl.labelText = "default"
                $ctrl.labelNewText = ""
                
                $ctrl.$onChanges = function (changes) {
                    if (changes.label) {
                        if (changes.label.currentValue) {
                            $ctrl.labelText = $translate.instant(changes.label.currentValue)
                        }
                    }
                    if (changes.labelNew) {
                        if (changes.labelNew.currentValue) {
                            $ctrl.labelNewText = $translate.instant(changes.labelNew.currentValue)
                        }
                    }
                }

                $ctrl.hasDueDate = function (item) {
                    if (item.dueDate) {
                        return true
                    } else return false
                }

                $ctrl.hasResponsible = function (item) {
                    if (item.ownerUserId) {
                        return true;
                    } else return false;
                }


                $ctrl.$onInit = function() {
                    if (!$ctrl.data)
                        $ctrl.data = []
                }

                $ctrl.$postLink = function() {
                    if ($ctrl.columnLoaded) {
                        const ulEl = $element[0].querySelector("ul")
                        $ctrl.columnLoaded({column: ulEl})
                    }
                    
                }

                $ctrl.openDeviation = (deviation) => {
                    deviationCommonService.deviationOpenForEdit(deviation.id).then(updatedDeviation => {
                        
                    })
                }
                
                // Open A3
                $ctrl.openA3 = (a3Id) => {
                    // deviationCommonService.deviationOpenForEdit(deviation.id).then(updatedDeviation => {
                        
                    // })
                }
   
            }]
    });