﻿angular.module("DigiLean").factory('boardLayoutService', [
    function () {
        function boardLayoutService() {
            return {
                getResponsibleForCategory: function (task) {
                    var category = $("th[data-categoryid='" + task.columnCategoryId + "'] .responsible-for-category").first();
                    var rowCategory = $("td[data-rowid='" + task.rowCategoryId + "'] .responsible-for-category").first();
                    var categoryResponsibleUserId = $(category).attr("data-responsibleuserid");
                    var rowCategoryResponsibleUserId = $(rowCategory).attr("data-responsibleuserid");

                    if (categoryResponsibleUserId && categoryResponsibleUserId !== "null") {
                        return this.getResponsibleData(category);
                    }
                    if (rowCategoryResponsibleUserId && rowCategoryResponsibleUserId !== "null") {
                        return this.getResponsibleData(rowCategory);
                    }
                    return {
                        responsibleUserId: "",
                        responsibleDisplayName: "",
                        responsibleUser: ""
                    };
                },

                getResponsibleData: function (selectorWithDataAttributes) {
                    var username = $(selectorWithDataAttributes).attr("data-responsibleuser");
                    var userId = $(selectorWithDataAttributes).attr("data-responsibleuserid");
                    var displayName = $(selectorWithDataAttributes).attr("data-responsibledisplayname");

                    return {
                        responsibleUserId: userId,
                        responsibleDisplayName: displayName,
                        responsibleUser: username
                    };
                },

                getIEVersion: function() {
                    var sAgent = window.navigator.userAgent;
                    var Idx = sAgent.indexOf("MSIE");
                  
                    // If IE, return version number.
                    if (Idx > 0) 
                      return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));
                  
                    // If IE 11 then look for Updated user agent string.
                    else if (!!navigator.userAgent.match(/Trident\/7\./)) 
                      return 11;
                  
                    else
                      return 0; //It is not IE
                }
            }
        }
        return boardLayoutService;
    }]);

