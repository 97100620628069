﻿angular
    .module('DigiLean')
    .directive("fullscreen", ['$interval',
        function ($interval) {
            return {
                templateUrl: 'fullscreen.html',
                restrict: 'E',
                scope: {
                    'mode': '@'
                },
                link: function (scope, element, attrs) {
                    scope.showFullscreenIcon = true;
                    scope.toggleFullScreen = function () {
                        scope.showFullscreenIcon = ! scope.showFullscreenIcon;
                        var isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||    // alternative standard method  
                         (document.mozFullScreen || document.webkitIsFullScreen);

                        if (typeof isInFullScreen === 'undefined') {
                            isInFullScreen = document.msFullscreenElement != null;
                        }

                        //var docElm = document.getElementById("wrapper");
                        var docElm = document.body;

                        if (!isInFullScreen) {

                            if (docElm.requestFullscreen) {
                                docElm.requestFullscreen();
                            } else if (docElm.mozRequestFullScreen) {
                                docElm.mozRequestFullScreen();
                            } else if (docElm.webkitRequestFullScreen) {
                                docElm.webkitRequestFullScreen();
                            }
                            else if (docElm.msRequestFullscreen) {
                                docElm.msRequestFullscreen();
                            }
                            scope.publish("EnterFullScreenMode");
                        } else {
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.msExitFullscreen) {
                                document.msExitFullscreen();
                            } else if (document.mozCancelFullScreen) {
                                document.mozCancelFullScreen();
                            } else if (document.webkitExitFullscreen) {
                                document.webkitExitFullscreen();
                            }
                            scope.publish("ExitFullScreenMode");
                        }
                    }
                }
            }
        }]);
