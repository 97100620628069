import { $LearningMaterialVisitedInfo } from "@api";
import { ScopeSet } from "@azure/msal-common";

angular
    .module('DigiLean')
    .controller("projectCellSelectionController", ['$scope', '$uibModalInstance', 'options',
        function ($scope, $uibModalInstance, options) {

            $scope.options = options;
            $scope.isStrategyContext = false;
            if($scope.options && $scope.options.context == "strategy"){
                $scope.isStrategyContext = true;
            }
        
            $scope.addColumn = function (columnid) {
                $uibModalInstance.close(columnid);
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

    
        }]);