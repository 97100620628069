var DigiLean = angular.module('DigiLean');

DigiLean.directive("deviationEvaluationList", ['debounce', '$translate', '$uibModal', 'NgTableParams', 'deviationService', 
    function (debounce, $translate, $uibModal, NgTableParams, deviationService) {
        return {
            templateUrl: 'deviationEvaluationList.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'timeframe': '@',
                'settings': '=',
                'settingsChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {

                scope.filters = null;

                var translateFilter = $translate.instant('COMMON_FILTER') + "...";

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    loadData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        timePeriod: scope.options.timePeriod,
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                scope.$watch('timeframe', function (timeframe) {
                    scope.setTimePeriodDebounced(timeframe, null);
                });


                // Default options
                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        };
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                function loadData() {
                    if (!scope.timePeriod || !scope.timePeriod.period) return;
                    var options = {
                        TimePeriod:scope.timePeriod.period,
                        Filters: scope.filters
                    };
                    deviationService().getEvaluationSummaryList(options).then(function (data) {
                        /*$scope.totalProjects = projects.length;
        
                        angular.forEach(projects, function (project) {
                            if (project.projectCustomer) {
                                project.projectCustomerName = project.projectCustomer.name;
                            }
                            project.statusorder = getStatusOrder(project.status);
        
                        })*/
                        scope.rowdata = data;
                        scope.tableParams = new NgTableParams({}, { dataset: scope.rowdata });
                    });
                }

                scope.subscribe("DeviationCreated", function (deviation) {
                    loadData();
                });

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.filters = null;
                    scope.settings = settings;
                    if (settings) {
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        loadData();
                    } else {
                        loadData();
                    }
                });

                scope.categoryFilter = {
                    category: {
                        id: "text",
                        placeholder: translateFilter,
                    }
                };
    
                scope.causeFilter = {
                    cause: {
                        id: "text",
                        placeholder: translateFilter,
                    }
                };
    
                scope.lossTypeFilter = {
                    lossType: {
                        id: "text",
                        placeholder: translateFilter,
                    }
                };
    
                scope.showDeviations = function (row) {
                    var currentfilter = [{
                        sourceColumn: "problemCategoryId",
                        operator: "InList",
                        items: [row.categoryId]
                    },
                    {
                        sourceColumn: "problemReasonId",
                        operator: "InList",
                        items: [row.causeId]
                    },
                    {
                        sourceColumn: "lossTypeId",
                        operator: "InList",
                        items: [row.lossTypeId]
                    }];
                    var order = [{
                        sourceColumn: "Status",
                        SortDirection: "ASC"
                    }, {
                        sourceColumn: "Severity",
                        SortDirection: "DESC"
                    }];
                    var options = {
                        filters: currentfilter,
                        sortOrders: order,
                        timePeriod:scope.timePeriod.period,
                    };
    
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'deviationListForm.html',
                        controller: 'deviationListModalController',
                        windowClass: 'fullDeviation-modal-window',
                        resolve: {
                            options: function () {
                                return options;
                            }
                        }
                    });
                };


                function getDeviations(columnOrderNo){
                    deviationService().getDeviationsByFilter()
                    //TODO implement use getDeviationsByFilter");

                }


            }
        }
    }]);
