import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

const DigiLean = angular.module('DigiLean');
DigiLean.directive("radarchart", ['$uibModal', '$translate','debounce',
    function ($uibModal, $translate, debounce) {
        return {
            templateUrl: 'radarchart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                
                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                
                scope.imageUrl = staticFileService.image('radarchart.jpg');
                scope.seriesData = [];
                

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.settings = settings;
                    // apply
                    if (scope.settings) {
                                
                        if (scope.settings.title) {
                            scope.title = scope.settings.title;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        if (settings.seriesData) {
                            scope.seriesData = scope.settings.seriesData;
                            drawChart();
                        } else {
                            demoData();
                        }
                    } else{
                        demoData();
                    }
                    /*
                    if (!scope.seriesData && scope.isAdminMode){
                        setTimeout(function() {
                            scope.selectData();
                        }, 200); 
                    } else{
                        demoData();
                    } */
                    
                });

                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        seriesData: scope.seriesData,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }


                scope.commitChangesDebounced = debounce(scope.updateSettings, 2000, false);
                function demoData() {
                    /*var cat = ['Sales', 'Marketing', 'Development', 'Customer Support',
                    'Information Technology', 'Administration'];
                    var goalData = {
                        name: 'Allocated Budget',
                        data: [43000, 19000, 60000, 35000, 17000, 10000],
                        color: '#FF0000'
                    }
                    var assesmentData = {
                        name: 'Actual Spending',
                        data: [50000, 39000, 42000, 31000, 26000, 14000],
                        color: '#0099de'
                    }
                    scope.seriesData = {
                        categories: cat,
                        goalData: goalData,
                        assesmentData: assesmentData
                    };*/
                    scope.title = "Demo";
                    /*var cat = ['Long-term philosophy', 'Flow', 'Pull', 'Workload',
                    'Fix problems', 'Standardisation', 'Visual control', 'Reliable technology',
                    'Grow leaders', 'Develop people', 'Partners', 'Go and see', 'Slow decisionmaking',
                    'Continuous improvements'];*/
                        var cat = [$translate.instant('DASHBOARD_RADAR_CHART_LONGTERM'),
                        $translate.instant('DASHBOARD_RADAR_CHART_FLOW'),
                        $translate.instant('DASHBOARD_RADAR_CHART_PULL'),
                        $translate.instant('DASHBOARD_RADAR_CHART_WORKLOAD'),
                        $translate.instant('DASHBOARD_RADAR_CHART_FIX'),
                        $translate.instant('DASHBOARD_RADAR_CHART_STANDARDIZATION'),
                        $translate.instant('DASHBOARD_RADAR_CHART_VISUAL_CONTROL'),
                        $translate.instant('DASHBOARD_RADAR_CHART_RELIABLE_TEC'),
                        $translate.instant('DASHBOARD_RADAR_CHART_GROW_LEADERS'),
                        $translate.instant('DASHBOARD_RADAR_CHART_DEV_PEOPLE'),
                        $translate.instant('DASHBOARD_RADAR_CHART_PARTNERS'),
                        $translate.instant('DASHBOARD_RADAR_CHART_GO_SEE'),
                        $translate.instant('DASHBOARD_RADAR_CHART_SLOW_DES'),
                        $translate.instant('DASHBOARD_RADAR_CHART_CON_IMP')];
                    var goalData = {
                        name: $translate.instant('DASHBOARD_RADAR_CHART_GOAL'),//'Goal this year',
                        data: [5, 7, 8, 5, 4, 4, 4, 6, 3, 4, 3, 5, 5, 6],
                        color: '#FF0000'
                    }
                    var assesmentData = {
                        name: $translate.instant('A3_STEP_CURRENT_SITUATION'),//'Current situation',
                        data: [3, 5, 8, 4, 3, 2, 4, 6, 1, 2, 2, 4, 5, 2],
                        color: '#0099de'
                    }
                    scope.seriesData = {
                        categories: cat,
                        goalData: goalData,
                        assesmentData: assesmentData
                    };
                    drawChart();
                }


                scope.registerData = function () {

                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'radarchartRegistrationDialog.html',
                        controller: 'radarchartRegistrationController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            data: function () {
                                return scope.seriesData;
                            }
                        }
                    });
                    modalInstance.result.then(function (result) {
                        scope.seriesData = result;
                        drawChart();
                        scope.updateSettings();
                    }, function(res){});
                };
                

                let currentChart = null;
                    function drawChart() {

                    var renderElement = $(elem).find(".radarchart")[0];
                    currentChart = new Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'line',
                            polar: true,
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        title: {
                            text: '',
                            x: -80
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        pane: {
                            size: '80%'
                        },
                        xAxis: {
                            categories: scope.seriesData.categories,
                            tickmarkPlacement: 'on',
                            lineWidth: 1,
                            tickInterval: 1,
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        yAxis: {                
                            gridLineInterpolation: 'polygon',
                            lineWidth: 0,
                            min: 0,
                            max: 10,
                            tickInterval: 2,
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        tooltip: {
                            shared: true,
                            pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br>'
                        },
            
                        series: [{
                            name: scope.seriesData.goalData.name,
                            data: scope.seriesData.goalData.data,
                            color: scope.seriesData.goalData.color,
                            pointPlacement: 'on'
                        }, {
                            name: scope.seriesData.assesmentData.name,
                            data: scope.seriesData.assesmentData.data,
                            color: scope.seriesData.assesmentData.color,
                            pointPlacement: 'on'
                        }],
                        legend: {
                            itemStyle: {
                                fontSize: "0.9em"
                            }
                        }
                    });
                    resizeChart();
                }


                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight
                    
                    // find with inside padding of graph-content
                    let graphContent = appEl.querySelector(".graph-content")
                    if (graphContent) {
                        let graphContentInner = graphContent.querySelector(".graph-content-inner")
                        if (graphContentInner)
                            width = graphContentInner.offsetWidth
                    }
                    var chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (currentChart)
                        currentChart.setSize(width, height, true)
                }

                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

            }
        }
    }]);
