import config from "@common/config"
import * as assetService from "@common/services/assetService"
import { userFull } from "@common/stores/userStore"

const DigiLean = angular.module('DigiLean')
DigiLean.controller('profileSettingsController', ['$scope','$stateParams', '$filter', 'navigationService','employeeService', 'authService',
    function ($scope, $stateParams, $filter, navigationService,employeeService, authService) {

        $scope.digiLeanVersion = config.digiLeanBuildVersion
        $scope.hasVisitedSettingsPage = true;

        $scope.businessUnitId = userFull.user.businessUnitId
        $scope.tabs = [
            {
                name: "info",
                isActive: false,
                title: "COMMON_INFO"
            },
            {
                name: "notifications",
                isActive: false,
                title: "COMMON_NOTIFICATIONS"
            },
            {
                name: "permissions",
                isActive: false,
                title: "ADMIN_PERMISSIONS"
            },
        ]

        employeeService().getUserPreference("HasVisitedSettingsPage").then(function(value) {
            if (!value) {
                $scope.hasVisitedSettingsPage = false;
            }
        });

        $scope.selectedTab = null;
        $scope.selectedTabName = "";
        

        $scope.selectTab = function (tab) {
            if ($scope.selectedTab) {
                $scope.selectedTab.isActive = false;
            }
            tab.isActive = true;
            $scope.selectedTab = tab
            $scope.selectedTabName = tab.name;
            if($scope.selectedTabName == 'permissions'){
                assetService.getList().then(function (assets) {
                    $scope.assets = assets;
                });
                $scope.hasProjectModule = navigationService().hasModule("PROJECT");
            
                if($scope.hasProjectModule){
                    assetService.getMyProjectAssets().then(function (assets) {
                        $scope.projectassets = assets;
                    });
                }
                $scope.isAdmin = authService.hasRole("Admin");
                $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin")
                $scope.isBoardDesigner = authService.hasRole("BoardDesigner")
                $scope.isDataAdmin = authService.hasRole("DataAdmin")
                $scope.isMessageWriter = authService.hasRole("MessageWriter")
                $scope.isImprovementAdmin = authService.hasRole("ImprovementAdmin");
                $scope.isDeviationAdmin = authService.hasRole("DeviationAdmin");
                $scope.isProjectAdmin = authService.hasRole("ProjectAdmin");
                $scope.isA3Admin = authService.hasRole("A3Admin");
                $scope.isLearningMaterialEditor = authService.hasRole("LearningAdmin");
                $scope.isStrategyAdmin = authService.hasRole("StrategyAdmin");
            
            }
        }

        // Select tab based on input or default first.
        if ($stateParams.tab) {
            var tab = $stateParams.tab;
            var hasTab = $filter('filter')($scope.tabs, { name: tab });
            if (hasTab.length == 1) {
                $scope.selectTab(hasTab[0]);
            } else {
                $scope.selectTab($scope.tabs[0]);
            }

        } else {
            $scope.selectTab($scope.tabs[0]);
        }

        
        
        $scope.ok = function () {
            $scope.hasVisitedSettingsPage = true;
            // user has visited
            employeeService().updateUserPreference("HasVisitedSettingsPage", true).then(function(value) {

            });
        }


    }]);