var DigiLean = angular.module('DigiLean');  
DigiLean.controller('myDeviationsController', ['$scope','$filter', '$uibModalInstance', '$uibModal', 'deviationService',
    function ($scope, $filter, $uibModalInstance, $uibModal, deviationService) {
        $scope.items = [];

        $scope.deviations = [];
        $scope.subscribe("DeviationChangedStatus", function (changed) {
            // Check if comment is related to one of users
            var item = $filter('filter')($scope.deviations, { id: changed.deviationId }, true);
            if (item.length > 0) {
                item[0].status = changed.status;
                item[0].changed = true;
                setDeviationFirstInList(item[0]);
            }
        });
        $scope.subscribe("DeviationTitleChanged", function (item) {
            var existing = $filter('filter')($scope.deviations, { id: item.deviationId }, true);
            if (existing.length === 1) {
                existing[0].title = item.newText;
                existing[0].cssClass = "animated bounce"; // Add animation
                existing[0].changed = true;
                $scope.$apply();
            }
        });
        $scope.subscribe("DeviationUpdated", function (item) {
            // find by id
            var existingDeviations = $filter('filter')($scope.deviations, { id: item.id }, true);
            if (existingDeviations.length === 1) {
                getDeviations();
            }
        });
        $scope.subscribe("DeviationCreated", function (item) {
            item.cssClass = "animated bounce"; // Add animation
            item.changed = true;
            // Make sure it's created by current user
            if (userid === item.createdByUserId) {
                getDeviations();
            }
        });
        $scope.subscribe("DeviationDeleted", function (deviation) {
            // find by id
            var deletedid = deviation.id;
            var existingDeviations = $filter('filter')($scope.deviations, { id: deletedid }, true);
            if (existingDeviations.length === 1) {
                getDeviations();
                // splice does not work with pager
                //var index = $scope.deviations.indexOf(existingDeviations[0]);
                //$scope.deviations.splice(index, 1);
            }
        });
        $scope.addComment = function (deviation) {
            if (typeof deviation.comments === 'undefined') {
                deviation.comments = [];
            }
            var currentComment = {
                comment: deviation.activeComment
            };
            deviationService().addComment(deviation.id, currentComment).then(function (savedComment) {

            });
            deviation.activeComment = "";

        }

        $scope.subscribe("DeviationCommentedOn", function (comment) {
            var item = $filter('filter')($scope.deviations, { id: comment.deviationId }, true);
            if (item.length > 0) {
                item[0].changed = true;
                item[0].comments.push(comment);
            }
        });

        function setDeviationFirstInList(item){  
            if (item) {
                var index = $scope.deviations.indexOf(item);
                $scope.deviations.splice(index, 1);
                $scope.deviations.unshift(item);
            }
        }

        function getDeviations() {
            $scope.isLoading = true;
            deviationService().getMyDeviations().then(function (items) {
                items.forEach(function (deviation) {
                    deviation.changed = false;
                });
                $scope.deviations = items;
                $scope.isLoading = false;
            });
        }
        getDeviations();

        $scope.showComments = function (suggestion) {
            suggestion.showComments = !suggestion.showComments;
        }

        $scope.close = function () {
            $uibModalInstance.close();      
        };

        $scope.openDeviation = function (id) {
            deviationService().get(id).then(function (deviation) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'fullDeviation.html',
                    controller: 'fullDeviationController',
                    windowClass: 'full-screen',
                    resolve: {
                        deviation: function () {
                            return deviation;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
            });
        }

    }]);