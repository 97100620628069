angular
    .module('DigiLean')
    .directive("topTimeImprovementInitiatives", ['$filter', '$state','$translate', '$uibModal','suggestionService',
        function ($filter, $state,$translate, $uibModal,suggestionService) {
            return {
                templateUrl: 'topTimeImprovementInitiatives.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {
                    scope.timeframe = "thisyear";

                    scope.options = {
                        timePeriod: {
                            timeframe: "thisyear",
                            useDefaultTimeframes: true,
                            timeframes:[]
                        }
                    };
                    
                    var chartHeight = 400;
                    var grafcolor = ['#2A2E36', '#0099DE', '#5cb85c', '#0077B5', '#5e5e5e', '#f8ac59', '#434348', '#434338'],
                        graphtextcolor = '#ffffff';

                    var initiatives = [];
                        

                    var yAxisText = "";
                    var unrealizedLabel = "";
                    var realizedLabel = "";

                    $translate(['COMMON_HOURS','A3_REALIZED', 'COMMON_ESTIMATED']).then(function (translations) {
                        
                        yAxisText = translations.COMMON_HOURS;
                        realizedLabel = translations.A3_REALIZED;
                        unrealizedLabel = translations.COMMON_ESTIMATED;
                        getData();
                    });
                    

                    scope.periodChangedHandler = function (timePeriod) {
                        scope.timePeriod = timePeriod.period;
                        scope.timeframe = timePeriod.timeframe;
                        getData();
                    }

                    function getMoreInfo(index){
                        var selected = initiatives[index];
                        if(selected && selected.improvementSuggestionId){
                            suggestionService().get(selected.improvementSuggestionId).then(function (suggestion) {
                                var modalInstance = $uibModal.open({
                                    backdrop: 'static',
                                    templateUrl: 'fullImprovementInfoForm.html',
                                    controller: 'fullImprovementModalController',
                                    windowClass: 'fullSuggestion-modal-window',
                                    resolve: {
                                        suggestion: function () {
                                            return suggestion;
                                        },
                                        activeTab: function () {
                                            return "Info";
                                        }
                                    }
                                });
                                modalInstance.result.then(function (updatedSuggestion) {
                                    scope.getValues();
                                });
                            });
                        } else if(selected && selected.a3Id){
                            $state.go('a3wizard', { id: selected.a3Id });
                        }
                    }

                    function getData() {
                        if (!scope.timePeriod) return;
                        var realized = [],
                            estimated = [],
                            labelcategories = [];
                        suggestionService().getTopTimeImprovementInitiatives(scope.timePeriod).then(function (result) {
                            initiatives = result;

                            if(result && result.length > 10){     
                                chartHeight = 400 + 30*(result.length -10);
                            }
                            // Build the data arrays
                            for (var i = 0; i < result.length; i++) {
                                var estimatedValue = 0;
                                if(result[i].estimated){
                                    var text = '';
                                    if(result[i].endDate){
                                        text = moment(result[i].endDate).format("MMM DD, YYYY");
                                    }
                                    estimatedValue = [text, result[i].estimated];
                                }
                                var realizedValue = 0;
                                if(result[i].realized){
                                    var text = '';
                                    if(result[i].endDate){
                                        text = moment(result[i].endDate).format("MMM DD, YYYY");
                                    }
                                    realizedValue = [text,result[i].realized];
                                } 
                                labelcategories.push(result[i].title);
                                estimated.push(estimatedValue); 
                                realized.push(realizedValue);
                            }

                            var renderElement = $(elem).find(".toptimeimprovementinitiatives")[0];
                            if (renderElement)
                                Highcharts.chart({
                                    chart: {
                                        renderTo: renderElement,
                                        type: 'bar',
                                        height: chartHeight,
                                    },
                                    title: {
                                        text: ''
                                    },
                                    xAxis: {
                                        categories: labelcategories            
                                    },
                                    yAxis: {
                                        min: 0,
                                        title: {
                                            text: yAxisText  
                                        }
                                    },
                                    credits: {
                                        enabled: false
                                    },
                                    exporting: { enabled: false },
                                    legend: {
                                        reversed: true
                                    },
                                    plotOptions: {
                                        series: {
                                            stacking: 'normal',
                                            point: {
                                                events: {
                                                  click: function(te) {
                                                    getMoreInfo(this.x);
                                                  }
                                                }
                                              }
                                        },bar: {
                                            dataLabels: {            
                                        formatter: function(){
                                        return this.point.name
                                        },
                                              enabled: true
                                          }
                                        }
                                    },
                                    series: [
                                        {
                                            name: unrealizedLabel,
                                            data: estimated,
                                            color: grafcolor[7] 
                                        },{
                                            name: realizedLabel, 
                                            data: realized,
                                            color: grafcolor[2]
                                        }]
                                });
                        });
                    }
                }
            }
        }]);
