angular
    .module('DigiLean')
    .controller("newContactPersonModalController", ['$scope', '$uibModalInstance', 'contactlistService', 'contact', 
        function ($scope, $uibModalInstance, contactlistService, contact) {

            $scope.contact = contact;

            $scope.ok = function () {
                contactlistService().add($scope.contact).then(function (savedItem) {
                    $uibModalInstance.close(savedItem);
                });         
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            $scope.checkEmail = function (email) {
                if(email){
                    $scope.invalidEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
                } else {
                    $scope.invalidEmail = false;
                }
            }

        
        }]);


          
        