import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.factory('boardTaskService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {
        function boardTaskService() {
            var resource = "/task";
            var resourceUrl = webApiUrl + resource;

            return {
                get: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getInfo: function (id) {
                    var url = resourceUrl + "/getinfo/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                }, 
                getRepeatInfo: function (id) {
                    var url = resourceUrl + "/getRepeatInfo/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                }, 
                getTaskInfoBoardName: function (id) {
                    var url = resourceUrl + "/getTaskInfoBoardName/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                canEdit: function (id) {
                    var url = resourceUrl + "/canedit/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getMyTasks: function (timeperiod) {
                    var url = resourceUrl + "/mytasks";
                    return $http.post(url, timeperiod).then(function (result) {
                        return result.data;
                    });
                },

                getTagCount: function () {
                    var url = resourceUrl + "/getTagCount/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getModifiedInfo: function (taskId) {
                    var url = resourceUrl + "/getModifiedInfo/" + taskId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getTableValues: function (tableParams) {
                    var url = resourceUrl + "/getTableValues/";
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },

                getTotalNumberOfTasks: function () {
                    var url = resourceUrl + "/getTotalNumberOfTasks/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getTotalNumberOfBoardTasks: function () {
                    var url = resourceUrl + "/getTotalNumberOfBoardTasks/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getListForCurrentUser: function () {
                    var url = resourceUrl + "/getmytasks";
                    var timeZoneOffset = {
                        offset: timeService.getTimeZoneOffset()
                    }
                    return $http.post(url, timeZoneOffset).then(function (result) {
                        return result.data;
                    });
                },

                getMyTasksByStatus: function (status, timePeriod) {
                    var url = resourceUrl + "/GetMyTasksByStatus/" + status;

                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getUserTasks: function (userId) {
                    var url = resourceUrl + "/getUserTasks/" + userId;
                    var timeZoneOffset = {
                        offset: timeService.getTimeZoneOffset()
                    }
                    return $http.post(url, timeZoneOffset).then(function (result) {
                        return result.data;
                    });
                },
                getSubTasks: function (parentId) {
                    var url = resourceUrl + "/getSubTasks/" + parentId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getSiblings: function (siblingId) {
                    var url = resourceUrl + "/getsiblings/" + siblingId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getBoardInfo: function (id) {
                    var url = resourceUrl + "/getboardinfo/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getComments: function (taskId) {
                    var url = resourceUrl + "/getComments/" + taskId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getCommentWithReplies: function (taskId) {
                    var url = resourceUrl + "/getCommentWithReplies/" + taskId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getAttachments: function (taskId) {
                    var url = resourceUrl + "/getAttachments/" + taskId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getBoardStatus: function (id, period) {
                    var url = webApiUrl + "/board/getboardstatus/" + id;
                    return $http.post(url, period).then(function (result) {
                        return result.data;
                    });
                },
                getNoA3TaskPerStatus: function (a3Id) {
                    var url = webApiUrl + "/task/getNoA3TaskPerStatus/" + a3Id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getTasksByA3: function (a3Id) {
                    var url = webApiUrl + "/task/getTasksByA3/" + a3Id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getNoProjectTaskPerStatus: function (projectId) {
                    var url = webApiUrl + "/task/getNoProjectTaskPerStatus/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getTasksByProject: function (projectId) {
                    var url = webApiUrl + "/task/getTasksByProject/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getProjectPortfolioTasksForPeriod: function (boardId, period) {
                    var url = webApiUrl + "/task/GetProjectPortfolioTasksForPeriod/" + boardId;
                    return $http.post(url, period).then(function (result) {
                        return result.data;
                    });
                },
                
                addTask: function (task) {
                    return $http.post(resourceUrl, task).then(function (result) {
                        return result.data;
                    });
                },

                addSubTask: function (parentId, task) {
                    var url = webApiUrl + "/task/addSubTask/" + parentId;
                    return $http.post(url, task).then(function (result) {
                        return result.data;
                    });
                },

                addBoardTask: function (task) {
                    var url = resourceUrl + "/addBoardTask";
                    return $http.post(url, task).then(function (result) {
                        return result.data;
                    });
                },

                updateTask: function (task) {
                    var url = resourceUrl + "/" + task.id;
                    return $http.put(url, task).then(function (result) {
                        return result.data;
                    });
                },

                updateProgressStatus: function (id, status) {
                    var url = resourceUrl + "/" + id + "/changeProgressStatus";
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },
                updatePriorityStatus: function (id, status) {
                    var url = resourceUrl + "/" + id + "/changePriorityStatus";
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },

                updateStatus: function (id, status) {
                    var url = resourceUrl + "/" + id + "/changeStatus";
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },

                updateTitle: function (id, title) {
                    var url = resourceUrl + "/" + id + "/updateTitle";
                    return $http.put(url, title).then(function (result) {
                        return result.data;
                    });
                },
                updateBoard: function (id, taskboard) {
                    var url = resourceUrl + "/" + id + "/updateBoard";
                    return $http.put(url, taskboard).then(function (result) {
                        return result.data;
                    });
                },
                clearSubTaskBoard: function (id, taskboard) {
                    var url = resourceUrl + "/" + id + "/clearSubTaskBoard";
                    return $http.put(url, taskboard).then(function (result) {
                        return result.data;
                    });
                },
                

                updateDate: function (id, date) {
                    var url = resourceUrl + "/" + id + "/updateDate";
                    var day = moment(date).isoWeekday();
                    console.log(day);
                    var dateOptions = {
                        date: date,
                        dayOfWeek: day
                    }
                    return $http.put(url, dateOptions).then(function (result) {
                        return result.data;
                    });
                },

                updateSortOrderForSubtasks: function(taskId, sortOrders) {
                    var url = resourceUrl + "/" + taskId + "/UpdateSortOrderForSubtasks";
                    return $http.put(url, sortOrders).then(function (result) {
                        return result.data;
                    });
                },

                updateactionItemstatus: function (id, status) {
                    var url = resourceUrl + "/" + id + "/updateactionItemstatus";
                    return $http.put(url, status).then(function (result) {
                        return result.data;
                    });
                },

                deleteTask: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.delete(url).then(function () {
                        return;
                    });
                },
                deleteFutureRepeatingTask: function (id) {
                    var url = resourceUrl + "/deletefuturerepeatingtask/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                deleteRepeatingTask: function (id) {
                    var url = resourceUrl + "/deleterepeatingtask/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                deleteClonedTask: function (id) {
                    var url = resourceUrl + "/deleteClonedTask/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                deleteAClonedTask: function (id) {
                    var url = resourceUrl + "/deleteAClonedTask/" + id;
                    return $http.delete(url).then(function () {
                        return;
                    });
                },


                moveTask: function (options) {
                    var url = resourceUrl + "/" + options.taskId + "/updateposition";
                    var day = moment(options.boardDate).isoWeekday();
                    var dateOptions = {
                        date: options.boardDate,
                        dayOfWeek: day
                    }
                    options.dateOptions = dateOptions;
                    return $http.put(url, options).then(function () {
                        return;
                    });
                },
                moveTaskToPersonalBoard: function (id) {
                    var url = resourceUrl + "/" + id + "/MoveTaskToPersonalBoard";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                moveActionItemToTask: function (options) {
                    options.timeZoneOffset = timeService.getTimeZoneOffset();
                    var url = resourceUrl + "/" + options.id + "/moveActionItemToTask";
                    return $http.put(url, options).then(function (result) {
                        return result.data;
                    });
                },
                moveTaskToActionItem: function (options) {
                    var url = resourceUrl + "/" + options.id + "/moveTaskToActionItem";
                    return $http.put(url, options).then(function (result) {
                        return result.data;
                    });
                },
                moveTaskToArchive: function (id) {
                    var url = resourceUrl + "/" + id + "/moveTaskToArchive";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                moveTaskFromArchive: function (id) {
                    var url = resourceUrl + "/" + id + "/moveTaskFromArchive";
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },
                updateActionListItem: function (options) {
                    var url = resourceUrl + "/" + options.id + "/updateActionListItem";
                    return $http.put(url, options).then(function (result) {
                        return result.data;
                    });
                },

                createActionListItem: function (item) {
                    var url = resourceUrl + "/" + item.id + "/createActionListItem";
                    return $http.put(url, item).then(function (result) {
                        return result.data;
                    });
                },

                assignUser: function (options) {
                    var url = resourceUrl + "/" + options.taskId + "/assignuser";
                    return $http.put(url, options.user).then(function () {
                        return;
                    });
                },

                updateDuration: function (id, taskDuration) {
                    var url = resourceUrl + "/" + id + "/updateduration";
                    return $http.put(url, taskDuration).then(function () {
                        return;
                    });
                },
                updateStartDate: function (id, startDate) {
                    var url = resourceUrl + "/" + id + "/updateStartDate";
                    var taskStartDate = {
                        taskId: id,
                        startTime: startDate
                    }
                    return $http.put(url, taskStartDate).then(function () {
                        return;
                    });
                },
                updateRowPosition: function (rowPosition) {
                    var url = resourceUrl + "/" + rowPosition.taskId + "/updateRowPosition";
                    return $http.put(url, rowPosition).then(function () {
                        return;
                    });
                },

                cloneTask: function (taskId, options) {
                    var url = resourceUrl + "/" + taskId + "/clonetask";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                copyTask: function (taskId) {
                    var url = resourceUrl + "/" + taskId + "/copyTask";
                    return $http.post(url).then(function (result) {
                        return result.data;
                    });
                },

                repeatTask: function (taskId, repeatOption) {
                    var url = resourceUrl + "/" + taskId + "/repeatTask";
                    return $http.post(url, repeatOption).then(function (result) {
                        return result.data;
                    });
                },

                addComment: function (options) {
                    var url = resourceUrl + "/" + options.boardTaskId + "/addcomment";
                    return $http.post(url, options).then(function (result) {
                        return result.data; // comment object is returned
                    });
                },
                deleteComment: function (taskId, commentId) {
                    var url = resourceUrl + "/" + taskId + "/deleteComment/" + commentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                addAttachment: function (taskId, fileInfo) {
                    var url = resourceUrl + "/" + taskId + "/addAttachment";
                    return $http.post(url, fileInfo).then(function (result) {
                        return result.data;
                    });
                },

                deleteAttachment: function (taskId, attachmentId) {
                    var url = resourceUrl + "/" + taskId + "/deleteAttachment/" + attachmentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

            }
        }
        return boardTaskService;
    }]);