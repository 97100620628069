import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("weeklyBoardCard", 
        function () {
            return {
                templateUrl: 'weeklyBoardCard.html',
                restrict: 'E',
                scope: {
                    "board": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.model = null;
                    scope.isLoading = true;
                    scope.$watch('board', function (board) {
                        if (!board) return;
                        
                        scope.isLoading = false;
                        if (board.boardType == "Kanban") {
                            scope.previewUrl = staticFileService.previewImage("kanbanBoard.jpg");
                        }
                        else {
                            scope.previewUrl = staticFileService.previewImage("weeklyboard.jpg");
                        }
                        

                    });
                }
            }
        });
