import * as monitoringService from "@common/services/data/monitoringService"
import moment from "moment"
import toastr from "toastr"
import { userFullProfile } from "@common/stores/userStore"
import angular from "angular"
import { ConnectorMonitorList, Heartbeat, LogMessage, LogMessageType, MonitorState } from "@api"
import type { Subscription } from "rxjs"
import dialog from "@common/components/digilean/DigileanDialog"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')
DigiLean.component('integrationsMonitor', {
    templateUrl: "integrationsMonitor.html",
    bindings: {
        'showAll': '<',
    },
    controller: ['$scope',
        function(this: NgComponent, $scope) {
            let $ctrl = this

            $ctrl.loading = false
            let sub: Subscription | null = null

            $ctrl.company = { name: "" }
            $ctrl.$onInit = function() {
                sub = userFullProfile.subscribe(ufp => {
                    if (ufp && ufp.company)
                        $ctrl.company = ufp.company
                })
                loaddata()
            }
            $ctrl.$onDestroy = function() {
                sub?.unsubscribe()
                if (intervalId)
                    clearInterval(intervalId)
            }
            $ctrl.connectors = []

            $ctrl.isValidDate = function (date) {
                var result = moment(date)
                return result.isValid
            }

            async function loaddata() {
                console.log("load all monitor overview data")
                $ctrl.loading = true
                let list: ConnectorMonitorList[] = []

                try {
                    if ($ctrl.showAll)
                        list = await monitoringService.getConnectorsAll()
                    else 
                        list = await monitoringService.getConnectorsCompany()

                    $ctrl.connectors = list
                }
                catch (error) {
                    console.error(error)
                    toastr.error("Something went wrong when loading monitor data")
                }
                finally {
                    $ctrl.loading = false
                }
            }
            $ctrl.reload = loaddata

            /// remember to clear interval
            let intervalId = setInterval(function () {
                $ctrl.connectors.forEach((connector: ConnectorMonitorList) => {
                    const now = moment()
                    const last = moment(connector.lastHeartbeat)
                    const diffS = now.diff(last, "seconds")
                    if (diffS > 5) {
                        connector.heartbeatActive = false
                    }
                })
            }, 5000)

            // events //
            $scope.subscribe("NewLogMessage", function (log: LogMessage) {
                if (log.type == LogMessageType.Error) {
                    console.log("NewLogMessage log.type,Error, set lastday to error", log)
                    if (log.connectorId) {
                        const connector = $ctrl.connectors.find(c => c.id) as ConnectorMonitorList
                        if (connector) {
                            connector.stateLastDay = MonitorState.RecentError
                        }
                    }
                }
            })
            $scope.subscribe("HeartbeatReceived", (heartbeat: Heartbeat) => {
                $ctrl.connectors.forEach((connector: ConnectorMonitorList) => {
                    if (connector.id == heartbeat.id) {
                        connector.heartbeatActive = true
                        connector.lastHeartbeat = new Date().toISOString()
                        connector.timeRunning = heartbeat.timeRunning
                    }
                })
            })
            $scope.subscribe("ConnectorCreated", function (connector) {
                toastr.info("New Connector created: " + connector.name)
            })
            $scope.subscribe("ConnectorUpdated", function (connector) {
                toastr.info("Connector updated: " + connector.name)
            })
            $scope.subscribe("ConnectorDeleted", function (connector) {
                toastr.info("Connector deleted: " + connector.name)
            })

            function openLastErrorLog (connectorId: string, days: number) {
                let el = document.createElement("monitor-job-log")
                el.connectorid = connectorId
                el.setAttribute("connectorid", connectorId)
                el.days = days
                
                dialog.openElement({
                    title: `Last ${days} days errors`,
                    hideOkBtn: true,
                }, el)
            }
            $scope.openLastErrorLog = openLastErrorLog
        }
    ]
})