/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomerSettingKeys {
    Language = 'language',
    Currency = 'currency',
    Modules = 'modules',
    DateFormat = 'dateFormat',
    Fiscalmonth = 'fiscalmonth',
}
