angular
    .module('DigiLean')
    .controller("cellCloneSelectorController", ['$scope', '$state', '$uibModalInstance', '$filter', 'smartcellService', 'celltype',
        function ($scope, $state, $uibModalInstance, $filter, smartcellService, celltype) {

            $scope.visualizationType = null;
            $scope.celltype = celltype;
            $scope.types = [];

            function getAvailableCellIdentificators(){
                if($scope.celltype == 'textfromapp'){
                    smartcellService().getClonedCellIdentificators("text").then(function (result) {
                        $scope.types = result;
                    });
                }
            }
            getAvailableCellIdentificators();
            
            $scope.changeVisualizationType = function (type) {
                $scope.visualizationType = type;
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.visualizationType);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
