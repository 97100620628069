import { AssetBusinessTree } from "@api"

export function reloadWithCollapsedState(updatedAssetTree: AssetBusinessTree[], currentAssetTree: AssetBusinessTree[]) {
    const updatedCollapsed = updatedAssetTree.map(at => {
        findAndMapCollapsed(at, currentAssetTree)
        if (at.children && at.children.length > 0) {
            for (const child of at.children)
                findAndMapCollapsed(child, currentAssetTree)
        }
        return at
    })
    return updatedCollapsed
}

function findAndMapCollapsed(at: AssetBusinessTree, currentAssetTree: AssetBusinessTree[]) {
    if (!at.item)
        return at
    const id = at.item.id!
    const assetCurrent = findCurrentAssetTreeById(id, currentAssetTree)
    if (assetCurrent)
        at.isCollapsed = assetCurrent.isCollapsed
    return at
}

function findCurrentAssetTreeById(id: number, tree: AssetBusinessTree[]) {
    for (const at of tree) {
        if (at.item?.id == id)
            return at
        if (at.children && at.children.length > 0) {
            const foundInChildren = findCurrentAssetTreeById(id, at.children)
            if (foundInChildren)
                return foundInChildren
        }
    }
}