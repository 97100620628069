import cronstrue from "cronstrue"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('quartz-cron-displayer')
export class DeviceInfo extends LitElement {
    
    static styles = css`
        :host {
		    display: inline;
	    }
    `
    
    options = {
        use24HourTimeFormat: true,
        verbose: true
    }

    @property({attribute: true})
    cronexp: string = ""

    render() {
        if (!this.cronexp)
            return html`<span>Empty cron expression</span>`

        this.title = this.cronexp

        const humanReadable = cronstrue.toString(this.cronexp, this.options)
        
        return html`
            <span>${humanReadable}</span>
        `
    }
}
