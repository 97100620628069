angular
    .module('DigiLean').directive("summarySection", ['$filter', '$translate', 'learningService', 'debounce',
        function ($filter, $translate, learningService, debounce) {
            return {
                templateUrl: 'summarySection.html',
                restrict: 'E',
                scope: {
                    'section': '<',
                    'isEditMode': '<',
                },
                link: function (scope) {
                    scope.update = function (content) {
                        scope.section.content = content;
                        learningService().updateSection(scope.section);
                    };
                    scope.commitChangesDebounced = debounce(scope.update, 2000, false);
                    scope.contentChanged = function(val) {
                        scope.commitChangesDebounced(val);
                    };
                    scope.froalaOptions = {
                        toolbarButtons: ["bold", "italic", "underline", 
                            "|", "fontSize", 
                            "|", "align", "formatOL", "formatUL"]
                    };
                    scope.$watch("section", function (section) {
                        if (section) {
                            scope.text = scope.section.content;
                        }
                    });
                    scope.$watch("isEditMode", function (isEditMode) {
                        scope.text = scope.section.content;
                    });
                }
            };
        }]);
