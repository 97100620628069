
angular.module("DigiLean")
    .directive('threeCStatusCell', ['$filter',
        function ($filter) {
            return {
                templateUrl: "threeCStatusCell.html",
                restrict: "E",
                scope: {
                    'status': '<',
                    'statusChangedHandler': '&',
                },
                link: function (scope, elem, attrs) {

                    scope.$watch('status', function (status) {
                        if (!status) return;
                        setStatus(status);
                    });

                    var setStatus = function(status) {
                        var status = $filter('filter')(scope.PDCAoptions, { status: status }, true);
                        scope.selectedStatus = status[0];
                        scope.cssClass = "animated flash";
                        setTimeout(() => {
                            scope.cssClass = "";
                        },1000);
                    }
  
                    scope.cssClass = "";
                    scope.statusChanged = function (status) {
                        setStatus(status);
                       
                        scope.statusChangedHandler({ status: status });
                    }
                    scope.PDCAoptions = [
                        {
                            status: "0",
                            label: "Not started",
                            pieChart: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 15,
                                    fill: ["#f3f3f4", "#f3f3f4", "#f3f3f4", "#f3f3f4"],
                                }
                            }
                        },
                        {
                            status: "25",
                            label: "Owner Assigned",
                            pieChart: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 15,
                                    fill: ["#0077B5", "#f3f3f4", "#f3f3f4", "#f3f3f4"],
                                }
                            }
                        },
                        {
                            status: "50",
                            label: "Action started",
                            pieChart: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 15,
                                    fill: ["#0077B5", "#0077B5", "#f3f3f4", "#f3f3f4"],
                                }
                            }
                        },
                        {
                            status: "75",
                            label: "Action complete",
                            pieChart: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 15,
                                    fill: ["#0077B5", "#0077B5", "#0077B5", "#f3f3f4"],
                                }
                            }
                        },
                        {
                            status: "100",
                            label: "Result Achieved",
                            pieChart: {
                                data: [1, 1, 1, 1],
                                options: {
                                    radius: 15,
                                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"],
                                }
                            }
                        }
                    ];
                }
            }
        }]);