angular
.module('DigiLean')
.directive("prioritystatusSelector", ['$filter', 'suggestionService', 
    function ($filter, suggestionService) {
        return {
            templateUrl: 'prioritystatusselector.html',
            restrict: 'E',
            scope: {
                'filters': '=',
                'source': '=',
                'filterChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {
                scope.includeAll = true;
                scope.priorities = [];

                function getPriorityList() {
                    scope.priorities = [
                        {
                            label: suggestionService().getPriorityStatusText(1),
                            priorityStatus: 1
                        },
                        {
                            label: suggestionService().getPriorityStatusText(2),
                            priorityStatus: 2
                        },
                        {
                            label: suggestionService().getPriorityStatusText(3),
                            priorityStatus: 3
                        },
                        {
                            label: suggestionService().getPriorityStatusText(4),
                            priorityStatus: 4
                        }
                    ];
                    applyFilter();
                }

                scope.$watch('source', function () {
                    getPriorityList();
                });


                function applyFilter(){
                    if (scope.filters && scope.priorities) {

                        var identifiers = [];
                        var selectedFilter = scope.filters[0];
                        if (selectedFilter && selectedFilter.items.length > 0) {

                            selectedFilter.items.forEach(item => {
                                identifiers.push(parseInt(item));
                            });
                            selectedFilter.items = identifiers;
                        } 
                        for (var k = 0; k < scope.priorities.length; k++) {
                            var index = selectedFilter.items.indexOf(scope.priorities[k].priorityStatus);
                            if (index > -1){
                                scope.priorities[k].include = true;
                            } else {
                                scope.priorities[k].include = false;
                            }                     
                        }

                        var selected = $filter('filter')(scope.priorities, { include: true });
                        if (selected.length === scope.priorities.length && selected.length === 0) {
                            scope.includeAll = true;
                        } else {
                            scope.includeAll = false;
                        }
                    } else if (scope.priorities) {
                        for (var i = 0; i < scope.priorities.length; i++) {
                            scope.priorities[i].include = true;                     
                        }
                    }
                }

                scope.deselectAll = function (include) {
                    for (var k = 0; k < scope.priorities.length; k++) {
                        scope.priorities[k].include = include;                     
                    }
                    scope.modifyFilter();
                };

                scope.modifyFilter = function(){
                    var selected = $filter('filter')(scope.priorities, { include: true });
                    if(selected.length === scope.priorities.length){
                        scope.includeAll = true;
                        scope.filters = null;
                    } else {
                        scope.includeAll = false;
                        var selectedItems = [];
                        for(var i=0; i < selected.length; i++){
                             selectedItems.push(selected[i].priorityStatus);
                        }
                        scope.filters = [{
                            sourceColumn: "priorityStatus",
                            operator: "InList",
                            items: selectedItems
                        }];
                    }
                    scope.filterChangeHandler({ updatedSourceColumn: "priorityStatus", filter: scope.filters });
                };

            }
        }
    }]);
