﻿import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('boardActionController', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'modalService', 'boardTaskService', 'boardId', 'activityService', 'task', 'activeTab',
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, modalService, boardTaskService, boardId, activityService, task, activeTab) {

        $scope.boards = {};
        $scope.selectedEmployee = null;
        $scope.selectedBoard = {};
        $scope.task = task;
        var taskCurrentBoardId = task.boardId;
        

        // Tabs
        var tabs = ["Info", "Comments", "History"];
        $scope.indexTab = tabs.indexOf(activeTab);
        $scope.selectTab = function (tab) {
            $scope.indexTab = tabs.indexOf(tab);
        }
        // Make sure boardDate is date object 
        task.boardDate = moment.utc(task.boardDate).toDate();


        $scope.blankuser = { id: null, fullName: "", userName: "", displayName: "" }

        var cancelTranslation = $translate.instant('COMMON_CANCEL');
        console.log(cancelTranslation);
        $rootScope.$on('$translateChangeSuccess', function () {
            console.log("boardactioncontroller translated event recieved");
            cancelTranslation = $translate.instant('COMMON_CANCEL');
        });

        $scope.canEdit = false;
        // Chceck if user is allowed to edit task
        boardTaskService().canEdit(task.id).then(function (canEdit) {
            $scope.canEdit = canEdit;
        });

        boardService.getUserListForBoard(boardId).then(function (data) {
            angular.forEach(data, function (employee, key) {
                employee.fullName = employee.firstName + " " + employee.lastName;
            });
            $scope.employees = data;

            $scope.employees.unshift($scope.blankuser);
            $scope.selectedEmployee = $filter('filter')(data, { id: task.responsibleUserId }, true)[0];
            if (!$scope.selectedEmployee) {
                $scope.selectedEmployee = $scope.blankuser;
            }
        });

        boardService.getMemberBoards().then(function (data) {
            $scope.boards = data;
            $scope.selectedBoard = $filter('filter')(data, { id: boardId }, true)[0];
        });



        // Init tags and focus
        setTimeout(function () {

            $("#taskTags").tagit({
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
            if ($scope.task.tags) {

                if (typeof stringValue === "string") {
                    var tagArray = $scope.task.tags.split(",");
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i]);
                    }
                } else {
                    var tagArray = $scope.task.tags;
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i].tag);
                    }
                }
            }
        }, 50);

        var getTags = function () {

            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: tagsArray[i].id,
                    Tag: tagsArray[i],
                    BoardTaskId: $scope.task.id
                });
            }
            return tags;
        }


        // History
        var getActivityLog = function () {
            var activityRequest = {
                activity: "BoardTask",
                activityId: $scope.task.id
            };
            var $translate = $filter('translate');
            var translateOptions = {
                month: $translate('COMMON_TIMEELAPSED_MONTH'),
                days: $translate('COMMON_TIMEELAPSED_DAYS'),
                hours: $translate('COMMON_TIMEELAPSED_HOURS'),
                mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
                seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
                now: $translate('COMMON_TIMEELAPSED_NOW')
            }
            activityService().getLogForActivity(activityRequest).then(function (logItems) {
                logItems.forEach(function (activity) {
                    activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                    if (activity.payLoad && typeof activity.payLoad === "string") {
                        activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                    }
                });
                $scope.activityLog = logItems;

            });
        }
        getActivityLog();

        // Get Comments
        var getComments = function () {
            boardTaskService().getComments($scope.task.id).then(function (comments) {
                $scope.comments = comments;
            });
        }
        getComments();

        $scope.activeComment = "";
        $scope.hasAddedComments = false;
        $scope.activeCommentChanged = function (value) {
            $scope.hasAddedComments = true;
            $scope.activeComment = value;
        }

        // IF date not set, than suggest a week from now
        if (!task.boardDate) {
            var utcDate = timeService.getUTCDate();
            task.boardDate = utcDate.add(7, 'days').toDate();
        }



        $scope.boardChanged = function (board) {
            $scope.task.boardId = board.id;
        }

        $scope.responsibleChanged = function (user) {
            $scope.task.responsibleUserId = user.id;
            $scope.task.responsibleUser = user.userName;
            $scope.task.responsibleDisplayName = user.displayName;
        }

        $scope.archive = function () {
            boardTaskService().moveTaskToArchive($scope.task.id).then(function (result) {
                $uibModalInstance.dismiss('cancel');
            });
        }
        
         

        $scope.delete = function () {
            var taskId = $scope.task.id;
            var repeatParentId = $scope.task.repeatParentId;
            var siblingId = $scope.task.siblingId;

            if (!repeatParentId && !siblingId) {
                boardTaskService().deleteTask(taskId).then(function () {
                    $uibModalInstance.dismiss('cancel');
                });
            }

            if (repeatParentId) {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'repeatdelete.html',
                    controller: 'repeatdeleteController',
                    windowClass: 'newdeviation-modal-window',
                    resolve: {
                        taskId: function () {
                            return taskId;
                        },
                        repeatParentId: function () {
                            return repeatParentId;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $uibModalInstance.close($scope.task);
                });
            }

            if (siblingId) {

                $translate(['COMMON_CANCEL', 'BOARD_DELETE_TASKS', 'BOARD_DELETE_CLONED_TASK', 'BOARD_DELETE_CLONED_TASK_DESCRIPTION']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.BOARD_DELETE_TASKS,
                        headerText: translations.BOARD_DELETE_CLONED_TASK,
                        bodyText: translations.BOARD_DELETE_CLONED_TASK_DESCRIPTION
                    };

                    modalService.showModal({}, modalOptions).then(function (result) {
                        boardTaskService().deleteClonedTask(siblingId).then(function (result) {
                            $scope.publish("SendTasksDeleted", result);
                            $uibModalInstance.dismiss('cancel');
                        });
                    });
                });
            }
        }

        $scope.ok = function () {
            // Create action and set as result
            $scope.isProcessing = true;
            $scope.task.tags = getTags();
            if ($scope.hasAddedComments) {
                var comment = {
                    boardTaskId: $scope.task.id,
                    comment: $scope.activeComment
                };
                boardTaskService().addComment(comment).then(function (savedComment) {
                    // Comment was added
                    $scope.comments.push(savedComment);
                });
            }
            boardTaskService().updateActionListItem($scope.task).then(function (result) {
                $scope.isProcessing = false;
                angular.forEach(result.affectedTaskIds, function (id, key) {
                    var clone = $.extend(true, {}, result.task);
                    clone.id = id;
                });
                // check if task should be moved to another board action list
                if ($scope.task.boardId !== taskCurrentBoardId) {
                    var change = {
                        taskId: $scope.task.id,
                        fromBoardId: $scope.task.boardId,
                        toBoardId: $scope.selectedBoard.id
                    };
                    $scope.publish("SendTaskChangedBoard", change);
                }
                $uibModalInstance.close($scope.task);
            });


        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);