﻿angular.module("DigiLean").factory('folderService', ['$http', 'webApiUrl',
function ($http, webApiUrl) {
    function folderService() {
        var resource = "/folder";
        var resourceUrl = webApiUrl + resource;
        return {
            getList: function() {   
                return $http.get(resourceUrl).then(function (result) {
                    return result.data;
                });
            },

            getFlexiBoards: function() {   
                var url = resourceUrl + "/GetFlexiBoards";
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
            getBoardsInfo: function() {   // id, name, boardType
                var url = resourceUrl + "/GetBoardsInfo";
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
            
            getFlexiBoardsForAdmin: function() {   
                var url = resourceUrl + "/GetFlexiBoardsForAdmin";
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },

            


            add: function (folder) {
                return $http.post(resourceUrl, folder).then(function (result) {
                    return result.data;
                });
            },
            update: function (folder) {
                return $http.put(resourceUrl, folder).then(function (result) {
                    return result.data;
                });
            },
            delete: function (id) {
                var url = resourceUrl + "/DeleteFolder/" + id;
                return $http.delete(url).then(function (result) {
                    return result.data;
                });
            }
        }
    }
    return folderService;
}]);