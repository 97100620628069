angular
    .module('DigiLean')
    .directive("smartactionlistNumberCell", ['$filter', 'debounce', 'projectService','mathService',
        function ($filter, debounce, projectService, mathService) {
            return {
                templateUrl: 'smartactionlistNumberCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.number = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    var settings = null;
                    scope.$watch('model', function (model) {
                        var value = model.cell.value;
                         
                        settings = model.column.settings;
                        if (settings) {
                            if(settings.unit){
                                scope.unit = settings.unit;
                                if(settings.unitPlacement){
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                            if(settings.decimals !== null || settings.decimals !== undefined){
                                scope.decimals = settings.decimals;
                            }
                        }
                        if (value) {
                            setValue(value);
                        }
                        model.getSortValue = function() {
                            return scope.number;
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            //document.querySelector( "input" ).focus();
                            $(".project-text-cell").focus();
                        }, 50 );
                    }
                    
                    var setValue = function(cell) {
                        scope.background = cell.background;
                        scope.number = cell.number;

                        scope.updateAggregateCell();
                        if(scope.decimals !== null) {
                            if(scope.decimals !== undefined) {
                                scope.number = mathService().roundNumber(scope.number, scope.decimals);
                            }
                        } 
                        scope.color = cell.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = cell.background;
                        }
                    }
                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        if (cell.boardTaskId == scope.model.cell.boardTaskId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        }
                    });
                    scope.subscribe("SmartActionListNumberCellDecimalUpdated", function (value) {
                        if(scope.model.cell.smartTableColumnId == value.smartActionListColumnId){
                            scope.decimals = value.decimals;
                            if(scope.decimals !== null) {
                                if(scope.decimals !== undefined) {
                                    scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                }
                            }
                        }
                    });
                    scope.subscribe("SmartActionListNumberCellUnitUpdated", function (value) {
                        if(scope.model.cell.smartTableColumnId === value.columnId){
                            scope.unit = value.unit;
                        }
                    });

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.numberChanged = function (number) {
                        if(scope.number === number) return;
                        scope.number = number;
                        scope.updateAggregateCell();
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            number: scope.number
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    }

                    scope.sendEvent = function(){
                        var payload =  {
                            smartActionListColumnId: scope.model.column.id,
                            taskId: scope.model.task.id,
                            value: scope.number
                        }  
                        scope.publish("SmartActionListNumberCellUpdated", payload);
                    }

                    scope.updateAggregateCell = debounce(scope.sendEvent, 1000, false);

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
