// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('newYearlyTaskController', ['$scope', '$filter', '$uibModalInstance', 'boardTaskService', 'task', 'options', 'users', 'taskDrawingService',
    function ($scope, $filter, $uibModalInstance, boardTaskService, task, options, users, taskDrawingService) {
        $scope.task = task;
        $scope.users = users;
        $scope.uploadedFiles = [];

        $scope.isProcessing = false;
        $scope.showDueDate = false;
        $scope.postIts = taskDrawingService().getPostIts();

        $scope.enddateBeforeStartdate = false;
        

        // colors
        var borderStyle = "6px solid";
        $scope.taskBorderStyle = {
            "border-bottom": borderStyle + "#ffffff"
        }
        $scope.background = "#ffffff";
        $scope.fillColor = "#ffffff";
        $scope.customColorBackground = "#a9a9a9";

        $scope.selectColor = function (postIt) {
            $scope.selectedPostIt = postIt;
            $scope.background = postIt.background;
            $scope.fillColor = postIt.fillColor;
            $scope.taskBorderStyle = {
                "border-bottom": borderStyle + $scope.background
            }
            if (postIt.tags) {
                setTimeout(function () {
                    $("#taskTags").tagit({
                        // This will make Tag-it submit a single form value, as a comma-delimited field.
                        singleField: true,
                        singleFieldNode: $('#tagsSelected')
                    });
                    var tagArray = postIt.tags;
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i]);
                    }
                }, 10);
            }
        }

        if (options) {

            // Set color
            if (options.postIts) {
                $scope.postIts = options.postIts;
            } else {
                $scope.postIts = taskDrawingService().getPostIts();
            }

            if (options.color) {
                task.color = options.color;
                $scope.background = task.color;
                $scope.taskBorderStyle = {
                    "border-bottom": borderStyle + $scope.background
                }
                var predefinedPostIt = $filter('filter')($scope.postIts, { background: $scope.background });
                if (predefinedPostIt.length > 0) {
                    $scope.selectColor(predefinedPostIt[0]);
                } else //Custom color 
                {
                    $scope.selectColor({ title: "custom", background: $scope.customColorBackground });
                    $scope.customColorBackground = task.color;
                }

            }

            if (options.attribute) {
                // Set responsilbe
                if (options.attribute.type == "responsible" && options.attribute.value) {
                    // Set responsible
                    var user = options.attribute.value;
                    $scope.task.responsibleUserId = user.userId;
                    $scope.task.responsibleUser = user.userName;
                    $scope.task.responsibleDisplayName = user.userDisplayName;

                }
                // Set textcell attribute
                if (options.attribute.type == "text" && options.attribute.value) {
                    // Set cell / attribute
                    $scope.task.cells = [];
                    $scope.task.cells.push(options.attribute.value);
                }
            }
        };

        //$scope.postIts = taskDrawingService().getPostIts()


        /*
                if (task.color) {
                    $scope.background = task.color;
                    $scope.taskBorderStyle = {
                        "border-bottom": borderStyle + $scope.background
                    }
                    var predefinedPostIt = $filter('filter')($scope.postIts, { background: $scope.background });
                    if (predefinedPostIt.length > 0) {
                        $scope.selectColor(predefinedPostIt[0]);
                    } else //Custom color 
                    {
                        $scope.selectColor({ title: "custom", background: $scope.customColorBackground });
                        $scope.customColorBackground = task.color;
                    }
                }*/

        
        // Init tags and focus (without this the textfield is 2px)
        setTimeout(function () {
            $("#taskTags").tagit({
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
            var tagArray = $scope.task.tags.split(",");
            for (var i = 0; i < tagArray.length; i++) {
                $("#taskTags").tagit('createTag', tagArray[i]);
            }
            // Description as focus
            //$('#taskDescriptionId').focus();
        }, 50);

        /*
        var setProfileImage = function () {
            if (!$scope.task || !$scope.task.responsibleUserId || !$scope.users) return;
        
            for (var i = 0; i < $scope.users.length; i++) {
                var user = $scope.users[i];
                if (user.profileImageUrl && user.id == $scope.task.responsibleUserId) {
                    scope.task.responsibleImageUrl = user.profileImageUrl;
                }
            }
        }*/

        $scope.emptyUser = {
            id: '',
            userName: null,
            profileImageUrl: null,
            fullName: null,
            displayName: null
        }
        $scope.selectUser = function (user) {

            $scope.task.responsibleImageUrl = user.profileImageUrl;
            $scope.task.responsibleUserId = user.id;
            $scope.task.responsibleUser = user.fullName;
            $scope.task.responsibleDisplayName = user.displayName;
            $scope.task.user = user;
        }

        $scope.startTimeUpdated = function ($event) {
            const date = $event.originalEvent.detail
            $scope.task.startTime = date
            if ($scope.task.startTime) {
                // Make sure boardDate is date object 
                $scope.task.startTime = moment($scope.task.startTime).toDate();
                checkDate();
            }
        }
        $scope.endTimeUpdated = function ($event) {
            const date = $event.originalEvent.detail
            $scope.task.endTime = date
            if ($scope.task.endTime) {
                // Make sure boardDate is date object 
                $scope.task.endTime = moment($scope.task.endTime).toDate();
                checkDate();
            }
        }

        function checkDate(){
            $scope.enddateBeforeStartdate = false;
            if(moment($scope.task.endTime).isBefore($scope.task.startTime)){
                $scope.enddateBeforeStartdate = true;
            }
        }
        $scope.ok = function () {
            $scope.isProcessing = true;
            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: 0,
                    Tag: tagsArray[i],
                    BoardTaskId: 0
                });
            }
            $scope.task.boardDate = $scope.task.endTime;
            $scope.task.tags = tags;
            $scope.task.color = $scope.fillColor;
            $scope.task.files = $scope.uploadedFiles;
            boardTaskService().addBoardTask($scope.task).then(function (createdTask) {
                $scope.isProcessing = false;
                $uibModalInstance.close(createdTask);
            });

        };


        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);