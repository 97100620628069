import angular from "angular"
const DigiLean = angular.module("DigiLean")
import { cloneDeep, debounce } from "lodash"
import type { LearningItemSection } from "@api"
import { NgComponent } from "@common/model/angularModel"
DigiLean.component("diagramSection", 
{
    bindings: {
        'section': '<',
        'isEditMode': '<'
    },
    templateUrl: "diagramSection.html",
    controller: ['$element', 'learningService', 
    function(this: NgComponent, $element, learningService) {
        const $ctrl = this
        $ctrl.title = "Diagram"
        $ctrl.description = ""
        $ctrl.svg = ""
    
        const getCurrentSvgFromSettings = () => {
            const svg = $ctrl.section.content
            $ctrl.svg = svg
            $ctrl.description = $ctrl.section.title
        }

        $ctrl.$onInit = function() {
            if ($ctrl.section) {
                if ($ctrl.section.content) {
                    getCurrentSvgFromSettings()
                }
            }
        }

        $ctrl.svgChanged = function(event) {
            const orgEvt = event.originalEvent
            const svg = orgEvt.detail
            $ctrl.svg = svg
            update()
            //saveSettings()
        }
        

        function update() {
            const section = cloneDeep($ctrl.section) as LearningItemSection
            section.content = $ctrl.svg
            section.title = $ctrl.description
            learningService().updateSection(section)
        }
        
        const commitChangesDebounced = debounce(update, 2000)

        let debouncer
        $ctrl.updateDescription = function() {
            debouncer = commitChangesDebounced()
        }

        function openEditor() {
            let diagramEl = $element[0].querySelector("digilean-diagram")
            if (diagramEl)
                diagramEl.triggerEdit()
        }
        $ctrl.$onChanges = function (changes) {
            if (changes.isEditMode) {
                if (changes.isEditMode.currentValue && !changes.isEditMode.previousValue) {
                    // openEditor()
                }
                if (!changes.isEditMode.currentValue && changes.isEditMode.previousValue) {
                    commitChangesDebounced.flush()
                }
            }
        }
        $ctrl.openEditor = openEditor

        // scope.commitChangesDebounced = debounce(scope.update, 2000, false);
        // scope.contentChanged = function(val) {
        //     scope.commitChangesDebounced(val);
        // };
    }]
})
