﻿angular
    .module('DigiLean')
    .controller("deviationStatusController", ['$scope', '$http', '$filter', '$translate', '$uibModal', 'deviationService',
        function ($scope, $http, $filter, $translate, $uibModal, deviationService) {

            $scope.options = {
                timePeriod: {
                    timeframe: "year",
                    useDefaultTimeframes: true,
                    timeframes: []
                }
            };

        }]);
