﻿angular.module("DigiLean").factory('employeeService', ['$rootScope', '$http', 'webApiUrl', '$filter',
    function ($rootScope, $http, webApiUrl, $filter) {
        function employeeService() {
            var resource = "/user";
            var resourceUrl = webApiUrl + resource;
            return {

                hasModule: function (modules, moduleName) {
                    var hasAccess = $filter('filter')(modules, { name: moduleName });
                    return hasAccess.length > 0;
                },

                getList: function () {
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },
                changeLanguage: function (language) {
                    var url = resourceUrl + "/updatePreferredLanguage?language=" + language;
                    return $http.put(url).then(function (result) {
                        return result.data;
                    });
                },

                getMembership: function (assetId) {
                    var url = resourceUrl + "/getMemberShip/" + assetId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getUserSettings: function () {
                    var url = resourceUrl + "/getUserSettings";
                    return $http.get(url).then(function (result) {
                        if (result.data && typeof result.data === "string") {
                            result.data = JSON.parse(result.data);
                        }
                        return result.data;
                    });
                },

                updateUserSettings: function (settings) {
                    var url = resourceUrl + "/updateSettings"
                    if (!settings)
                        return
                    
                    if (settings && typeof settings !== "string") {
                        settings = JSON.stringify(settings);
                    }

                    return $http.put(url, { settings: settings }).then(function (result) {
                        return result.data;
                    });
                },

                updateUserPreference: function (key, value) {
                    var url = resourceUrl + "/preference";
                    if (value && typeof value !== "string") {
                        if(key == 'FlexiboardFavoriteBoards'){ // prevent duplicate boards
                            var uniqueBoards = [];
                            angular.forEach(value, function(item) {                      
                                if(uniqueBoards.indexOf(item) === -1) {
                                    uniqueBoards.push(item);
                                }
                            });
                            value = uniqueBoards;
                        }
                        value = JSON.stringify(value);
                    }

                    var preference = {
                        key: key,
                        value: value
                    }
                    return $http.put(url, preference).then(function (result) {
                        return result.data;
                    });
                },

                deleteUserPreference: function (key) {
                    var url = resourceUrl + "/preference/" + key;
                    return $http.delete(url, key).then(function (result) {
                        return result.data;
                    });
                },

                getUserPreference: function (key) {
                    var url = resourceUrl + "/preference/" + key;
                    return $http.get(url).then(function (result) {
                        if (result.data && result.data.value && typeof result.data.value === "string") {
                            result.data.value = JSON.parse(result.data.value);
                        }
                        return result.data;
                    });
                },
            }
        }
        return employeeService;
    }]);