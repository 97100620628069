angular.module("DigiLean").filter('deviationSeverityTranslation', ['$translate', function ($translate) {
    return function(severity) {
        switch (severity) {          
                case 1:
                    return $translate.instant('COMMON_LOW');
                case 2:
                    return $translate.instant('COMMON_MEDIUM');
                case 3:
                    return $translate.instant('COMMON_HIGH');
                default:
                    return $translate.instant('COMMON_NOT_SPECIFIED');
                    
            }
    };
}]);