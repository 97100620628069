angular
    .module('DigiLean').directive("textSection", ['$filter', '$translate', 'learningService','debounce',
        function ($filter, $translate, learningService, debounce) {
            return {
                templateUrl: 'textSection.html',
                restrict: 'E',
                scope: {
                    'section': '<',
                    'isEditMode': '<',
                },
                link: function (scope) {
                    scope.update = function (content) {
                        scope.section.content = content;
                        learningService().updateSection(scope.section);
                    };

                    scope.commitChangesDebounced = debounce(scope.update, 2000, false);
                    scope.froalaOptions = {
                        
                        toolbarButtons: ['paragraphFormat','bold', 'italic', 'underline', 'strikeThrough', 
                            '|', 'fontSize', 'color', 'inlineClass', 'inlineStyle', 'paragraphStyle', 'lineHeight', 
                            '|', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', 'insertLink', 'insertTable', 
                            '|', 'specialCharacters', 'insertHR', '|', 'undo', 'redo'],
                    };
                    // hook up to froala-editor
                    scope.contentChanged = function(val) {
                        scope.commitChangesDebounced(val);
                    };

                    scope.$watch("section", function (section) {
                        if (section) {
                            scope.text = scope.section.content;
                        }
                    });
                    scope.$watch("isEditMode", function (isEditMode) {
                        scope.text = scope.section.content;
                    });
                  
                }

            }
        }]);
