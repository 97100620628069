angular.module("DigiLean").factory("statusColumnProvider", ['$rootScope','$filter', '$translate', 'boardTaskService',
    function ($rootScope,$filter, $translate, boardTaskService) {
        var columns = [];
        var tasks = [];
        function statusColumnProvider() {
            function createStatusColumn(title, property, propertyValue, tasks) {
                var column = {
                    showAddButton: true,
                    title: title,
                    displayUser: false,
                    tasks: [],
                    property: property,
                    propertyValue: propertyValue,
                    isPartOfColumn: function (task, comparer) {
                        if (comparer == "progressStatus") {
                            return task.progressStatus == propertyValue;
                        }
                        if (comparer == "status" && task.progressStatus != "inprogress") {
                            return task.status == propertyValue;
                        }
                        return false;
                    },
                    hasTask: function(taskId) {
                        var task = $filter('filter')(this.tasks, { id: taskId }, true);
                        return task.length > 0;
                    },
                    addTask: function(task) {
                        if (this.isPartOfColumn(task, this.property)) {
                            this.tasks.unshift(task)
                            return true;
                        }
                        return false;
                    },
                    removeTask: function(task) {
                        if (this.isPartOfColumn(task, this.property)) {
                            var index = this.tasks.indexOf(task);
                            this.tasks.splice(index, 1);
                            return true;
                        }
                        return false;
                    },
                    saveChanges: function (changeSettings) {
                        // Set property to comparer
                        var statusOptions = {
                            taskid: changeSettings.taskId,
                            status: changeSettings.propertyValue
                        };
                        if (changeSettings.propertyValue == "inprogress") {
                            boardTaskService().updateProgressStatus(changeSettings.taskId, statusOptions).then(function (result) {
                            });
                        } else {
                            boardTaskService().updateStatus(changeSettings.taskId, statusOptions).then(function (result) {
                            });
                        }
                    },
                    sortTasks: function() {
                        this.tasks = $filter('orderBy')(this.tasks, 'status');
                    },
                };
                // Add tasks to column
                tasks.forEach(function (task) {
                    if (column.isPartOfColumn(task, column.property)) {
                        column.tasks.push(task)
                    }
                });
                // Sort by status
                column.sortTasks();
                return column;
            }

            $rootScope.subscribe('TaskStatusUpdated', function (updatedstatus) {
                // Is task part of board?
                var task = $filter('filter')(tasks, { id: updatedstatus.taskId }, true);
                if (task.length > 0) {
                    task[0].status = updatedstatus.status;
                    task[0].progressStatus = updatedstatus.progressStatus;
                    task[0].animate("bounce");
                    columns.forEach(function(column){
                        // Remove if wrong status
                        if (column.propertyValue != updatedstatus.status && column.hasTask(updatedstatus.taskId)) {
                            // Add task to column
                            var index = column.tasks.indexOf(task[0]);
                            column.tasks.splice(index, 1);
                        }
                         // Add task to column
                        if (column.propertyValue == updatedstatus.status) {
                            if (!column.hasTask(updatedstatus.taskId)) {
                                
                                column.tasks.push(task[0]);
                            }
                        }
                    });
                    
                }
            });

            return {
                getColumns: function (boardTasks, users) {

                    tasks = boardTasks;
                    columns = [];
                    columns.push(createStatusColumn($translate.instant("COMMON_NEW"), "status", "blank", tasks));
                    columns.push(createStatusColumn($translate.instant("COMMON_IN_PROGRESS"), "progressStatus", "inprogress", tasks));
                    columns.push(createStatusColumn($translate.instant("COMMON_DONE"), "status", "OK", tasks));
                    columns.push(createStatusColumn($translate.instant("COMMON_BLOCKED"), "status", "unacceptable", tasks));
                    return columns;
                }

               
            }

            

          
        }
  
        return statusColumnProvider;
    }]);
