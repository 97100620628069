angular
    .module('DigiLean').directive("learningSummary", ['$filter', '$translate', 'learningService', 'debounce',
        function ($filter, $translate, learningService, debounce) {
            return {
                templateUrl: 'learningSummary.html',
                restrict: 'E',
                scope: {
                    'materialId': '<',
                    'isEditMode': '<',
                    'selectedPageId': '<',
                    'pageSelectHandler': '&',
                },
                link: function ($scope, elem, attrs) {
                    
                    $scope.$watch("materialId", function (materialId) {
                        if (!$scope.materialId) return;
                        loadSummary(materialId);
                    });

                    $scope.$watch("isEditMode", function (isEditMode) {
                        if (!$scope.isEditMode) {
                            setAllItemsEditMode(false);
                        };
                    });
                    $scope.$watch("selectedPageId", function (selectedPageId) {
                        setSelectPageId(selectedPageId);
                    });
                    var currentPageId = null;
                    var setSelectPageId = function(selectedPageId) {
                        if (!selectedPageId) {
                            $scope.selectedPage = null;
                            return;
                        } 
                        if (currentPageId == selectedPageId) return;
                        if (!$scope.summary) return;
                        $scope.summary.forEach(element => {
                            element.pages.forEach(page => {
                                if(page.id == selectedPageId) {
                                    $scope.selectedPage = page;
                                }
                            });
                            
                        });
                    }
            
                    function setAllItemsEditMode(isEditMode) {
                        for (let index = 0; index < $scope.summary.length; index++) {
                            const item = $scope.summary[index];
                            item.isEditMode = isEditMode;
                            if (item.pages) {
                                for (let i = 0; i < item.pages.length; i++) {
                                    const page = item.pages[i];
                                    page.isEditMode = isEditMode;
                                }
                            }
                        }
                    }
                    $scope.selectedPage = null;
                    $scope.selectPage = function (page) {
                        var payLoad = {
                            page: page
                        }
                        $scope.selectedPage = page;
                        currentPageId = page.id;
                        $scope.pageSelectHandler(payLoad);
                    }
                    $scope.setItemViewMode = function (item) {
                        item.isEditMode = false;
                    }
                    $scope.setItemEditMode = function (item) {
                        item.isEditMode = true;
                        $scope.selectPage(item);
                    }
            
                    $scope.removeItem = function (item) {
                        learningService().deleteItem(item.id).then(function () {
                            loadSummary($scope.materialId);
                        });
                    }
            
                
                    $scope.itemTitleChanged = function (item) {
                        learningService().updateItemTitle(item.id, item.title).then(function (itemTitle) {
            
                        });
                    }
            
                    function updateSummarySortOrder(e, ui) {
                        // if (materialId !== sourceChapterId && !ui.item.sortable.received) return;
                        var chapters = ui.item.sortable.droptargetModel;
                        var summarySortOrder = {
                            materialId: $scope.materialId,
                            sortOrders: []
                        }
            
                        var index = 0;
                        chapters.forEach(page => {
                            summarySortOrder.sortOrders.push({
                                id: page.id,
                                sortOrder: index
                            });
                            index += 1;
                        });
                        learningService().updateSummarySortOrder(summarySortOrder);
                    }
            
                    function updatePageListSortOrder(e, ui) {
                        var target = ui.item.sortable.droptarget[0];
                        var source = ui.item.sortable.source[0];
                        var chapterId = $(target).attr("data-id");
                        var sourceChapterId = $(source).attr("data-id");
                        // if the sort event is part of move, we will wait for recieved flag to complete, otherwise items will not be correct.
                        if (chapterId !== sourceChapterId && !ui.item.sortable.received) return;
                        var pages = ui.item.sortable.droptargetModel;
                        var chapterSortOrder = {
                            chapterId: chapterId,
                            sortOrders: []
                        }
            
                        var index = 0;
                        pages.forEach(page => {
                            chapterSortOrder.sortOrders.push({
                                id: page.id,
                                sortOrder: index
                            });
                            index += 1;
                        });
                        learningService().updateChapterSortOrder(chapterSortOrder);
                    }
            
                    $scope.summarySortableOptions = {
                        connectWith: ".summaryList",
                        handle: ".drag-handle",
                        placeholder: "summary-item-sortable-placeholder",
                        stop: updateSummarySortOrder
                    };
                    $scope.pageListSortableOptions = {
                        connectWith: ".chapter-page-list",
                        handle: ".drag-handle",
                        placeholder: "summary-item-sortable-placeholder",
                        stop: updatePageListSortOrder
                    };
            
                    $scope.summary = [];
                    function loadSummary(materialId) {
                        learningService().getItems(materialId).then(function (summary) {
                            $scope.material = summary.material;
                            $scope.frontPage = summary.material;
                            $scope.summary = summary.items;
                            setSelectPageId($scope.selectedPageId);
                        });
                    }
                 
            
                    $scope.addChapter = function () {
                        var chapter = {
                            learningMaterialId: $scope.materialId,
                            title: $translate.instant("COMMON_TITLE"),
                            itemType: "chapter",
                            pages: [],
                            parentSortOrder: 0,
                            sortOrder: $scope.summary.length + 1
                        }
                        learningService().createItem(chapter).then(function (item) {
                            $scope.summary.push(item);
                            $scope.setItemEditMode(item);
                        })
            
                    }
            
                    $scope.addPage = function (addToChapter) {
                        // add to chapter
                        var page = {
                            learningMaterialId: $scope.materialId,
                            title: $translate.instant("COMMON_TITLE"),
                            itemType: "page",
                            sortOrder: $scope.summary.length + 1,
                            parentSortOrder: 0,
                        }
                        if (addToChapter) {
                            page.parentId = addToChapter.id;
                            page.sortOrder = addToChapter.sortOrder;
                            page.parentSortOrder = addToChapter.pages.length + 1;
                            learningService().createItem(page).then(function (item) {
                                addToChapter.pages.push(item);
                                $scope.setItemEditMode(item);
                                $scope.selectPage(item);
                            })
            
                        } else {
                            learningService().createItem(page).then(function (item) {
                                $scope.summary.push(item);
                                $scope.setItemEditMode(item);
                            })
                        }
            
                    }
            
                    $scope.subscribe('LearningItemTitleChanged', function (titleChanged) {
                        for (let index = 0; index < $scope.summary.length; index++) {
                            const element = $scope.summary[index];
                            if (element.id == titleChanged.itemId) {
                                element.title = titleChanged.title;
                                break;
                            }
                            for (let index = 0; index < element.pages.length; index++) {
                                const page = element.pages[index];
                                if (page.id == titleChanged.itemId) {
                                    page.title = titleChanged.title;
                                    break;
                                }
                            }       
                        }
                    });
                  
                }

            }
        }]);
