var DigiLean = angular.module('DigiLean');  
DigiLean.controller('causeAdminController', ['$scope','$translate', 'deviationService','modalService', 'languageAdminService',
function ($scope, $translate, deviationService, modalService, languageAdminService) {
    $scope.savedSuccessfully = false;
    $scope.message = "";

    $translate('DEVIATION_NEW_DEVIATION_CAUSE').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
    });

        
    var defaultConfig = {languageCode: '',
                    languageLabel: $translate.instant('ADMIN_DEFAULT_CONFIG'),
                    nativeLabel: $translate.instant('ADMIN_DEFAULT_CONFIG')
                    };
    $scope.selectedLanguage = defaultConfig;
    $scope.languages = languageAdminService().getSupportedLanguages()
    $scope.languages.unshift($scope.selectedLanguage);
    $scope.selectedCustomLanguageLabel;

    $scope.changeLanguage = function (language) {
        if(!language) return;
        $scope.message = "";
        if(!$scope.registration.title){
            $scope.selectedLanguage = defaultConfig; //reset back top default setting
            $scope.message = $translate.instant('ADMIN_DATA_FIELD_MISSING') + ": " + $translate.instant('DEVIATION_CAUSE');
            return;
        }
        $scope.selectedLanguage = language;
        $scope.selectedCustomLanguageLabel = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
    }

    $scope.updateTranslationLabel = function (translabel) {
        var existing = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        
        if(!existing){
            var customLanguageLabel = {
                id:0,
                languageCode: $scope.selectedLanguage.languageCode,
                label: translabel.label,
                description: translabel.description
            }
            $scope.registration.customLanguageLabels.push(customLanguageLabel);
        } else {
            existing.label = translabel.label;
            existing.description = translabel.description;
        }
    }


    $scope.clearActiveCause = function () {
        $translate('DEVIATION_NEW_DEVIATION_CAUSE').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });
        $scope.message = "";
        $scope.selectedCustomLanguageLabel = {};
        $scope.registration = {
            isNewCause: true,
            id: 0,
            title: "",
            description:"",
            customLanguageLabels: []
        };
    }

    $scope.clearActiveCause();

    $scope.causes = [];
    var loadCauses = function () {
        deviationService().getTranslationsWithCauses().then(function (causes) {
            $scope.causes = causes;
        });
    }
    loadCauses();
    $scope.editCause = function (cause) {
        $translate('COMMON_EDIT').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });
        $scope.registration = {
            isNewCause: false,
            id: cause.id,
            title: cause.title,
            description: cause.description,
            customLanguageLabels: cause.customLanguageLabels
        };
        $scope.selectedCustomLanguageLabel = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
    
    }

    $scope.delete = function (item) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CAUSE_CONFIRMATION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.COMMON_DELETE,
                headerText: translations.COMMON_DELETE + ' ' + item.title + '?',
                bodyText: translations.ADMIN_MESSAGE_DELETE_CAUSE_CONFIRMATION 
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                deviationService().deleteCause(item.id).then(function (data) {  
                    loadCauses();
                    clearActiveCause();
                    $translate('ADMIN_MESSAGE_CONSEQUENCE_DELETED').then(function (msg) {
                            toastr.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                    });
                },function (err) {
                });
            });
        });
    }    

    $scope.save = function () {
        $scope.message = "";
        if ($scope.registration.isNewCause) {
            if(!$scope.registration.title){
                $scope.selectedLanguage = defaultConfig; //reset back top default setting
                $scope.message = $translate.instant('ADMIN_DATA_FIELD_MISSING') + ": " + $translate.instant('DEVIATION_CAUSE');
                return;
            }
            deviationService().createCause($scope.registration).then(function () {
                $translate('ADMIN_MESSAGE_CAUSE_CREATED').then(function (msg) {
                    toastr.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                loadCauses();
                $scope.clearActiveCause();
            });
        } else {
            deviationService().updateCause($scope.registration).then(function () {
                $translate('ADMIN_MESSAGE_CAUSE_UPDATED').then(function (msg) {
                    toastr.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                loadCauses();
            });
        }
    }

}]);