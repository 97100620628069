angular
    .module('DigiLean')
    .directive("boolSelector", ['$filter', 'dataService',
        function ($filter, dataService) {
            return {
                templateUrl: 'boolselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.selected = "all";

                    scope.$watch('filters', function () {
                        
                        applyFilter();
                    });

                    function applyFilter(){
                        if(!scope.filters){
                            scope.selected = "all";
                        } else {
                            if(scope.filters.length > 0 && scope.filters[0].items[0]){
                                scope.selected = "yes";  
                            } else {
                                scope.selected = "no";
                            }
                        }
                    }

                    scope.modifyFilter = function(){
                        if(scope.selected === "yes"){
                            scope.filters = [{
                                sourceColumn: scope.source,
                                operator: "InList",
                                items: [true]
                            }];  
                        } else if (scope.selected === "no"){
                            scope.filters = [{
                                sourceColumn: scope.source,
                                operator: "InList",
                                items: [false]
                            }];
                        } else {
                            scope.filters = null;
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                    }
                }
            }
        }]);
