import angular from "angular"
import * as staticFileService from "@common/shared/staticFileService"

const DigiLean = angular.module('DigiLean')
interface DigileanDiagramTemplate {
    imageUrl: string
    url: string
    name: string
    description: string
}

DigiLean.controller('DiagramTemplateSelectorController', ['$scope', '$uibModalInstance', '$translate',
    function ($scope, $uibModalInstance, $translate) {
     
        const templates: DigileanDiagramTemplate[] = [
            {
                imageUrl: staticFileService.previewImage("Diagram-preview-blank.jpg"),
                url: staticFileService.diagramTemplate("BlankTemplate.drawio.svg"),
                name: $translate.instant('DIAGRAM_TEMPLATE_BLANK'),
                description: "Blank template"
            },
            {
                imageUrl: staticFileService.previewImage("Diagram-preview-simplefishbone.jpg"),
                url: staticFileService.diagramTemplate("SimpleFishboneTemplate.drawio.svg"),
                name: $translate.instant('DIAGRAM_TEMPLATE_SIMPLE_FISHBONE'),
                description: "Simple Fishbone template"
            },
            {
                imageUrl: staticFileService.previewImage("Diagram-preview-fishbone.jpg"),
                url: staticFileService.diagramTemplate("FishboneTemplate.drawio.svg"),
                name: $translate.instant('DIAGRAM_TEMPLATE_FISHBONE'),
                description: "Fishbone template"
            },
            {
                imageUrl: staticFileService.previewImage("Diagram-preview-vsm.jpg"),
                url: staticFileService.diagramTemplate("ValueStreamMapping.drawio.svg"),
                name: $translate.instant('DIAGRAM_TEMPLATE_VALUE_STREAM_MAPPING'),
                description: "Value Stream Mapping template"
            },
            {
                imageUrl: staticFileService.previewImage("Diagram-preview-fivewhys.jpg"),
                url: staticFileService.diagramTemplate("FiveWhysTemplate.drawio.svg"),
                name: $translate.instant('DIAGRAM_TEMPLATE_FIVE_WHYS'),
                description: "Five Whys template"
            },
            {
                imageUrl: staticFileService.previewImage("Diagram-preview-spaghetti.jpg"),
                url: staticFileService.diagramTemplate("Spaghetti.drawio.svg"),
                name: $translate.instant('DIAGRAM_TEMPLATE_SPAGHETTI'),
                description: "Spaghetti template"
            }
        ]
        $scope.templates = templates
        $scope.ready = true
        
        const fetchTemplate = async (url: string) => {
            const res = await fetch(url)
            const svg = await res.text()
            return svg
        }

        $scope.selectTemplate = async function(template: DigileanDiagramTemplate) {
            const templateSvg = await fetchTemplate(template.url)
            $uibModalInstance.close({templateSvg, name: template.name})
        }
        
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }
    }])