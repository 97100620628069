angular
    .module('DigiLean')
    .directive("navnewa3", ['$uibModal',
        function ($uibModal) {
            return {
                templateUrl: 'navnewa3.html',
                restrict: 'E',
                scope: {
                    'mode': '@'
                },
                link: function (scope, element, attrs) {
                    scope.createNew = function () {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newA3Form.html',
                            controller: 'newA3ModalController',
                            resolve: {
                                a3: function () {
                                    return {
                                        id: 0,
                                        title: "",
                                        assetId: ""
                                    }
                                }

                            }
                        });

                        // When user has created new a3
                        modalInstance.result.then(function (a3) {
                            a3Service().addA3(a3).then(function (savedA3) {
                                $state.go('a3wizard', { id: savedA3.id }, { reload: false });
                                $scope.publish("SendA3Created", savedA3);
                            });
                        }, function () {
                            //$log.info('Modal dismissed at: ' + new Date());
                        });

                    }
                }
            }
        }]);



