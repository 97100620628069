angular
    .module('DigiLean')
    .directive("smarttableProgressbar", ['$filter', '$translate',
        function ($filter, $translate) {
            return {
                templateUrl: 'smarttableProgressbar.html',
                restrict: 'E',
                scope: {
                    'progress': '=',
                    'color': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('progress', function (value) {
                        
                    });
                    scope.$watch('color', function (value) {
                        
                    });
                }
            }
        }]);
