/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserCheckupStatus {
    ActiveUser = 'activeUser',
    NotExist = 'notExist',
    ExistInCurrentInstallation = 'existInCurrentInstallation',
    ExistInAnotherInstallation = 'existInAnotherInstallation',
}
