﻿var DigiLean = angular.module('DigiLean');
DigiLean.factory('boardDrawService', ['$http', '$translate', '$compile', 'taskDrawingService',
    function ($http, $translate, $compile, taskDrawingService) {

        var service = {};
        service.drawBoard = function (boardElement, options) {
            var table = boardElement;
            // Make sure table is clean.
            $(table).empty();
            var headerMinWidth = 100;
            // For weeklyBoards
            var isWeeklyBoard = false;
            var isKamishibai = false;
            var settings = JSON.parse(options.board.board.settings);
            if (settings && settings.isKamishibai) {
                isKamishibai = settings.isKamishibai;
            }
            if (options.board.board.boardType == "Weekly") {
                isWeeklyBoard = true;
            }
            // Should row text show horizontal?
            var showHorizontalRowHeaders = "vertical";
            if (settings && settings.ShowHorizontalRowHeaders) {
                showHorizontalRowHeaders = "horizontal";
            }
            var categoryDirection = "vertical-text";
            if (settings && settings.ShowHorizontalRowHeaders) {
                categoryDirection = "horizontal-text";
            }
            //
            // header with empty column to be above rows
            // TODO: translate tooltip text
            var header = $('<thead></thead>');
            var row = $('<tr></tr>');
            row.append("<th class='boardtable-navigation-header category-cell-size " + categoryDirection + "' style='background: white;'><div class='rotate-row-headers' data-direction='" + showHorizontalRowHeaders + "' data-toggle='tooltip' data-placement='left'  title='change direction of row headers'><i class='fa fa-arrows-alt-v'></i></div></th>");
            // row.append("<th class='boardtable-navigation-header' style='background: white;'></th>");
            // draw columns
            $.each(options.board.columns, function (key, val) {
                // Add data column
                var columnStyle = "style='min-width: " + headerMinWidth + "px;'";
                if (val.categoryStyle.backgroundColor !== null && val.categoryStyle.foregroundColor !== null) {
                    columnStyle = "style='min-width: " + headerMinWidth + "px;background-color: " + val.categoryStyle.backgroundColor + ";color:" + val.categoryStyle.foregroundColor + "'";
                }
                if (val.categoryStyle.backgroundColor !== null && val.categoryStyle.foregroundColor === null) {
                    columnStyle = "style='min-width: " + headerMinWidth + "px;background-color: " + val.categoryStyle.backgroundColor + ";'";
                }
                if (val.categoryStyle.backgroundColor === null && val.categoryStyle.foregroundColor !== null) {
                    columnStyle = "style='min-width: " + headerMinWidth + "px;color: " + val.categoryStyle.foregroundColor + ";'";
                }

                var dateText = "";
                var dayContainer = "";
                if (val.weekdayName) {
                    dateText = "<span class='weekdayContainer dateText' data-weekday='" + val.weekdayName + "' data-date=''></span>";
                    dayContainer = "<div class='category-day-container'></div>";
                }

                var responsible = {
                    categoryId: val.id,
                    userName: val.responsibleUser,
                    userId: val.responsibleUserId,
                    userDisplayName: val.responsibleDisplayName
                }
                var responsibleForCategory = taskDrawingService().drawResponsibleForCategory(responsible);

                row.append("<th data-categoryid=" + val.id + " class='category-header' " + columnStyle + " >" + dayContainer + "<div class='boardcategory'>"
                    //+ "<i class='fa fa-caret-square-o-left fa' data-column='column-show'></i>"
                    + "<div class='headerText' data-toggle='tooltip'  data-placement='left' title='" + val.name + "'>" + val.name + "</div>" + dateText + "<div class='responsible-for-category-container'>" + responsibleForCategory + "</div></div></th>");
            });

            header.append(row);
            table.append(header);


            // Create rows
            var body = $('<tbody></tbody>');
            $.each(options.board.rows, function (key, val) {
                // Add data column

                var rowStyle = "style='";
                if (val.categoryStyle.backgroundColor !== null && val.categoryStyle.foregroundColor !== null) {
                    rowStyle = rowStyle + "background-color:" + val.categoryStyle.backgroundColor + ";color:" + val.categoryStyle.foregroundColor + ";'";
                }
                if (val.categoryStyle.backgroundColor !== null && val.categoryStyle.foregroundColor === null) {
                    rowStyle = rowStyle + "background-color:" + val.categoryStyle.backgroundColor + ";'";
                }
                if (val.categoryStyle.backgroundColor === null && val.categoryStyle.foregroundColor !== null) {
                    rowStyle = rowStyle + "color: " + val.categoryStyle.foregroundColor + ";'";
                }

                var row = $('<tr></tr>');
                var rowData = val;

                var rowResponsible = {
                    categoryId: val.id,
                    userName: val.responsibleUser,
                    userId: val.responsibleUserId,
                    userDisplayName: val.responsibleDisplayName
                }
                var rowResponsibleCategory = taskDrawingService().drawResponsibleForCategory(rowResponsible);
                // weeklyboard handling rows
                var dateText = "";
                var dayContainer = "";
                if (val.weekdayName) {
                    dateText = "<div class='weekdayContainer verticaldateText' data-weekday='" + val.weekdayName + "' data-date=''></div";
                    dayContainer = "<div class='category-day-container top-right'></div>";
                }
                row.append("<td data-rowid='" + rowData.id + "' class=rowcategory " + rowStyle + "><div class='rowcategory-user-container'>" + dayContainer + '<div class=' + showHorizontalRowHeaders + ' data-toggle="tooltip" title=' + rowData.name + '>' + rowData.name + dateText + "</div>" + rowResponsibleCategory + "</div></td>");
                // Create cells
                $.each(options.board.columns, function (key, val) {
                    var td = $("<td class='board-table-cell' data-rowid=" + rowData.id + ' data-categoryid=' + val.id + "></td>");
                    var boardCell = $('<div class=boardcell></div>');

                    // find matching task that belong in category and row
                    var tasksmatchingCell = options.board.tasks.filter(function (task) {
                        // Allow for category be either row or column
                        return (task.rowCategoryId === rowData.id || task.rowCategoryId === val.id) && ((task.columnCategoryId === rowData.id || task.columnCategoryId === val.id));
                    });
                    // cell ready, inserted into td
                    td.append(boardCell);
                    row.append(td);
                });
                body.append(row);

            });
            table.append(body);

            // adjust "equal row height
            //   var tableHeight = $("#boardTable").height();
            //   var rowCount = options.board.rows.length;
            //   var rowHeight = (tableHeight - 40) / rowCount;
            //   $(".rowcategory").css("min-height", rowHeight);

            $('[data-toggle="tooltip"]').tooltip();
            var tableWidth = $("#boardContainer .ibox").width();
            var cellMaxWidth = Math.round((tableWidth - 60) / options.board.columns.length);
            $(window).off();
            $(window).resize(function () {
                tableWidth = $("#boardContainer .ibox").width();
                cellMaxWidth = Math.round((tableWidth - 60) / options.board.columns.length);
                $(".boardcell").css("max-width", cellMaxWidth + "px");
                $(".boardcell").css("min-width", (cellMaxWidth - 60) + "px");
            });
            // Adjust cells so the table scales according to table.

            $(".boardcell").css("max-width", cellMaxWidth + "px");
            $(".boardcell").css("min-width", (cellMaxWidth - 60) + "px");
            return this;
        };

        service.addWeekSelector = function(scope, boardid) {
            
            var portletHeader = $(".boardtable-navigation-header").first();
            // var el = $compile( "<fileinfo></fileinfo>" )( $scope );
            var internalScope = scope.$new(true);
            internalScope.boardid = boardid;
            // var el = $compile("<task-attachments files='files'></task-attachments>")(internalScope);
            var el = $compile("<week-selector boardid='boardid'></week-selector>")(internalScope);
            portletHeader.append(el);
        }

        service.refreshTableLayout = function () {
            var tableWidth = $("#boardContainer .ibox").width();
            var board = $("#boardTable");
            var columns = $("#boardTable th").length - 1; // Subtract the container column
            var cellMaxWidth = Math.round((tableWidth - 60) / columns);
            $(".boardcell").css("max-width", cellMaxWidth + "px");
            $(".boardcell").css("min-width", (cellMaxWidth - 60) + "px");
            setTimeout(function () {
                var $table = $("table.board");
                $table.trigger('reflow');
            }, 20);
        }

        service.drawTask = function (task, access, animation, isWeeklyBoard, isKamishibai) {
            var iconclass = taskDrawingService().getIconClass(task.status, isKamishibai);
            var actionContainer = taskDrawingService().getActionContainer(isWeeklyBoard);
            var responsibleContainer = taskDrawingService().drawResponsibleContainer(task);
            var commentsContainer = taskDrawingService().drawCommentsContainer(task.commentCount);
            var attachmentContainer = taskDrawingService().drawAttachmentContainer(task.attachmentCount);
            var subtaskContainer = "<div class='subtask-status-container'></div>";
            if (task.hasOwnProperty('type') === false) {
                // if task object doesn't have a type we will create it
                if (isWeeklyBoard) {
                    task.type = "WeeklyBoardTask"
                } else {
                    task.type = "KanbanTask"
                }
            }
            // Format tags to semicolon string
            var tags = [];
            if (task.tags) {
                for (var i = 0; i < task.tags.length; i++) {
                    tags.push(task.tags[i].tag);
                }
                tags = tags.join(",");
            }
            if (tags.length === 0) tags = "";

            var animationClass = "";
            if (animation) {
                animationClass = "animated " + animation;
            }


            // conditional task theme.
            var style = "";
            var statusClass = ""; // Default status

            var theme = taskDrawingService().getTaskCardTheme(task, isKamishibai);

            style = theme.portletStyle;
            statusClass = theme.statusClass;

            var wrapperClass = "<div class='content-wrapper'>";
            var kamishibaiIconStyle = "";
            if(isKamishibai){
                wrapperClass = "<div class='kamishibai-content-wrapper'>";
                kamishibaiIconStyle = "color:white;";
            }

            var html = "<div class='portlet " + animationClass + "' data-id=" + task.id + " data-type='" + task.type + "'  data-tags='" + tags 
            + "' data-responsibleuserid='" + task.responsibleUserId + "' data-responsibleuser='" + task.responsibleUser + "' data-color='" 
            + task.color + "'  data-displayname='" + task.responsibleDisplayName + "' data-repeatParentId=" + task.repeatParentId + " data-siblingid="
             + task.siblingId + " data-duration=" + task.durationInSeconds + " data-durationstart='" + task.startTime + "' data-durationend='" + task.endTime + "' style='" + style + "'>" +
                "<div class='portlet-header " + statusClass + "'>" +

                "<div class='status' data-status='" + task.status + "'><i class='" + iconclass + " fa-2x' style='font-size: 18px;" +kamishibaiIconStyle+ "'></i></div>" +
                "<div class='tasktitle'>" +
                service.getBoardDate(task, isWeeklyBoard) + "<div class='taskheader'>" + task.title + "</div>" + taskDrawingService().getTextDescription(task)
                + "<div class='image-container'></div>"
                + " </div>" 
                + "<div class='boardtask-attachments-container'></div>"
                + "</div>" + taskDrawingService().drawTags(task.tags, service.getTaskInfoIndicators(task))
                + wrapperClass
                + "<div class='content'>" + actionContainer + subtaskContainer + commentsContainer + attachmentContainer + responsibleContainer + "</div>"
                + "</div>";


            return html;
        },

            service.getBoardDate = function (task, isWeeklyBoard) {
                if (isWeeklyBoard && isWeeklyBoard === true) return "";
                if (!task.boardDate) return "";
                // Overdue check

                var overdue = "";
                var duration = moment.duration(moment().diff(moment(task.boardDate)));
                var days = duration.asDays();
                if (days > 1 && task.status === "blank") {
                    overdue = "overdue";
                }
                var container = $("<div class='date-container'></div>");
                container.append('<i class="far fa-calendar-alt ' + overdue + '"> <date-viewer date="' + task.boardDate + '"></date-viewer></i>')
                return container.wrap('<p/>').parent().html();
            }

        service.getTaskInfoIndicators = function (task) {
            if (!task.repeatParentId && !task.siblingId 
                && !task.improvementSuggestionId && !task.a3StepId 
                && !task.deviationId && !task.parentId && task.taskType !== 'SubTask') return "";

            var container = $("<div class='info-container'></div>");
            if (task.repeatParentId) {
                container.append("<i class='fas fa-redo-alt info-icon repeat-icon' data-toggle='tooltip' data-placement='bottom' title='" + $translate.instant("BOARD_REPEATED_TASK") + "'></i>");
            }
            if (task.siblingId) {
                container.append("<i class='fa fa-clone clone-icon info-icon' data-toggle='tooltip' data-siblingId=" + task.siblingId + " data-placement='bottom' title='" + $translate.instant("BOARD_CLONED_TASK_CLICK_INFO") + "'></i>");
            }
            if (task.taskType == 'SubTask' || task.parentId) {
                container.append("<i class='fas fa-sitemap info-icon parent-icon' data-toggle='tooltip' data-parentid=" + task.parentId + "  data-placement='bottom' title='" + $translate.instant("COMMON_PARENT_TASK_INFO") + "'></i>");
            }
            if (task.improvementSuggestionId) {
                container.append("<i class='fal fa-lightbulb improvement-icon info-icon' data-toggle='tooltip' data-improvementsuggestionid=" + task.improvementSuggestionId + "  data-placement='bottom' title='" + $translate.instant("COMMON_SUGGESTION_TASK_CLICK_INFO") + "'></i>");
            }
            if (task.deviationId) {
                container.append("<i class='fa fa-exclamation-triangle deviation-icon info-icon' data-toggle='tooltip' data-deviationid=" + task.deviationId + "  data-placement='bottom' title='" + $translate.instant("COMMON_DEVIATION_TASK_CLICK_INFO") + "'></i>");
            }
            if (task.a3StepId) {
                container.append("<i class='far fa-file a3-icon info-icon' data-toggle='tooltip' data-a3id=" + task.a3Id + " data-placement='bottom' title='" + $translate.instant("BOARD_A3_TASK_CLICK_INFO") + "'></i>");
            } /*<a ui-sref="a3wizard({id:task.actionParentId})">*/
            return container.wrap('<p/>').parent().html();;
        }

        service.redrawTasks = function (board, tasks, isWeeklyBoard, isKamishibai) {
            // remove tasks in board
            $(board).find("div .portlet").remove();
            $(board).css("overflow-x", "hidden");
            $(board).find(".boardcell").css("min-height", 80);
            var animation = "bounceInLeft";
            var rowsInDom = angular.element("td.rowcategory");
            var rows = [];
            angular.forEach(rowsInDom, function (row, key) {
                rows.push(row.attributes['data-rowid'].value);
            });

            angular.forEach(tasks, function (task, key) {
                if (animation === "bounceInLeft") {
                    animation = "bounceInRight";
                } else {
                    animation = "bounceInLeft";
                }
                animation = "animated " + animation;
                var cell = angular.element("td[data-rowid='" + task.rowCategoryId + "'][data-categoryid=" + task.columnCategoryId + "]").find(".boardcell");
                // row and categoryId might be reversed
                if (cell.length === 0) {
                    cell = angular.element("td[data-rowid='" + task.columnCategoryId + "'][data-categoryid=" + task.rowCategoryId + "]").find(".boardcell");
                }
                var taskHtml = service.drawTask(task, null, animation, isWeeklyBoard, isKamishibai);
                cell.append(taskHtml);
            });
            
            service.recalculateZindex();
            // remove animation afterwards
            service.removeAnimations(board);
            // Adjust fixed header
            setTimeout(function () {
                var $table = $("table.board");
                $table.trigger('reflow');
            }, 20);
        }

        service.removeAnimations = function (board) {
            // remove animation afterwards
            setTimeout(function () {
                $(board).find("div .portlet").removeClass("animated");
                $(board).css("overflow-x", "auto");
            }, 1000);
        };

        service.compareForZindex = function (portletA, portletB) {
            var comparison = 0;
            if (portletA.offsetLeft === portletB.offsetLeft) {
                if (portletA.offsetTop > portletB.offsetTop) {
                    comparison = 1;
                } else {
                    comparison = -1;
                }
            } else if (portletA.offsetLeft > portletB.offsetLeft) {
                comparison = -1;
            } else {
                comparison = 1;
            }
            return comparison;
        }

        service.recalculateZindex = function () {
            // // TODO: Should we check if IE and not perform recalc?
            var portlets = $("#boardContainer .portlet");
            var sortedPortlets = portlets.sort(service.compareForZindex);
            var i = 0;
            var startIndex = 800;
            angular.forEach(sortedPortlets, function (portlet, key) {
                var zindex = startIndex - i;
                if (zindex < 1) {
                    zindex = 1;
                }
                $(portlet).css("z-index", zindex);
                i += 1;
            });
            // parentHeight
            var cells = $(".boardcell");
            var cellHeights = [];
            for (var i = 0; i < cells.length; i++) {
                var cell = cells[i];
                var targetHeight = $(cell).parent().height() - 11; // subtract padding so it's not growing all the time
                cellHeights.push({
                    cell: cell,
                    height: targetHeight
                });
                $(cell).css("min-width", $(cell).parent().width() + "px");
            }



        }

        return service;
    }]);