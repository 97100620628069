import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('digilean-button')
export class DigiLeanButton extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
            --digilean-button-color: var(--digilean-primary-text);
            --digilean-button-outline-color: var(--digilean-input-border);
            --digilean-button-border-color: var(--digilean-input-border);
            --digilean-button-background: var(--digilean-secondary-background);
            --digilean-button-background-hover: var(--digilean-primary-background);
        }
        button {
            cursor: pointer;
            color: var(--digilean-button-color);
            outline-color: var(--digilean-button-outline-color);
            border: 1px solid var(--digilean-button-border-color);
            background: var(--digilean-button-background);
            box-sizing: border-box;
            border-radius:3px;
            border-width: 1px;
            padding: 0.5em 1em;
            transition: background .1s linear;
        }
        button:hover {
            background: var(--digilean-button-background-hover);
        }
        button:disabled {
            cursor: not-allowed;
            color: var(--digilean-disabled-color);
            outline-color: var(--digilean-primary-button);
            border-color: var(--digilean-primary-button);
            background: var(--digilean-disabled-background);	
        }
    `

    render() {
        return html`
            <button type="button">
                <slot>Btn</slot>
            </button>
        `
    }
}

@customElement('digilean-button-primary')
export class DigiLeanButtonPrimary extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
        }
        digilean-button {
            --digilean-button-color: var(--digilean-main-menu-text);
            --digilean-button-outline-color: var(--digilean-primary);
            --digilean-button-border-color: var(--digilean-primary);
            --digilean-button-background: var(--digilean-primary);
            --digilean-button-background-hover: var(--digilean-blue-diamond);
        }
    `

    render() {
        return html`
            <digilean-button>
                <slot>Btn</slot>
            </digilean-button>
        `
    }
}