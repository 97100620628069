import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("navnewbubble", ['$translate', 'dataSourceService', 'authService', 'dataService', 'dataListService',
        function ($translate, dataSourceService, authService, dataService, dataListService) {
            return {
                templateUrl: 'navnewbubble.html',
                restrict: 'E',
                scope: {
                    'mode': '@'
                },
                link: function (scope, element, attrs) {
                    scope.selectedAsset = null;
                    scope.value = 1;
                    scope.description = null;
                    var userid = authService.getCurrentUser().userId;
                    var dataSourceId = 0;
                    scope.isProcessing = false;

                    var listOptions = { includeDeletedItems: false };
                    var listId = 0;
                    function loadList() {
                        dataListService().getFeatureList("CompetenceBall", listOptions).then(function (list) {
                            if (list) {
                                listId = list.id;
                                scope.categories = list.items;
                                scope.selectedCategory = scope.categories[0];
                            }
                        });
                    }
                    loadList();

                    dataSourceService().getFeatureSource("CompetenceBall").then(function (source) {
                        if (source) {
                            dataSourceId = source.id;
                        }
                    })

                    assetService.getList(true).then(function (data) {
                        scope.assets = data;
                        scope.selectedAsset = scope.assets[0];
                    });


                    /* Events */
                    scope.subscribe("DataListSortOrderChanged", function (dataListId) {
                        if (dataListId == listId) {
                            loadList();
                        }
                    });
                    scope.subscribe("DataListItemAdded", function (item) {
                        if (item.dataListId == listId) {
                            loadList();
                        }
                    });
                    scope.subscribe("DataListItemUpdated", function (item) {
                        if (item.dataListId == listId) {
                            loadList();
                        }
                    });
                    scope.subscribe("DataListItemDeleted", function (item) {
                        if (item.dataListId == listId) {
                            loadList();
                        }
                    });

                    scope.selectCategory = function (cat) {
                        scope.selectedCategory = cat;
                    }
                    scope.selectAsset = function (asset) {
                        scope.selectedAsset = asset;
                    }

                    scope.save = function () {
                        scope.isProcessing = true;

                        var utcDate = moment.utc(scope.selectedDate).format();
                        var valueString = scope.value.toString();
                        var registration = {
                            valueDate: utcDate,
                            value: valueString,
                            dimension: scope.selectedCategory.id,
                            assetid: scope.selectedAsset.id,
                            dimension2: userid,
                            description: scope.description
                        };

                        dataService().add(dataSourceId, registration).then(function (savedRegistration) {
                            scope.isProcessing = false;
                            scope.description = "";
                            $translate('NAV_COMPETENCE_BALL_CREATED').then(function (msg) {
                                toastr.success(msg);
                            });
                        });

                    }
                }
            }
        }]);
