angular
    .module('DigiLean')
    .directive("numberSelector", ['$filter', '$translate','dataService',
        function ($filter, $translate, dataService) {
            return {
                templateUrl: 'numberselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.operator1 = "";
                    scope.operator2 = "";

                    scope.valueoperator1 = "";
                    scope.valueoperator2 = "";

                    scope.$watch('source', function () {
                        applyFilter();
                    });

                    scope.operator1option = [
                        {
                            translatedText: $translate.instant('DASHBOARD_LESS_THAN'),
                            dbCode: "LessThan"
                        },{
                            translatedText: $translate.instant('DASHBOARD_GREATER_THAN_OR_EQUAL'), 
                            dbCode: "GreaterThanOrEquals"
                        },
                        {
                            translatedText: $translate.instant('DASHBOARD_GREATER_THAN'),
                            dbCode: "GreaterThan"
                        }
                    ];

                    scope.operator2option = [
                        {
                            translatedText: $translate.instant('DASHBOARD_LESS_THAN_OR_EQUAL'),
                            dbCode: "LessThanOrEquals"
                        },
                        {
                            translatedText: $translate.instant('DASHBOARD_LESS_THAN'),
                            dbCode: "LessThan"
                        },
                        {
                            translatedText: $translate.instant('DASHBOARD_GREATER_THAN'),
                            dbCode: "GreaterThan"
                        }
                    ];

                    scope.clearOperator1 = function(){
                        scope.operator1 = "";
                        scope.valueoperator1 = "";
                        scope.modifyFilter();
                    }

                    scope.clearOperator2 = function(){
                        scope.operator2 = "";
                        scope.valueoperator2 = "";
                        scope.modifyFilter();
                    }

                    function applyFilter(){
                        if(!scope.filters){
                            scope.operator1 = "";
                            scope.operator2 = "";
                        } else {
                            if(scope.filters[0]){
                                scope.operator1 = $filter('filter')(scope.operator1option, { dbCode: scope.filters[0].operator }, true)[0];
                                scope.valueoperator1 = scope.filters[0].items[0];  
                            }
                            if (scope.filters[1]) {
                                scope.operator2 = $filter('filter')(scope.operator2option, { dbCode: scope.filters[1].operator }, true)[0];
                                scope.valueoperator2 = scope.filters[1].items[0];  
                            }
                        }
                    }

                    scope.modifyFilter = function(){
                        if(scope.operator1 !== ""){
                            scope.filters = [{
                                sourceColumn: scope.source,
                                operator: scope.operator1.dbCode,
                                items: [scope.valueoperator1]
                            }];
                            if(scope.operator2 !== ""){
                                var filter2 = {
                                    sourceColumn: scope.source,
                                    operator: scope.operator2.dbCode,
                                    items: [scope.valueoperator2]
                                };
                                scope.filters.push(filter2);
                            }  
                        } else {
                            scope.filters = null;
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                    }
                }
            }
        }]);
