angular
    .module('DigiLean').directive("videoSection", ['$sce', '$translate', 'learningService','debounce',
        function ($sce, $translate, learningService, debounce) {
            return {
                templateUrl: 'videoSection.html',
                restrict: 'E',
                scope: {
                    'section': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.update = function () {
                        learningService().updateSection(scope.section);
                    }
                    scope.trustSrc = function(src) {
                        return $sce.trustAsResourceUrl(src);
                      }
                    scope.videoUrlChanged = function() {
                        // format to embed
                        scope.videoUrl = scope.videoUrl.replace("watch?v=", "embed/")
                        scope.section.url = scope.videoUrl;
                        scope.update(); 
                    }
                    scope.videoUrl = null;
                    scope.$watch("section", function (section) {
                        if (section) {
                            if (section.url) {
                                scope.videoUrl = section.url;
                            }
                        }
                    });
                

                  
                }

            }
        }]);
