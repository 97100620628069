﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('repeatdeleteController', ['$scope', 'boardTaskService', '$uibModalInstance', 'taskId', 'repeatParentId',
  function ($scope, boardTaskService, $uibModalInstance, taskId, repeatParentId) {
      $scope.deleteJustThis = function () {
        
            boardTaskService().deleteTask(taskId).then(function (result) {
              $uibModalInstance.close();
          });
      };
      $scope.deleteFuture = function () {
        boardTaskService().deleteFutureRepeatingTask(taskId).then(function (result) {
          $scope.publish("SendTasksDeleted", result);
          $uibModalInstance.close();
      });
    };
      $scope.deleteAll = function () {
          boardTaskService().deleteRepeatingTask(repeatParentId).then(function (result) {
            $scope.publish("SendTasksDeleted", result);
            $uibModalInstance.close();
        });
      };
      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
  }]);