import angular from "angular"
import { DigiLeanNgScope } from "@common/model/angularModel"
import { A3CreateFromTemplate, A3TemplateInfo, Asset, AssetRoleType, AssetWithRole, UserShortInfo } from "@api"
import {getAllUsers, getUsersForAsset} from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import { AssetChangeInfo } from "@common/model/types"

interface A3Registration {
    assetId: number
    participants: UserShortInfo[]
}
interface A3AssetModel {
    assetId?: number | null
    assets: AssetWithRole[]
}

interface INewA3ModalScope extends DigiLeanNgScope {
    a3: A3CreateFromTemplate
    registration: A3Registration
    projectassetmodel: A3AssetModel
    assetmodel: A3AssetModel
    assets: AssetWithRole[]
    asset: Asset | AssetWithRole | null
    assetId: number | null
    projectassets: AssetWithRole[]
    projectasset: AssetWithRole
    users: UserShortInfo[]
    templates: A3TemplateInfo[]
    template?: A3TemplateInfo | null
    isPublic: boolean
    hasProjectModule: boolean
    useProjectGroup: boolean
    chooseMembers: boolean
    showAssetUsers: boolean
    add: (user: UserShortInfo) => void
    remove: (user: UserShortInfo) => void
    changeUseProjectGroup: () => void
    assetChangeHandler: (assetinfo: AssetChangeInfo) => void
    changeTemplate: (template: A3TemplateInfo) => void
    ok: () => void
    close: () => void
}

angular
    .module('DigiLean')
    .controller("newA3ModalController", ['$scope', '$state', '$uibModalInstance', 'authService', 'a3', 'a3Service', 'a3templateService', 'navigationService',
        function ($scope: INewA3ModalScope, $state, $uibModalInstance, authService, a3, a3Service, a3templateService, navigationService) {

            $scope.a3 = a3
            $scope.a3.hasCustomizedGroup = false
            $scope.asset = null
            $scope.template = null
            let allTemplates: A3TemplateInfo[] = []
            $scope.assets = [];
            $scope.isPublic = true;

            $scope.hasProjectModule = navigationService().hasModule("PROJECT");
            $scope.useProjectGroup = false;
            if (!$scope.hasProjectModule) {
                $scope.useProjectGroup = false;
            }

            $scope.registration = {
                assetId: 0,
                participants: []
            };

            $scope.chooseMembers = true;
            if($scope.a3 && $scope.a3.assetId && $scope.a3.assetId>0){
                $scope.chooseMembers = false;
            } else {
                getAllUsers().then(function (users) {
                    $scope.users = users
                    var userid = authService.getCurrentUser().userId
                    var matchingUser = $scope.users.find(u => u.userId === userid)
                    if (matchingUser) {
                        $scope.registration.participants.push(matchingUser)
                    }
                })
    
                assetService.getList().then(function (assets) {
                    $scope.assets = assets;
        
                    if ($scope.a3.assetId) {
                        var match = $scope.assets.find(a => a.id == $scope.a3.assetId)
                        if (match) {
                            $scope.asset = match
                        }
                    }
                    for (var k = 0; k < $scope.assets.length; k++) {
                        ///@ts-ignore
                        $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                    }
                    $scope.assetmodel = {
                        assetId: $scope.a3.assetId,
                        assets: $scope.assets
                    }
                })
                assetService.getMyProjectAssets().then(function (assets) {
                    $scope.projectassets = assets
                    let projectAssetId: number | null = null
                    if ($scope.projectasset)
                        projectAssetId = $scope.projectasset.id!
                    $scope.projectassetmodel = {
                        assetId: projectAssetId,
                        assets: $scope.projectassets
                    }
                })
            }

            
            a3templateService().getList().then(function (templates) {
                $scope.templates = templates
                allTemplates = templates
                if ($scope.a3.a3TemplateId) {
                    const match = $scope.templates.find(t => t.id == $scope.a3.a3TemplateId)
                    if (match) {
                        $scope.template = match
                    }
                }
            })
            $scope.changeUseProjectGroup = function () {
                $scope.asset = null
                $scope.assetId = null
                $scope.a3.hasCustomizedGroup = false
                $scope.showAssetUsers = false
            };
            $scope.assetChangeHandler = function (assetinfo) {
                if (assetinfo) {
                    const asset = assetinfo.asset;
                    $scope.asset = asset;
                    $scope.showAssetUsers = true;
                    getUsersForAsset(asset.id!).then(function (users) {
                        const owner = users.filter(u => u.assetRole == AssetRoleType.Owner)
                        const members = users.filter(u => u.assetRole == AssetRoleType.Member)
                        if ($scope.asset) {
                            const asset = $scope.asset as Asset
                            asset.users = members
                            if (owner.length > 0) {
                                asset.users = asset.users.concat(owner)
                            }
                        }
                    })

                    $scope.templates = allTemplates
                    const match = $scope.templates.filter(t => t.assetId == asset.id)
                    if (match.length > 0) {
                        $scope.templates = match;

                        const noAssetTemplate = allTemplates.filter(t => !t.assetId)
                        if (noAssetTemplate.length > 0) {
                            for (let t = 0; t < noAssetTemplate.length; t++) {
                                $scope.templates.push(noAssetTemplate[t])
                            }
                        }
                    } else {
                        const noAssetTemplate = allTemplates.filter(t => !t.assetId)
                        if (noAssetTemplate.length > 0) {
                            $scope.templates = noAssetTemplate
                        }
                    }
                }
            }

            $scope.changeTemplate = function (template) {
                if (template.assetId && $scope.chooseMembers) {
                    const match = $scope.assets.find(a => a.id == template.assetId)
                    if (match) {
                        $scope.asset = match
                    }
                }
            }

            $scope.add = function (user) {
                const existUser = $scope.registration.participants.filter(p => p.userId == user.userId)
                if (existUser.length == 0) {
                    $scope.registration.participants.unshift(user)
                }
                
                const matchingUser = $scope.users.find(u => u.userId == user.userId)
                if (matchingUser) {
                    const i = $scope.users.indexOf(matchingUser)
                    $scope.users.splice(i, 1)
                }
            };

            $scope.remove = function (user) {
                $scope.users.unshift(user);

                var matchingUser = $scope.registration.participants.find(p => p.userId == user.userId)
                if (matchingUser) {
                    const i = $scope.registration.participants.indexOf(matchingUser)
                    $scope.registration.participants.splice(i, 1)
                }
            }

            $scope.ok = function () {
                // Get asset
                $scope.a3.isPrivate = !$scope.isPublic;
                if ($scope.a3.hasCustomizedGroup) {
                    $scope.a3.participants = $scope.registration.participants;
                } else {
                    if ($scope.asset) {
                        $scope.a3.assetId = $scope.asset.id;
                    } else if(!$scope.a3.assetId){
                        //No users selected => set creator as participant
                        const currentuserid = authService.getCurrentUser().userId
                        const matchingUser = $scope.users.find(u => u.userId == currentuserid)
                        if (matchingUser) {
                            $scope.a3.participants = [matchingUser]
                            $scope.a3.hasCustomizedGroup = true
                        }
                    }
                }

                if ($scope.template) {
                    $scope.a3.a3TemplateId = $scope.template.id;
                    
                    a3Service().createA3FromTemplate($scope.a3).then(function (savedA3) {
                        $state.go('a3wizard', { id: savedA3.id }, { reload: false });
                        $scope.publish("SendA3Created", savedA3);
                        $uibModalInstance.close(savedA3);
                    });
                } else {
                    a3Service().addA3($scope.a3).then(function (savedA3) {
                        $state.go('a3wizard', { id: savedA3.id }, { reload: false });
                        $scope.publish("SendA3Created", savedA3);
                        $uibModalInstance.close(savedA3);
                    });
                }
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
