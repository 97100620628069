import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('fullDeviationController', ['$scope', '$filter', '$uibModal', '$uibModalInstance', '$translate', 'authService', 'navigationService', 'areaService', 'activityService', 'deviationService', 'deviation', 'modalService', 'actionlistService', 'projectService', 'pdfService', 'activeTab', 'employeeService', 'dataListService', 'isTeamsMode',
    function ($scope, $filter, $uibModal, $uibModalInstance, $translate, authService, navigationService, areaService, activityService, deviationService, deviation, modalService, actionlistService, projectService, pdfService, activeTab, employeeService, dataListService, isTeamsMode) {
        $scope.deviation = deviation;
        $scope.assets = [];
        $scope.areas = [];
        $scope.projects = [];
        deviation.asset = null;

        $scope.showFileGenerators = true;
        if (isTeamsMode) {
            $scope.showFileGenerators = false;
        }

        $scope.showAttachments = false;
        $scope.changedDueDate = false;
        $scope.selectedReportedAsset = null;
        $scope.selectedAsset = null;
        $scope.selectedArea = null;
        var activityLogLoaded = false;
        var currentSeverity = deviation.severity;
        var oldDueDate = deviation.dueDate;
        var consequencesLoaded = false;
        $scope.mytime = null;
        $scope.hstep = 1;
        $scope.mstep = 5;
        $scope.options = {
            hstep: [1, 2, 3],
            mstep: [1, 5, 10, 15, 25, 30]
        };
        $scope.showTime = false;
        $scope.showArea = false;
        $scope.showProject = false;

        $scope.customfields = [];
        var customFieldChanged = false;

        // Seleted categories
        var selectedCategories = [];
        for (let index = 0; index < deviation.categories.length; index++) {
            const element = deviation.categories[index];
            selectedCategories.push(element.problemCategory);
        }
        $scope.selectedCategories = selectedCategories;

        var currency = customerService.getDefaultCurrency()
        getCompanySetting("currency").then(function (setting) {
            if (setting) {
                currency = setting
            }
        });


        // Default check of modules. Will work unless page is refreshed 
        $scope.hasA3 = navigationService().hasModule("A3");

        // if page is refreshed we need to refresh the access to modules
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasA3 = navigationService().hasModule("A3");
        });

        $scope.canEdit = false;
        // Check if user is allowed to edit
        deviationService().canEdit(deviation.id).then(function (canEdit) {
            $scope.canEdit = canEdit;
        });

        function setSeverityDescription(){
            if($scope.severities){
                for(var i=0; i< $scope.severities.length; i++){
                    if($scope.severities[i].severity == 0){
                        $scope.severityNotSpecifiedDescription = $scope.severities[i].customLanguageLabel.label;
                    } else if($scope.severities[i].severity == 1){
                        $scope.severityLowDescription = $scope.severities[i].customLanguageLabel.label;
                    } else if($scope.severities[i].severity == 2){
                        $scope.severityMediumDescription = $scope.severities[i].customLanguageLabel.label;
                    } else if($scope.severities[i].severity == 3){
                        $scope.severityHighDescription = $scope.severities[i].customLanguageLabel.label;
                    }
                }    
            }
        }

        var deviationTypeId = $scope.deviation.deviationTypeId;
        deviationService().getDeviationTypeConfiguration(deviationTypeId).then(function (configuration) {
            $scope.configuration = configuration;
            $scope.deviationtypeTitle = configuration.title;
            checkFields($scope.configuration.fields);
            setCategories($scope.configuration.categories);
            setConsequences($scope.configuration.consequences);
            setProblemReasons($scope.configuration.causes);
            $scope.severities = $scope.configuration.severities;
            setSeverityDescription();
            if($scope.deviation){
                $scope.changeSeverity($scope.deviation.severity);
            }
        });

        $scope.descriptionChanged = function (content) {
            $scope.deviation.text = content;
        }

        $scope.archive = function () {
            deviationService().archive(deviation.id).then(function () {
                deviation.archivedDate = moment().toDate();
                $uibModalInstance.close($scope.deviation);
            });
        };

        $scope.unArchive = function () {
            deviationService().unArchive(deviation.id).then(function () {
                deviation.archivedDate = null;
                $uibModalInstance.close($scope.deviation);
            });
        };

        $scope.print = function () {
            pdfService().printDeviationReport($scope.deviation.id);
        };

        function checkFields(validFields) {
            if (validFields) {
                var hasCustomFields = false;
                for (var f = 0; f < validFields.length; f++) {
                    switch (validFields[f].type) {
                        case "PROJECT":
                            loadProjects();
                            $scope.showProject = true;
                            break;
                        case "AREA":
                            loadAreas();
                            $scope.showArea = true;
                            break;
                        case "DEVIATION_INCIDENT_TIME":
                            $scope.showTime = true;
                            $scope.mytime = moment($scope.deviation.incidentDate).toDate();
                            break;
                        default:
                            $scope.customfields.push(validFields[f]);
                            hasCustomFields = true;
                    }
                }
                if (hasCustomFields) {
                    setCustomFieldValues(validFields)
                }
            }
        }

        $scope.modifyCustomField = function () {
            customFieldChanged = true;
        };

        function setCustomFieldValues() {

            deviationService().getCustomFields($scope.deviation.id).then(function (values) {

                $scope.customfields.forEach(field => {
                    var match = $filter('filter')(values, { deviationTypeFieldId: field.id }, true);
                    if (match.length > 0) {
                        var value = match[0];
                        switch (field.type) {
                            case "bool":
                                if (value && value.value == "true") {
                                    field.value = true;
                                } else {
                                    field.value = false;
                                }
                                break;
                            case "number":
                                if (value && value.value) {
                                    field.value = Number(value.value);   //both number and double will be handled             
                                }
                                break;
                            case "list":
                                setCustomList(field, value.value);
                                break;
                            default:
                                field.value = value.value;
                                break;
                        }
                    } else { // If no value, make sure we load the list
                        switch (field.type) {
                            case "list":
                                setCustomList(field, null);
                                break;
                           
                        }
                    }
                });
            });

        }

        function setCustomList(field, listid) {
            var listOptions = { includeDeletedItems: false };
            dataListService().getItems(field.dataListId, listOptions).then(function (list) {
                field.datalistelements = list;
                field.value = listid;
                if (listid) {
                    var listelement = $filter('filter')(field.datalistelements, { id: parseInt(listid) }, true);
                    field.selectedDataListItem = listelement[0];
                }
            });
        }



        $scope.selectListItem = function (selectedDataListItem, item) {
            item.value = selectedDataListItem.id.toString();
            customFieldChanged = true;
        }

        function saveCustomFieldsValues() {
            if ($scope.customfields.length > 0) {
                deviationService().updateCustomFields($scope.deviation.id, $scope.customfields).then(function (result) {
                });
            }
        }


        $scope.subscribe("DeviationActionItemAdded", function (item) {
            if (item.deviationId && item.deviationId === $scope.deviation.id) {
                $scope.deviation.actionListCount = $scope.deviation.actionListCount + 1;
            }
        });


        // set color based on severity
        var severityColors = ["#c0c0c0", "#1872ab", "#FFCE54", "#DA4453"];
        $scope.severityLowDescription = "";
        $scope.severityMediumDescription = "";
        $scope.severityHighDescription = "";
        $scope.severityNotSpecifiedDescription = "";
        $scope.background = severityColors[$scope.deviation.severity];

        $scope.changeSeverity = function (severity) {
            $scope.deviation.severity = severity;
            $scope.background = severityColors[$scope.deviation.severity];
            $scope.severityDescription = "";
            if($scope.severities){
                var selectedSeverity = $filter('filter')($scope.severities, { severity: severity }, true)[0];
                if(selectedSeverity){
                    $scope.severityDescription = selectedSeverity.customLanguageLabel.label;
                }         
            }
        };

        $scope.statuses = [];
        $scope.deviation.selectedStatus = []; 
        $scope.activityLog = [];
        //Modified propertie flag
        $scope.statusChanged = false;
        $scope.problemCategoryChanged = false;
        $scope.problemReasonChanged = false;
        var consequencesChanged = false;
        $scope.selectedOwner = {};

        var newResponsible = deviation.owner;
        var newResponsibleUserId = deviation.ownerUserId;
        var oldResponsible = deviation.owner;
        var oldResponsibleUserId = deviation.ownerUserId;

        $scope.setTime = function (time) {
            $scope.deviation.incidentDate.setHours(time.getHours());
            $scope.deviation.incidentDate.setMinutes(time.getMinutes());
        };

        // File upload
        $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
        $scope.fileUploaded = function (file) {
            deviationService().addAttachment($scope.deviation.id, file).then(function (improvementAttachment) {
                improvementAttachment.cssState = "animated slideInDown";
                setTimeout(function () {
                    improvementAttachment.cssState = "";
                }, 500);
                $scope.deviation.attachments.push(improvementAttachment);
            });
        };

        // Resolve active tab
        var tabs = ["Info", "Action", "Categories", "Consequence", "Cause", "Evaluation", "Comments", "History"];
        if (activeTab == "Attachments") {
            $scope.showAttachments = true;
        } else {
            $scope.indexTab = tabs.indexOf(activeTab);
        }
        $scope.selectTab = function (tab) {
            $scope.indexTab = tabs.indexOf(tab);
            if (tab === "History") {
                getActivityLog();
            } /*else if (tab === "Comments") {
                sortCommentOrder();
            }  */
        };
        // Attachments

        $scope.toggleShowAttachments = function (show) {
            $scope.showAttachments = show;
        };

        $scope.deleteAttachment = function (attachment) {
            deviationService().deleteAttachment($scope.deviation.id, attachment.id).then(function (improvementAttachment) {
                // Send event
                attachment.cssState = "animated slideOutLeft";
                setTimeout(function () {
                    var index = $scope.deviation.attachments.indexOf(attachment);
                    $scope.deviation.attachments.splice(index, 1);
                }, 500);
            });
        };

        // Datepicker setup
        $scope.deviation.incidentDate = moment($scope.deviation.incidentDate).toDate();
        $scope.incidentDateChanged = function($event) {
            const date = $event.originalEvent.detail
            $scope.deviation.incidentDate = date
        }

        if ($scope.deviation.dueDate) {
            $scope.deviation.dueDate = moment($scope.deviation.dueDate).toDate();
        }
        $scope.datepickerDueDateOpen = function () {
            $scope.datePickerDueDate.opened = true;
        };
        $scope.datePickerDueDate = {
            opened: false
        };

        $scope.isEvaluated = false;
        if ($scope.deviation.statusEvaluatedDate) {
            $scope.isEvaluated = true;
        }
        function loadProjects() {

            projectService().getList().then(function (projects) {
                $scope.projects = projects;
                $scope.projectmodel = {
                    projectId: $scope.deviation.projectId,
                    projects: $scope.projects
                }
            });

        }
        $scope.projectChangeHandler = function (projectinfo) {
            if (projectinfo) {
                $scope.deviation.projectId = projectinfo.project.id;
            } else {
                $scope.deviation.projectId = null;
            }
        }

        function setConsequences(consequences) {
            $scope.consequences = consequences;
            consequencesLoaded = true;
            // Map id to correct type
            for (var i = 0; i < $scope.deviation.consequences.length; i++) {
                var registeredconsequence = $scope.deviation.consequences[i];
                var match = $filter('filter')(consequences, { id: registeredconsequence.lossTypeId }, true);
                if (match.length === 1) {
                    match[0].checked = true;
                    match[0].amount = $scope.deviation.consequences[i].amount;
                } 
            }
        }

        $scope.modifyConsequence = function (consequence) {
            consequencesChanged = true;
            if ($scope.deviation.consequences) {
                var match = $filter('filter')($scope.deviation.consequences, { lossTypeId: consequence.id }, true);

                if (match.length === 1) {
                    if (consequence.checked) {
                        match[0].amount = consequence.amount;
                    } else {
                        consequence.amount = null;
                        var index = $scope.deviation.consequences.indexOf(match[0]);
                        $scope.deviation.consequences.splice(index);
                    }
                } else { //consequences is not allready selected
                    consequence.lossTypeId = consequence.id;
                    $scope.deviation.consequences.push(consequence);
                }

            } else {
                $scope.deviation.consequences = [];
                consequence.lossTypeId = consequence.id;
                $scope.deviation.consequences.push(consequence);
            }
        };

        $scope.setConsequence = function (consequence) {
            if (consequence.amount) {
                consequence.checked = true;

                var valueString = consequence.amount.toString();
                var valuereplacecomma = valueString.replace(/,/g, '.');
                consequence.amount = valuereplacecomma;
                if (consequence.amount == '' || Number(consequence.amount)) {
                    consequence.notvalidnumber = false;
                    $scope.modifyConsequence(consequence);
                } else {
                    consequence.notvalidnumber = true;
                }
            } else {
                consequence.notvalidnumber = false;
            }
        };

        function setupAsset(assets, displayLevel){
            $scope.assets = assets;
            var selectedReportedAssets = $filter('filter')($scope.assets, { id: $scope.deviation.assetId }, true);
            if (selectedReportedAssets.length > 0) {
                $scope.selectedReportedAsset = selectedReportedAssets[0];
            }
            var selectedAssets = $filter('filter')($scope.assets, { id: $scope.deviation.followUpAssetId }, true);
            if (selectedAssets.length > 0) {
                $scope.selectedAsset = selectedAssets[0];
            }
            if(displayLevel){
                for (var k = 0; k < $scope.assets.length; k++) {
                    $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                }
            }  
            $scope.assetmodel = { assetId: $scope.deviation.followUpAssetId,
                assets: $scope.assets};
            $scope.reportedassetmodel = { assetId: $scope.deviation.assetId,
                    assets: $scope.assets};
        }

        function getAssetList() {
            if (authService.hasRole("Admin") || authService.hasRole("DeviationAdmin")) {
                assetService.getAllAssets(true).then(function (data) {
                    setupAsset(data, true);
                });
            } else {
                assetService.getList().then(function (data) {
                    var showLevel = false;
                    if (data && data.length>7) showLevel=true;
                    setupAsset(data, showLevel);
                });
            }
        };
        getAssetList();

        function loadAreas() {
            areaService().getList().then(function (areas) {
                $scope.areas = areas;
                if ($scope.deviation.areaId) {
                    var match = $filter('filter')($scope.areas, { id: $scope.deviation.areaId }, true);
                    if (match.length > 0) {
                        $scope.selectedArea = match[0];
                    }
                }
            });
        }

        $scope.members = [];
        function getUsers() {
            if ($scope.deviation.followUpAssetId) {
                customerService.getUsersForAsset($scope.deviation.followUpAssetId).then(function (users) {
                    $scope.members = users.filter(u => u.assetRole !== 'Viewer')
                    $scope.selectedOwner = {};
                    if ($scope.deviation.ownerUserId) {
                        $scope.selectedOwner = $filter('filter')($scope.members, { userId: $scope.deviation.ownerUserId })[0];
                    }
                });
            }
        }
        getUsers();

        $scope.projectChanged = function (project) {
            $scope.deviation.projectId = project.id;
        };

        $scope.reportedAssetChangeHandler = function (assetinfo) {
            if (assetinfo) {
                $scope.deviation.assetId = assetinfo.asset.id;
                $scope.selectedReportedAsset = assetinfo.asset;
            }       
        }

        $scope.assetChangeHandler = function (assetinfo) {
            if (assetinfo) {
                $scope.deviation.followUpAssetId = assetinfo.asset.id;
                $scope.selectedAsset = assetinfo.asset;
                getUsers();
            }       
        }

        $scope.areaChanged = function (area) {
            $scope.deviation.areaId = area.id;
            $scope.deviation.area = area;
        };

        $scope.selectedOwnerChanged = function (owner) {
            if (owner) {
                $scope.deviation.ownerUserId = owner.userId;
                $scope.deviation.owner = owner.fullName;
                $scope.deviation.ownerDisplayName = owner.displayName;
                newResponsible = owner.fullName;
                newResponsibleUserId = owner.userId;
            }
        };

        function setCategories(categories) {
            $scope.categories = categories;
        }

        function setProblemReasons(reasons) {
            $scope.problemReasons = reasons;
            if ($scope.deviation.problemReasons.length > 0) {
                for (var i = 0; i < $scope.deviation.problemReasons.length; i++) {
                    var reason = $filter('filter')($scope.problemReasons, { id: $scope.deviation.problemReasons[i] }, true);
                    if (reason.length > 0) {
                        reason[0].checked = true;
                    }
                }
            }
        }

        function translateStatuses() {
            $translate(['COMMON_NEW', 'DEVIATION_IN_PROGRESS', 'DEVIATION_RESOLVED', 'DEVIATION_RESOLVED', 'COMMON_REJECTED']).then(function (translations) {
                $scope.statuses = [
                    {
                        statusDisplayText: translations.COMMON_NEW,
                        statusDatabase: 0
                    },
                    {
                        statusDisplayText: translations.DEVIATION_IN_PROGRESS,
                        statusDatabase: 10
                    },
                    {
                        statusDisplayText: translations.DEVIATION_RESOLVED,
                        statusDatabase: 20
                    },
                    {
                        statusDisplayText: translations.COMMON_REJECTED,
                        statusDatabase: 30
                    }];
                setSelectedStatus();
            });

        }

        function setSelectedStatus() {
            $scope.deviation.selectedStatus = $filter('filter')($scope.statuses, { statusDatabase: deviation.status }, true)[0];
        }
        translateStatuses();

        $scope.modifyReason = function (reason) {
            if ($scope.deviation.problemReasons) {
                var i = $scope.deviation.problemReasons.indexOf(reason.id);
                if (i == -1) { //reason is not allready selected
                    $scope.deviation.problemReasons.push(reason.id);
                } else {
                    $scope.deviation.problemReasons.splice(i);
                }

            } else {
                $scope.deviation.problemReasons = [];
                $scope.deviation.problemReasons.push(reason.id);
            }
            $scope.problemReasonChanged = true;
        };

        // History
        function getActivityLog() {
            if (!activityLogLoaded) {
                var activityRequest = {
                    activity: "Deviation",
                    activityId: $scope.deviation.id
                };
                var $translate = $filter('translate');
                var translateOptions = {
                    month: $translate('COMMON_TIMEELAPSED_MONTH'),
                    days: $translate('COMMON_TIMEELAPSED_DAYS'),
                    hours: $translate('COMMON_TIMEELAPSED_HOURS'),
                    mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
                    seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
                    now: $translate('COMMON_TIMEELAPSED_NOW')
                };
                activityService().getLogForActivity(activityRequest).then(function (logItems) {
                    logItems.forEach(function (activity) {
                        activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                        if (activity.payLoad && typeof activity.payLoad === "string") {
                            activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                            if(activity.action == 'DeviationCategoriesChanged') {
                                setCategoryActivityPayload(activity.payLoad);
                            }
                        }
                    });
                    $scope.activityLog = logItems;
                    activityLogLoaded = true;
                });
            }
        }

        function setCategoryActivityPayload(payload){
            if(payload && payload.Categories && $scope.categories){
                payload.Categories.forEach(function (category) {
                    for(var i=0; i< $scope.categories.length; i++){
                        if($scope.categories[i].id == category.ProblemCategoryId){
                            category.ProblemCategory = $scope.categories[i];
                        } else if ($scope.categories[i].children && $scope.categories[i].children.length > 0){
                            for(var y=0; y< $scope.categories[i].children.length; y++){
                                if($scope.categories[i].children[y].id == category.ProblemCategoryId){
                                    category.ProblemCategory = $scope.categories[i].children[y];
                                    break;
                                } 
                            }
                        }
                    }
                    //category.ProblemCategory = $filter('filter')($scope.categories, { id: category.ProblemCategoryId }, true)[0];
                });
            }    
        }

        $scope.activeComment = "";
        $scope.activeCommentChanged = function (value) {
            $scope.hasAddedComments = true;
            $scope.activeComment = value;
        };

        $scope.setStatusChanged = function (newStatus) {
            if (newStatus.statusDatabase !== 20) { //Status different to solved
                $scope.statusChanged = true;
                $scope.deviation.status = newStatus.statusDatabase;
            } else {
                // Get actionlist
                actionlistService().getActionListForDeviation($scope.deviation.id).then(function (items) {
                    if (items && items.length > 0) {
                        var notCompletedActionItems = false;
                        for (var a = 0; a < items.length; a++) {
                            if (items[a].status !== 'OK') {
                                notCompletedActionItems = true;
                            }
                        }
                        if (notCompletedActionItems) {
                            $translate(['COMMON_CANCEL', 'DASHBOARD_CHANGE_STATUS', 'COMMON_CHANGE_STATUS', 'DEVIATION_CHANGE_STATUS_CONFIRMATION']).then(function (translations) {
                                var modalOptions = {
                                    closeButtonText: translations.COMMON_CANCEL,
                                    actionButtonText: translations.COMMON_CHANGE_STATUS,
                                    headerText: translations.DASHBOARD_CHANGE_STATUS + ' ' + $scope.deviation.selectedStatus.statusDisplayText + '?',
                                    bodyText: translations.DEVIATION_CHANGE_STATUS_CONFIRMATION
                                };

                                modalService.show({}, modalOptions).then(function (result) {
                                    $scope.statusChanged = true;
                                    $scope.deviation.status = newStatus.statusDatabase;
                                    $scope.deviation.selectedStatus = newStatus;
                                });

                                if (!$scope.statusChanged) {
                                    $scope.deviation.selectedStatus = $filter('filter')($scope.statuses, { statusDatabase: deviation.status }, true)[0];
                                }
                            });
                        }
                        else {
                            $scope.statusChanged = true;
                            $scope.deviation.status = newStatus.statusDatabase;
                        }
                    } else {
                        $scope.statusChanged = true;
                        $scope.deviation.status = newStatus.statusDatabase;
                    }
                });
            }
        };

        $scope.dueDateChanged = function ($event) {
            const date = $event.originalEvent.detail
            $scope.deviation.dueDate = date
            $scope.changedDueDate = true
        }


        $scope.toA3 = function () {
            var assetId = "";
            if ($scope.deviation.assetId) {
                assetId = $scope.deviation.assetId;
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'newA3Form.html',
                controller: 'newA3ModalController',
                resolve: {
                    a3: function () {
                        return {
                            id: 0,
                            title: $scope.deviation.title,
                            assetId: assetId,
                            deviationId: $scope.deviation.id,
                            goal: "",
                        }
                    }
                }
            });
            return modalInstance.result.then(function (a3) {
                $scope.publish("SendDeviationActivatedtoA3", $scope.deviation);
            }
            );
        };

        $scope.deleteConsequence = function (consequence, index) {
            $scope.deviation.consequences.splice(index, 1);
            consequencesChanged = true;
        };

        $scope.consequenceChanged = function (consequence) {
            consequence.lossTypeId = consequence.type.id;
            consequence.lossType = $filter('filter')($scope.consequences, { id: consequence.lossTypeId }, true)[0];
            consequencesChanged = true;
        };

        $scope.createNewConsequence = function () {
            var newConsequence = {
                amount: null,
                unit: currency
            };
            if ($scope.deviation.consequences) {
                $scope.deviation.consequences.push(newConsequence);
            } else {
                var consequenceArray = [];
                $scope.deviation.consequences = consequenceArray;
                $scope.deviation.consequences.push(newConsequence);
            }
        };

        employeeService().getList().then(function (data) {
            $scope.employees = data;
        });

        $scope.comments = [];
        deviationService().getComments(deviation.id).then(function (deviationComments) {
            $scope.totalComments = deviationComments.total;
            $scope.comments = deviationComments.comments;
        });

        $scope.subscribe("DeviationCommentedOn", function (c) {
            $scope.totalComments += 1;
        });
        $scope.saveComment = function (comment) {
            return deviationService().addComment(deviation.id, comment);
        };
        $scope.showAddComment = true;
        $scope.addComment = function (comment) {
            return $scope.saveComment(comment).then(function (savedComment) {
                $scope.comments.push(savedComment);
            });
        };
        $scope.deleteComment = function (comment) {
            return deviationService().deleteComment(deviation.id, comment.id).then(function () {
                $scope.totalComments -= 1;
            });
        };

        $scope.setEvaluationStatus = function (status) {
            $scope.deviation.evaluationStatus = status;
            $scope.deviation.statusEvaluatedDate = moment().toDate();
            $scope.isEvaluated = true;
        };

        $scope.setEvaluationTextChanged = function (text) {
            $scope.deviation.statusEvaluatedDate = moment().toDate();
            $scope.isEvaluated = true;
        };


        $scope.$watchCollection('selectedCategories', function (newCol, oldCol, scope) {
            if (newCol.length != oldCol.length) {
                $scope.validate();
            }
        });

        $scope.isValid = true;
        $scope.validationMessages = [];
        $scope.validate = function () {
            // Rules
            $scope.validationMessages = [];
            // Has categories
            if ($scope.selectedCategories.length == 0) {
                $scope.validationMessages.push($translate.instant("DEVIATION_VALIDATION_CATEGORIES_REQUIRED"));
            }
            // // If deviation closed, we need an evaluation test
            // if ($scope.isResolved && $scope.deviation.evaluationText.length == 0) {
            //     // evaluationText
            //     // statusDisplayText: translations.DEVIATION_RESOLVED,
            //     // statusDatabase: 20
            //     $scope.validationMessages.push($translate.instant("DEVIATION_VALIDATION_EVALUATION_TEXT_REQUIRED"));
            // }

            if ($scope.validationMessages.length > 0) {
                $scope.isValid = false;
            } else {
                $scope.isValid = true;
            }
            return $scope.isValid;
        };

        $scope.ok = function () {
            if (!$scope.validate()) return;


            // Categories
            // Check if deviation categories matches selectedCategories 
            $scope.deviation.categories = [];
            for (let index = 0; index < $scope.selectedCategories.length; index++) {
                const element = $scope.selectedCategories[index];
                $scope.deviation.categories.push({
                    deviationId: deviation.id,
                    problemCategoryId: element.id
                });
            }

            deviationService().updateDeviation($scope.deviation).then(function (deviation) {
                if ($scope.statusChanged) {
                    $scope.statusChanged = false;
                }

                if ($scope.problemReasonChanged) {

                    var selectedProblemReasons = [];
                    if ($scope.deviation.problemReasons.length > 0) {
                        for (var i = 0; i < $scope.deviation.problemReasons.length; i++) {
                            var reason = $filter('filter')($scope.problemReasons, { id: $scope.deviation.problemReasons[i] }, true);
                            if (reason.length > 0) {
                                selectedProblemReasons.push(reason[0]);
                            }
                        }
                    }
                    var problemreasons = {
                        deviationId: deviation.id,
                        currentProblemReasons: selectedProblemReasons
                    }
                    $scope.publish("SendDeviationProblemReasonChanged", problemreasons);
                }

                if (consequencesChanged) {
                    var consequences = {
                        deviationId: deviation.id,
                        consequences: $scope.deviation.consequences
                    }
                    deviationService().updateConsequences(consequences).then(function (result) {
                        consequencesChanged = false;
                    });

                }

                var newSeverity = parseInt($scope.deviation.severity);
                if (currentSeverity !== newSeverity) {
                    var severity = {
                        deviationId: deviation.id,
                        oldSeverity: currentSeverity,
                        newSeverity: newSeverity
                    };
                    $scope.publish("SendDeviationSeverityChanged", severity);
                }

                saveCustomFieldsValues();

                $scope.deviation = deviation;
                $uibModalInstance.close($scope.deviation);
            });

        };

        $scope.deleteDeviation = function (deviation) {
            var id = deviation.id;
            deviationService().delete(id).then(function (result) {
                if (result.affectedTaskIds && result.affectedTaskIds.length > 0) {
                    $scope.publish("SendTasksDeleted", result.affectedTaskIds);
                }

                $uibModalInstance.close($scope.deviation);
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);