import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("smarttableTextCell", ['$filter', 'debounce', '$uibModal', 'projectService', 'smartcellService',
        function ($filter, debounce, $uibModal, projectService, smartcellService) {
            return {
                templateUrl: 'smarttableTextCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'adminmode': '='
                },
                link: function (scope, elem, attrs) {
                    scope.text = "";
                    scope.color = "#676a6c";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    scope.isCloned = false;
                    var clonedNameCell = null;
                    var cloneProjectId = null;
                    scope.boardId = null;
                    scope.clonedBoardId = null;

                    scope.usePredefinedLabels = false;
                    scope.labels = [];
                    var columnId = null;

                    scope.$watch('model', function (model) {
                        var value = model.cell.value;
                        columnId = model.column.id;
                        scope.boardId = model.column.boardId;
                        var settings = model.column.settings;

                        if (value) {
                            setValue(value);
                            if (value.smartCloneId) {
                                scope.smartCloneId = value.smartCloneId;
                                cloneProjectId = value.cloneProjectId;
                                //clonedNameCell = value.clonedNameCell;
                                scope.isCloned = true;
                                getClonedValue();
                            }
                        }
                        if (settings) {
                            if (settings.usePredefinedLabels && settings.predefinedLabels) {
                                scope.labels = settings.predefinedLabels;
                                scope.usePredefinedLabels = true;
                            }
                        }
                        model.getSortValue = function () {
                            if (scope.usePredefinedLabels && scope.labels) {
                                var order = scope.labels.length;
                                if(scope.selectedPostIt){
                                    var match = scope.labels.find(a => a.title == scope.text || a.text == scope.text)
                                    if (match) {
                                        order = scope.labels.indexOf(match);
                                    }
                                }
                                return order;
                            }
                            return scope.text;
                        }
                    });

                    function getClonedValue() {
                        smartcellService().get(scope.smartCloneId).then(function (result) {
                            clonedNameCell = result.name;
                            setValue(JSON.parse(result.value));
                        });
                    }

                    scope.autofocus = function () {
                        setTimeout(function () {
                            //document.querySelector( "input" ).focus();
                            $(".project-text-cell").focus();
                        }, 50);
                    }

                    var setValue = function (value) {
                        scope.background = value.background;
                        scope.text = value.text;
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                        if (scope.isCloned) {
                            if (value.boardId) {
                                scope.clonedBoardId = value.boardId;
                            }
                            if (value.labels) {
                                scope.usePredefinedLabels = true;
                                scope.labels = value.labels;
                            } else {
                                scope.usePredefinedLabels = false;
                            }
                        }
                    }

                    scope.subscribe("SmartTextCellLabelUpdated", function (value) {
                        if (columnId === value.columnId) {
                            scope.labels = value.predefinedLabels;
                            scope.usePredefinedLabels = value.usePredefinedLabels
                        }
                    });

                    scope.subscribe("SmartClonedCellChanged", function (cell) {
                        if (scope.isCloned && cell.id == scope.smartCloneId) {
                            if (cell.value) {
                                setTimeout(function(){
                                    setValue(JSON.parse(cell.value));
                                },50);
                            }
                        }
                    });

                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        }
                    });
                    scope.selectTextAndColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default') {
                            scope.background = "inherit";
                            scope.color = "#676a6c";
                        } else {
                            scope.background = postIt.background;
                            scope.color = postIt.color;
                            scope.text = postIt.title;
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default') {
                            scope.background = "inherit";
                            scope.color = "#676a6c";
                        } else {
                            scope.background = postIt.background;
                            scope.color = "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        console.log("text changed: " + text);
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }

                    scope.removeClone = function () {
                        scope.smartCloneId = null;
                        scope.isCloned = false;
                        scope.saveChanges();
                    }

                    function openConfigurationModal(projectId) {
                        var settings = {
                            projectId: projectId,
                            visualizationType: "text",
                            smartCloneId: scope.smartCloneId,
                            smartCloneName: clonedNameCell

                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'cellCloneConfigForm.html',
                            controller: 'cellCloneConfigController',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            scope.smartCloneId = result.id;
                            cloneProjectId = result.projectId;
                            if (result.name) {
                                scope.isCloned = true;
                                clonedNameCell = result.name;
                                var existingCellValue = JSON.parse(result.value);
                                if (existingCellValue) {
                                    setValue(existingCellValue);
                                }
                                if (scope.labels) {
                                    scope.usePredefinedLabels = true;
                                }
                                scope.saveChanges();
                            }
                        }, function () {
                            //scope.isCloned = false;
                            //scope.saveChanges();
                        });
                    }

                    scope.openConfiguration = function () {
                        if(cloneProjectId) {
                            openConfigurationModal(cloneProjectId);
                        }
                        if (scope.projectId) {
                            openConfigurationModal(scope.projectId);
                        } else {
                            boardService.getProjectForBoard(scope.model.column.boardId).then(function (projectlist) {
                                if (projectlist && projectlist.length > 0) {
                                    return openConfigurationModal(projectlist[0].id);
                                } else {
                                    openConfigurationModal(null);
                                }
                            });
                        }
                    };

                    function updateCloneValue() {
                        if (clonedNameCell && scope.isCloned) {
                            var value = {
                                background: scope.background,
                                color: scope.color,
                                text: scope.text,
                                boardId: scope.boardId
                            };

                           
                            if (scope.labels) {
                                scope.usePredefinedLabels = true;
                                value.labels = scope.labels
                            }

                            var smartCloneCellValue = {
                                id: scope.smartCloneId,
                                value: value,
                                name: clonedNameCell,
                                type: "text",
                                projectId: cloneProjectId
                            };
                            smartcellService().updateCloneValue(smartCloneCellValue).then(function (savedStatus) {
                                scope.smartCloneId = savedStatus.id;
                                scope.clonedBoardId = scope.boardId;
                            });
                        }
                    }
                    scope.saveChanges = function () {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text,
                            smartCloneId: scope.smartCloneId,
                            cloneProjectId: cloneProjectId
                        }
                        if (scope.usePredefinedLabels) {
                            value.labels = scope.labels
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({ cell: scope.model.cell })
                        if (scope.isCloned) {
                            updateCloneValue();
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
