import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("improvementListFilter", ['$uibModal', '$filter', '$translate', 'suggestionService', 'strategyService', 'authService', 'navigationService', 'projectService', 'debounce',
        function ($uibModal, $filter, $translate, suggestionService, strategyService ,authService, navigationService, projectService, debounce) {
            return {
                templateUrl: 'improvementListFilter.html',
                restrict: 'E',
                scope: {
                    'filterInitial': '<',
                    'onFilterChanged': '&',
                    'options': '<',
                    'showHeader': "<"
                },
                link: function (scope, elem, attrs) {

                    scope.isSearchWordActive = false;
                    scope.isFilterActive = false;
                    scope.likesFilterActive = false;
                    scope.myResponsibleFilterActive = false;
                    scope.mySuggestionFilterActive = false;
                    scope.overdueFilterActive = false;
                    scope.assets = []
                    scope.projects = []
                    scope.categories = []

                    scope.showArchived = true;

      
                    scope.hasStrategyModule = navigationService().hasModule("STRATEGY");
                    scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                        scope.hasStrategyModule = navigationService().hasModule("STRATEGY"); 
                    });

                    scope.statuses = [
                        { id: 'Suggested', name: $translate.instant("COMMON_NEW"), filteredAndInResultList: false },
                        { id: 'Planned', name: $translate.instant("IMPROVEMENT_PLANNED"), filteredAndInResultList: false },
                        { id: 'InProgress', name: $translate.instant("COMMON_IN_PROGRESS"), filteredAndInResultList: false },
                        { id: 'Implemented', name: $translate.instant("IMPROVEMENT_IMPLEMENTED"), filteredAndInResultList: false },
                        { id: 'Evaluated', name: $translate.instant("IMPROVEMENT_EVALUATED"), filteredAndInResultList: false },
                        { id: 'Archived', name: $translate.instant("IMPROVEMENT_ARCHIVED"), filteredAndInResultList: false }
                    ]

                    scope.priorityOptions = [
                        {
                            priorityDB: 1,
                            translated: $translate.instant('IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT'),
                            cssClass: "highEffectLowEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 2,
                            translated: $translate.instant('IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT'),
                            cssClass: "highEffectHighEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 3,
                            translated: $translate.instant('IMPROVEMENT_LOW_EFFORT_LOW_EFFECT'),
                            cssClass: "lowEffectlowEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 4,
                            translated: $translate.instant('IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT'),
                            cssClass: "lowEffectHighEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 0,
                            translated: $translate.instant('IMPROVEMENT_NOT_PRIORITIZED'),
                            cssClass: "btn-default",
                            filteredAndInResultList: false
                        }
                    ];

                    scope.$watch('options', function (options) {
                        if (!options) return;
                        if (options.showArchived === false) {
                            scope.showArchived = false;
                        }
                    });

                    function resetListFilter(list) {
                        list.forEach(item => {
                            item.filteredAndInResultList = false
                        })
                    }
                    scope.toggleLikesFilter = function () {
                        scope.likesFilterActive = !scope.likesFilterActive
                        filterDebounce();
                    }

                    scope.toggleOverdueFilter = function () {
                        scope.overdueFilterActive = !scope.overdueFilterActive
                        filterDebounce();
                    }

                    scope.filterPriority = function (priority) {
                        priority.filteredAndInResultList = !priority.filteredAndInResultList
                        filterDebounce();
                    }

                    scope.clearFilter = function() {
                        scope.resetFilter();
                        if (scope.onFilterChanged) {
                            scope.onFilterChanged({filter: scope.filter})
                        }
                    }
                    scope.resetFilter = function () {
                        scope.query = ""
                        resetListFilter(scope.statuses);
                        resetListFilter(scope.assets);
                        resetListFilter(scope.projects);
                        resetListFilter(scope.categories);
                        resetListFilter(scope.goals);
                        resetListFilter(scope.priorityOptions);
                        scope.overdueFilterActive = false;
                        scope.myResponsibleFilterActive = false;
                        scope.mySuggestionFilterActive = false;
                        scope.overdueFilterActive = false;
                        scope.filter = [];
                    }

                    scope.toggleMyResponsibleFilter = function () {
                        scope.myResponsibleFilterActive = !scope.myResponsibleFilterActive
                        filterDebounce()
                    }

                    function getAllData() {
                        let promises = []
                        promises.push(assetService.getAllAssets().then(assets => {
                            scope.assets = _.sortBy(assets, function (i) { return i.name.toLowerCase() })
                            return true
                        }))
                        promises.push(suggestionService().getCategories().then(categories => {
                            scope.categories = categories
                            return true
                        }))
                        promises.push(projectService().getProjectListWithSuggestion().then(function (projects) {
                            scope.projects = _.sortBy(projects, function (i) { return i.name.toLowerCase() })
                            return true
                        }))
                        promises.push(strategyService().getBreakthroughObjectives().then(function (result) {
                            scope.goals = result
                            return true
                        }))
                        
                        Promise.all(promises).then(() => {
                            addInitialFilter()
                        })
                    }
                    getAllData()


                    scope.search = function () {
                        if (scope.query) {
                            scope.isSearchWordActive = true;
                        } else {
                            scope.isSearchWordActive = false;
                        }
                        filterDebounce()
                    }

                    scope.toggleMySuggestionFilter = function () {
                        scope.mySuggestionFilterActive = !scope.mySuggestionFilterActive
                        filterDebounce()
                    }

                    scope.filterStatus = function (status) {
                        status.filteredAndInResultList = !status.filteredAndInResultList
                        filterDebounce()
                    }
                    scope.filterAsset = function (asset) {
                        asset.filteredAndInResultList = !asset.filteredAndInResultList
                        filterDebounce()
                    }
                    scope.filterProject = function (project) {
                        project.filteredAndInResultList = !project.filteredAndInResultList
                        filterDebounce()
                    }
                    scope.filterCategory = function (category) {
                        category.filteredAndInResultList = !category.filteredAndInResultList
                        filterDebounce()
                    }
                    scope.filterGoal = function(goal) {
                        goal.filteredAndInResultList = !goal.filteredAndInResultList
                        filterDebounce()
                    }

                    function addToFilterIfActive(filter, list, sourceColumn, property) {
                        var itemToShow = list.filter(l => l.filteredAndInResultList)
                        if (itemToShow.length > 0) {
    
                            let itemFilter = {
                                sourceColumn: sourceColumn,
                                operator: "InList",
                                items: []
                            }
                            itemToShow.forEach(item => {
                                itemFilter.items.push(item[property])
                            })
                            filter.push(itemFilter)
                        }
                    }

                    const filterDebounce = debounce(setFilter, 500, false)
                    function setFilter() {

                        let filter = []
                        addToFilterIfActive(filter, scope.priorityOptions, "priorityStatus", "priorityDB")
                        addToFilterIfActive(filter, scope.statuses, "status", "id")
                        addToFilterIfActive(filter, scope.assets, "assetId", "id")
                        addToFilterIfActive(filter, scope.projects, "S.projectId", "id")
                        addToFilterIfActive(filter, scope.categories, "categoryId", "id")
                        addToFilterIfActive(filter, scope.goals, "Goals.StrategyGoalId", "id")
                        if (scope.myResponsibleFilterActive) {
                            var userid = authService.getCurrentUser().userId
                            var itemFilter = {
                                sourceColumn: "responsibleUserId",
                                operator: "InList",
                                items: [userid]
                            }
                            filter.push(itemFilter)
                        }
                        if (scope.mySuggestionFilterActive) {
                            let userid = authService.getCurrentUser().userId
                            let itemFilter = {
                                sourceColumn: "suggestedByUserId",
                                operator: "InList",
                                items: [userid]
                            }
                            filter.push(itemFilter)
                        }
                        if (scope.overdueFilterActive) {
                            let itemFilter = {
                                sourceColumn: "dueDate",
                                operator: "LessThanDate",
                                items: [moment().subtract(1, 'days')]
                            }
                            let notImplementedFilter = {
                                sourceColumn: "statusImplementedDate",
                                operator: "IsNull",
                                items: []
                            }
                            filter.push(itemFilter)
                            filter.push(notImplementedFilter)
                        }
                        if (scope.isSearchWordActive && scope.query) {
                            // Check for ID filter
                            if (isNumeric(scope.query)) {
                                // Check if suggestion is ID and show.
                                suggestionService().get(scope.query).then(function (suggestion) {
                                    if (suggestion) {
                                        var modalInstance = $uibModal.open({
                                            backdrop: 'static',
                                            templateUrl: 'fullImprovementInfoForm.html',
                                            controller: 'fullImprovementModalController',
                                            windowClass: 'fullSuggestion-modal-window',
                                            resolve: {
                                                suggestion: function () {
                                                    return suggestion
                                                },
                                                activeTab: function () {
                                                    return "Info"
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                            let itemFilter = {
                                sourceColumn: "title",
                                operator: "Contains",
                                items: [scope.query]
                            }
                            filter.push(itemFilter)
                        }
                        if (scope.onFilterChanged) {
                            scope.onFilterChanged({filter})
                        }
                    }

                    function isNumeric(num) {
                        return !isNaN(num)
                    }
                

                    function addInitialFilter() {
                        if (scope.filterInitial) {
                            let filter = scope.filterInitial
                            updateGuiFromFilter(filter, scope.priorityOptions, "priorityStatus", "priorityDB")
                            updateGuiFromFilter(filter, scope.statuses, "status", "id")
                            updateGuiFromFilter(filter, scope.assets, "assetId", "id")
                            updateGuiFromFilter(filter, scope.projects, "S.projectId", "id")
                            updateGuiFromFilter(filter, scope.categories, "categoryId", "id")
                            updateGuiFromFilter(filter, scope.goals, "Goals.StrategyGoalId", "id")
                            scope.myResponsibleFilterActive = updateGuiFromFilterSpecialCase(filter, "responsibleUserId")
                            scope.mySuggestionFilterActive = updateGuiFromFilterSpecialCase(filter, "suggestedByUserId")
                            scope.overdueFilterActive = updateGuiFromFilterSpecialCase(filter, "dueDate")
                        }
                    }
                    function updateGuiFromFilter(filter, list, sourceColumn, property) {
                        const appl = filter.find(f => f.sourceColumn == sourceColumn)
                        if (appl) {
                            const itemsint = stringtoint(appl.items)
                            list.forEach(l => {
                                const propVal = l[property]
                                if (appl.items.includes(propVal) || itemsint.includes(propVal))
                                    l.filteredAndInResultList = true
                            })
                        }
                    }
                    function stringtoint(listofstr) {
                        try {
                            if (!listofstr)
                                return []
                            return listofstr.map(str => parseInt(str))
                        }
                        catch(e) { return []}
                    }
                    function updateGuiFromFilterSpecialCase(filter, sourceColumn) {
                        const appl = filter.find(f => f.sourceColumn == sourceColumn)
                        return !!appl
                    }


                }

            }
        }]);
