﻿angular
    .module('DigiLean')
    .controller("deviationEvaluationController", ['$scope', '$http', '$filter', '$translate', '$uibModal', 'deviationService',
        function ($scope, $http, $filter, $translate, $uibModal, deviationService) {

            $scope.timeframe = "last12mth";
           
            $scope.smile = 0;
            $scope.meh = 0;
            $scope.frown = 0;

            $scope.isAllSelected = true;
            $scope.filter = null;
            var selectedType = null;
            
            deviationService().getDeviationTypes().then(function (types) {
                $scope.types = types;
                for(var i=0; i<$scope.types.length;i++ ){
                    $scope.types[i].isSelected = false;
                }
            });

            $scope.selectType = function(deviationtype){
                $scope.isAllSelected = false;
                for(var i=0; i<$scope.types.length;i++ ){
                    $scope.types[i].isSelected = false;
                }
                deviationtype.isSelected = true;
                selectedType = deviationtype;
                $scope.filters = deviationtype;
                var filtersettings = [
                    {sourceColumn: "deviationTypeId",
                        operator: "InList",
                        items: [selectedType.id]
                    }
                ];
                $scope.settings = {
                    title: deviationtype.title,
                    filters: filtersettings
                };
                load();
            };

            $scope.resetSelection = function(){
                $scope.isAllSelected = true;
                for(var i=0; i<$scope.types.length;i++ ){
                    $scope.types[i].isSelected = false;
                }
                $scope.filter = null;
                selectedType = null;
                $scope.settings = null;
                load();
            };
        
    
            $scope.options = {
                timePeriod: {
                    timeframe: $scope.timeframe,
                    timeframes: ['all', 'lastyear', 'last12mth', 'year', '30', 'year', 'month', 'previousmonth', 'previousisoweek', 'isoweek', 'day', 'custom']
                }
            };
            $scope.consequenceSettings = [];
            var load = function () {
                $scope.consequenceSettings = [];
                deviationService().getConsequences().then(function (consequences) {
                    for(var i=0; i<consequences.length; i++){
                        if(consequences[i].unit){
                            var cons = consequences[i];
                            var filtersettings = [
                                {sourceColumn: "lossTypeId",
                                    operator: "InList",
                                    items: [cons.id]
                                }
                            ];
                            if(!$scope.isAllSelected){
                                filtersettings.push({
                                    sourceColumn: "deviationTypeId",
                                    operator: "InList",
                                    items: [selectedType.id]
                                });
                            }
                            var settings = {
                                title: cons.title,
                                dataSource: {
                                    id: 'DeviationConsequence',
                                    valueElement: {
                                        unit: deviationService().getConsequenceUnitLabel(cons.unit)
                                    }
                                },
                                filters: filtersettings,
                                decimals: 1
                            };
                            $scope.consequenceSettings.push(settings);
                        }
                    }
                });
                var options = {};
                if($scope.settings){
                    options = {
                        filters : $scope.settings.filters
                    };
                }
            
                deviationService().getNoEvaluationStatus(options).then(function (evaluation) {
                    $scope.evaluation = evaluation;
                    if(evaluation.total > 0){
                        $scope.smile = (100*evaluation.smile / evaluation.total).toFixed(1);
                        $scope.meh = (100*evaluation.meh / evaluation.total).toFixed(1);
                        $scope.frown = (100*evaluation.frown / evaluation.total).toFixed(1);
                    }
                });
                
            };
            load();
            $scope.options = {
                timePeriod: {
                    timeframe: "year",
                    useDefaultTimeframes: true,
                    timeframes: []
                }
            };

            
            $scope.getConsequenseSingelSettings = function (cons) {
                var settings = {
                    title: cons.title,
                    timePeriod: {
                        timeframe: "year"
                    },
                    dataSource: {
                        id: 'DeviationConsequence',
                        valueElement: {
                            unit: cons.unit
                        }
                    },
                    filters: [
                        {sourceColumn: "lossTypeId",
                            operator: "InList",
                            items: [cons.id]
                        }
                    ],
                    decimals: 1
                };
                return settings;
            };

        }]);
