var DigiLean = angular.module('DigiLean');
DigiLean.controller('postitPredefinedTags', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'labels','projectService',
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, labels, projectService) {
        $scope.defaultLabels = projectService().getPostIts();
        $scope.labels = labels;
        if(!$scope.labels){
            $scope.labels = $scope.defaultLabels;               
          }

        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('DASHBOARD_DATA_SOURCE');
            $scope.steps[1].title = $translate.instant('COMMON_FILTER');
            $scope.currentStep = $scope.steps[0];
        });

        
            setTimeout(function () {
                for (var l = 0; l < $scope.labels.length; l++) {
                    // Init tags and focus (without this the textfield is 2px)
                    var tasktagID = "#taskTags-"+$scope.labels[l].id.toString();
                    var taskselectedID = '#tagsSelected-'+$scope.labels[l].id.toString();
                    $(tasktagID).tagit({
                        // This will make Tag-it submit a single form value, as a comma-delimited field.
                        singleField: true,
                        singleFieldNode: $(taskselectedID)
                    });
                    if($scope.labels[l].tags){
                        var tagArray = $scope.labels[l].tags;
                        for (var i = 0; i < tagArray.length; i++) {
                            $(tasktagID).tagit('createTag', tagArray[i]);
                        }
                    }
            }
            }, 50);
        
        $scope.createNew = function () {
            var highestId = _.max($scope.labels, function(label){ return label.id; });
            //var lastid = $scope.labels[$scope.labels.length-1].id;
            var newLabel = {
                id: highestId.id+1,
                title: "",
                fillColor: "#ffffff",
                background: "#E6E9ED",
                color: "#ffffff"
            }
            $scope.labels.push(newLabel);
            setTimeout(function () {
                var tasktagID = "#taskTags-"+newLabel.id.toString();
                    var taskselectedID = '#tagsSelected-'+newLabel.id.toString();
                    $(tasktagID).tagit({
                        // This will make Tag-it submit a single form value, as a comma-delimited field.
                        singleField: true,
                        singleFieldNode: $(taskselectedID)
                    });
            }, 50);    
        };

        $scope.delete = function (label) {
            var index = $scope.labels.indexOf(label);
            $scope.labels.splice(index, 1);    
        }

        $scope.templateColorChanged = function (label) {
            //Need this for timeboard tags
            var index = $scope.labels.indexOf(label);
            $scope.labels[index].fillColor = "#ffffff";
        }
        
        $scope.reset = function () {
            $scope.labels = $scope.defaultLabels; 
            setTimeout(function () {
                for (var l = 0; l < $scope.labels.length; l++) {
                    // Init tags and focus (without this the textfield is 2px)
                    var tasktagID = "#taskTags-"+$scope.labels[l].id.toString();
                    var taskselectedID = '#tagsSelected-'+$scope.labels[l].id.toString();
                    $(tasktagID).tagit({
                        // This will make Tag-it submit a single form value, as a comma-delimited field.
                        singleField: true,
                        singleFieldNode: $(taskselectedID)
                    });
                    if($scope.labels[l].tags){
                        $scope.labels[l].tags = [];
                    }
                }
            }, 50);  
        } 
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            for (var l = 0; l < $scope.labels.length; l++) {
                var tagSingleString = $('#tagsSelected-'+$scope.labels[l].id.toString()).val();          
                if(tagSingleString == ""){
                    $scope.labels[l].tags = [];
                } else {
                    var tagsArray = tagSingleString.split(",");
                    $scope.labels[l].tags = tagsArray;
                }
            }
            
            
            $uibModalInstance.close($scope.labels);
        };

    }]);