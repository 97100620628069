type PageState = DocumentVisibilityState | "active" | "passive" | "frozen" | "terminated"

const getState = (): PageState => {
    if (document.visibilityState === "hidden") {
        return "hidden"
    }
    if (document.hasFocus()) {
        return "active"
    }
    return "passive"
}

let state = getState()

const logStateChange = (nextState: PageState) => {
    const prevState = state
    if (nextState !== prevState) {
        // console.log(`DigiLEAN State change: ${prevState} >>> ${nextState}`)
        state = nextState
    }
}

['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(type => {
    window.addEventListener(type, () => logStateChange(getState()), {
        capture: true,
    })
})

window.addEventListener(
    'freeze',
    (e) => {
        logStateChange("frozen")
    },
    { capture: true }
)

window.addEventListener(
    'pagehide',
    event => {
        if (event.persisted) {
            logStateChange("frozen")
        } else {
            logStateChange("terminated")
        }
    },
    { capture: true }
)