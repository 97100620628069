angular
    .module('DigiLean')
    .controller("newDeviationTypeController", ['$scope', 'deviationService', '$uibModalInstance',
        function ($scope, deviationService, $uibModalInstance) {

            $scope.title = "";
            $scope.description = "";

            $scope.ok = function () {
                var deviationType = {
                    title: $scope.title,
                    description: $scope.description
                }
                deviationService().createDeviationType(deviationType).then(function(type){
                    $uibModalInstance.close(type)
                });          
            };
            $scope.currentStep = {};
            $scope.selectStep = function (step) {
                $scope.currentStep = step;
            }
            
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
