angular
    .module('DigiLean')
    .directive("priorityMatrixApp", ['$filter', '$sce',
        function ($filter, $sce) {
            return {
                templateUrl: 'priorityMatrixApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.title = "";
                    scope.filter = null;
                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (scope.settings) {
                            scope.title = settings.title;
                            scope.filter = settings.filter;
                        }
                    });

                    scope.updateSettings = function () {
                        if (scope.isAdminMode == false) return;
                        var componentSettings = {
                            title: scope.title,
                            filter: scope.filter,
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }

                    scope.filterChanged = function (filter) {
                        console.log("priorityMatrix filter changed");
                        scope.filter = filter;
                        scope.updateSettings();   
                    }

                }
            }
        }]);
