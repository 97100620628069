var DigiLean = angular.module('DigiLean');
DigiLean.directive("projectTarget", ['$filter', 'projectService','navigationService','$uibModal',
        function ($filter, projectService, navigationService, $uibModal) {
            return {
                templateUrl: 'projectTarget.html',
                restrict: 'E',
                scope: {
                    'editable': '=',
                    'projectId': '=',
                    'projectTargetId': '=',
                    'value': '='
                },
                link: function (scope, elem, attrs) {

                    scope.subscribe("UserAuthenticatedAndReady", function(profile)
                    {
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");   
                    });
                    
                    scope.setStatusValue = function (newValue) {
                        scope.value = newValue;
 
                        var status = {
                            status: scope.value,
                            projectTargetId: scope.projectTargetId,
                            projectId: scope.projectId
                        };
                        projectService().updateProjectTarget(status).then(function (savedStatus) {

                            if(scope.hasDeviation == null) {scope.hasDeviation = navigationService().hasModule("DEVIATION");}
                            if(scope.hasDeviation && newValue > 1) {
                                addDeviation(newValue);
                            }
                        });

                    }

                    function addDeviation(statusValue) {
                        var severity = DigiLean.hms.getDeviationSeverity(statusValue);
                        // Make sure task responsbile dropdown is closed if open.
                        var settings = {
                            projectId: scope.projectId,
                            severity: severity
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    }

                }

            }
        }]);
