angular
    .module('DigiLean')
    .controller("goalSelectorController", ['$scope', '$uibModalInstance','goals',
        function ($scope, $uibModalInstance,goals) {

            $scope.selectedStrategyGoal = null;
            $scope.strategyGoals = goals;
        
            $scope.setSelectedStrategyGoal = function (goal) {
                $uibModalInstance.close(goal.id);
            };


            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
