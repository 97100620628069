import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {ref, createRef, Ref} from "lit/directives/ref.js"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"

@customElement('digilean-input')
export class DigiLeanInput extends LitElement {
    
    private inputField: Ref<HTMLInputElement> = createRef()

    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            width: 500px;
        }
        * {
            box-sizing: border-box;
        }

        label {
            flex-basis: 30%;
        }

        input {
            flex-basis: 70%;
            flex-grow: 1;
            background-color: var(--digilean-secondary-background);
            background-image: none;
            border: none;
            outline: 1px solid var(--digilean-input-border);
            border-radius: 1px;
            color: inherit;
            display: block;
            padding: 6px 12px;
            transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
            /* font-size: 14px; */
        }
        input:focus {
            outline-color: var(--digilean-blue-sharp);
        }
    `

    @property({attribute: true})
    value = ""

    @property({attribute: true})
    name = ""

    @property({attribute: true})
    label = ""

    @property({attribute: true})
    placeholder = ""

    focus() {
        if (this.inputField.value)
            this.inputField.value.focus()
    }

    changeEvent(e: Event) {
        this.value = (e.target as HTMLInputElement).value
        this.sendEvent("change")
    }
    inputEvent(e: Event) {
        this.value = (e.target as HTMLInputElement).value
        this.sendEvent("input")
    }
    sendEvent(name: string) {
        const evt = new Event(name, {
            bubbles: true,
            composed: true
        })
        this.dispatchEvent(evt)
    }
    render() {
        let placeholder = this.placeholder ?? ""
        if (placeholder) {
            placeholder = translateLabelInstant(placeholder)
        }
        let label = this.label ?? ""
        if (label)
            label = translateLabelInstant(label)

        return html`
            ${label ? 
                html`<label for="${this.name}">${label}</label>`: ""
            }    
            <input type="text" ${ref(this.inputField)}
                id="${this.name}" name="${this.name}" 
                value=${this.value} 
                placeholder=${placeholder}
                @change=${this.changeEvent} 
                @input=${this.inputEvent} />
        `
    }
}
