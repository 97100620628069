import * as boardService from "@common/services/boardService"

angular
    .module("DigiLean")
    .directive('projectboardApp', ['$filter', 'projectService', 'debounce',
        function ($filter, projectService, debounce) {
            return {
                templateUrl: 'projectboardApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.boardData = null;
                    scope.hasBoard = false;
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            loadBoard(settings.boardId);
                        }
                        projectService().getMemberProjectPortfolio().then(function (boards) {
                             // Filter
                             scope.boards.push({
                                name: "",
                                id: 0
                            });
                            // Filter
                            for (let index = 0; index < boards.length; index++) {
                                const board = boards[index];
                                scope.boards.push(board);
                                
                            }
                            // Set selected 
                            if (scope.settings && scope.settings.boardId) {
                                var selected = $filter('filter')(scope.boards, { id: settings.boardId });
                                if (selected.length > 0) {
                                    scope.selectedBoard = selected[0];
                                }
                            }
                            else {
                                if (scope.boards.length > 0) {
                                    loadBoard(scope.boards[0].id);
                                    scope.selectedBoard = scope.boards[0];
                                }
                            }
                        });
                    });
                    scope.boards = [];
                    scope.boardChanged = function (selectedBoard) {
                        loadBoard(selectedBoard.id);
                        updateSettings();
                    }

                    var currentBoardId = 0;
                    var loadBoard = function (boardId) {
                        if (currentBoardId == boardId) return;
                        currentBoardId = boardId;
                        if (boardId == 0) {
                            scope.hasBoard = false;
                            return;    
                        }
                        scope.isLoading = true;
                        scope.hasBoard = true;
                        boardService.get(boardId).then(function (board) {
                            scope.boardData = board;
                            setTimeout(function () {
                                scope.isLoading = false;
                                updateContainerHeightDebounced();
                            }, 500);
                        })
                    }
                    function updateSettings() {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                boardId: scope.selectedBoard.id,
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }
                    function updateContainerHeight() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 40;
                        var container = $(elem).find(".project-board-app");
                        if (container.length === 0) return;
                        var targetHeight = height - headerHeight;
                        $(container).css("height", targetHeight);
                        scope.options = {
                            targetHeight: targetHeight
                        }
                    }
                    const updateContainerHeightDebounced = debounce(updateContainerHeight, 100, false)
                    scope.$on("widget-resized", function (event, args) {
                        updateContainerHeightDebounced();
                    });

                }
            }
        }]);

