angular
    .module('DigiLean')
    .directive("leadtimeImprovement", ['$filter', '$translate', 'suggestionService', 'authService',
        function ($filter, $translate, suggestionService, authService) {
            return {
                templateUrl: 'leadtimeImprovement.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {
                    scope.timeframe = "year";
                    var chartHeight = 400;

                    scope.options = {
                        timePeriod: {
                            timeframe: "year",
                            useDefaultTimeframes: true,
                            timeframes:[]
                        }
                    };
                    

                    var grafcolor = ['#2A2E36', '#0099DE', '#5cb85c', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'],
                        graphtextcolor = '#ffffff';

                    // Leadtime diagram
                    var leadtimecategories = [],
                        dataFromImplementedToEvaluated = [],
                        dataFromInProgressToImplemented = [],
                        dataFromPlannedToInProgress = [],
                        dataFromNewToPlanned = [];


                    var yAxisText = "";
                    var fromImplToEval = "";
                    var fromInProgressToImpl = "";
                    var fromPlannedToInProgress = "";
                    var fromNewToPlanned = "";
                    $translate(['IMPROVEMENT_TOTAL_LEAD_TIME', 'IMPROVEMENT_FROM_IMPL_TO_EVALUATED', 'IMPROVEMENT_FROM_IN_PROGRESS_TO_IMPL', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'IMPROVEMENT_FROM_NEW_TO_PLANNED']).then(function (translations) {
                        yAxisText = translations.IMPROVEMENT_TOTAL_LEAD_TIME;
                        fromImplToEval = translations.IMPROVEMENT_FROM_IMPL_TO_EVALUATED;
                        fromInProgressToImpl = translations.IMPROVEMENT_FROM_IN_PROGRESS_TO_IMPL;
                        fromPlannedToInProgress = translations.IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS;
                        fromNewToPlanned = translations.IMPROVEMENT_FROM_NEW_TO_PLANNED;
                        getData();
                    });

                    scope.periodChangedHandler = function (timePeriod) {
                        scope.timePeriod = timePeriod.period;
                        scope.timeframe = timePeriod.timeframe;
                        getData();
                    }

                    function getData() {
                        if (!scope.timePeriod) return;
                        var leadtimecategories = [],
                            dataFromImplementedToEvaluated = [],
                            dataFromInProgressToImplemented = [],
                            dataFromPlannedToInProgress = [],
                            dataFromNewToPlanned = [];

                        suggestionService().getLeadTimeForAssets(scope.timePeriod).then(function (result) {
                            var leadtimedata = result.results;

                            if(leadtimedata && leadtimedata.length > 5){     
                                chartHeight = 400 + 30*(leadtimedata.length -5);
                            }
                            // Build the data arrays
                            for (var i = 0; i < leadtimedata.length; i++) {
                                leadtimecategories.push(leadtimedata[i].asset);
                                dataFromImplementedToEvaluated.push(leadtimedata[i].fromImplementedToEvaluated);
                                dataFromInProgressToImplemented.push(leadtimedata[i].fromInProgressToImplemented);
                                dataFromPlannedToInProgress.push(leadtimedata[i].fromPlannedToInProgress);
                                dataFromNewToPlanned.push(leadtimedata[i].fromNewToPlanned);
                            }

                            var renderElement = $(elem).find(".leadtime")[0];
                            if (renderElement)
                                Highcharts.chart({
                                    chart: {
                                        renderTo: renderElement,
                                        type: 'bar',
                                        height: chartHeight,
                                    },
                                    title: {
                                        text: ''
                                    },
                                    xAxis: {
                                        categories: leadtimecategories
                                    },
                                    yAxis: {
                                        min: 0,
                                        title: {
                                            text: yAxisText //'Total lead time'
                                        }
                                    },
                                    credits: {
                                        enabled: false
                                    },
                                    exporting: { enabled: false },
                                    legend: {
                                        reversed: true
                                    },
                                    plotOptions: {
                                        series: {
                                            stacking: 'normal'
                                        }
                                    },
                                    series: [{
                                        name: fromImplToEval, //'From Implemented to Evaluated',
                                        data: dataFromImplementedToEvaluated,
                                        color: grafcolor[5] //'#f8ac59'
                                    }, {
                                        name: fromInProgressToImpl, //'From In Progress to Implemented',
                                        data: dataFromInProgressToImplemented,
                                        color: grafcolor[2] //'#23c6c8'
                                    }, {
                                        name: fromPlannedToInProgress, //'From Planned to In Progress',
                                        data: dataFromPlannedToInProgress,
                                        color: grafcolor[1] //'#0099DE'
                                    }, {
                                        name: fromNewToPlanned, //'From New to Planned',
                                        data: dataFromNewToPlanned,
                                        color: grafcolor[6] //'#434348'
                                    }]
                                });
                        });
                    }
                }
            }
        }]);
