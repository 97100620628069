/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomerSubscriptionType {
    Standard = 0,
    Professional = 1,
    Enterprise = 2,
    Custom = 3,
    None = 4,
}
