angular
    .module('DigiLean')
    .directive("strategyGoalCard", ['$state', 'strategyGoalService',
        function ($state,strategyGoalService) {
            return {
                templateUrl: 'strategyGoalCard.html',
                restrict: 'E',
                scope: {
                    'goalId': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.goal = [];
                    scope.$watch("goalId", function () {
                        loadGoal();
                    });

                    function loadGoal() {
                        if (scope.goalId == null) return;
                        strategyGoalService().getGoal(scope.goalId).then(function (goal) {
                            scope.goal = goal;
                        });
                    }

                    scope.goToHub = function () {
                        strategyGoalService().getGoal(scope.goal.breakthroughGoalId).then(function (breakthroughgoal) {
                            if(breakthroughgoal){
                                /*var assetid = null;
                                if(scope.goal.assetId){
                                    assetid = scope.goal.assetId;
                                }*/
                                $state.go('goalDetails', { id: scope.goal.breakthroughGoalId, assetid: scope.goal.assetId }, { reload: false });
                                //$state.go('goalDetails', { id: breakthroughgoal.projectId, assetid: scope.goal.assetId }, { reload: false });
                            }
                        });          
                    };
                }
            }
        }]);