angular.module('DigiLean').factory('weeklyRenderService', ['$filter',
    function ($filter) {
        function weeklyRenderService() {

            return {
                addTaskToRow: function (task, row, timeOptions) {
                    // Make sure task has rowIdentifcator
                    task.rowCategoryId = row.id;
                    task.rowIndex = row.index;
                    // If no current tasks, than create
                    if (row.taskRows.length == 0) {
                        var newRow = this.createTaskRow(task, timeOptions);
                        row.taskRows.push(newRow);
                        return;
                    }
                    // else we will try to fit in first available row.
                    for (let index = 0; index < row.taskRows.length; index++) {
                        const taskRow = row.taskRows[index];
                        var taskAdded = this.addTaskToRowIfSpace(task, taskRow, timeOptions)
                        if (taskAdded) return;
                    }
                    // If not placed in existing row, we need to add a new one
                    var newRow = this.createTaskRow(task, timeOptions);
                    row.taskRows.push(newRow);
                    return;
                },

                moveTask: function (task, targetOptions, timeOptions) {
                    var targetDay = targetOptions.targetDay;
                    var diff = task.startDay - targetDay;
                    if (diff > 0) {
                        task.startTime = moment(task.startTime).subtract(diff, "days");
                        task.endTime = moment(task.endTime).subtract(diff, "days");
                    } else {
                        diff = diff * -1;
                        task.startTime = moment(task.startTime).add(diff, "days");
                        task.endTime = moment(task.endTime).add(diff, "days");
                    }
                    this.calculateDuration(task, timeOptions);
                },

                reCalculateRow: function (data, targetRowId, timeOptions) {
                    var targetRow = $filter('filter')(data.rows, { id: targetRowId }, true)[0];
                    targetRow.taskRows.splice(0, targetRow.taskRows.length);
                    var tasks = $filter('filter')(data.tasks, { rowCategoryId: targetRowId }, true);
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        this.addTaskToRow(task, targetRow, timeOptions);
                    }

                },

                reCalculateRowByIndex: function(data, targetRowIndex, timeOptions) {
                    var targetRow =  $filter('filter')(data.rows, { index: targetRowIndex }, true)[0];
                    targetRow.taskRows.splice(0, targetRow.taskRows.length);
                    var tasks = $filter('filter')(data.tasks, { rowIndex: targetRowIndex }, true);
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        this.addTaskToRow(task, targetRow, timeOptions);
                    }
                    
                },

                addTaskToRowIfSpace(task, taskRow, timeOptions) {
                    // First check if all cells needed are available
                    for (let index = task.startDay; index <= task.endDay; index++) {
                        var startCell = $filter('filter')(taskRow.cells, { id: index }, true);
                        // if cell doesn't exists (already part of another colspan or already have task)
                        if (startCell.length == 0 || startCell[0].task) return false;
                    }
                    // Add task to cell and set colspan
                    var startCell = $filter('filter')(taskRow.cells, { id: task.startDay }, true)[0];
                    startCell.colspan = task.durationInDaysCurrentWeek;
                    startCell.task = task;
                    // Now let's removed cell affected by colspan
                    if (task.durationInDaysCurrentWeek > 1) {
                        // Use start month(not -1) because 0 index array.
                        var index = taskRow.cells.indexOf(startCell);
                        taskRow.cells.splice(index + 1, task.durationInDaysCurrentWeek - 1);
                    }
                    return true;
                },

                createTaskRow: function (task, timeOptions) {
                    var newRow = {
                        cells: []
                    }
                    for (let index = timeOptions.firstDay.number; index < task.startDay; index++) {
                        var cell = {
                            id: index,
                            colspan: 1
                        }
                        newRow.cells.push(cell)
                    }
                    var taskCell = {
                        id: task.startDay,
                        colspan: task.durationInDaysCurrentWeek,
                        task: task
                    }
                    newRow.cells.push(taskCell);
                    for (let index = (task.startDay + task.durationInDaysCurrentWeek); index <= 7; index++) {
                        var cell = {
                            id: index,
                            colspan: 1
                        }
                        newRow.cells.push(cell)
                    }
                    return newRow;
                },
                calculateDuration: function (task, timeOptions) {
                    var firstDay = timeOptions.week.days[0];
                    var lastday = timeOptions.week.days[timeOptions.week.days.length - 1];
                    task.startTime = moment(task.startTime).toDate();
                    task.endTime = moment(task.endTime).toDate();
                    if (task.startTime && task.endTime) {
                        var startDay = moment(task.startTime).isoWeekday(); // Javascript months are 0 indexed
                        
                        var endDay = moment(task.endTime).isoWeekday();
                        task.isStartOutsideCurrentPeriod = false;
                        task.isEndOutsideCurrentPeriod = false;

                        // If
                        if (task.endTime > lastday.date) {
                            // if task duration end within a greater month, we need to set it to this year
                            endDay = lastday.number;
                            task.isEndOutsideCurrentPeriod = true;
                        }
                        if (task.startTime < firstDay.date) {
                            // if task duration end within a greater month, we need to set it to this year
                            startDay = firstDay.number;
                            task.isStartOutsideCurrentPeriod = true;
                        }
                        // How many days within current year
                        task.startDay = startDay;
                        task.endDay = endDay;

                        task.durationInDaysCurrentWeek = (endDay - startDay) + 1; // we need to add one
                    }
                }

            };
        }
        return weeklyRenderService;
    }]);

