angular
    .module('DigiLean')
    .directive("a3TasksCell", ['$filter', '$translate', '$uibModal', 'boardTaskService',
        function ($filter, $translate, $uibModal, boardTaskService) {
            return {
                templateUrl: 'a3TasksCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.taskCount = 0;
                    scope.taskCompletedCount = 0;
                    var a3Id = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.a3) {
                            if (scope.model.a3.id) {
                                a3Id = scope.model.a3.id;
                                refresh();
                            }
                        }
                        model.getSortValue = function() {
                            return scope.taskCount;
                        }
                    });

                    function refresh(){
                        boardTaskService().getNoA3TaskPerStatus(a3Id).then(function (statusdata) {
                            scope.taskCompletedCount = statusdata.ok;
                            scope.taskCount = statusdata.blank + statusdata.ok + statusdata.unacceptable;
                        });
                    }

                    scope.subscribe('TaskStatusUpdated', function (statustask) {
                            refresh();
                        
                    });

                    scope.subscribe('TaskDeleted', function (id) {
                        refresh();
                    });
                    scope.subscribe('NewTask', function (task) {
                        refresh();
                    });
           
                    
                scope.openTasks = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'a3TaskListForm.html',
                        controller: 'a3TaskListModalController',
                        windowClass: 'fullDeviation-modal-window',
                        resolve: {
                            a3Id: function () {
                                return a3Id;
                            }
                        }
                    });
                };
            }        
        }
    }]);


    