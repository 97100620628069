angular.module("DigiLean").factory('strategyGoalService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {
        function strategyGoalService() {
            var resource = "/strategyGoal";
            var resourceUrl = webApiUrl + resource;

            return {
                getGoal: function (goalId) {
                    var url = resourceUrl + "/getGoal/" + goalId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                }, 
                createGoal: function (goal) {
                    var url = resourceUrl  + "/createGoal/";
                    return $http.post(url, goal).then(function (result) {
                        return result.data;
                    });
                },
                createGoals: function (goals) {
                    var url = resourceUrl  + "/createGoals/";
                    return $http.post(url, goals).then(function (result) {
                        return result.data;
                    });
                },
                updateGoal: function (goal) {
                    var url = resourceUrl  + "/updateGoal/" + goal.id;
                    return $http.post(url, goal).then(function (result) {
                        return result.data;
                    });
                },
                moveStrategyGoal: function (goal) {
                    var url = resourceUrl  + "/MoveStrategyGoal/";
                    return $http.post(url, goal).then(function (result) {
                        return result.data;
                    });
                },
                deleteGoal: function (goalId) {
                    var url = resourceUrl + "/deleteGoal/" + goalId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                updateAsset: function (goalId, assetid) {
                    var url = resourceUrl + "/" + goalId + "/UpdateAsset/" + assetid;
                    return $http.put(url).then(function () {
                        return;
                    });
                },

                updateColor: function (goalId, color) {
                    var strategyGoalColor =  {
                        goalId: goalId,
                        color: color
                    }
                    var url = resourceUrl + "/updateColor/" + goalId;
                    return $http.put(url,strategyGoalColor).then(function (result) {
                        return result.data;
                    });
                },
              
                
                updateName: function (goalId, name) {
                    var strategyGoalName =  {
                        goalId: goalId,
                        name: name
                    }
                    var url = resourceUrl + "/updateName/" + goalId;
                    return $http.put(url,strategyGoalName).then(function (result) {
                        return result.data;
                    });
                },

                updateStartDate: function (goalId, date) {
                    var projectDate =  {
                        actionDate: date
                    }
                    var url = resourceUrl + "/updateStartDate/" + goalId;
                    return $http.put(url,projectDate).then(function (result) {
                        return result.data;
                    });
                },  
                updateEndDate: function (goalId, date) {
                    var projectDate =  {
                        actionDate: date
                    }
                    var url = resourceUrl + "/updateEndDate/" + goalId;
                    return $http.put(url,projectDate).then(function (result) {
                        return result.data;
                    });
                },  
                updateDescription: function (goal) {
                    var url = resourceUrl + "/updateDescription/" + goal.id;
                    return $http.put(url,goal).then(function (result) {
                        return result.data;
                    });
                }, 
                     
            };
        }
        return strategyGoalService;
    }]);