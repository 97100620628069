﻿import * as assetService from "@common/services/assetService"
import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('newFlexiboardConfigurationController', ['$scope', '$translate', '$uibModalInstance', 'smartActionListService', 'navigationService', 'options',
    function ($scope, $translate, $uibModalInstance, smartActionListService, navigationService, options) {
        $scope.hasProjectModule = navigationService().hasModule("PROJECT");
        $scope.useProjectGroup = true;

        $scope.isProcessing = false;
        $scope.participants = [];
        $scope.selectedAssetId = null;
        $scope.selectedAssetType = "";
        $scope.name = "";
        $scope.description = "";
        $scope.isPublic = false;
        $scope.errormessage = null;

        var tabs = ["Info", "Participants"];
        $scope.indexTab = 0;
        $scope.selectTab = function (tab) {
            $scope.indexTab = tabs.indexOf(tab);
        }

        $scope.chooseMembers = true;
        if (options.assetId && options.assetId > 0) {
            $scope.chooseMembers = false;
            $scope.selectedAssetId = options.assetId;
        }
        if(options.hasOwnProperty('includeCustomGroup') || options.hasOwnProperty('includeProject')) {
            $scope.memberSelectorOptions = {
                includeCustomGroup : options.includeCustomGroup,
                includeProject: options.includeProject
            }
        }
        $scope.memberSelectionChanged = function (selection) {
            $scope.selectedAssetId = selection.assetId;
            $scope.participants = selection.participants;
            $scope.selectedAssetType = selection.assetType;
            validationFailed();
        }
        $scope.nameChanged = function (name) {
            $scope.name = name;
            if($scope.name){
                $scope.errormessage = null;
            }
        }

        $scope.descriptionChanged = function (description) {
            $scope.description = description;
        }

        $scope.setPublicStatus = function () {
            $scope.isPublic = !$scope.isPublic;
        }

        function validationFailed() {
            if (!$scope.name) {
                $scope.errormessage = $translate.instant('COMMON_NAME');
                $scope.selectTab("Info");
                return true;
            }
            if (!$scope.selectedAssetId && $scope.selectedAssetType != "CUSTOMIZED") {
                $scope.errormessage = $translate.instant('COMMON_PARTICIPANTS');
                $scope.chooseMembers = true;
                $scope.selectTab("Participants");
                return true;
            }
            $scope.errormessage = null;
            return false;
        }

        $scope.ok = function () {
            if (validationFailed()) return;
            $scope.isProcessing = true;

            if ($scope.selectedAssetType == "CUSTOMIZED") {
                var participants = [];
                $scope.participants.forEach(p => {
                    var participant = {
                        userId: p.userId,
                        role: "Member"
                    }
                    participants.push(participant);
                });

                var customAsset = {
                    name: $scope.name,
                    participants: participants
                }
                assetService.createCustomAsset(customAsset).then(function (asset) {
                    $scope.selectedAssetId = asset.id;
                    createFlexiboard();
                });

            } else {
                createFlexiboard();
            }

        }

        function createFlexiboard() {
            var dashboard = {
                title: $scope.name,
                description: $scope.description,
                assetId: $scope.selectedAssetId,
                isPublic: $scope.isPublic
            };
            
            $scope.isProcessing = true;
            switch (options.operation) {
                case "copy":
                    dashboard.sourceId = options.dashboardAsTemplateId;
                    dashboardService.copyDashboard(options.dashboardAsTemplateId, dashboard).then(function (dashboard) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(dashboard);
                    });
                    break;
                case "createFromTemplate":
                    dashboard.sourceId = options.dashboardAsTemplateId;
                    dashboardService.createFromTemplate(options.dashboardAsTemplateId, dashboard).then(function (dashboard) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(dashboard);
                    });
                    break;
                case "newFlexiboard":
                    dashboardService.addDashboard(dashboard).then(function (dashboard) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(dashboard);
                    });
                    break;
                case "newSmartActionList":
                    options.template.board.assetId = $scope.selectedAssetId;
                    options.template.board.name = $scope.name;
                    smartActionListService().createsmartActionList(options.template).then(function (smartActionList) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(smartActionList);
                    });
                    break;
                case "newSmartActionListFromTemplate":
                    smartActionListService().createSmartActionListFromTemplate(options.templateId, dashboard).then(function (smartActionList) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(smartActionList);
                    });
                    break;
                case "newTaskBoardFromTemplate":
                    boardService.createFromTemplate(options.template.templateId, dashboard).then(function (board) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(board);
                    });
                    break;
                case "newPerformanceBoard":
                    options.template.board.assetId = $scope.selectedAssetId;
                    options.template.board.name = $scope.name;
                    boardService.create(options.template).then(function (savedModel) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(savedModel);
                    });
                    break;
                case "newTaskBoard":
                    options.template.board.assetId = $scope.selectedAssetId;
                    options.template.board.name = $scope.name;
                    boardService.create(options.template).then(function (savedModel) {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(savedModel);
                    });
                    break;
            }
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);