angular
    .module('DigiLean')
    .directive("consequenceSelector", ['$filter', 'deviationService', 
        function ($filter, deviationService) {
            return {
                templateUrl: 'consequenceSelector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.includeAll = true;

                    function getconsequenceList() {
                        deviationService().getConsequences().then(function (consequences) {
                            scope.consequences = consequences;
                            applyFilter();         
                        });
                    };
                    getconsequenceList();

                    scope.$watch('source', function () {
                        applyFilter();
                    });


                    function applyFilter(){
                        if (scope.filters && scope.filters.length > 0 && scope.consequences) {
                            var identifiers = [];
                            var selectedFilter = scope.filters[0];
                            if (selectedFilter && selectedFilter.items.length > 0) {

                                selectedFilter.items.forEach(item => {
                                    identifiers.push(parseInt(item));
                                });
                                selectedFilter.items = identifiers;
                            }
                            
                            for (var k = 0; k < scope.consequences.length; k++) {
                                var index = -1;
                                if(selectedFilter.items.length > 0 ){
                                    index = selectedFilter.items.indexOf(scope.consequences[k].id);
                                }
                                if (index > -1){
                                    scope.consequences[k].include = true;
                                } else {
                                    scope.consequences[k].include = false;
                                }                     
                            }

                            var selected = $filter('filter')(scope.consequences, { include: true });
                            if (selected.length === scope.consequences.length && selected.length === 0) {
                                scope.includeAll = true;
                            } else {
                                scope.includeAll = false;
                            }
                        } else if (scope.consequences) {
                            for (var i = 0; i < scope.consequences.length; i++) {
                                scope.consequences[i].include = true;                     
                            }
                        }
                    }

                    scope.deselectAll = function (include) {
                        for (var k = 0; k < scope.consequences.length; k++) {
                            scope.consequences[k].include = include;                     
                        }
                        scope.modifyFilter();
                    };

                    scope.modifyFilter = function(){
                        var selected = $filter('filter')(scope.consequences, { include: true });
                        if(selected.length === scope.consequences.length){
                            scope.includeAll = true;
                            scope.filters = null;
                        } else {
                            scope.includeAll = false;
                            var selectedItems = [];
                            for(var i=0; i < selected.length; i++){
                                 selectedItems.push(selected[i].id);
                            }
                            scope.filters = [{
                                sourceColumn: "lossTypeId",
                                operator: "InList",
                                items: selectedItems
                            }];
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: "lossTypeId", filter: scope.filters });
                    };

                }
            }
        }]);
