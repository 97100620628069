import { getUserProfile } from "@common/stores/userStore"
// Controller for the actual modal
const DigiLean = angular.module('DigiLean')
DigiLean.controller('learningController', ['$scope','$filter', 'languageAdminService', 'learningPublicService', 'navigationService',
    function ($scope, $filter, languageAdminService, learningPublicService, navigationService) {

        $scope.isAllLanguagesSelected = true

        $scope.hasLearningModule = navigationService().hasModule("LEARNING")
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasLearningModule = navigationService().hasModule("LEARNING")
        })

        $scope.languages = languageAdminService().getSupportedLanguages()
        
        // For now all languages are selected until a smart search with AI is implemented
        /*const up = getUserProfile()
        if (up.preferredLanguage) {
            $scope.preferredLanguage = up.preferredLanguage
            //$scope.isAllLanguagesSelected = false For now all languages are selected until a smart search with AI is implemented
        } else
            $scope.isAllLanguagesSelected = true
    */

        // Get materail
        $scope.allMaterials = []
        $scope.materials = []
        learningPublicService.getPublishedMaterials().then(function (materials) {
            $scope.allMaterials = materials
            identifyNew(materials);
            filter()
        })

        $scope.toggleLanguage = function (language) {
            if (language == "all") {
                $scope.isAllLanguagesSelected = true
                $scope.preferredLanguage = ""
            } else {
                $scope.isAllLanguagesSelected = false
                $scope.preferredLanguage = language
            }
            filter()
        }

        $scope.isAllSourcesSelected = true
        $scope.isInternalSelected = false
        $scope.isExternalSelected = false

        $scope.toggleContentSource = function (source) {
            if (source == "all") {
                $scope.isAllSourcesSelected = true
                $scope.isInternalSelected = false
                $scope.isExternalSelected = false
            }
            if (source == "internal") {
                $scope.isInternalSelected = true
                $scope.isAllSourcesSelected = false
                $scope.isExternalSelected = false
            }
            if (source == "external") {
                $scope.isExternalSelected = true
                $scope.isAllSourcesSelected = false
                $scope.isInternalSelected = false
                
            }
            filter()
        }
/*
        $scope.getLink = function(material) {
            // "learning/internal/1?pageid=0 learningexternal({id:material.id, pageid: 0})"
            let link = "/#/learning/"
            
            link += material.isExternal ? "external" : "internal"
            if(material.isExternal || material.externalId){
                var externalId = parseInt(material.externalId);
                link += `/${externalId}?pageid=0`
            } else {
                link += `/${material.id}?pageid=0`
            }     
            return link
        }*/

        function getMaterialLink(material) {
            // "learning/internal/1?pageid=0 learningexternal({id:material.id, pageid: 0})"
            let link = "/#/learning/"
            link += material.isExternal ? "external" : "internal"
            link += `/${material.id}?pageid=0`
            return link
        }

        function filter() {
            // Filter on language
            var filter = filterOnLanguage($scope.allMaterials)
            var filteredMaterials = filterOnSource(filter)
            if(filteredMaterials && filteredMaterials.length > 0){
                for (var i=0; i<filteredMaterials.length; i++){
                    filteredMaterials[i].materiallink = getMaterialLink(filteredMaterials[i]);
                }
            }
            $scope.materials = filteredMaterials
        }

        function filterOnLanguage(materials) {
            if ($scope.isAllLanguagesSelected)
                return materials
            var filtered =  $filter('filter')(materials, { language: $scope.preferredLanguage}, true)
            return filtered
        }

        function filterOnSource(materials) {
            if ($scope.isAllSourcesSelected)
                return materials
            var filtered = []
            materials.forEach(material => {
                if ($scope.isInternalSelected && !material.isExternal)
                    filtered.push(material)
                
                if ($scope.isExternalSelected && material.isExternal)
                    filtered.push(material)
            })
            return filtered
        }

        function identifyNew(materials) {
            var sortedList = []
            var thirtyDaysSince = moment.utc().subtract(31, 'days')
            for (var i = 0; i < materials.length; i++) {
                // all materials not older than 30-31 days is automatic new
                if (thirtyDaysSince.isBefore(moment(materials[i].publishDate)))
                    materials[i].isNew = true

                // at least two materials will be categorized as new
                if (i == 0 || i == 1) {
                    sortedList.push(materials[i])
                } else {
                    var firstInList = moment(sortedList[0].publishDate)
                    var secondInList = moment(sortedList[1].publishDate)
                    var currentMaterial = moment(materials[i].publishDate)
                    var isAfterCheck = currentMaterial.isAfter(firstInList)
                    if (isAfterCheck) {
                        sortedList[0] = materials[i]
                    } else if (currentMaterial.isAfter(secondInList)) {
                        sortedList[1] = materials[i]
                    }
                }
            }
            if (sortedList[0]) {
                sortedList[0].isNew = true
            }
            if (sortedList[1]) {
                sortedList[1].isNew = true
            }
        }

    }])