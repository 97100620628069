angular
    .module('DigiLean')
    .controller("learningPageCommentController", ['$scope', '$uibModalInstance', 'selectedPage', 'material', 
        function ($scope, $uibModalInstance, selectedPage, material) {
            $scope.selectedPageId = selectedPage.id
            $scope.materialId = material.id
            $scope.pagetitle = selectedPage.title

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel')
            }

        }])
