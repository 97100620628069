angular
    .module('DigiLean')
    .directive("deviationCategoriesSelector", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'deviationCategoriesSelector.html',
                restrict: 'E',
                scope: {
                    'categories': '=',
                    'selectedCategories': '=',
                },
                link: function (scope, element, attrs) {
                    scope.$watch("categories", function (categories) {
                        setSelectedCategories();
                    });

                    scope.$watch("selectedCategories", function () {
                        // retrieve and match with categories.
                        setSelectedCategories();
                    });

                    function setSelectedCategories() {
                        if (!scope.categories) return;
                        if (!scope.selectedCategories) return;
                        for (let index = 0; index < scope.categories.length; index++) {
                            const category = scope.categories[index];
                            var isSelected = $filter('filter')(scope.selectedCategories, { id: category.id }, true);
                            if (isSelected.length == 0) {
                                category.isSelected = false;
                            } else {
                                category.isSelected = true;
                            }
                            // also match subcategories
                            for (let index = 0; index < category.children.length; index++) {
                                const subCategory = category.children[index];
                                var isSelected = $filter('filter')(scope.selectedCategories, { id: subCategory.id }, true);
                                if (isSelected.length == 0) {
                                    subCategory.isSelected = false;
                                } else {
                                    subCategory.isSelected = true;
                                }
                                
                            }
                        }
                    }

                    scope.toggleCategory = function(category) {
                        // Mark parent as selected
                        if (category.isSelected) {
                            addSelectedCategory(category);
                        } else {
                            removeSelectedCategory(category);
                            if (category.children) {
                                for (let index = 0; index < category.children.length; index++) {
                                    const subCategory = category.children[index];
                                    subCategory.isSelected = false;
                                    removeSelectedCategory(subCategory);
                                }
                            }
                        }

                    }
                    scope.toggleSubCategory = function(subCategory) {
                        // Mark parent as selected
                        var parent = $filter('filter')(scope.categories, { id: subCategory.parentId }, true);
                        if (parent.length > 0) {
                            parent[0].isSelected = true;
                            addSelectedCategory(parent[0]);
                        }
                        if (subCategory.isSelected) {
                            addSelectedCategory(subCategory);
                        } else {
                            removeSelectedCategory(subCategory);

                        }
                    }

                    function addSelectedCategory(category) {
                        var exists = $filter('filter')(scope.selectedCategories, { id: category.id }, true);
                        if (exists.length == 0) {
                            scope.selectedCategories.push(category);
                        }
                    }

                    function removeSelectedCategory(category) {
                        var exists = $filter('filter')(scope.selectedCategories, { id: category.id }, true);
                        if (exists.length > 0) {
                            var index = scope.selectedCategories.indexOf(exists[0]);
                            scope.selectedCategories.splice(index, 1);
                        }
                    }

                }
            }
        }]);
