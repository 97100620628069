// TODO: check if in use

angular
    .module('DigiLean')
    .directive("teammemberimprovement", ['$uibModal', 'boardTaskService', 'suggestionService',
        function ($uibModal, boardTaskService, suggestionService) {
            return {
                templateUrl: 'teammemberimprovement.html',
                restrict: 'E',
                scope: {
                    'user': '='
                },
                link: function (scope, element, attrs) {
                    scope.tasks = [];

                    scope.improvementOverdueCount = 0;
                    scope.improvementTodayCount = 0;
                    scope.improvementFutureCount = 0;

                    scope.$watch("user", function (user) {         
                        if(user){
                            scope.user = user;
                            getUserTasks(user.userId);
                        }
                    });

                    scope.openImprovement = function (improvementid) {

                        suggestionService().get(improvementid).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    };

                    function getUserTasks(userId) {
                        boardTaskService().getUserTasks(userId).then(function (data) {

                            data.overdue.forEach(function (element, index, array) {
                                if(element.taskType === 'Improvement'){
                                    scope.improvementOverdueCount = scope.improvementOverdueCount + 1;
                                    element.dateStyleClass = "overdue";
                                    scope.tasks.push(element);
                                }
                            });
                            data.today.forEach(function (element, index, array) {
                                if (element.taskType === 'Improvement'){
                                    scope.improvementTodayCount = scope.improvementTodayCount + 1;
                                    scope.tasks.push(element);
                                }
                            });
                            data.future.forEach(function (element, index, array) {
                                if (element.taskType === 'Improvement'){
                                    scope.improvementFutureCount = scope.improvementFutureCount + 1;
                                    scope.tasks.push(element);
                                }

                            });
                            scope.improvementCount = scope.improvementOverdueCount + scope.improvementTodayCount + scope.improvementFutureCount;
                        });
                    }

                    scope.newPieChart = {
                        data: [0, 0, 0, 0], 
                        options: {
                            radius: 18,
                            fill: ["#f3f3f4"],
                        }
                    };
                    scope.planPieChart = {
                        data: [1, 1, 1, 1], 
                        options: {
                            radius: 18,       
                            fill: ["#23c6c8", "#ffffff", "#ffffff","#ffffff"],
                        }
                    };
                    scope.doPieChart = {
                        data: [1, 1, 1, 1], 
                        options: {
                            radius: 18,
                            fill: ["#23c6c8", "#23c6c8", "#ffffff","#ffffff"],
                        }
                    };
                    scope.actPieChart = {
                        data: [1, 1, 1, 1], 
                        options: {
                            radius: 18,
                            fill: ["#23c6c8", "#23c6c8", "#23c6c8", "#23c6c8"]
                        }
                    };
                }
            }
        }]);
