import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .controller("taskListModalController", ['$scope', '$uibModalInstance', '$uibModal', '$filter', 'boardTaskService', 'authService', 'projectId',
        function ($scope, $uibModalInstance, $uibModal, $filter, boardTaskService, authService, projectId) {

            $scope.tasks = [];
            $scope.isAdmin = authService.hasRole("Admin");
            var projectBoards = [];
            $scope.projectId = projectId;

            boardTaskService().getTasksByProject(projectId).then(function (tasks) {
                $scope.tasks = tasks; 
                if($scope.tasks && $scope.tasks.length > 0) {
                    boardService.getProjectBoards(projectId).then(function(boards) {
                        projectBoards = boards;
                        for (var i=0; i<$scope.tasks.length; i++){
                            var board = $filter('filter')(boards, { id: $scope.tasks[i].boardId }, true);
                            if (board.length == 1) {
                                $scope.tasks[i].board = board[0];                
                            }                   
                        }
                    });
                    setDateStyle($scope.tasks);
                }
            });

            /*
            $scope.subscribe('NewTask', function (task) {
                var board = $filter('filter')(projectBoards, { id: task.boardid  });
                if (board.length > 0) {
                    if(task.boardDate){
                        task.boardDate = moment(task.boardDate).toDate();
                    }
                    task.board = board[0]; 
                    if(task.status != 'OK' ){
                        task.dateStyleClass = getDateStyle(task.boardDate);
                    } 
                    $scope.tasks.unshift(task);
                }
            });*/
            $scope.subscribe('TaskAssigned', function (status) {
                var task = $filter('filter')($scope.tasks, { id: status.taskId });
                if (task.length > 0) {  
                    $scope.$apply(function () {
                        task[0].responsibleUser = status.userName;
                    });           
                }
            });
            $scope.subscribe('TaskDeleted', function (id) {
                var task = $filter('filter')($scope.tasks, { id: id });
                if (task.length > 0) {
                    $scope.$apply(function () {
                        var index = $scope.tasks.indexOf(task[0]);
                        if (index > -1) {
                            $scope.tasks.splice(index, 1);
                        }
                    });
                }
            });
            $scope.subscribe('TaskStatusUpdated', function (status) {
                var task = $filter('filter')($scope.tasks, { id: status.taskId });
                if (task.length > 0) {
                    $scope.$apply(function () {
                        task[0].status = status.status;
                        if (task[0].status != 'OK') {
                            task[0].dateStyleClass = getDateStyle(task[0].boardDate);
                        } else {
                            task[0].dateStyleClass = "";
                        }
                    });
                }
            });
            $scope.subscribe('TaskDateMoved', function (moveSettings) {
                var task = $filter('filter')($scope.tasks, { id: moveSettings.taskId  });
                if (task.length > 0) {
                    $scope.$apply(function () {
                        task[0].boardDate = moment(moveSettings.boardDate).toDate();
                        if (task[0].status != 'OK') {
                            task[0].dateStyleClass = getDateStyle(task[0].boardDate);
                        }
                    });
                }
            });
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            function addTask(task, board){
                if(task.boardDate){
                    task.boardDate = moment(task.boardDate).toDate();
                }
                task.board = board; 
                if(task.status != 'OK' ){
                    task.dateStyleClass = getDateStyle(task.boardDate);
                } 
                $scope.tasks.unshift(task);
            }

            $scope.add = function () {

                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newtasktoprojecttasklist.html', 
                    controller: 'newtasktoprojecttasklistController',
                    resolve: {
                        projectId: function () {
                            return $scope.projectId;
                        }
                    }         
                });
                modalInstance.result.then(function (task) {
                    var board = $filter('filter')(projectBoards, { id: task.boardid  });
                    if (board.length > 0) {
                        addTask(task, board[0]);
                    } else {
                        if(task.status != 'OK' ){
                            task.dateStyleClass = getDateStyle(task.boardDate);
                        } 
                        $scope.tasks.unshift(task);
                    }
                }, function () {
        
                });
    
            }

            

            $scope.openTask = function (task) {
                if($scope.isAdmin){
                    boardTaskService().get(task.id).then(function (task) {
                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardTask.html',
                            controller: 'boardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                activeTab: function () {
                                    return "Info";
                                },
                                options: function () {
                                    var options = {
                                        archiveAvailable: false
                                    }
                                    return options;
                                }
                            }
                        });
                    });
                }
            }

            function setDateStyle(items){
                items.forEach(function (item) {
                    if(item.status != 'OK' ){
                        item.dateStyleClass = getDateStyle(item.boardDate);
                    }
                });
            }

            function getDateStyle(date) {
                if (!date) return "";
                // Overdue check

                var overdue = "";
                var duration = moment.duration(moment().diff(moment(date)));
                var days = duration.asDays();
                if (days > 1) {
                    overdue = "overdue";
                }
                return overdue;
            }
        }]);