angular
    .module('DigiLean')
    .controller("projectListAdminController", ['$scope', '$translate', '$uibModal', '$filter', 'NgTableParams', 'projectService', 'modalService', '$state',
        function ($scope, $translate, $uibModal, $filter, NgTableParams, projectService, modalService, $state) {

            $scope.yearlyTimespan = true;
            $scope.totalProjects = 0;

            var translateFilter = $translate.instant('COMMON_FILTER') + "...";
            
            $scope.nameFilter = {
                name: {
                  id: "text",
                  placeholder: translateFilter,
                }
              };

              $scope.numberFilter = {
                projectNumber: {
                  id: "text",
                  placeholder: translateFilter,
                }
              };

              $scope.customerFilter = {
                projectCustomerName: {
                  id: "text",
                  placeholder: translateFilter,
                }
              };

              $scope.ownerFilter = {
                owner: {
                  id: "text",
                  placeholder: translateFilter,
                }
              };

            projectService().getList().then(function (projects) {
                $scope.totalProjects = projects.length;

                angular.forEach(projects, function (project) {
                    if (project.projectCustomer) {
                        project.projectCustomerName = project.projectCustomer.name;
                    }
                    project.statusorder = getStatusOrder(project.status);

                })
                $scope.projects = projects;
                $scope.tableParams = new NgTableParams({}, { dataset: $scope.projects });
            });

            $scope.addProject = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'projectNewModal.html',
                    controller: 'projectNewController',
                    resolve: {
                        existing: function () {
                            return $scope.projects;
                        }
                    }
                });

                modalInstance.result.then(function (newproject) {

                    if (newproject) {

                        projectService().addProject(newproject).then(function (createdProject) {

                            $state.go('selectedProject', { id: createdProject.id }, { reload: false });
                        });
                    }
                }, function () {
                });
            }


            $scope.delete = function () {

                $scope.showTeamBoards = false;
            }

            $scope.delete = function (project) {
                $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_PROJECT_CONFIRMATION']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.COMMON_DELETE,
                        headerText: translations.COMMON_DELETE + ' ' + project.name + '?',
                        bodyText: translations.ADMIN_MESSAGE_DELETE_PROJECT_CONFIRMATION
                    };

                    modalService.showModal({}, modalOptions).then(function (result) {
                        projectService().deleteProject(project.id).then(function (deletedProject) {
                        });
                    });

                });
            }

            function getStatusOrder(dbstatus) {
                switch (dbstatus) {
                    case "ONHOLD":
                        return 1;
                    case "New":
                        return 2;
                    case "PROSPECT":
                        return 3;
                    case "NOTSTARTED":
                        return 4;
                    case "INPROGRESS":
                        return 5;

                    case "CLOSED":
                        return 6;
                    default:
                        return 0;
                }
            }
            $scope.subscribe("ProjectDeleted", function (payLoad) {
                var foundProject = null;
                var foundProject = $filter('filter')($scope.projects, { id: payLoad.id });
                if (foundProject && foundProject.length === 1) {
                    var projectItem = foundProject[0];
                    projectItem.cssState = "animated slideOutRight"; // Add animation
                    var index = $scope.projects.indexOf(projectItem);
                    if (index > -1) {
                        $scope.projects.splice(index, 1);
                        $scope.totalProjects = $scope.projects.length;
                        $scope.tableParams = new NgTableParams({}, { dataset: $scope.projects });
                    }

                }
            });

        }]);



