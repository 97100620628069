import * as assetService from "@common/services/assetService"
import "./assetSelectorDropdown.css"
angular
    .module('DigiLean')
    .directive("assetSelectorDropdown", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'assetSelectorDropdown.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'userid': '=', //Fjerne denne?
                    'ismandatory': '=',
                    'changeHandler': '&',
                    'allowBunitSelect': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.assets = null;
                    scope.asset = null;

                    scope.$watch("model", function () {
                        if(scope.model && scope.model.assets){
                            scope.assets = scope.model.assets
                            setCssLevel()
                        } 
                        if(scope.model && scope.model.assetId){
                            if(!scope.assets){
                                assetService.getList().then(function (assets) {
                                    scope.assets = assets;
                                    setCssLevel()
                                    setAsset(scope.model.assetId)
                                })
                            } else {
                                setAsset(scope.model.assetId)
                            }
                        } else {
                            scope.asset = null;
                        }
                    });

                    function setCssLevel() {
                        for (var k = 0; k < scope.assets.length; k++) {
                            scope.assets[k].cssLevel = "level-" + scope.assets[k].level
                        }
                    }
                    function setAsset(assetId){
                        var asset = scope.assets.find(a => a.id == assetId)
                        if (asset)
                            scope.asset = asset
                    }
                    

                    scope.selectAsset = function (asset, $event) {
                        if (!scope.allowBunitSelect && asset.assetType == "BUSINESSUNIT") {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return false
                        }

                        scope.asset = asset;
                        if(asset){
                            scope.assetid = asset.id;
                        } else {
                            scope.assetid = null;
                        }
                        var updateinfo = {
                            model: scope.model,
                            asset: scope.asset
                        }  
                        scope.changeHandler({assetinfo: updateinfo})
                    };
                }
            }
        }]);