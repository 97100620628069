﻿import * as assetService from "@common/services/assetService"
import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('boardSettingsController',
    ['$scope', '$uibModalInstance', 'navigationService', 'options',
        function ($scope, $uibModalInstance, navigationService, options) {

            $scope.hasProjectModule = navigationService().hasModule("PROJECT");
            $scope.useProjectGroup = true;
            $scope.selectedOwner = {};
            $scope.isProcessing = false;
            $scope.participants = [];
            $scope.selectedAssetId = null;
            $scope.selectedAssetType = "";
            $scope.name = "";
            $scope.description = "";
            $scope.isPublic = false;

            if (options.targetType == "Board") {
                boardService.getBoardInfo(options.targetId).then(function (board) {
                    $scope.board = board;
                    $scope.name = board.name;
                    $scope.description = board.description;
                    $scope.assetId = board.assetId;
                    $scope.selectedAssetId = board.assetId;
                    $scope.isPublic = board.isPublic;
                })
            }
            if (options.targetType == "Dashboard") {
                dashboardService.get(options.targetId).then(function (dashboard) {
                    $scope.dashboard = dashboard;
                    $scope.name = dashboard.title;
                    $scope.description = dashboard.description;
                    $scope.assetId = dashboard.assetId;
                    $scope.selectedAssetId = dashboard.assetId;
                    $scope.isPublic = dashboard.isPublic;
                })
            }
            if(options.includeProjects == false || options.includeCustomGroup == false){
                $scope.memberselectoroptions = {};
                if(options.includeProjects == false){
                    $scope.memberselectoroptions.includeProject = false;                    
                }
                if(options.includeCustomGroup == false){
                    $scope.memberselectoroptions.includeCustomGroup = false;                    
                }
            }

            $scope.memberSelectionChanged = function (selection) {
                $scope.selectedAssetId = selection.assetId;
                $scope.participants = selection.participants;
                $scope.selectedAssetType = selection.assetType;
            }
            $scope.nameChanged = function(name) {
                $scope.name = name;
            }

            $scope.descriptionChanged = function(description) {
                $scope.description = description;
            }

            $scope.setPublicStatus = function() {
                $scope.isPublic = !$scope.isPublic;
            }

            $scope.ok = function () {
                $scope.isProcessing = true;

                // If new asset, we need to create it then set it to board.

                if ($scope.selectedAssetType == "CUSTOMIZED") {
                    var participants = [];
                    $scope.participants.forEach(p => {
                        var participant = {
                            userId: p.userId,
                            role: "Member"
                        }
                        participants.push(participant);
                    });
                    if ($scope.selectedAssetId == 0) {
                        var customAsset = {
                            name : $scope.name,
                            participants : participants
                        }
                        assetService.createCustomAsset(customAsset).then(function(asset){
                            $scope.selectedAssetId = asset.id;
                            updateBoard();
                        });
                    } else {
                        // Update list of participants
                        assetService.updateCustomAssetMembers($scope.selectedAssetId, participants).then(function(asset){
                            updateBoard();
                        });
                    }
                    
                } else {
                    updateBoard();
                }

            };

            var updateBoard = function() {
                

                var board = {
                    description: $scope.description,
                    isPublic: $scope.isPublic,
                    assetId: $scope.selectedAssetId,
                }
                if ($scope.selectedAssetId) {
                    board.assetId = $scope.selectedAssetId;
                }
                if (options.targetType == "Board") {
                    board.name = $scope.name;
                    if (!$scope.selectedAssetId) {
                        board.assetId = $scope.board.assetId;
                    }           
                    board.Owner = $scope.board.owner;
                    board.OwnerDisplayName = $scope.board.ownerDisplayName;
                    board.OwnerUserId = $scope.board.ownerUserId; 

                    boardService.update(options.targetId, board).then(function () {
                        $scope.isProcessing = false;
                        $uibModalInstance.close(board);
                    });
                }
                if (options.targetType == "Dashboard") {
                    var oldAssetId =  $scope.dashboard.assetId;
                    board.title = $scope.name;
                    board.id = options.targetId;
                    board.settings =  $scope.dashboard.settings;
                    dashboardService.saveDashboard(board).then(function () {
                        if(oldAssetId !== $scope.selectedAssetId){
                            dashboardService.updateDashboardAsset(board).then(function () {
                                $scope.isProcessing = false;
                                $uibModalInstance.close(board);
                            });
                        } else {
                            $scope.isProcessing = false;
                            $uibModalInstance.close(board);
                        }
                    });
                }
            }

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);