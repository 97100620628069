var DigiLean = angular.module('DigiLean');
DigiLean.directive("deviationTypeCategories", ['$filter', 'deviationService',
        function ($filter, deviationService) {
            return {
                templateUrl: 'deviationTypeCategories.html',
                restrict: 'E',
                scope: {
                    'deviationTypeId': '=',
                    'deviationTypeTitle': '=',
                },
                link: function (scope, elem, attrs) {

                    scope.categories = [];

                    scope.$watch("deviationTypeId", function () {
                        if (!scope.deviationTypeId) return;
                        loadSettings();
                    });
                    
                    function loadSettings(){
                        scope.deviationType = {title: "RUH"};
                        deviationService().getProblemCategories().then(function (categories) {
                            scope.categories = categories;
                            deviationService().getCategoriesForType(scope.deviationTypeId).then(function(validcategories) {                           
                                var validCategoriesForType = validcategories;
                                for(var i=0; i<scope.categories.length; i++){
                                    scope.categories[i].show = checkShow(scope.categories[i].id, validCategoriesForType);
                                }
                            });
                        });
                    }

                    function checkShow(itemId, validItems){
                        var matching = $filter('filter')(validItems, { problemCategoryId: itemId }, true);
                        if (matching.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }  

                    scope.toggleShow = function (item) {
                        item.problemCategoryId = item.id;
                        if(item.show){
                            item.deviationTypeId = scope.deviationTypeId;
                            deviationService().addDeviationTypeCategory(item).then(function() {                                                  
                            });
                        } else {
                            if(item.id){
                                deviationService().deleteDeviationTypeCategory(item.id).then(function() {                                                  
                                });
                            }
                        }
                    };

                }

            }
        }]);
