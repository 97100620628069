import * as customerService from "@common/services/customers/customerService"

angular.module("DigiLean").factory('dataSourceService', ['$http', '$translate', 'webApiUrl', 'navigationService',
    function ($http, $translate, webApiUrl, navigationService) {
        function dataService() {
            var resource = "/datasources";
            var resourceUrl = webApiUrl + resource;

            return {
                add: function (value) {
                    var url = resourceUrl + "/Add";
                    return $http.post(url, value).then(function (result) {
                        return result.data;
                    });
                },

                addTarget: function (id, value) {
                    var url = resourceUrl + "/" + id + "/addTarget";
                    return $http.post(url, value).then(function (result) {
                        return result.data;
                    });
                },
                deleteTarget: function (id) {
                    var url = resourceUrl + "/deleteDataSourceTarget/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                    },
                update: function (id, value) {
                    var url = resourceUrl + "/Update/" + id;
                    return $http.put(url, value).then(function (result) {
                        return result.data;
                    });
                },
                delete: function (id) {
                    var url = resourceUrl + "/deleteDataSource/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                get: function (id) {
                    var url = resourceUrl + "/get/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getFeatureSource: function (feature) {
                    var url = resourceUrl + "/getFeatureSource/" + feature;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                match: function (model) {
                    var url = resourceUrl + "/Match";
                    return $http.post(url, model).then(function (result) {
                        return result.data;
                    });
                },
                getAllTags: function () {
                    var url = resourceUrl + "/getAllTags/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                addTag: function (id, value) {
                    var url = resourceUrl + "/" + id + "/addTag";
                    return $http.post(url, value).then(function (result) {
                        return result.data;
                    });
                },
                deleteTag: function (datasourceid, tagid) {
                    var url = resourceUrl + "/" + datasourceid + "/deleteTag/" + tagid;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getTargets: function (id) {
                    var url = resourceUrl + "/getTargets/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getTargetSumForPeriod: function (id, options) {
                    var url = resourceUrl + "/getTargetSumForPeriod/" + id;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getList: function () {
                    var url = resourceUrl + "/getlist";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getModuleList: function (currencySetting) {
                    var currency = customerService.getDefaultCurrency()
                    if (currencySetting) {
                        currency = currencySetting;
                    }
                    var list = [];
                    var hasBoard = navigationService().hasModule("BOARD");
                    var hasImprovement = navigationService().hasModule("IMPROVEMENT");
                    var hasDeviation = navigationService().hasModule("DEVIATION");
                    var hasA3 = navigationService().hasModule("A3");
                    var hasProject = navigationService().hasModule("PROJECT");
                    var hasStrategy = navigationService().hasModule("STRATEGY");
                    var translationNumber = $translate.instant('COMMON_NUMBER');

                    if (hasBoard) {
                        var boardGroupElements = [
                                {label: $translate.instant('COMMON_ASSET'),
                                sourceColumn: "assetId",
                                type: "asset"
                                },
                                {label: $translate.instant('COMMON_CREATED_BY'),
                                sourceColumn: "createdByUserId",
                                type: "user"
                                },
                                {label: $translate.instant('COMMON_OWNER'),
                                sourceColumn: "ownerUserId",
                                type: "user"
                                }
                        ];
                        var newboards = {
                            id: "NewBoard",
                            title: $translate.instant('COMMON_TEAM_BOARDS'),
                            description: $translate.instant('DASHBOARD_BOARD_NEW_DESC'),
                            valueElement: {unit: translationNumber, 
                                        label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: boardGroupElements
                        };
                        var taskGroupElements = [
                            {label: $translate.instant('COMMON_TEAM_BOARD'),
                            sourceColumn: "boardId",
                            type: "board"
                            },
                            {label: $translate.instant('COMMON_RESPONSIBLE'),
                            sourceColumn: "responsibleUserId",
                            type: "user"
                            },
                            {label: $translate.instant('COMMON_TAG'),
                            sourceColumn: "tasktag",
                            type: "tasktag"
                            }
                        ];
                        var taskStatusElement = {label: $translate.instant('COMMON_STATUS'),
                                                sourceColumn: "status",
                                                type: "boardstatus"
                                                };
                        var allTaskGroupElements = taskGroupElements.slice();
                        allTaskGroupElements.push(taskStatusElement);
                        var tasks = {
                            id: "Tasks",
                            title: $translate.instant('COMMON_TASKS'),
                            description: $translate.instant('DASHBOARD_TASK_DESC'),
                            valueElement: {unit: translationNumber, 
                                label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: allTaskGroupElements
                        };
                        var completedTasks = {
                            id: "CompletedTasks",
                            title: $translate.instant('COMMON_NUMBER_OF_COMPLETED_TASKS'),
                            description: $translate.instant('COMMON_NUMBER_OF_COMPLETED_TASKS_DESC'),
                            valueElement: {unit: translationNumber, 
                                            label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: taskGroupElements
                        };
                        list.push(newboards);
                        //list.push(tasks);
                        //list.push(completedTasks);
                    }
                    if (hasImprovement) {
                        var elements = [
                                {label: $translate.instant('COMMON_ASSET'),
                                sourceColumn: "assetId",
                                type: "asset"
                                },
                                {label: $translate.instant('COMMON_CATEGORY'),
                                sourceColumn: "categoryId",
                                type: "improvementcategory"
                                },
                                {label: $translate.instant('COMMON_SUGGESTED_BY'),
                                sourceColumn: "suggestedByUserId",
                                type: "user"
                                },
                                {label: $translate.instant('IMPROVEMENT_PRIORITY'),
                                sourceColumn: "priorityStatus",
                                type: "prioritystatus"
                                }
                        ];
                        
                        var newElements = [elements[0], elements[1], elements[2]];

                        if(hasProject){
                            elements.push({label: $translate.instant('PROJECT_PROJECT'),
                            sourceColumn: "projectId",
                            type: "project"
                            });

                            newElements.push({label: $translate.instant('PROJECT_PROJECT'),
                            sourceColumn: "projectId",
                            type: "project"
                            });
                        }

                        if(hasStrategy){
                            elements.unshift({label: $translate.instant('COMMON_GOAL'),
                            sourceColumn: "strategyGoalId",
                            type: "strategygoal"
                            });

                            newElements.unshift({label: $translate.instant('COMMON_GOAL'),
                            sourceColumn: "strategyGoalId",
                            type: "strategygoal"
                            });
                        }
                        // The improvement id's should not be changed since it is used as an identificator
                        // in graph components. (e.g. startsWith("Improvement"))
                        var improvementSuggestionCompleted = {
                            id: "ImprovementSuggestionCompleted",
                            title: $translate.instant('DASHBOARD_COMPLETED_IMPROVEMENTS'),
                            description: $translate.instant('DASHBOARD_IMPROVEMENT_SUGGESTION_COMPLETED_DESC'),
                            valueElement: {unit: translationNumber, 
                                        label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: elements
                        };
                        var improvementSuggestion = {
                            id: "ImprovementSuggestion",
                            title: $translate.instant('COMMON_SUGGESTIONS'),
                            description: $translate.instant('DASHBOARD_IMPROVEMENT_SUGGESTION_OPEN_DESC'),
                            valueElement: {unit: translationNumber, 
                                    label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN'
                        };
                        var improvementSuggestionNew = {
                            id: "ImprovementSuggestionNew",
                            title: $translate.instant('IMPROVEMENT_NEW_SUGGESTIONS'),
                            description: $translate.instant('DASHBOARD_IMPROVEMENT_SUGGESTION_NEW_DESC'),
                            valueElement: {unit: translationNumber, 
                                        label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: newElements
                        };
                        list.push(improvementSuggestionNew);
                        list.push(improvementSuggestionCompleted);
                        
                        //list.push(improvementSuggestion);
                    }
                    if (hasDeviation) {
                        var deviationGroupElements = [
                            {label: $translate.instant('COMMON_REPORTED_BY_GROUP'),
                            sourceColumn: "assetId",
                            type: "asset"
                            },
                            {label: $translate.instant('COMMON_REPORTED_BY'),
                            sourceColumn: "createdByUserId",
                            type: "user"
                            },
                            {label: $translate.instant('DEVIATION_FOLLOW_UP_GROUP'),
                            sourceColumn: "followUpAssetId",
                            type: "followupasset"
                            },
                            {label: $translate.instant('COMMON_OWNER'),
                            sourceColumn: "ownerUserId",
                            type: "user"
                            },
                            {label: $translate.instant('COMMON_AREA'),
                            sourceColumn: "areaId",
                            type: "area"
                            },
                            {label: $translate.instant('COMMON_CATEGORY'),
                            sourceColumn: "problemCategoryId",
                            type: "problemcategory"
                            },
                            {label: $translate.instant('DEVIATION_SEVERITY'),
                            sourceColumn: "severity",
                            type: "deviationseverity"
                            },
                            {label: $translate.instant('COMMON_DEVIATION_TYPES'),
                            sourceColumn: "deviationTypeId",
                            type: "deviationtype"
                            }
                    ];
                    if(hasProject){
                        deviationGroupElements.push({label: $translate.instant('PROJECT_PROJECT'),
                        sourceColumn: "projectId",
                        type: "project"
                        });
                    }
 
                    var deviationConsequenceGroupElements = [
                        {label: $translate.instant('COMMON_DEVIATION_TYPES'),
                            sourceColumn: "deviationTypeId",
                            type: "deviationtype"
                        },
                        {label: $translate.instant('COMMON_ASSET'),
                        sourceColumn: "assetId",
                        type: "asset"
                        },
                        {label: $translate.instant('COMMON_AREA'),
                        sourceColumn: "areaId",
                        type: "area"
                        },
                        {label: $translate.instant('DEVIATION_CONSEQUENCE'),
                        sourceColumn: "lossTypeId",
                        type: "consequence"
                        },
                        {label: $translate.instant('ADMIN_DATA_DATASOURCE_UNIT'),
                        sourceColumn: "unit",
                        type: "unit"
                        },
                    ];
                    if(hasProject){
                        deviationConsequenceGroupElements.push({label: $translate.instant('PROJECT_PROJECT'),
                        sourceColumn: "projectId",
                        type: "project"
                        });
                    }
                    
                    var deviationCompleted = {
                            id: "DeviationCompleted",
                            title: $translate.instant('DASHBOARD_COMPLETED_DEVIATIONS'),
                            description: $translate.instant('DASHBOARD_DEVIATION_COMPLETED_DESC'),
                            valueElement: {unit: translationNumber, 
                                        label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: deviationGroupElements
                        };
                        var deviationNew = {
                            id: "DeviationNew",
                            title: $translate.instant('DEVIATION_NEW_DEVIATIONS'),
                            description: $translate.instant('DASHBOARD_DEVIATION_NEW_DESC'),
                            valueElement: {unit: translationNumber, 
                                label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: deviationGroupElements
                        };
                        var deviationConsequence = {
                            id: "DeviationConsequence",
                            title: $translate.instant('DEVIATION_DATASOURCE_CONSEQUENCE'),
                            description: $translate.instant('DEVIATION_DATASOURCE_CONSEQUENCE_DESCRIPTION'),
                            valueElement: {},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: deviationConsequenceGroupElements
                        };
                        list.push(deviationNew);
                        list.push(deviationCompleted);
                        list.push(deviationConsequence);
                        
                    }
                    if (hasA3) {
                        var a3GroupElements = [
                                {label: $translate.instant('COMMON_ASSET'),
                                sourceColumn: "assetId",
                                type: "asset"
                                },
                                {label: $translate.instant('COMMON_CATEGORY'),
                                sourceColumn: "categoryId",
                                type: "a3category"
                                },
                                {label: $translate.instant('COMMON_TEMPLATE'),
                                sourceColumn: "a3TemplateId",
                                type: "a3template"
                                },
                                {label: $translate.instant('COMMON_CREATED_BY'),
                                sourceColumn: "createdByUserId",
                                type: "user"
                                },
                                {label: $translate.instant('COMMON_OWNER'),
                                sourceColumn: "ownerUserId",
                                type: "user"
                                }
                        ];
                        

                        var a3Completed = {
                            id: "A3Completed",
                            title: $translate.instant('DASHBOARD_A3_COMPLETED'), 
                            description: $translate.instant('DASHBOARD_A3_COMPLETED_DESC'),
                            valueElement: {unit: translationNumber, 
                                label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: a3GroupElements
                        };
                        var a3New = {
                            id: "A3New",
                            title: $translate.instant('A3_NEW_A3'), 
                            description: $translate.instant('DASHBOARD_A3_NEW_DESC'),
                            valueElement: {unit: translationNumber, 
                                label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: a3GroupElements
                        };
                        var a3ROI = {
                            id: "A3ROI",
                            title: $translate.instant('IMPROVEMENT_ROI') + " A3", 
                            description: $translate.instant('DASHBOARD_ROI_DESC'),
                            valueElement: {unit: "%", label: "ROI"},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: a3GroupElements
                        };
                        var a3Gains = null;
                        var a3PotentialGains = null;
                        
                            a3Gains ={
                                id: "A3INVESTMENTGAINS",
                                title: $translate.instant('A3_MONEY_GAINS'), 
                                description: $translate.instant('DASHBOARD_A3_MONEY_GAINS_DESC'),
                                valueElement: {unit: currency, label: "Gains"},
                                objectSource: 'internal',
                                ownerUserDisplayName: 'DigiLEAN',
                                elements: a3GroupElements
                            };
    
                            a3PotentialGains = {
                                id: "A3POTENTIALINVESTMENTGAINS",
                                title: $translate.instant('A3_POTENTIAL_GAINS'), 
                                description: $translate.instant('DASHBOARD_A3_POTENTIAL_GAINS_DESC'),
                                valueElement: {unit: currency, label: "Gains"},
                                objectSource: 'internal',
                                ownerUserDisplayName: 'DigiLEAN',
                                elements: a3GroupElements
                            };
                            var statusElement = {label: $translate.instant('COMMON_STATUS'),
                                            sourceColumn: "status",
                                            type: "a3status"
                                            };
                        var allA3GroupElement = a3GroupElements.slice();
                        allA3GroupElement.push(statusElement);
                        var a3 = {
                            id: "A3",
                            title: "A3",
                            description: $translate.instant('DASHBOARD_A3_ALL_DESC'),
                            valueElement: {unit: translationNumber, 
                                label: translationNumber},
                            objectSource: 'internal',
                            ownerUserDisplayName: 'DigiLEAN',
                            elements: allA3GroupElement
                        };
                        //list.push(a3);
                        list.push(a3New);
                        list.push(a3Completed);
                        list.push(a3ROI);
                        list.push(a3Gains);
                        list.push(a3PotentialGains); 
                    }
                    return list;
                    
                },
                addElement: function (id, value) {
                    var url = resourceUrl + "/" + id + "/AddElement";
                    return $http.post(url, value).then(function (result) {
                        return result.data;
                    });
                },

                updateElement: function (id, value) {
                    var url = resourceUrl + "/UpdateElement/" + id;
                    return $http.put(url, value).then(function (result) {
                        return result.data;
                    });
                },
                deleteElement: function (id) {
                    var url = resourceUrl + "/DeleteElement/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

            }
        }
        return dataService;
    }]);