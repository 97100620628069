import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("teamSpaceCard", 
        function () {
            return {
                templateUrl: 'teamSpaceCard.html',
                restrict: 'E',
                scope: {
                },
                link: function (scope, elem, attrs) {
                    
                    scope.url = staticFileService.image("teamspace.jpg");

                }
            }
        });
