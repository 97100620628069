import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("actionlistBoards", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'actionlistBoards.html',
                restrict: 'E',
                scope: {
                    'assetid': '=',
                    'boardid': '=',
                    'viewcontext': '=',  
                    'week': '=',
                    'readonly': '@',
                    'filter': '<?'
                },
                link: function (scope, element, attrs) {
                    scope.boards = [];
                    scope.taskCount = 0;
                    scope.taskOverdueCount = 0;

                    scope.$watch("assetid", function () {
                        if (!scope.assetid) return;
                        loadBoards(scope.assetid);
                    });


                    // Subscribe to get notification when a board has changed task status
                    scope.subscribe("BoardActionListStatusChanged", function(status) {
                        // current board
                        var targetBoard = $filter('filter')(scope.boards, { "id" : status.boardId });
                        if (targetBoard.length == 1) {
                            targetBoard[0].taskCount = status.total;
                            targetBoard[0].taskOverdueCount = status.overdue;
                         
                        }
                        calculateTaskCounts(scope.boards)
                    })

                    function calculateTaskCounts(boards) {
                        var total = 0;
                        var overdue = 0;
                        if(boards){
                            for (var t=0; t<boards.length; t++){
                                total += boards[t].taskCount;
                                overdue += boards[t].taskOverdueCount;
                            }
                        }
                        scope.taskCount = total;
                        scope.taskOverdueCount = overdue;
                        var status = {
                            total: scope.taskCount,
                            overdue: scope.taskOverdueCount
                        }
                        scope.publish("BoardActionListCountChanged", status)
                    }

                    function loadBoards(assetId) {
                        // Get boards
                        boardService.getBoardsForAsset(assetId).then(function(boards) {
                            // Find selectedboard and set at top.
                            var selectedBoard = null;
                            for (var i=0; i<boards.length; i++){
                                boards[i].isOpen = false;
                                if (boards[i].id == scope.boardid) {
                                    selectedBoard = boards[i];
                                    selectedBoard.isOpen = true;
                                } else {
                                    
                                }
                                
                            }
                            if (selectedBoard) {
                                var index = boards.indexOf(selectedBoard);
                                boards.splice(index, 1);
                                boards.unshift(selectedBoard);
                            }
                            
                            scope.boards = boards;
                            calculateTaskCounts(scope.boards);
                        });
                    }


                }
            }
        }]);
