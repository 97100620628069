angular
    .module('DigiLean')
    .controller("confirmationDialogController", ['$scope', '$state', '$uibModalInstance', 'proposition',
        function ($scope, $state, $uibModalInstance, proposition) {


            proposition.title.then(x => $scope.title = x);
            proposition.text.then(x => $scope.text = x);
            proposition.okText.then(x => $scope.okText = x);
            proposition.cancelText.then(x => $scope.cancelText = x);


            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
