angular
    .module('DigiLean')
    .directive("strategyBowlerMonthlyActual", ['$filter','$uibModal','strategyService','dataSourceService','dataService',
        function ($filter,$uibModal, strategyService,dataSourceService, dataService) {
            return {
                templateUrl: 'strategyBowlerMonthlyActual.html',
                restrict: 'E',
                scope: {
                    'kpi': '<',
                    'year': '<',
                    'month': '<',
                    'valuechanged': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.onTarget = null;
                    scope.editable = true;
                    scope.showNoteIcon = false;
                    scope.note = null;
                    var dataSource = null;
                    var timePeriod = null;
                    var locale = 'en-US';
                    var yearForValue = null;

                    function setActualValue(value){
                        scope.actual = value;
                        if(scope.actual){
                            scope.actualformatted = scope.actual.toLocaleString(locale);
                        } else {
                            scope.actualformatted = null;
                        }
                    }

                    scope.$watch("kpi", function (kpi) {
                        if (!kpi) return;
                        scope.operator = kpi.operator;
                        scope.kpiid = kpi.id;
                        
                        if (navigator.language)
                            locale = navigator.language.toLowerCase();
                        var value = $filter('filter')(kpi.kpiMonthlyValues, { month: scope.month },true);
                        if (value && value.length > 0) { 
                            setActualValue(value[0].actual);
                            scope.plan = value[0].plan;
                            scope.note = value[0].note;
                            yearForValue = value[0].year;
                            if(!yearForValue) yearForValue = scope.year;
                            showNoteIcon();
                            checkOnTarget();
                        }
                        if(!yearForValue) yearForValue = scope.year;
                    });

                    scope.$watch("valuechanged", function (valuechanged) {
                        if (!valuechanged) return;
                        scope.operator = scope.kpi.operator;
                        scope.kpiid = scope.kpi.id;
                        var value = $filter('filter')(scope.kpi.kpiMonthlyValues, { month: scope.month },true);
                        if (value.length > 0) { 
                            setActualValue(value[0].actual);
                            scope.plan = value[0].plan;
                            scope.note = value[0].note;
                            showNoteIcon();
                            checkOnTarget();
                        }
                    });
                    scope.subscribe("KPIMonthlyValueUpdated", function (update) {
                        if (update.kpiId == scope.kpiid && scope.month == update.month && yearForValue == update.year) {
                            scope.plan = update.plan;
                            if(!scope.kpi.dataSourceId){
                                setActualValue(update.actual);
                            }
                            scope.note = update.note;
                            showNoteIcon();
                            checkOnTarget();
                        }
                    });

                    //Datasource events
                    scope.subscribe("DataValueAdded", function (value) {
                        if(isWithinTimePeriod(value.valueDate)){
                            checkUpdatedDatasourceValue(value.dataSourceId);
                        }           
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if(isWithinTimePeriod(value.valueDate)){
                            checkUpdatedDatasourceValue(value.dataSourceId);
                        } 
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        checkUpdatedDatasourceValue(value.dataSourceId);
                    });

                    function isWithinTimePeriod(valueDate){
                        timePeriod = getTimePeriod(); 
                        var result = false;
                        if(valueDate && timePeriod){ //valid input
                            var timeperiodfrom = moment(timePeriod.from);
                            var timeperiodto = moment(timePeriod.to);
                            if(moment(timeperiodfrom).isBefore(valueDate)
                                && moment(valueDate).isBefore(timeperiodto)){
                                return true;
                            }
                        }
                        return result;
                    }
                    function checkUpdatedDatasourceValue (id) {
                        if (!scope.kpi.dataSourceId) return;
                        if (scope.kpi.dataSourceId !== id) return;
                        var formula = scope.kpi.formula;
                        /*if(scope.kpi.unit == "%"){
                            formula = "average";
                        }*/
                        var options = {
                            timePeriod: getTimePeriod(),
                            filters: scope.kpi.filter,
                            formula: formula
                        };
                        dataService().getSumForPeriod(scope.kpi.dataSourceId, options).then(function (sum) {
                            if(sum) {
                                setActualValue(sum);
                            } else {
                                setActualValue(0);
                            }
                            checkOnTarget();
                        });
                    };

                    function getTimePeriod(){
                        if (timePeriod) return timePeriod;
                        var startDate = moment().set({'year': yearForValue, 'month': scope.month-1, 'date':1}).startOf('day');
                        var endDate = moment(startDate).endOf("month");
                        timePeriod = {
                            from: startDate.utc().toDate().toJSON(),
                            to: endDate.utc().toDate().toJSON()
                        }; 
                        timePeriod = timePeriod;
                        return timePeriod;
                    }

                    function showNoteIcon(){
                        if(!scope.note){
                            scope.showNoteIcon = false;
                        } else {
                            scope.showNoteIcon = true;
                        }
                    }

                    scope.subscribe("KPIUpdated", function (update) {
                        if (update.id == scope.kpiid && update.operator != scope.operator) {
                            scope.operator = update.operator;
                            checkOnTarget();
                        }
                    });

                    function checkDatasourceTargetValid(){
                        if(scope.kpi.dataSourceId){
                            if(yearForValue > moment().year()){
                                scope.onTarget = null;
                            } else if(yearForValue == moment().year() && scope.month > moment().month()+1){
                                scope.onTarget = null;
                            }
                        }   
                    }

                    function checkOnTarget(){
                        if(!scope.actual){
                            scope.onTarget = null;
                        }
                        if(scope.operator && scope.actual != null && scope.plan != null){
                            if(scope.operator == '>='){
                                if(scope.actual >= scope.plan){
                                    scope.onTarget = true;
                                } else {
                                    scope.onTarget = false;
                                }
                                
                            } else if(scope.operator == '<='){
                                if(scope.actual <= scope.plan){
                                    scope.onTarget = true;
                                } else {
                                    scope.onTarget = false;                               
                                }
                                
                            }
                            else if(scope.operator == '>'){
                                if(scope.actual > scope.plan){
                                    scope.onTarget = true;
                                } else {
                                    scope.onTarget = false;
                                }
                            }
                            else if(scope.operator == '<'){
                                if(scope.actual < scope.plan){
                                    scope.onTarget = true;
                                } else {
                                    scope.onTarget = false;                               
                                }                   
                            }
                            checkDatasourceTargetValid(); // remove targetindicatorcolor if datasource is used and this is a future value
                        }
                    }

                    scope.openDataValues = function(){
                        if(scope.kpi.dataSourceId){
                            if(dataSource){
                                openDataValuesPopup(dataSource);
                            } else {
                                dataSourceService().get(scope.kpi.dataSourceId).then(function(dataSource) {
                                    dataSource = dataSource;
                                    openDataValuesPopup(dataSource);
                                });
                            }
                        }                 
                    }

                    function openDataValuesPopup(dataSource){
                        var datasourceoptions = {
                            dataSource: dataSource,
                            filters: scope.kpi.filter,
                            timePeriod: getTimePeriod(),
                            editable: true
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'datavaluesListForm.html',
                            controller: 'datavaluesListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options: function () {
                                    return  datasourceoptions;
                                }
                            }
                        });
                    }

                    scope.updateNote = function(newNote){
                        scope.note = newNote;
                        var kpiMonthlyValue = {
                            KPIId:scope.kpiid,
                            month:scope.month,
                            year: yearForValue,
                            actual: scope.actual,
                            plan: scope.plan,
                            note: newNote
                        }
                        showNoteIcon();

                        strategyService().updateKPIMonthlyValue(kpiMonthlyValue).then(function () {

                        });

                    }
                    scope.clearNote = function(){
                        scope.note = '';
                        var kpiMonthlyValue = {
                            KPIId:scope.kpiid,
                            month:scope.month,
                            year: yearForValue,
                            actual: scope.actual,
                            plan: scope.plan,
                            note: null
                        }
                        showNoteIcon();
                        strategyService().updateKPIMonthlyValue(kpiMonthlyValue).then(function () {

                        });

                    }
                    scope.updateValue = function(newActual){
                        var kpiMonthlyValue = {
                            KPIId:scope.kpiid,
                            month:scope.month,
                            year: yearForValue,
                            actual: newActual,
                            plan: scope.plan,
                            note: scope.note
                        }

                        strategyService().updateKPIMonthlyValue(kpiMonthlyValue).then(function () {

                        });

                    }

                }
            }
        }]);