import angular from "angular"
import { DigiLeanAuthUser } from "@common/model/types"
import { DigiLeanAuthRoles } from "@common/model/types"
import { getAuthUser } from "@common/stores/userStore"
import * as customerService from "@common/services/customers/customerService"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')
DigiLean.component('legacyApiKeys', {
    templateUrl: "legacyApiKeys.html",
    controller: function() {
            let $ctrl = this as NgComponent

            $ctrl.apiKeys = []
            $ctrl.customers = []
            $ctrl.selectedCustomer = null
            $ctrl.isKaizenAdmin = false
            const user: DigiLeanAuthUser = getAuthUser()
            
            async function checkUserRole () {
                if (user.loggedIn && user.roles?.includes(DigiLeanAuthRoles.KaizenAdmin)) {
                    $ctrl.isKaizenAdmin = true
                    const customers = await customerService.getCustomersSimpleForAdmin()
                    $ctrl.customers = customers
                    $ctrl.selectedCustomer = customers.filter(r => r.id === user.customerId)[0]
                    getKeys()
                } else {
                    $ctrl.isKaizenAdmin = false
                    getKeys()
                }
            }

            checkUserRole()

            const getKeys = async () => {
                const keys = await customerService.getApiKeys()
                $ctrl.apiKeys = keys
                return getCustomerName()
            }


            var getCustomerName = function () {
                $ctrl.apiKeys.forEach(k => {
                    var customer = $ctrl.customers.filter(x => x.id === k.customerId)[0]
                    if (customer) {
                        k.customer = customer.name
                    }
                })
            }
        }
})
