import * as staticFileService from "@common/shared/staticFileService"

angular.module('DigiLean').factory('appstoreService', ['$translate', 'authService', 'navigationService',
    function ($translate, authService, navigationService) {
        function appstoreService() {
            return {
                getPopularApps: function () {
                    var list = [
                        "smartactionlist",
                        "singlevalue",
                        "diagrameditor",
                        "targetgraph",
                        "texteditor",
                        "smiley",
                        "multichart",
                    ]

                    var apps = this.getApps();
                    var quickListApps = [];
                    list.forEach(app => {
                        // filter
                        for (let index = 0; index < apps.length; index++) {
                            const element = apps[index];
                            if (element.component == app) {
                                quickListApps.push(element);
                                continue;
                            }
                        }
                    });
                    return quickListApps;
                },

                getApps: function () {
                    var board = $translate.instant("COMMON_BOARD");
                    var project = $translate.instant("COMMON_PROJECT");
                    var customized = $translate.instant("DASHBOARD_CUSTOMIZED");
                    var improvement = $translate.instant("COMMON_IMPROVEMENT");
                    var deviation = $translate.instant("COMMON_DEVIATION");
                    var registration = $translate.instant("COMMON_REGISTRATION");
                    var general = $translate.instant("COMMON_GENERAL");
                    var tasks = $translate.instant("COMMON_TASKS");
                    var strategy = $translate.instant("STRATEGY_STRATEGY");
                    var apps = [
                        {
                            name: $translate.instant("DASHBOARD_SINGLE_VALUE"),
                            component: "singlevalue",
                            category: "KPI",
                            imageUrl: staticFileService.image("singlevalue.jpg"),
                            previewImageUrl: staticFileService.previewImage("singlevalue.jpg"),
                            description: $translate.instant("DASHBOARD_SINGLE_VALUE_DESC"),
                            defaultXSize: 8,
                            defaultYSize: 4
                        },
                        {
                            name: $translate.instant("DASHBOARD_SINGLE_VALUE_TARGET"),
                            component: "singlevaluetarget",
                            category: "KPI",
                            imageUrl: staticFileService.image("singlevaluetarget.jpg"),
                            previewImageUrl: staticFileService.previewImage("singlevaluetarget.jpg"),
                            description: $translate.instant("DASHBOARD_SINGLE_VALUE_TARGET_DESC"),
                            defaultXSize: 8,
                            defaultYSize: 4
                        },
                        {
                            name: $translate.instant("DASHBOARD_TARGET_GRAPH"),
                            component: "targetgraph",
                            category: "KPI",
                            imageUrl: staticFileService.image("targetgraph.jpg"),
                            previewImageUrl: staticFileService.previewImage("targetgraph.jpg"),
                            description: $translate.instant("DASHBOARD_TARGET_GRAPH_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_MIN_MAX_CHART"),
                            component: "minmaxchart",
                            category: "KPI",
                            imageUrl: staticFileService.image("minmaxchart.jpg"),
                            previewImageUrl: staticFileService.previewImage("minmaxchart.jpg"),
                            description: $translate.instant("DASHBOARD_MIN_MAX_CHART_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_TREND_BARCHART"),
                            component: "trendbarchart",
                            category: customized,
                            imageUrl: staticFileService.image("trendbarchart.jpg"),
                            previewImageUrl: staticFileService.previewImage("trendbarchart.jpg"),
                            description: $translate.instant("DASHBOARD_TREND_BARCHART_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_PIE_CHART"),
                            component: "piechart",
                            category: customized,
                            imageUrl: staticFileService.image('piechart.jpg'),
                            previewImageUrl: staticFileService.previewImage('piechart.jpg'),
                            description: $translate.instant("DASHBOARD_PIE_CHART_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },

                        {
                            name: $translate.instant("DASHBOARD_STACKED_BAR_CHART"),
                            component: "barchart",
                            category: customized,
                            imageUrl: staticFileService.image("barchart.jpg"),
                            previewImageUrl: staticFileService.previewImage("barchart.jpg"),
                            description: $translate.instant("DASHBOARD_STACKED_BAR_CHART_DESC"),
                            defaultXSize: 20,
                            defaultYSize: 20
                        },
                        {
                            name: $translate.instant("DASHBOARD_STACKED_GROUPED_BAR_CHART"),
                            component: "stackedhorizantalbarchart",
                            category: customized,
                            imageUrl: staticFileService.image("stackedHorizontalBarChart.jpg"),
                            previewImageUrl: staticFileService.previewImage("stackedHorizontalBarChart.jpg"),
                            description: $translate.instant("DASHBOARD_STACKED_GROUPED_BAR_CHART_DESC"),
                            defaultXSize: 20,
                            defaultYSize: 20
                        },
                        {
                            name:  $translate.instant("DASHBOARD_SPLIT_PACKED_BUBBLE_CHART"), 
                            component: "splitPackedBubbleChart",
                            category: customized,
                            imageUrl: staticFileService.image("splitPackedBubbleChart.jpg"),
                            previewImageUrl: staticFileService.previewImage("splitPackedBubbleChart.jpg"),
                            description: $translate.instant("DASHBOARD_SPLIT_PACKED_BUBBLE_CHART_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 12
                        },
                        
                        {
                            name: "Pareto",
                            component: "pareto",
                            category: customized,
                            imageUrl: staticFileService.image('pareto.jpg'),
                            previewImageUrl: staticFileService.previewImage('pareto.jpg'),
                            description: $translate.instant("DASHBOARD_PARETO_CHART_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_COMPARE_CHART"),
                            component: "comparechart",
                            category: customized,
                            imageUrl: staticFileService.image("comparechart.jpg"),
                            previewImageUrl: staticFileService.previewImage("comparechart.jpg"),
                            description: $translate.instant("DASHBOARD_COMPARE_CHART_DESC"),
                            defaultXSize: 20,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_MULTI_CHART"),
                            component: "multichart",
                            category: customized,
                            imageUrl: staticFileService.image("multichart.jpg"),
                            previewImageUrl: staticFileService.previewImage("multichart.jpg"),
                            description: $translate.instant("DASHBOARD_MULTI_CHART_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_LIVE_TREND"),
                            component: "livetrend",
                            category: customized,
                            imageUrl: staticFileService.image("livetrend.jpg"),
                            previewImageUrl: staticFileService.previewImage("livetrend.jpg"),
                            description: $translate.instant("DASHBOARD_LIVE_TREND_DESC"),
                            defaultXSize: 13,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_RADAR_CHART"),
                            component: "radarchart",
                            category: customized,
                            imageUrl: staticFileService.image('radarchart.jpg'),
                            previewImageUrl: staticFileService.previewImage('radarchart.jpg'),
                            description: $translate.instant("DASHBOARD_RADAR_CHART_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_SINGLE_VALUE_REGISTRATION"),
                            component: "singlevalueregistration",
                            category: registration,
                            imageUrl: staticFileService.image("singlevalueregistration.jpg"),
                            previewImageUrl: staticFileService.previewImage("singlevalueregistration.jpg"),
                            description: $translate.instant("DASHBOARD_SINGLE_VALUE_REGISTRATION_DESC"),
                            defaultXSize: 8,
                            defaultYSize: 4
                        },
                        {
                            name: $translate.instant("BOARD_PRODUCTION_BOARD"),
                            component: "productionboard",
                            category: registration,
                            imageUrl: staticFileService.image("productionboard.jpg"),
                            previewImageUrl: staticFileService.previewImage("productionboard.jpg"),
                            description: $translate.instant("BOARD_PRODUCTION_BOARD_DESCRIPTION"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        },        
                        {
                            name: $translate.instant("DASHBOARD_KPI_GREENCROSS"),
                            component: "greencross",
                            category: registration,
                            imageUrl: staticFileService.image("greencross.jpg"),
                            previewImageUrl: staticFileService.previewImage("greencross.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_GREENCROSS_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "5S",
                            component: "5s",
                            category: registration,
                            imageUrl: staticFileService.image("fives.jpg"),
                            previewImageUrl: staticFileService.previewImage("5s.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_5S_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "A",
                            component: "A",
                            category: registration,
                            imageUrl: staticFileService.image("a.jpg"),
                            previewImageUrl: staticFileService.previewImage("a.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_A_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "C",
                            component: "C",
                            category: registration,
                            imageUrl: staticFileService.image("c.jpg"),
                            previewImageUrl: staticFileService.previewImage("c.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_C_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "D",
                            component: "D",
                            category: registration,
                            imageUrl: staticFileService.image("d.jpg"),
                            previewImageUrl: staticFileService.previewImage("d.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_D_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },{
                            name: "I",
                            component: "I",
                            category: registration,
                            imageUrl: staticFileService.image("i.jpg"),
                            previewImageUrl: staticFileService.previewImage("i.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_I_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "K",
                            component: "K",
                            category: registration,
                            imageUrl: staticFileService.image("k.jpg"),
                            previewImageUrl: staticFileService.previewImage("k.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_K_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "L",
                            component: "L",
                            category: registration,
                            imageUrl: staticFileService.image("l.jpg"),
                            previewImageUrl: staticFileService.previewImage("l.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_L_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "P",
                            component: "P",
                            category: registration,
                            imageUrl: staticFileService.image("p.jpg"),
                            previewImageUrl: staticFileService.previewImage("p.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_P_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: "Q",
                            component: "Q",
                            category: registration,
                            imageUrl: staticFileService.image("q.jpg"),
                            previewImageUrl: staticFileService.previewImage("q.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_Q_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_KPI_DYNAMIC"),
                            component: "dailykpidynamic",
                            category: registration,
                            imageUrl: staticFileService.image("dailykpidynamic.jpg"),
                            previewImageUrl: staticFileService.previewImage("dailykpidynamic.jpg"),
                            description: $translate.instant("DASHBOARD_KPI_DYNAMIC_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_TEXTEDITOR"),
                            component: "texteditor",
                            category: registration,
                            imageUrl: staticFileService.image("texteditor.jpg"),
                            previewImageUrl: staticFileService.previewImage("texteditor.jpg"),
                            description: $translate.instant("DASHBOARD_TEXTEDITOR_DESC"),
                            defaultXSize: 16,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DIAGRAMEDITOR"),
                            component: "diagrameditor",
                            category: registration,
                            imageUrl: staticFileService.image("diagram-editor.jpg"),
                            previewImageUrl: staticFileService.previewImage("diagram-editor-fishbone.jpg"),
                            description: $translate.instant("DASHBOARD_DIAGRAMEDITOR_DESC"),
                            defaultXSize: 16,
                            defaultYSize: 10
                        },{
                            name: $translate.instant("COMMON_REGISTRATION"),
                            component: "registration",
                            category: registration,
                            imageUrl: staticFileService.image("register.jpg"),
                            previewImageUrl: staticFileService.previewImage("register.jpg"),
                            description: $translate.instant("DASHBOARD_REGISTRATION_DESC"),
                            defaultXSize: 20,
                            defaultYSize: 20
                        },
                        {
                            name: $translate.instant("DASHBOARD_DATASOURCE_VALUES_LIST"),
                            component: "datasourcevalueslist",
                            category: general,
                            imageUrl: staticFileService.image("datasourcevalueslist.jpg"),
                            previewImageUrl: staticFileService.previewImage("datasourcevalueslist.jpg"),
                            description: $translate.instant("DASHBOARD_DATASOURCE_VALUES_LIST_DESC"),
                            defaultXSize: 24,
                            defaultYSize: 20
                        },
                        {
                            name: $translate.instant("COMMON_MESSAGES"),
                            component: "messagedisplayer",
                            category: general,
                            imageUrl: staticFileService.image("messagedisplayer.jpg"),
                            previewImageUrl: staticFileService.previewImage("messagedisplayer.jpg"),
                            description: $translate.instant("DASHBOARD_MESSAGE_DISPLAYER_DESCRIPTION"),
                            defaultXSize: 16,
                            defaultYSize: 12
                        },
                        {
                            name: $translate.instant("DASHBOARD_IMAGE_DISPLAYER_TEXT"),
                            component: "imagedisplayer",
                            category: general,
                            imageUrl: staticFileService.image("imagedisplayer.png"),
                            previewImageUrl: staticFileService.previewImage("imagedisplayer.png"),
                            description: $translate.instant("DASHBOARD_IMAGE_DISPLAYER_DESCRIPTION"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("DASHBOARD_PDF_DISPLAYER_TEXT"),
                            component: "pdfdisplayer",
                            category: general,
                            imageUrl: staticFileService.image("pdfdisplayer.png"),
                            previewImageUrl: staticFileService.previewImage("pdfdisplayer.jpg"),
                            description: $translate.instant("DASHBOARD_PDF_DISPLAYER_DESCRIPTION"),
                            defaultXSize: 10,
                            defaultYSize: 16
                        },
                        {
                            name: $translate.instant("DASHBOARD_WEB_VIEWER_TEXT"),
                            component: "webviewer",
                            category: general,
                            imageUrl: staticFileService.image("webviewer.jpg"),
                            previewImageUrl: staticFileService.previewImage("webviewer.jpg"),
                            description: $translate.instant("DASHBOARD_WEB_VIEWER_DESCRIPTION"),
                            defaultXSize: 16,
                            defaultYSize: 12
                        },
                        {
                            name: $translate.instant("DASHBOARD_COUNTDOWN_APP_TITLE"),
                            component: "daycounter",
                            category: general,
                            imageUrl: staticFileService.image("daycounter.jpg"),
                            previewImageUrl: staticFileService.previewImage("daycounter.jpg"),
                            description: $translate.instant("DASHBOARD_COUNTDOWN_APP_DESCRIPTION"),
                            defaultXSize: 8,
                            defaultYSize: 8
                        },
                        {
                            name: $translate.instant("DASHBOARD_SMILEY_APP_TITLE"),
                            component: "smiley",
                            category: registration,
                            imageUrl: staticFileService.image("smileyApp.jpg"),
                            previewImageUrl: staticFileService.previewImage("smiley.jpg"),
                            description: $translate.instant("DASHBOARD_SMILEY_APP_DESCRIPTION"),
                            defaultXSize: 8,
                            defaultYSize: 8
                        },
                        {
                            name: $translate.instant("BOARD_ACTION_LIST"),
                            component: "actionList",
                            category: tasks,
                            imageUrl: staticFileService.image("actionList.png"),
                            previewImageUrl: staticFileService.previewImage("actionList.png"),
                            description: $translate.instant("BOARD_ACTION_LIST_DESCRIPTION"),
                            defaultXSize: 16,
                            defaultYSize: 12
                        },
                        {
                            name: $translate.instant("DASHBOARD_PERSONAL_CALENDAR"),
                            component: "personalcalendar",
                            category: board,
                            imageUrl: staticFileService.image("personal-calendar.jpg"),
                            previewImageUrl: staticFileService.previewImage("personal-calendar.jpg"),
                            description: $translate.instant("DASHBOARD_PERSONAL_CALENDAR_DESC"),
                            defaultXSize: 18,
                            defaultYSize: 16
                        },
                        {
                            name: $translate.instant("DASHBOARD_TEAM_CALENDAR"),
                            component: "teamboardcalendar",
                            category: board,
                            imageUrl: staticFileService.image("team-calendar.jpg"),
                            previewImageUrl: staticFileService.previewImage("team-calendar.jpg"),
                            description: $translate.instant("DASHBOARD_TEAM_CALENDAR_DESC"),
                            defaultXSize: 18,
                            defaultYSize: 16
                        },
                        {
                            name: $translate.instant("DASHBOARD_TEAM_TASKS"),
                            component: "teamtasks",
                            category: board,
                            imageUrl: staticFileService.image("teamtask.jpg"),
                            previewImageUrl: staticFileService.previewImage("teamtask.jpg"),
                            description: $translate.instant("DASHBOARD_TEAM_TASKS_DESC"),
                            defaultXSize: 20,
                            defaultYSize: 12
                        },
                        {
                            name: $translate.instant("BOARD_YEARLY_BOARD"),
                            component: "timeboard",
                            category: board,
                            imageUrl: staticFileService.image("timeboard.jpg"),
                            previewImageUrl: staticFileService.previewImage("timeboard.jpg"),
                            description: $translate.instant("BOARD_YEARLY_BOARD_DESCRIPTION"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        },
                        {
                            name: $translate.instant("PROJECT_PROJECT_PORTFOLIO"),
                            component: "projectboard",
                            category: project,
                            imageUrl: staticFileService.image("projectboardApp.jpg"),
                            previewImageUrl: staticFileService.previewImage("projectboardApp.jpg"),
                            description: $translate.instant("BOARD_PROJECT_BOARD_DESCRIPTION"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        },
                        {
                            name: $translate.instant("DASHBOARD_SMARTTABLE"),
                            component: "smarttable",
                            category: board,
                            imageUrl: staticFileService.image("projectboardApp.jpg"),
                            previewImageUrl: staticFileService.previewImage("smarttable.jpg"),
                            description: $translate.instant("DASHBOARD_SMARTTABLE_DESCRIPTION"),
                            defaultXSize: 16,
                            defaultYSize: 6
                        },
                        {
                            name: $translate.instant("DASHBOARD_SMARTACTIONLIST"),
                            component: "smartactionlist",
                            category: board,
                            imageUrl: staticFileService.image("smartactionlist.jpg"),
                            previewImageUrl: staticFileService.previewImage("smartactionlist.jpg"),
                            description: $translate.instant("DASHBOARD_SMARTACTIONLIST_DESCRIPTION"),
                            defaultXSize: 30,
                            defaultYSize: 10
                        },
                        {
                            name: $translate.instant("BOARD_WEEKLY_BOARD"),
                            component: "weeklyboard",
                            category: board,
                            imageUrl: staticFileService.image("weeklyboard.jpg"),
                            previewImageUrl: staticFileService.previewImage("weeklyboard.jpg"),
                            description: $translate.instant("BOARD_WEEKLY_BOARD_DESCRIPTION"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        },
                        {
                            name: $translate.instant("BOARD_KANBAN_BOARD"),
                            component: "kanbanboard",
                            category: board,
                            imageUrl: staticFileService.image("kanbanBoard.jpg"),
                            previewImageUrl: staticFileService.previewImage("kanbanBoard.jpg"),
                            description: $translate.instant("BOARD_KANBAN_BOARD_DESCRIPTION"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        },
                        
                    ];
                    if (navigationService().hasModule("IMPROVEMENT")) {
                        apps.push({
                            name: $translate.instant("IMPROVEMENT_PRIORITY_MATRIX"),
                            component: "prioritymatrix",
                            category: improvement,
                            imageUrl: staticFileService.image("prioritymatrix.jpg"),
                            previewImageUrl: staticFileService.previewImage("prioritymatrix.jpg"),
                            description: $translate.instant("DASHBOARD_PRIORITYMATRIX_DESC"),
                            defaultXSize: 28,
                            defaultYSize: 20

                        });
                        apps.push({
                            name: $translate.instant("IMPROVEMENT_ONGOING"),
                            component: "ongoingimprovement",
                            category: improvement,
                            imageUrl: staticFileService.image("ongoingimprovement.png"),
                            previewImageUrl: staticFileService.previewImage("ongoingimprovement.jpg"),
                            description: $translate.instant("DASHBOARD_ONGOING_IMPROVEMENT_DESC"),
                            defaultXSize: 28,
                            defaultYSize: 20

                        });
                        apps.push({
                            name: $translate.instant("DASHBOARD_RANKING"),
                            component: "range",
                            category: improvement,
                            imageUrl: staticFileService.image("range-improvement.jpg"),
                            previewImageUrl: staticFileService.previewImage("range-improvement.jpg"),
                            description: $translate.instant("DASHBOARD_RANKING_DESC"),
                            defaultXSize: 10,
                            defaultYSize: 10
                        });
                    }
                    if (navigationService().hasModule("A3")) {
                        apps.push({
                            name: "A3 " + $translate.instant("A3_VIEWER"),
                            component: "a3viewer",
                            category: "A3",
                            imageUrl: staticFileService.image("a3viewer.jpg"),
                            previewImageUrl: staticFileService.previewImage("a3viewer.jpg"),
                            description: $translate.instant("A3_VIEWER_DESCRIPTION"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        });
                        apps.push({ 
                            name: $translate.instant("A3_TABLE"),
                            component: "a3table",
                            category: "A3",
                            imageUrl: staticFileService.image("a3table.jpg"),
                            previewImageUrl: staticFileService.previewImage("a3table.jpg"),
                            description: $translate.instant("A3_TABLE_DESC"),
                            defaultXSize: 40,
                            defaultYSize: 20
                        });
                    }
                    if (navigationService().hasModule("DEVIATION")) {
                        apps.push({
                            name: $translate.instant("DEVIATION_NEW_DEVIATION"),
                            component: "quickincidentregistration",
                            category: deviation,
                            imageUrl: staticFileService.image("quickincidentregistration.jpg"),
                            previewImageUrl: staticFileService.previewImage("quickincidentregistration.jpg"),
                            description: $translate.instant("DASHBOARD_QUICK_INCIDENT_REGISTRATION_DESC"),
                            defaultXSize: 6,
                            defaultYSize: 6

                        });
                        apps.push({
                            name: $translate.instant("IMPROVEMENT_ONGOING"),
                            component: "ongoingdeviation",
                            category: deviation,
                            imageUrl: staticFileService.image("ongoingdeviation.jpg"),
                            previewImageUrl: staticFileService.previewImage("ongoingdeviation.jpg"),
                            description: $translate.instant("DASHBOARD_ONGOING_DEVIATION_DESC"),
                            defaultXSize: 28,
                            defaultYSize: 20

                        });
                        apps.push({
                            name: $translate.instant("DEVIATION_PARETO_CHALLENGES"),
                            component: "deviationcategorysummary",
                            category: deviation,
                            imageUrl: staticFileService.image("paretoDeviationCategories.jpg"),
                            previewImageUrl: staticFileService.previewImage("deviationCategorySummary.jpg"),
                            description: $translate.instant("DEVIATION_PARETO_CHALLENGES"),
                            defaultXSize: 14,
                            defaultYSize: 12
                        });
                    }

                    if (navigationService().hasModule("STRATEGY")) {
                        apps.push({
                            name: $translate.instant("STRATEGY_BREAKDOWN"),
                            component: "strategymapchart",
                            category: strategy,
                            imageUrl: staticFileService.image("strategymapchart.jpg"),
                            previewImageUrl: staticFileService.previewImage("strategymapchart.jpg"),
                            description: $translate.instant("STRATEGY_BREAKDOWN_DESC"),
                            defaultXSize: 28,
                            defaultYSize: 20

                        });
                    }

                    if (authService.hasRole("KaizenAdmin")) {
                        apps.push({
                            name: "Debugger app, only for development",
                            component: "debug",
                            category: "Kaizen Solutions",
                            imageUrl: staticFileService.image("debugApp.jpg"),
                            previewImageUrl: staticFileService.previewImage("debugApp.jpg"),
                            description: "DEBUGGING",
                            defaultXSize: 12,
                            defaultYSize: 12
                        });
                        
                        apps.push({
                            name: $translate.instant("DASHBOARD_USERS_ONLINE"),
                            component: "usersonline",
                            category: "Kaizen Solutions",
                            imageUrl: staticFileService.image("usersonline.jpg"),
                            previewImageUrl: staticFileService.previewImage("usersonline.jpg"),
                            description: $translate.instant("DASHBOARD_USERS_ONLINE_DESC")
                        });

                    }
              
                    var currentCustomer = authService.getCurrentUser().customerId;
                    var threeCCustomers = [
                        139, 1, 7, 48 // GKN, DigiLEAN, demo, english demo
                    ]; 
                    if (threeCCustomers.includes(currentCustomer)) {
                        apps.push({
                            name: $translate.instant("DASHBOARD_3C_APP_TITLE"),
                            component: "3ctable",
                            category: tasks,
                            imageUrl: staticFileService.image("3ctable.jpg"),
                            previewImageUrl: staticFileService.previewImage("3ctable.jpg"),
                            description: $translate.instant("DASHBOARD_3C_APP_DESCRIPTION"),
                            defaultXSize: 16,
                            defaultYSize: 12
                        });
                    }
                    return apps;
                },


            };
        }
        return appstoreService;
    }]);