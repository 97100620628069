var DigiLean = angular.module('DigiLean');  
DigiLean.controller('customerAdminController', ['$scope', '$translate', 'projectService','modalService',
function ($scope, $translate, projectService, modalService) {

    

    $scope.setItemViewMode = function (item) {
        item.isEditMode = false;
    }
    $scope.setItemEditMode = function (item) {
        item.isEditMode = true;
    }

    $scope.removeItem = function (item) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CUSTOMER_CONFIRMATION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.COMMON_DELETE,
                headerText: translations.COMMON_DELETE + ' ' + item.name + '?',
                bodyText: translations.ADMIN_MESSAGE_DELETE_CUSTOMER_CONFIRMATION 
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                projectService().deleteProjectCustomer(item.id).then(function (data) {  
                    var index = $scope.customers.indexOf(item);
                    $scope.customers.splice(index, 1);
                    $translate('ADMIN_MESSAGE_CUSTOMER_DELETED').then(function (msg) {
                            toastr.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                    });
                },function (err) {
                });
            });
        });
    }

    $scope.customers = [];
    var load = function () {
        projectService().getProjectCustomers().then(function (customers) {
            $scope.customers = customers;
        });    
    }
    load();


    $scope.addItem = function () {
        var item = {
            isNew: true,
            id: 0,
            name: ""
        }
        projectService().createNewCustomer(item).then(function (savedItem) {
            $translate('ADMIN_MESSAGE_CUSTOMER_CREATED').then(function (msg) {
                toastr.success(msg);
            }, function (translationId) {
                $scope.headline = translationId;
            });
            savedItem.isEditMode = true;
            $scope.customers.push(savedItem);
            setTimeout(function () {
                $(".project-name").focus();
            }, 50);
        });
    }

      $scope.saveItem = function (item) {
        projectService().updateCustomerInfo(item).then(function () {
            $translate('ADMIN_MESSAGE_CUSTOMER_UPDATED').then(function (msg) {
                toastr.success(msg);
            }, function (translationId) {
                $scope.headline = translationId;
            });
        });    
    }

}]);