angular
    .module('DigiLean')
    .directive("strategyBowlerMonthlyPlan", ['$filter','$uibModal','strategyService','dataSourceService','dataService',
        function ($filter,$uibModal, strategyService,dataSourceService, dataService) {
            return {
                templateUrl: 'strategyBowlerMonthlyPlan.html',
                restrict: 'E',
                scope: {
                    'kpi': '<',
                    'year': '<',
                    'month': '<',
                    'valuechanged': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.editable = true;
                    scope.active = false;
                    var targetDataSource = null;
                    var timePeriod = null;
                    var locale = 'en-US';
                    var yearForValue = null;

                    function setPlanValue(value){
                        scope.plan = value;
                        if(scope.plan) {
                            scope.planformatted = scope.plan.toLocaleString(locale);   
                        } else {
                            scope.planformatted = null;
                        }      
                    }

                    scope.$watch("kpi", function (kpi) {
                        if (!kpi) return;
                        scope.kpiid = kpi.id;
                        var value = $filter('filter')(kpi.kpiMonthlyValues, { month: scope.month },true);
                        if (value && value.length > 0) { 
                            setPlanValue(value[0].plan);
                            scope.actual = value[0].actual;
                            yearForValue = value[0].year;
                            if(!yearForValue) yearForValue = scope.year;
                        }
                        if(!yearForValue) yearForValue = scope.year;
                    });

                    scope.$watch("valuechanged", function (valuechanged) {
                        if (!valuechanged) return;
                        scope.kpiid = scope.kpi.id;
                        var value = $filter('filter')(scope.kpi.kpiMonthlyValues, { month: scope.month },true);
                        if (value.length > 0) { 
                            scope.actual = value[0].actual;
                            setPlanValue(value[0].plan);
                        }
                    });

                    scope.subscribe("KPIMonthlyValueUpdated", function (update) {
                        if (update.kpiId == scope.kpiid && scope.month == update.month && yearForValue == update.year) {
                            scope.actual = update.actual;
                            if(!scope.kpi.targetDataSourceId){
                                if(update.plan != scope.plan){
                                    setPlanValue(update.plan);           
                                }
                            }      
                        }
                    });

                    scope.setActive = function() {
                        scope.active = true;   
                        setTimeout(function() {
                            $(".kpi-plan").focus();
                        }, 50 );                        
                    }

                    //Datasource events
                    scope.subscribe("DataValueAdded", function (value) {
                        if(isWithinTimePeriod(value.valueDate)){
                            checkUpdatedDatasourceValue(value.dataSourceId);
                        }           
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if(isWithinTimePeriod(value.valueDate)){
                            checkUpdatedDatasourceValue(value.dataSourceId);
                        } 
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        checkUpdatedDatasourceValue(value.dataSourceId);
                    });

                    function isWithinTimePeriod(valueDate){
                        timePeriod = getTimePeriod(); 
                        var result = false;
                        if(valueDate && timePeriod){ //valid input
                            var timeperiodfrom = moment(timePeriod.from);
                            var timeperiodto = moment(timePeriod.to);
                            if(moment(timeperiodfrom).isBefore(valueDate)
                                && moment(valueDate).isBefore(timeperiodto)){
                                return true;
                            }
                        }
                        return result;
                    }
                    function checkUpdatedDatasourceValue (id) {
                        if (!scope.kpi.targetDataSourceId) return;
                        if (scope.kpi.targetDataSourceId !== id) return;
                        var formula = null;
                        if(scope.kpi.unit == "%"){
                            formula = "average";
                        }
                        var options = {
                            timePeriod: getTimePeriod(),
                            filters: scope.kpi.targetFilter,
                            formula: formula
                        };
                        dataService().getSumForPeriod(scope.kpi.targetDataSourceId, options).then(function (sum) {
                            if(sum){
                                setPlanValue(sum);
                            } else {
                                setPlanValue(0);
                            }
                        });
                    };

                    
                    scope.openDataValues = function(){
                        if(scope.kpi.targetDataSourceId){
                            if(targetDataSource){
                                openDataValuesPopup(targetDataSource);
                            } else {
                                dataSourceService().get(scope.kpi.targetDataSourceId).then(function(dataSource) {
                                    targetDataSource = dataSource;
                                    openDataValuesPopup(targetDataSource);
                                });
                            }
                        }                 
                    }

                    function openDataValuesPopup(dataSource){
                        var datasourceoptions = {
                            dataSource: targetDataSource,
                            filters: scope.kpi.targetFilter,
                            timePeriod: getTimePeriod(),
                            editable: true
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'datavaluesListForm.html',
                            controller: 'datavaluesListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options: function () {
                                    return  datasourceoptions;
                                }
                            }
                        });
                    }

                    function getTimePeriod(){
                        if (timePeriod) return timePeriod;
                        var startDate = moment().set({'year': yearForValue, 'month': scope.month-1, 'date':1}).startOf('day');
                        var endDate = moment(startDate).endOf("month");
                        timePeriod = {
                            from: startDate.utc().toDate().toJSON(),
                            to: endDate.utc().toDate().toJSON()
                        }; 
                        return timePeriod;
                    }
                    scope.updateValue = function(newPlan){
                        scope.active = false;  
                        if(newPlan != scope.plan){
                            var kpiMonthlyValue = {
                                KPIId:scope.kpiid,
                                month:scope.month,
                                year: yearForValue,
                                plan: newPlan,
                                actual: scope.actual
                            }

                            strategyService().updateKPIMonthlyValue(kpiMonthlyValue).then(function () {

                            });
                        }

                    }

                }
            }
        }]);