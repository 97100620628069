import angular from "angular"
import moment from "moment"
import '@common/shared/dates/datePicker.css'
import { NgComponent } from "@common/model/angularModel"
// import "./scalableText.css"

const DigiLean = angular.module("DigiLean")
DigiLean.component("kpiMonthSelector", 
{
    bindings: {
        'statusByMonth': '<',
    },
    controller: ['$element', function(this: NgComponent, $element) {
        let $ctrl = this

        $ctrl.selectedMonth = moment().format('MMM')
        $ctrl.selectedYear = moment().year()
        $ctrl.currentYear = moment().year()
        $ctrl.monthNames = []
        $ctrl.months = [0,1,2,3,4,5,6,7,8,9,10,11]

        $ctrl.$onInit = function() {
            setMonthNames()
        }

        $ctrl.changeYear = function(change){
            $ctrl.selectedYear = $ctrl.selectedYear + change
            dispatchCustomEvent("selected-year-changed", $ctrl.selectedYear)
        }
        $ctrl.changeToMonth = function(month) {
            $ctrl.selectedMonth = moment().month(month).format('MMM')
            dispatchCustomEvent("selected-month-changed", month)
        }
        // $ctrl.$onDestroy = function() {}
        
        function setMonthNames(){
            var threeletters = moment.monthsShort("-MMM-")
            for(var i=0; i<threeletters.length; i++){
                $ctrl.monthNames[i] = threeletters[i].charAt(0).toUpperCase();
            }
        }
        
        function dispatchCustomEvent(name, detail) {
            const options = { detail, bubbles: true, composed: true }
            $element[0].dispatchEvent(new CustomEvent(name, options))
        }
    }],
    templateUrl: "kpiMonthSelector.html"
})