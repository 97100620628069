﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('appstoreController', ['$scope', '$filter', '$uibModalInstance', '$translate', 'appstoreService',
    function ($scope, $filter, $uibModalInstance, $translate,appstoreService) {
        $scope.selectedApp = {};

        $scope.allApps = appstoreService().getApps();
        $scope.apps = $scope.allApps;

        $scope.isAllSelected = true;
        $scope.types = [
            {
                title: "KPI",
                isSelected: false,
                apps: ["singlevalue","singlevaluetarget", "targetgraph", "minmaxchart", "productionboard","greencross", "5s",
                "A", "C", "D", "I","K", "L", "P","Q","dailykpidynamic","smiley"]
            },
            {
                title: $translate.instant("DASHBOARD_GRAPH"),
                isSelected: false,
                apps: ["targetgraph", "trendbarchart","minmaxchart", "piechart",
                "barchart", "stackedhorizantalbarchart", "splitPackedBubbleChart", 
                "pareto", "comparechart", "multichart", "livetrend", "radarchart"]
            },
            {
                title: $translate.instant("COMMON_CONTENT"),
                isSelected: false,
                apps: ["texteditor","imagedisplayer", "webviewer", "smarttable", "datasourcevalueslist", "diagrameditor", "pdfdisplayer", "messagedisplayer"]
            },
            {
                title: $translate.instant("COMMON_REGISTRATION"),
                isSelected: false,
                apps: ["singlevalueregistration", "registration","productionboard","greencross", "5s",
                "A", "C", "D", "I","K", "L", "P","Q","dailykpidynamic","quickincidentregistration","smiley"]
            },
            {
                title: $translate.instant("COMMON_TASKS"),
                isSelected: false,
                apps: ["smartactionlist", "weeklyboard", "kanbanboard","timeboard", "actionList","personalcalendar", "teamboardcalendar", "teamtasks" ]
            },
            {
                title: $translate.instant("COMMON_IMPROVEMENT"),
                isSelected: false,
                apps: ["prioritymatrix", "ongoingimprovement", "range", "pareto", "singlevalue","singlevaluetarget", "targetgraph", "daycounter"]
            },
            {
                title: $translate.instant("COMMON_A3"),
                isSelected: false,
                apps: ["a3viewer", "a3table","pareto","singlevalue","singlevaluetarget", "targetgraph", "daycounter" ]
            },
            {
                title: $translate.instant("COMMON_DEVIATION"),
                isSelected: false,
                apps: ["quickincidentregistration","ongoingdeviation","deviationcategorysummary","pareto","singlevalue","singlevaluetarget", "targetgraph","greencross","5s", "A", "C", "D", "I","K", "L", "P","Q", "dailykpidynamic", "daycounter" ]
            },
            {
                title: $translate.instant("COMMON_PROJECT"),
                isSelected: false,
                apps: ["projectboard","greencross","5s", "A", "C", "D", "I","K", "L", "P","Q", "dailykpidynamic"]
            },
            {
                title: $translate.instant("COMMON_OTHER"),
                isSelected: false,
                apps: ["daycounter", "smiley"]
            }
        ]

        function operation(list1, list2, isUnion) {
            var result = [];
            
            for (var i = 0; i < list1.length; i++) {
                var item1 = list1[i],
                    found = false;
                for (var j = 0; j < list2.length && !found; j++) {
                    found = item1.component === list2[j];
                }
                if (found === !!isUnion) { // isUnion is coerced to boolean
                    result.push(item1);
                }
            }
            return result;
        }
        
        // Following functions are to be used:
        function inBoth(list1, list2) {
            return operation(list1, list2, true);
        }
        
        
        $scope.selectType = function (type) {
            $scope.isAllSelected = false;
            for(var i=0; i<$scope.types.length;i++ ){
                $scope.types[i].isSelected = false;
            }
            type.isSelected = true;
            $scope.apps = inBoth($scope.allApps, type.apps)
        }

        $scope.resetSelection = function(){
            $scope.isAllSelected = true;
            for(var i=0; i<$scope.types.length;i++ ){
                $scope.types[i].isSelected = false;
            }
            $scope.apps = $scope.allApps;
        }

        $scope.selectApp = function (app) {
            $uibModalInstance.close(app);
        }

        $scope.ok = function () {
            $uibModalInstance.close($scope.selectedApp);

        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);