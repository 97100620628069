angular
    .module('DigiLean')
    .directive("initiativesaggregatedtimerealized", ['$filter', '$translate', 'suggestionService', 'authService', 'layoutService',
        function ($filter, $translate, suggestionService, authService, layoutService) {
            return {
                templateUrl: 'initiativesaggregatedtimerealized.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {

                    var statusAggregated = "";
                    var realized = "";
                    var estimated = "";
                    var axisLabel = ""; 
                    
                    $translate(['COMMON_HOURS','PROJECT_STATUS_AGGREGATED', 'A3_REALIZED', 'COMMON_ESTIMATED', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'IMPROVEMENT_FROM_NEW_TO_PLANNED']).then(function (translations) {
                        statusAggregated = translations.PROJECT_STATUS_AGGREGATED;
                        realized = translations.A3_REALIZED;
                        estimated = translations.COMMON_ESTIMATED;
                        axisLabel = translations.COMMON_HOURS;
                        getStatusData();
                    });

                    function aggregateResult(array){
                        var result = [];
                        var aggregated = 0;
                        for(var i=0; i<array.length; i++){
                            var value = aggregated + array[i];
                            aggregated = aggregated + array[i];
                            result.push(value);
                        }
                        return result;
                    }

                    

                    function getStatusData() {
                        var endDate = moment().utc().endOf('year');
                        var startDate = moment().utc().startOf('year');
                        // Monthly suggested, need to find start and end of month
                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };
                        suggestionService().getTimeInitiativeMonthlyRealized(timePeriod).then(function (result) {
                            var data = result;

                            var aggregated = $(elem).find(".initiativesaggregatedtimerealized")
                            Highcharts.chart({
                                chart: {
                                    renderTo: aggregated[0],
                                    type: 'area'
                                },
                        title: {
                            text: statusAggregated,
                            style: {
                                color: '#676a6c',
                                fontWeight: 600,
                                fontSize: '1.2em',
                                fontFamily: 'open sans'
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        exporting: { enabled: false },
                        xAxis: {
                            categories: data.estimated.monthNumbers
                        },
                        yAxis: [{
                            min: 0,
                            title: {
                                text: axisLabel
                            }
                        }],
                        legend: {
                            shadow: false
                        },
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            column: {
                                grouping: false,
                                shadow: false,
                                borderWidth: 0
                            }
                        },
                        series: [
                            {
                                name: estimated,
                                color: '#434338',
                                data: aggregateResult(data.estimated.amount), //[140, 230, 270, 320, 400, 480, 480, 500, 550, 620, 660, 730],
                                pointPadding: 0.4,
                                pointPlacement: 0
                            },{
                            name: realized,
                            color: '#5cb85c',
                            data: aggregateResult(data.realized.amount), //[100, 173, 223, 223,223,223,223,223,223,223,223,223],
                            pointPadding: 0.25,
                            pointPlacement: 0
                        }]
                    });

                        });

                    }
                }

            }
        }]);



















