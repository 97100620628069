var DigiLean = angular.module('DigiLean');
DigiLean.controller('hoursHubController', ['$scope', '$filter', '$state', 'dataSourceService', 
    function ($scope, $filter, $state, dataSourceService) {
        var scope = $scope;
        dataSourceService().getFeatureSource("HourRegistration").then(function (source) {
            if (source) {
                scope.dataSource = source;
                // When all feature info is loaded, we can setup the rest
                loadDashboard(scope.dataSource.id);
            }
        })

        // Dashboard settings
        function loadDashboard(dataSourceId) {
            scope.thisMonthSettings = {
                title: "Denne måneden",
                timePeriod: {
                    timeframe: "month"
                },
                dataSource: {
                    id: dataSourceId,
                    valueElement: {
                        unit: "Timer"
                    }
                },
                decimals: 1
            }
            scope.thisMonthInvoicedSettings = {
                title: "Fakturerbart",
                timePeriod: {
                    timeframe: "month"
                },
                dataSource: {
                    id: dataSourceId,
                    valueElement: {
                        unit: "Timer"
                    }
                },
                filters: [
                    {// Fakturerbart
                        sourceColumn: "dimension3",
                        operator: "InList",
                        items: ["true"]
                    }
                ],
                decimals: 1
            }
            scope.thisYearSettings = {
                title: "I år",
                timePeriod: {
                    timeframe: "year"
                },
                dataSource: {
                    id: dataSourceId,
                    valueElement: {
                        unit: "Timer"
                    }
                },
                decimals: 1
            }
            scope.thisYearInvoicedSettings = {
                title: "I år",
                timePeriod: {
                    timeframe: "year"
                },
                dataSource: {
                    id: dataSourceId,
                    valueElement: {
                        unit: "Timer"
                    }
                },
                filters: [
                    {// Fakturerbart
                        sourceColumn: "dimension3",
                        operator: "InList",
                        items: ["true"]
                    }
                ],
                decimals: 1
            }
            scope.thisMonthPieSettings = {
                title: "Fordeling pr prosjekt",
                timePeriod: {
                    timeframe: "month"
                },
                dataSource: {
                    id: dataSourceId,
                    valueElement: {
                        unit: "Timer"
                    }
                },
                groupedBy: "projectId"
            }
        }

    }]);


