import * as customerService from "@common/services/customers/customerService"
import { getUserProfile } from "@common/stores/userStore"
import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .component("messageRepliesDialog", {
        templateUrl: 'messageRepliesDialog.html',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller:
            function () {
                var $ctrl = this;
                
                var currentUser;
                $ctrl.ok = function () {
                    $ctrl.close({$value: $ctrl.selected.item});
                };
            
                $ctrl.cancel = function () {
                    $ctrl.dismiss({$value: 'cancel'});
                };

                $ctrl.addReply = function() {
                    var reply = {
                        senderUserId: currentUser.id,
                        senderName: currentUser.fullName,
                        text: $ctrl.newReply
                    };
                    customerService.addReply($ctrl.msg.id, reply).then(function() {
                        calculateTimeElapsed();
                        $ctrl.newReply = '';
                    });

                };
                $ctrl.$onInit = function () {
                    $ctrl.msg = $ctrl.resolve.msg;
                    $ctrl.replies = $ctrl.msg.replies ? $ctrl.msg.replies : [];
                    calculateTimeElapsed();
                    currentUser = getUserProfile();
                };

                $ctrl.$onChanges = function (changes) {
                    if (changes.resolve) {
                        if (!changes.resolve.currentValue && changes.resolve.currentValue.msg && changes.resolve.currentValue.msg.replies)
                            calculateTimeElapsed();
                    }
                };

                function calculateTimeElapsed() {
                    $ctrl.replies.forEach(function (msg) {
                        timeService.addTimeElapsedForMsg(msg);
                    });
                }
            }
    });