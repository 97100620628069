import { JobRunState, MonitorState } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { formatDateTime} from "@common/services/datetime/dateTimeFormattingLocale"

@customElement('connector-state')
export class ConnectorStateViewer extends LitElement {

    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-block;
        }
    `
    @property({attribute: true})
    state: MonitorState = MonitorState.NoRun

    @property({attribute: true})
    date: string = ""

    render() {
        let dateFormatted = ""
        if (this.date) {
            let date = new Date(this.date)
            dateFormatted = `start ${formatDateTime(date)}`
        }
        this.title = `${this.state} ${dateFormatted}`
        switch (this.state) {
            case MonitorState.Success:
                return html`<digilean-checked></digilean-checked>`
            case MonitorState.RecentError:
                return html`<digilean-failed></digilean-failed>`
            case MonitorState.NoRun:
                return html`<digilean-failed dead=${true}></digilean-failed>`
            default:
                return html`<span></span>`
        }
    }
}
