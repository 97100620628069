angular
    .module('DigiLean')
    .directive("smartActionListDueDateCell", ['boardTaskService',
        function (boardTaskService) {
            return {
                templateUrl: 'smartActionListDueDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.dateMissed = false;
                    scope.dateModel = {
                        date: null
                    };

                    scope.changeColor = false;
                    var changeColorSettings = false;

                    scope.changeColorXDaysBefore = 1;

                    scope.updateDate = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.dateModel.date = date
                        boardTaskService().updateDate(scope.model.task.id, date)
                    }

                    scope.$watch("model", function (model) {
                        if (scope.model) {
                            if (scope.model.task && scope.model.task.boardDate) {
                                scope.dateModel.date = moment(scope.model.task.boardDate).toDate();
                            }
                            scope.datePicker = {
                                opened: false
                            }
                            var settings = model.column.settings;
                            if (settings) {

                                if(settings.changeColor && settings.changeColorXDaysBefore){

                                    scope.changeColor = true;
                                    changeColorSettings = true;
                                    scope.changeColorXDaysBefore = settings.changeColorXDaysBefore;
                                }
                            }
                            checkDate();

                            model.getSortValue = function () {

                                return scope.dateModel.date;
                            }
                        }
                    });

                    scope.subscribe('TaskStatusUpdated', function (updatedstatus) {
                        if (scope.model.task.id == updatedstatus.taskId) {
                            scope.model.task.status = updatedstatus.status;
                            if (updatedstatus.status == 'OK') {
                                scope.dateMissed = false;
                                scope.changeColor = false;
                                scope.color = "#0099DE";
                                scope.status = "";
                            } else { 
                                checkDate();
                            }
                        }
                    });
                    scope.subscribe('TaskDurationUpdated', function (duration) {
                        if (scope.model.task.id == duration.taskId) {
                            scope.dateModel.date = moment(duration.endTime).toDate();
                            checkDate();
                        }
                    });
                    scope.subscribe('TaskDateMoved', function (moveSettings) {
                        if (moveSettings.taskId == scope.model.task.id) {
                            scope.dateModel.date = moment(moveSettings.boardDate).toDate();
                            checkDate();
                        }
                    });

                    function checkDate() {
                        scope.status = "";
                        if (scope.dateModel.date) {

                            if(changeColorSettings){
                                scope.changeColor = true;
                            } else {
                                scope.changeColor = false;
                            }

                            var duration = moment.duration(moment().endOf('day').diff(moment(scope.dateModel.date).endOf('day')));
                            var days = duration.asDays();
                            if (days >= 1) {
                                scope.diffToPlannedDate = Math.trunc(days);
                                scope.dateMissed = false;
                                if (scope.model.task.status !== 'OK') {
                                    scope.dateMissed = true;
                                    scope.status = "missed";
                                } else {
                                    scope.dateMissed = false;
                                    scope.changeColor = false;
                                    scope.color = "#0099DE";
                                }
                            } else { //FUTURE
                                if (scope.model.task.status !== 'OK') {
                                    scope.status = "future";
                                    scope.diffToPlannedDate = Math.round(days) * -1;
                                } else {
                                    scope.dateMissed = false;
                                    scope.color = "#0099DE";
                                }
                            }
                        }
                    }
                }
            }
        }]);
