import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { systemHub } from "@common/services/systemHub"
import { Heartbeat } from "@api"
import { formatDateTime } from "@common/services/datetime/dateTimeFormattingLocale"

@customElement('heartbeat-indicator')
export class HeartbeatIndicator extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
        }
        @keyframes rubberBand {
            from {transform: scale3d(1, 1, 1);}

            30% {transform: scale3d(1.25, 0.75, 1);}

            40% {transform: scale3d(0.75, 1.25, 1);}

            50% {transform: scale3d(1.15, 0.85, 1);}

            65% {transform: scale3d(.95, 1.05, 1);}

            75% { transform: scale3d(1.05, .95, 1); }

            to { transform: scale3d(1, 1, 1);}
        }
        .active {
            color: var(--digilean-danger);
            animation: 1s linear 1s infinite rubberBand;
        }
    `
    
    @property({attribute: true})
    connectorid = ""

    connectedCallback(): void {
        super.connectedCallback()

        systemHub.on("HeartbeatReceived", (heartbeat: Heartbeat) => {
            if (heartbeat.id == this.connectorid) {
                this.isActive = true
                this.title = formatDateTime(new Date())
            }
        })
    }

    @state()
    isActive = false
    
    render() {
        let icon = "fa fa-heart fa-2x"
        
        return html`
            <fa-icon .icon=${icon} class="${this.isActive ? 'active': ''}"></fa-icon>
        `
    }
}
