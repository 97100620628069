angular
    .module('DigiLean')
    .directive("improvementcategorySelector", ['$filter', 'suggestionService',
        function ($filter, suggestionService) {
            return {
                templateUrl: 'improvementcategoryselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.includeAll = true;

                    function getCategoriesList() {
                        suggestionService().getCategories().then(function (categories) {
                            scope.items = categories;
                            applyFilter();
                        });
                    }
                    getCategoriesList();

                    scope.$watch('source', function () {
                        applyFilter();
                    });


                    function applyFilter() {
                        if (scope.filters && scope.items) {
                            var identifiers = [];
                            var selectedFilter = scope.filters[0];
                            if (selectedFilter && selectedFilter.items.length > 0) {

                                selectedFilter.items.forEach(item => {
                                    identifiers.push(parseInt(item));
                                });
                                selectedFilter.items = identifiers;
                            }
                            for (var k = 0; k < scope.items.length; k++) {
                                var index = selectedFilter.items.indexOf(scope.items[k].id);
                                if (index > -1) {
                                    scope.items[k].include = true;
                                } else {
                                    scope.items[k].include = false;
                                }
                            }

                            var selected = $filter('filter')(scope.items, { include: true });
                            if (selected.length === scope.items.length && selected.length === 0) {
                                scope.includeAll = true;
                            } else {
                                scope.includeAll = false;
                            }
                        } else if (scope.items) {
                            for (var i = 0; i < scope.items.length; i++) {
                                scope.items[i].include = true;
                            }
                        }
                    }

                    scope.deselectAll = function (include) {
                        for (var k = 0; k < scope.items.length; k++) {
                            scope.items[k].include = include;
                        }
                        scope.modifyFilter();
                    };

                    scope.modifyFilter = function () {
                        var selected = $filter('filter')(scope.items, { include: true });
                        if (selected.length === scope.items.length) {
                            scope.includeAll = true;
                            scope.filters = null;
                        } else {
                            scope.includeAll = false;
                            var selectedItems = [];
                            for (var i = 0; i < selected.length; i++) {
                                selectedItems.push(selected[i].id);
                            }
                            /* Skal filter være en tabell, eller vet vi på dette tidspunktet at det er kun filter som gjelder asset?
                            scope.filters = [{
                                sourceColumn: "assetId",
                                operator: "InList",
                                items: selectedItems
                            }];*/
                            scope.filters = [{
                                sourceColumn: "categoryId",
                                operator: "InList",
                                items: selectedItems
                            }];
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: "categoryId", filter: scope.filters });
                    };

                }
            }
        }]);
