import * as customerService from "@common/services/customers/customerService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("projectParticipants", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'projectParticipants.html',
                restrict: 'E',
                scope: {
                    'projectId': '='
                },
                link: function (scope, elem, attrs) {

                    scope.participants = [];
                    var loadUsers = function () {
                        customerService.getAllUsers().then(function (users) {
                            scope.users = users;
                            projectService().getUsers(scope.projectId).then(function (projectUsers) {
                                if(projectUsers){
                                scope.participants = projectUsers;
                                for (var i = 0; i < projectUsers.length; i++) {
                                    scope.users = $filter('filter')(scope.users, NotLikeIdFilter("userId", projectUsers[i].userId));
                                }
                            }
                            });
                        });
                    }
                    

                    scope.$watch("projectId", function () {
                        if (!scope.projectId) return;
                        loadUsers();
                    });

                    scope.subscribe("ProjectOwnerUpdated", function (project) {
                        if (project.id == scope.projectId) {
                           loadUsers();              
                        }
                    });
                    scope.subscribe("ProjectParticipantAdded", function (projectuserinfo) {
                        if (projectuserinfo.projectId == scope.projectId) {
                            var matchingParticipant = $filter('filter')(scope.participants, { userId: projectuserinfo.userId }, true);
                            if (matchingParticipant.length == 0) {
                                var matchingUser = $filter('filter')(scope.users, { userId: projectuserinfo.userId }, true);
                                if (matchingUser.length > 0) {
                                    scope.participants.unshift(matchingUser[0]);  
                                    var i = scope.users.indexOf(matchingUser[0]);
                                    scope.users.splice(i, 1);
                                } 
                            }                
                        }
                    });

                    function NotLikeIdFilter(property, userId) {
                        return function (item) {
                            if (item[property] === null) return false;
                            var id = item[property];
                            return id !== userId;
            
                        }
                    }

                    scope.add = function (user) {
                        user.assetRole = "Member";
                        scope.participants.unshift(user);
            
                        var matchingUser = $filter('filter')(scope.users, { userId: user.userId }, true);
                        if (matchingUser.length > 0) {
                            projectService().addUser(scope.projectId, matchingUser[0]).then(function (user) {
                                var i = scope.users.indexOf(matchingUser[0]);
                                scope.users.splice(i, 1);
                            });    
                        }
                    };
            
                    scope.remove = function (user) {
                        scope.users.unshift(user);
            
                        var matchingUser = $filter('filter')(scope.participants, { userId: user.userId }, true);
                        if (matchingUser.length > 0) {
                            projectService().removeUser(scope.projectId, matchingUser[0].userId).then(function (user) {
                                var i = scope.participants.indexOf(matchingUser[0]);
                                scope.participants.splice(i, 1);
                            });
                            
                        }
                    };

                }

            }
        }]);
