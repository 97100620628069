﻿import * as boardService from "@common/services/boardService"

var DigiLean =  angular.module('DigiLean');
DigiLean.controller('boardCategoryDetailsController', ['$scope', 'boardCategory','options', '$uibModalInstance', '$uibModal',
function ($scope, boardCategory, options, $uibModalInstance, $uibModal) {
    $scope.style = {
        background: boardCategory.style.background,
        color: boardCategory.style.color
    }
    $scope.name = boardCategory.name;
    $scope.weekdayName = boardCategory.weekdayName;
    $scope.isWeekday = false;
    $scope.options = options;


    function initialize() {
        if (boardCategory.weekdayName) {
            $scope.isWeekday = true;
        }
    }
    initialize();
    
    $scope.updateWeekdayName = function(weekdayName) {
        $scope.weekdayName = weekdayName;
    }

    $scope.toggleIsWeekday = function() {
        $scope.isWeekday = !$scope.isWeekday;
    }


    $scope.save = function () {
        // set style attributes to categoryStyle!
        boardCategory.name = $scope.name;

        if ($scope.isWeekday) {
            boardCategory.weekdayName = $scope.weekdayName;
            boardCategory.isWeekday = true;
        } else {
            boardCategory.weekdayName = "";
            boardCategory.isWeekday = false;
        }

        boardCategory.style = $scope.style;
        boardCategory.categoryStyle = JSON.stringify({
            BackgroundColor: $scope.style.background,
            ForegroundColor: $scope.style.color
        });
        boardService.updateCategory(boardCategory.id, boardCategory).then(function (category) {
            $uibModalInstance.close("updated");
        });
    }

    $scope.delete = function () {
        boardService.deleteCategory(boardCategory.id).then(function (data) {
            $uibModalInstance.close("deleted");
        });
    }

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

}]);