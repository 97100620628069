import * as dashboardService from "@common/services/dashboardService"

angular
    .module('DigiLean')
    .directive("dashboardPreview", ['$filter', 'appstoreService',
        function ($filter, appstoreService) {
            return {
                templateUrl: 'dashboardPreview.html',
                restrict: 'E',
                scope: {
                    template: '='
                },
                link: function (scope, elem, attrs) {
                    
                    scope.showImage = false;
                    var apps = appstoreService().getApps();
                    scope.$watch('template', function (template) {
                        
                        if (template && template.id) {
                            dashboardService.getTemplate(template.id).then(function(model) {
                                scope.model = model;
                                scope.selectPage(model.pages[0]);
                            })
                        } else {
                            scope.showImage = true;
                        }
                    });


                    scope.selectPage = function(page) {
                        // set app image
                        
                        page.widgets.forEach(app => {
                            var matchingApp = $filter('filter')(apps, { component: app.component }, true); 
                            if (matchingApp.length > 0) {
                                app.imageUrl = matchingApp[0].previewImageUrl;
                            }
                   
                        });
                        scope.selectedPage = page;
                    }

                    scope.gridsterOptions = {  //margins: [20, 10],   (top, right, bottom, and left).
                        margins: [5, 5],
                        columns: 40,
                        maxRows: 200,
                        sparse: true, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
                        swapping: false, // allow widgets of same size to swap place
                        resizable: {
                            enabled: false,
                        },
                        draggable: {
                            enabled: false, // whether dragging items is supported
                            
                        }
                    };
                }
            }
        }]);
