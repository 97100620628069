angular
    .module('DigiLean')
    .directive("smartactionlistStatusCell", ['boardTaskService',
        function (boardTaskService) {
            return {
                templateUrl: 'smartactionlistStatusCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'adminmode': '='
                },
                link: function (scope, elem, attrs) {
                    scope.task = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.task) {
                            scope.task = scope.model.task; 
                        }
                        model.getSortValue = function() {
                            return scope.task.status;
                        }   
                    });


                    scope.subscribe('TaskStatusUpdated', function (updatedstatus) {
                        if (scope.task && scope.task.id == updatedstatus.taskId) {
                            scope.task.status = updatedstatus.status;
                            scope.task.progressStatus = updatedstatus.progressStatus;
                        }
                    });
                             
                   
                    scope.setStatusValue = function (newStatus) {
                        var taskStatus = { taskId: scope.task.id, status: newStatus }

                        boardTaskService().updateStatus(scope.task.id, taskStatus).then(function (result) {                          
                        });
                    }
                 
                }

            }
        }]);
