var DigiLean = angular.module('DigiLean');  
DigiLean.controller('deviationCustomFieldAdminController', ['$scope', '$filter', '$stateParams', '$location', '$timeout', '$translate', 'deviationService','modalService','dataListService','languageAdminService',
function ($scope, $filter, $stateParams, $location, $timeout, $translate, deviationService, modalService, dataListService, languageAdminService) {
    $scope.savedSuccessfully = false;
    $scope.message = "";
    $scope.isElementRegistrationNew = false;
    $scope.selectedAttributeType = null;
    $scope.selectedDataList = null;

    $scope.customfields = [];
    var load = function () {
        deviationService().getAllCustomFields().then(function (fields) {
            for(var i=0; i<fields.length; i++){
                var typeinfo = $filter('filter')($scope.customTypes, { id: fields[i].type })[0];
                if(typeinfo){
                    fields[i].typelabel = typeinfo.label;
                    $scope.customfields.push(fields[i]);
                }
            }
        });
    }
    load();
    dataListService().getLists().then(function (list) {
        if (list) {
            $scope.datalists = list;
        }
    });

    var defaultConfig = {languageCode: '',
                        languageLabel: $translate.instant('ADMIN_DEFAULT_CONFIG'),
                        nativeLabel: $translate.instant('ADMIN_DEFAULT_CONFIG')
                        };
    $scope.selectedLanguage = defaultConfig;
    $scope.languages = languageAdminService().getSupportedLanguages()
    $scope.languages.unshift($scope.selectedLanguage);

    $scope.changeLanguage = function (language) {
        $scope.selectedLanguage = language;
        $scope.customfields.forEach(item => {
            item.customLanguageLabel = item.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        });
    }

    $scope.updateTranslationLabel = function (item) {
        if(item.customLanguageLabel){
            if(!item.customLanguageLabel.languageCode){ // new translation label
                item.customLanguageLabel.languageCode = $scope.selectedLanguage.languageCode;
            }
            deviationService().updateCustomFieldTranslationLabel(item.id, item.customLanguageLabel).then(function (result) {
                if(result){
                    var existing = item.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
                    if(existing){
                        existing = result;
                    } else {
                        item.customLanguageLabels.push(result);
                    }
                }
            })
        }             
    }
    $scope.setItemViewMode = function (item) {
        item.isEditMode = false;
        $scope.message = "";
        $scope.updateTranslationLabel(item);
    }
    $scope.setItemEditMode = function (item) {
        item.isEditMode = true;
        if(item.dataListId && $scope.datalists){
            var existing = $scope.datalists.find(q => q.id == item.dataListId)
            if(existing){
                item.dataListName = existing.name;
            }
        }
        $scope.message = "";
    }

    $scope.updateItem = function (item) {
        deviationService().updateDeviationCustomField(item.id, item).then(function (item) {
        })
    }

 
    $scope.customTypes = [
        { id: "text", label: $translate.instant('ADMIN_DATA_DATASOURCE_SMALLTEXT'), languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
        { id: "description", label:$translate.instant('ADMIN_DATA_DATASOURCE_LARGERTEXT'), languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
        { id: "number", label: $translate.instant('ADMIN_DATA_DATASOURCE_NUMBER'), languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
        { id: "bool", label: $translate.instant('ADMIN_DATA_DATASOURCE_BOOLEAN'), languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
        { id: "list", label: $translate.instant('ADMIN_DATA_LISTS'), languageCode: "ADMIN_DATA_LISTS" }
    ];

    $scope.addCustomField = function () {
        $scope.message = "";
        $scope.isElementRegistrationNew = true;  
        $scope.newField =
        {label: "",
        type: null,
        sortOrder:0}           
    };


    $scope.selectedAttributeTypeChanged = function(item){
        $scope.newField.type = item.type;
        $scope.selectedAttributeType = item
    }

    $scope.deleteCustomField = function (field) {
        $scope.isElementRegistrationNew = true;   
        if(field.id){
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_DEVIATION_CUSTOMFIELD_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + field.label + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_DEVIATION_CUSTOMFIELD_CONFIRMATION
                };
                modalService.showModal({}, modalOptions).then(function (result) {
                    var index = $scope.customfields.indexOf(field);
                    $scope.customfields.splice(index, 1);
                    $scope.isElementRegistrationNew = false;
                    deviationService().deleteDeviationCustomField(field.id).then(function() {                                                  
                    });
                });
            });
        }     
    };

    $scope.saveElement = function (field) {
        $scope.message = "";
        if(!field.label){
            $scope.message = $translate.instant('ADMIN_DATA_FIELD_MISSING') + ": " + $translate.instant('COMMON_LABEL');
            return;
        }
        if(!$scope.selectedAttributeType){
            $scope.message = $translate.instant('ADMIN_DATA_FIELD_MISSING') + ": " + $translate.instant('COMMON_TYPE');
            return;
        }
        $scope.isElementRegistrationNew = false; 
        field.type = $scope.selectedAttributeType.id;
        if($scope.selectedDataList){
            field.dataListId = $scope.selectedDataList.id
        }
        deviationService().addDeviationCustomField(field).then(function(result) {     
            $scope.selectedAttributeType = null;
            $scope.selectedDataList = null;

            var typeinfo = $filter('filter')($scope.customTypes, { id: result.type })[0];
            if(typeinfo){
                result.typelabel = typeinfo.label;
            } 
            if(!result.customLanguageLabels) {
                result.customLanguageLabels = [];
            }
            $scope.customfields.push(result);                                            
        });       
    };

}]);