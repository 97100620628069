import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("smarttableStartDateCell", ['debounce','smarttableService',
        function (debounce, smarttableService) {
            return {
                templateUrl: 'smarttableStartDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.dateMissed = false;
                    scope.date = {};
                    scope.value = null;

                    scope.updateStartDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.date.startDate = date
                        smarttableService().updateStartTime(scope.model.cell.smartTableRowId,scope.date.startDate).then(function (savedDate) {
                            checkDate()
                        })
                    }

                    scope.updateEstimatedStartDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.date.estimatedStartDate = date
                        var value = {
                            estimatedStartDate: scope.date.estimatedStartDate
                        }
                        scope.model.cell.value = value
                        scope.cellChangeHandler({cell: scope.model.cell})
                        checkDate()
                    }

                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                scope.date.estimatedStartDate = moment(cell.value.estimatedStartDate).toDate();;
                            }
                        }
                    });
                    scope.saveChanges = function() {
                        var value = {
                            estimatedStartDate: scope.date.estimatedStartDate
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);
                    

                    scope.$watch("model", function (model) {
                        if (scope.model) {
                            if(scope.model.cell && scope.model.cell.value && scope.model.cell.value.estimatedStartDate){
                                scope.date.estimatedStartDate = moment(scope.model.cell.value.estimatedStartDate).toDate();
                            }
                            if (scope.model.row) {
                                scope.row = scope.model.row;
                                
                                if(scope.row.startTime){
                                    scope.date.startDate = moment(scope.row.startTime).toDate();
                                }
                                
                                scope.date.datePicker = {
                                    opened: false
                                },
                                scope.date.estimatedDatePicker = {
                                    opened: false
                                }
                                checkDate();
                            } else {
                                createTempDate();
                            }
                        } 
                        model.getSortValue = function() {
                            if (scope.date) {
                                if(scope.date.startDate){
                                    return scope.date.startDate;
                                } else {
                                    return scope.date.estimatedStartDate;
                                }              
                            }
                            return null;
                        }

                    });

                    scope.subscribe("SmartTableRowStartTimeUpdated", function (cell) {
                        if (cell.id == scope.model.cell.smartTableRowId) {
                            scope.date.startDate = moment(cell.startTime).toDate();
                            checkDate();
                        }
                    });
                    function createTempDate(){
                        scope.date = {
                            id: scope.model.cell.smartTableRowId,
                            startDate: null,
                            estimatedStartDate: null,
                            datePicker : {
                                opened: false
                            },
                            estimatedDatePicker : {
                                opened: false
                            }
                        };
                    }

                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            number: scope.estimatedStartDate
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    }
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                    function checkDate() {
                        scope.date.status = "";
                        if (scope.date) {
                            scope.dateMissed = false;

                            scope.date.startDate = timeService.validateDate(scope.date.startDate);
                            scope.date.estimatedStartDate = timeService.validateDate(scope.date.estimatedStartDate);
                            
                            if (scope.date.startDate) {
                                scope.date.status = "completed";
                                var diff =  moment.duration(moment(scope.date.startDate).diff(moment(scope.date.estimatedStartDate)));
                                var days = diff.asDays();
                                if(!isNaN(days)){
                                    scope.date.diffPlannedVSActual = Math.round(days);
                                }else {
                                    scope.date.diffPlannedVSActual = null;
                                }
                            } else if (scope.date.estimatedStartDate) {
                                //check if overdue/missed
                 
                                var duration = moment.duration(moment().startOf('day').diff(moment(scope.date.estimatedStartDate).startOf('day')));
                                var days = duration.asDays();
                                if (days > 1) {
                                    scope.date.status = "missed";
                                    scope.date.diffToPlannedDate = Math.trunc(days);
                                    scope.dateMissed = true;
                                } else {
                                    scope.date.status = "future";
                                    scope.date.diffToPlannedDate = Math.round(days)*-1;
                                }
                            }
                        }
                    }
                }
            }
        }]);
