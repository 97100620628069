angular.module('DigiLean').factory('mathService', [
    function () {
        function mathService() {

            return {

                roundNumber: function(num, scale){
                    if(!num) return num;
                    if(!("" + num).includes("e")) {
                        return +(Math.round(num + "e+" + scale)  + "e-" + scale);
                      } else {
                        var arr = ("" + num).split("e");
                        var sig = ""
                        if(+arr[1] + scale > 0) {
                          sig = "+";
                        }
                        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
                      }
                },
                isNumeric: function(str, allowblank) {
                    if(allowblank && (str == "" || !str)) return true;
                    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
                },
                correctForComma: function(str){
                    if(str && str.indexOf(",") > -1){
                        return str.replace(",", ".");
                    }
                    return str;
                }
            };
        }
        return mathService;
    }]);

