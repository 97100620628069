angular
    .module('DigiLean')
    .directive("strategyDescriptionCell", ['strategyGoalService',
        function (strategyGoalService) {
            return {
                templateUrl: 'strategyDescriptionCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                },
                link: function (scope, elem, attrs) {
                   
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";                
                    scope.description = "";
   

                    scope.$watch('model', function (model) {
                        if(scope.model){
                            scope.description =  model.goal.description;
                            model.getSortValue = function () {
                                return scope.description;
                            }
                        }
                        
                    });

                    scope.setActive= function(){
                        scope.active = true;
                    }

                    scope.subscribe("StrategyGoalDescriptionUpdated", function (goal) {
                        if (goal.id == scope.model.goal.id) {
                            if (goal.description) {
                                scope.description = goal.description;
                            } else {
                                scope.description = "";
                            }
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            //document.querySelector( "input" ).focus();
                            $(".layouttextarea").focus();
                        }, 50 );
                    }

                    scope.updateDescription = function(description) {
                        scope.description = description;
                        var goalDescription = {
                            id: scope.model.goal.id,
                            description : scope.description
                        };
                        strategyGoalService().updateDescription(goalDescription).then(function (result) {
                        });
                    };
                    

                }
            }
        }]);
