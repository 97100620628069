import { userFullProfile } from "@common/stores/userStore"
import angular from "angular"
import { DigiLeanNgScope } from "@common/model/angularModel"

interface iHasModuleDirective extends DigiLeanNgScope{
    hasModule: string
}

angular
.module('DigiLean')
.directive('hasModule', function() {
    return {
        restrict: 'A',
        link: function(scope: iHasModuleDirective, elm, attr) {
            const el = elm[0] as HTMLElement
            if (!el)
                return
            
            const module = attr.hasModule as string
            
            let originalDisplay = el.style.display

            let hasModule = false
            let sub = userFullProfile.subscribe(userProfile => {
                if (module) {
                    if (userProfile && userProfile.modules && Array.isArray(userProfile.modules)) {

                        if (userProfile.modules.some(m => m.name === module))
                            hasModule = true
                    }
                }
                if (!hasModule)
                    el.style.display = "none"
                else
                    el.style.display = originalDisplay
            })
            
            scope.$on('$destroy', function() {
                if (sub)
                    sub.unsubscribe()
            })
        }
    }
})