import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("weekSelector", 
        function () {
            return {
                templateUrl: 'weekSelector.html',
                restrict: 'E',
                scope: {
                    'boardid': '<'
                },
                link: function (scope, elem, attrs) {

                    scope.weekYear = moment().isoWeekYear();
                    scope.weekNumber = moment().isoWeek();
                    scope.week = null;

                    scope.subscribe("BoardWeekChanged", function(weekChange) {
                        if (scope.boardid == weekChange.boardid) {
                            scope.week = weekChange.week;
                            scope.weekYear = weekChange.weekYear;
                            scope.weekNumber = weekChange.weekNumber;
                        }
                    });

                    function loadCurrentWeek() {
                        var week = timeService.getCurrentWeek(scope.weekNumber, scope.weekYear);
                        scope.week = week;
                    }
                    loadCurrentWeek();
                    scope.nextWeek = function () {
                        var firstDayNextWeek = moment(scope.week.endDate).add(1, 'days');
                        scope.weekYear = firstDayNextWeek.isoWeekYear();
                        scope.weekNumber = firstDayNextWeek.isoWeek();
                        loadCurrentWeek();
                        publishWeekChange();
                    }
                    scope.previousWeek = function () {
                        var previousWeek = getPreviousWeek();
                        scope.weekYear = previousWeek.weekYear;
                        scope.weekNumber = previousWeek.weekNumber;
                        loadCurrentWeek();
                        publishWeekChange();
                    }

                    function publishWeekChange() {
                        var weekChange = {
                            boardid: scope.boardid,
                            weekYear: scope.weekYear,
                            weekNumber: scope.weekNumber,
                            week: scope.week
                        }
                        scope.publish("BoardWeekChanged", weekChange);
                    }

                    function getPreviousWeek() {
                        var lastDayPreviousWeek = moment(scope.week.startDate).subtract(1, 'days');
                        return {
                            weekYear: lastDayPreviousWeek.isoWeekYear(),
                            weekNumber: lastDayPreviousWeek.isoWeek(),
                            translatedWeekNumber: lastDayPreviousWeek.isoWeek()
                        }
                    }

                }
            }
        });
