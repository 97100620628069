﻿var DigiLean = DigiLean || {};
DigiLean.domain = {
    domains: ['https://digilean.tools/', 'https://boardhub.digilean.tools/', 'https://improvementhub.digilean.tools/',
        'https://systemhub.digilean.tools/', 'https://socialhub.digilean.tools/'],

    getDomain: function () {
        //localStorage.removeItem("activeDomains");
        var activeDomains = localStorage.getItem("activeDomains");
        if (!activeDomains) {
            activeDomains = [];
        } else {
            activeDomains = JSON.parse(activeDomains);
        }
        // loop through domains and check if already used.

        for (var i = 0; i < DigiLean.domain.domains.length; i++) {
            // check activedomain
            var domain = DigiLean.domain.domains[i];
            var index = activeDomains.indexOf(domain);
            if (index === -1) {
                DigiLean.domain.addActiveDomain(domain);
                return domain;
            }
        }
        DigiLean.domain.addActiveDomain(DigiLean.domain.domains[0]);
        return domain;
    },

    addActiveDomain: function (domain) {
        // localStorage.removeItem("activeDomains");
        var activeDomains = localStorage.getItem("activeDomains");
        if (!activeDomains) {
            activeDomains = [];
        } else {
            activeDomains = JSON.parse(activeDomains);
        }
        activeDomains.push(domain);

        localStorage.setItem("activeDomains", JSON.stringify(activeDomains));
    },

    removeDomain(domain) {
        var activeDomains = localStorage.getItem("activeDomains");
        if (!activeDomains) {
            activeDomains = [];
        } else {
            activeDomains = JSON.parse(activeDomains);
        }
        var index = activeDomains.indexOf(domain);
        if (index > -1) {
            activeDomains.splice(index, 1);
        }
        localStorage.setItem("activeDomains", JSON.stringify(activeDomains));
    }
}
