angular
    .module('DigiLean')
    .directive("monthlystatusImprovement", ['$filter', '$translate', 'suggestionService', 'authService', 'layoutService',
        function ($filter, $translate, suggestionService, authService, layoutService) {
            return {
                templateUrl: 'monthlystatusImprovement.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {

                    var grafcolor = layoutService().getColors(), //['#2A2E36', '#0099DE', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#DA4453'],
                        graphtextcolor = '#ffffff',
                        colorById = [];
                    var chartHeight = 400;

                    var leadtimecategories = [],
                        xfirstlinecategories = [],
                        xmonthcategories = [],
                        browserDataSuggestionByAsset = [],
                        dataFromNewToPlanned = [];

                    var customerId = authService.getCurrentUser().customerId;

                    function getColorById(id) {
                        if (id === null) id = 0;
                        var color = $filter('filter')(colorById, { id: id });
                        if (color.length > 0) {
                            return color[0].color;
                        } else {
                            var index = colorById.length % grafcolor.length;
                            var newColorById = {
                                id: id,
                                color: grafcolor[index] //colorById.length]
                            }
                            colorById.push(newColorById);
                            return newColorById.color;
                        }
                    }

                    function getStatusData() {
                        var endDate = moment().utc().endOf('month');
                        var startDate = moment().utc().add(-3, 'month').startOf('month');
                        // Monthly suggested, need to find start and end of month
                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };
                        suggestionService().getNoOfSuggestionByAssetAndStatusAndMonth(timePeriod).then(function (result) {
                            var data = result;
                            var newSuggestionByAssetData = result.new;
                            var implementedSuggestionByAssetData = result.implemented;
                            var notSpecified = $translate.instant('COMMON_NOT_SPECIFIED');

                            if (newSuggestionByAssetData) {
                                if(newSuggestionByAssetData.length > 12){     
                                    chartHeight = 400 + 30*(newSuggestionByAssetData.length -12);
                                }

                                // Build the data arrays
                                for (var i = 0; i < newSuggestionByAssetData.length; i++) {
                                    var assetId = newSuggestionByAssetData[i].assetId;
                                    var asset = newSuggestionByAssetData[i].asset;
                                    if (!assetId) {
                                        assetId = 0;
                                        asset = notSpecified;
                                    }

                                    // add new browser data
                                    browserDataSuggestionByAsset.push({
                                        name: asset,
                                        data: newSuggestionByAssetData[i].suggestionCount,
                                        color: getColorById(assetId),
                                        stack: firstlinecategorynew,
                                        xAxis: 1
                                    });

                                    // add implemented browser data
                                    if (implementedSuggestionByAssetData.length > i) {
                                        var impAssetId = implementedSuggestionByAssetData[i].assetId;
                                        var impAsset = implementedSuggestionByAssetData[i].asset;
                                        if (!impAssetId) {
                                            impAssetId = 0;
                                            impAsset = notSpecified;
                                        }
                                        browserDataSuggestionByAsset.push({
                                            name: impAsset,
                                            data: implementedSuggestionByAssetData[i].suggestionCount,
                                            color: getColorById(assetId),//getColorById(newSuggestionByAssetData[i].assetId),
                                            showInLegend: false,
                                            stack: firstlinecategoryimpl,
                                            xAxis: 1
                                        });
                                    }
                                }

                                xmonthcategories = newSuggestionByAssetData[0].monthNumbers;

                                for (var i = 0; i < xmonthcategories.length; i++) {
                                    xfirstlinecategories.push('');
                                    xfirstlinecategories.push(firstlinecategorynew);
                                    xfirstlinecategories.push(firstlinecategoryimpl);
                                    xfirstlinecategories.push('');
                                }

                                var zeroes = [];
                                xfirstlinecategories.forEach(function () { zeroes.push(0); });


                                browserDataSuggestionByAsset.push({
                                    name: '',
                                    data: zeroes,
                                    showInLegend: false,
                                    stack: firstlinecategoryimpl,
                                });

                                var monthly = $(elem).find(".monthlystatus")
                                Highcharts.chart({
                                    chart: {
                                        renderTo: monthly[0],
                                        type: 'column',
                                        height: chartHeight,
                                    },


                                    title: {
                                        text: ''
                                    },

                                    credits: {
                                        enabled: false
                                    },
                                    exporting: { enabled: false },

                                    xAxis: [
                                        {
                                            categories: xfirstlinecategories,
                                            labels: { autoRotation: false, style: { textOverflow: 'none' } },
                                            tickWidth: 0,
                                        },
                                        {
                                            categories: xmonthcategories,
                                            lineColor: graphtextcolor,
                                            tickWidth: 1,
                                            tickLength: 30
                                        },
                                    ],

                                    yAxis: {
                                        allowDecimals: false,
                                        min: 0,
                                        title: {
                                            text: noOfSuggestions
                                        }
                                    },

                                    tooltip: {
                                        formatter: function () {
                                            return '<b>' + this.x + ' ' + this.series.options.stack + '</b><br>' +
                                                this.series.name + ': ' + this.y + '<br>' +
                                                'Total: ' + this.point.stackTotal;
                                        }
                                    },
                                    plotOptions: {
                                        series: {
                                            events: {
                                                legendItemClick: function () {
                                                    var myname = this.name;
                                                    var myvis = !this.visible;
                                                    this.chart.series.forEach(function (elem) {
                                                        if (elem.name == myname) {
                                                            elem.setVisible(myvis);
                                                        }
                                                    });
                                                    return false;
                                                }
                                            }
                                        },
                                        column: {
                                            stacking: 'normal',
                                            groupPadding: 0.20,
                                        }
                                    },
                                    series: browserDataSuggestionByAsset
                                });
                            }

                        });


                    }

                    var firstlinecategorynew = "";
                    var firstlinecategoryimpl = "";
                    var noOfSuggestions = "";
                    var fromInProgressToImpl = "";
                    var fromPlannedToInProgress = "";
                    var fromNewToPlanned = "";

                    $translate(['COMMON_NEW', 'IMPROVEMENT_IMPL', 'IMPROVEMENT_NUMBER_OF_SUGGESTIONS', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'IMPROVEMENT_FROM_NEW_TO_PLANNED']).then(function (translations) {
                        firstlinecategorynew = translations.COMMON_NEW;
                        firstlinecategoryimpl = translations.IMPROVEMENT_IMPL;
                        noOfSuggestions = translations.IMPROVEMENT_NUMBER_OF_SUGGESTIONS;
                        fromInProgressToImpl = translations.IMPROVEMENT_FROM_IN_PROGRESS_TO_IMPL;
                        fromPlannedToInProgress = translations.IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS;
                        fromNewToPlanned = translations.IMPROVEMENT_FROM_NEW_TO_PLANNED;

                        getStatusData();

                    });
                }

            }
        }]);



















