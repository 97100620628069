import backend from "@common/services/backendHttp"
import type { CustomerConnectedClients } from "@api"

const resourceUrl = "hubs/system"

export const getAllUsersOnlineRest = async () => {
    const url = `${resourceUrl}/getAllUsersOnline`
    return await backend.get<CustomerConnectedClients[]>(url)
}

export const updateSource = async (connectionId: string, source: string) => {
    const user = {connectionId, source}
    const url = `${resourceUrl}/updateSource`
    await backend.post(url, user)
}

export const sendProxyEvent = async (eventKey: string, data?: Object) => {
    const url = `${resourceUrl}/ProxyEvent/${eventKey}`
    if (!data)
        data = {}
    await backend.post(url, data)
}


export const postConnectionEvent = async (connectionId: string) => {
    const url = `signalruser/${connectionId}`
    await backend.post(url, {connectionId})
}