import { ConnectorMonitor, Heartbeat } from "@api"
import * as monitoringService from "@common/services/data/monitoringService"
import angular from "angular"
import { debounce } from "lodash"

import { NgComponent } from "@common/model/angularModel"

interface IConnectorMonitor extends NgComponent {
    data: ConnectorMonitor
    loading: boolean
    showNumRuns: number
    pageNum: number
    connectorId: string
}

const DigiLean = angular.module('DigiLean')
DigiLean.component('connectorMonitor', {
    templateUrl: "connectorMonitor.html",
    controller: ['$stateParams',
        function(this: IConnectorMonitor, $stateParams) {
            let $ctrl = this
            
            $ctrl.loading = false
            $ctrl.showNumRuns = 7
            $ctrl.pageNum = 1
            $ctrl.connectorId = $stateParams.id as string
            
            $ctrl.runs = {}
            $ctrl.data = { id: $ctrl.connectorId,name: "", customerId: 0 }
            $ctrl.$onInit = function() {
                loadData()
            }
            
            const loadData = async () => {
                $ctrl.loading = true

                const data = await monitoringService.getConnector($ctrl.connectorId, $ctrl.showNumRuns, $ctrl.pageNum)
                
                $ctrl.data = data
                $ctrl.loading = false
            }
            $ctrl.reload = function() {
                reloadDebounced()
            }

            const reloadDebounced = debounce(loadData, 1000)
        }
    ]
})