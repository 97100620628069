import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

type PopupDirection = "down" | "up"

@customElement('digilean-popup')
export class PopupElement extends LitElement {
    
    private buttonSlot: HTMLElement | null
    private contentSlot: HTMLElement | null

    private clickHandler
    constructor() {
        super()
        this.buttonSlot = null
        this.contentSlot = null
    }
    static styles = css`
        :host {
            display: block;
            position: relative;
            height: var(--digilean-image-height, 1.5rem);
            width: var(--digilean-image-width, 2rem);
            --digilean-fa-icon-width: 1rem;
            --digilean-fa-icon-height: 1rem;
        }
        fa-icon:hover {
            cursor: pointer;
        }
        div.popdown {
            display: none;
            flex-direction: column;
            position: absolute;
            width: var(--digilean-popup-width, 200px);
            z-index: 10;
            top: 2rem;
            right: 0;
            border-radius: var(--digilean-border-radius, 3px);
            background: var(--digilean-secondary-background);
            border: 1px solid var(--digilean-input-border);
            box-shadow: var(--digilean-popup-shadow);
        }
        div.popdown.show {
            display: flex;
        }
        div.top {
            display: flex;
            position: static;
            flex-direction: row;
            justify-content: flex-end;
        }
        div.content {
            position: static;
        }
    `

    @property({attribute: true})
    direction: PopupDirection = "down"

    @property({attribute: false})
    show: boolean = false

    toggle() {
        console.log("toggle")
        this.show = !this.show
        this.requestUpdate()
    }
    //popupClickHandler(e: Event) {
        //const composedPath = e.composedPath()
        //const target = e.target as HTMLElement
        //this.getSlots()
        // if (this.buttonSlot && composedPath.includes(this.buttonSlot)) {
        //     if (!this.show)
        //         this.show = false
        //     return
        // }
        //if (!this.contentSlot)
        //    return
        // do nothing when not showing
        //if (!this.show)
        //    return
        // close when showing and clicking outside
        // if (!composedPath.includes(this.contentSlot))
        //     this.show = false  
    //}
    getSlots() {
        if (!this.shadowRoot || (this.contentSlot && this.buttonSlot))
            return
        this.buttonSlot = this.shadowRoot.querySelector("slot[name=button]")
        this.contentSlot = this.shadowRoot.querySelector("slot[name=content]")
    }
    connectedCallback(): void {
        super.connectedCallback()
        this.getSlots()
        // this.clickHandler = (e: Event) => this.popupClickHandler(e)
        // document.addEventListener("click", this.clickHandler)
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
        // document.removeEventListener("click", this.clickHandler)
    }
    render() {
        
        return html`
            <slot name="button">
                <fa-icon icon="fas fa-ellipsis-v">
                </fa-icon>
            </slot>
            
            <div class="popdown ${this.show ? 'show' : ''}">
                <div class="top">
                    <!-- <fa-icon icon="fa fa-times-circle center" @click=${this.toggle}></fa-icon> -->
                </div>
                <div class="content">
                    <slot name="content">no content</slot>
                </div>
            </div>
        `
    }
}