import { getUserProfile } from "@common/stores/userStore"

angular.module('DigiLean').factory('userProvider', [
    function () {
        function userProvider() {
            return {
                getUserLanguage: function () { 
                    let language = "en"
                    const userprofile = getUserProfile()
                    if (userprofile.preferredLanguage)
                        language = userprofile.preferredLanguage
                    return language;
                },
            };
        }
        return userProvider;
    }]);

