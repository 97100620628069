angular
    .module("DigiLean")
    .directive('debugApp', ['$translate',
        function ($translate) {
            return {
                templateUrl: 'debugApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.title =  "DEBUGGING"
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                    });

                    scope.updateSettings = function(){
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                title: scope.title
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                    scope.$on("widget-resized", function (event, args) {
                        // var host = $(elem).closest(".grid-stack-item-content");
                        // if (host.length == 0)
                        //     host = $(elem).closest(".modal-body")
                        
                        // if (host.length == 0) return;
                        // var width = host.width();
                        // var height = host.height();
                        // // Find header to subtract from new height
                        // var header = $(elem).find(".ibox-title");
                        // var headerHeight = header.height() + 40;
                        // var container = $(elem).find(".debug-app");
                        // if (container.length === 0) return;
                        // $(container).css("height", (height - headerHeight));
                    });

                }
            }
        }]);

