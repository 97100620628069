angular
    .module('DigiLean')
    .directive("taskActions", ['$uibModal', 'boardTaskService',
        function ($uibModal, boardTaskService) {
            return {
                templateUrl: 'taskActions.html',
                restrict: 'E',
                scope: {
                    'task': '<',
                    'editable': '<',
                    'options': '='
                },
                link: function (scope, elem, attrs) {
                    // scope.$watch('task', function (task) {
                    //     console.log(task);
                    // });

                    scope.$watch('options', function (options) {
                       if(options){
                            if(options.disableCopy){
                                scope.disableCopy = true;   
                            }
                       }
                    });

                    scope.cloneTask = function () {
                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'clonetask.html',
                            controller: 'clonetaskController',
                            resolve: {
                                task: function () {
                                    return scope.task;
                                }
                            }
                        });
                    }

                    scope.deleteTask = function (task) {
                        if (task.siblingId) {
                            $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'clonedelete.html',
                                controller: 'clonedeleteController',
                                windowClass: 'newdeviation-modal-window',
                                resolve: {
                                    taskId: function () {
                                        return task.id;
                                    },
                                    siblingId: function () {
                                        return task.siblingId;
                                    }
                                }
                            });
                        } else {
                            boardTaskService().deleteTask(task.id).then(function () {
                            });
                        }
                    }
                    scope.copyTask = function (task) {
                        boardTaskService().copyTask(task.id).then(function (result) {
                            
                        });
                    }
                    scope.archive = function (task) {
                        boardTaskService().moveTaskToArchive(task.id).then(function (result) {
                            
                        });
                    }

                    scope.unarchive = function (task) {
                        boardTaskService().moveTaskFromArchive(task.id).then(function (result) {
                            
                        });
                    }

                    scope.moveTask = function (task) {
                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'movetask.html',
                            controller: 'movetaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    }
                }
            }
        }]);
