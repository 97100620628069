// Controller for two modual popup newtasktopersonalactionlist.html and newtasktoactionlist.html
import * as boardService from "@common/services/boardService"
import { getUserProfile } from "@common/stores/userStore"

angular.module("DigiLean").controller('newtasktopersonaltasklistController', ['$scope', '$filter', '$uibModalInstance', '$translate', 'boardTaskService', 'navigationService', 'employeeService', 'options',
    function ($scope, $filter, $uibModalInstance, $translate, boardTaskService, navigationService, employeeService, options) {
        $scope.boards = {};
        $scope.selectedEmployee = {};
        $scope.allUsers = {};

        /* Froala has some issues unless given a little pause before showing  */
        $scope.isFormReady = false;
        setTimeout(function () {
            $scope.isFormReady = true;
        }, 100);

        var translatedPersonalBoard = $translate.instant('BOARD_PERSONAL_BOARD');
        //$scope.selectedBoard = translatedPersonalBoard;
        // the new action object
        $scope.task = {
            id: 0,
            text: "",
            priorityStatus: 0,
            isActionItem: true,
            status: 'blank'
        };

        $scope.hasBoardModule = navigationService().hasModule("BOARD");

        $scope.emptyUser = {
            id: '',
            userName: null,
            profileImageUrl: null,
            fullName: null,
            displayName: null
        }

        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasBoardModule = navigationService().hasModule("BOARD");
        });

        boardService.getMemberBoards().then(function (data) {
            $scope.boards = data;
            var personalBoard = {
                name: translatedPersonalBoard,
                id: null
            }
            $scope.boards.unshift(personalBoard);
            $scope.selectedBoard = personalBoard;
            if (options && options.selectedUser) {
                $scope.selectedEmployee = options.selectedUser;
                $scope.task.responsibleImageUrl = options.selectedUser.profileImageUrl;
                $scope.task.responsibleUserId = options.selectedUser.userId; //Note that this object uses userId not id
                $scope.task.responsibleUser = options.selectedUser.fullName;
                $scope.task.responsibleDisplayName = options.selectedUser.displayName;
                $scope.task.user = options.selectedUser;
            } else {
                $scope.getUserProfile();
            }
        });

        employeeService().getList().then(function (data) {
            $scope.allUsers = data;
            $scope.employees = $scope.allUsers;
        });

        $scope.descriptionChanged = function (content) {
            $scope.task.text = content;
        }

        $scope.getUserProfile = function () {
            const currentUser = getUserProfile()
            $scope.selectedEmployee = currentUser;
            $scope.selectUser(currentUser);
        }

        $scope.boardDateChanged = function($event) {
            const date = $event.originalEvent.detail
            $scope.task.boardDate = date
        }


        if (options && options.date) {
            $scope.task.boardDate = options.date.toDate();
        }       

        $scope.changePriorityStatus = function (priority) {
            $scope.task.priorityStatus = priority;
        }


        $scope.selectUser = function (user) {

            $scope.task.responsibleImageUrl = user.profileImageUrl;
            $scope.task.responsibleUserId = user.id;
            $scope.task.responsibleUser = user.fullName;
            $scope.task.responsibleDisplayName = user.displayName;
            if (user.id == '') {
                $scope.task.user = null;
            } else {
                $scope.task.user = user;
            }
        }

        $scope.getUserListForBoard = function (board) {
            if (board.id) {
                boardService.getUserListForBoard(board.id).then(function (data) {
                    angular.forEach(data, function (employee, key) {
                        employee.fullName = employee.firstName + " " + employee.lastName;
                    });
                    $scope.employees = data;
                    if (data.length > 0) {
                        if ($scope.task && $scope.task.responsibleUserId) {
                            $scope.task.user = $filter('filter')(data, { id: $scope.task.responsibleUserId }, true)[0];
                        }
                    }
                });
            } else {
                $scope.employees = $scope.allUsers;
            }
        }

        $scope.ok = function () {
            // Create new action 

            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: 0,
                    Tag: tagsArray[i],
                    BoardTaskId: 0
                });
            }
            var boardId = null;
            if ($scope.selectedBoard && $scope.selectedBoard.name !== translatedPersonalBoard) {
                boardId = $scope.selectedBoard.id;
            }
            
            $scope.task.boardId = boardId;
            $scope.task.tags = tags;

            boardTaskService().addTask($scope.task).then(function (taskId) {
                $scope.task.id = taskId;
                $scope.publish("SendCreateActionListItem", $scope.task);
                $uibModalInstance.close($scope.task);
            });

        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };



        setTimeout(function () {
            $("#taskTags").tagit({
                // availableTags: sampleTags,
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
        }, 200);
    }]);