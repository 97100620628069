angular
    .module('DigiLean').directive("a3ViewerApp", ['a3Service', 'debounce',
        function a3ViewerApp(a3Service, debounce) {
            return {
                templateUrl: 'a3ViewerApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function ($scope, elem) {
                    $scope.a3Id = null;
                    $scope.canEdit = false;
                    //$scope.isAdminMode = false;
                    $scope.selectedA3 = {};

                    $scope.$watch('isAdminMode', function (value) {
                        if(value){
                            loadAllA3s();
                        }
                    });

                    $scope.$watch('settings', function (settings) {
                        $scope.settings = settings;
                        if (settings && settings.a3Id) {
                            $scope.a3Id = settings.a3Id;
                        }
                        updateContainerHeightDebounced();
                    });

                    $scope.a3Changed = function (selectedA3) {
                        $scope.a3Id = selectedA3.id;
                        updateSettings();
                    }

                    
                    function loadAllA3s() {
                        if ($scope.allA3s) return;
                        a3Service().getA3s().then(function (a3s) {
                            $scope.allA3s = a3s;
                            if ($scope.a3Id) {
                                const selected = $scope.allA3s.find(a => a.id == $scope.a3Id);
                                if(selected){
                                    $scope.selectedA3 = selected;
                                }
                            }
                        });
                    }

                    function updateSettings() {
                        if ($scope.isAdminMode) {
                            var componentSettings = {
                                a3Id: $scope.a3Id
                            };
                            $scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                    $scope.targetSize = {
                        height: 1654,
                        width: 2338
                    }
                    function updateContainerHeight() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 40;
                        var container = $(elem).find(".a3viewer");
                        if (container.length === 0) return;
                        var targetHeight = height - headerHeight;
                        //$(container).css("height", targetHeight);
                        $scope.targetSize = {
                            height: height,
                            width: width
                        }
                    }
                    const updateContainerHeightDebounced = debounce(updateContainerHeight, 100, false)
                    $scope.$on("widget-resized", function (event, args) {
                        updateContainerHeightDebounced();
                    });
                }
            }

        }]);






