
angular.module("DigiLean")
    .directive('threeCTextCell', ['debounce',
        function (debounce) {
            return {
                templateUrl: "threeCTextCell.html",
                restrict: "E",
                scope: {
                    'content': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('content', function (model) {

                    });

                    scope.contentChanged = function() {
                        changesDebounced();
                    }
                
                    var changesDebounced = debounce(commitChanges, 3000, false);
                    function commitChanges() {
                        scope.changeHandler({content: scope.content});
                    }
                }
            }
        }]);