angular.module("DigiLean").factory('hoursService', ['$http', 'webApiUrl', 
    function ($http, webApiUrl) {
        function hoursService() {
            var resource = "/hours";
            var resourceUrl = webApiUrl + resource;
            return {
                getProjectHours: function (timePeriod) {
                    var url = resourceUrl + "/GetProjectHours";
                    return $http.post(url, timePeriod).then(function(result){
                        return result.data;
                    });
                },
                approveHours: function (hours) {
                    var url = resourceUrl + "/approveHours";
                    return $http.post(url, hours).then(function(result){
                        return result.data;
                    });
                },
                unApproveHours: function (hours) {
                    var url = resourceUrl + "/unApproveHours";
                    return $http.post(url, hours).then(function(result){
                        return result.data;
                    });
                },
            };
        }
        return hoursService;
    }]);