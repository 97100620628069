import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("numberCellAutomatic", ['$filter', 'debounce', 'projectService','dataService','mathService',
        function ($filter, debounce, projectService, dataService, mathService) {
            return {
                templateUrl: 'numberCellAutomatic.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.number = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";
                    scope.lastDate = null;
                    scope.projectId = null;
                    scope.color = "#676a6c";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    var filters = [];
                    var dataSource = null;
                    var period = null; 
                    scope.projectAttributeId = null;
                    scope.decimals = null;
                    var settings = null;

                    scope.$watch('model', function (model) {
                        var value = model.projectAttribute.value;
                        scope.projectAttributeId = model.boardAttributeId;
                        settings = model.column.settings;
                        //TODO: if settings.timePeriod.timeframe == "last"
                        if (settings.timePeriod.timeframe !== "last") {     
                            period = timeService.getTimePeriod(settings.timePeriod.timeframe);
                        } else {
                            period = timeService.getTimePeriod("all");
                        }
                        scope.projectId = model.project.id;
                        if (settings) {
                            if(settings.dataSource){
                                dataSource = settings.dataSource;
                            }
                            if(value){
                                scope.color = value.color;
                                scope.background = value.background;
                            }
                            if(settings.decimals !== null || settings.decimals !== undefined){
                                scope.decimals = settings.decimals;
                            }
                            if(settings.unit){
                                scope.unit = settings.unit;
                                if(settings.unitPlacement){
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                            if(settings.filters){
                                filters = settings.filters.slice();
                                var existingProjectFilter = $filter('filter')(filters, { sourceColumn: "projectId" });
                                if (existingProjectFilter.length > 0) {
                                    var index = filters.indexOf(existingProjectFilter[0]);
                                    filters.splice(index, 1);
                                }
                            }
                            var projectfilter = {
                                items: [scope.projectId],
                                operator: "InList",
                                sourceColumn: "projectId"
                            }
                            filters.push(projectfilter);
                            scope.updateValue(dataSource.id);
                            if(model.column.projectAttribute){
                                setColor(model.column.projectAttribute);
                            }
                        } 
                        model.getSortValue = function () {
                            return scope.number;
                        }
                    });
                    
                    scope.subscribe("ProjectNumberCellAutomaticTimeframeUpdated", function (value) {
                        if(scope.projectAttributeId === value.boardAttributeId){                           
                            scope.lastDate = null;
                            settings.timePeriod.timeframe = value.timePeriod.timeframe;
                            scope.updateValue(dataSource.id);
                        }
                    });
                    scope.subscribe("ProjectNumberCellDecimalUpdated", function (value) {
                        if(scope.projectAttributeId === value.projectAttributeId){
                            scope.decimals = value.decimals;
                            scope.updateValue(dataSource.id);
                        }
                    });
                    scope.subscribe("ProjectNumberCellUnitUpdated", function (value) {
                        if(scope.projectAttributeId === value.projectAttributeId){
                            scope.unit = value.unit;
                        }
                    });
                    scope.subscribe("ProjectNumberCellUnitPlacementUpdated", function (value) {
                        if(scope.projectAttributeId === value.projectAttributeId){
                            scope.unitPlacement = value.unitPlacement;
                        }
                    });
                    scope.subscribe("DataValueAdded", function (value) {
                        scope.updateValue(value.dataSourceId);
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        scope.updateValue(value.dataSourceId);
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        scope.updateValue(value.dataSourceId);
                    });

                    function getLastValue(){
                        if (settings.timePeriod.timeframe === "last") {
                            period = null;
                        } else {
                            if(!period){
                            period = timeService.getTimePeriod("all");
                            }
                        }
                        dataService().getLastDate(dataSource.id, period, filters).then(function (fromDate) {
                            scope.lastDate = moment(fromDate).toDate();
                            var toDate = moment(fromDate).endOf('day');
                            var dayperiod = {
                                from:fromDate,
                                to:toDate
                            };
                        
                            var options = {
                                timePeriod: dayperiod,
                                filters: filters,
                            };

                            dataService().getSumForPeriod(dataSource.id, options).then(function (sum) {
                                scope.number = sum;
                                    var payload =  {
                                        projectAttributeId: scope.projectAttributeId,
                                        projectId: scope.projectId,
                                        value: scope.number
                                    }  
                                    scope.publish("ProjectNumberCellUpdated", payload);

                                    if(scope.decimals !== null) {
                                        if(scope.decimals !== undefined) {
                                            scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                        }
                                    }                           
                            });
                        });

                    }

                    function getAll(){
                        
                        period = timeService.getTimePeriod("all");
                        
                        dataService().getLastDate(dataSource.id, null, filters).then(function (lastDate) {
                       
                            var toDate = moment(lastDate).endOf('day');
                            var newperiod = {
                                from:period.from,
                                to:toDate.toDate().toJSON()
                            };
                        
                            var formula = null;
                            if(dataSource.valueElement.unit == "%"){
                                formula = "average";
                            }
                            var options = {
                                timePeriod: newperiod,
                                filters: filters,
                                formula: formula
                            };

                            dataService().getSumForPeriod(dataSource.id, options).then(function (sum) {
                                scope.number = sum;
                                var payload =  {
                                    projectAttributeId: scope.projectAttributeId,
                                    projectId: scope.projectId,
                                    value: scope.number
                                }  
                                scope.publish("ProjectNumberCellUpdated", payload);
                                if(scope.decimals !== null) {
                                    if(scope.decimals !== undefined) {
                                        scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                    }
                                }                          
                            });
                        });

                    }
                    scope.updateValue = function (id) {
                        if (!dataSource) return;
                        if (dataSource.id !== id) return;
                        if (settings.timePeriod.timeframe === "last") {
                            getLastValue();
                        } else if(settings.timePeriod.timeframe === "all" ){
                            getAll();
                        } else{
                            period = timeService.getTimePeriod(settings.timePeriod.timeframe);

                            var formula = null;
                            if(dataSource.valueElement.unit == "%"){
                                formula = "average";
                            }
                            var options = {
                                timePeriod: period,
                                filters: filters,
                                formula: formula
                            };
                            dataService().getSumForPeriod(dataSource.id, options).then(function (sum) {
        
                                scope.number = sum;
                                var payload =  {
                                    projectAttributeId: scope.projectAttributeId,
                                    projectId: scope.projectId,
                                    value: scope.number
                                }  
                                scope.publish("ProjectNumberCellUpdated", payload);
                                if(scope.decimals !== null) {
                                    if(scope.decimals !== undefined) {
                                        scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                    }
                                }                           
                            });
                        }
                    
                    };
                    
                    var setColor = function(cell) {
                        if(cell && cell.background){
                            scope.background = cell.background;
                            scope.color = cell.color;
                        }
                     
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = cell.background;
                        }
                    }
/*
                    function updateCellSumToTotalSum(sum) {
                        var projectSum = {projectId: scope.projectId,
                            cellNumber: scope.number};
                        var existingSumForProject = $filter('filter')(scope.model.column.projectAttributes, { projectId: projectId });
                        
                        if (existingSumForProject && existingSumForProject.length > 0) {
                            var index = scope.model.column.projectAttributes.indexOf(existingSumForProject);
                            scope.model.column.projectAttributes[index] = projectSum;
                        } else {
                            scope.model.column.projectAttributes.push(projectSum);
                        }                       
                    }*/
                
                    /*
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.settings) {
                               // setValue(attribute);
                            }
                        }
                    });*/

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            //dataSource: dataSource
                        }
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
