angular.module("DigiLean").filter('improvementPriorityTranslation', ['$translate', function ($translate) {
    return function(priority) {
        switch (priority) {
                case 1:
                    return $translate.instant('IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT');
                case 2:
                    return $translate.instant('IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT');
                case 3:
                    return $translate.instant('IMPROVEMENT_LOW_EFFORT_LOW_EFFECT');
                case 4:
                    return $translate.instant('IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT');
                default:
                    return $translate.instant('IMPROVEMENT_NOT_PRIORITIZED');
                    
            }
    };
}]);