
import angular from "angular"

const DigiLean = angular.module('DigiLean')
DigiLean.component('userComponent', {
    templateUrl: "userComponent.html",
    controller: ['$rootScope',
        function($rootScope) {
            
        }]
})