angular
.module('DigiLean')
.controller('learningViewerController', ['$scope','$stateParams', '$filter', 'learningPublicService', '$uibModal', '$state', '$translate',
    function ($scope,$stateParams, $filter, learningPublicService, $uibModal, $state, $translate) {
        $scope.id = parseInt($stateParams.id)
        $scope.selectedPageId = null
        if ($stateParams.pageid) {
            $scope.selectedPageId = parseInt($stateParams.pageid)
        }
        this.uiOnParamsChanged = function(newParams) {
            console.log("new params: ", newParams)
        }
        $scope.isExternal = $stateParams.location == "external"
        $scope.isEditMode = false
        $scope.pages = []
        $scope.items = []
        learningPublicService.getItems($scope.isExternal, $scope.id).then(function (summary) {
            $scope.material = summary.material
            $scope.items = summary.items
            $scope.items.forEach(element => {
                $scope.pages = $scope.pages.concat(element.pages)   
            })
            setCurrentPage($scope.selectedPageId)
        })
        console.debug("loading learningviewer");
        $scope.$on('$locationChangeSuccess', function(e, newUrl, oldUrl) {
            // Use $state.params to update as needed
            // Needed to handle browser back/forward
            if (!newUrl.includes("learning/internal") && !newUrl.includes("learning/external"))
                return 

            if ($stateParams.pageid) {
                $scope.selectedPageId = parseInt($stateParams.pageid)
                setCurrentPage($scope.selectedPageId)
            }else {
                $scope.selectFrontPage()
            }
        })
        $scope.frontPage = null
        $scope.selectedPage = null
        $scope.previousPage = null
        $scope.nextPage = null
        $scope.selectedPageType = "frontPage"
        
        $scope.selectFrontPage = function (page) {
            $scope.selectedPageType = "frontPage"
            $scope.selectedPage = null
            $scope.selectedPageId = null
            $state.go('.', {pageid: ""})
            setNextPage($scope.selectedPage)
        }


        $scope.selectPage = function (page) {
            if (page && page.id != $scope.selectedPageId) {
                learningPublicService.addPageVisit($scope.id, page.id)
            }
            $scope.selectedPage = page
            $scope.selectedPageType = "normalPage"
            $scope.selectedPageId = page.id
            $state.go($state.current, {pageid: page.id}, {location: true})
            setNextPage($scope.selectedPage)
        }

        $scope.pageSelectHandler = function (page) {
            $scope.selectPage(page)
        }

        var setCurrentPage = function(pageId) {
            if (!pageId) {
                setNextPage(null)
                return
            }
            var currentPage = $filter('filter')($scope.pages, { id: pageId }, true)
            if (currentPage.length > 0) {
                $scope.selectPage(currentPage[0])
            }
        }

        var setNextPage = function(currentPage) {

            if (!currentPage) {
                $scope.previousPage = null
                $scope.nextPage = $scope.pages[0]
                return
            }
            var currentIndex = -1
            var currentPage = $filter('filter')($scope.pages, { id: currentPage.id }, true)
            if (currentPage.length > 0) {
               currentIndex = $scope.pages.indexOf(currentPage[0])
            }
            // If lastpage
            if (currentIndex == $scope.pages.length) {
                $scope.nextPage = null
            } else {
                $scope.nextPage = $scope.pages[currentIndex+1]
            }
            if (currentIndex == 0) {
                $scope.previousPage = null
            } else {
                $scope.previousPage = $scope.pages[currentIndex-1]
            }
        }
    }])