import angular from "angular"
import * as apiClientsService from "./apiClientsService"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')
DigiLean.component('apiClients', {
    templateUrl: "apiClients.html",
    controller: ['$uibModal',
        function(this: NgComponent, $uibModal) {
            let $ctrl = this
            $ctrl.noAccess = true
            $ctrl.clients = []

            async function getList() {
                try {
                    const clients = await apiClientsService.getClientList()
                    $ctrl.clients = clients
                    $ctrl.noAccess = false
                }
                catch (err) {
                    console.log(err)
                    $ctrl.noAccess = true
                }
            }


            $ctrl.addClient = () => {
                const modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newApiClientForm.html',
                    controller: 'newApiClientController',
                    resolve: {
                        client: function () {
                            return {
                                id: 0,
                                customerId: 0,
                            }
                        }
                    }
                })
                modalInstance.result.then((newClient) => {
                    apiClientsService.createClient(newClient).then(client => {
                        getList()
                    })
                }, 
                    () => { console.log("closed without saving") 
                })
            }

            $ctrl.editClient = async (clientId) => {
                const clientToEdit = await apiClientsService.getClient(clientId)
                const modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'editApiClientForm.html',
                    controller: 'editApiClientController',
                    resolve: { client: () => clientToEdit }
                })
                modalInstance.result.then((client) => {
                    
                    console.log(`edited ${client.clientId}, name ${client.clientName}`)
                    getList()
                    
                }, () => { console.log("closed without saving")})
            }

            $ctrl.$onInit = function() {
                console.log("init")
                getList()
            }
            
        }
    ]
})