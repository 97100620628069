import { getUserProfile } from "@common/stores/userStore"

import FileSaver from 'file-saver';
angular.module("DigiLean").factory('excelService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {
        function excelService() {
            var resource = "/excel"
            var resourceUrl = webApiUrl + resource;
            let lang = "en"
            const up = getUserProfile()
            if (up.preferredLanguage) {
                lang = up.preferredLanguage
            }
            return {
                exportProjectBoard: function(boardId) {
                    const fileName = "DigiLEAN - project portfolio.xlsx"
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
                    resourceUrl = resourceUrl + "/CreateProjectPortfolioReport/" + boardId + "?timezoneOffsetInMinutes=" + offset + "&lang=" + lang
                    $http.post(resourceUrl, null, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        FileSaver.saveAs(file, fileName);
                    })
                },
                exportDataValues: function(dataSourceId) {
                    const fileName = "DigiLEAN - dataValues.xlsx"
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
                    resourceUrl = resourceUrl + "/CreateDataValuesReport/" + dataSourceId + "?timezoneOffsetInMinutes=" + offset
                    $http.post(resourceUrl, null, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        FileSaver.saveAs(file, fileName);
                    })
                },

                exportImprovementValues: function(params) {
                    var fileName = "DigiLEAN - improvements.xlsx"
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
                    resourceUrl = resourceUrl + "/CreateImprovementReport?timezoneOffsetInMinutes=" + offset + "&lang=" + lang
                    $http.post(resourceUrl, params, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        FileSaver.saveAs(file, fileName);
                    })
                },
                exportDeviationValues: function(params) {
                    var fileName = "DigiLEAN - Deviations.xlsx"
                    
                    var date = new Date()
                    var offset = date.getTimezoneOffset()
                    resourceUrl = resourceUrl + "/CreateDeviationReport?timezoneOffsetInMinutes=" + offset + "&lang=" + lang
                    $http.post(resourceUrl, params, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                        FileSaver.saveAs(file, fileName);
                    })
                },

            }
        }
        return excelService
    }])
