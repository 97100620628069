angular
    .module('DigiLean')
    .directive("strategyBowlerTitle", ['$translate','strategyService','modalService',
        function ($translate,strategyService, modalService) {
            return {
                templateUrl: 'strategyBowlerTitle.html',
                restrict: 'E',
                scope: {
                    'kpi': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.editable = true;

                    /*
                    scope.$watch("kpi", function (kpi) {
                        if (!kpi) return;
                    });*/

                    scope.subscribe("KPIUpdated", function (update) {
                        if (update.Id == scope.kpi.id) {
                            scope.kpi.title = update.title;
                            scope.kpi.unit = update.unit;
                            scope.kpi.operator = update.operator;
                            scope.kpi.title.animate("bounce");
                        }
                    });


                    scope.update = function(){
                        if(scope.kpi.title != ''){
                            strategyService().updateKPI(scope.kpi).then(function () {
                            });
                        }
                    };

                    scope.delete = function(){
                        var modalOptions = {
                            closeButtonText: $translate.instant('COMMON_CANCEL'),
                            actionButtonText: $translate.instant('COMMON_DELETE'),
                            headerText: scope.kpi.title,
                            bodyText: $translate.instant('ADMIN_MESSAGE_DELETE_ROW_CONFIRMATION')   
                        };
        
                        modalService.showModal({}, modalOptions).then(function (result) {
                            strategyService().deleteKPI(scope.kpi.id).then(function () {
                            });
                        });
                        
                    };

                    scope.setDirectionDown = function () {
                        scope.kpi.operator = '<=';
                    };
                    scope.setDirectionUp = function () {
                        scope.kpi.operator = '>=';
                    };

                }
            }
        }]);