﻿import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
angular
    .module('DigiLean')
    .directive("cost", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'cost.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'model': '='
                },
                link: function (scope, elem, attrs) {

                    scope.currency = customerService.getDefaultCurrency()
                    getCompanySetting("currency").then(function (setting) {
                        if (setting) {
                            scope.currency = setting
                        }
                    })
                }
            }
        }]);
