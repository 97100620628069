var DigiLean = angular.module('DigiLean');
DigiLean.controller('myprojectsController', ['$scope', '$state', '$element', '$filter', '$uibModal', '$translate', 'projectService', 'navigationService',
    function ($scope, $state, $element, $filter, $uibModal, $translate, projectService, navigationService) {

        $scope.hasProjectModule = navigationService().hasModule("PROJECT");
        $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
            var hasAccess = $filter('filter')(profile.modules, { name: "PROJECT" });
            $scope.hasProjectModule = hasAccess.length > 0;
        });

        $scope.projects = [];
        $scope.items = [];

        $scope.isLoading = true;
        projectService().getMyProjectsInfo().then(function (projects) {
            $scope.projects = projects;
            for (var i = 0; i <  $scope.projects.length; i++) {
                checkMilestones($scope.projects[i]);
            }
            $scope.isLoading = false;
        });
        /*
        $scope.subscribe("A3sDeleted", function (ids) {
            // remove a3 from list
            var a3ToRemove = $filter('filter')($scope.a3s, idInList('id', ids));
            if (a3ToRemove.length > 0) {
                angular.forEach(a3ToRemove, function (a3) {
                    var index = $scope.a3s.indexOf(a3);
                    $scope.a3s.splice(index, 1);
                });
                $scope.$apply();
            }
        });*/


        function checkMilestones(project) {
            if (project.milestones && project.milestones.length > 0) {
                project.milestoneMissed = false;
                for (var i = 0; i < project.milestones.length; i++) {
                    if (project.milestones[i].actualDate) {
                        project.milestones[i].status = "completed";
                    } else if (project.milestones[i].plannedDate) {
                        //check if overdue/missed
                        var today = moment().startOf('day');
                        var target = moment(project.milestones[i].plannedDate).startOf('day');;
                        var days = today.diff(target, 'days'); 
                        if (days >= 1) {
                            project.milestones[i].status = "missed";
                            project.milestoneMissed = true;
                        } else {
                            project.milestones[i].status = "future";
                        }
                        addNextMilestone(project, project.milestones[i], days);
                    }
                }
            }
        }

        function addNextMilestone(project, milestone, days){
            
            if(project.nextMilestone){
                if(moment(project.nextMilestone.milestone.plannedDate).isAfter(moment(milestone.plannedDate))) {
                    project.nextMilestone = {
                        milestone : milestone,
                        days : Math.abs(days)
                    }
                } 
            }   else {
                project.nextMilestone = {
                    milestone : milestone,
                    days : Math.abs(days)
                }        
            }   
        } 


    }]);