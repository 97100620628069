angular
    .module('DigiLean')
    .directive("projectDescriptionCell", ['$filter', '$translate', 'projectService',
        function ($filter, $translate, projectService) {
            return {
                templateUrl: 'projectDescriptionCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                },
                link: function (scope, elem, attrs) {
                   
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                   // scope.postIts = projectService().getPostIts();
                   
                    scope.description = "";
                    
                    /*scope.postIts = projectService().getPostIts();
                    scope.usePredefinedLabels = false;
                    scope.labels = [];*/

                    scope.$watch('model', function (model) {
                        if(scope.model){
                            scope.description =  model.project.description;
                            model.getSortValue = function () {
                                return scope.description;
                            }
                        }
                        
                    });

                    scope.setActive= function(){
                        scope.active = true;
                    }

                    scope.subscribe("ProjectDescriptionUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            if (project.description) {
                                scope.description = project.description;
                            } else {
                                scope.description = "";
                            }
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            //document.querySelector( "input" ).focus();
                            $(".layouttextarea").focus();
                        }, 50 );
                    }

                    scope.updateProjectDescription = function(description) {
                        projectService().updateProjectDescription(scope.model.project.id, description).then(function (result) {
                        });
                    };
                    

                }
            }
        }]);
