angular
    .module('DigiLean')
    .directive("iLetter", ['kpiLetterBaseDirective',
        function (kpiLetterBaseDirective) {
            var iLetterDirective = {
                name: 'iLetter',
                templateUrl: 'iLetter.html',
                restrict: 'EA',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                }
            };

            iLetterDirective.compile = function (element, attrs) {
                return {
                    post: function (scope, element, attrs) {
                        kpiLetterBaseDirective[0].link(scope, element, attrs);
                        scope.visualizationType = "I";
                   
                    }
                };
            };

            return iLetterDirective;
        }]);
