angular.module('DigiLean').factory('monthlyRenderService', ['$filter',
    function ($filter) {
        function monthlyRenderService() {

            return {
                addTaskToRow: function (task, row, timeOptions) {
                    // Make sure task has rowIdentifcator
                    task.rowCategoryId = row.id;
                    task.rowIndex = row.index;
                    // If no current tasks, than create
                    if (row.taskRows.length == 0) {
                        var newRow = this.createTaskRow(task, timeOptions);
                        row.taskRows.push(newRow);
                        return;
                    }
                    // else we will try to fit in first available row.
                    for (let index = 0; index < row.taskRows.length; index++) {
                        const taskRow = row.taskRows[index];
                        var taskAdded = this.addTaskToRowIfSpace(task, taskRow, timeOptions)
                        if (taskAdded) return;
                    }
                    // If not placed in existing row, we need to add a new one
                    var newRow = this.createTaskRow(task, timeOptions);
                    row.taskRows.push(newRow);
                    return;
                },

                moveTask: function (task, targetOptions, timeOptions) {
                    var targetWeek = targetOptions.targetWeek;
                    // Special rule for week 1 when in december, to add correct week
                    if (targetOptions.targetMonth == 12 && targetWeek == 1) {
                        // find index of week 1.
                        targetWeek = timeOptions.lastWeek.indexNumber;
                    } 
                    var diff = task.startWeek - targetWeek;
                    if (diff > 0) {
                        task.startTime = moment(task.startTime).subtract(diff, "weeks");
                        task.endTime = moment(task.endTime).subtract(diff, "weeks");
                    } else {
                        diff = diff * -1;
                        task.startTime = moment(task.startTime).add(diff, "weeks");
                        task.endTime = moment(task.endTime).add(diff, "weeks");
                    }
                    this.calculateDuration(task, timeOptions);
                },

                reCalculateRow: function (data, targetRowId, timeOptions) {
                    var targetRow = $filter('filter')(data.rows, { id: targetRowId }, true)[0];
                    targetRow.taskRows.splice(0, targetRow.taskRows.length);
                    var tasks = $filter('filter')(data.tasks, { rowCategoryId: targetRowId }, true);
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        this.addTaskToRow(task, targetRow, timeOptions);
                    }

                },

                reCalculateRowByIndex: function(data, targetRowIndex, timeOptions) {
                    var targetRow =  $filter('filter')(data.rows, { index: targetRowIndex }, true)[0];
                    targetRow.taskRows.splice(0, targetRow.taskRows.length);
                    var tasks = $filter('filter')(data.tasks, { rowIndex: targetRowIndex }, true);
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        this.addTaskToRow(task, targetRow, timeOptions);
                    }
                    
                },

                addTaskToRowIfSpace(task, taskRow, timeOptions) {
                    // First check if all cells needed are available
                    for (let index = task.startWeek; index <= task.endWeek; index++) {
                        var startCell = $filter('filter')(taskRow.cells, { id: index }, true);
                        // if cell doesn't exists (already part of another colspan or already have task)
                        if (startCell.length == 0 || startCell[0].task) return false;
                    }
                    // Add task to cell and set colspan
                    var startCell = $filter('filter')(taskRow.cells, { id: task.startWeek }, true)[0];
                    startCell.colspan = task.durationInWeeksCurrentMonth;
                    startCell.task = task;
                    // Now let's removed cell affected by colspan
                    if (task.durationInWeeksCurrentMonth > 1) {
                        // Use start month(not -1) because 0 index array.
                        var index = taskRow.cells.indexOf(startCell);
                        taskRow.cells.splice(index + 1, task.durationInWeeksCurrentMonth - 1);
                    }
                    return true;
                },

                createTaskRow: function (task, timeOptions) {
                    var newRow = {
                        cells: []
                    }
                    for (let index = timeOptions.firstWeek.indexNumber; index < task.startWeek; index++) {
                        var cell = {
                            id: index,
                            colspan: 1
                        }
                        newRow.cells.push(cell)
                    }
                    var taskCell = {
                        id: task.startWeek,
                        colspan: task.durationInWeeksCurrentMonth,
                        task: task
                    }
                    newRow.cells.push(taskCell);
                    for (let index = (task.startWeek + task.durationInWeeksCurrentMonth); index <= timeOptions.lastWeek.indexNumber; index++) {
                        var cell = {
                            id: index,
                            colspan: 1
                        }
                        newRow.cells.push(cell)
                    }
                    return newRow;
                },
                calculateDuration: function (task, timeOptions) {
                    var firstWeek = timeOptions.month.weeks[0];
                    var lastweek = timeOptions.month.weeks[timeOptions.month.weeks.length - 1];
                    task.startTime = moment(task.startTime).toDate();
                    task.endTime = moment(task.endTime).toDate();
                    if (task.startTime && task.endTime) {
                        var startWeek = moment(task.startTime).isoWeek(); // Javascript months are 0 indexed
                        
                        var endWeek = moment(task.endTime).isoWeek();
                        task.isStartOutsideCurrentPeriod = false;
                        task.isEndOutsideCurrentPeriod = false;

                        // If
                        if (endWeek < startWeek || task.endTime > lastweek.endDate) {
                            // if task duration end within a greater month, we need to set it to this year
                            endWeek = lastweek.indexNumber;
                            task.isEndOutsideCurrentPeriod = true;
                        }
                        if (startWeek > endWeek || task.startTime < firstWeek.startDate) {
                            // if task duration end within a greater month, we need to set it to this year
                            startWeek = firstWeek.indexNumber;
                            if (firstWeek.indexNumber > firstWeek.number) {
                                startWeek = firstWeek.number;
                            }
                            task.isStartOutsideCurrentPeriod = true;
                        }
                        // How many weeks within current year
                        task.startWeek = startWeek;
                        task.endWeek = endWeek;
                        // December rule, 1 week next year
                        if (timeOptions.month.id == 12 && task.startWeek == 1) {
                            task.startWeek = lastweek.indexNumber;
                            task.endWeek = lastweek.indexNumber;
                        }
                        if (timeOptions.month.id == 1 && task.startWeek > 50) {
                            task.startWeek = firstWeek.indexNumber;
                        }
                        if (timeOptions.month.id == 1 && task.endWeek > 50) {
                            task.endWeek = endWeek.indexNumber;
                        }
                        task.durationInWeeksCurrentMonth = (endWeek - startWeek) + 1; // we need to add one
                    }
                }

            };
        }
        return monthlyRenderService;
    }]);

