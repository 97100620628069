var DigiLean = angular.module('DigiLean');  
DigiLean.controller('projectRoleAdminController', ['$scope', '$filter', '$stateParams', '$location', '$timeout', '$translate', 'authService', 'projectService','modalService',
    function ($scope, $filter, $stateParams, $location, $timeout, $translate, authService, projectService, modalService) {
        $scope.savedSuccessfully = false;
        $scope.message = "";

        $translate('PROJECT_NEW_ROLE').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });


        $scope.clearActive = function () {
            $translate('PROJECT_NEW_ROLE').then(function (title) {
                $scope.actionTitle = title;
            }, function (translationId) {
                $scope.headline = translationId;
            });
            $scope.registration = {
                isNew: true,
                id: 0,
                name: ""
            };
        }
        $scope.clearActive();

        $scope.delete = function (item) {
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + item.name + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_CONFIRMATION 
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    projectService().deleteProjectRole(item.id).then(function (data) {  
                        load();
                        $scope.clearActive();
                        $translate('ADMIN_MESSAGE_ROLE_DELETED').then(function (msg) {
                            toastr.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    },function (err) {
                    });
                });
            });
        }

        $scope.customers = [];
        var load = function () {
            projectService().getProjectCustomers().then(function (customers) {
                $scope.customers = customers;
            });    
        }
        load();
        $scope.edit = function (item) {
            $translate('COMMON_EDIT').then(function (title) {
                $scope.actionTitle = title;
            }, function (translationId) {
                $scope.headline = translationId;
            });
            $scope.registration = {
                isNew: false,
                id: item.id,
                name: item.name
            };
        }


        $scope.createNew = function(name){
            var role = {
                projectId: $scope.projectId,
                name: customername
            };

            projectService().createNewCustomer(customer).then(function (newRole) {
                //$scope.updateCustomer(newcustomer);
            });
        };
        /*
            $scope.updateCustomer = function(customer){
                projectService().updateCustomer($scope.projectId, customer.id).then(function (registeredcustomer) {
                });   
            };
        */

        $scope.save = function () {
            if ($scope.registration.isNew) {
                projectService().createNewRole($scope.registration).then(function (newrole) {
                    $translate('COMMON_CREATED').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    load();
                    $scope.clearActive();
                });
            } else {
                projectService().updateRole($scope.registration).then(function () {
                    $translate('ADMIN_MESSAGE_ROLE_UPDATED').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    load();
                });
            }
        }

    }]);