angular
    .module('DigiLean')
    .directive("numberCellAggregate", ['$filter', 'mathService',
        function ($filter, mathService) {
            return {
                templateUrl: 'numberCellAggregate.html',
                restrict: 'E',
                scope: {
                    'model': '='
                },
                link: function (scope, elem, attrs) {
                    var projectAttributeId = null;
                    scope.numberCellAggregateValue = null;
                    scope.aggregateType = "SUM";
                    var valuePerProject = [];
                    scope.decimals = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";

                    scope.$watch('model', function (model) {
                        projectAttributeId = model.id;
                        if (model && model.settings) {
                            var settings = model.settings;
                            if(settings.aggregateType){
                                scope.aggregateType = settings.aggregateType;
                            }
                            if(settings.dataSource && settings.dataSource.valueElement.unit == "%"){ //procent has to be calculated as average value
                                scope.aggregateType = "AVERAGE";
                            }
                            if(settings.unit){
                                scope.unit = settings.unit;
                                if(settings.unitPlacement){
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                            if(settings.decimals !== null || settings.decimals !== undefined){
                                scope.decimals = settings.decimals;
                            }
                        }
                        model.getSortValue = function () {
                            return scope.numberCellAggregateValue;
                        }

                    });

                    scope.subscribe("ProjectNumberCellUpdated", function (value) {
                        if(projectAttributeId === value.projectAttributeId){
                            updateValue(value);
                        }
                    });

                        
                    scope.subscribe("ProjectNumberCellUnitUpdated", function (value) {
                        if(projectAttributeId === value.projectAttributeId){
                            scope.unit = value.unit;
                        }
                    });
                    scope.subscribe("ProjectNumberCellUnitPlacementUpdated", function (value) {
                        if(projectAttributeId === value.projectAttributeId){
                            scope.unitPlacement = value.unitPlacement;
                        }
                    });
                    scope.subscribe("ProjectNumberCellDecimalUpdated", function (value) {
                        if(projectAttributeId === value.projectAttributeId){
                            scope.decimals = value.decimals;
                            calculateAggregateValue();
                        }
                    });
                    scope.subscribe("ProjectNumberCellAggregateTypeUpdated", function (value) {
                        if(projectAttributeId === value.projectAttributeId){
                            scope.aggregateType = value.aggregateType;
                            calculateAggregateValue();
                        }
                    });

                    scope.subscribe("ProjectDeleted", function (payLoad) {
                        var existingValueForProject = $filter('filter')(valuePerProject, { projectId: payLoad.id });

                        if (existingValueForProject && existingValueForProject.length > 0) {
                            var index = valuePerProject.indexOf(existingSumForProject);
                            valuePerProject.splice(index, 1);
                            calculateAggregateValue();
                        }
                    });


                    function updateValue(updatedValue) {

                        var existingValueForProject = $filter('filter')(valuePerProject, { projectId: updatedValue.projectId });

                        if (existingValueForProject && existingValueForProject.length > 0) {
                            var index = valuePerProject.indexOf(existingValueForProject[0]);
                            valuePerProject[index] = updatedValue;
                        } else {
                            valuePerProject.push(updatedValue);
                        }
                        calculateAggregateValue();
                    }

                    function calculateAggregateValue() {
                        scope.numberCellAggregateValue = 0;
                        var noOfProjectCellWithValue = 0;
                        for (var i = 0; i < valuePerProject.length; i++) {
                            if (valuePerProject[i].value) {
                                scope.numberCellAggregateValue = scope.numberCellAggregateValue + valuePerProject[i].value;
                                noOfProjectCellWithValue = noOfProjectCellWithValue + 1;
                            }
                        }
                        if (scope.aggregateType === "AVERAGE" && noOfProjectCellWithValue > 0) {
                            scope.numberCellAggregateValue = scope.numberCellAggregateValue / noOfProjectCellWithValue;
                        }

                        if(scope.decimals !== null) {
                            if(scope.decimals !== undefined) {
                                scope.numberCellAggregateValue = mathService().roundNumber(scope.numberCellAggregateValue, scope.decimals);
                            }
                        }
                    }
                }
            }
        }]);
