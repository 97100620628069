angular
    .module('DigiLean')
    .controller("goalEditPopup", ['$scope', '$uibModalInstance', 'strategyGoalService', 'goalId',
        function ($scope, $uibModalInstance, strategyGoalService, goalId) {
            $scope.goalId = goalId;
            $scope.isValid = false;
            $scope.goalEditChangedHandler = function (goalEdit) {
                $scope.isValid = false;
                $scope.goalEdit = goalEdit;
                if (!goalEdit) return;
                if (!goalEdit.color) return;
                $scope.color = goalEdit.color;
                if (!goalEdit.name) return;
                $scope.isValid = true;
            }

            strategyGoalService().getGoal(goalId).then(function (goal) {
                $scope.goal = goal;
            });

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function () {
                var changesToSave = [];
                if ($scope.goal.name != $scope.goalEdit.name) {
                    changesToSave.push(strategyGoalService().updateName(goalId, $scope.goalEdit.name));
                }
                if ($scope.goal.color != $scope.goalEdit.color) {
                    changesToSave.push(strategyGoalService().updateColor(goalId, $scope.goalEdit.color));
                }
                if ($scope.goal.assetId != $scope.goalEdit.assetId) {
                    changesToSave.push(strategyGoalService().updateAsset(goalId, $scope.goalEdit.assetId));
                }
                Promise.all(changesToSave).then((values) => {
                    $uibModalInstance.close();
                });
            };
        }]);
