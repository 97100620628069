﻿var DigiLean = angular.module('DigiLean');

DigiLean.directive("latestDataValue", ['dataService',
    function (dataService) {
        return {
            templateUrl: 'latestDataValue.html',
            restrict: 'E',
            scope: {
                'datasourceId': '<',
            },
            link: function (scope, elem, attrs) {
                scope.value = null;
                scope.$watch('datasourceId', function (datasourceId) {
                    if (!datasourceId) return;
                    var options = {
                        numberOfValues : 1,
                        sortOrder : "DESC"
                    }
                    dataService().getLatestValues(datasourceId, options).then(function(value){
                        if (value.length == 1) {
                            scope.value = value[0];
                        }
                        
                    });
                });
            }
        }
    }]);
