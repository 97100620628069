import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .controller("deviationListModalController", ['$scope', '$translate','$filter', '$uibModalInstance', '$uibModal', 'deviationService', 'authService', 'options',
        function ($scope, $translate, $filter, $uibModalInstance, $uibModal, deviationService, authService, options) {

            $scope.options = options;
            $scope.deviations = [];
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("DeviationAdmin");
            
            if(options && options.timePeriod){
                deviationService().getDeviationsForPeriod($scope.options).then(function (deviations) {
                    $scope.deviations = deviations;  
                    setDateStyleToDeviations($scope.deviations);
                    assetService.getAllAssets().then(function (assets) {
                        $scope.assets = assets;
    
                        for (var i = 0; i < $scope.deviations.length; i++) {
                            
                            var a = $filter('filter')($scope.assets, { id: $scope.deviations[i].followUpAssetId }, true);
                            if (a && a.length > 0) {
                                $scope.deviations[i].followUpGroup = a[0].name;
                            }
                        }
                    
                    });   
                });
            } else {
                deviationService().getDeviationsByFilter($scope.options).then(function (deviations) {
                    $scope.deviations = deviations;  
                    setDateStyleToDeviations($scope.deviations);
                    assetService.getList().then(function (assets) {
                        $scope.assets = assets;

                        for (var i = 0; i < $scope.deviations.length; i++) {
                            
                            var a = $filter('filter')($scope.assets, { id: $scope.deviations[i].followUpAssetId }, true);
                            if (a && a.length > 0) {
                                $scope.deviations[i].followUpGroup = a[0].name;
                            }
                        }
                    
                    });   
                });
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            $scope.openDeviation = function (deviation) {
               // if($scope.isAdmin){
                deviationService().get(deviation.id).then(function (deviation) {
    
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'fullDeviation.html',
                        controller: 'fullDeviationController',
                        windowClass: 'full-screen',
                        resolve: {
                            deviation: function () {
                                return deviation;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                },
                function (response) {
                    if (response.status === 404){
                        swal({
                            title: $translate.instant("COMMON_NO_ACCESS"),
                            text: $translate.instant("COMMON_REQUIRED_GROUP_MEMBERSHIP"),
                            icon: "warning",
                            buttons: [$translate.instant("COMMON_OK")],
                            dangerMode: true,
                            closeOnConfirm: true,
                            closeOnClickOutside: true,
                            closeOnEsc: true
                        });
                    }
                });/*
                } else {
                    swal({
                        title: $translate.instant("COMMON_NO_ACCESS"),
                        text: $translate.instant("COMMON_REQUIRED_GROUP_MEMBERSHIP"),
                        icon: "warning",
                        buttons: [$translate.instant("COMMON_OK")],
                        dangerMode: true,
                        closeOnConfirm: true,
                        closeOnClickOutside: true,
                        closeOnEsc: true
                    });
                }*/
            }

            function setDateStyleToDeviations(items){
                items.forEach(function (item) {
                    if(item.status < 20 ){
                        item.dateStyleClass = getDateStyle(item.dueDate);
                    }
                });
            }

            function getDateStyle(date) {
                if (!date) return "";
                // Overdue check

                var overdue = "";
                var duration = moment.duration(moment().diff(moment(date)));
                var days = duration.asDays();
                if (days > 1) {
                    overdue = "overdue";
                }
                return overdue;
            }
        }]);