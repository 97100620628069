import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import { getUserProfile } from "@common/stores/userStore"

angular
.module("DigiLean")
.directive('smileyApp', ['$translate', 'debounce',
    function ($translate, debounce) {
        return {
            templateUrl: 'smileyApp.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '<',
                'settings': '<',
                'settingsChangeHandler': '&',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                
                scope.theme = getDefaultAppTheme()

                scope.selectedSmiley = {
                    value: 'smile',
                    date: null,
                    comment: "",
                    lastmodifiedBy: ""
                }
                scope.comment = "";

                scope.title = $translate.instant("COMMON_TITLE");
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }

                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                scope.appStyle = getDefaultAppStyle()
                scope.smileyStyle = {
                    "font-size": "3.2rem"
                }
                function calcStyles() {
                    let fontSmiley = "3.2rem"
                    if(scope.layoutFactor)
                        fontSmiley = 3.2 * scope.layoutFactor + "rem"
                    
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    scope.smileyStyle["font-size"] = fontSmiley
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                scope.$watch('settings', function (settings) {
                    scope.settings = settings;
                    if (settings) {
                        scope.title = settings.title;
                        scope.style = settings.style;
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        if (settings.smiley) {
                            scope.selectedSmiley = settings.smiley;
                            scope.comment = scope.selectedSmiley.comment;
                        }
                    }
                });

                scope.changeSmiley = function (smiley) {
                    scope.selectedSmiley.value = smiley;
                    scope.updateSettings();
                }
                scope.updateComment = function (comment) {
                    scope.selectedSmiley.comment = comment;
                    scope.selectedSmiley.commentDate = moment().toDate();
                    scope.commitChangesDebounced();
                }
                scope.clearComment = function () {
                    scope.selectedSmiley.comment = "";
                    scope.comment = "";
                    scope.commitChangesDebounced();
                }
                scope.updateSettings = function () {
                    const currentUser = getUserProfile()
                    scope.selectedSmiley.lastmodifiedBy = currentUser.fullName;

                    var componentSettings = {
                        title: scope.title,
                        style: scope.style,
                        smiley: scope.selectedSmiley,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                
                }
                scope.commitChangesDebounced = debounce(scope.updateSettings, 2000, false);
                scope.$on("widget-resized", function (event, args) {
                    var host = $(elem).closest("widget");
                    if (host.length == 0)
                        host = $(elem).closest(".modal-body")
                    
                    if (host.length == 0) return;
                    var width = host.width();
                    var height = host.height();
                    // Find header to subtract from new height
                    var header = $(elem).find(".ibox-title");
                    var headerHeight = header.height() + 40;
                    var container = $(elem).find(".daycounter");
                    if (container.length === 0) return;
                    $(container).css("height", (height - headerHeight));
                });

            }
        }
    }]);

