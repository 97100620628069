angular
    .module('DigiLean')
    .directive("targetFromAppCell", ['qualityService','$translate',
        function (qualityService, $translate) {
            return {
                templateUrl: 'targetFromAppCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.value = null;
                    scope.projectId = null;
                    var visualizationType = null;

                    scope.$watch('model', function (model) {
                        scope.projectId = model.project.id;
                        if (model.column.settings) {
                            if(model.column.settings.visualizationType){
                                visualizationType = model.column.settings.visualizationType;
                                scope.updateValue(visualizationType);
                            }
                        } 
                        model.getSortValue = function () {
                            return scope.value;
                        }
                    });

                    scope.subscribe("DateStatusChanged", function (status) {
                        if(status.visualizationType == visualizationType){ 
                            if (scope.projectId && status.projectId === scope.projectId) {
                                scope.updateValue(visualizationType); 
                            }
                        }
                    });

                    scope.updateValue = function (visualizationType) {
                        if (!visualizationType) return;
                        qualityService().getLatestProjectStatusValue(visualizationType, scope.projectId).then(function (result) {
                            if(result){
                                scope.value = result.status;
                                scope.registerDate = result.registrationDate;
                               // scope.tooltip = $translate.instant('COMMON_REGISTER_DATE') + ": " + result.registrationDate;
                            }
                        });
                    };
                }
            }
        }]);
