angular
    .module('DigiLean')
    .directive("subTasksCell", ['$filter', '$translate', '$uibModal', 'boardTaskService',
        function ($filter, $translate, $uibModal, boardTaskService) {
            return {
                templateUrl: 'subTasksCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.participants = [];
                    scope.subTaskCount = 0;
                    scope.subTaskCompletedCount = 0;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.task) {
                            if (scope.model.task) {
                                scope.subTaskCount = scope.model.task.subTaskCount;
                                scope.subTaskCompletedCount = scope.model.task.subTaskCompletedCount;
                            }
                        }
                        scope.model.getSortValue = function() {
                            return scope.subTaskCount;
                        }
                    });

                    scope.subscribe("SubTaskCountChanged", function(count) {
                        if (count.taskId == scope.model.task.id || count.taskId == scope.model.task.siblingId) {
                            scope.subTaskCount = count.subTaskCount;
                            scope.subTaskCompletedCount = count.subTaskCompletedCount;
                        }
                    });
                
                    
                    scope.openTask = function () {
                        boardTaskService().get(scope.model.task.id).then(function (task) {
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return "SubTasks";
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    };
                }
            }
        }]);