angular
    .module('DigiLean')
    .directive("projectDateCell", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'projectDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    var defaultDateColor = "#0099DE";
                    scope.color = defaultDateColor;
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    scope.value = "";
                    

                    scope.updateDate = function($event){
                        const date = $event.originalEvent.detail
                        scope.value = date
                        scope.saveChanges()
                    }
                    

                    scope.$watch("model", function (model) {
                        var value = model.projectAttribute.value;
                        if (value) {
                            setValue(value);
                        } 
                        model.getSortValue = function() {
                            if (scope.value) {
                                return scope.value.value;
                            }
                            return null;
                        }
                    });

                    var setValue = function(value) {
                        scope.background = value.background;
                        if(value.value){
                            scope.value = moment(value.value).toDate();
                        } else {
                            scope.value = "";
                        }
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  defaultDateColor;
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.saveChanges();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = {title:"custom", background: color};
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.saveChanges();
                    }

                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            value: scope.value
                        }
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                    }
                }
            }
        }]);
