
angular.module('DigiLean').factory('learningPublicService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {

        const resource = "/LearningPublic";
        const resourceUrl = webApiUrl + resource

        return {
            getUserHelpLearningMaterial(languageCode) {
                if (languageCode != "en" && languageCode != "no") {
                    languageCode = "en"
                }
                const url = `${resourceUrl}/gethelp/${languageCode}`
                return $http.get(url).then(function (result) {
                    var material = result.data;
                    if (material) {
                        material.link = "/#/learning/external/" + material.id + "?pageid=0"
                    }
                    return result.data
                });
            },
            getHelpPage(languageCode, featureKey) {
                const url = `${resourceUrl}/gethelpPage?languageCode=${languageCode}&featureKey=${featureKey}`
                return $http.get(url).then(function (result) {
                    return result.data
                });
            },
            getPublishedMaterials: function () {
                const url = resourceUrl + "/getPublishedMaterials";
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            getItems: function (isExternal, materialId) {
                isExternal = !!isExternal
                const url = `${resourceUrl}/getItems/${materialId}?isExternal=${isExternal}`
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            getPage: function (isExternal, pageId) {
                isExternal = !!isExternal
                const url = `${resourceUrl}/getPage/${pageId}?isExternal=${isExternal}`
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            getPageComments: function (materialId, pageId) {
                const url = `${resourceUrl}/${materialId}/getPageComments/${pageId}`
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            addPageComment: function (materialId, pageId, comment) {
                const url = `${resourceUrl}/${materialId}/addPageComment/${pageId}`
                return $http.post(url, comment).then(function (result) {
                    return result.data
                })
            },
            deletePageComment: function (materialId, commentid) {
                const url = `${resourceUrl}/${materialId}/deletePageComment/${commentid}`
                return $http.delete(url).then(function (result) {
                    return result.data
                })
            },
            addPageVisit: function (materialId, pageId) {
                const url = `${resourceUrl}/${materialId}/addPageVisit/${pageId}`
                return $http.post(url).then(function (result) {
                    return result.data
                })
            },
        }
    }])
