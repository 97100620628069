import {LitElement, html, css} from "lit"
import {customElement, state} from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"

@customElement('digilean-checked')
export class DigiLeanChecked extends LitElement {

    @state()
    open = false
    
    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-block;
            user-select: none;
        }
        #outer-box {
            display: inline-flex;
            box-sizing: border-box;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            vertical-align: middle;
        }
        #outer-box.open {
            background: transparent; //transparent
        }
        svg {
            position: absolute;
            z-index: -1;
            height: calc(var(--digilean-icon-height, 2rem) * 0.6);
            width: calc(var(--digilean-icon-width, 2rem) - 0.6);
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition-delay: 0.5s;
            transition: all 1s ease;
            transform: translate3d(0, 0, 0);
        }
        svg#check {
            stroke: var(--digilean-white);
            transform: scale(0.2);
        }
        #outer-box.open svg#check {
            transform: scale(1);
            stroke-dashoffset: 0;
            z-index: 1;
        }

        svg#circle-check {
            transform: scale(0);
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            /* transition: fill 250ms cubic-bezier(.4,.0,.23,1); */
        }
        #outer-box.open svg#circle-check {
            z-index: 1;
            transform: scale(1);
            fill: var(--digilean-button-success);
        }

        svg#bubble-check {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            //z-index: -21;
            transform: scale(0);
            opacity: 1;
            transition: all 1s ease-out;
        }
        #outer-box.open svg#bubble-check {
            fill: var(--digilean-button-success-border);
            z-index: 1;
            transform: scale(3.2);
            opacity: 0;
            visibility: hidden;
        }
    `
    connectedCallback(): void {
        super.connectedCallback()
        setTimeout(() => {
            this.open = true
        }, 100)
        
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
        this.open = false
    }
    render() {
        const classes = {
            open: this.open
        }
        return html`
            <div id="outer-box" class=${classMap(classes)}>
                <svg id="bubble-check" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="circle-check" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="check" viewbox="0 0 13 13">
                    <polyline points="2 6 5 10 11 3"></polyline>
                </svg>
            </div>
        `
    }
}
