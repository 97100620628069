import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { translateLabel, selectedLang } from "@common/services/language/DigiLeanLang"
import { Subscription } from "rxjs"

@customElement('translate-label')
export class TranslateLabel extends LitElement {
    static styles = css`
        :host {
            display: inline;
        }
    `
    private sub: Subscription | null = null
    connectedCallback(): void {
        super.connectedCallback()
        this.sub = selectedLang.subscribe((sl => {
            if (sl.ready)
                this.getTranslation()
        }))
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
        if (this.sub) this.sub.unsubscribe()
    }

    _label = ""
    @property({attribute: true})
    set label(value: string) {
        this._label = value
        if (value)
            this.getTranslation()
    }
    get label() {
        return this._label
    }

    async getTranslation() {
        this.translation = await translateLabel(this.label)
    }
    @state()
    translation = ""

    render() {
        return html`${this.translation}`
    }
}