angular.module('DigiLean').factory('boardRowProvider', ['$filter',
    function ($filter) {
        function boardRowProvider() {
            var rows = [];
            return {
                createCategories: function (model) {
                    return [];
                },
                createRows: function(model, selectedCategory) {
                    var rowIndex = 0;
                    for (let index = 0; index < model.rows.length; index++) {
                        const row = model.rows[index];
                        row.index = rowIndex;
                        row.type ="boardcategory";
                        row.taskRows = [];
                        rowIndex += 1;
                    }
                    return model.rows;
                },
            };
        }
        return boardRowProvider;
    }]);

