import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("boardTaskSelectorDropdown", ['$filter', 'boardTaskService',
        function ($filter, boardTaskService) {
            return {
                templateUrl: 'boardTaskSelectorDropdown.html',
                restrict: 'E',
                scope: {
                    'task': '=',
                    'boards': '=',
                    'options': '='
                    
                    /*'userChangeHandler': '&',*/
                },
                link: function (scope, elem, attrs) {

                    //scope.boards = [];
                    scope.boardSelectedIsMandatory = false;

                    scope.$watch("task", function () {
                        setBoard();
                    });

                    scope.$watch("boards", function () {
                        setBoard();      
                    });

                    scope.$watch("options", function () {
                        if(scope.options){
                            if(scope.options.mandatory){
                                scope.boardSelectedIsMandatory = true;
                            }
                        }      
                    });

                    function setBoard() {
                        if (scope.task && scope.task.boardId && scope.boards) {
                            const match = scope.boards.find(t => t.id == scope.task.boardId)
                            if (match) {
                                scope.task.board = match
                            }
                        }
                    }
                    scope.clearBoard = function (task) {

                        var change = {
                            taskId: task.id,
                            fromBoardId: task.boardId === null ? 0 : task.boardId,
                            toBoardId: 0
                        };
                        task.boardId = null;
                        task.board = null;

                        task.isActionItem = false;
                        task.rowCategoryId = null;
                        task.columnCategoryId = null;

                        boardTaskService().updateBoard(task.id, change).then(function (result) {
                            scope.publish("SendTaskChangedBoard", change);
                        });
                    }

                    scope.selectBoard = function (task, board, $event) {
                        if(!(board.boardType == 'Yearly' && task.boardId == board.id)){
                            task.newBoardSelected = true;
                            scope.columns = null;
                            scope.selectedColumn = null;
                            scope.rows = null;
                            scope.selectedRow = null;
                        }

                        if(!(board && board.boardType == 'SmartActionList')){
                            $event.stopPropagation();
                        } else {
                            task.newBoardSelected = false;
                        }

                        if (task.boardId !== board.id) {

                            
                            var change = {
                                taskId: task.id,
                                fromBoardId: task.boardId === null ? 0 : task.boardId,
                                toBoardId: board.id === null ? 0 : board.id
                            };


                            task.boardId = board.id;
                            setBoard(task);
                            if(board.boardType == 'Yearly'){
                                placeTaskInYearlyBoard(task);
                                task.showColumnRow = true;
                            }
                            else {
                                if (board.id) {
                                boardTaskService().updateBoard(task.id, change).then(function (result) {
                                    scope.publish("SendTaskChangedBoard", change);
                                    
                                });
                                } else {
                                    boardTaskService().moveTaskToPersonalBoard(task.id).then(function (result) {
                                        scope.publish("SendTaskChangedBoard", change);
                                    });
                                }
                            }
                        }

                    }

                    function placeTaskInYearlyBoard(task){
                        boardService.getBoardCategories(task.boardId).then(function (data) {

                            angular.forEach(data.rows, function (row) {
                                if (row.responsibleUserId) {
                                    row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                                } else {
                                    row.displayName = row.name;
                                }
                            });
                            scope.columns = data.columns;
                            task.selectedColumn = data.columns[0];
                            scope.rows = data.rows;
                            task.selectedRow = data.rows[0];
                            //mapDateToColumn(task);
                            //mapDateToRow(task);
                            //mapResponsibleToColumn(task);
                            mapResponsibleToRow(task);
                            scope.moveTaskToRowColumn(task); //place task in first row as default placement since no action list is available for a yearly board
                        });
                    }
                    var mapDateToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.weekdayName && (row.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedRow = row;
                            }
                        });
                    }
                    var mapDateToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.weekdayName && (column.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.responsibleUserId == task.responsibleUserId) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.responsibleUserId == task.responsibleUserId) {
                                task.selectedRow = row;
                            }
                        });
                    }
                    scope.placeTaskInBoard = function (task) {

                        task.showColumnRow = true;
                        boardService.getBoardCategories(task.boardId).then(function (data) {

                            angular.forEach(data.columns, function (column) {
                                if (column.responsibleUserId) {
                                    column.displayName = column.name + " (" + column.responsibleDisplayName + ")";
                                } else {
                                    column.displayName = column.name;
                                }


                            });
                            angular.forEach(data.rows, function (row) {
                                if (row.responsibleUserId) {
                                    row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                                } else {
                                    row.displayName = row.name;
                                }
                            });
                            scope.columns = data.columns;
                            task.selectedColumn = data.columns[0];
                            scope.rows = data.rows;
                            task.selectedRow = data.rows[0];
                            mapDateToColumn(task);
                            mapDateToRow(task);
                            mapResponsibleToColumn(task);
                            mapResponsibleToRow(task);
                        });
                    }

                    scope.moveTaskToRowColumn = function (task) {
                        task.isActionItem = false;
                        var rowId = 0;
                        var columnId = 0;
                        if (task.selectedRow && task.selectedRow.id) {
                            rowId = task.selectedRow.id;
                        }
                        if (task.selectedColumn && task.selectedColumn.id) {
                            columnId = task.selectedColumn.id;
                        }
                        var actionItemToTask = {
                            id: task.id,
                            boardId: task.boardId,
                            boardDate: task.boardDate,
                            rowCategoryId: rowId,
                            columnCategoryId: columnId
                        };
                        boardTaskService().moveActionItemToTask(actionItemToTask).then(function (task) {
                            scope.publish("SendActionItemMovedToTask", task);
                            task.newBoardSelected = false;
                            task.showColumnRow = false;
                        });

                    }

                }
            }
        }]);