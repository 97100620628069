var DigiLean = angular.module('DigiLean');
DigiLean.controller('learningAdminController', ['$scope', '$filter', 'learningService','$uibModal','$state','$translate','modalService',
    function ($scope, $filter, learningService, $uibModal, $state, $translate, modalService) {
        var load = function() {
            learningService().getLearningMaterials().then(function(materials) {
                $scope.materials = materials;
            });
        }

        load();

        $scope.editSettings = function(material) {
            $state.go('learningdesigner', { id: material.id }, { reload: false });
        }

        $scope.edit = function (material) {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'editLearningMaterial.html',
                controller: 'editLearningMaterialController',
                resolve: {
                    materialId: function () {
                        return material.id;
                    }
                }
            });
            modalInstance.result.then(function (material) {
                var found = $filter('filter')($scope.materials, { id: material.id });
                    if (found.length == 1) {
                        var index = $scope.materials.indexOf(found[0]);
                        $scope.materials[index] = material;
                    }
            }, function () {
            });
        }

        $scope.addLearningMaterial = function () {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newLearningMaterial.html',
                controller: 'newLearningMaterialController'
            });
            modalInstance.result.then(function (material) {
                $scope.materials.push(material);
            }, function () {
            });
        }

        $scope.deleteMaterial = function (material) {
                var modalOptions = {
                    closeButtonText: $translate.instant("COMMON_CANCEL"),
                    actionButtonText: $translate.instant("COMMON_DELETE"),
                    headerText: $translate.instant("COMMON_DELETE")  + ' ' + material.title + '?',
                    bodyText: $translate.instant("ADMIN_MESSAGE_DELETE_LEARNINGMATERIAL_CONFIRMATION") 
                };
    
                modalService.showModal({}, modalOptions).then(function (result) {
                    learningService().deleteLearningMaterial(material.id).then(function (material) {  
                        load();
                    },function (err) {
                    });
                });
        }
    }]);