import { cloneDeep } from "lodash"
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs"

export class DlSubject<T> extends BehaviorSubject<T> {
    getClonedValue(){
        const value = super.getValue()
        return value
    }
}

// returns observable with cloned values
export const clonedObservable = (subject) => {
    return subject.pipe(map(data => cloneDeep(data)));
}