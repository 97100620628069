import CountUp from './../../../../../thirdparty/countUp/countUp.min';
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as timeService from "@common/services/timeService"

angular
    .module("DigiLean")
    .directive('dayCounter', ['$translate', '$uibModal','dataService',
        function ($translate, $uibModal,dataService) {
            return {
                templateUrl: 'daycounter.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        setTheme()
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    function setTheme() {
                        scope.appStyle["background-color"] = scope.theme.fillColor
                        scope.appStyle["color"] = scope.theme.background
                    }
                    calcStyles()
                    setTheme()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.title =  $translate.instant("COMMON_TITLE");
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    scope.filters = null;
                    var timePeriod = {
                        timeframe: "all",
                        period: timeService.getTimePeriod("all")
                    }
                    

                    scope.days = 0;
                    scope.viewModeSelection = "countdown";
                    scope.dateSourceSelection = "manual";
                    scope.editInViewMode = false;
                    
                    scope.targetDate = moment.utc().startOf('day').toDate();
                    scope.style = {
                        background: "transparent",
                        color: "inherit"
                    }
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            scope.title = settings.title;            
                            scope.viewModeSelection = settings.viewModeSelection;
                            scope.style = settings.style;
                            if (settings.theme) {
                                scope.theme = settings.theme;
                                setTheme()
                            }
                            if (settings.dataSource) {
                                scope.dataSource = settings.dataSource;
                                scope.dateSourceSelection = "datasource";
                                if (settings.filters) {
                                    scope.filters = settings.filters;
                                }
                                scope.updateValue(scope.dataSource.id);
                            } else { //manual input
                                scope.targetDate = moment(settings.targetDate).toDate();
                                if (scope.settings.editInViewMode) {
                                    scope.editInViewMode = scope.settings.editInViewMode;
                                }
                            }
                        }
                        else {
                            scope.viewModeSelection = "countdown";
                        }
                        calculateDays(scope.targetDate);
                    });

                    scope.$watch('viewModeSelection', function (selection) {
                        if(scope.dateSourceSelection == "datasource"){
                            scope.updateValue(scope.dataSource.id);
                        } else {
                            calculateDays(scope.targetDate);
                        }           
                        scope.updateSettings();
                    });

                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    scope.targetDateUpdated = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.targetDate = date
                        scope.dataSource = null;
                        scope.filters = null;
                        calculateDays(scope.targetDate, scope.isCountDown)
                        scope.updateSettings()
                    }

                    scope.toggleEditMode = function () {
                        calculateDays(scope.targetDate);
                        scope.updateSettings();
                    }

                    var valueElement = $(elem).find(".value")[0];
                    var editValueElement = $(elem).find(".editvalue")[0];
                    function calculateDays(targetDate) {
                        var today = moment().startOf('day');
                        var target = moment(targetDate).startOf('day');;
                        scope.days = target.diff(today, 'days')
                        
                        if (scope.viewModeSelection == "countup") {
                            scope.days = scope.days * -1
                        } 
                        if(scope.editInViewMode){
                            var numAnim = new CountUp(editValueElement, 0, scope.days);
                            numAnim.start();
                        } else {
                            var numAnim = new CountUp(valueElement, 0, scope.days);
                            numAnim.start();
                        }       
                    }

                    scope.updateValue = function (id) {
                        if (!scope.dataSource) return;
                        if (scope.dataSource.id !== id) return;

                        dataService().getLastDate(id, timePeriod.period, scope.filters).then(function (lastDate) {
                            calculateDays(lastDate);
                        });
                    };

                    scope.updateSettings = function(){
                        if (scope.isAdminMode || scope.editInViewMode) {
                            var componentSettings = {
                                title: scope.title,
                                viewModeSelection: scope.viewModeSelection,
                                targetDate: scope.targetDate, //relevant for manual input
                                editInViewMode: scope.editInViewMode, //relevant for manual input
                                dataSource: scope.dataSource, //relevant if datasource is selected
                                filters: scope.filters, //relevant if datasource is selected
                                style: scope.style,
                                theme: scope.theme
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                    // Select defintion to use.
                scope.selectDataSource = function () {
                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    if (!scope.filters) {
                        scope.filters = [];
                    }
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return false;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return false;
                            }

                        }
                    });

                    modalInstance.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        var strippedDatasource = {
                            id:result.dataSource.id,
                            title:result.dataSource.title
                        }
                        scope.dataSource = strippedDatasource;
                        scope.filters = result.filters;
                        scope.dateSourceSelection = "datasource";
                        scope.editInViewMode = false; //only relevant for manual input
                        scope.updateSettings();
                        scope.updateValue(scope.dataSource.id);
                    });
                };

                // Subscribe to component related event
                scope.subscribe("DataValueAdded", function (value) {
                    scope.updateValue(value.dataSourceId);
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    scope.updateValue(value.dataSourceId);
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    scope.updateValue(value.dataSourceId);
                });

                function checkDeviationEvents(){
                    if(scope.dataSource){
                        if(scope.dataSource.id == 'DeviationNew' || 
                        scope.dataSource.id == 'DeviationCompleted' ||
                        scope.dataSource.id == 'DeviationConsequence'){
                            scope.updateValue(scope.dataSource.id);
                        }
                    }
                }
                scope.subscribe("DeviationCreated", function (item) {
                    checkDeviationEvents();
                });
                scope.subscribe("DeviationDeleted", function (deviation) {
                    checkDeviationEvents();
                });
                scope.subscribe("DeviationUpdated", function (item) {
                    checkDeviationEvents();
                });

                //Improvement
                function checkImprovementEvents(){
                    if(scope.dataSource){
                        if(scope.dataSource.id == 'ImprovementSuggestionNew' || 
                        scope.dataSource.id == 'ImprovementSuggestion' ||
                        scope.dataSource.id == 'ImprovementSuggestionCompleted'){
                            scope.updateValue(scope.dataSource.id);
                        }
                    }
                }
                scope.subscribe("NewImprovement", function (item) {
                    checkImprovementEvents();
                });
                scope.subscribe("ImprovementDeleted", function (deviation) {
                    checkImprovementEvents();
                });
                scope.subscribe("ImprovementUpdated", function (item) {
                    checkImprovementEvents();
                });

                //A3
                function checkA3Events(){
                    if(scope.dataSource){
                        if(scope.dataSource.id == 'A3New' || 
                        scope.dataSource.id == 'A3Completed' ||
                        scope.dataSource.id == 'A3ROI'){
                            scope.updateValue(scope.dataSource.id);
                        }
                    }
                }
                scope.subscribe("A3Created", function (item) {
                    checkA3Events();
                });
                scope.subscribe("A3sDeleted", function (deviation) {
                    checkA3Events();
                });
                scope.subscribe("A3Updated", function (item) {
                    checkA3Events();
                });
                    scope.$on("widget-resized", function (event, args) {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        
                        var width = host.width();
                        var height = host.height();
                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 40;
                        var container = $(elem).find(".daycounter");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                    });

                }
            }
        }]);

