// Controller to load correct board => based on type
import * as boardService from "@common/services/boardService"
import { fixTransparentText } from "@common/services/helperLib"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('boardResolverController', ['$scope', '$stateParams', 'isTeamsMode',
  function ($scope, $stateParams, isTeamsMode) {
    $scope.boardid = parseInt($stateParams.id);
    $scope.isAdminMode = false;
    if ($stateParams.mode && $stateParams.mode == "admin") {
      $scope.isAdminMode = true;
    }
    $scope.isLoading = true;
    $scope.boardData = null;
    $scope.projectid = null;
    $scope.showProjectMilestone = false;
    $scope.isInTeams = false;
    if (isTeamsMode) {
      $scope.isInTeams = true;
    }

    // Teams specific
    $scope.isConfigurableTab = false
    const params = new URLSearchParams(window.location.search)
    if (params.has("context")) {
      const context = params.get("context")
      $scope.isConfigurableTab = context == "configTab"
    }

    var actionListSettings = {
      boardId: $scope.boardid,
      showAddButton: true,
      showAggregateSum: true,
    };

    $scope.options = {
      fixedHeader: true
    };

    $scope.yearlyBoardOptions = {
      showTeamMembers: true,
      showHeader: true
    };
    boardService.get($scope.boardid).then(function (board) {
      $scope.boardData = board;
      if ($scope.boardData.board.asset) {
        $scope.projectid = $scope.boardData.board.asset.projectId;
        if ($scope.projectid) {
          $scope.showProjectMilestone = true;
        }
        $scope.assetId = $scope.boardData.board.asset.id;
      }


      var settings;
      if (board.board.settings) {
        actionListSettings = JSON.parse(board.board.settings);
        fixTransparentText(actionListSettings)
        settings = actionListSettings;
      };
      actionListSettings.boardId = $scope.boardid;
      actionListSettings.title = $scope.boardData.board.name;
      $scope.actionListSettings = actionListSettings;
      $scope.boardType = board.board.boardType;
      if (!$scope.boardType) {
        $scope.boardType = "Default"
        // CHeck if weekly board
        if (settings && settings.IsWeeklyBoard) {
          board.board.boardType = "Weekly"
        }
      }
      if ($scope.boardType == "3C") {
        $scope.options = {
          boardId: $scope.boardid,
          isStandAlone: true
        };
      }
      $scope.isLoading = false;
      $scope.$apply();
    })
  }]);