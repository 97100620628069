import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("a3EndDateCell", ['a3Service',
        function (a3Service) {
            return {
                templateUrl: 'a3EndDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.dateMissed = false;
                    scope.date = null;
                    

                    scope.date = {
                        endDate: null,
                        estimatedEndDate: null,
                    };

                    scope.updateEstimatedEndDate = function($event) {
                        const date = $event.originalEvent.detail      
                        scope.date.estimatedEndDate = date
                        scope.updateDate()
                    }
                    scope.updateEndEndDate = function($event) {
                        const date = $event.originalEvent.detail      
                        scope.date.endDate = date
                        scope.updateDate()
                    }
                    scope.updateDate = function() {
                        scope.a3Project.endDate = scope.date.endDate
                        scope.a3Project.estimatedEndDate = scope.date.estimatedEndDate
                        a3Service().updateProjectInfo(scope.model.a3.id, scope.a3Project).then(function (savedDate) { 
                            checkDate()
                        })
                    }
                    
                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.a3) {
                            if (!scope.model.a3.a3ProjectId) {
                                scope.a3Project = {
                                    estimatedEndDate: null,
                                    endDate: null,
                                    estimatedTotalCost: null,
                                    estimatedSavings: null,
                                    actualTotalCost: null,
                                    actualSavings: null,
                                };
                            } else {
                                getA3ProjectInfo();
                            }
                        }
                        model.getSortValue = function() {
                            if (scope.date) {
                                return scope.date.endDate;
                            }
                            return null;
                        }
                    });

                    function getA3ProjectInfo(){
                        a3Service().getA3ProjectInfo(scope.model.a3.id).then(function (a3ProjectInfo) { 
                            scope.a3Project = a3ProjectInfo;
                            if(scope.a3Project.estimatedEndDate){
                                scope.date.estimatedEndDate = moment(scope.a3Project.estimatedEndDate).toDate();
                            }   
                            if(scope.a3Project.endDate){
                                scope.date.endDate = moment(scope.a3Project.endDate).toDate();
                            }
                            checkDate();
                        });
                    }   
                    
                    scope.subscribe('A3ProjectInfoUpdated', function (a3) {
                        if (scope.model.a3.id === a3.id) {
                            if(a3.estimatedEndDate || a3.endDate){
                                if(a3.estimatedEndDate){
                                    scope.date.estimatedEndDate = moment(a3.estimatedEndDate).toDate();
                                }
                                if(a3.estimatedEndDate){
                                    scope.date.endDate = moment(a3.endDate).toDate();
                                }
                                checkDate();
                            }
                            scope.a3Project.estimatedTotalCost = a3.estimatedTotalCost;
                            scope.a3Project.estimatedSavings = a3.estimatedSavings;
                            scope.a3Project.actualTotalCost = a3.actualTotalCost;
                            scope.a3Project.actualSavings = a3.actualSavings;
                            
                        }
                    });


                    function createTempDate(){
                        scope.date = {
                            endDate: null,
                            estimatedEndDate: null,
                            datePicker : {
                                opened: false
                            },
                            estimatedDatePicker : {
                                opened: false
                            }
                        };
                    }

                    function checkDate() {
                        scope.date.status = "";
                        scope.date.endDate = timeService.validateDate(scope.date.endDate);
                        scope.date.estimatedEndDate = timeService.validateDate(scope.date.estimatedEndDate);
                        scope.dateMissed = false;
                        
                        if (scope.date.endDate) {
                            scope.date.status = "completed";
                            var diff =  moment.duration(moment(scope.date.endDate).diff(moment(scope.date.estimatedEndDate)));
                            var days = diff.asDays();
                            if(!isNaN(days)){
                                scope.date.diffPlannedVSActual = Math.round(days);
                            }else {
                                scope.date.diffPlannedVSActual = null;
                            }
                        } else if (scope.date.estimatedEndDate) {
                            //check if overdue/missed
                
                            var duration = moment.duration(moment().endOf('day').diff(moment(scope.date.estimatedEndDate).endOf('day')));
                            var days = duration.asDays();
                            if (days > 1) {
                                scope.date.status = "missed";
                                scope.date.diffToPlannedDate = Math.trunc(days);
                                scope.dateMissed = true;
                            } else {
                                scope.date.status = "future";
                                scope.date.diffToPlannedDate = Math.round(days)*-1;
                            }
                        }
                    }
                }
            }
        }]);
