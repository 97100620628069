import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

// Controller for two modual popup newtasktopersonalactionlist.html and newtasktoactionlist.html
var DigiLean = angular.module('DigiLean');
DigiLean.controller('actionListNewTaskController', ['$scope', '$filter', '$uibModalInstance', 'boardTaskService', 'authService','boardid', 'date',
    function ($scope, $filter, $uibModalInstance, boardTaskService, authService, boardid, date) {
        $scope.boards = {};
        $scope.date = date;
        $scope.selectedEmployee = {};
        $scope.selectedBoard = null;
        var userid = authService.getCurrentUser().userId;
        $scope.task = {};

        boardService.getMemberBoards().then(function (data) {
            $scope.boards = data;
            if (boardid) {
                $scope.selectedBoard = $filter('filter')(data, { id: boardid }, true)[0];
            } else {
                $scope.selectedBoard = data[0];
            }
            $scope.getUserListForBoard();
        });

        $scope.getUserListForBoard = function () {
            boardService.getUserListForBoard(boardid).then(function (data) {
                angular.forEach(data, function (employee, key) {
                    employee.fullName = employee.firstName + " " + employee.lastName;
                });
                $scope.employees = data;
                if (data.length > 0) {
                    $scope.selectedEmployee = $filter('filter')(data, { id: userid }, true)[0];
                }
            });
        }

        // Datepicker setup
        $scope.formats = ['dd.MM.yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'shortDate']
        $scope.format = $scope.formats[0];
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.datepickerOpen = function () {
            $scope.datePicker.opened = true;
        }
        $scope.datePicker = {
            opened: false
        };
        $scope.dateOptions = timeService.globalDatePickerOptions();


        $scope.ok = function () {
            // Create new action 

            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: 0,
                    Tag: tagsArray[i],
                    BoardTaskId: 0
                });
            }
            // var boardId = null;
            // // if ($scope.selectedBoard) {
            //     boardId = boardid;
            // // }
            date = moment($scope.date).utc().toDate();
            var task = {
                id: 0,
                isActionItem: true, // Belongs to action list
                boardId: boardid,
                title: $scope.task.title,
                text: $scope.task.text,
                boardDate: date,
                status: 'blank',
                tags: tags
            }

            var responsible = $scope.selectedEmployee;
            task.responsibleUserId = responsible.id;
            task.responsibleDisplayName = responsible.displayName;
            task.responsibleUser = responsible.firstName + " " + responsible.lastName;

            boardTaskService().addTask(task).then(function (taskId) {
                task.id = taskId;
                $scope.publish("SendCreateActionListItem", task);
                $uibModalInstance.close(task);
            });

        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };



        setTimeout(function () {
            $("#taskTags").tagit({
                // availableTags: sampleTags,
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
        }, 200);

        $scope.dateChanged = function(event) {
            const eventdate = event.originalEvent.detail
            $scope.date = eventdate
        }
    }]);