import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('benefitsRealizationController', ['$scope', '$filter', '$translate', 'NgTableParams', 'a3Service','suggestionService',
    function ($scope, $filter, $translate, NgTableParams, a3Service,suggestionService) {
   
        $scope.timeframe = "thisyear";
        $scope.estimatedtimeframe = "thisyear";
        $scope.timePeriod = timeService.getTimePeriod($scope.timeframe);
        $scope.estimatedtimePeriod = timeService.getTimePeriod($scope.estimatedtimeframe);

        $scope.realized = 0;
        $scope.estimated = 0;

        $scope.options = {
            timePeriod: {
                timeframe: "thisyear",
                useDefaultTimeframes: true,
                timeframes:[]
            }
        };
        $scope.periodChangedHandler = function (timePeriod) {
            $scope.timePeriod = timePeriod.period;
            $scope.timeframe = timePeriod.timeframe;
            getData();
        }

        $scope.estimatedoptions = {
            timePeriod: {
                timeframe: "thisyear",
                useDefaultTimeframes: true,
                timeframes:[]
            }
        };
        $scope.estimatedperiodChangedHandler = function (timePeriod) {
            $scope.estimatedtimePeriod = timePeriod.period;
            $scope.estimatedtimeframe = timePeriod.timeframe;
            getEstimated();
        }

        $scope.currency = customerService.getDefaultCurrency(); 
        getCompanySetting("currency").then(function (setting) {
            if (setting) {
                $scope.currency = setting
            }
            getData();
            getEstimated();
        });
        
        function getData(){
            suggestionService().getRealization($scope.timePeriod).then(function (result) {
                if(result){
                    $scope.realized = result;
                } else {
                    $scope.realized = 0;
                }
            });
        }

        function getEstimated(){
            suggestionService().getEstimatedInitiatives($scope.estimatedtimePeriod).then(function (result) {
                if(result){
                    $scope.estimated = result;
                } else {
                    $scope.estimated = 0;
                }
            });
        }
        
        
        
        
        a3Service().getProjectInfoForA3s().then(function (a3s) {
            angular.forEach(a3s, function (a3) {
                a3.statusorder = getStatusOrder(a3.status);
                checkMilestones(a3);
                calculateRealization(a3);
            })
             $scope.tableParams = new NgTableParams({}, { dataset: a3s });
        });

        function calculateRealization(a3) {
            if (a3.estimatedSavings && a3.estimatedTotalCost) {
                a3.estimated = a3.estimatedSavings - a3.estimatedTotalCost;
            }
            if (a3.actualSavings && a3.actualTotalCost) {
                a3.realized = a3.actualSavings - a3.actualTotalCost;
            }
        }

        function checkMilestones(a3) {
            if (a3.milestones && a3.milestones.length > 0) {
                a3.milestoneMissed = false;
                for (var i = 0; i < a3.milestones.length; i++) {
                    if (a3.milestones[i].actualDate) {
                        a3.milestones[i].status = "completed";
                    } else if (a3.milestones[i].plannedDate) {
                        //check if overdue/missed
                        var duration = moment.duration(moment().diff(moment(a3.milestones[i].plannedDate)));
                        var days = duration.asDays();
                        if (days > 1) {
                            a3.milestones[i].status = "missed";
                            a3.milestoneMissed = true;
                        } else {
                            a3.milestones[i].status = "future";
                        }
                    }
                }
            }
        }

        $translate(['COMMON_TITLE', 'COMMON_STATUS']).then(function (translations) {
            $scope.translatedTitle = translations.COMMON_TITLE;
            $scope.translatedStatus = translations.COMMON_STATUS;
        });
        $scope.$on('$translateChangeSuccess', function () {
            $scope.translatedTitle = $translate.instant('COMMON_TITLE');
            $scope.translatedStatus = $translate.instant('COMMON_STATUS');
        });
        $scope.translatedTitel = [
            { id: "text", label: "Small text", languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
            { id: "description", label: "Larger text", languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
            { id: "number", label: "Number", languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
            { id: "bool", label: "Boolean", languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
            { id: "asset", label: "Asset", languageCode: "COMMON_ASSET", sourceColumn: "assetId" },
            { id: "area", label: "Area", languageCode: "COMMON_AREA", sourceColumn: "areaId" },
            { id: "project", label: "Project", languageCode: "COMMON_PROJECT", sourceColumn: "projectId" },
        ];

        $scope.toggleShowMilestone = function (a3) {
            a3.showMilestone = !a3.showMilestone;
        };

        function getStatusOrder(dbstatus) {
            switch (dbstatus) {
                case "New":
                    return 1;
                case "Plan":
                    return 2;
                case "Do":
                    return 3;
                case "Check":
                    return 4;
                case "Act":
                    return 5;
                case "Closed":
                    return 6;
                default:
                    return 0;
            }
        }

        //$scope.tableParams = new NgTableParams({}, { dataset: a3});  

    }]);

