﻿2
angular
    .module('DigiLean')
    .directive("learningPageEvaluation", ['evaluationService','favoriteService',
        function (evaluationService, favoriteService) {
            return {
                templateUrl: 'learningPageEvaluation.html',
                restrict: 'E',
                scope: {
                    'pageId': '<',
                    'fileName': '<',
                    'isExternal': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.isUseful = false;
                    scope.isNotUseful = false;
                    scope.isStar = false;
                    scope.$watch('pageId', function (pageId) {
                        if (!pageId) return;
                        scope.isUseful = false;
                        scope.isNotUseful = false;
                        evaluationService().getMyEvaluationForPage(scope.isExternal, pageId).then(function(evaluation) {
                            if (evaluation) {
                                if (evaluation.value == 1) {
                                    scope.isUseful = true;
                                } else {
                                    scope.isNotUseful = true;
                                }
                            }
                        })
                        favoriteService().hasPageAsFavorite(pageId).then(function(status) {
                            scope.isStar = status;
                        })
                    });

                    scope.setUseful = function() {
                        var evaluation = {
                            value: 1
                        }
                        if (!scope.isUseful) {
                            evaluationService().evaluatePage(scope.isExternal, scope.pageId, evaluation).then(function(comment){
                                scope.isUseful = true;
                                scope.isNotUseful = false;
                            });
                        } else {
                            evaluationService().removeEvaluationFromPage(scope.isExternal, scope.pageId).then(function(comment){
                                scope.isUseful = false;
                                scope.isNotUseful = false;
                            });
                        }
                    }
                    scope.setNotUseful = function() {
                        var evaluation = {
                            value: -1
                        }
                        if (!scope.isNotUseful) {
                            evaluationService().evaluatePage(scope.isExternal, scope.pageId, evaluation).then(function(comment){
                                scope.isUseful = false;
                                scope.isNotUseful = true;
                            });
                        } else {
                            evaluationService().removeEvaluationFromPage(scope.isExternal, scope.pageId).then(function(comment){
                                scope.isUseful = false;
                                scope.isNotUseful = false;
                            });
                        }
                       
                    }
                    scope.setStar = function() {
                        scope.isStar = !scope.isStar;
                        var source = "internal";
                        if (scope.isExternal) source = "external";
                        if (scope.isStar) {
                            favoriteService().addPageAsFavorite(source, scope.pageId).then(function(status) {
                              
                            })
                        } else {
                            favoriteService().removePageAsFavorite(source, scope.pageId).then(function(status) {
                                
                            })
                        }
                        
                        // evaluationService().evaluatePage(scope.pageId, evaluation).then(function(comment){
                        //     scope.isUseful = false;
                        //     scope.isNotUseful = true;
                        // });
                    }
                }
            }
        }]);
