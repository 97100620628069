import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("goalCreate", ['$rootScope', '$filter', 'strategyService', 'strategyGoalService', 'authService', 'themeService',
        function ($rootScope,$filter, strategyService, strategyGoalService, authService, themeService) {
            return {
                templateUrl: 'goalCreate.html',
                restrict: 'E',
                scope: {
                    'options': "<",
                    'goalcreateChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    var allColors = themeService().getColors();
                    scope.selectedColor = null;

                    var allColors = ["#FCBB42", "#3BAFDA", "#8CC152", "#ED5565", "#E9573F", "#D770AD", "#c18852", "#5254c1",
                        "#fcea42", "#52c1c0", "#c1bf52", "#449d44", "#8b8d92", "#202020", "#13105e", "#AC92EC"];

                    scope.getAvailableColors = function () {
                        scope.selectedColor = null;
                        scope.availableColors = [];
                        strategyService().getBreakthroughObjectives().then(function (result) {
                            scope.goals = result;
                            for (var i = 0; i < allColors.length; i++) {
                                var colorUsed = $filter('filter')(scope.goals, { color: allColors[i] }, true);
                                if (colorUsed.length == 0) {
                                    scope.availableColors.push(allColors[i]);
                                }
                            }
                            scope.selectedColor = scope.availableColors[0];
                            updateHandler();
                        });
                        
                    }
                    scope.$watch("options", function (options) {
                        if(scope.options){
                            scope.name = scope.options.name;
                            var parentGoalId = scope.options.goalId;
                            if (parentGoalId > 0) {
                                scope.goalType = "SubGoal";
                                // LoadparentGoal
                                strategyGoalService().getGoal(parentGoalId).then(function(parentGoal){
                                    scope.parentGoal = parentGoal;
                                    scope.selectedColor = parentGoal.color;
                                    updateHandler();
                                });
                            } else {
                                scope.goalType = "BreakThroughObjective";
                                scope.getAvailableColors();
                            }
                        }
                    });

                    scope.textChanged = function (name) {
                        scope.name = name;
                        updateHandler();
                    }
                    scope.colorChanged = function (col) {
                        scope.selectedColor = col;
                        updateHandler();
                    }

                    /*

                    scope.assetId = null;
                    scope.setAsset = function (asset) {
                        scope.selectAsset = asset;
                        if (asset) {
                            scope.assetId = scope.selectAsset.id;
                        }
                        updateHandler();
                    };*/

                    scope.modifyAsset = function () {
                        updateHandler();
                    };
                    

                    function updateHandler() {
                        if (!scope.goalcreateChangeHandler) return;
                        var selected = $filter('filter')(scope.assets, { include: true });
                        var goalCreate = {
                            name: scope.name,
                            //assetId: scope.assetId,
                            assetList: selected,
                            color: scope.selectedColor,
                        }
                        scope.goalcreateChangeHandler({ goalCreate: goalCreate })
                    }

                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                    $rootScope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                        if (scope.isAdmin) {
                            scope.canEdit = true;
                            scope.selectAsset = null;
                        }
                    });

                    scope.selectAsset = null;
                    function getAssetList() {
                        if (scope.isAdmin) {
                            assetService.getAllAssets(true).then(function (assets) {
                                scope.assets = assets;
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;
                                }
                            });
                        } else {
                            assetService.getList().then(function (assets) {
                                scope.assets = assets;
                                if (scope.assets.length > 0) {
                                    scope.selectAsset = scope.assets[0];
                                    scope.assetId = scope.selectAsset.id;
                                }
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;

                                }

                            });
                        }

                    };
                    getAssetList();
                }
            }
        }]);