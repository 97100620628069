import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr"
import config from "@common/config"
import { authUser } from "@common/stores/userStore"
import type { DigiLeanAuthUser } from "@common/model/types"
import { DlSubject } from "@common/stores/reactive"
import * as systemHubService from "@common/services/systemHubService"

const systemHubUrl = config.signalrUrl + "/system"

let user: DigiLeanAuthUser
const systemHubStateSub = new DlSubject<HubConnectionState>(HubConnectionState.Disconnected)
export const systemHubState = systemHubStateSub.asObservable()

const builder = new HubConnectionBuilder()


export function getConnectionId() {
    return systemHub.connectionId ? systemHub.connectionId : ""
}

export let systemHub = builder.withUrl(systemHubUrl,{
    accessTokenFactory: () => {
        const token = user.token!
        return token
    }
})
    .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.elapsedMilliseconds < 60000) {
                // If we've been reconnecting for less than 60 seconds so far,
                // wait between 0 and 10 seconds before the next reconnect attempt.
                return 2000
            } else if (retryContext.elapsedMilliseconds > 60000 && retryContext.elapsedMilliseconds < 600000) {
                // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                return 10000
            }
            else {
                return null // Stop trying
            }
        }
    })
    .build()

systemHub.onclose(async (error) => {
    console.log(error)
    systemHubStateSub.next(systemHub.state)
    console.log("Disconnected from SystemHub")
    // Try to reconnect, unless the user is logging out
    if (user.loggedIn) {
        setTimeout(async () => {
            await connectSystemHub()
        }, 5000)
    }
})

systemHub.onreconnecting((error) => {
    console.log(error)
    systemHubStateSub.next(systemHub.state)
    console.log('Reconnect to SystemHub')
})

systemHub.onreconnected(connectionId => {
    systemHubStateSub.next(systemHub.state)
    console.log("systemhub: reconnected, new connectionId", connectionId)
    onConnected()
})

const connectSystemHub = async () => {
    console.log("Connecting to SystemHub")
    await systemHub.start().catch(e => {
        console.error(e)
        systemHubStateSub.next(systemHub.state)
    })
    systemHubStateSub.next(systemHub.state)
}

const onConnected = () => {
    let connectionId = systemHub.connectionId!
    console.log("systemhub: connected, connectionId", connectionId)
    systemHubService.sendProxyEvent("SendUserConnectedRequest")
    if (config.isTeamsMode)
        systemHubService.updateSource(connectionId, "teams")
    
    if (location.hostname == "localhost") {
        systemHubService.postConnectionEvent(connectionId!)
    }
}

const connectToSignalR = async () => {
    if (systemHub.state !== HubConnectionState.Disconnected)
        return //already have a hub connection, if it's broken the reconnect logic will kick in
    
    await connectSystemHub()

    console.log("connected SystemHub, systemHub.state", systemHub.state)
    onConnected()
}

authUser.subscribe(u => {
    user = u
    if (u.loggedIn) {
        connectToSignalR()
    } else {
        systemHub.stop()
    }
})

