import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive('inboxNotifications', ['$translate', '$filter', '$interval', 'notificationService',
        function ($translate, $filter, $interval, notificationService) {
            return {
                templateUrl: 'inboxNotifications.html',
                restrict: 'E',
                scope: {
                    "selectedNotificationId": "<?"
                },
                link: function (scope, elem, attrs) {
                    scope.newAlerts = 0;
                    scope.viewMode = "Active";
                    scope.notifications = [];
                    // Get action list for translate and icons
                    var actions = notificationService().getNotificationActions();

                    scope.subscribe('Notification', function (notification) {
                        notification.isNew = true;
                        updateNotificationWithActionData(notification);
                        scope.notifications.unshift(notification);
                        scope.allNotifications.unshift(notification);
                        calculateTimeElapsed();
                    });
                    scope.subscribe('NotificationSeen', function (notification) {
                        var partOfList = $filter('filter')(scope.allNotifications, { id: notification.id }, true);
                        if (partOfList.length > 0) {
                            partOfList[0].seenTime = notification.seenTime;
                        }
                    });
                    scope.subscribe('MarkedAllNotificationsAsRead', function (user) {
                        setAllAsRead();
                    });

                    function loadNotifications() {
                        var status = 0; // Open
                        scope.allNotifications = [];
                        
                        scope.isLoading = true;
                        if (scope.viewMode == "Archived") status = 1;

                        var queryOptions = {
                            count: 100,
                            status: status
                        }
                        notificationService().getMyNotifications(queryOptions).then(function (notifications) {
                            notifications.forEach(function (notification) {
                                notification.isNew = false;
                                updateNotificationWithActionData(notification);
                            });
                            
                            calculateTimeElapsed();

                            // If selectedNotification, we will insert it first and load message.
                            if (scope.selectedNotificationId) {
                                var hasNotification = $filter('filter')(notifications, { id: scope.selectedNotificationId });
                                if (hasNotification.length == 1) {
                                    var notification = hasNotification[0];
                                    var index = notifications.indexOf(notification);
                                    if (index >= 0) {
                                        notifications.splice(index, 1);
                                        notifications.unshift(notification);
                                        scope.toggleNotification(notification);
                                    }
                                }
                            }

                            scope.allNotifications = notifications;
                            // Preload 10 first? => Will result in many API calls
                            // for (let index = 0; index < 10; index++) {
                            //     if (notifications.length > index) {
                            //         scope.toggleNotification(notifications[index]);
                            //     }
                            // }
                            scope.isLoading = false;
                        });
                    }
                    loadNotifications();

                    scope.toggleViewMode = function (viewMode) {
                        loadNotifications();
                    }
                    scope.toggleNotification = function (notification) {
                        if (notification.isOpen) {
                            notification.isOpen = false;
                            return;
                        }
                        notification.isOpen = true;
                        notificationService().getActivityForNotification(notification.activityLogItemId).then(function (item) {
                            notification.isLoaded = true;
                            var payLoad = JSON.parse(item.payLoad);
                            notification.payLoadText = item.payLoad;
                            notification.payLoad = payLoad;
                        });

                        if (!notification.seenTime) {
                            // Toggle as seen
                            notificationService().seenNotification(notification.id).then(function () {

                            });
                        }
                    };

                    scope.markAllasRead = function() {
                        notificationService().markAllAsSeen().then(function(){
                            setAllAsRead();
                        });
                    }

                    scope.archiveNotfication = function (notification) {
                        notification.animation = "fadeOutUp";
                        setTimeout(function () {
                            var index = scope.notifications.indexOf(notification);
                            scope.notifications.splice(index, 1);
                            index = scope.allNotifications.indexOf(notification);
                            scope.allNotifications.splice(index, 1);
                            notificationService().archiveNotification(notification.id).then(function () {

                            });
                        }, 200);

                    }

                    function setAllAsRead() {
                        scope.allNotifications.forEach(x => {
                            x.seenTime = moment();
                        });
                    }

                    function updateNotificationWithActionData(notification) {
                        var targetAction = $filter('filter')(actions, { action: notification.action }, true);
                        if (targetAction.length > 0) {
                            notification.title = $translate.instant(targetAction[0].translateLabel);
                            notification.actionSymbol = targetAction[0].actionSymbol;
                        }
                    }

                    var translateOptions = {
                        month: $translate.instant('COMMON_TIMEELAPSED_MONTH'),
                        days: $translate.instant('COMMON_TIMEELAPSED_DAYS'),
                        hours: $translate.instant('COMMON_TIMEELAPSED_HOURS'),
                        mins: $translate.instant('COMMON_TIMEELAPSED_MINUTES'),
                        seconds: $translate.instant('COMMON_TIMEELAPSED_SECONDS'),
                        now: $translate.instant('COMMON_TIMEELAPSED_NOW')
                    }
                    var calculateTimeElapsed = function () {
                        // Recalculate time elapsed
                        scope.allNotifications.forEach(function (notification) {
                            notification.timeElapsed = timeService.getTimeElapsed(notification.notificationTime, translateOptions);
                        });
                    }

                    var updateTimer = $interval(calculateTimeElapsed, 5000);
                    elem.on('$destroy', function () {
                        $interval.cancel(updateTimer);
                    });
                }
            }
        }]);
