﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('cloneInfoController', ['$scope', '$filter', '$uibModalInstance', 'boardTaskService', 'task',
  function ($scope, $filter, $uibModalInstance, boardTaskService, task) {
      $scope.task = task;

      boardTaskService().getSiblings(task.siblingId).then(function (data) {
          $scope.siblings = data;
      });

      $scope.ok = function () {
          $uibModalInstance.close();
      };
      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
  }]);