import { userFullProfile } from "@common/stores/userStore"
import angular from "angular"
import { DigiLeanNgScope } from "@common/model/angularModel"
import { CustomerSubscriptionType } from "@api"

interface iHasSubscriptionDirective extends DigiLeanNgScope{
    hasSubscription: string
}

angular
.module('DigiLean')
.directive('hasSubscription', function() {
    return {
        restrict: 'A',
        link: function(scope: iHasSubscriptionDirective, elm, attr) {
            const el = elm[0] as HTMLElement
            if (!el)
                return
            
            const subscription = attr.hasSubscription as CustomerSubscriptionType
            
            let originalDisplay = el.style.display

            let hasSubscription = false
            let sub = userFullProfile.subscribe(userProfile => {
                if (subscription && userProfile) {

                    if (userProfile.subscription) {
                        hasSubscription = userProfile.subscription == subscription
                    }
                }
                if (!hasSubscription)
                    el.style.display = "none"
                else
                    el.style.display = originalDisplay
            })
            
            scope.$on('$destroy', function() {
                if (sub)
                    sub.unsubscribe()
            })
        }
    }
})