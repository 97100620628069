import * as customerService from "@common/services/customers/customerService"
import { getUserProfile } from "@common/stores/userStore"

angular
    .module('DigiLean')
    .directive("improvementCommentMessage", ['$uibModal', 'suggestionService',
        function ($uibModal, suggestionService) {
            return {
                templateUrl: 'improvementCommentMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.suggestion = null;
                    scope.isLoading = true;
                    scope.users = [];
                    scope.selectedCommentId = 0;
                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        scope.selectedCommentId = payLoad.Id;
                        suggestionService().get(payLoad.ImprovementSuggestionId).then(function (suggestion) {
                            suggestion.dateStyleClass = getDateStyle(suggestion.dueDate);
                            scope.suggestion = suggestion;
                            if (scope.suggestion.assetId) {
                                customerService.getUsersForAsset(scope.suggestion.assetId).then(function (users) {
                                    scope.users = users;
                                });
                            }
                        });
                        // Get current user + users for commenting
                        scope.currentUser = getUserProfile();
                        suggestionService().getComments(payLoad.ImprovementSuggestionId).then(function (model) {
                            scope.comments = model.comments;
                        });
               
                    });

                    function getDateStyle(date) {
                        if (!date) return "";
                        // Overdue check

                        var overdue = "";
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            overdue = "overdue";
                        }
                        return overdue;
                    }
                    
                    scope.saveComment = function(comment) {
                        comment.suggestionId = scope.suggestion.id;
                        return suggestionService().addComment(comment);
                    };

                    scope.deleteComment = function(comment) {
                        return suggestionService().deleteComment(scope.suggestion.id, comment.id);
                    };

                    scope.openSuggestion = function (activeTab) {
                        if (!activeTab) activeTab = "Info";

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'fullImprovementInfoForm.html',
                            controller: 'fullImprovementModalController',
                            windowClass: 'fullSuggestion-modal-window',
                            resolve: {
                                suggestion: function () {
                                    return scope.suggestion;
                                },
                                activeTab: function () {
                                    return activeTab;
                                }
                            }
                        });

                    }
                }
            }
        }]);