import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("boardAccessChecker",
    function () {
        return {
            templateUrl: 'boardAccessChecker.html',
            restrict: 'E',
            scope: {
                'accessOptions': '<?',
            },
            link: function (scope, elem, attrs) {
                // comments here
                scope.noAccessVisible=false;
                scope.$watch("accessOptions", function (options) {
                    if(options){
                        if(options.hasAccess === false){
                            scope.noAccessVisible = true;
                            boardService.getRequiredAccess(options.boardId).then(function(result){
                                scope.accessInfo = result;
                            });
                        }
                    }
                });

            }
        }
    });
