import angular from 'angular';
import angularDragula from 'angularjs-dragula';
import digiLeanOidc from "@common/services/auth/digileanOidc"
import { userFullProfile } from "@common/stores/userStore"
import { setAndLoadLang } from "@common/services/language/DigiLeanLang"


var allModules = [
    'ui.router',
    'ui.bootstrap',
    'ui.tree',
    'ui.toggle',
    'ngFileUpload',
    'ang-drag-drop',
    'ngSanitize',
    'angular-loading-bar',
    'bootstrapLightbox',
    'pascalprecht.translate',
    'colorpicker.module',
    'tmh.dynamicLocale',
    'gridster',
    'as.sortable',
    'ngTable',
    'angular-peity',
    'ngFileSaver',
    'ui.sortable',
    'angular-cron-gen',
    'ngImgCrop',
    angularDragula(angular),
    'ngMaterial'
]
var DigiLean = angular.module('DigiLean', allModules)

DigiLean.value('webApiUrl', webApiUrl)
DigiLean.value('serverUrl', backendUrl)
DigiLean.value('authServerUrl', authServerUrl)
DigiLean.value('fileStoreUrl', fileStoreUrl)
DigiLean.value('isTeamsMode', isTeamsMode)

DigiLean.factory('moduleCache', ['$cacheFactory', function ($cacheFactory) {
    return $cacheFactory('moduleCache')
}])

DigiLean.run(['$templateCache', function ($templateCache) {

    function requireAll(requireContext) {
        return requireContext.keys().map(function (val) {
            return {
                tpl: requireContext(val),
                name: val.split('/').pop()
            }
        })
    }

    let appFiles = requireAll(require.context(".", true, /\.html$/))
    appFiles.map(function (val) {
        var template = val.tpl
        $templateCache.put(val.name, template.default) // latest html-loader use esmodule
    })

    let commonFiles = requireAll(require.context("../../../common", true, /\.html$/))
    commonFiles.map(function (val) {
        var template = val.tpl
        $templateCache.put(val.name, template.default) // latest html-loader use esmodule
    })
}])


/*
This directive allows us to pass a function in on an enter key to do what we want.
*/
DigiLean.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter)
                })

                event.preventDefault()
            }
        })
    }
})

// actually loads angular app
async function bootstrapApp(userProfile) {
    // alternative to ng-app in index.html, want to do authentication before bootstrapping
    await setAndLoadLang(userProfile.preferredLanguage)
    angular.element(function() {
        angular.bootstrap(document, ['DigiLean'], {
            strictDi: true
        })
    })
    sub.unsubscribe()
}

let loginRequest = { 
    state: `${window.location.origin}${window.location.hash}`,
}
const params = new URLSearchParams(window.location.search)
if (params.has("login_hint")) {
    const loginHint = params.get("login_hint")
    console.log(`Login hint Login_hint found='${loginHint}'`)
    loginRequest.login_hint = loginHint
}


digiLeanOidc.initialize().then(user => {
    if (!user)
        digiLeanOidc.login()
})

let appBootstrapped = false
const sub = userFullProfile.subscribe(ufp => {
    if (!ufp || !ufp.user || !ufp.user.userId) {
        console.log("not logged in yet")
    }
    else {
        //console.log("authuser", user)
        if (!appBootstrapped) {
            bootstrapApp(ufp.user)
            appBootstrapped = true
        }
        else
            console.error("app already bootstrapped")
        return ufp
    }
})
