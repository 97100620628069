﻿import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('clonetaskController', ['$scope','$uibModalInstance', 'boardTaskService', 'task',
    function ($scope, $uibModalInstance, boardTaskService, task) {
        $scope.selectedBoard = null;
        $scope.task = task;
        $scope.selectedColumn = null;

        boardTaskService().get($scope.task.id).then(function (original) {
            $scope.selectedBoardDate = moment(original.boardDate).toDate();
            $scope.dayOfWeek = moment(original.boardDate).format('dddd');
            mapDateToColumn();
            mapDateToRow();
        });


        $scope.columns = [];
        $scope.rows = [];

        $scope.hasRows = false;
        $scope.hasColumns = false;

        $scope.selectedDateChanged = function ($event) {
            const date = $event.originalEvent.detail
            $scope.selectedBoardDate = date
            $scope.dayOfWeek = moment($scope.selectedBoardDate).format('d');
            mapDateToColumn();
            mapDateToRow();
        }

        var mapDateToRow = function () {
            if ($scope.rows.length == 0) return;
            if (!$scope.selectedBoardDate) return;

            // Try to select correct row
            angular.forEach($scope.rows, function (row) {
                if (row.weekdayName && (row.weekdayName ==  timeService.getWeekDayNameFromDate($scope.selectedBoardDate))) {
                    $scope.selectedRow = row;
                }
            });
        }

        var mapDateToColumn = function () {
            if ($scope.columns.length == 0) return;
            if (!$scope.selectedBoardDate) return;

            // Try to select correct row
            angular.forEach($scope.columns, function (column) {
                if (column.weekdayName && (column.weekdayName ==  timeService.getWeekDayNameFromDate($scope.selectedBoardDate))) {
                    $scope.selectedColumn = column;
                }
            });
        }
        var mapResponsibleToColumn = function (task) {
            if (!$scope.columns || $scope.columns.length == 0) return;
            if (!task.responsibleUserId) return;

            // Try to select correct row
            angular.forEach($scope.columns, function (column) {
                if (column.responsibleUserId == task.responsibleUserId) {
                    $scope.selectedColumn = column;
                }
            });
        }
        var mapResponsibleToRow = function (task) {
            if (!$scope.rows || $scope.rows.length == 0) return;
            if (!task.responsibleUserId) return;

            // Try to select correct row
            angular.forEach($scope.rows, function (row) {
                if (row.responsibleUserId == task.responsibleUserId) {
                    $scope.selectedRow = row;
                }
            });
        }
        $scope.changeBoard = function (board) {
            $scope.selectedBoard = board;
            $scope.hasRows = false;
            $scope.hasColumns = false;

            if (board.boardType == "SmartActionList") {
                return;
            }
            boardService.getBoardCategories(board.id).then(function (data) {

                angular.forEach(data.columns, function (column) {
                    if (column.responsibleUserId) {
                        column.displayName = column.name + " (" + column.responsibleDisplayName + ")";
                    } else {
                        column.displayName = column.name;
                    }

                });
                angular.forEach(data.rows, function (row) {
                    if (row.responsibleUserId) {
                        row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                    } else {
                        row.displayName = row.name;
                    }
                });
                if (data.columns.length > 0) {
                    $scope.columns = data.columns;
                    $scope.selectedColumn = data.columns[0];
                    $scope.hasColumns = true;
                }
                if (data.rows.length > 0) {
                    $scope.rows = data.rows;
                    $scope.selectedRow = data.rows[0];
                    $scope.hasRows = true;
                }
                mapDateToColumn();
                mapDateToRow();
                mapResponsibleToColumn($scope.task);
                mapResponsibleToRow($scope.task);
            });
        }
        $scope.ok = function () {
            var responsibleUserid, responsibleUser, responsibleDisplayName;
            var columnCategoryId, rowCategoryId;

            if ($scope.selectedColumn) {
                responsibleUserid = $scope.selectedColumn.responsibleUserId;
                responsibleUser = $scope.selectedColumn.responsibleUser;
                responsibleDisplayName = $scope.selectedColumn.responsibleDisplayName;
                columnCategoryId = $scope.selectedColumn.id
            }
            if ($scope.selectedRow) {
                rowCategoryId = $scope.selectedRow.id;
                if (!responsibleUserid) {
                    responsibleUserid = $scope.selectedRow.responsibleUserId;
                    responsibleUser = $scope.selectedRow.responsibleUser;
                    responsibleDisplayName = $scope.selectedRow.responsibleDisplayName;
                }
            }
           
            var timeNow = new Date();
            var timezone = timeNow.getTimezoneOffset() / 60 * (-1);
            var cloneOptions = {
                boardId: $scope.selectedBoard.id,
                boardDate: $scope.selectedBoardDate,
                timeZoneOffset: timezone,
                columnCategoryId: columnCategoryId,
                rowCategoryId: rowCategoryId,
                responsibleUser: responsibleUser,
                responsibleDisplayName: responsibleDisplayName,
                responsibleUserId: responsibleUserid
            }

            boardTaskService().cloneTask($scope.task.id, cloneOptions).then(function (clone) {
                $uibModalInstance.close(clone);
            });
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);