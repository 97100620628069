angular
    .module('DigiLean').directive("fileSection", ['learningService','debounce',
        function (learningService, debounce) {
            return {
                templateUrl: 'fileSection.html',
                restrict: 'E',
                scope: {
                    'section': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.update = function () {
                        if (scope.settings && typeof scope.settings !== "string") {
                            scope.settings.files = scope.files;
                            scope.section.settings = JSON.stringify(scope.settings);
                        }
                        learningService().updateSection(scope.section);
                        // Parse back so we are sure
                        //scope.section.settings = JSON.parse(scope.section.settings);
                    }
                    scope.commitChangesDebounced = debounce(scope.update, 2000, false);
                    scope.settings = {

                    }
                    scope.$watch("section", function (section) {
                        if (section) {
                            // Load settings with file links
                            if (section.settings) {
                                scope.settings = JSON.parse(section.settings);
                                scope.files = scope.settings.files;
                            } 
                        }
                        if (!scope.files) {
                            scope.files = [];
                        }
                    });

                    scope.deleteFile = function (file) {
                        var i = scope.files.indexOf(file);
                        scope.files.splice(i, 1);
                        scope.update();
                    }

                    
                    scope.fileUploaded = function (file) {
                        scope.update();
                    };

                }

            }
        }]);
