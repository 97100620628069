import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('actionListEditTaskController', ['$scope', '$filter', '$uibModalInstance', 'boardTaskService', 'authService', 'task', 'date', 
    function ($scope, $filter, $uibModalInstance, boardTaskService, authService, task, date) {
        $scope.boards = {};
        $scope.task = task;
        $scope.date = date;
        $scope.selectedEmployee = {};
        $scope.selectedBoard = null;
        var userid = authService.getCurrentUser().userId;

        $scope.getUserListForBoard = function () {
            boardService.getUserListForBoard(task.boardId).then(function (data) {
                angular.forEach(data, function (employee, key) {
                    employee.fullName = employee.firstName + " " + employee.lastName;
                });
                $scope.employees = data;
                if (data.length > 0) {
                    if($scope.task && $scope.task.responsibleUserId){
                        $scope.selectedEmployee = $filter('filter')(data, { id: $scope.task.responsibleUserId }, true)[0];
                    } else {
                        $scope.selectedEmployee = $filter('filter')(data, { id: userid }, true)[0];
                    }    
                }
            });
        }
        $scope.getUserListForBoard();

        $scope.ok = function (task) {
            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: 0,
                    Tag: tagsArray[i],
                    BoardTaskId: 0
                });
            }
            task.tags = tags;
            $scope.date = moment($scope.date).utc().toDate();
            task.boardDate = $scope.date;

            boardTaskService().updateTask(task).then(function () {
                $uibModalInstance.close(task);
            });
        };

        $scope.updateDate = function ($event) {
            const date = $event.originalEvent.detail
            $scope.date = date
            $scope.task.boardDate = moment(date).toDate()
            boardTaskService().updateDate($scope.task.id, $scope.task.boardDate)
        }

        $scope.updateResponsible = function (newResponsible) {
            var options = {
                taskId: $scope.task.id,
                user: newResponsible
            };
    
            $scope.task.responsibleUserId = newResponsible.id;
            $scope.task.responsibleUser = newResponsible.fullName;
            $scope.task.responsibleDisplayName = newResponsible.displayName;
            newResponsible.userName = newResponsible.fullName;
            boardTaskService().assignUser(options).then(function () {        
            });
        };
        
        $scope.delete = function (task) {
            boardTaskService().deleteTask(task.id).then(function (taskId) {
                $uibModalInstance.close(task);
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.dateChanged = function($event) {
            const date = $event.originalEvent.detail
            $scope.date = date
        }

        setTimeout(function () {
            $("#taskTags").tagit({
                // availableTags: sampleTags,
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
        }, 200);

        
        // Init tags and focus
        setTimeout(function () {
            $("#taskTags").tagit({
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
            if ($scope.task.tags) {
                if (typeof stringValue === "string") {
                    var tagArray = $scope.task.tags.split(",");
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i]);
                    }
                } else {
                    var tagArray = $scope.task.tags;
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i].tag);
                    }
                }
            }
        }, 50);
    }]);