var DigiLean = angular.module('DigiLean');
DigiLean.directive("taskThisWeek", ['mathService','$translate', 'projectService',
    function (mathService, $translate, customerService, projectService) {
        return {
            templateUrl: 'taskThisWeek.html',
            restrict: 'E',
            scope: {
            },
            link: function (scope, elem, attrs) {
                var projectOnTimeChart = null;

                var noOfMilestoneMet = 0;
                var noOfMilestoneDelayed = 0;
                var noOfMilestoneRemaining = 0;

                var percentOnTime = null;
                var percentMet = null;
                var percentDelayed = null;
                var percentRemaining = null;
                scope.decimals = 1;
                var totalMilestones = 0;

                projectService().getProjectInfo(166).then(function (project) {
                    scope.project = project;
                    calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                });
                function isValidDate(date) {
                    var result = moment(date);
                    return result._isValid;
                };
                function calculateMilestones(project){
                    
                    if (project.milestones && project.milestones.length > 0) {
                       
                        for (var i = 0; i < project.milestones.length; i++) {
                            if (isValidDate(project.milestones[i].actualDate)) {
                                if (isValidDate(project.milestones[i].plannedDate)) {
                                    var duration = moment.duration(moment(project.milestones[i].plannedDate).diff(moment(project.milestones[i].actualDate), 'days'));
                                    if(duration<0){
                                        noOfMilestoneDelayed = noOfMilestoneDelayed + 1;
                                    } else {
                                        noOfMilestoneMet = noOfMilestoneMet + 1;
                                    }
                                }
                            } else if (isValidDate(project.milestones[i].plannedDate)) {
                                //check if overdue/missed
                                var duration = moment.duration(moment().diff(moment(project.milestones[i].plannedDate)));
                                var days = duration.asDays().toFixed(0);  
                                if (days >= 1) {
                                    noOfMilestoneDelayed = noOfMilestoneDelayed + 1; 
                                } else {
                                    noOfMilestoneRemaining = noOfMilestoneRemaining + 1;
                                }
                            }
                        }
                    }
                    checkOnTime(project.estimatedStartDate, project.startDate);
                    checkOnTime(project.estimatedEndDate, project.endDate);
                    totalMilestones = noOfMilestoneMet + noOfMilestoneDelayed + noOfMilestoneRemaining;
                    if (totalMilestones>0){
                        
                        percentOnTime = 100 * noOfMilestoneMet / totalMilestones;
                        percentDelayed = 100 * noOfMilestoneDelayed / totalMilestones;
                        percentRemaining = 100 * noOfMilestoneRemaining / totalMilestones;

                        percentOnTime = mathService().roundNumber(percentOnTime, scope.decimals);
                        percentDelayed = mathService().roundNumber(percentDelayed, scope.decimals);
                        percentRemaining = mathService().roundNumber(percentRemaining, scope.decimals);
                    }

                }

                function checkOnTime(estimatedDate, actualDate){
                    if (isValidDate(actualDate)) {
                        if (isValidDate(estimatedDate)) {
                            var duration = moment.duration(moment(estimatedDate).diff(moment(actualDate), 'days'));
                            if(duration<0){
                                noOfMilestoneDelayed = noOfMilestoneDelayed + 1;
                            } else {
                                noOfMilestoneMet = noOfMilestoneMet + 1;
                            }
                        }
                    } else if (isValidDate(estimatedDate)) {
                        //check if overdue/missed
                        var duration = moment.duration(moment().diff(moment(estimatedDate)));
                        var days = duration.asDays().toFixed(0);  
                        if (days >= 1) {
                            noOfMilestoneDelayed = noOfMilestoneDelayed + 1; 
                        } else {
                            noOfMilestoneRemaining = noOfMilestoneRemaining + 1;
                        }
                    }
                
                }
                function drawChart() {
                    var renderElement = $(elem).find(".projectontime")[0];
                    projectOnTimeChart = new Highcharts.Chart({
                        chart: {
                            renderTo: renderElement,
                            plotBackgroundColor: '#f3f3f4', //null, 
                            plotBorderWidth: 0,
                            plotShadow: false,
                            styledMode: true
                        },
                        title: {
                            text: '<h1>' + totalMilestones + ' </h1>',
                            align: 'center',
                            verticalAlign: 'middle',
                            y: 5
                        },
                        tooltip: {
                            headerFormat: 'Oppgaver denne uka <br>',
                            //pointFormat: '<span style="color:{series.color}">{point.y}: <b>{series.name:,.0f}</b><br>'                           
                            pointFormat: '<span style="color:{series.color}">{point.name}: <b>{point.y:,.0f}</b><br>'
                            //pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                        },
                        credits: {
                            enabled: false //remove link to highcharts.com
                        },
                        exporting: {
                            enabled: false //remove context menu
                        },
                        plotOptions: {
                            pie: {
                                dataLabels: {
                                    enabled: false,
                                    distance: -50,
                                    style: {
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }
                                },
                                size: '110%',
                                colors: ['#23c6c8', '#d9534f', '#434348'] //['#5cb85c', '#d9534f', '#434348']
                            }
                        },
                        series: [{
                            type: 'pie',
                            name: 'Oppgaver denne uka',
                            innerSize: '50%',
                            data: [
                                [$translate.instant('DASHBOARD_COMPLETED'), noOfMilestoneMet], //milestone met  
                                [$translate.instant('BOARD_WAITING'), noOfMilestoneDelayed], // 
                                [$translate.instant('PROJECT_REMAINING'), noOfMilestoneRemaining], 
                                
                            ]
                        }]
                    });
                }


                

            }
        }
    }]);