import { BusinessUnit, User } from "@api"
import backend from "@common/services/backendHttp"

const resourceUrl = "api/BusinessUnit"
let unitsCached: BusinessUnit[] | null = null
export const getAllUnits = () => {
    //const url = `${resourceUrl}/getAllAssets/${useCache}`
    const url = resourceUrl
    return backend.get<BusinessUnit[]>(url)
}

export const getAllUnitsCached = async () => {
    if (!unitsCached) {
        const units = await getAllUnits()
        unitsCached = units ? units : []
    }
    return unitsCached
}

export const getBusinessUnitUsers = (id: number) => {
    const url = `${resourceUrl}/${id}/members`
    return backend.get<User[]>(url)
}