import { DigiLeanAuthRoles, DigiLeanAuthUser, DigiLeanRole, RoleArray } from "@common/model/types"
import { authUser } from "@common/stores/userStore"
import angular from "angular"

let user: DigiLeanAuthUser = {loggedIn: true, roles: new RoleArray()}
authUser.subscribe(u => user = u)

angular
.module('DigiLean')
.directive('kaizenAdminOnly', function() {
    return {
        restrict: 'A',
        link: function(scope, elm) {
            const el = elm[0] as HTMLElement
            if (!user.loggedIn || !user.roles.hasRole(DigiLeanAuthRoles.KaizenAdmin)) {
                if (el)
                    el.style.display = "none"
            }
        }
    }
})

angular
.module('DigiLean')
.directive('hasRole', function() {
    return {
        restrict: 'A',
        link: function(scope, elm, attr) {
            const el = elm[0] as HTMLElement
            if (!el)
                return
            const roles = attr.hasRole as string
            if (!roles)
                el.style.display = "none"
                
            const rolesArr = roles.split("||").map(r => r.trim()) as DigiLeanRole[]
            let hasRole = false

            rolesArr.map(role => {
                if (user.roles.hasRole(role)) {
                    hasRole = true
                }
            })
            
            if (!user.loggedIn)
                hasRole = false
            
            if (!hasRole)
                el.style.display = "none"
        }
    }
})