angular.module("DigiLean").factory("predefinedLabelColumnProvider", ['$rootScope', '$filter', '$translate', 'smartActionListService',
    function ($rootScope, $filter, $translate, smartActionListService) {
        var columns = [];
        var tasks = [];
        function predefinedLabelColumnProvider() {
            function createColumn(label, listColumn, tasks) {

                var column = {
                    showAddButton: label.isEmptyOrNull,
                    title: label.title,
                    background: label.background,
                    color: label.color,
                    displayUser: false,
                    tasks: [],
                    model: listColumn,
                    property: "text",
                    propertyValue: label,
                    isPartOfColumn: function (task, comparer) {
                        var value = $filter('filter')(task.cells, { smartTableColumnId: listColumn.id }, true);
                        if (value.length > 0 && value[0].value) {
                            // 
                            if (value[0].value.text == this.title) {
                                return true;
                            }
                        } else {
                            if (this.propertyValue.isEmptyOrNull) {
                                return true;
                            }
                        }
                        return false;
                    },
                    hasTask: function (taskId) {
                        var task = $filter('filter')(this.tasks, { id: taskId }, true);
                        return task.length > 0;
                    },
                    addTask: function (task) {
                        if (this.isPartOfColumn(task, this.property)) {
                            this.tasks.unshift(task)
                            return true;
                        }
                        return false;
                    },
                    removeTask: function (task) {
                        if (this.isPartOfColumn(task, this.property)) {
                            var index = this.tasks.indexOf(task);
                            this.tasks.splice(index, 1);
                            return true;
                        }
                        return false;
                    },
                    saveChanges: function (changeSettings) {
                        // Set property to comparer
                        var cellValue = $filter('filter')(changeSettings.task.cells, { smartTableColumnId: changeSettings.dropColumn.model.id }, true);
                        var cellId = 0;
                        if (cellValue.length > 0) {
                            cellId = cellValue[0].id;
                        }
                        var value = {
                            background: changeSettings.propertyValue.background,
                            color: changeSettings.propertyValue.color,
                            text: changeSettings.propertyValue.title
                        }
                        value = JSON.stringify(value);
                        var cell = {
                            id: cellId,
                            boardTaskId: changeSettings.task.id,
                            smartTableColumnId: changeSettings.dropColumn.model.id,
                            value: value
                        }
                        smartActionListService().updateCell(cell).then(function (savedCell) {
                            if (savedCell.value) {
                                savedCell.value = JSON.parse(savedCell.value);
                            }
                        });
                    },
                    sortTasks: function() {
                        this.tasks = $filter('orderBy')(this.tasks, 'status');
                    },
                };
                // Add tasks to column
                tasks.forEach(function (task) {
                    if (column.isPartOfColumn(task, column.property)) {
                        column.tasks.push(task)
                    }
                });
                // Sort by status
                column.sortTasks();
                return column;
            }

            $rootScope.subscribe('SmartActionListCellUpdated', function (cell) {
                // Is task part of board?
                var task = $filter('filter')(tasks, { id: cell.boardTaskId }, true);
                if (task.length > 0) {
                    task[0].animate("bounce");
                    columns.forEach(function (column) {
                        // Remove from previous column
                        if (column.propertyValue.title != cell.value.text && column.hasTask(task[0].id)) {
                            var index = column.tasks.indexOf(task[0]);
                            column.tasks.splice(index, 1);
                        }
                        // Add task to column
                        if (column.propertyValue.title == cell.value.text && !column.hasTask(task[0].id)) {
                            column.tasks.push(task[0]);
                        }
                    });
                }
            });

            return {
                getColumns: function (boardTasks, users, listColumn) {
                    var labels = listColumn.settings.predefinedLabels;
                    columns = [];
                    tasks = boardTasks;
                    var emptyLabel = {
                        isEmptyOrNull: true,
                        title: $translate.instant("COMMON_NOT_ASSIGNED"),
                    }
                    columns.push(createColumn(emptyLabel, listColumn, tasks));
                    labels.forEach(function (label) {
                        label.isEmptyOrNull = false;
                        columns.push(createColumn(label, listColumn, tasks));
                    })
                    return columns;
                }


            }



        }

        return predefinedLabelColumnProvider;
    }]);
