/* REMOVE THIS IF NOT USED*/
angular
    .module('DigiLean')
    .controller("breakthroughNewController", ['$scope', '$state', '$uibModalInstance', '$filter', '$translate','$rootScope', 'projectService', 'NgTableParams', 'existing',
        function ($scope, $state, $uibModalInstance, $filter, $translate,$rootScope, projectService, NgTableParams, existing) {

            $scope.existingProjects = existing;
            $scope.projects= [];
            $scope.selectedProject = {};
            $scope.projectExists = false;
            $scope.projectNameExists = false;
            
            $scope.newproject = {
                name: "",
                category: "StrategyBoard",
                projectNumber:"",
                color: ""
            };

            strategyService().getBreakthroughObjectives().then(function (projects) {
                $scope.projects = projects;
                
            });

            $scope.ok = function () {
                if(!exists()) {
                    $uibModalInstance.close($scope.newproject);
                }
            };

            $scope.okWithSameProjectName = function () {

                $uibModalInstance.close($scope.newproject);
            
            };
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.checkName = function () {
                if($scope.projectNameExists) {
                    if(!exists()){
                        $scope.projectNameExists = false;
                    }
                }
            };

            function exists(){
                var allreadyExists = false;
                $scope.projectExists = false;
                $scope.projectNameExists = false;
                for(var i=0; i<$scope.projects.length; i++ ){
                    if($scope.projects[i].projectNumber && $scope.newproject.projectNumber){
                        if($scope.projects[i].projectNumber.toUpperCase() === $scope.newproject.projectNumber.toUpperCase()){
                            $scope.projectExists = true;
                            return true;
                        }
                    }
                    if($scope.projects[i].name.toUpperCase() === $scope.newproject.name.toUpperCase()){
                        $scope.projectNameExists = true;
                        return true;
                    }
                    
                }
                $scope.projectExists = false;
                return allreadyExists;
            }
    

        }]);
