angular
    .module('DigiLean')
    .directive("smartCommonNumberCellAggregate", ['$filter', 'mathService',
        function ($filter, mathService) {
            return {
                templateUrl: 'smartCommonNumberCellAggregate.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                },
                link: function (scope, elem, attrs) {
                    var columnId = null;
                    scope.numberCellAggregateValue = null;
                    scope.aggregateType = "SUM";
                    var valuePerRow = [];
                    scope.decimals = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";

                    scope.$watch('model', function (model) {
                        columnId = model.id;
                        if (model && model.settings) {
                            var settings = model.settings;
                            if(settings.aggregateType){
                                scope.aggregateType = settings.aggregateType;
                            }
                            if(settings.dataSource && settings.dataSource.valueElement.unit == "%"){ //procent has to be calculated as average value
                                scope.aggregateType = "AVERAGE";
                            }
                            if(settings.unit){
                                scope.unit = settings.unit;
                                if(settings.unitPlacement){
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                            if(settings.decimals !== null || settings.decimals !== undefined){
                                scope.decimals = settings.decimals;
                            }
                        }
                        model.getSortValue = function () {
                            return scope.numberCellAggregateValue;
                        }

                    });

                    scope.subscribe("SmartCommonNumberCellUpdated", function (value) {
                        if(scope.type == value.type && columnId === value.columnId){
                            updateValue(value);
                        }
                    });

                        
                    scope.subscribe("SmartCommonNumberCellUnitUpdated", function (value) {
                        if(scope.type == value.type && columnId === value.columnId){
                            scope.unit = value.unit;
                        }
                    });
                    scope.subscribe("SmartCommonCellUnitPlacementUpdated", function (value) {
                        if(scope.type == value.type && columnId === value.columnId){
                            scope.unitPlacement = value.unitPlacement;
                        }
                    });
                    scope.subscribe("SmartCommonNumberCellDecimalUpdated", function (value) {
                        if(scope.type == value.type && columnId === value.columnId){
                            scope.decimals = value.decimals;
                            calculateAggregateValue();
                        }
                    });
                    scope.subscribe("SmartCommonNumberCellAggregateTypeUpdated", function (value) {
                        if(scope.type == value.type && columnId === value.columnId){
                            scope.aggregateType = value.aggregateType;
                            calculateAggregateValue();
                        }
                    });

                    scope.subscribe("ProjectDeleted", function (payLoad) {
                        if(scope.type == 'project'){
                            var existingValueForProject = $filter('filter')(valuePerRow, { projectId: payLoad.id });

                            if (existingValueForProject && existingValueForProject.length > 0) {
                                var index = valuePerRow.indexOf(existingSumForProject);
                                valuePerRow.splice(index, 1);
                                calculateAggregateValue();
                            }
                        }
                    });

                    scope.subscribe("A3Deleted", function (payLoad) {
                        if(scope.type == 'a3'){
                            var existingValue = $filter('filter')(valuePerRow, { a3Id: payLoad.id });

                            if (existingValue && existingValue.length > 0) {
                                var index = valuePerRow.indexOf(existingValue[0]);
                                valuePerRow.splice(index, 1);
                                calculateAggregateValue();
                            }
                        }
                    });


                    function updateValue(updatedValue) {
                        var existingValueForRow = $filter('filter')(valuePerRow, { rowId: updatedValue.rowId });
                        if (existingValueForRow && existingValueForRow.length > 0) {
                            var index = valuePerRow.indexOf(existingValueForRow[0]);
                            valuePerRow[index] = updatedValue;
                        } else {
                            valuePerRow.push(updatedValue);
                        }
                        calculateAggregateValue();
                    }

                    function calculateAggregateValue() {
                        scope.numberCellAggregateValue = 0;
                        var noOfRowCellWithValue = 0;
                        for (var i = 0; i < valuePerRow.length; i++) {
                            if (valuePerRow[i].value) {
                                scope.numberCellAggregateValue = scope.numberCellAggregateValue + valuePerRow[i].value;
                                noOfRowCellWithValue = noOfRowCellWithValue + 1;
                            }
                        }
                        if (scope.aggregateType === "AVERAGE" && noOfRowCellWithValue > 0) {
                            scope.numberCellAggregateValue = scope.numberCellAggregateValue / noOfRowCellWithValue;
                        }

                        if(scope.decimals !== null) {
                            if(scope.decimals !== undefined) {
                                scope.numberCellAggregateValue = mathService().roundNumber(scope.numberCellAggregateValue, scope.decimals);
                            }
                        }
                    }
                }
            }
        }]);
