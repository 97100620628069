import angular from "angular"
const DigiLean = angular.module('DigiLean')
DigiLean.controller('DiagramEditorFullscreenController', ['$scope', '$uibModalInstance', 'data', 'title', 'showTemplates',
    function ($scope, $uibModalInstance, data, title, showTemplates) {
        
        $scope.title = title
        $scope.data = data
        $scope.ready = true
        $scope.show = !!showTemplates

        $scope.saveAndExit = function(event) {
            const orgEvt = event.originalEvent
            const newSvg = orgEvt.detail
            $uibModalInstance.close(newSvg)
        }
        $scope.exitNoSave = function(event) {
            $uibModalInstance.close()
        }

        let editorEl = document.querySelector("digilean-diagram-editor")
        $scope.save = function () {
            if (!editorEl)
                editorEl = document.querySelector("digilean-diagram-editor")
            ///@ts-ignore
            editorEl.export()
        }
        $scope.showTemplates = function() {
            if (!editorEl)
                editorEl = document.querySelector("digilean-diagram-editor")
            ///@ts-ignore
            editorEl.showTemplateDialog()
        }
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }
    }])