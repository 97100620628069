var DigiLean = angular.module('DigiLean');
DigiLean.directive("deviationTypeCauses", ['$filter', 'deviationService',
        function ($filter, deviationService) {
            return {
                templateUrl: 'deviationTypeCauses.html',
                restrict: 'E',
                scope: {
                    'deviationTypeId': '=',
                    'deviationTypeTitle': '=',
                },
                link: function (scope, elem, attrs) {

                    scope.causes = [];

                    scope.$watch("deviationTypeId", function () {
                        if (!scope.deviationTypeId) return;
                        loadSettings();
                    });
                    
                    function loadSettings(){
                        scope.deviationType = {title: "RUH"};
                        deviationService().getCauses().then(function (causes) {
                            scope.causes = causes;
                            deviationService().getCausesForType(scope.deviationTypeId).then(function(validcauses) {                           
                                var validCausesForType = validcauses;
                                for(var i=0; i<scope.causes.length; i++){
                                    scope.causes[i].show = checkShow(scope.causes[i].id, validCausesForType);
                                }
                            });
                        });
                    }

                    function checkShow(itemId, validItems){
                        var matching = $filter('filter')(validItems, { problemReasonId: itemId }, true);
                        if (matching.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }  

                    scope.toggleShow = function (item) {
                        item.problemReasonId = item.id;
                        if(item.show){
                            item.deviationTypeId = scope.deviationTypeId;
                            deviationService().addDeviationTypeCause(item).then(function() {                                                  
                            });
                        } else {
                            if(item.id){
                                deviationService().deleteDeviationTypeCause(item.id).then(function() {                                                  
                                });
                            }
                        }
                    };

                }

            }
        }]);
