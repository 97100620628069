angular
    .module('DigiLean').directive("projectLinks", ['projectService',
        function (projectService) {
            return {
                templateUrl: 'projectLinks.html',
                restrict: 'E',
                scope: {
                    'projectId': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.uploadedFiles = [];
                    scope.files = [];
                    scope.isLoading = true;
                    scope.toogleEditModeOn = true;
                    if(scope.isEditMode){
                        scope.toogleEditModeOn = false;
                    }

                    scope.title = "";
                    scope.url = "";

                    scope.$watch("projectId", function (projectId) {
                        if (!projectId) return;
                        projectService().getLinks(projectId).then(function(links) {
                            scope.links = links;
                            scope.isLoading = false;
                        });
                    });

                    scope.deleteLink = function (link) {
                        var i = scope.links.indexOf(link);
                        scope.links.splice(i, 1);
                        projectService().deleteLink(scope.projectId, link.id).then(function() {

                        });
                    }

                    function isValid(){
                        scope.notValidTitle = false;
                        scope.notValidUrl = false;
                        if(scope.title == ""){
                            scope.notValidTitle = true;
                        }
                        if(scope.url == ""){
                            scope.notValidUrl = true;
                        }

                        if(scope.notValidTitle || scope.notValidUrl){
                            return false;
                        } else return true;
                    }

                    scope.addLink = function () {
                        if(isValid()){
                            var link = {
                                title:scope.title,
                                url : scope.url
                            }
                            projectService().addLink(scope.projectId, link).then(function(stored) {
                                scope.isEditMode = false;
                                scope.title = "";
                                scope.url = "";
                                scope.links.push(stored);
                            });
                        }
                    };
                    scope.toggleEditMode = function () {
                        scope.isEditMode = !scope.isEditMode ;
                    };

                }

            }
        }]);
