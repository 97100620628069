angular
    .module('DigiLean')
    .controller("assetSelectorModalController", ['$scope', '$filter', '$uibModalInstance', 'filters',
        function ($scope, $filter, $uibModalInstance, filters) {

            $scope.filters = filters;
            
            $scope.updateFilter = function (updatedSourceColumn, filters) {
                var hasFilter = $filter('filter')($scope.filters, { sourceColumn: updatedSourceColumn });
                // Remove existing filter connected to sourceColumn
                if (hasFilter && hasFilter.length > 0) {
                    // Remove existing filter
                    angular.forEach(hasFilter, function (filter, key) {
                        var index = $scope.filters.indexOf(hasFilter[key]);
                        $scope.filters.splice(index, 1);
                    });
                }
                angular.forEach(filters, function (filter) {
                    if (filter) {
                        if($scope.filters === undefined || !$scope.filters){
                            $scope.filters = [];
                        }
                        $scope.filters.push(filter);
                    }
                });
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.filters);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
