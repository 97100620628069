﻿    angular.module("DigiLean").factory('languageAdminService', ['$http', 'webApiUrl','$translate',
  function ($http, webApiUrl,$translate) {
      function languageAdminService() {
          var resource = "/languageAdmin";
          var resourceUrl = webApiUrl + resource;
          return {
              getSupportedLanguages: function(){
                  var languages = [
                        {languageCode: 'de',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_GERMAN'),
                        nativeLabel: 'Deutsch'
                        },
                        {languageCode: 'en',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_ENGLISH'),
                        nativeLabel: 'English'
                        },
                        {languageCode: 'es',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_SPANISH'),
                        nativeLabel: 'Español'
                        },                
                        {languageCode: 'fr',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_FRENCH'),
                        nativeLabel: 'Français'
                        },
                        {languageCode: 'it',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_ITALIAN'),
                        nativeLabel: 'Italiano'
                        },
                        {languageCode: 'no',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_NORWEGIAN'),
                        nativeLabel: 'Norsk'
                        },
                        {languageCode: 'sv',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_SWEDISH'),
                        nativeLabel: 'Svenska'
                        },
                        {languageCode: 'tr',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_TURKISH'),
                        nativeLabel: 'Türkçe'
                        },
                        {languageCode: 'pl',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_POLISH'),
                        nativeLabel: 'Polski'
                        },
                        {languageCode: 'hu',
                        languageLabel: $translate.instant('COMMON_LANGUAGE_HUNGARIAN'),
                        nativeLabel: 'Magyar'
                        }
                  ]
                  return languages;
              },
              getList: function(useCache) {   
                if (!useCache) useCache = false;
                  return $http.get(resourceUrl, { cache: useCache}).then(function (result) {
                      return result.data;
                    });
              },
              translate: function (options) {
                var url = resourceUrl + "/translate/";
                return $http.post(url, options).then(function (result) {
                    return result.data;
                });
            },
              add: function (label) {
                  return $http.post(resourceUrl, label).then(function (result) {
                      return result.data;
                  });
              },
              update: function (label) {
                  return $http.put(resourceUrl, label).then(function (result) {
                      return result.data;
                  });
              },
              delete: function (id) {
                  var url = resourceUrl + "/delete/" + id;
                  return $http.delete(url).then(function (result) {
                      return result.data;
                  });
              },
          }
      }
      return languageAdminService;
  }]);