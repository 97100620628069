angular
    .module('DigiLean')
    .directive("smartPersonCell", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'smartPersonCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.$watch('model', function (model) {
                        var value = null;
                        if (scope.type == 'project') {
                            value = model.projectAttribute.value;
                        } else if (scope.type == 'a3') {
                            if(model.a3TableCell){
                                value = model.a3TableCell.value;
                            }             
                        }
                        if (value) {
                            setValue(value);
                            mapUser();
                        }
                        model.getSortValue = function () {
                            if (scope.user) {
                                return scope.user.fullName;
                            }
                            return "";
                        }
                    });

                    var setValue = function (value) {
                        scope.displayName = value.displayName;
                        scope.user = value;
                    }
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });

                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.column.id) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                                mapUser();
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });

                    scope.$watch("users", function (users) {
                        if (!users) return;
                        mapUser();
                    });

                    var mapUser = function() {
                        if (scope.user && scope.users) {
                            var matchingUser = scope.users.find(u => u.userId == scope.user.userId)
                            if (matchingUser) {
                                scope.displayName = matchingUser.ownerDisplayName
                                scope.user = matchingUser
                            }
                        }
                    }
                    scope.clearSelectedUser = function () {
                        scope.selectUser(null);
                        
                    };
                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.model.user = user;

                        var simpelUser = {userId:user.userId,
                            displayName: user.displayName,
                            fullName:user.fullName
                        };
                        
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = simpelUser;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = simpelUser;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: scope.model.column.id,
                                   a3Id: scope.model.a3.id,
                                   value: simpelUser
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    };
                }
            }
        }]);