import { cloneDeep } from "lodash"
import { getCompanySetting } from "@common/stores/companySettingsStore"

angular
    .module('DigiLean')
    .controller("a3designerController", ['$scope', '$translate', '$stateParams', '$state', '$uibModal', 'languageAdminService', 'a3templateService', 'a3Service', 'debounce', 'authService',
        function ($scope, $translate, $stateParams, $state, $uibModal, languageAdminService, a3templateService, a3Service, debounce, authService) {

            $scope.templateId = parseInt($stateParams.id)
            $scope.selectedAsset = null
            $scope.categories = {}
            $scope.selectedCategory = {}
            $scope.languages = languageAdminService().getSupportedLanguages()
            $scope.selectedLanguage = $scope.languages[0]

            $scope.isKaizenAdmin = authService.hasRole('KaizenAdmin')
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.isKaizenAdmin = authService.hasRole('KaizenAdmin')
            })
            // Default colors
            $scope.background = "#1ab394"
            $scope.color = "#ffffff"
            $scope.defaultStyle = { 'background': $scope.background, 'color': $scope.color }

            $scope.froalaOptions = {
                toolbarButtons: ['bold', 'italic', 'underline',
                    '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle',
                    '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertImage', 'insertVideo', 'insertTable', 'insertDiagram',
                    '|', 'emoticons', 'insertHR', 'clearFormatting',
                    '|', 'help', '|', 'undo', 'redo'],
                
                imageEditButtons: ['editDiagram', 'imageReplace', 'imageAlign', 'imageCaption', 'imageRemove']
            }
            $scope.contentChanged = function (val) {
                $scope.selectedStep.defaultContent = val
                $scope.stepChangesDebounced($scope.selectedStep) // we will debounce save so we don't save too often.
            }

            $scope.publishTemplate = function () {
                a3templateService().publishTemplate($scope.templateId).then(function (publishedTemplate) {
                    $scope.template.publishStatus = publishedTemplate.publishStatus
                })
            }
            $scope.unPublishTemplate = function () {
                a3templateService().unPublishTemplate($scope.templateId).then(function (publishedTemplate) {
                    $scope.template.publishStatus = publishedTemplate.publishStatus
                })
            }

            function setSelectedLang() {
                let selectedLang = $scope.languages.find(l => l.languageCode == $scope.template.language)
                $scope.selectedLanguage = selectedLang
            }

            function loadTemplate() {
                a3templateService().get($scope.templateId).then(function (template) {
                    $scope.template = template
                    
                    if (!template.language) {
                        getCompanySetting("language").then(function (setting) {
                            if (setting) {
                                $scope.template.language = setting
                                setSelectedLang()
                            }
                        })
                    } else {
                        setSelectedLang()
                    }

                    a3Service().getCategories().then(function (categories) {
                        $scope.categories = categories
                        if (template.categoryId) {
                            let selectedCat = $scope.categories.find(c => c.id == template.categoryId)
                            $scope.selectedCategory = selectedCat
                        }
                    })
                    // style
                    if (template.style) {
                        var templateStyle = JSON.parse(template.style)
                        $scope.background = templateStyle.background
                        $scope.color = templateStyle.color
                    }

                    template.steps = template.steps.filter(x => !x.isProjectInfo)

                    if (template.steps.length > 0) {
                        // Parse style settings
                        for (var i = 0; i < template.steps.length; i++) {
                            var step = template.steps[i]
                            var style = step.style
                            if (style) {
                                var parsedStyle = JSON.parse(step.style)
                                step.background = parsedStyle.background
                                step.color = parsedStyle.color
                            } else {
                                step.background = $scope.background
                                step.color = $scope.color
                            }
                        }
                        $scope.editStep(template.steps[0])
                    }
                })
            }

            loadTemplate()
            // Template changes
            function commitChanges() {
                // Set style
                var templateStyle = {
                    'background': $scope.background,
                    'color': $scope.color
                }
                $scope.template.style = JSON.stringify(templateStyle)
                var template = $scope.template
                var itemToSave = {
                    id: template.id,
                    name: template.name,
                    language: template.language,
                    categoryId: template.categoryId,
                    style: template.style,
                    description: template.description,
                    includeProjectInfo: template.includeProjectInfo
                }
                a3templateService().updateTemplate(itemToSave).then(function (template) {
                    $scope.publish("SendA3TemplateUpdated", template)
                })
            }

            $scope.changesDebounced = debounce(commitChanges, 1000, false)
            $scope.saveTemplateInfo = function () {
                $scope.changesDebounced()
            }


            function commitStepChanges(step) {
                var templateStyle = {
                    'background': step.background,
                    'color': step.color
                }

                var itemToSave = {
                    id: step.id,
                    name: step.name,
                    isActionList: step.isActionList,
                    style: JSON.stringify(templateStyle),
                    description: step.description,
                    defaultContent: step.defaultContent,
                    a3TemplateId: step.a3TemplateId
                }
                a3templateService().updateStep(itemToSave).then(function (step) {
                    // Not publishing event for now, too "chatty"
                })
            }
            $scope.stepChangesDebounced = debounce(commitStepChanges, 1000, false)

            $scope.languageChanged = function (lang) {
                $scope.template.language = lang.languageCode
                setSelectedLang()
                $scope.saveTemplateInfo()
            }

            $scope.categoryChanged = function (category) {
                $scope.template.categoryId = category.id
                $scope.saveTemplateInfo()
            }

            $scope.templateColorChanged = function () {
                $scope.defaultStyle = { 'background': $scope.background, 'color': $scope.color, 'opacity': 0.3 }
                for (var i = 0; i < $scope.template.steps.length; i++) {
                    var step = $scope.template.steps[i]
                    if (step.id === $scope.selectedStep.id) {
                        step.style = { 'background': $scope.background, 'color': $scope.color }
                    } else {
                        step.style = $scope.defaultStyle
                    }
                    step.background = $scope.background
                    step.color = $scope.color
                    commitStepChanges(step)
                }
                $scope.saveTemplateInfo()
            }

            $scope.updateColors = function () {
                for (var i = 0; i < $scope.template.steps.length; i++) {
                    var step = $scope.template.steps[i]
                    if (step.id === $scope.selectedStep.id) {
                        step.style = { 'background': step.background, 'color': step.color }
                    } else {
                        step.style = { 'background': step.background, 'color': step.color, 'opacity': 0.6 }
                    }
                }
            }

            $scope.colorChanged = function (step) {
                $scope.updateColors()
                $scope.stepChangesDebounced(step)
            }


            $scope.stepsSortableOptions = {
                clone: false,
                accept: function (sourceItemHandleScope, destSortableScope) {
                    return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id
                },
                orderChanged: function (event) {
                    updateSortOrder()
                }
            }

            function updateSortOrder() {
                var sortOrders = []
                angular.forEach($scope.template.steps, function (row, key) {
                    row.sortOrder = key + 1
                    var sortOrder = { id: row.id, sortOrder: row.sortOrder }
                    sortOrders.push(sortOrder)
                })
                a3templateService().updateSortOrder($scope.template.id, sortOrders).then(function () {
                    SendA3TemplateUpdated()
                })
            }

            $scope.editStep = function (step) {
                $scope.selectedStep = step
                $scope.stepContent = step.defaultContent
                $scope.updateColors()
            }

            $scope.delete = function (step) {
                a3templateService().deleteStep(step.id).then(function () {
                    var rowindex = $scope.template.steps.indexOf(step)
                    $scope.template.steps.splice(rowindex, 1)
                    updateSortOrder()
                    if ($scope.template.steps.length > 0) {
                        $scope.editStep($scope.template.steps[0])
                    }
                    SendA3TemplateUpdated()
                })
            }

            function SendA3TemplateUpdated() {
                let template = cloneDeep($scope.template)
                template.steps = template.steps.map(step => {
                    if (typeof step.style !== "string")
                        step.style = JSON.stringify(step.style)
                    return step
                })
                $scope.publish("SendA3TemplateUpdated", template)
            }
            $scope.addStep = function () {
                var templateStyle = {
                    'background': $scope.background,
                    'color': $scope.color
                }
                var step = {
                    sortorder: $scope.template.steps.length,
                    name: $translate.instant('A3_NEW_STEP'),
                    isActionList: false,
                    a3templateId: $scope.template.id,
                    style: JSON.stringify(templateStyle)
                }
                a3templateService().addStep($scope.template.id, step).then(function (step) {
                    $scope.template.steps.push(step)
                    step.background = $scope.background
                    step.color = $scope.color
                    updateSortOrder()
                    $scope.editStep(step)
                    SendA3TemplateUpdated()
                })
            }

            $scope.newA3FromTemplate = function () {
                var categoryId = ""
                var assetId = ""
                if ($scope.template.categoryId) {
                    categoryId = $scope.template.categoryId
                }
                if ($scope.template.assetId) {
                    assetId = $scope.template.assetId
                }
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newA3Form.html',
                    controller: 'newA3ModalController',
                    resolve: {
                        a3: function () {
                            return {
                                id: 0,
                                title: "",
                                assetId: assetId,
                                categoryId: categoryId,
                                goal: "",
                                templateId: $scope.template.id
                            }
                        }
                    }
                })
            }

            $scope.layout = function () {
                $state.go('a3templateDesigner', { id: $scope.templateId }, { reload: false })
                return
            }

            $('.note-editable').css('font-size', '14px')

        }])