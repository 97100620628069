import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("smartActionlistCard", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'smartActionlistCard.html',
                restrict: 'E',
                scope: {
                    "board" : "<",
                },
                link: function (scope, elem, attrs) {
                    scope.model = null;
                    scope.isLoading = true;
                    scope.previewUrl = staticFileService.image("smartActionlistCard.jpg");
                    scope.$watch('board', function (board) {
                        if (!board) return;
                        if (!board.name) {
                            board.name = $translate.instant("BOARD_SMARTACTIONLIST");
                        }
                    });
                }
            };
        }]);
