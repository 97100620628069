/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MonitorState {
    Success = 'success',
    RecentError = 'recentError',
    RecentNoRun = 'recentNoRun',
    NoRun = 'noRun',
}
