angular.module("DigiLean").service('modulesService', ['$http', 'webApiUrl', '$translate',
    function ($http, webApiUrl, $translate) {
        const allModulesConfig = [
            {
                name:"BOARD",
                title:"COMMON_BOARD",
                icon: "fal fa-desktop",
                desc: $translate.instant('COMMON_BOARDS'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name:"IMPROVEMENT",
                title: "COMMON_IMPROVEMENT",
                icon: "fal fa-lightbulb",
                desc: $translate.instant('COMMON_IMPROVEMENT_DESC'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name:"DEVIATION",
                title: "COMMON_DEVIATION",
                icon: "fa fa-exclamation-triangle",
                desc: $translate.instant('COMMON_DEVIATION_DESC'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name:"PROJECT",
                title: "COMMON_PROJECT",
                icon: "far fa-calendar-alt",
                desc: $translate.instant('COMMON_PROJECT_DESC'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name:"A3",
                title: "COMMON_A3",
                icon: "far fa-file",
                desc: $translate.instant('COMMON_A3_DESC'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name:"STRATEGY",
                title: "STRATEGY_STRATEGY",
                icon: "fas fa-rocket",
                desc: $translate.instant(''),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name:"LEARNING",
                title: "LEARNING_LEARNING_CENTER",
                icon: "fas fa-graduation-cap",
                desc: $translate.instant('COMMON_LEARNING_DESC'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_MODULES_OVERVIEW" }
            },
            {
                name: "COMPETENCEBALL",
                title: "NAV_COMPETENCE_BALL",
                icon: "fal fa-bowling-ball",
                desc: $translate.instant('NAV_COMPETENCE_BALL_CONFIGURATION'),
                tooltip: $translate.instant('COMMON_CLICK_INFO'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_COMPETENCEBALL_MODULE" }
            },
            {
                name: "HOURREGISTRATION",
                title: "COMMON_HOUR_REGISTRATION",
                icon: "fal fa-clock",
                desc: $translate.instant(''),
                tooltip: $translate.instant('COMMON_HOUR_REGISTRATION'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_HOURREGISTRATION_MODULE" }
            },
            {
                name: "TEAMSPACE",
                title: "COMMON_TEAM_SPACE",
                icon: "fal fa-users",
                desc:$translate.instant('ADMIN_EXTRA_FEATURE_TEAM_SPACE_DESC'),
                tooltip: $translate.instant('ADMIN_EXTRA_FEATURE_TEAM_SPACE_DESC'),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_TEAMSPACE_MODULE" }
            },
            {
                name: "AZUREAD",
                title: "COMMON_AZURE_AD",
                icon: "fab fa-windows",
                desc: $translate.instant("ADMIN_EXTRA_FEATURE_AZUREAD_DESC"),
                tooltip: $translate.instant("ADMIN_EXTRA_FEATURE_AZUREAD_DESC"),
                startUsing: $translate.instant('ADMIN_EXTRA_FEATURE_START_USING'),
                learning: { location: "external", featureKey: "HELP_AZUREAD_MODULE" }
            },
            {
                name: "LEARNINGFROMBJARNE",
                title: "not supported anymore?"
            }
        ]

        function getCustomerAllowedModules() {
            const url = `${resourceUrl}/getCustomerAllowedModules`
            return $http.get(url).then(function (result) {
                return result.data
            })
        }
        const resourceUrl = webApiUrl + "/CustomerModule"
        return {
            getAllowedModulesConfig: function() {
                return getCustomerAllowedModules().then(allowed => {
                    const allowedModules = allowed.map(m => {
                        const config = allModulesConfig.find(a => a.name == m.name)
                        if (config) {
                            m.title = config.title
                            m.icon = config.icon
                            m.desc = config.desc
                            m.tooltip = config.tooltip
                            m.startUsing = config.startUsing
                            m.learning = config.learning
                            return m
                        }
                        
                    }).reduce((list, module) => {
                        if (module)
                            list.push(module)
                        return list
                    },[])
                    return allowedModules
                })
            },
            
            
            getModulesSetupForKaizenAdmin: function (customerId) {
                const url = resourceUrl + "/getModulesSetupForKaizenAdmin/" + customerId
                    return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            
            //kaizenadmin allow
            allowModule: function(customerId, feature) {
                const url = `${resourceUrl}/allowModule/${customerId}/${feature}`
                return $http.put(url).then(function (result) {
                    return result.data
                })
            },
            //kaizenadmin disallow
            disallowModule: function(customerId, feature) {
                const url = `${resourceUrl}/disallowModule/${customerId}/${feature}`
                return $http.delete(url).then(function (result) {
                    return result.data
                })
            },
            getCustomerAllowedModules: getCustomerAllowedModules,
            getCustomerAllowedModulesForCustomer: function(customerId) {
                const url = `${resourceUrl}/getCustomerAllowedModules/${customerId}`
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            // GetCustomerEnabledModules - not sure if is in use
            getCustomerEnabledModules: function () {
                var cache = moduleCache.get('customerModules');
                if (cache) { // If there’s something in the cache, use it!
                    return new Promise(function (resolve, reject) {
                        resolve(cache);
                    })
                }
                const url = resourceUrl + "/getCustomerEnabledModules"
                return $http.get(url).then(function (result) {
                    moduleCache.put('customerModules', result.data)
                    return result.data
                })
            },
            enableModule: function (feature) {
                const url = resourceUrl + "/enableModule/" + feature;
                return $http.put(url).then(function (result) {
                    return result.data
                })
            },
            disableModule: function (feature) {
                const url = resourceUrl + "/disableModule/" + feature;
                return $http.delete(url).then(function (result) {
                    return result.data
                })
            }
        }
    }
])