
angular
    .module('DigiLean')
    .directive("updatedResponsibleSuggestionMessage", ['$uibModal', 'suggestionService',
        function ($uibModal, suggestionService) {
            return {
                templateUrl: 'updatedResponsibleSuggestionMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.suggestion = null;
                    scope.isLoading = true;

                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        suggestionService().get(payLoad.SuggestionId).then(function (suggestion) {
                            suggestion.dateStyleClass = getDateStyle(suggestion.dueDate);
                            scope.suggestion = suggestion;
                        });
                    });

                    function getDateStyle(date) {
                        if (!date) return "";
                        // Overdue check

                        var overdue = "";
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            overdue = "overdue";
                        }
                        return overdue;
                    }
                    scope.openSuggestion = function (activeTab) {
                        if (!activeTab) activeTab = "Info";

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'fullImprovementInfoForm.html',
                            controller: 'fullImprovementModalController',
                            windowClass: 'fullSuggestion-modal-window',
                            resolve: {
                                suggestion: function () {
                                    return scope.suggestion;
                                },
                                activeTab: function () {
                                    return activeTab;
                                }
                            }
                        });

                    }
                }
            }
        }]);