import * as systemHubService from "@common/services/systemHubService"
import * as customerService from "@common/services/customers/customerService"

var DigiLean = angular.module('DigiLean')
DigiLean.controller('customerMonitoringController', ['$scope', '$element', '$filter', 'authService',
    function ($scope, $element, $filter, authService) {

        $scope.title = 'Users online';

        $scope.customerEvents = [];
        $scope.customersOnline = [];
        $scope.customers = [];
        $scope.totalConnections = 0;
        $scope.usersOnline = 0;
        $scope.totalUsersOnline = 0;
        $scope.totalTeamsUsersOnline = 0;
        $scope.showClients = function (customer) {
            customer.showClients = !customer.showClients;
        }
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            if (!authService.hasRole("KaizenAdmin")) return;
            loadData();
        });


        function loadData() {
            // This is only for Kaizen so return if not in role.
            if (!authService.hasRole("KaizenAdmin")) return;
            customerService.getCustomersSimpleForAdmin().then(function (customers) {
                angular.forEach(customers, function (item, key) {
                    var currentCustomer = {
                        customerId: item.id,
                        name: item.name,
                        clients: [],
                        cssState: ""
                    }
                    $scope.customers.push(currentCustomer)
                })
                systemHubService.getAllUsersOnlineRest().then(function(users) {
                    handleAllUsersOnline(users)
                })
            })
        }
        

        loadData();

        var hasLoadedAllUsers = false;
        $scope.subscribe("CustomerUsersConnectedChanged", function (customer) {
            if (!hasLoadedAllUsers) {
                $scope.publish("GetAllUsersOnline");
                hasLoadedAllUsers = true;
            }
            updateCustomerOnlineCount(customer);
            $scope.$apply();
            $scope.publish("GetOnlineUsersStatus");
        });

        $scope.translationData = {
            totalUsersOnline: $scope.totalUsersOnline
        };

        $scope.subscribe("OnlineUsersStatus", function (usersOnline) {
            $scope.totalConnections = usersOnline.totalNumberOfConnections;
        });
        $scope.publish("GetOnlineUsersStatus");

        $scope.subscribe("AllUsersOnline", function (usersOnline) {
            handleAllUsersOnline(usersOnline)
            $scope.$apply()
        })

        function handleAllUsersOnline(users) {
            angular.forEach(users, function (companyOnline, key) {
                updateCustomerOnlineCount(companyOnline)
            })
        }
        var maxClients = 2;
        function updateCustomerOnlineCount(customerOnline) {
            var customer = $filter('filter')($scope.customers, { customerId: customerOnline.customerId }, true);
            
            if (customer.length > 0) {
                customer[0].teamsClients = 0;
                customer[0].clients = customerOnline.clients;
                customer[0].clients.forEach(client => {
                    if (client.source == "teams") {
                        customer[0].teamsClients += 1;
                    }
                });
                customer[0].cssState = "animated bounce";
            }
            $scope.customersOnline = $filter('filter')($scope.customers, function (customer) {
                return customer.clients.length > 0;
            });
            $scope.customersOnline.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return -1
                }
            })
            updateTotalUsersOnline();
            setCustomerProgressCount();
        }

        function setCustomerProgressCount() {
            angular.forEach($scope.customers, function (customer, key) {

                customer.progress = customer.clients.length / maxClients * 100;
                customer.progressBackground = "#0099DE";
                customer.progressBorderColor = "transparent"
            });

        };

        function updateTotalUsersOnline() {
            var count = 0;
            var teamsCount = 0;
            var allClients = [];
            angular.forEach($scope.customers, function (customer, key) {
                count += customer.clients.length;
                if (customer.clients.length > maxClients) {
                    maxClients = customer.clients.length;
                }
                customer.clients.forEach(client => {
                    if (client.source == "teams") {
                        teamsCount += 1;
                    }
                    allClients.push(client);
                });
            });
            var uniqueUsers = _.countBy(allClients, "userName");
            $scope.usersOnline = Object.keys(uniqueUsers).length;
            $scope.totalUsersOnline = count;
            $scope.totalTeamsUsersOnline = teamsCount;
            $scope.translationData.totalUsersOnline = count;
        }

        let intervalId = 0
        var chart;
        function loadChart() {
            var renderElement = $($element).find(".liveCustomerChart")[0];
            chart = new Highcharts.Chart({
                chart: {
                    renderTo: renderElement,
                    defaultSeriesType: 'spline'
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false //remove link to highcharts.com
                },
                exporting: {
                    enabled: false //remove context menu
                },
                xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 150,
                    maxZoom: 20 * 1000
                },
                yAxis: {
                    minPadding: 0.2,
                    maxPadding: 0.2,
                    title: {
                        text: ''
                    }
                },
                series: [{
                    name: 'Users online',
                    data: []
                }]
            });

            if (intervalId)
                return
            // Timer that updates the chart
            intervalId = setInterval(function () {
                var series = chart.series[0],
                    shift = series.data.length > 1000; // shift if the series is 
                // longer than 20
                var point = [new Date().getTime(), $scope.totalUsersOnline];
                // add the point
                chart.series[0].addPoint(point, true, shift);
            }, 5000);
        };

        loadChart();
        $scope.$on("$destroy", () => {
            clearInterval(intervalId)
        })
    }]);