import * as staticFileService from "@common/shared/staticFileService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("smartActionListPreview",
        function () {
            return {
                templateUrl: 'smartActionListPreview.html',
                restrict: 'E',
                scope: {
                    template: '=',
                },
                link: function (scope, elem, attrs) {
                    
 //                   scope.imageUrl = staticFileService.image("smartActionListPreview.jpg");
                    scope.imageUrl = staticFileService.image("smartactionlist.gif");
                    scope.showImage = false;
                    scope.$watch('template', function (template) {
                        
                        if (template && template.id) {
                            boardService.getTemplate(template.id).then(function(model) {
                                scope.model = model;
                                if(model.tasks.length == 0){
                                    model.tasks[0]= {};
                                }
                            })
                        } else {
                            scope.showImage = true;
                        }
                    });
                }
            }
        });
