angular
    .module('DigiLean')
    .controller("a3SelectorController", ['$scope', '$state', '$uibModalInstance', '$filter', '$translate', '$rootScope', 'a3Service', 'NgTableParams', 'existing',
        function ($scope, $state, $uibModalInstance, $filter, $translate, $rootScope, a3Service, NgTableParams, existing) {

            $scope.existingA3s = existing;
            $scope.a3s = [];
            $scope.selectedA3 = {};
            a3Service().getA3s().then(function (a3s) {
                $scope.allA3s = a3s;
                $scope.a3s = a3s;
                if ($scope.existingA3s && $scope.existingA3s.length > 0) {
                    for (var e = 0; e < $scope.existingA3s.length; e++) {
                        var filtered = $scope.a3s.filter(function (value, index, arr) {
                            return value.id !== $scope.existingA3s[e].id;
                        });
                        $scope.a3s = filtered;
                    }
                }

                $scope.tableParams = new NgTableParams({
                }, 
                {
                    dataset: $scope.a3s
                });
            });



            $scope.ok = function (selected) {
                $uibModalInstance.close(selected);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.newPieChart = {
                data: [0, 0, 0, 0],
                options: {
                    radius: 18,
                    fill: ["#f3f3f4"],
                }
            };
            $scope.planPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#f3f3f4", "#f3f3f4", "#f3f3f4"],
                }
            };
            $scope.doPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#f3f3f4", "#f3f3f4"],
                }
            };
            $scope.checkPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#f3f3f4"],
                }
            };
            $scope.actPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"]
                }
            };

            $scope.statusOptions = [
                {
                    statusDB: "New",
                    translated: $translate.instant('COMMON_NEW'),
                    cssClass: "new",
                    filteredAndInResultList: false,
                    pie: $scope.newPieChart
                }, {
                    statusDB: "Plan",
                    translated: $translate.instant('A3_PLAN'),
                    cssClass: "plan",
                    filteredAndInResultList: false,
                    pie: $scope.planPieChart
                }, {
                    statusDB: "Do",
                    translated: $translate.instant('COMMON_DO'),
                    cssClass: "do",
                    filteredAndInResultList: false,
                    pie: $scope.doPieChart
                }, {
                    statusDB: "Check",
                    translated: $translate.instant('COMMON_CHECK'),
                    cssClass: "check",
                    filteredAndInResultList: false,
                    pie: $scope.checkPieChart
                }, {
                    statusDB: "Act",
                    translated: $translate.instant('COMMON_ACT'),
                    cssClass: "act",
                    filteredAndInResultList: false,
                    pie: $scope.actPieChart
                }, {
                    statusDB: "Closed",
                    translated: $translate.instant('DEVIATION_CLOSED'),
                    filteredAndInResultList: false
                }
            ];
        }]);
