angular
    .module('DigiLean')
    .directive("projectTargetCell", ['$filter', '$translate','navigationService',
        function ($filter, $translate, navigationService) {
            return {
                templateUrl: 'projectTargetCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.value = 0;
                    scope.subscribe("UserAuthenticatedAndReady", function(profile)
                    {
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");   
                    });
                    scope.$watch("model", function (model) {
                        var value = scope.model.projectAttribute.value;
                        if (value) {
                            scope.value = value.value;
                        }
                        model.getSortValue = function () {
                            return scope.value;
                        }
                    });
         
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                scope.value = attribute.value.value;
                            }
                        }
                    });

                    scope.setStatusValue = function (newValue) {
                        scope.value = newValue;
                        scope.model.projectAttribute.value = {
                            value: scope.value
                        };
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})

                    }

                    function addDeviation(statusValue) {
                        var severity = DigiLean.hms.getDeviationSeverity(statusValue);
                        // Make sure task responsbile dropdown is closed if open.
                        var settings = {
                            projectId: scope.projectId,
                            severity: severity
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    }
                }
            }
        }]);
