angular
    .module('DigiLean')
    .controller("projectSelectorController", ['$scope', '$state', '$uibModalInstance', '$filter', '$translate', '$rootScope', 'projectService', 'NgTableParams', 'existing',
        function ($scope, $state, $uibModalInstance, $filter, $translate, $rootScope, projectService, NgTableParams, existing) {

            $scope.existingProjects = existing;
            $scope.projects = [];
            $scope.selectedProject = {};
            projectService().getList().then(function (projects) {
                $scope.projects = projects;
                if ($scope.existingProjects && $scope.existingProjects.length > 0) {
                    for (var e = 0; e < $scope.existingProjects.length; e++) {
                        var filtered = $scope.projects.filter(function (value, index, arr) {
                            return value.id !== $scope.existingProjects[e].id;
                        });
                        $scope.projects = filtered;
                    }
                }

                $scope.tableParams = new NgTableParams({
                }, 
                {
                    dataset: $scope.projects
                });
            });



            $scope.ok = function (selectedProject) {
                $uibModalInstance.close(selectedProject);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };


            $translate(['COMMON_ALL', 'COMMON_NEW', 'COMMON_IN_PROGRESS', 'IMPROVEMENT_IMPLEMENTED', 'IMPROVEMENT_EVALUATED']).then(function (translations) {
                $scope.status = [
                    { id: 'All', name: translations.COMMON_ALL },
                    { id: 'New', name: translations.COMMON_NEW },
                    { id: 'In Progress', name: translations.COMMON_IN_PROGRESS },
                    { id: 'Implemented', name: translations.IMPROVEMENT_IMPLEMENTED },
                    { id: 'Evaluated', name: translations.IMPROVEMENT_EVALUATED }
                ];
            });

            $rootScope.$on('$translateChangeSuccess', function () {
                $scope.status = [
                    { id: 'All', name: $translate.instant('COMMON_ALL') },
                    { id: 'New', name: $translate.instant('COMMON_NEW') },
                    { id: 'In Progress', name: $translate.instant('COMMON_IN_PROGRESS') },
                    { id: 'Implemented', name: $translate.instant('IMPROVEMENT_IMPLEMENTED') },
                    { id: 'Evaluated', name: $translate.instant('IMPROVEMENT_EVALUATED') }
                ];
            });

            $scope.newPieChart = {
                data: [0, 0, 0, 0],
                options: {
                    radius: 18,
                    fill: ["#ffffff"],
                }
            };
            $scope.planPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#ffffff", "#ffffff", "#ffffff"],
                }
            };
            $scope.doPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#ffffff", "#ffffff"],
                }
            };
            $scope.checkPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#ffffff"]
                }
            };
            $scope.actPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"]
                }
            };
        }]);
