angular
    .module('DigiLean')
    .directive("projectSelectorDropdown", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'projectSelectorDropdown.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'userid': '=',
                    'ismandatory': '=',
                    'projectChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.projects = null;
                    scope.project = null;
                    scope.isLoading = true;

                    scope.$watch("userid", function () {
                        if(!scope.userid && !scope.model) return;
                        if(scope.model && scope.model.projects){
                            scope.projects = scope.model.projects;
                            scope.isLoading = false;
                        } else {
                            if(!scope.projects){
                                projectService().getList().then(function (data) {
                                    scope.projects = data;
                                    scope.isLoading = false;
                                });
                            } else if (scope.userid){ 
                                projectService().getMyProjects().then(function (data) {
                                    scope.projects = data;
                                    scope.isLoading = false;
                                });
                            }
                        }
                    });

                    scope.$watch("model", function () {
                        if(!scope.userid && !scope.model) return;
                        if(scope.model && scope.model.projects){
                            scope.projects = scope.model.projects;
                            scope.isLoading = false;
                        } 
                        if(scope.model && scope.model.projectId){
                            if(!scope.projects){
                                if (scope.userid){ 
                                    projectService().getMyProjects().then(function (data) {
                                        scope.projects = data;
                                        scope.isLoading = false;
                                        setProject(scope.model.projectId);
                                    });
                                } else {
                                    projectService().getList().then(function (data) {
                                        scope.projects = data;
                                        scope.isLoading = false;
                                        setProject(scope.model.projectId);
                                    });
                                }              
                            } else {
                                setProject(scope.model.projectId);
                            }
                        } else {
                            scope.project = null;
                        }
                    });

                    function setProject(projectId){
                        var project = $filter('filter')(scope.projects, { id: projectId }, true);
                        if (project.length > 0) {
                            scope.project = project[0];
                        }
                    }

                    

                    scope.selectProject = function (project) {
                        scope.project = project;
                        if(project){
                            scope.projectid = project.id;
                        } else {
                            scope.projectid = null;
                        }
                        var updateinfo = {
                            model: scope.model,
                            project: scope.project
                        }  
                        scope.projectChangeHandler({projectinfo: updateinfo})
                    };
                }
            }
        }]);