import {LitElement, html, css} from "lit"
import {customElement, state} from "lit/decorators.js"

@customElement('digilean-ok-errors')
export class DigiLeanOkWithErrors extends LitElement {
    
    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-block;
            user-select: none;
        }
        #outer-box {
            display: inline-flex;
            box-sizing: border-box;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            vertical-align: middle;
        }
        #outer-box.open {
            background: transparent; //transparent
        }
        svg {
            position: absolute;
            z-index: -1;
            height: calc(var(--digilean-icon-height, 2rem) * 0.6);
            width: calc(var(--digilean-icon-width, 2rem) - 0.6);
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition-delay: 0.5s;
            transition: all 1s ease;
            transform: translate3d(0, 0, 0);
        }
        svg#check {
            stroke: var(--digilean-white);
        }
        #outer-box.open svg#check {
            stroke-dashoffset: 0;
            z-index: 1;
        }

        svg#circle-check {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            /* transition: fill 250ms cubic-bezier(.4,.0,.23,1); */
        }
        #outer-box.open svg#circle-check {
            z-index: 1;
            fill: var(--digilean-button-success);
        }

        svg#error-sign {
            z-index: 2;
            transform: scale(1.2);
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            fill: var(--digilean-danger);
        }
    `
    connectedCallback(): void {
        super.connectedCallback()
        
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
    }
    render() {
        return html`
            <div id="outer-box" class="open">
                <svg id="circle-check" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="check" viewbox="0 0 13 13">
                    <polyline points="2 6 5 10 11 3"></polyline>
                </svg>
                <svg id="error-sign" viewbox="0 0 18 18">
                    <g xmlns="http://www.w3.org/2000/svg" id="Error">
                        <circle cx="14" cy="14" r="4" />
                        <rect x="14" y="11" width="1" height="3" fill="white" />
                        <rect x="14" y="15" width="1" height="1" fill="white" />
                    </g>
                </svg>
            </div>
        `
    }
}
