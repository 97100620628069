﻿import * as profileService from "@common/services/user/profileService"

angular
    .module('DigiLean')
    .directive("profileImage", ['Lightbox', 'taskDrawingService',
        function (Lightbox, taskDrawingService) {
            return {
                templateUrl: 'profileImage.html',
                restrict: 'E',
                scope: {
                    'userId': '=',
                    'shape': '@?',
                    'size': '@?'
                },
                link: function (scope, elem, attrs) {
                    scope.shape = "square";
                    scope.size = "small";
                    scope.css = "";
                    scope.imageUrl = null
                    
                    scope.$watch('userId', function (userId) {
                        scope.imageUrl = taskDrawingService().getProfileImageUrl(scope.userId, "ExtraSmall") 
                    });
                    scope.$watch('shape', function (shape) {
                        if (shape == "round") {
                            scope.css = "img-circle";
                        }
                    });
                    scope.openLightboxModal = function () {
                        var images = [
                            {
                                'url': scope.file.validFileUrl,
                                'thumbUrl': scope.file.validFileUrl
                            }
                        ];
                        Lightbox.openModal(images, 0);
                    };
                }
            }
        }]);
