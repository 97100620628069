import angular from "angular"
import { setAndLoadLang } from "@common/services/language/DigiLeanLang"
import moment from "moment"

const digiLean = angular.module("DigiLean")

digiLean.factory('DigiLeanLangState', ['$translate', 'tmhDynamicLocale', '$http', function ($translate, tmhDynamicLocale, $http) {
    
    function setLang(code: string) {
        console.log("DigiLean Language:: set to:", code)
        setAndLoadLang(code)
        tmhDynamicLocale.set(code)
        $translate.use(code)
        setRequestHeader(code)
        setMomentLocale(code)
    }

    function setRequestHeader(code: string) {
        // Request header
        let locale = code
        if (code == "en") {
            if (navigator.language)
                locale = navigator.language
        }
        $http.defaults.headers.common["Accept-Language"] = locale
    }
    return {setLang}

}])

digiLean.factory('DigiLeanAngularLoader', function () {
    return function (options) {
        return setAndLoadLang(options.key)
    }
})

const setMomentLocale = (code: string) => {
    if (code === "no") {
        moment.locale("nb")
    } else if (code === "en") {
        moment.locale("en-gb") //Use ISO week
    } else moment.locale(code)
}