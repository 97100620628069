﻿2
angular
    .module('DigiLean')
    .directive("myFavoritePages", ['favoriteService',
        function (favoriteService) {
            return {
                templateUrl: 'myFavoritePages.html',
                restrict: 'E',
                link: function (scope, elem, attrs) {
                    scope.pages = [];
                    favoriteService().getMyFavoritePages().then(function(pages) {
                        scope.pages = pages;
                    });

                    scope.getLink = function(page) {
                        let link = "/#/learning/"
                        link += page.source
                        link += `/${page.learningMaterialId}?pageid=`
                        link += page.pageId ? page.pageId : '0';
                        return link
                    }
                }
            }
        }]);
