﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('changestatusController', ['$scope', '$filter', '$uibModalInstance', '$translate', 'suggestionService', 'suggestion',
    function ($scope, $filter, $uibModalInstance, $translate, suggestionService, suggestion) {
        $scope.title = "Change suggestion status";

        $translate('DASHBOARD_CHANGE_SUGGESTION_STATUS').then(function (title) {
            $scope.title = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });


        $scope.statuses = suggestionService().getSuggestionStatuses();

        $scope.comment = "";
        $scope.suggestion = suggestion;
        $scope.selectedStatus = $filter('filter')($scope.statuses, { status: suggestion.status }, true)[0];
        $scope.ok = function () {
            $scope.suggestion.status = $scope.selectedStatus.status;
            var suggestionStatus = {
                suggestionId: $scope.suggestion.id,
                status: $scope.suggestion.status,
                priorityStatus: 0,
                comment: $scope.comment
            }
            suggestionService().updateStatusOnCustomerSuggestion(suggestionStatus).then(function () {
                $uibModalInstance.close(suggestionStatus);
            });
        };

        $scope.cancel = function () {
            $translate('COMMON_CANCEL').then(function (msg) {
                $uibModalInstance.dismiss(msg);
            }, function (translationId) {
                $scope.headline = translationId;
            });
        };
    }]);