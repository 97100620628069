angular
    .module('DigiLean')
    .directive("stringSelector", ['$filter', '$translate', 'dataService',
        function ($filter, $translate, dataService) {
            return {
                templateUrl: 'stringselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                 
                    scope.$watch('source', function () {
                        applyFilter();
                    });

                    scope.operatorChanged = function (item, operator) {
                        item.operator = operator.dbCode;
                    }

                    scope.operator1option = [
                        {
                            translatedText: $translate.instant('DASHBOARD_CONTAINS'),
                            dbCode: "Contains"
                        }, {
                            translatedText: $translate.instant('DASHBOARD_NOT_CONTAIN'),
                            dbCode: "NotContain"
                        }
                    ];

                    scope.add = function () {
                        if (!scope.filters) scope.filters = [];
                        var filterItem = {
                            sourceColumn: scope.source,
                            operator: scope.operator1option[0].dbCode,
                            selectedOperator: scope.operator1option[0],
                            text: ""
                        };
                        scope.filters.push(filterItem);
                    }

                    scope.delete = function (item) {
                        var index = scope.filters.indexOf(item);
                        if (index != -1) {
                            scope.filters.splice(index, 1);
                            updateSettings();
                        }
                    }

                    function updateSettings() {
                        var filters = [];
                        if (scope.filters) {
                            for (var i = 0; i < scope.filters.length; i++) {
                                filters.push({
                                    sourceColumn: scope.source,
                                    operator: scope.filters[i].selectedOperator.dbCode,
                                    items: [scope.filters[i].text]
                                });
                            }
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: filters });
                    }

                    function applyFilter() {
                        if (!scope.filters) return;
                        
                        for (var i = 0; i < scope.filters.length; i++) {
                            scope.filters[i].selectedOperator = $filter('filter')(scope.operator1option, { dbCode: scope.filters[i].operator })[0],
                            scope.filters[i].text = scope.filters[i].items[0];
                        }

                    }

                    scope.modifyFilter = function (filter) {
                        updateSettings();
                    }
                }
            }
        }]);
