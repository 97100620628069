import * as userAdmin from "@common/services/user/userAdminService"
import * as customerService from "@common/services/customers/customerService"
import { DigiLeanNgScope, IHasModules } from "@common/model/angularModel"
import angular from "angular"
import { AuthenticationMode, BusinessUnit, FilterElement, TableParams, UserInfoModel } from "@api"
import toastr from "toastr"
import { NgTableParams } from "@common/thirdparty/ng-table/ng-table"
import { getAllUnitsCached } from "@common/services/businessUnitService"
import { getUserProfile } from "@common/stores/userStore"

interface IUserListColumn {
    title: string
    cssClass: string
    field: string
    visible: boolean
    sortable: string
    getValue: ($scope: any, row: any) => void,
    dataType: string
}

interface IUserListResultScope extends DigiLeanNgScope, IHasModules {
    registrations: UserInfoModel[]
    allUsers: UserInfoModel[]

    allColumns: IUserListColumn[]
    columns: IUserListColumn[]
    tableParams: typeof NgTableParams

    newValuesCounter: number
    totalValues: number
    totalMatchingRecords: number
    numberOfUsers: number
    numberOfMobileUsers: number
    mobileLicenses: any
    authenticationMode: AuthenticationMode
    useAD: (user: UserInfoModel) => boolean

    businessUnits: BusinessUnit[]
    sorting: any[] | string
    filter: FilterElement[]
    headline: string

    delete: (user: UserInfoModel) => void
    resetPassword: (user: UserInfoModel) => void
    editUser: (user: UserInfoModel) => void
    editUserHandler: ({user}) => void

    getTableValues: (params: any) => Promise<UserInfoModel[] | undefined | null>
    buildColumns: () => void
    getValues: () => void
    getValueForElement: (element: any, item: any) => void
    showCol: (item: any, col: any) => void
    selectColumn: (column: any) => void
}

angular
    .module('DigiLean')
    .directive("userListResult", ['$filter', '$translate', '$sce', 'modalService', 'navigationService','authService','$uibModal', 'NgTableParams',
        function ($filter, $translate, $sce, modalService, navigationService,authService, $uibModal, NgTableParams) {
            return {
                templateUrl: 'userListResult.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                    'mobilelicenses': '<',
                    'editUserHandler': '&?',
                },
                link: function (scope: IUserListResultScope, elem, attrs) {

                    scope.columns = []
                    scope.registrations = []
                    scope.newValuesCounter = 0
                    scope.totalValues = 0
                    scope.totalMatchingRecords = 0
                    scope.allUsers = []

                    scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT")
                    scope.hasDeviationModule = navigationService().hasModule("DEVIATION")
                    scope.hasA3Module = navigationService().hasModule("A3")
                    scope.hasStrategy = navigationService().hasModule("STRATEGY")
                    scope.hasProject = navigationService().hasModule("PROJECT")
                    scope.hasLearningModule = navigationService().hasModule("LEARNING")
            
                    scope.businessUnits = []
                    getAllUnitsCached().then(units => {
                        if (units.length > 0) {
                            scope.businessUnits = units
                            setupAllColumns()
                            scope.buildColumns()
                        }
                    })
                    // if page is refreshed we need to refresh the access to modules
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT")
                        scope.hasDeviationModule = navigationService().hasModule("DEVIATION")
                        scope.hasA3Module = navigationService().hasModule("A3")
                        scope.hasProject = navigationService().hasModule("PROJECT")
                        scope.hasStrategy = navigationService().hasModule("STRATEGY")
                        scope.hasLearningModule = navigationService().hasModule("LEARNING")
                        setupAllColumns()
                    })
                    

                    // Rewrite this to give correct count if user is businessunitAdmin????
                    customerService.getActiveUserLicenses().then(function (active) {
                        scope.numberOfUsers = active
                        customerService.getActiveMobileLicenses().then(function (activemobile) {
                            scope.numberOfMobileUsers = activemobile
                            scope.totalValues = scope.numberOfUsers + scope.numberOfMobileUsers
                            if(scope.totalMatchingRecords > scope.totalValues) { //total values does not include support user and can therefore have one value less than totalMatchingRecords
                                scope.totalValues = scope.totalMatchingRecords
                            }     
                        })
                    })
                    setupAllColumns()

                    // Filter handling
                    scope.$watch('filter', function (filter) {
                        if (!filter) {
                            scope.filter = []
                        }
                        scope.getValues()
                    })

                    scope.$watch('mobilelicenses', function (licenses) {
                        if(licenses){
                            scope.mobileLicenses = licenses
                            setupAllColumns()
                            scope.buildColumns()
                        }
                    })

                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn
                        var value = item[sourceColumn]
                        return value
                    }


                    scope.getValues = function () {
                        scope.buildColumns()
                        scope.tableParams = new NgTableParams({
                            count: 25,
                        },{
                            getData: scope.getTableValues
                        })

                    }
                    scope.sorting = ""
                    scope.getTableValues = function (params) {

                        var sorting: any[] = []
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            })
                        }
                        scope.sorting = sorting
                        addBusinessUnitToFilter() //add this filter here to make sure no timing issues occur
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams: TableParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: scope.filter
                        }
                        return customerService.getTableValues(dbParams).then(function (data) {
                            scope.totalMatchingRecords = data.total!
                            if(scope.totalMatchingRecords > scope.totalValues){ //total values does not include support user and can therefore have one value less than totalMatchingRecords
                                scope.totalValues = scope.totalMatchingRecords
                            }
                            params.total(data.total) // recal. page nav controls
                            return data.values

                        })
                    }

                    function addBusinessUnitToFilter(){
                        scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
                        if(!scope.isBusinessunitAdmin) return;
                        const currentUser = getUserProfile()
                        var businessUnitId = currentUser.businessUnitId?.toString() || "0"; //setting id equal to zero should never happen, 
                        // but if it does then no users will be retrieved since the businessunitadmin does not have a valid businessunit associated with it
                        var businessUnitFilter = {
                            sourceColumn: "businessUnitId",
                            operator: "InList",
                            items: [businessUnitId]
                        }
                        scope.filter.push(businessUnitFilter)
                    }

                    customerService.getAuthenticationMode().then(function(result) {
                        scope.authenticationMode = result
                    })
                    scope.useAD = function(user) {
                        if (scope.authenticationMode == 0)
                            return false
                        if (scope.authenticationMode == 2 && !user.useAD)
                            return false
                        return true
                    }
                    
                    scope.showCol = function(item, col) {
                        let show = item[col.field]
                        return !!show
                    }

                    function setupAllColumns(){
                        scope.allColumns = [
                            {
                                title: "COMMON_USER",
                                cssClass: "",
                                field: 'user',
                                visible: true,
                                sortable: 'user',
                                getValue: renderValue,
                                dataType: "text"
                            },
                            {
                                title: "ADMIN_ASSETTYPE_BUSINESS_UNIT",
                                cssClass: "",
                                field: "businessUnitId",
                                visible: scope.businessUnits.length > 0,
                                sortable: "businessUnitId",
                                getValue: renderValue,
                                dataType: "string"
                            },
                            {
                                title: "ADMIN_ADMIN",
                                cssClass: "fa fa-key",
                                field: "isAdmin",
                                visible: true,
                                sortable: "isAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_BUSINESSUNITADMIN",
                                cssClass: "far fa-building",
                                field: "isBusinessunitAdmin",
                                visible: scope.businessUnits.length > 0,
                                sortable: "isBusinessunitAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_BOARDDESIGNER",
                                cssClass: "fas fa-desktop",
                                field: "isBoardDesigner",
                                visible: true,
                                sortable: "isBusinessunitAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_DATAADMIN",
                                cssClass: "fas fa-database",
                                field: "isDataAdmin",
                                visible: true ,
                                sortable: "isDataAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_MESSAGEWRITER",
                                cssClass: "fas fa-envelope",
                                field: "isMessageWriter",
                                visible: true ,
                                sortable: "isMessageWriter",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_IMPROVEMENTADMIN",
                                cssClass: "fas fa-lightbulb",
                                field: "isImprovementAdmin",
                                visible: scope.hasImprovementModule,
                                sortable: "isImprovementAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_DEVIATIONADMIN",
                                cssClass: "fas fa-exclamation-triangle",
                                field: "isDeviationAdmin",
                                visible: scope.hasDeviationModule,
                                sortable: "isDeviationAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_PROJECTADMIN",
                                cssClass: "fas fa-calendar-alt",
                                field: "isProjectAdmin",
                                visible: scope.hasProject,
                                sortable: "isProjectAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_A3ADMIN",
                                cssClass: "far fa-file",
                                field: "isA3Admin",
                                visible: scope.hasA3Module ,
                                sortable: "isA3Admin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_STRATEGYADMIN",
                                cssClass: "fas fa-rocket",
                                field: "isStrategyAdmin",
                                visible: scope.hasStrategy,
                                sortable: "isStrategyAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "ADMIN_IS_LEARNINGMATERIALEDITOR",
                                cssClass: "fas fa-graduation-cap",
                                field: "isLearningAdmin",
                                visible: scope.hasLearningModule,
                                sortable: "isLearningAdmin",
                                getValue: renderValue,
                                dataType: "bool"
                            },
                            {
                                title: "COMMON_MOBILE_USER",
                                cssClass: "fa fa-mobile-alt",
                                field: "isMobileUser",
                                visible: scope.numberOfMobileUsers>0 || scope.mobileLicenses > 0,
                                sortable: "isMobileUser",
                                getValue: renderValue,
                                dataType: "bool"
                            }
                        ]
                    }

                    scope.buildColumns = function () {
                        scope.columns = scope.allColumns.filter(c => c.visible)
                    }

                    scope.selectColumn = function(column) {
                        column.visible = !column.visible
                        scope.buildColumns()
                    }


                    function renderValue(this: any, row) {
                        const item = this 
                        const field = item.field
                        const value = row[field]
                        if (this.dataType === "date") {
                            return $filter('date')(value, "mediumDate")
                        }
                        if (this.dataType === "timestamp") {
                            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss")
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>"
                            } else {
                                return ""
                            }
                        } 
                        return value
                    }

                    scope.delete = function (user) {
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_USER_CONFIRMATION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,//'Cancel',
                                actionButtonText: translations.COMMON_DELETE,//'Delete User',
                                headerText: translations.COMMON_DELETE + ' ' + user.firstName + ' ' + user.lastName + '?',
                                bodyText: translations.ADMIN_MESSAGE_DELETE_USER_CONFIRMATION//'All data on user will be lost. Are you sure you want to delete this user?'
                            }
            
                            modalService.showModal({}, modalOptions).then(function (result) {
                                userAdmin.deleteUser(user.id!).then(res => {
                                    $translate('ADMIN_MESSAGE_USER_DELETED').then(function (msg) {
                                        toastr.info(msg)
                                    }, function (translationId) {
                                        scope.headline = translationId
                                    })
                                    scope.getValues()
                                    
                                }, function (err) {
                                    $translate('ADMIN_ERROR_MESSAGE_DELETE_USER').then(function (msg) {
                                        toastr.error(msg)
                                    }, function (translationId) {
                                        scope.headline = translationId
                                    })
                                })
                            })
                        })
                    }

                    scope.resetPassword = function (user) {
                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'forgotPasswordForm.html',
                            controller: 'forgotPasswordModalController',
                            resolve: {
                                user: function () {
                                    return {
                                        email: user.email,
                                    }
                                }
                            }
                        })
                    }

                    scope.editUser = function(user){
                        if(user && user.isSupport){
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'useraccessform.html',
                            controller: 'userAccessController',
                            resolve: {
                                user: function () {
                                    return user
                                }
                            }
                        })   
                        modalInstance.result.then(function (user) { 
                        }, function () {
                        })
                        } else {
                            scope.editUserHandler({ user: user })
                        }
                        
                    }

                }

            }
        }])
