/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ImprovementStatus {
    Suggested = 'Suggested',
    Planned = 'Planned',
    InProgress = 'InProgress',
    Implemented = 'Implemented',
    Evaluated = 'Evaluated',
    Archived = 'Archived',
    Activated = 'Activated',
    Open = 'Open',
}
