
angular
.module('DigiLean').directive('froalaView', ['$sce', function($sce) {
    return {
        restrict: 'ACM',
        scope: false,
        link: function(scope, element, attrs) {
            element.addClass('fr-view')
            scope.$watch(attrs.froalaView, function(nv) {
                if (nv || nv === '') {
                    var explicitlyTrustedValue = $sce.trustAsHtml(nv)
                    element.html(explicitlyTrustedValue.toString())
                }
            })
        }
    }
}])