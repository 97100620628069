import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("smarttableNumberCellAutomatic", ['$filter', 'debounce', '$uibModal', 'projectService','dataService','mathService',
        function ($filter, debounce, $uibModal, projectService, dataService, mathService) {
            return {
                templateUrl: 'smarttableNumberCellAutomatic.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.number = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";
                    scope.lastDate = null;

                    scope.smartTableRowId = null;
                    scope.color = "#676a6c";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    var filters = [];
                    var dataSource = null;
                    var period = null; 
                    scope.smartTableColumnId = null;
                    scope.decimals = null;
                    var settings = null;

                    scope.$watch('model', function (model) {
                        var value = model.cell.value;
                        scope.smartTableColumnId = model.cell.smartTableColumnId;
                        settings = model.column.settings; 
                        if (settings.timePeriod.timeframe == "custom") {     
                            period = settings.timePeriod.period;
                        } else if (settings.timePeriod.timeframe !== "last") {     
                            period = timeService.getTimePeriod(settings.timePeriod.timeframe);
                        } else {
                            period = timeService.getTimePeriod("all");
                        }
                        
                        scope.smartTableRowId = model.cell.smartTableRowId;
                        if(value){
                            if(value.dataSource){
                                dataSource = value.dataSource;
                            }
                            if(value.filters){
                                filters = value.filters;
                            }
                            setColor(value);
                        }
                        if (settings) {
                            if(!dataSource && settings.dataSource){
                                dataSource = settings.dataSource;
                            }
                            if(settings.decimals !== null || settings.decimals !== undefined){
                                scope.decimals = settings.decimals;
                            }
                            if(settings.unit){
                                scope.unit = settings.unit;
                                if(settings.unitPlacement){
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                            if(!filters && settings.filers){
                                filters = settings.filters;
                            }
                        } 
                        scope.updateValue(dataSource.id);
                        model.getSortValue = function() {
                            return scope.number;
                        }
                    });

                    scope.subscribe("SmartTableNumberCellAutomaticTimeframeUpdated", function (value) {
                        if(scope.smartTableColumnId === value.columnId){                           
                            scope.lastDate = null;
                            settings.timePeriod.timeframe = value.timePeriod.timeframe;
                            if (value.timePeriod.timeframe == "custom") {     
                                settings.timePeriod.period = value.timePeriod.period;
                            }              
                            scope.updateValue(dataSource.id);
                        }
                    });
                    scope.subscribe("SmartTableNumberCellDecimalUpdated", function (value) {
                        if(scope.smartTableColumnId == value.columnId){
                            scope.decimals = value.decimals;
                            scope.updateValue(dataSource.id); 
                        }
                    });
                    scope.subscribe("SmartTableNumberCellUnitUpdated", function (value) {
                        if(scope.smartTableColumnId === value.columnId){
                            scope.unit = value.unit;
                        }
                    });
                    scope.subscribe("SmartTableNumberCellUnitPlacementUpdated", function (value) {
                        if(scope.smartTableColumnId === value.columnId){
                            scope.unitPlacement = value.unitPlacement;
                        }
                    });
                    scope.subscribe("DataValueAdded", function (value) {
                        scope.updateValue(value.dataSourceId);
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        scope.updateValue(value.dataSourceId);
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        scope.updateValue(value.dataSourceId);
                    });

                    function getLastValue(){
                        if (settings.timePeriod.timeframe == "custom") {     
                            period = settings.timePeriod.period;
                        }
                        else if (settings.timePeriod.timeframe === "last") {
                            period = null;
                        } else {
                            if(!period){
                            period = timeService.getTimePeriod("all");
                            }
                        }
                        dataService().getLastDate(dataSource.id, period, filters).then(function (fromDate) {
                            scope.lastDate = moment(fromDate).toDate();
                            var toDate = moment(fromDate).endOf('day');
                            var dayperiod = {
                                from:fromDate,
                                to:toDate
                            };
                        
                            var options = {
                                timePeriod: dayperiod,
                                filters: filters,
                            };

                            dataService().getSumForPeriod(dataSource.id, options).then(function (sum) {
                                scope.number = sum;
                                var payload =  {
                                    smartTableColumnId: scope.smartTableColumnId,
                                    smartTableRowId: scope.smartTableRowId,
                                    value: scope.number
                                }  
                                scope.publish("SmartTableNumberCellUpdated", payload);

                                if(scope.decimals !== null) {
                                    if(scope.decimals !== undefined) {
                                        scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                    }
                                }                           
                            });
                        });

                    }

                    function getAll(){
                        
                        period = timeService.getTimePeriod("all");
                        
                        dataService().getLastDate(dataSource.id, null, filters).then(function (lastDate) {
                       
                            var toDate = moment(lastDate).endOf('day');
                            var newperiod = {
                                from:period.from,
                                to:toDate
                            };
                        
                            var formula = null;
                            if(dataSource.valueElement.unit == "%"){
                                formula = "average";
                            }
                            var options = {
                                timePeriod: newperiod,
                                filters: filters,
                                formula: formula
                            };

                            dataService().getSumForPeriod(dataSource.id, options).then(function (sum) {
                                scope.number = sum;
                                    var payload =  {
                                        smartTableColumnId: scope.smartTableColumnId,
                                        smartTableRowId: scope.smartTableRowId,
                                        value: scope.number
                                    }  
                                    scope.publish("SmartTableNumberCellUpdated", payload);
                                    if(scope.decimals !== null) {
                                        if(scope.decimals !== undefined) {
                                            scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                        }
                                    }                         
                            });
                        });

                    }

                    scope.updateValue = function (id) {
                        if (!dataSource) return;
                        if (dataSource.id !== id) return;
                        if (settings.timePeriod.timeframe === "last") {
                            getLastValue();
                        } else if(settings.timePeriod.timeframe === "all" ){
                            getAll();
                        } else{

                            if (settings.timePeriod.timeframe == "custom") {     
                                period = settings.timePeriod.period;
                            }else {
                             period = timeService.getTimePeriod(settings.timePeriod.timeframe);
                            }
                            var formula = null;
                            if(dataSource.valueElement.unit == "%"){
                                formula = "average";
                            }
                            var options = {
                                timePeriod: period,
                                filters: filters,
                                formula: formula
                            };
                        dataService().getSumForPeriod(dataSource.id, options).then(function (sum) {
    
                            scope.number = sum;
                                var payload =  {
                                    smartTableColumnId: scope.smartTableColumnId,
                                    smartTableRowId: scope.smartTableRowId,
                                    value: scope.number
                                }  
                                scope.publish("SmartTableNumberCellUpdated", payload);
                                if(scope.decimals !== null) {
                                    if(scope.decimals !== undefined) {
                                        scope.number = mathService().roundNumber(scope.number, scope.decimals);
                                    }
                                }               
                        });
                    }
                    };
                    
                    var setColor = function(cell) {
                        if(cell && cell.background){
                            scope.background = cell.background;
                            scope.color = cell.color;
                        }
                     
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = cell.background;
                        }
                    }

                    scope.openDataSource = function(){
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return true;
                                },
                                dataSource: function () {
                                    return dataSource;
                                },
                                filters: function () {
                                    return filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            filters = result.filters;
                            dataSource = result.dataSource;
                            scope.updateValue(dataSource.id);
                            scope.saveChanges();
                        });
                    }

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            filters: filters,
                            dataSource: dataSource
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
