﻿import Dropzone from "dropzone";
import { getAuthUser } from "@common/stores/userStore"

angular
    .module('DigiLean')
    .directive("dropzone", ['$filter', 'webApiUrl',
        function ($filter, webApiUrl) {
            return {
                restrict: 'A',
                scope: {
                    'uploadedfiles': '=',
                    'autoUpload': '=',
                    'removeFilesAfterUpload': '=',
                    'fileUploadedHandler': '&',
                    'uploadPath': '@',
                    'isPublic': '@?',
                    'toDigiLean': '@?',
                },
                link: function (scope, element, attrs) {
                    var dropzone;
                    const authUser = getAuthUser()
                    
                    let uploadUrl = webApiUrl + "/document/PostBlobUpload/"
                    if (scope.isPublic) {
                        uploadUrl = webApiUrl + "/document/PostPublicBlobUpload";
                    } else if (scope.toDigiLean) {
                        uploadUrl = webApiUrl + "/document/PostBlobUploadDigiLean"
                    }

                    Dropzone.autoDiscover = false;
                    if (scope.uploadPath) {
                        uploadUrl = webApiUrl + scope.uploadPath;
                    }
                    var config = {
                        url: uploadUrl,
                        maxFilesize: 100,
                        paramName: "uploadfile",
                        maxThumbnailFilesize: 8,
                        uploadMultiple: true,
                        autoProcessQueue: scope.autoUpload,
                        withCredentials: true,
                        maxFiles: 10,
                        headers: {
                            'Authorization': 'Bearer ' + authUser.token
                        }
                    };

                    scope.$watch('uploadedfiles', function (files) {
                        if (uploadedFiles && uploadedFiles.length > 0 && (files === null || files.length === 0)) {
                            uploadedFiles.forEach(function (file){
                                setTimeout(function () {
                                    dropzone.removeFile(file);
                                }, 1000);
                            });        
                        }
                    });

                    var uploadedFiles = [];
                    var eventHandlers = {
                        // 'addedfile': function (file) {
                        //     var t = file;
                        // },
                        'success': function (file, response) {
                            if (!scope.uploadedfiles) scope.uploadedfiles = [];
                            if (response.length > 0) {
                                response.forEach(function (file) {
                                    var exists = $filter('filter')(uploadedFiles, { id: file.id });
                                    // Make sure we don't save a file more than once
                                    if (exists.length == 0) {
                                        // Check
                                        uploadedFiles.push(file);
                                        scope.uploadedfiles.push(file);
                                        if (scope.fileUploadedHandler) {
                                            scope.fileUploadedHandler({ file: file });
                                        }
                                    }
                                });

                            }

                            // Remove from dropzone, since they have been uploaded
                            if (scope.removeFilesAfterUpload) {
                                setTimeout(function () {
                                    dropzone.removeFile(file);
                                }, 1000);

                            }

                        },
                        'complete': function (file, response) {

                        }
                    };

                    dropzone = new Dropzone(element[0], config);

                    // add paste event listener to the page
                    document.addEventListener("paste", documentPasteHandler);

                    angular.forEach(eventHandlers, function (handler, event) {
                        dropzone.on(event, handler);
                    });

                    function documentPasteHandler(event) {
                        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
                        for (var index in items) {
                            var item = items[index];
                            console.debug("file pasted to dropzone" + item.kind);
                            if (item.kind === 'file') {
                                // adds the file to your dropzone instance
                                dropzone.addFile(item.getAsFile())
                            }
                        }
                    }

                    scope.processDropzone = function () {
                        dropzone.processQueue();
                    };

                    scope.resetDropzone = function () {
                        dropzone.removeAllFiles();
                    }

                    scope.$on('$destroy', function () {
                        console.debug("destroy dropzone");
                        if (document.removeEventListener) {                   // For all major browsers, except IE 8 and earlier
                            document.removeEventListener("paste", documentPasteHandler);
                        }
                    })
                    
                }
            }
        }]);
