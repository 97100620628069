angular
    .module('DigiLean')
    .directive("datasourceSearchFilter", ['$filter', 'dataSourceService',
        function ($filter, dataSourceService) {
            return {
                templateUrl: 'dataSourceSearchFilter.html',
                restrict: 'E',
                scope: {
                    'filters': '<',
                    'datasources': '=',
                    'layoutdirection': '@',
                },
                link: function (scope, elem, attrs) {

                    scope.tags = [];
                    scope.isfiltered = false;


                    scope.$watch('filters', function(){
                        if(scope.filters && scope.filters.length > 0){
                            scope.isfiltered = true;
                        } else {
                            scope.isfiltered = false;
                        }
                    })

                    scope.$watch('datasources', function () {
                        if (!scope.datasources) return;

                        dataSourceService().getAllTags().then(function (tags) {
                            scope.tags = tags;
                        });
                    });


                    scope.filterTags = function (tag) {
                        tag.isActive = !tag.isActive;
                        filterDataSource();
                    }

                    scope.clearFilter = function(){
                        scope.datasources.filteredList = scope.datasources.list;
                        resetListFilter(scope.tags);
                        scope.isfiltered = false;
                    }

                    function resetListFilter(list) {
                        list.forEach(item => {
                            item.isActive = false
                        })
                    }

                    function filterDataSource() {
                        scope.datasources.filteredList = scope.datasources.list;

                        var filteredTags = $filter('filter')(scope.tags, { isActive: true }, true);
                        if (filteredTags.length > 0) {
                            scope.isfiltered = true;
                            scope.datasources.filteredList = getFilteredListByTags(scope.datasources.filteredList, filteredTags);
                        } else {
                            scope.isfiltered = false; 
                        }
                    }
                    function getFilteredListByTags(list, filteredTags) {
                        if (filteredTags.length === 0) return list;
                        var filteredList = [];
                        var filteredDataSourceIds = [];
                        for (var j = 0; j < filteredTags.length; j++) {
                            filteredDataSourceIds = filteredDataSourceIds.concat(filteredTags[j].dataSources);
                        }
                        for (var i = 0; i < list.length; i++) {
                            if(valueInList(list[i].id, filteredDataSourceIds)){
                                filteredList.push(list[i]);
                            }
                        }
                        return filteredList;
                    }
                    function valueInList(property, list) {
                        for (var i = 0; i < list.length; i++) {
                            if (property === list[i]) return true;
                        }
                        return false;
                        
                    }
                }

            }
        }]);
