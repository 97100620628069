import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("datasourceValues", ['$filter', '$translate', '$sce', 'areaService', 'projectService', 'dataService', 'NgTableParams', 'excelService', '$uibModal', 'dataListService',
        function ($filter, $translate, $sce, areaService, projectService, dataService, NgTableParams, excelService, $uibModal, dataListService) {
            return {
                templateUrl: 'datasourceValues.html',
                restrict: 'E',
                scope: {
                    'datasource': '=',
                    'selectedItemForUpdate': '=',
                    'filters': '=',
                    'timeperiod': '='
                },
                link: function (scope, elem, attrs) {

                    scope.columns = [];
                    scope.registrations = [];
                    scope.newValuesCounter = 0;
                    scope.totalValues = 0;
                    scope.allUsers = [];
                    scope.selectedDataList = [];
                    scope.dataLists = {};
                    scope.isfiltered = false;

                    scope.$watch('filters', function(){
                        if(scope.filters && scope.filters.length > 0){
                            scope.isfiltered = true;
                        } else {
                            scope.isfiltered = false;
                        }
                    })

                    scope.$watch('timeperiod', function(){
                        if(scope.timeperiod){
                            scope.isfiltered = true;
                        }
                    })

                    scope.$watch('datasource', function () {
                        if (!scope.datasource) return;

                        var listOperations = [];
                        angular.forEach(scope.datasource.elements, function (element) {
                            switch (element.type) {
                                case "asset":
                                    listOperations.push(assetService.getAllAssets(true).then(function (data) {
                                        scope.assets = data;
                                    }));
                                    break;
                                case "area":
                                    listOperations.push(areaService().getList(true).then(function (data) {
                                        scope.areas = data;
                                    }));
                                    break;
                                case "project":
                                    listOperations.push(projectService().getList(true).then(function (data) {
                                        scope.projects = data;
                                    }));
                                    break;
                                case "user":
                                    listOperations.push(customerService.getAllUsers().then(function (users) {
                                        scope.allUsers = users;
                                    }));
                                    break;
                                case "list":
                                    var listOptions = { includeDeletedItems: false };
                                    listOperations.push(dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                        if (list) {
                                            scope.dataLists[element.sourceColumn] = list; 
                                        }
                                    }));
                                    break;
                            }

                        });

                        Promise.all(listOperations).then(() => {
                            scope.getLatestValues();
                        })


                    });


                    scope.importFromExcel = function () {
                        //
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'excelImporter.html',
                            controller: 'excelImporterController',
                            resolve: {
                                dataSourceId: function () {
                                    return scope.datasource.id;
                                },
                            }
                        });
                        // When user has created new suggestion
                        modalInstance.result.then(function () {
                            scope.getLatestValues();
                        }, function () {
                            //$log.info('Modal dismissed at: ' + new Date());
                        });

                    }


                    scope.exportToExcel = function () {
                        excelService().exportDataValues(scope.datasource.id);
                    }



                    scope.subscribe("DataValueAdded", function (dataValue) {
                        if (!scope.datasource) return;
                        if (dataValue.dataSourceId === scope.datasource.id) {
                            if (scope.datasource.primaryInputSource === "automatic") {
                                scope.newValuesCounter += 1; // If automatic
                            } else {
                                scope.getLatestValues();
                            }
                        }
                    });

                    scope.subscribe("DataValueUpdated", function (dataValue) {
                        if (!scope.datasource) return;
                        if (dataValue.dataSourceId === scope.datasource.id) {
                            scope.getLatestValues();
                        }
                    });
                    scope.subscribe("DataValueDeleted", function (dataValueDeleted) {
                        if (!scope.datasource) return;
                        if (dataValueDeleted.dataSourceId === scope.datasource.id) {
                            //scope.getLatestValues();
                            var selected = $filter('filter')(scope.tableParams.data, { id: dataValueDeleted.valueId });
                            if (selected.length > 0) {
                                var index = scope.tableParams.data.indexOf(selected[0]);
                                if (index > -1) {
                                    scope.tableParams.data.splice(index, 1);
                                }
                            }
                        }
                    });
                    scope.edit = function (item) {
                        scope.selectedItemForUpdate = item;
                    }

                    scope.delete = function (item) {
                        dataService().delete(item.id).then(function () {

                        });
                    }
                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                    scope.getLatestValues = function (params) {
                        if (!scope.datasource) return;
                        scope.newValuesCounter = 0;



                        scope.buildColumns(scope.datasource);
                        scope.tableParams = new NgTableParams({
                            sorting: { valueDate: "desc" },
                        },
                            {
                                getData: scope.getTableValues
                            });

                    }

                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            filters: scope.filters,
                            timePeriod: scope.timeperiod,
                            sorting: sorting
                        }
                        return dataService().getTableValues(scope.datasource.id, dbParams).then(function (data) {
                            angular.forEach(data.values, function (registration) {
                                var asset = $filter('filter')(scope.assets, { id: registration.assetId }, true);
                                if (asset && asset.length > 0) {
                                    registration.assetName = asset[0].name;
                                } else {
                                    if (registration.assetId) {
                                        registration.assetName = registration.assetId;
                                    }
                                }
                                var area = $filter('filter')(scope.areas, { id: registration.areaId }, true);
                                if (area && area.length > 0) {
                                    registration.areaName = area[0].name;
                                } else {
                                    if (registration.areaId) {
                                        registration.areaName = registration.areaId;
                                    }
                                }
                                var project = $filter('filter')(scope.projects, { id: registration.projectId }, true);
                                if (project && project.length > 0) {
                                    registration.projectName = project[0].displayName;
                                } else {
                                    if (registration.projectId) {
                                        registration.projectName = registration.projectId;
                                    }
                                }

                            });
                            scope.totalValues = data.total;
                            params.total(data.total); // recal. page nav controls
                            return data.values;
                        });
                    }
                    scope.buildColumns = function (datasource) {
                        scope.columns = [
                            {
                                title: $translate.instant("COMMON_DATE"),
                                field: 'valueDate',
                                visible: true,
                                sortable: 'valueDate',
                                getValue: renderValue,
                                dataType: "date"
                            },
                            {
                                title: $translate.instant("COMMON_REGISTRATION"),
                                field: 'registrationDate',
                                visible: true,
                                sortable: 'registrationDate',
                                getValue: renderValue,
                                dataType: "timestamp"
                            },
                            {
                                title: $translate.instant("COMMON_VALUE"),
                                field: 'value',
                                sortable: 'value',
                                getValue: renderValue,
                                visible: true,
                                dataType: "text"
                            }
                        ];
                        var listIndex = -1;
                        for (var i = 0; i < datasource.elements.length; i++) {
                            var element = datasource.elements[i];
                            var dataField = element.sourceColumn;
                            if (dataField === "assetId") dataField = "assetName";
                            if (dataField === "areaId") dataField = "areaName";
                            if (dataField === "projectId") dataField = "projectName";
                            scope.columns.push({
                                title: element.label,
                                field: dataField,
                                getValue: renderValue,
                                visible: true,
                                sortable: dataField,
                                dataType: element.type,
                                sourceColumn: element.sourceColumn
                            });
                        }
                        var deleteColumn =
                        {
                            title: $translate.instant("COMMON_ACTION"),
                            visible: true,
                            dataType: "command"
                        }
                        scope.columns.push(deleteColumn);
                    }

                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            return $sce.trustAsHtml(`<datetime-viewer mode="long" date="${value}"></datetime-viewer>`)
                        }
                        if (this.dataType === "timestamp") {
                            return $sce.trustAsHtml(`<datetime-viewer mode="long" date="${value}"></datetime-viewer>`)
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        } if (this.dataType === "user") {
                            var user = $filter('filter')(scope.allUsers, { userId: value }, true);
                            if (user.length > 0) {
                                return user[0].fullName;
                            }
                        }
                        if (this.dataType === "list") {
                            var listitem = $filter('filter')(scope.dataLists[this.sourceColumn], { id: parseInt(value) }, true);
                            if (listitem.length > 0) {
                                return listitem[0].name;
                            }
                        }
                        return value;
                    }


                }

            }
        }]);
