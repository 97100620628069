angular
    .module('DigiLean')
    .directive("smartRowNoCell", [
        function () {
            return {
                templateUrl: 'smartRowNoCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'rowindex': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.number = null;

                    scope.$watch('rowindex', function (index) {
                        if (index || index === 0) {
                            scope.number = index + 1;
                        }
                    });

                    scope.$watch('model', function (model) {
                        if (model) {
                            model.getSortValue = function () {
                                return scope.number;
                            }
                        }
                    });

                }
            }
        }]);
