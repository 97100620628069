import type { PreferenceKeyValue, User, UserFullProfile, UserPreference, UserProfileImageInfo } from "@api"
import config from "@common/config"
import { DigiLeanAuthUser, RoleArray } from "@common/model/types"
import backend from "@common/services/backendHttp"
import { setAuthUser, authUser, setFullUserProfile, setUserProfile } from "@common/stores/userStore"
import { User as OidcUser} from "oidc-client-ts"

const profileImageContainerUrl = `${config.fileStoreUrl}/profile-images`
let resourceUrl = "api/user"

authUser.subscribe(user => {
    if (user && user.loggedIn) {
        loadUserProfile()
        loadUserFullProfile()
    } else {
        setUserProfile({})
        setFullUserProfile({})
    }
})

export const getAllUsers = async () => {
    const all = await backend.get<User[]>(resourceUrl)
    return all
}

export const loadUserFullProfile = async () => {
    const fullProfile = await backend.get<UserFullProfile>(`${resourceUrl}/getfullprofile`)
    if (fullProfile.settings && typeof fullProfile.settings.settings === "string") {
        fullProfile.settings = JSON.parse(fullProfile.settings.settings);
    }
    setFullUserProfile(fullProfile)
    return fullProfile
}

export const loadUserProfile = async () => {
    const profile = await backend.get<User>(`${resourceUrl}/getuserprofile`)
    setUserProfile(profile)
    return profile
}

export const getUsersProfileImage = async () => {
    const userpi = await backend.get<UserProfileImageInfo[]>("profile/GetUsersWithProfileImage")
    return userpi
}

export const getProfileImageFilestore = (imageSize: string, fileName: string) => {
    return `${profileImageContainerUrl}/${imageSize}-${fileName}`
}

export const getUserPreference = async (key: string) => {
    const url = `${resourceUrl}/preference/${key}`
    const setting = await backend.get<UserPreference>(url)
    if (setting.value && typeof setting.value === "string")
        return JSON.parse(setting.value)
    return []
}

export const deleteUserPreference = async (key: string) => {
    const url = `${resourceUrl}/preference/${key}`
    return await backend.delete<UserPreference>(url)
}

export const updateUserPreference = async (key: string, value: PreferenceKeyValue) => {
    const url = `${resourceUrl}/preference`
    let valString = ""
    if (value  && typeof value !== "string")
        valString = JSON.stringify(value)

    const preference = {
        key: key,
        value: valString
    }
    const setting = await backend.put<UserPreference>(url, preference)
    if (setting.value && typeof setting.value === "string")
        return JSON.parse(setting.value)
    return []
}

export function setAuthUserFromOidc (user: OidcUser) {
    const authUser = convertOidcDigiLeanUser(user)
    setAuthUser(authUser)
}

function convertOidcDigiLeanUser (user: OidcUser) {
    let authUser: DigiLeanAuthUser = {
        loggedIn: true,
        token: user.access_token,
        idToken: user.id_token,
        roles: new RoleArray()
    }
    
    authUser.customerId = parseInt(user.profile.customer_id as string)
    authUser.userName = user.profile.preferred_username
    authUser.userId = user.profile.sub
    authUser.idp = user.profile.idp as string
    authUser.language = user.profile.locale
    authUser.tokenExpire = user.expires_at
    
    authUser.roles = new RoleArray(user.profile.role as string | string[])
    
    return authUser
}