angular
.module('DigiLean')
.directive("actionlistA3", ['$filter','a3Service',
    function ($filter, a3Service) {
        return {
            templateUrl: 'actionlistA3.html',
            restrict: 'E',
            scope: {
                'assetid': '=',
                'a3Count': '=',
                'a3OverdueCount': '=',
                'readonly': '@'
            },
            link: function (scope, element, attrs) {
                //scope.tasks = [];
                scope.a3s = [];

                scope.$watch("assetid", function () {
                    if (!scope.assetid) return;
                    loadA3s(scope.assetid);
                });
 
                
                scope.subscribe("A3ActionListStatusChanged", function(status) {
                    // current board
                    var target = $filter('filter')(scope.a3s, { "id" : status.a3Id });
                    if (target.length == 1) {
                        target[0].a3taskCount = status.total;
                        target[0].a3taskOverdueCount = status.overdue;
                     
                    }
                    calculateTaskCounts(scope.a3s);
                });

                function calculateTaskCounts(a3s) {
                    var total = 0;
                    var overdue = 0;
                    if(a3s){
                        for (var t=0; t<a3s.length; t++){
                            total += a3s[t].a3taskCount;
                            overdue += a3s[t].a3taskOverdueCount;
                        }
                    }
                    scope.a3taskCount = total;
                    scope.a3taskOverdueCount = overdue;
                    var status = {
                        total: scope.a3taskCount,
                        overdue: scope.a3taskOverdueCount
                    }
                    scope.publish("A3ActionListCountChanged", status)
                }

                function loadA3s(assetId) {
                    
                    a3Service().getA3sForAssetWithActionItems(assetId).then(function(a3s) {                           
                        scope.a3s = a3s;
                        calculateTaskCounts(scope.a3s);
                    });
                }
            }
        }
    }]);
