angular
    .module('DigiLean').directive("strategyLinks", ['strategyService',
        function (strategyService) {
            return {
                templateUrl: 'strategyLinks.html',
                restrict: 'E',
                scope: {
                    'strategyGoalId': '<',
                    'assetId': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.uploadedFiles = [];
                    scope.files = [];
                    scope.isLoading = true;
                    var currentAssetId = null;
                    scope.toogleEditModeOn = true;
                    if(scope.isEditMode){
                        scope.toogleEditModeOn = false;
                    }

                    scope.title = "";
                    scope.url = "";

                    scope.$watch("strategyGoalId", function (strategyGoalId) {
                        loadData();
                    });

                    scope.$watch("assetId", function (assetId) {
                        if(currentAssetId != assetId){
                            currentAssetId = scope.assetId;
                            loadData();
                        }
                        
                    });

                    function loadData(){
                        if (!scope.strategyGoalId) return;
                        
                        scope.isLoading = true;
                        strategyService().getLinks(scope.strategyGoalId, scope.assetId).then(function(links) {
                            scope.links = links;
                            scope.isLoading = false;
                        });
                    }

                    scope.deleteLink = function (link) {
                        var i = scope.links.indexOf(link);
                        scope.links.splice(i, 1);
                        strategyService().deleteLink(scope.strategyGoalId, link.id).then(function() {

                        });
                    }

                    scope.addLink = function () {
                        var link = {
                            title:scope.title,
                            url : scope.url
                        }
                        strategyService().addLink(scope.strategyGoalId, scope.assetId, link).then(function(stored) {
                            scope.isEditMode = false;
                            scope.title = "";
                            scope.url = "";
                            scope.links.push(stored);
                        });
                    };
                    scope.toggleEditMode = function () {
                        scope.isEditMode = !scope.isEditMode ;
                    };

                }

            }
        }]);
