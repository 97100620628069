angular
.module('DigiLean')
.directive("deviationseveritySelector", ['$filter', 'deviationService', 
    function ($filter, deviationService) {
        return {
            templateUrl: 'deviationseverityselector.html',
            restrict: 'E',
            scope: {
                'filters': '=',
                'source': '=',
                'filterChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {
                scope.includeAll = true;
                scope.severities = [];

                function getList() {
                    scope.severities = [
                        {
                            label: deviationService().getSeverityText("1"),
                            severity: "1"
                        },
                        {
                            label: deviationService().getSeverityText("2"),
                            severity: "2"
                        },
                        {
                            label: deviationService().getSeverityText("3"),
                            severity: "3"
                        },
                        {
                            label: deviationService().getSeverityText(""),
                            severity: ""
                        }
                    ];
                    applyFilter();
                }

                scope.$watch('source', function () {
                    getList();
                });


                function applyFilter(){
                    if (scope.filters && scope.severities) {
                        var selectedFilter = scope.filters[0];
                        for (var k = 0; k < scope.severities.length; k++) {
                            var index = selectedFilter.items.indexOf(scope.severities[k].severity);
                            if (index > -1){
                                scope.severities[k].include = true;
                            } else {
                                scope.severities[k].include = false;
                            }                     
                        }

                        var selected = $filter('filter')(scope.severities, { include: true });
                        if (selected.length === scope.severities.length && selected.length === 0) {
                            scope.includeAll = true;
                        } else {
                            scope.includeAll = false;
                        }
                    } else if (scope.severities) {
                        for (var i = 0; i < scope.severities.length; i++) {
                            scope.severities[i].include = true;                     
                        }
                    }
                }

                scope.deselectAll = function (include) {
                    for (var k = 0; k < scope.severities.length; k++) {
                        scope.severities[k].include = include;                     
                    }
                    scope.modifyFilter();
                };

                scope.modifyFilter = function(){
                    var selected = $filter('filter')(scope.severities, { include: true });
                    if(selected.length === scope.severities.length){
                        scope.includeAll = true;
                        scope.filters = null;
                    } else {
                        scope.includeAll = false;
                        var selectedItems = [];
                        for(var i=0; i < selected.length; i++){
                             selectedItems.push(selected[i].severity);
                        }
                        scope.filters = [{
                            sourceColumn: scope.source,
                            operator: "InList",
                            items: selectedItems
                        }];
                    }
                    scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                };

            }
        }
    }]);
