var DigiLean = angular.module('DigiLean');
DigiLean.controller('a3reportsController', ['$scope', '$filter', '$translate', 'NgTableParams', 'a3Service',
    function ($scope, $filter, $translate, NgTableParams, a3Service) {
        $scope.name = 'World';
        $scope.newPieChart = {
            data: [0, 0, 0, 0],
            options: {
                radius: 18,
                fill: ["#f3f3f4"],
            }
        };
        $scope.planPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#23c6c8", "#f3f3f4", "#f3f3f4", "#f3f3f4"],
            }
        };
        $scope.doPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#23c6c8", "#23c6c8", "#f3f3f4", "#f3f3f4"],
            }
        };
        $scope.checkPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#23c6c8", "#23c6c8", "#23c6c8", "#f3f3f4"]
            }
        };
        $scope.actPieChart = {
            data: [1, 1, 1, 1],
            options: {
                radius: 18,
                fill: ["#23c6c8", "#23c6c8", "#23c6c8", "#23c6c8"]
            }
        };

        a3Service().getProjectInfoForA3s().then(function (a3s) {
            angular.forEach(a3s, function (a3) {
                a3.statusorder = getStatusOrder(a3.status);
                checkMilestones(a3);
                calculateRealization(a3);
            })
             $scope.tableParams = new NgTableParams({}, { dataset: a3s });
        });

        function calculateRealization(a3) {
            if (a3.estimatedSavings && a3.estimatedTotalCost) {
                a3.estimated = a3.estimatedSavings - a3.estimatedTotalCost;
            }
            if (a3.actualSavings && a3.actualTotalCost) {
                a3.realized = a3.actualSavings - a3.actualTotalCost;
            }
        }

        function checkMilestones(a3) {
            if (a3.milestones && a3.milestones.length > 0) {
                a3.milestoneMissed = false;
                for (var i = 0; i < a3.milestones.length; i++) {
                    if (a3.milestones[i].actualDate) {
                        a3.milestones[i].status = "completed";
                    } else if (a3.milestones[i].plannedDate) {
                        //check if overdue/missed
                        var duration = moment.duration(moment().diff(moment(a3.milestones[i].plannedDate)));
                        var days = duration.asDays();
                        if (days > 1) {
                            a3.milestones[i].status = "missed";
                            a3.milestoneMissed = true;
                        } else {
                            a3.milestones[i].status = "future";
                        }
                    }
                }
            }
        }

        $translate(['COMMON_TITLE', 'COMMON_STATUS']).then(function (translations) {
            $scope.translatedTitle = translations.COMMON_TITLE;
            $scope.translatedStatus = translations.COMMON_STATUS;
        });
        $scope.$on('$translateChangeSuccess', function () {
            $scope.translatedTitle = $translate.instant('COMMON_TITLE');
            $scope.translatedStatus = $translate.instant('COMMON_STATUS');
        });
        $scope.translatedTitel = [
            { id: "text", label: "Small text", languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
            { id: "description", label: "Larger text", languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
            { id: "number", label: "Number", languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
            { id: "bool", label: "Boolean", languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
            { id: "asset", label: "Asset", languageCode: "COMMON_ASSET", sourceColumn: "assetId" },
            { id: "area", label: "Area", languageCode: "COMMON_AREA", sourceColumn: "areaId" },
            { id: "project", label: "Project", languageCode: "COMMON_PROJECT", sourceColumn: "projectId" },
        ];

        $scope.toggleShowMilestone = function (a3) {
            a3.showMilestone = !a3.showMilestone;
        };

        function getStatusOrder(dbstatus) {
            switch (dbstatus) {
                case "New":
                    return 1;
                case "Plan":
                    return 2;
                case "Do":
                    return 3;
                case "Check":
                    return 4;
                case "Act":
                    return 5;
                case "Closed":
                    return 6;
                default:
                    return 0;
            }
        }

        //$scope.tableParams = new NgTableParams({}, { dataset: a3});  

    }]);

