angular
    .module('DigiLean')
    .directive("registrationtarget", ['dataSourceService',
        function (dataSourceService) {
            return {
                templateUrl: 'registrationtarget.html',
                restrict: 'E',
                scope: {
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'isAdminMode': '='
                },
                link: function (scope, elem, attrs) {

                    scope.operators = [
                        {dbCode:">=", html:"&gt;="},
                        {dbCode:">", html:"&gt;"},
                        {dbCode:"<=", html:"&lt;="},
                        {dbCode:"<", html:"&lt;"},
                    ];

                    scope.redoperators = [
                        {dbCode:">=", html:"&gt;="},
                        {dbCode:">", html:"&gt;"},
                        {dbCode:"<=", html:"&lt;="},
                        {dbCode:"<", html:"&lt;"},
                    ];

                    scope.selectedGreenOperator = null;
                    scope.selectedRedOperator = null;

                    scope.greenvalue = null;
                    scope.redvalue = null;
                    scope.yellowDescription = "";

                    scope.notValidExpression = false;
                    scope.notValidDate = false;

                    scope.targets = [];
                    
                    scope.selectedDate = moment().startOf('day').toDate(); 
                    scope.value = null;
                    scope.selectedItemForUpdate = null;
                    scope.isProcessing = false;

                    scope.$watch('settings', function (settings) {
                        // apply
                        if (settings) {
                            scope.setDataSource(settings.dataSource.id);
                            setTargets(settings.dataSource.id);
                        } 
                    });

                    scope.setDataSource = function (id) {
                        dataSourceService().get(id).then(function(dataSource) {
                            scope.dataSource = dataSource;
                            var componentSettings = {
                                dataSource: dataSource
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                            // Add value property for element
                            angular.forEach(scope.dataSource.elements, function(element) {
                                element.value = null;
                            });
                        });
                    }

                    function setTargets(dataSourceId){
                        dataSourceService().getTargets(dataSourceId).then(function(targets) {
                            angular.forEach(targets, function (registration) {
                                if(registration.greenOperator && registration.greenValue){
                                    registration.yellowDescription = getYellowOperator(registration.greenOperator) + " " + registration.greenValue;
                                    if(registration.redOperator && registration.redvalue) {
                                        registration.yellowDescription += " and ";
                                    }
                                }
                                if(registration.redOperator && registration.redValue){
                                    registration.yellowDescription +=  getYellowOperator(registration.redOperator) + " " + registration.redValue;
                                }
                                var validFromDate = moment(registration.validFrom);
                                if(validFromDate.isSame(scope.selectedDate)){
                                    scope.notValidDate = true;
                                }
                            });
                            scope.targets = targets;
                        });
                    }

                    scope.toDate = moment().toDate();

                    scope.selectedDateChangedEvent = function ($event) {
                        if ($event.originalEvent.detail) {
                            let date = $event.originalEvent.detail
                            selectedDateChanged(date)
                        }
                    }

                    const selectedDateChanged = function (date) {
                        scope.selectedDate = date
                        scope.notValidDate = false
                        angular.forEach(scope.targets, function (target) {
                            var validFromDate = moment(target.validFrom)
                            if(validFromDate.isSame(scope.selectedDate)){
                                scope.notValidDate = true
                            }
                        })
                    }

                    scope.greenChanged = function(){
                        if(scope.selectedGreenOperator && scope.greenvalue){
                            setAvailableRedOperator(scope.selectedGreenOperator.dbCode);
                        }
                        setYellowText();
                        validateExpression();
                    }

                    function validateExpression(){
                        if(scope.selectedGreenOperator && scope.greenvalue &&
                            scope.selectedRedOperator && scope.redvalue){
                            scope.greenvalue = scope.greenvalue.toString().replace(',', '.');
                            scope.redvalue = scope.redvalue.toString().replace(',', '.');    
                            if(scope.selectedGreenOperator.dbCode === ">=" || scope.selectedGreenOperator.dbCode === ">"){
                                if(Number(scope.greenvalue) < Number(scope.redvalue)){
                                    scope.notValidExpression = true;
                                    return;
                                }
                            } else {
                                if(Number(scope.greenvalue) > Number(scope.redvalue)){
                                    scope.notValidExpression = true;
                                    return;
                                }
                            }
                        }
                        scope.notValidExpression = false;  
                    }

                    function setYellowText() {
                        if(scope.selectedGreenOperator && scope.greenvalue){
                            scope.yellowDescription = getYellowOperator(scope.selectedGreenOperator.dbCode) + " " + scope.greenvalue;
                            if(scope.selectedRedOperator && scope.redvalue) {
                                scope.yellowDescription += " and ";
                            }
                        }
                        if(scope.selectedRedOperator && scope.redvalue){
                            scope.yellowDescription +=  getYellowOperator(scope.selectedRedOperator.dbCode) + " " + scope.redvalue;
                        }
                    }

                    scope.redChanged = function(){
                        setYellowText();
                        validateExpression();
                    }

                    function setAvailableRedOperator(greenoperator){
                        var newRedOperators;
                        switch (greenoperator) {
                            case ">=":
                                newRedOperators = [
                                    {dbCode:"<=", html:"&lt;="},
                                    {dbCode:"<", html:"&lt;"}
                                ];
                                break;
                            case ">":
                                newRedOperators = [
                                    {dbCode:"<=", html:"&lt;="},
                                    {dbCode:"<", html:"&lt;"}
                                ];
                                break;
                            case "<=":
                                newRedOperators = [
                                    {dbCode:">=", html:"&gt;="},
                                    {dbCode:">", html:"&gt;"}
                                ];
                                break;
                            case "<":
                                newRedOperators = [
                                    {dbCode:">=", html:"&gt;="},
                                    {dbCode:">", html:"&gt;"}
                                ];
                                break; 
                        } 
                        if(!(scope.redoperators && scope.redoperators[0].dbCode === newRedOperators[0].dbCode && scope.redoperators.length === newRedOperators.length)){
                            scope.redoperators = newRedOperators;
                        }      
                    }

                    function getYellowOperator(operator){
                        switch (operator) {
                            case ">=":
                                return "<";
                            case ">":
                                return "<=";
                            case "<=":
                                return ">";
                            case "<":
                                return ">=";
                        }
                    }
                    scope.save = function () {
                        scope.isProcessing = true;
                        var utcDate = moment.utc(scope.selectedDate).format();
                        var redOperator = null;
                        if(scope.selectedRedOperator){
                            redOperator = scope.selectedRedOperator.dbCode;
                        }
                        var registration = {
                            datasourceid:  scope.dataSource.id,
                            validFrom: utcDate, 
                            greenvalue: scope.greenvalue,
                            greenoperator: scope.selectedGreenOperator.dbCode,
                            redvalue: scope.redvalue,
                            redoperator: redOperator,
                        }

                        dataSourceService().addTarget(scope.dataSource.id,registration).then(function (savedRegistration) {
                            clearRegistration();
                            setTargets(scope.dataSource.id);
                        });
                    }

                    scope.delete = function (target) {
                        dataSourceService().deleteTarget(target.id).then(function (result) {
                           //Remove target from targets
                           var index = scope.targets.indexOf(target);
                                if (index > -1) {
                                    scope.targets.splice(index, 1);
                                }
                        });
                    }


                    function clearRegistration() {
                        scope.isProcessing = false;
                        selectedDateChanged(moment().startOf('day').toDate());
                        
                        scope.selectedGreenOperator = null;
                        scope.selectedRedOperator = null;

                        scope.greenvalue = null;
                        scope.redvalue = null;
                        scope.yellowDescription = "";

                        scope.notValidExpression = false;
                    }

                }

            }
        }]);
