angular
    .module('DigiLean')
    .directive("smartRiskCell", ['debounce',
        function (debounce) {
            return {
                templateUrl: 'smartRiskCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.score = null;
                    scope.probability = null;
                    scope.consequence = null;
                    var rowId = null;
                    var columnId = null;

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == rowId && attribute.projectBoardAttributeId == columnId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == rowId && tablecell.a3TableColumnId == columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });

                    scope.$watch('model', function (model) {
                        var value = null;
                        if (scope.type == 'project') {  
                            rowId = scope.model.project.id;
                            columnId = scope.model.boardAttributeId;
                            value = model.projectAttribute.value;
                        } else if (scope.type == 'a3') {
                            rowId = scope.model.a3.id;
                            columnId = scope.model.columnId;
                            if(model.a3TableCell){
                                value = model.a3TableCell.value;
                            }             
                        }
                        if (value) {
                            setValue(value);
                        }
                        model.getSortValue = function() {
                            return scope.score;
                        }
                    });

                    function calculateScore(){
                        scope.score = scope.probability * scope.consequence;

                        scope.background = "inherit";
                        if(scope.score < 4){
                            scope.background = '#5cb85c';
                        } else if (scope.score >= 4 && scope.score < 10){ 
                            scope.background = '#f8c500';
                        } else if (scope.score >= 10 && scope.score < 13){
                            scope.background = '#f8ac59';
                        } else if (scope.score > 12){
                            scope.background = '#d9534f';
                        }
                    }
                    var setValue = function (cell) {
                        scope.probability = cell.probability;
                        scope.consequence = cell.consequence;
                        calculateScore();
                    }



                    scope.probabilityChanged = function (score) {
                        scope.probability = score;
                        calculateScore();
                        scope.commitChangesDebounced();
                    }

                    scope.consequenceChanged = function (score) {
                        scope.consequence = score;
                        calculateScore();
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var value = {
                            probability: scope.probability,
                            consequence: scope.consequence
                        }
                        if(scope.type == 'project'){
                            scope.model.projectAttribute.value = value;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        }else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = value;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: value
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);


                }
            }
        }]);
