angular
    .module('DigiLean')
    .directive("smartRatingCell", ['debounce',
        function (debounce) {
            return {
                templateUrl: 'smartRatingCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.rating = 0;
                    var rowId = null;
                    var columnId = null;

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == rowId && attribute.projectBoardAttributeId == columnId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });
                    scope.$watch('model', function (model) {
                        var value = null;
                        if (scope.type == 'project') {
                            columnId = model.boardAttributeId;
                            rowId = model.project.id;
                            value = model.projectAttribute.value;              
                            
                        } else if (scope.type == 'a3') {
                            columnId = model.columnId;
                            rowId = model.a3.id;
                            if(model.a3TableCell) {
                                value = model.a3TableCell.value;             
                            }
                        }
                        if (value) {
                            setValue(value);
                        }
                        model.getSortValue = function() {
                            return scope.rating;
                        }
                    });

                    function calculateScore(){
                        scope.score = scope.probability * scope.consequence;

                        scope.background = "inherit";
                        if(scope.score < 4){
                            scope.background = '#5cb85c';
                        } else if (scope.score >= 4 && scope.score < 10){ 
                            scope.background = '#f8c500';
                        } else if (scope.score >= 10 && scope.score < 13){
                            scope.background = '#f8ac59';
                        } else if (scope.score > 12){
                            scope.background = '#d9534f';
                        }
                    }
                    var setValue = function (cell) {
                        
                        //scope.updateAggregateCell();
                        scope.rating = cell.rating;
                        
                    }



                    scope.ratingChanged = function (score) {
                        if(scope.editable){
                            if(score== scope.rating){
                                scope.rating = 0;
                            } else {
                                scope.rating = score;
                            }
                            scope.commitChangesDebounced();
                        }
                    }

                    scope.saveChanges = function() {
                        var newvalue = {
                            rating: scope.rating
                        }
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = newvalue;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = newvalue;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: newvalue
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);


                }
            }
        }]);
