import { ScopeSet } from "@azure/msal-common";

var DigiLean = angular.module('DigiLean');
DigiLean.controller('strategyKPIConfigurationController', ['$scope', '$uibModalInstance', '$uibModal','$filter','$translate','modalService','strategyService','dataSourceService','configuration',
    function ($scope, $uibModalInstance, $uibModal, $filter,$translate,modalService, strategyService,dataSourceService, configuration) {
        $scope.settings = configuration;

        //Relevant for dataserie configuration
        $scope.formula = 'sum';

        $scope.firstdecision = null;
        $scope.thirddecision = null;

        $scope.operators = [
            { dbCode: ">=", html: "&gt;=" },
            { dbCode: ">", html: "&gt;" },
            { dbCode: "<=", html: "&lt;=" },
            { dbCode: "<", html: "&lt;" },
        ];
        $scope.operator = $scope.operators[0];

        function init() {
            if ($scope.settings) {
                $scope.title = $scope.settings.title;
                $scope.dataSourceId = $scope.settings.dataSourceId;
                $scope.filters = $scope.settings.filter;
                $scope.unit = $scope.settings.unit;
                $scope.targetDataSourceId = $scope.settings.targetDataSourceId;
                $scope.targetFilters = $scope.settings.targetFilter;
                $scope.targetValue = $scope.settings.targetValue;
                if($scope.settings.formula){
                    $scope.formula = $scope.settings.formula;
                }
                if($scope.settings.operator){
                    var operator = $filter('filter')($scope.operators, { dbCode: $scope.settings.operator}, true);
                    if (operator.length > 0) {
                        $scope.operator = operator[0];
                    }
                }

                if($scope.dataSourceId){
                    $scope.firstdecision = "datasource";
                    dataSourceService().get($scope.dataSourceId).then(function(dataSource) {
                        $scope.dataSource = dataSource;
                    });
                } else if($scope.title){
                    $scope.firstdecision = "manual";
                }

                if($scope.targetDataSourceId){
                    $scope.targetdecision = "datasourceTarget";
                    dataSourceService().get($scope.targetDataSourceId).then(function(dataSource) {
                        $scope.targetDataSource = dataSource;
                    });
                } else if($scope.targetValue !== null){
                    $scope.targetdecision = "manualTarget";
                }

                if ($scope.settings.startDate) {
                    $scope.startDate = moment($scope.settings.startDate).toDate();
                }
                if ($scope.settings.endDate) {
                    $scope.endDate = moment($scope.settings.endDate).toDate();
                }
            }
        }
        init();

        $scope.updateTitle = function (title){
            $scope.title = title;
        };

        $scope.updateUnit = function (unit){
            $scope.unit = unit;
        };

        $scope.startDateUpdated = function (event) {
            const orgEvt = event.originalEvent
            const startDate = orgEvt.detail
            $scope.startDate = startDate
            $scope.changedStartDate = true
            checkDate()
        }

        $scope.endDateUpdated = function (event) {
            const orgEvt = event.originalEvent
            const endDate = orgEvt.detail
            $scope.endDate = endDate
            checkDate()
        }
        function checkDate(){      
            $scope.enddateBeforeStartdate = false;
            if(!($scope.endDate && $scope.startDate)) return;
            if(moment($scope.endDate).isBefore($scope.startDate)){
                $scope.enddateBeforeStartdate = true;             
            }
        }

        /*
        $scope.updateTargetValue = function (targetValue){
            $scope.targetValue = targetValue;
            $scope.targetDataSource = null;
        };*/

        $scope.operatorChanged = function (operator){
            $scope.operator = operator;
        };

        $scope.delete = function(){
            if($scope.settings && $scope.settings.id){
                var modalOptions = {
                    closeButtonText: $translate.instant('COMMON_CANCEL'),
                    actionButtonText: $translate.instant('COMMON_DELETE'),
                    headerText: $scope.title,
                    bodyText: $translate.instant('ADMIN_MESSAGE_DELETE_ROW_CONFIRMATION')   
                };
    
                modalService.showModal({}, modalOptions).then(function (result) {
                    strategyService().deleteKPI($scope.settings.id).then(function () {
                    });
                });
            }
            $uibModalInstance.close("Delete");
            
        };

        
        $scope.selectDataSource = function () {
            var hasDataSerie = false;
            if ($scope.dataSource) {
                hasDataSerie = true;
            }
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'dataSourceSingleSelector.html',
                controller: 'dataSourceSingleSelector',
                windowClass: 'newdeviation-modal-window',
                resolve: {
                    hasDataSerie: function () {
                        return hasDataSerie;
                    },
                    dataSource: function () {
                        return $scope.dataSource;
                    },
                    filters: function () {
                        return $scope.filters;
                    },
                    withTarget: function () {
                        return false;
                    },
                    type: function () {
                        return null;
                    },
                    externalOnly: function () {
                        return true;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.dataSource = result.dataSource;
                if(result.dataSource.valueElement){
                    $scope.unit = result.dataSource.valueElement.unit;
                } 
                $scope.filters = result.filters;
                if(!$scope.title){
                    $scope.title =  $scope.dataSource.title;       
                }
            });
        };

        $scope.selectTargetDataSource = function () {
            var hasDataSerie = false;
            if ($scope.targetDataSource) {
                hasDataSerie = true;
            }
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'dataSourceSingleSelector.html',
                controller: 'dataSourceSingleSelector',
                windowClass: 'newdeviation-modal-window',
                resolve: {
                    hasDataSerie: function () {
                        return hasDataSerie;
                    },
                    dataSource: function () {
                        return $scope.targetDataSource;
                    },
                    filters: function () {
                        return $scope.targetFilters;
                    },
                    withTarget: function () {
                        return false;
                    },
                    type: function () {
                        return null;
                    },
                    externalOnly: function () {
                        return true;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.targetDataSource = result.dataSource;
                $scope.targetFilters = result.filters;
            });
        };

        $scope.changeFormula = function(newFormula){
            $scope.formula = newFormula;
        }

        function isInputOk(){
            $scope.titleMissing = false;
            $scope.dataSourceMissing = false;
            $scope.targetMissing = false;
            if(!$scope.title || $scope.title == ''){
                $scope.titleMissing = true;
            }

            if($scope.firstdecision== 'datasource' && !$scope.dataSource ){
                $scope.dataSourceMissing = true;
            }
            
            if(!$scope.targetDataSource && $scope.targetdecision === 'datasourceTarget'){
                $scope.targetMissing = true;
            }
            if($scope.titleMissing || $scope.dataSourceMissing || $scope.targetMissing){
                return false;
            } else {
                return true;
            }
        }

        $scope.ok = function () {
            if(isInputOk()){
                $scope.settings.title = $scope.title;
                if($scope.dataSource){
                    $scope.settings.dataSourceId = $scope.dataSource.id;
                } else {
                    $scope.settings.dataSourceId = null;
                }
                $scope.settings.dataSource = $scope.dataSource;
                $scope.settings.filters = $scope.filters;
                if($scope.targetDataSource){
                    $scope.settings.targetDataSourceId = $scope.targetDataSource.id;
                } else {
                    $scope.settings.targetDataSourceId = null;
                }
                
                $scope.settings.targetDataSource = $scope.targetDataSource;
                $scope.settings.targetFilters = $scope.targetFilters;
                $scope.settings.unit = $scope.unit;
                $scope.settings.formula = $scope.formula;
                $scope.settings.operator = $scope.operator.dbCode;

                if($scope.startDate){
                    $scope.settings.startDate = $scope.startDate;
                }
                if($scope.endDate){
                    $scope.settings.endDate = $scope.endDate;
                }
    
                $uibModalInstance.close($scope.settings);
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);