import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import { getUserProfile } from "@common/stores/userStore"

angular
    .module('DigiLean')
    .controller("newSuggestionModalController", ['$scope', '$uibModalInstance', '$filter', 'navigationService', 'projectService', 'suggestionService', 'strategyService', 'mathService', 'suggestion',
        function ($scope, $uibModalInstance, $filter, navigationService, projectService, suggestionService, strategyService, mathService, suggestion) {

            // the new action object
            $scope.suggestion = suggestion;
            $scope.asset = null;
            $scope.assets = [];
            $scope.categories = [];
            $scope.selectedCategory = "";
            $scope.uploadedFiles = [];
            $scope.users = [];

            $scope.hasStrategy = navigationService().hasModule("STRATEGY");
            $scope.goals = [];
            $scope.hasProject = navigationService().hasModule("PROJECT");
            $scope.project = null;
            $scope.projects = [];

            // Changed handler for text => HtmlTextArea
            $scope.descriptionChanged = function (content) {
                $scope.suggestion.text = content;
            }
            // if page is refreshed we need to refresh the access to modules
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.hasProject = navigationService().hasModule("PROJECT");
                loadProjects();
                $scope.hasStrategy = navigationService().hasModule("STRATEGY");
                loadGoals();
            });

            $scope.currency = customerService.getDefaultCurrency()
            getCompanySetting("currency").then(function (setting) {
                if (setting) {
                    $scope.currency = setting
                }
            })

            function loadProjects() {
                if ($scope.hasProject) {
                    projectService().getOngoingProjects().then(function (projects) {
                        $scope.projects = projects;
                        $scope.projectmodel = { projectId: $scope.suggestion.projectId,
                            projects: $scope.projects}
                    });
                }
            }
            loadProjects();

            $scope.projectChangeHandler = function (projectinfo) {
                if (projectinfo) {
                    $scope.suggestion.projectId = projectinfo.project.id;
                } else {
                    $scope.suggestion.projectId = null;
                }                       
            }

            $scope.assetChangeHandler = function (assetinfo) {
                if (assetinfo) {
                    $scope.suggestion.assetId = assetinfo.asset.id;
                    $scope.asset = assetinfo.asset;
                    customerService.getUsersForAsset(assetinfo.asset.id).then(function (assetUsers) {
                        $scope.users = assetUsers;
                        angular.forEach($scope.users, function (user, key) {
                            user.fullName = user.firstName + " " + user.lastName;
                        });
                        var match = $filter('filter')($scope.users, { userId: $scope.user.userId });
                        if (match.length > 0) {
                            $scope.user = match[0];
                        }
                    });
                } else {
                    $scope.suggestion.assetId = null;
                    $scope.asset = null;
                }       
                $scope.suggestion.goals = null;
                loadGoals();
            }

            function loadGoals() {
                if ($scope.hasStrategy) {
                    if ($scope.asset && $scope.asset.id) {
                        loadTeamGoals();
                    } else {
                        loadBreakthroughGoals();
                    }
                }
            }

            function loadTeamGoals() {
                strategyService().getAllActiveTeamGoals($scope.asset.id).then(function (goals) {
                    if (goals && goals.length > 0) {
                        $scope.goals = goals;
                        $scope.breakgoals = [];
                    } else {
                        $scope.goals = [];
                        loadBreakthroughGoals();
                    }
                });
            }

            function loadBreakthroughGoals() {
                strategyService().getActiveBreakthroughObjectives().then(function (result) {
                    $scope.breakgoals = result;
                    $scope.goals = [];
                });
            }
 
            loadGoals();
 
            function refresh() {
                const currentUser = getUserProfile()
                $scope.users.push(currentUser);
                $scope.user = currentUser;
                $scope.user.userId = currentUser.id;
                $scope.user.fullName = $scope.user.firstName + " " + $scope.user.lastName;
                $scope.suggestion.suggestedByUserId = $scope.user.userId;
                $scope.suggestion.suggestedBy = $scope.user.fullName;
            }
            refresh();

            assetService.getList().then(function (assets) {
                $scope.assets = assets;
                for (var k = 0; k < $scope.assets.length; k++) {
                    $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                }

                if ($scope.suggestion.assetId) {
                    var match = $filter('filter')($scope.assets, { id: $scope.suggestion.assetId }, true);
                    if (match.length > 0) {
                        $scope.asset = match[0];
                    }
                }
                $scope.assetmodel = { assetId: $scope.suggestion.assetId,
                    assets: $scope.assets};

            });

            suggestionService().getCategories().then(function (categories) {
                $scope.categories = categories;
            });

            $scope.categoryChanged = function (category) {
                $scope.selectedCategory = category;
            }

            $scope.changeProject = function (project) {
                $scope.project = project;
                if ($scope.project && $scope.project.id) {
                    $scope.suggestion.projectId = $scope.project.id;
                } else {
                    $scope.suggestion.projectId = null;
                }
            };

            $scope.changeSuggestedBy = function (user) {
                $scope.user = user;
            };

            $scope.checkEstimatedCostOfInvestment = function () {
                $scope.suggestion.estimatedCostOfInvestment = mathService().correctForComma($scope.suggestion.estimatedCostOfInvestment);
                $scope.estimatedCostOfInvestmentIsInvalid = !mathService().isNumeric($scope.suggestion.estimatedCostOfInvestment, true);
            };
            $scope.checkEstimatedGainOfInvestment = function () {
                $scope.suggestion.estimatedGainOfInvestment = mathService().correctForComma($scope.suggestion.estimatedGainOfInvestment);
                $scope.estimatedGainOfInvestmentIsInvalid = !mathService().isNumeric($scope.suggestion.estimatedGainOfInvestment, true);
            };
            $scope.checkEstimatedTimeInvested = function () {
                $scope.suggestion.estimatedCostOfInvestmentHours = mathService().correctForComma($scope.suggestion.estimatedCostOfInvestmentHours);
                $scope.estimatedTimeInvestedIsInvalid = !mathService().isNumeric($scope.suggestion.estimatedCostOfInvestmentHours, true);
            };
            $scope.checkEstimatedTimeGained = function () {
                $scope.suggestion.estimatedGainOfInvestmentHours = mathService().correctForComma($scope.suggestion.estimatedGainOfInvestmentHours);
                $scope.estimatedTimeGainIsInvalid = !mathService().isNumeric($scope.suggestion.estimatedGainOfInvestmentHours, true);
            };


            $scope.toggleGoal = function (goal) {
                if ($scope.suggestion.goals) {
                    var match = $filter('filter')($scope.suggestion.goals, goal.id);
                    if (match.length > 0) {
                        var index = $scope.suggestion.goals.indexOf(match[0]);
                        $scope.suggestion.goals.splice(index, 1);
                    } else {
                        $scope.suggestion.goals.push(goal.id);
                    }
                } else {
                    $scope.suggestion.goals = [goal.id];
                }
            };

            function addMissingBreakthroughGoals() {
                if ($scope.suggestion.goals) {
                    for (var i = 0; i < $scope.suggestion.goals.length; i++) {
                        var match = $filter('filter')($scope.goals, $scope.suggestion.goals[i], true);
                        if (match.length > 0) {
                            var breakthroughgoalid = match[0].id;
                            var exists = $filter('filter')($scope.suggestion.goals, breakthroughgoalid, true);
                            if (exists.length == 0) {
                                $scope.suggestion.goals.push(breakthroughgoalid);
                            }
                        }
                    }
                }
            }


            $scope.ok = function () {
                // Get asset
                if ($scope.asset) {
                    $scope.suggestion.assetId = $scope.asset.id;
                    addMissingBreakthroughGoals();
                }
                $scope.suggestion.attachments = $scope.uploadedFiles;
                $scope.suggestion.suggestedByUserId = $scope.user.userId;
                $scope.suggestion.suggestedBy = $scope.user.fullName;

                if ($scope.selectedCategory !== '') {
                    $scope.suggestion.categoryId = $scope.selectedCategory.id;
                }
                if ($scope.estimatedTimeGainIsInvalid || 
                    $scope.estimatedTimeInvestedIsInvalid || 
                    $scope.estimatedGainOfInvestmentIsInvalid || 
                    $scope.estimatedCostOfInvestmentIsInvalid) {
                    return;
                }
                $uibModalInstance.close($scope.suggestion);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }]);
