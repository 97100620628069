import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

// Controller for two modual popup newtasktopersonalactionlist.html and newtasktoactionlist.html
// IS this a duplicate to actionListNewTaskController ?????
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('newTaskToActionListController', ['$scope', '$filter', '$uibModalInstance', 'boardTaskService', 'boardid', 'date',
    function ($scope, $filter, $uibModalInstance, boardTaskService, boardid, date) {
        $scope.boards = {};
        $scope.selectedEmployee = {};
        $scope.selectedBoard = null;
        
        $scope.emptyUser = {
            id: '',
            userName: null,
            profileImageUrl: null,
            fullName: null,
            displayName: null
        }
        $scope.task = { id: 0,
            isActionItem: true,
            text: "",
            status: 'blank'};
        if(date){
            $scope.task.boardDate = moment(date).toDate();
        }

        boardService.getMemberBoards().then(function (data) {
            $scope.boards = data;
            if (boardid) {
                $scope.selectedBoard = $filter('filter')(data, { id: boardid }, true)[0];
            } else {
                $scope.selectedBoard = data[0];
            }

            $scope.getUserListForBoard();
        });

        $scope.descriptionChanged = function (content) {
            $scope.task.text = content;
        }

        $scope.getUserListForBoard = function () {
            if ($scope.selectedBoard !== null) {
                boardService.getUserListForBoard($scope.selectedBoard.id).then(function (data) {
                    angular.forEach(data, function (employee, key) {
                        employee.fullName = employee.firstName + " " + employee.lastName;
                    });
                    $scope.employees = data;
                    if (data.length > 0) {
                        if($scope.task && $scope.task.responsibleUserId){
                            $scope.task.user = $filter('filter')(data, { id: $scope.task.responsibleUserId }, true)[0];
                        } 
                    }
                });
            }
        }

        // Datepicker setup
        $scope.formats = ['dd.MM.yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'shortDate']
        $scope.format = $scope.formats[0];
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.datepickerOpen = function () {
            $scope.datePicker.opened = true;
        }
        $scope.datePicker = {
            opened: false
        };
        $scope.dateOptions = timeService.globalDatePickerOptions();

        $scope.selectUser = function (user) {

            $scope.task.responsibleImageUrl = user.profileImageUrl;
            $scope.task.responsibleUserId = user.id;
            $scope.task.responsibleUser = user.fullName;
            $scope.task.responsibleDisplayName = user.displayName;
            if(user.id == ''){
                $scope.task.user = null;    
            } else {
                $scope.task.user = user;
            }
        }



        $scope.ok = function () {
            // Create new action 

            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: 0,
                    Tag: tagsArray[i],
                    BoardTaskId: 0
                });
            }
            var boardId = null;
            if ($scope.selectedBoard) {
                boardId = $scope.selectedBoard.id;
            }
            $scope.task.boardId = boardId;
            $scope.task.tags = tags;

            boardTaskService().addTask($scope.task).then(function (taskId) {
                $scope.task.id = taskId;
                $scope.publish("SendCreateActionListItem", $scope.task);
                $uibModalInstance.close($scope.task);
            });

        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };



        setTimeout(function () {
            $("#taskTags").tagit({
                // availableTags: sampleTags,
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
        }, 200);

        $scope.boardDateChanged = function(event) {
            const date = event.originalEvent.detail
            $scope.task.boardDate = date
        }
    }]);