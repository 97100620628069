import FileSaver from 'file-saver'
import config from "@common/config"
import { getUserProfile } from "@common/stores/userStore"

angular.module("DigiLean").factory('pdfService', ['$http', 'serverUrl',
    function ($http, serverUrl) {
        function pdfService() {
            var resource = "/pdf"
            var resourceUrl = serverUrl + resource

            let lang = "en"
            const up = getUserProfile()
            if (up.preferredLanguage)
                lang = up.preferredLanguage
            
            return {
                printA3: function (fileName, a3VersionId, language, timezoneOffset) {
                    resourceUrl = resourceUrl + "/printA3/" + a3VersionId + "?lang=" + language + "&timezoneOffset=" + timezoneOffset;
                    $http.post(resourceUrl, null, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                },

                saveA3: function (fileName, a3VersionId, language, timezoneOffset) {
                    resourceUrl = resourceUrl + "/savea3/" + a3VersionId + "?lang=" + language + "&timezoneOffset=" + timezoneOffset;
                    return $http.get(resourceUrl).then(function(result) {
                        return result
                    });
                },
                previewA3: function(a3VersionId, language) {
                    resourceUrl = resourceUrl + "/previewA3/" + a3VersionId + "?lang=" + language;
                    return $http.post(resourceUrl, null).then(function(data) {
                        return;
                    });
                },

                printLearningPage: function(pageId, pageTitle, isExternal) {
                    console.log("isExternal", isExternal)
                    if (!pageTitle) pageTitle = "page";
                    var fileName = pageTitle + ".pdf";
                    
                    resourceUrl = serverUrl + "/learningpage/" + pageId+ "?lang=" + lang + "&isExternal=" + isExternal
                    return $http.post(resourceUrl, null, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                },
                printLearningPageExporter: async function(pageId, pageTitle) {
                    if (!pageTitle) pageTitle = "page"
                    var fileName = pageTitle + ".pdf"
                    
                    resourceUrl = serverUrl + "/LearningPageHtml/" + pageId+ "?lang=" + lang
                    
                    const resHtml = await $http.get(resourceUrl)
                    const html = resHtml.data

                    const resPdf = await $http.post(config.pdfExportUrl, {html}, { responseType: 'arraybuffer' })
                    var file = new Blob([resPdf.data], { type: 'application/pdf' })
                    FileSaver.saveAs(file, fileName)
                    return
                },

                printDeviationReport: function(deviationId) {
                    var fileName = "deviation-report.pdf";
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
           
                    resourceUrl = resourceUrl + "/deviationreport/" + deviationId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
                    $http.post(resourceUrl, null, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                },

                 
                printDeviationProjectReport: function(projectId,timePeriod) {
                    var fileName = "deviation-report.pdf";
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
         
                    resourceUrl = resourceUrl + "/DeviationProjectReport/" + projectId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
                    $http.post(resourceUrl, timePeriod, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                },

                printProjectHoursReport: function(projectId,timePeriod) {
                    var fileName = "invoice-report.pdf";
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
         
                    resourceUrl = resourceUrl + "/InvoiceProjectReport/" + projectId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
                    $http.post(resourceUrl, timePeriod, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                },

                createImprovementListReport: function(params) {
                    var fileName = "DigiLEAN - Improvements.pdf";
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
         
                    resourceUrl = resourceUrl + "/ImprovementListReport?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
                    $http.post(resourceUrl, params, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                },

                createDeviationListReport: function(params) {
                    var fileName = "DigiLEAN - Deviations.pdf";
                    
                    var date = new Date();
                    var offset = date.getTimezoneOffset()
         
                    resourceUrl = resourceUrl + "/DeviationListReport?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
                    $http.post(resourceUrl, params, { responseType: 'arraybuffer' }).then(function(result) {
                        var file = new Blob([result.data], { type: 'application/pdf' });
                        FileSaver.saveAs(file, fileName); 
                    });
                }


            }
        }
        return pdfService;
    }]);
