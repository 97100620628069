﻿import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("a3Comments", ['$filter', '$translate', 'a3Service',
    function ($filter, $translate, a3Service) {
        return {
            templateUrl: 'a3Comments.html',
            restrict: 'E',
            scope: {
                'a3id': '=',
                'stepid': '=',
                'isvisible': '=',
                'readonly': '@',
                'commentCountChangeHandler': '&',
            },
            link: function (scope, element, attrs) {
                scope.comments = [];
                scope.comment = "";
                var translateOptions = {};
                scope.subscribe('A3StepCommentedOn', function (comment) {
                    if (comment.a3StepId === scope.stepid) {
                        comment.cssState = "slideInDown";
                        scope.comments.push(comment);
                        updateCommentsPosition();
                        translateAndRecalculateTimeElapsed();
                        scrollToBottom();
                        scope.$apply();
                        scope.commentCountChangeHandler({ commentCount: scope.comments.length })
                    }
                });

                scope.add = function () {
                    var comment = {
                        comment: scope.comment
                    }
                    a3Service().addCommentToStep(scope.a3id, scope.stepid, comment).then(function (addedComment) {
                        scope.comment = "";
                    });
                }

                scope.$watch("a3id", function () {
                    if (!scope.a3id) return;
                    a3Service().getUserlistForA3(scope.a3id).then(function (users) {

                    });
                });

                scope.$watch("stepid", function () {
                    if (!scope.stepid) return;
                    scope.comment = "";
                    a3Service().getCommentsOnStep(scope.stepid).then(function (items) {

                        scope.comments = items;
                        updateCommentsPosition();
                        translateAndRecalculateTimeElapsed();
                        scrollToBottom();
                        scope.commentCountChangeHandler({ commentCount: scope.comments.length })
                    });
                });


                scope.$watch("isvisible", function () {
                    if (!scope.isvisible) return;
                    scrollToBottom();
                });

                var updateCommentsPosition = function () {
                    var position = "left";
                    var commentedBy = "";
                    angular.forEach(scope.comments, function (item) {
                        if (commentedBy !== "" && commentedBy !== item.commentedByUserId) {
                            if (position === "left") {
                                position = "right";
                            } else {
                                position = "left";
                            }
                        }
                        commentedBy = item.commentedByUserId;
                        item.position = position;
                    });
                }

                // DEFAULT TRANSLATION
                $translate(['COMMON_TIMEELAPSED_MONTH', 'COMMON_TIMEELAPSED_DAYS', 'COMMON_TIMEELAPSED_HOURS', 'COMMON_TIMEELAPSED_MINUTES', 'COMMON_TIMEELAPSED_SECONDS', 'COMMON_TIMEELAPSED_NOW']).then(function (translations) {
                    translateOptions = {
                        month: translations.COMMON_TIMEELAPSED_MONTH,
                        days: translations.COMMON_TIMEELAPSED_DAYS,
                        hours: translations.COMMON_TIMEELAPSED_HOURS,
                        mins: translations.COMMON_TIMEELAPSED_MINUTES,
                        seconds: translations.COMMON_TIMEELAPSED_SECONDS,
                        now: translations.COMMON_TIMEELAPSED_NOW
                    }
                    // Now we can get data
                    translateAndRecalculateTimeElapsed();
                });

                var translateAndRecalculateTimeElapsed = function () {
                    // Recalculate time elapsed
                    scope.comments.forEach(function (comment) {
                        comment.occured = timeService.getTimeElapsed(comment.commentDate, translateOptions);
                    });
                }

                var scrollToBottom = function () {
                    setTimeout(function () {
                        var wtf = $('.a3-small-chat-box .content');
                        var height = wtf[0].scrollHeight;
                        wtf.scrollTop(height);
                    }, 20);

                }
            }
        }
    }]);
