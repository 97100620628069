import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("subTaskList", ['$uibModal', 'boardTaskService', 'taskDrawingService', 'debounce',
        function ($uibModal, boardTaskService, taskDrawingService, debounce) {
            return {
                templateUrl: 'subTaskList.html',
                restrict: 'E',
                scope: {
                    'parentTask': '<',
                    'targetDate': '<',
                    'isReadOnly': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.tasks = [];
                    scope.boards = [];
                    scope.canEdit = true;
                    scope.isLoading = false;
                    scope.isBoardsLoaded = false;
                    scope.isUsersLoaded = false;

                    scope.$watch('parentTask', function (parentTask) {
                        if (scope.parentTask && scope.parentTask.id > 0) {
                            scope.parentId = scope.parentTask.id
                            loadData(scope.parentId);
                        }
                    });
                    scope.$watch('isReadOnly', function (isReadOnly) {
                        if (scope.isReadOnly == true) {
                            scope.canEdit = false;
                        } else {
                            scope.canEdit = true;
                        }

                    });

                    async function loadData(taskId) {
                        await getSubTasks(taskId);
                        await loadBoards();
                        await getAllUsers();
                    }

                    var getSubTasks = function (parentId) {
                        scope.isLoading = true;
                        boardTaskService().getSubTasks(parentId).then(function (tasks) {
                            //
                            for (let index = 0; index < tasks.length; index++) {
                                const task = tasks[index];

                                setupTask(task);
                            }
                            scope.tasks = tasks;
                            scope.isLoading = false;
                        });
                    }

                    var setupTask = function (task) {
                        if (task.boardDate) {
                            task.boardDate = moment(task.boardDate).toDate();
                        }
                        task.datePicker = {
                            opened: false
                        }
                        setResponsible(task);
                        setBoard(task);
                    }

                    scope.setActive = function (task) {
                        task.active = true;
                        setTimeout(function () {
                            $(elem).find(".project-name").focus();
                        }, 50);
                    }

                    var getTaskInList = function (id) {
                        var task = scope.tasks.find(t => t.id == id);
                        return task;
                    };
                    
                    scope.subscribe("TaskUpdated", function (updatedTask) {
                        var task = getTaskInList(updatedTask.id);
                        if (task) {
                            task.title = updatedTask.title;
                            task.text = updatedTask.text;
                            task.color = updatedTask.color;
                            if(task.ResponsibleUserId != updatedTask.ResponsibleUserId){
                                task.responsibleUserId = updatedTask.ResponsibleUserId;
                                setResponsible(task);
                            }
                        }
                    });
                    scope.subscribe("TaskCommentAdded", function (comment) {
                        var task = getTaskInList(comment.boardTaskId);
                        if (task) {
                            task.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe("TaskCommentDeleted", function (comment) {
                        var task = getTaskInList(comment.boardTaskId);
                        if (task) {
                            task.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe('TaskAttachmentCountChanged', function (attachment) {
                        var task = getTaskInList(attachment.taskId);
                        if (task) {
                            task.attachmentCount = attachment.attachmentCount;
                        }
                    });

                    scope.subscribe('TaskAssigned', function (status) {
                        var task = getTaskInList(status.taskId);
                        if (task) {
                            task.responsibleUserId = status.userId;
                            setResponsible(task);
                        }
                    });

                    scope.updateDate = function (task, event) {
                        const date = event.originalEvent.detail
                        task.boardDate = date
                        boardTaskService().updateDate(task.id, task.boardDate)
                        mapDateToColumn(task)
                        mapDateToRow(task)
                    }

                    scope.openTask = function (activeTab, subtask) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            canChangeBoard: false,
                            users: scope.users
                        };

                        boardTaskService().get(subtask.id).then(function (task) {
                            $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        return options;
                                    }
                                }
                            });
                        })

                    }

                    function loadBoards() {
                        if (scope.isBoardsLoaded) return;
                        scope.isBoardsLoaded = true;
                        boardService.getAllBoardNames().then(function (data) {
                            scope.boards = [];
                            for (let b = 0; b < data.length; b++) {
                                if (data[b].boardType != 'Yearly') {
                                    scope.boards.unshift(data[b]);
                                }
                            }
                            for (let index = 0; index < scope.tasks.length; index++) {
                                const task = scope.tasks[index];
                                setBoard(task);
                            }
                        });
                    }

                    // Responsible
                    function setResponsible(task) {
                        if (task.responsibleUserId && scope.users) {
                            var user = scope.users.find(u => u.userId.toLowerCase() == task.responsibleUserId.toLowerCase());
                            if (user) {
                                task.user = user;
                            }
                        }
                    }

                    function setBoard(task) {
                        if (task.boardId && scope.boards) {
                            var existsOutsideParent = false;
                            if (task.boardId == scope.parentTask.boardId) {
                                if (task.isActionItem) {
                                    existsOutsideParent = true;
                                } else if (task.rowCategoryId) {
                                    existsOutsideParent = true;
                                }
                            } else {
                                existsOutsideParent = true;
                            }
                            if (existsOutsideParent) {
                                var board = scope.boards.find(b => b.id == task.boardId);
                                if (board) {
                                    task.board = board;
                                }
                            }
                        }
                    }
                    var profileImageUsers = [];
                    function getAllUsers() {
                        if (scope.isUsersLoaded) return;
                        scope.isUsersLoaded = true;
                        profileService.getUsersWithProfileImage().then(function (imageUsers) {
                            profileImageUsers = imageUsers;
                            customerService.getAllUsers().then(function (users) {
                                scope.users = users;
                                for (var i = 0; i < scope.users.length; i++) {
                                    var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(scope.users[i].userId, "ExtraSmall", profileImageUsers);
                                    if (imageUrl) {
                                        scope.users[i].profileImage = true;
                                        scope.users[i].profileImageUrl = imageUrl;
                                    } else {
                                        scope.users[i].profileImage = false;
                                    }
                                }

                                for (let index = 0; index < scope.tasks.length; index++) {
                                    const task = scope.tasks[index];
                                    setResponsible(task);
                                }
                            });
                        });
                    }
                    
                    scope.clearSelectedUser = function (task) {
                        var emptyUser = {
                            userId: null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.selectUser(task, emptyUser);

                    };
                    scope.selectUser = function (task, user) {
                        task.user = user;
                        task.ResponsibleUserId
                        var userDetails = {
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName,
                            previousUserId: task.responsibleUserId,
                            previousUserName: task.responsibleUser
                        };
                        // save
                        var options = {
                            taskId: task.id,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);
                        task.responsibleUserId = user.userId;
                        task.responsibleDisplayName = user.displayName;
                        task.responsibleUser = user.fullName;
                        mapResponsibleToColumn(task);
                        mapResponsibleToRow(task);
                    };



                    scope.clearBoard = function (task) {

                        var change = {
                            taskId: task.id,
                            fromBoardId: task.boardId,
                            toBoardId: scope.parentTask.boardId
                        };
                        task.boardId = scope.parentTask.boardId;
                        task.board = null;
                        setBoard(task);
                        task.board = null;

                        task.isActionItem = false;
                        task.rowCategoryId = null;
                        task.columnCategoryId = null;

                        boardTaskService().clearSubTaskBoard(task.id, change).then(function (result) {
                            scope.publish("SendTaskChangedBoard", change);
                        });
                    }

                    scope.selectThisBoard = function (task, $event) {
                        var board = scope.boards.find(b => b.id == scope.parentTask.boardId);
                        if (board) {
                            scope.selectBoard(task, board, $event);
                        }
                    }

                    scope.selectBoard = function (task, board, $event) {
                        task.newBoardSelected = true;
                        scope.columns = null;
                        scope.selectedColumn = null;
                        scope.rows = null;
                        scope.selectedRow = null;

                        if (!(board && board.boardType == 'SmartActionList')) {
                            $event.stopPropagation();
                        } else {
                            task.newBoardSelected = false;
                        }

                        if (task.boardId !== board.id) {
                            var change = {
                                taskId: task.id,
                                fromBoardId: task.boardId,
                                toBoardId: board.id
                            };

                            task.boardId = board.id;
                            setBoard(task);
                            if (board.id) {
                                //scope.placeTaskInBoard(task,board);
                                boardTaskService().moveTaskToActionItem(task).then(function (result) {
                                    scope.publish("SendTaskChangedBoard", change);
                                });
                            } else {
                                boardTaskService().moveTaskToPersonalBoard(task.id).then(function (result) {
                                    scope.publish("SendTaskChangedBoard", change);
                                });
                            }
                        } else { // to the same board
                            if (!task.isActionItem) {
                                if(task.columnCategoryId && task.rowCategoryId) {
                                    task.newBoardSelected = false;
                                    return; // if it is already placed in the same board, do not do anything
                                }
                                boardTaskService().moveTaskToActionItem(task).then(function (result) {
                                    var change = {
                                        taskId: task.id,
                                        fromBoardId: 0,
                                        toBoardId: board.id
                                    };
                                    task.isActionItem = true;
                                    task.boardId = board.id;
                                    setBoard(task);
                                    // remove this such that it can be placed directly in the board without a double selection  task.newBoardSelected = false;
                                    scope.publish("SendTaskChangedBoard", change);
                                });
                            }
                        }
                    }

                    var mapDateToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.weekdayName && (row.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedRow = row;
                            }
                        });
                    }
                    var mapDateToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.weekdayName && (column.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.responsibleUserId == task.responsibleUserId) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.responsibleUserId == task.responsibleUserId) {
                                task.selectedRow = row;
                            }
                        });
                    }

                    scope.placeTaskInBoard = function (task) {

                        task.showColumnRow = true;
                        boardService.getBoardCategories(task.boardId).then(function (data) {

                            angular.forEach(data.columns, function (column) {
                                if (column.responsibleUserId) {
                                    column.displayName = column.name + " (" + column.responsibleDisplayName + ")";
                                } else {
                                    column.displayName = column.name;
                                }


                            });
                            angular.forEach(data.rows, function (row) {
                                if (row.responsibleUserId) {
                                    row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                                } else {
                                    row.displayName = row.name;
                                }
                            });
                            scope.columns = data.columns;
                            task.selectedColumn = data.columns[0];
                            scope.rows = data.rows;
                            task.selectedRow = data.rows[0];
                            mapDateToColumn(task);
                            mapDateToRow(task);
                            mapResponsibleToColumn(task);
                            mapResponsibleToRow(task);
                        });
                    }

                    scope.moveTaskToRowColumn = function (task) {
                        task.isActionItem = false;

                        var rowId = 0;
                        var columnId = 0;
                        if (task.selectedRow && task.selectedRow.id) {
                            rowId = task.selectedRow.id;
                        }
                        if (task.selectedColumn && task.selectedColumn.id) {
                            columnId = task.selectedColumn.id;
                        }
                        var actionItemToTask = {
                            id: task.id,
                            boardId: task.boardId,
                            boardDate: task.boardDate,
                            rowCategoryId: rowId,
                            columnCategoryId: columnId
                        };
                        boardTaskService().moveActionItemToTask(actionItemToTask).then(function (task) {
                            scope.publish("SendActionItemMovedToTask", task);
                            task.newBoardSelected = false;
                            task.showColumnRow = false;
                        });

                    }
                    scope.add = function () {
                        var boardDate = moment().utc().startOf('day').toDate();
                        if (scope.targetDate) {
                            boardDate = scope.targetDate;
                        }
                        var item = {
                            parentId: scope.parentId,
                            title: "",
                            boardDate: boardDate,
                            taskType: 'SubTask',
                            user: null
                        }
                        boardTaskService().addSubTask(scope.parentId, item).then(function (subTask) {
                            //setupTask(subTask);
                            if (subTask.boardDate) {
                                subTask.boardDate = moment(subTask.boardDate).toDate();
                            }
                            subTask.datePicker = {
                                opened: false
                            }
                            scope.tasks.unshift(subTask);
                            scope.setActive(subTask);
                        });
                    }

                    scope.delete = function (task) {
                        // Delete subtask
                        var index = scope.tasks.indexOf(task);
                        scope.tasks.splice(index, 1);
                        boardTaskService().deleteTask(task.id).then(function () {

                        });
                    }

                    scope.changeStatus = function (task) {
                        if (scope.readonly) return;
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        var status = {
                            taskId: task.id,
                            status: task.status
                        };
                        statusChangeDebounced(status);
                    }
                    var statusChangeDebounced = debounce(commitStatusChanged, 200, false);
                    function commitStatusChanged(status) {
                        boardTaskService().updateStatus(status.taskId, status).then(function (taskIds) {

                        });
                    }

                    scope.titleChanged = function (task) {
                        task.active = false;
                        var taskTitle = {
                            taskId: task.id,
                            title: task.title
                        }
                        boardTaskService().updateTitle(task.id, taskTitle).then(function (result) {

                        });
                    }

                    scope.$on('task-bag.drop-model', function (e, el, target, source) {
                        var sortOrders = [];
                        var counter = 0;
                        scope.tasks.forEach(task => {
                            sortOrders.push({
                                taskId: task.id,
                                sortOrder: counter
                            });
                            counter += 1;
                        });
                        boardTaskService().updateSortOrderForSubtasks(scope.parentId, sortOrders).then(function (result) {

                        });
                    });

                },

            }
        }]);


