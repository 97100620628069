import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("improvementFilter", ['$filter', '$translate', 'strategyService', 'suggestionService', 'authService', 'projectService', 'navigationService',
        function ($filter, $translate, strategyService, suggestionService, authService, projectService, navigationService) {
            return {
                templateUrl: 'improvementFilter.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                    'filterChangedHandler': '&',
                    'new': '=',
                    'planned': '=',
                    'inprogress': '=',
                    'implemented': '=',
                    'evaluated': '=',
                    'timePeriod': '='
                },
                link: function (scope, elem, attrs) {
                    scope.isFilterActive = false;
                    scope.likesFilterActive = false;
                    scope.myResponsibleFilterActive = false;
                    scope.assets = [];
                    scope.categories = [];
                    scope.projects = [];
                    scope.goals = [];
                    scope.statuses = [scope.new, scope.planned, scope.inprogress, scope.implemented, scope.evaluated];

                    scope.hasStrategyModule = navigationService().hasModule("STRATEGY");
                    scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                        scope.hasStrategyModule = navigationService().hasModule("STRATEGY"); 
                    });
                    scope.$watch('filter', function (filter) {
                        if (filter) {
                            scope.filterPreferences = filter;
                            filterByUserPreference();
                        }
                    });
                    scope.$watch('new', function () {
                        if (scope.new) {
                            scope.statuses = [scope.new, scope.planned, scope.inprogress, scope.implemented, scope.evaluated];
                            loadAssets();
                            loadCategories();
                            loadProjects();
                            loadGoals();
                        }
                    });

                    scope.$watch('timePeriod', function (value) {
                        if (value) {
                            getAllData();
                            filterByUserPreference();
                        }
                    });

                    // Events
                    scope.subscribe("NewImprovement", function (newSuggestion) {
                        var isPartOfContainer = $filter('filter')(scope.new.list, { id: newSuggestion.id });
                        if (isPartOfContainer.length === 0) {
                            suggestionService().get(newSuggestion.id).then(function (suggestion) {
                                suggestion.cssState = "animated slideInDown"; // Add animation
                                scope.new.list.unshift(suggestion);
                                removeAnmiation(suggestion);
                                if (scope.isFilterActive) {
                                    filterSuggestions();
                                }
                            });
                        }
                    });
                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = "";
                        }, 1000);
                    }

                    function filterByUserPreference() {

                        if (scope.filterPreferences) {
                            if (scope.filterPreferences.priority) {
                                setPriorityFilterPreferences(scope.filterPreferences.priority);
                            }
                            if (scope.filterPreferences.asset) {
                                setAssetFilterPreferences(scope.filterPreferences.asset);
                            }
                            if (scope.filterPreferences.project) {
                                setProjectFilterPreferences(scope.filterPreferences.project);
                            }
                            if (scope.filterPreferences.goal) {
                                setGoalFilterPreferences(scope.filterPreferences.project);
                            }
                            if (scope.filterPreferences.category) {
                                setCategoryFilterPreferences(scope.filterPreferences.category);
                            }
                            if (scope.filterPreferences.status) {
                                setStatusFilterPreferences(scope.filterPreferences.status);
                            }
                            if (scope.filterPreferences.myResponsible) {
                                scope.myResponsibleFilterActive = true;
                            }
                            if (scope.filterPreferences.likes) {
                                scope.likesFilterActive = true;
                            }
                            filterSuggestions();
                        }

                    }

                    scope.filterProject = function (project) {
                        project.filteredAndInResultList = !project.filteredAndInResultList;
                        if (project.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.project) {
                                scope.filterPreferences.project.push(project.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.project = [project.id];
                                } else {
                                    scope.filterPreferences = {
                                        project: [project.id]
                                    };
                                }
                                scope.filterPreferences.project = [project.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.project) {
                                var index = scope.filterPreferences.project.indexOf(project.id);
                                scope.filterPreferences.project.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }
                    scope.filterGoal = function (goal) {
                        goal.filteredAndInResultList = !goal.filteredAndInResultList;
                        if (goal.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.goal) {
                                scope.filterPreferences.goal.push(goal.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.goal = [goal.id];
                                } else {
                                    scope.filterPreferences = {
                                        goal: [goal.id]
                                    };
                                }
                                scope.filterPreferences.goal = [goal.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.goal) {
                                var index = scope.filterPreferences.goal.indexOf(goal.id);
                                scope.filterPreferences.goal.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }

                    function setProjectFilterPreferences(preferences) {
                        angular.forEach(preferences, function (projectId) {
                            for (var t = 0; t < scope.projects.length; t++) {
                                if (scope.projects[t].id === projectId) {
                                    scope.projects[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setPriorityFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredId) {
                            for (var i = 0; i < scope.priorityOptions.length; i++) {
                                if (scope.priorityOptions[i].priorityDB === filteredId) {
                                    scope.priorityOptions[i].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setAssetFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredAssetId) {
                            for (var a = 0; a < scope.assets.length; a++) {
                                if (scope.assets[a].id === filteredAssetId) {
                                    scope.assets[a].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setCategoryFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredId) {
                            for (var i = 0; i < scope.categories.length; i++) {
                                if (scope.categories[i].id === filteredId) {
                                    scope.categories[i].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setStatusFilterPreferences(preferences) {
                        angular.forEach(preferences, function (hiddenStatus) {
                            for (var i = 0; i < scope.statuses.length; i++) {
                                if (scope.statuses[i].status === hiddenStatus) {
                                    scope.statuses[i].hide = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setGoalFilterPreferences(preferences) {
                        angular.forEach(preferences, function (id) {
                            for (var t = 0; t < scope.goals.length; t++) {
                                if (scope.goals[t].id === id) {
                                    scope.goals[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function getData(improvementStatus) {
                        suggestionService().getListByStatus(improvementStatus.status, scope.timePeriod).then(function (result) {
                            for (var s = 0; s < result.length; s++) {
                                result[s].dateStyleClass = getDateStyle(result[s].dueDate);
                                //setReleatedGoals(result[s]);
                            }

                            improvementStatus.list = result;
                            improvementStatus.filteredList = result;
                            if (scope.isFilterActive) {
                                filterSuggestions();
                            }
                        });
                    }


                    function getAllData() {
                        getData(scope.new);
                        getData(scope.planned);
                        getData(scope.inprogress);
                        getData(scope.implemented);
                        getData(scope.evaluated);
                    }

                    //getAllData();

                    function getDateStyle(date) {
                        if (!date) return "";
                        // Overdue check
                        var overdue = "";
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            overdue = "overdue";
                        }
                        return overdue;
                    }


                    
                    scope.hideStatus = function (status) {
                        status.hide = !status.hide;
                        if (status.hide) {
                            if (scope.filterPreferences && scope.filterPreferences.status) {
                                scope.filterPreferences.status.push(status.status);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.status = [status.status];
                                } else {
                                    scope.filterPreferences = {
                                        status: [status.status]
                                    };
                                }
                                scope.filterPreferences.status = [status.status];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.status) {
                                var index = scope.filterPreferences.status.indexOf(status.status);
                                scope.filterPreferences.status.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                    };
                    scope.priorityOptions = [
                        {
                            priorityDB: 1,
                            translated: $translate.instant('IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT'),
                            cssClass: "highEffectLowEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 2,
                            translated: $translate.instant('IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT'),
                            cssClass: "highEffectHighEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 3,
                            translated: $translate.instant('IMPROVEMENT_LOW_EFFORT_LOW_EFFECT'),
                            cssClass: "lowEffectlowEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 4,
                            translated: $translate.instant('IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT'),
                            cssClass: "lowEffectHighEffort",
                            filteredAndInResultList: false
                        }, {
                            priorityDB: 0,
                            translated: $translate.instant('IMPROVEMENT_NOT_PRIORITIZED'),
                            cssClass: "btn-default",
                            filteredAndInResultList: false
                        }
                    ];

                    function loadAssets() {
                        assetService.getAllAssets().then(function (assets) {
                            scope.assets = assets;
                            resetAssetFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadCategories() {
                        suggestionService().getCategories().then(function (items) {
                            scope.categories = items;
                            resetCategoryFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadProjects() {
                        projectService().getProjectListWithSuggestion().then(function (items) {
                            scope.projects = items;
                            resetProjectFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadGoals() {
                        strategyService().getBreakthroughObjectives().then(function (result) {
                            scope.goals = result;
                            resetGoalFilter();
                            filterByUserPreference();
                        });
                    }
                    scope.toggleLikesFilter = function () {
                        scope.likesFilterActive = !scope.likesFilterActive;
                        if (scope.likesFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.likes = true;
                            } else {
                                scope.filterPreferences = {
                                    likes: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.likes = null;
                        }
                        filterSuggestions();
                        updateFilterPreferences();
                    }

                    scope.toggleMyResponsibleFilter = function () {
                        scope.myResponsibleFilterActive = !scope.myResponsibleFilterActive;
                        if (scope.myResponsibleFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.myResponsible = true;
                            } else {
                                scope.filterPreferences = {
                                    myResponsible: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.myResponsible = null;
                        }
                        filterSuggestions();
                        updateFilterPreferences();
                    }

                    function resetProjectFilter() {
                        angular.forEach(scope.projects, function (project) {
                            project.filteredAndInResultList = false;
                        });
                    }
                    function resetGoalFilter() {
                        angular.forEach(scope.goals, function (goal) {
                            goal.filteredAndInResultList = false;
                        });
                    }
                    function resetAssetFilter() {
                        angular.forEach(scope.assets, function (asset) {
                            asset.filteredAndInResultList = false;
                        });
                    }

                    function resetCategoryFilter() {
                        angular.forEach(scope.categories, function (category) {
                            category.filteredAndInResultList = false;
                        });
                    }
                    function resetPriorityFilter() {
                        angular.forEach(scope.priorityOptions, function (item) {
                            item.filteredAndInResultList = false;
                        });
                    }
                    scope.filterPriority = function (priority) {
                        priority.filteredAndInResultList = !priority.filteredAndInResultList;
                        if (priority.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.priority) {
                                scope.filterPreferences.priority.push(priority.priorityDB);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.priority = [priority.priorityDB];
                                } else {
                                    scope.filterPreferences = {
                                        priority: [priority.priorityDB]
                                    };
                                }
                                scope.filterPreferences.priority = [priority.priorityDB];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.priority) {
                                var index = scope.filterPreferences.priority.indexOf(priority.priorityDB);
                                scope.filterPreferences.priority.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }
                    scope.filterAsset = function (asset) {
                        asset.filteredAndInResultList = !asset.filteredAndInResultList;
                        if (asset.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.asset) {
                                scope.filterPreferences.asset.push(asset.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.asset = [asset.id];
                                } else {
                                    scope.filterPreferences = {
                                        asset: [asset.id]
                                    };
                                }
                                scope.filterPreferences.asset = [asset.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.asset) {
                                var index = scope.filterPreferences.asset.indexOf(asset.id);
                                scope.filterPreferences.asset.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }
                    scope.filterCategory = function (category) {
                        category.filteredAndInResultList = !category.filteredAndInResultList;
                        if (category.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.category) {
                                scope.filterPreferences.category.push(category.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.category = [category.id];
                                } else {
                                    scope.filterPreferences = {
                                        category: [category.id]
                                    };
                                }
                                scope.filterPreferences.category = [category.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.category) {
                                var index = scope.filterPreferences.category.indexOf(category.id);
                                scope.filterPreferences.category.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }

                    function updateFilterPreferences() {
                        scope.filterChangedHandler({ filter: scope.filterPreferences });
                    }

                    function resetStatusFilter() {
                        angular.forEach(scope.statuses, function (status) {
                            status.hide = false;
                        });
                    }
                    scope.removeFilter = function () {
                        resetStatusFilter();
                        resetAssetFilter();
                        resetCategoryFilter();
                        resetPriorityFilter();
                        resetProjectFilter();
                        resetGoalFilter();
                        scope.myResponsibleFilterActive = false;
                        scope.likesFilterActive = false;
                        filterSuggestions();
                        resetFilterPreferences();
                    };

                    function resetFilterPreferences() {
                        scope.filterPreferences = null;
                        updateFilterPreferences();

                    }

                    function filterSuggestions() {
                        scope.new.filteredList = scope.new.list;
                        scope.planned.filteredList = scope.planned.list;
                        scope.inprogress.filteredList = scope.inprogress.list;
                        scope.implemented.filteredList = scope.implemented.list;
                        scope.evaluated.filteredList = scope.evaluated.list;

                        var assetsToShow = $filter('filter')(scope.assets, { filteredAndInResultList: true }, true);
                        if (assetsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByAssets(scope.new.filteredList, assetsToShow);
                            scope.planned.filteredList = getFilteredListByAssets(scope.planned.filteredList, assetsToShow);
                            scope.inprogress.filteredList = getFilteredListByAssets(scope.inprogress.filteredList, assetsToShow);
                            scope.implemented.filteredList = getFilteredListByAssets(scope.implemented.filteredList, assetsToShow);
                            scope.evaluated.filteredList = getFilteredListByAssets(scope.evaluated.filteredList, assetsToShow);
                        }

                        var categoriesToShow = $filter('filter')(scope.categories, { filteredAndInResultList: true }, true);
                        if (categoriesToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByCategories(scope.new.filteredList, categoriesToShow);
                            scope.planned.filteredList = getFilteredListByCategories(scope.planned.filteredList, categoriesToShow);
                            scope.inprogress.filteredList = getFilteredListByCategories(scope.inprogress.filteredList, categoriesToShow);
                            scope.implemented.filteredList = getFilteredListByCategories(scope.implemented.filteredList, categoriesToShow);
                            scope.evaluated.filteredList = getFilteredListByCategories(scope.evaluated.filteredList, categoriesToShow);
                        }

                        var activePriorityFilter = priorityFilterActive();
                        if (activePriorityFilter.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.planned.filteredList = $filter('filter')(scope.planned.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, valueInList('priorityStatus', activePriorityFilter));
                            scope.evaluated.filteredList = $filter('filter')(scope.evaluated.filteredList, valueInList('priorityStatus', activePriorityFilter));
                        }

                        if (scope.likesFilterActive) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, hasValuesInListFilter('likes'));
                            scope.planned.filteredList = $filter('filter')(scope.planned.filteredList, hasValuesInListFilter('likes'));
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, hasValuesInListFilter('likes'));
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, hasValuesInListFilter('likes'));
                            scope.evaluated.filteredList = $filter('filter')(scope.evaluated.filteredList, hasValuesInListFilter('likes'));
                        }
                        var projectsToShow = $filter('filter')(scope.projects, { filteredAndInResultList: true }, true);
                        if (projectsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByProjects(scope.new.filteredList, projectsToShow);
                            scope.planned.filteredList = getFilteredListByProjects(scope.planned.filteredList, projectsToShow);
                            scope.inprogress.filteredList = getFilteredListByProjects(scope.inprogress.filteredList, projectsToShow);
                            scope.implemented.filteredList = getFilteredListByProjects(scope.implemented.filteredList, projectsToShow);
                            scope.evaluated.filteredList = getFilteredListByProjects(scope.evaluated.filteredList, projectsToShow);
                        }
                        var goalsToShow = $filter('filter')(scope.goals, { filteredAndInResultList: true }, true);
                        if (goalsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByGoals(scope.new.filteredList, goalsToShow);
                            scope.planned.filteredList = getFilteredListByGoals(scope.planned.filteredList, goalsToShow);
                            scope.inprogress.filteredList = getFilteredListByGoals(scope.inprogress.filteredList, goalsToShow);
                            scope.implemented.filteredList = getFilteredListByGoals(scope.implemented.filteredList, goalsToShow);
                            scope.evaluated.filteredList = getFilteredListByGoals(scope.evaluated.filteredList, goalsToShow);
                        }

                        if (scope.myResponsibleFilterActive) {
                            var userid = authService.getCurrentUser().userId;
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, { responsibleUserId: userid });
                            scope.planned.filteredList = $filter('filter')(scope.planned.filteredList, { responsibleUserId: userid });
                            scope.inprogress.filteredList = $filter('filter')(scope.inprogress.filteredList, { responsibleUserId: userid });
                            scope.implemented.filteredList = $filter('filter')(scope.implemented.filteredList, { responsibleUserId: userid });
                            scope.evaluated.filteredList = $filter('filter')(scope.evaluated.filteredList, { responsibleUserId: userid });
                        }
                    }
                    function valueInList(property, list) {
                        return function (item) {
                            for (var i = 0; i < list.length; i++) {
                                if (item[property] === list[i]) return true;
                            }
                            return false;
                        }
                    }
                    function hasValuesInListFilter(property) {
                        return function (item) {
                            if (item[property] && item[property].length > 0) return true;
                            return false;
                        }
                    }
                    function getFilteredListByProjects(list, projects) {
                        if (projects.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < projects.length; j++) {
                                var project = projects[j];
                                if (suggestion.projectId === project.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByGoals(list, goals) {
                        if (goals.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < goals.length; j++) {
                                var goal = goals[j];
                                if(suggestion.goals){
                                    for (var k = 0; k < suggestion.goals.length; k++) {
                                        if (suggestion.goals[k].strategyGoalId === goal.id) {
                                            filteredList.push(suggestion);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByAssets(list, assets) {
                        if (assets.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < assets.length; j++) {
                                var asset = assets[j];
                                if (suggestion.assetId === asset.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByCategories(list, categories) {
                        if (categories.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < categories.length; j++) {
                                var category = categories[j];
                                if (suggestion.categoryId === category.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function priorityFilterActive() {
                        var filteredPriority = [];
                        angular.forEach(scope.priorityOptions, function (priority) {
                            if (priority.filteredAndInResultList) {
                                filteredPriority.push(priority.priorityDB);
                            }
                        });
                        return filteredPriority;
                    }
                }
            }
        }]);
