﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('widgetDeleteConfirmation', ['$scope', '$uibModalInstance', 'widget',
  function ($scope, $uibModalInstance, widget) {
      $scope.answer = function(answer) {
          $uibModalInstance.close(answer);
      }
      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
  }]);