﻿import * as assetService from "@common/services/assetService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('newProjectPortfolioConfigurationController', ['$scope', '$uibModalInstance', 'projectBoardService',
    function ($scope, $uibModalInstance, projectBoardService) {

        $scope.useProjectGroup = true;

        $scope.isProcessing = false;
        $scope.participants = [];
        $scope.selectedAssetId = null;
        $scope.selectedAssetType = "";
        $scope.name = "";
        $scope.description = "";
        $scope.isPublic = false;

        $scope.options = {
            includeProject: false
        }
        

        $scope.projectBoardTemplate = createProjectTemplate();

        $scope.memberSelectionChanged = function (selection) {
            $scope.selectedAssetId = selection.assetId;
            $scope.participants = selection.participants;
            $scope.selectedAssetType = selection.assetType;
        }
        $scope.nameChanged = function(name) {
            $scope.name = name;
        }

        $scope.descriptionChanged = function(description) {
            $scope.description = description;
        }

        $scope.setPublicStatus = function() {
            $scope.isPublic = !$scope.isPublic;
        }

        function createProjectTemplate() {

            var template = {
                board: {
                    boardType: "Project",
                    name: "",
                    description: "",
                    settings: JSON.stringify({
                        IsWeeklyBoard: false
                    })
                },
                columns: [],
                rows: [],
                attributes: projectBoardService().getStandardProjectColumns()
            
            };
            return template;
        }

        $scope.ok = function () {
           // if (!$scope.hasSelectedAsset) return;
            $scope.isProcessing = true;
            
            if ($scope.selectedAssetType == "CUSTOMIZED") {
                var participants = [];
                $scope.participants.forEach(p => {
                    var participant = {
                        userId: p.userId,
                        role: "Member"
                    }
                    participants.push(participant);
                });
               
                    var customAsset = {
                        name : $scope.name,
                        participants : participants
                    }
                    assetService.createCustomAsset(customAsset).then(function(asset){
                        $scope.selectedAssetId = asset.id;
                        create();
                    });
                
            } else {
                create();
            }            
        }

        function create(){
            $scope.projectBoardTemplate.board.name = $scope.name;
            $scope.projectBoardTemplate.board.description = $scope.description;
            $scope.projectBoardTemplate.board.isPublic = $scope.isPublic
            $scope.projectBoardTemplate.board.assetId = $scope.selectedAssetId;


            boardService.create($scope.projectBoardTemplate).then(function (savedModel) {
                var newBoardId = savedModel.board.id;
                $scope.isProcessing = false;
                $uibModalInstance.close(newBoardId);      
            });
        
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);