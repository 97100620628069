import * as dragula from "dragula"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import { translateOldImprovementSettings } from "@common/shared/settingsTranslatorImprovement"
angular
    .module('DigiLean')
    .directive('ongoingImprovement', ['$stateParams', '$filter', '$uibModal', 'suggestionService', 'employeeService', 'debounce', 'authService',
        function ($stateParams, $filter, $uibModal, suggestionService, employeeService, debounce, authService) {
            return {
                templateUrl: 'ongoingImprovement.html',
                restrict: 'E',
                scope: {
                    'context': '@',
                    'viewMode': '@',
                    'appTitle': '@',
                    'appTheme': '<?',
                    'settings': '<?',
                    'settingsChangedHandler': '&?',
                },
                link: function (scope) {

                    // Default settings, should be overriden by eventual settings
                    let pageNum = 1;
                    let pageSize = 10;
                    let sorting = { lastModified: "desc" };
                    let draggedSuggestionId = 0;
                    scope.title = translateLabelInstant("COMMON_IMPROVEMENT") + " - " + translateLabelInstant("IMPROVEMENT_ONGOING") // Translate
                    scope.theme = null;

                    scope.allColumns = suggestionService().getAllColumns(null);

                    scope.listOptions = {
                        showFilter: false,
                        showFileGenerators: false,
                        showPaging: false,
                        showArchived: false
                    }

                    scope.fullscreenMode = false;
                    // determine if hosted in app and running in fullscreen
                    if (scope.context == "app") {
                        const hasFullScreenElements = document.querySelectorAll("div.modal.full-screen");
                        scope.fullscreenMode = hasFullScreenElements.length > 0;
                    }

                    scope.timePeriod = null;
                    scope.isSettingsLoaded = false;

                    // Kanban column options
                    const toggleAllKanbanColumns = function (visible) {
                        scope.showNewColumn = visible;
                        scope.showPlannedColumn = visible;
                        scope.ShowInProgressColumn = visible;
                        scope.showImplementedColumn = visible;
                        scope.showEvaluatedColumn = visible;
                    }
                    toggleAllKanbanColumns(false); // Default hidden untill filter resolves.

                    // default timeperiod
                    var timeSettings = {
                        timeframe: "30",
                        useDefaultTimeframes: true,
                        timeframes: [],
                        period: null,
                        getTimePeriod: function () {
                            return {
                                useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                                timeframes: timeSettings.timeframes,
                                timeframe: timeSettings.timeframe,
                                period: timeSettings.period
                            }
                        },
                        setTimePeriod: function (timeframe, period) {
                            timeSettings.timeframe = timeframe;
                            timeSettings.period = period;
                            // debounce, to make sure not starting loading several timeframes.
                            scope.options = {
                                timePeriod: timeSettings.getTimePeriod()
                            }
                        }
                    }

                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    // Default options
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                    scope.periodChangedHandler = function (timePeriod) {
                        if (scope.timePeriod && timePeriod.period
                            && scope.timePeriod.from === timePeriod.period.from
                            && scope.timePeriod.to === timePeriod.period.to) {
                            return;
                        }
                        scope.timePeriod = timePeriod;
                        getDataDebounce();
                        scope.settingsChanged();
                    };

                    scope.columnListChangeHandler = function (listColumns) {
                        if (listColumns) {
                            scope.listColumns = listColumns.listColumns;
                        }
                        scope.settingsChanged();
                    }

                    // ViewMode
                    scope.toggleViewMode = function (viewMode) {
                        scope.viewMode = viewMode;
                        scope.settingsChanged();
                    }

                    // Title
                    scope.$watch('appTitle', function (title) {
                        if (title) {
                            scope.title = title;
                        }
                    });
                    // Theme
                    scope.$watch('appTheme', function (theme) {
                        if (theme) {
                            scope.theme = theme;
                        }
                    });

                    // /* FILTER * /;
                    scope.filter = null;

                    var userid = authService.getCurrentUser().userId
                    var dueDate = moment().subtract(1, 'days');

                    scope.$watch('context', function (context) {
                        if (context == "standAlone") {
                            employeeService().getUserPreference("ImprovementOngoingFilter").then(function (filterpreferences) {
                                if (filterpreferences && filterpreferences.value) {
                                    var userSettings = filterpreferences.value;
                                    // assign settings
                                    // Check to see if the settings are old, old settings do not have viewMode
                                    if (!userSettings.viewMode) {
                                        if (userSettings.timePeriod) {
                                            scope.settings = {
                                                filter: userSettings,
                                                timePeriod: {
                                                    timeframe: userSettings.timeframe,
                                                    period: userSettings.timePeriod
                                                }
                                            }
                                        } else {
                                            scope.settings = {
                                                filter: userSettings
                                            }
                                        }
                                    } else {
                                        scope.settings = userSettings;
                                        if (userSettings.viewMode && userSettings.viewMode == "List") {
                                            scope.viewMode = userSettings.viewMode;
                                        }
                                    }

                                    scope.settings = translateOldImprovementSettings(scope.settings, userid, dueDate)
                                }
                            });
                        }
                    });

                    scope.$watch('settings', function (settings) {
                        // Apply other  settings
                        scope.isSettingsLoaded = true;
                        if (settings) {
                            settings = translateOldImprovementSettings(settings, userid, dueDate);

                            if (settings.title) {
                                scope.title = settings.title;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            if (settings.filter) {
                                scope.filter = settings.filter;
                            }
                            if (settings.sorting) {
                                sorting = settings.sorting;
                            }
                            if (settings.listColumns) {
                                scope.listColumns = settings.listColumns;
                                scope.listColumns.forEach((c) => {
                                    var column = $filter('filter')(scope.allColumns, { field: c.field }, true);
                                    if (column && column.length >= 0) {
                                        column[0].visible = c.visible;
                                    }
                                })
                            }

                            if (settings.timePeriod) {
                                scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                            }
                        }
                    });

                    var setFilterOptions = function () {
                        scope.filterWithTimePeriod = {
                            filter: scope.filter,
                            timePeriod: scope.timePeriod.period,
                            listColumns: scope.listColumns,
                            sorting: sorting
                        }
                    }
                    scope.tableParamsChanged = function (params) {
                        // When sorting or page size has changed in List
                        sorting = params.sorting;
                        scope.settingsChanged();
                    }

                    scope.settingsChanged = function () {
                        if (!scope.isSettingsLoaded) return;
                        // Raise settings changeHandler
                        var appSettings = {
                            filter: scope.filter,
                            viewMode: scope.viewMode,
                            listColumns: scope.listColumns,
                            sorting: sorting,
                            timePeriod: {
                                period: scope.timePeriod.period,
                                timeframe: scope.timePeriod.timeframe
                            }
                        }
                        if (scope.context == "app" && scope.settingsChangedHandler) {

                            scope.settingsChangedHandler({ settings: appSettings });
                        }
                        if (scope.context == "standAlone" && scope.isSettingsLoaded) {
                            // Handle setting internally
                            employeeService().updateUserPreference("ImprovementOngoingFilter", appSettings).then(function () {

                            });
                        }
                    }

                    scope.filterChanged = function (filter) {
                        scope.filter = filter;
                        getDataDebounce();
                        // Raise settings changed event
                        scope.settingsChanged();
                    };

                    function resetData() {
                        scope.data = {
                            new: [],
                            planned: [],
                            progress: [],
                            implemented: [],
                            evaluated: []
                        }
                    }
                    resetData()

                    const getdata = async () => {
                        if (!scope.timePeriod) return;
                        let promises = []
                        setFilterOptions();
                        getTotalNumberOfImprovements();
                        toggleKanbanColumnsBasedOnFilter();

                        if (scope.showNewColumn) promises.push(getValuesByStatus("Suggested").then(d => { scope.data.new = d; return d.total }))
                        if (scope.showPlannedColumn) promises.push(getValuesByStatus("Planned").then(d => { scope.data.planned = d; return d.total }))
                        if (scope.ShowInProgressColumn) promises.push(getValuesByStatus("InProgress").then(d => { scope.data.progress = d; return d.total }))
                        if (scope.showImplementedColumn) promises.push(getValuesByStatus("Implemented").then(d => { scope.data.implemented = d; return d.total }))
                        if (scope.showEvaluatedColumn) promises.push(getValuesByStatus("Evaluated").then(d => { scope.data.evaluated = d; return d.total }))
                        Promise.all(promises).then(all => {
                            scope.totalItems = all.reduce((a, b) => a + b, 0);
                        });
                    }

                    const toggleKanbanColumnsBasedOnFilter = function () {
                        if (scope.filter && Array.isArray(scope.filter)) {
                            // Check if filter on status
                            const found = scope.filter.find((item) => item.sourceColumn == 'status')
                            if (found) {
                                scope.showNewColumn = found.items.indexOf("Suggested") > -1;
                                scope.showPlannedColumn = found.items.indexOf("Planned") > -1
                                scope.ShowInProgressColumn = found.items.indexOf("InProgress") > -1
                                scope.showImplementedColumn = found.items.indexOf("Implemented") > -1
                                scope.showEvaluatedColumn = found.items.indexOf("Evaluated") > -1
                                return;
                            }
                        }
                        toggleAllKanbanColumns(true);
                    }

                    const getDataDebounce = debounce(getdata, 500, false);

                    const getValuesByStatus = function (status) {

                        let filters = []
                        if (scope.filter && Array.isArray(scope.filter)) {
                            // copy elements to local filter by spread operator ...
                            filters = [...scope.filter]
                        }

                        const statusFilter = {
                            sourceColumn: "status",
                            operator: "InList",
                            items: [status]
                        }
                        filters.push(statusFilter)


                        const sorting = [{
                            direction: "desc",
                            property: "lastModified"
                        }]
                        const tableParams = {
                            page: pageNum,
                            count: pageSize,
                            sorting,
                            filters
                        }

                        const filterModel = {
                            tableParams,
                            timePeriod: scope.timePeriod.period,
                        }
                        return suggestionService().getOngoingFiltered(filterModel).then(function (data) {
                            if(status !== "Implemented" && status !== "Evaluated") {
                                data.values.forEach(value => {
                                    value.dateStyleClass = getDateStyle(value.dueDate)
                                })
                            }
                            return data
                        })
                    }

                    /* Paging */
                    scope.pageNumChanged = function ($event) {
                        pageNum = $event.detail.pageNum ? $event.detail.pageNum : 1
                        getDataDebounce()
                    }
                    scope.pageSizeChanged = function ($event) {
                        pageSize = $event.detail.pageSize ? $event.detail.pageSize : 10
                        getDataDebounce()
                    }

                    function getTotalNumberOfImprovements() {
                        suggestionService().getTotalNumberOfImprovements(false).then(function (count) {
                            scope.totalNumberOfImprovements = count;
                        });
                    }


                    /* Drag and drop */
                    let drake;
                    scope.columnLoaded = function (column) {
                        if (drake && drake.containers && column)
                            drake.containers.push(column)
                    }
                    function setupDragula() {
                        drake = dragula({ revertOnSpill: true }) // append containers
                        drake.on("over", (el, container) => {
                            container.classList.add("hover");
                        });
                        drake.on("out", (el, container) => {
                            container.classList.remove("hover");
                        });
                        drake.on("drop", (el, target, source, sibling) => {
                            const fromStatus = source.dataset.status
                            const toStatus = target.dataset.status
                            const id = el.dataset.id
                            if (fromStatus == toStatus)
                                return false

                            const changedStatus = {
                                suggestionId: id,
                                status: toStatus
                            }
                            draggedSuggestionId = id;
                            suggestionService().updateStatus(changedStatus).then(() => {
                                
                             }, (err) => {
                                source.appendChild(el)
                                return
                            })
                        })
                    }

                    setupDragula();

                    scope.openSuggestion = function (suggestionInfo) {
                        suggestionService().get(suggestionInfo.id).then(function (suggestion) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });;
                        });
                    }

                    if ($stateParams.id && scope.context != 'app') {
                        var suggestion = {
                            id: parseInt($stateParams.id)
                        }
                        scope.openSuggestion(suggestion);
                    }

                    function getDateStyle(date) {
                        if (!date) return "";
                        // Overdue check

                        var overdue = "";
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            overdue = "overdue";
                        }
                        return overdue;
                    }


                    /* EVENT HANDLE CODE */
                    var updateSuggestion = function (suggestionId) {
                        if (scope.showNewColumn) checkAndReplace(scope.data.new.values, suggestionId)
                        if (scope.showPlannedColumn) checkAndReplace(scope.data.planned.values, suggestionId)
                        if (scope.ShowInProgressColumn) checkAndReplace(scope.data.progress.values, suggestionId)
                        if (scope.showImplementedColumn) checkAndReplace(scope.data.implemented.values, suggestionId)
                        if (scope.showEvaluatedColumn) checkAndReplace(scope.data.evaluated.values, suggestionId)
                    }

                    var getListByStatus = function (status) {
                        switch (status) {
                            case "Suggested":
                                return scope.data.new.values;
                            case "Planned":
                                return scope.data.planned.values;
                            case "InProgress":
                                return scope.data.progress.values;
                            case "Implemented":
                                return scope.data.implemented.values;
                            case "Evaluated":
                                return scope.data.evaluated.values;
                            default:
                                return [];
                        }
                    }


                    function checkAndReplace(datalist, suggestionId) {
                        const suggestion = datalist.find(s => s.id == suggestionId)
                        if (suggestion)
                            replaceSuggestion(datalist, suggestionId, suggestion)
                    }

                    async function replaceSuggestion(list, suggestionId, existingSuggestion) {
                        const dbSuggestion = await getSuggestionDb(suggestionId)
                        if (dbSuggestion.attachments && dbSuggestion.attachments.length > 0) {
                            dbSuggestion.firstAttachmentFileInfo = dbSuggestion.attachments[dbSuggestion.attachments.length - 1].fileInfo;
                        }
                        const index = list.indexOf(existingSuggestion)
                        list.splice(index, 1, dbSuggestion)
                        dbSuggestion.dateStyleClass = getDateStyle(dbSuggestion.dueDate)
                        dbSuggestion.cssState = "animated bounce"
                        removeAnmiation(dbSuggestion)
                    }

                    async function getSuggestionDb(id) {
                        const dbSuggestion = await suggestionService().get(id)
                        setViewFieldsFromDb(dbSuggestion)
                        return dbSuggestion
                    }

                    function setViewFieldsFromDb(suggestion) {
                        if (suggestion.comments && suggestion.comments.length > 0)
                            suggestion.commentCount = suggestion.comments.length
                        if (suggestion.asset)
                            suggestion.assetName = suggestion.asset.name
                    }

                    function removeFromList(list, suggestionId) {
                        const currentSuggestion = list.find(l => l.id == suggestionId)
                        if (!currentSuggestion)
                            return null;

                        const index = list.indexOf(currentSuggestion)
                        list.splice(index, 1)
                        return currentSuggestion;
                    }

                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = ""
                        }, 1000)
                    }

                    scope.subscribe("ImprovementTitleChanged", function (improvementTitle) {
                        updateSuggestion(improvementTitle.suggestionId);
                    });
                    scope.subscribe("ImprovementResponsibleChanged", function (improvementResponsible) {
                        updateSuggestion(improvementResponsible.suggestionId);
                    });
                    scope.subscribe("ImprovementUpdated", function (suggestion) {
                        updateSuggestion(suggestion.id);
                    });
                    scope.subscribe("NewImprovement", function (suggestion) {
                        getDataDebounce();
                    });

                    scope.subscribe("ImprovementChangedStatus", async function (status) {
                        
                        if (status.suggestionId == draggedSuggestionId) {
                            // If this is same, it means this was the even of the drag operation performed by this sesson, so no need to update.
                            draggedSuggestionId = 0; // Reset flag
                            return;
                         } 
                        
                        // First check if suggestion already in correc status, then no need to remove
                        var list = getListByStatus(status.status);
                        if (list.find(s => s.id == status.suggestionId)) return;

                        if (scope.showNewColumn) {
                            var suggestion = removeFromList(scope.data.new.values, status.suggestionId);
                            if (suggestion) addSuggestionToStatusColumn(suggestion, status.status);
                        } 
                        if (scope.showPlannedColumn) {
                            var suggestion = removeFromList(scope.data.planned.values, status.suggestionId);
                            if (suggestion) addSuggestionToStatusColumn(suggestion, status.status);
                        } 
                        if (scope.ShowInProgressColumn) {
                            var suggestion = removeFromList(scope.data.progress.values, status.suggestionId);
                            if (suggestion) addSuggestionToStatusColumn(suggestion, status.status);
                        } 
                        if (scope.showImplementedColumn) {
                            var suggestion = removeFromList(scope.data.implemented.values, status.suggestionId);
                            if (suggestion) addSuggestionToStatusColumn(suggestion, status.status);
                        } 
                        if (scope.showEvaluatedColumn) {
                            var suggestion = removeFromList(scope.data.evaluated.values, status.suggestionId);
                            if (suggestion) addSuggestionToStatusColumn(suggestion, status.status);
                        } 
                    });

                    var addSuggestionToStatusColumn = function(suggestion, status) {
                        suggestion.status = status;
                        suggestion.cssState = "animated bounce"
                        var list = getListByStatus(status);
                        list.unshift(suggestion);
                        removeAnmiation(suggestion)
                    }

                    scope.subscribe("ImprovementPriorityChanged", function (priority) {
                        updateSuggestion(priority.suggestionId);
                    });

                    scope.subscribe("ImprovementDeleted", function (suggestion) {
                        if (scope.showNewColumn) removeFromList(scope.data.new.values, suggestion.suggestionId)
                        if (scope.showPlannedColumn) removeFromList(scope.data.planned.values, suggestion.suggestionId)
                        if (scope.ShowInProgressColumn) removeFromList(scope.data.progress.values, suggestion.suggestionId)
                        if (scope.showImplementedColumn) removeFromList(scope.data.implemented.values, suggestion.suggestionId)
                        if (scope.showEvaluatedColumn) removeFromList(scope.data.evaluated.values, suggestion.suggestionId)

                    });


                    scope.newItem = function () {
                        const modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'newSuggestionForm.html',
                            controller: 'newSuggestionModalController',
                            resolve: {
                                suggestion: function () {
                                    return {
                                        id: 0,
                                        title: "",
                                        text: "",
                                        suggestedBy: "",
                                        suggestionDate: new Date(),
                                        showComments: false,
                                        likes: [],
                                        comments: [],
                                    }
                                }
                            }
                        })

                        modalInstance.result.then(function (suggestion) {
                            suggestionService().addSuggestion(suggestion)
                        }, () => { })
                    }
                }
            };
        }]);


