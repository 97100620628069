import { JobRunLog, JobRunState } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

import moment from "moment"

@customElement('jobrun-duration')
export class JobRunStateViewer extends LitElement {
    _timerInterval = 0
    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-flex;
            flex-direction: row;
            font-size: 8px;
        }
        span {
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
        }
    `

    private _jobrun: JobRunLog | undefined

    get jobrun() {
        return this._jobrun!
    }

    @property({attribute: true})
    set jobrun(value: JobRunLog) {
        this._jobrun = value
        if (this._jobrun.state == JobRunState.Running){
            if (!this._timerInterval)
                this._timerInterval = window.setInterval(() => this.requestUpdate(), 5000)
        } else
            this.removeTimer()
    }


    removeTimer() {
        if (this._timerInterval)
            window.clearInterval(this._timerInterval)
    }

    disconnectedCallback() {
        super.disconnectedCallback()
        this.removeTimer()
    }
    render() {

        if (!this.jobrun || !this.jobrun.started)
            return html`<span></span>`

        let start = new Date(this.jobrun.started)
        let end = new Date()

        if (this.jobrun.finished)
            end = new Date(this.jobrun.finished)
        else if (this.jobrun.state !== JobRunState.Running)
            return html`<span>n/a</span>`
        
        const startDate = moment(start)
        const endDate = moment(end)
        const diff = endDate.diff(startDate, "seconds")
        
        let diffS = diff
        let diffM = 0
        if (diff > 59) {
            diffM = Math.floor(diff / 60)
            diffS = diff % 60
        }

        if (diffM == 0) {
            return html`
            <span style="min-width: 40px;"><span style="font-weight: bold; font-size: 10px;">${diffS}</span>s</span>
        `
        } else {
            
            return html`
            <span style="min-width: 40px;">
            <span style="font-weight: bold; font-size: 9px;">${diffM}</span>m 
            <span style="font-weight: bold; font-size: 9px;">${diffS}</span>s
            </span>
        `    
        }

        
    }
}
