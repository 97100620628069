angular
    .module('DigiLean')
    .controller('customerstatusController', ['$rootScope','$scope', '$filter', '$translate', 'authService', 'projectService', 
    function($rootScope,$scope, $filter, $translate, authService, projectService) {

        

            
            $scope.totalDeviations = null;
            $scope.totalSuggestions = null;
            
        $scope.customerId = authService.getCurrentUser().customerId;

        projectService().getProjectCustomers().then(function (customers) {
            $scope.customers = customers;

        });
        projectService().getCustomerProjectsDeliveredOnTime().then(function (result) {
            $scope.percentOnTime = Math.round(result);

        });

        projectService().getCustomerProjectNoOfSuggestions().then(function (result) {
            $scope.totalSuggestions = result;
        });
        
        projectService().getCustomerProjectNoOfDeviations().then(function (result) {
            $scope.totalDeviations = result;
        });


        $scope.items = [];
        projectService().getProjectCustomerStats().then(function (summary) {
            //$scope.material = summary.material;
            $scope.items = summary.items; 
            var totalProjectsLevels = calculateLevels(summary.totalProjectsRange, 4);
            var onTimeLevels = calculateLevels(summary.onTimeRange, 4);
            var totalSuggestionsLevel = calculateLevels(summary.totalSuggestionsRange, 4);
            var totalDeviationsLevel = calculateLevels(summary.totalDeviationsRange, 4);

            $scope.items.forEach(item => {
                item.totalProjectsLevels = getLevel(item.totalProjects, totalProjectsLevels);
                item.onTimeLevels = getLevel(item.percentOnTime, onTimeLevels);
                item.totalSuggestionsLevel = getLevel(item.totalSuggestions, totalSuggestionsLevel);
                item.totalDeviationsLevel = getLevel(item.totalDeviations, totalDeviationsLevel);
            });
        });

        function calculateLevels(range, numberOfLevels) {
            var rangeSize = range.max - range.min;
            var levelSize = rangeSize / numberOfLevels;
            var levels = [];
            var levelStart = range.min;
            for (let index = 1; index <= numberOfLevels; index++) {
                var level = {
                    start: levelStart,
                    end: levelStart + levelSize
                };
                levels.push(level);
                levelStart = level.end;
            }
            return levels;
        }
        function getLevel(value, levels) {
            if (value == 0 || levels.length == 0) return "level-0";
            
            for (let index = 0; index <= levels.length; index++) {
                var level = levels[index];
                if (value >= level.start && value <= level.end) {
                    return "level-" + (index + 1);
                }
            }
            return "level-0";
        }



     }]);