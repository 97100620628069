angular
.module('DigiLean')
.directive("navnewtask", ['$uibModal',
    function ($uibModal) {
        return {
            templateUrl: 'navnewtask.html',
            restrict: 'E',
            scope: {
                'mode': '@'
            },
            link: function (scope, element, attrs) {
                scope.createNew = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'newtasktopersonaltasklist.html', 
                        controller: 'newtasktopersonaltasklistController',
                        resolve: {
                            options: function () {
                                return null;
                            }
                        }
                    });
                }
            }
        }
    }]);
