angular
    .module('DigiLean')
    .directive("datalistSelector", ['$filter', 'dataListService',
        function ($filter, dataListService) {
            return {
                templateUrl: 'datalistselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'listid': '@',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.includeAll = true;
                    scope.$watch('source', function () {
                        var listOptions = { includeDeletedItems: false };
                        var source = source;
                 
                        dataListService().getItems(scope.listid, listOptions).then(function (list) {
                            if (list) {
                                scope.listItems = list;
                                applyFilter();
                            }
                        });

                    });


                    function applyFilter() {
                        if (scope.filters && scope.filters.length > 0) {

                            var identifiers = [];
                            var selectedFilter = scope.filters[0];
                            if (selectedFilter && selectedFilter.items.length > 0) {

                                selectedFilter.items.forEach(item => {
                                    identifiers.push(parseInt(item));
                                });
                                selectedFilter.items = identifiers;
                            }

                            for (var k = 0; k < scope.listItems.length; k++) {
                                var index = -1;
                                if (selectedFilter.items.length > 0) {
                                    index = selectedFilter.items.indexOf(scope.listItems[k].id);
                                }
                                if (index > -1) {
                                    scope.listItems[k].include = true;
                                } else {
                                    scope.listItems[k].include = false;
                                }
                            }

                            var selected = $filter('filter')(scope.listItems, { include: true });
                            if (selected.length === scope.listItems.length && selected.length === 0) {
                                scope.includeAll = true;
                            } else {
                                scope.includeAll = false;
                            }
                        } else if (scope.listItems) {
                            for (var i = 0; i < scope.listItems.length; i++) {
                                scope.listItems[i].include = true;
                            }
                        }
                    }

                    scope.deselectAll = function (include) {
                        for (var k = 0; k < scope.listItems.length; k++) {
                            scope.listItems[k].include = include;
                        }
                        scope.modifyFilter();
                    };

                    scope.modifyFilter = function () {
                        var selected = $filter('filter')(scope.listItems, { include: true });
                        if (selected.length === scope.listItems.length) {
                            scope.includeAll = true;
                            scope.filters = null;
                        } else {
                            scope.includeAll = false;
                            var selectedItems = [];
                            for (var i = 0; i < selected.length; i++) {
                                selectedItems.push(selected[i].id);
                            }
                            scope.filters = [{
                                sourceColumn: scope.source,
                                operator: "InList",
                                items: selectedItems
                            }];
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                    };

                }
            }
        }]);
