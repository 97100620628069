﻿angular.module('DigiLean').factory('microsoftConnectService', ['$http',
  function ($http) {
      function microsoftConnectService() {
        var resource = "/microsoftConnect";
        var resourceUrl = webApiUrl + resource;

          return {
              getUsers: function () {
                  var url = resourceUrl + "/getUsers";
                  return $http.get(url).then(function (result) {
                      return result.data;
                  });
              },
          }
      }
      return microsoftConnectService;
  }]);