angular
    .module('DigiLean')
    .directive("projectNextMilestoneCell", ['$filter', '$translate', 'projectService',
        function ($filter, $translate, projectService) {
            return {
                templateUrl: 'projectNextMilestoneCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.milestoneMissed = false;
                    var createNew = false;
                    scope.milestone = null;
                    scope.value = null;

                    var projectId = null;
                    var isStartDate = false;
                    var isEndDate = false;

                    scope.changeColor = false;
                    scope.changeColorXDaysBefore = 1;

                    scope.update = function (newValue) {
                        if (scope.milestone && !createNew) {
                            scope.value = newValue;
                            scope.saveChanges();
                        } else {
                            createNew();
                        }
                    }


                    scope.$watch("model", function (model) {
                        projectId = model.project.id
                        var settings = model.column.settings;
                        if (projectId) {
                            loadNextMilestone(projectId);
                        }
                        if (settings) {
                            if (settings.changeColor && settings.changeColorXDaysBefore) {
                                scope.changeColor = true;
                                scope.changeColorXDaysBefore = settings.changeColorXDaysBefore;
                            }
                        }
                        model.getSortValue = function () {
                            if (scope.milestone) {
                                if(scope.milestone.actualDate){
                                    return scope.milestone.actualDate;
                                } else {
                                    return scope.milestone.plannedDate;
                                }     
                            } else {
                                if(scope.model.project && scope.model.project.milestones && scope.milestoneId){
                                    var foundMilestone = $filter('filter')(scope.model.project.milestones, { id: scope.milestoneId });
                                    if (foundMilestone.length > 0) {
                                        if(foundMilestone[0].actualDate){
                                            return moment(foundMilestone[0].actualDate).toDate();
                                        } else if(foundMilestone[0].plannedDate){
                                            return moment(foundMilestone[0].plannedDate).toDate();
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return null;
                                }
                            }
                        }
                    });

                    scope.subscribe("ProjectDateColorCellUpdated", function (value) {
                        if (scope.model.boardAttributeId === value.projectAttributeId) {
                            scope.changeColor = value.changeColor;
                            scope.changeColorXDaysBefore = value.changeColorXDaysBefore;
                        }
                    });


                    scope.subscribe("ProjectMilestoneUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.id == scope.milestone.id) {
                            loadNextMilestone(projectId);
                        }
                    });

                    scope.subscribe("ProjectMilestoneUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.id == scope.milestone.id) {
                            loadNextMilestone(projectId);
                        }
                    });
                    scope.subscribe("ProjectStartDateUpdated", function (project) {
                        if (project.id == scope.model.project.id && scope.milestone.milestoneName == 'STARTDATE') {
                            loadNextMilestone(projectId);
                        }
                    });
                    scope.subscribe("ProjectEstimatedStartDateUpdated", function (project) {
                        if (project.id == scope.model.project.id && scope.milestone.milestoneName == 'STARTDATE') {
                            loadNextMilestone(projectId);
                        }
                    });
                    scope.subscribe("ProjectEndDateUpdated", function (project) {
                        if (project.id == scope.model.project.id && scope.milestone.milestoneName == 'ENDDATE') {
                            loadNextMilestone(projectId);
                        }
                    });
                    scope.subscribe("ProjectEstimatedEndDateUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            var currentDate = moment(scope.milestone.plannedDate).startOf('day');
                            var updatedDate = moment(project.estimatedEndDate).startOf('day');;
                            var diffInDays = currentDate.diff(updatedDate, 'days');
                            if (diffInDays >= 1) {
                                loadNextMilestone(projectId);
                            }
                        }
                    });

                    function loadNextMilestone(projectId) {
                        isStartDate = false;
                        isEndDate = false;
                        scope.milestoneMissed = false;
                        projectService().getNextMilestonesForProject(projectId).then(function (nextmilestone) {
                            if (nextmilestone) {
                                scope.milestone = nextmilestone;
                                if (scope.milestone.plannedDate) {
                                    scope.milestone.plannedDate = moment(nextmilestone.plannedDate).toDate();
                                    var today = moment().startOf('day');
                                    var target = moment(scope.milestone.plannedDate).startOf('day');
                                    scope.days = today.diff(target, 'days');
                                    if (scope.days >= 1) {
                                        scope.milestoneMissed = true;
                                    }
                                    scope.days = Math.abs(scope.days);
                                    scope.translationData = {
                                        days: scope.days
                                    };
                                }

                                scope.milestone.name = scope.milestone.milestoneName;
                                translateSystemName();

                                if (scope.milestone.actualDate) {
                                    scope.milestone.actualDate = moment(milestone.actualDate).toDate();
                                }

                            } else {
                                createTempMilestone();
                            }
                        });
                    }
                    function createTempMilestone() {
                        createNew = true;
                        scope.milestone = {
                            name: scope.model.column.name,//$translate.instant('PROJECT_MILESTONE'),
                            projectId: scope.model.project.id,
                            plannedDate: null,
                            actualDate: null
                        };
                    }

                    function translateSystemName() {
                        if (scope.milestone.milestoneName == 'STARTDATE') {
                            isStartDate = true;
                            isEndDate = false;
                            scope.milestone.name = $translate.instant('PROJECT_START_DATE');
                        } else if (scope.milestone.milestoneName == 'ENDDATE') {
                            isEndDate = true;
                            isStartDate = false;
                            scope.milestone.name = $translate.instant('PROJECT_COMPLETION_DATE');
                        }
                    }

                    scope.saveActualDate = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.milestone.actualDate = date
                        if (isStartDate) {
                            projectService().updateStartDate(projectId, scope.milestone.actualDate).then(function (milestone) {
                                loadNextMilestone(projectId);
                            });
                        } else if (isEndDate) {
                            projectService().updateEndDate(projectId, scope.milestone.actualDate).then(function (milestone) {
                                loadNextMilestone(projectId);
                            });
                        } else {
                            projectService().updateMilestone(scope.milestone).then(function (milestone) {
                                loadNextMilestone(projectId);
                            });
                        }
                    }

                    scope.saveEstimatedDate = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.milestone.plannedDate = date
                        if (isStartDate) {
                            projectService().updateEstimatedStartDate(projectId, scope.milestone.plannedDate).then(function (milestone) {
                                loadNextMilestone(projectId);
                            });
                        } else if (isEndDate) {
                            projectService().updateEstimatedEndDate(projectId, scope.milestone.plannedDate).then(function (milestone) {
                                loadNextMilestone(projectId);
                            });
                        } else {
                            projectService().updateMilestone(scope.milestone).then(function (milestone) {
                                loadNextMilestone(projectId);
                            });
                        }
                    }

                }
            }
        }]);
