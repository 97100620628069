angular
.module('DigiLean')
.directive("navnewsuggestion", ['$uibModal','suggestionService',
    function ($uibModal, suggestionService) {
        return {
            templateUrl: 'navnewsuggestion.html',
            restrict: 'E',
            scope: {
                'mode': '@'
            },
            link: function (scope, element, attrs) {
                scope.createNew = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'newSuggestionForm.html',
                        controller: 'newSuggestionModalController',
                        resolve: {
                            suggestion: function () {
                                return {
                                    id: 0,
                                    title:"",
                                    text: "",
                                    suggestedBy: "",
                                    suggestionDate: new Date(),
                                    showComments: false,
                                    likes: [],
                                    comments: [],
                                }
                            }
        
                        }
                    });
        
                    // When user has created new suggestion
                    modalInstance.result.then(function (suggestion) {
                        suggestionService().addSuggestion(suggestion).then(function (savedsuggestion) {

                        });
                    }, function () {
                        //$log.info('Modal dismissed at: ' + new Date());
                    });
                }
            }
        }
    }]);
