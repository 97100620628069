import * as customerService from "@common/services/customers/customerService"

angular
.module('DigiLean')
.directive("userSelector", ['$filter', 
    function ($filter) {
        return {
            templateUrl: 'userselector.html',
            restrict: 'E',
            scope: {
                'filters': '=',
                'source': '=',
                'filterChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {
                scope.includeAll = true;

                function getUserList() {
                    customerService.getAllUsers().then(function (users) {
                        scope.users = users;
                        applyFilter();         
                    });
                }
                getUserList();

                scope.$watch('filters', function () {
                    applyFilter();
                });

                function applyFilter(){
                    if (scope.filters && scope.users) {
                        var identifiers = [];
                        var selectedFilter = scope.filters[0];
                    
                        for (var k = 0; k < scope.users.length; k++) {
                            var index = -1;
                            if(selectedFilter.items.length > 0 ){
                                index = selectedFilter.items.indexOf(scope.users[k].userId);
                            }
                            if (index > -1){
                                scope.users[k].include = true;
                            } else {
                                scope.users[k].include = false;
                            }                     
                        }

                        var selected = $filter('filter')(scope.users, { include: true });
                        if (selected.length === scope.users.length && selected.length === 0) {
                            scope.includeAll = true;
                        } else {
                            scope.includeAll = false;
                        }
                    } else if (scope.users) {
                        for (var k = 0; k < scope.users.length; k++) {
                            scope.users[k].include = true;                     
                        }
                    }
                }

                scope.deselectAll = function (include) {
                    for (var k = 0; k < scope.users.length; k++) {
                        scope.users[k].include = include;                     
                    }
                    scope.modifyFilter();
                };

                scope.modifyFilter = function(){
                    var selected = $filter('filter')(scope.users, { include: true });
                    if(selected.length === scope.users.length){
                        scope.includeAll = true;
                        scope.filters = null;
                    } else {
                        scope.includeAll = false;
                        var selectedItems = [];
                        for(var i=0; i < selected.length; i++){
                             selectedItems.push(selected[i].userId);
                        }
                        scope.filters = [{
                            sourceColumn: scope.source,//"UserId",
                            operator: "InList",
                            items: selectedItems
                        }];
                    }
                    //scope.filterChangeHandler({ updatedSourceColumn: "UserId", filter: scope.filters });
                    scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                };

            }
        }
    }]);
