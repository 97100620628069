﻿var DigiLean = angular.module('DigiLean');

DigiLean.directive("unitLabel", [
    function () {
        return {
            templateUrl: 'unitLabel.html',
            restrict: 'E',
            scope: {
                'unitcode': '<',
            },
                link: function (scope, elem, attrs) {
                
            }
        }
    }]);
