import type { AzureAdDigiLeanGroup, AzureAdDigiLeanUser } from "@api"
import backend from "@common/services/backendHttp"

let resourceUrl = "api/AzureAd"

export const lookupUserAd = async (searchTerm: string) => {
    const url = `${resourceUrl}/lookup?searchTerm=${searchTerm}`
    const res = await backend.get<AzureAdDigiLeanUser[]>(url)
    return res
}

export const lookupGroupAd = async (searchTerm: string) => {
    const url = `${resourceUrl}/lookupGroups?searchTerm=${searchTerm}`
    const res = await backend.get<AzureAdDigiLeanGroup[]>(url)
    return res
}