
import angular from "angular"
import { getAuthUser } from "@common/stores/userStore"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')
DigiLean.component('monitoringAll', {
    templateUrl: "monitoringAll.html",
    controller: function() {
        let $ctrl = this as NgComponent
        $ctrl.showAll = false

        const user = getAuthUser()
        
        const hasRole = user.roles.hasRole("KaizenAdmin")
        $ctrl.showAll = hasRole
    }
})