import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .component("replyableMessage", {
        templateUrl: 'replyableMessage.html',
        bindings: {
            msg: '<'
        },
        controller: ['$scope', '$uibModal',
            function ($scope, $uibModal) {
                var $ctrl = this;

                $ctrl.openReplies = function() {
                    $uibModal.open({ backdrop: 'static',
                        component: 'messageRepliesDialog',
                        resolve: {
                            msg: function () {
                                return $ctrl.msg;
                            }
                        }
                    });
                };
                $ctrl.hasComments = function() {
                    return $ctrl.msg.replies && Array.isArray($ctrl.msg.replies) && $ctrl.msg.replies.length > 0;
                };
                
                $ctrl.$onInit = function() {
                    customerService.getReplies($ctrl.msg.id).then(function(replies) {
                        $ctrl.msg.replies = replies;
                    });

                    $scope.subscribe("MessageReplySent", function(r) {
                        if (r.parentId == $ctrl.msg.id) {
                            timeService.addTimeElapsedForMsg(r);
                            $ctrl.msg.replies.unshift(r);
                        }
                    });
                };
            }]
    });