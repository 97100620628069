// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('newDataListController', ['$scope',  '$uibModalInstance', 'dataListService',
    function ($scope, $uibModalInstance, dataListService) {

        $scope.dataList = {
            name: "",
            description: "",
            isSystemList: false
        }

        $scope.ok = function () {     
            dataListService().createList($scope.dataList).then(function (dataList) {
                $uibModalInstance.close(dataList);
            });

        }
        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);