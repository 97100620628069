import { Theme } from "@common/model/types"
import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
// import { classMap } from "lit/directives/class-map.js"
import {styleMap} from "lit/directives/style-map.js"


const themes: Theme[] = [
    {
        background: "var(--digilean-primary-text)",
        fillColor: "var(--digilean-secondary-background)",
        color: "inherit"
    },
    {
        fillColor: "#FFF6DF", // orange
        background: "#FCBB42"
    },
    {
        fillColor: "#EDF7E3", // green
        background: "#8CC152"
    },
    {
        fillColor: "#DFF3FB", //blue
        background: "#0099de" //"#3BAFDA"
    },
    {
        fillColor: "#FEE4DF", // red
        background: "#E9573F"
    },
    {
        fillColor: "#FBE9F3",  // pink
        background: "#D770AD"
    }
]

@customElement('theme-selector')
export class DigiLeanThemeSelector extends LitElement {
    
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
        }

        .post-it {
            margin-left:7px;
            margin-right:1px;
            height: 24px;
            width: 24px;
            margin: 5px;
            border: 1px solid darkgrey;
            cursor: pointer;
        }
    `

    
    onSelect(theme: Theme) {
        const options = {
            detail: theme,
            bubbles: true,
            composed: true
        }
        this.dispatchEvent(new CustomEvent("theme-selected", options))
    }

    render() {
        
        return themes.map(theme => {
            const themeStyles = {
                "backgroundColor": theme.fillColor,
                "border": "2px solid " + theme.background
            }
            return html`
                <div class="post-it"
                    @click=${() => this.onSelect(theme)}
                    style=${styleMap(themeStyles)}>
                </div>
            `
        }) 
        
    }
}