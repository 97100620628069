import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')

    .directive("digileanBarChart", ['$filter', '$translate', 'dataService', '$uibModal', 'a3Service', 'a3templateService', 'projectService', 'strategyService', 'suggestionService', 'deviationService', 'areaService', 'dataListService','debounce',
        function ($filter, $translate, dataService, $uibModal, a3Service, a3templateService, projectService,strategyService, suggestionService, deviationService, areaService, dataListService, debounce) {
            return {
                templateUrl: 'barchart.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'globalTimePeriod': '@',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    var grafcolor = ['#2A2E36', '#0099DE', '#d9534f', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#17987e'],
                        graphtextcolor = '#ffffff';
                    
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.optimizationChallenges = false;
                    scope.configurationChallenges = false;

                    scope.title = "";
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    scope.dataSource = null;
                    scope.filters = [];
                    var groupedBy = "";
                    var groupingIntervall = "month";

                    // Support list for labels
                    scope.strategygoals = null;
                    var problemCategories = null;
                    var deviationTypes = null;
                    var consequences = null;
                    scope.areas = null;
                    var assets = null;
                    scope.a3templates = null;
                    scope.boards = null;
                    scope.imageUrl = staticFileService.image("barchart.jpg");

                    var listGroupedElements = null;

                    scope.showTarget = false;
                    var targetSerieData = null;

                    var timeSettings = {
                        timeframe: "YTD",
                        useDefaultTimeframes: true,
                        timeframes: [],
                        period: null,
                        getTimePeriod: function () {
                            return {
                                useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                                timeframes: timeSettings.timeframes,
                                timeframe: timeSettings.timeframe,
                                period: timeSettings.period
                            }
                        },
                        setTimePeriod: function (timeframe, period) {
                            timeSettings.timeframe = timeframe;
                            timeSettings.period = period;
                            // debounce, to make sure not starting loading several timeframes.
                            scope.options = {
                                timePeriod: timeSettings.getTimePeriod()
                            }
                        }
                    }
                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    // Default options
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                    scope.$watch('settings', function (settings) {

                        if (settings) {
                            scope.settings = settings;
                            if (settings.dataSource) {
                                console.log('settings datasource:');
                                console.log(settings.dataSource);
                                scope.dataSource = settings.dataSource;
                            }
                            if (settings.groupedBy) {
                                groupedBy = settings.groupedBy;
                            }
                            if (settings.groupingIntervall) {
                                groupingIntervall = settings.groupingIntervall;
                            }
                            if (settings.filters) {
                                scope.filters = settings.filters;
                            }
                            if (settings.timePeriod) {
                                scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                            }
                            if (settings.title) {
                                scope.title = settings.title;
                            }
                            if (settings.showTarget === true) {
                                scope.showTarget = settings.showTarget;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            initialize();
                        }
                        if (!scope.dataSource && scope.isAdminMode) {
                            setTimeout(function () {
                                scope.selectDataSource();
                            }, 200);
                        }
                    });

                    // Subscribe to component related event
                    scope.subscribe("DataValueAdded", function (value) {
                        if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                        initialize();
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                        initialize();
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                        initialize();
                    });

                    function initialize() {
                        console.log('initialize');
                        console.log(scope.dataSource);

                        if (!scope.dataSource) return;
                        scope.configurationChallenges = false;
                        var groupingElement = {
                            sourceColumn: groupedBy //"assetId"
                        }
                        if (groupedBy === "areaId") {
                            areaService().getList(true).then(function (areas) {
                                scope.areas = areas;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                            assetService.getAllAssetsIncludingSystem().then(function (data) {
                                assets = data;
                                getData(groupingElement);
                            });
                        }
                        else if (groupedBy === "projectId") {
                            projectService().getList(true).then(function (data) {
                                scope.projects = data;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "strategyGoalId") {
                            strategyService().getBreakthroughObjectives().then(function (goals) {
                                scope.goals = goals;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "problemCategoryId") {
                            deviationService().getProblemCategories(true).then(function (categories) {
                                problemCategories = categories;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "deviationTypeId") {
                            deviationService().getAllDeviationTypes().then(function (types) {
                                deviationTypes = types;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "lossTypeId") {
                            deviationService().getConsequences(true).then(function (data) {
                                consequences = data;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "categoryId") {
                            if (scope.dataSource.id.startsWith("Improvement")) {
                                suggestionService().getCategories(true).then(function (categories) {
                                    scope.suggestionCategories = categories;
                                    getData(groupingElement);
                                });
                            } else {//assue A3 categories
                                a3Service().getCategories(true).then(function (categories) {
                                    scope.a3Categories = categories;
                                    getData(groupingElement);
                                });
                            }
                        } else if (groupedBy === "boardId") {
                            boardService.getAll(true).then(function (boards) {
                                scope.boards = boards;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                            customerService.getAllUsers(true).then(function (users) {
                                scope.users = users;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "a3TemplateId") {
                            a3templateService().getList().then(function (templates) {
                                scope.a3templates = templates;
                                getData(groupingElement);
                            });
                        } else if (groupedBy === "dimension" || groupedBy === "dimension2" || groupedBy === "dimension3" || groupedBy === "dimension4") {
                            var listId = checkElementType(groupedBy, 'list');
                            if (listId > 0) {
                                var listOptions = { includeDeletedItems: true };
                                dataListService().getItems(listId, listOptions).then(function (data) {
                                    listGroupedElements = data;
                                    getData(groupingElement);
                                });
                            } else if (checkElementType(groupedBy, 'user') == 1) {
                                customerService.getAllUsers().then(function (users) {
                                    scope.users = users;
                                    getData(groupingElement);
                                });
                            } else {
                                getData(groupingElement);
                            }
                        } else {
                            getData(groupingElement);
                        }
                    }
                    
                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }
                    scope.toggleShowTarget = function () {
                        scope.showTarget = !scope.showTarget;
                        var groupingElement = {
                            sourceColumn: groupedBy 
                        }
                        getData(groupingElement);
                        scope.updateSettings();
                    }

                    function setupTargetSerieData(values){
                        var greentarget = [];
                        for (var i = 0; i < values.length; i++) {
    
                            var greenelement = {
                                x: i,
                                color: 'green',
                                y: values[i].greenValue
                            };
                            greentarget.push(greenelement);
                        }
                        targetSerieData = {
                            data : greentarget,
                            type: 'spline',
                            color: 'green',
                            dashStyle: 'ShortDash',
                            name:  $translate.instant('COMMON_TARGET'), 
                            marker: {
                                enabled:false
                            }
                        }
                    }

                    function loadTargetData(){
                        var options = {
                            groupByInterval: groupingIntervall,
                            timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                            timePeriod: scope.timePeriod.period,
                            filters: scope.filters,
                        };
    
                        dataService().getTargetValuesForPeriod(scope.dataSource.id, options).then(function (values) {
                            if (values) {
                                setupTargetSerieData(values);
                                setupChartData();
                                setupChart();
                            }
                        }); 
                    }

                    function checkElementType(sourceCol, typeChecking){
                        var ref = -1;
                        var element = $filter('filter')(scope.dataSource.elements, { sourceColumn: sourceCol }, true);
                        if(element.length>0){
                            var type = element[0].type;
                            if(type === typeChecking && typeChecking === 'list'){
                                return  element[0].dataListId;
                            } else if (type === typeChecking){
                                return  1;
                            }
                        }
                        return ref;
                    }

                    scope.registerData = function () {
                        var settings = {
                            dataSource: scope.dataSource
                        };

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'registrationDialog.html',
                            controller: 'registrationDialogController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                componentSettings: function () {
                                    return settings;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                        }, function (res) { });
                    };

                    function getPointName(element) {
                        if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                            return getNameById(assets, element);
                        } else if (groupedBy === "priorityStatus") {
                            return suggestionService().getPriorityStatusText(parseInt(element));
                        } else if (groupedBy === "problemCategoryId") {
                            return getNameById(problemCategories, element);
                        } else if (groupedBy === "deviationTypeId") {
                            return getNameById(deviationTypes, element);
                        } else if (groupedBy === "lossTypeId") {
                            return getNameById(consequences, element);
                        } else if (groupedBy === "a3TemplateId") {
                            return getNameById(scope.a3templates, element);
                        } else if (groupedBy === "categoryId") {
                            if (scope.dataSource.id.startsWith("Improvement")) {
                                return getNameById(scope.suggestionCategories, element);
                            } else {    // Only A3
                                return getNameById(scope.a3Categories, element);
                            }
                        } else if (groupedBy === "areaId") {
                            return getNameById(scope.areas, element);
                        } else if (groupedBy === "projectId") {
                            return getNameById(scope.projects, element);
                        } else if (groupedBy === "strategyGoalId") {
                            return getNameById(scope.goals, element); 
                        } else if (groupedBy === "boardId") {
                            return getNameById(scope.boards, element);
                        } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                            return getNameByUserId(scope.users, element);
                        } else if (groupedBy === "dimension" || groupedBy === "dimension2" || groupedBy === "dimension3" || groupedBy === "dimension4") {
                            if (checkElementType(groupedBy, 'user') == 1) {
                                return getNameByUserId(scope.users, element);
                            } else if (checkElementType(groupedBy, 'list') !== -1){
                                return getNameById(listGroupedElements, element);
                            }    
                            else {    
                                return element;
                            }
                        } else if (groupedBy === "severity") {
                            switch (element) {
                                case "1":
                                    return $translate.instant('COMMON_LOW');
                                case "2":
                                    return $translate.instant('COMMON_MEDIUM');
                                case "3":
                                    return $translate.instant('COMMON_HIGH');
                                default:
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                            }
                        } else if (groupedBy === "status") {
                            switch (element) {
                                case "unacceptable":
                                    return $translate.instant('BOARD_INCOMPLETED');
                                case "blank":
                                    return $translate.instant('BOARD_OPEN');
                                case "OK":
                                    return $translate.instant('DASHBOARD_COMPLETED');
                                default:
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                            }
                        } else {
                            return element;
                        }
                    }

                  

                    function getData(groupingElement) {
                        if (!scope.timePeriod) return;
                        if (!groupingElement || groupingElement.sourceColumn == "") {
                            scope.configurationChallenges = true;
                            return;
                        }
                        
                        var options = {
                            groupByInterval: groupingIntervall, //"month", // Foreløpig kun støtte for month, kan tenkes utvides.
                            timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                            timePeriod: scope.timePeriod.period,
                            filters: scope.filters,
                            groupingElement: groupingElement // Hvilken dimensjon man ønsker gruppert på. Eksempelvis AssetId + de eventuell AssetId verdiene man ønsker svaret begrenset til.
                        };


                        if (potentialOptimizationChallenges(scope.dataSource.id, options.timePeriod.from, options.timePeriod.to)) {
                            scope.optimizationChallenges = true;

                        } else {
                            scope.optimizationChallenges = false;
                            dataService().getGroupedSumForPeriod(scope.dataSource.id, options).then(function (values) {
                                if (!values) {
                                    console.log("No values for data source:" + scope.dataSource.id);
                                    return;
                                } else {
                                    var complexityindex = 0;
                                    try {
                                        var noOfGroupCategories = values.results.length;
                                        var noOfDaysWithValues = values.results[0].results.length;
                                        complexityindex = noOfGroupCategories * noOfDaysWithValues;
                                    } catch (err) {
                                    }
                                    if(complexityindex > 3000){
                                        scope.optimizationChallenges = true;
                                    } else {
                                        scope.values = values.results;
                                        if(scope.showTarget){
                                            loadTargetData()
                                        } else {
                                            setupChartData();
                                            setupChart();
                                        }
                                    }
                                }
                            });
                        }
                    }

                    function potentialOptimizationChallenges(datasourceId, from, to) {
                        if (datasourceId == 'Tasks' || datasourceId == 'CompletedTasks') {
                            var timeSpan = timeService.getDurationInDays(from, to);
                            if (timeSpan > 32) {
                                return true;
                            }
                        }
                        return false;
                    }

                    /* This method will be called also when component is first initialized */
                    scope.periodChangedHandler = function (timePeriod) {

                        if (!timePeriod.timeframe) return;
                        if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                        scope.timePeriod = timePeriod;
                        scope.options.timePeriod = timePeriod;
                        if (timePeriod.timeframe === "all" && scope.dataSource) {
                            dataService().getFirstDate(scope.dataSource.id, scope.timePeriod.period, null).then(function (fromDate) {
                                dataService().getLastDate(scope.dataSource.id, scope.timePeriod.period, null).then(function (toDate) {
                                    scope.timePeriod.period.from = fromDate;
                                    var endOfDaytoDate = moment(toDate).endOf('day');
                                    scope.timePeriod.period.to = endOfDaytoDate;
                                    initialize();
                                });
                            });
                        }
                        else {
                            initialize();
                        }

                        if (scope.isAdminMode) {
                            scope.updateSettings();
                        }

                    }

                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            timePeriod: scope.timePeriod,
                            dataSource: scope.dataSource,
                            groupedBy: groupedBy,
                            groupingIntervall: groupingIntervall,
                            filters: scope.filters,
                            showTarget: scope.showTarget,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }

                    // Select dataSource to use.
                    let selectDataSourceModal
                    scope.selectDataSource = function () {
                        if (selectDataSourceModal)
                            return

                        var hasDataSerie = false;
                        if (scope.dataSource) {
                            hasDataSerie = true;
                        }
                        selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceGroupSelector.html',
                            controller: 'dataSourceGroupSelector',
                            windowClass: 'dataserie-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return scope.dataSource;
                                },
                                groupedBy: function () {
                                    return groupedBy;
                                },
                                groupingIntervall: function(){
                                    return groupingIntervall;
                                },
                                filters: function () {
                                    return scope.filters;
                                }
                            }
                        });

                        selectDataSourceModal.result.then(function (result) {
                            groupedBy = result.groupedBy;
                            groupingIntervall = result.groupingIntervall;
                            if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                                scope.title = result.dataSource.title;
                                setRecommendedTimeperiod();
                            }
                            scope.dataSource = result.dataSource; 
                            if(scope.dataSource && scope.dataSource.objectSource == 'internal'){ //no target line for internal datasources. (Use targetgraph instead)
                                scope.showTarget = false;
                            }             
                            scope.filters = result.filters;
                            scope.updateSettings();
                            initialize();
                        }, () => {}).finally(() => {
                            selectDataSourceModal = null
                        });
                    };

                    function setRecommendedTimeperiod(){
                        if(groupingIntervall){
                            if(groupingIntervall == "DAY"){
                                timeSettings.timeframe = 'isoweek';
                                scope.options = {
                                    timePeriod: timeSettings.getTimePeriod()
                                }
                                scope.setTimePeriodDebounced(timeSettings.timeframe, scope.options.timePeriod);               
                            }
                        }
                    }


                    function getNameById(source, id) {
                        if (id && source) {
                            for (var k = 0; k < source.length; k++) {
                                if (source[k].id.toString() === id.toString()) {
                                    if (source[k].name) {
                                        return source[k].name;
                                    } else if (source[k].title) {
                                        return source[k].title;
                                    } else {
                                        return $translate.instant('COMMON_NOT_SPECIFIED');
                                    }
                                }
                            }
                            return $translate.instant('COMMON_NOT_SPECIFIED');
                        } else {
                            return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    }

                    // Used when grouped by user
                    function getNameByUserId(source, id) {
                        if (id && source) {
                            for (var k = 0; k < source.length; k++) {
                                if (source[k].userId.toString() === id.toString()) {
                                    if (source[k].fullName) {
                                        return source[k].fullName;
                                    } else if (source[k].userName) {
                                        return source[k].userName;
                                    } else {
                                        return $translate.instant('COMMON_NOT_SPECIFIED');
                                    }
                                }
                            }
                            return $translate.instant('COMMON_NOT_SPECIFIED');
                        } else {
                            return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    }

                    function getColor(index, value){
                        if(groupedBy == "severity"){
                            return deviationService().getSeverityColor(parseInt(value));
                        } else if (groupedBy == "strategyGoalId"){
                            for (var k = 0; k < scope.goals.length; k++) {
                                if (scope.goals[k].id.toString() === value.toString()) {
                                    if (scope.goals[k].color) {
                                        return scope.goals[k].color;
                                    
                                    } else {
                                        return null;
                                    }
                                }
                            }
                        } else {
                            return grafcolor[index];
                        }
                    }

                    function setupChartData() {

                        // x-axis
                        scope.xAxisData = [];

                        if (scope.values[0]) {
                            for (var i = 0; i < scope.values[0].results.length; i++) {
                                var xAxisDataLabel = "";
                                // Assume every groupingElement has data for every bar
                                if(groupingIntervall == "DAY"){
                                    xAxisDataLabel = moment(scope.values[0].results[i].valueDate).format("D.MMM");
                                } else if(groupingIntervall == "WEEK"){
                                    xAxisDataLabel = $translate.instant('COMMON_TIMEPERIOD_WEEK_PREFIX')+ scope.values[0].results[i].week;
                                } else if(groupingIntervall == "YEAR"){
                                    xAxisDataLabel = scope.values[0].results[i].year;
                                } else {
                                    if (scope.values[0].results[i].month) {
                                        xAxisDataLabel = scope.values[0].results[i].month + ".";
                                    }
                                    xAxisDataLabel = xAxisDataLabel + scope.values[0].results[i].year;
                                }
                                scope.xAxisData.push(xAxisDataLabel);
                            }
                        } else {
                            // alert("No values for defined component");
                        }

                        // data population
                        scope.seriesData = [];
                        for (var i = 0; i < scope.values.length; i++) {
                            var name = getPointName(scope.values[i].element);
                            var color = getColor(i, scope.values[i].element);// grafcolor[i];
                            var data = [];
                            for (var y = 0; y < scope.values[i].results.length; y++) {
                                data.push(scope.values[i].results[y].value);
                            }
                            var element = {
                                name: name,
                                color: color,
                                data: data
                            };
                            scope.seriesData.push(element);
                        }

                        if (scope.showTarget && targetSerieData) {
                            scope.seriesData.unshift(targetSerieData);
                        }
                    }

                    var barChart;
                    function setupChart() {
                        var unit = "";
                        if(scope.dataSource.valueElement && scope.dataSource.valueElement.unit){
                            unit = scope.dataSource.valueElement.unit;
                        }

                        var renderElement = $(elem).find(".barchart")[0];
                        barChart = new Highcharts.chart({
                            chart: {
                                renderTo: renderElement,
                                type: 'column'
                            },
                            title: {
                                text: ''
                            },
                            credits: {
                                enabled: false
                            }, exporting: {
                                enabled: false //remove context menu
                            },
                            xAxis: {
                                categories: scope.xAxisData
                            },
                            yAxis: {
                                title: {
                                    text: unit
                                },
                                stackLabels: {
                                    enabled: true,
                                    style: {
                                        fontWeight: 'bold',
                                        color: grafcolor || graphtextcolor
                                    }
                                }
                            },
                            tooltip: {
                                shared: true
                            },
                            plotOptions: {
                                column: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            },
                            series: scope.seriesData

                        });
                       resizeChart();
                    }

                    scope.updateValue = function (id) {
                        if (!scope.definition) return;
                        if (scope.definition.datasource !== id) return;
                        initialize();
                    }

                    
                    function resizeChart() {
                        if (!elem) return
                        let appEl = elem[0]

                        let width = appEl.offsetWidth
                        let height = appEl.offsetHeight

                        let headerEl = appEl.querySelector(".app-header")
                        if (headerEl)
                            height = height - headerEl.offsetHeight
                        
                        var chartContainer = $(elem).find(".highcharts-container").parent()
                        if (chartContainer.length === 0) return

                        if (barChart)
                        barChart.setSize(width, height, true)
                    }

                    scope.$on("widget-resized", function (event, args) {
                        resizeChart();
                    });

                }

            }
        }]);
