import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('activitycountController', ['$scope', '$interval', '$filter','$element', 'activityService', 'authService', '$translate',
    function ($scope, $interval, $filter,$element, activityService, authService, $translate) {

        $scope.activities = [];
        var translateOptions = {};

        // This is only for Kaizen so return if not in role.
        if (!authService.hasRole("KaizenAdmin")) return;

        // DEFAULT TRANSLATION
        $translate(['COMMON_TIMEELAPSED_MONTH', 'COMMON_TIMEELAPSED_DAYS', 'COMMON_TIMEELAPSED_HOURS', 'COMMON_TIMEELAPSED_MINUTES', 'COMMON_TIMEELAPSED_SECONDS', 'COMMON_TIMEELAPSED_NOW']).then(function (translations) {
            translateOptions = {
                month: translations.COMMON_TIMEELAPSED_MONTH,
                days: translations.COMMON_TIMEELAPSED_DAYS,
                hours: translations.COMMON_TIMEELAPSED_HOURS,
                mins: translations.COMMON_TIMEELAPSED_MINUTES,
                seconds: translations.COMMON_TIMEELAPSED_SECONDS,
                now: translations.COMMON_TIMEELAPSED_NOW
            }
            // Now we can get data
            subscribeToEvents();
            getLatestActivity();
        });

        function removeAnmiation(activity) {
            setTimeout(function () {
                activity.hasChanged = false;
                $scope.$apply();
            }, 5000);
        }
    
        var getLatestActivity = function () {
            activityService().getActivityLast24hours().then(function (activities) {
                activities.forEach(function (activity) {
                    activity.occured = timeService.getTimeElapsed(activity.lastModified, translateOptions);
                });
                $scope.activities = activities;
            });
        }

        var updateFeedTimeStamp = function () {
            $scope.activities.forEach(function (activity) {
                activity.occured = timeService.getTimeElapsed(activity.lastModified, translateOptions);
            });
        }

        var updateTimer = $interval(updateFeedTimeStamp, 5000);
        // listen on DOM destroy (removal) event, and cancel the next UI update
        // to prevent updating time after the DOM element was removed.
        $element.on('$destroy', function () {
            $interval.cancel(updateTimer);
        });
        // Subscribe to Events
        var subscribeToEvents = function () {
            $scope.subscribe('SystemActivityOccured', function (activity) {
                // Handle activity and add to list. Need some filter for what is relevant.
                var category = $filter('filter')($scope.activities, { action: activity.action }, true);
                var item;
                if (category.length > 0) {
                    item = category[0];
                    item.count += 1;
                } else {
                    item = { action: activity.action, count: 1 }
                    $scope.activities.push(item);
                }
                item.hasChanged = true;
                item.lastModified = activity.activityDate;
                // Calculate time elapsed for all elements
                updateFeedTimeStamp();
                $scope.$apply();
                removeAnmiation(item);
            });
        }
    }]);