﻿var DigiLean = angular.module('DigiLean');

DigiLean.directive("deviationCategorySummary", ['$filter', '$translate', 'deviationService', 'navigationService', 'dataService','debounce',
    function ($filter, $translate, deviationService, navigationService, dataService, debounce) {
        return {
            templateUrl: 'deviationCategorySummary.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'timeframe': '@',
                'settings': '=',
                'settingsChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {

                scope.title = $translate.instant('DEVIATION_PARETO_CHALLENGES');
                scope.selectedFilters = [];
                var categorySummaryData = null;
                var drilledDown = false;
                var countTranslated = $translate.instant('COMMON_COUNT');
                var accumulatedTranslated = $translate.instant('DASHBOARD_ACCUMULATED');
                var recordingTranslated = $translate.instant('DEVIATION_NUMBER_OF_DEVIATIONS');
                var hasProject = navigationService().hasModule("PROJECT");

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    loadData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.options.timePeriod,
                        filters: scope.selectedFilters
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                scope.$watch('timeframe', function (timeframe) {
                    scope.setTimePeriodDebounced(timeframe, null);
                });

                // Default options
                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                function loadData() {
                    if (!scope.timePeriod || !scope.timePeriod.period) return;
                    var options = {TimePeriod:scope.timePeriod.period,
                        Filters: scope.selectedFilters}
                    deviationService().getSummaryOfCategoriesWithFilter(options).then(function (data) {
                        categorySummaryData = data;
                        drawChart(data);
                        resizeChart();
                    });
                }

                scope.subscribe("DeviationCreated", function (deviation) {
                    loadData();
                });

                scope.filterChangedHandler = function (filters) {
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                    loadData();
                };

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.selectedFilters = [];
                    if (settings) {
                        if (settings.title) {
                            scope.title = settings.title;
                        }
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (settings.filters) {
                            scope.selectedFilters = settings.filters;
                        }
                        loadData();
                    } else {
                        loadData();
                    }
                });

                function dataDrill(columnOrderNo) {
                    drilledDown = !drilledDown;
                    if (drilledDown) {
                        if (categorySummaryData[columnOrderNo] && categorySummaryData[columnOrderNo].subCategorySummary) {
                            if (categorySummaryData[columnOrderNo].subCategorySummary.length > 0) {
                                drawChart(categorySummaryData[columnOrderNo].subCategorySummary);
                            } else {
                                drilledDown = false;
                            }
                        }
                    } else {
                        drawChart(categorySummaryData);
                    }
                }

                // Draw options
                var categoryChart
                var paddingRight = 30;
                var paddingBottom = 100;
                function resizeChart() {
                    var host = $(elem).closest(".grid-stack-item-content");
                    if (host.length == 0)
                        host = $(elem).closest(".modal-body")
                    
                    if (host.length == 0) return;
                    var width = host.width();
                    var height = host.height();
                    if (categoryChart)
                    categoryChart.setSize(width - paddingRight, height - paddingBottom, true);
                }
                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

                function drawChart(data) {

                    var categories = [];
                    var count = [];
                    var accumulated = [];

                    for (var i = 0; i < data.length; i++) {
                        var currentItem = data[i];
                        categories.push(currentItem.title);
                        count.push(currentItem.count);
                        accumulated.push(currentItem.accumulated);

                    }

                    var renderElement = $(elem).find(".summary-chart")[0];
                    categoryChart = Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        },
                        legend: {
                            layout: 'horizontal',
                            verticalAlign: 'bottom'
                        },
                        exporting: { enabled: false },
                        tooltip: {
                            formatter: function () {
                                if (this.series.name === accumulatedTranslated) {
                                    return this.y + '%';
                                }
                                //return this.x + ':<br/>' + '<b># ' + this.y.toString().replace('.', ',') + ' </b>' + '<br/>' + '<b>Percent ' + Math.round((this.y / 28) * 100) + '% </b>';
                                return this.x + ':<br/>' + '<b># ' + this.y + '</b>';
                            }
                        },
                        xAxis: {
                            categories: categories,
                            crosshair: true
                        },
                        yAxis: [{
                            title: {
                                text: recordingTranslated
                            }
                        }, {
                            labels: {
                                formatter: function () {
                                    return this.value + '%';
                                }
                            },
                            max: 100,
                            min: 0,
                            opposite: true,
                            plotLines: [{
                                color: 'red',
                                dashStyle: 'shortdash',
                                value: 80,
                                width: 3,
                                zIndex: 10
                            }],
                            title: {
                                text: ''
                            }
                        }],
                        series: [{
                            name: countTranslated,
                            data: count,
                            color: '#FFCE54',
                            type: 'column',
                            point: {
                                events: {
                                    click: function () {
                                        dataDrill(this.x);
                                    }
                                }
                            }
                        }, {
                            data: accumulated,
                            name: accumulatedTranslated,
                            type: 'spline',
                            yAxis: 1,
                            id: 'accumulated'
                        }
                        ]
                    });
                }
                


                var deviationGroupElements = [
                    {
                        label: $translate.instant('COMMON_DEVIATION_TYPES'),
                        sourceColumn: "deviationTypeId",
                        type: "deviationtype"
                    },
                    {
                        label: $translate.instant('DEVIATION_SEVERITY'),
                        sourceColumn: "severity",
                        type: "deviationseverity"
                    },
                    {
                        label: $translate.instant('COMMON_ASSET'),
                        sourceColumn: "assetId",
                        type: "asset"
                    },
                    {
                        label: $translate.instant('COMMON_AREA'),
                        sourceColumn: "areaId",
                        type: "area"
                    },
                    {
                        label: $translate.instant('COMMON_CATEGORY'),
                        sourceColumn: "problemCategoryId",
                        type: "problemcategory"
                    },
                                      
                ];
        
                if(hasProject){
                    var project = {
                        label: $translate.instant('PROJECT_PROJECT'),
                        sourceColumn: "projectId",
                        type: "project"
                    };
                    deviationGroupElements.push(project);
                }

                var deviationNew = {
                    id: "DeviationNew",
                    title: $translate.instant('DEVIATION_NEW_DEVIATIONS'),
                    description: $translate.instant('DASHBOARD_DEVIATION_NEW_DESC'),
                    valueElement: {
                        unit: $translate.instant('COMMON_NUMBER'),
                        label: $translate.instant('COMMON_NUMBER')
                    },
                    objectSource: 'internal',
                    ownerUserDisplayName: 'DigiLEAN',
                    elements: deviationGroupElements
                };
                scope.filterDatasource = deviationNew;

            }
        }
    }]);
