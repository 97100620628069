angular
    .module('DigiLean')
    .directive("smartactionlistTextCell", ['$filter', '$translate', 'projectService','debounce',
        function ($filter, $translate, projectService, debounce) {
            return {
                templateUrl: 'smartactionlistTextCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.text = "";
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();

                    scope.usePredefinedLabels = false;
                    scope.labels = [];
                    var columnId = null;

                    scope.$watch('model', function (model) {
                        var value = model.cell.value;
                        columnId = model.column.id;
                        var settings = model.column.settings;

                        if (value) {
                            setValue(value);
                        }
                        if (settings) {
                            if (settings.usePredefinedLabels && settings.predefinedLabels) {
                                scope.labels = settings.predefinedLabels;
                                scope.usePredefinedLabels = true;
                            }
                        }
                        // Extend model with SetValue method to allow timeboard to change
                        model.setValue = function (value) {
                            setValue(value);
                            scope.saveChanges();
                        };
                        model.getSortValue = function () {
                            if (scope.usePredefinedLabels && scope.labels) {
                                var order = scope.labels.length;
                                if(scope.selectedPostIt){
                                    var match = scope.labels.find(a => a.title == scope.text || a.text == scope.text)
                                    if (match) {
                                        order = scope.labels.indexOf(match);
                                    }
                                }
                                return order;
                            }
                            return scope.text;
                        }
                    });

                    scope.autofocus = function () {
                        setTimeout(function () {
                            //document.querySelector( "input" ).focus();
                            $(".project-text-cell").focus();
                        }, 50);
                    }

                    var setValue = function (value) {
                        scope.background = value.background;
                        scope.text = value.text;
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background};
                            scope.customColorBackground = value.background;
                        }
                    }

                    scope.subscribe("SmartActionListTextCellLabelUpdated", function (value) {
                        if (columnId === value.columnId) {
                            scope.labels = value.predefinedLabels;
                            scope.usePredefinedLabels = value.usePredefinedLabels
                        }
                    });

                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        if (cell.boardTaskId == scope.model.cell.boardTaskId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        }
                    });
                    scope.selectTextAndColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default' || postIt.title == "") {
                            scope.background = "inherit";
                            scope.color = postIt.color;
                            scope.text = "";
                        } else {
                            scope.background = postIt.background;
                            scope.color = postIt.color;
                            scope.text = postIt.title;
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default') {
                            scope.background = "inherit";
                            scope.color = "#676a6c";
                        } else {
                            scope.background = postIt.background;
                            scope.color = "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }
                    scope.saveChanges = function () {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({ cell: scope.model.cell })
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
