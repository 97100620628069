/* Create attachments component for task */
angular
    .module('DigiLean')
    .directive("taskAttachment", ['$filter', '$uibModal','documentService',
        function ($filter, $uibModal, documentService) {
            return {
                templateUrl: 'taskattachment.html',
                restrict: 'E',
                scope: {
                    'file': '<',
                },
                link: function (scope, elem, attrs) {
                    var viewerFileExtensions = [
                        { type: 'image', extension: '.jpg' },
                        { type: 'image', extension: '.jpeg' },
                        { type: 'image', extension: '.png' },
                        { type: 'pdf', extension: '.pdf' },
                        { type: 'excel', extension: '.xlsx' },
                        { type: 'excel', extension: '.xls' },
                        { type: 'word', extension: '.doc' },
                        { type: 'word', extension: '.docx' },
                        { type: 'powerpoint', extension: '.ppt' },
                        { type: 'powerpoint', extension: '.pptx' },
                    ];
  
                    scope.$watch('file', function (file) {
                        // apply
                        if (!file) return;
                        if (file.validFileUrl) return;
                        var fileType = $filter('filter')(viewerFileExtensions, { extension: file.fileExtension.toLowerCase() });
                        if (fileType.length > 0) {
                            file.fileType = fileType[0].type;
                        } 
                    });
                }
            }
        }]);
