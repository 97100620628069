
angular
    .module('DigiLean')
    .component("improvementColumn", {
        templateUrl: 'improvementColumn.html',
        bindings: {
            context: '@',
            label: '@',
            labelNew: '@',
            name: '@',
            data: '<',
            columnLoaded: '&'
        },
        controller: ['$element', '$translate', 'improvementCommonService',
            function ($element, $translate, improvementCommonService) {
                var $ctrl = this
                $ctrl.labelText = "default"
                $ctrl.labelNewText = ""
                
                $ctrl.$onChanges = function (changes) {
                    if (changes.label) {
                        if (changes.label.currentValue) {
                            $ctrl.labelText = $translate.instant(changes.label.currentValue)
                        }
                    }
                    if (changes.labelNew) {
                        if (changes.labelNew.currentValue) {
                            $ctrl.labelNewText = $translate.instant(changes.labelNew.currentValue)
                        }
                    }
                }

                $ctrl.newClicked = function() {
                    const options = {detail: "new", bubbles: false, composed: true}
                    $element[0].dispatchEvent(new CustomEvent("new-improvement-clicked", options))
                }
                $ctrl.hasDueDate = function (item) {
                    if (item.dueDate) {
                        return true
                    } else return false
                }

                $ctrl.hasResponsible = function (item) {
                    if (item.responsibleUserId) {
                        return true;
                    } else return false;
                }

                $ctrl.hasFinancialNumbers = function (item) {
                    if (item.estimatedCostOfInvestment > 0 || item.costOfInvestment > 0 || item.estimatedGainOfInvestment > 0 || item.gainOfInvestment > 0) {
                        return true;
                    } else return false;
                };

                $ctrl.$onInit = function() {
                    if (!$ctrl.data)
                        $ctrl.data = []
                }

                $ctrl.$postLink = function() {
                    if ($ctrl.columnLoaded) {
                        const ulEl = $element[0].querySelector("ul")
                        $ctrl.columnLoaded({column: ulEl})
                    }
                    
                }
                
                $ctrl.suggestionOpenForEdit = (suggestion) => {
                    improvementCommonService.suggestionOpenForEdit(suggestion.id).then(updatedSuggestion => {
                        suggestion.roi = updatedSuggestion.roi;
                    })
                }
            }]
    });