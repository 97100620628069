angular
    .module('DigiLean')
    .controller("editDeviationTypeController", ['$scope','$translate', 'deviationService', 'languageAdminService','$uibModalInstance', 'deviationTypeId', 
    function ($scope, $translate,deviationService, languageAdminService, $uibModalInstance, deviationTypeId) {


        $scope.deviationTypeId = deviationTypeId;
        $scope.deviationType = {};

        var defaultConfig = {languageCode: '',
                    languageLabel: $translate.instant('ADMIN_DEFAULT_CONFIG'),
                    nativeLabel: $translate.instant('ADMIN_DEFAULT_CONFIG')
                    };
        $scope.selectedLanguage = defaultConfig;
        $scope.languages = languageAdminService().getSupportedLanguages()
        $scope.languages.unshift($scope.selectedLanguage);
        $scope.selectedCustomLanguageLabel;

        $scope.changeLanguage = function (language) {
            if(!language) return;
            $scope.selectedLanguage = language;
            $scope.selectedCustomLanguageLabel = $scope.deviationType.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
            if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
        }

        $scope.updateTranslationLabel = function (translabel) {
            var existing =  $scope.deviationType.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
            
            if(!existing){
                var customLanguageLabel = {
                    id:0,
                    languageCode: $scope.selectedLanguage.languageCode,
                    label: translabel.label,
                    description: translabel.description
                }
                $scope.deviationType.customLanguageLabels.push(customLanguageLabel);
            } else {
                existing.label = translabel.label;
                existing.description = translabel.description;
            }
        }

        deviationService().getTranslationsWithDeviationType($scope.deviationTypeId).then(function(type){
            $scope.deviationType = type;
        });
        

        $scope.ok = function () {
            deviationService().updateDeviationType($scope.deviationType).then(function(type){
                $uibModalInstance.close(type)
            });          
        };

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);
