import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("connectedUsers", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'connectedUsers.html',
                restrict: 'E',
                scope: {
                    "boardId": "<?",
                    "dashboardId": "<?",
                    "clearSelection": "<",
                    "refreshUsers": "<?",
                    "selectUserHandler": "&",
                    "listExpandedHandler": "&"
                },
                link: function (scope, elem, attrs) {
                    scope.users = [];
                    scope.displayUsers = [];
                    scope.showAll = false;
                    scope.shouldExpandButtonBeVisible = false;
                    scope.showAllTooltip = $translate.instant("COMMON_SHOW_ALL");
                    scope.showFewerTooltip = $translate.instant("COMMON_SHOW_FEWER");
                    var maxDisplayUsers = 8;

                    scope.$watch('boardId', function () {
                        if (!scope.boardId) return;
                        loadUsers();
                    });

                    scope.$watch('refreshUsers', function () {
                        if (scope.refreshUsers) {
                            loadUsers();
                        }
                    });

                    scope.$watch('dashboardId', function () {
                        if (!scope.dashboardId) return;
                        loadUsers();

                    });


                    var loadUsers = function () {
                        if (scope.boardId) {
                            boardService.getUserListForBoard(scope.boardId).then(function (data) {
                                updateUsers(data);
                            });
                        }
                        if (scope.dashboardId) {
                            dashboardService.getUserListForBoard(scope.dashboardId).then(function (data) {
                                updateUsers(data);
                             });
                        }
                    }

                    
                    var activateDraggable = function() {
                        setTimeout(function () {
                            $(".activeuser").draggable({
                                cursor: 'move',
                                helper: userplaceHolder
                            }, 50);
                        }, 200);
                    }


                    var updateUsers = function (data) {
                        data.forEach(user => {
                            user.isSelected = false;
                        });
                        scope.displayUsers = [];
                        scope.users = data;
                        scope.users.sort(function (a, b) {
                            if (a.profileImage && b.profileImage) {
                                var test = a.displayName > b.displayName;
                                if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1
                                }
                            } else if (a.profileImage) {
                                return -1
                            } else {
                                return 1
                            }
                        })
                        for (let index = 0; index < maxDisplayUsers; index++) {
                            if (scope.users.length - 1 >= index) {
                                scope.displayUsers.push(scope.users[index]);
                            }
                        }
                        activateDraggable();
                        scope.shouldExpandButtonBeVisible = scope.displayUsers.length < scope.users.length;
                        scope.publish("RefreshUserConnected");
                    }

                    scope.toggleShowAll = function () {
                        scope.showAll = !scope.showAll;
                        if (scope.listExpandedHandler) {
                            scope.listExpandedHandler();
                        }
                       activateDraggable();
                    }

                    scope.$watch('clearSelection', function () {
                        if (!scope.clearSelection) return;
                        if (!scope.selectedUser) return;
                        scope.selectedUser.isSelected = false;
                    });

                    // connected users
                    scope.subscribe('UsersConnectedChanged', function (data) {
                        console.log('userconnected event');
                        updateConnectedUsers(data);
                    });

                    scope.selectedUser = null;
                    scope.selectUser = function (user) {
                        // If user already selected, we need to clear selection.
                        if (scope.selectedUser && scope.selectedUser !== user) { // Clear previous selectedUser
                            scope.selectedUser.isSelected = false;
                        }
                        if (user.isSelected) {
                            scope.selectedUser = null;
                        } else {
                            scope.selectedUser = user;
                        }
                        user.isSelected = !user.isSelected;
                        scope.selectUserHandler({ user: scope.selectedUser });
                    }

                    function updateConnectedUsers(connectedUsers) {
                        if (!connectedUsers) return;
                        for (var i = 0; i < scope.users.length; i++) {
                            var user = scope.users[i];
                            var isConnected = false;
                            for (var j = 0; j < connectedUsers.length; j++) {
                                if (scope.users[i].userName.toLowerCase() === connectedUsers[j].userName.toLowerCase()) {
                                    isConnected = true;
                                }
                            }
                            user.isConnected = isConnected;
                        }
                    }

                    // DragAndDrop of user
                    function userplaceHolder(event) {
                        var dragTarget = event.currentTarget;
                        var html = $(dragTarget).html();
                        return "<li class='activeuser' style='z-index: 1002'>" + html + "</li>";
                        // var user = $(dragTarget).data("displayname");
                        // return "<li class='activeuser' style='z-index: 1000'><i class='fa fa-user'></i>" + user + "</li>";
                    }
                }
            }
        }]);
