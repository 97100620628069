import { getUserPreference, updateUserPreference, deleteUserPreference} from "@common/services/user/userService"

let a3Favorites: any[] = []

const FavoriteA3s = "FavoriteA3s"
export const getA3Favorites = async () => {
    a3Favorites = await getUserPreference(FavoriteA3s)
    return a3Favorites
}
export const updateFavoriteA3s = async (value: any) => {
    a3Favorites = await updateUserPreference(FavoriteA3s, value)
    return a3Favorites
}

export const addA3ToFavorites = async (id: number, title: string) => {
    const alreadyFav = a3Favorites.find(a => a.id == id)
    if (!alreadyFav) {
        a3Favorites.push({id, title, isStar: true})
        return await updateFavoriteA3s(a3Favorites)
    }
    return a3Favorites
}

export const removeA3FromFavorites = async (a3Id: number) => {
    const a3ToRemove = a3Favorites.find(a => a.id == a3Id)
    if (a3ToRemove) {
        const index = a3Favorites.indexOf(a3ToRemove)
        a3Favorites.splice(index, 1)
        return await updateFavoriteA3s(a3Favorites)
    }
    return a3Favorites
}

const A3Filter = "A3Filter"
export const getA3Filter = async () => {
    const filter = await getUserPreference(A3Filter)
    return filter
}
export const updateA3Filter = async (value: any) => {
    return await updateUserPreference(A3Filter, value)
}
export const deleteA3Filter = async () => {
    return await deleteUserPreference(A3Filter)
}