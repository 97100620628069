import * as assetService from "@common/services/assetService"
var DigiLean = angular.module('DigiLean');
DigiLean.controller('categoryAdminController', ['$scope', '$filter', '$translate', 'deviationService', 'modalService','languageAdminService',
function ($scope, $filter, $translate, deviationService, modalService, languageAdminService) {
    $scope.allCategories = null;
    $scope.message = "";
    $scope.assets = null;
    $scope.categoryList = [];
    $scope.selectedCategory = {};
    $scope.selectedAsset = {};
    $scope.showEdit = false;

    $translate('ADMIN_REGISTER_NEW_CATEGORY').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
    });

    var defaultConfig = {languageCode: '',
                    languageLabel: $translate.instant('ADMIN_DEFAULT_CONFIG'),
                    nativeLabel: $translate.instant('ADMIN_DEFAULT_CONFIG')
                    };
    $scope.selectedLanguage = defaultConfig;
    $scope.languages = languageAdminService().getSupportedLanguages()
    $scope.languages.unshift($scope.selectedLanguage);
    $scope.selectedCustomLanguageLabel;

    $scope.changeLanguage = function (language) {
        if(!language) return;
        $scope.selectedLanguage = language;
        $scope.selectedCustomLanguageLabel = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
    }

    $scope.updateTranslationLabel = function (translabel) {
        var existing = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        
        if(!existing){
            var customLanguageLabel = {
                id:0,
                languageCode: $scope.selectedLanguage.languageCode,
                label: translabel.label,
                description: translabel.description
            }
            $scope.registration.customLanguageLabels.push(customLanguageLabel);
        } else {
            existing.label = translabel.label;
            existing.description = translabel.description;
        }
    }

    $scope.newCategory = function () {
        resetRegistration();
       // $scope.showEdit = true;
    }

    $scope.assetChanged = function (asset) {     
        $scope.registration.assetId = asset.id;
        $scope.selectedAsset = asset;
    }

    $scope.assetChangeHandler = function (assetinfo) {
        if (assetinfo) {
            $scope.registration.assetId = assetinfo.asset.id;
            $scope.selectedAsset = assetinfo.asset;
        }       
    }
    
    function setupAsset(assets){
        $scope.assets = assets;
        for (var k = 0; k < $scope.assets.length; k++) {
            $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
        }
        $scope.assetmodel = { assetId: null,
            assets: $scope.assets};
    }
    assetService.getAllAssets().then(function (data) {
        setupAsset(data);
        setFollowUpGroup();
    });

    $scope.save = function (isValid) {
        if ($scope.selectedCategory) {
            $scope.registration.parentId = $scope.selectedCategory.id;
        }
        
        if ($scope.registration.isNew) {
            deviationService().createProblemCategory($scope.registration).then(function (newCategory) {
                loadCategories();
                resetRegistration();
                // Raise signalr event
                $translate('ADMIN_MESSAGE_CATEGORY_ADDED').then(function (msg) {
                    toastr.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
            }, function () {

            });
        } else {
            deviationService().updateProblemCategory($scope.registration).then(function () {
                loadCategories();
                resetRegistration();
                // Raise signalr event
                $translate('ADMIN_MESSAGE_CATEGORY_MODIFIED').then(function (msg) {
                    toastr.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
            }, function () {

            });
        }
    }

    $scope.edit = function (category) {
        $scope.new = false;
        
        $scope.registration = {
            isNew: false,
            id: category.id,
            title: category.title,
            description: category.description,
            parentId: category.parentId,
            customLanguageLabels: category.customLanguageLabels
        }
     
        $scope.selectedCategory = $filter('filter')($scope.allCategories, { id: category.parentId }, true)[0];
        $scope.categoryList = $filter('filter')($scope.allCategories, NotLikeIdFilter("id", category.id));
        var clearCategory = {title:""}; 
        $scope.categoryList.unshift(clearCategory);
        $scope.selectedAsset = $filter('filter')($scope.assets, { id: category.assetId })[0];
        $scope.assetmodel = { assetId: category.assetId,
            assets: $scope.assets};
        $scope.selectedCustomLanguageLabel = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
    }

    $scope.newSubCategory = function (category) {
        $scope.showEdit = true;
        $translate('ADMIN_REGISTER_NEW_CATEGORY').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });
        
        $scope.registration = {
            isNew: true,
            title: "",
            description: "",
            parentId: category.id,
            customLanguageLabels: []
        }
     
        $scope.selectedCategory = $filter('filter')($scope. gories, { id: category.id })[0];     
    }
    

    function NotLikeIdFilter(property, categoryId) {
        return function (item) {
            if (item[property] === null) return false;
            var id = item[property];
            return id !== categoryId;

        }
    }


    function setFollowUpGroup() {
        if($scope.allCategories && $scope.assets){
            for(var i=0; i<$scope.allCategories.length; i++){
                if($scope.allCategories[i].assetId){
                    var asset = $filter('filter')($scope.assets , { id: $scope.allCategories[i].assetId }, true);
                    if(asset[0]){
                        $scope.allCategories[i].assetName = asset[0].name;
                    }
                }
                if($scope.allCategories[i].children){
                    for(var s=0; s<$scope.allCategories[i].children.length; s++){
                        if($scope.allCategories[i].children[s].assetId){
                            var asset = $filter('filter')($scope.assets , { id: $scope.allCategories[i].children[s].assetId }, true);
                            if(asset[0]){
                                $scope.allCategories[i].children[s].assetName = asset[0].name;
                            }
                        }
                    }
                }
            }
        }
    }   

    $scope.delete = function (category) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CATEGORY_CONFIRMATION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.COMMON_DELETE,
                headerText: translations.COMMON_DELETE + ' ' + category.title + '?',
                bodyText: translations.ADMIN_MESSAGE_DELETE_CATEGORY_CONFIRMATION 
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                deviationService().deleteProblemCategory(category.id).then(function (data) {  
                    loadCategories();
                    resetRegistration();
                    $translate('ADMIN_MESSAGE_CATEGORY_DELETED').then(function (msg) {
                            toastr.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                    });
                },function (err) {
                });
            });
        });
    }

    var resetRegistration = function () {
        $scope.new = true;
        $translate('ADMIN_REGISTER_NEW_CATEGORY').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });
        $scope.selectedCategory = {};
        $scope.selectedAsset = {};
        $scope.categoryList = $scope.allCategories;
        $scope.selectedCustomLanguageLabel = {};
        $scope.registration = {
            isNew: true,
            id: 0,
            title: "",
            description: "",
            parentId: null,
            customLanguageLabels: []
        }
    }

    var loadCategories = function () {
        deviationService().getTranslationsWithProblemCategories().then(function (list) {
            $scope.allCategories = list;
                   
            $scope.categoryList = $scope.allCategories.slice();
            var clearCategory = {title:""}; 
            $scope.categoryList.unshift(clearCategory);
            setFollowUpGroup();
            setTimeout(function () {
                $('.dd').nestable({ 
                    maxDepth:2 });
                $('.dd').on('change', function (e, l) {
                    /* on change event */
                    var structure = $('.dd').nestable('serialize');
                    deviationService().saveCategoryTree(structure);
                });
            }, 600);
        });
    }

    resetRegistration();
    loadCategories();
    setFollowUpGroup();
}]);