import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("actionlistA3tasks", ['$filter', '$uibModal', '$state', 'boardTaskService', 'actionlistService', 'taskDrawingService',
        function ($filter, $uibModal, $state, boardTaskService, actionlistService, taskDrawingService) {
            return {
                templateUrl: 'actionlistA3tasks.html',
                restrict: 'E',
                scope: {
                    'a3': '=',
                    'readonly': '@',
                    'viewcontext': '=',
                },
                link: function (scope, element, attrs) {
                    scope.tasks = [];
                    scope.a3taskCount = 0;
                    scope.a3taskOverdueCount = 0;
                    scope.$watch("a3", function () {
                        if (!scope.a3) return;
                        // refreshTasks(scope.a3.id);
                        setupDragDrop();
                    });

                    scope.$watch("viewcontext", function () {
                        if (!scope.viewcontext) return;
                    });

                    scope.toggleOpenA3 = function() {
                        if (!scope.a3) return;
                        if (!scope.a3.hasLoadedTasks) {
                            refreshTasks(scope.a3.id);
                        }
                    }

                    function isViewTimeBoard() {
                        if (scope.viewcontext && scope.viewcontext.view === "TimeBoard") return true;
                        return false;
                    }
                    function setupDragDrop() {
                        // Make board container droppable to accept tasks from external sources, including Timeboard.
                        $(element).find(".actionlist-container").droppable({
                            drop: function (event, ui) {
                                var id = 0;
                                $(this)
                                .removeClass("ui-state-highlight")
                                // Timeobard drop check
                                var isTimeBoardEvent = $(ui.draggable).hasClass("fc-event");
                                if (isTimeBoardEvent === true) {
                                    id = $(ui.draggable).attr("data-id");
                                    moveTaskToActionList(id, function () {
                                        // Remove from timeboard.
                                        if (isViewTimeBoard) {
                                            $('.calendar').fullCalendar('removeEvents', id);
                                        }

                                    });
                                    return;
                                }

                                var isPortlet = $(ui.draggable).hasClass("portlet");
                                if (isPortlet === true || isActionItem) {
                                    id = $(ui.draggable).attr("data-id");
                                    moveTaskToActionList(id, function () {
                                    });
                                    return;
                                }

                                var isActionItem = $(ui.draggable).attr("data-is-action-item").length > 0;
                                if (isActionItem) {
                                    id = $(ui.draggable).attr("data-id");
                                    moveTaskToActionList(id, function () {
                                    });
                                }
                                // From weeklyboard / kanban board

                            }, over: function (event, ui) {
                                $(this).addClass("ui-state-highlight")
                                scope.a3.isOpen = true;
                                toggleOpenA3();
                            },
                            out: function (event, ui) {
                                $(this).removeClass("ui-state-highlight")
                            }
                        });

                        $(element).find(".actionlist").sortable({
                            connectWith: ".boardcell, .actionlist, fc-event-container, task-container",
                            placeholder: "portlet-placeholder ui-corner-all",
                            receive: function (e, ui) {
                                if (ui) {
                                    ui.sender.sortable("cancel");
                                }
                            }
                        });
                    }

                    function moveTaskToActionList(id, completeCallBack) {
                        boardTaskService().get(id).then(function (task) {
                            boardTaskService().moveTaskToActionItem(task).then(function (result) {
                                scope.isProcessing = false;
                                completeCallBack();
                                scope.publish('SendTaskMovedToActionList', task);
                            });
                        })

                    }

                    scope.subscribe('TaskMovedToActionList', function (task) {
                        if (!task.a3Id) return; // we only support a3s
                        if (task.a3Id !== scope.a3.id) return;
                        if (!isTaskPartOfA3(task.id)) {
                            task.dateStyleClass = actionlistService().getTaskDateStyle(task);
                            addTaskToActionList(task);
                        };
                    });

                    scope.subscribe('ActionItemMovedToTask', function (task) {
                        if (isTaskPartOfA3(task.id)) {
                            removeTaskFromActionList(task.id)
                        };
                    });

                    scope.subscribe('TaskStatusUpdated', function (status) {
                        updateTaskStatus(status);
                    });
                    scope.subscribe('TaskUpdated', function (task) {
                        updateTask(task);
                    });
                    scope.subscribe('TaskCommentAdded', function (task) {
                        updateTask(task);
                    });
                    scope.subscribe('TasksDeleted', function (taskIds) {
                        removeTasksFromActionList(taskIds);
                    });

                    scope.subscribe('CreateActionListItem', function (task) {
                        if (!task.a3Id) return; // we only support a3s
                        if (task.a3Id !== scope.a3.id) return;
                        if (!isTaskPartOfA3(task.id)) {
                            addTaskToActionList(task);
                        };
                    });

                    scope.subscribe('TaskDeleted', function (taskId) {
                        if (isTaskPartOfA3(taskId)) {
                            removeTaskFromActionList(taskId)
                        };
                    });

                    function isTaskPartOfA3(taskId) {
                        return $filter('filter')(scope.tasks, { id: taskId }).length > 0;
                    }

                    scope.changeStatus = function (task) {
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        var statusOptions = {
                            taskid: task.id,
                            status: task.status
                        };
                        boardTaskService().updateStatus(task.id, statusOptions).then(function (result) {
                            task.dateStyleClass = actionlistService().getTaskDateStyle(task);
                            updateTaskStatusCount();
                        });
                    }

                    scope.goToA3 = function (a3id) {
                        $state.go('a3wizard', { id: a3id }, { reload: false });
                    }

                    function updateTaskStatusCount() {
                        scope.a3taskCount = scope.tasks.length;
                        scope.a3taskOverdueCount = $filter('filter')(scope.tasks, { dateStyleClass: "overdue" }).length;
                        // Publish result
                        var status = {
                            a3Id: scope.a3.id,
                            total: scope.a3taskCount,
                            overdue: scope.a3taskOverdueCount
                        }
                        scope.publish("A3ActionListStatusChanged", status);
                    }

                    function updateTaskStatus(status) {
                        var tasks = $filter('filter')(scope.tasks, { id: status.taskId }, true);
                        if (tasks.length > 0) {
                            tasks[0].status = status.status;
                            tasks[0].dateStyleClass = actionlistService().getTaskDateStyle(tasks[0]);
                        }
                        updateTaskStatusCount();
                    }

                    function updateTask(task) {
                        var updatedtasks = $filter('filter')(scope.tasks, { id: task.id }, true);
                        if (updatedtasks.length > 0) {
                            refreshTasks(scope.a3.id);
                        }
                    }
                    function addTaskToActionList(task) {
                        scope.tasks.push(task);
                        updateTaskStatusCount();
                    }

                    function removeTasksFromActionList(taskIds) {
                        angular.forEach(taskIds, function (taskid, key) {
                            var matchingTasks = $filter('filter')(scope.tasks, { id: taskid }, true);
                            if (matchingTasks.length > 0) {
                                var i = scope.tasks.indexOf(matchingTasks[0]);
                                scope.tasks.splice(i, 1);
                            }
                        });
                        updateTaskStatusCount();
                    }
                    function removeTaskFromActionList(taskId) {
                        // remove task that is moved
                        var taskIds = [];
                        taskIds.push(taskId);
                        removeTasksFromActionList(taskIds);
                    }

                    scope.openForEdit = function (task, activeTab) {
                        if (!activeTab) activeTab = "Info";
                        boardTaskService().get(task.id).then(function (task) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false
                                        }
                                        return options;
                                    }

                                    
                                }
                            });
                        });
                    }


                    scope.isLoading = true;
                    function refreshTasks(a3Id) {
                        scope.a3.hasLoadedTasks = true;
                        scope.tasks = [];
                        scope.a3taskCount = 0;
                        scope.a3taskOverdueCount = 0;
                        var timeperiod = timeService.getTimePeriodBeforeAfter('180', '14');
                        var actionTaskQuery = {
                            actionType: 'A3',
                            statuses: ['blank', 'unacceptable'],
                            timeperiod: timeperiod,
                            entityId: a3Id
                        }
                        actionlistService().getActionTasks(actionTaskQuery).then(function (tasks) {
                            scope.isLoading = false;
                            for (var t = 0; t < tasks.length; t++) {
                                scope.tasks.push(tasks[t]);
                            }
                            var week = timeService.getCurrentWeek(moment().isoWeek(), moment().isoWeekYear());
                            var okTaskQuery = {
                                actionType: 'A3',
                                entityId: a3Id,
                                statuses: ['OK'],
                                timeperiod: {
                                    from: week.startDate.toJSON(),
                                    to: week.endDate.toJSON()
                                }
                            }
                            actionlistService().getActionTasks(okTaskQuery).then(function (tasks) {
                                // Check only overdue for blank and unacceptable tasks
                                for (var t = 0; t < scope.tasks.length; t++) {
                                    scope.tasks[t].dateStyleClass = actionlistService().getTaskDateStyle(scope.tasks[t]);
                                }
                                
                                for (var t = 0; t < tasks.length; t++) {
                                    scope.tasks.push(tasks[t]);
                                }

                                updateTaskStatusCount();
                            });
                        });

                    }

                }
            }
        }]);
