angular.module('DigiLean').factory('yearlyboardRenderService', ['$filter',
    function ($filter) {
        function yearlyboardRenderService() {

            return {
                addTaskToRow: function (task, row, timeOptions) {
                    // Make sure task has rowIdentifcator
                    task.rowCategoryId = row.id;
                    task.rowIndex = row.index;
                    // If no current tasks, than create
                    if (row.taskRows.length == 0) {
                        var newRow = this.createTaskRow(task);
                        row.taskRows.push(newRow);
                        return;
                    }
                    // else we will try to fit in first available row.
                    for (let index = 0; index < row.taskRows.length; index++) {
                        const taskRow = row.taskRows[index];
                        var taskAdded = this.addTaskToRowIfSpace(task, taskRow)
                        if (taskAdded) return;
                    }
                    // If not placed in existing row, we need to add a new one
                    var newRow = this.createTaskRow(task, timeOptions);
                    row.taskRows.push(newRow);
                    return;
                },

                moveTask: function(task, targetOptions, timeOptions) {
                    var targetMonth = targetOptions.targetMonth;
                    var diff = task.startMonth - targetMonth;
                    if (diff > 0) {
                        task.startTime = moment(task.startTime).subtract(diff, "months");
                        task.endTime = moment(task.endTime).subtract(diff, "months");
                    } else {
                        diff = diff * -1;
                        task.startTime = moment(task.startTime).add(diff, "months");
                        task.endTime = moment(task.endTime).add(diff, "months");
                    }
                    this.calculateDuration(task, timeOptions);
                },

                reCalculateRow: function(data, targetRowId, timeOptions) {
                    var targetRow =  $filter('filter')(data.rows, { id: targetRowId }, true)[0];
                    if (!targetRow) return;
                    targetRow.taskRows.splice(0, targetRow.taskRows.length);
                    var tasks = $filter('filter')(data.tasks, { rowCategoryId: targetRowId }, true);
                    if (tasks.length == 0) return;
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        this.addTaskToRow(task, targetRow);
                    }
                    
                },

                reCalculateRowByIndex: function(data, targetRowIndex, timeOptions) {
                    var targetRow =  $filter('filter')(data.rows, { index: targetRowIndex }, true)[0];
                    targetRow.taskRows.splice(0, targetRow.taskRows.length);
                    var tasks = $filter('filter')(data.tasks, { rowIndex: targetRowIndex }, true);
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        this.addTaskToRow(task, targetRow);
                    }
                    
                },

                addTaskToRowIfSpace(task, taskRow, timeOptions) {
                    // First check if all cells needed are available
                    for (let index = task.startMonth; index <= task.endMonth; index++) {
                        var startCell =  $filter('filter')(taskRow.cells, { id: index }, true);
                        // if cell doesn't exists (already part of another colspan or already have task)
                        if (startCell.length == 0 || startCell[0].task) return false;
                    }
                    // Add task to cell and set colspan
                    var startCell = $filter('filter')(taskRow.cells, { id: task.startMonth }, true)[0];
                    startCell.colspan = task.durationInMonthsCurrentYear;
                    startCell.task = task;
                    // Now let's removed cell affected by colspan
                    if (task.durationInMonthsCurrentYear > 1) {
                        // Use start month(not -1) because 0 index array.
                        var index = taskRow.cells.indexOf(startCell);
                        taskRow.cells.splice(index+1, task.durationInMonthsCurrentYear-1);
                    }
                    return true;
                },

                createTaskRow: function (task, timeOptions) {
                    var newRow = {
                        cells: []
                    }
                    for (let index = 1; index < task.startMonth; index++) {
                        var cell = {
                            id: index,
                            colspan: 1
                        }
                        newRow.cells.push(cell)
                    }
                    var taskCell = {
                        id: task.startMonth,
                        colspan: task.durationInMonthsCurrentYear,
                        task: task
                    }
                    newRow.cells.push(taskCell);
                    for (let index = (task.startMonth + task.durationInMonthsCurrentYear); index <= 12; index++) {
                        var cell = {
                            id: index,
                            colspan: 1
                        }
                        newRow.cells.push(cell)
                    }
                    return newRow;
                },
                calculateDuration: function (task, timeOptions) {
                    var currentYear = timeOptions.year;
                    if (task.startTime && task.endTime) {
                        var startMonth = moment(task.startTime).month() + 1; // Javascript months are 0 indexed
                        var startYear = moment(task.startTime).year();
                        var endMonth = moment(task.endTime).month() + 1;
                        var endYear = moment(task.endTime).year();
                        task.isStartOutsideCurrentPeriod = false;
                        task.isEndOutsideCurrentPeriod = false;
                        
                        // If
                        if (endYear > currentYear) {
                            // if task duration end within a greater year, we need to set it to this year
                            endMonth = 12;
                            task.isEndOutsideCurrentPeriod = true;
                        }
                        if (startYear < currentYear) {
                            // if task duration end within a greater year, we need to set it to this year
                            startMonth = 1;
                            task.isStartOutsideCurrentPeriod = true;
                        }
                        // How many month within current month
                        task.startMonth = startMonth;
                        task.endMonth = endMonth;
                        
                        task.durationInMonthsCurrentYear = (endMonth - startMonth) + 1; // we need to add one
                    }
                }

            };
        }
        return yearlyboardRenderService;
    }]);

