﻿angular
    .module('DigiLean')
    .directive("priorityMatrix", ['$filter', 'authService', '$uibModal', 'suggestionService', 'employeeService',
        function ($filter, authService, $uibModal, suggestionService, employeeService) {
            return {
                templateUrl: 'priorityMatrix.html',
                restrict: 'E',
                scope: {
                    'filterSource': '@',
                    'widgetSettingsFilter': '<',
                    'filterChangedHandler': '&',
                    'context': '@'
                },
                link: function (scope, elem, attrs) {
                    scope.userid = authService.getCurrentUser().userId;

                    // if page is refreshed we need to refresh the access to modules
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.userid = profile.user.id;
                    });

                    scope.filter = null;
                    scope.$watch('filterSource', function (filterSource) {
                        if (filterSource == "userpreference") {
                            employeeService().getUserPreference("ImprovementPriorityFilter").then(function (filterpreferences) {
                                if (filterpreferences && filterpreferences.value) {
                                    scope.filter = filterpreferences.value;
                                }
                            });
                        }
                    });
                    scope.$watch('widgetSettingsFilter', function (filter) {
                        if (scope.filterSource == "widget" && filter) {
                            scope.filter = filter;
                        }
                    });
                    scope.filterChanged = function (filter) {
                        if (scope.filterSource == "widget") {
                            // Pass on filter to widget
                            scope.filterChangedHandler({ filter: filter });
                        } else {
                            if (!filter) { // Clear filter
                                employeeService().deleteUserPreference("ImprovementPriorityFilter").then(function () {
                                });
                            } else { // update
                                employeeService().updateUserPreference("ImprovementPriorityFilter", filter).then(function () {

                                });
                            }
                        }
                    }
                    // Get all 3 years back
                    var startDate = moment().subtract(36, 'month').startOf("month");
                    var endDate = moment().endOf('month');
                    scope.timePeriod = {
                        from: startDate.utc().toDate().toJSON(),
                        to: endDate.utc().toDate().toJSON()
                    }

                    scope.new = {
                        list: [],
                        filteredList: []
                    };
                    scope.suggestionStatus1 = {
                        list: [],
                        filteredList: []
                    };
                    scope.suggestionStatus2 = {
                        list: [],
                        filteredList: []
                    };
                    scope.suggestionStatus3 = {
                        list: [],
                        filteredList: []
                    };
                    scope.suggestionStatus4 = {
                        list: [],
                        filteredList: []
                    };


                    // UI SORTABLE => Drag and drop, SuggestionList
                    scope.suggestionSortableOptions = {
                        connectWith: ".suggestioncontainer",
                        receive: changePriorityStatus
                    };
                    // Priority matrix options pr 
                    scope.matrixSortableOptions = {
                        connectWith: "#suggestionList, .suggestioncontainer",
                        receive: changePriorityStatus
                    };

                    function changePriorityStatus(e, ui) {
                        var target = ui.item.sortable.droptarget[0];
                        var prioritystatus = $(target).attr("data-prioritystatus");
                        var source = ui.item.sortable.source[0];
                        var sourceStatus = $(source).attr("data-prioritystatus");
                        var suggestion = ui.item.sortable.model;
                        // alert("from priority: " + sourceStatus + " to: "  + prioritystatus);
                        var status = "Suggested";
                        if (prioritystatus > 0) status = "Planned";
                        var statusData = {
                            suggestionId: suggestion.id,
                            status: status,
                            priorityStatus: parseInt(prioritystatus)
                        };

                        suggestionService().changePriorityStatus(statusData).then(function () {
                            // if status also has changed we need to notify
                            if (suggestion.status !== statusData.status) {
                                suggestionService().updateStatus(statusData).then(function () {

                                });

                            }
                        });
                    };


                    scope.subscribe("ImprovementLikeAdded", function (like) {
                        var existingSuggestions = $filter('filter')(scope.new.list, { id: like.improvementSuggestionId }, true);
                        if (existingSuggestions.length === 1) {
                            var index = scope.new.list.indexOf(existingSuggestions[0]);
                            scope.new.list[index].likes.push(like);
                        }
                    });

                    scope.subscribe("ImprovementLikeRemoved", function (like) {
                        var inList = $filter('filter')(scope.new.list, { id: like.improvementSuggestionId }, true);
                        if (inList.length === 1) {
                            var existingLike = $filter('filter')(inList[0].likes, { id: like.id }, true);
                            if (existingLike.length == 1) {
                                var index = inList[0].likes.indexOf(existingLike[0]);
                                inList[0].likes.splice(index, 1);
                            }

                        }
                    });

                    scope.subscribe("ImprovementTitleChanged", function (improvementTitle) {
                        var existingSuggestions = $filter('filter')(scope.new.list, { id: improvementTitle.suggestionId }, true);
                        if (existingSuggestions.length === 1) {
                            var index = scope.new.list.indexOf(existingSuggestions[0]);
                            scope.new.list[index].title = improvementTitle.newText;
                            scope.new.list[index].cssClass = "animated bounce";
                            // check filtered list
                            var existingfilteredSuggestions = $filter('filter')(scope.new.filteredList, { id: improvementTitle.suggestionId }, true);
                            if (existingfilteredSuggestions.length === 1) {
                                var index = scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                                scope.new.filteredList[index].title = improvementTitle.newText;
                                scope.new.filteredList[index].cssClass = "animated bounce";
                            }
                        } else {
                            existingSuggestions = $filter('filter')(scope.suggestionStatus1.list, { id: improvementTitle.suggestionId }, true);
                            if (existingSuggestions.length === 1) {
                                var index = scope.suggestionStatus1.list.indexOf(existingSuggestions[0]);
                                scope.suggestionStatus1.list[index].title = improvementTitle.newText;
                                scope.suggestionStatus1.list[index].cssClass = "animated bounce";
                                // check filtered list
                                var existingfilteredSuggestions = $filter('filter')(scope.suggestionStatus1.filteredList, { id: improvementTitle.suggestionId }, true);
                                if (existingfilteredSuggestions.length === 1) {
                                    var index = scope.suggestionStatus1.filteredList.indexOf(existingfilteredSuggestions[0]);
                                    scope.suggestionStatus1.filteredList[index].title = improvementTitle.newText;
                                    scope.suggestionStatus1.filteredList[index].cssClass = "animated bounce";
                                }
                            } else {
                                existingSuggestions = $filter('filter')(scope.suggestionStatus2.list, { id: improvementTitle.suggestionId }, true);
                                if (existingSuggestions.length === 1) {
                                    var index = scope.suggestionStatus2.list.indexOf(existingSuggestions[0]);
                                    scope.suggestionStatus2.list[index].title = improvementTitle.newText;
                                    scope.suggestionStatus2.list[index].cssClass = "animated bounce";
                                    // check filtered list
                                    var existingfilteredSuggestions = $filter('filter')(scope.suggestionStatus2.filteredList, { id: improvementTitle.suggestionId }, true);
                                    if (existingfilteredSuggestions.length === 1) {
                                        var index = scope.suggestionStatus2.filteredList.indexOf(existingfilteredSuggestions[0]);
                                        scope.suggestionStatus2.filteredList[index].title = improvementTitle.newText;
                                        scope.suggestionStatus2.filteredList[index].cssClass = "animated bounce";
                                    }
                                } else {
                                    existingSuggestions = $filter('filter')(scope.suggestionStatus3.list, { id: improvementTitle.suggestionId }, true);
                                    if (existingSuggestions.length === 1) {
                                        var index = scope.suggestionStatus3.list.indexOf(existingSuggestions[0]);
                                        scope.suggestionStatus3.list[index].title = improvementTitle.newText;
                                        scope.suggestionStatus3.list[index].cssClass = "animated bounce";
                                        // check filtered list
                                        var existingfilteredSuggestions = $filter('filter')(scope.suggestionStatus3.filteredList, { id: improvementTitle.suggestionId }, true);
                                        if (existingfilteredSuggestions.length === 1) {
                                            var index = scope.suggestionStatus3.filteredList.indexOf(existingfilteredSuggestions[0]);
                                            scope.suggestionStatus3.filteredList[index].title = improvementTitle.newText;
                                            scope.suggestionStatus3.filteredList[index].cssClass = "animated bounce";
                                        }
                                    } else {
                                        existingSuggestions = $filter('filter')(scope.suggestionStatus4.list, { id: improvementTitle.suggestionId }, true);
                                        if (existingSuggestions.length === 1) {
                                            var index = scope.suggestionStatus4.list.indexOf(existingSuggestions[0]);
                                            scope.suggestionStatus4.list[index].title = improvementTitle.newText;
                                            scope.suggestionStatus4.list[index].cssClass = "animated bounce";
                                            // check filtered list
                                            var existingfilteredSuggestions = $filter('filter')(scope.suggestionStatus4.filteredList, { id: improvementTitle.suggestionId }, true);
                                            if (existingfilteredSuggestions.length === 1) {
                                                var index = scope.suggestionStatus4.filteredList.indexOf(existingfilteredSuggestions[0]);
                                                scope.suggestionStatus4.filteredList[index].title = improvementTitle.newText;
                                                scope.suggestionStatus4.filteredList[index].cssClass = "animated bounce";
                                            }
                                        }
                                    }   
                                }
                            }
                        }
                    });





                    scope.subscribe("ImprovementResponsibleChanged", function (improvementResponsible) {
                        // find suggestion by id
                        var existingSuggestions = $filter('filter')(scope.new.list, { id: improvementResponsible.suggestionId }, true);
                        if (existingSuggestions.length === 1) {
                            var index = scope.new.list.indexOf(existingSuggestions[0]);
                            scope.new.list[index].responsibleUserId = improvementResponsible.newResponsibleUserId;
                            scope.new.list[index].responsibleUser = improvementResponsible.newResponsibleUser;
                            scope.new.list[index].cssClass = "animated bounce"; // Add animation

                            var existingfilteredSuggestions = $filter('filter')(scope.new.filteredList, { id: improvementResponsible.suggestionId }, true);
                            if (existingfilteredSuggestions.length === 1) {
                                var index = scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                                scope.new.filteredList[index].responsibleUserId = improvementResponsible.newResponsibleUserId;
                                scope.new.filteredList[index].responsibleUser = improvementResponsible.newResponsibleUser;
                                scope.new.filteredList[index].cssClass = "animated bounce"; // Add animation
                            }
                        }
                    });

                    scope.subscribe("ImprovementUpdated", function (suggestion) {
                        suggestion.cssClass = "animated bounce"; // Add animation
                        // find suggestion by id
                        suggestionService().get(suggestion.id).then(function (fullSuggestion) {
                            var existingSuggestions = $filter('filter')(scope.new.list, { id: fullSuggestion.id }, true);
                            if (existingSuggestions.length === 1) {
                                var index = scope.new.list.indexOf(existingSuggestions[0]);
                                fullSuggestion.cssClass = "animated bounce";
                                scope.new.list[index] = fullSuggestion;
                            }

                            var existingfilteredSuggestions = $filter('filter')(scope.new.filteredList, { id: fullSuggestion.id }, true);
                            if (existingfilteredSuggestions.length === 1) {
                                var index2 = scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                                fullSuggestion.cssClass = "animated bounce";
                                scope.new.filteredList[index2] = fullSuggestion;
                            }
                        });

                    });


                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = "";
                        }, 1000);
                    }

                    scope.filterById = function (value) {
                        return (scope.filterValues.indexOf(value.id) !== -1);
                    };


                    scope.suggestionOpenForEdit = function (suggestionInfo) {
                        suggestionService().get(suggestionInfo.id).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: scope.animationsEnabled,
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                            modalInstance.result.then(function (updatedSuggestion) {
                                suggestion.roi = updatedSuggestion.roi;
                            });
                        });
                    }


                    scope.showComments = function (suggestion) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: scope.animationsEnabled,
                            templateUrl: 'fullImprovementInfoForm.html',
                            controller: 'fullImprovementModalController',
                            windowClass: 'fullSuggestion-modal-window',
                            resolve: {
                                suggestion: function () {
                                    return suggestion;
                                },
                                activeTab: function () {
                                    return "Comments";
                                }
                            }
                        });
                        modalInstance.result.then(function (updatedSuggestion) {
                            suggestion.roi = updatedSuggestion.roi;
                        });

                    }

                    scope.likedByMe = function (suggestion) {
                        if (suggestion.likes) {
                            for (var i = 0; i < suggestion.likes.length; i++) {
                                if (suggestion.likes[i].likedByUserId === scope.userid) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }

                    scope.commentedByMe = function (suggestion) {
                        if (suggestion.comments) {
                            for (var i = 0; i < suggestion.comments.length; i++) {
                                if (suggestion.comments[i].commentedByUserId === scope.userid) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }

                    scope.addComment = function (suggestion) {
                        if (typeof suggestion.comments === 'undefined') {
                            suggestion.comments = [];
                        }

                        var currentComment = {
                            suggestionId: suggestion.id,
                            comment: suggestion.activeComment
                        };
                        suggestionService().addComment(currentComment).then(function (savedComment) {
                            suggestion.comments.push(savedComment);
                        });
                        suggestion.activeComment = "";
                    }

                    scope.like = function (item) {
                        //item.isLiked = !item.isLiked;
                        if (!scope.likedByMe(item)) {
                            suggestionService().addLike(item.id).then(function (like) {
                                // Like event will increase counter
                            });
                        } else {
                            suggestionService().removeLike(item.id).then(function () {
                            });
                        }
                    }


                    // Add suggestion
                    scope.newItem = function () {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newSuggestionForm.html',
                            controller: 'newSuggestionModalController',
                            resolve: {
                                suggestion: function () {
                                    return {
                                        id: 0,
                                        title: "",
                                        text: "",
                                        suggestedBy: "",
                                        suggestionDate: new Date(),
                                        showComments: false,
                                        likes: [],
                                        comments: [],
                                    }
                                }

                            }
                        });

                        // When user has created new suggestion
                        modalInstance.result.then(function (suggestion) {
                            suggestionService().addSuggestion(suggestion).then(function (savedsuggestion) {
                            });
                        }, function () {
                            //$log.info('Modal dismissed at: ' + new Date());
                        });

                    };
                }
            }

        }]);