﻿    angular.module("DigiLean").factory('contactlistService', ['$http', 'webApiUrl',
  function ($http, webApiUrl) {
      function contactlistService() {
          var resource = "/person";
          var resourceUrl = webApiUrl + resource;
          return {
              getList: function(useCache) {   
                if (!useCache) useCache = false;
                  return $http.get(resourceUrl, { cache: useCache}).then(function (result) {
                      return result.data;
                  });
              },
              getById: function (id) {
                  var url = resourceUrl + "/get/" + id;
                  return $http.get(url).then(function (result) {
                      return result.data;
                  });
              },

              add: function (person) {
                  return $http.post(resourceUrl, person).then(function (result) {
                      return result.data;
                  });
              },
              update: function (person) {
                  return $http.put(resourceUrl, person).then(function (result) {
                      return result.data;
                  });
              },
              delete: function (id) {
                  var url = resourceUrl + "/delete/" + id;
                  return $http.delete(url).then(function (result) {
                      return result.data;
                  });
              },
              updateProjectContactPerson: function (personId, projectId) {
                  resourceUrl = resourceUrl + "/UpdateProjectContactPerson/" + personId;
                return $http.put(resourceUrl, projectId).then(function (result) {
                    return result.data;
                });
              },
              deleteProjectContactPerson: function (projectId) {
                resourceUrl = resourceUrl + "/DeleteProjectContactPerson/" + projectId;
                return $http.delete(resourceUrl).then(function (result) {
                    return result.data;
                });
            },
              getProjectContactPerson: function (projectId) {
                resourceUrl = resourceUrl + "/GetProjectContactPerson/" + projectId;
                return $http.get(resourceUrl).then(function (result) {
                    return result.data;
                });
            },
          }
      }
      return contactlistService;
  }]);