﻿
angular.module('DigiLean').factory('translationService', ['$translate',
    function ($translate) {
        function translationService() {

            return {
                getTranslatedSmartColumnName: function (type) {
                    switch (type) {
                        case "projectName":
                            return $translate.instant("PROJECT_NAME");
                        case "projectNumber":
                            return $translate.instant("PROJECT_NUMBER");
                        case "projectStatus":
                            return $translate.instant("COMMON_STATUS");
                        case "customer":
                            return $translate.instant("PROJECT_CUSTOMER");
                        case "owner":
                            return $translate.instant("COMMON_RESPONSIBLE");
                        case "asset":
                            return $translate.instant("COMMON_ASSET");
                        case "participants":
                            return $translate.instant("COMMON_PARTICIPANTS");
                        case "startdate":
                            return $translate.instant("PROJECT_START_DATE");
                        case "nextmilestone":
                            return $translate.instant("PROJECT_NEXT_MILESTONE");
                        case "last-modified-date":
                            return $translate.instant("COMMON_LAST_CHANGED");
                        case "duedate":
                            return $translate.instant("COMMON_DUE_DATE");
                        case "enddate":
                            return $translate.instant("COMMON_END_DATE");
                        case "text":
                            return $translate.instant("COMMON_TEXT");
                        case "date":
                            return $translate.instant("COMMON_DATE");
                        case "number":
                            return $translate.instant("ADMIN_DATA_DATASOURCE_NUMBER");
                        case "rowno":
                            return $translate.instant("COMMON_NUMBER_SHORT");
                        case "linktohub":
                            return $translate.instant("PROJECT_HUB");
                        case "contactperson":
                            return $translate.instant("ADMIN_DATA_CONTACT");
                        case "tasks":
                            return $translate.instant("COMMON_TASKS");
                        case "subtasks":
                            return $translate.instant("COMMON_SUBTASKS");
                        case "numberAutomatic":
                            return $translate.instant("COMMON_AUTOMATIC");
                        case "progress":
                            return $translate.instant("PROJECT_PROGRESS");
                        case "progresstasks":
                            return $translate.instant("COMMON_TASKS_PROGRESS");
                        case "progresssubtasks":
                            return $translate.instant("COMMON_SUBTASKS_PROGRESS");
                        case "target":
                            return $translate.instant("ADMIN_DATA_DATASOURCE_TARGETS");
                        case "responsible":
                            return $translate.instant("COMMON_RESPONSIBLE");
                        case "risk":
                            return $translate.instant("PROJECT_RISK");
                        case "rating":
                            return $translate.instant("COMMON_RATING");
                        case "check":
                            return $translate.instant("COMMON_CHECKPOINT");
                        case "linktoa3":
                            return $translate.instant("IMPROVEMENT_TO_A3");
                        case "a3Goal":
                            return $translate.instant("COMMON_GOAL");
                        case "subGoals":
                            return $translate.instant("STRATEGY_SUB_GOALS");
                        case "projectdescription":
                            return $translate.instant("COMMON_DESCRIPTION");
                        case "taskdescription":
                            return $translate.instant("COMMON_DESCRIPTION");
                        case "name": //smarttable
                            return $translate.instant("COMMON_DESCRIPTION");
                        case "icon":
                            return $translate.instant("BOARD_ICON");
                        default:
                            return "";
                    }
                },
            };
        }
        return translationService;
    }]);

