angular
    .module('DigiLean')
    .directive("deviationList", ['$filter', '$translate',
        function ($filter, $translate) {
            return {
                templateUrl: 'deviationList.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    scope.filterChanged = function (filter) {
                        scope.params.filter = filter
                    }
                    scope.params = {
                        
                    };

                    scope.listOptions = {
                        showFilter : true,
                        showFileGenerators: true,
                        showArchived: true
                    }
                }

            }
        }]);
