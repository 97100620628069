import { getCompanySetting } from "@common/stores/companySettingsStore"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("deviationTypeConsequences", ['$filter', 'deviationService','languageAdminService',
        function ($filter, deviationService, languageAdminService) {
            return {
                templateUrl: 'deviationTypeConsequences.html',
                restrict: 'E',
                scope: {
                    'deviationTypeId': '=',    
                    'deviationTypeTitle': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.consequences = [];

                    scope.severityNotSpecifiedDesc = "";
                    scope.severityLowDesc = "";
                    scope.severityMediumDesc = "";
                    scope.severityHighDesc = "";

                    var orgLanguage = 'en';
                    var allSeverities = [];

                    scope.$watch("deviationTypeId", function () {
                        if (!scope.deviationTypeId) return;
                        loadSettings();
                    });

                    scope.languages = languageAdminService().getSupportedLanguages()
                    scope.selectedCustomLanguageLabel;
                
                    scope.changeLanguage = function (language) {
                        if(!language) return;
                        scope.selectedLanguage = language;
                        setSeverityTranslationLabel();
                    }

                    function setSeverityTranslationLabel(){                      
                        scope.severityNotSpecifiedDesc = "";
                        scope.severityLowDesc = "";
                        scope.severityMediumDesc = "";
                        scope.severityHighDesc = "";
                        for(var i=0; i< allSeverities.length; i++){
                            if(allSeverities[i].severity == 0){
                                scope.severityNotSpecifiedDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, scope.selectedLanguage.languageCode);
                            } else if(allSeverities[i].severity == 1){
                                scope.severityLowDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, scope.selectedLanguage.languageCode);
                            } else if(allSeverities[i].severity == 2){
                                scope.severityMediumDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, scope.selectedLanguage.languageCode);
                            } else if(allSeverities[i].severity == 3){
                                scope.severityHighDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, scope.selectedLanguage.languageCode);
                            }
                        }
                    }
                    
                    function loadSettings(){
                        deviationService().getConsequences().then(function (consequences) {
                            scope.consequences = consequences;
                            deviationService().getConsequencesForType(scope.deviationTypeId).then(function(consequences) {                           
                                var validConsequencesForType = consequences;
                                for(var i=0; i<scope.consequences.length; i++){
                                    scope.consequences[i].show = checkShow(scope.consequences[i].id, validConsequencesForType);
                                }            
                            });

                            deviationService().getSeveritiesForType(scope.deviationTypeId).then(function(severities) {                           
                                allSeverities = severities;    
                                if(allSeverities){
                                    getCompanySetting("language").then(value => {
                                        orgLanguage = value
                                        scope.selectedLanguage = scope.languages.find(q => q.languageCode == orgLanguage)
                                        for(var i=0; i< allSeverities.length; i++){
                                            if(allSeverities[i].severity == 0){
                                                scope.severityNotSpecifiedDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, orgLanguage);
                                            } else if(allSeverities[i].severity == 1){
                                                scope.severityLowDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, orgLanguage);
                                            } else if(allSeverities[i].severity == 2){
                                                scope.severityMediumDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, orgLanguage);
                                            } else if(allSeverities[i].severity == 3){
                                                scope.severityHighDesc = getTranslationLabel(allSeverities[i].customLanguageLabels, orgLanguage);
                                            }
                                        }
                                    })
                                }       
                            });
                        });
                    }

                    function getTranslationLabel(list,languageCode){
                        var customLanguageLabel = list.find(q => q.languageCode == languageCode);
                        if(!customLanguageLabel){
                            return "";
                        }
                        return customLanguageLabel.label;
                    }

                    function checkShow(itemId, validConsequences){
                        var matching = $filter('filter')(validConsequences, { consequenceId: itemId }, true);
                        if (matching.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }  

                    scope.toggleShow = function (item) {
                        item.consequenceId = item.id;
                        if(item.show){
                            item.deviationTypeId = scope.deviationTypeId;
                            deviationService().addDeviationTypeConsequence(item).then(function() {                                                  
                            });
                        } else {
                            if(item.id){
                                deviationService().deleteDeviationTypeConsequence(item.id).then(function() {                                                  
                                });
                            }
                        }
                    };

                    scope.updateSeverityDescription = function(severity, description){
                        var matching = $filter('filter')(allSeverities, { severity: severity }, true);
                        var item = {severity:severity,
                            deviationTypeId: scope.deviationTypeId};
                        var translabel = {
                                label: description
                            }
                        if (matching.length > 0) {
                            item = matching[0];
                            var existing = item.customLanguageLabels.find(q => q.languageCode == scope.selectedLanguage.languageCode)
        
                            if(!existing){
                                var customLanguageLabel = {
                                    id:0,
                                    languageCode: scope.selectedLanguage.languageCode,
                                    label: translabel.label
                                }
                                item.id = 0;
                                item.customLanguageLabel = customLanguageLabel;
                            } else {
                                if(existing.label == translabel.label) return; // no changes made to label
                                existing.label = translabel.label;
                                item.customLanguageLabelId = existing.id;
                                item.customLanguageLabel = existing;
                            }            
                        } else { // new custom label
                            item.id = 0;
                            var labelTranslation = {
                                id:0,
                                languageCode: scope.selectedLanguage.languageCode,
                                label: translabel.label
                            }
                            item.customLanguageLabel = labelTranslation;
                        }
                        deviationService().updateDeviationSeverityTranslation(item).then(function(result) {   
                            // have to update allSeverities with all valid translations
                            deviationService().getSeveritiesForType(scope.deviationTypeId).then(function(severities) {                           
                                allSeverities = severities;

                            });
                        });
                    }
                }

            }
        }]);
