

angular
    .module('DigiLean')
    .directive('taskInfo', ['$filter', '$translate', 'boardTaskService',
        function ($filter, $translate, boardTaskService) {
            return {
                templateUrl: 'taskInfo.html',
                restrict: 'E',
                scope: {
                   "id" : "<"
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.$watch('id', function (id) {
                        // var value = model.projectAttribute.value;
                        if (id) {
                            boardTaskService().getInfo(id).then(function(info){
                                scope.title = info.title;
                            })
                        }
                    });


                }
            }
        }]);
