angular
    .module('DigiLean')
    .directive("pager", ['pagerService',
        function (pagerService) {
            return {
                templateUrl: 'pager.html',
                restrict: 'E',
                scope: {
                    'allItems': '=',
                    'items': '=',
                    'pageSize':'=',
                    'settings': '='
                },
                link: function (scope, elem, attrs) {
                    var result = [];
                    var currentPage = 1;
                    scope.pager = {};
                    scope.setPage = setPage;

                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        //checkSettings();
                    });
                    scope.$watch('allItems', function (items) {
                        if (!items) return;
                        if (items.length > 0) {
                            result = items;
                            initController();
                        }
                    });

                    scope.$watchCollection('allItems', function(changed, old) {
                        if (!changed || !old) return;
                        if (changed.length > old.length) {
                            result = changed;
                            setPage(currentPage);
                        }
                        
                      });

                    function initController() {
                        // initialize to page 1
                        scope.setPage(1);
                    }

                    function setPage(page) {
                        if (page < 1 || page > scope.pager.totalPages) {
                            return;
                        }
                        currentPage = page;
                        // get pager object from service
                        scope.pager = pagerService().getPager(result.length, page, scope.pageSize);

                        // get current page of items
                        scope.items = result.slice(scope.pager.startIndex, scope.pager.endIndex + 1);
                    }

                }

            }
        }]);
