import angular from "angular"
import {html} from "@common/services/template/templateLiteral"
import '@common/shared/dates/datePicker.css'
import "./scalableText.css"

const DigiLean = angular.module("DigiLean")
DigiLean.component("scalableText", 
{
    bindings: {
        'text': '<'
    },
    controller: function() {
        // let $ctrl = this
        // $ctrl.$onInit = function() {
        //     console.log("scalableText", $ctrl.text)
        // }
        //$ctrl.$onDestroy = function() {}
    },
    template: html`
        <svg viewBox="0 0 20 20">
            <text x="50%" y="50%" class="scalable-text"
                dominant-baseline="middle" text-anchor="middle">
                {{$ctrl.text}}
            </text>
        </svg>
    `
})