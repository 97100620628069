
angular.module("DigiLean").factory("responsibleColumnProvider", ['$rootScope', '$filter', '$translate', 'boardTaskService','orderByFilter',
    function ($rootScope, $filter, $translate, boardTaskService, orderByFilter) {
        var columns = [];
        var tasks = [];
        var users = [];

        function responsibleColumnProvider() {
            function createResponsibleColumn(responsible, tasks) {
                var column = {
                    showAddButton: true,
                    title: responsible.name,
                    displayUser: true,
                    user: responsible.user,
                    tasks: [],
                    property: "responsible",
                    propertyValue: responsible.userId,
                    isPartOfColumn: function (task) {
                        if (!task.responsibleUserId && !this.propertyValue) {
                            return true;
                        }
                        return task.responsibleUserId == this.propertyValue;
                    },
                    hasTask: function(taskId) {
                        var task = $filter('filter')(this.tasks, { id: taskId }, true);
                        return task.length > 0;
                    },
                    addTask: function(task) {
                        if (this.isPartOfColumn(task)) {
                            this.tasks.unshift(task)
                            return true;
                        }
                        return false;
                    },
                    removeTask: function(task) {
                        if (this.isPartOfColumn(task, this.property)) {
                            var index = this.tasks.indexOf(task);
                            this.tasks.splice(index, 1);
                            return true;
                        }
                        return false;
                    },
                
                    saveChanges: function (changeSettings) {
                        // Set property to comparer
                        var user = changeSettings.dropColumn.user;
                        var userDetails = {
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName
                        };
                        var options = {
                            taskId: changeSettings.taskId,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);
                    },
                    sortTasks: function() {
                        this.tasks = $filter('orderBy')(this.tasks, 'status');
                    },
                };
                // Add tasks to column
                tasks.forEach(function (task) {
                    if (column.isPartOfColumn(task)) {
                        column.tasks.push(task)
                    }
                });
                column.sortTasks();
                return column;
            }

            $rootScope.subscribe('TaskAssigned', function (taskAssigned) {
                // Is task part of board?
                var task = $filter('filter')(tasks, { id: taskAssigned.taskId }, true);
                if (task.length > 0) {
                    var hasColumnForResponsible = false;
                    task[0].responsibleUserId = taskAssigned.userId;
                    task[0].responsibleUser = taskAssigned.user;
                    task[0].responsibleUserDisplayName = taskAssigned.userDisplayName;
                    task[0].animate("bounce");
                    columns.forEach(function(column){
                        // Remove if wrong responsible
                        if (column.propertyValue != taskAssigned.userId && column.hasTask(taskAssigned.taskId)) {
                            // Add task to column
                            var index = column.tasks.indexOf(task[0]);
                            column.tasks.splice(index, 1);
                        }
                         // Add task to column
                        if (column.propertyValue == taskAssigned.userId) {
                            if (!column.hasTask(taskAssigned.taskId)) {
                                
                                column.tasks.push(task[0]);
                            }
                            hasColumnForResponsible = true;
                        }
                        // Match not assigned task
                        if (!column.propertyValue && !taskAssigned.userId) {
                            if (!column.hasTask(taskAssigned.taskId)) {
                                column.tasks.push(task[0]);
                            }
                            hasColumnForResponsible = true;
                        }
                    });
                    
                    // Add if not exists
                    if (!hasColumnForResponsible) {
                        // Create new column

                        var responsible = {
                            userId : taskAssigned.userId
                        }
                        var userProfile = $filter('filter')(users, { userId: taskAssigned.userId }, true);
                        if (userProfile.length > 0) {
                            responsible.name = userProfile[0].fullName;
                            responsible.user = userProfile[0];
                        }
                        columns.push(createResponsibleColumn(responsible, tasks));
                    }
                }
            });



            return {
                getColumns: function (boardTasks, allUsers, options) {
                    
                    if (!options) {
                        options = {
                            showNotAssignedColum: true
                        };
                    }
                    var responsibles = [
                        
                    ];
                    columns = [];
                    tasks = boardTasks;
                    users = allUsers;
                    // Find distinct users
                    for (let index = 0; index < tasks.length; index++) {
                        const task = tasks[index];
                        var isResponsibleInList = $filter('filter')(responsibles, { userId: task.responsibleUserId }, true);
                        if (isResponsibleInList.length == 0 && task.responsibleUserId) {
                            var responsible = {
                                userId: task.responsibleUserId,
                                name: task.responsibleUser,
                                user: null,
                            };
                            var userProfile = $filter('filter')(users, { userId: task.responsibleUserId }, true);
                            if (userProfile.length > 0) {
                                responsible.user = userProfile[0];
                            }
                            responsibles.push(responsible);
                        }
                    }
                    // Sort responsibles.
                    responsibles = orderByFilter(responsibles, 'name');
                    if (options.showNotAssignedColum) {
                        responsibles.unshift({
                            userId: null,
                            name: $translate.instant("COMMON_NOT_ASSIGNED"),
                            user: {
                                userId: null,
                                userName: "",
                                userDisplayName: ""
                            }
                        })
                    }
                    responsibles.forEach(function (responsible) {
                        columns.push(createResponsibleColumn(responsible, tasks));
                    })
                    return columns;
                }


            }



        }

        return responsibleColumnProvider;
    }]);
