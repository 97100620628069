import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive('myNotifications', ['$translate','$state', '$interval','$filter', 'notificationService',
        function ($translate,$state, $interval, $filter, notificationService) {
            return {
                templateUrl: 'myNotifications.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    
                    scope.notifications = [];
                    var actions = notificationService().getNotificationActions();

                    scope.subscribe('Notification', function (notification) {
                        notification.isNew = true;
                        updateNotificationWithActionData(notification);
                        scope.notifications.unshift(notification);
                        updateNewAlertCounter(newAlerts + 1);
                        calculateTimeElapsed();
                    });
                    scope.subscribe('MarkedAllNotificationsAsRead', function () {
                        updateNewAlertCounter(0);
                        calculateTimeElapsed();
                    })
                    scope.subscribe('NotificationSeen', function (notification) {
                        updateNewAlertCounter(newAlerts - 1);
                        calculateTimeElapsed();
                        var partOfList = $filter('filter')(scope.notifications, { id: notification.id }, true);
                        if (partOfList.length > 0) {
                            partOfList[0].isNew = false;
                        }
                    });

                    scope.openNotification = function(notification) {
                        if (notification.ïsNew) {
                            notificationService().seenNotification(notification.id).then(function(){
                                $state.go('inbox', { tab: 'notification', notificationid: notification.id }, { reload: false });
                            });                            
                        } else {
                            $state.go('inbox', { tab: 'notification', notificationid: notification.id }, { reload: false });
                        }
                        
                    }
                    notificationService().getUnseenNotificationsCount().then(function(count) {
                       updateNewAlertCounter(count);
                    });

                    var newAlerts = 0;
                    function updateNewAlertCounter(count) {
                        if (count < 0) count = 0;
                        if (count >= 100) {
                            scope.newAlerts = "99+";
                        } else {
                            scope.newAlerts = count;
                        }
                        newAlerts = count;
                    }

                    function updateNotificationWithActionData(notification) {
                        var targetAction = $filter('filter')(actions, { action: notification.action }, true);
                        if (targetAction.length > 0) {
                            notification.title = $translate.instant(targetAction[0].translateLabel);
                            notification.actionSymbol = targetAction[0].actionSymbol;
                        }
                    }
                    var translateOptions = {
                        month: $translate.instant('COMMON_TIMEELAPSED_MONTH'),
                        days: $translate.instant('COMMON_TIMEELAPSED_DAYS'),
                        hours: $translate.instant('COMMON_TIMEELAPSED_HOURS'),
                        mins: $translate.instant('COMMON_TIMEELAPSED_MINUTES'),
                        seconds: $translate.instant('COMMON_TIMEELAPSED_SECONDS'),
                        now: $translate.instant('COMMON_TIMEELAPSED_NOW')
                    }
                    var calculateTimeElapsed = function () {
                        // Recalculate time elapsed
                        scope.notifications.forEach(function (notification) {
                            notification.timeElapsed = timeService.getTimeElapsed(notification.notificationTime, translateOptions);
                        });
                    }

                    var updateTimer = $interval(calculateTimeElapsed, 5000);
                    elem.on('$destroy', function () {
                        $interval.cancel(updateTimer);
                    });
                }
            }
        }]);
