angular.module("DigiLean").factory('deviationCommonService', ['deviationService', '$uibModal',
    function (deviationService, $uibModal) {
        const deviationOpenForEdit = function (deviationId) {
            return deviationService().get(deviationId).then(function (deviation) {
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    templateUrl: 'fullDeviation.html',
                    controller: 'fullDeviationController',
                    windowClass: 'full-screen',
                    resolve: {
                        deviation: function () {
                            return deviation;
                        },
                        activeTab: function () {
                            return "Info";
                        }
                    }
                });
                return modalInstance.result.then(function (deviation) {
                    return deviation;
                })
            })
        }

        return {
            deviationOpenForEdit
        }
    }
])