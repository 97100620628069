import * as timeService from "@common/services/timeService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

const DigiLean = angular.module('DigiLean')
DigiLean.directive("targetGraph", ['$uibModal', '$translate', 'dataService', 'debounce',
    function ($uibModal, $translate, dataService, debounce) {
        return {
            templateUrl: 'targetgraph.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '<',
                'settings': '<',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()

                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)
                
                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                scope.imageUrl = staticFileService.image('targetgraph.jpg');
                scope.seriesData = [];
                scope.yAxisPlotLines = [];
                scope.xAxisPlotLines = [];
                scope.decimals = 0;
                scope.showRegression = false;
                var minY = 0;
                
                var timeFrames = [   
                    'all', 
                    'last3years', 
                    'lastyear', 
                    'thisyear',
                    'fiscalyear', 
                    'YTD',
                    'last12mth', 
                    'month', 
                    'previousmonth', 
                    '30',
                    'previousisoweek',
                    'isoweek',
                    'day',
                    'number',
                    'custom'    
                ];
                var timeSettings = {
                    timeframe: "thisyear",
                    timeframes: timeFrames,
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.settings = settings;
                    // apply
                    if (scope.settings) {
                        scope.dataSource = scope.settings.dataSource;
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (scope.settings.title) {
                            scope.title = scope.settings.title;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.decimals) {
                            scope.decimals = settings.decimals;
                        }
                        if (settings.showRegression === true) {
                            scope.showRegression = settings.showRegression;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                    } 
                    if (!scope.dataSource && scope.isAdminMode){
                        setTimeout(function() {
                            scope.selectDataSource();
                        }, 200); 
                    }
                });

                // Subscribe to component related event
                scope.subscribe("DataValueAdded", function (value) {
                    if (!value) return;
                    if(shouldDataBeRefreshed(value.dataSourceId, value.valueDate)) {
                        refreshData();
                    }
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (!value) return;
                    if(shouldDataBeRefreshed(value.dataSourceId, value.valueDate)) {
                        refreshData();
                    }
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    if (!value) return;
                    if(shouldDataBeRefreshed(value.dataSourceId, value.valueDate)) {
                        refreshData();
                    }
                });

                function shouldDataBeRefreshed(dataSourceId, valueDate){
                    if (!scope.dataSource) return false;
                    if (scope.dataSource.id === dataSourceId) {
                        if (dateInSelectedTimeFrame(valueDate)) {
                            return true;
                        } 
                    } else if (scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id === dataSourceId) {
                        if (dateInSelectedTimeFrame(valueDate)) {
                            return true;
                        } 
                    }
                    return false;
                }

                function dateInSelectedTimeFrame(utcDate) {
                    if (!scope.timePeriod) {
                        return true;
                    }
                    if (moment(utcDate).isBefore(scope.timePeriod.period.from) ||
                        moment(utcDate).isAfter(scope.timePeriod.period.to)) {
                        return false;
                    } else {
                        return true;
                    }
                }

                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }

                scope.toggleShowRegression = function () {
                    refreshData();
                    scope.updateSettings();
                }
                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.timePeriod,
                        dataSource: scope.dataSource,
                        filters: scope.filters,
                        decimals: scope.decimals,
                        showRegression: scope.showRegression,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                /* This method will be called also when component is first initialized */
                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    refreshData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                function refreshData() {
                    scope.seriesData = [];
                    scope.yAxisPlotLines = [];
                    scope.xAxisPlotLines = [];
                    if (scope.dataSource) {
                        getValuesForSerie(scope.dataSource);
                    }
                }

                function setupChartData(values) {
                    scope.xAxisData = [];

                    if (values) {
                        for (var i = 0; i < values.length; i++) {
                            var xAxisDataLabel = "";
                            // Assume every groupingElement has data for every bar
                            if(scope.dataSource.unitOfTime == "DAY"){
                                xAxisDataLabel = moment(values[i].valueDate).format("D.MMM");
                            } else if(scope.dataSource.unitOfTime == "WEEK"){
                                xAxisDataLabel = $translate.instant('COMMON_TIMEPERIOD_WEEK_PREFIX')+ values[i].week;
                            } else {
                            
                                if (values[i].month) {
                                    xAxisDataLabel = values[i].month + ".";
                                }
                                xAxisDataLabel = xAxisDataLabel + values[i].year;
                            }
                            scope.xAxisData.push(xAxisDataLabel);
                        }
                    } else {
                        // alert("No values for defined component");
                        scope.datafound = false; 
                    }

                    var data = [];
                    var greentarget = [];
                    for (var i = 0; i < values.length; i++) {
                        var color = DigiLean.target.getTargetIndicatorColor(values[i].targetIndicator);

                        if(values[i].value && values[i].value < minY){
                            minY = values[i].value;
                        }
                        var element = {
                            x: i,
                            color: color,
                            y: values[i].value
                        };
                        data.push(element);

                        var greenelement = {
                            x: i,
                            color: 'green',
                            y: values[i].greenValue
                        };
                        greentarget.push(greenelement);
                    }

                    if(scope.showRegression){
                        scope.seriesData = [{
                            regression: true ,
                            regressionSettings: {
                                name : 'Trend'
                            },
                            data: data,
                            type: 'column',
                            name: $translate.instant('COMMON_TOTAL')
                        }];
                    } else {
                        scope.seriesData = [{
                            data: data,
                            type: 'column',
                            name: $translate.instant('COMMON_TOTAL')
                        },{
                            data : greentarget,
                            type: 'spline',
                            dashStyle: 'ShortDash',
                            name:  $translate.instant('COMMON_TARGET'), 
                            marker: {
                                enabled:false
                            }
                        }];
                    }
                }

                function setNullValues(array, no){
                    for(var i=0; i<no; i++){
                        array.push(null);
                    }
                    return array;
                }

                function getTargetValueFromOtherDatasource(targetValues, value, unitOfTime){
                    if(!targetValues || !value || !unitOfTime) return null;
                    switch(unitOfTime){
                        case 'DAY':
                            return targetValues.find(function (target) {
                                return target.valueDate === value.valueDate;
                            });
                        case 'WEEK':
                            return targetValues.find(function (target) {
                                return target.week === value.week && target.year === value.year;
                            });
                        case 'MONTH':
                            return targetValues.find(function (target) {
                                return target.month === value.month && target.year === value.year;
                            });
                        case 'YEAR':
                            return targetValues.find(function (target) {
                                return target.year === value.year;
                            });
                        default:
                            return null;
                    }
                }

                scope.updateDecimals = function (updatedDecimal) {
                    if(updatedDecimal == undefined) updatedDecimal = 9; //UpdatedDecimals is undefined when user tries to set unsupported value (over 9 and below 0)
                    scope.decimals = updatedDecimal;
                    refreshData();
                    scope.updateSettings();
                };

                function getValuesForSerie(datasource) {
                    if (!scope.timePeriod) return;

                    var options = {
                        groupByInterval: scope.dataSource.unitOfTime,//"month",
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: scope.timePeriod.period,
                        filters: scope.filters,
                    };

                    if (datasource.targets && datasource.targets.length > 0 && datasource.objectSource !='internal') {
                        var manualOptions = {
                            targets: datasource.targets,
                            timePeriodGroupQueryOptions: options
                        }
                        dataService().getManualTargetValuesForPeriod(datasource.id, manualOptions).then(function (values) {
                            if (values) {
                            scope.datafound = true;
                            setupChartData(values);
                            drawChart();
                        } else {
                            scope.datafound = false;
                        }});
                        return;
                    }

                    dataService().getTargetValuesForPeriod(datasource.id, options).then(function (values) {
                        scope.datafound = true;
                        if (values) {
                            if(scope.dataSource.targetDataSource && scope.dataSource.targetDataSource.id){
                                var targetOptions = {
                                    groupByInterval: scope.dataSource.unitOfTime,//"e.g. month",
                                    timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                                    timePeriod: scope.timePeriod.period,
                                    filters: scope.dataSource.targetFilters,
                                };
                                dataService().getTargetValuesForPeriod(scope.dataSource.targetDataSource.id, targetOptions).then(function (targetValues) {
                                    if (targetValues) {
                                        for(var v=0; v<values.length; v++) {
                                            var targetValue = getTargetValueFromOtherDatasource(targetValues, values[v], datasource.unitOfTime);
                                            if(targetValue){
                                                var targetResult = DigiLean.target.getTargetResultByValueAndOperator(values[v].value, scope.dataSource.targetGreenOperator, targetValue.value);
                                                if(targetResult){
                                                    values[v].targetIndicator = targetResult.targetIndicator;
                                                    values[v].greenValue = targetValue.value;
                                                }
                                            } else {
                                                values[v].targetIndicator = "";
                                                values[v].greenValue = null;
                                            }
                                        }
                                        setupChartData(values);
                                        drawChart();
                                    }
                                });
                            } else {
                                if(scope.dataSource.objectSource =='internal'){
                                    for(var v=0; v<values.length; v++) {
                                        var options = {
                                            timePeriod: scope.options.timePeriod.period,
                                            value: values[v],
                                            valueUnit: datasource.valueUnit,
                                            filters: scope.filters,
                                            decimals : scope.decimals,
                                            unitOfTime: datasource.unitOfTime
                                        };
                                        
                                        var targetResult = DigiLean.target.getTargetResultForUnitOfTime(options, scope.dataSource.targets);
                                        if(targetResult){
                                            values[v].targetIndicator = targetResult.targetIndicator;
                                            values[v].greenValue = targetResult.greenValue;
                                        }
                                    }
                                }
                                setupChartData(values);
                                drawChart();
                            }
                        } else {
                            scope.datafound = false;
                        }
                    });                        
                }


                let currentChart = null;
                function drawChart() {

                    var renderElement = $(elem).find(".targetgraph")[0];
                    currentChart = new Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'column',
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            categories: scope.xAxisData,
                            offset: 10,
                            labels: {
                                // autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                                autoRotation: [10, 20, 30, 40, 50, 60, 70, 80, 90],
                                rotation: 45,
                                style: {
                                    fontSize: "0.9em"
                                }
                            },
                        },
                        yAxis: {
                            min: minY,
                            title: {
                                text: scope.dataSource.valueElement.unit
                            },
                            plotLines: [{
                                color: '#C0C0C0',
                                width: 1,
                                value: 0
                            }],
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        fontSize: "0.9em"
                                    },
                                    format: '{point.y:,.'+ scope.decimals +'f}'
                                }
                            }
                        },
                        legend: {
                            enabled: false    
                        },
                        series: scope.seriesData

                    });
                    resizeChart();
                }

                scope.registerData = function () {
                    var settings = {
                        dataSource: scope.dataSource
                        };   
                    
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'registrationDialog.html',
                            controller: 'registrationDialogController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                componentSettings: function () {
                                    return settings;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {      
                        });
                    }; 

                let selectDataSourceModal
                scope.selectDataSource = function () {
                    if (selectDataSourceModal)
                        return
                    
                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return true;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return false;
                            }
                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        scope.filters = result.filters;
                        scope.options.unitOfTime = scope.dataSource.unitOfTime;
                        scope.updateSettings();
                        refreshData();
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                };

                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight
                    
                    var chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (currentChart)
                        currentChart.setSize(width, height, true)
                }

                scope.$on("widget-resized", function (event, widget) {
                    console.log("Got widget-resized")
                    // if (widget && widget.id !== scope.widget.id) return // TODO: potential reduce amount of resize work
                    resizeChart();
                });

                scope.$on("$destroy", () => {
                    if (currentChart)
                        currentChart.destroy()
                })
            }
        }
    }]);
