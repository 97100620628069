﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('moveOrCloneSelector', ['$scope', '$uibModalInstance', 
  function ($scope, $uibModalInstance) {
    $scope.move = function () {

      $uibModalInstance.close("move");

    };
    $scope.clone = function () {

      $uibModalInstance.close("clone");
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }]);