﻿import * as timeService from "@common/services/timeService"

angular
    .module("DigiLean")
    .directive('invoices', ['customerAccountService',
        function (customerAccountService) {
            return {
                templateUrl: 'invoices.html',
                restrict: 'E',
                scope: {
                    // 'datasourceId': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = false;
                    scope.timePeriod =  timeService.getTimePeriod("month"); // Current month, should be able to change
                    function loadData() {
                        scope.isLoading = true;
                        customerAccountService().getInvoicesForPeriod(scope.timePeriod).then(function (invoices) {
                            scope.invoices = invoices;
                            scope.isLoading = false;
                        });
                    }
                    loadData();

                    scope.lastMonth = function () {
                        var start = moment(scope.timePeriod.from).subtract(1, 'months');
                        var end = moment(start).endOf("month"); 
                        scope.timePeriod = {
                            from: start.utc().toDate().toJSON(),
                            to: end.utc().toDate().toJSON()
                        };
                        loadData();
                    }
                    scope.nextMonth = function () {
                        var start = moment(scope.timePeriod.from).add(1, 'months');
                        var end = moment(start).endOf("month"); 
                        scope.timePeriod = {
                            from: start.utc().toDate().toJSON(),
                            to: end.utc().toDate().toJSON()
                        };
                        loadData();
                    }
                    scope.refresh = function() {
                        loadData();
                    }
                }
            }
        }]);

