angular
    .module('DigiLean').directive("a3Links", ['a3Service','$filter',
        function (a3Service, $filter) {
            return {
                templateUrl: 'a3Links.html',
                restrict: 'E',
                scope: {
                    'a3Id': '<',
                    'isEditMode': '<',
                    'linkCountChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    //scope.uploadedFiles = [];
                    //scope.files = [];
                    scope.isLoading = true;
                    scope.toogleEditModeOn = true;
                    if(scope.isEditMode){
                        scope.toogleEditModeOn = false;
                    }

                    scope.title = "";
                    scope.url = "";

                    scope.$watch("a3Id", function (a3Id) {
                        if (!a3Id) return;
                        a3Service().getLinks(a3Id).then(function(links) {
                            scope.links = links;
                            scope.isLoading = false;
                            scope.linkCountChangeHandler({ linkCount: scope.links.length })
                        });
                    });

                    scope.subscribe('A3LinkAdded', function (link) {
                        if (link.a3Id === scope.a3Id) {
                            link.cssState = "slideInDown";
                            scope.links.push(link);
                            scope.linkCountChangeHandler({ linkCount: scope.links.length })
                        }
                    });

                    scope.subscribe('A3LinkDeleted', function (link) {
                        if (link.a3Id === scope.a3Id) {
                            var isPartOf = $filter('filter')(scope.links, { id: link.id }, true);
                            if (isPartOf.length > 0) {
                                link.cssState = "slideOutDown";
                                var i = scope.links.indexOf(isPartOf[0]);
                                scope.links.splice(i, 1);
                                scope.linkCountChangeHandler({ linkCount: scope.links.length })
                            }
                        }
                    });

                    scope.deleteLink = function (link) {
                        //var i = scope.links.indexOf(link);
                        //scope.links.splice(i, 1);
                        a3Service().deleteLink(scope.a3Id, link.id).then(function() {

                        });
                    }

                    function isValid(){
                        scope.notValidTitle = false;
                        scope.notValidUrl = false;
                        if(scope.title == ""){
                            scope.notValidTitle = true;
                        }
                        if(scope.url == ""){
                            scope.notValidUrl = true;
                        }

                        if(scope.notValidTitle || scope.notValidUrl){
                            return false;
                        } else return true;
                    }

                    scope.addLink = function () {
                        if(isValid()){
                            var link = {
                                title:scope.title,
                                url : scope.url
                            }
                            a3Service().addLink(scope.a3Id, link).then(function(stored) {
                                scope.isEditMode = false;
                                scope.title = "";
                                scope.url = "";
                                //scope.links.push(stored);
                            });
                        }
                    };
                    scope.toggleEditMode = function () {
                        scope.isEditMode = !scope.isEditMode ;
                    };

                }

            }
        }]);
