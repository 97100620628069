import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("messageSender", ['$translate', '$uibModal',
        function ($translate, $uibModal) {
            return {
                templateUrl: 'messageSender.html',
                restrict: 'E',
                scope: {
                    
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.text = "";
                    scope.errormessage = "";
                    scope.uploadedFiles = [];
                    scope.reciever = "all";
                    scope.filters = [];
                    scope.hidefroala = false;

                    function getMessages(){
                        customerService.getAdminMessages().then(function(result){
                            scope.messages = result;
                            scope.messages.forEach(message => {
                                if (message.isPublic){
                                    message.typeColor = "#5ab700";
                                    message.type = $translate.instant('COMMON_ALL');
                                } else if (message.recipientUserId != null){
                                    message.typeColor = "#F8AC59"
                                    message.type = $translate.instant('COMMON_PERSONAL');
                                } else if (message.projectId != null){
                                    message.typeColor = "#0099DE"
                                    message.type = $translate.instant('COMMON_PROJECT');
                                } else if (message.assetId != null){
                                    message.typeColor = "#f0ad4e"
                                    message.type = $translate.instant('COMMON_TEAM_BOARD');
                                } else {
                                    message.typeColor = "#D770AD"
                                    message.type = $translate.instant('COMMON_ASSET');
                                }
                            });
                        });
                    }                    
                    getMessages();

                    scope.setAll = function(){
                        scope.reciever = "all";
                    }

                    scope.selectAssets = function () {
                        scope.reciever = "asset";
                        scope.hidefroala = true;
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'assetSelectorModal.html',
                            controller: 'assetSelectorModalController',
                            windowClass: 'assetselector-modal-window',
                            resolve: {

                                filters: function () {
                                    return scope.filters;
                                }
                            }
                        });
                
                        modalInstance.result.then(function (result) {
                            scope.filters = result;
                            scope.hidefroala = false;
                        }, function(res){
                            scope.hidefroala = false;
                        });
                    };

                    scope.textChanged = function (content) {
                        scope.text = content;
                    }

                    scope.deleteMessage = function (message) {
                        scope.isDeleting = true;
                        customerService.deleteMessage(message.id).then(function (result) {
                            scope.isDeleting = false;
                            getMessages();
                        });
                    };
                    

                    scope.subscribe("MessageSent", function (message) {
                        console.log("message sent");
                        toastr.success($translate.instant('COMMON_MESSAGE')+ " "+ $translate.instant('COMMON_SENT_TO')+ " "+ $translate.instant('ADMIN_USERS').toLowerCase());
                        getMessages();
                    });

                    scope.subscribe("ProjectMessageAdded", function (message) {
                        getMessages();
                    });
            
                    scope.fileUploaded = function (file) {
                        boardTaskService().addAttachment($scope.task.id, file).then(function (attachment) {
                            attachment.cssState = "animated slideInDown";
                            setTimeout(function () {
                                attachment.cssState = "";
                            }, 500);
                            $scope.attachments.push(attachment);
                            // Send event
                            $scope.publish("SendTaskAttachmentAdded", attachment);
                        });
                    };

                    scope.sendMessage = function () { 
                        scope.errormessage = "";
                        if(!scope.title){
                            scope.errormessage = $translate.instant("ADMIN_DATA_FIELD_MISSING");
                            return;
                        }  

                        var message = {
                            title: scope.title,
                            text: scope.text,
                            files: scope.uploadedFiles
                        };
                        if(scope.reciever = "asset" && scope.filters.length > 0){
                            var assetIds = scope.filters[0].items;
                            message.assets = [];
                            message.isPublic = false;
                            for(var i=0; i<assetIds.length; i++){
                                var messageAsset = 
                                {
                                    AssetId: assetIds[i]
                                }; 
                                message.assets.push(messageAsset);
                            }
                        } else {
                            message.isPublic = true;
                        }
                        customerService.sendMessage(message).then(function (result) {
                            scope.title = "";
                            scope.text = "";
                            scope.uploadedFiles = [];
                            getMessages();
                        });
                    };
                }
            }
        }]);
