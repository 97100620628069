angular
    .module('DigiLean')
    .directive("smarttableLinkCell", ['$filter', 'debounce', 'projectService',
        function ($filter, debounce, projectService) {
            return {
                templateUrl: 'smarttableLinkCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'adminmode': '='
                },
                link: function (scope, elem, attrs) {
                    scope.url = "";
                    scope.openInNewTab = true;
                    scope.text = "";
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();

                    scope.showDropdown = false;

                    scope.usePredefinedLabels = false;
                    scope.labels = [];
                    var columnId = null;

                    scope.$watch('model', function (model) {
                        var value = model.cell.value;
                        columnId = model.column.id;
                        var settings = model.column.settings;
  
                        if (value) {
                            setValue(value);
                        } 
                        model.getSortValue = function() {
                            return  scope.text;
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            //$(elem).closest(".smarttable-link-cell").focus(); fungerer ikke
                            $(".smarttable-link-cell").focus();
                            
                        }, 50 );
                    }
                    
                    var setValue = function(value) {
                        scope.background = value.background;
                        scope.url = value.url;
                        scope.openInNewTab = value.openInNewTab;
                        scope.text = value.text;
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }
                
                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        }
                    });
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }
                    scope.urlChanged = function (url) {
                        if(scope.url === url) return;
                        scope.url = url;
                        scope.commitChangesDebounced();
                    }

                    scope.openChanged = function (open) {
                        if(scope.openInNewTab === open) return;
                        scope.openInNewTab = open;
                        scope.commitChangesDebounced();
                    }
                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text,
                            url: scope.url,
                            openInNewTab: scope.openInNewTab
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
