angular
    .module('DigiLean')
    .controller("targetVisualizationSelectorController", ['$scope', '$state', '$uibModalInstance', '$filter', 'qualityService',
        function ($scope, $state, $uibModalInstance, $filter, qualityService) {

            $scope.visualizationType = null;
            
            qualityService().getVisualizationTypesForProjects().then(function (result) {
                $scope.types = result;
            });
            $scope.changeVisualizationType = function (type) {
                $scope.visualizationType = type;
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.visualizationType);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
