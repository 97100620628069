angular
    .module('DigiLean')
    .directive("projectContactPersonCell", ['$filter', '$translate','projectService','modalService','authService','contactlistService','$uibModal',
        function ($filter, $translate, projectService,modalService, authService, contactlistService, $uibModal) {
            return {
                templateUrl: 'projectContactPersonCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.contact = null;
                    scope.contacts = [];
                    scope.qcontact = "";

                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
                    // if page is refreshed we need to refresh
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
                    });

                    scope.$watch('model', function (projectModel) {
                        var value = scope.model.projectAttribute.value;
                        if (value) {
                            setValue(value);
                        }
                    });

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            
                            contactlistService().getList().then(function (items) {
                                scope.contacts = items;
                                setProjectContact();
                            });
                        } 
                        model.getSortValue = function() {
                            if (scope.contact) {
                                return scope.contact.fullName;
                            }
                            return "";
                        }
                    });

                    
                    scope.subscribe("PersonMembershipChanged", function (membership) {
                        if (membership.projectId == scope.model.project.id && membership.role == "CustomerContact") {
                            var matching = $filter('filter')(scope.contacts, { id: membership.personId }, true);
                            if (matching.length > 0) {
                                if(scope.contact){
                                    scope.contact.isSelected = false;
                                }
                                scope.contact = matching[0];
                                scope.contact.isSelected = true;           
                            }
                        }
                    });
                    /*
                    scope.subscribe("ProjectContactPersonUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            scope.contact = project.contactPerson;
                        }
                    });

                    scope.subscribe("ProjectCustomerCreated", function (newCustomer) {
                        projectService().getProjectCustomers().then(function (customers) {
                            scope.customers = customers;    
                        });
                    });*/


                    var setValue = function (value) {
                        scope.customer = value;
                    } 

                    

                    scope.updateContact = function(contact){
                        if(scope.contact){
                            scope.contact.isSelected = false;
                        }
                        scope.contact = contact;
                        scope.contact.isSelected = true;
                        contactlistService().updateProjectContactPerson(contact.id, scope.model.project.id).then(function (registeredcontact) {
                        });   
                    };
                    
                    function setProjectContact(){
                        contactlistService().getProjectContactPerson(scope.model.project.id).then(function (contact) {
                            if(contact){
                                var matching = $filter('filter')(scope.contacts, { id: contact.id }, true);
                                if (matching.length > 0) {
                                    scope.contact = matching[0];
                                    scope.contact.isSelected = true;           
                                } 
                            }
                        });
                    } 
        
                    scope.clearContact = function () {
                        contactlistService().deleteProjectContactPerson(scope.model.project.id).then(function () {
                            if(scope.contact){
                                scope.contact.isSelected = false;
                            }
                            scope.contact = null;
                        });
                    };    
        
                    scope.newContact = function (name) {
                        var suggestedName = "";
                        var suggestedCompany = "";
                        if(name){
                            suggestedName = name;
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newContactPersonForm.html',
                            controller: 'newContactPersonModalController',
                            resolve: {
                                contact: function () {
                                    return {
                                        id: 0,
                                        firstName: suggestedName,
                                        lastName: "",
                                        title: "",
                                        email: "",
                                        phoneNumber: "",
                                        companyName: suggestedCompany
                                    };
                                }
            
                            }
                        });
            
                        // When user has created new contact
                        modalInstance.result.then(function (newcontact) {
                            contactlistService().getList().then(function (items) {
                                scope.contacts = items;
                                var matching = $filter('filter')(scope.contacts, { id: newcontact.id }, true);
                                if (matching.length > 0) {
                                    scope.updateContact(matching[0]);           
                                }
                                
                            });
                        }, function () {
                        });
                    };

                }
            }
        }]);
