angular.module("DigiLean").factory('projectService', ['$http', 'webApiUrl', '$translate',
    function ($http, webApiUrl, $translate) {
        function projectService() {
            var resource = "/project";
            var resourceUrl = webApiUrl + resource;

            return {
                getPostIts: function () {
                    return [
                        {
                            id:0,
                            title: "default",
                            fillColor: "#ffffff",
                            background: "#f1f1f1",
                            color: "#f1f1f1"
                        },
                        {
                            id:1,
                            title: $translate.instant('DASHBOARD_OVERDUE'), // orange 
                            // fillColor: "#FFCE54",            
                            fillColor: "#FFF6DF",
                            background: "#FCBB42",
                            color: "#ffffff",
                        },
                        {
                            id:2,
                            title: $translate.instant('COMMON_OK'), // green
                            // fillColor: "#A0D468",
                            fillColor: "#EDF7E3",
                            background: "#8CC152",
                            color: "#ffffff",
                        },
                        {
                            id:3,
                            title: $translate.instant('COMMON_IN_PROGRESS'), //blue  
                            // fillColor: "#4FC1E9",
                            fillColor: "#DFF3FB",
                            background: "#3BAFDA",
                            color: "#ffffff",
                        },
                        {
                            id:4,
                            title: $translate.instant('DASHBOARD_STUCK'), // red
                            // fillColor: "#FC6E51",
                            fillColor: "#FEE4DF",
                            background: "#E9573F",
                            color: "#ffffff",
                        },
                        {
                            id:5,
                            title: $translate.instant('BOARD_WAITING'), // pink
                            // fillColor: "#EC87C0",
                            fillColor: "#FBE9F3", 
                            background: "#D770AD",
                            color: "#ffffff",
                        }

                    ];
                },
                
                getStatus: function () {
                    return [
                        {   sortorder: 1,
                            translatedText: $translate.instant('PROJECT_ON_HOLD'),
                            description: $translate.instant('PROJECT_ON_HOLD_DESC'), 
                            dbCode: "ONHOLD",
                            background:'#d9534f',
                            color: '#fff'

                        },
                        {   sortorder: 4,
                            translatedText: $translate.instant('PROJECT_PROSPECT'),     
                            description: $translate.instant('PROJECT_PROSPECT_DESC'), 
                            dbCode: "PROSPECT",
                            background:'#3bafda',
                            color: '#fff'

                        },
                        {   sortorder: 5,
                            translatedText: $translate.instant('PROJECT_NOT_STARTED'),     
                            description: $translate.instant('PROJECT_NOT_STARTED_DESC'), 
                            dbCode: "NOTSTARTED",
                            background:'#f8ac59',
                            color: '#fff'

                        },
                        {   sortorder: 10,
                            translatedText: $translate.instant('COMMON_IN_PROGRESS'),     
                            description: $translate.instant('PROJECT_IN_PROGRESS_DESC'),
                            dbCode: "INPROGRESS",
                            background: '#5cb85c',
                            color: '#fff'
                        },
                        {
                            sortorder: 20,
                            translatedText: $translate.instant('DEVIATION_CLOSED'),     
                            description: $translate.instant('PROJECT_CLOSED_DESC'),
                            dbCode: "CLOSED",
                            background: '#434348',
                            color: '#fff'
                        }
                    ];
                },
                addProject: function (project) {
                    var url = resourceUrl;
                    return $http.post(url, project).then(function (result) {
                        return result.data;
                    });
                },
                addProjects: function (projects) {
                    var url = resourceUrl + "/addList";
                    return $http.post(url, projects).then(function (result) {
                        return result.data;
                    });
                },
                saveImage: function (projectId, imageId) {
                    var url = resourceUrl + "/saveImage";
                    var options = {
                        projectId: projectId,
                        imageId: imageId
                    }
                    return $http.put(url, options).then(function (result) {
                        return result.data;
                    });
                },
                hasAccess: function (projectId) {
                    var url = resourceUrl + "/hasAccess/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getProjectCustomerStats: function () {
                    var url = resourceUrl + "/getProjectCustomerStats/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getCustomerProjectsDeliveredOnTime: function() {
                    var url = resourceUrl + "/getCustomerProjectsDeliveredOnTime/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getCustomerProjectNoOfSuggestions: function() {
                    var url = resourceUrl + "/getCustomerProjectNoOfSuggestions/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getCustomerProjectNoOfDeviations: function() {
                    var url = resourceUrl + "/getCustomerProjectNoOfDeviations/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getLinks: function(projectId) {
                    var url = resourceUrl + "/getLinks/" + projectId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addLink: function (projectId, link) {
                    var url = resourceUrl + "/" + projectId + "/addLink";
                    return $http.post(url, link).then(function (result) {
                        return result.data;
                    });
                },
                deleteLink: function (projectId, linkId) {
                    var url = resourceUrl + "/" + projectId + "/deleteLink/" + linkId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getAttachments: function(projectId) {
                    var url = resourceUrl + "/getAttachments/" + projectId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addAttachment: function (projectId, fileInfo) {
                    var url = resourceUrl + "/" + projectId + "/addAttachment";
                    return $http.post(url, fileInfo).then(function (result) {
                        return result.data;
                    });
                },

                deleteAttachment: function (projectId, attachmentId) {
                    var url = resourceUrl + "/" + projectId + "/deleteAttachment/" + attachmentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                addProjectToBoard: function (projectId, boardId, sortOrder) {
                    var url = resourceUrl + "/addProjectToBoard/";
                    var projectBoard = {
                        projectId: projectId,
                        boardId: boardId,
                        sortOrder: sortOrder
                    }
                    return $http.post(url, projectBoard).then(function (result) {
                        return result.data;
                    });
                },
                getBoardForProject: function (projectId) {
                    var url = resourceUrl + "/getBoardForProject/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                removeProjectFromBoard: function (projectId, boardId, projectBoardId) {
                    var url = resourceUrl + "/removeProjectFromBoard/";
                    var projectBoard = {
                        projectId: projectId,
                        boardId: boardId,
                        id: projectBoardId
                    }
                    return $http.post(url, projectBoard).then(function (result) {
                        return result.data;
                    });
                },
                addProjectAttributeToBoard: function (attribute) {
                    var url = resourceUrl + "/AddProjectAttributeToBoard/";
                    return $http.post(url, attribute).then(function (result) {
                        return result.data;
                    });
                },
                deleteProjectAttribute: function(attribute) {
                    var url = resourceUrl + "/DeleteProjectAttribute/" + attribute.id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectBoardSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/updateProjectBoardSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectBoardRowSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/updateProjectBoardRowSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectAttribute: function (attribute) {
                    var url = resourceUrl + "/updateProjectAttribute/";
                    return $http.put(url, attribute).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectBoardAttribute: function (col) {
                    var updated = {
                        id: col.id,
                        name: col.name,
                        boardId: col.boardId
                    }
                    if (col.settings) {  
                        updated.settings = JSON.stringify(col.settings);
                    }
                    var url = resourceUrl + "/updateProjectBoardAttribute/";
                    return $http.put(url, updated).then(function (result) {
                        return result.data;
                    });
                },
                getList: function (useCache){
                    if (!useCache) useCache = false;
                    return $http.get(resourceUrl, { cache: useCache}).then(function(result){
                        return result.data;
                    });
                },
                getOngoingProjects: function() {
                    var url = resourceUrl + "/getOngoingProjects/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getNextMilestonesForProject: function(projectId) {
                    var url = resourceUrl + "/GetNextMilestonesForProject/" + projectId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getMemberProjectPortfolio: function () { 
                    var url = resourceUrl + "/GetMemberProjectPortfolio";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getMemberProjectPortfolioInfo: function () { 
                    var url = resourceUrl + "/GetMemberProjectPortfolioInfo";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getProjectPortfolioForAdmin: function () { 
                    var url = resourceUrl + "/GetProjectPortfolioForAdmin";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                
                
                /* could be useful
                getProgressProjects: function (){
                    
                    var currentfilter = [{
                        sourceColumn: "Status",
                        operator: "InList",
                        items: ["INPROGRESS"]
                        
                    },{
                        sourceColumn: "Category",
                        operator: "NotContain",
                        items: ["A3"]
                        
                    }]; 
                    var options= {
                        filters: currentfilter
                    }
                    var url = resourceUrl + "/getFilteredProjects";
                    return $http.post(url, options).then(function(result){
                        return result.data;
                    });
                },*/
                getActiveProjects: function (){
                    
                    var currentfilter = [{
                        sourceColumn: "Status",
                        operator: "InList",
                        items: ["New", "NOTSTARTED","INPROGRESS"]
                        
                    }]; 
                    var options= {
                        filters: currentfilter
                    }
                    var url = resourceUrl + "/getFilteredProjects";
                    return $http.post(url, options).then(function(result){
                        return result.data;
                    });
                },
                
                getMyProjects: function (){
                    var url = resourceUrl + "/getMyProjects";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getProjectsInfo: function (){
                    var url = resourceUrl + "/GetProjectsInfo";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                
                getMyProjectsInfo: function (){
                    var url = resourceUrl + "/GetMyProjectsInfo";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                }, 
                GetMyProjectsMiniInfo: function (){ // only id and name
                    var url = resourceUrl + "/GetMyProjectsMiniInfo";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },   
                getUserProjects: function (userid){ 
                    var url = resourceUrl + "/GetUserProjects/" + userid;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },                 
                getBoardsForProject: function (projectId){
                    var url = resourceUrl + "/GetBoardsForProject/"+ projectId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getA3ForProject: function (projectId){
                    var url = resourceUrl + "/GetA3ForProject/"+ projectId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getDashboardsForProject: function (projectId){
                    var url = resourceUrl + "/GetDashboardsForProject/"+ projectId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getProjectListWithDeviation: function (){
                    var url = resourceUrl + "/getProjectListWithDeviation";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getProjectListWithSuggestion: function (){
                    var url = resourceUrl + "/getProjectListWithSuggestion";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getProjectListWithA3: function (){
                    var url = resourceUrl + "/getProjectListWithA3";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getProjectInfo: function (projectId){
                    var url = resourceUrl + "/getProjectInfo/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getMilestone: function (milestoneId){
                    var url = resourceUrl + "/getMilestone/" + milestoneId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                
                
                updateParticipants: function (projectId, participantGroup) {
                    var url = resourceUrl + "/" + projectId + "/updateParticipants";
                    return $http.put(url, participantGroup).then(function (result) {
                        return result.data;
                    });
                },

                getMilestonesForProject: function (projectId) {
                    var url = resourceUrl + "/getMilestonesForProject/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                

                getUsers: function (projectId) {
                    var url = resourceUrl + "/getUsers/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getProjectCustomers: function () {
                    var url = resourceUrl + "/getProjectCustomers/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createNewCustomer: function (customer) {
                    var url = resourceUrl + "/createNewCustomer/";
                    return $http.post(url,customer).then(function (result) {
                        return result.data;
                    });
                },
                saveUserProjects: function (userId, userprojects) {
                    var url = resourceUrl + "/SaveUserProjects/" + userId;
                    return $http.post(url, userprojects).then(function (result) {
                        return result.data;
                    });
                },
                
                updateCustomerInfo: function (customer) {
                    var url = resourceUrl + "/updateCustomerInfo/";
                    return $http.put(url,customer).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectName: function (id, name) {
                    var project =  {
                        id: id,
                        name: name
                    }
                    var url = resourceUrl + "/updateProjectName/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },
                updateOwner: function (projectid, user) {
                    var project =  {
                        id: projectid,
                        owner: user.fullName,
                        ownerUserId : user.userId,
                        ownerDisplayName: user.displayName
                    }
                    var url = resourceUrl + "/updateOwner/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },
                addUser: function (projectid, user) {
                    if(!user.assetRole){
                        user.assetRole = 'Member';
                    }
                    var projectuser =  {
                        projectId: projectid,
                        userId : user.userId,
                        role:  user.assetRole          
                    }
                    var url = resourceUrl + "/addUser/";
                    return $http.put(url,projectuser).then(function (result) {
                        return result.data;
                    });
                },
                removeUser: function (projectid, userId) {
                    var projectuser =  {
                        projectId: projectid,
                        userId : userId          
                    }
                    var url = resourceUrl + "/removeUser/";
                    return $http.put(url,projectuser).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectStatus: function (id, dbstatus) {
                    var project =  {
                        id: id,
                        status: dbstatus
                    }
                    var url = resourceUrl + "/updateProjectStatus/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectNumber: function (id, projectNumber) {
                    var project =  {
                        id: id,
                        projectNumber: projectNumber
                    }
                    var url = resourceUrl + "/updateProjectNumber/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },
                updateCustomer: function (id, customerId) {
                    var project =  {
                        id: id,
                        projectCustomerId: customerId
                    }
                    var url = resourceUrl + "/updateProjectCustomer/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },
                deleteProjectCustomer: function (id) {
                    var url = resourceUrl + "/deleteProjectCustomer/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                /*
                updateStartDate: function (dateinfo) {
                    var project =  {
                        id: dateinfo.id,
                        startDate: dateinfo.startDate,
                        estimatedStartDate: dateinfo.estimatedStartDate,
                        
                    }
                    var url = resourceUrl + "/updateStartDate/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },*/
                deleteProject: function (projectId) {
                    var url = resourceUrl + "/deleteProject/" + projectId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                deleteMilestone: function (milestoneId) {
                    var url = resourceUrl + "/deleteMilestone/" + milestoneId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
    
                createMilestone: function (milestone) {
                    var url = resourceUrl + "/createMilestone/";
                    return $http.post(url,milestone).then(function (result) {
                        return result.data;
                    });
                },
    
                updateMilestone: function (milestone) {
                    var url = resourceUrl + "/updateMilestone/";
                    return $http.put(url,milestone).then(function (result) {
                        return result.data;
                    });
                },

                updateEstimatedStartDate: function (id, date) {
                    var projectDate =  {
                        projectId: id,
                        actionDate: date
                    }
                    var url = resourceUrl + "/updateEstimatedStartDate/" + id;
                    return $http.put(url,projectDate).then(function (result) {
                        return result.data;
                    });
                },
                updateStartDate: function (id, date) {
                    var projectDate =  {
                        projectId: id,
                        actionDate: date
                    }
                    var url = resourceUrl + "/updateStartDate/" + id;
                    return $http.put(url,projectDate).then(function (result) {
                        return result.data;
                    });
                },
                updateEstimatedEndDate: function (id, date) {
                    var projectDate =  {
                        projectId: id,
                        actionDate: date
                    }
                    var url = resourceUrl + "/updateEstimatedEndDate/" + id;
                    return $http.put(url,projectDate).then(function (result) {
                        return result.data;
                    });
                },
                updateEndDate: function (id, endDate) {
                    var projectDate =  {
                        projectId: id,
                        actionDate: endDate
                    }
                    var url = resourceUrl + "/updateEndDate/" + id;
                    return $http.put(url,projectDate).then(function (result) {
                        return result.data;
                    });
                },
                updateProjectDescription: function (id, description) {
                    var projectDescription =  {
                        id: id,
                        description: description
                    }
                    var url = resourceUrl + "/updateProjectDescription/";
                    return $http.put(url,projectDescription).then(function (result) {
                        return result.data;
                    });
                },
           
            };
        }
        return projectService;
    }]);