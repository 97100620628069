angular.module("DigiLean").factory('strategyService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {
        function strategyService() {
            var resource = "/strategy";
            var resourceUrl = webApiUrl + resource;

            return {
                fixStrategy: function (){
                    var url = resourceUrl + "/fixStrategy/";
                    return $http.put(url).then(function(result){
                        return result.data;
                    });
                },
                getStrategyMap: function (){
                    var url = resourceUrl + "/getStrategyMap/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getStrategyMapByAsset: function (assetid){
                    var url = resourceUrl + "/getStrategyMapByAsset/" + assetid;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                canEditGoal: function (goalId) {
                    var url = resourceUrl + "/caneditgoal/" + goalId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getTeamGoals: function (breakthroughGoalId, assetId) {
                    var url = resourceUrl + "/getTeamGoals?breakthroughGoalId=" + breakthroughGoalId + "&assetId=" + assetId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getAllActiveTeamGoals: function (assetId) {
                    var url = resourceUrl + "/GetAllActiveTeamGoals?assetId=" + assetId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getAllTeamGoals: function (assetId) {
                    var url = resourceUrl + "/GetAllTeamGoals?assetId=" + assetId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                
                getAllGoals: function (){
                    var url = resourceUrl + "/getAllGoals/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getActiveBreakthroughObjectives: function (){
                    var url = resourceUrl + "/GetActiveBreakthroughObjectives/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getBreakthroughObjectives: function (){
                    var url = resourceUrl + "/getBreakthroughObjectives/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getStrategyBoard: function (){
                    var url = resourceUrl + "/GetStrategyBoard/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                
                getStrategyBoardById: function (boardId){
                    var url = resourceUrl + "/GetStrategyBoardById/" + boardId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getSubGoalsBoard: function (goalId) {
                    var url = resourceUrl + "/getSubGoalsBoard/" + goalId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                updateStrategyGoalSortOrder: function (boardId, sortOrder) {
                    var url = resourceUrl + "/UpdateStrategyGoalSortOrder/" + boardId ;
                    return $http.put(url, sortOrder).then(function (result) {
                        return result.data;
                    });
                },
                getNoImprovementPerStrategyStatus: function (strategyGoalId, assetId){
                    var url = resourceUrl + "/" + strategyGoalId + "/getNoImprovementPerStrategyStatus/";
                    if(assetId){
                        url = resourceUrl + "/" + strategyGoalId + "/getNoImprovementPerStrategyStatus/" + assetId;
                    }
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                getMyStrategyTasks: function (timeperiod){
                    var strategyGoalsId = [];
                    var strategyGoalsQuery = {
                        strategyGoalsList:strategyGoalsId,
                        timePeriod: timeperiod
                    }
                    var url = resourceUrl + "/getMyStrategyTasks/";
                    return $http.post(url, strategyGoalsQuery).then(function(result){
                        return result.data;
                    });
                },

                getTasksForStrategyGoals: function (strategyGoals, timeperiod){
                    var strategyGoalsId = [];
                    for(var i=0; i<strategyGoals.length; i++){
                        strategyGoalsId.push(strategyGoals[i].id);
                    }
                    var strategyGoalsQuery = {
                        strategyGoalsList:strategyGoalsId,
                        timePeriod: timeperiod
                    }
                    var url = resourceUrl + "/getTasksForStrategyGoals/";
                    return $http.post(url, strategyGoalsQuery).then(function(result){
                        return result.data;
                    });
                },

                getA3sForStrategyGoal: function (strategyGoalId, assetId){
                    var url = resourceUrl + "/" + strategyGoalId + "/getA3sForStrategyGoal/";
                    if(assetId){
                        url = resourceUrl + "/" + strategyGoalId + "/getA3sForStrategyGoal/" + assetId;
                    }
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },

                getBoardsForStrategyGoal: function (strategyGoalId,assetId){
                    var url = resourceUrl + "/" + strategyGoalId + "/getBoardsForStrategyGoal/";
                    if(assetId){
                        url = resourceUrl + "/" + strategyGoalId + "/getBoardsForStrategyGoal/" + assetId;
                    }
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },

                getDashboardsForStrategyGoal: function (strategyGoalId,assetId){
                    var url = resourceUrl + "/" + strategyGoalId + "/getDashboardsForStrategyGoal/";
                    if(assetId){
                        url = resourceUrl + "/" + strategyGoalId + "/getDashboardsForStrategyGoal/" + assetId;
                    }
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },

                getVision: function (){
                    var url = resourceUrl + "/getStrategy/";
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                updateStrategy: function (strategy) {
                    var url = resourceUrl + "/updateStrategy/";;
                    return $http.put(url, strategy).then(function (result) {
                        return result.data;
                    });
                },
                getList: function (useCache){
                    if (!useCache) useCache = false;
                    return $http.get(resourceUrl, { cache: useCache}).then(function(result){
                        return result.data;
                    });
                },
                
                updateProjectStatus: function (id, dbstatus) {
                    var project =  {
                        id: id,
                        status: dbstatus
                    }
                    var url = resourceUrl + "/updateProjectStatus/";
                    return $http.put(url,project).then(function (result) {
                        return result.data;
                    });
                },
            
                addKPI: function (kpi) {
                    var url = resourceUrl  + "/addKPI/";
                    return $http.post(url, kpi).then(function (result) {
                        return result.data;
                    });
                },
                updateKPI: function (kpi) {
                    var url = resourceUrl + "/" + kpi.id + "/updateKPI";
                    return $http.put(url, kpi).then(function () {
                        return;
                    });
                },
                deleteKPI: function (id) {
                    var url = resourceUrl + "/deleteKPI/" + id;
                    return $http.delete(url).then(function () {
                        return;
                    });
                },
               
                getGoalsWithKPIsByAsset:function (assetId, year){
                    var strategyAssetYear ={
                        assetId: assetId,
                        year: year
                    }
                    var url = resourceUrl + "/getGoalsWithKPIsByAsset/";
                    return $http.post(url, strategyAssetYear).then(function(result){
                        return result.data;
                    });
                },
                updateKPIMonthlyValue:function (kpiMonthlyValue){
                    var url = resourceUrl + "/updateKPIMonthlyValue/";
                    return $http.post(url,kpiMonthlyValue).then(function (result) {
                        return result.data;
                    });
                },
                getGoalKPIs:function (strategyGoalId){
                    var url = resourceUrl + "/GetGoalKPIs/";
                    return $http.post(url, strategyGoalId).then(function(result){
                        return result.data;
                    });
                },
                getLinks: function(strategyGoalId, assetId) {
                    if(!assetId){
                        assetId = 0;
                    }
                    var url = resourceUrl + "/"+ strategyGoalId + "/getLinks/" +assetId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addLink: function (strategyGoalId, assetId, link) {
                    var strategyGoalLink = {
                        strategyGoalId: strategyGoalId,
                        assetId:assetId,
                        Title : link.title,
                        url : link.url
                    };
                    var url = resourceUrl + "/" + strategyGoalId + "/addLink";
                    return $http.post(url, strategyGoalLink).then(function (result) {
                        return result.data;
                    });
                },
                deleteLink: function (strategyGoalId, linkId) {
                    var url = resourceUrl + "/" + strategyGoalId + "/deleteLink/" + linkId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                
                getAttachments: function(strategyGoalId, assetId) {
                    if(!assetId){
                        assetId = 0;
                    }
                    var url = resourceUrl + "/"+ strategyGoalId + "/getAttachments/" +assetId;
                    return $http.get(url).then(function(result){
                        return result.data;
                    });
                },
                addAttachment: function (strategyGoalId, assetId, fileInfo) {
                    var strategyFileInfo = {
                        assetId:assetId,
                        fileInfo: fileInfo
                    };
                    var url = resourceUrl + "/" + strategyGoalId + "/addAttachment";
                    return $http.post(url, strategyFileInfo).then(function (result) {
                        return result.data;
                    });
                },

                deleteAttachment: function (strategyGoalId, attachmentId) {
                    var url = resourceUrl + "/" + strategyGoalId + "/deleteAttachment/" + attachmentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getTableValues: function (tableParams) {
                    var url = resourceUrl + "/getTableValues/";
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },
            };
        }
        return strategyService;
    }]);