import lottie from 'lottie-web';
import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("emptyFlexiboardPreview", 
        function () {
            return {
                templateUrl: 'emptyFlexiboardPreview.html',
                restrict: 'E',
                scope: {
                },
                link: function (scope, elem, attrs) {

                    scope.imageUrl = staticFileService.image("flexiboard_create_new_app.gif");
                    
                    
                   // scope.imageUrl = staticFileService.image("emptyFlexiboardTemplate.jpg");

                    scope.animationUrl = require("./lotti-singel-barchart.json");

                    var okContainer = $(elem).find(".singel-barchart-animation")[0]; // the dom element that will contain the animation
                     var completeAnimation = lottie.loadAnimation({
                            container: okContainer,
                            renderer: 'svg',
                            name: "lotti-singel-barchart",
                            loop: true,
                            autoplay: true,
                            path: 'https://assets10.lottiefiles.com/packages/lf20_X7iGbd.json' //https://lottiefiles.com/share/X7iGbd 
                        });

                }
            }
        });
