import * as staticFileService from "@common/shared/staticFileService"
import { getDefaultAppStyle, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("minmaxchart", ['$uibModal', '$translate', 'dataService', 'debounce',
    function ($uibModal, $translate, dataService, debounce) {
        return {
            templateUrl: 'minmaxchart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = {
                    background: "var(--digilean-primary-text)",
                    fillColor: "var(--digilean-secondary-background)",
                    color: "inherit"
                }
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)

                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }

                scope.imageUrl = staticFileService.image('minmaxchart.jpg');
                scope.seriesData = [];
                scope.yAxisPlotLines = [];
                
                scope.decimals = 0;
                var selectedGrouping = "";
                
                scope.min = null;
                scope.max = null;
                var minY = 0;
                var maxY = null;

                var timeFrames = [   
                    'all', 
                    'last3years', 
                    'lastyear', 
                    'thisyear',
                    'fiscalyear',
                    'YTD',
                    'last12mth', 
                    'month', 
                    'previousmonth',
                    'custom'    
                ];
                var timeSettings = {
                    timeframe: "YTD",
                    timeframes: timeFrames,
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                function setTimeFrames(grouping){
                    if(grouping.toLowerCase() == "day"){
                        timeSettings.timeframes = [   
                            'all', 
                            'lastyear', 
                            'thisyear',
                            'YTD',
                            'last12mth', 
                            'previousmonth',
                            'month',  
                            'previousisoweek', 
                            'isoweek',
                            'nextweek',
                            'yesterday',
                            'day',
                            'number',
                            'custom'    
                        ];
                    } else if(grouping.toLowerCase() == "week"){
                        timeSettings.timeframes = [   
                            'all', 
                            'lastyear', 
                            'thisyear',
                            'YTD',
                            'last12mth',
                            'previousmonth', 
                            'month', 
                            'previousisoweek', 
                            'isoweek',
                            'nextweek',
                            'custom'    
                        ];
                    }
                }

                function setDayTimeSettings(process){
                    if(process == 'initial'){
                        timeSettings.timeframe = "isoweek";
                    }
                    setTimeFrames("day");
                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);
                }

                function setWeekTimeSettings(process){          
                    if(process == 'initial'){
                        timeSettings.timeframe = "month";
                    }  
                    setTimeFrames("week");  
                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);
                }

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.settings = settings;
                    // apply
                    if (scope.settings) {
                        scope.dataSource = scope.settings.dataSource;
                        if (scope.settings.selectedGrouping) {
                            selectedGrouping = scope.settings.selectedGrouping;
                            setTimeFrames(selectedGrouping);
                        }
                        
                        if (scope.settings.title) {
                            scope.title = scope.settings.title;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if(settings.min !== null || settings.min !== undefined){
                            scope.min = settings.min;
                        }
                        if(settings.max !== null || settings.max !== undefined){
                            scope.max = settings.max;
                        }
                        if (settings.decimals) {
                            scope.decimals = settings.decimals;
                        }
                        
                        if(scope.settings.minY !== null || scope.settings.minY !== undefined){
                            minY = scope.settings.minY;
                        }
                        if(scope.settings.maxY !== null || scope.settings.maxY !== undefined){
                            maxY = scope.settings.maxY;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                    } 
                    if (!scope.dataSource && scope.isAdminMode){
                        setTimeout(function() {
                            scope.selectDataSource();
                        }, 200); 
                    }
                });


                // Subscribe to component related event
                scope.subscribe("DataValueAdded", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            refreshData();
                        }
                    }
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            refreshData();
                        }
                    }
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id === value.dataSourceId) {
                        if (dateInSelectedTimeFrame(value.valueDate)) {
                            refreshData();
                        }
                    }
                });

                function dateInSelectedTimeFrame(utcDate) {
                    if (!scope.timePeriod) {
                        return true;
                    }
                    if (moment(utcDate).isBefore(scope.timePeriod.period.from) ||
                        moment(utcDate).isAfter(scope.timePeriod.period.to)) {
                        return false;
                    } else {
                        return true;
                    }
                }

                scope.updateDecimals = function (newDecimal) {
                    if(newDecimal == undefined) newDecimal = 9; //max decimals supported by highcharts
                    scope.decimals = newDecimal;
                    refreshData();
                    scope.updateSettings();
                };
                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                scope.changeColor = function () {
                    refreshData();
                    scope.updateSettings();
                }

                scope.updateMin = function (min) {
                    scope.min = min;
                    refreshData();
                    scope.updateSettings();
                }
                
                scope.updateMax = function (max) {
                    scope.max = max;
                    if(scope.min == null){
                        scope.min = 0;
                    }
                    refreshData();
                    scope.updateSettings();
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.timePeriod,
                        dataSource: scope.dataSource,
                        filters: scope.filters,
                        min: scope.min,
                        max: scope.max,
                        minY: minY, //y-axis
                        maxY: maxY, //y-axis
                        selectedGrouping: selectedGrouping,
                        decimals: scope.decimals,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                /* This method will be called also when component is first initialized */
                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.timePeriod = timePeriod;
                    scope.options.timePeriod = timePeriod;
                    refreshData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                function refreshData() {
                    scope.seriesData = [];
                    scope.yAxisPlotLines = [];
                    scope.xAxisPlotLines = [];
                    if (scope.dataSource) {
                        getValuesForSerie(scope.dataSource);
                    }
                }

                function setupMinMax() {
                    scope.yAxisPlotLines = [];
                    var elementMin =
                        {
                            dashStyle: 'ShortDash',
                            label: {
                                text: $translate.instant('DASHBOARD_MIN') 
                            },
                            value: scope.min,
                            width: 2,
                            index: 1,
                            color: 'black',

                        };
                        var elementMax =
                        {
                            dashStyle: 'ShortDash',
                            label: {
                                text: $translate.instant('DASHBOARD_MAX') 
                            },
                            value: scope.max,
                            width: 2,
                            index: 1,
                            color: 'black',

                        };
                        scope.yAxisPlotLines.push(elementMin);
                        scope.yAxisPlotLines.push(elementMax);
                }

                function setupChartData(values) {
                    scope.xAxisData = [];

                    if (values) {
                        for (var i = 0; i < values.length; i++) {
                            var xAxisDataLabel = "";
                            // Assume every groupingElement has data for every bar
                            if(selectedGrouping == "day" || selectedGrouping == "none"){
                                xAxisDataLabel = moment(values[i].valueDate).format("D.MMM");
                            } else if(selectedGrouping == "week"){
                                xAxisDataLabel = $translate.instant('COMMON_TIMEPERIOD_WEEK_PREFIX') + values[i].week;
                            } else {
                            
                                if (values[i].month) {
                                    xAxisDataLabel = values[i].month + ".";
                                }
                                xAxisDataLabel = xAxisDataLabel + values[i].year;
                            }
                            scope.xAxisData.push(xAxisDataLabel);
                        }
                    } else {
                        // alert("No values for defined component");
                    }

                    setupMinMax();
                    var redcolor = DigiLean.target.getTargetIndicatorColor("red");
                    var greencolor = DigiLean.target.getTargetIndicatorColor("green");
                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                    
                        var element = {
                            x: i,
                            color:'black',
                            y: values[i].value
                        };
                        data.push(element);
                    }

                    scope.seriesData = [{
                        data: data,
                        id: "datavalues",
                        type: 'spline',
                        lineWidth: 4,
                        name: scope.dataSource.title,
                        dataGrouping: getDataGrouping("sum")
                    },
                    {
                        type: 'spline',      
                        linkedTo: 'datavalues',
                        data: data,
                        order:1,
                        zoneAxis: 'y',
                        lineWidth: 4,
                        name: scope.dataSource.title,
                        zones: [{
                            color: redcolor,
                            value: scope.min
                        }, {
                            color: greencolor,
                            value: scope.max
                        }, {
                            color: redcolor
                        }]
                    }];

                }

                function setDefaultGroupingInterval(datasource){
                    if(!selectedGrouping){
                        selectedGrouping = "month";
                        if(datasource && datasource.unitOfTime){
                            selectedGrouping = datasource.unitOfTime.toLowerCase();
                        }
                    }
                }

                function getDataGrouping(approximation) {
                    switch (selectedGrouping) {
                        case "day":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['day', [1]]]
                            };
                            return data;
                        case "week":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['week', [1]]]
                            };
                            return data;
                        case "month":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['month', [1]]]
                            };
                            return data;
                        case "year":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['year', null]]
                            };
                            return data;
                        default: // e.g. none
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['day', [1]]]
                            };
                            return data;
                    }
                }

                function getValuesForSerie(datasource) {
                    if (!scope.timePeriod) return;

                    if(selectedGrouping == "none"){
                        getValuesForNoneGrouping(datasource);
                    } else {
                        setDefaultGroupingInterval(datasource);
                   
                        var options = {
                            groupByInterval: selectedGrouping,
                            timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                            timePeriod: scope.timePeriod.period,
                            filters: scope.filters,
                        };
    
                        dataService().getTargetValuesForPeriod(datasource.id, options).then(function (values) {
                            if (values) {
                                if(scope.dataSource.objectSource =='internal'){
                                    for(var v=0; v<values.length; v++) {
                                        var options = {
                                            timePeriod: scope.options.timePeriod.period,
                                            value: values[v],
                                            valueUnit: datasource.valueUnit,
                                            filters: scope.filters,
                                            decimals : scope.decimals,
                                            unitOfTime: datasource.unitOfTime
                                        };
                                    }
                                }
                                setupChartData(values);
                                drawChart();
                            }
                        });
                    }                                          
                }

                function getValuesForNoneGrouping(datasource){
                    var options = {
                        timePeriod: scope.timePeriod.period,
                        filters: scope.filters
                    };
                    dataService().getValuesForPeriod(datasource.id, options).then(function (values) {
                        if (values) {
                            setupNoneGroupingChartData(values);
                            drawNoneGroupedChart();
                        }
                    });
                }

                function setupNoneGroupingChartData(values) {
                    //var label = dataSerie.name;
                    var data = [];
                    setupMinMax();
                    var redcolor = DigiLean.target.getTargetIndicatorColor("red");
                    var greencolor = DigiLean.target.getTargetIndicatorColor("green");

                    angular.forEach(values, function (dataValue) {
                        // Format date to HighCharts format
                        var valueDate = moment(dataValue.valueDate).valueOf();
                        data.push([valueDate, dataValue.value]);
                    });

                    scope.seriesData = [{
                        findNearestPointBy: 'xy',
                        data: data,
                        id: "datavalues",
                        type: 'spline',
                        lineWidth: 4,
                        color: 'black',
                        name: scope.dataSource.title,
                    },
                    {
                        type: 'spline',      
                        linkedTo: 'datavalues',
                        data: data,
                        order:1,
                        zoneAxis: 'y',
                        lineWidth: 4,
                        name: scope.dataSource.title,
                        zones: [{
                            color: redcolor,
                            value: scope.min
                        }, {
                            color: greencolor,
                            value: scope.max
                        }, {
                            color: redcolor
                        }]
                    }];
                }

                scope.selectConfiguration = function () {
                    setDefaultGroupingInterval(scope.datasource);
                    var config = {
                        selectedGrouping: selectedGrouping,
                        simple: true,
                        minY: minY,
                        maxY: maxY
                    }
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'graphConfiguration.html',
                        controller: 'graphConfigurationController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            configuration: function () {
                                return config;
                            }
                        }
                    });

                    modalInstance.result.then(function (configuration) {
                        selectedGrouping = configuration.selectedGrouping;
                        setTimeSettings(selectedGrouping, "update");
                        minY = configuration.minY;
                        maxY = configuration.maxY;
                        scope.updateSettings();
                        refreshData();
                    }, function(res){});
                };

                function setTimeSettings(grouping, process){
                    if(grouping.toLowerCase() == "day"){
                        setDayTimeSettings(process);
                    } else if(grouping.toLowerCase() == "week"){
                        setWeekTimeSettings(process);
                    } 
                }

                let currentChart = null;
                function drawChart() {

                    var renderElement = $(elem).find(".minmaxchart")[0];
                    currentChart = new Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'spline',
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        scrollbar: {
                            enabled: false
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            categories: scope.xAxisData,
                            labels: {
                            style: {
                                fontSize: "0.9em"
                            }
                        }
                        },
                        yAxis: {
                            //min: 0, //if min:null then the data decide start of yaxis
                            min: minY,
                            max: maxY,
                            title: {
                                text: scope.dataSource.valueElement.unit
                            },
                            plotLines: scope.yAxisPlotLines,
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        tooltip: {
                            valueDecimals: scope.decimals
                        },
                        legend: {
                            enabled: false    
                        },
                        series: scope.seriesData

                    });
                    resizeChart();
                }

                function drawNoneGroupedChart() {

                    var renderElement = $(elem).find(".minmaxchart")[0];
                    currentChart = new Highcharts.chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'spline'
                        },
                        scrollbar: {
                            enabled: false
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            type: 'datetime',
                            plotLines: scope.xAxisPlotLines,
                        },
                        yAxis: {
                            min: minY,
                            max: maxY,
                            title: {
                                text: scope.dataSource.valueElement.unit
                            },
                            plotLines: scope.yAxisPlotLines
                        },
                        legend: {
                            enabled: false    
                        },
                        plotOptions: {
                            line: {
                                dataLabels: {
                                    enabled: true
                                },
                                enableMouseTracking: false
                            },
                        },
                        series: scope.seriesData

                    });
                    resizeChart();
                }

                scope.registerData = function () {
                    var settings = {
                        dataSource: scope.dataSource
                    };   
                
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'registrationDialog.html',
                        controller: 'registrationDialogController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            componentSettings: function () {
                                return settings;
                            }
                        }
                    });
                    modalInstance.result.then(function (result) {      
                    });
                };

                let selectDataSourceModal
                scope.selectDataSource = function () {
                    if (selectDataSourceModal)
                        return

                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return false;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return false;
                            }
                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        scope.filters = result.filters;
                        scope.options.unitOfTime = scope.dataSource.unitOfTime;
                        setDefaultGroupingInterval(scope.dataSource);
                        setTimeSettings(selectedGrouping, "initial");        
                        scope.updateSettings();
                        refreshData();
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                };

                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight
                    
                    var chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (currentChart)
                        currentChart.setSize(width, height, true)
                }

                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

            }
        }
    }]);
