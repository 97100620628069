import { NgComponent } from "@common/model/angularModel"
import angular from "angular"
const DigiLean = angular.module("DigiLean")
const html = String.raw
DigiLean.component("digileanDiagram", 
{
    bindings: {
        'isEditPossible': '<',
        'title': '<',
        'svgInput': '<',
        'svgHeight': '<',
        'svgWidth': '<'
    },
    template: html`
        <div id="digilean-diagram" 
            class="digilean-diagram" 
            ng-class="{'edit-possible': $ctrl.isEditPossible}"
            ng-click="$ctrl.edit($event)">
        </div>
    `,
    controller: ['$element', '$uibModal', function(this: NgComponent, $element, $uibModal) {
        const $ctrl = this
        
        $ctrl.svg = ""

        let divEl: HTMLDivElement
        let data = ""
    
        const setSvgImage = (svg: string) => {
            $ctrl.svg = svg
            if (divEl) {
                divEl.innerHTML = svg
                let svgEl = divEl.querySelector("svg")
                if (svgEl) {
                    data = svgEl.getAttribute("content") as string
                    svgEl.removeAttribute("content")
                    svgEl.removeAttribute("width")
                    svgEl.removeAttribute("height")
                }
            }
        }
    
        // $ctrl.$onInit = function() {}

        function dispatchSvgChangedEvent() {
            const options = {
                detail: $ctrl.svg,
                bubbles: true,
                composed: true
            }
            $element[0].dispatchEvent(new CustomEvent("svg-changed", options))
        }
        
        const openEditDialog = function () {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'DiagramEditorFullscreen.html',
                controller: 'DiagramEditorFullscreenController',
                windowClass: "digilean-diagram-edit",
                resolve: {
                    data: () => data,
                    title: () => $ctrl.title,
                    showTemplates: () => $ctrl.showTemplates
                }
            })
            modalInstance.result.then(function (newSvg) {
                $ctrl.showTemplates = false
                // empty on discard
                if (newSvg) {
                    setSvgImage(newSvg)
                    dispatchSvgChangedEvent()
                }

            }, function (er) {
                console.log(er)
            })
        }

        
        $ctrl.edit = function(event) {
            event.preventDefault()
            event.stopPropagation()
            if ($ctrl.isEditPossible)
                openEditDialog()
        }

        const openTemplateDialog = function () {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'DiagramTemplateSelector.html',
                controller: 'DiagramTemplateSelectorController',
                windowClass: "digilean-diagram-template-selector",
            })
            modalInstance.result.then(function (res) {
                setSvgImage(res.templateSvg)
                dispatchSvgChangedEvent()
                if (res.name == "Blank")
                    $ctrl.showTemplates = true
                openEditDialog()

            }, (er) => {
                console.log(er)
            })
        }

        $ctrl.$postLink = function() {
            let el = $element[0]
            el.triggerTemplate = openTemplateDialog // expose on element
            el.triggerEdit = openEditDialog // expose on element
            divEl = el.querySelector("#digilean-diagram")
            if ($ctrl.svgInput) 
                setSvgImage($ctrl.svgInput)
            else {
                setTimeout(() => {
                    openTemplateDialog()
                }, 500)
            }
            setHeightAndWidth()
        }

        function setHeightAndWidth() {
            console.log("svgHeight", $ctrl.svgHeight)
            console.log("svgWidth", $ctrl.svgWidth)
            if (!divEl) return

            if ($ctrl.svgHeight)
                divEl.style.setProperty("height", `calc(${$ctrl.svgHeight}px - 1em)`)
            
            if ($ctrl.svgWidth)
                divEl.style.setProperty("width", `calc(${$ctrl.svgWidth}px - 1em)`)
        }
        $ctrl.$onChanges = function (changes) {
            if (changes.svgHeight && changes.svgHeight.currentValue) {
                setHeightAndWidth()
            }
        }
    }]
})