angular
    .module('DigiLean')
    .directive("rangeImprovement", ['$filter', 'suggestionService','authService',
        function ($filter, suggestionService, authService) {
            return {
                templateUrl: 'rangeImprovement.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {
                    // Get data from previous period 
                    var previousperiod = {
                        from: moment().add(-60, 'days').toDate().toJSON(),
                        to: moment().add(-30, 'days').toDate().toJSON()
                    };
                    scope.previousRankingData = [];

                    // Get data for current period
                    var period = {
                        from: moment().add(-30, 'days').toDate().toJSON(),
                        to: moment().add(1, 'days').toDate().toJSON()
                    };
                    scope.rankingData = [];
                    suggestionService().getImplementedSuggestionByAsset(period).then(function (data) {
                        scope.rankingData = data;
                        suggestionService().getImplementedSuggestionByAsset(previousperiod).then(function (prevdata) {
                            scope.previousRankingData = prevdata;
                            setTrend();
                        });               
                    });

                    function setTrend() {
                        for (var i = 0; i < scope.rankingData.length; i++) {
                            scope.rankingData[i].trend = 1;
                            for (var j = 0; j < scope.previousRankingData.length; j++) {
                                if (scope.rankingData[i].assetName === scope.previousRankingData[j].assetName){
                                    scope.rankingData[i].trend = j - i;
                                }
                            }  
                        }      
                    }

                }
            }
        }]);
