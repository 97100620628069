import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("projectMilestoneCell", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'projectMilestoneCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.milestoneMissed = false;
                    var createNew = false;
                    scope.milestone = null;
                    scope.value = null;
                    scope.changeColor = false,
                    scope.changeColorXDaysBefore = 1;

                    scope.opened = false;

                    scope.update = function (newValue) {
                        if (scope.milestone && !createNew) {
                            scope.value = newValue;
                            scope.saveChanges();
                        } else {
                            createNew();
                        }
                    }

                    scope.$watch("model", function (model) {
                        var value = model.projectAttribute.value;
                        var settings = model.column.settings;
                        if (value) {
                            setValue(value);
                        } else {
                            createTempMilestone();
                        }
                        if (settings) {
                            if(settings.changeColor && settings.changeColorXDaysBefore){
                                scope.changeColor = true;
                                scope.changeColorXDaysBefore = settings.changeColorXDaysBefore;
                            }
                        }
                        model.getSortValue = function () {
                            if (scope.milestone) {
                                if(scope.milestone.actualDate){
                                    return scope.milestone.actualDate;
                                } else {
                                    return scope.milestone.plannedDate;
                                }     
                            } else {
                                if(scope.model.project && scope.model.project.milestones && scope.milestoneId){
                                    var foundMilestone = $filter('filter')(scope.model.project.milestones, { id: scope.milestoneId });
                                    if (foundMilestone.length > 0) {
                                        if(foundMilestone[0].actualDate){
                                            return moment(foundMilestone[0].actualDate).toDate();
                                        } else if(foundMilestone[0].plannedDate){
                                            return moment(foundMilestone[0].plannedDate).toDate();
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return null;
                                }
                            }
                        }
                    });

                    scope.subscribe("ProjectDateColorCellUpdated", function (value) {
                        if(scope.model.boardAttributeId === value.projectAttributeId){
                            scope.changeColor = value.changeColor;
                            scope.changeColorXDaysBefore = value.changeColorXDaysBefore;
                        }
                    });

                    var setValue = function (value) {

                        //scope.value = moment(value.value).toDate();
                        scope.milestoneId = value.milestoneId;
                        projectService().getMilestone(scope.milestoneId).then(function (milestone) {

                            if (milestone) {
                                scope.milestone = milestone;
                                if (scope.milestone.plannedDate) {
                                    scope.milestone.plannedDate = moment(milestone.plannedDate).toDate();
                                }
                                if (scope.milestone.actualDate) {
                                    scope.milestone.actualDate = moment(milestone.actualDate).toDate();
                                }

                                scope.milestone.datePicker = {
                                    opened: false
                                },
                                scope.milestone.estimatedDatePicker = {
                                    opened: false
                                }

                                checkMilestone();
                            } else {
                                createTempMilestone();
                            }
                        });
                    }

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });

                    scope.subscribe("ProjectMilestoneUpdated", function (milestone) {
                        if (milestone.id == scope.milestoneId) {
                            if (milestone.plannedDate) {
                                scope.milestone.plannedDate = moment(milestone.plannedDate).toDate();
                            }
                            if (milestone.actualDate) {
                                scope.milestone.actualDate = moment(milestone.actualDate).toDate();
                            }
                            scope.milestone.datePicker = {
                                opened: false
                            }, scope.milestone.estimatedDatePicker = {
                                opened: false
                            };
                            checkMilestone();
                        }
                    });

                    scope.subscribe("ProjectBoardAttributeUpdated", function (boardattribute) {
                        if (boardattribute.id === scope.model.boardAttributeId) {
                            if (scope.milestone.name !== boardattribute.name) {
                                scope.milestone.name = boardattribute.name;
                            }
                        }
                    });

                    function createTempMilestone() {
                        createNew = true;
                        scope.milestone = {
                            name: scope.model.column.name,//$translate.instant('PROJECT_MILESTONE'),
                            projectId: scope.model.project.id,
                            plannedDate: null,
                            completedDate: null,
                            datePicker : {
                                opened: false
                            },
                            estimatedDatePicker : {
                                opened: false
                            }
                        };
                    }

                    scope.savePlannedDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.milestone.plannedDate = date
                        scope.saveChanges()
                    }
                    scope.saveActualDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.milestone.actualDate = date
                        scope.saveChanges()
                    }
                    scope.saveChanges = function () {
                        if (scope.model.column.name) {
                            scope.milestone.name = scope.model.column.name;
                        }
                        if (!createNew) {
                            projectService().updateMilestone(scope.milestone).then(function (milestone) {
                                checkMilestone();
                                saveCellAttributes();
                            });
                        } else {
                            createNewMilestone();
                        }

                    }

                    function createNewMilestone() {
                        projectService().createMilestone(scope.milestone).then(function (milestone) {
                            scope.milestone = milestone;
                            scope.milestone.datePicker = {
                                opened: false
                            }, scope.milestone.estimatedDatePicker = {
                                opened: false
                            };
                            createNew = false;
                            if (milestone.actualDate) {
                                scope.milestone.actualDate = moment(milestone.actualDate).toDate();
                            }
                            if (milestone.plannedDate) {
                                scope.milestone.plannedDate = moment(milestone.plannedDate).toDate();
                            }
                            saveCellAttributes();
                            checkMilestone();
                        });
                    }

                    function saveCellAttributes() {
                        var value = {
                            milestoneId: scope.milestone.id
                        }
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({ projectAttribute: scope.model.projectAttribute })
                    }

                    function checkMilestone() {
                        if (scope.milestone) {
                            scope.milestone.actualDate = timeService.validateDate(scope.milestone.actualDate);
                            scope.milestone.plannedDate = timeService.validateDate(scope.milestone.plannedDate);
                            scope.milestoneMissed = false;
                            if (scope.milestone.actualDate) {
                                scope.milestone.status = "completed";
                                var diff = moment.duration(moment(scope.milestone.actualDate).diff(moment(scope.milestone.plannedDate)));//moment.duration(moment(scope.milestone.actualDate).diff(moment(scope.milestone.plannedDate)));
                                if (!isNaN(diff)) {
                                    scope.milestone.diffPlannedVSActual = diff.asDays();
                                } else {
                                    scope.milestone.diffPlannedVSActual = null;
                                }
                            } else if (scope.milestone.plannedDate) {
                                //check if overdue/missed

                                var duration = moment.duration(moment().startOf('day').diff(moment(scope.milestone.plannedDate)));
                                var days = duration.asDays();
                                if (days >= 1) {
                                    scope.milestone.status = "missed";
                                    scope.milestone.diffToPlannedDate = Math.trunc(days);
                                    scope.milestoneMissed = true;
                                } else {
                                    scope.milestone.status = "future";
                                    scope.milestone.diffToPlannedDate = Math.round(days) * -1;
                                }
                            }
                        }
                    }
                }
            }
        }]);
