
angular.module("DigiLean")
    .directive('threeCDateCell', ['$rootScope',
        function ($rootScope) {
            return {
                templateUrl: "threeCDateCell.html",
                restrict: "E",
                scope: {
                    'date': '<',
                    'dateChangedHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    
                    scope.cssClass = "";
                    scope.dateChanged = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.date = date
                        scope.cssClass = "animated flash";
                        setTimeout(() => {
                            scope.cssClass = "";
                        },2000);
                        scope.dateChangedHandler({ date: date });
                    }

                }
            }
        }]);