
import lottie from "lottie-web";
angular
    .module('DigiLean')
    .directive('animationTaskStatus', ['$rootScope', 'boardTaskService', 'taskDrawingService','debounce',
        function ($rootScope, boardTaskService, taskDrawingService, debounce) {
            return {
                templateUrl: 'animationTaskStatus.html',
                restrict: 'E',
                scope: {
                    "task": "<",
                    'readonly': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.$watch('task', function (task) {
                        if (!task) return;
                        checkCompletedStatus();
                    });
                    scope.$on('$destroy', function () {
                        if (completeAnimation) {
                            completeAnimation.destroy(completeAnimationName);
                        }
                    });
                    var statusChangeDebounced = debounce(commitStatusChanged, 1000, false);

                    scope.changeStatus = function (task) {
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        if (task.status == "OK") {
                            showCompleteAnimation();
                        }
                        var statusOptions = {
                            taskId: task.id,
                            status: task.status
                        };
                        statusChangeDebounced(task, statusOptions);
                    }

                    

                    function checkCompletedStatus() {
                        if (scope.task.status == "OK") {
                            setTimeout(function() {
                                showCompleteAnimation();
                            }, 100);
                        } else {
                            if (completeAnimation) {
                                completeAnimation.stop(completeAnimationName);
                            }
                        }
                                

                    }

                    var completeAnimation;
                    var completeAnimationName = "ok-animation";
                    function showCompleteAnimation() {
                        if (completeAnimation) {
                            completeAnimation.play();
                            return;
                        }
                        var okContainer = $(elem).find(".ok-animation")[0]; // the dom element that will contain the animation
                        completeAnimation = lottie.loadAnimation({
                            container: okContainer,
                            renderer: 'svg',
                            name: completeAnimationName,
                            loop: false,
                            autoplay: true,
                            path: 'https://assets3.lottiefiles.com/packages/lf20_sbJCHH.json' // the path to the animation json
                        });

                        okContainer.addEventListener('click', function () {
                            if(!scope.readonly){
                            completeAnimation.stop(completeAnimationName);
                            
                            scope.changeStatus(scope.task);
                        }
                        });
                    }

                    function commitStatusChanged(task, statusOptions) {
                        boardTaskService().updateStatus(task.id, statusOptions).then(function (result) {

                        });
                    }

                    $rootScope.subscribe('TaskStatusUpdated', function (status) {
                        updateTaskStatus(status.taskId, status.status, "BoardTask");
                    });

                    function updateTaskStatus(id, status, entityType) {
                        if (!entityType) entityType = "BoardTask";
                        if (scope.task.id != id) return;

                        scope.task.status = status;
                        checkCompletedStatus();

                    }
                }
            }
        }]);
