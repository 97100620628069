﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('fullscreenWidgetController', ['$scope','$uibModalInstance', 'options',
    function ($scope, $uibModalInstance, options) {
     
        $scope.widget = options.widget;
        $scope.assetId = options.assetId;
        $scope.title = options.title;
        $scope.subTitle = options.subTitle;
        $scope.ok = function () {
            $uibModalInstance.close($scope.selectedApp);

        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);