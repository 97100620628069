import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("yearlyBoardCard", 
        function () {
            return {
                templateUrl: 'yearlyBoardCard.html',
                restrict: 'E',
                scope: {
                    "board" : "<",
                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = true;
                    scope.$watch('board', function (board) {
                        if (!board) return;
                        scope.isLoading = false;
                    });

                    scope.url = staticFileService.image("timeboard.jpg");


                }
            };
        });
