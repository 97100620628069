import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .controller("suggestionListModalController", ['$scope', '$filter', '$uibModalInstance', '$uibModal', 'suggestionService', 'authService', 'options',
        function ($scope, $filter, $uibModalInstance, $uibModal, suggestionService, authService, options) {

            $scope.options = options;
            $scope.deviations = [];
            $scope.isAdmin = authService.hasRole("Admin") ||authService.hasRole("ImprovementAdmin");

            suggestionService().getSuggestionsByFilter($scope.options).then(function (suggestions) {
                $scope.suggestions = suggestions;  
                setDateStyleToSuggestions($scope.suggestions);
                assetService.getAllAssets().then(function (assets) {
                    $scope.assets = assets;
                    assignAssetNameToSuggestions(suggestions);
                    suggestionService().getCategories().then(function (items) {
                        $scope.categories = items;
                        assignCategoryNameToSuggestions(suggestions);
                    });
                    /*
                    for (var i = 0; i < $scope.suggestions.length; i++) {
                        
                        var a = $filter('filter')($scope.assets, { id: $scope.suggestions[i].responsibleUserId }, true);
                        if (a && a.length > 0) {
                            $scope.suggestions[i].responsibleUserId = a[0].name;
                        }
                    }*/
                
                });   
            });

            function assignAssetNameToSuggestions(suggestions) {
                suggestions.forEach(function (suggestion) {
                    if (suggestion.assetId) {
                        var asset = $filter('filter')($scope.assets, { id: suggestion.assetId }, true);
                        if (asset.length === 1) {
                            suggestion.assetName = asset[0].name;
                        }
                    }
                });
            }
            function assignCategoryNameToSuggestions(suggestions) {
                suggestions.forEach(function (suggestion) {
                    if (suggestion.categoryId) {
                        var category = $filter('filter')($scope.categories, { id: suggestion.categoryId }, true);
                        if (category.length === 1) {
                            suggestion.category = category[0].name;
                        }
                    }
                });
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            $scope.openSuggestion = function (suggestion) {
                suggestionService().get(suggestion.id).then(function (suggestion) {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'fullImprovementInfoForm.html',
                        controller: 'fullImprovementModalController',
                        windowClass: 'fullSuggestion-modal-window',
                        resolve: {
                            suggestion: function () {
                                return suggestion;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                });
            };

            function setDateStyleToSuggestions(suggestions){
                suggestions.forEach(function (suggestion) {
                    if(suggestion.status === "Suggested" || suggestion.status === "Planned" || suggestion.status === "InProgress"){
                        suggestion.dateStyleClass = getDateStyle(suggestion.dueDate);
                    }
                });
            }

            function getDateStyle(date) {
                if (!date) return "";
                // Overdue check

                var overdue = "";
                var duration = moment.duration(moment().diff(moment(date)));
                var days = duration.asDays();
                if (days > 1) {
                    overdue = "overdue";
                }
                return overdue;
            }
        }]);