
angular.module("DigiLean")
    .directive('zoomSelector', ['$filter',
        function ($filter) {
            return {
                templateUrl: "zoomSelector.html",
                restrict: "E",
                scope: {
                    'zoom': '<',
                    'zoomChangedHandler': '&',
                    'zoomOptions': '<'
                },
                link: function (scope, elem, attrs) {

                    scope.zoomMax = 2
                    scope.zoomMin = 0.25

                    scope.zoomLevels = [0.5, 0.75, 1, 1.25]

                    let internalZoomLevels = []
                    function calcInternal() {
                        internalZoomLevels = [scope.zoomMin]
                        step = 0.1
                        let level = internalZoomLevels[0]
                        while(level < scope.zoomMax) {
                            level = parseFloat((level + step).toFixed(1))
                            if (level < scope.zoomMax)
                                internalZoomLevels.push(level)
                            else
                                internalZoomLevels.push(scope.zoomMax)
                        }
                    }
                    
                    calcInternal()
                    scope.$watch('zoomOptions', function (options) {
                        if (options) {
                            if (options.zoomLevels) {
                                scope.zoomLevels = options.zoomLevels
                            }
                            if (options.zoomMax)
                                scope.zoomMax = options.zoomMax
                            if (options.zoomMin)
                                scope.zoomMin = options.zoomMin
                            
                            calcInternal()
                        }
                    })
                    scope.setZoom = function(zoom) {
                        scope.zoom = zoom
                        scope.zoomChanged()
                    }
                    scope.zoomOut = function($event) {
                        $event.stopPropagation()
                        const index = getIndex()
                        if (index === -1) return

                        if ((index) > 0) {
                            scope.zoom = internalZoomLevels[index - 1]
                        } else {
                            scope.zoom  = internalZoomLevels[index]
                        }
                        scope.zoomChanged()
                    }
                    scope.zoomIn = function($event) {
                        $event.stopPropagation()
                        const index = getIndex()
                        if (index === -1) return
                        

                        if ((index + 1) < internalZoomLevels.length) {
                            scope.zoom = internalZoomLevels[index + 1]
                        } else {
                            scope.zoom = internalZoomLevels[index]
                        }
                        scope.zoomChanged()
                    }
                    
                    scope.zoomChanged = function () {
                        scope.zoomChangedHandler({ zoom: scope.zoom })
                    }
                    
                    function getIndex() {
                        let index = internalZoomLevels.indexOf(scope.zoom)
                        if (index == -1) {
                            // try get closest
                            let zoomRounded = parseFloat(scope.zoom.toFixed(1))
                            index = internalZoomLevels.indexOf(zoomRounded)
                        }
                        return index
                    }
                }
            }
        }])