﻿angular
    .module('DigiLean')
    .directive("a3step", ['a3Service', 'debounce',
        function ( a3Service, debounce) {
            return {
                templateUrl: 'a3step.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'model': '=',
                    'isAdminMode': '=',
                },
                link: function (scope) {
                  
                    scope.$watch('model', function () {
                        var model = scope.model;
                        scope.stepContent = scope.model.content;
                        if (model.style && typeof (model.style) === "string") {
                            model.style = JSON.parse(model.style);
                        }

                    });

                    scope.froalaInit = false;

                 

                    scope.commitChanges = function (id, content) {
                        var stepContent = {
                            id: id,
                            content: content
                        };
                        a3Service().saveStep(stepContent).then(function (step) {
                            // Synk version step
                        });
                    };

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.commitChanges, 2000, false);
                    scope.contentChanged = function(val) {
                        if (!scope.froalaInit) {
                            scope.froalaInit = true;
                            return;
                        }
                        scope.model.content = val;
                        scope.commitChangesDebounced(scope.model.id, val);
                    };
                    scope.froalaOptions = {
                        toolbarButtons: ['bold', 'italic', 'underline', '|', 'fontFamily', 'fontSize', 'color',
                            'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink',
                            'insertImage', 'insertVideo', 'insertTable', 'insertDiagram', '|', 'insertHR', 'clearFormatting',
                            '|', 'help', '|', 'undo', 'redo'],
                        imageEditButtons: ['editDiagram', 'imageReplace', 'imageAlign', 'imageCaption', 'imageRemove']
                    };
                }
            };
        }]);
