
var DigiLean = angular.module('DigiLean');
DigiLean.directive("myCompetenceBalls", ['$filter', '$translate', 'authService', 'navigationService', 'dataSourceService', 'dataService', 'dataListService','debounce',
    function ($filter, $translate, authService,navigationService, dataSourceService, dataService, dataListService,debounce) {
        return {
            templateUrl: 'myCompetenceBalls.html',
            restrict: 'E',
            $scope: {
            },
            link: function ($scope, elem, attrs) {

                
                var maxSize = 1;
                var userid = authService.getCurrentUser().userId;

                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        $scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                $scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                $scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);


                /* This method will be called also when component is first initialized */
                $scope.periodChangedHandler = function (timePeriod) {

                    if (!timePeriod.timeframe) return;
                    if ($scope.timePeriod && $scope.timePeriod.period.from === timePeriod.period.from && $scope.timePeriod.period.to === timePeriod.period.to) return;
                    $scope.timePeriod = timePeriod;
                    $scope.options.timePeriod = timePeriod;
                    if (timePeriod.timeframe === "all" && dataSourceId) {
                        dataService().getFirstDate(dataSourceId, $scope.timePeriod.period, null).then(function (fromDate) {
                            $scope.timePeriod.period.from = fromDate;
                            load();
                        });
                    } else {
                        load();
                    }

                }
                var totalSerie = [];
                var categories = []; 
                $scope.seriesData = [];

                var splitPackedBubbleChart = null;
                $scope.isLoading = true;

                var dataSourceId = 0;
                var listOptions = { includeDeletedItems: true };

                $scope.hasCompetanceBallModule = navigationService().hasModule("COMPETENCEBALL");
                $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                    $scope.hasCompetanceBallModule = navigationService().hasModule("COMPETENCEBALL");
                    userid = authService.getCurrentUser().userId;
                    init();
                });

                function init(){
                    if($scope.hasCompetanceBallModule){
                        dataListService().getFeatureList("CompetenceBall", listOptions).then(function (list) {
                            if (list) {
                                categories = list.items;
                            }
                        });
                        dataSourceService().getFeatureSource("CompetenceBall").then(function (source) {
                            if (source) {
                                dataSourceId = source.id;
                                load();
                            }
                        })
                    }
                }
                init();

                $scope.subscribe("NewCompetenceBall", function (competenceball) {
                    if (competenceball.createdByUserId == userid) {
                        load();
                    }
                });
                $scope.subscribe("DataValueAdded", function (value) {
                    if (dataSourceId == value.dataSourceId && value.dimension2 == userid) {
                        load();
                    }
                });
                $scope.subscribe("DataValueUpdated", function (value) {
                    if (dataSourceId == value.dataSourceId && value.dimension2 == userid) {
                        load();
                    }
                });
                $scope.subscribe("DataValueDeleted", function (value) {
                    if (dataSourceId == value.dataSourceId) {
                        load();
                    }
                });

                var paddingRight = 30;
                var paddingBottom = 100;
                function resizeChart() {
                    var host = $(elem).closest(".grid-stack-item-content");
                    if (host.length == 0)
                        host = $(elem).closest(".modal-body")
                    
                    if (host.length == 0) return;
                    var width = host.width();
                    var height = width;
                    if (splitPackedBubbleChart)
                        splitPackedBubbleChart.setSize(width - paddingRight, height - paddingBottom, true);
                }
                $scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });


                function unique(arr, prop) {
                    return arr.map(function (e) { return e[prop]; }).filter(function (e, i, a) {
                        return i === a.indexOf(e);
                    });
                }

                var totalSerie = [];

                function getCompetenceCategoryName(catid) {
                    var cat = $filter('filter')(categories, { id: parseInt(catid) }, true);
                    if (cat.length > 0) {
                        return cat[0].name;
                    } else {
                        return $translate.instant('COMMON_UNDEFINED');
                    }
                }
                function load() {
                    if(dataSourceId == 0) init();
                    if(userid && $scope.hasCompetanceBallModule){
                        $scope.isLoading = true;
                        var competenceballs = [];


                        $scope.seriesData = [];
                        


                        var filter = [{sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]}];
                        var options = {
                            timePeriod: $scope.timePeriod.period,
                            filters: filter
                        };
                        dataService().getValuesForPeriod(dataSourceId, options).then(function (competenceballs) {

                            if (competenceballs) {
                                totalSerie = [];
                                var registeredCategories = unique(competenceballs, 'dimension');
                                for (var i = 0; i < registeredCategories.length; i++) {
                                    var cat = registeredCategories[i];
                                    var dataValues = [];
                                    var elementsWithGivenCategory = $filter('filter')(competenceballs, { dimension: cat }, true);
                                    if (elementsWithGivenCategory.length > 0) {
                                        for (var y = 0; y < elementsWithGivenCategory.length; y++) {

                                            var label = cat;
                                            var miniboble = {
                                                name: "",
                                                value: elementsWithGivenCategory[y].value,
                                                description: elementsWithGivenCategory[y].description
                                            }
                                            dataValues.push(miniboble);
                                            if (totalSerie[i]) {
                                                totalSerie[i] = totalSerie[i] + elementsWithGivenCategory[y].value;
                                            } else {

                                                totalSerie[i] = elementsWithGivenCategory[y].value;
                                            }
                                        }
                                    }
                                    var element = {
                                        name: getCompetenceCategoryName(cat),
                                        data: dataValues
                                    };
                                    $scope.seriesData.push(element);
                                }
                            }
                            maxSize = Math.max.apply(null, totalSerie) * 1.5;
                            $scope.isLoading = false;
                            setupChart();
                        });
                    }
                }
 

                function setupChart() {

                    var renderElement = $(elem).find(".my-split-packed-bubble-chart")[0];
                    splitPackedBubbleChart = new Highcharts.Chart({
                        chart: {
                            renderTo: renderElement,
                            type: 'packedbubble',
                            height: '100%'
                        },
                        title: {
                            text: ''
                        }, credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        tooltip: {
                            useHTML: true,
                            pointFormat: '<b>{point.description}:</b> {point.value} <br>' 
                        },
                        plotOptions: {
                            packedbubble: {
                                minSize: '20%',
                                maxSize: '100%',
                                zMin: 0,
                                zMax: 2,
                                layoutAlgorithm: {
                                    splitSeries: false,
                                    gravitationalConstant: 0.02,
                                    bubblePadding: 10
                                },
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}',
                                    style: {
                                        color: 'black',
                                        textOutline: 'none',
                                        fontWeight: 'normal'
                                    }
                                }
                            }
                        },
                        series: $scope.seriesData
                    });

                }
            }

        }
    }]);