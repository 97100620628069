angular
    .module('DigiLean')
    .directive("personalTaskCard", ['$filter', '$uibModal', 'taskDrawingService', 'boardTaskService',
        function ($filter, $uibModal, taskDrawingService, boardTaskService) {
            return {
                templateUrl: 'personalTaskCard.html',
                restrict: 'E',
                scope: {
                    'task': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.postIts = taskDrawingService().getPostIts();
                    scope.$watch("task", function (task) {
                        if (!task) return;
                        setColor(task.color);
                        // task.displayColor = "silver";
                    });
                    var setColor = function (taskColor) {
                        // Try to match fillColor
                        var predefinedPostIt = $filter('filter')(scope.postIts, { fillColor: taskColor });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                            setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                        } else {
                            // Try to match background color
                            var backgroundPostIt = $filter('filter')(scope.postIts, { background: taskColor });
                            if (backgroundPostIt.length > 0) {
                                scope.selectedPostIt = backgroundPostIt[0];
                                setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                            } else {
                                setStyle(scope.fillColor, scope.borderColor);
                            }
                        }
                    }

                    scope.hasDueDate = function (item) {
                        if (!item) return;
                        if (item.boardDate) {
                            return true;
                        } else return false;
                    };

                    var setStyle = function (fillColor, borderColor) {

                        if (fillColor == "#ffffff") {
                            borderColor = scope.borderColor;
                        }

                        scope.task.displayColor = borderColor;
                    }

                    //$scope.changePriorityStatusDebounced = debounce(changePriorityStatus, 2000, false);
                    scope.changePriorityStatus = function () {
                        var item = scope.task;
                        var statusClasses = [-10, 0, 10];
                        var index = $.inArray(item.priorityStatus, statusClasses);
                        var nextIndex = index + 1;
                        if (nextIndex === statusClasses.length) {
                            nextIndex = 0;
                        }
                        item.priorityStatus = statusClasses[nextIndex];
                        var statusOptions = {
                            taskid: item.id,
                            prioritystatus: item.priorityStatus
                        };
                        boardTaskService().updatePriorityStatus(item.id, statusOptions).then(function (result) {
                        });
                    };

                    scope.openTask = function(taskId, activeTab) {
                        boardTaskService().get(taskId).then(function (task) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false,
                                            canChangeBoard: true
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }]);