angular
    .module('DigiLean')
    .directive("textFromAppCell", ['smartcellService','debounce','projectService',
        function (smartcellService, debounce, projectService) {
            return {
                templateUrl: 'textFromAppCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                }, 
                link: function (scope, elem, attrs) {
                    scope.text = "";
                    scope.color = "#676a6c"; 
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    scope.isCloned = false;
                    scope.boardId = null;
                    scope.clonedBoardId = null;

                    scope.usePredefinedLabels = false;
                    scope.labels = [];
                    scope.projectId = null;
                    var visualizationType = null;

                    scope.$watch('model', function (model) {
                        scope.projectId = model.project.id;
                        var settings = model.column.settings;
                        scope.boardId = model.column.boardId;
                        if (settings) {
                            if(settings.visualizationType){
                                scope.isCloned = true;
                                visualizationType = model.column.settings.visualizationType;
                                scope.updateValue(visualizationType);
                            }
                            if(settings.usePredefinedLabels && settings.predefinedLabels){
                                scope.labels = settings.predefinedLabels;
                                scope.usePredefinedLabels = true;
                            }
                        } 
                        model.getSortValue = function () {
                            return scope.text;
                        }
                    });

                    scope.subscribe("SmartClonedCellChanged", function (change) {
                        if(change.name == visualizationType){ 
                            if (scope.projectId && change.projectId === scope.projectId) {
                                var value = JSON.parse(change.value);
                                scope.text =value.text;
                                scope.color = value.color;
                                scope.background = value.background;
                                scope.registerDate = change.lastModified;

                                scope.commitCellOnlyDebounced();
                            }
                        }
                    });

                    scope.updateValue = function (visualizationType) {
                        if (!visualizationType) return;
                        smartcellService().getProjectValueForClonedCell(visualizationType, scope.projectId).then(function (result) {
                            if(result){
                                var value = JSON.parse(result.value);
                                scope.text =value.text;
                                scope.color = value.color;
                                scope.background = value.background;
                                scope.registerDate = result.lastModified;

                                if(value.boardId){
                                    scope.clonedBoardId = value.boardId;
                                }

                                if(value.labels){
                                    scope.usePredefinedLabels = true;
                                    scope.labels = value.labels;
                                } else {
                                    scope.usePredefinedLabels = false;
                                }
                            }
                        });
                    };
                    function updateCloneValue(){
                        if(visualizationType && scope.isCloned){  
                            var value = {
                                background: scope.background,
                                color: scope.color,
                                text: scope.text,
                                boardId: scope.boardId
                            };
                            if(scope.usePredefinedLabels){
                                value.labels = scope.labels
                            }
                            var smartCloneCellValue = {
                                id: scope.smartCloneId, 
                                value: value,
                                name: visualizationType,
                                type: "text",
                                projectId: scope.projectId
                            };
                            smartcellService().updateCloneValue(smartCloneCellValue).then(function (savedStatus) {          
                                scope.clonedBoardId = scope.boardId;
                            });
                        }
                    }

                    scope.selectTextAndColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  postIt.color;    
                        scope.text = postIt.title;
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }

                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text
                        }
                        
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                        if(scope.isCloned){
                            updateCloneValue();
                        }
                    }
                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                 
                    scope.saveCellChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text
                        }
                        
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                        
                    }
                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                    scope.commitCellOnlyDebounced = debounce(scope.saveCellChanges, 1000, false);

                }
            }
        }]);
