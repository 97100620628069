import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean').directive("hoursRegistration", ['$filter', '$uibModal', 'projectService', 'dataService', 'dataListService', 'dataSourceService', 'authService', 'hoursService', 'employeeService',
        function ($filter, $uibModal, projectService, dataService, dataListService, dataSourceService, authService, hoursService, employeeService) {
            return {
                templateUrl: 'hoursRegistration.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {

                    // Hours datasource - FeatureKey => HourRegistration
                    // Dimension = Aktivitet (DataListe)
                    // Dimension2 = Registrert av (Person => userid)
                    // Dimension3 = Fakturerbar (bool)
                    // Dimension4 = Godkjent (bool)

                    var userid = authService.getCurrentUser().userId;
                    scope.subscribe('UserAuthenticatedAndReady', function (profile) {
                        userid = profile.user.id;
                        loadDataForWeek();
                    });
                    scope.isLoading = true;
                    scope.isProcessing = false;
                    scope.activityCodes = [];

                    function loadDataForWeek() {
                        if (!scope.selectedWeek) return;
                        var options = {
                            timePeriod: {
                                from: scope.selectedWeek.startDate,
                                to: scope.selectedWeek.endDate
                            },
                            filters: [
                                // Filter on current user
                                {
                                    sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]
                                }
                            ]
                        };
                        dataService().getValuesForPeriod(scope.dataSource.id, options).then(function (values) {
                            // if (values.length > 0) {
                               
                            // } else {
                            //     createRows([], false);
                            //     // Load Previous week values and create proxy rows to make it easier     
                            //     // var previousWeek = getPreviousWeek();
                            //     // options.timePeriod.from = previousWeek.startDate;
                            //     // options.timePeriod.to = previousWeek.endDate;
                            //     // dataService().getValuesForPeriod(scope.dataSource.id, options).then(function (values) {
                            //     //     createRows(values, false);
                            //     // });
                            // }
                            createRows(values, false);
                            checkApprovalStatus(values);
                        });
                        // LoadDashboard
                        loadDashboard(scope.dataSource.id);
                    }

                    function createRows(values, createEmptyRows) {
                        // Sort

                        // group by project
                        var projectRows = [];
                        values.forEach(value => {
                            // map activity
                            value.activity = value.dimension;
                            var activity = $filter('filter')(scope.activityCodes, { id: parseInt(value.dimension) }, true);
                            if (activity.length > 0) {
                                value.activity = activity[0];
                            }
                            // group on projects and activity
                            var projectInList = $filter('filter')(projectRows, { id: value.projectId, activityId: value.dimension }, true);
                            if (projectInList.length > 0) {
                                projectInList[0].values.push(value);
                            } else {
                                var projectRow = {
                                    id: value.projectId,
                                    activityId: value.dimension,
                                    activity: value.activity,
                                    values: [value]
                                }
                                projectRows.push(projectRow);
                            }
                        });
                        // Create project rows for quick acces that hasn't a value
                        scope.quickAccess.forEach(project => {
                            var match = $filter('filter')(projectRows, { id: project.id }, true);
                            if (match.length == 0) {
                                var projectRow = {
                                    id: project.id,
                                    activityId: null,
                                    activity: "",
                                    values: []
                                }
                                projectRows.push(projectRow);
                            }
                        });

                        projectRows = _.sortBy(projectRows, 'id');
                        // Create Row.
                        projectRows.forEach(row => {
                            var match = $filter('filter')(scope.projects, { id: row.id }, true);
                            row.project = {
                                id: row.id,
                                displayName: "unknown"
                            }
                            if (match.length > 0) {
                                row.project = match[0];
                            }

                            if (createEmptyRows) {
                                row.values = [];
                            }
                            scope.table.addRow(scope.table, row, scope.selectedWeek);
                        });
                        scope.table.calculateTotal();
                    }
                    // projectlist => Get All to match values, but probably need some favorite
                    employeeService().getUserPreference("HourRegistrationProjects").then(function (settings) {
                        if (settings && settings.value) {
                            angular.forEach(settings.value, function (project) {
                                if (project) {
                                    scope.quickAccess.push({
                                        id: project.id,
                                        name: project.name,
                                        projectNumber: project.projectNumber
                                    });
                                }
                            });
                        }
                        projectService().getList(true).then(function (projects) {
                            scope.projects = projects;
                            scope.projects.forEach(project => {
                                var hasQuickAccess = $filter('filter')(scope.quickAccess, { id: project.id }, true)
                                if (hasQuickAccess.length > 0) {
                                    project.isStar = true;
                                } else {
                                    project.isStar = false;
                                }
                            });
                            loadFeature()
                        });
                    });


                    // Feature list and Datasource
                    function loadFeature() {
                        var listOptions = { includeDeletedItems: false };
                        dataListService().getFeatureList("HourRegistration", listOptions).then(function (list) {
                            if (list) {
                                scope.activityCodes = list.items;
                            }
                            dataSourceService().getFeatureSource("HourRegistration").then(function (source) {
                                if (source) {
                                    scope.dataSource = source;
                                    // When all feature info is loaded, we can setup the rest
                                    setupTimeOptions();
                                }
                            })
                        });
                    }


                    // favorite
                    scope.quickAccess = [];
                    function saveQuickAccess() {

                        employeeService().updateUserPreference("HourRegistrationProjects", scope.quickAccess).then(function (settings) {
                        });
                    }
                    scope.addToQuickAccess = function (project) {
                        project.isStar = true;
                        scope.quickAccess.push({
                            id: project.id,
                            name: project.name,
                            projectNumber: project.projectNumber
                        })
                        saveQuickAccess();
                    }
                    scope.removeFromQuickAccess = function (project) {
                        project.isStar = false;
                        var isFavorite = $filter('filter')(scope.quickAccess, { id: project.id }, true)
                        if (isFavorite.length > 0) {
                            var currentIndex = scope.quickAccess.indexOf(isFavorite[0]);
                            scope.quickAccess.splice(currentIndex, 1);
                            saveQuickAccess();
                        }
                    }


                    function createRow(table, projectRow, week) {
                        var row = {
                            id: projectRow.project.id,
                            project: projectRow.project,
                            activity: projectRow.activity,
                            activityId: projectRow.activityId,
                            hoursTotal: 0,
                            invoiceHours: 0,
                            days: [],
                            calculateTotal: function () {
                                let sum = this.days.reduce((s, f) => {
                                    return s + f.hoursTotal;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                this.hoursTotal = sum;
                                let invoicesum = this.days.reduce((s, f) => {
                                    return s + f.invoiceHours;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                this.invoiceHours = invoicesum;
                                table.calculateTotal();
                            }
                        }
                        // Calcluate Days
                        week.days.forEach(dayInWeek => {
                            // add values
                            var day = {
                                day: dayInWeek,
                                hours: 0,
                                hoursTotal: 0,
                                invoiceHours: 0,
                                newRegistration: {
                                    hours: 0,
                                    description: "",
                                    isInvoiceable: row.project.isInvoiceable,
                                },
                                setHours: function (hours) {
                                    this.newRegistration.hours = hours;
                                },
                                deleteRegistration: function (registration) {
                                    var currentDay = this;
                                    dataService().delete(registration.id).then(function () {
                                        var index = day.registrations.indexOf(registration);
                                        currentDay.registrations.splice(index, 1);
                                        currentDay.calculateTotal();
                                    })
                                },
                                saveRegistration: function () {
                                    var hours = this.newRegistration.hours.toString();
                                    hours = hours.replace(",", ".");
                                    hours = parseFloat(hours);
                                    // Save datavalue
                                    var registration = {
                                        hours: hours,
                                        description: this.newRegistration.description,
                                        isInvoiceable: this.newRegistration.isInvoiceable.toString(),
                                    };
                                    // Convert to DataValue and save
                                    scope.isProcessing = true;
                                    var utcDate = moment.utc(this.day.date).format();
                                    var activityId = null;
                                    if (row.activity) activityId = row.activity.id;
                                    var dataValue = {
                                        valueDate: utcDate,
                                        value: hours,
                                        dimension: activityId,
                                        projectId: row.project.id,
                                        dimension2: userid,
                                        dimension3: registration.isInvoiceable,
                                        description: registration.description
                                    };

                                    dataService().add(scope.dataSource.id, dataValue).then(function (savedValue) {
                                        scope.isProcessing = false;
                                        registration.id = savedValue.id;
                                    });
                                    this.registrations.push(registration);
                                    this.calculateTotal();
                                    // Clear newRegistration
                                    this.newRegistration.hours = 0;
                                    this.newRegistration.description = "";
                                },
                                registrations: [],
                                calculateTotal: function () {
                                    let sum = this.registrations.reduce((s, f) => {
                                        return s + f.hours;  // return the sum of the accumulator and the current time, as the the new accumulator
                                    }, 0);
                                    this.hoursTotal = sum;
                                    var invoicesum = 0;
                                    this.registrations.forEach(element => {
                                        if (element.isInvoiceable == 'true') {
                                            invoicesum += element.hours
                                        }
                                    });
                                    this.invoiceHours = invoicesum;
                                    row.calculateTotal();
                                }
                            }

                            row.days.push(day);
                            // Add and calulate values to day
                            if (projectRow.values) {
                                mapValuesToDay(day, projectRow.values);
                                day.calculateTotal();
                            }

                        });
                        return row;
                    }

                    function mapValuesToDay(day, values) {
                        for (let index = 0; index < values.length; index++) {
                            const value = values[index];
                            var dayDate = moment(day.day.date).date();
                            var valueDate = moment(value.valueDate).date();
                            if (dayDate == valueDate) {
                                // var invoiceHours = 0;
                                day.registrations.push(
                                    {
                                        id: value.id,
                                        activity: value.activity,
                                        hours: value.value,
                                        isInvoiceable: value.dimension3,
                                        description: value.description,
                                        isApproved: value.dimension4
                                    }
                                );
                            }
                        }
                    }

                    function setupTable(week) {
                        var table = {
                            rows: [],
                            days: [],
                            addRow: function (table, row, week) {
                                // Check if project exists
                                initProject(row.project);
                                var rowIndex = 0;
                                var row = createRow(table, row, week);
                                var hasProjectRow = $filter('filter')(this.rows, { id: row.id });
                                if (hasProjectRow.length > 0) {
                                    hasProjectRow[0].rowSpan = hasProjectRow.length + 1;
                                    row.hasParent = true;
                                    var currentIndex = this.rows.indexOf(hasProjectRow[0]);
                                    rowIndex = currentIndex + hasProjectRow.length;
                                } else {
                                    row.hasParent = false;
                                    row.rowSpan = 1;
                                }
                                if (rowIndex == 0) {
                                    this.rows.push(row);
                                } else {
                                    this.rows.splice(rowIndex, 0, row);
                                }

                            },
                            hoursTotal: 0,
                            invoiceHours: 0,
                            calculateTotal: function () {
                                // total pr row.
                                let sum = this.rows.reduce((s, f) => {
                                    return s + f.hoursTotal;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                this.hoursTotal = sum;
                                sum = this.rows.reduce((s, f) => {
                                    return s + f.invoiceHours;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                this.invoiceHours = sum;
                                // Calculate total  pr day.
                                for (let index = 0; index < this.days.length; index++) {
                                    const dayInWeek = this.days[index];
                                    var daySum = 0;
                                    var invoiceSum = 0;
                                    this.rows.forEach(row => {
                                        // Get total for day in each row
                                        daySum += row.days[index].hoursTotal;
                                        invoiceSum += row.days[index].invoiceHours;
                                    });
                                    dayInWeek.hoursTotal = daySum;
                                    dayInWeek.invoiceHours = invoiceSum;
                                }
                            }
                        }
                        // Create days
                        week.days.forEach(dayInWeek => {
                            var day = {
                                day: dayInWeek,
                                hoursTotal: 0,
                                invoiceHours: 0,
                            }
                            table.days.push(day);
                        });
                        // Create rows based on project
                        scope.table = table;
                    }

                    function initProject(project) {
                        // INternal project should be "000-099"
                        if (project.projectNumber && project.projectNumber.length == 3 && project.projectNumber.substring(0, 1) == "0") {
                            project.isInvoiceable = false;
                        } else {
                            project.isInvoiceable = true;
                        }
                    }

                    // Add project
                    scope.addRow = function () {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'projectSelectorModal.html',
                            controller: 'projectSelectorController',
                            resolve: {
                                existing: function () {
                                    return []; // To be replaced with existing project list
                                }
                            }
                        });

                        modalInstance.result.then(function (exisitingProject) {
                            if (exisitingProject) {
                                // Load project and create Row
                                // map project to project list
                                var project = $filter('filter')(scope.projects, { id: exisitingProject.id }, true);
                                if (project.length > 0) {
                                    exisitingProject = project[0];
                                }
                                var row = {
                                    id: exisitingProject.id,
                                    project: exisitingProject
                                }
                                scope.table.addRow(scope.table, row, scope.selectedWeek);
                            }
                        }, function () {
                        });

                    }

                    // Approval
                    scope.isWeekApproved = false;
                    function checkApprovalStatus(values) {
                        // If one or more values not approved => week is not approved
                        var approvedValues = $filter('filter')(values, { dimension4: "true" });
                        if (values.length > 0 && approvedValues.length == values.length) {
                            scope.isWeekApproved = true;
                        } else {
                            scope.isWeekApproved = false;
                        }
                    }
                    scope.approveHours = function (status) {
                        // Get all registrations and send the for approval.
                        var rows = scope.table.rows;
                        var registrations = [];
                        rows.forEach(row => {
                            row.days.forEach(day => {
                                registrations = registrations.concat(day.registrations);
                            });
                        });
                        var list = [];
                        registrations.forEach(reg => {
                            list.push({
                                id: reg.id
                            });
                        });
                        if (list.length > 0) {
                            if (status) {
                                hoursService().approveHours(list);
                                scope.isWeekApproved = true;
                            } else {
                                hoursService().unApproveHours(list);
                                scope.isWeekApproved = false;
                            }

                        }
                    }

                    // Week and time setup
                    scope.selectWeek = function (week) {
                        scope.selectedWeek = week;
                        // SETUP TABLE
                        setupTable(scope.selectedWeek, []);
                        // Load data
                        loadDataForWeek();

                    }

                    function setupTimeOptions() {
                        scope.months = timeService.getMonths();
                        scope.selectedYear = moment().year(); // Get currentYear if not set
                        var currentMonth = moment().month();
                        var month = scope.months[currentMonth];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectedMonth = month;
                        var currentWeek = moment().isoWeek();
                        var hasWeek = $filter('filter')(scope.selectedMonth.weeks, { number: currentWeek }, true);
                        if (hasWeek.length > 0) {
                            scope.selectWeek(hasWeek[0]);
                        } else {
                            scope.selectWeek(scope.selectedMonth.weeks[0]);
                        }
                    }

                    function getPreviousWeek() {
                        var currentWeekIndex = scope.selectedMonth.weeks.indexOf(scope.selectedWeek);
                        var currentWeekNumber = scope.selectedWeek.number;
                        var previousWeekIndex = currentWeekIndex - 1;
                        if (currentWeekIndex == 0) { // First week, so we need to go back a month
                            scope.previousMonth(false);
                            previousWeekIndex = scope.selectedMonth.weeks.length - 1;
                        }
                        var previousWeek = scope.selectedMonth.weeks[previousWeekIndex];
                        if (previousWeekIndex == (scope.selectedMonth.weeks.length - 1) && previousWeek.number == currentWeekNumber) {
                            previousWeek = scope.selectedMonth.weeks[previousWeekIndex - 1];
                        }
                        return previousWeek;
                    }
                    scope.previousWeek = function () {
                        var previousWeek = getPreviousWeek();
                        scope.selectWeek(previousWeek);
                    }
                    scope.nextWeek = function () {
                        var currentWeekIndex = scope.selectedMonth.weeks.indexOf(scope.selectedWeek);
                        var currentWeekNumber = scope.selectedWeek.number;
                        var nextWeekIndex = currentWeekIndex + 1;
                        if (nextWeekIndex > (scope.selectedMonth.weeks.length - 1)) { // Last week of month, so we need to go forward a month
                            scope.nextMonth(false);
                            nextWeekIndex = 0;
                        }
                        var nextWeek = scope.selectedMonth.weeks[nextWeekIndex];
                        // If week is share between two months, we need to move it to the next
                        if (nextWeekIndex == 0 && nextWeek.number == currentWeekNumber) {
                            nextWeek = scope.selectedMonth.weeks[1];
                        }
                        scope.selectWeek(nextWeek, true);
                    }
                    var selectYear = function (year) {
                        scope.selectedYear = year;
                        // Adjust month
                        var month = scope.months[0];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectedMonth = month;
                        setMonthSelection(month);
                        scope.selectedWeek = month.weeks[0];
                        setWeekSelection(scope.selectedWeek);
                    }

                    scope.selectMonth = function (month) {
                        scope.selectedMonth = month;
                        scope.selectedMonth.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        // Also adjust week to be in correct month
                        scope.selectedWeek = month.weeks[0];
                    };

                    scope.previousYear = function () {
                        selectYear(scope.selectedYear - 1);
                    }
                    scope.nextYear = function () {
                        selectYear(scope.selectedYear + 1);
                    }
                    scope.previousMonth = function (refreshData) {
                        var previousMonth = scope.selectedMonth.id - 1;
                        if (previousMonth == 0) {
                            previousMonth = 12;
                            scope.selectedYear -= 1;
                        }
                        var previousMonthIndex = previousMonth - 1; // subtract index
                        var month = scope.months[previousMonthIndex];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectMonth(month, refreshData);
                    }
                    scope.nextMonth = function (refreshData) {
                        var nextMonth = scope.selectedMonth.id + 1;
                        if (nextMonth == 13) {
                            nextMonth = 1;
                            scope.selectedYear += 1;
                        }
                        var nextMonthIndex = nextMonth - 1; // subtract for index
                        var month = scope.months[nextMonthIndex];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectMonth(month, refreshData);
                    }

                    // Dashboard settings
                    function loadDashboard(dataSourceId) {
                        scope.thisMonthSettings = {
                            title: "Denne måneden",
                            timePeriod: {
                                timeframe: "month"
                            },
                            dataSource: {
                                id: dataSourceId,
                                valueElement: {
                                    unit: "Timer"
                                }
                            },
                            filters: [
                                // Filter on current user
                                {
                                    sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]
                                }
                            ],
                            decimals: 1
                        }
                        scope.thisMonthInvoicedSettings = {
                            title: "Fakturerbart",
                            timePeriod: {
                                timeframe: "month"
                            },
                            dataSource: {
                                id: dataSourceId,
                                valueElement: {
                                    unit: "Timer"
                                }
                            },
                            filters: [
                                // Filter on current user
                                {
                                    sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]
                                },
                                {
                                    sourceColumn: "dimension3",
                                    operator: "InList",
                                    items: ["true"]
                                }
                            ],
                            decimals: 1
                        }
                        scope.thisYearSettings = {
                            title: "I år",
                            timePeriod: {
                                timeframe: "year"
                            },
                            dataSource: {
                                id: dataSourceId,
                                valueElement: {
                                    unit: "Timer"
                                }
                            },
                            filters: [
                                // Filter on current user
                                {
                                    sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]
                                }
                            ],
                            decimals: 1
                        }
                        scope.thisYearInvoicedSettings = {
                            title: "Fakturerbart",
                            timePeriod: {
                                timeframe: "year"
                            },
                            dataSource: {
                                id: dataSourceId,
                                valueElement: {
                                    unit: "Timer"
                                }
                            },
                            filters: [
                                // Filter on current user
                                {
                                    sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]
                                },
                                {
                                    sourceColumn: "dimension3",
                                    operator: "InList",
                                    items: ["true"]
                                }
                            ],
                            decimals: 1
                        }
                        scope.thisMonthPieSettings = {
                            title: "Fordeling pr prosjekt",
                            timePeriod: {
                                timeframe: "month"
                            },
                            dataSource: {
                                id: dataSourceId,
                                valueElement: {
                                    unit: "Timer"
                                }
                            },
                            filters: [
                                // Filter on current user
                                {
                                    sourceColumn: "dimension2",
                                    operator: "InList",
                                    items: [userid]
                                }
                            ],
                            groupedBy: "projectId"
                        }
                    }

                }

            }
        }]);
