angular
    .module('DigiLean').directive("projectAttachments", ['projectService',
        function (projectService) {
            return {
                templateUrl: 'projectAttachments.html',
                restrict: 'E',
                scope: {
                    'projectId': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.update = function () {
                        
                    }
                    scope.uploadedFiles = [];
                    scope.files = [];
                    scope.isLoading = true;
                    scope.toogleEditModeOn = true;
                    if(scope.isEditMode){
                        scope.toogleEditModeOn = false;
                    }

                    scope.$watch("projectId", function (projectId) {
                        if (!projectId) return;
                        projectService().getAttachments(projectId).then(function(files) {
                            scope.files = files;
                            scope.isLoading = false;
                        });
                    });

                    scope.deleteFile = function (file) {
                        var i = scope.files.indexOf(file);
                        scope.files.splice(i, 1);
                        projectService().deleteAttachment(scope.projectId, file.id).then(function() {

                        });
                    }

                    scope.fileUploaded = function (file) {
                        projectService().addAttachment(scope.projectId, file).then(function(attachment) {
                            scope.isEditMode = false;
                            scope.files.push(attachment);
                        });
                    };
                    scope.toggleEditMode = function () {
                        scope.isEditMode = !scope.isEditMode ;
                    };

                }

            }
        }]);
