angular
    .module('DigiLean')
    .directive("postitHub", ['$filter', '$translate','taskDrawingService',
        function ($filter, $translate, taskDrawingService) {
            return {
                templateUrl: 'postitHub.html',
                restrict: 'E',
                scope: {
                    'settings': '=',    
                    "clearSelection" : "<",
                    "selectColorHandler" : "&",
                },
                link: function (scope, elem, attrs) {
                    var dragDescription = "";

                    scope.$watch('settings', function (settings) {
                        
                        $translate(['BOARD_POSIT_DRAG_DESCRIPTION']).then(function (translations) {
                            dragDescription = translations.BOARD_POSIT_DRAG_DESCRIPTION;
                            if (settings) {
                                scope.postIts = settings;           
                            } else {
                                scope.postIts = taskDrawingService().getPostIts();
                            }
                            scope.postIts.forEach(color => {
                                color.isSelected = false;
                                if(color.tags && color.tags.length >0){
                                    color.tooltip = getTagsDescription(color);
                                } else {
                                    color.tooltip = dragDescription;
                                }
                            });
                            setTimeout(function () {
                                $("#postItContainer .post-it").draggable({
                                    cursor: 'move',
                                    helper: postitPlaceholder
                                });
                            }, 50);
                        });
                    });

                    function getTagsDescription(postIt){
                        var tooltip = "";
                        if(postIt.tags){
                            for (var i = 0; i < postIt.tags.length; i++) {
                                if(i>0){
                                    tooltip = tooltip.concat(', ');
                                }
                                tooltip = tooltip.concat(postIt.tags[i]);
                            }
                        }
                        return tooltip;
                    }

                    scope.$watch('clearSelection', function () {
                        if (!scope.clearSelection) return;
                        if (!scope.selectedColor) return;
                        scope.selectedColor.isSelected = false;
                    });
                    
                    scope.selectedColor = null;
                    scope.selectColor = function(color) {
                        // If user already selected, we need to clear selection.
                        if (scope.selectedColor && scope.selectedColor !== color) { // Clear previous selectedUser
                            scope.selectedColor.isSelected = false;
                        }
                        if (color.isSelected) {
                            scope.selectedColor = null;
                        } else {
                            scope.selectedColor = color;
                        }
                        color.isSelected = !color.isSelected;
                        scope.selectColorHandler({ color: scope.selectedColor });
                    }

                    // DragAndDrop of user
                    function postitPlaceholder(event) {
                        var dragTarget = event.currentTarget;
                        var fillcolor = $(dragTarget).data("fillcolor");
                        var color = $(dragTarget).data("color");

                        return "<div class='post-it-dragged' data-color='" + color + "' style='background: " + fillcolor + ";border-bottom: '2px solid "  + color + ";'><i class='fal fa-table'></i> " +  dragDescription + "</div>";
                    }
                }
            }
        }]);
