angular
    .module('DigiLean')
    .directive("projectStatusCell", ['$filter', '$translate', 'projectService',
        function ($filter, $translate, projectService) {
            return {
                templateUrl: 'projectStatusCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                },
                link: function (scope, elem, attrs) {
                   
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                   
                    scope.statusOptions = projectService().getStatus();
                    
                    /*scope.postIts = projectService().getPostIts();
                    scope.usePredefinedLabels = false;
                    scope.labels = [];*/

                    scope.$watch('model', function (model) {
                        scope.selectedStatusOption = $filter('filter')(scope.statusOptions, { dbCode: model.project.status }, true)[0];
                    
                        model.getSortValue = function() {
                            if (!scope.selectedStatusOption) return 0;
                            return scope.selectedStatusOption.sortorder;
                        }
                        
                    });

                    scope.subscribe("ProjectStatusUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            if (project.status) {
                                 scope.selectedStatusOption = $filter('filter')(scope.statusOptions, { dbCode: project.status }, true)[0];               
                            }
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            //document.querySelector( "input" ).focus();
                            $(".project-status-cell").focus();
                        }, 50 );
                    }

                    scope.statusChanged = function(projectStatus) {      
                       // scope.color = projectStatus.color;
                       // scope.background = projectStatus.background;
                        if(scope.selectedStatusOption && projectStatus.dbCode === scope.selectedStatusOption.dbCode){
                            return;       
                        }
                        projectService().updateProjectStatus(scope.model.project.id, projectStatus.dbCode).then(function (result) {                         
                        });
                    }
                    
                    /*
                    var setValue = function(value) {
                        scope.background = value.background;
                        scope.text = value.text;
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        $scope.selectedStatusOption = $filter('filter')($scope.statusOptions, { dbCode: $scope.project.status }, true)[0];
                    
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }

  

                    scope.selectTextAndColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  postIt.color;    
                        scope.text = postIt.title;
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  "#676a6c";
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }
                    scope.saveChanges = function() {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text
                        }
                        scope.model.projectAttribute.value = value;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);*/

                }
            }
        }]);
