import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .controller("greencrossStatusModalController", ['$scope', '$uibModalInstance', '$filter', 'areaService', 'projectService','qualityService', 'timePeriod','visualizationType','filters',
        function ($scope, $uibModalInstance, $filter, areaService, projectService, qualityService, timePeriod, visualizationType, filters) {

            $scope.resultList = [];

            qualityService().getAllStatusValuesForPeriod(visualizationType, timePeriod, filters).then(function (result) {
                $scope.resultList = result;
                projectService().getActiveProjects().then(function (projects) {
                    $scope.projects = projects;
                    areaService().getList(true).then(function (areas) {
                        $scope.areas = areas;
                        assetService.getAllAssets().then(function (assets) {
                            $scope.assets = assets;
                            for(var i=0; i< $scope.resultList.length; i++){
                                if($scope.resultList[i].assetId){
                                    $scope.resultList[i].asset = getAssetName($scope.resultList[i].assetId);
                                } else if($scope.resultList[i].projectId){
                                    $scope.resultList[i].projectName = getProjectName($scope.resultList[i].projectId);
                                } else {
                                    $scope.resultList[i].area = getArea($scope.resultList[i].areaId);
                                }
                            }
                        });
                    });
                });
                
            });

            

            projectService().getActiveProjects().then(function (projects) {
                $scope.projects = projects;
            });

            function getAssetName (assetId) {
                var exist = $filter('filter')($scope.assets, { id: assetId }, true);
                if (exist.length > 0) {
                    return exist[0].name;
                } else return "";
            }
            function getProjectName (projectId) {
                var exist = $filter('filter')($scope.projects, { id: projectId }, true);
                if (exist.length > 0) {
                    return exist[0].name;
                } else return "";
            }

            function getArea(areaId) {
                var exist = $filter('filter')($scope.areas, { id: areaId }, true);
                if (exist.length > 0) {
                    return exist[0].name;
                } else return "";
            }
            

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            
        }]);