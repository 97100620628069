import { RectangleObject } from "@common/model/types"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import * as staticFileService from "@common/shared/staticFileService"
import "./resolutionDisplayer"
import { Dashboard, DashboardWidget } from "@api"

export enum LayoutOption {
    Default = "default",
    Landscape = "landscape"
}

enum ScreenSize {
    Medium = "medium",
    Large = "large",
    ExtraLarge = "extraLarge"
}

interface ScreenSizesConfig {
    type: ScreenSize,
    size: RectangleObject,
    translated?: string
}

export interface LayoutMode {
    option: LayoutOption
    screenSize?: ScreenSize
    maxRows?: number
}

const screenConfigurations: ScreenSizesConfig[] = [
    {
        type: ScreenSize.Medium,
        size: { width: 1500, height: 750 },
        translated: translateLabelInstant('DASHBOARD_SCREEN_SIZE_MEDIUM')
    },
    {
        type: ScreenSize.Large,
        size: { width: 2000, height: 1000 },
        translated: translateLabelInstant('DASHBOARD_SCREEN_SIZE_LARGE')
    },
    {
        type: ScreenSize.ExtraLarge,
        size: { width: 2700, height: 1350 },
        translated: translateLabelInstant('DASHBOARD_SCREEN_SIZE_EXTRALARGE')
    }
]
export function getScreenConfiguration(screenSize: ScreenSize) {
    const config = screenConfigurations.find(sc => sc.type == screenSize)
    return config
}

export function getLayoutOptions(option: LayoutOption) {
    let options = structuredClone(layoutOptions)
    options = options.filter(lo => lo.option === option)
    return options
}
// export function getScreenSizes() {
//     const sizes = Object.values(ScreenSize).filter((v) => isNaN(Number(v)))
//     return sizes
// }
export function getScreenSizesConfiguration() {
    return screenConfigurations
}
export interface LayoutOptionConfig {
    option: LayoutOption
    minRows: number
    maxRows: number
    translated?: string
}
const defaultOption: LayoutOptionConfig = {
    option: LayoutOption.Default,
    minRows: 1,
    maxRows: 200
}

const layoutOptions: LayoutOptionConfig[] = [
    defaultOption,
    {
        option: LayoutOption.Landscape,
        minRows: 20,
        maxRows: 20,
        translated: "16:9 (40 x 20)"
    },
    {
        option: LayoutOption.Landscape,
        minRows: 22,
        maxRows: 22,
        translated: "16:10 (40 x 22)"
    },
    {
        option: LayoutOption.Landscape,
        minRows: 24,
        maxRows: 24,
        translated: "3:2 (40 x 24)"
    },
    {
        option: LayoutOption.Landscape,
        minRows: 28,
        maxRows: 28,
        translated: "4:3 (40 x 28)"
    }
]

export function getLayoutConfig(layoutMode: LayoutMode) {
    if (layoutMode.option == LayoutOption.Landscape && !layoutMode.maxRows)
        throw new Error("layoutmode landscape must have maxRows!")

    if (layoutMode.option == LayoutOption.Default)
        return defaultOption

    // use weak comparison here as select element will make the value a string
    const config = layoutOptions.find(lc => lc.maxRows == layoutMode.maxRows)
    return config ?? defaultOption
}

export function calculateLayoutFactor(screenSize: ScreenSize, current: RectangleObject) {
    
    const screenConfig = getScreenConfiguration(screenSize)
    if (!screenConfig)
        throw new Error("Unknown screen size")

    const factorWidthFixed = (current.width / screenConfig.size.width).toFixed(2)
    const factorWidth = parseFloat(factorWidthFixed)
    return factorWidth
}

export function getThemes() {
    return  [
        {
            type: "default",
            name: translateLabelInstant("BOARD_TEXT_MODE_DARK")
        },
        {
            type: "custom",
            name: translateLabelInstant("BOARD_TEXT_MODE_LIGHT")
        },
    ]
}

export function getBackgrounds() {
    return [
        {
            name: "skyscraper",
            previewUrl: staticFileService.image('skyscraper-preview.jpg'),
            url: staticFileService.image('skyscraper.jpg'),
        },
        {
            name: "industry-crane",
            previewUrl: staticFileService.image('industry-crane-preview.jpg'),
            url: staticFileService.image('industry-crane.jpg'),
        },
        {
            name: "fjord",
            previewUrl: staticFileService.image('fjord-preview.jpg'),
            url: staticFileService.image('fjord.jpg'),
        },
        {
            name: "orange-landscape",
            previewUrl: staticFileService.image('orange-landscape-preview.jpg'),
            url: staticFileService.image('orange-landscape.jpg'),
        },
        {
            name: "orange-sky",
            previewUrl: staticFileService.image('orange-sky-preview.jpg'),
            url: staticFileService.image('orange-sky.jpg'),
        },
        {
            name: "industry",
            previewUrl: staticFileService.image('industry-preview.jpg'),
            url: staticFileService.image('industry.jpg'),
        },
        {
            name: "mountain",
            previewUrl: staticFileService.image('mountain-preview.jpg'),
            url: staticFileService.image('mountain.jpg'),
        }

    ];
}

interface PageState {
    title: string
    height: number
}
export function isPossibleToConvertToLandscape(currentDashboard: Dashboard, maxHeight: number) {
    if (!currentDashboard || !currentDashboard.pages || currentDashboard.pages.length === 0)
        return false

    if (typeof maxHeight == "string")
        maxHeight = parseInt(maxHeight)

    const pageHeights = getPageHeights(currentDashboard)
    const errors = pageHeights.reduce((array: PageState[], page) => {
        if (page && page.height > maxHeight)
            array.push(page)
        return array
    }, [])
    
    return {
        isPossibleToChange: errors.length === 0,
        errors,
        maxHeight
    }
}

type AppScale = { [key in ScreenSize]: number }
// ScreenSize factor
const appScaleFactors: AppScale = {
    medium: 1,
    large: 0.8,
    extraLarge: 0.6
}

const minAppSize = 2
const maxAppSize = 40

export function adjustAppSize(screenSize: ScreenSize, size: number) {
    let adjustedSize = 12
    
    if (!size)
        return adjustedSize

    if (!screenSize)
        return size

    if (screenSize === ScreenSize.Medium)
        return size

    const scaleFactor = appScaleFactors[screenSize]
    
    adjustedSize = Math.floor(size * scaleFactor)
    if (adjustedSize < minAppSize) return minAppSize
    if (adjustedSize > maxAppSize) return maxAppSize
    return adjustedSize;
}

function getPageHeights(dashboard: Dashboard) {
    if (!dashboard || !dashboard.pages || dashboard.pages.length == 0)
        return []

    return dashboard.pages.map(p => {
        const pageHeight = getPageHeightByWidgets(p.widgets)
        return { title: p.title, height: pageHeight } as PageState
    })
}

function getPageHeightByWidgets(widgets?: DashboardWidget[] | null) {
    if (!widgets || widgets.length === 0)
        return 0
    let max = 0
    widgets.forEach(w => {
        const height = (w.row ? w.row : 0) + w.sizeY
        if (height > max)
            max = height
    })
    return max
}