angular
    .module('DigiLean')
    .directive("lLetter", ['kpiLetterBaseDirective',
        function (kpiLetterBaseDirective) {
            var lLetterDirective = {
                name: 'lLetter',
                templateUrl: 'lLetter.html',
                restrict: 'EA',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                }
            };

            lLetterDirective.compile = function (element, attrs) {
                return {
                    post: function (scope, element, attrs) {
                        kpiLetterBaseDirective[0].link(scope, element, attrs);
                        scope.visualizationType = "L";
                   
                    }
                };
            };

            return lLetterDirective;
        }]);
