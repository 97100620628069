import angular from "angular"
const DigiLean = angular.module('DigiLean')
DigiLean.controller('jobScheduleDialogController', ['$scope', '$uibModalInstance', 'cronExpression',
    function ($scope, $uibModalInstance, cronExpression) {
        $scope.cronExpression = cronExpression
        $scope.cronOptions = {
            use24HourTime: true,
            cronFormat: 'quartz',
            formInputClass: 'form-control cron-gen-input',
            formSelectClass: 'form-control cron-gen-select',
            formRadioClass: 'cron-gen-radio',
            formCheckboxClass: 'cron-gen-checkbox',
            hideMinutesTab: false,
            hideHourlyTab: false,
            hideDailyTab: false,
            hideWeeklyTab: false,
            hideMonthlyTab: false,
            hideYearlyTab: false,
            hideAdvancedTab: false,
            hideSeconds: true
        }
        $scope.ok = function() {
            $uibModalInstance.close({cronExpression: $scope.cronExpression})
        }
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }
    }])