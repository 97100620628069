angular
    .module('DigiLean')
    .directive("a3ParticipantsCell", ['$filter', '$translate', '$uibModal', 'projectService',
        function ($filter, $translate, $uibModal, projectService) {
            return {
                templateUrl: 'a3ParticipantsCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.participants = [];

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            if (scope.model.project.id) {
                                projectService().getUsers(scope.model.project.id).then(function (projectUsers) {
                                    if (projectUsers) {
                                        scope.participants = projectUsers;
                                    }
                                });
                            }
                        }
                        model.getSortValue = function () {

                            return scope.participants.length;

                        }
                    });

                    scope.subscribe("ProjectParticipantAdded", function (projectuserinfo) {
                        if (projectuserinfo.projectId == scope.model.project.id) {
                            scope.participants.unshift(projectuserinfo);
                        }
                    });

                    scope.subscribe("ProjectParticipantRemoved", function (projectuserinfo) {
                        if (projectuserinfo.projectId == scope.model.project.id) {
                            var matchingUser = $filter('filter')(scope.participants, { userId: projectuserinfo.userId }, true);
                            if (matchingUser.length > 0) {
                                var index = scope.participants.indexOf(matchingUser[0]);
                                if (index > -1) {
                                    scope.participants.splice(index, 1);
                                }
                            }
                        }
                    });

                    scope.openParticipants = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'projectParticipantModal.html',
                            controller: 'projectParticipantModalController',
                            resolve: {
                                projectId: function () {
                                    return scope.model.project.id;
                                }
                            }
                        });
                    };
                }
            }
        }]);