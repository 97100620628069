var DigiLean = angular.module('DigiLean');  
DigiLean.controller('myRegisteredSuggestionsController', ['$scope','$filter', '$uibModal', 'position','suggestionService',
    function ($scope, $filter, $uibModal, position, suggestionService) {
        $scope.suggestions = [];
        $scope.implemented = 0;
        if(position){
            $scope.position = position;
        } else {
            $scope.position = 'center';
        }

        suggestionService().getMySuggestions().then(function (suggestions) {
            $scope.suggestionimplemented = 0;
            $scope.suggestions = suggestions;
            suggestions.forEach(function (suggestion) {
                if(suggestion.status == "Implemented" || suggestion.status == "Evaluated") $scope.suggestionimplemented++;
    
            });
            $scope.suggestions = suggestions;
            $scope.translationData = {
                noOfSuggestions: $scope.suggestions.length
            };
        });

        $scope.openSuggestionss = function () {
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'myRegisteredSuggestionsForm.html',
                    controller: 'mysuggestionsController',
                    windowClass: 'fullDeviation-modal-window',
                    resolve: {
                    }
                });
        }

    }]);



