angular
    .module('DigiLean')
    .controller("newLearningMaterialController", ['$scope', 'languageAdminService','learningService', '$uibModalInstance',
        function ($scope, languageAdminService,learningService, $uibModalInstance) {

            $scope.title = "";
            $scope.description = "";
            $scope.imageId = null;
            $scope.languages = languageAdminService().getSupportedLanguages();
            $scope.selectedLanguage = $scope.languages[0];

            $scope.setLanguage = function (language) {
                $scope.selectedLanguage = language;
            };
            $scope.ok = function () {
                var material = {
                    title: $scope.title,
                    description: $scope.description,
                    imageId: $scope.imageId,
                    language: $scope.selectedLanguage.languageCode
                }
                learningService().createLearningMaterial(material).then(function(created){
                    $uibModalInstance.close(created)
                });          
            };

            $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
            $scope.fileUploaded = function (file) {
                $scope.imageId = file.id;
            };
            
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
