import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean').directive("invoiceReport", ['$filter','hoursService','dataService','dataListService', 'pdfService','dataSourceService',
        function ($filter, hoursService, dataService, dataListService, pdfService, dataSourceService) {
            return {
                templateUrl: 'invoiceReport.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = false;
                    scope.isProcessing = false;
                    scope.options = {
                        timePeriod: {
                            timeframe: "month",
                            useDefaultTimeframes: true
                        }
                    };

                    

                    scope.currentTimePeriod = null;
                    scope.loadValues = function (project) {
                        project.showDetails = !project.showDetails;
                        if (project.hasLoadedValues) return;
                        project.hasLoadedValues = true;
                        project.isLoadingValues = true;
                        var queryOptions = {
                            timePeriod: scope.currentTimePeriod,
                            filters: [
                                // Filter on project
                                {
                                    sourceColumn: "projectId",
                                    operator: "InList",
                                    items: [project.projectId]
                                },
                                // { // Fakturertbart
                                //     sourceColumn: "dimension3",
                                //     operator: "InList",
                                //     items: ["true"]
                                // }
                            ]
                        }
                        dataService().getValuesForPeriod(scope.dataSourceId, queryOptions).then(function (values) {
                            values.forEach(value => {
                                value.valueDate = moment(value.valueDate).toDate();
                                var activity = $filter('filter')(scope.activityCodes, { id: parseInt(value.dimension)}, true);
                                if (activity.length > 0) {
                                    value.activity = activity[0].name;
                                }
                                var person = $filter('filter')(scope.users, { id: value.dimension2 });
                                if (person.length > 0) {
                                    value.responsible = person[0].fullName;
                                }
                            });
                            project.values = values;
                            project.isLoadingValues = false;
                        });
                    }
                    scope.periodChangedHandler = function (timePeriod) {
                        if (!timePeriod.timeframe) return;
                        scope.currentTimePeriod = timePeriod.period;
                        scope.isLoading = true;
                        hoursService().getProjectHours(timePeriod.period).then(function (result) {
                            result.projectHours.forEach(project => {
                                // Extend object for loading values state.
                                project.showDetails = false;
                                project.hasLoadedValues = false;
                                project.isLoadingValues = false;
                                project.values = [];
                            });
                            scope.projects = result.projectHours;
                            scope.isLoading = false;
                            scope.dataSourceId = result.dataSourceId;
                            scope.sumHours = result.totalHours;
                            scope.invoiceHours = result.invoiceHours;
                        });

                    };
                    scope.printPdf = function(project) {
                        pdfService().printProjectHoursReport(project.projectId, scope.currentTimePeriod);
                    }
                    // Load activityList
                    var listOptions = { includeDeletedItems: false };
                    dataListService().getFeatureList("HourRegistration", listOptions).then(function (list) {
                        if (list) {
                            scope.activityCodes = list.items;
                        }
                    });
                    // Load Persons
                    customerService.getAllUsers().then(function (users) {
                        scope.users = users;
                    });

                    dataSourceService().getFeatureSource("ReactivateCustomerAccount").then(function (source) {
                        if (source) {
                            var dataSourceId = source.id;
                            scope.reactivateSettings = {
                                title: "Reaktiverte kunder",
                                timePeriod: {
                                    timeframe: "year"
                                },
                                dataSource: source,
                                groupedBy: "dimension"
                            }
                        }
                    })

                }

            }
        }]);
