import { CustomerSettingKeys } from "@api"
import { DlSubject } from "@common/stores/reactive"
import { authUser } from "@common/stores/userStore"
import { getCompanySettingServer, updateCompanySettingServer} from "@common/services/customers/customerService"

type CompanySettings = { 
    [k in CustomerSettingKeys]?: string | null
}

type CompanySettingsString = `${CustomerSettingKeys}`
//type CompanySettingsString = keyof typeof CustomerSettingKeys

const companySettingsSubject = new DlSubject<CompanySettings>({
    "dateFormat": "",
    "currency": "",
    "language": "",
    "fiscalmonth": ""
})

/**
 * Gets cached company setting as string
 * 
 * @param {CompanySettingsString} keyStr - the company settings key
 */
export const getCompanySetting = async (keyStr: CompanySettingsString) => {

    const key = keyStr as CustomerSettingKeys

    const currentSettings = companySettingsSubject.getValue()
    if (currentSettings && currentSettings[key]) {
        return currentSettings[key] as string
    }
    
    const setting = await getCompanySettingServer(key)
    if (!setting || !setting.value)
        return ""
    
    updateSettingInSubject(key, setting.value)
    return setting.value as string
}

export const saveCompanySetting = async (key: CustomerSettingKeys, value: string) => {
    await updateCompanySettingServer(key, value)
    updateSettingInSubject(key, value)
}

authUser.subscribe(user => {
    if (user.loggedIn) {
        getAllSettings()
    }
})


const getAllSettings = async () => {
    for (const key in CustomerSettingKeys) {
        const setting = await getCompanySettingServer(CustomerSettingKeys[key])
        if (setting) {
            updateSettingInSubject(CustomerSettingKeys[key], setting.value)
        }
    }
}

const updateSettingInSubject = (key: CustomerSettingKeys, value: string | null | undefined) => {
    let currentSettings = companySettingsSubject.getClonedValue()
    currentSettings[key] = value
    companySettingsSubject.next(currentSettings)
}
