import * as boardService from "@common/services/boardService"

angular
    .module("DigiLean")
    .directive('timeBoard', ['$filter', '$uibModal', 'debounce',
        function ($filter, $uibModal, debounce) {
            return {
                templateUrl: 'timeboard.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'assetId': '<',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.boards = [];
                    scope.selectedBoard = null;

                    scope.boardData = null;
                    scope.hasBoard = false;
                    scope.yearlyBoardOptions = {
                        showTeamMembers: true,
                        showHeader: true,
                        taskDetailLevel: "normal",
                        viewModeSelection: "year",
                        hideWrench: false
                    };
                    scope.optionsChangeHandler = function(options) {
                        scope.yearlyBoardOptions.viewModeSelection = options.viewModeSelection;
                        scope.yearlyBoardOptions.taskLevelDetails = options.taskDetailLevel;
                        updateSettings();
                    }
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            if(settings.boardOptions){
                                scope.yearlyBoardOptions = settings.boardOptions; 
                            }
                            loadBoard(settings.boardId);
                        }
                        boardService.getMemberBoards().then(function (boards) {
                            // Filter
                            for (let index = 0; index < boards.length; index++) {
                                const board = boards[index];
                                if (board.boardType == 'Yearly') {
                                   scope.boards.push(board);
                                }
                            }
                            // Set selected 
                            if (scope.settings && scope.settings.boardId) {
                                var selected = $filter('filter')(scope.boards, { id: settings.boardId });
                                if (selected.length > 0) {
                                    scope.selectedBoard = selected[0];
                                }
                            }
                        });
                    });


                    scope.boardChanged = function (selectedBoard) {
                        loadBoard(selectedBoard.id);
                        scope.selectedBoard = selectedBoard;
                        updateSettings();
                    }

                    scope.updateBoardOptionsDetailLevel = function (detaillevel) {
                        scope.yearlyBoardOptions = {
                            showTeamMembers: scope.yearlyBoardOptions.showTeamMembers,
                            showHeader: scope.yearlyBoardOptions.showHeader,
                            taskDetailLevel: detaillevel,
                            viewModeSelection: scope.yearlyBoardOptions.viewModeSelection
                        };
                        updateSettings();
                    }

                    scope.updateBoardOptionsView = function (view) {
                        scope.yearlyBoardOptions = {
                            showTeamMembers: scope.yearlyBoardOptions.showTeamMembers,
                            showHeader: scope.yearlyBoardOptions.showHeader,
                            taskDetailLevel: scope.yearlyBoardOptions.taskDetailLevel,
                            viewModeSelection: view
                        };
                        updateSettings();
                    }
                    
                    var currentBoardId = 0;
                    var loadBoard = function (boardId) {
                        if (currentBoardId == boardId) return;
                        scope.isLoading = true;
                        scope.hasBoard = true;
                        boardService.get(boardId).then(function (board) {
                            scope.boardData = board;
                            setTimeout(function () {
                                scope.isLoading = false;
                            }, 500);
                        })
                    }

                    function updateSettings() {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                boardId: scope.selectedBoard.id,
                                boardOptions: scope.yearlyBoardOptions
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                    scope.createBoard = function(){
                        var template = boardService.createYearlyTemplate();
                        var savetemplate = {
                            board: {
                                name: template.template.name,
                                boardType: template.template.boardType,
                                settings: template.template.settings
                            },
                            columns: [],
                            rows: []
                        };
                        // set attributes
                        if (template.attributes) savetemplate.attributes = template.attributes;
                        // Parse columns
                        template.columns.forEach(function (col) {
                            savetemplate.columns.push(
                                {
                                    name: col.name,
                                    weekdayName: col.weekdayName,
                                    sortOrder: col.sortOrder,
                                    categoryStyle: JSON.stringify(col.categoryStyle)
                                });
                        });
                        template.rows.forEach(function (row) {
                            savetemplate.rows.push(
                                {
                                    name: row.name,
                                    sortOrder: row.sortOrder,
                                    categoryStyle: JSON.stringify(row.categoryStyle),
                                    weekdayName: row.weekdayName
                                });
                        });
                        var options = {
                            dashboardAsTemplateId: null,
                            assetId: scope.assetId,
                            operation: "newTaskBoard",
                            template: savetemplate,
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newFlexiboardConfiguration.html',
                            controller: 'newFlexiboardConfigurationController',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                            scope.publish("SendBoardCreated", result);                   
                            scope.boards.push(result.board);
                            scope.boardChanged(result.board);
                        });
                    }
                    
                    function resize() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 40;
                        var container = $(elem).find(".timeboard");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                    }

                    const resizeDebounced = debounce(resize, 100, false)
                    resizeDebounced()
                    scope.$on("widget-resized", function (event, args) {
                        resizeDebounced()
                    });

                }
            }
        }]);

