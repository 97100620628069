﻿angular.module("DigiLean").factory('smartcellService', ['$http', 'webApiUrl',
function ($http, webApiUrl) {
    function smartcellService() {
        var resource = "/smartcell";
        var resourceUrl = webApiUrl + resource;
        return {
            getClonedCellIdentificators: function(type) {   
                var url = resourceUrl + "/getClonedCellIdentificators/" + type;
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },

            get: function (id) {
                var url = resourceUrl + "/" + id;
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },
/*
            getValueForClonedCell: function(id) {   
                var url = resourceUrl + "/getValueForClonedCell/" + id;
                return $http.get(url).then(function (result) {
                    return result.data;
                });
            },*/
            getProjectValueForClonedCell: function (visualizationType, projectId) {
                var url = resourceUrl + "/getProjectValueForClonedCell";
                var visualizationProjectQuery = {
                    visualizationType: visualizationType,
                    projectId: projectId
                };
                return $http.post(url, visualizationProjectQuery).then(function (result) {
                    return result.data;
                });
            },

            updateDateStatus: function (dateStatus) {
                var url = resourceUrl + "/UpdateDateStatus";
                return $http.put(url, dateStatus).then(function (result) {
                    return result.data;
                });
            },

            updateCloneValue: function (update) {
                var url = resourceUrl + "/UpdateCloneValue";
                /*var smartCloneCellValue =
                {
                    id: update.id,
                    name: update.name,
                    type: update.type,
                    projectId: update.projectId,
                    deviationId: update.deviationId,
                    value: update.value
                }*/
                update.value = JSON.stringify(update.value);
                return $http.put(url, update).then(function (result) {
                    return result.data;
                });
            },
            removeClone: function (boardId, id) {
                var url = resourceUrl + "/removeClone/" + id;
                return $http.delete(url, boardId).then(function (result) {
                    return result.data;
                });
            },
        }
    }
    return smartcellService;
}]);