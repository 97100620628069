import { DatabaseType, DeployCustomer, Module } from "@api";
import { DigiLeanNgScope } from "@common/model/angularModel";
import { getAllDatabases } from "@common/services/customers/infrastructureService"
import * as customerService from "@common/services/customers/customerService"
import angular from "angular"
import toastr from "toastr"

interface IModule extends Module {
    isEnabled: boolean
}

interface IDeployScope extends DigiLeanNgScope {
    deployCustomer: DeployCustomer
    allowedModules: IModule[]
    customerConnectionstringNames: string[]
    customerDataConnectionstringNames: string[]
    customerStorageConnectionstringNames: string[]
    isSelectedModule: (module: IModule) => void
    toggleModule: (module: IModule) => void
    languages: any
    changeLanguage: (languageCode: string) => void
    isSelectedLang: (l: any) => boolean
    changeCurrency: (currency: string) => void
    deploy: () => void
    close: () => void
}
angular
    .module('DigiLean')
    .controller("deployCustomerDialogController", ['$scope', '$uibModalInstance', 'modulesService', 'customer', 'languages',
        function ($scope: IDeployScope, $uibModalInstance, modulesService, customer, languages) {

            $scope.customerConnectionstringNames = []
            $scope.customerDataConnectionstringNames = []
            $scope.customerStorageConnectionstringNames = []
            $scope.customerName = customer.name;
            
            $scope.deployCustomer = {
                id: customer.id,
                connectionStringName: customer.connectionStringName,
                dataConnectionStringName: customer.dataConnectionStringName,
                storageConnectionStringName: customer.storageConnectionStringName,
                language: "",
                currency: "",
                modules: []
            }
            $scope.allowedModules = []
            modulesService.getCustomerAllowedModulesForCustomer(customer.id).then(modules => {
                $scope.allowedModules = modules.map(m => {
                    m.isEnabled = true
                    return m
                })
            })

            $scope.isSelectedModule = function(module) {
                return !!module.isEnabled
            }
            $scope.toggleModule = function(module) {
                module.isEnabled = !module.isEnabled
            }
            $scope.languages = languages

            $scope.changeLanguage = function (languageCode) {
                $scope.deployCustomer.language = languageCode;
            }
            $scope.isSelectedLang = function(l) {
                return $scope.deployCustomer.language == l.languageCode
            }
            $scope.changeCurrency = function (currency) {
                $scope.deployCustomer.currency = currency
            }

            $scope.deployingInProgress = false
            $scope.deploy = async function () {
                if ($scope.deployingInProgress)
                    return

                $scope.deployingInProgress = true
                
                const selectedModules = $scope.allowedModules.filter(m => m.isEnabled)
                $scope.deployCustomer.modules = selectedModules
                
                try {
                    const deployedCustomer = await customerService.deployCustomer($scope.deployCustomer)
                    $uibModalInstance.close(deployedCustomer)

                } catch (error: any) {
                    console.log(error)
                    if (error.message) {
                        toastr.error(error.message, "Error", {timeOut: 15000})
                    } else
                        toastr.error("something went wrong!")
                }
    
            }

            $scope.close = function () {
                
                $uibModalInstance.dismiss('cancel')
            }

            getAllDatabases().then(dbs => {
                if (!dbs || dbs.length == 0)
                    return
                $scope.customerConnectionstringNames = dbs.filter(d => d.type === DatabaseType.Customer).map(d => d.connectionStringName)
                $scope.customerDataConnectionstringNames = dbs.filter(d => d.type === DatabaseType.CustomerData).map(d => d.connectionStringName)
                $scope.customerStorageConnectionstringNames = dbs.filter(d => d.type === DatabaseType.StorageAccount).map(d => d.connectionStringName)
            })
        }])