var DigiLean = angular.module('DigiLean');
DigiLean.directive("strategyGoalResourceLinks", ['$filter', '$state','a3Service','strategyService', 'navigationService', '$uibModal',
    function ($filter,$state, a3Service, strategyService, navigationService, $uibModal) {
        return {
            templateUrl: 'strategyGoalResourceLinks.html',
            restrict: 'E',
            scope: {
                'strategyGoalId': '<',
                'assetId': '<',
            },
            link: function (scope, elem, attrs) {

                scope.hasA3Module = navigationService().hasModule("A3");
                scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                    scope.hasA3Module = navigationService().hasModule("A3");
                });

                scope.boards = [];
                scope.dashboards = [];
                scope.a3s = [];
                scope.isLoading = true;
                scope.$watch("strategyGoalId", function () {
                    loadData();
                })

                scope.$watch("assetId", function () {
                    loadData();
                })

                function loadData(){
                    if (scope.strategyGoalId) {
                        strategyService().getBoardsForStrategyGoal(scope.strategyGoalId,scope.assetId).then(function (boards) {
                            scope.boards = boards;
                            scope.isLoading = false;
                        });
                        strategyService().getDashboardsForStrategyGoal(scope.strategyGoalId, scope.assetId).then(function (dashboards) {
                            scope.dashboards = dashboards;
                            scope.isLoading = false;
                        });
                        strategyService().getA3sForStrategyGoal(scope.strategyGoalId, scope.assetId).then(function (a3s) {
                            scope.a3s = a3s;
                            scope.isLoading = false;
                        });
                    }
                }

                scope.createNewBoard = function () {
                    if(scope.strategyGoalId){
                        $state.go('newFlexiboard', { assetid: scope.assetId, strategygoalid:scope.strategyGoalId }, { reload: false });
                    }
                }

                scope.createNewA3 = function () {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'newA3Form.html',
                        controller: 'newA3ModalController',
                        resolve: {
                            a3: function () {
                                return {
                                    id: 0,
                                    title: "",
                                    assetId: scope.assetId,
                                    category: "",
                                    goal: ""
                                }
                            }

                        }
                    });
                    modalInstance.result.then(function (result) {
                        if(result && result.id){
                            a3Service().addStrategyGoalToA3(result.id, scope.strategyGoalId).then(function (saved) {
                            });
                        }
                    });

                };
            }

        }
    }]);
