angular.module("DigiLean").factory('projectMessageService', ['$http', 'webApiUrl',
    function ($http, webApiUrl) {
        var resource = "/projectmessage";
        var resourceUrl = webApiUrl + resource;
        return {
            addMessage: function (projectId, message) {
                var url = resourceUrl + "/addMessage/" + projectId;
                return $http.post(url, message).then(function (result) {
                    return result.data;
                });
            },
            getMessages: function(projectId) {
                var url = resourceUrl + "/getMessages/" + projectId;
                return $http.get(url).then(function(result){
                    return result.data;
                });
            },
            editMessage: function (projectId, message) {
                var url = resourceUrl + "/editMessage/" + projectId;
                return $http.put(url, message).then(function (result) {
                    return result.data;
                });
            },
            deleteMessage: function(projectId, messageId) {
                var url = resourceUrl + "/deleteMessage/" + projectId + "/" + messageId
                return $http.delete(url).then(function (result) {
                    return result.data;
                });
            }
        };
    
    }]);