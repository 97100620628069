angular
.module('DigiLean')
.directive("actionlistDeviation", ['$filter', 'deviationService',
    function ($filter, deviationService) {
        return {
            templateUrl: 'actionlistDeviation.html',
            restrict: 'E',
            scope: {
                'assetid': '=',
                'deviationCount': '=',
                'deviationOverdueCount': '=',
                'readonly': '@'
            },
            link: function (scope, element, attrs) {
                //scope.tasks = [];

                scope.deviations = [];

                scope.$watch("assetid", function () {
                    if (!scope.assetid) return;
                    loadDeviations(scope.assetid);
                });

                scope.subscribe("DeviationActionListStatusChanged", function(status) {
                    var target = $filter('filter')(scope.deviations, { "id" : status.deviationId });
                    if (target.length == 1) {
                        target[0].deviationtaskCount = status.total;
                        target[0].deviationtaskOverdueCount = status.overdue;
                     
                    }
                    calculateTaskCounts(scope.deviations);
                });

                function calculateTaskCounts(deviations) {       
                        var total = 0;
                        var overdue = 0;
                        if(deviations){
                            for (var t=0; t<deviations.length; t++){
                                total += deviations[t].deviationtaskCount;
                                overdue += deviations[t].deviationtaskOverdueCount;
                            }
                        }
                        scope.deviationtaskCount = total;
                        scope.deviationtaskOverdueCount = overdue;
                        var status = {
                            total: scope.deviationtaskCount,
                            overdue: scope.deviationtaskOverdueCount
                        }
                        scope.publish("DeviationActionListCountChanged", status)

                }

                
                function loadDeviations(assetId) {
                    deviationService().getDeviationsForAssetWithActionItems(assetId).then(function(deviations) {                           
                        scope.deviations = deviations;
                        calculateTaskCounts(scope.deviations);
                    });
                }
            }
        }
    }]);
