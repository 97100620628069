angular
    .module('DigiLean')
    .directive("projectLinkToHubCell", ['$state',
        function ($state,) {
            return {
                templateUrl: 'projectLinkToHubCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.projectId = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            if (scope.model.project.id) {
                                scope.projectId = scope.model.project.id;
                            }
                        }
                        model.getSortValue = function () {
                            return scope.projectId;
                        }
                    });
                }
            }
        }]);