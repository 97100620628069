import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("smarttableTargetCell", ['$uibModal','navigationService','qualityService',
        function ($uibModal, navigationService, qualityService) {
            return {
                templateUrl: 'smarttableTargetCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'adminmode': '='
                },
                link: function (scope, elem, attrs) {
                    scope.value = 0;
                    scope.isAvailableInProjectBoard = false;
                    
                    scope.subscribe("UserAuthenticatedAndReady", function(profile)
                    {
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");   
                    });
                    scope.$watch("model", function (model) {
                        var value = scope.model.cell.value;
                        if (value) {
                            scope.value = value.value;
                            if (value.settings) {
                                scope.isAvailableInProjectBoard = value.settings.isAvailableInProjectBoard;
                                scope.projectId = value.settings.projectId;
                                scope.visualizationType = value.settings.visualizationType;
                            }
                        }
                        model.getSortValue = function() {
                            return scope.value;
                        }
                    });

                
                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                scope.value = cell.value.value;
                            }
                        }
                    });

                    
                    function openConfigurationModal(projectId){
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'targetProjectVisualizationForm.html',
                            controller: 'targetProjectVisualizationController',
                            resolve: {
                                projectId: function () {
                                    return projectId;
                                },
                                visualizationType: function () {
                                    return scope.visualizationType;
                                },
                            }
                        });

                        modalInstance.result.then(function (result) {
                            scope.visualizationType = result.type;
                            scope.projectId = result.projectId;
                            if(scope.projectId && scope.visualizationType){
                                scope.isAvailableInProjectBoard = true;
                                updateDateStatus();

                                var newsettings = {
                                    isAvailableInProjectBoard: true,
                                    projectId:scope.projectId,
                                    visualizationType: scope.visualizationType
                                };
                                scope.model.cell.value = {
                                    value: scope.value,
                                    settings: newsettings
                                };
                
                                scope.cellChangeHandler({ cell: scope.model.cell });
                            }
                        });
                        
                    }

                    
                    scope.openConfiguration = function () {

                        if(scope.projectId){
                            openConfigurationModal(scope.projectId);
                        } else {
                            boardService.getProjectForBoard(scope.model.column.boardId).then(function (projectlist) {
                                if(projectlist && projectlist.length > 0){
                                    return openConfigurationModal(projectlist[0].id);
                                } else {
                                    openConfigurationModal(null);
                                }
                            });
                        }

                        
                    };

                    
                    scope.setStatusValue = function (newValue) {
                        scope.value = newValue;

                        if(scope.model.cell.value && scope.model.cell.value.settings){
                            scope.model.cell.value = {
                                value: scope.value,
                                settings: scope.model.cell.value.settings 
                            }; 
                        }
                        else {
                            scope.model.cell.value = {
                                value: scope.value
                            };
                        }
                        
                        scope.cellChangeHandler({cell: scope.model.cell})
                        if(scope.isAvailableInProjectBoard){
                            updateDateStatus();
                        }

                    }

                    function updateDateStatus(){
                        if(scope.projectId && scope.visualizationType){

                        
                    var date = new Date();

                    var status = {
                        status: scope.value,
                        date: date,
                        areaId: null,
                        projectId: scope.projectId,
                        visualizationType: scope.visualizationType
                    };
                    qualityService().updateDateStatus(status).then(function (savedStatus) {
                        
                    });
                }
                }

                    function addDeviation(statusValue) {
                        var severity = DigiLean.hms.getDeviationSeverity(statusValue);
                        // Make sure task responsbile dropdown is closed if open.
                        var settings = {
                            severity: severity
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    }
                }
            }
        }]);
