import { Dashboard, DashboardTemplate, DashboardStrategyGoal, User, BoardCopyOptions, DashboardPage, 
    DashboardCreateFromTemplateOptions, CreateDashboardTemplateOptions, DashboardSettings, DashboardTitle, 
    DashboardWidget, ComponentSettings } from "@api"

import backend from "@common/services/backendHttp"

const resourceUrl = "api/dashboard"

export function get(id: number) {
    const url = resourceUrl + "/getdashboard/" + id
    return backend.get<Dashboard>(url)
}

export function getTemplate(id: number) {
    const url = resourceUrl + "/getTemplate/" + id
    return backend.get<DashboardTemplate>(url)
}

export function getTemplates() {
    const url = resourceUrl + "/getTemplates"
    return backend.get<DashboardTemplate[]>(url)
}

export function getPreviewImageUrl(id: number) {
    const url = resourceUrl + "/GetPreviewImageUrl/" + id                
    return backend.get<string>(url)
}

export function generatePreview(id: number) {
    const url = "preview/generateDashboard/" + id
    return backend.post(url, {})
}

export function addStrategyGoalToDashboard(id: number, strategyGoalId: number) {
    const url = resourceUrl + "/" + id + "/addStrategyGoalToDashboard"
    return backend.post<DashboardStrategyGoal>(url, strategyGoalId)
}

export function getUserListForBoard(boardId: number) {
    const url = resourceUrl + "/getUserListForBoard/" + boardId
    return backend.get<User[]>(url)
}

export function getAll() {
    return backend.get<Dashboard[]>(resourceUrl)
}

export function getAllForAdmin() {
    const url = resourceUrl + "/getAllForAdmin"
    return backend.get<Dashboard[]>(url)
}

export function addDashboard(dashboard: Dashboard) {
    return backend.post<Dashboard>(resourceUrl, dashboard)
}

export function copyDashboard(id: number, targetOptions: BoardCopyOptions) {
    const url = resourceUrl + "/copyDashboard/" + id
    return backend.post<Dashboard>(url, targetOptions)
}

export function copyPage(id: number, targetOptions: BoardCopyOptions) {
    const url = resourceUrl + "/copyPage/" + id
    return backend.post<DashboardPage>(url, targetOptions)
}

export function createFromTemplate(id: number, targetOptions: DashboardCreateFromTemplateOptions) {
    const url = resourceUrl + "/createFromTemplate/" + id
    return backend.post<Dashboard>(url, targetOptions)
}

export function createTemplate(id: number, options: CreateDashboardTemplateOptions) {
    const url = resourceUrl + "/createTemplate/" + id
    return backend.post<DashboardTemplate>(url, options)
}

export function saveDashboard(dashboard: Dashboard) {
    const url = resourceUrl + "/" + dashboard.id;
    return backend.put<Dashboard>(url, dashboard)
}

export function updateSettings(id: number, settings: string) {
    const dashboardSettings: DashboardSettings = {
        dashboardId: id,
        settings: settings
    }
    const url = resourceUrl + "/updateSettings/" + id;
    return backend.put<DashboardSettings>(url, dashboardSettings)
}

export function updateTitle(id: number, title: string) {
    const dashboardTitle: DashboardTitle = {
        dashboardId: id,
        title: title
    };
    const url = resourceUrl + "/updateTitle/" + id;
    return backend.put<DashboardTitle>(url, dashboardTitle)
}

export function updateDashboardAsset(dashboard: Dashboard) {
    const url = resourceUrl + "/UpdateDashboardAsset/" + dashboard.id
    return backend.put(url, dashboard)
}

export async function saveComponentSettings(widgetId: number, settings: ComponentSettings) {
    if (widgetId <= 0)
        throw new Error(`ID can't be ${widgetId}`)
    const url = resourceUrl + "/SaveComponentSettings/" + widgetId
    return await backend.put(url, settings)
}

/**
 * 
 * @param widget Updates coordinates as well
 */
export function updateWidget(widget: DashboardWidget) {
    const widgetClone = structuredClone(widget) //new standard method in browsers API for deep cloning
    if (typeof widgetClone.settings == "object")
        widgetClone.settings = JSON.stringify(widgetClone.settings)
    const url = resourceUrl + "/updateWidget/" + widget.id
    return backend.put(url, widgetClone)
}


export function deleteDashboard(dashboardId: number) {
    const url = resourceUrl + "/" + dashboardId;
    return backend.delete(url)
}

export function deleteTemplate(templateId: number) {
    const url = resourceUrl + "/deleteTemplate/" + templateId;
    return backend.delete(url)
}

export function addPage(page: DashboardPage) {
    const url = resourceUrl + "/addPage"
    return backend.post<DashboardPage>(url, page)
}

export function deletePage(pageId: number) {
    const url = resourceUrl + "/deletePage/" + pageId
    return backend.delete<number>(url)
}

export function addWidget(widget: DashboardWidget) {
    const url = resourceUrl + "/addwidget"
    return backend.post<DashboardWidget>(url, widget)
}

export function copyWidget(id: number, placementWidget: DashboardWidget) {
    const url = resourceUrl + "/copyWidget/" + id
    return backend.post<DashboardWidget>(url, placementWidget)
}

export function deleteWidget(widgetId: number) {
    const url = resourceUrl + "/deleteWidget/" + widgetId;
    return backend.delete<number>(url)
}

export function tryFixLayout(dashboardId: number, pageId: number) {
    const url = `${resourceUrl}/${dashboardId}/FixLayout/${pageId}`
    return backend.get<DashboardWidget[]>(url)
}
export function tryFixLayoutWidgets(widgets: DashboardWidget[]) {
    const widgetsSettings = widgets.map(w => {
        if (w.settings && typeof w.settings !== "string") {
            w.settings = JSON.stringify(w.settings)
        }
        return w
    })
    const url = `${resourceUrl}/TryFixLayout`
    return backend.post<DashboardWidget[]>(url, widgetsSettings)
}
export function getPageRowMax(dashboardId: number) {
    return backend.get<number>(`${resourceUrl}/${dashboardId}/GetPageRowMax`)
}
export function getThemes() {
    return [
        {
            fillColor: "var(--digilean-secondary-background)",
            background: "var(--digilean-primary-text)",
            color: "inherit"
        },
        {
            fillColor: "#FFF6DF", // orange
            background: "#FCBB42"
        },
        {
            fillColor: "#EDF7E3", // green
            background: "#8CC152"
        },
        {
            fillColor: "#DFF3FB", //blue
            background: "#0099de" //"#3BAFDA"
        },
        {
            fillColor: "#FEE4DF", // red
            background: "#E9573F"
        },
        {
            fillColor: "#FBE9F3",  // pink
            background: "#D770AD"
        }

    ]
}