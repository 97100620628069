import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("goalEdit", ['$rootScope', '$filter', '$translate','strategyService', 'authService', 'themeService',
        function ($rootScope, $filter, $translate,strategyService, authService, themeService) {
            return {
                templateUrl: 'goalEdit.html',
                restrict: 'E',
                scope: {
                    'goal': "<",
                    'goaleditChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    var allColors = themeService().getColors();
                    scope.selectedColor = null;
                    scope.getAvailableColors = function (selectedColor) {

                        scope.availableColors = [];
                        strategyService().getBreakthroughObjectives().then(function (result) {
                            scope.goals = result;
                            for (var i = 0; i < allColors.length; i++) {
                                var colorUsed = $filter('filter')(scope.goals, { color: allColors[i] }, true);
                                if (colorUsed.length == 0) {
                                    scope.availableColors.push(allColors[i]);
                                }
                            }
                            if (selectedColor) {
                                scope.availableColors.unshift(selectedColor);
                            } else {
                                scope.selectedColor = scope.availableColors[0];
                            }
                            updateHandler();
                        });

                    }

                    scope.assetId = null;
                    scope.$watch("goal", function (goal) {
                        if (!goal) return;
                        scope.goal = goal;
                        scope.name = goal.name;
                        scope.selectedColor = goal.color;
                        scope.assetId = goal.assetId;

                        if (goal.parentId) {
                            scope.goalType = "SubGoal";
                        } else {
                            scope.goalType = "BreakThroughObjective";
                            scope.getAvailableColors(scope.selectedColor);
                        }

                        updateAsset();
                        updateHandler();
                    });

                    scope.textChanged = function (name) {
                        scope.name = name;
                        updateHandler();
                    }
                    scope.colorChanged = function (col) {
                        scope.selectedColor = col;
                        updateHandler();
                    }


                    scope.setAsset = function (asset) {
                        scope.selectAsset = asset;
                        if (asset) {
                            scope.assetId = scope.selectAsset.id;
                        }
                        updateHandler();
                    };

                    function updateAsset() {
                        if (!scope.assets) return;
                        if (!scope.assetId) return;
                        
                        var matchingAsset = $filter('filter')(scope.assets, { id: scope.assetId }, true);;
                        if (matchingAsset.length > 0) {
                            scope.selectAsset = matchingAsset[0];
                        }
                        
                        // 
                    }
                    function updateHandler() {
                        if (!scope.goaleditChangeHandler) return;
                        var goalEdit = {
                            name: scope.name,
                            assetId: scope.assetId,
                            color: scope.selectedColor,
                        }
                        scope.goaleditChangeHandler({ goalEdit: goalEdit })
                    }
                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                    $rootScope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                        if (scope.isAdmin) {
                            scope.canEdit = true;
                            scope.selectAsset = null;
                        }
                    });

                    scope.clearAsset = function () {
                        var emptyAsset = {
                            id : "",
                            name: $translate.instant('STRATEGY_TOP_LEVEL')
                        }
                        scope.assetId = ""; //must be empty string in order for backend to accept the parameter
                        scope.selectAsset = emptyAsset;
                        updateHandler();
                    };

                    scope.selectAsset = null;
                    function getAssetList() {
                        if (scope.isAdmin) {
                            assetService.getAllAssets(true).then(function (assets) {
                                scope.assets = assets;
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;
                                }
                                updateAsset();
                            });
                        } else {
                            assetService.getList().then(function (assets) {
                                scope.assets = assets;
                                if (scope.assets.length > 0) {
                                    scope.selectAsset = scope.assets[0];
                                }
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;

                                }
                                updateAsset();
                            });
                        }
                        // Set selected asset
                        
                    };
                    getAssetList();
                }
            }
        }]);