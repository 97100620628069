
angular
    .module('DigiLean')
    .controller("commonCellSelectionController", ['$scope', '$uibModalInstance', 'type',
        function ($scope, $uibModalInstance, type) {

            $scope.type = type;
        
            $scope.addColumn = function (columnid) {
                $uibModalInstance.close(columnid);
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

    
        }]);