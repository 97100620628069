import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"

@customElement('digilean-failed')
export class DigiLeanFailed extends LitElement {

    @state()
    open = false

    @property({attribute: true})
    dead = false

    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-block;
            user-select: none;
            /* -webkit-tap-highlight-color: transparent; */
        }
        
        #outer-box {
            display: inline-flex;
            box-sizing: border-box;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            transform: scale(1);
        }
        #outer-box.open {
            border: none;
            background: transparent
        }

        svg {
            position: absolute;
            z-index: -1;
            height: calc(var(--digilean-icon-height, 2rem) * 0.6);
            width: calc(var(--digilean-icon-width, 2rem) - 0.6);
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            /* transition-delay: 0.1s; */
            transform: translate3d(0, 0, 0);
        }
        
        svg#cross {
            transform: scale(0);
            transition: all 0.8s ease;
            stroke: var(--digilean-main-menu-background);
        }
        svg.circle {
            transform: scale(0);
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            transition: all 1s ease;
            /* transition: fill 250ms cubic-bezier(.4,.0,.23,1); */
        }
        
        #outer-box.open svg#circle-cross {
            transform: scale(1);
            z-index: 1;
            fill: var(--digilean-danger);
        }

        #outer-box.open.dead svg#circle-cross {
            fill: var(--digilean-primary-text);
        }
        
        #outer-box.open svg#cross {
            stroke: var(--digilean-white);
            transform: scale(1);
            stroke-dashoffset: 0;
            z-index: 1;
        }

        .bubble {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            z-index: -21;
            transform: scale(0);
            opacity: 1;
        }
        
        #outer-box.open #bubble-cross {
            /* fill: var(--digilean-danger); */
            stroke: var(--digilean-danger);
            stroke-width: 10;
            z-index: 1;
            transform: scale(1.6);
            opacity: 0;
            transition: all 1s ease-out;
        }
    `
    connectedCallback(): void {
        super.connectedCallback()
        setTimeout(() => {
            this.open = true
        }, 100)
        
    }
    disconnectedCallback(): void {
        super.disconnectedCallback()
        this.open = false
    }
    render() {
        const classes = {
            open: this.open,
            dead: this.dead
        }
        return html`
            <div id="outer-box" class=${classMap(classes)}>
                <svg id="bubble-cross" class="bubble" viewbox="0 0 120 120">
                    <circle cx="60" cy="60" r="60"></circle>
                </svg>
                <svg id="circle-cross" class="circle" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="cross" viewbox="0 0 12 12">
                    <polyline points="2 2 10 10"></polyline>
                    <polyline points="2 10 10 2"></polyline>
                </svg>
            </div>
        `
    }
}
