import { LogMessage, LogMessageType } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"

@customElement('logmessage-viewer')
export class JobRunStateViewer extends LitElement {
    
    static styles = css`
        :host {
            display: block;
        }
        article {
            display: flex;
            flex-direction: row;
            font-size: 1rem;
            gap: 0.6rem;
        }
        .error {
            color: var(--digilean-error);
        }
        .success {
            color: var(--digilean-success);
        }
        .warning {
            color: var(--digilean-warning)
        }
        .jobstartstop {
            color: var(--digilean-primary)
        }
    `

    @property({attribute: true})
    log: LogMessage | null = null

    get showjobpartid() {
        return this._showJobPartId.toString()
    }
    private _showJobPartId = false
    @property({attribute: true})
    set showjobpartid(value: string) {
        if (value == "false")
            this._showJobPartId = false
        else
            this._showJobPartId = true
    }

    render() {
        if (!this.log)
            return html`<span></span>`
        
        const classes = {
            error: this.log.type == LogMessageType.Error,
            success: this.log.type == LogMessageType.Success,
            warning: this.log.type == LogMessageType.Warning,
            jobstartstop: (this.log.type == LogMessageType.JobStarted || this.log.type == LogMessageType.JobFinished)
        }
        return html`
        <article class=${classMap(classes)}>
            <datetime-viewer date="${this.log.timestamp}"></datetime-viewer>
            <div>${this.log.type}</div>
            <div>${this.log.message}</div>
            ${this.log.exception ? html`<div>${this.log.exception}</div>` : ""}
            ${this.log.jobPartId && this._showJobPartId ? html`<div>jobPartId ${this.log.jobPartId}</div>` : ""}
        </article>
        `
    }
}
