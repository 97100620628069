import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("assetSelector", ['$filter', 'authService',
        function ($filter,authService) {
            return {
                templateUrl: 'assetselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.includeAll = true;
                    var sourceColumn = "assetId"; //default source column

                    function getAssetList() {
                        if(authService.hasRole("Admin")){
                            assetService.getAllAssets(true).then(function (assets) {
                                scope.assets = assets;
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                                }
                                applyFilter();         
                            });
                        } else {
                            assetService.getList().then(function (assets) {
                                scope.assets = assets;
                                for (var k = 0; k < scope.assets.length; k++) {
                                    scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                                }
                                applyFilter();         
                            });
                        }
                    };
                    getAssetList();

                    scope.$watch('source', function () {
                        if(scope.source && scope.source === 'followUpAssetId'){
                            sourceColumn = "followUpAssetId";
                        }
                        applyFilter();
                    });

                    scope.$watch('filters', function () {
                        applyFilter();
                    });

                    function applyFilter(){
                        if (scope.filters && scope.filters.length > 0 && scope.assets) {

                            var identifiers = [];
                            var selectedFilter = scope.filters[0];
                            if (selectedFilter && selectedFilter.items.length > 0) {

                                selectedFilter.items.forEach(item => {
                                    identifiers.push(parseInt(item));
                                });
                                selectedFilter.items = identifiers;
                            }
                            
                            for (var k = 0; k < scope.assets.length; k++) {
                                if(selectedFilter.items.length > 0 ){
                                    // Set asset as filter if exists
                                    var exists = false;
                                    selectedFilter.items.forEach(item => {
                                        if (parseInt(item) == scope.assets[k].id) {
                                            exists = true;
                                        }
                                    });
                                    scope.assets[k].include = exists;
                                } else {
                                    scope.assets[k].include = false;   
                                }
                                            
                            }

                            var selected = $filter('filter')(scope.assets, { include: true });
                            if (selected.length === scope.assets.length && selected.length === 0) {
                                scope.includeAll = true;
                            } else {
                                scope.includeAll = false;
                            }
                        } else if (scope.assets) {
                            for (var i = 0; i < scope.assets.length; i++) {
                                scope.assets[i].include = true;                     
                            }
                        }
                    }

                    scope.toggleAll = function (include) {
                        for (var k = 0; k < scope.assets.length; k++) {
                            scope.assets[k].include = include;                     
                        }
                        if(include){
                            scope.modifyFilter();
                        } 
                    };

                    scope.modifyFilter = function(){
                        var selected = $filter('filter')(scope.assets, { include: true });
                        if(selected.length == 0){ //if no items are selected then all item should be included. It is not possible to filter zero items
                            scope.includeAll = true;
                            scope.filters = null;
                            for (var k = 0; k < scope.assets.length; k++) {
                                scope.assets[k].include = true;                     
                            }
                        } else if(selected.length === scope.assets.length){ 
                            scope.includeAll = true;
                            scope.filters = null;
                        } else {
                            scope.includeAll = false;
                            var selectedItems = [];
                            for(var i=0; i < selected.length; i++){
                                 selectedItems.push(selected[i].id);
                            }
                            scope.filters = [{
                                sourceColumn: sourceColumn,
                                operator: "InList",
                                items: selectedItems
                            }];
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: sourceColumn, filter: scope.filters });
                    };

                }
            }
        }]);
