import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

angular
    .module("DigiLean")
    .directive('performanceBoard', ['$filter', '$translate', '$uibModal', '$state', 'modalService',
        function ($filter, $translate, $uibModal, $state, modalService) {
            return {
                templateUrl: 'performanceboard.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'model': '<',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    var idName = null;
                    scope.isProcessing = false;

                    scope.boardSettings = {
                        countdown: {showCountdown: true, countdownMinutes:15},
                        incidentfilter: 'ASSET'
                    }

                    scope.$watch('model', function (model) {
                        if (!model) return;
                        scope.model = model; // Should be board object
                        scope.assetId = model.board.assetId;
                        scope.boardId = model.board.id;
                        
                        if(model.board.settings){
                            if (typeof (model.board.settings) === "string") {
                                scope.boardSettings = JSON.parse(model.board.settings);
                            } else if (model.board.settings){
                                scope.boardSettings = model.board.settings;
                            } else {
                                scope.boardSettings = {
                                    countdown: {showCountdown: true, countdownMinutes:15},
                                    incidentfilter: 'ASSET'
                                }
                            }               
                        }
                        applySetting();

                        // Parse columns
                        var columns = [];
                        model.columns.forEach(col => {
                            var settings = JSON.parse(col.settings);
                            // init app Settings
                            settings.apps.forEach(app => {
                                switch (app.type) {
                                    case "pareto":
                                        app.settings = getParetoSettings(settings.deviationTypeId, scope.assetId);
                                        break;
                                    case "deviationActionListResult":
                                        app.settings = getActionListSetting(settings.deviationTypeId, scope.assetId);
                                        break;
                                    case "deviationSeverity":
                                        app.settings = getDeviationSeveritySetting(settings.deviationTypeId);
                                        break;
                                    default:
                                        app.settings = {
                                            assetId: scope.assetId,
                                            deviationTypeId: settings.deviationTypeId,
                                            title: " ",
                                            identificationName: idName
                                        }
                                        break;
                                }
                            })
                            columns.push({
                                id: col.id,
                                title: col.name,
                                sortOrder: col.sortOrder,
                                settings: settings,
                                type: settings.apps[0].type,
                                deviationTypeId: settings.deviationTypeId
                            })
                        });
                        scope.columns = columns;
                        if (scope.isAdminMode) {
                            // Need to use delay, to allow config template to load.
                            setTimeout(() => {
                                scope.editConfiguration();
                            }, 100);
                            
                        }
                    });

                    function applySetting(){
                        if(scope.boardSettings.incidentfilter == "ALL") {
                            idName = $translate.instant('COMMON_ALL');
                            scope.assetId = null;
                        } else if (scope.model.board.asset) {
                            idName = scope.model.board.asset.name;
                            scope.assetId = scope.model.board.assetId;
                        }
                    }

                    scope.edit = function () {
                        var _options = {
                            targetType: "Board",
                            targetId: scope.model.board.id,
                            includeCustomGroup: false,
                            includeProjects: false
                        };
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardSettings.html',
                            controller: 'boardSettingsController',
                            resolve: {
                                options: function options() {
                                    return _options;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                            if(result.assetId != scope.model.board.assetId){
                                location.reload(false);

                            } else { //possibly changed title
                                scope.model.board.name = result.name;
                            }
                            
                        }, function () {});
                    };

                    scope.deleteBoard = function () {
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.ADMIN_DELETED_BOARD,
                                headerText: translations.COMMON_DELETE + ' ' + scope.model.board.name + '?',
                                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
                            };

                            modalService.showModal({}, modalOptions).then(function (result) {
                                boardService.deleteBoard(scope.model.board.id).then(function () {
                                    $state.go('flexiboards');
                                    scope.publish("SendBoardDeleted", scope.model.board.id);
                                });
                            });

                        });
                    }

                    scope.editConfiguration = function () {
                        scope.isAdminMode = false;
                        var model = {
                            columns: [],
                            settings: {
                                incidentfilter: scope.boardSettings.incidentfilter
                            }
                        };
                        scope.columns.forEach(col => {
                            model.columns.push({
                                title: col.title,
                                type: col.type,
                                deviationTypeId: col.deviationTypeId,
                                id: col.id
                            });
                        });
                        // Open dialog
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'performanceboardConfigController.html',
                            controller: 'performanceboardConfigController',
                            resolve: {
                                model: model
                            }
                        });

                        modalInstance.result.then(function (result) {
                            scope.isProcessing = true;
                            checkBoardSettings(result.settings);
                            
                       
                            var listOperations = [];
                            // Resolve if existing col is updated or deleted
                            var columns = scope.columns;
                            columns.forEach(col => {
                                var exists = $filter('filter')(result.columns, { id: col.id }, true);
                                if (exists.length > 0) {
                                    var resultColumn = exists[0];

                                    var lastRow = "deviationActionListResult";
                                    if(result.settings && result.settings.incidentfilter == "ALL"){
                                        lastRow = "deviationSeverity";
                                    }

                                    // Update app
                                    var colSettings = {
                                        deviationTypeId: resultColumn.deviationTypeId,
                                        apps: [
                                            { type: resultColumn.type },
                                            { type: col.settings.apps[1].type },
                                            { type: lastRow }
                                        ]
                                    }
                                    exists[0].columnSettings = colSettings;
                                    var cat = {
                                        name: resultColumn.title,
                                        categoryStyle : JSON.stringify(colSettings)
                                    }
                                    listOperations.push(boardService.updateCategory(col.id, cat).then(function(data) {}))
                                } else {
                                    // delete
                                    listOperations.push(boardService.deleteCategory(col.id).then(function() {}));
                                }
                            });
                            // Check which one is new.
                            result.columns.forEach(item => {
                                var exists = $filter('filter')(columns, { id: item.id }, true);
                                if (exists.length == 0) {
                                    // add operation.
                                    var lastRow = "deviationActionListResult";
                                    if(result.settings && result.settings.incidentfilter == "ALL"){
                                        lastRow = "deviationSeverity";
                                    }
                                    var colSettings = {
                                        deviationTypeId: item.deviationTypeId,
                                        apps: [
                                            {
                                                type: item.type,
                                            },
                                            {
                                                type: "pareto",
                                            },
                                            {
                                                type: lastRow,
                                            },
                                        ]
                                    }
                                    item.columnSettings = colSettings;
                                    var boardCategory = {
                                        boardId : scope.boardId,
                                        name: item.title,
                                        isColumnCategory: true,
                                        categoryStyle : JSON.stringify(colSettings)
                                    }
                                    listOperations.push(boardService.createCategory(scope.boardId, boardCategory).then(function(data) {
                                        item.id = data.id;
                                    }));
                                } else if (result.settings && result.settings.incidentfilter == "ALL"){
                                    item.columnSettings.apps[2].type = "deviationSeverity";
                                }
                            })
                            // 
                            // Run all change operations
                            Promise.all(listOperations).then(() => {
                                var sortOrders = [];
                                var index = 0;
                                result.columns.forEach(item => { 
                                    item.sortOrder = index;
                                    sortOrders.push({
                                        id: item.id,
                                        sortOrder : index  
                                    });
                                    index += 1;
                                });
                                boardService.changeSortOrder(scope.boardId, sortOrders).then(function() {
                                    createColumns(result.columns); // Recreate
                                });
                                
                            });                      
                        });
                    }

                    scope.toogleShowCountdown = function(){
                        var showcountdown = scope.boardSettings.countdown.showCountdown;
                        var updatedcountdownMinutes = scope.boardSettings.countdown.countdownMinutes;
                        var incidentfilter = scope.boardSettings.incidentfilter;
                        boardService.updateSettings(scope.boardId, scope.boardSettings).then(function () {
                            scope.boardSettings = {
                                countdown: {showCountdown: showcountdown, countdownMinutes:updatedcountdownMinutes},
                                incidentfilter: incidentfilter
                            }
                        });
                    }

                    scope.updateCountdownMinutes = function(){
                        if(scope.boardSettings.countdown.countdownMinutes){
                            var showcountdown = scope.boardSettings.countdown.showCountdown;
                        var updatedcountdownMinutes = scope.boardSettings.countdown.countdownMinutes;
                        var incidentfilter = scope.boardSettings.incidentfilter;
                        boardService.updateSettings(scope.boardId, scope.boardSettings).then(function () {
                            scope.boardSettings = {
                                countdown: {showCountdown: showcountdown, countdownMinutes:updatedcountdownMinutes},
                                incidentfilter: incidentfilter
                            }
                        });
                        }
                    }
                    function checkBoardSettings(settings){
                        if(settings && scope.boardSettings.incidentfilter != settings.incidentfilter){
                            scope.boardSettings.incidentfilter = settings.incidentfilter;
                            applySetting();
                            boardService.updateSettings(scope.boardId, scope.boardSettings).then(function () {
                            });
                        }
                    }

                    function createColumns(columns) {
                        scope.columns.splice(0, 50); // Clear existing setup.
                        columns.forEach(item => {
                            item.columnSettings.apps.forEach(app => {
                                switch (app.type) {
                                    case "pareto":
                                        app.settings = getParetoSettings(item.columnSettings.deviationTypeId, scope.assetId);
                                        break;
                                    case "deviationActionListResult":
                                        app.settings = getActionListSetting(item.columnSettings.deviationTypeId, scope.assetId);
                                        break;
                                    case "deviationSeverity":
                                        app.settings = getDeviationSeveritySetting(item.columnSettings.deviationTypeId);
                                        break;
                                    default:
                                        app.settings = {
                                            assetId: scope.assetId,
                                            deviationTypeId: item.columnSettings.deviationTypeId,
                                            title: " ",
                                            identificationName: idName
                                        }
                                        break;
                                }
                            })
                            scope.columns.push({
                                id: item.id,
                                title: item.title,
                                sortOrder: item.sortOrder,
                                settings: item.columnSettings,
                                type: item.columnSettings.apps[0].type,
                                deviationTypeId: item.columnSettings.deviationTypeId
                            })
                        })           
                        scope.isProcessing = false;
                    }

                    
                    function getDeviationSeveritySetting(deviationTypeId) {
                        var filter = [
                            {
                                sourceColumn: "deviationTypeId",
                                operator: "InList",
                                items: [deviationTypeId]
                            }
                        ]
                        return {
                            autoSize: true,
                            title: $translate.instant("DEVIATION_SEVERITY"),
                            dataSource: {
                                id: "DeviationNew",
                                title: $translate.instant('COMMON_COUNT'),
                                valueElement: {
                                    unit: "Number",
                                    label: "Number"
                                }
                            },
                            timePeriod:{
                                timeframe:"YTD",
                                period: timeService.getTimePeriod("YTD")
                            },
                            groupedBy: "severity",
                            groupingIntervall: "month",
                            filters: filter
                        };
                    }
                    function getParetoSettings(deviationTypeId, assetId) {
                        var filter = [
                            {
                                sourceColumn: "deviationTypeId",
                                operator: "InList",
                                items: [deviationTypeId]
                            }
                        ]
                        if(assetId){
                            filter.push(
                                {
                                    sourceColumn: "assetId",
                                    operator: "InList",
                                    items: [assetId]
                                })
                        }
                        return {
                            autoSize: true,
                            title: $translate.instant("DEVIATION_SUMMARY_CATEGORIES"),
                            dataSource: {
                                id: "DeviationNew",
                                title: $translate.instant('COMMON_COUNT'),
                                valueElement: {
                                    unit: "Number",
                                    label: "Number"
                                }
                            },
                            timePeriod:{
                                timeframe:"YTD",
                                period: timeService.getTimePeriod("YTD")
                            },
                            groupedBy: "problemCategoryId",
                            groupingIntervall: "month",
                            filters: filter
                        };
                    }
                    function getActionListSetting(deviationTypeId, assetId) {
                        var filter = [
                            {
                                sourceColumn: "deviationTypeId",
                                operator: "InList",
                                items: [deviationTypeId]
                            }
                        ]
                        if(assetId){
                            filter.push(
                                {
                                    sourceColumn: "followUpAssetId",
                                    operator: "InList",
                                    items: [assetId]
                                })
                        }
                        return filter;
                    }

                    
                    

                    scope.newTask = function (column) {
                        if(!scope.users){
                            customerService.getAllUsers().then(function (users) {
                                scope.users = users;
                                openNewTask(column);
                            });
                        } else {
                            openNewTask(column); 
                        }
                    }

                    function openNewTask(column){
                        var task = {
                            id: 0,
                            boardId: scope.model.board.id,
                            boardDate: moment().toDate(),
                            title: "",
                            text: "",
                            columnCategoryId: column.id,
                            status: 'blank',
                            tags: "",
                            sortOrder: 0
                        }
                        var options = {
                            users: scope.users,
                            showDueDate: true
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                        });
                    }
                }
            }
        }]);

