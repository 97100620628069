import * as profileService from "@common/services/user/profileService"

var DigiLean = angular.module('DigiLean');  
DigiLean.controller('myprofilephotoController', ['$scope', '$uibModalInstance',
    function ($scope, $uibModalInstance) {
    
        $scope.originalImage = "";
        $scope.croppedImage = "";

        function getProfile() {
            profileService.getMyProfile().then(function(profile) {
                $scope.profile = profile;
                if (profile.profileImage) {
                    $scope.originalImage = profile.profileImage.fileUrl
                }
            });
        }
        getProfile();
    
        $scope.fileUrl = "";
        $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
        $scope.fileUploaded = function (file) {
            $scope.fileUrl = "";
            profileService.updateProfileImage(file.id).then(function () {
                getProfile();
            })
        };

        var formData = null;
        $scope.croppedImageUrl = null;
        $scope.cropSizeChanged = function() {
            if ($scope.croppedImage) {
                setTimeout(function() {
                    var blob = dataURLtoBlob($scope.croppedImage);
                    $scope.croppedImageUrl = URL.createObjectURL( blob );
                    formData = new FormData();
                    formData.append("myFile", blob, "cropped.jpg");
                }, 100);
            }            
        }

        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type:mime});
        }

        $scope.isProcessing = false;
        $scope.ok = function () {
            $scope.isProcessing = true;
            if (formData) {
                profileService.uploadProfileImage(formData).then(function(data){
                    profileService.updateProfileImage(data[0].id).then(function (profile) {
                        $uibModalInstance.close();
                        $scope.isProcessing = false;
                    })
                    
                })
            }
            
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);