
angular
    .module('DigiLean')
    .directive("taskAssignedMessage", ['$uibModal', 'boardTaskService','suggestionService','deviationService',
        function ($uibModal, boardTaskService,suggestionService, deviationService) {
            return {
                templateUrl: 'taskAssignedMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.task = null;
                    scope.isLoading = true;

                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        boardTaskService().get(payLoad.TaskId).then(function (task) {
                            scope.task = task;
                        });
                    });

                    scope.openTask = function (activeTab) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            // archiveAvailable: true,
                            // users: scope.users
                        }

                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardTask.html',
                            controller: 'boardTaskController',
                            resolve: {
                                task: function () {
                                    return scope.task;
                                },
                                activeTab: function () {
                                    return activeTab;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });
                    }

                    scope.openSuggestion = function () {
                        suggestionService().get(scope.task.improvementSuggestionId).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });

                    }

                    scope.openDeviation = function () {
                        deviationService().get(scope.task.deviationId).then(function (deviation) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });

                    }
                }
            }
        }]);