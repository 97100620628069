﻿import { handleErrorsAspNet } from "@common/services/error/aspNetErrorHandling"
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .controller("changePasswordModalController", ['$scope', '$uibModalInstance', '$translate',
        function ($scope, $uibModalInstance, $translate) {
            $scope.message = "";
            $scope.user = {
                currentPassword: "",
                password: "",
                confirmPassword: ""
            }
            $scope.ok = function () {
                $scope.errors = []
                $scope.message = ""
                
                customerService.changePassword($scope.user).then(function() {
                    $translate('ADMIN_PASSWORD_CHANGED_SUCCESSFULLY').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId
                    })
                    $uibModalInstance.close()
                }, function (response) {
                    let errors = handleErrorsAspNet(response.data)
                    
                    $scope.errors = errors
                    $translate('ADMIN_PASSWORD_CHANGED_FAILED').then(function (errormsg) {
                        $scope.message = errormsg
                    }, function (translationId) {
                        $scope.headline = translationId;
                    })
                })
            }

            $scope.close = function () {
                $translate('COMMON_CANCEL').then(function (text) {
                    $uibModalInstance.dismiss(text)
                }, function (translationId) {
                    $scope.headline = translationId
                })
                
            }
        }])
