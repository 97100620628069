angular
    .module('DigiLean')
    .directive("taskList", ['$filter', '$translate', 
        function ($filter, $translate) {
            return {
                templateUrl: 'taskList.html',
                restrict: 'E',
                scope: {
                   
                },
                link: function (scope, elem, attrs) {
                    scope.filter = [];
                    scope.filterChanged = function(filter) {
                        scope.filter = filter
                    }
                }
            }
        }]);
