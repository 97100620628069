import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("userSelectorDropdown", ['taskDrawingService',
        function (taskDrawingService) {
            return {
                templateUrl: 'userSelectorDropdown.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'userid': '=',
                    'name': '=',
                    'userChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.users = null;
                    scope.user = null;

                    scope.showname = "full";

                    scope.$watch("userid", function () {

                        if (!scope.users) {
                            customerService.getAllUsers().then(function (users) {
                                scope.users = users;
                                for (var i = 0; i < scope.users.length; i++) {
                                    var imageUrl = taskDrawingService().getProfileImageUrl(scope.users[i].userId, "ExtraSmall");
                                    if (imageUrl) {
                                        scope.users[i].profileImage = true;
                                        scope.users[i].profileImageUrl = imageUrl;
                                    } else {
                                        scope.users[i].profileImage = false;
                                    }
                                }
                                if (!scope.userid) {
                                    scope.user = null;
                                    return;
                                } else {
                                    scope.user = scope.users.find(u => u.userId == scope.userid);
                                }

                            });
                        } else if (scope.userid) {
                            scope.user = scope.users.find(u => u.userId == scope.userid);
                        } else if (!scope.userid) {
                            scope.user = null;
                            return;
                        }
                    });

                    scope.$watch("name", function () {
                        if(scope.name){
                            scope.showname = scope.name;
                        }      
                    });

                    scope.selectUser = function (user) {
                        scope.user = user;
                        if (user) {
                            scope.userid = user.userId;
                        } else {
                            scope.userid = null;
                        }
                        var updateduserinfo = {
                            model: scope.model,
                            user: scope.user
                        }
                        scope.userChangeHandler({ userinfo: updateduserinfo })
                    };
                }
            }
        }]);