import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("goalKpiTable", ['strategyService','mathService','dataService',
        function (strategyService,mathService,dataService) {
            return {
                templateUrl: 'goalKpiTable.html',
                restrict: 'E',
                scope: {
                    'goalId': '='
                },
                link: function (scope, elem, attrs) {
                    scope.kpis = null;
                    var decimals = 2;
                    var timePeriodYTD = timeService.getTimePeriod("YTD");

                    scope.$watch("goalId", function () {
                        if (scope.goalId) {
                            strategyService().getGoalKPIs(scope.goalId).then(function (result) {
                                scope.kpis = result;
                                for(var i=0; i<scope.kpis.length; i++){
                                    if(scope.kpis[i].dataSourceId || scope.kpis[i].targetDataSourceId){
                                        loadDataSourceValues(scope.kpis[i]);
                                    } else {
                                        checkOnTarget(scope.kpis[i]);
                                    }   
                                    scope.kpis[i].ytdActualLabel = getYTDLabel(scope.kpis[i], timePeriodYTD);     
                                }
                            });
                        }               
                    });

                    function loadDataSourceValues(kpi){
                        if(kpi.dataSourceId){
                            if (kpi.filter && typeof (kpi.filter) === "string") {
                                kpi.filter = JSON.parse(kpi.filter);
                            }
                            var formula = kpi.formula;
                            if(!formula && kpi.unit == "%"){
                                formula = "average";
                            }
                            var options = {
                                timePeriod: timePeriodYTD,
                                filters: kpi.filter,
                                formula: formula
                            };
                            dataService().getSumForPeriod(kpi.dataSourceId, options).then(function (sum) {
                                if(sum){
                                    kpi.ytdActualValue = sum;
                                } else {
                                    kpi.ytdActualValue = 0;
                                } 
                                getLastValue(kpi);
                            });
                        } else {
                            loadTargetDataSourceValues(kpi);
                        }
                    }

                    // YTDLabel represent januar to last month
                    function getYTDLabel(kpi, timePeriod){
                        var januaryLabel = timeService.getMonthLabel(1);
                        var year = moment().year();
                        var currentMomentMonth = moment().month(); // cur
                        var label = timeService.getMonthLabel(1) + " " + year;
                        if(currentMomentMonth >= 2){ 
                            label = label + " - " + timeService.getMonthLabel(currentMomentMonth) + " " + year;
                        }
                        return label;
                    }

                    function loadTargetDataSourceValues(kpi){
                        if(kpi.targetDataSourceId){
                            if (kpi.targetFilter && typeof (kpi.targetFilter) === "string") {
                                kpi.targetFilter = JSON.parse(kpi.targetFilter);
                            }
                            var options = {
                                timePeriod: timePeriodYTD,
                                filters: kpi.targetFilter
        
                            };
                            dataService().getSumForPeriod(kpi.targetDataSourceId, options).then(function (sum) {
                                if (kpi.formula == 'average' || (!formula && kpi.unit == "%")) { //Can we assume this also for planned datasource?
                                    dataService().getNumberOfValuesForPeriod(kpi.targetDataSourceId, options).then(function (numberOfValues) {
                                        kpi.ytdPlannedValue = sum / numberOfValues;
                                    });
                                } else {
                                    if(sum){
                                        kpi.ytdPlannedValue = sum;
                                    } else {
                                        kpi.ytdPlannedValue = 0;
                                    }           
                                }
                                if(kpi.lastValueYear && kpi.lastValueMonth){
                                    var timePeriod = getMonthTimePeriod(kpi);
                                    var options = {
                                        timePeriod: timePeriod,
                                        filters: kpi.targetFilter,
                                    };
                                    dataService().getSumForPeriod(kpi.targetDataSourceId, options).then(function (sum) {
                                        if(sum && sum !== ''){
                                            kpi.lastPlannedValue = sum;  
                                        } else {
                                            kpi.lastPlannedValue = null;
                                        }
                                        checkOnTarget(kpi);                        
                                    });
                                }

                            });
                        } else {
                            checkOnTarget(kpi);  
                        }
                    }

                    function getLastValue(kpi){
                        var startDate = moment("2015-01-01");
                        var endDate = moment();
                        var timePeriod = {
                            from: startDate.utc().toDate().toJSON(),
                            to: endDate.utc().toDate().toJSON()
                        };
                        dataService().getLastDate(kpi.dataSourceId, timePeriod, kpi.filter).then(function (lastDate) {
                            var fromDate = moment(lastDate).startOf('month');
                            var toDate = moment(lastDate).endOf('day');
                            kpi.lastValueMonth = toDate.month()+1;
                            kpi.lastValueYear = toDate.year();
                            setTooltipData(kpi);
                            var dayperiod = {
                                from:fromDate,
                                to:toDate
                            };
                        
                            var formula = kpi.formula;
                            if(!formula && kpi.unit == "%"){
                                formula = "average";
                            }
                            var options = {
                                timePeriod: dayperiod,
                                filters: kpi.filter,
                                formula: formula
                            };
                            dataService().getSumForPeriod(kpi.dataSourceId, options).then(function (sum) {
                                kpi.lastActualValue = sum;   
                                loadTargetDataSourceValues(kpi);                         
                            });
                        });

                    }
                    
                    function getMonthTimePeriod(kpi){
                        var startDate = moment().set({'year': kpi.lastValueYear, 'month': kpi.lastValueMonth-1, 'date':1}).startOf('day');
                        var endDate = moment(startDate).endOf("month");
                        timePeriod = {
                            from: startDate.utc().toDate().toJSON(),
                            to: endDate.utc().toDate().toJSON()
                        }; 
                        return timePeriod;
                    }

                    function setTooltipData(kpi){
                        if(kpi.lastValueMonth){
                            kpi.lastValueMonthLabel = timeService.getMonthLabel(kpi.lastValueMonth);
                        }

                    }

                    function checkOnTarget(kpi){
                        if(kpi.kpiOperator){
                            if(kpi.kpiOperator == '>='){
                                if(kpi.lastPlannedValue != null && kpi.lastActualValue != null){
                                    if(kpi.lastActualValue >= kpi.lastPlannedValue){
                                        kpi.lastOnTarget = true;
                                    } else {
                                        kpi.lastOnTarget = false;
                                    }
                                    setTooltipData(kpi);
                                }
                                if(kpi.ytdPlannedValue != null && kpi.ytdActualValue != null){
                                    if(kpi.ytdActualValue >= kpi.ytdPlannedValue){
                                        kpi.YTDOnTarget = true;
                                    } else {
                                        kpi.YTDOnTarget = false;
                                    }
                                }
                            
                                
                            } else if(kpi.kpiOperator == '<='){
                                if(kpi.lastPlannedValue != null && kpi.lastActualValue != null){
                                    if(kpi.lastActualValue <= kpi.lastPlannedValue){
                                        kpi.lastOnTarget = true;
                                    } else {
                                        kpi.lastOnTarget = false;
                                    }
                                    setTooltipData(kpi);
                                }
                                if(kpi.ytdPlannedValue != null && kpi.ytdActualValue != null){
                                    if(kpi.ytdActualValue <= kpi.ytdPlannedValue){
                                        kpi.YTDOnTarget = true;
                                    } else {
                                        kpi.YTDOnTarget = false;
                                    }
                                }    
                            }
                            kpi.ytdActualValue = mathService().roundNumber(kpi.ytdActualValue, decimals);
                            kpi.lastActualValue = mathService().roundNumber(kpi.lastActualValue, decimals);
                        
                        }
                        kpi.ytdPlannedValue = mathService().roundNumber(kpi.ytdPlannedValue, decimals);
                        kpi.lastPlannedValue = mathService().roundNumber(kpi.lastPlannedValue, decimals);
                    }

                }
            }
        }]);

        