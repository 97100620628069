import { getUserProfile } from "@common/stores/userStore"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("taskCommentMessage", ['$uibModal', 'boardTaskService',
        function ($uibModal, boardTaskService) {
            return {
                templateUrl: 'taskCommentMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.task = null;
                    scope.isLoading = true;
                    scope.users = [];
                    scope.selectedCommentId = 0;
                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        scope.selectedCommentId = payLoad.Id;
                        boardTaskService().get(payLoad.BoardTaskId).then(function (task) {
                            scope.task = task;
                            if (task.boardId && task.boardId > 0) {
                                boardService.getUserListForBoard(task.boardId).then(function (users) {
                                    scope.users = users;
                                });
                            }
                        });

                        scope.currentUser = getUserProfile();
                        boardTaskService().getCommentWithReplies(payLoad.BoardTaskId).then(function (model) {
                            scope.comments = model.comments;
                        });
               
                    });
                    
                    scope.saveComment = function(comment) {
                        comment.boardTaskId = scope.task.id;
                        return boardTaskService().addComment(comment);
                    };

                    scope.deleteComment = function(comment) {
                        return boardTaskService().deleteComment(scope.task.id, comment.id);
                    };

                    scope.openTask = function (activeTab) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            // archiveAvailable: true,
                            // users: scope.users
                        }

                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardTask.html',
                            controller: 'boardTaskController',
                            resolve: {
                                task: function () {
                                    return scope.task;
                                },
                                activeTab: function () {
                                    return activeTab;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });

                    }
                }
            }
        }]);