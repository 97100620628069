var DigiLean = angular.module('DigiLean');
DigiLean.controller('timeframeSelectorModal', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'timePeriod',
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, timePeriod) {
        //$scope.selected = "all";
        $scope.timePeriod = timePeriod;
        $scope.showTimeSelector = false;
        $scope.change = function (selected) {
            $scope.selected = selected;
            $scope.showTimeSelector = false;
            if (selected =="all"){
                $scope.timePeriod.timeframe = "all";   
            } else if (selected =="last"){
                $scope.timePeriod.timeframe = "last";        
            } else {
                $scope.timePeriod.timeframe = "all";
                $scope.showTimeSelector = true;
            }
            $scope.options = {
                timePeriod: $scope.timePeriod
            };
        }
        if($scope.timePeriod.timeframe === "all"){
            $scope.selected = "all";
            $scope.change($scope.selected);
        } else if($scope.timePeriod.timeframe === "last"){
            $scope.selected = "last";
        } else {
            $scope.selected = "timeframe"; 
            $scope.showTimeSelector = true;
        }

        $scope.options = {
            timePeriod: $scope.timePeriod
        };

        
        $scope.periodChangedHandler = function (timePeriod) {

            if (!timePeriod.timeframe) return;
            if ($scope.options.timePeriod && $scope.options.timePeriod.timeframe === timePeriod.timeframe && timePeriod.timeframe != "custom") return;

            if(timePeriod.timeframe == "custom" && timePeriod.period.to == $scope.options.timePeriod.period.to && timePeriod.period.from == $scope.options.timePeriod.period.from) return;
            $scope.options = {
                timePeriod: timePeriod
            };

        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            $uibModalInstance.close($scope.options.timePeriod);
        };

    }]);