angular
    .module('DigiLean')
    .directive("a3GoalCell", ['$filter', '$translate','a3Service',
        function ($filter, $translate, a3Service) {
            return {
                templateUrl: 'a3GoalCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.goal = "";
                    scope.active = false;
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";

                    scope.$watch('model', function (model) {
                        scope.goal = model.a3.goal;
                        model.getSortValue = function() {
                            return scope.goal;
                        }
                    });

                    
                    scope.subscribe("A3GoalChanged", function (a3) {
                        if (a3.a3Id == scope.model.a3.id) {
                            if (a3.goal) {
                                scope.goal = a3.goal;
                            } else {
                                scope.goal = "";
                            }
                        }
                    });

                    scope.setActive = function() {
                        scope.active = true;   
                        setTimeout(function() {
                            $(".a3-goal").focus();
                        }, 50 );                        
                    }
                    scope.goalChanged = function(goal) {
                        scope.active = false;  
                        if(goal !== scope.goal){    
                            var data = 
                            {
                                a3Id : scope.model.a3.id,
                                goal: goal
                            };
                            a3Service().updateA3Goal(data).then(function (result) {                         
                                });
                            
                        }
                    }
                }
            }
        }]);
