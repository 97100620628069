import { JobRunLog, JobRunResult, JobRunState } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { formatDateTime} from "@common/services/datetime/dateTimeFormattingLocale"

@customElement('jobrun-state')
export class JobRunStateViewer extends LitElement {

    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-block;
        }
    `
    @property({attribute: true})
    state: JobRunState = JobRunState.Running

    @property({attribute: true})
    result: JobRunResult = JobRunResult.Success

    @property({attribute: true})
    date: string = ""

    render() {
        let dateFormatted = ""
        if (this.date) {
            let date = new Date(this.date)
            dateFormatted = `start ${formatDateTime(date)}`
        }
        this.title = `${this.state} ${dateFormatted}`
        if (this)

        switch (this.state) {
            case JobRunState.Running:
                return html`<digilean-working></digilean-working>`
            case JobRunState.Completed:
                 if (this.result == JobRunResult.Failed)
                    return html`<digilean-failed></digilean-failed>`
                return html`<digilean-checked></digilean-checked>`
            
            // case JobRunState.WithErrors:
            //     return html`<digilean-ok-errors></digilean-ok-errors>`
            default:
                return html`<span></span>`
        }
    }
}
