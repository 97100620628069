﻿import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("costTemplate", ['$filter',
        function ($filter) {
            return {
                templateUrl: 'costTemplate.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'model': '='
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('model', function () {
                        if (!model) return;
                        var model = scope.model;    
                        if (model.style && typeof (model.style) === "string") {
                            scope.style = JSON.parse(model.style);
                        }
                        getCompanySetting("currency").then(function (setting) {
                            if (setting) {
                                scope.currency = setting
                            }
                        })
                    });

                    scope.currency = customerService.getDefaultCurrency()
                    
                }
            }
        }]);
