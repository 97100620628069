import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('myMessagesController', ['$scope', '$state', '$filter', '$translate','$uibModal', 'authService',
    function ($scope, $state, $filter, $translate, $uibModal, authService) {
        $scope.isLoading = true;
        $scope.canWrite = authService.hasRole("Admin") || authService.hasRole("MessageWriter");
        $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
            var hasAccess = $filter('filter')(profile.modules, { name: "IMPROVEMENT" });
            $scope.canWrite = authService.hasRole("Admin") || authService.hasRole("MessageWriter");
        });

        $scope.allMessages = [];
        $scope.messages = [];

        $scope.subscribe("MessageReceived", function () {
            getMessages();
        });
        
        $scope.subscribe("ProjectMessageAdded", function () {
            getMessages();
        });

        $scope.subscribe("ProjectMessageEdited", function () {
            getMessages();
        });
        $scope.subscribe("ProjectMessageDeleted", function () {
            getMessages();
        });
        $scope.goTo = function (msg) {
            if(msg.projectId != null){
                $state.go('projectDetails', { id: msg.projectId }, { reload: false });
            }
        }

        function getMessages() {
            customerService.getMessages().then(function (result) {
                $scope.allMessages = result;
                $scope.isLoading = false;
                $scope.allMessages.forEach(message => {
                    // replace linebreaks
                    message.text = message.text.replace(/\r\n|\r|\n/g, "<br />");
                    if (message.isPublic){
                        message.typeColor = "#5ab700";
                        message.type = $translate.instant('COMMON_ALL');
                    } else if (message.recipientUserId != null){
                        message.typeColor = "#F8AC59"
                        message.type = $translate.instant('COMMON_PERSONAL');
                    } else if (message.projectId != null){
                        message.typeColor = "#0099DE"
                        message.type = $translate.instant('COMMON_PROJECT');
                    } else if (message.assetId != null){
                        message.typeColor = "#f0ad4e"
                        message.type = $translate.instant('COMMON_TEAM_BOARD');
                    } else {
                        message.typeColor = "#D770AD"
                        message.type = $translate.instant('COMMON_ASSET');
                    }

                });
                calculateTimeElapsed();
            });
        }
        getMessages();

        $scope.createNew = function () {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newMessageModal.html',
                controller: 'newMessageModalController',
                windowClass: 'assetselector-modal-window',
                resolve: {
                }
            });
        };

        var calculateTimeElapsed = function () {
            // Recalculate time elapsed
            $scope.allMessages.forEach(function (message) {
                timeService.addTimeElapsedForMsg(message);
            });
        };


    }]);