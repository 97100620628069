import { UserProfileImageInfo, ProfileImage, User, FileInfo, UserInfo } from "@api"
import backend from "@common/services/backendHttp"
const resourceUrl = "api/profile"
const userResourceUrl = "api/user"
 
export const getUsersWithProfileImage = () => {
    const url = resourceUrl + "/GetUsersWithProfileImage/"
    return backend.get<UserProfileImageInfo[]>(url)
}

export const getProfileImageByUserName = (userName: string) => {
    const userNameQuery = {
        userName
    }
    const url = userResourceUrl + "/getProfileImageByUserName"
    return backend.post<string>(url, userNameQuery)
}

export const getProfileImage = (userId: string) => {
    const url = resourceUrl + "/getProfileImage/" + userId
    return backend.get<ProfileImage>(url)
}
export const getMyProfile = () => {
    const url = resourceUrl + "/getMyProfile/"
    return backend.get<User>(url)
}
export const updateProfileInfo = (profileInfo: UserInfo) => {
    var url = resourceUrl + "/updateProfileInfo/"
    return backend.put(url, profileInfo)
}
export const updateProfileImage = (imageId: string) => {
    var url = resourceUrl + "/updateProfileImage/" + imageId
    return backend.put<User>(url, {nothing: ""})
}

export const uploadProfileImage = (formData: FormData) => {
    var url = resourceUrl + "/uploadProfileImage"
    // var config = {
    //     headers: { 'Content-Type': undefined }
    // }
    return backend.postForm<FileInfo[]>(url, formData)
}