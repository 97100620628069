import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("deviationActionListResult", ['$filter', '$translate', 'deviationService', '$uibModal', 'NgTableParams','boardTaskService', 'actionlistService',
        function ($filter, $translate, deviationService, $uibModal, NgTableParams, boardTaskService, actionlistService) {
            return {
                templateUrl: 'deviationActionListResult.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                    'boardid': '=',
                    'columnid': '='
                },
                link: function (scope, elem, attrs) {

                    scope.columns = [];
                    scope.registrations = [];
                    scope.allUsers = [];
                    scope.selectedDataList = [];
                    scope.responsibleSetting = "noname";

                    var deviationTypeId = null;
                    var followupAssetId = null;


                    // Filter handling
                    scope.$watch('filter', function (filter) {
                        if (!filter) {
                            scope.filter = [];
                        } else {
                            for(var i=0; i< filter.length; i++){
                                if(filter[i].sourceColumn == 'deviationTypeId'){
                                    deviationTypeId = filter[i].items[0];
                                } else if(filter[i].sourceColumn == 'followUpAssetId'){
                                    followupAssetId = filter[i].items[0];
                                }
                            }
                        }
                        scope.getValues();
                    });

                    function getAllUsers(){
                        if(!scope.users){
                            customerService.getAllUsers().then(function (users) {
                                scope.users = users;
                            });
                        }
                    }
                    getAllUsers();

                    
                    scope.opened = [];
                    scope.overdueFilterActive = false;

                    scope.subscribe("NewTask", function (task) {
                        if (task.boardId === scope.boardid) {
                            scope.getValues();
                        }
                    });

                    scope.subscribe("DeviationActionItemAdded", function (item) {
                        if (item.deviationTypeId == deviationTypeId && item.assetId == followupAssetId){
                            scope.getValues();
                        }
                    });

                    scope.subscribe('TaskDeleted', function (id) {
                        var task = $filter('filter')(scope.items, { id: id }, true);
                        if (task.length > 0) {
                            var item = task[0];
                            var index = scope.items.indexOf(item);
                            if (index > -1) {
                                item.cssState = "animated slideOutLeft"; // Add animation
                                setTimeout(function () {
                                    scope.items.splice(index, 1);
                                }, 300);
                            }
                        }
                    });

                    scope.subscribe('TaskDescriptionUpdated', function (descriptionUpdate) {
                        var task = $filter('filter')(scope.items, { id: descriptionUpdate.taskId }, true);
                        if (task.length > 0) {
                            scope.$apply(function () {
                                task[0].title = descriptionUpdate.description;
                            });
                        }
                    });

                    scope.subscribe('TaskAssigned', function (status) {
                        var task = $filter('filter')(scope.items, { id: status.taskId }, true);
                        if (task.length > 0) {
                            scope.$apply(function () {
                                task[0].responsibleUserId = status.userId;
                            });
                        }/*
                        if (status.taskId == scope.model.task.id) {
                            var matchingUser = $filter('filter')(scope.users, { userId: status.userId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                                scope.model.task.responsibleUserId = scope.user.userId;
                                scope.model.task.responsibleUser= scope.user.fullName;
                                scope.model.task.responsibleDisplayName = scope.user.displayName;
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }*/
                    });

                    scope.subscribe('TaskDateUpdated', function (status) {
                        var task = $filter('filter')(scope.items, { id: status.taskId }, true);
                        if (task.length > 0) {
                            scope.$apply(function () {
                                task[0].boardDate = moment(status.actionDate).toDate();
                                task[0].isOverdue = isOverdue(task[0].boardDate, task[0].status);
                            });
                        }
                    });

                    scope.userChangeHandler = function (userinfo) {
                        if (userinfo) {
                            var user = userinfo.user;
                            var item = userinfo.model;
                            item.user = user;
                            // 
                            actionlistService().assignActionListItemUser(item.id, user).then(function () {    
                            }); 
                        }
                    }


                    scope.changeActionDate = function (item, $event) {
                        const date = $event.originalEvent.detail
                        item.boardDate = date
                        if (item.boardDate) { // Valid date format
                            item.error = false;
                            var actionDate = {
                                taskId: item.id,
                                actionDate: item.boardDate
                            };
                            actionlistService().updateItemActionDate(actionDate).then(function () {
                                //$translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                    // toastr.success(msg);
                                //});
                                // publish event on change
                                scope.publish("SendTaskDateUpdated", actionDate);
                            });
                        } else {
                            $translate('COMMON_NOT_VALID_DATE').then(function (trans) {
                                item.error = trans;
                            });

                        }
                    }

                    scope.openDeviation = function (deviationid) {
                        if (deviationid) {
                            deviationService().get(deviationid).then(function (deviation) {
                                var modalInstance = $uibModal.open({
                                    backdrop: 'static',
                                    templateUrl: 'fullDeviation.html',
                                    controller: 'fullDeviationController',
                                    windowClass: 'full-screen',
                                    resolve: {
                                        deviation: function () {
                                            return deviation;
                                        },
                                        activeTab: function () {
                                            return "Info";
                                        }
                                    }
                                });
                            });
                        }
                    }


                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                   

                    scope.openTask = function (activeTab, subtask) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            canChangeBoard: false,
                            users: scope.users
                        };

                        boardTaskService().get(subtask.id).then(function (task) {
                            $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        return options;
                                    }
                                }
                            });
                        })

                    }


                    scope.getValues = function (params) {
                        scope.buildColumns();
                        scope.tableParams = new NgTableParams({
                            sorting: { boardDate: "desc" },
                            count: 15,
                        },
                            {
                                getData: scope.getTableValues
                            });

                    }
                    scope.sorting = "";
                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        scope.sorting = sorting;
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: scope.filter
                        }
                        var boardTableValues = {
                            boardId: scope.boardid,
                            columnId: scope.columnid,
                            tableParams: dbParams
                        }
                        return deviationService().getActionTableValues(boardTableValues).then(function (data) {
                            scope.totalMatchingRecords = data.total;
                            params.total(data.total); // recal. page nav controls
                            // check values
                            data.values.forEach(value => {
                                value.isOverdue = isOverdue(value.boardDate, value.status);
                            });
                            scope.items = data.values;
                            return data.values;

                        });
                    }
                    scope.buildColumns = function (datasource) {
                        scope.columns = [
                            {
                                title: $translate.instant("COMMON_STATUS"),
                                field: 'status',
                                visible: true,
                                sortable: 'status',
                                getValue: renderValue,
                                dataType: "text"
                            },
                            {
                                title: $translate.instant("DEVIATION_ACTIONS"),
                                field: 'title',
                                visible: true,
                                sortable: 'title',
                                getValue: renderValue,
                                dataType: "text"
                            },
                            {
                                title: $translate.instant("DEVIATION_SEVERITY"),
                                field: 'severity',
                                visible: true,
                                sortable: 'severity',
                                getValue: renderValue,
                                dataType: "int"
                            },
                            {
                                title: $translate.instant("COMMON_RESPONSIBLE"),
                                field: 'responsibleDisplayName',
                                sortable: 'responsibleDisplayName',
                                getValue: renderValue,
                                visible: true,
                                dataType: "text"
                            },
                            {
                                title: $translate.instant("COMMON_DUE_DATE"),
                                field: 'boardDate',
                                visible: true,
                                sortable: 'boardDate',
                                getValue: renderValue,
                                dataType: "date"
                            },
                        ];

                    }

                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            return $filter('date')(value, "mediumDate");
                        }
                        if (this.dataType === "timestamp") {
                            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        }
                        return value;
                    }

                    function isOverdue(date, status) {
                        // Overdue check

                        if (!date) return false;
                        if (status == 'OK') return false;
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            return true;
                        }
                        return false;
                    }

                }

            }
        }]);
