var DigiLean = angular.module("DigiLean")
DigiLean.component("myDigileanFeedback", 
{
    templateUrl: "mydigileanfeedback.html",
    controller: ['suggestionService',
        function(suggestionService) {
            let $ctrl = this
            $ctrl.feedbacks = []
            $ctrl.showComments = function(fb) {
                fb.showComments = !fb.showComments
            }

            function getMyFeedBack() {
                suggestionService().getMyDigiLeanFeedback().then(data => {
                    if (data && data.length > 0)
                        $ctrl.feedbacks = data
                })
            }
            $ctrl.addcomment = function(event, fb) {
                const comment = event.detail
                const feedbackId = fb.id
                return suggestionService().commentOnFeedback(feedbackId, comment).then(comment => {
                    getMyFeedBack()
                }, err => {
                    toastr.error("Could not post comment")
                })
            }
            $ctrl.$onInit = function() {
                getMyFeedBack()
            }
        }]
})