angular.module('DigiLean').controller('contactlist', ['$scope', '$translate', 'contactlistService','modalService',
function ($scope, $translate,contactlistService, modalService) {


    $scope.removeItem= function (item) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CONTACT_CONFIRMATION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.COMMON_DELETE,
                headerText: translations.COMMON_DELETE + ' ' + item.firstName + ' ' + item.lastName + '?',
                bodyText: translations.ADMIN_MESSAGE_DELETE_CONTACT_CONFIRMATION 
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                contactlistService().delete(item.id).then(function (data) {  
                    var index = $scope.items.indexOf(item);
                    $scope.items.splice(index, 1);
                    $translate('ADMIN_MESSAGE_CONTACT_DELETED').then(function (msg) {
                            toastr.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                    });
                },function (err) {
                });
            });
        });
    }

    $scope.setItemViewMode = function (item) {
        item.isEditMode = false;
    }
    $scope.setItemEditMode = function (item) {
        item.isEditMode = true;
    }

    $scope.addItem = function () {
        var item = {
            id: 0,
            firstName: "",
            lastName: "",
            title: "",
            email: "",
            phoneNumber: "",
            companyName: ""
        }
        contactlistService().add(item).then(function (savedItem) {
            $translate('ADMIN_MESSAGE_CONTACT_ADDED').then(function (msg) {
                toastr.success(msg);
            }, function (translationId) {
                $scope.headline = translationId;
            });
            savedItem.isEditMode = true;
            $scope.items.push(savedItem);
            setTimeout(function () {
                $(".project-name").focus();
            }, 50);
        });
    }

    $scope.items = [];
    var load = function () {
        contactlistService().getList().then(function (items) {
            $scope.items = items;
        });
    }
    load();

    $scope.saveItem = function (item) {
        contactlistService().update(item).then(function () {
        });     
    }

}]);