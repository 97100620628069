import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('fa-icon')
export class FontawesomeIconLit extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
            margin: 0;
            padding: 0;
            color: var(--digilean-primary-text);
        }
    `

    @property({attribute: true})
    icon = "fal fa-sticky-note"

    render() {
        return html`
            <link href="/assets/fontawesome.all.min.css" rel="stylesheet">
            <i class="${this.icon}"></i>
        `
        // const ht = icon(faCamera).html[0]
        // return html`${unsafeSVG(ht)}`
    }
}