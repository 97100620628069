import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"
import * as timeService from "@common/services/timeService"

angular
.module('DigiLean')
.directive("multiChart", ['$uibModal','dataService', 'debounce',
    function ($uibModal, dataService, debounce) {
        return {
            templateUrl: 'multichart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)

                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                scope.imageUrl = staticFileService.image('multichart.jpg');
                scope.seriesData = [];
                scope.yAxisPlotLines = [];
                scope.xAxisPlotLines = [];
                scope.timeframe = "";
                var showNavigation = false;
                var showRangeSelector = false;
                var selectedGrouping = "day";
                var minY = null;
                var maxY = null;


                scope.timePeriod = {
                    period: {}
                };


                const timeSettings = {
                    timeframe: "year",
                    useDefaultTimeframes: true,
                    timeframes:[],
                    period: null,
                    getTimePeriod: function() {
                        return   {
                            useDefaultTimeframes : timeSettings.useDefaultTimeframes,
                            timeframes : timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod : function(timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }
                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);
     
                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    scope.settings = settings;
                    // apply
                    if (scope.settings) {
                        if (scope.settings.dataSeries) {
                            scope.dataSeries = scope.settings.dataSeries;
                        }
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (scope.settings.title) {
                            scope.title = scope.settings.title;
                        }
                        if (scope.settings.showNavigation) {
                            showNavigation = scope.settings.showNavigation;
                        }
                        if (scope.settings.showRangeSelector) {
                            showRangeSelector = scope.settings.showRangeSelector;
                        }
                        if (scope.settings.selectedGrouping) {
                            selectedGrouping = scope.settings.selectedGrouping;
                        }
                        if(scope.settings.minY !== null || scope.settings.minY !== undefined){
                            minY = scope.settings.minY;
                        }
                        if(scope.settings.maxY !== null || scope.settings.maxY !== undefined){
                            maxY = scope.settings.maxY;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                    }
                    if  (!scope.dataSeries && scope.isAdminMode) {
                        setTimeout(function() {
                            scope.selectDefinition(); 
                        }, 200); 
                    }
                });

                // Subscribe to component related event
                scope.subscribe("DataValueAdded", function (value) {
                    if (dateInSelectedTimeFrame(value.valueDate)) {
                        updateDataValue(value.dataSourceId);
                    }
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    if (dateInSelectedTimeFrame(value.valueDate)) {
                        updateDataValue(value.dataSourceId);
                    }
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    if (dateInSelectedTimeFrame(value.valueDate)) {
                        updateDataValue(value.dataSourceId);
                    }
                });

                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                function dateInSelectedTimeFrame(utcDate) {
                    if (!scope.timePeriod) {
                        return true;
                    }
                    if (moment(utcDate).isBefore(scope.timePeriod.period.from) ||
                        moment(utcDate).isAfter(scope.timePeriod.period.to)) {
                        return false;
                    } else {
                        return true;
                    }
                }

                function updateDataValue(dataSourceId) {
                    if (scope.dataSeries) {
                        for (var s = 0; s < scope.dataSeries.length; s++) {
                            var serie = scope.dataSeries[s];
                            if (serie.datasource && serie.datasource.id == dataSourceId) {
                                refreshData();
                                return;
                            }
                        }
                    }
                }

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timePeriod: scope.timePeriod,
                        dataSeries: scope.dataSeries,
                        showNavigation: showNavigation,
                        showRangeSelector: showRangeSelector,
                        selectedGrouping: selectedGrouping,
                        minY: minY,
                        maxY: maxY,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                }

                /* This method will be called also when component is first initialized */
                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    if (scope.timePeriod && scope.timePeriod.period.from === timePeriod.period.from && scope.timePeriod.period.to === timePeriod.period.to) return;
                    scope.options.timePeriod = timePeriod;
                    scope.timePeriod = timePeriod;
                    refreshData();
                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                }

                function refreshData() {
                    scope.seriesData = [];
                    scope.yAxisPlotLines = [];
                    scope.xAxisPlotLines = [];
                    if (scope.dataSeries) {
                        for (var s = 0; s < scope.dataSeries.length; s++) {
                            var serie = scope.dataSeries[s];
                            if (serie.manual) {
                                setManualData(serie, s);
                            }
                            else {
                                getValuesForSerie(serie, s);
                            }
                        }
                    }
                }

                function setManualData(serie, order) {
                    if (serie.axis === "y") {
                        var element =
                        {
                            dashStyle: 'shortdash',
                            label: {
                                text: serie.name
                            },
                            value: serie.datavalue,
                            width: 2,
                            index: order,
                            color: serie.graphcolor//'lightgreen',

                        };
                        scope.yAxisPlotLines.push(element);
                    }
                    if (serie.axis === "x") { // assume date value
                        var valueDate = moment(serie.datavalue).valueOf();
                        var element =
                        {
                            dashStyle: 'shortdash',
                            label: {
                                text: serie.name
                            },
                            value: valueDate,
                            width: 2,
                            zindex: 10,
                            color: serie.graphcolor//'lightgreen'
                        };
                        scope.xAxisPlotLines.push(element);
                    }
                }

                function getValuesForSerie(serie, order) {
                    if (!scope.timePeriod || !serie.datasource) return;
                    var options = {
                        timeZone: timeService.getUserTimeZone(),
                        timePeriod: scope.timePeriod.period,
                        filters: serie.filters
                    };
                    dataService().getValuesForPeriod(serie.datasource.id, options).then(function (values) {
                        if (values) {
                            setupChartData(serie, values, order);
                            drawChart();
                        }
                    });
                }

                function setupChartData(dataSerie, values, order) {
                    var graphtype = dataSerie.graphtype;
                    var label = dataSerie.name;
                    var graphcolor = dataSerie.graphcolor;
                    var data = [];
                    var accumulatedValue = 0;

                    angular.forEach(values, function (dataValue) {
                        // Format date to HighCharts format
                        var valueDate = moment(dataValue.valueDate).valueOf();
                        
                        if (dataSerie.accumulated) {
                            accumulatedValue = accumulatedValue + dataValue.value;
                            data.push([valueDate, accumulatedValue]);
                        } else {
                            data.push([valueDate, dataValue.value]);
                        }
                    });

                    var element =
                    {
                        findNearestPointBy: 'xy',
                        type: graphtype, //e.g. 'spline',
                        name: label, //e.g. '# førstemøter booket',
                        data: data,
                        index: order,
                        color: graphcolor //'lightgreen'     
                    };
                    setGraphWidthBasedOnGraphtype(element, dataSerie);
                    var grouping = null;

                    if (dataSerie.accumulated) {
                        grouping = getDataGrouping("high");
                        element.getExtremesFromAll = true;
                    }else if(dataSerie.average){
                        grouping = getDataGrouping("average");
                    } 
                    else {
                        grouping = getDataGrouping("sum");
                    }
                    if (grouping) {
                        grouping.groupAll = true;
                        element.dataGrouping = grouping;
                    }
                    scope.seriesData.push(element);
                }

                function setGraphWidthBasedOnGraphtype(element, dataSerie) {
                    if (element.type === 'spline' || element.type === 'area') {
                        if (dataSerie.width > 0) {
                            element.lineWidth = dataSerie.width;
                        }
                    } else if (element.type === 'column') {
                        if (dataSerie.width > 0) {
                            element.lineWidth = dataSerie.width;
                            element.pointWidth = dataSerie.width;
                        }
                    }
                }

                function getDataGrouping(approximation) {
                    switch (selectedGrouping) {
                        case "day":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['day', [1]]]
                            };
                            return data;
                        case "week":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['week', [1]]]
                            };
                            return data;
                        case "month":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['month', [1]]]
                            };
                            return data;
                        case "year":
                            var data = {
                                approximation: approximation,
                                enabled: true,
                                forced: true,
                                units: [['year', null]]
                            };
                            return data;
                        default:
                            return null;
                    }
                }

                /* Not able to execute this, where should it be moved?
                Highcharts.dateFormats.W = function (timestamp) {
                    var date = new Date(timestamp),
                        day = date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
                        dayNumber;
                
                    date.setDate(date.getUTCDate() + 4 - day);
                    dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000);
                
                    return 1 + Math.floor(dayNumber / 7);
                };*/

                let currentChart = null;
                function drawChart() {
                    if (currentChart) {
                        currentChart.destroy();
                    }
                    var chartElement = $(elem).find(".multichart")[0];
                    currentChart = Highstock.stockChart({
                        chart: {
                            renderTo: chartElement,
                            zoomType: 'x',
                            style: {
                                fontSize: "0.9em"
                            }
                        },
                        time: {
                            useUTC: false
                        },
                        rangeSelector: {
                            enabled: showRangeSelector
                        },
                        navigator: {
                            enabled: showNavigation
                        },
                        scrollbar: {
                            enabled: false
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            type: 'datetime',
                            plotLines: scope.xAxisPlotLines,
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        yAxis: {
                            title: {
                                text: ''
                            },
                            min: minY,
                            max: maxY,
                            plotLines: scope.yAxisPlotLines,
                            labels: {
                                style: {
                                    fontSize: "0.9em"
                                }
                            }
                        },
                        legend: {
                            enabled: true // graph description (color and description text per graph item)
                        },/*
                        tooltip: {
                            shared: true,
                            crosshairs: true
                        },*/
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        fontSize: "0.9em"
                                    },
                                    formatter: function() {
                                        var value = this.point.y;
                                
                                        if (value % 1) {
                                          return Highcharts.numberFormat(value, 2);
                                        }
                                        return Highcharts.numberFormat(value, -1);
                                    }
                                },
                            },
                            line: {
                                dataLabels: {
                                    enabled: true
                                },
                                enableMouseTracking: false
                            },
                            area: {
                                marker: {
                                    radius: 2
                                },
                                states: {
                                    hover: {
                                        lineWidth: 1
                                    }
                                },
                                threshold: null
                            }
                        },
                        series: scope.seriesData
                    });
                    resizeChart();

                }

                // Select defintion to use.

                let selectDefinitionModalInstance = null
                scope.selectDefinition = function () {
                    if (selectDefinitionModalInstance)
                        return // prevent double dialog
                    selectDefinitionModalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataseriesselectorForm.html',
                        controller: 'dataseriesselectorModalController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            dataSeries: function () {
                                return scope.dataSeries;
                            }
                        }
                    });
                    selectDefinitionModalInstance.result.then(function (dataSeries) {
                        scope.dataSeries = dataSeries;
                        scope.updateSettings();
                        refreshData();
                    }, ()=>{}).finally(() => {
                        selectDefinitionModalInstance = null
                    });
                };

                scope.selectConfiguration = function () {
                    var config = {
                        selectedGrouping: selectedGrouping,
                        showNavigation: showNavigation,
                        showRangeSelector: showRangeSelector,
                        minY: minY,
                        maxY: maxY
                    }
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'graphConfiguration.html',
                        controller: 'graphConfigurationController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            configuration: function () {
                                return config;
                            }
                        }
                    });

                    modalInstance.result.then(function (configuration) {
                        selectedGrouping = configuration.selectedGrouping;
                        showNavigation = configuration.showNavigation;
                        showRangeSelector = configuration.showRangeSelector;
                        minY = configuration.minY;
                        maxY = configuration.maxY;
                        scope.updateSettings();
                        refreshData();
                    }, function(res){});
                };

                function resizeChart() {
                    if (!elem) return
                    let appEl = elem[0]

                    let width = appEl.offsetWidth
                    let height = appEl.offsetHeight

                    let headerEl = appEl.querySelector(".app-header")
                    if (headerEl)
                        height = height - headerEl.offsetHeight

                    // find with inside padding of graph-content
                    let graphContent = appEl.querySelector(".graph-content")
                    if (graphContent) {
                        let graphContentInner = graphContent.querySelector(".graph-content-inner")
                        if (graphContentInner)
                            width = graphContentInner.offsetWidth
                    }
                    
                    let chartContainer = $(elem).find(".highcharts-container").parent()
                    if (chartContainer.length === 0) return

                    if (currentChart)
                        currentChart.setSize(width, height, true)
                }

                scope.$on("widget-resized", function (event, args) {
                    resizeChart();
                });

            }
        }
    }]);
