var DigiLean = angular.module('DigiLean');
DigiLean.directive("deviationTypeSetting", ['$filter', '$translate', 'deviationService', 'modalService', 'dataListService',
    function ($filter, $translate, deviationService, modalService, dataListService) {
        return {
            templateUrl: 'deviationTypeSetting.html',
            restrict: 'E',
            scope: {
                'deviationTypeId': '<',
                'deviationTypeTitle': '=',
            },
            link: function (scope, elem, attrs) {

                scope.deviationType = { title: "" };//{};

                scope.isElementRegistrationNew = false;
                scope.selectedAttributeType = null;
                scope.selectedDataList = null;
                scope.customfields = [];

                scope.typeSettings = [
                    {
                        label: $translate.instant('DEVIATION_INCIDENT_DATE'),
                        requiredByDigilean: true,
                        type: "DEVIATION_INCIDENT_DATE"
                    },
                    {
                        label: $translate.instant('DEVIATION_INCIDENT_TIME'),
                        requiredByDigilean: false,
                        show: false,
                        type: "DEVIATION_INCIDENT_TIME"
                    },
                    {
                        label: $translate.instant('COMMON_TITLE'),
                        requiredByDigilean: true,
                        type: "TITLE"
                    },
                    {
                        label: $translate.instant('COMMON_DESCRIPTION'),
                        requiredByDigilean: true,
                        type: "DESCRIPTION"
                    },
                    {
                        label: $translate.instant('COMMON_CATEGORY'),
                        requiredByDigilean: true,
                        type: "CATEGORY"
                    },
                    {
                        label: $translate.instant('COMMON_AREA'),
                        requiredByDigilean: false,
                        show: false,
                        type: "AREA"
                    },
                    {
                        label: $translate.instant('COMMON_PROJECT'),
                        requiredByDigilean: false,
                        show: false,
                        type: "PROJECT"
                    },
                    {
                        label: $translate.instant('COMMON_ASSET'),
                        requiredByDigilean: true,
                        type: "ASSET"
                    },
                    {
                        label: $translate.instant('COMMON_REPORTED_BY'),
                        requiredByDigilean: true,
                        type: "REPORTED_BY"
                    },
                    {
                        label: $translate.instant('DEVIATION_TYPE_OF_LOSS'),
                        requiredByDigilean: true,
                        type: "DEVIATION_TYPE_OF_LOSS"
                    },
                    {
                        label: $translate.instant('DEVIATION_CAUSES'),
                        requiredByDigilean: true,
                        type: "DEVIATION_CAUSES"
                    },
                    {
                        label: $translate.instant('DEVIATION_SEVERITY'),
                        requiredByDigilean: true,
                        type: "DEVIATION_SEVERITY"
                    }
                ];

                scope.customTypes = [
                    { id: "text", label: $translate.instant('ADMIN_DATA_DATASOURCE_SMALLTEXT'), languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
                    { id: "description", label: $translate.instant('ADMIN_DATA_DATASOURCE_LARGERTEXT'), languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
                    { id: "number", label: $translate.instant('ADMIN_DATA_DATASOURCE_NUMBER'), languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
                    { id: "bool", label: $translate.instant('ADMIN_DATA_DATASOURCE_BOOLEAN'), languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
                    { id: "list", label: $translate.instant('ADMIN_DATA_LISTS'), languageCode: "ADMIN_DATA_LISTS" }
                ];


                scope.$watch("deviationTypeId", function () {
                    if (!scope.deviationTypeId) return;
                    loadSettings();
                });

                function loadSettings() {
                    deviationService().getFieldsForType(scope.deviationTypeId).then(function (fields) {
                        for (var i = 0; i < fields.length; i++) {
                            var field = $filter('filter')(scope.typeSettings, { type: fields[i].type })[0];
                            if (field && fields[i].type != 'description') {
                                field.id = fields[i].id;
                                field.show = true;
                            } else { //custom field
                                fields[i].isEditMode = false;
                                var typeinfo = $filter('filter')(scope.customTypes, { id: fields[i].type })[0];
                                if (typeinfo) {
                                    fields[i].typelabel = typeinfo.label;
                                }
                                fields[i].show = true;
                                scope.customfields.push(fields[i]);
                            }

                        }
                        deviationService().getAllCustomFields().then(function (allfields) {
                            for (var i = 0; i < allfields.length; i++) {
                                var field = allfields[i];
                                var exists = $filter('filter')(scope.customfields, { deviationCustomFieldId: allfields[i].id })[0];
                                if (!exists && allfields[i].type != 'DEVIATION_INCIDENT_TIME') {
                                    var typeinfo = $filter('filter')(scope.customTypes, { id: allfields[i].type })[0];
                                    if (typeinfo) {
                                        field.typelabel = typeinfo.label;
                                    }
                                    field.show = false;
                                    field.deviationCustomFieldId = field.id;
                                    scope.customfields.push(field);
                                }
                            }
                        });

                        
                    });
                }

                dataListService().getLists().then(function (list) {
                    if (list) {
                        scope.datalists = list;
                    }
                });

                scope.addCustomField = function () {
                    scope.isElementRegistrationNew = true;
                    scope.newField =
                    {
                        deviationTypeId: scope.deviationTypeId,
                        label: "",
                        requiredByDigilean: false,
                        show: true,
                        type: null,
                        isMandatory: false
                    }

                };

                /*
                scope.deleteCustomField = function (field) {
                    scope.isElementRegistrationNew = true;   
                    if(field.id){
                        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_DATA_CONFIRMATION']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.COMMON_DELETE,
                                headerText: translations.COMMON_DELETE + ' ' + field.label + '?',
                                bodyText: translations.ADMIN_MESSAGE_DELETE_DATA_CONFIRMATION
                            };
                            modalService.showModal({}, modalOptions).then(function (result) {
                                var index = scope.customfields.indexOf(field);
                                scope.customfields.splice(index, 1);
                                scope.isElementRegistrationNew = false;
                                deviationService().deleteDeviationTypeField(field.id).then(function() {                                                  
                                });
                            });
                        });
                    }     
                };*/


                scope.setItemViewMode = function (item) {
                    item.isEditMode = false;
                }
                scope.setItemEditMode = function (item) {
                    item.isEditMode = true;
                }

                scope.toggleMandatoryField = function (item) {
                    item.isMandatory = !item.isMandatory;
                    scope.updateItem(item);
                }

                scope.saveElement = function (field) {
                    scope.isElementRegistrationNew = false;
                    field.type = scope.selectedAttributeType.id;
                    if (scope.selectedDataList) {
                        field.dataListId = scope.selectedDataList.id
                    }
                    deviationService().addDeviationTypeField(field).then(function (result) {
                        scope.selectedAttributeType = null;
                        scope.selectedDataList = null;

                        var typeinfo = $filter('filter')(scope.customTypes, { id: result.type })[0];
                        if (typeinfo) {
                            result.typelabel = typeinfo.label;
                            result.show = true;
                        }
                        scope.customfields.push(result);
                    });
                };

                scope.updateItem = function (item) {
                    deviationService().updateDeviationTypeField(item.id, item).then(function (item) {
                    })
                }

                scope.selectedAttributeTypeChanged = function (item) {
                    scope.newField.type = item.type;
                    scope.selectedAttributeType = item
                }

                scope.toggleShow = function (field) {

                    if (field.show) {
                        field.deviationTypeId = scope.deviationTypeId;
                        field.id = 0;
                        deviationService().addDeviationTypeField(field).then(function (savedField) {
                            field.id = savedField.id;
                        });
                    } else {
                        if (field.id) {
                            deviationService().deleteDeviationTypeField(field.id).then(function () {
                            });
                        }
                    }
                }



                function updateItemsOrder(e, ui) {
                    var items = ui.item.sortable.droptargetModel;
                    var itemsSortOrder = {
                        deviationTypeId: scope.deviationTypeId,
                        sortOrders: []
                    }

                    var index = 0;
                    items.forEach(item => {
                        if (item.show) {
                            itemsSortOrder.sortOrders.push({
                                id: item.id,
                                sortOrder: index,
                            });
                        }
                        index += 1;
                    });
                    deviationService().updateItemsSortOrder(scope.deviationTypeId, itemsSortOrder);
                }

                scope.itemsSortableOptions = {
                    connectWith: ".summaryList",
                    handle: ".drag-handle",
                    placeholder: "summary-item-sortable-placeholder",
                    stop: updateItemsOrder
                };



            }

        }
    }]);
