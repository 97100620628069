import { cloneDeep } from "lodash"
export function translateOldDeviationSettings(settings, currentUserId, dueDate) {

    if (!settings)
        return {}
    
    let converted = cloneDeep(settings)

    // old filter was an object, so if it's an array it's the new format
    if (settings.filter && !Array.isArray(settings.filter)) {
        converted.filter = []

        // area -> areaId
        if (settings.filter.area && Array.isArray(settings.filter.area)) {
            const itemFilter = generateItemFilter("areaId", settings.filter.area)
            converted.filter.push(itemFilter)
        }
        // asset -> followupAssetId
        if (settings.filter.asset && Array.isArray(settings.filter.asset)) {
            const itemFilter = generateItemFilter("followupAssetId", settings.filter.asset)
            converted.filter.push(itemFilter)
        }
        // category -> problemCategoryId
        if (settings.filter.category && Array.isArray(settings.filter.category)) {
            const itemFilter = generateItemFilter("problemCategoryId", settings.filter.category)
            converted.filter.push(itemFilter)
        }
        // deviationtype -> deviationTypeId
        if (settings.filter.deviationtype && Array.isArray(settings.filter.deviationtype)) {
            const itemFilter = generateItemFilter("deviationTypeId", settings.filter.deviationtype)
            converted.filter.push(itemFilter)
        }
        // project -> projectId
        if (settings.filter.project && Array.isArray(settings.filter.project)) {
            const itemFilter = generateItemFilter("projectId", settings.filter.project)
            converted.filter.push(itemFilter)
        }
        // severity -> severity
        if (settings.filter.severity && Array.isArray(settings.filter.severity)) {
            const itemFilter = generateItemFilter("severity", settings.filter.severity)
            converted.filter.push(itemFilter)
        }
        // status -> status
        if (settings.filter.status && Array.isArray(settings.filter.status)) {
            const itemFilter = generateItemFilter("status", settings.filter.status)
            converted.filter.push(itemFilter)
        }
        // myRegistration -> createdByUserId
        if (settings.filter.myRegistration) {
            const itemFilter = generateItemFilter("createdByUserId", [currentUserId])
            converted.filter.push(itemFilter)
        }
        //myResponsible -> ownerUserId
        if (settings.filter.myResponsible) {
            const itemFilter = generateItemFilter("ownerUserId", [currentUserId])
            converted.filter.push(itemFilter)
        }

        // overdueFilterActive
        if (settings.filter.overdueFilterActive) {
            const dueDateFilter = {
                sourceColumn: "dueDate",
                operator: "LessThanDate",
                items: [dueDate]
            }
            converted.filter.push(dueDateFilter)
            const statusImplementedFilter = {
                sourceColumn: "statusImplementedDate",
                operator: "IsNull",
                items: []
            }
            converted.filter.push(statusImplementedFilter)
        }

        // timeframe 
        if (settings.filter.timeframe) {
            converted.timePeriod = {
                timeframe: settings.filter.timeframe,
                period: settings.filter.timePeriod
            }
        }
    }
    

    return converted
}

function generateItemFilter(sourceColumn, items) {
    if (!items || !Array.isArray(items) || items.length === 0)
        return null
    
    let itemFilter = {
        sourceColumn: sourceColumn,
        operator: "InList",
        items: []
    }
    // const itemsString = items.map(i => `${i}`)
    itemFilter.items = items
    return itemFilter
}