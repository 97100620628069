import * as connectorAdminService from "@common/services/admin/connectorAdminService"
import * as customerService from "@common/services/customers/customerService"

const DigiLean = angular.module('DigiLean')
DigiLean.controller('connectorMonitoringController', ['$scope', '$uibModal', '$translate', 'modalService', 'authService',
    function ($scope, $uibModal, $translate, modalService, authService) {

        // This is only for Kaizen so return if not in role.
        if (!authService.hasRole("KaizenAdmin")) return;

        $scope.connectors = [];
        $scope.customers = [];

        $scope.subscribe("ConnectorCreated", function (connector) {
            loaddatas();
            $translate('ADMIN_DATA_CONNECTOR_CREATED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });
        $scope.subscribe("ConnectorUpdated", function (connector) {
            // loaddatas();
            $translate('ADMIN_DATA_CONNECTOR_UPDATED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });
        $scope.subscribe("ConnectorDeleted", function (connector) {
            loaddatas();
            $translate('ADMIN_DATA_CONNECTOR_DELETED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });

        $scope.subscribe("JobCreated", function (job) {
            loaddatas();
            $translate('ADMIN_DATA_JOB_ADDED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });
        $scope.subscribe("JobUpdated", function (job) {
            $translate('ADMIN_DATA_JOB_UPDATED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });
        $scope.subscribe("JobDeleted", function (job) {
            loaddatas();
            $translate('ADMIN_DATA_JOB_DELETED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });

        $scope.subscribe("JobExecuted", function (job) {
            $translate('ADMIN_DATA_JOB_EXECUTED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });

        $scope.subscribe("HeartbeatReceived", function (heartbeat) {
            $scope.connectors.forEach(function (connector) {
                if (connector.id.toLowerCase() == heartbeat.id.toLowerCase()) {
                    connector.heartbeatActive = true;
                    connector.lastHeartbeat = moment().toDate();
                    connector.timeRunning = heartbeat.timeRunning;
                }
            }, this);

        });

        $scope.subscribe("JobErrorOccurred", function (jobId) {
            $scope.connectors.forEach(function (connector) {
                var jobIds = connector.jobs.map(function (job) {
                    return job.id;
                });
                var index = jobIds.indexOf(jobId);
                if (index > -1) {
                    $translate('ADMIN_DATA_JOB_ERROROCCURRED').then(function (msg) {
                        toastr.error(msg + ' ' + connector.jobs[index].name);
                    });
                    connector.jobs[index].hasRecentError = true;
                    connector.hasJobWithRecentErrors = true;
                }
            })
        });

        $scope.subscribe("ConnectorStarted", function (connectorId) {

            var connectorIds = $scope.connectors.map(function (connector) {
                return connector.id;
            });
            if (connectorIds.indexOf(connectorId.toLowerCase()) > -1) {
                $translate('ADMIN_DATA_CONNECTOR_STARTED').then(function (msg) {
                    toastr.info(msg + ": " + connectorId.toLowerCase());
                });
            }
        });

        $scope.subscribe("ConnectorStopped", function (connectorId) {

            var connectorIds = $scope.connectors.map(function (connector) {
                return connector.id;
            });
            if (connectorIds.indexOf(connectorId.toLowerCase()) > -1) {
                $translate('ADMIN_DATA_CONNECTOR_STOPPED').then(function (msg) {
                    toastr.info(msg + ": " + connectorId.toLowerCase());
                });
            }
        });

        $scope.subscribe("JobExecuted", function (job) {
            $translate('ADMIN_DATA_JOB_EXECUTED').then(function (msg) {
                toastr.info(msg + ": " + job.name);
            });
        });

        $scope.delete = function (data) {

            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CONNECTOR_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + data.name + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_CONNECTOR_CONFIRMATION.replace('?', ' ') + data.name + '?'
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    connectorAdminService.deleteConnector(data.id).then(function (connector) {
                        $scope.publish("SendConnectorDeleted", connector);

                        $translate('ADMIN_MESSAGE_CONNECTOR_DELETED').then(function (msg) {
                            toastr.info(msg);
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    });
                });
            });
        }

        $scope.isValidDate = function (date) {
            var result = moment(date);
            return result._isValid;
        };

        $scope.startConnector = function (connector) {
            $scope.publish("SendStartConnector", connector);
        };

        $scope.stopConnector = function (connector) {
            $scope.publish("SendStopConnector", connector);
        };

        $scope.runJobNow = function (job) {
            $scope.publish("SendExecuteJob", job);
        }

        var loaddatas = function () {
            connectorAdminService.getList().then(function (list) {
                list.forEach(function (connector) {
                    connector.lastHeartbeat = moment(connector.lastHeartbeat).toDate();
                });
                $scope.connectors = list;
            });
            customerService.getCustomersSimpleForAdmin().then(function (list) {
                $scope.customers = list;
            });
        }

        $scope.getCustomer = function (id) {
            if (!$scope.customers)
                return null;
            var index = $scope.customers.map(function (customer) { return customer.id }).indexOf(id);
            if ($scope.customers[index])
                return $scope.customers[index].name;
        }

        const intervalId = setInterval(function () {
            $scope.connectors.forEach(function (connector) {
                var diff = moment().toDate() - moment(connector.lastHeartbeat).toDate();
                if (moment.duration(diff).asSeconds() > 5) {
                    connector.heartbeatActive = false;
                }
            });
        }, 5000);

        $scope.addConnector = function () {
            $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newConnector.html',
                controller: 'newConnectorController',
                resolve: {
                }
            });
        };

        $scope.toggleEnabled = function(job){
            job.enabled = !job.enabled;
            connectorAdminService.updateJob(job).then(function(result){
                $scope.publish("SendJobUpdated", job);
            });
        };

        $scope.showLastValues = function (job) {
            if (!$scope.valuesExpanded(job)) {
                $scope.getValues(job);
            }
        };

        $scope.valuesExpanded = function (job) {
            var id = '#collapseValues' + job.id;
            var element = angular.element(document).find(id);
            return element.hasClass('in');
        }


        $scope.getValues = function (job) {
            job.loadingValues = true;
            connectorAdminService.getLastValues(job).then(function (values) {
                job.values = values;
                setTimeout(function () {
                    job.loadingValues = false;
                }, 1000);
            });
        }

        $scope.showLastErrors = function (job) {
            if (!$scope.errorsExpanded(job)) {
                $scope.getErrors(job);
            }
        };

        $scope.errorsExpanded = function (job) {
            var id = '#collapseErrors' + job.id;
            var element = angular.element(document).find(id);
            return element.hasClass('in');
        }

        $scope.getErrors = function (job) {
            job.loadingErrors = true;
            connectorAdminService.getLastErrors(job).then(function (errors) {
                job.errors = errors;
                setTimeout(function () {
                    job.loadingErrors = false;
                }, 1000);
            });
        }
        
        loaddatas();
        $scope.$on("$destroy", () => {
            clearInterval(intervalId)
        })
    }]);