import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .controller("accountSetupController", ['$scope', '$uibModalInstance','$translate', 'accountowner', 'signupService', 'modulesService',
        function ($scope, $uibModalInstance, $translate, accountowner, signupService, modulesService) {

            $scope.accountowner = accountowner;
            $scope.useAD = false;
            $scope.step = 1;
            $scope.assetname = "Test";
            $scope.isLoading = false;
            $scope.warning = '';

            $scope.next = function () {
                $scope.step = $scope.step + 1;
            };

            $scope.teammembers = [];

            if($scope.accountowner.useAD){
                $scope.useAD = true;
            }

            $scope.ok = function () {
                //TODO kall metode for fullførelse av installasjon
                // 1. legg til moduler
                // 2. legg til brukere
                // 3. Opprett asset
                // 4. Opprett tavler for asset 
                // 5. employeeService().updateUserPreference("HasVisitedSettingsPage", true).then(function(value) {

                $scope.isLoading = true;
                if(!$scope.assetname){
                    $scope.assetname = "Test"; // Asset name can not be NULL
                }
                const selectedModules = $scope.modules.filter(m => m.hasModule)
                const setupInfo = 
                {
                    Modules : selectedModules,
                    trialUsers : $scope.teammembers,
                    assetName : $scope.assetname
                };

                signupService().newTrialCustomerSetup(setupInfo).then(function(value) {
                    location.reload(false);
                })
                
            };

            function getSubscription(){
               modulesService.getAllowedModulesConfig().then(function(allowed) {
                    $scope.modules = allowed;
                    for(var i=0; i<$scope.modules.length; i++){
                        $scope.modules[i].hasModule = true;
                    }
                })
            
            }
            getSubscription();

            $scope.close = function () {
                location.reload(false);
                $uibModalInstance.dismiss('cancel');
            }

            $scope.add = function () {
                if ($scope.registration.firstName && $scope.registration.lastName && $scope.registration.userName){
                    if(!$scope.useAD){
                        $scope.registration.email = $scope.registration.userName; 
                    }
                    $scope.warning = "";
                var newUser = {
                    isNewUser: true,
                    useAD: $scope.useAD,
                    id: 0,
                    firstName: $scope.registration.firstName,
                    lastName: $scope.registration.lastName,
                    userName: $scope.registration.userName,
                    password: "",
                    email: $scope.registration.email,
                    confirmPassword: "",
                    isAdmin: $scope.registration.isAdmin,
                    isSupport: false,
                    isApiUser: false,
                    isInfoScreenUser: false,
                    isMobileUser: false
                };
                $scope.teammembers.push(newUser);
                clearUser();
            } else {
                $scope.warning = $translate.instant('ADMIN_DATA_FIELD_MISSING');
            }
            }

            $scope.toggleModule = function (module) {
                module.hasModule = !module.hasModule;
            }

            $scope.checkExistingUser = async function (input) {
                $scope.warning = ""
                const response = await customerService.checkExistingUser(input)
                if(response){
                    if (response.status == 'ExistInAnotherInstallation') {
                        $scope.existInAnotherInstallation = true
                        $scope.warning = input + $translate.instant('ADMIN_ERROR_MESSAGE_ADD_EXISTING_USER')
                        clearUser()
                    }
                }
            }

            var clearUser = function () {
                $scope.message = "";
                $scope.registration = {
                    firstName: "",
                    lastName: "",
                    userName: "",
                    isAdmin: false
                };
            };
    
            clearUser();
            
        }]);
