angular
    .module('DigiLean')
    .directive("a3ProgressTasksCell", ['$filter', '$translate','$uibModal','boardTaskService',
        function ($filter, $translate, $uibModal, boardTaskService) {
            return {
                templateUrl: 'a3ProgressTasksCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                   

                    scope.options = [
                        { progress: 25, label: "20 %", color: "#0099DE"},
                        { progress: 50, label: "50 %", color: "#0099DE"},
                        { progress: 75, label: "75 %", color: "#0099DE"},
                        { progress: 100, label: "100 %", color: "#8CC152"},
                    ]

                    scope.customOption = {
                        progress: 0,
                        label: "0 %",
                        color: "0099DE"
                    };


                    var a3Id = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.a3) {
                            if (scope.model.a3.id) {
                                a3Id = scope.model.a3.id;
                                calculateProgress();
                            }
                        }
                        model.getSortValue = function() {
                            return scope.progress;
                        }
                    });

                    
                    scope.subscribe('TaskStatusUpdated', function (updatedstatus) {
                            calculateProgress();      
                    });

                    scope.subscribe('TaskDeleted', function (id) {
                        calculateProgress();
                    });
                    scope.subscribe('NewTask', function (task) {
                        calculateProgress();
                    }); 

                    function calculateProgress(){
                        boardTaskService().getNoA3TaskPerStatus(a3Id).then(function (statusdata) {
                            //scope.openTasks = statusdata.blank;
                            scope.taskCompletedCount = statusdata.ok;
                            //scope.noBlockedTasks = statusdata.unacceptable;
                            scope.taskCount = statusdata.blank + statusdata.ok + statusdata.unacceptable;
                            var progress = 0;
                            if(scope.taskCount > 0){
                                progress = Math.round(100 * statusdata.ok/scope.taskCount);
                            }
                                var progressOptions = {
                                    progress: progress,
                                    label: progress + " %",
                                    color: "0099DE"
                                };
                                scope.setProgress(progressOptions);
                        });

                    }
              
                    scope.openTasks = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'a3TaskListForm.html',
                            controller: 'a3TaskListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                a3Id: function () {
                                    return a3Id;
                                }
                            }
                        });
                    };
                                   
                    scope.setProgress = function(option) {
                        scope.progress = option.progress;
                        scope.customOption.progress = option.progress;
                        scope.color = option.color;
                        if (scope.progress == 100) { // Green color if 100
                            scope.color = "#8CC152";
                        }
                    }
                }
            }
        }]);
