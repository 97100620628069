angular
    .module('DigiLean')
    .controller("customerOrderDialogController", ['$scope', '$uibModalInstance', '$filter','customerAccountService', 'order', 'customerId',
        function ($scope, $uibModalInstance, $filter,customerAccountService, order, customerId) {

            if (!order || !order.id) {
                $scope.order = {
                    id:0,
                    customerId: customerId,
                    productId:0,
                    productPartName: "",
                    productName: "",
                    quantity: 1,
                    startDate: new Date()
                }
            } else {
                $scope.order = order;
                $scope.order.startDate = moment($scope.order.startDate).toDate();
                if($scope.order.endDate){
                    $scope.order.endDate = moment($scope.order.endDate).toDate();  
                }
            }

            $scope.selectedProduct = null;

            customerAccountService().getProductOrders().then(function (products) {
                $scope.allproducts = products;
                if($scope.order.productId){
                    var match = $filter('filter')($scope.allproducts, { id: $scope.order.productId }, true);
                    $scope.selectedProduct = match[0];
                }
            })

            $scope.productChanged = function (product) {
                $scope.order.productId = product.id;
                $scope.order.productPartName = product.partName;
                $scope.order.productName = product.name;
            };

            $scope.startDateChanged = function($event) {
                const date = $event.originalEvent.detail
                $scope.order.startDate = date
            }

            $scope.endDateChanged = function($event) {
                const date = $event.originalEvent.detail
                $scope.order.endDate = date
            }
            $scope.save = function () {
                customerAccountService().updateCustomerProductOrder($scope.order).then(function (savedOrder) {
                    $uibModalInstance.close(savedOrder)
                }, function(error) {
                    if (error.data) {
                        if (error.data.title)
                            toastr.error(error.data.title, "Error", {timeOut: 15000})
                    }
                    toastr.error("something went wrong!")
                })
            }

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel')
            }
        }])