import * as connectorAdminService from "@common/services/admin/connectorAdminService"
angular
    .module('DigiLean')
    .component("logMessageViewer", {
        templateUrl: 'logMessageViewer.html',
        bindings: {
            langCode: '@',
            show: '<',
            onlyError: '<',
            job: '<',
        },
        controller: ['$translate',
            function ($translate) {
                var $ctrl = this
                $ctrl.headline = "Logs"
                $ctrl.loading = false
                $ctrl.logs = []

                $ctrl.$onInit = function() {
                    //
                };

                $ctrl.load = async function() {
                    const logs = await getLogs($ctrl.job)
                    $ctrl.logs = logs
                }
                $ctrl.$onChanges = function (changes) {
                    if (changes.langCode) {
                        if (changes.langCode.currentValue) {
                            $ctrl.headline = $translate.instant($ctrl.langCode)
                        }
                    }
                    if (changes.show) {
                        if (changes.show.currentValue && !changes.show.previousValue) {
                            $ctrl.load()
                        }
                    }
                };

                async function getLogs(job) {
                    $ctrl.loading = true
                    let logs = []
                    if ($ctrl.onlyError)
                        logs = await connectorAdminService.getLastErrors(job);
                    else
                        logs = await connectorAdminService.getLastLogs(job);
                    setTimeout(function () {
                        $ctrl.loading = false
                    }, 1000);
                    return logs
                }
                $ctrl.$onDestroy = function() {
                    //
                };
            }]
    });