﻿// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  
DigiLean.controller('clonedeleteController', ['$scope', 'boardTaskService', '$uibModalInstance', 'taskId', 'siblingId',
  function ($scope, boardTaskService, $uibModalInstance, taskId, siblingId) {
      $scope.deleteJustThis = function () {
        
        boardTaskService().deleteAClonedTask(taskId).then(function (result) {
            $uibModalInstance.close();
        });
            /*boardTaskService().deleteTask(taskId).then(function (result) {
              $uibModalInstance.close();
          });*/
      };
      $scope.deleteAll = function () {
          boardTaskService().deleteClonedTask(siblingId).then(function (result) {
              $scope.publish("SendTasksDeleted", result);
              $uibModalInstance.close();
          });
      };
      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
  }]);