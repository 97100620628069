import { isEqual, omitBy, isUndefined, eq } from "lodash"

export function fixTransparentText(settings) {
    if (!settings || !settings.theme || !settings.theme.background) return
    if (settings.theme.background == "transparent") {
        settings.theme.background = "var(--digilean-primary-text)"
        settings.theme.fillColor = "var(--digilean-secondary-background)"
    }
}

export function isObjectEqual(object1, object2) {
    const typeOf1 = typeof object1
    const typeOf2 = typeof object2

    if (typeOf1 !== 'object' || typeOf2 !== 'object')
        return isEqual(object1, object2)

    const copy1 = omitBy(object1, isUndefined)
    const copy2 = omitBy(object2, isUndefined)

    //const match = isMatch(copy1, copy2)
    const equal = isEqual(copy1, copy2)
    return equal
}