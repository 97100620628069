angular
    .module('DigiLean')
    .controller("cellCloneConfigController", ['$scope', '$state', '$uibModalInstance', '$filter', 'smartcellService', 'projectService', 'settings',
        function ($scope, $state, $uibModalInstance, $filter, smartcellService, projectService, settings) {

            $scope.newid = null;
            $scope.projectId = settings.projectId;
            $scope.selectedProject = null;
            $scope.selectedExisting = null;
            $scope.projects = [];

            // TODO fjern id. Jobb kun med name!!!
            // $scope.existingid = null;

            $scope.choosenid = settings.smartCloneId;

            $scope.name = null;
            if (settings.smartCloneName) {
                $scope.name = settings.smartCloneName
            }



            projectService().getActiveProjects().then(function (projects) {
                $scope.projects = projects;
                if ($scope.projectId) {
                    var projects = $filter('filter')($scope.projects, { id: $scope.projectId }, true);
                    if (projects.length === 1) {
                        $scope.selectedProject = projects[0];
                        $scope.projectId = projects[0].id;
                    }
                }
            });

            smartcellService().getClonedCellIdentificators(settings.visualizationType).then(function (result) {
                $scope.ids = result;
                if ($scope.ids && $scope.name) {
                    var existing = $filter('filter')($scope.ids, $scope.name, true);
                    if (existing.length === 1) {
                        $scope.selectedExisting = existing[0];
                    }
                }
            });
            /*
            $scope.setId = function (existing) {
                $scope.choosenid = existing.id;
                $scope.name = existing.name
            };*/

            $scope.setName = function (name) {
                //$scope.choosenid = 0;
                $scope.name = name;
            };

            $scope.useSingelValue = function () {
                $scope.projectId = null;
            };

            $scope.useProject = function () {
                if ($scope.selectedProject) {
                    $scope.projectId = $scope.selectedProject.id;
                } else if ($scope.projects){
                    $scope.selectedProject = $scope.projects[0];
                    $scope.projectId = $scope.projects[0].id;  
                }else {
                    $scope.projectId = null;
                }
            };

            $scope.setProject = function (project) {
                $scope.projectId = project.id;
            };

            $scope.ok = function () {
                smartcellService().getProjectValueForClonedCell($scope.name, $scope.projectId).then(function (result) {
                    if (result) {
                        $uibModalInstance.close(result);
                    } else { // No cell value exist for this combination
                        var smartCloneCellValue =
                        {
                            id: 0,
                            name: $scope.name,
                            type: settings.visualizationType,
                            projectId: $scope.projectId,
                            value: {}
                        }
                        smartcellService().updateCloneValue(smartCloneCellValue).then(function (newCellValue) {
                            $uibModalInstance.close(newCellValue);
                        });
                    }
                });
                
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
