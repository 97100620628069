angular
    .module('DigiLean')
    .directive("smartLinkCell", ['$filter','debounce','$translate', 'projectService',
        function ($filter, debounce, $translate, projectService) {
            return {
                templateUrl: 'smartLinkCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                    'adminmode': '='
                },
                link: function (scope, elem, attrs) {
                    scope.url = "";
                    scope.openInNewTab = true;
                    scope.text = "";
                    scope.color = "#676a6c"; //"#ffffff";
                    scope.background = "inherit";
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();

                    scope.showDropdown = false;

                    scope.usePredefinedLabels = false;
                    scope.labels = [];
                    var columnId = null;
                    var rowId = null;

                    scope.$watch('editable', function (editable) {
                        var test = editable;
                    });

                    scope.$watch('model', function (model) {
                        var value = null;
                        if (scope.type == 'project') {
                            columnId = model.boardAttributeId;
                            rowId = model.project.id;
                            value = model.projectAttribute.value;             
                            
                        } else if (scope.type == 'a3') {
                            columnId = model.columnId;
                            rowId = model.a3.id;
                            if(model.a3TableCell){
                                value = model.a3TableCell.value;
                            }             
                        }
                        //var settings = model.column.settings;

                        if (value) {
                            setValue(value);
                        }
                        model.getSortValue = function () {
                            return scope.text;
                        }
                    });

                    scope.autofocus = function () {
                        setTimeout(function () {
                            //$(elem).closest(".smarttable-link-cell").focus(); fungerer ikke
                            $(".project-link-cell").focus();

                        }, 50);
                    }

                    var setValue = function (value) {
                        scope.background = value.background;
                        scope.url = value.url;
                        scope.openInNewTab = value.openInNewTab;
                        scope.text = value.text;
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == rowId && tablecell.a3TableColumnId == columnId) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });
                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if (postIt.title === 'default') {
                            scope.background = "inherit";
                            scope.color = "#676a6c";
                        } else {
                            scope.background = postIt.background;
                            scope.color = "#ffffff";
                        }
                        scope.commitChangesDebounced();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = { title: "custom", background: color };
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.commitChangesDebounced();
                    }
                    scope.textChanged = function (text) {
                        if (scope.text === text) return;
                        scope.text = text;
                        scope.commitChangesDebounced();
                    }
                    scope.urlChanged = function (url) {
                        if (scope.url === url) return;
                        scope.url = url;
                        scope.commitChangesDebounced();
                    }

                    scope.openChanged = function (open) {
                        if (scope.openInNewTab === open) return;
                        scope.openInNewTab = open;
                        scope.commitChangesDebounced();
                    }
                    scope.saveChanges = function () {
                        var value = {
                            background: scope.background,
                            color: scope.color,
                            text: scope.text,
                            url: scope.url,
                            openInNewTab: scope.openInNewTab
                        }
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = value;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = value;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: columnId,
                                   a3Id: rowId,
                                   value: value
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);

                }
            }
        }]);
