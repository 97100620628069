angular
    .module('DigiLean')
    .directive("smartDateCell", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'smartDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    var defaultDateColor = "#0099DE";
                    scope.color = defaultDateColor;
                    scope.customColorBackground = "#a9a9a9";
                    scope.postIts = projectService().getPostIts();
                    scope.value = "";
                    
                    scope.updateDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.value = date
                        scope.saveChanges()
                    }

                    scope.$watch("model", function (model) {
                        var value = null;
                        
                        if (scope.type == 'project') {
                            value = model.projectAttribute.value;              
                            
                        } else if (scope.type == 'a3') {
                            if(model.a3TableCell) {
                                value = model.a3TableCell.value;             
                            }
                        }
                        if (value) {
                            setValue(value);
                        } 
                        model.getSortValue = function() {
                            if (scope.value) {
                                return scope.value.value;
                            }
                            return null;
                        }
                    });

                    var setValue = function(value) {
                        scope.background = value.background;
                        if(value.value){
                            scope.value = moment(value.value).toDate();
                        } else {
                            scope.value = "";
                        }
                        scope.color = value.color;
                        var predefinedPostIt = $filter('filter')(scope.postIts, { background: scope.background });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                        } else //Custom color 
                        {
                            scope.selectedPostIt = { title: "custom", background: scope.background };
                            scope.customColorBackground = value.background;
                        }
                    }

                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.column.id) {
                            if (tablecell.value) {
                                setValue(tablecell.value);
                            }
                        }
                    });

                    scope.selectColor = function (postIt) {
                        scope.selectedPostIt = postIt;
                        if(postIt.title === 'default'){
                            scope.background = "inherit";
                            scope.color =  defaultDateColor;
                        } else {
                        scope.background = postIt.background;
                        scope.color =  "#ffffff";
                        }
                        scope.saveChanges();
                    }
                    scope.selectCustomColor = function (color) {
                        scope.selectedPostIt = {title:"custom", background: color};
                        scope.background = color;
                        scope.color = "#ffffff";
                        scope.saveChanges();
                    }

                    scope.saveChanges = function() {
                        var newvalue = {
                            background: scope.background,
                            color: scope.color,
                            value: scope.value
                        }
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = newvalue;
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = newvalue;
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: scope.model.column.a3TableColumnId,
                                   a3Id: scope.model.a3.id,
                                   value: newvalue
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }
                   
                    }
                }
            }
        }]);
