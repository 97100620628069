var DigiLean = angular.module('DigiLean');
DigiLean.controller('flexiboardsController', ['$scope', '$translate', '$filter', '$state', 'folderService', 'dragulaService', 'projectService', 'employeeService', 'authService', 'navigationService',
    function ($scope, $translate, $filter, $state, folderService, dragulaService, projectService, employeeService, authService, navigationService) {
        $scope.title = 'Boards';
        $scope.quickAccess = [];
        $scope.allBoards = [];
        $scope.filteredBoards = [];
        var hasTeamSpace = navigationService().hasModule("TEAMSPACE");

        $scope.isAllBoardSelected = true;
        $scope.isSmartactionlistSelected = false;
        $scope.isWeeklyboardSelected = false;
        $scope.isTimeboardSelected = false;
        $scope.isKanbanboardSelected = false;
        $scope.isFlexiboardSelected = false;
        $scope.isPerformanceSelected = false;

        $scope.systemBoards = [
            {
                id: "TASKSEARCH",
                name: $translate.instant("COMMON_TASK_SEARCH"),
                boardType: 'TASKSEARCH',
                isStar: false,
            },
            {
                id: "PERSONAL",
                name: $translate.instant("BOARD_PERSONAL_BOARD"),
                boardType: 'PERSONAL',
                isStar: false,
            },
            {
                id: "MYSPACE",
                name: $translate.instant("COMMON_MY_SPACE"),
                boardType: 'MYSPACE',
                isStar: false
            }
        ];

        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            hasTeamSpace = navigationService().hasModule("TEAMSPACE");
            addTeamSpaceBoard();
        });


        $scope.toggleBoardType = function (type) {
            if (type == "Dashboard") {
                $scope.isAllBoardSelected = false;
                $scope.isSmartactionlistSelected = false;
                $scope.isWeeklyboardSelected = false;
                $scope.isTimeboardSelected = false;
                $scope.isKanbanboardSelected = false;
                $scope.isFlexiboardSelected = true;
                $scope.isPerformanceSelected = false;
            }
            else if (type == "Performance") {
                $scope.isAllBoardSelected = false;
                $scope.isSmartactionlistSelected = false;
                $scope.isWeeklyboardSelected = false;
                $scope.isTimeboardSelected = false;
                $scope.isKanbanboardSelected = false;
                $scope.isFlexiboardSelected = false;
                $scope.isPerformanceSelected = true;
            }
            else if (type == "SmartActionList") {
                $scope.isAllBoardSelected = false;
                $scope.isSmartactionlistSelected = true;
                $scope.isWeeklyboardSelected = false;
                $scope.isTimeboardSelected = false;
                $scope.isKanbanboardSelected = false;
                $scope.isFlexiboardSelected = false;
                $scope.isPerformanceSelected = false;
            }
            else if (type == "Weekly") {
                $scope.isAllBoardSelected = false;
                $scope.isSmartactionlistSelected = false;
                $scope.isWeeklyboardSelected = true;
                $scope.isTimeboardSelected = false;
                $scope.isKanbanboardSelected = false;
                $scope.isFlexiboardSelected = false;
                $scope.isPerformanceSelected = false;
            }
            else if (type == "Yearly") {
                $scope.isAllBoardSelected = false;
                $scope.isSmartactionlistSelected = false;
                $scope.isWeeklyboardSelected = false;
                $scope.isTimeboardSelected = true;
                $scope.isKanbanboardSelected = false;
                $scope.isFlexiboardSelected = false;
                $scope.isPerformanceSelected = false;
            }
            else if (type == "Kanban") {
                $scope.isAllBoardSelected = false;
                $scope.isSmartactionlistSelected = false;
                $scope.isWeeklyboardSelected = false;
                $scope.isTimeboardSelected = false;
                $scope.isKanbanboardSelected = true;
                $scope.isFlexiboardSelected = false;
                $scope.isPerformanceSelected = false;
            }
            else if (type == "all") {
                $scope.isAllBoardSelected = true;
                $scope.isSmartactionlistSelected = false;
                $scope.isWeeklyboardSelected = false;
                $scope.isTimeboardSelected = false;
                $scope.isKanbanboardSelected = false;
                $scope.isFlexiboardSelected = false;
                $scope.isPerformanceSelected = false;
            }
            filter(type);
        }

        function filter(type) {
            if ($scope.isAllBoardSelected){
                $scope.filteredBoards = $scope.boards;
            } else {
                var boards = $filter('filter')($scope.boards, { boardType: type })
                $scope.filteredBoards = boards;
            }
        }
        function addTeamSpaceBoard() {
            if (hasTeamSpace) {
                $scope.systemBoards.push({
                    id: "TEAMSPACE",
                    name: $translate.instant("COMMON_TEAM_SPACE"),
                    boardType: 'TEAMSPACE',
                    isStar: false

                });
            }
        }
        addTeamSpaceBoard();

        if (authService.hasRole("KaizenAdmin")) {
            $scope.systemBoards.push(
                {
                    id: "SYSTEMACTIVITY",
                    name: "Kundemonitorering",
                    boardType: 'SYSTEMACTIVITY',
                    isStar: false
                });
        }

        $scope.subscribe("BoardCreated", function (board) {
            getBoards();
        });
        $scope.subscribe("BoardDeleted", function (id) {
            getBoards();
        });

        $scope.createNew = function () {
            $state.go('newFlexiboard');
        }

        $scope.toggleFolder = function (folder) {
            folder.isOpen = !folder.isOpen;
        }

        $scope.addToQuickAccess = function (board) {
            board.isStar = true;
            $scope.quickAccess.push(board);
            saveQuickAccess();
        }


        $scope.removeFromQuickAccess = function (board) {
            board.isStar = false;
            var isFavorite = $filter('filter')($scope.quickAccess, { id: board.id })
            if (isFavorite.length > 0) {
                var currentIndex = $scope.quickAccess.indexOf(isFavorite[0]);
                $scope.quickAccess.splice(currentIndex, 1);
                saveQuickAccess();
            }
            var board = $filter('filter')($scope.allBoards, { id: board.id })
            if (board.length > 0) {
                board[0].isStar = false;
            }
        }
        var allProjects = [];
        function getBoards() {
            employeeService().getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                if (settings && settings.value) {
                    angular.forEach(settings.value, function (board) {
                        if (board) {
                            board.isStar = true;
                            board.hasAccess = false;
                            $scope.quickAccess.push(board);
                        }
                    });
                }
                projectService().getList(true).then(function (projects) {
                    allProjects = projects;
                    folderService().getFlexiBoards().then(function (boards) {
                        $scope.boards = boards;
                        $scope.filteredBoards = boards;
                        $scope.allBoards = boards.concat($scope.systemBoards);

                        angular.forEach($scope.allBoards, function (board) {
                            var isFavorite = $filter('filter')($scope.quickAccess, { id: board.id }, true)
                            if (isFavorite.length > 0) {
                                board.isStar = true;
                                isFavorite[0].asset = board.asset;
                                isFavorite[0].name = board.name;
                                isFavorite[0].hasAccess = true;
                            } else {
                                board.isStar = false;
                            }
                        });
                        // Remove quick access board no longer available
                        var quickAccessBoardsToRemove = $filter('filter')($scope.quickAccess, { hasAccess: false });
                        if (quickAccessBoardsToRemove.length > 0) {
                            quickAccessBoardsToRemove.forEach(x => {
                                // Only remove boards that are not system boards
                                if(!(x.boardType == 'PERSONAL' || x.boardType == 'MYSPACE' || x.boardType == 'TEAMSPACE' || x.boardType == 'SYSTEMACTIVITY'|| x.boardType == 'TASKSEARCH')){
                                    var index = $scope.quickAccess.indexOf(x);
                                    $scope.quickAccess.splice(index, 1);
                                }
                                
                            });
                            saveQuickAccess();
                        }

                        mapBoardsToProject($scope.quickAccess, allProjects);
                        mapBoardsToProject($scope.boards, allProjects);
                    });
                });

            });

        }

        getBoards();

        function mapBoardsToProject(boards, projects) {
            angular.forEach(boards, function (board) {
                // if asset is project, add project to board
                if (board.asset && board.asset.projectId) {
                    var project = $filter('filter')(allProjects, { id: board.asset.projectId }, true)
                    if (project.length > 0) {
                        board.project = project[0];
                    }
                }
            });
        }

        $scope.newFlexiboard = function () {
            $state.go('newFlexiboard', {}, { reload: false });
        }

        function saveQuickAccess() {

            employeeService().updateUserPreference("FlexiboardFavoriteBoards", $scope.quickAccess).then(function (settings) {
                $scope.publish("FlexiboardFavoriteBoardsChanged", $scope.quickAccess);
            });
        }

        dragulaService.options($scope, 'folder-bag', {
            removeOnSpill: false,
            moves: function (el, container, target) {
                if (target.classList) {
                    return target.classList.contains('parent-draggable');
                }
                return false;
            }
        });

        $scope.$on('board-bag.drop-model', function (el, target, source) {
            saveQuickAccess();
        });

        $scope.$on('board-bag.over', function (e, el, container) {
            container.addClass('drop-over');
        });

        $scope.$on('board-bag.out', function (e, el, container) {
            container.removeClass('drop-over');
        });

    }]);


