var DigiLean = angular.module('DigiLean');
DigiLean.directive("numericInput", ['$filter', 
    function ($filter) {
        return {
            templateUrl: 'numericInput.html',
            restrict: 'E',
            scope: {
                'value': '<',
                'valueChanged': '&',
            },
            link: function (scope, elem, attrs) {

                var commaActive = false;
                var tempStringValue = "";

                var countDecimals = function(value) {
                    let text = value.toString()
                    // verify if number 0.000005 is represented as "5e-6"
                    if (text.indexOf('e-') > -1) {
                      let [base, trail] = text.split('e-');
                      let deg = parseInt(trail, 10);
                      return deg;
                    }
                    // count decimals for number in representation like "0.123456"
                    if (Math.floor(value) !== value) {
                        var decimals = value.toString().split(".")[1];
                        if(decimals){
                            return decimals.length;
                        } else return 0;
                    }
                    return 0;
                  }

                function parseString(valueString){
                    if(valueString && valueString.length > 0){
                        var ending = valueString.substring(valueString.length-1, valueString.length);
                        if(ending == "0"){
                            var decimalPlaces = countDecimals(valueString);
                            if(decimalPlaces>0)
                                return parseFloat(valueString).toFixed(decimalPlaces);
                        }
                    }
                    return parseFloat(valueString);
                }

                // comments here
                scope.addNumber = function(number) {
                    if (!scope.value) {
                        scope.value = "";
                    }
                    // Treat value as string to add
                    var sum = scope.value.toString();
                    if(commaActive){
                        sum = sum + ".";
                        commaActive = false;
                    }
                    sum = sum + number.toString();
                    tempStringValue = sum;
                    var value = parseString(sum);//parseFloat(sum);
                    scope.valueChanged({number: value});
                }

                scope.backSpace = function() {
                    if (!scope.value) return;
                    // Treat value as string to add
                    var value = scope.value.toString();
                    value = value.substring(0, value.length - 1);
                    if (value) {
                        scope.valueChanged({number: parseString(value)});
                    } else {
                        scope.valueChanged({number: null});
                    }       
                }

                scope.comma = function() {

                    if (!scope.value) {
                        scope.value = "0";
                    }
                    var value = scope.value.toString();
                    if(value.includes(".")){
                        commaActive = false;
                    } else {
                        commaActive = true;
                    }
    
                }
            }
        }
    }]);
