import { NgComponent } from "@common/model/angularModel"
import angular from "angular"
const DigiLean = angular.module('DigiLean')

DigiLean.component('integrations', {
    templateUrl: "integrations.html",
    controller: ['$stateParams',
        function (this: NgComponent, $stateParams) {
            let $ctrl = this 

            $ctrl.indexTab = 0
            
            $ctrl.tabs = ["Monitor", "Config"]
            function selectTab (tab) {
                $ctrl.indexTab = $ctrl.tabs.indexOf(tab)
            }
            $ctrl.selectTab = selectTab
            
            //if ($stateParams.tab) {
            //    const tab = $stateParams.tab
            //    selectTab(tab)
            //} 
        }
    ]
})
