import * as assetService from "@common/services/assetService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('colorlabelConfigurationController', ['$scope', '$uibModalInstance', '$uibModal','$filter','$translate','authService','qualityService','areaService','projectService','navigationService','deviationService','configuration',
    function ($scope, $uibModalInstance, $uibModal, $filter,$translate,authService,qualityService,areaService,projectService,navigationService,deviationService,configuration) {
        $scope.settings = configuration;
        $scope.typeSelected = "";

        //Relevant for dataserie configuration
        $scope.formula = 'sum';

        $scope.availablevaluelabels = [
            { value: 0, label: '', show:false },
            { value: 1, label: '', show:false },
            { value: 2, label: '', show:false },
            { value: 3, label: '', show:false },
            { value: 4, label: '', show:false }
        ];

        $scope.operators = [
            { dbCode: ">=", html: "&gt;=" },
            { dbCode: ">", html: "&gt;" },
            { dbCode: "<=", html: "&lt;=" },
            { dbCode: "<", html: "&lt;" },
        ];

        $scope.hasProjectModule = navigationService().hasModule("PROJECT");
        $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasProjectModule = navigationService().hasModule("PROJECT");
            $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
            setAdminPrivilege();
        });

        $scope.selectStep = function (step) {
            $scope.currentStep = step;
        }

        $scope.steps = [];
        $translate(['COMMON_SOURCE', 'COMMON_LAYOUT','ADMIN_DATA_DATASOURCE_TARGETS']).then(function (translations) {
            $scope.steps.push({ id: 'COMMON_SOURCE', title: translations.COMMON_SOURCE, show:true });
            $scope.steps.push({ id: 'COMMON_LAYOUT', title: translations.COMMON_LAYOUT,show:true });
            $scope.steps.push({ id: 'ADMIN_DATA_DATASOURCE_TARGETS', title: translations.ADMIN_DATA_DATASOURCE_TARGETS,show:false });
            $scope.currentStep = $scope.steps[0];
            init();
        });
        $scope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('COMMON_SOURCE');
            $scope.steps[1].title = $translate.instant('COMMON_LAYOUT'); 
            $scope.steps[2].title = $translate.instant('ADMIN_DATA_DATASOURCE_TARGETS'); 
        });

        function setAdminPrivilege(){
            $scope.dataAdminPrivilege = false;
            $scope.isAdmin = authService.hasRole("Admin");
            $scope.isDataAdmin = authService.hasRole("DataAdmin");

            if($scope.isAdmin || $scope.isDataAdmin) {
                $scope.dataAdminPrivilege = true
            }
        }

        function init() {
            setAdminPrivilege();
            if ($scope.settings) {
                $scope.typeSelected = $scope.settings.typeSelected;
                $scope.identificationName = $scope.settings.identificationName;
                $scope.areaId = $scope.settings.areaId;
                $scope.projectId = $scope.settings.projectId;
                $scope.assetId = $scope.settings.assetId;
                $scope.dataSource = $scope.settings.dataSource;
                $scope.deviationTypeId = $scope.settings.deviationTypeId;
                $scope.visualizationType = $scope.settings.visualizationType;
                if($scope.settings.typeVariant){
                    $scope.typeVariant = $scope.settings.typeVariant;
                }
                
                for (var i = 0; i < $scope.availablevaluelabels.length; i++) {
                    checkShow($scope.availablevaluelabels[i], $scope.settings.valuelabels);
                }
                if($scope.settings.formula){
                    $scope.formula = $scope.settings.formula;
                }
                // DATASOURCE
                if($scope.dataSource && $scope.dataSource.id){
                    $scope.firstdecision = "datasource";
                    $scope.registration = 'readonly';
                    $scope.steps[1].show = false;
                    $scope.steps[2].show = true;
                    $scope.filters = $scope.settings.filters;

                //DEVIATION 
                }else if($scope.deviationTypeId){
                    $scope.loadDeviationTypes();
                    
                    $scope.firstdecision = "deviation";
                    $scope.steps[1].show = false; 
                    if($scope.assetId){
                        $scope.registration = 'edit';
                        $scope.typeSelected = 'asset'; 
                    } else {
                        if($scope.settings.filters){
                            $scope.filters = $scope.settings.filters; 
                            $scope.registration = 'readonlywithfilters';
                            $scope.typeSelected = 'filtered';
                            getDeviationFilterDatasource();
                        } else {
                            $scope.registration = 'readonly';
                            $scope.typeSelected = 'all';
                        }                
                    }
                }

                // MANUAL
                else if($scope.settings.assetId == null && $scope.settings.areaId == null && $scope.settings.projectId == null){
                    if($scope.settings.filters){
                        $scope.filters = $scope.settings.filters; 
                        $scope.registration = 'readonlywithfilters';
                        $scope.typeSelected = 'filtered';
                        $scope.firstdecision = "manual";
                        $scope.steps[1].show = false;
                        $scope.setRegistration($scope.registration);
                    } else {
                        $scope.registration = 'readonly';
                        $scope.typeSelected = 'all';
                        $scope.firstdecision = "manual";
                        $scope.steps[1].show = false;
                    } 
                } else {
                    $scope.registration = 'edit'; 
                    $scope.firstdecision = "manual"; 
                }
            }
        }
        
        $scope.changeFormula = function(newFormula){
            $scope.formula = newFormula;
        }

        $scope.setType = function(type){
            $scope.assetErrorMessage = null;
            $scope.projectErrorMessage = null;
            $scope.areaErrorMessage = null;
            if(type === 'area'){
                $scope.typeSelected = type;
                if(!$scope.areas){        
                    areaService().getList().then(function (areas) {
                        $scope.areas = areas;
                        if(!$scope.areaId){
                            if($scope.areas.length > 0){
                                $scope.updateArea($scope.areas[0]);
                            } else {
                                $scope.areaErrorMessage = $translate.instant('COMMON_NO_AREA');
                                $scope.areaId = null;
                                $scope.projectId = null;
                                $scope.assetId = null;
                            }
                        }
                    });
                } else {
                    if($scope.areas.length > 0){
                        $scope.updateArea($scope.areas[0]);
                    } else {
                        $scope.areaErrorMessage = $translate.instant('COMMON_NO_AREA');
                        $scope.areaId = null;
                        $scope.projectId = null;
                        $scope.assetId = null;
                    }
                }
            } else if(type === 'project'){
                $scope.typeSelected = type;
                if(!$scope.projects){        
                    projectService().getActiveProjects().then(function (projects) {
                        $scope.projects = projects;
                        if(!$scope.projectId){
                            if($scope.projects.length > 0){
                                $scope.updateProject($scope.projects[0]);
                            } else {
                                $scope.projectErrorMessage = $translate.instant('COMMON_NO_PROJECT');
                                $scope.areaId = null;
                                $scope.projectId = null;
                                $scope.assetId = null;
                            }
                        }
                    });
                } else {
                    if($scope.projects.length > 0){
                        $scope.updateProject($scope.projects[0]);
                    } else {
                        $scope.projectErrorMessage = $translate.instant('COMMON_NO_PROJECT');
                        $scope.areaId = null;
                        $scope.projectId = null;
                        $scope.assetId = null;
                    }
                }
            } else {
                $scope.typeSelected = type;
                if(!$scope.assets){        
                    assetService.getAllAssets().then(function (assets) {
                        $scope.assets = assets;
                        if(!$scope.assetId){
                            if($scope.assets.length > 0){
                                $scope.updateAsset($scope.assets[0]);
                            } else {
                                $scope.assetErrorMessage = $translate.instant('COMMON_NO_GROUP');
                                $scope.areaId = null;
                                $scope.projectId = null;
                                $scope.assetId = null;
                            }
                        }
                    });
                } else {
                    if($scope.assets.length > 0){
                        $scope.updateAsset($scope.assets[0]);
                    } else {
                        $scope.assetErrorMessage = $translate.instant('COMMON_NO_GROUP');
                        $scope.areaId = null;
                        $scope.projectId = null;
                        $scope.assetId = null;
                    }
                }
            }
        }


        $scope.loadAssets = function(){
            if(!$scope.assets){        
                assetService.getAllAssets().then(function (assets) {
                    $scope.assets = assets;
                    if(!$scope.assetId){
                        if($scope.assets.length > 0){
                            $scope.updateAsset($scope.assets[0]);
                        } else {
                            $scope.assetErrorMessage = $translate.instant('COMMON_NO_GROUP');
                            $scope.areaId = null;
                            $scope.projectId = null;
                            $scope.assetId = null;
                        }
                    }
                });
            } else {
                if(!$scope.assetId){
                    if($scope.assets.length > 0){
                        $scope.updateAsset($scope.assets[0]);
                    } else {
                        $scope.assetErrorMessage = $translate.instant('COMMON_NO_GROUP'); 
                        $scope.areaId = null;
                        $scope.projectId = null;
                        $scope.assetId = null;
                    }
                }
            }
        }
        $scope.loadAreas = function(){
            if(!$scope.areas){        
                areaService().getList(true).then(function (areas) {
                    $scope.areas = areas;
                    if(!$scope.areaId){
                        if($scope.areas.length > 0){
                            $scope.updateArea($scope.areas[0]);
                        } else {
                            $scope.areaErrorMessage = $translate.instant('COMMON_NO_AREA');
                            $scope.areaId = null;
                            $scope.projectId = null;
                            $scope.assetId = null;
                        }
                    }
                });
            } else {
                if(!$scope.areaId){
                    if($scope.areas.length > 0){
                        $scope.updateArea($scope.areas[0]);
                    } else {
                        $scope.areaErrorMessage = $translate.instant('COMMON_NO_AREA');
                        $scope.areaId = null;
                        $scope.projectId = null;
                        $scope.assetId = null;
                    }
                }
            }
        }

        $scope.loadDeviationTypes = function(){
            if(!$scope.deviationTypes){      
                deviationService().getAllDeviationTypes().then(function(types) {
                    $scope.deviationTypes = types;
                    if(!$scope.deviationTypeId){
                        if($scope.deviationTypes.length > 0){
                            $scope.updateDeviationType($scope.deviationTypes[0]);
                        } else {
                            $scope.deviationTypeErrorMessage = $translate.instant('DEVIATION_NO_DEVIATION_TYPES');
                        }
                    } else {
                        var type = $filter('filter')($scope.deviationTypes, { id: $scope.deviationTypeId }, true)[0];
                        if(type){
                            $scope.updateDeviationType(type);
                        } 
                    }
                });
            } else {
                if(!$scope.deviationTypeId){
                    if($scope.deviationTypes.length > 0){
                        $scope.updateDeviationType($scope.deviationTypes[0]);
                    } else {
                        $scope.deviationTypeErrorMessage = $translate.instant('DEVIATION_NO_DEVIATION_TYPES');
                    }
                }
            }
        }


        

        $scope.loadProjects = function(){
            if(!$scope.projects){        
                projectService().getActiveProjects().then(function (projects) {
                    $scope.projects = projects;
                    if(!$scope.projectId){
                        if($scope.projects.length > 0){
                            $scope.updateProject($scope.projects[0]);
                        } else {
                            $scope.projectErrorMessage = $translate.instant('COMMON_NO_PROJECT');
                            $scope.areaId = null;
                            $scope.projectId = null;
                            $scope.assetId = null;
                        }
                    }
                });
            } else {
                if(!$scope.projectId){
                    if($scope.projects.length > 0){
                        $scope.updateProject($scope.projects[0]);
                    } else {
                        $scope.projectErrorMessage = $translate.instant('COMMON_NO_PROJECT');
                        $scope.areaId = null;
                        $scope.projectId = null;
                        $scope.assetId = null;
                    }
                }
            }
        }
        $scope.setRegistration = function (value) {
            $scope.registration = value;
            if(value == 'edit'){
                $scope.steps[1].show = true;
                $scope.loadAssets();
            } else if(value == 'readonlywithfilters'){
                if(!$scope.filters) $scope.filters = [];
                getDeviationFilterDatasource();
                $scope.steps[1].show = false;
                $scope.readonlywithfilters();
            } else {
                $scope.steps[1].show = false;
                $scope.all();
            }
        };

        $scope.updateDeviationType = function (type){
            $scope.deviationTypeId = type.id;
            $scope.deviationTypeTitle = type.title;
        };

        $scope.updateArea = function (area){
            $scope.areaId = area.id;
            $scope.projectId = null;
            $scope.assetId = null;
            $scope.typeSelected = 'area';
            $scope.identificationName = area.name;
            $scope.registration = 'edit';
            //$scope.editable = true;
        };

        $scope.updateAsset = function (asset){
            $scope.areaId = null;
            $scope.projectId = null;
            $scope.assetId = asset.id;
            $scope.typeSelected = 'asset';
            $scope.identificationName = asset.name;
            $scope.registration = 'edit';
            //$scope.editable = true;
        };

        $scope.readonlywithfilters = function(){
            $scope.areaId = null;
            $scope.projectId = null;
            $scope.assetId = null;
            $scope.registration = 'readonlywithfilters';
            //$scope.editable = false;
            $scope.typeSelected = 'filter';
            $scope.identificationName = $translate.instant('COMMON_FILTER');
        }
        $scope.all = function (){
            $scope.areaId = null;
            $scope.projectId = null;
            $scope.assetId = null;
            $scope.registration = 'readonly';
            //$scope.editable = false;
            $scope.typeSelected = 'all';
            $scope.identificationName = $translate.instant('COMMON_ALL');
        };

        $scope.updateProject = function (project){
            $scope.areaId = null;
            $scope.projectId = project.id;
            $scope.typeSelected = 'project';
            $scope.identificationName = project.name;
            $scope.registration = 'edit';
            //$scope.editable = true;
        };

        function checkShow(availableitem, validItems) {
            var matching = $filter('filter')(validItems, { value: availableitem.value }, true);
            if ( matching && matching.length > 0) {
                availableitem.show = true;
                availableitem.label = matching[0].label;
            }
        }

        function resetValueLabels(){
            $scope.availablevaluelabels = [
                { value: 0, label: '', show:true },
                { value: 1, label: '', show:true },
                { value: 2, label: '', show:true },
                { value: 3, label: '', show:true },
                { value: 4, label: '', show:true }
            ];
        }

        $scope.setManual = function () {
            if($scope.firstdecision != "manual") resetValueLabels();
            $scope.firstdecision = "manual";
            $scope.registration = "edit";
            $scope.steps[1].show = true;
            $scope.steps[2].show = false;
            $scope.loadAssets();         
        };

        function resetFilters(){        
            $scope.filters = null;
        }

        $scope.setDeviation = function(){
            resetFilters();
            $scope.firstdecision = "deviation";
            $scope.registration = "edit";
            $scope.steps[1].show = false;
            $scope.steps[2].show = false;
            $scope.loadDeviationTypes();
            $scope.loadAssets(); 
        }

        $scope.selectDataSource = function () {
            $scope.firstdecision = "datasource";
            $scope.registration = "readonly";
            $scope.steps[1].show = false;
            $scope.steps[2].show = true;
            var hasDataSerie = false;
            if ($scope.dataSource) {
                hasDataSerie = true;
            }
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'dataSourceSingleSelector.html',
                controller: 'dataSourceSingleSelector',
                windowClass: 'newdeviation-modal-window',
                resolve: {
                    hasDataSerie: function () {
                        return hasDataSerie;
                    },
                    dataSource: function () {
                        return $scope.dataSource;
                    },
                    filters: function () {
                        return $scope.filters;
                    },
                    withTarget: function () {
                        return false;
                    },
                    type: function () {
                        return 'DAY';
                    },
                    externalOnly: function () {
                        return true;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.dataSource = result.dataSource;
                $scope.filters = result.filters;
                if(!hasDataSerie || !$scope.dataSource.targets){
                    var defaulttargets = [
                        {colorStyleClass: 'greencross-green',
                        operator: { dbCode: "<=", html: "&lt;=" },
                        targetValue: 0},
                        {colorStyleClass: 'greencross-yellow',
                        operator: { dbCode: "<=", html: "&gt;=" },
                        targetValue: 2},
                        {colorStyleClass: 'greencross-red',
                        operator: { dbCode: ">", html: "&gt;" },
                        targetValue: 2}
                    ];
                    $scope.dataSource.targets = defaulttargets;         
                }
                var matching = $filter('filter')($scope.steps, { id: 'ADMIN_DATA_DATASOURCE_TARGETS' }, true);
                if (matching.length > 0) {
                    $scope.currentStep = matching[0];
                }
            });
        };

        $scope.filterChangedHandler = function (filters) {
          $scope.filters = filters;  
        };

        function getDeviationFilterDatasource(){
            var deviationGroupElements = [
                {
                    label: $translate.instant('COMMON_ASSET'),
                    sourceColumn: "assetId",
                    type: "asset"
                },
                {
                    label: $translate.instant('COMMON_AREA'),
                    sourceColumn: "areaId",
                    type: "area"
                },                       
            ];
    
            if($scope.hasProjectModule){
                var project = {
                    label: $translate.instant('PROJECT_PROJECT'),
                    sourceColumn: "projectId",
                    type: "project"
                };
                deviationGroupElements.push(project);
            }
            var deviationNew = {
                id: "DeviationNew",
                title: $translate.instant('DEVIATION_NEW_DEVIATIONS'),
                description: $translate.instant('DASHBOARD_DEVIATION_NEW_DESC'),
                valueElement: {
                    unit: $translate.instant('COMMON_NUMBER'),
                    label: $translate.instant('COMMON_NUMBER')
                },
                objectSource: 'internal',
                ownerUserDisplayName: 'DigiLEAN',
                elements: deviationGroupElements
            };
            $scope.filterDatasource = deviationNew;
        }

        $scope.ok = function () {
            var validItems = $filter('filter')($scope.availablevaluelabels, { show: true }, true);
            if (validItems.length > 0) {
                $scope.settings.valuelabels = validItems;
            } else {
                $scope.settings.valuelabels = null; //TODO sjekk at denne ikke ødelegger og setter feil show-status logikk
            }

            $scope.settings.areaId = $scope.areaId
            $scope.settings.projectId = $scope.projectId;
            $scope.settings.assetId = $scope.assetId;
            if($scope.registration == 'readonlywithfilters'){
                $scope.dataSource = null;
                $scope.identificationName = "";
                // Normally filters will have values
            } else if($scope.firstdecision != 'datasource'){
                $scope.dataSource = null;
                $scope.filters = null;
            }
            if($scope.dataSource){              
                $scope.identificationName = "";
            }
            else if($scope.settings.areaId == null && $scope.settings.projectId == null && $scope.settings.assetId == null){
                $scope.all();
            }
            $scope.settings.formula = $scope.formula;
            $scope.settings.dataSource = $scope.dataSource;
            $scope.settings.filters = $scope.filters;
            $scope.settings.identificationName = $scope.identificationName;
            $scope.settings.typeVariant = $scope.typeVariant;
            $scope.settings.typeSelected = $scope.typeSelected;
            
            
            if($scope.firstdecision == 'deviation'){
                $scope.settings.deviationTypeId = $scope.deviationTypeId;
            } else {
                $scope.settings.deviationTypeId = null;
            }
            $uibModalInstance.close($scope.settings);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        
        $scope.updateVariantType = function(variant){
            $scope.typeVariant = variant.type;
            if($scope.typeVariant == $scope.visualizationType){
                $scope.typeVariant = null;
            } 
        };

        $scope.getRelevantVisualizationTypes = function(){
            if($scope.visualizationTypes) return;
            qualityService().getVisualizationTypeVariants($scope.visualizationType).then(function(result) {
                var variants = result;
                if($scope.visualizationType == 'DYNAMIC'){
                    var standardTypes = qualityService().getStandardVisualizationTypes();
                    $scope.visualizationTypes = standardTypes;
                    if(variants){
                        for(var i=0; i<variants.length; i++){
                            $scope.visualizationTypes.push({
                                type: variants[i],
                                title: variants[i]
                            })
                        }
                    }
                } else {
                    var defaultType = {
                        type: $scope.visualizationType,
                        title: $translate.instant('COMMON_DEFAULT')
                    };
                    $scope.visualizationTypes = [defaultType];
                    if(variants){
                        for(var i=0; i<variants.length; i++){
                            if(variants[i].includes('-')){
                                var charToBeRemoved = $scope.visualizationType.length;
                                var variant = variants[i].substr(charToBeRemoved+1);
                                $scope.visualizationTypes.push({
                                    type: variant, //variants[i], ly the variant description must be stored (nor the main type e.g. C-)
                                    title: variant
                                })
                            }
                        }
                    }
                }
            }); 
        }
        $scope.newVariant = function (newname) {
            $translate(['COMMON_CANCEL', 'COMMON_SUBMIT', 'ADMIN_NEW_CUSTOMER']).then(function (translations) {
            
            swal({
                text: translations.COMMON_CREATE_NEW,
                content: "input",
                button: {
                  text: translations.COMMON_SUBMIT,
                  closeModal: true,
                  closeOnClickOutside: true,
                  closeOnEsc: true
                },
              }).then(name => {
                if (!name) throw null;
                if ($scope.visualizationType == "DYNAMIC"){
                    $scope.typeVariant = "DYNAMIC-" + name;
                } else {
                    $scope.typeVariant = name;
                }     
              })
            }); 

        };

    }]);