﻿var DigiLean = angular.module('DigiLean');
DigiLean.directive("a3milestones", ['$filter', '$translate', 'a3Service',
    function ($filter, $translate, a3Service) {
        return {
            templateUrl: 'a3milestones.html',
            restrict: 'E',
            scope: {
                'a3ProjectId': '=',
                'canEdit': '=',
                'readonly': '@'
            },
            link: function (scope, element, attrs) {

                scope.milestones = [];
     

                scope.$watch("a3ProjectId", function () {
                    if (!scope.a3ProjectId) return;
                    // get milestones
                    a3Service().getMilestonesForA3Project(scope.a3ProjectId).then(function (milestones) {
                        angular.forEach(milestones, function(milestone){
                            milestone.plannedDate = moment(milestone.plannedDate).toDate();
                            milestone.actualDate = moment(milestone.actualDate).toDate();
                            milestone.datePicker = {
                                opened: false
                            },
                            milestone.endDatePicker = {
                                opened: false
                            }
                        })
                        scope.milestones = milestones;
                    });
                });
                

                scope.add = function () {
                    var milestone = {
                        name:  $translate.instant('PROJECT_MILESTONE'),
                        a3ProjectId: scope.a3ProjectId,
                        plannedDate: moment().toDate(),
                        completedDate: null,
                        datePicker : {
                            opened: false
                        },
                        endDatePicker : {
                            opened: false
                        }
                    };
                    a3Service().createA3Milestone(milestone).then(function (milestone) {
                        milestone.datePicker = {
                            opened: false
                        },
                        milestone.endDatePicker = {
                            opened: false
                        }
                        scope.milestones.push(milestone);
                    });
                }

                scope.delete = function (milestone) {
                    a3Service().deleteA3Milestone(milestone.id).then(function () {
                        var index = scope.milestones.indexOf(milestone);
                        scope.milestones.splice(index, 1);    
                    });
                    
                }

                scope.milestoneChanged = function (milestone) {
                    a3Service().updateA3Milestone(milestone)
                }

                scope.milestonePlannedDateChanged = function(milestone, $event) {
                    const date = $event.originalEvent.detail
                    milestone.plannedDate = date
                    scope.milestoneChanged(milestone)
                }
                scope.milestoneActualDateChanged = function(milestone, event) {
                    const date = event.originalEvent.detail
                    milestone.actualDate = date
                    scope.milestoneChanged(milestone)
                }
            }
        }
    }])
