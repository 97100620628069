import * as customerService from "@common/services/customers/customerService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .controller("newtasktoprojecttasklistController", ['$scope', '$filter', '$uibModalInstance', '$translate', 'boardTaskService', 'projectService', 'taskDrawingService', 'projectId',
        function ($scope, $filter, $uibModalInstance, $translate, boardTaskService, projectService, taskDrawingService, projectId) {


            $scope.boards = {};
            $scope.selectedEmployee = {};
            var translatedProjectTaskList = $translate.instant('PROJECT_PROJECT_TASKS');
            $scope.selectedBoard = null;//translatedPersonalBoard;
            // the new action object
            $scope.task = {
                id: 0,
                priorityStatus: 0,
                isActionItem: true,
                status: 'blank'
            };


            $scope.emptyUser = {
                id: '',
                userName: null,
                profileImageUrl: null,
                fullName: null,
                displayName: null
            }
            /* Froala has some issues unless given a little pause before showing  */
            $scope.isFormReady = false;
            setTimeout(function () {
                $scope.isFormReady = true;
            }, 100);


            var projectBoardId = null; // the project's default task list


            projectService().getBoardsForProject(projectId).then(function (boards) {
                $scope.boards = boards;
                projectService().getBoardForProject(projectId).then(function (board) {
                    projectBoardId = board.id;
                    var existboard = $filter('filter')($scope.boards, { id: board.id }, true);
                    if (existboard.length > 0) {
                        $scope.selectedBoard = existboard[0];
                    } else {
                        $scope.boards.unshift(board);
                        $scope.selectedBoard = board;
                    }
                });
            });

            customerService.getAllUsers().then(function (users) {
                $scope.allusers = users;
                for (var i = 0; i < $scope.allusers.length; i++) {
                    var imageUrl = taskDrawingService().getProfileImageUrl($scope.allusers[i].userId, "ExtraSmall");
                    if (imageUrl) {
                        $scope.allusers[i].profileImage = true;
                        $scope.allusers[i].profileImageUrl = imageUrl;
                    } else {
                        $scope.allusers[i].profileImage = false;
                    }
                }
                $scope.employees = $scope.allusers;
            });


            $scope.boardDateChanged = function($event) {
                const date = $event.originalEvent.detail
                $scope.task.boardDate = date
            }


            // Make sure boardDate is date object 
            $scope.descriptionChanged = function (content) {
                $scope.task.text = content
            }

            $scope.changePriorityStatus = function (priority) {
                $scope.task.priorityStatus = priority;
            }


            $scope.selectUser = function (user) {

                $scope.task.responsibleImageUrl = user.profileImageUrl;
                $scope.task.responsibleUserId = user.id;
                $scope.task.responsibleUser = user.fullName;
                $scope.task.responsibleDisplayName = user.displayName;
                if (user.id == '') {
                    $scope.task.user = null;
                } else {
                    $scope.task.user = user;
                }
            }

            $scope.getUserListForBoard = function (board) {
                if (board.id == projectBoardId) {
                    $scope.employees = $scope.allusers;
                    if ($scope.task && $scope.task.responsibleUserId) {
                        $scope.task.user = $filter('filter')($scope.allusers, { id: $scope.task.responsibleUserId }, true)[0];
                    }
                } else {
                    boardService.getUserListForBoard(board.id).then(function (data) {
                        angular.forEach(data, function (employee, key) {
                            employee.fullName = employee.firstName + " " + employee.lastName;
                        });
                        $scope.employees = data;
                        if (data.length > 0) {
                            if ($scope.task && $scope.task.responsibleUserId) {
                                $scope.task.user = $filter('filter')(data, { id: $scope.task.responsibleUserId }, true)[0];
                            }
                        }
                    });
                }
            }

            $scope.ok = function () {
                if (!$scope.selectedBoard) return;

                $scope.task.boardId = $scope.selectedBoard.id;

                boardTaskService().addTask($scope.task).then(function (taskId) {
                    $scope.task.id = taskId;
                    $scope.publish("SendCreateActionListItem", $scope.task);
                    $scope.task.board = $scope.selectedBoard;
                    $uibModalInstance.close($scope.task);
                });

            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };


            /*
            setTimeout(function () {
                $("#taskTags").tagit({
                    // availableTags: sampleTags,
                    // This will make Tag-it submit a single form value, as a comma-delimited field.
                    singleField: true,
                    singleFieldNode: $('#tagsSelected')
                });
            }, 200);*/
        }]);