angular
    .module('DigiLean')
    .directive("a3StatusCell", ['$filter', '$translate', 'a3Service',
        function ($filter, $translate, a3Service) {
            return {
                templateUrl: 'a3StatusCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                },
                link: function (scope, elem, attrs) {
                   
                   
                    scope.statusOptions = a3Service().getStatus();

                    scope.$watch('model', function (model) {
                        scope.selectedStatusOption = $filter('filter')(scope.statusOptions, { statusDB: model.a3.status }, true)[0];
                    
                        model.getSortValue = function() {
                            return scope.selectedStatusOption.sortorder;
                        }
                        
                    });

                    scope.subscribe("A3StatusChanged", function (status) {
                        if (status.a3Id == scope.model.a3.id) {
                            if (status.status) {
                                 scope.selectedStatusOption = $filter('filter')(scope.statusOptions, { statusDB: status.status }, true)[0];             
                      
                            }
                        }
                    });

                    scope.autofocus = function(){
                        setTimeout(function() {
                            //document.querySelector( "input" ).focus();
                            $(".project-status-cell").focus();
                        }, 50 );
                    }

                    scope.statusChanged = function(status) {      
                        if(scope.selectedStatusOption && status === scope.selectedStatusOption.statusDB){
                            return;       
                        }
                        a3Service().updateA3Status(scope.model.a3.id, status).then(function (result) {                         
                        });
                    }

                }
            }
        }]);
