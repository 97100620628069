import * as dashboardService from "@common/services/dashboardService"

angular
    .module("DigiLean")
    .directive('performanceboardConfig', ['$filter', 'deviationService','appstoreService',
        function ($filter, deviationService, appstoreService) {
            return {
                templateUrl: 'performanceboardConfig.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'model': '<',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.theme = {
                        background: "transparent",
                        color: "inherit"
                    };
                    scope.postIts = dashboardService.getThemes();
                    var apps = appstoreService().getApps();
                    scope.kpiTypes = [
                        {
                            type: "5s",
                            title: "5S"
                        },
                        {
                            type: "greencross",
                            title: "Green cross"
                        },
                        {
                            type: "A",
                            title: "A"
                        },
                        {
                            type: "C",
                            title: "C"
                        },
                        {
                            type: "D",
                            title: "D"
                        },
                        {
                            type: "I",
                            title: "I"
                        },
                        {
                            type: "K",
                            title: "K"
                        },
                        {
                            type: "L",
                            title: "L"
                        },
                        {
                            type: "P",
                            title: "P"
                        },
                        {
                            type: "Q",
                            title: "Q"
                        },
                    ];
                    // Map image Url
                    scope.kpiTypes.forEach(item => {
                        item.imageUrl = getImageUrl(item.type);
                    });
               

                    function getImageUrl(app) {
                        var matchingApp = $filter('filter')(apps, { component: app }, true); 
                            if (matchingApp.length > 0) {
                                return matchingApp[0].previewImageUrl;
                            } else {
                                return "";
                            }
                    }


                    deviationService().getAllDeviationTypes().then(function (types) {
                        scope.deviationTypes = types;
                        // map to columns.
                    });

                    scope.createNew = function () {
                        var column = {
                            id: 0,
                            type: scope.kpiTypes[0].type,
                            deviationTypeId: scope.deviationTypes[0].id,
                            title: "New column"
                        }
                        column.imageUrl = getImageUrl(column.type)
                        scope.columns.push(column);
                    };

                    scope.delete = function (column) {
                        var index = scope.columns.indexOf(column);
                        scope.columns.splice(index, 1);
                    }

                    scope.$watch('model', function (model) {
                        scope.model = model; // Should be board object
                        scope.columns = model.columns;
                    });

                }
            }
        }]);

