import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"

import "./registration.css"

angular
    .module('DigiLean')
    .directive("registration", ['$filter', 'authService', 'dataService', '$uibModal', 'dataSourceService', 'areaService', 'projectService', 'dataListService',
        function ($filter, authService, dataService, $uibModal, dataSourceService, areaService, projectService, dataListService) {
            return {
                templateUrl: 'registration.html',
                restrict: 'E',
                scope: {
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'isAdminMode': '=',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.selectedAsset = null;
                    scope.selectedArea = null;
                    scope.selectedProject = null;
                    scope.selectedDate = moment().startOf('day').toDate(); //removed time portion. Check if this may affect utc date
                    scope.value = null;
                    scope.description = "";
                    scope.selectedItemForUpdate = null;
                    scope.isProcessing = false;
                    
                    scope.showTime = false;
                    var now = moment().toDate(); 
                    //set the time to 12:00
                    scope.mytime = now.setHours(12,0,0);
                    scope.hstep = 1;
                    scope.mstep = 5;
                    scope.options = {
                        hstep: [1, 2, 3],
                        mstep: [1, 5, 10, 15, 25, 30]
                    };

                    scope.selectedDataListItem = null;
                    scope.selectedDataList = null;
                    scope.datalists = [];
                    scope.filters = [];
                    scope.timePeriod = null;
                    scope.areas = [];
                    scope.assets = [];
                    scope.projects = [];
                    scope.allUsers = [];

                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin");
                    });

                    scope.subscribe("DataSourceUpdated", function (dataSource) {
                        if (!scope.dataSource) return;
                        if (dataSource.dataSourceId === scope.dataSource.id) {
                            scope.setDataSource(dataSource.dataSourceId);
                        }
                    });
                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        // apply
                        if (settings) {
                            if (settings.filters) {
                                scope.filters = settings.filters;
                            }
                            if (settings.timePeriod) {
                                scope.timePeriod = settings.timePeriod;
                                scope.selectedDate = getValueDate();
                            }
                            scope.setDataSource(settings.dataSource.id);
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                        }
                    });

                    scope.changeTheme = function (theme) {
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    scope.$watch('selectedItemForUpdate', function (item) {
                        if (item) {
                            scope.selectedAsset = null;
                            scope.selectedArea = null;
                            scope.selectedProject = null;
                            scope.selectedDataListItem = null;
                            scope.selectedItemForUpdate = item;
                            if (item.user) item.user = null;
                            var asset = $filter('filter')(scope.assets, { id: item.assetId }, true);
                            if (asset.length > 0) {
                                scope.selectedAsset = asset[0];
                            }
                            angular.forEach(scope.dataSource.elements, function (element) {
                                element.value = null;
                                if (element.type === "user") {
                                    element.user = null;
                                } else if (element.type === "asset"){
                                    var selectedAsssetId = null;
                                    if(scope.selectedAsset) selectedAsssetId=scope.selectedAsset.id;
                                    scope.assetmodel = { assetId:selectedAsssetId,
                                        assets: scope.assets,
                                    item:element};
                                }
                            });

                            var area = $filter('filter')(scope.areas, { id: item.areaId }, true);
                            if (area.length > 0) {
                                scope.selectedArea = area[0];
                            }
                            var project = $filter('filter')(scope.projects, { id: item.projectId }, true);
                            if (project.length > 0) {
                                scope.selectedProject = project[0];
                                scope.projectmodel = { projectId:scope.selectedProject.id,
                                    projects: scope.projects};
                            }
                            angular.forEach(scope.dataSource.elements, function (element) {
                                var value = item[element.sourceColumn];
                                if (element.type === "number" && value) {
                                    value = parseFloat(value);
                                } else if(element.type === "bool") {
                                    if (value && value == "true") {
                                        value = true;
                                    } else {
                                        value = false;
                                    }
                                } else if (element.type === "user" && value) {
                                    var user = $filter('filter')(scope.allUsers, { userId: value }, true);
                                    if (user.length > 0) {
                                        element.user = user[0];
                                    }
                                } else if (element.type === "list") {
                                    if (value) {
                                        var listitem = $filter('filter')(element.dataList, { id: parseInt(value) }, true);
                                        if (listitem.length === 1) {
                                            element.selectedDataListItem = listitem[0];
                                        } else {
                                            element.selectedDataListItem = {};
                                        }
                                    } else {
                                        element.selectedDataListItem = {};
                                    }

                                }// need to cast/parse
                                element.value = value;

                            });
                            scope.selectedDate = moment(item.valueDate).toDate();
                            scope.mytime = moment(item.valueDate).toDate();
                            scope.value = item.value;
                            scope.description = item.description;
                        }
                    });

                    function getValueDate(){
                        var toDay = moment().startOf('day');
                        if(scope.timePeriod){
                            var toDate = moment(scope.timePeriod.to).startOf('day');
                            if(toDate.isBefore(toDay)){
                                return toDate.toDate();
                            }
                        }
                        return toDay.toDate();
                    }

                    scope.setTime = function (time) {
                        //scope.mytime = time;
                        scope.selectedDate.setHours(time.getHours());
                        scope.selectedDate.setMinutes(time.getMinutes());
                    };

                    function initRegistration() {
                        scope.isProcessing = false;
                        if(!scope.dataSource) return;
                        if(scope.dataSource.unitOfTime == "DAY"){
                            scope.showTime = true;
                        }
                        scope.value = null;
                        scope.description = "";
                        scope.selectedDate = getValueDate();
                        scope.mytime = scope.selectedDate.setHours(12,0,0);
                        scope.selectedDataListItem = null;
                        angular.forEach(scope.dataSource.elements, function (element) {
                            var hasFilter = $filter('filter')(scope.filters, { sourceColumn: element.sourceColumn }, true);
                            if (hasFilter.length > 0) {
                                if (hasFilter[0].items.length == 1) {
                                    element.value = hasFilter[0].items[0]; //preselect the first filtered item of this filterelement
                                    element.registerValue = false;
                                } else { //multiple selected elements
                                    setFilteredSelectors(element, hasFilter[0].items)
                                }
                                if(element.type == "list"){ //need to have the list available in case of updates
                                    var listOptions = { includeDeletedItems: false };
                                    dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                        if (list) {
                                            element.dataList = list;
                                        }
                                    });
                                }
                            }
                            else {
                                element.registerValue = true;
                                switch (element.type) {
                                    case "asset":
                                        assetService.getAllAssets(true).then(function (data) {
                                            scope.assets = data;
                                            for (var k = 0; k < scope.assets.length; k++) {
                                                scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                                            }
                                            scope.assetmodel = { assetId:null,
                                                assets: scope.assets,
                                                item:element};
                                        });
                                        break;
                                    case "area":
                                        areaService().getList(true).then(function (data) {
                                            scope.areas = data;
                                        });
                                        break;
                                    case "project":
                                        projectService().getList(true).then(function (data) {
                                            scope.projects = data;
                                            scope.projectmodel = { projectId:null,
                                                projects: scope.projects};
                                        });
                                        break;
                                    case "user":
                                        customerService.getAllUsers().then(function (users) {
                                            scope.allUsers = users;
                                        });
                                        break;
                                    case "list":
                                        var listOptions = { includeDeletedItems: false };
                                        dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                            if (list) {
                                                element.dataList = list;
                                                element.selectedDataListItem = {};
                                            }
                                        });
                                        break;
                                    default:
                                        element.value = null;

                                }
                            }
                        });

                    }

                    function setFilteredSelectors(element, filteritems) {
                        element.registerValue = true;
                        switch (element.type) {
                            case "asset":
                                assetService.getAllAssets(true).then(function (data) {
                                    scope.assets = [];
                                    for (var i = 0; i < filteritems.length; i++) {
                                        var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                        if (hasElement.length > 0) {
                                            scope.assets.push(hasElement[0]);
                                        }
                                    }
                                    var selectedAsssetId = null;
                                    if(scope.assets.length == 1) selectedAsssetId = scope.assets[0].id;
                                    scope.assetmodel = { assetId:selectedAsssetId,
                                    assets: scope.assets,
                                    item: element};
                                });
                                break;
                            case "area":
                                areaService().getList(true).then(function (data) {
                                    scope.areas = [];
                                    for (var i = 0; i < filteritems.length; i++) {
                                        var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                        if (hasElement.length > 0) {
                                            scope.areas.push(hasElement[0]);
                                        }
                                    }
                                });
                                break;
                            case "project":
                                projectService().getList(true).then(function (data) {
                                    scope.projects = [];
                                    for (var i = 0; i < filteritems.length; i++) {
                                        var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                        if (hasElement.length > 0) {
                                            scope.projects.push(hasElement[0]);
                                        }
                                    }
                                });
                                break;
                            case "user":
                                customerService.getAllUsers().then(function (data) {
                                    scope.allUsers = [];
                                    for (var i = 0; i < filteritems.length; i++) {
                                        var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                        if (hasElement.length > 0) {
                                            scope.allUsers.push(hasElement[0]);
                                        }
                                    }
                                });
                                break;
                            case "list":
                                var listOptions = { includeDeletedItems: false };
                                dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                    if (list) {
                                        element.dataList = [];
                                        for (var i = 0; i < filteritems.length; i++) {
                                            var hasElement = $filter('filter')(list, { id: filteritems[i] }, true);
                                            if (hasElement.length > 0) {
                                                element.dataList.push(hasElement[0]);
                                            }
                                        }
                                        element.selectedDataListItem = {};
                                    }
                                });
                                break;
                            default:
                                element.value = null;
                        }
                    }

                    scope.setDataSource = function (id) {
                        dataSourceService().get(id).then(function (dataSource) {
                            scope.dataSource = dataSource;
                            // Add value property for element
                            angular.forEach(scope.dataSource.elements, function (element) {
                                element.value = null;
                            });
                            initRegistration();
                        });
                    }

                    scope.updateSettings = function () {
                        var componentSettings = {
                            dataSource: scope.dataSource,
                            filters: scope.filters,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    };

                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                    scope.toDate = moment().toDate();

                    scope.selectedDateChanged = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.selectedDate = date;
                    }

                    scope.assetChangeHandler = function (assetinfo) {
                        if (assetinfo) {
                            assetinfo.model.item.value = assetinfo.asset.id;
                            scope.selectedAsset = assetinfo.asset;
                        }       
                    }

                    scope.selectArea = function (area, item) {
                        scope.selectedArea = area;
                        item.value = area.id;
                    }
                    scope.selectProject = function (project, item) {
                        scope.selectedProject = project;
                        item.value = project.id;
                    }
                    scope.projectChangeHandler = function (projectinfo) {
                        if (projectinfo) {
                            var project = projectinfo.project;
                            var item = projectinfo.model;
                            scope.selectedProject = project;
                            if (item) {
                                item.project = project;
                                item.value = project.id;
                                item.dataMissing = false;
                            } else {
                                checkMandatoryField();
                            }
                        } else {
                            scope.selectedProject = null;
                        }
                    }
                    scope.userChangeHandler = function (userinfo) {
                        if (userinfo) {
                            var user = userinfo.user;
                            var item = userinfo.model;
                            if (item) {
                                item.user = user;
                                item.value = user.userId;
                                item.dataMissing = false;
                            } else {
                                checkMandatoryField();
                            }
                        }
                    }

                    function checkMandatoryField() {
                        angular.forEach(scope.dataSource.elements, function (element) {
                            if (element.type == 'project') {
                                if (scope.selectedProject && scope.selectedProject.id) {
                                    element.dataMissing = false;
                                    element.value = scope.selectedProject.id;
                                } else {
                                    element.dataMissing = true;
                                }
                            }
                            if (element.type !== 'bool' && !element.value && element.isMandatory) {
                                element.dataMissing = true;
                            } else {
                                element.dataMissing = false;
                            }
                        });
                    }

                    scope.selectUser = function (user, item) {
                        item.user = user;
                        item.user.responsibleImageUrl = user.profileImageUrl;
                        item.user.responsibleUserId = user.id;
                        item.user.responsibleUser = user.fullName;
                        item.user.responsibleDisplayName = user.displayName;
                        if (user.id == '') {
                            item.user = null;
                            item.value = null;
                            item.dataMissing = true;
                        } else {
                            item.user = user;
                            item.value = user.userId;
                            item.dataMissing = false;
                        }
                    }

                    scope.selectListItem = function (selectedDataListItem, item) {
                        if (selectedDataListItem.name) {
                            scope.selectedDataListItem = selectedDataListItem;
                            item.value = selectedDataListItem.id;
                        } else { // blank list element
                            scope.selectedDataListItem = {};
                            item.value = null;
                        }

                    }


                    scope.save = function () {
                        scope.isProcessing = true;
                        var utcDate = moment.utc(scope.selectedDate).format();
                        var valueString = scope.value.toString();
                        var valuereplacecomma = valueString.replace(/,/g, '.');
                        var registration = {
                            valueDate: utcDate,
                            value: valuereplacecomma,
                            description: scope.description
                        }
                        var saveOK = true;
                        angular.forEach(scope.dataSource.elements, function (element) {
                            if (element.type == 'project') {
                                if (scope.selectedProject && scope.selectedProject.id) {
                                    element.value = scope.selectedProject.id;
                                    element.dataMissing = false;
                                } else if (element.value) { //filter value is used
                                    element.dataMissing = false;
                                } else if (element.isMandatory) {
                                    element.dataMissing = true;
                                    saveOK = false;
                                    element.value = null;
                                }
                            } else if (element.type == 'bool'){
                                if(!element.value){ //either the boolean value is true or false, it should never be NULL
                                    element.value = false;
                                }
                            }
                            registration[element.sourceColumn] = element.value;

                            if (element.type !== 'bool' && !element.value && element.isMandatory) {
                                element.dataMissing = true;
                                saveOK = false;
                            } else {
                                element.dataMissing = false;
                            }
                        });
                        if (saveOK) {

                            if (scope.selectedItemForUpdate) {
                                registration.id = scope.selectedItemForUpdate.id;
                                dataService().update(registration.id, registration).then(function (savedRegistration) {
                                    scope.clearRegistration();
                                });
                            } else {
                                dataService().add(scope.dataSource.id, registration).then(function (savedRegistration) {
                                    scope.clearRegistration();
                                });
                            }
                        }
                        scope.isProcessing = false;
                    }

                    scope.clearRegistration = function () {
                        scope.isProcessing = false;
                        scope.value = null;
                        scope.description = "";
                        scope.selectedItemForUpdate = null;
                        scope.selectedDate = getValueDate();
                        clearTimePicker();
                        scope.selectedDataListItem = null;
                        scope.selectedProject = null;
                        scope.selectedAsset = null;
                        angular.forEach(scope.dataSource.elements, function (element) {
                            if (!(element.type === 'asset' || element.type === 'area' || element.type === 'project' || element.type === 'user' || element.type === 'list')) element.value = null;
                        });

                    }

                    function clearTimePicker(){
                        var updateTime = new Date();
                        updateTime.setHours( 12 );
                        updateTime.setMinutes( 0 );
                        scope.mytime = updateTime;
                    }

                    scope.selectDataSource = function () {
                        var hasDataSerie = false;
                        if (scope.dataSource) {
                            hasDataSerie = true;
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return scope.dataSource;
                                },
                                filters: function () {
                                    return scope.filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return true;
                                }

                            }
                        });

                        modalInstance.result.then(function (result) {
                            scope.dataSource = result.dataSource;
                            scope.filters = result.filters;
                            scope.setDataSource(scope.dataSource.id);
                            scope.updateSettings();
                        });
                    };

                }

            }
        }]);
