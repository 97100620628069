import angular from "angular"
import toastr from "toastr"
import * as connectorAdminService from "@common/services/admin/connectorAdminService"
import { formatDateTime } from "@common/services/datetime/dateTimeFormattingLocale"
import type { Connector, Heartbeat, Job } from "@api"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')
DigiLean.component('connectorAdmin', {
    templateUrl: "connectorAdmin.html",
    controller: ['$scope', '$stateParams', '$translate', '$uibModal', 'modalService',
        function(this: NgComponent, $scope, $stateParams, $translate, $uibModal, modalService) {
            let $ctrl = this
            
            $ctrl.timezone = new Date().toTimeString().slice(9)
            $ctrl.jobs = []
            $ctrl.jobtypes = []

            let connectorId = $stateParams.id as string
            $ctrl.connector = {}

            $ctrl.isKaizenAdmin = false

            $ctrl.$onInit = function() {
                loaddatas()
            }

            async function loaddatas() {
                const list = await connectorAdminService.getJobs(connectorId)
                $ctrl.jobs = list
                const connector = await connectorAdminService.get(connectorId)
                $ctrl.connector = connector
                const jobtypes = await connectorAdminService.getJobTypes()
                $ctrl.jobtypes = jobtypes
            }

            $ctrl.updateConnector = async function () {
                const uptConnector = await connectorAdminService.update($ctrl.connector)
                $scope.publish("SendConnectorUpdated", uptConnector)
            }
            
            $ctrl.startConnector = function (connector: Connector) {
                $ctrl.publish("SendStartConnector", {id: connector.id, customerId: connector.customerId})
            }

            $ctrl.stopConnector = function (connector: Connector) {
                $ctrl.publish("SendStopConnector", {id: connector.id, customerId: connector.customerId})
            }

            $ctrl.addJob = function () {
                $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newJob.html',
                    controller: 'newJobController',
                    resolve: {
                        connectorId: function () {
                            return connectorId
                        }
                    }
                })
            }
            $ctrl.delete = async function (job: Job) {
                const translations = await $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_JOB_CONFIRMATION'])
                const modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + job.name + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_JOB_CONFIRMATION.replace(' ?', ' ' + job.name + '?')
                };

                await modalService.showModal({}, modalOptions).catch(err => {
                    if (err !== "cancel")
                        console.error(err)
                })
                const deletedJob = await connectorAdminService.deleteJob(job.id)
                $scope.publish("SendJobDeleted", deletedJob)

                const msg = await $translate('ADMIN_MESSAGE_JOB_DELETED')
                toastr.info(msg)
            }

            $ctrl.start = function (connector: Connector) {
                $scope.publish("SendStartConnector", connector);
            }
            $ctrl.stop = function (connector: Connector) {
                $scope.publish("SendStopConnector", connector);
            }
            $ctrl.runJobNow = function (job: Job) {
                $scope.publish("SendExecuteJob", job);
            }
            
            $ctrl.errorsExpanded = function (job) {
                var id = '#collapseErrors' + job.id;
                var element = angular.element(document).find(id);
                return element.hasClass('in');
            }
    
            $ctrl.logsExpanded = function (job) {
                var id = '#collapseLogs' + job.id;
                var element = angular.element(document).find(id);
                return element.hasClass('in');
            }
            // events //
            $scope.subscribe("ConnectorUpdated", async function (connector: Connector) {
                const msg = await $translate('ADMIN_MESSAGE_CONNECTOR_UPDATED')
                toastr.info(msg)
            })
            $scope.subscribe("JobCreated", async function (job: Job) {
                loaddatas();
                const msg = await $translate('ADMIN_DATA_JOB_ADDED')
                toastr.info(msg + ": " + job.name);
            })
            $scope.subscribe("JobUpdated", async function (job: Job) {
                loaddatas()
                const msg = await $translate('ADMIN_DATA_JOB_UPDATED')
                toastr.info(msg + ": " + job.name)
            })
            $scope.subscribe("JobDeleted", async function (job: Job) {
                loaddatas()
                const msg = await $translate('ADMIN_DATA_JOB_DELETED')
                toastr.info(msg + ": " + job.name)
            })
            $scope.subscribe("JobExecuted", async function (job: Job) {
                const msg = await $translate('ADMIN_DATA_JOB_EXECUTED')
                toastr.info(msg + ": " + job.name)
            })
            $scope.subscribe("HeartbeatReceived", (heartbeat: Heartbeat) => {
                if (!$ctrl.connector)
                    return
                
                let connector = $ctrl.connector as Connector
                if (connector.id == heartbeat.id) {
                    connector.heartbeatActive = true
                    connector.lastHeartbeat = formatDateTime(new Date())
                }
            })
        }
    ]
})
