angular
    .module('DigiLean')
    .directive("smartTargetCell", ['$filter', '$translate','navigationService',
        function ($filter, $translate, navigationService) {
            return {
                templateUrl: 'smartTargetCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'type': '=',
                    'editable': '=',
                    'changeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.value = 0;
                    /*scope.subscribe("UserAuthenticatedAndReady", function(profile)
                    {
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");   
                    });*/
                    scope.$watch("model", function (model) {
                        var value = null;
                        if (scope.type == 'project') {
                            value = model.projectAttribute.value;              
                            
                        } else if (scope.type == 'a3') {
                            if(model.a3TableCell) {
                                value = model.a3TableCell.value;             
                            }
                        }
                        if (value) {
                            scope.value = value.value;
                        }
                        model.getSortValue = function () {
                            return scope.value;
                        }
                    });

                
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (scope.type == 'project' && attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                scope.value = attribute.value.value;
                            }
                        }
                    });
                    scope.subscribe("A3TableCellUpdated", function (tablecell) {
                        if (scope.type == 'a3' && tablecell.a3Id == scope.model.a3.id && tablecell.a3TableColumnId == scope.model.column.id) {
                            if (tablecell.value) {
                                scope.value = tablecell.value.value;
                            }
                        }
                    });

                    scope.setStatusValue = function (newValue) {
                        scope.value = newValue;
                        if (scope.type == 'project') {
                            scope.model.projectAttribute.value = {
                                value: newValue
                            };
                            scope.changeHandler({projectAttribute: scope.model.projectAttribute})
                        } else if (scope.type == 'a3') {
                            if(scope.model.a3TableCell){
                                scope.model.a3TableCell.value = {
                                    value: newValue
                                };
                            }else {
                               var a3TableCell =  {
                                   id: 0,
                                   a3TableColumnId: scope.model.column.id,
                                   a3Id: scope.model.a3.id,
                                   value: {
                                    value: newValue
                                }
                                };
                                scope.model.a3TableCell =  a3TableCell; 
                            }
                            scope.changeHandler({a3TableCell: scope.model.a3TableCell})
                        }

                    }

                    /*
                    function addDeviation(statusValue) {
                        var severity = DigiLean.hms.getDeviationSeverity(statusValue);
                        // Make sure task responsbile dropdown is closed if open.
                        var settings = {
                            projectId: scope.projectId,
                            severity: severity
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    }*/
                }
            }
        }]);
