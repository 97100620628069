import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("memberBoardSelector", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'memberBoardSelector.html',
                restrict: 'E',
                scope: {
                    'boardSelected': '&',
                    'includePersonalBoard': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.selectedBoard = null;

                    boardService.getMemberBoards().then(function (data) {
                        scope.boards = data;
                        if (scope.includePersonalBoard) {
                            var personalBoard = {
                                name: $translate.instant('BOARD_PERSONAL_BOARD'),
                                id: null,
                                boardType: "PERSONAL"
                            }
                            scope.boards.unshift(personalBoard);
                        }
                    });

                    scope.changeBoard = function (board) {
                        scope.selectedBoard = board;
                        // Raise event
                        scope.boardSelected({
                            board: board
                        });
                    }
                }
            }
        }]);
