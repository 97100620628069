import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('tasktoactionlistController', ['$scope', '$filter', '$uibModalInstance', 'boardTaskService', 'task', 'targetBoardId',
    function ($scope, $filter, $uibModalInstance, boardTaskService, task, targetBoardId) {
        $scope.boards = {};
        $scope.selectedEmployee = {};
        $scope.selectedBoard = {};
        var boardId = targetBoardId;

        $scope.blankuser = { id: null, fullName: "", userName: "", displayName: "" }

        $scope.canEdit = false;
        // Chceck if user is allowed to edit task
        boardTaskService().canEdit(task.id).then(function (canEdit) {
            $scope.canEdit = canEdit;
        });

        $scope.descriptionChanged = function (content) {
            $scope.task.text = content;
        }

        boardService.getUserListForBoard(boardId).then(function (data) {
            angular.forEach(data, function (employee, key) {
                employee.fullName = employee.firstName + " " + employee.lastName;
            });
            $scope.employees = data;

            $scope.employees.unshift($scope.blankuser);
            $scope.selectedEmployee = $filter('filter')(data, { id: task.responsibleUserId }, true)[0];
            if (!$scope.selectedEmployee) {
                $scope.selectedEmployee = $scope.blankuser;
            }
        });

        boardService.getMemberBoards().then(function (data) {
            $scope.boards = data;
            //$scope.selectedBoard = $filter('filter')(data, { id: task.boardId }, true)[0];
            $scope.selectedBoard = $filter('filter')(data, { id: boardId }, true)[0];
        });


        // IF date not set, than suggest a week from now
        if (!task.boardDate) {
            var utcDate = timeService.getUTCDate();
            task.boardDate = utcDate.add(7, 'days').toDate();
        }

        // Make sure boardDate is date object 
        task.boardDate = moment.utc(task.boardDate).toDate();
        // the new action object
        $scope.task = task;

        $scope.delete = function () {
            var taskId = $scope.task.id;
            var repeatParentId = $scope.task.repeatParentId;
            var siblingId = $scope.task.siblingId;

            if (!repeatParentId && !siblingId) {
                boardTaskService().deleteTask(taskId).then(function () {
                    $uibModalInstance.dismiss('cancel');
                });
            }

            if (repeatParentId) {
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'repeatdelete.html',
                    controller: 'repeatdeleteController',
                    windowClass: 'newdeviation-modal-window',
                    resolve: {
                        taskId: function () {
                            return taskId;
                        },
                        repeatParentId: function () {
                            return repeatParentId;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $uibModalInstance.close($scope.task);
                });
                /*
                  $translate(['COMMON_CANCEL', 'BOARD_DELETE_TASKS', 'BOARD_DELETE_REPEATING_TASK', 'BOARD_DELETE_REPEATING_TASK_DESCRIPTION']).then(function (translations) {
                      var modalOptions = {
                          closeButtonText: translations.COMMON_CANCEL,
                          actionButtonText: translations.BOARD_DELETE_TASKS,
                          headerText: translations.BOARD_DELETE_REPEATING_TASK,
                          bodyText: translations.BOARD_DELETE_REPEATING_TASK_DESCRIPTION
                      };
    
                      modalService.showModal({}, modalOptions).then(function (result) {
                          boardTaskService().deleteRepeatingTask(repeatParentId).then(function (result) {
                              $scope.publish("SendTasksDeleted", result);
                              $uibModalInstance.dismiss('cancel');
                          });
                      });
                  });*/
            }

            if (siblingId) {

                $translate(['COMMON_CANCEL', 'BOARD_DELETE_TASKS', 'BOARD_DELETE_CLONED_TASK', 'BOARD_DELETE_CLONED_TASK_DESCRIPTION']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.BOARD_DELETE_TASKS,
                        headerText: translations.BOARD_DELETE_CLONED_TASK,
                        bodyText: translations.BOARD_DELETE_CLONED_TASK_DESCRIPTION
                    };

                    modalService.showModal({}, modalOptions).then(function (result) {
                        boardTaskService().deleteClonedTask(siblingId).then(function (result) {
                            $scope.publish("SendTasksDeleted", result);
                            $uibModalInstance.dismiss('cancel');
                        });
                    });
                });
            }
        }

        $scope.ok = function () {
            // Create action and set as result
            $scope.task.responsibleUserId = $scope.selectedEmployee.id;
            $scope.task.responsibleUser = $scope.selectedEmployee.userName;
            $scope.task.responsibleDisplayName = $scope.selectedEmployee.displayName;
            $scope.task.boardId = $scope.selectedBoard.id;
            $scope.isProcessing = true;

            boardTaskService().moveTaskToActionItem($scope.task).then(function (result) {
                $scope.isProcessing = false;
                $scope.publish('SendTaskMovedToActionList', task);
                if ($scope.task.boardId !== $scope.selectedBoard.id) {
                    var change = {
                        taskId: $scope.task.id,
                        fromBoardId: $scope.task.boardId,
                        toBoardId: $scope.selectedBoard.id
                    };
                    $scope.publish("SendTaskChangedBoard", change);
                }

                $uibModalInstance.close(result);
            });
            // check if task should be moved to another board action list



        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.taskBoardDateChange = function($event) {
            const date = $event.originalEvent.detail
            $scope.task.boardDate = date
        }
    }])