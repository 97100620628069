﻿var DigiLean = angular.module('DigiLean'); DigiLean.controller('deviationProjectStatusController', ['$scope', '$translate', 'authService',
    function ($scope, $translate, authService) {



        var deviationGroupElements = [
            {
                label: $translate.instant('PROJECT_PROJECT'),
                sourceColumn: "projectId",
                type: "project"
            },
            {
                label: $translate.instant('COMMON_ASSET'),
                sourceColumn: "assetId",
                type: "asset"
            },
            {
                label: $translate.instant('COMMON_AREA'),
                sourceColumn: "areaId",
                type: "area"
            },
            {
                label: $translate.instant('COMMON_CATEGORY'),
                sourceColumn: "problemCategoryId",
                type: "problemcategory"
            },
            {
                label: $translate.instant('DEVIATION_SEVERITY'),
                sourceColumn: "severity",
                type: "deviationseverity"
            },
            {
                label: $translate.instant('COMMON_DEVIATION_TYPES'),
                sourceColumn: "deviationTypeId",
                type: "deviationtype"
            }
        ];

        var deviationNew = {
            id: "DeviationNew",
            title: $translate.instant('DEVIATION_NEW_DEVIATIONS'),
            description: $translate.instant('DASHBOARD_DEVIATION_NEW_DESC'),
            valueElement: {
                unit: $translate.instant('COMMON_NUMBER'),
                label: $translate.instant('COMMON_NUMBER')
            },
            objectSource: 'internal',
            ownerUserDisplayName: 'DigiLEAN',
            elements: deviationGroupElements
        };
        $scope.filterDatasource = deviationNew;

        
        $scope.selectedFilters = [];

        $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("DeviationAdmin");
        // if page is refreshed we need to refresh
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("DeviationAdmin");
        });

        $scope.timeframe = "last12mth";
        $scope.options = {
            timePeriod: {
                timeframe: $scope.timeframe,
                timeframes: ['all', 'lastyear', 'last12mth', 'year', '30', 'year', 'month', 'previousmonth', 'previousisoweek', 'isoweek', 'day', 'custom']
            }
        };
        function initSettings() {

            $scope.settingProjectStatus =
                {
                    groupColumn: "projectId",
                    stackingColumn: "status",
                    filters: $scope.selectedFilters
                }

            $scope.settingProjectType =
                {
                    groupColumn: "projectId",
                    stackingColumn: "deviationTypeId",
                    filters: $scope.selectedFilters
                }


            $scope.settingProjectSeverity =
                {
                    groupColumn: "projectId",
                    stackingColumn: "severity",
                    filters: $scope.selectedFilters
                }

            $scope.settingProjectCategory =
                {
                    groupColumn: "projectId",
                    stackingColumn: "problemCategoryId",
                    filters: $scope.selectedFilters
                }

            $scope.settingCreatedBy =
                {
                    groupColumn: "createdByUserId",
                    stackingColumn: "projectId",
                    filters: $scope.selectedFilters
                }
        }
        initSettings();

        $scope.periodChangedHandler = function (timePeriod) {
            $scope.options.timePeriod = timePeriod;
            $scope.timeframe = timePeriod.timeframe;
        };

        $scope.filterChangedHandler = function (filters) {
            $scope.selectedFilters = filters;
            initSettings();
        };

        
    }]);