angular
    .module('DigiLean')
    .directive("datasourceTarget", ['dataSourceService', '$uibModal','$translate',
        function (dataSourceService, $uibModal, $translate) {
            return {
                templateUrl: 'datasourceTarget.html',
                restrict: 'E',
                scope: {
                    'datasource': '=',
                    'settingsChangeHandler': '&',
                    'isAdminMode': '='
                },
                link: function (scope, elem, attrs) {

                    scope.operators = [
                        { dbCode: ">=", html: "&gt;=" },
                        { dbCode: ">", html: "&gt;" },
                        { dbCode: "<=", html: "&lt;=" },
                        { dbCode: "<", html: "&lt;" },
                    ];

                    scope.redoperators = [
                        { dbCode: ">=", html: "&gt;=" },
                        { dbCode: ">", html: "&gt;" },
                        { dbCode: "<=", html: "&lt;=" },
                        { dbCode: "<", html: "&lt;" },
                    ];

                    scope.showRegistration = false;
                    scope.selectedGreenOperator = null;
                    scope.selectedRedOperator = null;

                    scope.greenvalue = null;
                    scope.redvalue = null;
                    scope.yellowDescription = "";

                    scope.notValidExpression = false;
                    scope.notValidDate = false;

                    scope.targets = [];
                    
                    scope.selectedDate = moment().startOf('day').toDate();
                    scope.value = null;
                    scope.selectedItemForUpdate = null;
                    scope.isProcessing = false;

                    function resetTargets(){
                        scope.targets = [];
                        scope.datasource.targets = null;
                        scope.datasource.targetDataSource = null;
                        scope.datasource.targetFilters = null;
                        scope.datasource.targetGreenOperator = null;
                    }

                    scope.$watch('datasource', function (datasource) {
                        if (datasource) {
                            scope.setDataSource(datasource);
                            setTargetSettings(datasource);
                        }
                    });

                    function setTargetSettings(dataSource) {
                        scope.targetsource = "targetdatasource"; //default value
                        if (dataSource.targets && dataSource.targets.length > 0) { //manual targets are defined
                            scope.targetsource = "manual";
                            setInternalTargets(dataSource);
                        } else if (dataSource.targetDataSource){ //another datasource as target is defined
                            scope.targetsource = "targetdatasource";
                            if(dataSource.targetGreenOperator){
                                scope.greenOperator = scope.operators.find(o => o.dbCode == dataSource.targetGreenOperator)
                            }
                        } else {
                            if(dataSource.objectSource =='internal'){ //internal datasource must have manual targets
                                scope.targetsource = "manual";
                            } else {
                                scope.targetsource = "datasource"; //if no targets are defined use the targets defined for this datasource
                                getTargets(dataSource.id);
                            }
                        }
                    
                    }

                    scope.selectDataSource = function () {
                        var hasDataSerie = false;
                        if (scope.dataSource.targetDataSource) {
                            hasDataSerie = true;
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return scope.dataSource.targetDataSource;
                                },
                                filters: function () {
                                    return scope.dataSource.targetFilters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return true;
                                }
                            }
                        });
            
                        modalInstance.result.then(function (result) {
                            scope.dataSource.targetDataSource = result.dataSource;
                            scope.dataSource.targetFilters = result.filters;
                        });
                    };

                    scope.setCurrentDatasourceTarget = function() {
                        scope.targetsource = "datasource";
                        resetTargets();
                        getTargets(scope.dataSource.id);
                    }

                    scope.setManual = function() {
                        scope.targetsource = "manual";
                        scope.targets = [];
                        scope.notValidExpression = false;
                        scope.notValidDate = false;
                    }

                    scope.setOtherDatasourceAsTarget = function() {
                        scope.targetsource = "targetdatasource";
                        if(scope.dataSource.targetDataSource == null || scope.dataSource.targetDataSource == undefined){
                            resetTargets();                        
                            scope.greenOperatorChanged(scope.operators[0]);
                            scope.selectDataSource();
                        }    
                    }

                    scope.greenOperatorChanged = function (operator){
                        scope.greenOperator = operator;
                        scope.dataSource.targetGreenOperator = operator.dbCode;
                    };

                    function setInternalTargets(dataSource){
                        processTargets(dataSource.targets);
                    }
                    scope.registration = function () {
                        if(scope.dataSource.unitOfTime == null || scope.dataSource.unitOfTime == undefined) return;
                        scope.showRegistration = true;
                    }

                    scope.setDataSource = function (dataSource) {                     
                        scope.dataSource = dataSource;
                        var componentSettings = {
                            dataSource: dataSource
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                        // Add value property for element
                        angular.forEach(scope.dataSource.elements, function (element) {
                            element.value = null;
                        });
                    }

                    function getTargets(dataSourceId) {
                        dataSourceService().getTargets(dataSourceId).then(function (targets) {
                            processTargets(targets);
                        });
                    }

                    function processTargets(targets){
                        if(targets){
                            angular.forEach(targets, function (registration) {
                                if (registration.greenOperator && registration.greenValue) {
                                    registration.yellowDescription = getYellowOperator(registration.greenOperator) + " " + registration.greenValue;
                                    if (registration.redOperator && registration.redvalue) {
                                        registration.yellowDescription += " and ";
                                    }
                                }
                                if (registration.redOperator && registration.redValue) {
                                    registration.yellowDescription += getYellowOperator(registration.redOperator) + " " + registration.redValue;
                                }
                                var validFromDate = moment(registration.validFrom);
                                if (validFromDate.isSame(scope.selectedDate)) {
                                    scope.notValidDate = true;
                                }
                            });
                            scope.targets = targets;
                        }
                    }

                    scope.toDate = moment().toDate();

                    scope.selectedDateChangedEvent = function ($event) {
                        if ($event && $event.originalEvent) {
                            const date = $event.originalEvent.detail
                            selectedDateChanged(date)
                        }                       
                    }

                    const selectedDateChanged = function (date) {
                        scope.selectedDate = date
                        scope.notValidDate = false
                        angular.forEach(scope.targets, function (target) {
                            var validFromDate = moment(target.validFrom)
                            if (validFromDate.isSame(scope.selectedDate)) {
                                scope.notValidDate = true
                            }
                        })
                    }

                    scope.greenChanged = function (selectedGreenOperator) {
                        scope.selectedGreenOperator = selectedGreenOperator;
                        if (scope.selectedGreenOperator) {
                            setAvailableRedOperator(scope.selectedGreenOperator.dbCode);
                        }
                        setYellowText();
                        validateExpression();
                    }

                    scope.greenValueChanged = function (value) {
                        scope.greenvalue = value;
                        setYellowText();
                        validateExpression();
                    }

                    function validateExpression() {
                        if (scope.selectedGreenOperator && scope.greenvalue &&
                            scope.selectedRedOperator && scope.redvalue) {
                            scope.greenvalue = scope.greenvalue.toString().replace(',', '.');
                            scope.redvalue = scope.redvalue.toString().replace(',', '.');
                            if (scope.selectedGreenOperator.dbCode === ">=" || scope.selectedGreenOperator.dbCode === ">") {
                                if (Number(scope.greenvalue) < Number(scope.redvalue)) {
                                    scope.notValidExpression = true;
                                    return;
                                }
                            } else {
                                if (Number(scope.greenvalue) > Number(scope.redvalue)) {
                                    scope.notValidExpression = true;
                                    return;
                                }
                            }
                        }
                        scope.notValidExpression = false;
                    }

                    function setYellowText() {
                        if (scope.selectedGreenOperator && scope.greenvalue) {
                            scope.yellowDescription = getYellowOperator(scope.selectedGreenOperator.dbCode) + " " + scope.greenvalue;
                            if (scope.selectedRedOperator && scope.redvalue) {
                                scope.yellowDescription += " and ";
                            }
                        }
                        if (scope.selectedRedOperator && scope.redvalue) {
                            scope.yellowDescription += getYellowOperator(scope.selectedRedOperator.dbCode) + " " + scope.redvalue;
                        }
                    }

                    scope.redChanged = function (operator) {
                        scope.selectedRedOperator = operator;
                        setYellowText();
                        validateExpression();
                    }
                    scope.redValueChanged = function (value) {
                        scope.redvalue = value;
                        setYellowText();
                        validateExpression();
                    }

                    function setAvailableRedOperator(greenoperator) {
                        var newRedOperators;
                        switch (greenoperator) {
                            case ">=":
                                newRedOperators = [
                                    { dbCode: "<=", html: "&lt;=" },
                                    { dbCode: "<", html: "&lt;" }
                                ];
                                break;
                            case ">":
                                newRedOperators = [
                                    { dbCode: "<=", html: "&lt;=" },
                                    { dbCode: "<", html: "&lt;" }
                                ];
                                break;
                            case "<=":
                                newRedOperators = [
                                    { dbCode: ">=", html: "&gt;=" },
                                    { dbCode: ">", html: "&gt;" }
                                ];
                                break;
                            case "<":
                                newRedOperators = [
                                    { dbCode: ">=", html: "&gt;=" },
                                    { dbCode: ">", html: "&gt;" }
                                ];
                                break;
                        }
                        if (!(scope.redoperators && scope.redoperators[0].dbCode === newRedOperators[0].dbCode && scope.redoperators.length === newRedOperators.length)) {
                            scope.redoperators = newRedOperators;
                        }
                    }

                    function getYellowOperator(operator) {
                        switch (operator) {
                            case ">=":
                                return "<";
                            case ">":
                                return "<=";
                            case "<=":
                                return ">";
                            case "<":
                                return ">=";
                        }
                    }

                    scope.setUnitOfTime = function (unit) {
                        scope.datasource.unitOfTime = unit;           
                    }

                    scope.save = function () {
                        scope.isProcessing = true;
                        var utcDate = moment.utc(scope.selectedDate).format();
                        var redOperator = null;
                        if (scope.selectedRedOperator) {
                            redOperator = scope.selectedRedOperator.dbCode;
                        }

                        if(scope.targetsource == "manual"){
                            scope.targets.push({dataSourceId:scope.dataSource.id,
                                                greenOperator: scope.selectedGreenOperator.dbCode,
                                                greenValue: scope.greenvalue,
                                                redOperator: redOperator,
                                                redValue: scope.redvalue,
                                                validFrom: utcDate
                                            });
                            scope.datasource.targets = scope.targets; 
                            $translate('COMMON_CREATED').then(function (msg) {
                                toastr.success(msg);
                            });
                            processTargets(scope.targets);
                            clearRegistration();       
                        } else {
                            var registration = {
                                datasourceid: scope.dataSource.id,
                                validFrom: utcDate,
                                greenvalue: scope.greenvalue,
                                greenoperator: scope.selectedGreenOperator.dbCode,
                                redvalue: scope.redvalue,
                                redoperator: redOperator,
                            }
                        
                            dataSourceService().addTarget(scope.dataSource.id, registration).then(function (savedRegistration) {
                                $translate('COMMON_CREATED').then(function (msg) {
                                    toastr.success(msg);
                                });
                                clearRegistration();
                                getTargets(scope.dataSource.id);
                            });
                        }
                    }

                    scope.delete = function (target) {
                        if(scope.targetsource == "targetdatasource") return //should not be able to delete targetdatasource targets
                        if(scope.targetsource == "manual"){
                            //Remove target from targets
                            var index = scope.targets.indexOf(target);
                            if (index > -1) {
                                scope.targets.splice(index, 1);
                            }
                        } else {   
                            dataSourceService().deleteTarget(target.id).then(function (result) {
                                //Remove target from targets
                                var index = scope.targets.indexOf(target);
                                if (index > -1) {
                                    scope.targets.splice(index, 1);
                                }
                            });
                        }
                    }


                    function clearRegistration() {
                        scope.isProcessing = false;
                        selectedDateChanged(moment().startOf('day').toDate());

                        /*scope.selectedGreenOperator = null;
                        scope.selectedRedOperator = null;

                        scope.greenvalue = null;
                        scope.redvalue = null;
                        scope.yellowDescription = "";*/
                        scope.showRegistration = false;
                        scope.notValidExpression = false;
                    }

                }

            }
        }]);