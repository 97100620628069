var DigiLean = angular.module('DigiLean');
DigiLean.controller('improvementAdminController', ['$scope', '$filter', '$stateParams', '$location', '$timeout', '$translate', 'authService', 'suggestionService', 'modalService',
    function ($scope, $filter, $stateParams, $location, $timeout, $translate, authService, suggestionService, modalService) {
        $scope.savedSuccessfully = false;
        $scope.message = "";

        $translate('DEVIATION_NEW_CATEGORY').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });


        $scope.clearActive = function () {
            $translate('DEVIATION_NEW_CATEGORY').then(function (title) {
                $scope.actionTitle = title;
            }, function (translationId) {
                $scope.headline = translationId;
            });
            $scope.registration = {
                isNew: true,
                id: 0,
                name: "",
                description: ""
            };
        }
        $scope.clearActive();

        $scope.delete = function (item) {
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CATEGORY_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + item.name + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_CATEGORY_CONFIRMATION
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    suggestionService().deleteCategory(item.id).then(function (data) {
                        load();
                        $scope.clearActive();
                        $translate('ADMIN_MESSAGE_CATEGORY_DELETED').then(function (msg) {
                            toastr.info(msg);
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    }, function (err) {
                    });
                });
            });
        }

        $scope.categories = [];
        var load = function () {
            suggestionService().getCategories().then(function (categories) {
                $scope.categories = categories;
            });
        }
        load();
        $scope.edit = function (item) {
            $translate('COMMON_EDIT').then(function (title) {
                $scope.actionTitle = title;
            }, function (translationId) {
                $scope.headline = translationId;
            });
            $scope.registration = {
                isNew: false,
                id: item.id,
                name: item.name,
                description: item.description
            };
        }

        $scope.save = function () {
            if ($scope.registration.isNew) {
                suggestionService().createCategory($scope.registration).then(function () {
                    $translate('ADMIN_MESSAGE_CATEGORY_ADDED').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    load();
                    $scope.clearActive();
                });
            } else {
                suggestionService().updateCategory($scope.registration).then(function () {
                    $translate('ADMIN_MESSAGE_CATEGORY_MODIFIED').then(function (msg) {
                        toastr.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    load();
                });
            }
        }

    }]);