import { BlobInfo, FileInfo } from "@api"
import backend from "@common/services/backendHttp"

export const uploadImageToBlobStorageNoPreview = async (file: Blob) => {
    const url = "api/document/PostImageBlobUploadNoPreview"
    const res = await backend.postFile<FileInfo>(url, file) as Promise<FileInfo>
    return res
}

export const uploadFileBlobStorage = async (file: Blob) => {
    if (!file)
        return
    const url = "api/document/PostBlobUpload"
    // backend method supports multiple files thats why it returns array
    const res = await backend.postFile<FileInfo[]>(url, file) as Promise<FileInfo[]>
    return res
}

export const getFileUrl = async (blobName: string, fileInfoId: string) => {
    const blobInfo: BlobInfo = {
        blobName,
        fileInfoId
    }
    const url = "api/document/GetFileUrl"
    const res = await backend.post<string>(url, blobInfo)
    return res
}