import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("licensesChangedMessage", 
        function () {
            return {
                templateUrl: 'licensesChangedMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.actionResults = [];
                    scope.isLoading = true;

                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        scope.actionResults = [];
                        customerService.getLicenseChange(payLoad.Id).then(function (info) {
                            scope.isLoading = false;
                            scope.actionResults.push(info);
                        });
                    });
                }
            }
        });