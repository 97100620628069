var DigiLean = angular.module('DigiLean');
DigiLean.directive("projectOnTime", ['mathService','$translate',
    function (mathService, $translate) {
        return {
            templateUrl: 'projectOnTime.html',
            restrict: 'E',
            scope: {
                'project': '='
            },
            link: function (scope, elem, attrs) {
                var projectOnTimeChart = null;

                var noOfMilestoneMet = 0;
                var noOfMilestoneDelayed = 0;
                var noOfMilestoneRemaining = 0;

                var percentOnTime = null;
                var percentMet = null;
                var percentDelayed = null;
                var percentRemaining = null;
                scope.decimals = 1;
                var projectId = null;

                scope.$watch('project', function () {
                    if (scope.project) {
                        projectId = scope.project.id;
                        calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                    }
                });
                function isValidDate(date) {
                    var result = moment(date);
                    return result._isValid;
                };

                function reset(){
                    
                    noOfMilestoneMet = 0;
                    noOfMilestoneDelayed = 0;
                    noOfMilestoneRemaining = 0;

                    percentOnTime = null;
                    percentMet = null;
                    percentDelayed = null;
                    percentRemaining = null;
                }
                scope.subscribe("ProjectStartDateUpdated", function (project) {
                    if (project.id == projectId) {
                        reset();
                        calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                    }
                });
                scope.subscribe("ProjectEstimatedStartDateUpdated", function (project) {
                    if (project.id == projectId) {
                        reset();
                        calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                    }
                });

                scope.subscribe("ProjectEndDateUpdated", function (project) {
                    if (project.id == projectId) {
                        reset();
                        calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                    }
                });
                scope.subscribe("ProjectEstimatedEndDateUpdated", function (project) {
                    if (project.id == projectId) {
                        reset();
                        calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                    }
                });
                scope.subscribe("ProjectMilestoneUpdated", function (attribute) {
                    if (attribute.projectId == projectId) {
                        reset();
                        calculateMilestones(scope.project);
                        if(percentOnTime != null){
                            drawChart();
                        }
                    }
                });
                function calculateMilestones(project){
                    
                    if (project.milestones && project.milestones.length > 0) {
                       
                        for (var i = 0; i < project.milestones.length; i++) {
                            if (isValidDate(project.milestones[i].actualDate)) {
                                if (isValidDate(project.milestones[i].plannedDate)) {
                                    var duration = moment.duration(moment(project.milestones[i].plannedDate).diff(moment(project.milestones[i].actualDate), 'days'));
                                    if(duration<0){
                                        noOfMilestoneDelayed = noOfMilestoneDelayed + 1;
                                    } else {
                                        noOfMilestoneMet = noOfMilestoneMet + 1;
                                    }
                                }
                            } else if (isValidDate(project.milestones[i].plannedDate)) {
                                //check if overdue/missed
                                var duration = moment.duration(moment().diff(moment(project.milestones[i].plannedDate)));
                                var days = duration.asDays().toFixed(0);  
                                if (days >= 1) {
                                    noOfMilestoneDelayed = noOfMilestoneDelayed + 1; 
                                } else {
                                    noOfMilestoneRemaining = noOfMilestoneRemaining + 1;
                                }
                            }
                        }
                    }
                    checkOnTime(project.estimatedStartDate, project.startDate);
                    checkOnTime(project.estimatedEndDate, project.endDate);
                    var totalMilestones = noOfMilestoneMet + noOfMilestoneDelayed + noOfMilestoneRemaining;
                    if (totalMilestones>0){
                        
                        percentOnTime = 100 * noOfMilestoneMet / totalMilestones;
                        percentDelayed = 100 * noOfMilestoneDelayed / totalMilestones;
                        percentRemaining = 100 * noOfMilestoneRemaining / totalMilestones;

                        percentOnTime = mathService().roundNumber(percentOnTime, scope.decimals);
                        percentDelayed = mathService().roundNumber(percentDelayed, scope.decimals);
                        percentRemaining = mathService().roundNumber(percentRemaining, scope.decimals);
                    }

                }

                function checkOnTime(estimatedDate, actualDate){
                    if (isValidDate(actualDate)) {
                        if (isValidDate(estimatedDate)) {
                            var duration = moment.duration(moment(estimatedDate).diff(moment(actualDate), 'days'));
                            if(duration<0){
                                noOfMilestoneDelayed = noOfMilestoneDelayed + 1;
                            } else {
                                noOfMilestoneMet = noOfMilestoneMet + 1;
                            }
                        }
                    } else if (isValidDate(estimatedDate)) {
                        //check if overdue/missed
                        var duration = moment.duration(moment().diff(moment(estimatedDate)));
                        var days = duration.asDays().toFixed(0);  
                        if (days >= 1) {
                            noOfMilestoneDelayed = noOfMilestoneDelayed + 1; 
                        } else {
                            noOfMilestoneRemaining = noOfMilestoneRemaining + 1;
                        }
                    }
                
                }

                function drawChart() {
                    var renderElement = $(elem).find(".projectontime")[0];
                    projectOnTimeChart = new Highcharts.Chart({
                        chart: {
                            renderTo: renderElement,
                            plotBackgroundColor: 'var(--digilean-fifthery-background)',//'#fafafa', //null, // '#f3f3f4', 
                            backgroundColor: 'var(--digilean-fifthery-background)',
                            plotBorderWidth: 0,
                            plotShadow: false,
                        },
                        title: {
                            text: $translate.instant('PROJECT_ON_TIME') + '<br>' + percentOnTime +' %',
                            align: 'center',
                            verticalAlign: 'middle',
                            y: 40,
                            style: {
                                color: 'var(--digilean-primary-text)'
                            }
                        },
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                        },
                        credits: {
                            enabled: false //remove link to highcharts.com
                        },
                        exporting: {
                            enabled: false //remove context menu
                        },
                        plotOptions: {
                            pie: {
                                dataLabels: {
                                    enabled: true,
                                    distance: -50,
                                    style: {
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }
                                },
                                startAngle: -90,
                                endAngle: 90,
                                center: ['50%', '75%'],
                                size: '110%',
                                colors: ['#8cc152', '#d9534f', '#434348'] 
                            }
                        },
                        series: [{
                            type: 'pie',
                            name: $translate.instant('PROJECT_MILESTONES'),
                            innerSize: '50%',
                            data: [
                                [$translate.instant('PROJECT_ON_TIME'), percentOnTime], //milestone met  
                                [$translate.instant('DASHBOARD_OVERDUE'), percentDelayed], // 
                                [$translate.instant('PROJECT_REMAINING'), percentRemaining], 
                                
                            ]
                        }]
                    });
                }


                

            }
        }
    }]);