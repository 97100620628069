import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
angular
    .module('DigiLean')
    .controller("newMessageModalController", ['$scope', '$filter', '$uibModalInstance','$translate', 'authService',
        function ($scope, $filter, $uibModalInstance, $translate, authService) {

            $scope.title = "";
            $scope.text = "";
            $scope.errormessage = "";
            $scope.uploadedFiles = [];
            
            $scope.filters = [];
            $scope.updateFilter = function (updatedSourceColumn, filters) {
                var hasFilter = $filter('filter')($scope.filters, { sourceColumn: updatedSourceColumn });
                // Remove existing filter connected to sourceColumn
                if (hasFilter && hasFilter.length > 0) {
                    // Remove existing filter
                    angular.forEach(hasFilter, function (filter, key) {
                        var index = $scope.filters.indexOf(hasFilter[key]);
                        $scope.filters.splice(index, 1);
                    });
                }
                angular.forEach(filters, function (filter) {
                    if (filter) {
                        if($scope.filters === undefined){
                            $scope.filters = [];
                        }
                        $scope.filters.push(filter);
                    }
                });
            };

            
            $scope.titleChanged = function (content) {
                $scope.title = content;
            }

            $scope.textChanged = function (content) {
                $scope.text = content;
            }

            $scope.send = function () {
                $scope.errormessage = "";
                if(!$scope.title){
                    $scope.errormessage = $translate.instant("ADMIN_DATA_FIELD_MISSING") + ": "
                    +  $translate.instant("COMMON_TITLE");
                    return;
                }  
 
                var message = {
                    title: $scope.title,
                    text: $scope.text,
                    files: $scope.uploadedFiles,
                    isPublic: false
                };

                if(!$scope.filters || $scope.filters.length == 0){ //role defines how many assets user can send to
                    if(authService.hasRole("Admin")){
                        message.isPublic = true;
                    } else {
                        message.assets = [];
                        assetService.getList().then(function (assets) {
                            assets.forEach(asset => {
                                var messageAsset = 
                                {
                                    AssetId: asset.id
                                }; 
                                message.assets.push(messageAsset);
                            })  
                            customerService.sendMessage(message).then(function (result) {
                            });
                            $uibModalInstance.close(message);      
                        });
                    }
                } else { // message only to be sent to selected assets
                    var assetIds = $scope.filters[0].items;
                    message.assets = [];
                    for(var i=0; i<assetIds.length; i++){
                        var messageAsset = 
                        {
                            AssetId: assetIds[i]
                        }; 
                        message.assets.push(messageAsset);
                    }
                }
                customerService.sendMessage(message).then(function (result) {
                });
                $uibModalInstance.close(message);          
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
