import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('fullImprovementModalController', ['$scope', '$filter', '$uibModal', '$uibModalInstance', '$translate', 'strategyService', 'navigationService', 'authService', 'activityService', 'suggestionService', 'suggestion', 'activeTab', 'modalService', 'projectService','a3Service','mathService',
    function ($scope, $filter, $uibModal, $uibModalInstance, $translate, strategyService,navigationService, authService, activityService, suggestionService, suggestion, activeTab, modalService, projectService,a3Service, mathService) {
        $scope.assets = [];
        $scope.selectedAsset = {};
        $scope.projects = [];
        //$scope.selectedProject = {};
        $scope.categories = [];
        $scope.selectedCategory = {};
        suggestion.asset = null;
        $scope.suggestion = suggestion;
        if ($scope.suggestion.dueDate) {
            $scope.suggestion.dueDate = moment($scope.suggestion.dueDate).toDate();
        }

        $scope.authentication = authService
        $scope.canEdit = false;
        // Chceck if user is allowed to edit task
        suggestionService().canEdit($scope.suggestion.id).then(function (canEdit) {
            $scope.canEdit = canEdit;
        });
        
        $scope.statuses = [];
        $scope.selectedStatus = {};
        var statusChanged = false;
        $scope.priorityStatusText = ""; // gui translated status text
        var oldPriority = $scope.suggestion.priorityStatus;
        var newPriority = $scope.suggestion.priorityStatus;
        $scope.selectedPriority = {};

        $scope.activityLog = [];

        var confirmEvaluationStatusDialog = false;
        var changedDueDate = false;
        var newDueDate = null;
        var oldDueDate = suggestion.dueDate;
        var changedROIInfo = false;

        $scope.selectedResponsible = {};
        var oldResponsible = suggestion.reponsibleDisplayName;
        var oldResponsibleUserId = suggestion.responsibleUserId;
        var newResponsible = oldResponsible;
        var newResponsibleUserId = oldResponsibleUserId;

        var userid = authService.getCurrentUser().userId;
        updateUserLikeStatus($scope.suggestion);

        // Default check of modules. Will work unless page is refreshed 
        $scope.hasBoard = navigationService().hasModule("BOARD");
        $scope.hasProject = navigationService().hasModule("PROJECT");
        $scope.hasA3 = navigationService().hasModule("A3");
        $scope.hasStrategy = navigationService().hasModule("STRATEGY");
        $scope.goals = [];
        $scope.modifyGoal = false;
        $scope.relatedGoals = false;
        var changedGoal = false;
        loadGoals();
        
        // Changed handler for text => HtmlTextArea
        $scope.descriptionChanged = function(content) {
            $scope.suggestion.text = content;
        }

        // if page is refreshed we need to refresh the access to modules
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasBoard = navigationService().hasModule("BOARD");
            $scope.hasProject = navigationService().hasModule("PROJECT");
            $scope.hasA3 = navigationService().hasModule("A3");
            loadProjects();
            $scope.hasStrategy = navigationService().hasModule("STRATEGY");
            loadGoals();
            userid = profile.user.id;
            updateUserLikeStatus($scope.suggestion);
        });

 
        function loadGoals(){
            if($scope.hasStrategy){
                if($scope.suggestion.asset && $scope.suggestion.asset.id){
                    loadTeamGoals($scope.suggestion.asset.id);
                } else if($scope.suggestion.assetId){
                    loadTeamGoals($scope.suggestion.assetId);
                } else {
                    loadBreakthroughGoals();  
                }           
            }
        }
        function loadTeamGoals(assetId){
            strategyService().getAllTeamGoals(assetId).then(function(goals){                      
                if(goals && goals.length > 0){
                    $scope.goals = goals;
                    var relatedGoals = $scope.suggestion.goals;
                    if(relatedGoals && relatedGoals.length>0){
                        for(var i=0; i<$scope.goals.length; i++){
                            var matching = $filter('filter')(relatedGoals, { strategyGoalId: $scope.goals[i].id}, true);
                            if (matching.length > 0) {
                                $scope.goals[i].selected = true;
                                $scope.relatedGoals = true;
                                for(var c=0; c<$scope.goals[i].childGoals.length; c++){
                                    var matchingChild = $filter('filter')(relatedGoals, { strategyGoalId: $scope.goals[i].childGoals[c].id}, true);
                                    if (matchingChild.length > 0) {
                                        $scope.goals[i].childGoals[c].selected = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    $scope.goals = [];
                    loadBreakthroughGoals();
                }
            });
        }

        function loadBreakthroughGoals(){
            strategyService().getBreakthroughObjectives().then(function (goals) {
                if(goals && goals.length > 0){
                    $scope.goals = goals;
                    var relatedGoals = $scope.suggestion.goals;
                    if(relatedGoals && relatedGoals.length>0){
                        for(var i=0; i<$scope.goals.length; i++){
                            var matching = $filter('filter')(relatedGoals, { strategyGoalId: $scope.goals[i].id}, true);
                            if (matching.length > 0) {
                                $scope.goals[i].selected = true;
                                $scope.relatedGoals = true;
                            }
                        }
                    }
                }
            });
        }

        function updateUserLikeStatus(suggestion) {
            //
            var existingLike = $filter('filter')(suggestion.likes, { likedByUserId: userid }, true);
            if (existingLike.length > 0) {
                suggestion.isLiked = true;
            } else {
                suggestion.isLiked = false;
            }
        }

        $scope.likeSuggestion = function (item) {
            item.isLiked = !item.isLiked;
            if (item.isLiked) {
                suggestionService().addLike(item.id).then(function (like) {
                    // Like event will increase counter
                });
            } else {
                suggestionService().removeLike(item.id).then(function () {
                });
            }
        }

        $scope.toggleGoal = function(goal) {
            if($scope.modifyGoal){
                changedGoal = true;
                if($scope.suggestion.goals){
                    var matching = $filter('filter')($scope.suggestion.goals, { strategyGoalId: goal.id }, true);
                    if (matching.length > 0) {
                        var index =$scope.suggestion.goals.indexOf(matching[0]);
                        if (index > -1) {
                            $scope.suggestion.goals.splice(index, 1);
                            unselectGoal(goal);
                        }
                    }else {
                        $scope.relatedGoals = true;
                        var improvementStrategyGoal = {
                            id: 0,
                            improvementSuggestionId: $scope.suggestion.id,
                            strategyGoal: goal,
                            strategyGoalId: goal.id
                        }
                        $scope.suggestion.goals.unshift(improvementStrategyGoal);  
                        var breakthrougGoalList = $filter('filter')($scope.goals, { id: goal.id }, true);
                        if(breakthrougGoalList.length > 0){
                            breakthrougGoalList[0].selected = true;
                        }
                    }
                } else {
                    var improvementStrategyGoal = {
                        id: 0,
                        improvementSuggestionId: $scope.suggestion.id,
                        strategyGoal: goal,
                        strategyGoalId: goal.id
                    }
                    $scope.suggestion.goals = [improvementStrategyGoal];
                    var breakthrougGoalList = $filter('filter')($scope.goals, { id: goal.id }, true);
                    if(breakthrougGoalList.length > 0){
                        breakthrougGoalList[0].selected = true;
                    }
                }
            }
        };
        $scope.toggleSubGoal = function(goal) {
            changedGoal = true;
            if($scope.suggestion.goals){
                var matching = $filter('filter')($scope.suggestion.goals, { strategyGoalId: goal.id }, true);
                if (matching.length > 0) {
                    var index =$scope.suggestion.goals.indexOf(matching[0]);
                    if (index > -1) {
                        $scope.suggestion.goals.splice(index, 1);
                    }
                } else {
                    $scope.relatedGoals = true;
                    var improvementStrategyGoal = {
                        id: 0,
                        improvementSuggestionId: $scope.suggestion.id,
                        strategyGoal: goal,
                        strategyGoalId: goal.id
                    }
                    $scope.suggestion.goals.unshift(improvementStrategyGoal);  
                    addMissingBreakthroughGoal(goal);
                }
            } else {
                var breakthrougGoalList = $filter('filter')($scope.goals, { id: goal.breakthroughgoalid }, true);
                if(breakthrougGoalList.length > 0){
                    var breakthrougGoal = {
                        id: 0,
                        improvementSuggestionId: $scope.suggestion.id,
                        strategyGoal: breakthrougGoalList[0],
                        strategyGoalId: breakthrougGoalList[0].id
                    };
                    breakthrougGoalList[0].selected = true;
                    var improvementStrategyGoal = {
                        id: 0,
                        improvementSuggestionId: $scope.suggestion.id,
                        strategyGoal: goal,
                        strategyGoalId: goal.id
                    }
                    $scope.suggestion.goals = [breakthrougGoal,improvementStrategyGoal];
                    $scope.relatedGoals = true;
                }
            }
        };
        function unselectGoal(goal){
            var breakthrougGoalList = $filter('filter')($scope.goals, { id: goal.id }, true);
            if(breakthrougGoalList.length > 0){
                breakthrougGoalList[0].selected = false;
                if(breakthrougGoalList[0].childGoals){
                    for(var i=0; i<breakthrougGoalList[0].childGoals.lenght; i++){
                        breakthrougGoalList[0].childGoals[i].selected = false;
                        var matchingSubgoalslist = $filter('filter')($scope.suggestion.goals, { strategyGoalId: breakthrougGoalList[0].childGoals[i].id }, true);
                        if (matchingSubgoalslist.length > 0) {
                            var index =$scope.suggestion.goals.indexOf(matchingSubgoalslist[0]);
                            if (index > -1) {
                                $scope.suggestion.goals.splice(index, 1);
                            }
                        }
                    }
                }
            }
        }
        function addMissingBreakthroughGoal(subgoal){
            var exists = $filter('filter')($scope.suggestion.goals, { strategyGoalId: subgoal.breakThroughGoalId }, true);
            if(exists.length == 0){
                var breakthrougGoalList = $filter('filter')($scope.goals, { id: subgoal.breakThroughGoalId }, true);
                if (breakthrougGoalList.length > 0){
                    breakthrougGoalList[0].selected = true;
                    var breakthrougGoal = {
                        id: 0,
                        improvementSuggestionId: $scope.suggestion.id,
                        strategyGoal: breakthrougGoalList[0],
                        strategyGoalId: breakthrougGoalList[0].id
                    };
                    $scope.suggestion.goals.unshift(breakthrougGoal);
                }
            }
        }

        
        function updateGoals(){
            if(changedGoal){
                // remove goals not related to asset
                if($scope.suggestion.goals && $scope.suggestion.assetId){
                    var validGoalsForAsset = []
                    for(var i=0; i<$scope.suggestion.goals.length; i++){
                        var goalList = $filter('filter')($scope.goals, { id: $scope.suggestion.goals[i].strategyGoalId }, true);
                        if (goalList.length > 0){ 
                            validGoalsForAsset.unshift($scope.suggestion.goals[i]);
                            if(goalList[0].childGoals){
                                for(var c=0; c<goalList[0].childGoals.length; c++){
                                    var subgoalList = $filter('filter')($scope.suggestion.goals, { strategyGoalId: goalList[0].childGoals[c].id }, true);
                                    if (subgoalList.length > 0){ 
                                        validGoalsForAsset.unshift(subgoalList[0]);
                                        
                                    }
                                }
                            }
                        }
                    }
                    $scope.suggestion.goals = validGoalsForAsset;
                }
                suggestionService().updateImprovementStrategyGoal($scope.suggestion).then(function (data) {
                });
            }
        }
        
        // 
        var tabs = ["Info", "Attachments", "ROI", "Time", "Evaluation", "Action", "Comments", "History"];
        $scope.indexTab = tabs.indexOf(activeTab);
        $scope.selectTab = function (tab) {
            $scope.indexTab = tabs.indexOf(tab);
            if (tab === "History") {
                getActivityLog();
            } else if (tab === "Comments") {
                sortCommentOrder();
            }
        };

        $scope.subscribe("ImprovementLikeAdded", function (like) {
            if ($scope.suggestion.id === like.improvementSuggestionId) {
                var likeExists = $filter('filter')($scope.suggestion.likes, { id: like.id }, true);
                if (likeExists.length === 0) {
                    $scope.suggestion.likes.push(like);
                    updateUserLikeStatus($scope.suggestion);
                }
            }
        });

        
        $scope.subscribe("ImprovementLikeRemoved", function (like) {
            if ($scope.suggestion.id === like.improvementSuggestionId) {
                var existingLike = $filter('filter')($scope.suggestion.likes, { id: like.id }, true);
                if (existingLike.length == 1) {
                    var index = $scope.suggestion.likes.indexOf(existingLike[0]);
                    $scope.suggestion.likes.splice(index, 1);
                }

            }
        })

        $scope.currency = customerService.getDefaultCurrency(); 
        getCompanySetting("currency").then(function (setting) {
            if (setting) {
                $scope.currency = setting
            }
        });


        // File upload
        $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
        $scope.fileUploaded = function (file) {
            suggestionService().addAttachment($scope.suggestion.id, file).then(function (improvementAttachment) {
                improvementAttachment.cssState = "animated slideInDown";
                setTimeout(function () {
                    improvementAttachment.cssState = "";
                }, 500);
                $scope.suggestion.attachments.push(improvementAttachment);
                // Send event
                $scope.publish("SendSuggestionAttachmentAdded", improvementAttachment);
            });
        };

        function sortCommentOrder() {
            if ($scope.suggestion.comments) {
                $scope.suggestion.comments.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.commentDate) - new Date(a.commentDate);
                });
            }
        }

        
        $scope.toggleModifyGoal = function () {
            $scope.modifyGoal = !$scope.modifyGoal;
        };
        $scope.deleteAttachment = function (attachment) {
            suggestionService().deleteAttachment($scope.suggestion.id, attachment.id).then(function (improvementAttachment) {
                // Send event
                attachment.cssState = "animated slideOutLeft";
                setTimeout(function () {
                    var index = $scope.suggestion.attachments.indexOf(attachment);
                    $scope.suggestion.attachments.splice(index, 1);
                }, 500);
                $scope.publish("SendSuggestionAttachmentDeleted", attachment);
            });
        };

        //Modified propertie flag
        var categoryChanged = false;

        function loadProjects() {
            if ($scope.hasProject) {
                projectService().getOngoingProjects().then(function (projects) {
                    $scope.projects = projects;
                    $scope.projectmodel = { projectId: $scope.suggestion.projectId,
                        projects: $scope.projects}
                });
            }
        }
        loadProjects();

        $scope.projectChangeHandler = function (projectinfo) {
            if (projectinfo) {
                $scope.suggestion.projectId = projectinfo.project.id;
            } else {
                $scope.suggestion.projectId = null;
            }                       
        }

        $scope.assetChangeHandler = function (assetinfo) {
            if (assetinfo) {
                $scope.suggestion.assetId = assetinfo.asset.id;
                $scope.suggestion.asset = assetinfo.asset;
                customerService.getUsersForAsset(assetinfo.asset.id).then(function (data) {
                    data = data.filter(u => u.assetRole !== 'Viewer')
                    angular.forEach(data, function (employee, key) {
                        if (employee.screenName) {
                            employee.fullName = employee.firstName + " " + employee.lastName;
                        } else {
                            employee.screenName = employee.firstName;
                            employee.fullName = employee.firstName + " " + employee.lastName;
                        }
                    });
                    $scope.employees = data;
                    if (suggestion.responsibleUserId) {
                        var responsible = $filter('filter')(data, { userId: suggestion.responsibleUserId }, true);
                        if (responsible.length > 0) {
                            $scope.selectedResponsible = responsible[0];
                        }
                    }
                    
                });
                if($scope.hasStrategy){
                    changedGoal = true;
                    //reset selected goals
                    for(var i=0; i<$scope.goals.length; i++){
                        $scope.goals[i].selected = false;
                    }
                    //remove sub goals from previous asset
                    if($scope.suggestion.goals){
                        var selectedBreakthrougGoals = []
                        for(var g=0; g<$scope.suggestion.goals.length; g++){
                            if($scope.suggestion.goals[g].strategyGoal.level == 0){
                                selectedBreakthrougGoals.unshift($scope.suggestion.goals[g]);
                            }
                        }
                        $scope.suggestion.goals = selectedBreakthrougGoals;
                    }
                    loadTeamGoals(assetinfo.asset.id);
                }
            } else {
                $scope.suggestion.assetId = null;
                $scope.suggestion.asset = null;
            }       



            
        }

        assetService.getAllAssets().then(function (data) {
            $scope.assets = data;
            for (var k = 0; k < $scope.assets.length; k++) {
                $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
            }
            if (suggestion.assetId) {
                var selectedAssets = $filter('filter')(data, { id: suggestion.assetId }, true);
                if (selectedAssets.length > 0) {
                    suggestion.asset = selectedAssets[0];

                    customerService.getUsersForAsset(suggestion.assetId).then(function (data) {
                        data = data.filter(u => u.assetRole !== 'Viewer')
                        angular.forEach(data, function (employee, key) {
                            if (employee.screenName) {
                                employee.fullName = employee.firstName + " " + employee.lastName;
                            } else {
                                employee.screenName = employee.firstName;
                                employee.fullName = employee.firstName + " " + employee.lastName;
                            }
                        });
                        $scope.employees = data;
                        if (suggestion.responsibleUserId) {
                            var responsible = $filter('filter')(data, { userId: suggestion.responsibleUserId }, true);
                            if (responsible.length > 0) {
                                $scope.selectedResponsible = responsible[0];
                            }
                        }
                    });
                }
            }
            $scope.assetmodel = { assetId: $scope.suggestion.assetId,
                assets: $scope.assets};
        });

        suggestionService().getCategories().then(function (categories) {
            $scope.categories = categories;
            if (suggestion.categoryId) {
                var match = $filter('filter')(categories, { id: suggestion.categoryId }, true);
                if (match.length > 0) {
                    $scope.selectedCategory = match[0];
                }
            }
        });

        function translateStatuses() {
            $translate(['COMMON_NEW', 'IMPROVEMENT_PLANNED', 'COMMON_IN_PROGRESS', 'IMPROVEMENT_IMPLEMENTED', 'IMPROVEMENT_EVALUATED', 'IMPROVEMENT_ARCHIVED']).then(function (translations) {
                $scope.statuses = [
                    {
                        statusDisplayText: translations.COMMON_NEW,
                        statusDatabaseText: "Suggested"
                    },
                    {
                        statusDisplayText: translations.IMPROVEMENT_PLANNED,
                        statusDatabaseText: "Planned"
                    },
                    {
                        statusDisplayText: translations.COMMON_IN_PROGRESS,
                        statusDatabaseText: "InProgress"
                    },
                    {
                        statusDisplayText: translations.IMPROVEMENT_IMPLEMENTED,
                        statusDatabaseText: "Implemented"
                    },
                    {
                        statusDisplayText: translations.IMPROVEMENT_EVALUATED,
                        statusDatabaseText: "Evaluated"
                    },
                    {
                        statusDisplayText: translations.IMPROVEMENT_ARCHIVED,
                        statusDatabaseText: "Archived"
                    }];
                setSelectedStatus();
            });

        }

        function setSelectedStatus() {

            if (suggestion.status === 'Activated' || suggestion.status === 'Planned' || suggestion.status === 'Open') {
                $scope.selectedStatus = $scope.statuses[1];
            } else {
                $scope.selectedStatus = $filter('filter')($scope.statuses, { statusDatabaseText: suggestion.status }, true)[0];
            }
        }
        translateStatuses();

        function translatePriorities() {
            $translate(['IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT', 'IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT', 'IMPROVEMENT_LOW_EFFORT_LOW_EFFECT', 'IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT', 'IMPROVEMENT_NOT_PRIORITIZED']).then(function (translations) {
                $scope.priorities = [
                    {
                        priorityDisplayText: translations.IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT,
                        priorityDatabase: 1
                    },
                    {
                        priorityDisplayText: translations.IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT,
                        priorityDatabase: 2
                    },
                    {
                        priorityDisplayText: translations.IMPROVEMENT_LOW_EFFORT_LOW_EFFECT,
                        priorityDatabase: 3
                    },
                    {
                        priorityDisplayText: translations.IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT,
                        priorityDatabase: 4
                    },
                    {
                        priorityDisplayText: translations.IMPROVEMENT_NOT_PRIORITIZED,
                        priorityDatabase: 0
                    }];
                setSelectedPriority();
            });

        }

        function setSelectedPriority() {
            var pri = $filter('filter')($scope.priorities, { priorityDatabase: suggestion.priorityStatus }, true);
            if (pri.length > 0) {
                $scope.selectedPriority = pri[0];
            }
        }
        translatePriorities();

        function translateLabels() {

            switch (suggestion.priorityStatus) {
                case 1:
                    $translate('IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT').then(function (trans) {
                        $scope.priorityStatusText = trans;
                    });
                    break;
                case 2:
                    $translate('IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT').then(function (trans) {
                        $scope.priorityStatusText = trans;
                    });
                    break;
                case 3:
                    $translate('IMPROVEMENT_LOW_EFFORT_LOW_EFFECT').then(function (trans) {
                        $scope.priorityStatusText = trans;
                    });
                    break;
                case 4:
                    $translate('IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT').then(function (trans) {
                        $scope.priorityStatusText = trans;
                    });
                    break;
                default:
                    $translate('IMPROVEMENT_NOT_PRIORITIZED').then(function (trans) {
                        $scope.priorityStatusText = trans;
                    });
            }
        }
        translateLabels();

        // History
        function getActivityLog() {
            var activityRequest = {
                activity: "ImprovementSuggestion",
                activityId: $scope.suggestion.id
            };
            var $translate = $filter('translate');
            var translateOptions = {
                month: $translate('COMMON_TIMEELAPSED_MONTH'),
                days: $translate('COMMON_TIMEELAPSED_DAYS'),
                hours: $translate('COMMON_TIMEELAPSED_HOURS'),
                mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
                seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
                now: $translate('COMMON_TIMEELAPSED_NOW')
            };
            activityService().getLogForActivity(activityRequest).then(function (logItems) {
                logItems.forEach(function (activity) {
                    activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                    if (activity.payLoad && typeof activity.payLoad === "string") {
                        activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                    }
                });
                $scope.activityLog = logItems;

            });
        }

        $scope.setStatusChanged = function (status) {
            if (status) {
                $scope.suggestion.status = status.statusDatabaseText;
                statusChanged = true;
            }
        };

        function isStringANumber(value) {
            return mathService().isNumeric(value, true);
        }
        $scope.checkEstimatedTimeInvested = function () {
            $scope.suggestion.estimatedCostOfInvestmentHours = mathService().correctForComma($scope.suggestion.estimatedCostOfInvestmentHours);
            $scope.estimatedTimeInvestedIsInvalid = !isStringANumber($scope.suggestion.estimatedCostOfInvestmentHours);
        };
        $scope.checkEstimatedTimeGained = function () {
            $scope.suggestion.estimatedGainOfInvestmentHours = mathService().correctForComma($scope.suggestion.estimatedGainOfInvestmentHours);
            $scope.estimatedTimeGainIsInvalid = !isStringANumber($scope.suggestion.estimatedGainOfInvestmentHours);
        };
        $scope.checkTimeInvested = function () {
            $scope.suggestion.costOfInvestmentHours = mathService().correctForComma($scope.suggestion.costOfInvestmentHours);
            $scope.timeInvestedIsInvalid = !isStringANumber($scope.suggestion.costOfInvestmentHours);
        };
        $scope.checkTimeGained = function () {
            $scope.suggestion.gainOfInvestmentHours = mathService().correctForComma($scope.suggestion.gainOfInvestmentHours);
            $scope.timeGainIsInvalid = !isStringANumber($scope.suggestion.gainOfInvestmentHours);
        };
        $scope.CheckEstimatedCostOfInvestment = function () {
            changedROIInfo = true;
            $scope.suggestion.estimatedCostOfInvestment = mathService().correctForComma($scope.suggestion.estimatedCostOfInvestment);
            $scope.estimatedCostOfInvestmentIsInvalid = !isStringANumber($scope.suggestion.estimatedCostOfInvestment);
        };
        $scope.checkEstimatedGainOfInvestment = function () {
            changedROIInfo = true;
            $scope.suggestion.estimatedGainOfInvestment = mathService().correctForComma($scope.suggestion.estimatedGainOfInvestment);
            $scope.estimatedGainOfInvestmentIsInvalid = !isStringANumber($scope.suggestion.estimatedGainOfInvestment);
        };
        $scope.checkCostOfInvestment = function () {
            changedROIInfo = true;
            $scope.suggestion.costOfInvestment = mathService().correctForComma($scope.suggestion.costOfInvestment);
            $scope.costOfInvestmentIsInvalid = !isStringANumber($scope.suggestion.costOfInvestment);
        };
        $scope.checkGainOfInvestment = function () {
            changedROIInfo = true;
            $scope.suggestion.gainOfInvestment = mathService().correctForComma($scope.suggestion.gainOfInvestment);
            $scope.gainOfInvestmentIsInvalid = !isStringANumber($scope.suggestion.gainOfInvestment);
        };


        $scope.like = function () {
            $scope.suggestion.isLiked = !$scope.suggestion.isLiked;
            if ($scope.suggestion.isLiked) {
                suggestionService().addLike($scope.suggestion.id).then(function (like) {
                    // Like event will increase counter
                });
            } else {
                suggestionService().removeLike($scope.suggestion.id).then(function () {
                });
            }
        };

        $scope.toA3 = function () {
            var assetId = "";
            if ($scope.suggestion.assetId) {
                assetId = $scope.suggestion.assetId;
            }
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newA3Form.html',
                controller: 'newA3ModalController',
                resolve: {
                    a3: function () {
                        return {
                            id: 0,
                            title: $scope.suggestion.title,
                            assetId: assetId,
                            improvementSuggestionId: $scope.suggestion.id,
                            goal: "",
                        }
                    }
                }
            });
            return modalInstance.result.then(function (a3) {
                $scope.suggestion.a3Id = a3.id;
                $scope.publish("SendImprovementActivatedtoA3", $scope.suggestion);
            }
            );
        };

        $scope.priorityChanged = function (priority) {
            if (priority) {
                newPriority = priority.priorityDatabase;
                $scope.suggestion.priorityStatus = priority.priorityDatabase;
            }
        };

        $scope.categoryChanged = function (category) {
            if (category) {
                $scope.suggestion.categoryId = category.id;
                $scope.selectedCategory = category;
                categoryChanged = true;
            }
        };

        /*
        $scope.assetChanged = function (asset) {
            customerService.getUsersForAsset(asset.id).then(function (data) {
                data = data.filter(u => u.assetRole !== 'Viewer')
                angular.forEach(data, function (employee, key) {
                    if (employee.screenName) {
                        employee.fullName = employee.firstName + " " + employee.lastName;
                    } else {
                        employee.screenName = employee.firstName;
                        employee.fullName = employee.firstName + " " + employee.lastName;
                    }
                });
                $scope.employees = data;
                if (suggestion.responsibleUserId) {
                    var responsible = $filter('filter')(data, { userId: suggestion.responsibleUserId }, true);
                    if (responsible.length > 0) {
                        $scope.selectedResponsible = responsible[0];
                    }
                }
                
            });
            if($scope.hasStrategy){
                changedGoal = true;
                //reset selected goals
                for(var i=0; i<$scope.goals.length; i++){
                    $scope.goals[i].selected = false;
                }
                //remove sub goals from previous asset
                if($scope.suggestion.goals){
                    var selectedBreakthrougGoals = []
                    for(var g=0; g<$scope.suggestion.goals.length; g++){
                        if($scope.suggestion.goals[g].strategyGoal.level == 0){
                            selectedBreakthrougGoals.unshift($scope.suggestion.goals[g]);
                        }
                    }
                    $scope.suggestion.goals = selectedBreakthrougGoals;
                }
                loadTeamGoals(asset.id);
            }
        };*/

        $scope.projectChanged = function (project) {
            $scope.suggestion.projectId = project.id;
        };

        $scope.responsibleChanged = function (user) {
            if (user) {
                $scope.suggestion.responsibleUserId = user.userId;
                $scope.suggestion.responsibleUser = user.fullName;
                $scope.suggestion.responsibleDisplayName = user.screenName;
                newResponsible = user.fullName;
                newResponsibleUserId = user.userId;
            }
        }

        $scope.setEvaluationStatus = function (status) {
            $scope.suggestion.evaluationStatus = status;
            confirmEvaluationStatusDialog = true;
        };

        $scope.setEvaluationTextChanged = function (text) {
            confirmEvaluationStatusDialog = true;
        };

        function confirmEvaluationStatus() {
            $translate(['COMMON_NO', 'DASHBOARD_CHANGE_STATUS', 'COMMON_YES', 'IMPROVEMENT_CHANGE_STATUS_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_NO,
                    actionButtonText: translations.COMMON_YES,
                    headerText: translations.DASHBOARD_CHANGE_STATUS + '?',
                    bodyText: translations.IMPROVEMENT_CHANGE_STATUS_CONFIRMATION
                };

                modalService.show({}, modalOptions).then(function (result) {
                    $scope.suggestion.statusEvaluatedDate = moment().toDate();
                    $scope.suggestion.status = "Evaluated";
                    confirmEvaluationStatusDialog = false;
                    $scope.ok();
                },
                function () {
                    confirmEvaluationStatusDialog = false;
                    $scope.ok();
                });
            });
        }

        $scope.getPriorityStatusText = function (prioritystatusno) {
            return suggestionService().getPriorityStatusText(prioritystatusno);
        };

        customerService.getAllUsers().then(function (users) {
            $scope.allUsers = users;
        });
        
        $scope.subscribe("SuggestionCommentedOn", function(c) {
            $scope.totalComments += 1;
        });
        $scope.saveComment = function(comment) {
            comment.suggestionId = suggestion.id;
            return suggestionService().addComment(comment);
        };
        $scope.comments = [];
        suggestionService().getComments(suggestion.id).then(function(suggestionComments) {
            $scope.totalComments = suggestionComments.total;
            $scope.comments = suggestionComments.comments;
        });
        $scope.showAddComment = true;
        $scope.addComment = function (comment) {
            return $scope.saveComment(comment).then(function (savedComment) {
                $scope.comments.push(savedComment);
            });
        };
        $scope.deleteComment = function (comment) {
            return suggestionService().deleteComment(suggestion.id, comment.id).then(function() {
                $scope.totalComments -= 1;
            });
        };
        $scope.dueDateChanged = function (event) {
            const date = event.originalEvent.detail
            suggestion.dueDate = date
            changedDueDate = true
            newDueDate = date
        }

        $scope.updateDueDate = function () {
            /*
                        boardTaskService().updateDate(task.id, task.boardDate).then(function (taskMoved) {
                            $scope.publish("SendChangedSuggestionDueDate", taskMoved);
                        });*/
        };

        $scope.ok = function () {
            if (confirmEvaluationStatusDialog && $scope.suggestion.status !== "Evaluated") {
                confirmEvaluationStatus();
                return;
            }
            if ($scope.suggestion.asset) {
                $scope.suggestion.assetId = $scope.suggestion.asset.id;
                $scope.suggestion.assetName = $scope.suggestion.asset.name;
            }

            if ($scope.changedDueDate) {
                $scope.updateDueDate();
            }

            if(changedROIInfo){
                updateROI();
            }
            updateGoals();

            suggestionService().updateSuggestion($scope.suggestion).then(function (suggestion) {
                $scope.publish("SendImprovementUpdated", suggestion);

                if (categoryChanged) {
                    var changedCategory =
                    {
                        suggestionId: suggestion.id,
                        categoryName: $scope.selectedCategory.name,
                        categoryId: $scope.suggestion.categoryId
                    };
                    $scope.publish("SendImprovementChangedCategory", changedCategory);
                    categoryChanged = false;
                }

                $scope.suggestion = suggestion;
                if(statusChanged && $scope.suggestion.a3Id){
                    
                    $translate(['COMMON_CANCEL', 'COMMON_UPDATE_STATUS', 'COMMON_CHANGE_STATUS', 'IMPROVEMENT_UPDATE_A3_STATUS']).then(function (translations) {
                        var modalOptions = {
                            closeButtonText: translations.COMMON_CANCEL,
                            actionButtonText: translations.COMMON_UPDATE_STATUS,
                            headerText: translations.COMMON_CHANGE_STATUS,
                            bodyText: translations.IMPROVEMENT_UPDATE_A3_STATUS
                        };
        
                        modalService.showModal({}, modalOptions).then(function (result) {
                            var newstatus = suggestionService().getA3Status($scope.suggestion.status);
                            
                            if(newstatus){
                                a3Service().updateA3Status($scope.suggestion.a3Id, newstatus).then(function () {
                                });
                            }
                            
                        });
                    });
                }
                
                $uibModalInstance.close($scope.suggestion);
            });

        };

        function updateROI(){
            var improvementROI =
            {
                suggestionId : $scope.suggestion.id,
                newEstimatedCostOfInvestment : $scope.suggestion.estimatedCostOfInvestment, 
                newCostOfInvestment : $scope.suggestion.costOfInvestment,
                newEstimatedGainOfInvestment: $scope.suggestion.estimatedGainOfInvestment,
                newGainOfInvestment: $scope.suggestion.gainOfInvestment
        
            }
            suggestionService().updateROI(improvementROI).then(function (roisuggestion) {
                return roisuggestion;
            });
        }

        $scope.deleteSuggestion = function (suggestion) {
            var id = suggestion.id;

            var modalOptions = {
                closeButtonText: $translate.instant("COMMON_CANCEL"),
                actionButtonText: $translate.instant("ADMIN_DELETE_SUGGESTION"),
                headerText: $translate.instant("COMMON_DELETE") + ' ' + $translate.instant("COMMON_SUGGESTION") + '?',
                bodyText: $translate.instant("ADMIN_DELETE_SUGGESTION_DESCRIPTION")
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                suggestionService().deleteSuggestion(id).then(function (result) {

                    if (result.affectedTaskIds && result.affectedTaskIds.length > 0) {
                        $scope.publish("SendTasksDeleted", result.affectedTaskIds);
                    }
                    if (result.affectedA3TaskIds && result.affectedA3TaskIds.length > 0) {
                        $scope.publish("SendTasksDeleted", result.affectedA3TaskIds);
                    }
                    if (result.affectedA3Ids && result.affectedA3Ids.length > 0) {
                        angular.forEach(result.affectedA3Ids, function (id, key) {
                            $scope.publish("SendA3Deleted", id);
                        });
                    }
                    $uibModalInstance.close($scope.suggestion);
                });

            });
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);