var DigiLean = angular.module('DigiLean');
DigiLean.controller('performanceboardConfigController', ['$scope','$uibModalInstance', 'model',
    function ($scope, $uibModalInstance,model) {
        $scope.model = model;

        $scope.isAdmin = true;

        $scope.incidentfilter = "ASSET";
        if($scope.model.settings && $scope.model.settings.incidentfilter){
            $scope.incidentfilter = $scope.model.settings.incidentfilter;
        }

        $scope.changeIncidentFilter  = function (option) {
            if($scope.model.settings){
                $scope.model.settings.incidentfilter = option;
            } else {
                $scope.model.setting = {
                    incidentfilter : option
                }
            }
            
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            if(!validConfiguration(model)) {
                $scope.wrongConfiguration = true; 
            } else {
                $uibModalInstance.close($scope.model);
            }       
        };

        function validConfiguration(model){
            var valid = true;
            if(model && model.columns){
                for(var i=0; i<model.columns.length; i++){
                    if(!model.columns[i].deviationTypeId){
                        valid = false;
                        return;
                    }
                }
            } else {
                valid = false;
            }
            return valid;
        }
    }]);