import { translateOldFilter } from "@common/shared/filterTranslator"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"


angular
    .module('DigiLean')
    .directive("ongoingImprovementApp", ['authService','debounce',
        function (authService, debounce) {
            return {
                templateUrl: 'ongoingImprovementApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {

                    scope.title = "";
                    scope.filter = null;
                    scope.timePeriod = null;
                    scope.theme = getDefaultAppTheme()
                    scope.viewMode = "Kanban";
                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (scope.settings) {
                            if (scope.settings.title) {
                                scope.title = scope.settings.title;
                            }
                            if (scope.settings.theme) {
                                scope.theme = scope.settings.theme;
                            }
                            if (scope.settings.viewMode) {
                                scope.viewMode = scope.settings.viewMode;
                            }
                            if (scope.settings.listColumns) {
                                scope.listColumns = scope.settings.listColumns;
                            }
                            if (scope.settings.timePeriod) {
                                scope.timePeriod = scope.settings.timePeriod;
                            }
                            if (scope.settings.sorting) {
                                scope.sorting = scope.settings.sorting;
                            }
                            const userid = authService.getCurrentUser().userId;
                            const translatedFilter = translateOldFilter(settings.filter, userid);
                            scope.filter = translatedFilter;
                     
                        }
                        resizeDebounced();

                        scope.appSettings = {
                            viewMode: scope.viewMode,
                            filter: scope.filter,
                            listColumns: scope.listColumns,
                            timePeriod: scope.timePeriod,
                            sorting: scope.sorting
                        };
                    });
                    
                    scope.updateSettings = function () {
                        if (scope.isAdminMode == false) return;
                        var componentSettings = {
                            title: scope.title,
                            theme: scope.theme,
                            filter: scope.filter,
                            listColumns: scope.listColumns,
                            viewMode: scope.viewMode,
                            timePeriod: scope.timePeriod,
                            sorting: scope.sorting
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }

                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
    
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()

                    scope.settingsChanged = function (settings) {
                        if (!scope.isAdminMode) return;
                        if (settings.viewMode) {
                            scope.viewMode = settings.viewMode;
                        }
                        if (settings.timePeriod) {
                            scope.timePeriod = settings.timePeriod;
                        }
                        if (settings.filter) {
                            scope.filter = settings.filter;
                        }
                        if (settings.listColumns) {
                            scope.listColumns = settings.listColumns;
                        }
                        if (settings.sorting) {
                            scope.sorting = settings.sorting;
                        }
                        scope.updateSettings(); 
                    }
             
                    scope.$on("widget-resized", function (event, args) {
                        resizeDebounced();
                    });

                    function resize() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var height = host.height();

                        var headerHeight = 0;
                        var container = $(elem).find(".ongoing-improvement-container");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                    }
                    const resizeDebounced = debounce(resize, 200, false)
                    resizeDebounced();
                }
            }
        }]);
