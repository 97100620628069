angular
    .module('DigiLean')
    .directive("projectProgressCell", ['$filter', '$translate',
        function ($filter, $translate) {
            return {
                templateUrl: 'projectProgressCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.options = [
                        { progress: 25, label: "25 %", color: "#0099DE"},
                        { progress: 50, label: "50 %", color: "#0099DE"},
                        { progress: 75, label: "75 %", color: "#0099DE"},
                        { progress: 100, label: "100 %", color: "#8CC152"},
                    ]

                    scope.customOption = {
                        progress: 0,
                        label: "0 %",
                        color: "0099DE"
                    };
                    scope.$watch("model", function (model) {
                        var value = scope.model.projectAttribute.value;
                        if (value) {
                            scope.progress = value.progress;
                            scope.color = value.color;
                            scope.customOption.progress = value.progress;
                        }
                        model.getSortValue = function () {
                            if (scope.progress) {
                                return scope.progress;
                            }
                            return 0;
                        }
                    });
 
                                   
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value && scope.progress !== attribute.value.progress) {
                                scope.progress = attribute.value.progress;
                                scope.color = attribute.value.color;    
                                scope.label = attribute.value.label;
                                scope.customOption.progress = attribute.value.progress;
                            }
                        }
                    });
                    
                    
                    scope.setProgress = function(option) {
                        scope.progress = option.progress;
                        scope.customOption.progress = option.progress;
                        scope.color = option.color;
                        if (scope.progress == 100) { // Green color if 100
                            scope.color = "#8CC152";
                        }
                        // Trigger change to save
                        scope.model.projectAttribute.value = {
                            progress: scope.progress,
                            color: scope.color,
                            label: option.label
                        }
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                    }
                }
            }
        }]);
