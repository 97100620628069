import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

angular
    .module('DigiLean')
    .directive("newSubTaskList", ['taskDrawingService',
        function (taskDrawingService) {
            return {
                templateUrl: 'newSubTaskList.html',
                restrict: 'E',
                scope: {
                    'parentTask': '<',
                    'targetDate': '<',
                    'tasks': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.canEdit = true;
                    scope.boards = [];

                    scope.setActive = function (task) {
                        task.active = true;
                        setTimeout(function () {
                            $(elem).find(".project-name").focus();
                        }, 50);
                    }


                    scope.$watch('parentTask', function (parentTask) {
                        if (scope.parentTask) {
                            loadData(scope.parentId);        
                        }
                    });
                    
                    async function loadData(taskId) {
                        await getAllUsers();
                        await getBoards();
                    }
                    // Date
                    scope.updateDate = function (task, $event) {
                        const date = $event.originalEvent.detail
                        task.boardDate = date
                        // boardTaskService().updateDate(task.id, task.boardDate);
                        mapDateToColumn(task)
                        mapDateToRow(task)
                    }

                    // Responsible
                    function setResponsible(task) {
                        if (task.responsibleUserId && scope.users) {
                            var user = scope.users.find(u => u.userId.toLowerCase() == task.responsibleUserId).toLowerCase();
                            if (user) {
                                task.user = user;

                            }
                        }
                    }

                    var setupTask = function (task) {
                        task.boardDate = moment(task.boardDate).toDate();
                        task.datePicker = {
                            opened: false
                        }
                        setResponsible(task);
                    }

                    function getAllUsers() {
                        customerService.getAllUsers().then(function (users) {
                            scope.users = users;
                            for (var i = 0; i < scope.users.length; i++) {
                                var imageUrl = taskDrawingService().getProfileImageUrl(scope.users[i].userId, "ExtraSmall");
                                if (imageUrl) {
                                    scope.users[i].profileImage = true;
                                    scope.users[i].profileImageUrl = imageUrl;
                                } else {
                                    scope.users[i].profileImage = false;
                                }
                            }
                        });
                    }

                    function getBoards() {
                        boardService.getAllBoardNames().then(function (data) {
                            scope.boards = data;
                        });
                    }

                    scope.clearBoard = function (task) {
                        task.isActionItem = false;
                        task.board = null;

                        task.rowCategoryId = null;
                        task.columnCategoryId = null;
                    }


                    scope.selectThisBoard = function (task) {
                        var board = scope.boards.find(b => b.id == scope.parentTask.boardId);
                        if (board) {
                            scope.selectBoard(task, board);
                        }

                    }

                    var mapDateToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.weekdayName && (row.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedRow = row;
                            }
                        });
                    }
                    var mapDateToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.boardDate) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.weekdayName && (column.weekdayName == timeService.getWeekDayNameFromDate(task.boardDate))) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToColumn = function (task) {
                        if (!scope.columns || scope.columns.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.columns, function (column) {
                            if (column.responsibleUserId == task.responsibleUserId) {
                                task.selectedColumn = column;
                            }
                        });
                    }
                    var mapResponsibleToRow = function (task) {
                        if (!scope.rows || scope.rows.length == 0) return;
                        if (!task.responsibleUserId) return;

                        // Try to select correct row
                        angular.forEach(scope.rows, function (row) {
                            if (row.responsibleUserId == task.responsibleUserId) {
                                task.selectedRow = row;
                            }
                        });
                    }

                    scope.placeTaskInBoard = function (task) {

                        task.showColumnRow = true;
                        boardService.getBoardCategories(task.board.id).then(function (data) {

                            angular.forEach(data.columns, function (column) {
                                if (column.responsibleUserId) {
                                    column.displayName = column.name + " (" + column.responsibleDisplayName + ")";
                                } else {
                                    column.displayName = column.name;
                                }


                            });
                            angular.forEach(data.rows, function (row) {
                                if (row.responsibleUserId) {
                                    row.displayName = row.name + " (" + row.responsibleDisplayName + ")";
                                } else {
                                    row.displayName = row.name;
                                }
                            });
                            scope.columns = data.columns;
                            task.selectedColumn = data.columns[0];
                            scope.rows = data.rows;
                            task.selectedRow = data.rows[0];
                            mapDateToColumn(task);
                            mapDateToRow(task);
                            mapResponsibleToColumn(task);
                            mapResponsibleToRow(task);
                        });
                    }

                    scope.moveTaskToRowColumn = function (task) {
                        task.isActionItem = false;

                        var rowId = 0;
                        var columnId = 0;
                        if (task.selectedRow && task.selectedRow.id) {
                            rowId = task.selectedRow.id;
                        }
                        if (task.selectedColumn && task.selectedColumn.id) {
                            columnId = task.selectedColumn.id;
                        }
                        task.rowCategoryId = rowId;
                        task.columnCategoryId = columnId;
                        task.newBoardSelected = false;
                        task.showColumnRow = false;

                    }

                    scope.clearSelectedUser = function (task) {
                        var emptyUser = {
                            userId: null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.selectUser(task, emptyUser);

                    };
                    scope.selectUser = function (task, user) {
                        task.user = user;
                        if (user) {
                            task.responsibleUserId = task.user.userId;
                            task.responsibleUser = task.user.fullName;
                            task.responsibleDisplayName = task.user.displayName;
                            mapResponsibleToColumn(task);
                            mapResponsibleToRow(task);
                        }
                    };


                    function setBoard(task) {
                        if (task.boardId && scope.boards) {
                            var board = scope.boards.find(b => b.id == task.boardId);
                            if (board) {
                                task.board = board;
                                task.isActionItem = true; //the task is placed on the specifiec boards actionlist
                            }
                        }
                    }

                    scope.selectBoard = function (task, board) {
                        task.boardId = board.id;
                        task.newBoardSelected = true;
                        scope.columns = null;
                        scope.selectedColumn = null;
                        scope.rows = null;
                        scope.selectedRow = null;
                        setBoard(task);
                    }

                    scope.add = function () {
                        var boardDate = moment().utc().startOf('day').toDate();
                        if (scope.targetDate) {
                            boardDate = scope.targetDate;
                        }
                        var task = {
                            title: "",
                            boardDate: boardDate,
                            taskType: 'SubTask',
                            user: null
                        }
                        setupTask(task);
                        scope.tasks.unshift(task);
                        scope.setActive(task);

                    }

                    scope.delete = function (task) {
                        // Delete subtask
                        var index = scope.tasks.indexOf(task);
                        scope.tasks.splice(index, 1);
                    }

                    scope.changeStatus = function (task) {
                        if (scope.readonly) return;
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        var status = {
                            taskId: task.id,
                            status: task.status
                        };
                        statusChangeDebounced(status);
                    }


                    scope.titleChanged = function (task) {
                        task.active = false;
                    }

                },

            }
        }]);

