
angular
    .module('DigiLean')
    .directive("newDeviationMessage", ['$uibModal', 'deviationService',
        function ($uibModal, deviationService) {
            return {
                templateUrl: 'newDeviationMessage.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.deviation = null;
                    scope.isLoading = true;
                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        deviationService().get(payLoad.Id).then(function (deviation) {
                            scope.deviation = deviation;
                        });
               
                    });
                  

                    scope.openDeviation = function (activeTab) {
                        if (!activeTab) activeTab = "Info";
                        
                        $uibModal.open({ backdrop: 'static',
                            templateUrl: 'fullDeviation.html',
                            controller: 'fullDeviationController',
                            windowClass: 'full-screen',
                            resolve: {
                                deviation: function () {
                                    return scope.deviation;
                                },
                                activeTab: function () {
                                    return activeTab;
                                }
                            }
                        });

                    }
                }
            }
        }]);