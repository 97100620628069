
angular
    .module('DigiLean')
    .directive("a3Card", ['a3Service',
        function ( a3Service) {
            return {
                templateUrl: 'a3Card.html',
                restrict: 'E',
                scope: {
                    "a3" : "<",
                },
                link: function (scope) {
                    scope.title = ""
                    scope.goal = ""
                    scope.isLoading = true
                    
                    scope.$watch('a3', function (a3) {
                        if (!a3) return
                        a3Service().getPreviewImageUrl(a3.id).then(function(url){
                            scope.previewUrl = url
                        })
                        
                        if (a3.title)
                            scope.title = a3.title
                        a3Service().get(a3.id).then(function(model){
                            scope.title = model.title
                            scope.goal = model.goal
                            scope.isLoading = false
                        })
                    })
                }
            }
        }])
    