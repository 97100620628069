import { Asset, AssetBusinessTree, AssetBusinessUnit, AssetInfo, AssetLeaf, AssetWithRole, CustomizedBoardAsset, GroupType, Participant, UserAssetAccess } from "@api"
import backend from "@common/services/backendHttp"

const resourceUrl = "api/asset"

export const getList = () => {
    return backend.get<AssetWithRole[]>(resourceUrl)
}

export const getAssetsByType = (type: GroupType) => {
    const url = resourceUrl + "/GetAssetsByType/" + type
    return backend.get<AssetWithRole[]>(url)
}

export const getMyProjectAssets = () => {
    const url = resourceUrl + "/getMyProjectAssets/"
    return backend.get<AssetWithRole[]>(url)
}

export const getProjectAssets = () => {
    const url = resourceUrl + "/getProjectAssets/"
    return backend.get<AssetWithRole[]>(url)
}

export const getAllUserAssets = () => {
    const url = resourceUrl + "/getAllUserAssets"
    return backend.get<AssetWithRole[]>(url)
}

export const getAssetsWithDeviation = () => {
    const url = resourceUrl + "/getAssetsWithDeviation"
    return backend.get<Asset[]>(url)
}

export const getAllAssets = (useCache?: boolean) => {
    if (!useCache)
        useCache = false
    const url = resourceUrl + "/getAllAssets"
    return backend.get<Asset[]>(url)
}

export const getAllAssetsIncludingSystem = () => { //only id and name
    const url = resourceUrl + "/GetAllAssetsIncludingSystem";
    return backend.get<AssetInfo[]>(url)
}

export const getById = (assetId: number) => {
    const url = resourceUrl + "/getById/" + assetId
    return backend.get<Asset>(url)
}

// for Admin-module
export const getUserAssets = (id: number) => {
    const url = resourceUrl + "/getUserAssets/" + id
    return backend.get<UserAssetAccess[]>(url)
}

export const saveUserAssets = (userId: string, tree: UserAssetAccess) => {
    const url = resourceUrl + "/saveUserAssets/" + userId
    return backend.post(url, tree)
}

export const getAssetBusinessTree = (businessUnitId?: number) => {
    const url = businessUnitId
    ? resourceUrl + "/getassetbusinesstree/" + businessUnitId
    : resourceUrl + "/getassetbusinesstree";
    return backend.get<AssetBusinessTree[]>(url)
}

export const saveAssetBusinessTree = (businessUnitId?: number) => {
    const url = businessUnitId
    ? resourceUrl + "/saveassetbusinesstree/" + businessUnitId
    : resourceUrl + "/saveassetbusinesstree";
    return backend.get<AssetBusinessTree[]>(url)
}

export const createCustomAsset = (asset: CustomizedBoardAsset) => {
    const url = resourceUrl + "/createCustomAsset"
    return backend.post<Asset>(url, asset)
}

export const updateCustomAssetMembers = (assetId: number, participants: Participant[]) => {
    const url = resourceUrl + "/updateCustomAssetMembers/" + assetId
    return backend.put(url, participants)
}

export const add = (asset: AssetBusinessUnit) => {
    return backend.post<AssetBusinessUnit>(resourceUrl, asset)
}

export const update = (asset: AssetBusinessUnit) => {
    return backend.put<AssetBusinessUnit>(resourceUrl, asset)
}

export const remove = (id: number) => {
    const url = resourceUrl + "/deleteasset/" + id
    return backend.delete(url)
}

export const updateAssetOrder = (asset: AssetLeaf) => {
    const url = resourceUrl + "/updateassetorder"
    return backend.post(url, asset)
}