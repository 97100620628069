﻿angular
    .module('DigiLean')
    .directive("draggableModal", ['pagerService',
        function (pagerService) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {
                    setTimeout(function () {
                        // SHould find closest parent to attach
                        $(".modal-dialog").draggable({
                            handle: ".modal-header"
                        });
                    }, 50);
                }
            }
        }]);
