﻿var DigiLean = angular.module('DigiLean');  
DigiLean.controller('parenttaskInfoController', ['$scope', '$uibModalInstance','boardTaskService', 'task',
  function ($scope, $uibModalInstance,boardTaskService, task) {
      $scope.task = task;

      boardTaskService().getTaskInfoBoardName(task.parentId).then(function (data) {
          $scope.parenttask = data;
      });
/*
      boardTaskService().getBoardInfo($scope.task.parentId).then(function (boarddata) {
        $scope.parentboard = boarddata;
    });*/

      $scope.ok = function () {
          $uibModalInstance.close();
      };
      $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
      };
  }]);