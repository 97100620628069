angular.module('DigiLean').factory('dataFilterService', ['$filter',
    function ($filter) {
        function dataFilterService() {

            function inList(value, list) {
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];
                    if (value.toString() == element.toString()) {
                        return true;
                    }
                } 
                return false;
            }

            function matchesFilter(value, filter) {
                var propertyValue = value[filter.sourceColumn];
                // Operator + items
                switch (filter.operator) {
                    case "InList":
                        // Validate in list
                        return inList(propertyValue, filter.items);
                    default:
                        return true;
                }
            }
            return {
                /* value = DataValue, filters = Array[FilterElment] */
                matchesFilters: function (value, filters) {
                    if (!filters) return true;
                    if (filters.length == 0) return true;
                    var status = true;
                    filters.forEach(element => {
                        if (!matchesFilter(value, element)) {
                            status = false;
                        } 
                    });
                    return status;
                },


            };
        }
        return dataFilterService;
    }]);

