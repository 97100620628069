﻿angular
    .module('DigiLean')
    .directive("teamGoalsTable", ['$translate', 'strategyService',
        function ($translate, strategyService) {
            return {
                templateUrl: 'teamGoalsTable.html',
                restrict: 'E',
                scope: {
                    'goalId': '=',
                    'assetId': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.editable = false;

                    scope.columns = [];
                    scope.goals = [];
                   

                    function createTable() {
                        scope.columns = [
                            {
                                name: $translate.instant("STRATEGY_PREVIOUS_LEVEL"),
                                sortOrder: 0,
                                attributeType: "parentgoaltitle"
                            },
                            {
                                name: $translate.instant("COMMON_GOAL"),
                                sortOrder: 1,
                                attributeType: "goaltitle"
                            },
                            {
                                name: $translate.instant("COMMON_DUE_DATE"),
                                sortOrder: 2,
                                attributeType: "duedate"
                            },
                            {
                                name: $translate.instant("STRATEGY_KPI"),
                                sortOrder: 3,
                                attributeType: "kpis"
                            }];
                          
                    }

                    createTable();

                    scope.$watch("assetId", function () {    
                        loadGoals();    
                     });


                    scope.$watch("goalId", function () {
                       loadGoals(); 
                    });

                    function loadGoals() {
                        if (scope.goalId == null) return;
                        if (scope.assetId == null) return;
                        strategyService().getTeamGoals(scope.goalId, scope.assetId).then(function(goals){
                            
                            var currentParentId = -1;
                            for (let index = 0; index < goals.length; index++) {
                                const goal = goals[index];
                                if (goal.parentId != currentParentId) {
                                    goal.first = true;
                                    currentParentId = goal.parentId;
                                    //TODO not sure why the structure from the backedend returns parent.childgoals having different assetId
                                    var currentrowspan = 1;
                                    if(goal.parent && goal.parent.childGoals){
                                        
                                        for (let s = 0; s < goal.parent.childGoals.length; s++) {
                                            if(goal.parent.childGoals[s].assetId == scope.assetId){
                                                currentrowspan = currentrowspan + 1;
                                            }
                                        }
                                    }
                                    goal.rowspan = currentrowspan;
                                }
                            }
                            scope.goals = goals;

                        });
                    }
                    
                }
            }
        }]);