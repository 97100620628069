import { getCompanySetting } from "@common/stores/companySettingsStore"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('a3templateStore', ['$scope', '$uibModalInstance', 'a3templateService',
    function ($scope, $uibModalInstance, a3templateService) {
        $scope.selectedApp = {};
        var alltemplates = [];

        $scope.orgLanguage = "en";
        getCompanySetting("language").then(function (setting) {
            if (setting) {
                $scope.orgLanguage = setting
            }
            a3templateService().getPublicTemplates().then(function (templates) {
                alltemplates = templates;
                // Filter on language
                $scope.toggleLanguage($scope.orgLanguage);
            });

        })


        $scope.getTemplate = function (template) {
            template.isSaving = true;
            // Do stuff
            a3templateService().downloadTemplate(template.id).then(function() {
                template.isCompleted = true;
            })
        }

        $scope.toggleLanguage = function (language) {
            if (language == "en") {
                $scope.isEnglishSelected = true;
                $scope.isNorwegianSelected = false;
            }
            if (language == "no") {
                $scope.isNorwegianSelected = true;
                $scope.isEnglishSelected = false;
            }
            $scope.templates = filterOnLanguage(alltemplates);
        }

        function filterOnLanguage(templates) {
            if ($scope.isAllLanguagesSelected) return templates;
            var filtered = [];
            templates.forEach(template => {
                if ($scope.isEnglishSelected && template.language == "en") {
                    filtered.push(template);
                }
                if ($scope.isNorwegianSelected && template.language == "no") {
                    filtered.push(template);
                }
            });
            return filtered;
        }

        $scope.ok = function () {
            $uibModalInstance.close($scope.selectedApp);

        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);