import angular from "angular"
import {html} from "@common/services/template/templateLiteral"
import {dateFormat} from "@common/services/datetime/dateTimeService"
import { NgComponent } from "@common/model/angularModel"
import '@common/shared/dates/datePicker.css'

const DigiLean = angular.module("DigiLean")
DigiLean.component("datePicker", 
{
    bindings: {
        'isDisabled': '<',
        'dateValue': '<',
        'defaultOpen': '<'
    },
    controller: ['$element', function(this: NgComponent, $element) {
        let $ctrl = this
        let observer:IntersectionObserver | null 
        $ctrl.state = { opened: false }

        const handleIntersect = (entries, observer) => {
            for (const entry of entries) {
                // if default-open is set, then observe and open when visible
                if (entry.isIntersecting) {
                    if ($ctrl.defaultOpen)
                        $ctrl.state.opened = true
                }
                else {
                    if ($ctrl.state.opened)
                        $ctrl.state.opened = false
                }
            }
        }

        $ctrl.$onInit = function() {
            observer = new IntersectionObserver(handleIntersect)
            observer.observe($element[0])
        }
        $ctrl.$onDestroy = function() {
            if (observer) {
                observer.disconnect()
            }
        }
        // @ts-ignore
        $ctrl.format = dateFormat
        
        $ctrl.dateOptions = {
            startingDay: 1 //ISO week
        }

        $ctrl.open = function($event) {
            $ctrl.state.opened = true
            $event.stopPropagation()
        }

        $ctrl.dateUpdated = function() {
            dispatchDateChangedEvent()
        }
        function dispatchDateChangedEvent() {
            const options = {
                detail: $ctrl.dateValue,
                bubbles: true,
                composed: true
            }
            $element[0].dispatchEvent(new CustomEvent("date-changed", options))
        }
    }],
    template: html`
        <div class="digilean-datepicker">
            <input type="text"
                class="form-control datepicker-input" 
                ng-disabled="$ctrl.isDisabled"
                is-open="$ctrl.state.opened"
                
                uib-datepicker-popup="{{$ctrl.format}}"
                datepicker-append-to-body="true"
                datepicker-options="$ctrl.dateOptions"

                ng-model="$ctrl.dateValue"
                ng-model-options="{updateOn: 'blur'}" 

                ng-click="$event.stopPropagation()"
                ng-change="$ctrl.dateUpdated()"
                
                current-text="{{ 'COMMON_TODAY' | translate }}"
                clear-text="{{ 'COMMON_CLEAR' | translate }}"
                close-text="{{ 'COMMON_CLOSE' | translate }}"
                
            />
            <button type="button" class="btn btn-default datepicker-button"
                ng-click="$ctrl.open($event)">
                <i class="glyphicon glyphicon-calendar"></i>
            </button>
        </div>
    `
})