import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"

const DigiLean = angular.module('DigiLean')
DigiLean.controller('strategyBowlerChartController', ['$scope', '$filter', 'strategyService', '$uibModal', '$translate','authService','dataService',
        function ($scope, $filter, strategyService, $uibModal, $translate, authService, dataService) {
            $scope.assetid = null;
            $scope.timecolumns = [];
            $scope.items = [];
            $scope.newKPI =  {title:'', operator: '>='};
            $scope.year = moment().year();
            $scope.timePeriod = timeService.getTimePeriod("year");
            $scope.editable = false;

            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
            var companyfiscalmonth = customerService.getDefaultFiscalmonth()
            var fiscalActive = false;

            function setup(){
                getCompanySetting("fiscalmonth").then(function (setting) {
                    if (setting)
                        companyfiscalmonth = parseInt(setting)
                    
                    // grouped by month
                    for (var i = 1; i < 13; i++) {
                        var diffFiscalMonth = i - companyfiscalmonth;
                        var fiscalYear = $scope.year;
                        var fiscalMonth = i;
                        if(companyfiscalmonth != 1 && (fiscalActive || diffFiscalMonth !== 0)){
                            fiscalActive = true;
                            if(companyfiscalmonth + i <= 13) {
                                fiscalYear = $scope.year - 1;
                                fiscalMonth = companyfiscalmonth + i - 1;
                            } else {
                                fiscalMonth = companyfiscalmonth + i - 13;
                            }
                        }     
                        var columnTitle = timeService.getMonthLabel(fiscalMonth);
                        var column = {
                            translated: columnTitle,
                            year: fiscalYear,
                            month: fiscalMonth
                        }
                        $scope.timecolumns.push(column);
                    }
                });
            }
            setup();

            $scope.subscribe("UserAuthenticatedAndReady", function(profile) { 
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
            });

            $scope.subscribe("KPIAdded", function (newkpi) {
                var value = $filter('filter')($scope.strategygoals, { id: newkpi.strategyGoalId });
                if (value.length > 0) { 
                    value[0].kpIs.push(newkpi);
                    if (newkpi.dataSourceId || newkpi.targetDataSourceId) {
                        getValuesForSerie(newkpi);
                    }
                }
            });
            
            $scope.subscribe("KPIDeleted", function (deletedid) {
                for(var i=0; i<$scope.strategygoals.length; i++){
                    var goal = $scope.strategygoals[i];
                    var value = $filter('filter')(goal.kpIs, { id: deletedid });
                    if (value.length > 0) { 
                        var index = goal.kpIs.indexOf(value[0]);
                        if (index > -1) {
                            goal.kpIs.splice(index, 1);
                            break;
                        }
                    }
                }
                
            });

            $scope.toggleEdit = function () {
                $scope.editable = !$scope.editable;
            }
            
            $scope.previousYear = function () {
                $scope.year = $scope.year - 1;
                loadData();
                setColumnYear();
            }
            $scope.nextYear = function () {
                $scope.year = $scope.year + 1;
                loadData();
                setColumnYear();
            }
            function setColumnYear(){
                if($scope.timecolumns){
                    angular.forEach($scope.timecolumns, function (element) {
                        element.year = $scope.year;
                    });
                }
            }

            function getTimePeriod(){
                if(fiscalActive){
                    var fiscalYearStart;
                    var companyfiscalmonthMoment = companyfiscalmonth - 1;
                    if(companyfiscalmonthMoment > moment().month()){
                        fiscalYearStart = moment($scope.year, "YYYY").subtract(1, 'year').month(companyfiscalmonthMoment).startOf("month");
                    } else {
                        fiscalYearStart = moment($scope.year, "YYYY").month(companyfiscalmonthMoment).startOf("month");
                    }
                    var fiscalYearEnd = moment(fiscalYearStart).add(12, "month").subtract(1, 'days').endOf("day");
                    $scope.timePeriod = {
                        from: fiscalYearStart.utc().toDate().toJSON(),
                        to: fiscalYearEnd.utc().toDate().toJSON()
                    };
                    return $scope.timePeriod;
                } else {
                    var startDate = moment($scope.year, "YYYY").startOf("year");
                    var endDate = moment(startDate).endOf("year");
                    $scope.timePeriod = {
                        from: startDate.utc().toDate().toJSON(),
                        to: endDate.utc().toDate().toJSON()
                    }; 
                    return $scope.timePeriod;
                }            
            }

            $scope.openKPIConfiguration = function(kpi, goal){      
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'strategyKPIConfiguration.html',
                    controller: 'strategyKPIConfigurationController',
                    resolve: {
                        configuration: function () {
                            return kpi;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                    if (result == 'Delete') return;

                    if(!(result.unit && typeof (result.unit) === "string")){
                        result.unit = "";
                    }
                    var filter = null;
                    if(result.filters){
                        filter = JSON.stringify(result.filters);
                    }
                    var targetFilter = null;
                    if(result.targetFilters){
                        targetFilter = JSON.stringify(result.targetFilters);
                    }
                    var updatedKPI = {
                            title: result.title,
                            dataSourceId: result.dataSourceId,
                            filter: filter,
                            unit: result.unit,
                            targetDataSourceId: result.targetDataSourceId,
                            targetFilter: targetFilter,
                            formula: result.formula,
                            operator: result.operator,
                            strategyGoalId: goal.id,
                            startDate: result.startDate,
                            endDate: result.endDate
                        }
                    if(kpi.isNew){
                        strategyService().addKPI(updatedKPI).then(function (result) {
                        });
                    } else {
                        updatedKPI.id = kpi.id;
                        strategyService().updateKPI(updatedKPI).then(function () {
                            kpi = updatedKPI;
                            kpi.valueChanged=moment().format();
                        });
                    }       
                });
            }

            $scope.addNewKPI = function (goal) {
                var newkpi = {
                    title: '',
                    strategyGoalId: goal.id,
                    isNew: true
                }
                $scope.openKPIConfiguration(newkpi, goal);
            };
     
            function getValuesForSerie(kpi) {
                $scope.timePeriod = getTimePeriod();
                if (!$scope.timePeriod) return;

                if(kpi.dataSourceId > 0){             
                    if (kpi.filter && typeof (kpi.filter) === "string") {
                        kpi.filter = JSON.parse(kpi.filter);
                    }

                    var options = {
                        groupByInterval: "month",
                        formula: kpi.formula,
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: $scope.timePeriod,
                        filters: kpi.filter,
                    };

                    dataService().getTargetValuesForPeriod(kpi.dataSourceId, options).then(function (values) {
                        if (values) {
                            for(var i=0; i<values.length; i++) {
                                
                                if (values[i].month) {
                                    var value = $filter('filter')(kpi.kpiMonthlyValues, { month: values[i].month },true);
                                    if (value.length > 0) { 
                                        value[0].actual = values[i].value;
                                    } else {
                                        var monthlyValue = {
                                            year: values[i].year, 
                                            month: values[i].month,
                                            actual : values[i].value
                                        }
                                        
                                        kpi.kpiMonthlyValues.push(monthlyValue);
                                    }
                                }         
                            }
                            if(kpi.targetDataSourceId > 0){
                                getTargetValuesForSerie(kpi);
                            } else {
                                kpi.valueChanged=moment().format();
                            }
                        } else if(kpi.targetDataSourceId > 0){
                            getTargetValuesForSerie(kpi);
                        }
                    });                
                } else if(kpi.targetDataSourceId > 0){
                    getTargetValuesForSerie(kpi);
                }          
            }

            function getTargetValuesForSerie(kpi) {
                $scope.timePeriod = getTimePeriod();
                if (!$scope.timePeriod) return;

                if (kpi.targetFilter && typeof (kpi.targetFilter) === "string") {
                    kpi.targetFilter = JSON.parse(kpi.targetFilter);
                }

                var options = {
                    groupByInterval: "month",
                    timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                    timePeriod: $scope.timePeriod,
                    filters: kpi.targetFilter,
                };

                dataService().getTargetValuesForPeriod(kpi.targetDataSourceId, options).then(function (values) {
                    if (values) {
                        for(var i=0; i<values.length; i++) {
                            
                            if (values[i].month) {
                                var value = $filter('filter')(kpi.kpiMonthlyValues, { month: values[i].month },true);
                                if (value.length > 0) { 
                                    value[0].plan = values[i].value;
                                } else {
                                    var monthlyValue = {
                                        year: $scope.year,
                                        month: values[i].month,
                                        plan : values[i].value
                                    }
                                    
                                    kpi.kpiMonthlyValues.push(monthlyValue);
                                }
                            }         
                        }
                        kpi.valueChanged=moment().format();
                    }
                });                        
            }           
            

            function loadData(){
                strategyService().getGoalsWithKPIsByAsset($scope.assetid, $scope.year).then(function (result) {
                    $scope.strategygoals = result; 
                    if(result){
                        for(var g=0; g<result.length; g++) {
                            if(result[g].kpIs){
                                for(var i=0; i<result[g].kpIs.length; i++) {
                                    if (result[g].kpIs[i].dataSourceId) {
                                        getValuesForSerie(result[g].kpIs[i]);
                                    }
                                    else if (result[g].kpIs[i].targetDataSourceId) {
                                        getTargetValuesForSerie(result[g].kpIs[i]);
                                    }          
                                }
                            }
                        }  
                                        
                    }
                });
            }

            function getAssetList() {
                if($scope.isAdmin){
                    assetService.getAllAssets(true).then(function (assets) {
                        $scope.assets = assets;     
                        for (var k = 0; k < $scope.assets.length; k++) {
                            $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                        }  
                        $scope.asset = {
                            id : null,
                            name: $translate.instant('STRATEGY_TOP_LEVEL')
                        };
                        $scope.assetname = $scope.asset.name;
                        $scope.assetid = $scope.asset.id;
                        loadData(); 
                    });
                } else {
                    assetService.getList().then(function (assets) {
                        $scope.assets = assets;  
                        for (var k = 0; k < $scope.assets.length; k++) {
                            $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                        }
                        if($scope.assets.length >0){
                            $scope.asset = $scope.assets[0];
                            $scope.assetname = $scope.assets[0].name;
                            $scope.assetid = $scope.assets[0].id;
                        }
                        loadData();
                             
                    });
                }
                
            }
            getAssetList();
            $scope.selectAsset = function (asset) {
                $scope.asset = asset;
                $scope.name = asset.name;
                $scope.assetid = asset.id;
                loadData();
            };

            $scope.toplevel = function () {
               var top = {
                    id : "",
                    name: $translate.instant('STRATEGY_TOP_LEVEL')
                };
                $scope.selectAsset(top);
                
            };
        
        }]);