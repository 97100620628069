angular
    .module('DigiLean')
    .directive("smartactionlistLastModifiedDateCell", ['boardTaskService', 
        function (boardTaskService) {
            return {
                templateUrl: 'smartactionlistLastModifiedDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {

                    scope.value = "";
                    scope.modifiedBy = "";
                    scope.update = function ($event) {
                        const date = $event.originalEvent.detail
                        scope.value = date
                        scope.saveChanges()
                    }

                    scope.$watch('model', function (model) {
                        var value = scope.model.task.lastModified;
                        var modifiedBy = scope.model.task.lastModifiedByUser;
                        if (value) {
                            setValue(value, modifiedBy);
                        }
                        model.getSortValue = function () {

                            return scope.value;
                        }
                    });

                    
                    var setValue = function (value, modifiedBy) {
                        if (value) {
                            var date = moment(value).toDate();
                            if (date.getFullYear() > 1) { // Make sure the date/year is valid
                                scope.value = date;
                            }
                            
                        } else {
                            scope.value = "";
                        }
                        scope.modifiedBy = modifiedBy;
                    }

                    // Event Sync
                    function updateModifiedInfo() {
                        boardTaskService().getModifiedInfo(scope.model.cell.boardTaskId).then(function(info) {
                            if (info) {
                                setValue(info.lastModified, info.lastModifiedByUser)
                            }
                        });
                    }
                    // TODO: Subscribe to all task event
                    // Check if task matches and fetch lastmodified if not present on event
                    // Probably need a custom backend method to avoid loading the whole task object.
                    //
                    scope.subscribe("TaskUpdated", function (task) {
                        if (task.id == scope.model.cell.boardTaskId) {
                            setValue(task.lastModified, task.lastModifiedByUser);
                        }
                    });
                    scope.subscribe("TaskStatusUpdated", function (task) {
                        if (task.taskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });
                    scope.subscribe("SmartActionListCellUpdated", function (cell) {
                        if (cell.boardTaskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });
                    scope.subscribe("TaskDateMoved", function (task) {
                        if (task.taskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });
                    scope.subscribe("TaskStartDateUpdated", function (task) {
                        if (task.taskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });
                    scope.subscribe("TaskDurationUpdated", function (task) {
                        if (task.taskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });
                    scope.subscribe("TaskComment", function (cell) {
                        if (cell.boardTaskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });
                    scope.subscribe("TaskAttachmentCountChanged", function (cell) {
                        if (cell.taskId == scope.model.cell.boardTaskId) {
                            updateModifiedInfo();
                        }
                    });                    


                }
            }
        }]);
