﻿var DigiLean = angular.module('DigiLean');
DigiLean.directive("milestones", ['$translate', 'projectService',
    function ($translate, projectService) {
        return {
            templateUrl: 'milestones.html',
            restrict: 'E',
            scope: {
                'projectId': '=',
                'canEdit': '=',
                'readonly': '@'
            },
            link: function (scope, element, attrs) {

                scope.milestones = [];
     

                scope.$watch("projectId", function () {
                    if (!scope.projectId) return;
                    // get milestones
                    projectService().getMilestonesForProject(scope.projectId).then(function (milestones) {
                        angular.forEach(milestones, function(milestone){
                            milestone.plannedDate = moment(milestone.plannedDate).toDate();
                            milestone.actualDate = moment(milestone.actualDate).toDate();
                            milestone.datePicker = {
                                opened: false
                            },
                            milestone.endDatePicker = {
                                opened: false
                            }
                        })
                        scope.milestones = milestones;
                    });
                });

                scope.add = function () {
                    var milestone = {
                        name:  $translate.instant('PROJECT_MILESTONE'),
                        projectId: scope.projectId,
                        plannedDate: moment().toDate(),
                        completedDate: null,
                        datePicker : {
                            opened: false
                        },
                        endDatePicker : {
                            opened: false
                        }
                    };
                    projectService().createMilestone(milestone).then(function (milestone) {
                        milestone.datePicker = {
                            opened: false
                        },
                        milestone.endDatePicker = {
                            opened: false
                        }
                        scope.milestones.push(milestone);
                    });
                }

                scope.delete = function (milestone) {
                    projectService().deleteMilestone(milestone.id).then(function () {
                        var index = scope.milestones.indexOf(milestone);
                        scope.milestones.splice(index, 1);    
                    });
                    
                }

                scope.milestoneChanged = function (milestone) {
                    projectService().updateMilestone(milestone)
                }

                scope.milestoneActualDateChanged = function(milestone, $event) {
                    const date = $event.originalEvent.detail
                    milestone.actualDate = date
                    scope.milestoneChanged(milestone)
                }
               
                scope.milestonePlannedDateChanged = function(milestone, $event) {
                    const date = $event.originalEvent.detail
                    milestone.plannedDate = date
                    scope.milestoneChanged(milestone)
                }
            }
        }
    }]);
