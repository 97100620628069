import * as assetService from "@common/services/assetService"
import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"

var DigiLean =  angular.module('DigiLean');
DigiLean.controller('flexiboardAdminController', ['$scope', '$translate', '$uibModal', '$state', 'modalService','folderService',
function ($scope, $translate, $uibModal, $state, modalService, folderService) {
    $scope.boards = [];
    $scope.activeboards = [];

    var loadBoards = function () {
        folderService().getFlexiBoardsForAdmin().then(function (boards) {
            checkBoardType(boards);
            $scope.activeboards = boards;
        });   
    }

    function checkBoardType(boards){
        if(boards){
            for(var b=0; b< boards.length; b++){
                if(boards[b].boardType === null){
                    var settings = JSON.parse(boards[b].settings);
                    if(settings && settings.IsWeeklyBoard){
                        boards[b].boardType = "Weekly";        
                    } else {
                        boards[b].boardType = "Kanban";        
                    }      
                }
            }        
        }
    }

    function initialize() {
        assetService.getAllAssets(true).then(function (assets) {
            $scope.assets = assets;
            loadBoards();
        });

    }
    initialize();



    $scope.subscribe("DashboardCreated", function (board) {
        if(board){
            loadBoards();
        }
    });


    $scope.subscribe("BoardCreated", function (board) {
        if(board){
            loadBoards();
        }
    });

    $scope.subscribe("BoardDeleted", function (id) {
        loadBoards();       
    });
    $scope.subscribe("DashboardDeleted", function (id) {
        loadBoards();       
    });
    $scope.edit = function (board) {
        var options = {
            targetType : "Board",
            targetId : board.id
        }

        if(board.boardType === "Dashboard"){
            options.targetType = "Dashboard";
        } else if(board.boardType === 'Performance'){
            options.includeCustomGroup = false;
            options.includeProjects = false;
        }
        var modalInstance = $uibModal.open({ backdrop: 'static',
            animation: true,
            templateUrl: 'boardSettings.html',
            controller: 'boardSettingsController',
            resolve: {
                options: function () {
                    return options;
                }
            }
        });
        modalInstance.result.then(function (asset) {
            loadBoards();
        }, function () {

        });
        
    }

    $scope.openBoardDesigner = function (board) {
        switch(board.boardType) {
            case "Dashboard":
                $state.go('flexiboard', { id: board.id, mode:"admin"}, { reload: false });
                break;
            case "SmartActionList":
                $state.go('board', { id: board.id, mode: "admin" }, { reload: false });
                break;
            case "Performance":
                $state.go('board', { id: board.id, mode: "admin" }, { reload: false });
                break;
            default:
                $state.go('boarddesigner', { id: board.id }, { reload: false });
        }
    }


    $scope.deleteBoard = function (board) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.ADMIN_DELETED_BOARD,
                headerText: translations.COMMON_DELETE + ' ' + board.name + '?',
                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                if(board.boardType === "Dashboard"){
                    dashboardService.deleteDashboard(board.id).then(function (data) {
                        toastr.info($translate.instant("ADMIN_MESSAGE_BOARD_DELETED"));
                        $scope.publish("SendDashboardDeleted", board.id);
                    });
                } else {
                    boardService.deleteBoard(board.id).then(function () {
                        toastr.info($translate.instant("ADMIN_MESSAGE_BOARD_DELETED"));
                        $scope.publish("SendBoardDeleted", board.id);
                    });
                }
                
            });

        });
    }

}]);