﻿import * as timeService from "@common/services/timeService"

angular
    .module("DigiLean")
    .directive('invoicing', ['$filter','customerAccountService', 
        function ($filter, customerAccountService) {
            return {
                templateUrl: 'invoicing.html',
                restrict: 'E',
                scope: {
                    // 'datasourceId': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = false;
                    scope.timePeriod = timeService.getTimePeriod("month"); // Current month, should be able to change
                    function loadData() {
                        scope.isLoading = true;
                        customerAccountService().getInvoiceSummaryForPeriod(scope.timePeriod).then(function (summary) {
                            scope.summary = summary;
                            customerAccountService().getInvoicesForPeriod(scope.timePeriod).then(function (invoices) {
                                scope.invoices = invoices;
                                // Now check invoice status on summary
                                scope.summary.result.invoicesCreated.forEach(element => {
                                    // filter on customer number
                                    element.invoiced = getInvoiceForCustomerNumber(scope.invoices, element.customerNumber)
                                    // check if invoice can assumed completed. Using parseInt to avoid "øreavrunding" forskjell
                                    element.invoiceCompleted = element.sumIncTax.toFixed(2) == element.invoiced.sumInvoiced.toFixed(2);
                                });
                                scope.isLoading = false;
                            });
                            
                        });
                        
                    }

                    function getInvoiceForCustomerNumber(invoices, customerNumber) {
                        var customerInvoiced = {
                            customerNumber: customerNumber,
                            sumInvoiced: 0,
                            invoices: []
                        };
                        invoices.forEach(invoice => {
                            if (invoice.customer.customerNumber == customerNumber) {
                                customerInvoiced.sumInvoiced += invoice.taxInclusiveAmountCurrency;
                                customerInvoiced.invoices.push(invoice);
                            }
                        });
                        return customerInvoiced;
                    }
                    loadData();

                    scope.lastMonth = function () {
                        var start = moment(scope.timePeriod.from).subtract(1, 'months');
                        var end = moment(start).endOf("month"); 
                        scope.timePeriod = {
                            from: start.utc().toDate().toJSON(),
                            to: end.utc().toDate().toJSON()
                        };
                        loadData();
                    }
                    scope.nextMonth = function () {
                        var start = moment(scope.timePeriod.from).add(1, 'months');
                        var end = moment(start).endOf("month"); 
                        scope.timePeriod = {
                            from: start.utc().toDate().toJSON(),
                            to: end.utc().toDate().toJSON()
                        };
                        loadData();
                    }

                    scope.refresh = function () {
                        loadData();
                    }

                    scope.createInvoice = function (invoice) {
                        customerAccountService().createInvoice(invoice).then(function (created) {
                            alert("Created invoice in UniEconomy with invoiceNumber: " + created.invoiceNumber);
                            invoice.invoiceCompleted = true;
                        });
                    }
                }
            }
        }]);

