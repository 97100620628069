﻿import * as assetService from "@common/services/assetService"

const DigiLean = angular.module('DigiLean')
DigiLean.controller('a3templatesController', ['$scope', '$translate', '$uibModal', '$state', 'a3templateService', 'modalService',
    function ($scope, $translate, $uibModal, $state, a3templateService, modalService) {
        $scope.templates = [];
        $scope.activeboards = [];


        $scope.subscribe("A3TemplateUpdated", function (a3) {
            loadTemplates();
        });
        $scope.subscribe("A3TemplateDeleted", function (a3) {
            loadTemplates();
        });
        $scope.subscribe("A3TemplateCreated", function (a3) {
            loadTemplates();
        });
        $scope.subscribe("A3TemplateDownloaded", function (a3) {
            loadTemplates();
        });
        var loadTemplates = function () {
            a3templateService().getList().then(function (templates) {
                $scope.templates = templates;
            });
        }

        $scope.delete = function (template) {
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'A3_MESSAGE_DELETE_TEMPLATE_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + template.name + '?',
                    bodyText: translations.A3_MESSAGE_DELETE_TEMPLATE_CONFIRMATION
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    a3templateService().deleteTemplate(template.id).then(function (data) {
                        $state.go('a3templates');
                        // publish event
                        $scope.publish("SendA3TemplateDeleted", template.id);
                    });
                });

            });
        }
        $scope.addTemplate = function () {
            var template = {
                name: $translate.instant('COMMON_CHANGE_NAME')
            }
            a3templateService().addTemplate(template).then(function (template) {

                $state.go('a3designer', { id: template.id }, { reload: false });
                $scope.publish("SendA3TemplateCreated", template);
            });

        }


        $scope.openDesigner = function (template) {
            $state.go('a3designer', { id: template.id }, { reload: false });
        }


        function initialize() {
            assetService.getList().then(function (assets) {
                $scope.assets = assets;
                loadTemplates();
            });

        }

        initialize();

        $scope.openTemplateStore = function () {
            var modalInstance = $uibModal.open({ backdrop: 'static',
                templateUrl: 'a3templateStore.html',
                controller: 'a3templateStore',
                windowClass: 'newdeviation-modal-window',
                resolve: {
                    // deviation: function () {
                    //     return deviation;
                    // },
                    // activeTab: function () {
                    //     return "Info";
                    // }
                }
            });

        }

    }]);