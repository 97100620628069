// Controller for the actual modal
import { addConnector } from "@common/services/admin/connectorAdminService"
const DigiLean = angular.module('DigiLean')
DigiLean.controller('newConnectorController', ['$scope', '$uibModalInstance',
    function ($scope, $uibModalInstance) {
        $scope.connector = {
            // id: "00000000-0000-0000-0000-000000000000",
            name: ""
        }
        $scope.ok = async function () {
            const connector = await addConnector($scope.connector)
            $scope.publish("SendConnectorCreated", connector)
            $uibModalInstance.close()
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel')
        }
    }
])