var DigiLean = angular.module('DigiLean');
DigiLean.controller('predefinedIcons', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'labels','smarttableService',
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, labels, smarttableService) {
        $scope.defaultLabels = smarttableService().getIcons();
        $scope.labels = labels;
        if(!$scope.labels){
            $scope.labels = $scope.defaultLabels;               
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('DASHBOARD_DATA_SOURCE');
            $scope.steps[1].title = $translate.instant('COMMON_FILTER');
            $scope.currentStep = $scope.steps[0];
        });

        
        $scope.createNew = function (item) {
            var newLabel = {
                icon: item,
                title: "",
                color: "#E6E9ED"
            }
            $scope.labels.push(newLabel);
        };

        $scope.delete = function (label) {
            var index = $scope.labels.indexOf(label);
            $scope.labels.splice(index, 1);    
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            $uibModalInstance.close($scope.labels);
        };

    }]);