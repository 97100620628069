import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"

var DigiLean = angular.module('DigiLean')
DigiLean.directive("membersSelector", ['$filter', 'authService', 'navigationService',
    function ($filter, authService, navigationService) {
        return {
            templateUrl: 'membersSelector.html',
            restrict: 'E',
            scope: {
                'assetId': '<',
                // 'canEdit': '=',
                'memberSelectionChanged': '&',
                'options': '<?'
            },
            link: function (scope, elem, attrs) {
                // comments here
                scope.hasProjectModule = navigationService().hasModule("PROJECT");
                scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                    scope.hasProjectModule = navigationService().hasModule("PROJECT");
                    loadProjects();
                });

                scope.canEdit = true;
                scope.participanttype = 'ASSET';
                scope.editParticipants = true;
                scope.projectassets = [];
                scope.projectasset = null;
                scope.assets = null;
                scope.asset = null;
                var originalAssetId = 0;
                var originalParticipantType = "ASSET";

                scope.includeProject = true;
                scope.includeCustomGroup = true;
                scope.$watch("options", function (options) {
                    if(options){
                        if (options.hasOwnProperty('includeCustomGroup')) {
                            scope.includeCustomGroup = options.includeCustomGroup;
                        }
                        if(options.includeProject === false){
                            scope.includeProject = false;
                        }
                        if(options.canEdit === false){
                            scope.canEdit = false;
                        }
                    }
                });

                scope.$watch("assetId", function (assetId) {

                    // options.targetType => Board or Dashboard
                    // options.assetId
                    loadAllUsers();
                    loadAssets();
                    if (scope.hasProjectModule) {
                        loadProjects();
                    }

                    if (!assetId) return;
                    originalAssetId = assetId;
                    loadUsersForAsset(scope.assetId);
                    assetService.getById(scope.assetId).then(function (asset) {
                        scope.asset = asset;
                        if (!asset) return;
                        if (asset.projectId) {
                            scope.participanttype = "PROJECT";
                            originalParticipantType = "PROJECT";
                        }
                        if (asset.isSystemAsset && !asset.projectId) {
                            scope.participanttype = "CUSTOMIZED"
                            originalParticipantType = "CUSTOMIZED";
                        }
                    });

                });

                var updateSelection = function (assetId, participants) {
                    var selection = {
                        assetId: assetId,
                        participants: participants,
                        assetType : scope.participanttype
                    }
                    scope.memberSelectionChanged({ selection: selection })
                }

                scope.toggleEditParticipants = function () {
                    scope.editParticipants = !scope.editParticipants;
                };

                
                scope.assetChangeHandler = function (assetinfo) {
                    if (assetinfo) {
                        scope.assetId = assetinfo.asset.id;
                        scope.selectedAsset = assetinfo.asset;
                        loadUsersForAsset(assetinfo.asset.id);
                    }       
                }
                scope.assetChanged = function (asset) {
                    loadUsersForAsset(asset.id);
                }

                var loadAllUsers = function () {
                    customerService.getAllUsers().then(function (users) {
                        scope.allUsers = users;
                        updateParticipants();
                    });
                }

                var loadUsersForAsset = function (assetId) {
                    customerService.getUsersForAsset(assetId).then(function (members) {
                        scope.participants = members;
                        updateSelection(assetId, members);
                        updateParticipants();
                    });
                }

                function setAsset(){
                    for (var k = 0; k < scope.assets.length; k++) {
                        scope.assets[k].cssLevel = "level-" + scope.assets[k].level;                     
                    }
                    setSelectedAsset();
                    scope.assetmodel = { assetId: scope.assetId,
                        assets: scope.assets};
                }
                function loadAssets() {
                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("BusinessunitAdmin");
                    if(scope.isAdmin){
                        assetService.getAllAssets().then(function (assets) {
                            scope.assets = assets;
                            setAsset();
                        });
                    } else {
                        assetService.getList().then(function (assets) {
                            scope.assets = assets;
                            setAsset();
                        });
                    }
                }

                function loadProjects() {
                    assetService.getProjectAssets().then(function (assets) {
                        scope.projectassets = assets;
                        setProjectAsset();
                    });
                    
                }


                function setProjectAsset() {
                    if (scope.assetId) {
                        scope.projectasset = $filter('filter')(scope.projectassets, { id: scope.assetId }, true)[0];
                    } else {
                        scope.projectasset = {};
                    }
                    var projectAssetId = null;
                    if(scope.projectasset) projectAssetId = scope.projectasset.id;
                    scope.projectassetmodel = { assetId: projectAssetId,
                        assets: scope.projectassets};
                }
                function setSelectedAsset() {
                    if (scope.assetId) {
                        scope.selectedAsset = $filter('filter')(scope.assets, { id: scope.assetId }, true)[0];
                    } else {
                        scope.selectedAsset = {};
                    }
                }

                function updateParticipants() {
                    if (!scope.participanttype == 'CUSTOMIZED') return;
                    if (!scope.allUsers) return;
                    if (!scope.participants) return;
                    // Set up users to select from
                    scope.users = scope.allUsers.slice();
                    
                    // Remove current participant so we can refresh the list
                    for (var i = 0; i < scope.participants.length; i++) {
                        var matchingUser = $filter('filter')(scope.users, { userId: scope.participants[i].userId }, true);
                        if (matchingUser.length > 0) {
                            var index = scope.users.indexOf(matchingUser[0]);
                            scope.users.splice(index, 1);
                        }
                    }

                }

                scope.changeParticipantType = function (type) {
                    if (type === 'PROJECT') {
                        scope.participanttype = 'PROJECT';
                    } else if (type === 'CUSTOMIZED') {
                        scope.participanttype = 'CUSTOMIZED';
                        scope.users = scope.allUsers.slice();
                        if (originalParticipantType == "CUSTOMIZED") {
                            loadUserForAsset(originalAssetId);
                        } else {
                            scope.participants = [];
                            updateSelection(0, scope.participants);
                        }
                    } else {
                        scope.participanttype = 'ASSET';
                    }
                };

                scope.addParticipants = function (user) {
                    scope.participants.unshift(user);
                    var matchingUser = $filter('filter')(scope.users, { userId: user.userId }, true);
                    if (matchingUser.length > 0) {
                        var i = scope.users.indexOf(matchingUser[0]);
                        scope.users.splice(i, 1);
                    }
                    if (originalParticipantType == "CUSTOMIZED") {
                        updateSelection(originalAssetId, scope.participants);
                    } else {
                        updateSelection(0, scope.participants);
                    }
                };

                scope.removeParticipants = function (user) {
                    scope.users.unshift(user);
                    var matchingUser = scope.participants.find(u => u.userId == user.userId )
                    if (matchingUser) {
                        var i = scope.participants.indexOf(matchingUser)
                        scope.participants.splice(i, 1)
                    }
                    if (originalParticipantType == "CUSTOMIZED")
                        updateSelection(originalAssetId, scope.participants)
                    else
                        updateSelection(0, scope.participants)
                    
                }
            }
        }
    }])
