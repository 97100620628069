﻿
angular
    .module('DigiLean')
    .directive("excelImportValues", ['$filter', '$uibModal', 'dataService', 'dataSourceService',
        function ($filter, $uibModal, dataService, dataSourceService) {
            return {
                templateUrl: 'excelImportValues.html',
                restrict: 'E',
                scope: {
                    'datasourceId': '@',
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('datasourceId', function (dataSourceId) {
                        if (!dataSourceId) return;
                        scope.uploadPath = "/excel/importdatavalues/" + dataSourceId;
                        dataSourceService().get(dataSourceId).then(function (dataSource) {
                            scope.dataSource = dataSource;
                        })
                    });

                    scope.fileParsed = function(result) {
                        scope.result = result;
                    }
                }
            }
        }]);
