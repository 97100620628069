﻿import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean')
DigiLean.directive("stackedDeviationHorizontalBarChart", ['$filter', '$translate', 'projectService', 'deviationService',
    function ($filter, $translate, projectService, deviationService) {
        return {
            templateUrl: 'stackedDeviationHorizontalBarChart.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'timeframe': '@',
                'timePeriod': '<',
                'settings': '=',
                'settingsChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {
                var grafcolor = ['#2A2E36', '#0099DE', '#d9534f', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#17987e'],
                        graphtextcolor = '#ffffff';

                scope.projects = null;
                var deviationTypes = null;
                var problemCategories = null;
                var groupColumn = "projectId";
                var stackingColumn = "status";
                var categorySummaryData = null;
                var showTotal = true;
                var groupingElementFound = false;
                var recordingTranslated = $translate.instant('DEVIATION_NUMBER_OF_DEVIATIONS');


                scope.$watch('timePeriod', function (timePeriod) {
                    if (!timePeriod) return;
                    loadData(scope.timePeriod);
                });

                function capitalizeFirstLetter(string) {
                    return string[0].toUpperCase() + string.slice(1);
                }

                function loadData(timePeriod) {
                    var grouping = {
                        sourceColumn: capitalizeFirstLetter(groupColumn) //"ProjectId",       
                    };
                    var stacking = {
                        sourceColumn: capitalizeFirstLetter(stackingColumn) //"Status",       
                    };
            
                    var options = {
                        groupingElement: grouping, 
                        timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                        timePeriod: scope.timePeriod,
                        filters: scope.filters,
                        stackingElement: stacking 
                    };
                    deviationService().getStackedDeviationGroupedSum(options).then(function (data) {
                        categorySummaryData = data;
                        drawChart(data);
                    });                  
                }

                scope.subscribe("DeviationCreated", function (deviation) {
                    loadData(scope.timePeriod);
                });

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    if (settings) {
                        
                        scope.settings = settings;
                        if (settings.dataSource) {
                            scope.dataSource = settings.dataSource;
                        }
                        if (settings.groupColumn) {
                            groupColumn = settings.groupColumn;
                        }
                        if (settings.stackingColumn) {
                            stackingColumn = settings.stackingColumn;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.showTotal) {
                            showTotal = settings.showTotal;
                        }
                        initialize(groupColumn);
                    }
                });

                function initialize(groupedBy) {  
                    if (groupedBy === "areaId") {
                        areaService().getList(true).then(function (areas) {
                            scope.areas = areas;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    } else if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                        assetService.getAllAssets().then(function (data) {
                            assets = data;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    }
                    else if (groupedBy === "projectId") {
                        projectService().getList(true).then(function (data) {
                            scope.projects = data;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    } else if (groupedBy === "problemCategoryId") {
                        deviationService().getProblemCategories().then(function (categories) {
                            problemCategories = categories;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });          
                    } else if (groupedBy === "deviationTypeId") {
                        deviationService().getAllDeviationTypes().then(function (types) {
                            deviationTypes = types;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    } else if (groupedBy === "categoryId") {
                        if (scope.dataSource.id.startsWith("Improvement")) {
                            suggestionService().getCategories().then(function (categories) {
                                scope.suggestionCategories = categories;
                                if(!groupingElementFound){
                                    groupingElementFound = true;
                                    initialize(stackingColumn);
                                } else{
                                    loadData(scope.timePeriod);
                                }
                            });
                        } else {//assue A3 categories
                            a3Service().getCategories().then(function (categories) {
                                scope.a3Categories = categories;
                                if(!groupingElementFound){
                                    groupingElementFound = true;
                                    initialize(stackingColumn);
                                } else{
                                    loadData(scope.timePeriod);
                                }
                            });
                        }
                    } else if (groupedBy === "boardId") {
                        boardService.getAll().then(function (boards) {
                            scope.boards = boards;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                        customerService.getAllUsers().then(function (users) {
                            scope.users = users;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    } else if (groupedBy === "a3TemplateId") {
                        a3templateService().getList().then(function (templates) {
                            scope.a3templates = templates;
                            if(!groupingElementFound){
                                groupingElementFound = true;
                                initialize(stackingColumn);
                            } else{
                                loadData(scope.timePeriod);
                            }
                        });
                    } else {
                        if(!groupingElementFound){
                            groupingElementFound = true;
                            initialize(stackingColumn);
                        } else{
                            loadData(scope.timePeriod);
                        }
                    }
                }

                function getPointName(element, groupedBy) {
                    if (groupedBy === "assetId" || groupedBy === "followUpAssetId") {
                        return getNameById(assets, element);
                    } else if (groupedBy === "priorityStatus") {
                        return suggestionService().getPriorityStatusText(parseInt(element));
                    } else if (groupedBy === "problemCategoryId") {
                        return getNameById(problemCategories, element);
                    } else if (groupedBy === "a3TemplateId") {
                        return getNameById(scope.a3templates, element);
                    } else if (groupedBy === "categoryId") {
                        if (scope.dataSource.id.startsWith("Improvement")) {
                            return getNameById(scope.suggestionCategories, element);
                        } else {    // Only A3
                            return getNameById(scope.a3Categories, element);
                        }
                    } else if (groupedBy === "areaId") {
                        return getNameById(scope.areas, element);
                    }else if (groupedBy === "projectId") { 
                        return getNameById(scope.projects, element);
                    } else if (groupedBy === "deviationTypeId") {
                        return getNameById(deviationTypes, element);    
                     } else if (groupedBy === "boardId") {
                        return getNameById(scope.boards, element);
                    } else if (groupedBy === "suggestedByUserId" || groupedBy === "createdByUserId" || groupedBy === "ownerUserId" || groupedBy === "responsibleUserId") {
                        return getNameByUserId(scope.users, element);
                    } else if (groupedBy === "severity") {
                        switch (element) {
                            case "1":
                                return $translate.instant('COMMON_LOW');
                            case "2":
                                return $translate.instant('COMMON_MEDIUM');
                            case "3":
                                return $translate.instant('COMMON_HIGH');
                            default:
                                return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    } else if (groupedBy === "status") {
                        switch (element) {
                            case "0":
                                return $translate.instant('COMMON_NEW');
                            case "10":
                                return $translate.instant('DEVIATION_IN_PROGRESS');
                            case "20":
                                return $translate.instant('DEVIATION_RESOLVED');
                            case "30":
                                return $translate.instant('COMMON_REJECTED');
                            case "unacceptable":
                                return $translate.instant('BOARD_INCOMPLETED');
                            case "blank":
                                return $translate.instant('BOARD_OPEN');
                            case "OK":
                                return $translate.instant('DASHBOARD_COMPLETED');
                            default:
                                return $translate.instant('COMMON_NOT_SPECIFIED');
                        }
                    } else {
                        return element;
                    }
                }

                function getNameById(source, id) {
                    if (id && source) {
                        for (var k = 0; k < source.length; k++) {
                            if (source[k].id.toString() === id.toString()) {
                                if (source[k].name) {
                                    return source[k].name;
                                } else if (source[k].title) {
                                    return source[k].title;
                                } else {
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                                }
                            }
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                }

                // Used when grouped by user
                function getNameByUserId(source, id) {
                    if (id && source) {
                        for (var k = 0; k < source.length; k++) {
                            if (source[k].userId.toString() === id.toString()) {
                                if (source[k].fullName) {
                                    return source[k].fullName;
                                } else if (source[k].userName) {
                                    return source[k].userName;
                                } else {
                                    return $translate.instant('COMMON_NOT_SPECIFIED');
                                }
                            }
                        }
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    } else {
                        return $translate.instant('COMMON_NOT_SPECIFIED');
                    }
                }

                function drawChart(data) {
                    scope.xAxisData = [];

                    if (data) {                      
                        var xAxisId = [];
                        var distinctStackedElements = [];
                        var totalSerie = [];
                        for (var i = 0; i < data.length; i++) {
                            var index = xAxisId.indexOf(data[i].element);
                            if (index < 0) {
                                xAxisId.push(data[i].element);
                                var xAxisDataLabel = getPointName(data[i].element, groupColumn);
                                scope.xAxisData.push(xAxisDataLabel);
                            }

                            var stackedindex = distinctStackedElements.indexOf(data[i].stackedElement);
                            if (stackedindex < 0) {
                                distinctStackedElements.push(data[i].stackedElement);
                                totalSerie.push(0);
                            }
                        }
                    }

                    scope.seriesData = [];            
                    var i = distinctStackedElements.length-1;
                    for (i; i >= 0; i--) {
                        var name = getPointName(distinctStackedElements[i], stackingColumn);
                        //var color = grafcolor[i];
                        var dataValues = [];
                        var stackedidtofilter = distinctStackedElements[i];
                        var elementsWithGivenStackedValue = $filter('filter')(data, { stackedElement: distinctStackedElements[i] }, true);
                        for (var y = 0; y < xAxisId.length; y++) {
                            var foundValue = $filter('filter')(elementsWithGivenStackedValue, { element: xAxisId[y] })[0];
                            if(foundValue){
                                dataValues.push(foundValue.value);
                                totalSerie[i] = totalSerie[i] + foundValue.value;
                            }else{
                                dataValues.push(0);
                            }
                        }
                        var element = {
                            name: name,
                            data: dataValues
                        };
                        scope.seriesData.push(element);
                    }

                    if(showTotal){
                        var t = totalSerie.length-1;
                        totalSerie.reverse();  
                        for (t; t >= 0; t--) {
                            scope.seriesData[t].data.unshift(totalSerie[t]);
                        }
                        scope.xAxisData.unshift($translate.instant('COMMON_TOTAL'));
                    }

                    var renderElement = $(elem).find(".stacked-deviation-horizontal-bar-chart")[0];
                    Highcharts.chart({
                        chart:{
                            renderTo: renderElement,
                            type: 'bar'
                        },
                        title: {
                            text: ''
                        },credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            categories: scope.xAxisData,
                            
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: ''
                            },
                            allowDecimals: false,
                        },
                        tooltip: {
                            headerFormat: '<b>{point.x}</b><br>',
                            pointFormat: '{series.name}: {point.y}<br>' + $translate.instant('COMMON_TOTAL') + ': {point.stackTotal}'
                        },
                        legend: {
                            reversed: true
                        },
                        plotOptions: {
                            series: {
                                stacking: 'normal'
                            }
                        },
                        series: scope.seriesData
                    });
                }

            }
        }
    }]);
