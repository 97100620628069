/*
    staticFileService
    
    For fetching static files that are uploaded to the baseurl container
*/    

const baseUrl = "https://digileanfiles.blob.core.windows.net/public-assets/"
const imagesFolder = "images/"
const previewImagesFolder = "preview-images/"
const diagramTemplatesFolder = "diagram-templates/"

const getFile = (folder: string, filename: string) => {
    return `${baseUrl}${folder}${filename}`
}
export const image = (filename: string) => {
    return getFile(imagesFolder, filename)
}
export const previewImage = (filename: string) => {
    return getFile(previewImagesFolder, filename)
}
export const diagramTemplate = (filename: string) => {
    return getFile(diagramTemplatesFolder, filename)
}

