
angular
    .module('DigiLean')
    .directive("projectMessageAdded",
        function () {
            return {
                templateUrl: 'projectMessageAdded.html',
                restrict: 'E',
                scope: {
                    "payLoad": "<",
                },
                link: function (scope, elem, attrs) {
                    scope.projectId = null;
                    scope.projectName  = null;
                    scope.message = "";
                    scope.isLoading = true;
                    scope.users = [];
                    scope.$watch('payLoad', function (payLoad) {
                        if (!payLoad) return;
                        scope.projectId = payLoad.ProjectId;
                        scope.projectName = payLoad.Title;
                        scope.message = payLoad.Text;
                    });

                }
            }
        });