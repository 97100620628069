angular
    .module('DigiLean')
    .directive("projectNameCell", ['$filter', '$translate','projectService',
        function ($filter, $translate, projectService) {
            return {
                templateUrl: 'projectNameCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.projectName = "";
                    scope.active = false;

                    scope.$watch('model', function (model) {
                        scope.projectName = model.project.name;
                        model.getSortValue = function() {
                            return scope.projectName;
                        }
                    });

                    scope.subscribe("ProjectNameUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            if (project.name) {
                                scope.projectName = project.name;
                            } else {
                                scope.projectName = "";
                            }
                        }
                    });

                    scope.setActive = function() {
                        scope.active = true;   
                        setTimeout(function() {
                            $(".project-name").focus();
                        }, 50 );                        
                    }
                    scope.nameChanged = function(projectName) {
                        scope.active = false;  
                        if(projectName !== scope.projectName){
                            projectService().updateProjectName(scope.model.project.id, projectName).then(function (result) {                         
                            });
                        }

                    }
                }
            }
        }]);
