var DigiLean = angular.module("DigiLean")
DigiLean.component("feedbackStatus", 
{
    templateUrl: "feedbackstatus.html",
    bindings: {
        'status': '<',
    },
    controller: [function(suggestionService) {
        let $ctrl = this
        $ctrl.status = ""

        // $ctrl.$onInit = function() {
        //     console.log(`status=${$ctrl.status}`)
        // }
    }]
})