angular
    .module('DigiLean')
    .directive("strategyStartDateCell", ['strategyGoalService',
        function (strategyGoalService) {
            return {
                templateUrl: 'strategyStartDateCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.color = "#0099DE";
                    scope.dateMissed = false;
                    scope.date = null;
                    scope.value = null;
                    

                    scope.updateStartDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.value = date
                        strategyGoalService().updateStartDate(scope.model.goal.id, scope.value)
                    }                   

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.goal) {
                            if (scope.model.goal.startDate) {
                                setValue(scope.model.goal.startDate);
                            } 
                            model.getSortValue = function() {
                                if (scope.date) {
                                    return scope.date;
                                }
                                return null;
                            }
                        }
                    });

                    var setValue = function(startDate) {
                        if(startDate){
                            scope.value = moment(startDate).toDate();
                        }
                    }
                 

                    scope.subscribe("StrategyGoalStartDateUpdated", function (goal) {
                        if (goal.id == scope.model.goal.id) {
                            setValue(goal.startDate);
                        }
                    });
                    
                }
            }
        }]);
