import type { WidgetSettings } from "@common/model/types"
import angular from "angular"
import { calcDefaultAppFontSize, getDefaultAppTheme, getDefaultAppStyle } from "@common/components/dashboard/designer/common/appStyleService"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module("DigiLean")

DigiLean.component("digileanDiagramApp", 
{
    bindings: {
        'isAdminMode': '<',
        'settings': '<',
        'layoutFactor': '<'
    },
    templateUrl: "DigileanDiagramApp.html",
    controller: ['$element', '$scope', function(this: NgComponent, $element, $scope) {
        const $ctrl = this
        
        $ctrl.title = "Diagram"
        $ctrl.titleChangedEvent = function($event: JQuery.Event) {
            $ctrl.title = $event.detail
            saveSettings()
        }
        $ctrl.svg = ""

        $ctrl.theme = getDefaultAppTheme()
        $ctrl.editInViewMode = false

        $ctrl.appStyle = getDefaultAppStyle()
        function calcStyles() {
            $ctrl.appStyle["font-size"] = calcDefaultAppFontSize($ctrl.layoutFactor)
        }
        calcStyles()
        
        $ctrl.showTemplates = false
        let diagramEl = null

        function setSvg(svg: string) {
            $ctrl.svg = svg
        }
    
        function getCurrentSvgFromSettings() {
            const svg = $ctrl.settings.content
            setSvg(svg)
        }

        $ctrl.toggledEditViewMode = function() {
            saveSettings()
        }

        $ctrl.$onInit = function() {
            setSettingsInitially()
        }
        $ctrl.$postLink = function() {
            let el = $element[0]
            diagramEl = el.querySelector("digilean-diagram")
            setTimeout(() => {
                resizeSvg()
            }, 500)
        }
        const setSettingsInitially = () => {
            if ($ctrl.settings) {
                const settingsNow = $ctrl.settings as WidgetSettings
                if (settingsNow.content)
                    getCurrentSvgFromSettings()
                
                if (settingsNow.title)
                    $ctrl.title = settingsNow.title
                
                if (settingsNow.theme)
                    $ctrl.theme = settingsNow.theme
                
                if (settingsNow.editInViewMode)
                    $ctrl.editInViewMode = settingsNow.editInViewMode
            }
        }

        function saveSettings() {
            let settings: WidgetSettings = {
                title: $ctrl.title,
                content: $ctrl.svg,
                editInViewMode: $ctrl.editInViewMode,
                theme: $ctrl.theme
            }
            dispatchCustomEvent("settings-changed", { settings })
        }
        $ctrl.saveSettings = saveSettings
        function dispatchCustomEvent(name:string, detail: any) {
            const options = {
                detail,
                bubbles: true,
                composed: true
            }
            $element[0].dispatchEvent(new CustomEvent(name, options))
        }

        
        $ctrl.isEditPossible = function () {
            return $ctrl.editInViewMode || $ctrl.isAdminMode
        }
        

        const openTemplateDialog = function () {
            ///@ts-ignore
            diagramEl?.triggerTemplate()
        }
        $ctrl.template = openTemplateDialog

        $ctrl.themeChanged = function(event) {
            const orgEvt = event.originalEvent
            const theme = orgEvt.detail
            $ctrl.theme = theme
            saveSettings()
        }
        $ctrl.svgChanged = function(event) {
            const orgEvt = event.originalEvent
            const svg = orgEvt.detail
            $ctrl.svg = svg
            saveSettings()
        }
        $ctrl.$onChanges = function (changes) {
            if (changes.layoutFactor && changes.layoutFactor.currentValue)
                calcStyles()
        }

        $ctrl.height = 0
        $ctrl.width = 0
        function resizeSvg() {
            if (!$element) return
            let appEl = $element[0]

            let width = appEl.offsetWidth
            $ctrl.width = width
            let height = appEl.offsetHeight

            let headerEl = appEl.querySelector(".app-header")
            if (headerEl)
                height = height - headerEl.offsetHeight
            
            $ctrl.height = height
        }

        $scope.$on("widget-resized", function () {
            resizeSvg()
        })
    }]
})