angular
    .module("DigiLean")
    .directive('datasourceTrendReport', ['dataSourceService',
        function (dataSourceService, ) {
            return {
                templateUrl: 'datasourceTrendReport.html',
                restrict: 'E',
                scope: {
                    'datasourceId': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.isLoaded = false;
                    dataSourceService().get(scope.datasourceId).then(function(dataSource) {
                       scope.dataSource = dataSource; 
                       // TODO: do smart things based on how the datasource is setup.
                       
                       loadData();
                       scope.isLoaded = true;
                    });

                    function loadData() {
                        scope.thisMonthSettings = {
                            title: "",
                            timePeriod: {
                                timeframe: "month"
                            },
                            dataSource: {
                                id: scope.datasourceId,
                                valueElement: {
                                    unit: scope.dataSource.valueElement.unit
                                }
                            },
                            decimals: 1
                        }

                        scope.thisYearSettings = {
                            title: "",
                            timePeriod: {
                                timeframe: "year"
                            },
                            dataSource: {
                                id: scope.datasourceId,
                                valueElement: {
                                    unit: scope.dataSource.valueElement.unit
                                }
                            },
                            decimals: 1
                        }

                        scope.thisYearMultiSettings = {
                            title: "",
                            timePeriod: {
                                timeframe: "year"
                            },
                            dataSeries: [{
                                datasource: {
                                    id: scope.datasourceId,
                                    manual: false,
                                    valueElement: {
                                        unit: scope.dataSource.valueElement.unit
                                    }
                                },
                                name: scope.dataSource.title,
                                graphType: "spline",
                                graphcolor: "#0099DE",
                                formula: "sum",
                                accumulated: false
                            }],
                            selectedGrouping: "day"
                        }
                    }
                }
            }
        }]);

