angular
    .module('DigiLean')
    .component("message", {
        templateUrl: 'message.html',
        bindings: {
            msg: '<'
        },
        controller: ['$state',
        function ($state) {
            const $ctrl = this

            $ctrl.goTo = function (msg) {
                if(msg.projectId != null){
                    $state.go('projectDetails', { id: msg.projectId }, { reload: false })
                } else if (msg.boardId != null){
                    $state.go('board', { id: msg.boardId }, { reload: false })
                }
            }
        }]
    })