angular
    .module('DigiLean')
    .directive("dailyKpiDynamic", ['kpiLetterBaseDirective',
        function (kpiLetterBaseDirective) {
            var dailyKpiDynamicDirective = {
                name: 'dailyKpiDynamic',
                templateUrl: 'dailyKpiDynamic.html',
                restrict: 'EA',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                }
            };

            dailyKpiDynamicDirective.compile = function (element, attrs) {
                return {
                    post: function (scope, element, attrs) {
                        kpiLetterBaseDirective[0].link(scope, element, attrs);
                        scope.visualizationType = "DYNAMIC";              
                    }
                };
            };

            return dailyKpiDynamicDirective;
        }]);
