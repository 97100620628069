﻿angular.module("DigiLean").service('projectBoardService', ['$translate', '$compile', 'profileImageService',
    function ($translate, $compile, profileImageService) {
        function projectBoardService() {
            return {

                getStandardProjectColumns: function () {

                    var columns = [
                        {
                            name: $translate.instant("PROJECT_NAME"),
                            sortOrder: 0,
                            attributeType: "projectName"
                        },
                        {
                            name: $translate.instant("PROJECT_NUMBER"),
                            sortOrder: 1,
                            attributeType: "projectNumber"
                        },
                        {
                            name: $translate.instant("PROJECT_CUSTOMER"),
                            sortOrder: 2,
                            attributeType: "customer"
                        },
                        {
                            name: $translate.instant("COMMON_RESPONSIBLE"),
                            sortOrder: 3,
                            attributeType: "owner"
                        },
                        {
                            name: $translate.instant("PROJECT_START_DATE"),
                            sortOrder: 4,
                            attributeType: "startdate"
                        },
                        {
                            name: $translate.instant("COMMON_END_DATE"),
                            sortOrder: 5,
                            attributeType: "enddate"
                        }
                    ]
                    return columns;
                },

                getStandardSmartTableColumns: function () {

                    var columns = [
                        {
                            name: $translate.instant("COMMON_DESCRIPTION"),
                            sortOrder: 0,
                            attributeType: "name"
                        }
                    ]
                    return columns;
                },

            }
        }
        return projectBoardService;
    }]);

