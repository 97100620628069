// Controller for the actual modal
var DigiLean = angular.module('DigiLean');  DigiLean.controller('likesController', ['$scope', '$uibModalInstance', 'suggestionService', 'suggestion',
    function ($scope, $uibModalInstance, suggestionService, suggestion) {
        
        $scope.suggestion = suggestion;
        $scope.likes = [];

        suggestionService().getLikesForSuggestion(suggestion.id).then(function(likes) {
            $scope.likes = likes;
        });
    
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);