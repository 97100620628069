angular
.module('DigiLean')
.controller('learningMaterialStatsController', ['$scope', '$stateParams', 'learningService', '$uibModal',
    function ($scope, $stateParams, learningService, $uibModal) {
        $scope.id = parseInt($stateParams.id)
        $scope.pages = []
        $scope.items = []
        learningService().getLearningMaterialStats($scope.id).then(function (summary) {
            $scope.material = summary.material
            $scope.items = summary.items 
            var totalVisitsLevels = calculateLevels(summary.totalVisitsRange, 4)
            var uniqueUsersLevels = calculateLevels(summary.uniqueUsersRange, 4)
            var likesLevel = calculateLevels(summary.likesRange, 4)
            var dislikeLevel = calculateLevels(summary.dislikesRange, 4)
            var numberOfCommentsLevels = calculateLevels(summary.numberOfCommentsRange, 4)

            $scope.items.forEach(item => {
                item.totalVisitsLevel = getLevel(item.totalVisits, totalVisitsLevels)
                item.uniqueUsersLevel = getLevel(item.uniqueUsers, uniqueUsersLevels)
                item.likesLevel = getLevel(item.likes, likesLevel)
                item.dislikesLevel = getLevel(item.dislikes, dislikeLevel)
                item.numberOfCommentsLevel = getLevel(item.numberOfComments, numberOfCommentsLevels)
            })
        })

        function calculateLevels(range, numberOfLevels) {
            var rangeSize = range.max - range.min
            var levelSize = rangeSize / numberOfLevels
            var levels = []
            var levelStart = range.min
            for (let index = 1; index <= numberOfLevels; index++) {
                var level = {
                    start: levelStart,
                    end: levelStart + levelSize
                }
                levels.push(level)
                levelStart = level.end
            }
            return levels
        }
        function getLevel(value, levels) {
            if (value == 0 || levels.length == 0) return "level-0"
            
            for (let index = 0; index <= levels.length; index++) {
                var level = levels[index]
                if (value >= level.start && value <= level.end) {
                    return "level-" + (index + 1)
                }
            }
            return "level-0"
        }

        $scope.openComments = function (item) {

            var modalInstance = $uibModal.open({ backdrop: 'static',
                templateUrl: 'learningPageCommentModal.html',
                controller: 'learningPageCommentController',
                windowClass: 'fullDeviation-modal-window',
                resolve: {
                    selectedPage: item,
                    material: $scope.material
                }
            })
            modalInstance.result.then(() => {}, () => {})
        }
        
    }])