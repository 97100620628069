﻿import * as boardService from "@common/services/boardService"
// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('newboardtemplateController', ['$scope', '$uibModalInstance', 'board',
    function ($scope, $uibModalInstance, board) {
        $scope.title = board.name;
        $scope.description = board.description;

        $scope.includeTasks = false;
        $scope.canIncludeTasks = false;
        if (board.boardType && (board.boardType == "SmartActionList" || board.boardType == "Kanban" || board.boardType == "Yearly")) {
            $scope.includeTasks = true;
            $scope.canIncludeTasks = true;
        }
        $scope.isProcessing = false;
        $scope.ok = function () {
            $scope.isProcessing = true;
            var options = {
                name: $scope.title,
                description: $scope.description,
                includeTasks: $scope.includeTasks
            };
            boardService.createTemplate(board.id, options).then(function (template) {
                $scope.isProcessing = false;
                $scope.publish("SendBoardTemplateCreated", template);
                $uibModalInstance.close(template);
            });
        };

        $scope.toggleIncludeTasks = function (include) {
            $scope.includeTasks = include;
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);