import { getAuthUser } from "@common/stores/userStore"
import * as timeService from "@common/services/timeService"

angular.module('DigiLean').directive("projectMessages", ['projectMessageService','$translate', 'modalService',
    function (projectMessageService, $translate, modalService) {
        return {
            templateUrl: 'projectMessages.html',
            restrict: 'E',
            scope: {
                'projectId': '<',
                'isEditMode': '<',
            },
            link: function (scope, elem, attrs) {

                const authUser = getAuthUser()
                scope.messageText = ""
                scope.showNewMessage = false;
                scope.isLoading = true;
                scope.showAddMessage = function() {
                    scope.showNewMessage = true;
                }
                scope.addMessage = function() {
                    var message = {
                        text: scope.messageText,
                        projectId: scope.projectId
                    }
                    scope.showNewMessage = false;
                    //scope.isLoading = true;
                    projectMessageService.addMessage(scope.projectId, message).then(function(message){
                        scope.messageText = "";
                        /*scope.isLoading = false;
                        scope.messages.unshift(message);
                        calculateTimeElapsed();*/
                    });
                }
                scope.editMessage = function(msg) {
                    msg.isEditing = true
                }
                scope.saveMessage = function(msg) {
                    projectMessageService.editMessage(scope.projectId, msg).then(data => {
                        msg.isEditing = false
                    })
                }
                scope.deleteMessage = function(messageId) {
                    // ADMIN_MESSAGE_DELETE_CONFIRMATION
                    $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'COMMON_MESSAGE', 'ADMIN_MESSAGE_DELETE_CONFIRMATION']).then(function (translations) {
                        var modalOptions = {
                            closeButtonText: translations.COMMON_CANCEL,
                            actionButtonText: translations.COMMON_DELETE,
                            headerText: translations.COMMON_DELETE + ' ' + translations.COMMON_MESSAGE + '?',
                            bodyText: translations.ADMIN_MESSAGE_DELETE_CONFIRMATION 
                        };
        
                        modalService.showModal({}, modalOptions).then(function (result) {
                            projectMessageService.deleteMessage(scope.projectId, messageId).then(function (data) {  
                                const newMessageList = scope.messages.filter(m => m.id !== messageId)
                                scope.messages = newMessageList
                            });
                        });
                    });
                }

                function getMessages() {
                    scope.isLoading = true;
                    projectMessageService.getMessages(scope.projectId).then(function(messages){
                        scope.isLoading = false;
                        messages.forEach(message => {
                            // replace linebreaks
                            message.text = message.text.replace(/\r\n|\r|\n/g, "<br />");
                        });
                        scope.messages = messages; 
                        scope.isLoading = false;
                        calculateTimeElapsed();
                    })
                }

                scope.$watch('projectId', function (projectId) {
                    // apply
                    if (!projectId) return;
                    getMessages()
                });

                scope.canUserChange = function(msg) {
                    if (msg.senderUserId == authUser.userId)
                        return true
                    if (authUser.roles && Array.isArray(authUser.roles) && authUser.roles.includes("Admin"))
                        return true
                    return false
                }
                
                var translateOptions = {
                    month: $translate.instant('COMMON_TIMEELAPSED_MONTH'),
                    days: $translate.instant('COMMON_TIMEELAPSED_DAYS'),
                    hours: $translate.instant('COMMON_TIMEELAPSED_HOURS'),
                    mins: $translate.instant('COMMON_TIMEELAPSED_MINUTES'),
                    seconds: $translate.instant('COMMON_TIMEELAPSED_SECONDS'),
                    now: $translate.instant('COMMON_TIMEELAPSED_NOW')
                }
                var calculateTimeElapsed = function () {
                    // Recalculate time elapsed
                    scope.messages.forEach(function (message) {
                        message.timeElapsed = timeService.getTimeElapsed(message.sendTime, translateOptions);
                    });
                }

                scope.subscribe("ProjectMessageAdded", function () {
                    getMessages()
                });
                scope.subscribe("ProjectMessageEdited", function () {
                    getMessages()
                });
                scope.subscribe("ProjectMessageDeleted", function () {
                    getMessages()
                });
                scope.$on("$destroy", function () {
                    
                });
            }

        }
    }]);
