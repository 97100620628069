﻿var DigiLean = angular.module('DigiLean');
DigiLean.controller('deviationTypeDetailsController', ['$scope', '$stateParams', '$filter', '$translate', 'deviationService',
    function ($scope, $stateParams, $filter, $translate, deviationService) {
        $scope.id = parseInt($stateParams.id);
        $scope.title = "";
        deviationService().getDeviationType($scope.id).then(function(deviationType) {
            $scope.title = deviationType.title;
        });
        $scope.steps = [];
        $scope.steps.push({ id: 'LAYOUT', title: $translate.instant("COMMON_LAYOUT") });
        $scope.steps.push({ id: 'CATEGORIES', title: $translate.instant("COMMON_CATEGORIES") });
        $scope.steps.push({ id: 'CONSEQUENCES', title: $translate.instant("DEVIATION_CONSEQUENCE") });
        $scope.steps.push({ id: 'CAUSES', title: $translate.instant("DEVIATION_CAUSES") });
        $scope.currentStep = $scope.steps[0];
        $scope.selectStep = function (step) {
            $scope.currentStep = step;
        }
    }]);