/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TeamsAccess {
    HasAccess = 'hasAccess',
    NewOrgNewUser = 'newOrgNewUser',
    ExistingOrgNewUser = 'existingOrgNewUser',
    ExistingOrgNoAutoSignup = 'existingOrgNoAutoSignup',
    WrongLicense = 'wrongLicense',
    DeletedUser = 'deletedUser',
}
