import Highcharts from 'highcharts';
$(function () {

    var colors = Highcharts.getOptions().colors;

    var firstlinecategories = ['', 'New', '', 'Impl.', ''];

    // Antall concat-kall avhenger av hvor mange måneder det finnes data for. Ergo den må lages dynamisk
    var xfirstlinecategories = firstlinecategories.concat(firstlinecategories).concat(firstlinecategories).concat(firstlinecategories).concat(firstlinecategories);
    var zeroes = [];
    xfirstlinecategories.forEach(function () { zeroes.push(0); });
    var options = {

        chart: {
            type: 'column'
        },

        title: {
            text: ''
        },

        credits: {
            enabled: false
        },
        xAxis: [

            {
                categories: xfirstlinecategories,
                labels: { autoRotation: false, style: { textOverflow: 'none' } },
                tickWidth: 0,
            },
            {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
                lineColor: '#ffffff',
                tickWidth: 1,
                tickLength: 30
            },
        ],

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: 'Number of suggestions'
            }
        },

        tooltip: {
            formatter: function () {

                return '<b>' + this.x + ' ' + this.series.options.stack + '</b><br>' +
                    this.series.name + ': ' + this.y + '<br>' +
                    'Total: ' + this.point.stackTotal;
            }
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                groupPadding: 0.20,
            }
        },

        series: [{
            name: 'Fysio- ergoterapi', /*'Human Resources',*/
            data: [5, 13, 14, 7, 12],
            color: '#0077B5', /*'#2A2E36',*/
            stack: 'New',
            xAxis: 1
        }, {
            name: 'Miljøtjenesten', /*'Resources & Technical', */
            data: [8, 8, 8, 8, 8],
            color: '#23c6c8', /*'#0099DE',*/
            stack: 'New',
            xAxis: 1
        }, {
            name: 'NAV',  /*'Operation & Engineering Services', */
            data: [22, 20, 6, 2, 1],
            color: '#2A2E36', /*'#23c6c8',*/
            stack: 'New',
            xAxis: 1
        }, {
            name: 'Psykisk helsearbeid', /*'HSEQ', */
            data: [13, 14, 8, 7, 12],
            color: '#5e5e5e', /*'#0077B5',*/
            stack: 'New',
            xAxis: 1
        }, {
            name: 'Sola sjukeheim', /*'Maintenance & Modifications',*/
            data: [3, 10, 8, 14, 13],
            color: '#2A2E36', /*'#5e5e5e',*/
            stack: 'New',
            xAxis: 1
        }, {
            name: 'Fysio- ergoterapi', /*'Human Resources', */
            data: [2, 3, 17, 5, 10],
            color: '#0077B5', /*'#2A2E36',*/
            showInLegend: false,
            stack: 'Impl.',
            xAxis: 1
        }, {
            name: 'Miljøtjenesten', /*'Resources & Technical', */
            data: [10, 0, 10, 12, 8],
            color: '#23c6c8', /*'#0099DE',*/
            showInLegend: false,
            stack: 'Impl.',
            xAxis: 1
        }, {
            name: 'NAV', /*'Operation & Engineering Services', */
            data: [5, 25, 6, 4, 3],
            color: '#2A2E36', /*'#23c6c8',*/
            showInLegend: false,
            stack: 'Impl.',
            xAxis: 1
        }, {
            name: 'Psykisk helsearbeid', /*'HSEQ', */
            data: [5, 10, 14, 4, 12],
            color: '#0077B5', /*'#0077B5',*/
            showInLegend: false,
            stack: 'Impl.',
            xAxis: 1
        }, {
            name: 'Sola sjukeheim', /*'Maintenance & Modifications', */
            data: [3, 10, 3, 10, 13],
            color: '#2A2E36', /*'#5e5e5e',*/
            showInLegend: false,
            stack: 'Impl.',
            xAxis: 1
        }, { // make the first x-aksis description show (New Impl. New Impl. etc)
            name: '',
            data: zeroes,
            showInLegend: false,
            stack: 'Impl.',

        }]
    };

    var onLegendClick = function (event) {
        var myname = this.name;
        var myvis = !this.visible;
        this.chart.series.forEach(function (elem) {
            if (elem.name == myname) {
                elem.setVisible(myvis);
            }
        });
        return false;
    }


    options.series.forEach(function (serie) {
        serie.events = { legendItemClick: onLegendClick };
    });

    if ($('#suggestionbyasset')[0])
        Highcharts.chart('suggestionbyasset', options);

});