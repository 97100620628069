import { DigiLeanNgScope } from "@common/model/angularModel"
import angular from "angular"
import toastr from "toastr"
import * as customerAdminService from "@common/services/customers/customerAdminService"
import { ApplicationUser, DeployCustomer, User } from "@api"
import { handleThrownErrorMsg } from "@common/services/error/aspNetErrorHandling"


interface IDeployScope extends DigiLeanNgScope {
    masterDbUsers: ApplicationUser[]
    customerDbUsers: User[]
    customer: DeployCustomer
    disable: () => void
    close: () => void
}

angular
    .module('DigiLean')
    .controller("disableCustomerDialogController", ['$scope', '$uibModalInstance', 'customer',
        function ($scope: IDeployScope, $uibModalInstance, customer: DeployCustomer) {
            
            $scope.customer = customer

            $scope.masterDbUsers = []
            $scope.customerDbUsers = []
            refreshUsers()

            $scope.disable = async function () {
                try {
                    await customerAdminService.disable(customer.id!)
                }
                catch(error: any) {
                    const msgs = handleThrownErrorMsg(error)
                    const msgsStr = msgs.join(",")
                    toastr.error(msgsStr)
                }
                $uibModalInstance.close()
            }

            $scope.close = function () {
                
                $uibModalInstance.dismiss('cancel')
            }
            
            $scope.disableUsers = async function() {
                try {
                    await customerAdminService.disableUsers(customer.id!)
                    refreshUsers()
                }
                catch (error: any) {
                    const msgs = handleThrownErrorMsg(error)
                    const msgsStr = msgs.join(",")
                    toastr.error(msgsStr)
                }
            }
            
            async function refreshUsers() {
                const mUsers = await customerAdminService.getMasterDbUsers(customer.id!)
                $scope.masterDbUsers = mUsers
                const cUsers = await customerAdminService.getcustomerDbUsers(customer.id!)
                $scope.customerDbUsers = cUsers
            }
        }])