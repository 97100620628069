import angular from "angular"
import * as customerService from "@common/services/customers/customerService"
import "./customerLookup.css"
import { NgComponent } from "@common/model/angularModel"
import { CustomerListModel } from "@api"

interface ICustomerLookup extends NgComponent {
    customers: CustomerListModel[]
}

angular.module('DigiLean')
    .component("customerLookup", {
        // bindings: {
        //     'file': '<',
        // },
        controller: ['$element', 
            function (this: ICustomerLookup, $element) {
                const $ctrl = this

                $ctrl.selectedCustomerText = "None selected"
                // $ctrl.$onDestroy = function() {}
                $ctrl.open = false

                $ctrl.toggleDropdown = function() {
                    $ctrl.open = !$ctrl.open
                }

                $ctrl.searchCustomer = ""
                $ctrl.customers = []
                $ctrl.$onInit = function() {
                    getCustomers()
                }
                async function getCustomers() {
                    $ctrl.customers = await customerService.getCustomersSimpleForAdmin()
                }
                $ctrl.selectCustomer = function(customer: CustomerListModel) {
                    $ctrl.selectedCustomerText = `${customer.name} (${customer.id})`
                    const options = {detail: customer, bubbles: false, composed: true}
                    $element[0].dispatchEvent(new CustomEvent("customer-changed", options))
                    $ctrl.toggleDropdown()
                }

                $ctrl.searchChange = function($event: any) {
                    const orgEvt = $event.originalEvent as Event
                    const val = (orgEvt.target as HTMLInputElement).value
                    $ctrl.searchCustomer = val
                }
            }
        ],
        templateUrl: 'customerLookup.html'
    })
