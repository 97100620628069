angular
    .module('DigiLean')
    .controller("registrationDialogController", ['$scope', '$uibModalInstance', 'componentSettings',
        function ($scope, $uibModalInstance, componentSettings) {

            $scope.isAdminMode = false;
            $scope.componentSettings = componentSettings;

        
            $scope.ok = function () {
                $uibModalInstance.close();
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }]);