import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("wheelImprovement", ['$translate', 'suggestionService',
        function ($translate, suggestionService) {
            return {
                templateUrl: 'wheelImprovement.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {

                    var grafcolor = ['#2A2E36', '#0099DE', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'],
                        graphtextcolor = '#ffffff';
                    scope.assets = [];
                    scope.selectedFilter = "All";

                    var totalSuggestion = '';
                    var planned = '';
                    var inProgress = '';
                    var implemented = '';
                    var evaluated = '';

                    var browserData = [],
                        categories = [],
                        data = [],
                        j,
                        i,
                        dataLen = 0,
                        drillDataLen,
                        brightness;

                    assetService.getList().then(function (assets) {


                        $translate(['COMMON_ALL', 'COMMON_MY_SUGGESTIONS']).then(function (translations) {
                            scope.assets = assets;
                            scope.assets.unshift({
                                name: translations.COMMON_MY_SUGGESTIONS,
                                id: -1
                            });
                            scope.assets.unshift({
                                name: translations.COMMON_ALL,
                                id: -2
                            });

                            scope.selectedFilter = translations.COMMON_ALL;
                        });
                    });

                    $translate(['IMPROVEMENT_TOTAL_SUGGESTIONS', 'IMPROVEMENT_PLANNED', 'COMMON_IN_PROGRESS', 'IMPROVEMENT_IMPLEMENTED', 'IMPROVEMENT_EVALUATED']).then(function (translations) {
                        totalSuggestion = translations.IMPROVEMENT_TOTAL_SUGGESTIONS;
                        planned = translations.IMPROVEMENT_PLANNED;
                        inProgress = translations.COMMON_IN_PROGRESS;
                        implemented = translations.IMPROVEMENT_IMPLEMENTED;
                        evaluated = translations.IMPROVEMENT_EVALUATED;

                        suggestionService().getNoImprovementPerStatus(null).then(function (statusdata) {
                            categories = [planned + ' #' + statusdata.planned,
                            inProgress + ' #' + statusdata.inProgress,
                            implemented + ' #' + statusdata.implemented,
                            evaluated + ' #' + statusdata.evaluated],

                                data = [{
                                    y: 25,
                                    color: grafcolor[6],
                                    drilldown: {
                                        name: 'Planned Assets',
                                        categories: ['Asset 1', 'Asset 2', 'Asset 3', 'Asset 4', 'Asset 5'],
                                        data: [5, 5, 5, 5, 5],
                                        color: grafcolor[6]
                                    }
                                }, {
                                    y: 25,
                                    color: grafcolor[1],
                                    drilldown: {
                                        name: inProgress,
                                        categories: ['Asset 1', 'Asset 2', 'Asset 3', 'Asset 4', 'Asset 5'],
                                        data: [4, 3, 2, 7, 9],
                                        color: grafcolor[1]
                                    }
                                }, {
                                    y: 25,
                                    color: grafcolor[2],
                                    drilldown: {
                                        name: implemented,
                                        categories: ['Asset 1', 'Asset 2', 'Asset 3', 'Asset 4', 'Asset 5'],
                                        data: [7, 7, 1, 5, 5],
                                        color: grafcolor[2]
                                    }
                                }, {
                                    y: 25,
                                    color: grafcolor[5],
                                    drilldown: {
                                        name: evaluated,
                                        categories: ['Asset 1', 'Asset 2', 'Asset 3', 'Asset 4', 'Asset 5'],
                                        data: [1, 9, 4, 6, 5],
                                        color: grafcolor[5]
                                    }
                                }],
                                browserData = [],
                                // versionsData = [],
                                i,
                                j,
                                dataLen = data.length,
                                drillDataLen,
                                brightness;

                            // Build the data arrays
                            for (var i = 0; i < dataLen; i += 1) {

                                // add browser data
                                browserData.push({
                                    name: categories[i],
                                    y: data[i].y,
                                    color: data[i].color
                                });
                            }

                            var index = $(elem).find(".wheel").data('highchartsChart');
                            var currentChart = Highcharts.charts[index];
                            currentChart.series[0].setData(browserData, true);
                        });

                        var wheel = $(elem).find(".wheel");
                        Highcharts.chart({
                            chart: {
                                renderTo: wheel[0],
                                type: 'pie'
                            },
                            title: {
                                text: ''
                            },
                            yAxis: {
                                title: {
                                    text: ''
                                }
                            },
                            credits: {
                                enabled: false
                            },
                            exporting: { enabled: false },

                            plotOptions: {
                                pie: {
                                    shadow: false,
                                    center: ['50%', '50%']
                                }
                            },
                            tooltip: { enabled: false },
                            series: [{
                                name: totalSuggestion,
                                data: browserData,
                                size: '100%',
                                dataLabels: {
                                    formatter: function () {
                                        return this.y > 5 ? this.point.name : null;
                                    },
                                    color: graphtextcolor,
                                    distance: -70,
                                    style: {
                                        fontSize: '14px'
                                    }
                                }
                            }]
                        });
                    });

                    scope.addFilterClickEvent = function (button) {
                        scope.selectedFilter = button.name;
                        if (button.id === -2) { //All
                            scope.filterByAsset(null);
                            
                        } else if (button.id === -1) { //My suggestions
                            scope.filterMyStatusImprovement();
                        } else {
                            scope.filterByAsset(button.id);
                        }
                    }

                    scope.filterMyStatusImprovement = function () {
                        suggestionService().getNoMyImprovementStatus().then(function (statusdata) {
                            var assetcategories = [planned + ' #' + statusdata.planned,
                            inProgress + ' #' + statusdata.inProgress,
                            implemented + ' #' + statusdata.implemented,
                            evaluated + ' #' + statusdata.evaluated];

                            var updatededBrowserData = [];

                            // Build the data arrays
                            for (var i = 0; i < dataLen; i += 1) {
                                // add new browser data
                                updatededBrowserData.push({
                                    name: assetcategories[i],
                                    y: data[i].y,
                                    color: data[i].color
                                });
                            }
                            var index = $(elem).find(".wheel").data('highchartsChart');
                            var currentChart = Highcharts.charts[index];
                            currentChart.series[0].setData(updatededBrowserData, true);
                        });
                    }

                    scope.filterByAsset = function (assetId) {
                        suggestionService().getNoImprovementPerStatus(assetId).then(function(statusdata) {
                            var assetcategories = [planned + ' #' + statusdata.planned,
                            inProgress + ' #' + statusdata.inProgress,
                            implemented + ' #' + statusdata.implemented,
                            evaluated + ' #' + statusdata.evaluated];

                            var updatededBrowserData = [];

                            // Build the data arrays
                            for (var i = 0; i < dataLen; i += 1) {
                                // add new browser data
                                updatededBrowserData.push({
                                    name: assetcategories[i],
                                    y: data[i].y,
                                    color: data[i].color
                                });
                            }

                            var index = $(elem).find(".wheel").data('highchartsChart');
                            var currentChart = Highcharts.charts[index];
                            currentChart.series[0].setData(updatededBrowserData, true);
                        });
                    }

                }
            }
        }]);
