angular
    .module('DigiLean')
    .directive("teamKanbanCard", ['$filter','$uibModal', '$state','taskDrawingService','boardTaskService','deviationService','suggestionService',
        function ($filter,$uibModal, $state, taskDrawingService, boardTaskService, deviationService, suggestionService) {
            return {
                templateUrl: 'teamKanbanCard.html',
                restrict: 'E',
                scope: {
                    'task': '<',
                    'users': '<?',
                    'column': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.postIts = taskDrawingService().getPostIts();
                    scope.$watch("users", function (users) {
                        if (!users) return;
                    });
                    scope.$watch("task", function (task) {
                        if (!task) return;
                        setColor(task.color);
                        // task.displayColor = "silver";
                    });
                    var setColor = function (taskColor) {
                        // Try to match fillColor
                        var predefinedPostIt = $filter('filter')(scope.postIts, { fillColor: taskColor });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                            setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                        } else {
                            // Try to match background color
                            var backgroundPostIt = $filter('filter')(scope.postIts, { background: taskColor });
                            if (backgroundPostIt.length > 0) {
                                scope.selectedPostIt = backgroundPostIt[0];
                                setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                            } else {
                                setStyle(scope.fillColor, scope.borderColor);
                            }
                        }
                    }

                    var setStyle = function (fillColor, borderColor) {
                            
                        if (fillColor == "#ffffff") {
                            borderColor = scope.borderColor;
                        }
                        
                        scope.task.displayColor = borderColor;
                    }

                    scope.openDeviation = function (task) {
                        var tab = "Info";
                        if(task.entityType == "BoardTask"){
                            tab = "Action";
                        }
                        deviationService().get(task.taskTypeId).then(function (deviation) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return tab;
                                    }
                                }
                            });
                        });
                    }

                    scope.openImprovementSuggestion = function (task) {
                        var tab = "Info";
                        if(task.entityType == "BoardTask"){
                            tab = "Action";
                        }

                        suggestionService().get(task.taskTypeId).then(function (suggestion) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return tab;
                                    }
                                }
                            });
                        });
                    }

                    scope.openTask = function () {
                        if(scope.task){
                            if(scope.task.taskType == 'Improvement'){
                                scope.openImprovementSuggestion(scope.task);
                            }else if(scope.task.taskType == 'Deviation'){
                                scope.openDeviation(scope.task);
                            } else if(scope.task.taskType == 'A3'){
                                $state.go('a3wizard', { id: scope.task.taskTypeId }, { reload: false });
                            }
                            else if(scope.task.taskType == 'SubTask'){
                                boardTaskService().get(scope.task.taskTypeId).then(function (task) {
                                    $uibModal.open({ backdrop: 'static',
                                        animation: true,
                                        templateUrl: 'boardTask.html',
                                        controller: 'boardTaskController',
                                        resolve: {
                                            task: function () {
                                                return task;
                                            },
                                            activeTab: function () {
                                                return "SubTasks";
                                            },
                                            options: function () {
                                                var options = {
                                                    archiveAvailable: true,
                                                    users: scope.users
                                                }
                                                return options;
                                            }
                                        }
                                    });
                                });
                            } else {
                                boardTaskService().get(scope.task.id).then(function (task) {
                                    $uibModal.open({ backdrop: 'static',
                                        animation: true,
                                        templateUrl: 'boardTask.html',
                                        controller: 'boardTaskController',
                                        resolve: {
                                            task: function () {
                                                return task;
                                            },
                                            activeTab: function () {
                                                return "Info";
                                            },
                                            options: function () {
                                                var options = {
                                                    archiveAvailable: true,
                                                    users: scope.users
                                                }
                                                return options;
                                            }
                                        }
                                    });
                                });
                            }
                        }
                        
                    }
                }
            }
        }]);