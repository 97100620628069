angular
    .module('DigiLean')
    .directive("a3LinkToPreviewCell", ['$state',
        function ($state,) {
            return {
                templateUrl: 'a3LinkToPreviewCell.html',
                restrict: 'E',
                scope: {
                    'model': '='
                },
                link: function (scope, elem, attrs) {
                    scope.a3Title = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.a3) {
                            if (scope.model.a3.title) {
                                scope.a3Title = scope.model.a3.title;
                            }
                        }
                        model.getSortValue = function () {
                            return scope.a3Title;
                        }
                    });


                    scope.goToA3 = function () {
                        //TODO must get latest version when a3 version is supported. Currently there are just one versjon of an A3
                        $state.go('a3PreviewDesigner', { id: scope.model.a3.id, version: 1}, { reload: false });
                    
                    }
                }
            }
        }]);