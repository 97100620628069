import { authUser } from "@common/stores/userStore"
import { getConnectionId } from "@common/services/systemHub"

angular.module('DigiLean').factory('authInterceptorService', ['$q', 'authService', 'errorHandling', 
    function ($q, authService, errorHandling) {

        var authInterceptorServiceFactory = {};
        let authData = {}
        let sub = authUser.subscribe(u => {
            authData = u
        })
        var _request = function (config) {

            config.headers = config.headers || {};
            
            if (authData && authData.token && !config.noAuth) {
                config.headers.Authorization = 'Bearer ' + authData.token;
            } else {
                //authService.login();
                console.log("not logged in");
            }

            const connectionId = getConnectionId()
            config.headers.Connectionid = connectionId
            return config;
        };

        var _responseError = function (rejection) {
            // manager.signinRedirect();
            if (rejection.status === 401) {
                return authService.renew();
            }

            if (rejection.status === 403 ) {
                errorHandling.handleForbidden(rejection)
            }

            if (!rejection.config) { // Fix of loadingbar issue with missing config.
                rejection.config = {
                    headers: {}
                };

            }
            return $q.reject(rejection);
        };

        authInterceptorServiceFactory.request = _request;
        authInterceptorServiceFactory.responseError = _responseError;

        return authInterceptorServiceFactory;
    }]);