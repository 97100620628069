angular
    .module('DigiLean')
    .directive("projectProgressTasksCell", ['$filter', '$translate','$uibModal','boardTaskService',
        function ($filter, $translate, $uibModal, boardTaskService) {
            return {
                templateUrl: 'projectProgressTasksCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    var taskId = null;

                    scope.options = [
                        { progress: 25, label: "20 %", color: "#0099DE"},
                        { progress: 50, label: "50 %", color: "#0099DE"},
                        { progress: 75, label: "75 %", color: "#0099DE"},
                        { progress: 100, label: "100 %", color: "#8CC152"},
                    ]

                    scope.customOption = {
                        progress: 0,
                        label: "0 %",
                        color: "0099DE"
                    };


                    var projectId = null;

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            if (scope.model.project.id) {
                                projectId = scope.model.project.id;
                                calculateProgress();
                            }
                        }
                        model.getSortValue = function() {
                            return scope.progress;
                        }
                    });

                    /*
                    scope.subscribe('TaskStatusUpdated', function (updatedstatus) {
                        if (scope.model.task.id == updatedstatus.taskId) {
                            scope.model.task.status = updatedstatus.status;
                            calculateProgress();
                        }
                    });

                    scope.subscribe("SubTaskCountChanged", function(count) {
                        if (count.taskId == taskId) {
                            scope.model.task.subTaskCount = count.subTaskCount;
                            scope.model.task.subTaskCompletedCount = count.subTaskCompletedCount;
                            calculateProgress();
                        }
                    });*/
              
                    scope.subscribe('TaskStatusUpdated', function (statustask) {
                        if (statustask.status  === 'OK') {
                            calculateProgress();
                        }
                    });

                    scope.subscribe('TaskDeleted', function (id) {
                        calculateProgress();
                    });
                    scope.subscribe('NewTask', function (task) {
                        calculateProgress();
                    }); 

                    function calculateProgress(){
                        boardTaskService().getNoProjectTaskPerStatus(projectId).then(function (statusdata) {
                            //scope.openTasks = statusdata.blank;
                            scope.taskCompletedCount = statusdata.ok;
                            //scope.noBlockedTasks = statusdata.unacceptable;
                            scope.taskCount = statusdata.blank + statusdata.ok + statusdata.unacceptable;
                            var progress = 0;
                            if(scope.taskCount > 0){
                                progress = Math.round(100 * statusdata.ok/scope.taskCount);
                            }
                                var progressOptions = {
                                    progress: progress,
                                    label: progress + " %",
                                    color: "0099DE"
                                };
                                scope.setProgress(progressOptions);
                        });

                    }
              
                    scope.openTasks = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'taskListForm.html',
                            controller: 'taskListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                projectId: function () {
                                    return projectId;
                                }
                            }
                        });
                    };
                                   
                    scope.setProgress = function(option) {
                        scope.progress = option.progress;
                        scope.customOption.progress = option.progress;
                        scope.color = option.color;
                        if (scope.progress == 100) { // Green color if 100
                            scope.color = "#8CC152";
                        }
                    }
                }
            }
        }]);
