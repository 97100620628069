import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .controller("forgotPasswordModalController", ['$scope', '$state', '$uibModalInstance', '$translate', 'user',
        function ($scope, $state, $uibModalInstance, $translate, user) {

            $scope.loginData = {
                userId: "",
                password: "",
                confirmPassword: "",
                code: ""
            };
            $scope.email = user.email;
            $scope.message = null;
            $scope.errormessage = null;
            $scope.errors = [];
        
            $scope.reset = function () {
                
                $scope.message = null;
                $scope.errormessage = null;
                customerService.createPasswordToken($scope.email).then(function (result) {
                    toastr.success($translate.instant('ADMIN_MESSAGE_RESET_PASSWORD'));
                    $scope.errormessage = null;
                    close();
                },
                function (response) {
                    if (response.status === 404){
                        $scope.errormessage = $translate.instant('ADMIN_USER_NOT_FOUND');
                    }
                    else{
                        $scope.message = response.statusText;
                    }
                });
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.standard);
            };

            $scope.close = close;
            function close() {
                $uibModalInstance.dismiss('cancel');
            }
        }]);
