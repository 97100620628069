
angular
    .module('DigiLean')
    .directive("deviationtypeSelector", ['$filter', 'deviationService', 
        function ($filter, deviationService) {
            return {
                templateUrl: 'deviationtypeselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
            link: function (scope, elem, attrs) {
                scope.includeAll = true;

                function getTypeList() {
                    deviationService().getAllDeviationTypes().then(function (types) {
                        scope.items = types;
                        applyFilter();         
                    });
                }
                

                scope.$watch('source', function () {
                    getTypeList();
                });


                function applyFilter(){
                    if (scope.filters && scope.items) {

                        var identifiers = [];
                        var selectedFilter = scope.filters[0];
                        if (selectedFilter && selectedFilter.items.length > 0) {

                            selectedFilter.items.forEach(item => {
                                identifiers.push(parseInt(item));
                            });
                            selectedFilter.items = identifiers;
                        }                     
                        for (var k = 0; k < scope.items.length; k++) {
                            var index = selectedFilter.items.indexOf(scope.items[k].id);
                            if (index > -1){
                                scope.items[k].include = true;
                            } else {
                                scope.items[k].include = false;
                            }                     
                        }

                        var selected = $filter('filter')(scope.items, { include: true });
                        if (selected.length === scope.items.length && selected.length === 0) {
                            scope.includeAll = true;
                        } else {
                            scope.includeAll = false;
                        }
                    } else if (scope.items) {
                        for (var i = 0; i < scope.items.length; i++) {
                            scope.items[i].include = true;                     
                        }
                    }
                }

                scope.deselectAll = function (include) {
                    for (var k = 0; k < scope.items.length; k++) {
                        scope.items[k].include = include;                     
                    }
                    scope.modifyFilter();
                };

                scope.modifyFilter = function(){
                    var selected = $filter('filter')(scope.items, { include: true });
                    if(selected.length === scope.items.length){
                        scope.includeAll = true;
                        scope.filters = null;
                    } else {
                        scope.includeAll = false;
                        var selectedItems = [];
                        for(var i=0; i < selected.length; i++){
                             selectedItems.push(selected[i].id);
                        }
                        scope.filters = [{
                            sourceColumn: scope.source,
                            operator: "InList",
                            items: selectedItems
                        }];
                    }
                    scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                };

            }
        }
    }]);