angular
.module('DigiLean')
.directive("inTrialInfo", ['$uibModal',
    function ($uibModal) {
        return {
            templateUrl: 'inTrialInfo.html',
            restrict: 'E',
            scope: {
                'isAdmin': '=',
            },
            link: function (scope, element, attrs) {
                /*
                scope.$watch("isAdmin", function () {
                    scope.isAdmin= scope.isAdmin; 
                });*/
            }
        }
    }]);
