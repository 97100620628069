import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .controller("goalDetailsController", ['$scope', '$translate','$stateParams', '$filter', '$uibModal','strategyService', 'authService', 'projectService','navigationService',
        function ($scope, $translate, $stateParams, $filter, $uibModal, strategyService, authService, projectService, navigationService) {

            $scope.isAdminMode = false;
            $scope.project = null;
            $scope.customers = null;
            $scope.owner = {};
            $scope.selectedStatusOption = {};

            //$scope.projectId = parseInt($stateParams.id);
            $scope.breakthroughGoalId = parseInt($stateParams.id);
            $scope.canEdit = false;
            $scope.titlehaserror = false;
            $scope.participants = [];

            $scope.color = null;
            $scope.subGoalsModel = null;

            var assetId = 0;
            if($stateParams.assetid){
                assetId = parseInt($stateParams.assetid); 
            }


            var timePeriod = timeService.getTimePeriod("all");
            

            $scope.selectAsset = null;
            $scope.selectedGoalId = null;
            var teamgoals = [];

            $scope.noImprovementImplemented = 0;
            $scope.noImprovementRemaining = 0;
            $scope.noImprovementDelayed = 0;

            $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
            $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");            

            $scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
                $scope.hasDeviationModule = navigationService().hasModule("DEVIATION");  
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("StrategyAdmin");
                if($scope.isAdmin){
                    $scope.canEdit = true;
                    $scope.selectAsset = null;
                }
            });
            
            if($scope.isAdmin){
                $scope.canEdit = true;
            }

            function getAssetList() {
                if($scope.isAdmin){
                    assetService.getAllAssets(true).then(function (assets) {
                        $scope.assets = assets;  
                        setPreseletedAsset();   
                        for (var k = 0; k < $scope.assets.length; k++) {
                            $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level;                     
                        }   
                    });
                } else {
                    assetService.getList().then(function (assets) {
                        $scope.assets = assets;  
                        if($scope.assets.length>0){
                            $scope.selectAsset = $scope.assets[0];
                            setPreseletedAsset();
                        }
                        for (var k = 0; k < $scope.assets.length; k++) {
                            $scope.assets[k].cssLevel = "level-" + $scope.assets[k].level; 
                                             
                        }   
                             
                    });
                }
                
                
            };
            getAssetList();

            function setPreseletedAsset(){
                if(assetId>0){ //Asset preselected
                    var accessToAsset = $filter('filter')($scope.assets, { id: assetId }, true);
                    if (accessToAsset.length > 0) {
                        $scope.selectAsset = accessToAsset[0];
                    }    
                }
            }
            
            //Kanbanboard
            $scope.options = {
                targetHeight: 800
            }

            get();

            $scope.setAsset = function (asset) {
                $scope.selectAsset = asset;
                setUpImprovementStatus();
            };

            function setUpImprovementStatus(){
                var assetId = null;
                if($scope.selectAsset){
                    assetId = $scope.selectAsset.id;
                }

                strategyService().getNoImprovementPerStrategyStatus($scope.goal.id, assetId).then(function (statusdata) {
                    $scope.noImprovementRemaining = statusdata.remaining;
                    $scope.noImprovementImplemented = statusdata.implemented;
                    $scope.noImprovementDelayed = statusdata.delayed;
                    var values=[$scope.noImprovementImplemented,$scope.noImprovementDelayed,$scope.noImprovementRemaining];
                    dmbChart(150,150,125,25,values,colors,labels,0);
                });
            }
            $scope.toplevel = function () {
                $scope.selectAsset = null;
                setUpImprovementStatus();
            };

            

            $scope.toggleEdit = function () {
                $scope.isAdminMode = !$scope.isAdminMode;
            };

            $scope.setImageViewMode = function () {
                $scope.editImage = false;
            };
            $scope.setImageEditMode = function () {
                $scope.editImage = true;
            };

            $scope.showImprovements = function () {
                var currentfilter = [];

                if($scope.selectAsset && $scope.selectAsset.id){
                    currentfilter.push({
                        sourceColumn: "assetId",
                        operator: "InList",
                        items: [$scope.selectAsset.id]
                    })
                    var allgoals = [$scope.goal.id];
                    /* Dersom vi alltid skal legge til breakthroughgoal for forslag registrert på gruppe og som har kobling til gruppemål, så
                    får vi duplikater dersom vi legger til teamgoals til filteret
                    for(var i=0; i<teamgoals.length; i++){
                        allgoals.push(teamgoals[i].id);
                    }*/
                    currentfilter.push({
                        sourceColumn: "strategyGoalId",
                        operator: "InList",
                        items: allgoals
    
                    })
                } else {
                    currentfilter.push({
                        sourceColumn: "strategyGoalId",
                        operator: "InList",
                        items: [$scope.goal.id]
    
                    })
                }
                var order = [{
                    sourceColumn: "Status",
                    SortDirection: "DESC"
                }, {
                    sourceColumn: "PriorityStatus",
                    SortDirection: "DESC"
                }];
                var options = {
                    filters: currentfilter,
                    sortOrders: order
                };

                var modalInstance = $uibModal.open({ backdrop: 'static',
                    templateUrl: 'suggestionListForm.html',
                    controller: 'suggestionListModalController',
                    windowClass: 'fullDeviation-modal-window',
                    resolve: {
                        options: function () {
                            return options;
                        }
                    }
                });
            };


            function get() {
                strategyService().getBreakthroughObjectives().then(function (goals) {
                    var breakthroughgoals = $filter('filter')(goals, { id: $scope.breakthroughGoalId }, true);
                    if (breakthroughgoals.length > 0) {
                        $scope.projectId =  breakthroughgoals[0].projectId;
                    }
                    $scope.color = breakthroughgoals[0].color;
                    $scope.goal = breakthroughgoals[0];
                    $scope.selectedGoalId = $scope.breakthroughGoalId;
                    setUpImprovementStatus();
                    strategyService().getSubGoalsBoard($scope.goal.id).then(function (board) {
                        $scope.subGoalsModel = board;
                        $scope.subGoalsModel.goal = $scope.goal;
                    });
                    
                    setProject();
                });
            }

            function setProject(){
                
                projectService().getProjectInfo($scope.projectId).then(function (project) {

                    $scope.project = project;

                    if(!$scope.canEdit){
                        var userId = authService.getCurrentUser().userId;
                        if($scope.project.ownerUserId == userId){
                            //project owner may edit
                            $scope.canEdit = true;
                        }
                    }
                      /*  
                    $scope.imageId = $scope.project.imageId;
                    $scope.imageUrl = $scope.project.imagePreviewUrl;
                    if (!$scope.imageUrl) {
                        $scope.editImage = true;
                    }

                    $scope.statusOptions = projectService().getStatus();
                    $scope.selectedStatusOption = $filter('filter')($scope.statusOptions, { dbCode: $scope.project.status }, true)[0];

                    */
                });
            }

         
            var canvas=document.getElementById("canvas");
var ctx=canvas.getContext("2d");

var colors= ['#8cc152','#e9573f','gray'];//['skyblue','gray','orange'];
//var values=[47,6,47];
var suggestionTranslated = $translate.instant('COMMON_SUGGESTIONS');

var labels=[suggestionTranslated,'Robot','Mandatory'];



function dmbChart(cx,cy,radius,arcwidth,values,colors,labels,selectedValue){
    var tot=0;
    var accum=0;
    var PI=Math.PI;
    var PI2=PI*2;
    var offset=-PI/2;
    ctx.lineWidth=arcwidth;
    for(var i=0;i<values.length;i++){tot+=values[i];}
    for(var i=0;i<values.length;i++){
        ctx.beginPath();
        ctx.arc(cx,cy,radius,
            offset+PI2*(accum/tot),
            offset+PI2*((accum+values[i])/tot)
        );
        ctx.strokeStyle=colors[i];
        ctx.stroke();
        accum+=values[i];
    }
    var innerRadius=radius-arcwidth-3;
    ctx.beginPath();
    ctx.arc(cx,cy,innerRadius,0,PI2);
    ctx.fillStyle=colors[selectedValue];
    ctx.fill();
    ctx.textAlign='center';
    ctx.textBaseline='bottom';
    ctx.fillStyle='white';
    ctx.font=(innerRadius)+'px verdana';
    ctx.fillText(tot,cx,cy+innerRadius*.9);
    ctx.font=(innerRadius/4)+'px verdana';
    ctx.fillText(labels[selectedValue],cx,cy-innerRadius*.25);
}

        }]);
