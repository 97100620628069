angular
    .module('DigiLean')
    .directive("teammember", ['$filter', '$uibModal', 'boardTaskService', 'navigationService', 'deviationService', 'suggestionService',
        function ($filter, $uibModal, boardTaskService, navigationService, deviationService, suggestionService) {
            return {
                templateUrl: 'teammember.html',
                restrict: 'E',
                scope: {
                    'user': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.taskCount = 0;
                    scope.taskOverdueCount = 0;
                    scope.taskTodayCount = 0;
                    scope.taskFutureCount = 0;
                    scope.improvementCount = 0;
                    scope.improvementOverdueCount = 0;
                    scope.improvementTodayCount = 0;
                    scope.improvementFutureCount = 0;
                    scope.deviationCount = 0;
                    scope.deviationOverdueCount = 0;
                    scope.deviationTodayCount = 0;
                    scope.deviationFutureCount = 0;
                    scope.a3Count = 0;
                    scope.a3OverdueCount = 0;
                    scope.a3TodayCount = 0;
                    scope.a3FutureCount = 0;

                    scope.hasBoard = navigationService().hasModule("BOARD");
                    scope.hasImprovement = navigationService().hasModule("IMPROVEMENT");
                    scope.hasDeviation = navigationService().hasModule("DEVIATION");
                    scope.hasA3 = navigationService().hasModule("A3");

                    scope.$watch('user', function (user) {
                        // apply
                        if (user) {
                            getUserTasks(user.userId);
                        }
                    });

                    scope.subscribe("UserAuthenticatedAndReady", function(profile)
                    {
                        scope.hasBoard = navigationService().hasModule("BOARD");
                        scope.hasImprovement = navigationService().hasModule("IMPROVEMENT");
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");
                        scope.hasA3 = navigationService().hasModule("A3");
                        //getUserTasks(profile.user.id);  
                    });

                    var colorClasses = ["label-primary", "label-warning-light"];
                    function getUserTasks(userId) {
                        boardTaskService().getUserTasks(userId).then(function (data) {
                            data.overdue.forEach(function (element, index, array) {
                                if(element.taskType === 'Board'){
                                    scope.taskOverdueCount = scope.taskOverdueCount + 1;
                                } else if (element.taskType === 'Improvement'){
                                    scope.improvementOverdueCount = scope.improvementOverdueCount + 1;
                                } else if (element.taskType === 'Deviation'){
                                    scope.deviationOverdueCount = scope.deviationOverdueCount + 1;
                                } else if (element.taskType === 'A3'){
                                    scope.a3OverdueCount = scope.a3OverdueCount + 1;
                                }
                            });
                            data.today.forEach(function (element, index, array) {
                                if(element.taskType === 'Board'){
                                    scope.taskTodayCount = scope.taskTodayCount + 1;
                                } else if (element.taskType === 'Improvement'){
                                    scope.improvementTodayCount = scope.improvementTodayCount + 1;
                                } else if (element.taskType === 'Deviation'){
                                    scope.deviationTodayCount = scope.deviationTodayCount + 1;
                                } else if (element.taskType === 'A3'){
                                    scope.a3TodayCount = scope.a3TodayCount + 1;
                                }
                            });
                            data.future.forEach(function (element, index, array) {
                                if(element.taskType === 'Board'){
                                    scope.taskFutureCount = scope.taskFutureCount + 1;
                                } else if (element.taskType === 'Improvement'){
                                    scope.improvementFutureCount = scope.improvementFutureCount + 1;
                                } else if (element.taskType === 'Deviation'){
                                    scope.deviationFutureCount = scope.deviationFutureCount + 1;
                                } else if (element.taskType === 'A3'){
                                    scope.a3FutureCount = scope.a3FutureCount + 1;
                                }

                            });
                            scope.taskCount = scope.taskOverdueCount + scope.taskTodayCount + scope.taskFutureCount;
                            scope.improvementCount = scope.improvementOverdueCount + scope.improvementTodayCount + scope.improvementFutureCount;
                            scope.deviationCount = scope.deviationOverdueCount + scope.deviationTodayCount + scope.deviationFutureCount;
                            scope.a3Count = scope.a3OverdueCount + scope.a3TodayCount + scope.a3FutureCount;
                            

                            scope.workList = data;
                        });
                    }
                },

                
            }
        }]);


