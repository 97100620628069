﻿angular.module("DigiLean").factory('evaluationService', ['$http', 'webApiUrl',
function ($http, webApiUrl) {
    function evaluationService() {
        var resource = "/evaluation";
        var resourceUrl = webApiUrl + resource;
        return {
            getMyEvaluationForPage: function(isExternal, pageId) {   
                isExternal = !!isExternal
                const url = `${resourceUrl}/getMyEvaluationForPage/${pageId}?isExternal=${isExternal}`
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },

            getEvaluationsForPage: function(isExternal, pageId) {   
                isExternal = !!isExternal
                const url = `${resourceUrl}/GetEvaluationsForPage/${pageId}?isExternal=${isExternal}`
                return $http.get(url).then(function (result) {
                    return result.data
                })
            },
            evaluatePage: function (isExternal, pageId, evaluation) {
                isExternal = !!isExternal
                const url = `${resourceUrl}/EvaluatePage/${pageId}?isExternal=${isExternal}`
                return $http.put(url, evaluation).then(function (result) {
                    return result.data
                })
            },
            removeEvaluationFromPage: function (isExternal, pageId) {
                isExternal = !!isExternal
                const url = `${resourceUrl}/removeEvaluationFromPage/${pageId}?isExternal=${isExternal}`
                return $http.delete(url).then(function (result) {
                    return result.data
                })
            },
        }
    }
    return evaluationService;
}]);