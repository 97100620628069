// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('newDataSourceController', ['$scope', '$filter', '$state', '$uibModalInstance', '$translate', 'dataSourceService',
    function ($scope, $filter, $state, $uibModalInstance, $translate, dataSourceService) {
        $scope.unitsOfTime = [
            {
                db: "DAY",
                translated: $translate.instant('DASHBOARD_DAILY')
            },  {
                db: "WEEK",
                translated: $translate.instant('BOARD_WEEKLY')
            },{
                db: "MONTH",
                translated: $translate.instant('BOARD_MONTHLY')
            }, {
                db: "YEAR",
                translated: $translate.instant('COMMON_YEARLY')
            }
        ];
        $scope.selectedUnitOfTime = null;

        $scope.dataSource = {
            title: "",
            description: "",
            objectSource: "external",
            unitOfTime: "",
            valueElement: {
                label: "value",
                type: "number",
                unit: "",
                isMandatory: true
            },
            dateElement: {
                label: "date",
                type: "DAY",
                isMandatory: true
            }
        }

        $scope.setUnitOfTime = function (unitoftime) {
            if (unitoftime) {
                $scope.dataSource.unitOfTime = unitoftime.db;
            }
        }

        $scope.ok = function () {
            // Create dataSource
            dataSourceService().add($scope.dataSource).then(function (dataSource) {
                $scope.publish("SendDataSourceCreated", dataSource);
                $uibModalInstance.close();
                $state.go('admindatasource', { id: dataSource.id }, { reload: false });
            });

        }
        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);