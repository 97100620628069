angular
    .module('DigiLean')
    .component("htmlTextArea", {
        templateUrl: 'htmlTextArea.html',
        bindings: {
            text: '<',
            contentChangedHandler: '&'
        },
        controller: ['$element', '$translate', 'taskDrawingService',
            function ($element, $translate, taskDrawingService) {
                var $ctrl = this;
                $ctrl.initialized = false;

                $ctrl.froalaOptions = {
                    key: "CTD5xD3E1G2A1A8B2wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2C1F4F1F1==",
                    attribution: false,
                    toolbarButtons: ["bold", "italic", "underline", '|' ,'formatUL','formatOL', '|', "insertLink"],
                    pastePlain: false,
                    imagePaste: false,
                    toolbarSticky: false, // if needed, make this a setting that can be consumed by bindings.
                    toolbarInline: false,
                    placeholderText: $translate.instant('COMMON_DESCRIPTION'),
                    heightMin: 160,
                    zIndex:2501,
                    linkAlwaysBlank: true,
                    quickInsertTags: []
                };

                $ctrl.$onInit = function() {
                    $ctrl.editContent = $ctrl.text;
                    setTimeout(function () {
                        $ctrl.initialized = true; //prevent toolbar to be drawn top of the screen
                    }, 100);
                };

                $ctrl.contentChanged = function(val) {
                    $ctrl.contentChangedHandler({ content: val });
                    // if (!scope.froalaInit) {
                    //     scope.froalaInit = true;
                    //     return;
                    // }
                    // scope.model.content = val;
                    // scope.commitChangesDebounced(scope.model.id, val);
                };

                $ctrl.$onChanges = function (changes) {

                    // alert(changes);
                    // if (changes.text) {
                    //     if (!changes.text.currentValue && froalaEditor && froalaEditor.html)
                    //         froalaEditor.html.set("");
                    // }
                };

                $ctrl.$onDestroy = function() {
                    
                };
            }]
    });