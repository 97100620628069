import { forEach } from "angular";

angular
    .module('DigiLean')
    .directive("deviationListResult", ['$filter', '$translate', 'deviationService', '$uibModal', 'NgTableParams', 'excelService', 'pdfService', 'isTeamsMode',
        function ($filter, $translate, deviationService, $uibModal, NgTableParams, excelService, pdfService, isTeamsMode) {
            return {
                templateUrl: 'deviationListResult.html',
                restrict: 'E',
                scope: {
                    'filterParams': '<',
                    'options': '<',
                    'tableParamsChanged': '&?',
                    'columnListChanged': '&'
                },
                link: function (scope, elem, attrs) {

                    scope.columns = [];
                    scope.totalValues = 0;
                    scope.totalMatchingRecords = 0;
                    var isFilterLoaded = false;
                    var isOptionsLoaded = false;
                    var deviationTypesWithUserLanguage = [];
                    // default values
                    scope.sorting = [];
                    scope.pageCount = 25;
                    scope.tableSorting = { lastModified: "desc" };
                    // Options
                    scope.showFilter = false;
                    scope.showFileGenerators = false;
                    scope.showArchived = false;
                    scope.isTeamsMode = isTeamsMode;
                 

                    deviationService().getAllDeviationTypes().then(function (result) {
                        deviationTypesWithUserLanguage = result
                    });
                    

                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            return $filter('date')(value, "mediumDate");
                        }
                        if (this.dataType === "timestamp") {
                            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        }
                        return value;
                    }
                    scope.allColumns = deviationService().getAllColumns(renderValue);

                    scope.timePeriod = null;
                    scope.$watch('filterParams', function (params) {
                        if (!params) return;
                        isFilterLoaded = true;
                        if (!params.filter) {
                            scope.filter = [];
                        } else {
                            scope.filter = params.filter;
                        }
                        if (params.timePeriod) {
                            scope.timePeriod = params.timePeriod;
                        }
                        if (params.sorting) {
                            scope.tableSorting = params.sorting;
                        }
                        if (params.listColumns) {
                            params.listColumns.forEach((c) => {
                                var column = $filter('filter')(scope.allColumns, { field: c.field })[0];
                                if (column) {
                                    column.visible = c.visible;
                                }
                            })
                        }

                        if (isFilterLoaded) {
                            scope.getValues();
                        }
                    });
                    function getTotalCount() {
                        deviationService().getTotalNumberOfDeviations(scope.showArchived).then(function (count) {
                            scope.totalValues = count;
                        });
                    }


                    scope.$watch('options', function (options) {
                        if (!options) {
                            scope.showFileGenerators = true;
                            scope.showFilter = true;
                            scope.showArchived = true;
                        } else {
                            // Apply options
                            scope.showFileGenerators = options.showFileGenerators;
                            scope.showFilter = options.showFilter;
                            scope.showArchived = options.showArchived;
                        }
                        isOptionsLoaded = true;
                        getTotalCount();
                        scope.getValues();
                    });


                    scope.filterChanged = function (filter) {
                        scope.filter = filter
                        scope.getValues();
                    }

                    scope.openDeviation = function (deviation) {
                        deviationService().get(deviation.id).then(function (deviation) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                            modalInstance.result.then(function (updatedSuggestion) {
                                scope.tableParams.reload();
                            });
                        });

                    }
                    scope.exportToPDF = function () {
                        var columns = getColumns();
                        columns.push({ field: "id" })
                        var dbParams = {
                            page: 1,
                            count: 5000,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            columns: columns,
                            timePeriod: scope.timePeriod
                        }
                        pdfService().createDeviationListReport(dbParams);
                    }


                    scope.exportToExcel = function () {
                        var columns = getColumns();
                        columns.push({ field: "id" })
                        var dbParams = {
                            page: 1,
                            count: 5000,
                            sorting: scope.sorting,
                            filters: scope.filter,
                            columns: columns,
                            timePeriod: scope.timePeriod
                        }
                        excelService().exportDeviationValues(dbParams);
                    }


                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }


                    scope.getValues = function (params) {
                        if (!isOptionsLoaded || !isFilterLoaded) return;
                        scope.buildColumns();
                        scope.tableParams = new NgTableParams({
                            sorting: scope.tableSorting,
                            count: scope.pageCount,
                        },
                        {
                            getData: scope.getTableValues
                        });
                    }


                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        scope.sorting = sorting;
                        // Resolve paging;
                        if (scope.tableSorting != params._params.sorting) {
                            scope.tableSorting = params._params.sorting;
                            raiseTableParamsChanged();
                        }

                        // Resolve filter
                        let filters = []
                        if (scope.filter && Array.isArray(scope.filter)) {
                            // copy elements to local filter by spread operator ...
                            filters = [...scope.filter]
                        }

                        if (!scope.showArchived) {
                            const archivedFilter = {
                                sourceColumn: "D.archivedDate",
                                operator: "IsNull",
                                items: []
                            }
                            filters.push(archivedFilter)
                        }

                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            sorting: scope.sorting,
                            filters: filters,
                            timePeriod: scope.timePeriod
                        }
                        return deviationService().getTableValues(dbParams).then(function (data) {
                            scope.totalMatchingRecords = data.total;
                            params.total(data.total); // recal. page nav controls
                            // check values
                            data.values.forEach(value => {
                                value.isOverdue = isOverdue(value.dueDate, value.statusImplementedDate);
                                value.deviationType = getDeviationTypeTranslation(value);
                            });
                            return data.values;

                        });
                    }

                    function getDeviationTypeTranslation(value) {
                        if (!deviationTypesWithUserLanguage || deviationTypesWithUserLanguage.length == 0) return value.deviationType;
                        var deviationType = $filter('filter')(deviationTypesWithUserLanguage, { id: value.deviationTypeId }, true);
                        if (deviationType && deviationType.length > 0) {
                            return deviationType[0].title;
                        }
                        return value.deviationType;
                    }
                    var raiseTableParamsChanged = function () {
                        if (!scope.tableParamsChanged) return;
                        // Raise settings changeHandler
                        var tableParamsSettings = {
                            sorting: scope.tableSorting,
                            pageCount: scope.pageCount
                        }
                        scope.tableParamsChanged({ params: tableParamsSettings });

                    }

                    scope.buildColumns = function () {
                        scope.columns = $filter('filter')(scope.allColumns, { visible: true });
                    }

                    var getColumns = function () {
                        var columns = [];
                        scope.columns.forEach((c) => {
                            columns.push({
                                field: c.field
                            });
                        })
                        return columns;
                    }

                    scope.selectColumn = function (column) {
                        column.visible = !column.visible;
                        scope.columnListChanged({
                            listColumns: {
                                listColumns: scope.allColumns
                            }
                        });
                        scope.buildColumns();
                    }

                    function isOverdue(date, implementedDate) {
                        // Overdue check

                        if (!date) return false;
                        if (implementedDate) return false;
                        var duration = moment.duration(moment().diff(moment(date)));
                        var days = duration.asDays();
                        if (days > 1) {
                            return true;
                        }
                        return false;
                    }

                }

            }
        }]);
