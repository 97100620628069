﻿import { getUserProfile } from "@common/stores/userStore"

angular
    .module('DigiLean')
    .directive("a3stepActionPlanTemplate", ['$filter', '$translate', 'a3Service', 'pdfService',
        function ($filter, $translate, a3Service, pdfService) {
            return {
                templateUrl: 'a3stepActionPlan.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'model': '=',
                    'isAdminMode': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('model', function () {
                        var model = scope.model;
                        if (model.step.style && typeof (model.step.style) === "string") {
                            scope.style = JSON.parse(model.step.style);
                        }
                    });

                    scope.generatePreview = function () {
                        let lang = "en"
                        const up = getUserProfile()
                        if (up.preferredLanguage) {
                            lang = up.preferredLanguage
                        }
                        // Replace . with - so filename is not corrupt in browser.
                        var name = scope.model.title.replace(/\./g, '-') // Crazy stuff=>https://stackoverflow.com/questions/1967119/why-does-javascript-replace-only-first-instance-when-using-replace
                        pdfService().previewA3(name, scope.model.step.a3VersionId, lang);

                    };

                    scope.commitChanges = function (id, content) {
                        var stepContent = {
                            id: id,
                            content: content
                        }
                        a3Service().saveStep(stepContent).then(function (step) {
                            // Synk version step
                            $scope.generatePreviewDebounced();
                        });
                    };

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.commitChanges, 2000, false);
                    scope.generatePreviewDebounced = debounce(scope.generatePreview, 10000, false);
                    scope.contentChanged = function(val) {
                        if (!scope.froalaInit) {
                            scope.froalaInit = true;
                            return;
                        }
                        scope.model.step.content = val;
                        scope.commitChangesDebounced(scope.model.step.id, val);
                    };
                    scope.froalaOptions = {
                        toolbarButtons: ['bold', 'italic', 'underline', '|', 'fontFamily', 'fontSize', 'color',
                            'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink',
                            'insertImage', 'insertVideo', 'insertTable', '|', 'emoticons', 'insertHR', 'clearFormatting',
                            '|', 'help', '|', 'undo', 'redo']

                    };
                }
            };

        }]);
