﻿import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .directive("a3info", ['$filter', 
        function ($filter) {
            return {
                templateUrl: 'a3info.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'model': '='
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('model', function () {
                        var model = scope.model;
                        if (model.style && typeof (model.style) === "string") {
                            scope.style = JSON.parse(model.style);
                        }

                        if (model.assetId) {
                            // Load participants
                            customerService.getUsersForAsset(model.assetId).then(function (members) {
                                scope.participants = members;
                            });
                        }

                        customerService.getCurrent().then(function(customer) {
                            scope.logoUrl = customer.logoUrl;
                        });
                    });
                }
            }
        }]);
