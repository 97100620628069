angular
    .module('DigiLean')
    .directive("goalKanbanCard", ['$filter','$uibModal','taskDrawingService','boardTaskService',
        function ($filter,$uibModal, taskDrawingService, boardTaskService) {
            return {
                templateUrl: 'goalKanbanCard.html',
                restrict: 'E',
                scope: {
                    'task': '<',
                    'users': '<?',
                    'column': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.postIts = taskDrawingService().getPostIts();

                    scope.taskoptions = {
                        disableCopy:true
                    }
                    scope.$watch("users", function (users) {
                        if (!users) return;
                    });
                    scope.$watch("task", function (task) {
                        if (!task) return;
                        setColor(task.color);
                        setResponsible(task)
                        scope.model = {task:task};
                    });
                    var setColor = function (taskColor) {
                        // Try to match fillColor
                        var predefinedPostIt = $filter('filter')(scope.postIts, { fillColor: taskColor });
                        if (predefinedPostIt.length > 0) {
                            scope.selectedPostIt = predefinedPostIt[0];
                            setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                        } else {
                            // Try to match background color
                            var backgroundPostIt = $filter('filter')(scope.postIts, { background: taskColor });
                            if (backgroundPostIt.length > 0) {
                                scope.selectedPostIt = backgroundPostIt[0];
                                setStyle(scope.selectedPostIt.fillColor, scope.selectedPostIt.background)
                            } else {
                                setStyle(scope.fillColor, scope.borderColor);
                            }
                        }
                    }

                    function setResponsible(task) {
                        if (task.responsibleUserId && scope.users) {
                            var user = $filter('filter')(scope.users, { userId: task.responsibleUserId }, false);
                            if (user.length > 0) {
                                task.user = user[0];
                            }
                        }
                    }

                    scope.subscribe("TaskUpdated", function (task) {
                        if (scope.model.task.id == task.id) {
                            scope.model.task.color = task.color;
                            setColor(scope.model.task.color);
                            scope.model.task.title = task.title;
                            scope.model.task.animate("bounce");
                        }
                    });

                    scope.subscribe('TaskAssigned', function (status) {
                        if (scope.model.task.id == status.taskId) {
                            scope.model.task.responsibleUserId = status.userId;
                            scope.model.task.responsibleUserName = status.userName;
                            scope.model.task.responsibleUserDisplayName = status.userDisplayName;
                            scope.model.task.animate("bounce");
                        }
                    });
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        if (scope.model.task.id == status.taskId) {
                                scope.model.task.status = status.status;
                                if(status.status == 'OK'){
                                    scope.model.task.overdue = false;
                                }
                        }
                    });

                    var setStyle = function (fillColor, borderColor) {
                            
                        if (fillColor == "#ffffff") {
                            borderColor = scope.borderColor;
                        }
                        
                        scope.task.displayColor = borderColor;
                    }

                    scope.openCloneInfo = function(task) {
                       
                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'cloneInfo.html',
                            controller: 'cloneInfoController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    }
                    scope.openTask = function () {
                        boardTaskService().get(scope.task.id).then(function (task) {
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: true,
                                            users: scope.users
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }]);