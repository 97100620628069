
import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .controller("a3ParticipantModalController", ['$scope', '$uibModalInstance', 'a3Id',
        function ($scope, $uibModalInstance, a3Id) {

            $scope.a3Id = a3Id;
  
            $scope.close = function () {
                $uibModalInstance.close();
            };

            $scope.memberSelectionChanged = function (selection) {
                $scope.selectedAssetId = selection.assetId;
                $scope.participants = selection.participants;
                $scope.selectedAssetType = selection.assetType;
                setOwner();
                if ($scope.a3.assetId != selection.assetId) {
                    $scope.a3.assetId = selection.assetId;
                    $scope.saveA3Metadata();
                }

                if (!selection.assetId && !selection.assetId == 0 && selection.assetType != "CUSTOMIZED") return;
                if (selection.assetId == $scope.a3.assetId  && selection.assetType != "CUSTOMIZED") return;
                updateMembers();
            }

            var updateMembers = function() {
                $scope.isProcessing = true;

                // If new asset, we need to create it then set it to board.

                if ($scope.selectedAssetType == "CUSTOMIZED") {
                    var participants = [];
                    $scope.participants.forEach(p => {
                        var participant = {
                            userId: p.userId,
                            role: "Member"
                        }
                        participants.push(participant);
                    });
                    if ($scope.selectedAssetId == 0) {
                        var customAsset = {
                            name : $scope.name,
                            participants : participants
                        }
                        assetService.createCustomAsset(customAsset).then(function(asset){
                            $scope.selectedAssetId = asset.id;
                            $scope.a3.assetId = asset.id;
                            $scope.saveA3Metadata();
                        });
                    } else {
                        // Update list of participants
                        assetService.updateCustomAssetMembers($scope.selectedAssetId, participants).then(function(asset){
                            $scope.saveA3Metadata();
                        });
                    }
                    
                } else {
                    $scope.saveA3Metadata();
                }
            }

            $scope.saveA3Metadata = function () {
                if (!$scope.a3.id) return;
                if ($scope.a3.title === "") {
                    $scope.titlehaserror = true;
                    return;
                }
                var metaData = {
                    id: $scope.a3.id,
                    assetId: $scope.a3.assetId,
                    ownerUserId: $scope.a3.ownerUserId,
                    owner: $scope.a3.owner,
                    ownerDisplayName: $scope.a3.ownerDisplayName,
                    categoryId: $scope.a3.categoryId,
                    status: $scope.a3.status,
                    hasCustomizedGroup: $scope.a3.hasCustomizedGroup
                };
                a3Service().saveA3Metadata(metaData).then(function (savedA3) {
                    //generatePreview();
                });
            };

            function setOwner() {
                if ($scope.a3.ownerUserId) {
                    var owner = $filter('filter')($scope.participants, { userId: $scope.a3.ownerUserId }, true);
                    if (owner.length === 1) {
                        $scope.selectedOwner = owner[0];
                    } else { // If not part of participants, we need to add user to list
                        var currentOwner = $filter('filter')($scope.allUsers, { userId: $scope.a3.ownerUserId }, true)[0];
                        $scope.participants.unshift(currentOwner);
                        $scope.selectedOwner = currentOwner;
                    }
                } else {
                    $scope.selectedOwner = {};
                }      
            }

        }]);
