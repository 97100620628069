import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as dashboardService from "@common/services/dashboardService"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import { getDefaultAppTheme } from "@common/components/dashboard/designer/common/appStyleService"

angular
    .module("DigiLean")
    .directive('smarttableApp', ['$filter', '$uibModal', '$translate', 'translationService', 'smarttableService','orderByFilter', 'taskDrawingService','debounce',
        function ($filter, $uibModal, $translate, translationService, smarttableService, orderByFilter, taskDrawingService, debounce) {
            return {
                templateUrl: 'smarttableApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'assetId': '<',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.smartTable = null;
                    scope.showAddButton = true;
                    scope.showAggregateSum = true;
                    scope.editable = true;
                    scope.columns = [];
                    scope.rows = [];

                    scope.theme = getDefaultAppTheme()
                    scope.postIts = dashboardService.getThemes();
                    

                    // Sorting
                    scope.sortColumnIndex = null;
                    scope.isAscedning = true;
                    scope.sortColumn = null;
                    scope.orderBy = function (column) {
                        if (scope.sortColumn && scope.sortColumn != column) {
                            scope.sortColumn.sort = "none";
                        }
                        if (!column.sort) column.sort = "none";
                        column.sort = getNextSortOrder(column.sort);
                        scope.sortColumn = column;
                        scope.sortColumnIndex = scope.columns.indexOf(column);
                        if (column.sort == "none") {
                            scope.rows = orderByFilter(scope.rows, 'sortOrder');
                        }
                        if (column.sort == "asc") {
                            scope.rows = orderByFilter(scope.rows, getSortValue, false);
                        }
                        if (column.sort == "desc") {
                            scope.rows = orderByFilter(scope.rows, getSortValue, true);
                        }
                    }

                    var getNextSortOrder = function (currentSortOrder) {
                        if (currentSortOrder == "none") return "asc";
                        if (currentSortOrder == "asc") return "desc";
                        if (currentSortOrder == "desc") return "none";
                    }

                    var getSortValue = function (row) {
                        if (row.cellModels) {
                            var cell = row.cellModels[scope.sortColumnIndex];
                            return cell.getSortValue();
                        }
                        return row.name;

                    }

                    // load users
                    var profileImageUsers = [];
                    function getAllUsers() {
                        profileService.getUsersWithProfileImage().then(function (imageUsers) {
                            profileImageUsers = imageUsers;
                            customerService.getAllUsers().then(function (users) {
                            
                                for (var i = 0; i < users.length; i++) {
                                    var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(users[i].userId, "ExtraSmall", profileImageUsers);
                                    if (imageUrl) {
                                        users[i].profileImage = true;
                                        users[i].profileImageUrl = imageUrl;
                                    } else {
                                        users[i].profileImage = false;
                                    }
                                }
                                scope.users = users;
                            });
                        });
                    }
                    getAllUsers();

                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) { 
                            if (settings.title) {
                                scope.title = settings.title;
                            }
                            if (settings.tableId) {
                                loadSmartTable(settings.tableId);
                            }
                            if (settings.showAddButton === false) {
                                scope.showAddButton = settings.showAddButton;
                            }
                            if (settings.showAggregateSum === false) {
                                scope.showAggregateSum = settings.showAggregateSum;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                        } else {
                            createTable();
                        }
                    });

                    scope.$watch("isAdminMode", function () {
                        /*if (scope.isAdminMode) {
                            scope.editable = true;
                        } else {
                            scope.editable = false;
                        }*/
                    });

                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    var loadSmartTable = function (tableId) {
                        scope.tableId = tableId;
                        scope.isLoading = true;
                        smarttableService().get(tableId).then(function (smartTable) {
                            scope.smartTable = smartTable;
                            boardService.canEdit(tableId).then(function (canEdit) {
                                scope.editable = canEdit;
                            });
                            setupTable();
                            setTimeout(function () {
                                scope.isLoading = false;
                                resizeDebounce();
                            }, 500);
                        })

                    }

                    function setupTable() {
                        scope.columns = scope.smartTable.columns;
                        scope.rows = scope.smartTable.rows;
                        if (scope.rows) {
                            for (var i = 0; i < scope.rows.length; i++) {
                                var row = scope.rows[i];
                                calculateCells(row, scope.columns)
                            }
                        }
                    }
                    function createTable() {
                        scope.isLoading = true;
                        var savetemplate = {
                            board: {
                                name: $translate.instant('DASHBOARD_SMARTTABLE'),
                                assetId: scope.assetId,
                                boardType: "Smarttable",
                                settings: JSON.stringify({
                                    IsWeeklyBoard: false
                                })
                            },
                            columns: [{
                                name: $translate.instant('COMMON_DESCRIPTION'),
                                attributeType: "name",
                                sortOrder: 0,
                                settings: "",
                            }, {
                                name: $translate.instant('IMPROVEMENT_PRIORITY'),
                                attributeType: "text",
                                sortOrder: 1,
                                settings: "",
                            },
                            {
                                name: $translate.instant('COMMON_COST'),
                                attributeType: "number",
                                sortOrder: 2,
                                settings: "",
                            }],
                            rows: [{
                                sortOrder: 0
                            },
                            {
                                sortOrder: 1
                            }
                            ],
                            cells: [
                                // ROW 1 
                                {
                                    rowIndex: 0,
                                    columnIndex: 0,
                                    valueText: $translate.instant('COMMON_NAME') + ' 1',
                                },
                                {
                                    rowIndex: 0,
                                    columnIndex: 1,
                                    valueText: "",
                                    value: JSON.stringify({
                                        text: $translate.instant('COMMON_LOW'),
                                        background: "#8CC152", // Green
                                        color: "#ffffff"
                                    }),
                                },
                                {

                                    rowIndex: 0,
                                    columnIndex: 2,
                                    valueText: "",
                                    value: JSON.stringify({
                                        number: 2000,
                                        background: "#8CC152", // Green
                                        color: "#ffffff"
                                    }),
                                },
                                // ROW 2 
                                {
                                    rowIndex: 1,
                                    columnIndex: 0,
                                    valueText: $translate.instant('COMMON_NAME') + ' 2', // Built in field.
                                },
                                {
                                    rowIndex: 1,
                                    columnIndex: 1,
                                    value: JSON.stringify({
                                        text: $translate.instant('COMMON_MEDIUM'),
                                        background: "#FCBB42", // orange
                                        color: "#ffffff"
                                    }),
                                },
                                {
                                    rowIndex: 1,
                                    columnIndex: 2,
                                    value: JSON.stringify({
                                        number: 6000,
                                        background: "#E9573F", // Red
                                        color: "#ffffff"
                                    }),
                                },
                            ]
                        };
                        smarttableService().createSmartTable(savetemplate).then(function (smartTable) {
                            scope.isLoading = false;
                            scope.tableId = smartTable.board.id;
                            scope.smartTable = smartTable;
                            scope.title = smartTable.board.name;
                            setupTable();
                            scope.updateSettings();
                        });
                    }
                    scope.updateBoardTitle = function () {
                        scope.updateSettings();
                        var boardName = {
                            boardId: scope.tableId,
                            name: scope.title
                        }

                        boardService.updateName(scope.tableId, boardName).then(function (smarttable) {
                        });
                    }

                    scope.updateSettings = function () {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                title: scope.title,
                                tableId: scope.tableId,
                                showAddButton: scope.showAddButton,
                                theme: scope.theme
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }

                    scope.toggleButton = function () {
                        scope.updateSettings();
                    }

                    scope.$on("widget-resized", function (event, args) {
                        resizeDebounce();
                    });

                    function resize() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 60;
                        var container = $(elem).find(".project-board-container");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                    }
                    const resizeDebounce = debounce(resize, 100, false)

                    var calculateCells = function (row, columns) {
                        row.cellModels = [];
                        for (let index = 0; index < columns.length; index++) {
                            const column = columns[index];
                            var cellModel = {
                                row: row,
                                column: column,
                                cell: null
                            }
                            var hasCell = $filter('filter')(row.cells, { smartTableColumnId: column.id }, true);
                            if (hasCell.length > 0) {
                                var cell = hasCell[0];
                                if (cell.value) {
                                    cell.value = JSON.parse(cell.value);
                                }
                                cellModel.cell = cell;
                            } else {
                                // Create default cell
                                cellModel.cell = {
                                    id: 0,
                                    smartTableRowId: row.id,
                                    smartTableColumnId: column.id,
                                    value: null
                                }
                            }
                            if (cellModel.column.settings && typeof (cellModel.column.settings) === "string") {
                                cellModel.column.settings = JSON.parse(cellModel.column.settings);
                            }
                            row.cellModels.push(cellModel)
                        }
                    }
                    var addColumnToRows = function (column) {
                        for (let index = 0; index < scope.rows.length; index++) {
                            var row = scope.rows[index];
                            var cellModel = {
                                row: row,
                                column: column,
                                cell: null
                            }
                            // Create default attribute
                            cellModel.cell = {
                                id: 0,
                                smartTableRowId: row.id,
                                smartTableColumnId: column.id,
                                value: null
                            }
                            row.cellModels.push(cellModel)
                        }
                    }

                    scope.columnNameUpdated = function (column) {
                        smarttableService().updateColumn(column).then(function (attribute) {
                        })
                    }

                    scope.predefinedIcons = function (col) {
                        if (!col.settings) {
                            col.settings = {};
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'predefinedIcons.html',
                            controller: 'predefinedIcons',
                            resolve: {
                                labels: function () {
                                    return col.settings.predefinedIcons;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            if (!col.settings) {
                                col.settings = {};
                            }
                            col.settings.predefinedIcons = result;
                            var payload = {
                                type: 'smarttable',
                                columnId: col.id,
                                predefinedIcons: col.settings.predefinedIcons
                            }
                            scope.publish("SmartIconCellSelectorUpdated", payload);

                            smarttableService().updateColumn(col).then(function (attribute) {

                            })

                        });
                    }
                    scope.predefinedLabels = function (col) {
                        if (col.settings.usePredefinedLabels) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'predefinedLabels.html',
                                controller: 'predefinedLabels',
                                resolve: {
                                    labels: function () {
                                        return col.settings.predefinedLabels;
                                    }
                                }
                            });

                            modalInstance.result.then(function (result) {
                                if (!col.settings) {
                                    col.settings = {};
                                }
                                col.settings.predefinedLabels = result;
                                var payload = {
                                    type: 'smarttable',
                                    columnId: col.id,
                                    predefinedLabels: col.settings.predefinedLabels,
                                    usePredefinedLabels: col.settings.usePredefinedLabels
                                }
                                scope.publish("SmartCommonTextCellLabelUpdated", payload);

                                smarttableService().updateColumn(col).then(function (attribute) {

                                })

                            });
                        } else {
                            col.settings.usePredefinedLabels = false;
                            var payload = {
                                type: 'smarttable',
                                columnId: col.id,
                                predefinedLabels: col.settings.predefinedLabels,
                                usePredefinedLabels: col.settings.usePredefinedLabels
                            }
                            scope.publish("SmartCommonTextCellLabelUpdated", payload);

                            smarttableService().updateColumn(col).then(function (attribute) {

                            })
                        }
                    }

                    scope.addRow = function () {
                        var row = {
                            boardId: scope.tableId,
                            name: $translate.instant('COMMON_NEW'),
                            sortOrder: scope.columns.length
                        };

                        smarttableService().addRow(row).then(function (createdRow) {

                        });
                    }

                    scope.addColumn = function (type) {
                        if (type === "numberAutomatic") {
                            addNumberAutomaticColumn(type);
                        } else {
                            var headertext = translationService().getTranslatedSmartColumnName(type);
                            if (headertext === "") {
                                headertext = type.charAt(0).toUpperCase() + type.slice(1);
                            }

                            var column = {
                                boardId: scope.tableId,
                                name: headertext,
                                attributeType: type,
                                sortOrder: scope.columns.length - 1
                            }
                            smarttableService().addColumn(column).then(function (savedAttribute) {

                            })

                        }
                    }
                    scope.editTimeframe = function (col) {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'timeframeSelectorModal.html',
                            controller: 'timeframeSelectorModal',
                            resolve: {
                                timePeriod: function () {
                                    return col.settings.timePeriod;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            col.settings.timePeriod = result; //result = timePeriod.timeframe e.g."all"
                            var payload = {
                                columnId: col.id,
                                timePeriod: col.settings.timePeriod
                            }
                            scope.publish("SmartTableNumberCellAutomaticTimeframeUpdated", payload);

                            smarttableService().updateColumn(col).then(function (attribute) {

                            })

                        });

                    }
                    scope.getTimeframeLabel = function (timeframe) {
                        return timeService.getTimeframeLabel(timeframe);
                    }


                    scope.delete = function (row) {
                        smarttableService().deleteRow(row.id).then(function (row) {
                        });
                    }

                    scope.deleteColumn = function (column) {
                        smarttableService().deleteColumn(column.id);
                    }

                    scope.calculateNumberCellTotal = function (colDef) {
                        var total = 0;
                        for (var i = 0; i < colDef.projectAttributes.length; i++) {
                            var value = colDef.projectAttributes[i].value;
                            var valueObject = JSON.parse(value);
                            var cellNumber = valueObject.number;
                            if (cellNumber) {
                                total = total + cellNumber;
                            }
                        }
                        return total;
                    }

                    function addNumberAutomaticColumn(type) {

                        var dataSource = null;
                        var filters = null;
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return false;
                                },
                                dataSource: function () {
                                    return dataSource;
                                },
                                filters: function () {
                                    return filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                            var timePeriod = {
                                timeframe: "all",
                                period: timeService.getTimePeriod("all")
                            }

                            var unit = null;
                            if (result.dataSource.valueElement) {
                                unit = result.dataSource.valueElement.unit;
                            }

                            var cellSettings = JSON.stringify({
                                dataSource: result.dataSource,
                                timePeriod: timePeriod,
                                filters: result.filters,
                                aggregateType: "SUM",
                                unit: unit,
                                unitPlacement: "right"
                            })
                            var column = {
                                boardId: scope.tableId,
                                name: result.dataSource.title,
                                attributeType: type,
                                sortOrder: scope.columns.length - 1,
                                settings: cellSettings,
                            }
                            smarttableService().addColumn(column).then(function (savedAttribute) {

                            })

                        });
                    };


                    scope.updateAggregateType = function (col) {
                        var payload = {
                            columnId: col.id,
                            aggregateType: col.settings.aggregateType
                        }
                        scope.publish("SmartTableNumberCellAggregateTypeUpdated", payload);

                        smarttableService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    scope.updateUnit = function (col) {
                        var payload = {
                            columnId: col.id,
                            unit: col.settings.unit
                        }
                        scope.publish("SmartTableNumberCellUnitUpdated", payload);

                        smarttableService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    scope.updateUnitPlacement = function (col) {
                        var payload = {
                            columnId: col.id,
                            unitPlacement: col.settings.unitPlacement
                        }
                        scope.publish("SmartTableNumberCellUnitPlacementUpdated", payload);

                        smarttableService().updateColumn(col).then(function (attribute) {
                        })
                    };
                    // TODO: FIX THIS Bakend/Frontend
                    scope.updateDecimals = function (col) {
                        var payload = {
                            columnId: col.id,
                            decimals: col.settings.decimals
                        }
                        // Use this event i norder to reuse the project elements components
                        scope.publish("SmartTableNumberCellDecimalUpdated", payload);


                        smarttableService().updateColumn(col).then(function (attribute) {
                        })

                    };

                    scope.columnWidthChangeHandler = function(col){
                        if(col){
                            smarttableService().updateColumn(col).then(function (attribute) {
                            })
                        }
                    }

                    scope.cellChangeHandler = function (cell) {
                        if (cell.value) {
                            cell.value = JSON.stringify(cell.value);
                        }

                        smarttableService().updateCell(cell).then(function (savedCell) {
                            // Parse value again
                            cell.id = savedCell.id;
                            if (savedCell.value) {
                                cell.value = JSON.parse(savedCell.value);
                            }
                        });
                    }


                    scope.subscribe("SmartTableColumnUpdated", function (updatedColumn) {
                        if (updatedColumn.boardId === scope.tableId) {
                            var hasColumn = $filter('filter')(scope.columns, { id: updatedColumn.id }, true);
                            if (hasColumn.length > 0) {
                                var column = hasColumn[0];
                                if (column.name !== updatedColumn.name) {
                                    column.name = updatedColumn.name;
                                }
                                if (updatedColumn.settings) {
                                    column.settings = JSON.parse(updatedColumn.settings);
                                }
                            }
                        }
                    });
                    scope.subscribe("SmartTableColumnAdded", function (column) {
                        if (column.boardId === scope.tableId) {
                            var hasColumn = $filter('filter')(scope.columns, { id: column.id }, true);
                            if (hasColumn.length === 0) {
                                addColumnToRows(column);
                                scope.columns.push(column);
                            }
                        }
                    });
                    scope.subscribe("SmartTableColumnDeleted", function (column) {
                        if (column.boardId === scope.tableId) {
                            loadSmartTable(scope.tableId);
                        }
                    });
                    scope.subscribe("SmartTableRowDeleted", function (row) {
                        var isPartOfBoard = $filter('filter')(scope.rows, { id: row.id });
                        if (isPartOfBoard && isPartOfBoard.length === 1) {
                            var tableRow = isPartOfBoard[0];
                            tableRow.cssState = "animated slideOutRight"; // Add animation
                            var index = scope.rows.indexOf(tableRow);
                            if (index > -1) {
                                scope.rows.splice(index, 1);
                            }

                        }
                    });

                    scope.subscribe("SmartTableRowCreated", function (row) {
                        if (row.boardId === scope.tableId) {
                            var has = $filter('filter')(scope.rows, { id: row.id }, true);
                            if (has.length === 0) {
                                calculateCells(row, scope.columns);
                                scope.rows.push(row);
                            }
                        }
                    });



                    scope.subscribe("SmartTableSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === scope.tableId) {
                            loadSmartTable(scope.tableId);
                        }
                    });
                    scope.subscribe("SmartTableRowSortOrderChanged", function (payLoad) {
                        if (payLoad.boardId === scope.tableId) {
                            loadSmartTable(scope.tableId);
                        }
                    });

                    // Drag and drop of columns events
                    scope.$on('column-header-bag.drop-model', function (e, el) {
                        if(scope.editable){
                            var columnOrder = scope.columns;
                            var sortOrder = [];
                            for (var i = 0; i < scope.columns.length; i++) {
                                var column = scope.columns[i];
                                var columnOrder = {
                                    smartTableColumnId: column.id,
                                    sortOrder: i
                                }
                                sortOrder.push(columnOrder)
                            }
                            smarttableService().updateColumnSortOrder(scope.tableId, sortOrder).then(function () {

                            });
                        }

                    });
                    scope.$on('column-header-bag.drag', function (e, el) {
                        $(el).find(".drag-drop-icon").hide();
                        $(el).find(".option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    scope.$on('column-header-bag.drop', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                        el.removeClass('drag-moved');
                    });

                    scope.$on('column-header-bag.dragend', function (e, el) {
                        $(el).find(".drag-drop-icon").show();
                        $(el).find(".option-icon").show();
                    });
                    
                    scope.$on('column-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    scope.$on('column-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });


                    // Drag and drop of rows events
                    scope.$on('row-header-bag.drop-model', function (e, el) {
                        if(scope.editable){
                            var rowOrder = scope.rows;
                            var sortOrder = [];
                            for (var i = 0; i < scope.rows.length; i++) {
                                var row = scope.rows[i];
                                var rowOrder = {
                                    smartTableRowId: row.id,
                                    sortOrder: i
                                }
                                sortOrder.push(rowOrder)
                            }
                            smarttableService().updateRowSortOrder(scope.tableId, sortOrder).then(function () {

                            });
                        }

                    });
                    scope.$on('row-header-bag.drag', function (e, el) {
                        $(el).find(".row-drag-drop-icon").hide();
                        //$(el).find(".row-option-icon").hide();
                        el.addClass('drag-moved');
                    });

                    scope.$on('row-header-bag.drop', function (e, el) {
                        $(el).find(".row-drag-drop-icon").show();
                        //$(el).find(".row-option-icon").show();
                        el.removeClass('drag-moved');

                    });
                    scope.$on('row-header-bag.over', function (e, el) {
                        el.addClass('drag-over');
                    })
                    scope.$on('row-header-bag.out', function (e, el) {
                        el.removeClass('drag-over');
                    });

                }
            }
        }]);

