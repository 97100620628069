angular
    .module('DigiLean')
    .directive("subtaskStatus", ['boardTaskService','$uibModal',
        function (boardTaskService, $uibModal) {
            return {
                templateUrl: 'subtaskStatus.html',
                scope: {
                    'task': '<',
                    'isReadOnly': '<'
                },
                restrict: 'E',
                link: function (scope, elem, attrs) {
                    scope.completeCss = "badge-warning";
                    scope.$watch('task', function (task) {
                        // apply
                        if (task) {
                            updateCompletedStatus();
                        }
                    });
                    
                    var updateCompletedStatus = function() {
                        if (scope.task && scope.task.subTaskCount > 0) {
                            if (scope.task.subTaskCount ==  scope.task.subTaskCompletedCount) {
                                scope.completeCss = "digilean-badge-completed";
                            } else {
                                scope.completeCss = "badge-warning";
                            }
                        }
                    }

                    scope.subscribe("SubTaskCountChanged", function(count) {
                        if (!scope.task) return;
                        if (count.taskId == scope.task.id || count.taskId == scope.task.siblingId) {
                            scope.task.subTaskCount = count.subTaskCount;
                            scope.task.subTaskCompletedCount = count.subTaskCompletedCount;
                            updateCompletedStatus();
                        }
                    });
                    scope.editTask = function () {
                        if (scope.isReadOnly) return;
                        boardTaskService().get(scope.task.id).then(function (task) {
                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return "SubTasks";
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }]);
