import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as boardService from "@common/services/boardService"

var DigiLean = angular.module("DigiLean")
DigiLean.controller('moveSubTaskController',
    ['$scope', '$uibModalInstance','boardTaskService','taskDrawingService','parenttask','days','debounce',
        function   ($scope,  $uibModalInstance,  boardTaskService,  taskDrawingService, parenttask,  days,  debounce) {
            $scope.parentTask = parenttask;
            $scope.days = days;

            $scope.tasks = [];
            $scope.boards = [];
            $scope.canEdit = true;

            $scope.isLoading = true;
            boardTaskService().getSubTasks($scope.parentTask.id).then(function (tasks) {
                //
                $scope.tasks = [];
                for (let index = 0; index < tasks.length; index++) {
                    const task = tasks[index];
                    if(task.status != 'OK'){
                        setupTask(task);
                        $scope.tasks.push(task);
                    }
                    
                }
                //$scope.tasks = tasks;
                $scope.isLoading = false;
            });

            var setupTask = function (task) {
                if (task.boardDate) {
                    task.boardDate = moment(task.boardDate).toDate();
                    task.newBoardDate = moment(task.boardDate).add($scope.days, 'days').toDate();
                    task.updateDueDate = true;
                } else {
                    task.updateDueDate = false;
                }
                task.datePicker = {
                    opened: false
                }
                setResponsible(task);
                setBoard(task);
            }

            $scope.translationData = {
                parentTaskTitle: $scope.parentTask.title
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            $scope.changeDate = function (task) {
                //  task.updateDueDate = !task.updateDueDate;
                if(!task.boardDate && task.updateDueDate){
                    task.newBoardDate = moment($scope.parentTask.boardDate).add($scope.days, 'days').toDate();
                }
            }

            function setResponsible(task) {
                if (task.responsibleUserId && $scope.users) {
                    var user = $scope.users.find(u => u.userId == task.responsibleUserId);
                    if (user) {
                        task.user = user;
                    }
                }
            }

            function setBoard(task) {
                if (task.boardId && $scope.boards) {
                    var existsOutsideParent = false;
                    if (task.boardId == $scope.parentTask.boardId) {
                        if (task.isActionItem) {
                            existsOutsideParent = true;
                        } else if (task.rowCategoryId) {
                            existsOutsideParent = true;
                        }
                    } else {
                        existsOutsideParent = true;
                    }
                    if (existsOutsideParent) {
                        var board = $scope.boards.find(b => b.id == task.boardId);
                        if (board) {
                            task.board = board;
                        }
                    }
                }
            }
            var profileImageUsers = [];
            function getAllUsers() {
                profileService.getUsersWithProfileImage().then(function (imageUsers) {
                    profileImageUsers = imageUsers;
                    customerService.getAllUsers().then(function (users) {
                        $scope.users = users;
                        for (var i = 0; i < $scope.users.length; i++) {
                            var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers($scope.users[i].userId, "ExtraSmall", profileImageUsers);
                            if (imageUrl) {
                                $scope.users[i].profileImage = true;
                                $scope.users[i].profileImageUrl = imageUrl;
                            } else {
                                $scope.users[i].profileImage = false;
                            }
                        }

                        for (let index = 0; index < $scope.tasks.length; index++) {
                            const task = $scope.tasks[index];
                            setResponsible(task);
                        }
                    });
                });
            }
            getAllUsers();


            boardService.getAllBoardNames().then(function (data) {
                $scope.boards = data;
                for (let index = 0; index < $scope.tasks.length; index++) {
                    const task = $scope.tasks[index];
                    setBoard(task);
                }
            });

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function () {
                for (let index = 0; index < $scope.tasks.length; index++) {
                    const task = $scope.tasks[index];
                    if (task.newBoardDate && task.updateDueDate) {
                        boardTaskService().updateDate(task.id, task.newBoardDate);
                    }
                }
                $uibModalInstance.close();
            }

            $scope.updateBoardDate = function($event, task) {
                const date = $event.originalEvent.detail
                task.newBoardDate = date
            }

            $scope.delete = function (task) {
                // Delete subtask
                var index = $scope.tasks.indexOf(task);
                $scope.tasks.splice(index, 1);
                boardTaskService().deleteTask(task.id).then(function () {

                });
            }

            $scope.changeStatus = function (task) {
                task.status = taskDrawingService().getNextStatus(task.status).status;
                var status = {
                    taskId: task.id,
                    status: task.status
                };
                statusChangeDebounced(status);
            }
            var statusChangeDebounced = debounce(commitStatusChanged, 200, false);
            function commitStatusChanged(status) {
                boardTaskService().updateStatus(status.taskId, status).then(function (taskIds) {

                });
            }

            $scope.titleChanged = function (task) {
                task.active = false;
                var taskTitle = {
                    taskId: task.id,
                    title: task.title
                }
                boardTaskService().updateTitle(task.id, taskTitle).then(function (result) {

                });
            }

            $scope.$on('task-bag.drop-model', function (e, el, target, source) {
                var sortOrders = [];
                var counter = 0;
                $scope.tasks.forEach(task => {
                    sortOrders.push({
                        taskId: task.id,
                        sortOrder: counter
                    });
                    counter += 1;
                });
                boardTaskService().updateSortOrderForSubtasks($scope.parentTask.id, sortOrders).then(function (result) {

                });
            });

        }]);


