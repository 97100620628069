import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive('myMessageNotifications', ['$filter', 'navigationService',
        function ($filter, navigationService) {
            return {
                templateUrl: 'myMessageNotifications.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    var newAlerts = 0;
                    var memberassets = [];
                    var projectassets = [];

                    assetService.getList().then(function (assets) {
                        memberassets = assets;
                    });
                    scope.hasProjectModule = navigationService().hasModule("PROJECT");
                
                    if(scope.hasProjectModule){
                        assetService.getMyProjectAssets().then(function (assets) {
                            projectassets = assets;
                        });
                    }

                    scope.subscribe("MessageReceived", function (message) {
                        if(!isRelevantMessage(message)) return;
                        newAlerts = newAlerts + 1;
                        updateNewAlertCounter(newAlerts);
                    });
                    
                    scope.subscribe("ProjectMessageAdded", function (message) {
                        if(scope.hasProjectModule){
                            if(!isRelevantMessage(message)) return;

                            newAlerts = newAlerts + 1;
                            updateNewAlertCounter(newAlerts);
                        }
                    });

                    function assetMatch(list1, list2) {
                        for (const itemlist1 of list1) {
                            for (const itemlist2 of list2) {
                                if (itemlist1.id === itemlist2.assetId) {
                                    return true;
                                }
                            }
                        }          
                        return false;
                    }

                    scope.resetAlertCounter = function() {
                        updateNewAlertCounter(0);
                    }

                    
                    function updateNewAlertCounter(count) {
                        if (count < 0) count = 0;
                        if (count >= 100) {
                            scope.newAlerts = "99+";
                        } else {
                            scope.newAlerts = count;
                        }
                        newAlerts = count;
                    }

                    function isRelevantMessage(message){
                        if(message.isPublic)
                            return true;
                        if(message.assets){
                            return assetMatch(memberassets, message.assets);
                        }
                        if(message.projectId){
                            var projectfilter = $filter('filter')(projectassets, { id: message.projectId });                       
                            if(projectfilter && projectfilter.length == 1){
                                return true;
                            }
                        }
                        return false;
                    }
                }
            }
        }]);
