angular
    .module('DigiLean').directive("projectNextMilestone", [
        '$filter', 'projectService', '$state', '$stateParams', '$translate', '$window', 'a3Service', 'pdfService',
        function projectNextMilestone($filter, projectService, $uibModal, $state, $stateParams, $translate, $window, a3Service, pdfService) {
            return {
                templateUrl: 'projectNextMilestone.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'projectId': '<',
                    'show': '='
                },
                link: function ($scope, elem) {
                    $scope.projectId = null;
                    $scope.next = {};
                    $scope.milestoneMissed = false;
                    $scope.days = null;

                    $scope.translationData = null;

                    $scope.$watch('projectId', function (projectId) {
                        if (!projectId) return;
                        loadNextMilestone(projectId);
                    });

                    $scope.remove = function() {
                        $scope.show = false;
                    }

                    function loadNextMilestone(projectId) {
                        
                        projectService().getNextMilestonesForProject(projectId).then(function (nextmilestone) {
                            $scope.next = nextmilestone;
                            if($scope.next.plannedDate){
                                var today = moment().startOf('day');
                                var target = moment($scope.next.plannedDate).startOf('day');
                                $scope.days = today.diff(target, 'days'); 
                                if ($scope.days >= 1) {
                                    $scope.milestoneMissed = true;
                                } 
                                $scope.days = Math.abs($scope.days);
                                $scope.translationData = {
                                    days: $scope.days
                                };
                            }
                             
                        });
                    }

                }
            }

        }]);






