
import lottie from "lottie-web"
import angular from "angular"

angular.module('DigiLean')
    .directive('heartAnimation', ['$rootScope',
        function ($rootScope) {
            return {
                templateUrl: 'heartAnimation.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    ///@ts-ignore
                    scope.animationFinished = false
                    scope.$on('$destroy', function () {
                        if (completeAnimation) {
                            completeAnimation.destroy(completeAnimationName)
                        }
                    })

                    let completeAnimation
                    let completeAnimationName = "heart-animation"
                    function showAnimation() {
                        if (completeAnimation) {
                            completeAnimation.play()
                            return
                        }
                        var okContainer = $(elem).find(".heart-animation")[0] // the dom element that will contain the animation
                        completeAnimation = lottie.loadAnimation({
                            container: okContainer,
                            renderer: 'svg',
                            name: completeAnimationName,
                            loop: false,
                            autoplay: true,
                            path: 'https://assets2.lottiefiles.com/datafiles/RCNn6rnkYkbg0RI/data.json' // the path to the animation json
                        })

                        completeAnimation.addEventListener('complete', function () {
                            ///@ts-ignore
                            scope.animationFinished = true;


                            // completeAnimation.stop(completeAnimationName);
                        })
                    }
                    showAnimation()
                }
            }
        }])
