angular
    .module('DigiLean')
    .directive("smarttableNumberCellAggregate", ['$filter', 'mathService',
        function ($filter, mathService) {
            return {
                templateUrl: 'smarttableNumberCellAggregate.html',
                restrict: 'E',
                scope: {
                    'model': '='
                },
                link: function (scope, elem, attrs) {
                    var smartTableColumnId = null;
                    scope.numberCellAggregateValue = null;
                    scope.aggregateType = "SUM";
                    var valuePerRow = [];
                    scope.decimals = null;
                    scope.unit = null;
                    scope.unitPlacement = "right";

                    scope.$watch('model', function (model) {
                        smartTableColumnId = model.id;
                        if (model && model.settings) {
                            var settings = model.settings;
                            if(settings.aggregateType){
                                scope.aggregateType = settings.aggregateType;
                            }
                            if(settings.dataSource && settings.dataSource.valueElement.unit == "%"){
                                scope.aggregateType = "AVERAGE";
                            }
                            if(settings.unit){
                                scope.unit = settings.unit;
                                if(settings.unitPlacement){
                                    scope.unitPlacement = settings.unitPlacement;
                                }
                            }
                            if(settings.decimals !== null || settings.decimals !== undefined){
                                scope.decimals = settings.decimals;
                            }
                        }
                        model.getSortValue = function() {
                            return scope.numberCellAggregateValue;
                        }
                    });

                    scope.subscribe("SmartTableNumberCellUpdated", function (value) {
                        if(smartTableColumnId === value.smartTableColumnId){
                            updateValue(value);
                        }
                    });

                    scope.subscribe("SmartTableNumberCellUnitUpdated", function (value) {
                        if(smartTableColumnId === value.columnId){
                            scope.unit = value.unit;
                        }
                    });
                    scope.subscribe("SmartTableNumberCellUnitPlacementUpdated", function (value) {
                        if(smartTableColumnId === value.columnId){
                            scope.unitPlacement = value.unitPlacement;
                        }
                    });
                    
                    scope.subscribe("SmartTableNumberCellDecimalUpdated", function (value) {
                        if(smartTableColumnId === value.columnId){
                            scope.decimals = value.decimals;
                            calculateAggregateValue();
                        }
                    });

                    scope.subscribe("SmartTableNumberCellAggregateTypeUpdated", function (value) {
                        if(smartTableColumnId === value.columnId){
                            scope.aggregateType = value.aggregateType;
                            calculateAggregateValue();
                        }
                    });

                    scope.subscribe("SmartTableRowDeleted", function (payLoad) {
                        var existingValue = $filter('filter')(valuePerRow, { valuePerRow: payLoad.valuePerRow });

                        if (existingValue && existingValue.length > 0) {
                            var index = valuePerRow.indexOf(existingValue);
                            valuePerRow.splice(index, 1);
                            calculateAggregateValue();
                        }
                    });


                    function updateValue(updatedValue) {

                        var existingValue = $filter('filter')(valuePerRow, { smartTableRowId: updatedValue.smartTableRowId });

                        if (existingValue && existingValue.length > 0) {
                            var index = valuePerRow.indexOf(existingValue[0]);
                            valuePerRow[index] = updatedValue;
                        } else {
                            valuePerRow.push(updatedValue);
                        }
                        calculateAggregateValue();
                    }

                    function calculateAggregateValue() {
                        scope.numberCellAggregateValue = 0;
                        var noOfCellWithValue = 0;
                        for (var i = 0; i < valuePerRow.length; i++) {
                            if (valuePerRow[i].value) {
                                scope.numberCellAggregateValue = scope.numberCellAggregateValue + valuePerRow[i].value;
                                noOfCellWithValue = noOfCellWithValue + 1;
                            }
                        }
                        if (scope.aggregateType === "AVERAGE" && noOfCellWithValue > 0) {
                            scope.numberCellAggregateValue = scope.numberCellAggregateValue / noOfCellWithValue;
                        }

                        if(scope.decimals !== null) {
                            if(scope.decimals !== undefined) {
                                scope.numberCellAggregateValue = mathService().roundNumber(scope.numberCellAggregateValue, scope.decimals);
                            }
                        }
                    }
                }
            }
        }]);
