﻿import * as boardService from "@common/services/boardService"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('boardDesignerController', ['$scope', '$stateParams', '$filter', '$translate', '$uibModal', 'modalService',
    function ($scope, $stateParams, $filter, $translate, $uibModal, modalService) {
        $scope.boardId = parseInt($stateParams.id);
        $scope.board = null;
        $scope.canEditMembers = false;
        $scope.isPublic = false;
        $scope.isKamishibai = false;
        $scope.refreshUserList = false;
        $scope.new = "New";

        $translate('COMMON_NEW').then(function (translated) {
            $scope.new = translated;
        });

        boardService.get($scope.boardId).then(function (board) {
            angular.forEach(board.columns, function (col) {
                col.style = {
                    "background": col.categoryStyle.backgroundColor,
                    "color": col.categoryStyle.foregroundColor
                }
                if (col.weekdayName) {
                    col.isWeekday = true;
                } else {
                    col.isWeekday = false;
                }
            });
            angular.forEach(board.rows, function (row) {
                row.style = {
                    "background": row.categoryStyle.backgroundColor,
                    "color": row.categoryStyle.foregroundColor
                }
                if (row.weekdayName) {
                    row.isWeekday = true;
                } else {
                    row.isWeekday = false;
                }
            });
            $scope.board = board;
            $scope.isPublic = board.board.isPublic;
            if (board.board.asset && board.board.asset.assetType === 'CUSTOMIZED') {
                $scope.canEditMembers = true;
            }
            if (board.board.settings) {
                $scope.board.board.settings = JSON.parse(board.board.settings);
                $scope.isKamishibai = $scope.board.board.settings.isKamishibai;
            };
        });

        $scope.updateBoard = function () {
            $scope.board.board.isPublic = $scope.isPublic;
            var board = {
                description: $scope.board.board.description,
                isPublic: $scope.isPublic,
                assetId: $scope.board.board.selectedAssetId,
                name: $scope.board.board.name,
                assetId: $scope.board.board.assetId,
                owner: $scope.board.board.owner,
                ownerDisplayName: $scope.board.board.ownerDisplayName,
                ownerUserId: $scope.board.board.ownerUserId
            }
            boardService.update($scope.boardId, board).then(function () {
                $translate('BOARD_UPDATED').then(function (msg) {
                    toastr.success(msg);
                });
            });
        }

        $scope.updateSettings = function () {
            if (!$scope.board.board.settings) {
                $scope.board.board.settings = {};
            }
            $scope.board.board.settings.isKamishibai = $scope.isKamishibai;
            $scope.board.board.settings = JSON.stringify($scope.board.board.settings);
            boardService.updateSettings($scope.boardid, $scope.board.board.settings).then(function () {
                $translate('BOARD_UPDATED').then(function (msg) {
                    toastr.success(msg);
                });
            });
        }

        $scope.updateBoardName = function () {
            var boardName = {
                boardId: $scope.boardId,
                name: $scope.board.board.name
            }
            boardService.updateName($scope.boardId, boardName).then(function () {
                $translate('BOARD_UPDATED').then(function (msg) {
                    toastr.success(msg);
                });
            });
        }

        $scope.columnSortableOptions = {
            clone: false,
            accept: function (sourceItemHandleScope, destSortableScope) {
                // We will only accept items within this scope (columns)
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            },
            orderChanged: function (event) {
                var sortOrders = [];
                angular.forEach($scope.board.columns, function (col, key) {
                    console.log("col sortOrder: " + key + ", name: " + col.name);
                    col.sortOrder = key;
                    var sortOrder = { id: col.id, sortOrder: key }
                    sortOrders.push(sortOrder);
                });
                boardService.changeSortOrder($scope.boardId, sortOrders).then(function () {
                    console.log("Saved");
                });
            }
        };

        $scope.rowSortableOptions = {
            clone: false,
            accept: function (sourceItemHandleScope, destSortableScope) {
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            },
            orderChanged: function (event) {
                var sortOrders = [];
                angular.forEach($scope.board.rows, function (row, key) {
                    console.log("row sortOrder: " + key + ", name: " + row.name);
                    row.sortOrder = key;
                    var sortOrder = { id: row.id, sortOrder: key }
                    sortOrders.push(sortOrder);
                });
                boardService.changeSortOrder($scope.boardId, sortOrders).then(function () {
                    console.log("Saved");
                });
            }
        };

        var options = {
            hideWeekDayOption: false
        }
        $scope.editCategory = function (category) {
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: $scope.animationsEnabled,
                templateUrl: 'boardCategoryDetails.html',
                controller: 'boardCategoryDetailsController',
                resolve: {
                    boardCategory: function () {
                        return category;
                    },
                    options: function () {
                        return options;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                if (result === "deleted") {
                    removeCategory(category);
                }
            }, function () {

            });
        }

        $scope.editMembers = function () {
            var assetId = $scope.board.board.assetId;
            var boardId = $scope.board.board.id;
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: $scope.animationsEnabled,
                templateUrl: 'assetparticipantsform.html',
                controller: 'assetParticipantsController',
                windowClass: 'assetparticipants-modal-window',
                resolve: {
                    assetId: function () {
                        return assetId;
                    },
                    boardId: function () {
                        return boardId;
                    }
                }
            });

            modalInstance.result.then(function () {
                // Reset boardId to trigger refresh of userlist
                $scope.refreshUserList = true;
                setTimeout(() => {
                    // toggle back to false after signal is given.
                    $scope.refreshUserList = false;
                }, 3000);

            }, function () {

            });
        }



        $scope.addColumn = function () {
            var style = "";
            if ($scope.board.columns.length > 0) {
                // Copy style from last column
                var lastColumn = $scope.board.columns[$scope.board.columns.length - 1];
                style = lastColumn.style;
            }

            var column = {
                boardId: $scope.boardId,
                name: $scope.new,
                isColumnCategory: true,
                sortOrder: $scope.board.columns.length,
                style: style
            }
            column.categoryStyle = JSON.stringify({
                BackgroundColor: style.background,
                ForegroundColor: style.color
            });
            boardService.createCategory($scope.boardId, column).then(function (category) {
                category.style = style;
                $scope.board.columns.push(category);
                $scope.editCategory(category);
            });

        }

        $scope.addRow = function () {
            var style = "";
            if ($scope.board.rows.length > 0) {
                // Copy style from last row
                var lastRow = $scope.board.rows[$scope.board.rows.length - 1];
                style = lastRow.style;
            }
            var row = {
                boardId: $scope.boardId,
                name: $scope.new,
                isRowCategory: true,
                sortOrder: $scope.board.rows.length,
                style: style
            }
            row.categoryStyle = JSON.stringify({
                BackgroundColor: style.background,
                ForegroundColor: style.color
            });
            boardService.createCategory($scope.boardId, row).then(function (category) {
                category.style = style;
                $scope.board.rows.push(category);
                $scope.editCategory(category);
            });

        }


        $scope.switchColumnRow = function () {
            boardService.rotateCategories($scope.boardId).then(function () {
                var temp = $scope.board.columns;
                $scope.board.columns = $scope.board.rows;
                $scope.board.rows = temp;
            });
        }



        $scope.hasValue = function (value) {
            if (value) return true;
            return false;
        }

        $scope.deleteCategory = function (category) {
            $translate(['COMMON_CANCEL', 'BOARD_DELETE_CATEGORY', 'COMMON_DELETE', 'BOARD_DELETE_CATEGORY_DESCRIPTION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.BOARD_DELETE_CATEGORY,
                    headerText: translations.COMMON_DELETE + ' ' + category.name + '?',
                    bodyText: translations.BOARD_DELETE_CATEGORY_DESCRIPTION
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    boardService.deleteCategory(category.id).then(function (data) {
                        removeCategory(category);
                    });
                });
            });
        }

        var removeCategory = function (category) {
            var col = $filter('filter')($scope.board.columns, { id: category.id }, true);
            if (col.length > 0) {
                var index = $scope.board.columns.indexOf(category);
                $scope.board.columns.splice(index, 1);
            }
            var row = $filter('filter')($scope.board.rows, { id: category.id }, true);
            if (row.length > 0) {
                var rowindex = $scope.board.rows.indexOf(category);
                $scope.board.rows.splice(rowindex, 1);
            }
        }
    }]);