/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GroupType {
    GENERAL = 'GENERAL',
    BUSINESSUNIT = 'BUSINESSUNIT',
    PROJECT = 'PROJECT',
    CUSTOMIZED = 'CUSTOMIZED',
}
