angular
    .module('DigiLean')
    .directive("projectNumberCell", ['$filter', '$translate','projectService',
        function ($filter, $translate, projectService) {
            return {
                templateUrl: 'projectNumberCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.projectNumber = "";
                    
                    scope.projectExists = false;

                    scope.$watch('model', function (model) {
                        scope.projectNumber = model.project.projectNumber;
                        model.getSortValue = function () {
                            return scope.projectNumber;
                        }
                    });

                    scope.subscribe("ProjectNumberUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            if (project.projectNumber) {
                                scope.projectNumber = project.projectNumber;
                            } else {
                                scope.projectNumber = null;
                            }
                        }
                    });

                    function exists(){
                        var allreadyExists = false;
                        scope.projectExists = false;
                        for(var i=0; i<scope.projects.length; i++ ){
                            if(scope.projects[i].projectNumber && scope.projectNumber){
                                if(scope.projects[i].projectNumber.toUpperCase() === scope.projectNumber.toUpperCase()){
                                    scope.projectExists = true;
                                    return true;
                                }
                            }
                             
                        }
                        scope.projectExists = false;
                        return allreadyExists;
                    }
                    scope.saveChanges = function(projectNumber) { 
                        projectService().getList().then(function (projects) {
                            scope.projectNumber = projectNumber;
                            scope.projects = projects;
                            if(!exists()) {
                                projectService().updateProjectNumber(scope.model.project.id, projectNumber).then(function () { 
                                
                                });
                            } 
                            
                        });
                         
                                                 
                    }
                }
            }
        }]);
