angular
    .module('DigiLean')
    .directive("leadtimeDeviation", ['$filter', '$translate', 'deviationService', 'authService',
        function ($filter, $translate, deviationService, authService) {
            return {
                templateUrl: 'leadtimeDeviation.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    scope.options = {
                        timePeriod: {
                            timeframe: "year",
                            useDefaultTimeframes: true,
                            timeframes: []
                        }
                    };

                    var grafcolor = ['#2A2E36', '#0099DE', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'],
                        graphtextcolor = '#ffffff';

                    // Leadtime diagram
                    var leadtimecategories = [],
                        dataFromInProgressToImplemented = [],
                        dataFromNewToInProgress = [];


                    var yAxisText = "";
                    var fromImplToEval = "";
                    var fromInProgressToImpl = "";
                    var fromPlannedToInProgress = "";
                    var fromNewToInProgress = "";
                    $translate(['IMPROVEMENT_TOTAL_LEAD_TIME', 'IMPROVEMENT_FROM_IMPL_TO_EVALUATED', 'DEVIATION_FROM_IN_PROGRESS_TO_RESOLVED', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'DEVIATION_FROM_NEW_TO_INPROGRESS']).then(function (translations) {
                        yAxisText = translations.IMPROVEMENT_TOTAL_LEAD_TIME;
                        fromImplToEval = translations.IMPROVEMENT_FROM_IMPL_TO_EVALUATED;
                        fromInProgressToImpl = translations.DEVIATION_FROM_IN_PROGRESS_TO_RESOLVED;
                        fromPlannedToInProgress = translations.IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS;
                        fromNewToInProgress = translations.DEVIATION_FROM_NEW_TO_INPROGRESS;
                        getData();
                    });

                    scope.periodChangedHandler = function (timePeriod) {
                        scope.timePeriod = timePeriod.period;
                        scope.timeframe = timePeriod.timeframe;
                        getData();
                    }

                    function getData() {
                        if (!scope.timePeriod) return;
                        /*var endDate = moment().utc().endOf('month');
                        var startDate = moment().utc().add(-11, 'month').startOf('month');
                       
                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };*/
                        var leadtimecategories = [],
                            dataFromInProgressToImplemented = [],
                            dataFromNewToInProgress = [];

                        deviationService().getLeadTimeForAssets(scope.timePeriod).then(function (result) {
                            var leadtimedata = result.results;
                            // Build the data arrays
                            for (var i = 0; i < leadtimedata.length; i++) {
                                if (leadtimedata[i].asset != null) {
                                    leadtimecategories.push(leadtimedata[i].asset);
                                    dataFromNewToInProgress.push(leadtimedata[i].fromNewToInProgress);
                                    dataFromInProgressToImplemented.push(leadtimedata[i].fromInProgressToImplemented);
                                }
                            }

                            var renderElement = $(elem).find(".leadtime")[0];
                            new Highcharts.chart({
                                chart: {
                                    renderTo: renderElement,
                                    type: 'bar'
                                },
                                title: {
                                    text: ''
                                },
                                xAxis: {
                                    categories: leadtimecategories
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: yAxisText //'Total lead time'
                                    }
                                },
                                credits: {
                                    enabled: false
                                },
                                exporting: { enabled: false },
                                legend: {
                                    reversed: true
                                },
                                plotOptions: {
                                    series: {
                                        stacking: 'normal'
                                    }
                                },
                                series: [{
                                    name: fromInProgressToImpl, //'From In Progress to Implemented',
                                    data: dataFromInProgressToImplemented,
                                    color: grafcolor[2] //'#23c6c8'
                                }, {
                                    name: fromNewToInProgress, //'From new to inprogress
                                    data: dataFromNewToInProgress,
                                    color: "#FFCE54" //'#f8ac59'
                                }]
                            });
                        });
                    }
                }
            }
        }]);
