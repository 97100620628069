import { translateLabelInstant } from "./language/DigiLeanLang"
import swal from 'sweetalert';

export function showFullScreen() {
    // IE 11 fix
    $(".overlay").hide();
    //---------------------
    var isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||    // alternative standard method  
        (document.mozFullScreen || document.webkitIsFullScreen);

    if (typeof isInFullScreen === 'undefined') {
        isInFullScreen = document.msFullscreenElement != null;
    }

    if (isInFullScreen) return;
    //var docElm = document.getElementById("wrapper");
    var docElm = document.body;
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
    }
    else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
    }
}
export function hideFullScreen() {
    // IE 11 fix
    $(".overlay").show();
    //---------------------
    var isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||    // alternative standard method  
        (document.mozFullScreen || document.webkitIsFullScreen);

    if (typeof isInFullScreen === 'undefined') {
        isInFullScreen = document.msFullscreenElement != null;
    }
    if (!isInFullScreen) return;
    //var docElm = document.getElementById("wrapper");
    var docElm = document.body;


    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }

}
export function showNoAccessPopup() {
    swal({
        title: translateLabelInstant("COMMON_NO_ACCESS"),
        text: translateLabelInstant("COMMON_REQUIRED_GROUP_MEMBERSHIP"),
        icon: "warning",
        button: {
            text: translateLabelInstant("COMMON_OK"),
            closeModal: true,
            closeOnClickOutside: true,
            closeOnEsc: true
        },
        dangerMode: true,
        closeOnClickOutside: true,
        closeOnEsc: true
    });
}
export function showSystemProjectBoardPopup() {
    swal({
        title: translateLabelInstant("COMMON_NO_ACCESS"),
        text: translateLabelInstant("PROJECT_CAN_NOT_DELETE_BOARD"),
        icon: "warning",
        button: {
            text: translateLabelInstant("COMMON_OK"),
            closeModal: true,
            closeOnClickOutside: true,
            closeOnEsc: true
        },
        dangerMode: true,
        closeOnClickOutside: true,
        closeOnEsc: true
    });
}