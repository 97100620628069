﻿angular
    .module('DigiLean')
    .controller("templatesController", ['$scope', 'Lightbox',
        function ($scope, Lightbox) {
            $scope.mediaFilters = ["All, document, audio, video"];
            $scope.selectedFolder = {};
            $scope.selectFolder = function (folder) {
                $scope.selectedFolder = folder;
                $scope.selectedFiles = folder.files;
            }
            
            $scope.allTags = ["A3", "HMS", "5S", "Tavlemøte", "Håndbok", "Kaizen", "Avvik", "Verdikjede", "Forbedringssteam", "Sløsing"];


            $scope.openLightboxModal = function (file) {
                var images = [
                    {
                        'url': file.url,
                        'thumbUrl': file.url
                    }
                ];
                
                Lightbox.openModal(images, 0);
            };

            $scope.folders = [
                {
                    name: 'Inspirasjon',
                    files: [
                        { name: 'Lean på Gransk', description: 'Lean på Gransk, steg for steg. Gran kommune', mediaType: 'pdfLink', url: 'img/demo/docs/Lean-på-gransk.pdf' },
                        { name: 'Tavlemøte Vestre Toten', description: 'Lean Vestre Toten - Tyttebærstien tavlemøte', mediaType: 'videoLink', url: "https://www.youtube.com/watch?v=HlDwcG9HZgM" },
                        { name: 'Lean manufacturing tour', description: 'Lean Manufacturing Tour', mediaType: 'videoLink', url: "https://www.youtube.com/watch?v=mqgHUwSaKj8" },
                        { name: 'garasje', description: 'fin innredet garasje', mediaType: 'image', url: 'img/demo/elfa-garage.jpg' },
                        { name: 'A3 Ebook eksempel', description: 'Eksempel på bruk av A3', mediaType: 'image', url: 'img/demo/A3-ebook.png' },
                        { name: 'Kaizen your life', description: 'Kaizen your life, PDCA', mediaType: 'image', url: 'img/demo/kaizen-in-real-life.jpg' },
                        { name: 'Lean temple', description: '', mediaType: 'image', url: 'img/demo/lean-temple.jpg' },
                        { name: 'Hoshin diagram', description: '', mediaType: 'image', url: 'img/demo/hoshin-diagram.gif' }
                        
                    ]
                },
                {
                    name: 'A3 maler',
                    files: [
                        { name: 'A3 mal Gran kommune', description: 'A3 mal Gran kommune', mediaType: 'pdfLink', url: 'img/demo/A3/a3-gran-mal.pdf' },
                        { name: 'Enkel A3', description: 'TheKaiZone.com', mediaType: 'pdfLink', url: 'img/demo/A3/Problem-Solving-A3-Template.pdf' }
                    ]
                },
                {
                    name: 'Ettpunkt leksjoner',
                    files: [
                        { name: 'Finne dokument leksjon', description: 'Vestre Toten kommune, ettpunkts leksjon på å finne dokument i kvalitetslos', mediaType: 'pdfLink', url: 'img/demo/docs/kvalitetslosen-ettpunkt.pdf' },
                    
                    ]
                },

                {
                    name: 'Sjekklister',
                    files: [
                        { name: '5s sjekkliste', description: 'Mal på sjekkliste for 5s', mediaType: 'excel', url: 'img/demo/docs/5s_checklist.xslx' },

                    ]
                },
                {
                    name: 'DigiLEAN maler',
                    files: [{ name: 'Tavle - Uke', description: 'Oppsett på uketavle', mediaType: 'excel', url: 'img/demo/board/weeklyboard.xlsx' }]
                }
            ];
            $scope.selectFolder($scope.folders[0]);
        
        }]);