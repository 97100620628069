﻿angular.module('DigiLean').factory('settingsService', ['$translate',
    function ($translate) {
        function settingsService() {
            return {
               
                getSettingsFromDataSource: function (dataSource) {
                    var settingsSource = {
                        id: dataSource.id,
                        source: dataSource.source
                    };
                    if (dataSource.valueElement) {
                        settingsSource.valueElement = dataSource.valueElement;
                    }
                    if (dataSource.targets) {
                        settingsSource.targets = dataSource.targets;
                    }
                    return settingsSource;
                },
            };
        }
        return settingsService;
    }]);

