angular
    .module('DigiLean')
    .controller("projectMilestoneListModalController", ['$scope', '$uibModalInstance', 'projectService', 'projectId',
        function ($scope, $uibModalInstance, projectService, projectId) {

        
            $scope.projectId = projectId;
            
            $scope.canEditMilestones = true;
            $scope.canEdit = true;


            $scope.updateEstimatedStartDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.estimatedStartDate = date
                projectService().updateEstimatedStartDate($scope.projectId, date)
            }

            $scope.updateStartDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.startDate = date
                projectService().updateStartDate($scope.projectId, date)
            }
            $scope.updateEstimatedEndDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.estimatedEndDate = date
                projectService().updateEstimatedEndDate($scope.projectId, date)
            }

            $scope.updateEndDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.endDate = date
                projectService().updateEndDate($scope.projectId, date)
            }

            
            function get() {
                projectService().getProjectInfo($scope.projectId).then(function (project) {
                    $scope.project = project;
                    if ($scope.project.estimatedStartDate) {
                        $scope.project.estimatedStartDate = moment($scope.project.estimatedStartDate).toDate();
                    }
                    if ($scope.project.startDate) {
                        $scope.project.startDate = moment($scope.project.startDate).toDate();
                    }
                    if ($scope.project.estimatedEndDate) {
                        $scope.project.estimatedEndDate = moment($scope.project.estimatedEndDate).toDate();
                    }
                    if ($scope.project.endDate) {
                        $scope.project.endDate = moment($scope.project.endDate).toDate();
                    }
                });
            }
            get();
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

        }]);