angular
    .module('DigiLean')
    .directive("strategyNameCell", ['$filter', '$state', '$uibModal',
        function ($filter, $state, $uibModal) {
            return {
                templateUrl: 'strategyNameCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'id': '=',
                    'parentGoal': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.projectName = "";
                    scope.active = false;
                    scope.hasParentGoal = false;

                    scope.$watch('model', function (model) {
                        if (scope.model) {
                            scope.projectName = model.goal.name;
                            scope.color = model.goal.color;
                            scope.parentgoal = model.goal;
                            model.getSortValue = function () {
                                return scope.projectName;
                            }
                        }
                    });

                    scope.editGoal = function () {
                        var goalId = scope.model.goal.id;
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'goalEditPopup.html',
                            controller: 'goalEditPopup',
                            resolve: {
                                goalId: function () {
                                    return goalId;
                                }

                            }
                        });
                        modalInstance.result.then(function (result) {

                        });
                    }

                    scope.$watch('parentGoal', function (goal) {
                        if (scope.parentGoal) {
                            scope.hasParentGoal = true;
                        }
                    });

                    scope.subscribe("StrategyGoalNameUpdated", function (goal) {
                        if (goal.id == scope.model.goal.id) {
                            scope.projectName = goal.name;
                        }
                    });

                    scope.subscribe("StrategyGoalColorUpdated", function (goal) {
                        if (goal.id == scope.model.goal.id) {
                            scope.color = goal.color;
                        }
                    });


                    scope.loadSubGoals = function () {
                        scope.model.showSubGoals = !scope.model.showSubGoals;
                    }




                    scope.goToHub = function () {
                        //$state.go('goalDetails', { id: scope.model.project.id }, { reload: false });
                        if (scope.parentGoal) {
                            $state.go('goalDetails', { id: scope.model.goal.breakthroughGoalId, assetid: scope.model.goal.assetId }, { reload: false });
                        } else {
                            $state.go('goalDetails', { id: scope.model.goal.id }, { reload: false });
                        }
                        
                    }
                }
            }
        }]);
