import * as dashboardService from "@common/services/dashboardService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize} from "@common/components/dashboard/designer/common/appStyleService"


angular
    .module("DigiLean")
    .directive('productionBoardApp', ['debounce',
        function (debounce) {
            return {
                templateUrl: 'productionBoardApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.rows = [];

                    scope.theme = getDefaultAppTheme()

                    scope.postIts = dashboardService.getThemes()
                    scope.noOfDaysShown = 5;
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }

                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()

                    scope.$watch('layoutFactor', calcStyles)

                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) { 
                            if (settings.title) {
                                scope.title = settings.title;
                            }
                            if (settings.noOfDaysShown) {
                                scope.noOfDaysShown = settings.noOfDaysShown;
                            }
                            if (settings.rows) {
                                scope.rows = settings.rows;
                                scope.model = {rows: settings.rows,
                                    noOfDaysShown: scope.noOfDaysShown};
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            if (settings.noOfDaysShown) {
                                scope.noOfDaysShown = settings.noOfDaysShown;
                            }
                        } else {
                            //createTable();
                        }
                    });

                    scope.$watch("isAdminMode", function () {
                        /*if (scope.isAdminMode) {
                            scope.editable = true;
                        } else {
                            scope.editable = false;
                        }*/
                    });

                    scope.changeNoOfDaysShown= function(days){
                        scope.noOfDaysShown = parseInt(days);
                        scope.model = {rows: scope.rows,
                            noOfDaysShown: scope.noOfDaysShown};
                        scope.updateSettings();
                    }
                    
                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    scope.modelChangeHandler = function(model) {
                        scope.rows = model.rows;
                        scope.updateSettings();
                    }


                    scope.updateSettings = function () {
                        if (scope.isAdminMode) {
                            var componentSettings = {
                                title: scope.title,
                                //tableId: scope.tableId,
                                rows: scope.rows,
                                noOfDaysShown: scope.noOfDaysShown,
                                theme: scope.theme
                            };
                            scope.settingsChangeHandler({ settings: componentSettings });
                        }
                    }


                    scope.$on("widget-resized", function (event, args) {
                        resizeDebounced();
                    });

                    function resize() {
                        var host = $(elem).closest(".grid-stack-item-content");
                        if (host.length == 0)
                            host = $(elem).closest(".modal-body")
                        
                        if (host.length == 0) return;
                        var width = host.width();
                        var height = host.height();

                        // Find header to subtract from new height
                        var header = $(elem).find(".ibox-title");
                        var headerHeight = header.height() + 60;
                        var container = $(elem).find(".project-board-container");
                        if (container.length === 0) return;
                        $(container).css("height", (height - headerHeight));
                    }
                    const resizeDebounced = debounce(resize, 50, false)
                }
            }
        }]);

