import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("digileanCompareChart", ['dataService', '$uibModal', 'debounce',
        function (dataService, $uibModal, debounce) {
            return {
                templateUrl: 'comparechart.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'settings': '=',
                    'settingsChangeHandler': '&',
                    'globalTimePeriod': '@',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    var grafcolor = ['#2A2E36', '#0099DE', '#d9534f', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#17987e']

                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)
                    
                    scope.title = "";
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    scope.imageUrl = staticFileService.image('comparechart.jpg');
                    scope.dataSource = null;
                    scope.seriesData = [];
                    scope.filters = [];
                    var selectedGrouping = "month";
                    var companyfiscalmonth = customerService.getDefaultFiscalmonth() -1; //convert to moment month 
                    scope.timePeriod = {
                        period: {}
                    };

                    const timeSettings = {
                        timeframe: "thisvslastyear",
                        timeframes: ['thisvslastyear','thisfiscalvslastfiscalyear', 'last3years', 'last3fiscalyears'],
                        period: null,
                        getTimePeriod: function () {
                            return {
                                useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                                timeframes: timeSettings.timeframes,
                                timeframe: timeSettings.timeframe,
                                period: timeSettings.period
                            }
                        },
                        setTimePeriod: function (timeframe, period) {
                            timeSettings.timeframe = timeframe;
                            timeSettings.period = period;
                            // debounce, to make sure not starting loading several timeframes.
                            scope.options = {
                                timePeriod: timeSettings.getTimePeriod()
                            }
                        }
                    }
                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 200, false);
                    // Default options
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);


                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        getCompanySetting("fiscalmonth").then(function (fiscalsetting) {
                            if (fiscalsetting) {
                                companyfiscalmonth = parseInt(fiscalsetting)-1; //convertet to moment month
                            }
                        
                            if (settings) {
                                if (settings.timePeriod) {
                                    scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                                }                     
                                if (settings.filters) {
                                    scope.filters = settings.filters;
                                }
                                
                                if (settings.title) {
                                    scope.title = settings.title;
                                }
                                if (settings.theme) {
                                    scope.theme = settings.theme;
                                }
                                scope.dataSource = settings.dataSource;
                            }
                            if (!scope.dataSource && scope.isAdminMode){
                                setTimeout(function() {
                                    scope.selectDataSource();
                                }, 200); 
                            }
                        })
                    });

                    // Subscribe to component related event
                    scope.subscribe("DataValueAdded", function (value) {
                        if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                        initialize();
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                        initialize();
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        if (!scope.dataSource || scope.dataSource.id !== value.dataSourceId) return;
                        initialize();
                    });

                    function initialize() {
                        scope.seriesData = [];

                        if (!scope.dataSource) return;
                        if (!scope.options) return;
                        if (scope.options.timePeriod.timeframe == "last3years") {
                            var currentPeriod = {
                                from: moment().startOf('year'),
                                to: moment.utc()
                            };
                            var comparePeriod1 = {
                                from: moment().startOf('year').subtract(1, 'years'),
                                to: moment().endOf('year').subtract(1, 'years')
                            };
                            var comparePeriod2 = {
                                from: moment().startOf('year').subtract(2, 'years'),
                                to: moment().endOf('year').subtract(2, 'years')
                            };
                            getAllData(comparePeriod2, comparePeriod1, currentPeriod);
                        } else if (scope.options.timePeriod.timeframe == "thisvslastyear") {
                            var currentPeriod = {
                                from: moment().startOf('year'),
                                to: moment.utc()
                            };
                            var comparePeriod = {
                                from: moment().startOf('year').subtract(1, 'years'),
                                to: moment().endOf('year').subtract(1, 'years')
                            };
                            getAllData(comparePeriod, currentPeriod, null);
                        } else if (scope.options.timePeriod.timeframe == "last3fiscalyears") {
                            var currentFiscalYearStart;
                            if(companyfiscalmonth > moment().month()){
                                currentFiscalYearStart = moment().subtract(1, 'year').month(companyfiscalmonth).startOf("month");
                            } else {
                                currentFiscalYearStart = moment().month(companyfiscalmonth).startOf("month");
                            }
                            var currentFiscalYearEnd = moment(currentFiscalYearStart).add(12, "month").subtract(1, 'days').endOf("day");
                            var currentPeriod = {
                                from: currentFiscalYearStart,
                                to: currentFiscalYearEnd
                            };
                            var comparePeriod1 = {
                                from: moment(currentFiscalYearStart).subtract(1, 'years'),
                                to: moment(currentFiscalYearEnd).subtract(1, 'years')
                            };
                            var comparePeriod2 = {
                                from: moment(currentFiscalYearStart).subtract(2, 'years'),
                                to: moment(currentFiscalYearEnd).subtract(2, 'years')
                            };
                            getAllData(comparePeriod2, comparePeriod1, currentPeriod);
                        } else if (scope.options.timePeriod.timeframe == "thisfiscalvslastfiscalyear") {
                            var currentFiscalYearStart;
                            if(companyfiscalmonth > moment().month()){
                                currentFiscalYearStart = moment().subtract(1, 'year').month(companyfiscalmonth).startOf("month");
                            } else {
                                currentFiscalYearStart = moment().month(companyfiscalmonth).startOf("month");
                            }
                            var currentFiscalYearEnd = moment(currentFiscalYearStart).add(12, "month").subtract(1, 'days').endOf("day");
                            var currentPeriod = {
                                from: currentFiscalYearStart,
                                to: currentFiscalYearEnd
                            };
                            var comparePeriod = {
                                from: moment(currentFiscalYearStart).subtract(1, 'years'),
                                to: moment(currentFiscalYearEnd).subtract(1, 'years')
                            };
                            getAllData(comparePeriod, currentPeriod, null);
                        }
                    }

                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    function getData(period, order) {
                        if (!period) return;
                        var options = {
                            timePeriod: period,
                            filters: scope.filters
                        };
                        dataService().getValuesForPeriod(scope.dataSource.id, options).then(function (values) {
                            if (values) {
                                setupChartData(period, values, order);
                                setupChart();
                            }
                        });
                    }


                    function getAllData(period1, period2, period3) {
                        if (!period1 || !period2) return;
                        var options1 = {
                            timePeriod: period1,
                            filters: scope.filters
                        };
                        var options2 = {
                            timePeriod: period2,
                            filters: scope.filters
                        };

                        let promises = []
                        promises.push(dataService().getValuesForPeriod(scope.dataSource.id, options1))
                        promises.push(dataService().getValuesForPeriod(scope.dataSource.id, options2))
                        if(period3){
                            var options3 = {
                                timePeriod: period3,
                                filters: scope.filters
                            };
                            promises.push(dataService().getValuesForPeriod(scope.dataSource.id, options3))
                        }
                        Promise.all(promises).then(function (values) {
                            setupChartData(period1, values[0], 0);
                            setupChartData(period2, values[1], 1);
                            if(period3){
                                setupChartData(period3, values[2], 2);
                            }
                            setupChart();
                        });
                    }

                    /* This method will be called also when component is first initialized */
                    scope.periodChangedHandler = function (timePeriod) {

                        if (!timePeriod.timeframe) return;
                        scope.options.timePeriod = timePeriod;
                        initialize();

                        if (scope.isAdminMode) {
                            scope.updateSettings();
                        }

                    }

                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            timePeriod: scope.options.timePeriod,
                            dataSource: scope.dataSource,
                            filters: scope.filters,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    };

                    scope.registerData = function () {
                        var settings = {
                            dataSource: scope.dataSource
                        };

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'registrationDialog.html',
                            controller: 'registrationDialogController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                componentSettings: function () {
                                    return settings;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                        });
                    };

                    let selectDataSourceModal
                    scope.selectDataSource = function () {
                        if (selectDataSourceModal)
                            return

                        var hasDataSerie = false;
                        if (scope.dataSource) {
                            hasDataSerie = true;
                        }
                        selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return scope.dataSource;
                                },
                                filters: function () {
                                    return scope.filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return false;
                                }
                            }
                        });

                        selectDataSourceModal.result.then(function (result) {
                            if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                                scope.title = result.dataSource.title;
                            }
                            scope.dataSource = result.dataSource;
                            scope.filters = result.filters;
                            scope.updateSettings();
                            initialize();
                            scope.updateValue(scope.dataSource.id);
                        }, () => {}).finally(() => {
                            selectDataSourceModal = null
                        });
                    };

                    function getMonthLabel(indexorder){
                        if(scope.options.timePeriod.timeframe == "last3fiscalyears" ||
                            scope.options.timePeriod.timeframe == "thisfiscalvslastfiscalyear"){
                                var combined = indexorder + companyfiscalmonth;
                                if(indexorder + companyfiscalmonth > 12) {
                                    combined = combined - 12;
                                    return timeService.getMonthLabel(combined);
                                } else {
                                    return timeService.getMonthLabel(combined);
                                }
                        } else {
                            return timeService.getMonthLabel(indexorder);
                        }
                    }

                    function getYearLabel(period){
                       if(scope.options.timePeriod.timeframe == "last3fiscalyears" ||
                            scope.options.timePeriod.timeframe == "thisfiscalvslastfiscalyear"){
                            return period.to.year();
                        } else {
                            return period.from.year();
                        }
                    }

                    function setupChartData(period, values, order) {
                        // x-axis
                        scope.xAxisData = [];
                        var data = [];
                        var label = getYearLabel(period);
                        var yearProcessed = scope.seriesData.find(y => y.name === 'label')
                        if(yearProcessed) return;

                        if (selectedGrouping == "month") {
                            for (var i = 1; i < 13; i++) {
                                var xAxisDataLabel = getMonthLabel(i);
                                scope.xAxisData.push(xAxisDataLabel);
                                data[i - 1] = 0;
                            }
                            angular.forEach(values, function (dataValue) {
                                var month = moment(dataValue.valueDate).get('month');
                                var xAxisIndex = month;
                                if(scope.options.timePeriod.timeframe == "last3fiscalyears" ||
                                    scope.options.timePeriod.timeframe == "thisfiscalvslastfiscalyear"){
                                    var xAxisIndex = month - companyfiscalmonth;
                                    if(xAxisIndex < 0) {
                                        xAxisIndex = xAxisIndex + 12;
                                    }
                                }
                                data[xAxisIndex] = data[xAxisIndex] + dataValue.value;
                            });
                        } else {
                            // alert("No values for defined component");
                        }

                        var element = {
                            name: label,
                            color: grafcolor[order],
                            data: data,
                            index: order
                        };
                        scope.seriesData.push(element);

                    }

                    let currentChart = null
                    function setupChart() {

                        var renderElement = $(elem).find(".comparechart")[0];
                        currentChart = new Highcharts.chart({
                            chart: {
                                renderTo: renderElement,
                                type: 'column',
                                style: {
                                    fontSize: "0.9em"
                                }
                            },
                            title: {
                                text: ''
                            },
                            credits: {
                                enabled: false
                            }, exporting: {
                                enabled: false //remove context menu
                            },
                            xAxis: {
                                categories: scope.xAxisData,
                                labels: {
                                    style: {
                                        fontSize: "0.9em"
                                    }
                                }
                            },

                            yAxis: {
                                min: 0,
                                title: {
                                    text: scope.dataSource.valueElement.unit
                                },
                                labels: {
                                    style: {
                                        fontSize: "0.9em"
                                    }
                                }
                            },
                            plotOptions: {
                                column: {
                                    dataLabels: {
                                        enabled: true,
                                        style: {
                                            fontSize: "0.9em"
                                        }
                                    }
                                }
                            },
                            legend: {
                                itemStyle: {
                                    fontSize: "0.9em"
                                }
                            },
                            series: scope.seriesData

                        });
                        resizeChart();
                    }

                    scope.updateValue = function (id) {
                        if (!scope.definition) return;
                        if (scope.definition.datasource !== id) return;
                        initialize();
                    }

                    function resizeChart() {
                        if (!elem) return
                        let appEl = elem[0]
    
                        let width = appEl.offsetWidth
                        let height = appEl.offsetHeight
    
                        let headerEl = appEl.querySelector(".app-header")
                        if (headerEl)
                            height = height - headerEl.offsetHeight
                        
                        var chartContainer = $(elem).find(".highcharts-container").parent()
                        if (chartContainer.length === 0) return
    
                        if (currentChart)
                            currentChart.setSize(width, height, true)
                    }

                    scope.$on("widget-resized", function (event, args) {
                        resizeChart();
                    });

                }

            }
        }]);
