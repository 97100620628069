angular
    .module('DigiLean')
    .directive("smartActionListTaskdescriptionCell", ['$filter', '$uibModal','boardTaskService',
        function ($filter, $uibModal, boardTaskService) {
            return {
                templateUrl: 'smartActionListTaskdescriptionCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'users': '<',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.text = "";
                    scope.active = false;

                    scope.$watch('model', function (model) {
                        scope.text = model.task.text;
                        if(model.task.text !== ""){
                            scope.hasDescription = true; 
                        }  
                        model.getSortValue = function() {
                            return scope.text;
                        }
                    });

                    scope.subscribe('TaskUpdated', function (task) {
                        if (task.id == scope.model.task.id) {
                            if (task.text) {
                                scope.text = task.text;
                            } else {
                                scope.text = "";
                            }

                            if(task.text == ""){
                                scope.hasDescription = false; 
                            } else {
                                scope.hasDescription = true;
                            }
                        }
                    });
                    scope.openTask = function (mode) {
                        if (!mode) mode = "Info";
                        boardTaskService().get(scope.model.task.id).then(function (task) {
                            
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return mode;
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: true,
                                            users: scope.users
                                        }
                                        if(scope.model.task.a3Id){
                                            options.canChangeBoard = false;
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }]);
