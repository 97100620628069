angular
    .module('DigiLean')
    .directive("timeinitiativesmonthlyrealized", ['$translate', 'suggestionService',
        function ($translate, suggestionService) {
            return {
                templateUrl: 'timeinitiativesmonthlyrealized.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@'
                },
                link: function (scope, elem, attrs) {

                    var monthlyRealized = "";
                    var realized = "";
                    var estimated = "";
                    var yAxisText = "";
                   
                    $translate(['COMMON_HOURS','PROJECT_MONTHLY_REALIZED', 'A3_REALIZED', 'COMMON_ESTIMATED', 'IMPROVEMENT_FROM_PLANNED_TO_IN_PROGRESS', 'IMPROVEMENT_FROM_NEW_TO_PLANNED']).then(function (translations) {
                        monthlyRealized = translations.PROJECT_MONTHLY_REALIZED;
                        realized = translations.A3_REALIZED;
                        estimated = translations.COMMON_ESTIMATED;
                        yAxisText = translations.COMMON_HOURS;

                        getStatusData();     

                    });


                    

                    function getStatusData() {
                        var endDate = moment().utc().endOf('year');
                        var startDate = moment().utc().startOf('year');
                        // Monthly suggested, need to find start and end of month
                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };
                        suggestionService().getTimeInitiativeMonthlyRealized(timePeriod).then(function (result) {
                            var data = result;

                            var monthly = $(elem).find(".timeinitiativesmonthlyrealized")
                            Highcharts.chart({
                                chart: {
                                    renderTo: monthly[0],
                                    type: 'column'
                                },
                                title: {
                                    text: monthlyRealized,
                                    style: {
                                        color: '#676a6c',
                                        fontWeight: 600,
                                        fontSize: '1.2em',
                                        fontFamily: 'open sans'
                                    }
                                },
                                credits: {
                                    enabled: false
                                },
                                exporting: { enabled: false },
                                xAxis: {
                                    categories: data.estimated.monthNumbers
                                },
                                yAxis: [{
                                    min: 0,
                                    title: {
                                        text: yAxisText
                                    }
                                }],
                                legend: {
                                    shadow: false
                                },
                                tooltip: {
                                    shared: true
                                },
                                plotOptions: {
                                    column: {
                                        grouping: false,
                                        shadow: false,
                                        borderWidth: 0
                                    }
                                },
                                series: [{
                                    name: realized,
                                    color: '#5cb85c',
                                    data: data.realized.amount, //[100, 73, 50, 0,0,0,0,0,0,0,0,0],
                                    pointPadding: 0.25,
                                    pointPlacement: 0
                                }, {
                                    name: estimated,
                                    color: '#434338',
                                    data: data.estimated.amount, //[140, 90, 40, 50, 80, 80, 0, 20, 50, 70, 40, 70],
                                    pointPadding: 0.4,
                                    pointPlacement: 0
                                }]
                            });

                        });

                    }

                }

            }
        }]);



















