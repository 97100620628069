import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("register", function () {
            return {
                templateUrl: 'register.html',
                restrict: 'E',
                scope: {
                    'header': '@',
                    'component': '@',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                    assetService.getList().then(function (data) {
                        scope.assets = data;
                        scope.selectedAsset = scope.assets[0];
                    });


                    scope.toDate = moment().toDate()

                    scope.toDateChanged = function($event) {
                        const date = $event.originalEvent.detail
                        scope.toDate = date
                    }

                    var grafcolor = ['#2A2E36', '#0099DE', '#23c6c8', '#0077B5', '#5e5e5e', '#f8ac59', '#434348'],
                        graphtextcolor = '#ffffff';

                    var defaultSettings = {
                        background: "white"
                    };
                    scope.$watch('settings', function () {
                        // apply
                        console.log("Pie settings: " + scope.settings);
                        scope.settings = defaultSettings;

                    })

                    scope.settingsChangeHandler({
                        settings: defaultSettings
                    })
                    

                    var host = $(elem).closest(".widget-component");
                    var width = $(host).width() * 0.4;
                    var height = $(host).height() * 0.4;
                    var pieElement = $(elem).find(".piehourschart")[0];
                    Highcharts.chart({
                        chart: {
                            renderTo: pieElement,
                            height: height,
                            width: width,
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: 'pie'
                        },
                        title: {
                            text: ''
                        },
                        credits: {
                            enabled: false //remove link to highcharts.com
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                                    /*style: {
                                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                                },*/
                                    connectorColor: 'silver'
                                }
                            }
                        },
                        series: [{
                            name: 'Fordeling',
                            data: [
                                { name: 'A', color: grafcolor[0], y: 56.33 },
                                {
                                    name: 'B',
                                    color: grafcolor[1],
                                    y: 24.03,
                                    sliced: true,
                                    selected: true
                                },
                                { name: 'C', color: grafcolor[2], y: 10.38 },
                                { name: 'D', color: grafcolor[3], y: 4.77 },
                                { name: 'E', color: grafcolor[4], y: 0.91 },
                                { name: 'Andre', color: grafcolor[5], y: 0.2 }
                            ]
                        }]
                    });





                    // Bar chart: Hours per day per asset
                    var highChart = $(elem).find(".registerchart").highcharts({
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: 'Ukeoversikt'//'Overview week'
                        },
                        credits: {
                            enabled: false
                        }, exporting: {
                            enabled: false //remove context menu
                        },
                        xAxis: {
                            categories: ['26. jun', '27. jun', '28. jun', '29. jun', '30. jun']
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: 'Dine totale antall timer'//'Total hours'
                            },
                            stackLabels: {
                                enabled: true,
                                style: {
                                    fontWeight: 'bold',
                                    color: grafcolor || graphtextcolor
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>{point.x}</b><br>',
                            pointFormat: '{series.name}: {point.y}<br>' + $translate.instant('COMMON_TOTAL') + ': {point.stackTotal}'
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        },
                        series: [{
                            name: 'Prosjekt A',//'Nyskaping DigiLEAN',
                            color: grafcolor[0],
                            data: [5, 3, 4, 7, 2]
                        }, {
                            name: 'Prosjekt B',
                            color: grafcolor[2],
                            data: [2, 2, 3, 2, 1]
                        }, {
                            name: 'Prosjekt C',
                            color: grafcolor[1],
                            data: [3, 4, 4, 2, 5]
                        }]


                    });

                }

            }
        });
