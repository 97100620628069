import { RectangleObject } from "@common/model/types"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('resolution-displayer')
export class ResolutionDisplayer extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
        }
        
    `
    
    private _res: RectangleObject = {width: 0, height: 0}
    @property({attribute: false})
    set res(value: RectangleObject | null) {
        if (value)
            this._res = value
        else
            this._res = {width: 0, height: 0}
        this.requestUpdate()
    }
    get res(): RectangleObject {
        return this._res
    }

    render() {
        return html`
            ${this.res.width}x${this.res.height}
        `
    }
}
    