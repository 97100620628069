var DigiLean = angular.module('DigiLean');
DigiLean.controller('excelImportController', ['$scope', '$uibModal', '$translate', 'modalService', 'authService', 'importExportService', 'dataSourceService', 'dataService', 'projectService',
    function ($scope, $uibModal, $translate, modalService, authService, importExportService, dataSourceService, dataService, projectService) {

        // $scope.existingDataSource = null;
        // $scope.dataSource = {};
        // $scope.dataSourceDescription = null;
        // $scope.allDataSources = null;
        // var dimensionCount = 1;
        // $scope.usedColumns = null;
        // $scope.uploadedFile = null;
        // $scope.allProjects = null;
        // $scope.missingProjects = [];


        // $scope.fileParsed = function (data) {
        //     $scope.data = null;
        //     $scope.existingValues = null;
        //     $scope.existingDataSource = null;
        //     $scope.data = data;
        //     setDefaults();
        //     loadDataSources();
        //     loadProjects();

        //     // localStorage["exceldata"] = JSON.stringify($scope.data);
        // };


        // $scope.options = [
        //     { value: "valueDate", display: "COMMON_DATE" },
        //     { value: "value", display: "COMMON_VALUE" },
        //     { value: "assetId", display: "COMMON_ASSET" },
        //     { value: "areaId", display: "COMMON_AREA" },
        //     { value: "projectId", display: "COMMON_PROJECT" },
        //     { value: "dimension", display: "COMMON_DIMENSION" },
        //     { value: "dimension2", display: "COMMON_DIMENSION2" },
        //     { value: "dimension3", display: "COMMON_DIMENSION3" },
        //     { value: "dimension4", display: "COMMON_DIMENSION4" },
        // ];

        // $scope.getOptions = function (selectedRef) {
        //     $scope.options.forEach(element => {
        //         if (element.value == selectedRef)
        //             element.inUse = false;
        //     });
        //     return $scope.options;
        // }

        // $scope.findExisting = function () {
        //     var datasource = getDataSource();

        //     dataSourceService().match(datasource).then(function (result) {
        //         $scope.existingDataSource = result;
        //         readValues();
        //     });
        // }

        // function getDataSource() {

        //     return {
        //         title: $scope.dataSource.name,
        //         description: $scope.dataSource.description,
        //         objectSource: "external",
        //         unitOfTime: "",
        //         assetId: getRefId("assetId"),
        //         areaId: getRefId("areaId"),
        //         projectId: getRefId("projectId"),
        //         dateElement: getDate(),
        //         valueElement: getValue(),
        //         elements: getElements()
        //     };
        // }

        // $scope.drop = function (el) {
        //     console.log(el);
        // };

        // var loadProjects = function () {
        //     projectService().getList().then(function (result) {
        //         $scope.allProjects = result;
        //     });
        // }

        // var loadDataSources = function () {
        //     dataSourceService().getList().then(function (result) {
        //         $scope.allDataSources = result;
        //     });
        // }

        // $scope.checkIfAlreadyExists = function (title) {

        //     if (!$scope.allDataSources)
        //         return false;
        //     return $scope.allDataSources.filter(x => x.title == title).length > 0;
        // };

        // $scope.createDataSource = function () {

        //     var datasource = getDataSource();
        //     dataSourceService().add(datasource).then(function (result) {
        //         $scope.existingDataSource = result;
        //         $scope.writeValuesToDataSource();
        //         loadDataSources();
        //     });
        // };

        // $scope.eraseAllExistingValues = function () {
        //     dataService().deleteAllValues($scope.existingDataSource.id).then(function (result) {
        //         $scope.findExisting();
        //     });
        // };

        // $scope.writeValuesToDataSource = function () {


        //     var values = [];
        //     findMissingProjects();
        //     projectService().addProjects($scope.missingProjects).then(function (result) {  // first add any missing projects
        //         let newProjects = result;
        //         newProjects.forEach(p => {
        //             $scope.allProjects.push(p);
        //         });

        //         $scope.data.series[0].rows.forEach(row => {
        //             var value = {}
        //             $scope.data.series[0].ref.forEach((ref, index) => {
        //                 if (ref != null && $scope.data.series[0].include[index]) {  //has value and is selected
        //                     var readValue = row.values[index];
        //                     value[ref] = ref == "valueDate" ? convertTimestamp(readValue) :
        //                         ref == "projectId" ? getProjectId(readValue) : readValue;
        //                 }
        //             });
        //             values.push(value);
        //         });
        //         dataService().addList($scope.existingDataSource.id, values).then(function (result) {
        //             $scope.findExisting();
        //         });
        //     });
        // }

        // function getProjectId(projectNumber) {
        //     let project = $scope.allProjects.filter(p => p.projectNumber && p.projectNumber.toLowerCase() == projectNumber.toString().toLowerCase())[0];
        //     return project.id;
        // }

        // function findMissingProjects() {
        //     var indexData = $scope.data.series[0].index;
        //     var projectIndex = indexData.ref.indexOf("projectId");

        //     $scope.data.series[0].rows.forEach(r => {
        //         var projectNumber = r.values[projectIndex].toString().toLowerCase();
        //         if ($scope.allProjects.filter(p => p.projectNumber && p.projectNumber.toLowerCase() == projectNumber).length == 0) {
        //             if ($scope.missingProjects.filter(m => m.projectNumber == projectNumber).length == 0) { //check if already added 
        //                 $scope.missingProjects.push({ name: projectNumber, projectNumber: projectNumber });
        //             }
        //         }
        //     });

        //     console.log("missing:")
        //     console.log($scope.missingProjects);
        // }


        // function convertTimestamp(value) {

        //     var timeStamp = moment(value, $scope.dataSource.timeStampFormat);
        //     return timeStamp.format();
        // }

        // function determineTimeFormat(nameIndex) {
        //     let dates = $scope.data.series[0].rows.map(r => r.values[nameIndex]);
        //     $scope.dataSource.timeStampFormat = 'YYYY.MM.DD HH.mm.ss';

        //     let dateSeparator = '.';
        //     let timeSeparator = '.';

        //     let firstPart = [];
        //     let secondPart = [];
        //     let thirdPart = [];

        //     dates.forEach(d => {
        //         if (d.includes('/'))
        //             dateSeparator = '/';
        //         if (d.includes('-'))
        //             dateSeparator = '-';

        //         let dateStrings = d.substring(0, 10).split(dateSeparator);
        //         firstPart.push(dateStrings[0].trim());
        //         secondPart.push(dateStrings[1].trim());
        //         thirdPart.push(dateStrings[2].trim());

        //         if (d.includes(':'))
        //             timeSeparator = ':';

        //     });

        //     let firstVals = firstPart.map(f => +f);
        //     let firstMax = Math.max(...firstVals);
        //     // let firstLength = firstPart.map(f => f.length);
        //     // let firstLengthMax = Math.max(...firstLength);

        //     let secondVals = secondPart.map(s => +s);
        //     let secondMax = Math.max(...secondVals);
        //     // let secondLength = secondPart.map(s => s.length);
        //     // let secondLengthMax = Math.max(...secondLength);

        //     let thirdVals = thirdPart.map(t => +t);
        //     let thirdMax = Math.max(...thirdVals);
        //     // let thirdLength = thirdPart.map(t => t.length);
        //     // let thirdLengthMax = Math.max(...thirdLength);

        //     let maxValues = [{ part: "first", value: firstMax }, { part: "second", value: secondMax }, { part: "third", value: thirdMax }];
        //     let maxValuesSorted = maxValues.sort((a, b) => {
        //         return a.value - b.value;
        //     });

        //     let format = {};
        //     let components = ["MM", "DD", "YYYY"];
        //     maxValuesSorted.forEach((v, index) => {
        //         format[v.part] = components[index];
        //     });

        //     $scope.dataSource.timeStampFormat = format.first + dateSeparator + format.second + dateSeparator + format.third + " HH" + timeSeparator + "mm" + timeSeparator + "ss";

        // }

        // $scope.convertToLocal = function (utcDateString) {
        //     return moment(utcDateString).format($scope.dataSource.timeStampFormat);
        // }

        // $scope.init = function (nameIndex) {
        //     let name = $scope.data.series[0].name[nameIndex].toLowerCase();
            
        //     let key = null;
        //     if (name.includes("date")
        //         || name.includes("dato")
        //         || name.includes("timestamp")
        //         || name.includes("tid")
        //     ) {
        //         key = "valueDate";
        //         determineTimeFormat(nameIndex);
        //     }
        //     else if (name.includes("asset")
        //         || name.includes("enhet")
        //         || name.includes("group")
        //         || name.includes("gruppe"))
        //         key = "assetId";
        //     else if (name.includes("area")
        //         || name.includes("område")
        //         || name.includes("avdeling")
        //         || name.includes("department"))
        //         key = "areaId";
        //     else if (name.includes("project")
        //         || name.includes("prosjekt")
        //         || name.includes("product")
        //         || name.includes("produkt"))
        //         key = "projectId";
        //     else if (name.includes("value")
        //         || name.includes("verdi")
        //         || name.includes("timer")
        //         || name.includes("hours")
        //         || name.includes("count")
        //         || name.includes("antall")
        //         || name.includes("point")
        //         || name.includes("poeng")
        //     )
        //         key = "value";
        //     else {
        //         key = "dimension";
        //         if (dimensionCount > 1)
        //             key = key + dimensionCount;

        //         dimensionCount++;
        //     }

        //     var option = $scope.options.filter(x => x.value == key)[0];
        //     $scope.data.series[0].ref[nameIndex] = option.value;
        // }

        // function readValues() {
        //     $scope.usedColumns = {};
        //     var options = {
        //         numberOfValues: 500,
        //         sortOrder: "ASC"
        //     };
        //     dataService().getLatestValues($scope.existingDataSource.id, options).then(function (result) {

        //         result.forEach(record => {
        //             if (record.assetId)
        //                 $scope.usedColumns['assetId'] = true;
        //             if (record.areaId)
        //                 $scope.usedColumns['areaId'] = true;
        //             if (record.projectId)
        //                 $scope.usedColumns['projectId'] = true;
        //             if (record.dimension)
        //                 $scope.usedColumns['dimension'] = true;
        //             if (record.dimension2)
        //                 $scope.usedColumns['dimension2'] = true;
        //             if (record.dimension3)
        //                 $scope.usedColumns['dimension3'] = true;
        //             if (record.dimension4)
        //                 $scope.usedColumns['dimension4'] = true;
        //         }
        //         );


        //         $scope.existingValues = result;
        //         setProjectNumbers();

        //     });
        // }

        // function setProjectNumbers() {
        //     $scope.existingValues.forEach(v => {
        //         let project = $scope.allProjects.filter(p => p.id == v.projectId)[0];
        //         if (project) {
        //             v.projectNumber = project.projectNumber;
        //         }
        //     });
        // }

        // $scope.selectedHeader = function (header) {
        //     if ($scope.excelData)
        //         localStorage["exceldata"] = $scope.excelData;

        // }

        // $scope.selectedRef = function (index) {
        //     if ($scope.excelData)
        //         localStorage["exceldata"] = $scope.excelData;
        //     var ref = $scope.data.series[0].ref[index];
        //     var refObject = $scope.options.filter(x => x.value == ref)[0];
        //     if (refObject)
        //         refObject.inUse = refObject.inUse != true ? true : false;
        // }

        // function getRefId(ref) {
        //     var indexData = $scope.data.series[0].index;
        //     var refIndex = indexData.ref.indexOf(ref);
        //     if (refIndex > -1 && indexData.include[refIndex])
        //         return indexData.name[refIndex];
        //     return null;
        // }

        // function getDate() {
        //     return {
        //         label: "date",
        //         type: "DAY",
        //         isMandatory: true
        //     }
        // }

        // function getValue() {
        //     return {
        //         label: "value",
        //         type: "number",
        //         unit: $scope.dataSource.unit,
        //         isMandatory: true
        //     }
        // }

        // function setDefaults() {
        //     let sourceName = $scope.data.series[0].name.toString();
        //     let unit = null;
        //     if (sourceName.includes('[')){
        //         let nameAndUnit = sourceName.split('[');
        //         sourceName = nameAndUnit[0].trim();
        //         unit = nameAndUnit[1].trim();
        //     }

        //     $scope.dataSource.name = sourceName;
        //     $scope.dataSource.description = "";
        //     $scope.dataSource.unit = unit;

        //     dimensionCount = 1;
        //     $scope.data.series[0].name.forEach((element, index) => {
        //         $scope.init(index);
        //     });


        // }

        // function getElements() {
        //     var indexData = $scope.data.series[0].index;
        //     var elements = []
        //     for (var i = 0; i < indexData.ref.length; i++) {
        //         if (indexData.ref[i]) {
        //             if (indexData.ref[i].toLowerCase().includes("dimension") && indexData.include[i])
        //                 elements.push({
        //                     label: indexData.name[i],
        //                     sourceColumn: indexData.ref[i],
        //                     type: "text",
        //                     isMandatory: true
        //                 });
        //             if (indexData.ref[i].toLowerCase().includes("area") && indexData.include[i])
        //                 elements.push({
        //                     label: indexData.name[i],
        //                     sourceColumn: indexData.ref[i],
        //                     type: "area",
        //                     isMandatory: true
        //                 });

        //             if (indexData.ref[i].toLowerCase().includes("asset") && indexData.include[i])
        //                 elements.push({
        //                     label: indexData.name[i],
        //                     sourceColumn: indexData.ref[i],
        //                     type: "asset",
        //                     isMandatory: true
        //                 });
        //             if (indexData.ref[i].toLowerCase().includes("project") && indexData.include[i])
        //                 elements.push({
        //                     label: indexData.name[i],
        //                     sourceColumn: indexData.ref[i],
        //                     type: "project",
        //                     isMandatory: true
        //                 });
        //         }
        //     }

        //     return elements;
        // }

        // $scope.addExcelJob = function () {
        //     $uibModal.open({ backdrop: 'static',
        //         animation: true,
        //         templateUrl: 'newJob.html',
        //         controller: 'newJobController',
        //         resolve: {
        //             job: function () {
        //                 return {
        //                     jobType: 'Excel',
        //                     dataSource: $scope.dataSource,
        //                     mapping: JSON.stringify($scope.data.series[0].index),
        //                     fileName: $scope.data.fileName
        //                 };
        //             }
        //         }
        //     });
        // }

        // loadDataSources();

        // if (localStorage["exceldata"]) {
        //     $scope.data = JSON.parse(localStorage["exceldata"]);
        //     setDefaults();
        // }

        // if ($scope.data) {
        //     $scope.findExisting();
        // }

    }]);