angular
    .module('DigiLean')
    .directive("a3TitleCell", ['$filter', '$translate','a3Service',
        function ($filter, $translate, a3Service) {
            return {
                templateUrl: 'a3TitleCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'id': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.active = false;

                    scope.$watch('model', function (model) {
                        scope.title = model.a3.title;
                        model.getSortValue = function() {
                            return scope.title;
                        }
                    });

                    scope.subscribe("A3TitleChanged", function (a3) {
                        if (a3.a3Id == scope.model.a3.id) {
                            if (a3.title) {
                                scope.title = a3.title;
                            } else {
                                scope.title = "";
                            }
                        }
                    });

                    scope.setActive = function() {
                        scope.active = true;   
                        setTimeout(function() {
                            $(".project-name").focus();
                        }, 50 );                        
                    }
                    scope.titleChanged = function(title) {
                        scope.active = false;  
                        if (!title) return;
                        if(title !== scope.title){
                            var data = 
                            {
                                a3Id : scope.model.a3.id,
                                title: title
                            };
                            a3Service().updateA3Title(data).then(function (result) {                         
                            });
                        }

                    }
                }
            }
        }]);
