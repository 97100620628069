import * as customerService from "@common/services/customers/customerService"

angular
    .module('DigiLean')
    .controller("customerAccountModalController", ['$scope', '$uibModalInstance', 'account', 'updated',
        function ($scope, $uibModalInstance, account, updated) {

            $scope.account = account;

            $scope.currentSubscription = account.subscription;
            $scope.currentRegularLicenses = account.userLicenses;
            $scope.currentMobileLicenses = account.mobileLicenses;

            $scope.showWarningMessageMobileUserLicenses = false;
            $scope.showWarningMessageUserLicenses = false;

            $scope.updated = updated;

            $scope.translationData = {
                currentSubscription: $scope.currentSubscription,
                currentRegularLicenses: $scope.currentRegularLicenses,
                currentMobileLicenses : $scope.currentMobileLicenses
            };

            $scope.translationData2 = {
                activeLicenses: $scope.activeLicenses
            };


   
            $scope.updateSubscription = function (sub) {
                $scope.account.subscription = sub;
            };

            $scope.checkActiveUsers = function (newNumber) {
                if(!newNumber || newNumber<10) {
                    $scope.account.userLicenses = 10;
                    return;
                }
                if($scope.activeLicenses){
                    if(newNumber<$scope.activeLicenses){
                            
                        $scope.showWarningMessageUserLicenses = true;
                    } else {
                        $scope.showWarningMessageUserLicenses = false;
                    }
                } else {
                    customerService.getActiveUserLicenses().then(function (active) {
                        $scope.activeLicenses = active;
                        if(newNumber<active){                 
                            $scope.showWarningMessageUserLicenses = true;
                        } else {
                            $scope.showWarningMessageUserLicenses = false;
                        }
                    });
                }
            };

            $scope.checkActiveMobileUsers = function (newNumber) {
                if( $scope.activeMobileLicenses){
                    if(newNumber<$scope.activeMobileLicenses){
                        $scope.showWarningMessageMobileUserLicenses = true;
                    } else {
                        $scope.showWarningMessageMobileUserLicenses = false;
                    }
                } else {
                    customerService.getActiveMobileLicenses().then(function (active) {
                        $scope.activeMobileLicenses = active;
                        if(newNumber<active){
                            $scope.showWarningMessageMobileUserLicenses = true;
                        } else {
                            $scope.showWarningMessageMobileUserLicenses = false;
                        }
                    });
                }
            };

            
            $scope.decreaseRegularLicences = function(){
                if($scope.account.userLicenses > 10){
                    $scope.account.userLicenses = $scope.account.userLicenses - 10;
                    $scope.checkActiveUsers($scope.account.userLicenses);
                }
            }
            $scope.increaseRegular = function(){
                $scope.account.userLicenses = $scope.account.userLicenses + 10;   
                $scope.checkActiveUsers($scope.account.userLicenses);
            }
            
            $scope.decreaseMobile = function(){
                if($scope.account.mobileLicenses >= 10){
                    $scope.account.mobileLicenses = $scope.account.mobileLicenses - 10;           
                } else {
                    $scope.account.mobileLicenses = 0;
                }
                $scope.checkActiveMobileUsers($scope.account.mobileLicenses);
            }
            $scope.increaseMobile = function(){
                $scope.account.mobileLicenses = $scope.account.mobileLicenses + 10; 
                $scope.checkActiveMobileUsers($scope.account.mobileLicenses);  
            }
            

            $scope.ok = function () {
                if($scope.showWarningMessageMobileUserLicenses || $scope.showWarningMessageUserLicenses) return;
                if($scope.currentSubscription != $scope.account.subscription || 
                    $scope.currentRegularLicenses != $scope.account.userLicenses ||
                    $scope.currentMobileLicenses != $scope.account.mobileLicenses){
                        $scope.updated = true;
                    }
                $uibModalInstance.close($scope.updated);
            };

            $scope.close = function () {
                $scope.account.subscription = $scope.currentSubscription;
                $scope.account.userLicenses = $scope.currentRegularLicenses;
                $scope.account.mobileLicenses = $scope.currentMobileLicenses;
                $uibModalInstance.dismiss('cancel');
            }
        }]);