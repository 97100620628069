﻿/*Deprecated???*/
angular.module("DigiLean").service('dataValueCenter', function () {
    return { Initialize: Initialize };
    function Initialize(scope) {
        console.log('dataValueCenter init');
        // We should only subscribe to Send events so we only log events once.
        scope.subscribe("TaskStatusUpdated", function (taskStatus) {

            var source = {
                dataSource: "CompletedTasks",
                id: taskStatus.taskId,
                value: taskStatus.status
            }
            publishValue(source);
        });

        function publishValue(activity) {
            scope.publish("SendDataSourceUpdated", activity);
        }
    }
}).run(['$rootScope', 'dataValueCenter', function ($rootScope, dataValueCenter) {
    dataValueCenter.Initialize($rootScope);
}]);