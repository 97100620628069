import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("actionlistBoardtasks", ['$filter', '$uibModal', '$state','boardTaskService', 'actionlistService', 'taskDrawingService',
        function ($filter, $uibModal, $state, boardTaskService, actionlistService, taskDrawingService) {
            return {
                templateUrl: 'actionlistBoardtasks.html',
                restrict: 'E',
                scope: {
                    'board': '=',
                    'week': '=',
                    'readonly': '@',
                    'viewcontext': '=',
                    'filter': '<?',
                    'currentBoardId': '='
                },
                link: function (scope, element, attrs) {
                    scope.tasks = [];
                    var alltasks = [];
                    scope.taskCount = 0;
                    scope.taskOverdueCount = 0;
                    
                    
                    scope.$watch("board", function () {
                        if (!scope.board) return;
                        scope.board.hasLoadedTasks = false;
                        // refreshBoardTasks(scope.board.id);
                        setupDragDrop();
                    });

                    scope.toggleOpenBoard = function() {
                        if (!scope.board) return;
                        if (!scope.board.hasLoadedTasks) {
                            refreshBoardTasks(scope.board.id);
                        }
                    }

                    scope.$watch("currentBoardId", function () {
                        if (!scope.currentBoardId) return;
                        // Only load tasks for current board directlu.
                        if (scope.board && scope.currentBoardId == scope.board.id) {
                            refreshBoardTasks(scope.board.id);
                        }
                        
                    });
                    scope.$watch("viewcontext", function () {
                        if (!scope.viewcontext) return;
                    });

                    

                    scope.$watch("filter", function (filter) {
                        if (!filter){
                            scope.tasks = alltasks;
                            return;
                        } 
                        if (filter.userId) {
                            filterByUser(filter.userId);
                        } else if (filter.tags) {
                            filterByTags(filter.tags);
                        } else if (filter.color) {
                            filterByColor(filter.color);
                        }else {
                            scope.tasks = alltasks;   
                        }
                    });

                    function filterByTags(tags){
                        if(tags.length == 0){
                            scope.tasks = alltasks;
                        }else {
                            scope.tasks = [];
                            for(var t=0; t<alltasks.length; t++){
                                if(alltasks[t].tags){
                                    for(var i=0; i<alltasks[t].tags.length; i++){
                                        for(var selected=0; selected<tags.length; selected++){
                                            if(alltasks[t].tags[i].tag == tags[selected].tag){
                                                var exists= $filter('filter')(scope.tasks, { id: alltasks[t].id }, true);
                                                if(exists.length == 0){
                                                    scope.tasks.push(alltasks[t]);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                    function filterByUser(userId){
                        scope.tasks = $filter('filter')(alltasks, { responsibleUserId: userId }, true);
                    }
                    function filterByColor(color){
                        scope.tasks = $filter('filter')(alltasks, { color: color }, true);
                    }
                    function isViewTimeBoard() {
                        if (scope.viewcontext && scope.viewcontext.view === "TimeBoard") return true;
                        return false;
                    }
                    function setupDragDrop() {
                        // Make board container droppable to accept tasks from external sources, including Timeboard.
                        $(element).find(".actionlist-container").droppable({
                            drop: function (event, ui) {
                                if($(ui.draggable).attr("data-id")){ //prevents post-it to be directly dragged to actionlist
                                    $(this).removeClass("ui-state-highlight");
                                    var id = 0;
                                    // Timeobard drop check
                                    var isTimeBoardEvent = $(ui.draggable).hasClass("fc-event");
                                    if (isTimeBoardEvent === true) {
                                        id = $(ui.draggable).attr("data-id");
                                        moveTaskToActionList(id, function () {
                                            // Remove from timeboard.
                                            if (isViewTimeBoard) {
                                                $('.calendar').fullCalendar('removeEvents', id);
                                            }

                                        });
                                        return;
                                    }

                                    var isPortlet = $(ui.draggable).hasClass("portlet");
                                    if (isPortlet === true || isActionItem) {
                                        id = $(ui.draggable).attr("data-id");
                                        moveTaskToActionList(id, function () {
                                            //refreshBoardTasks(scope.board.id);
                                        });
                                        return;
                                    }

                               // 
                                    var isActionItem = $(ui.draggable).attr("data-is-action-item").length > 0;
                                    if (isActionItem) {
                                        id = $(ui.draggable).attr("data-id");
                                        moveTaskToActionList(id, function () {
                                            //refreshBoardTasks(scope.board.id);
                                        });
                                    }
                                    // From weeklyboard / kanban board
                                }
                                
                      
                            }, over: function (event, ui) {
                                if($(ui.draggable).attr("data-id")){
                                    $(this).addClass("ui-state-highlight")
                                    scope.board.isOpen = true;
                                    scope.toggleOpenBoard();
                                }
                            },
                            out: function (event, ui) {
                                $(this).removeClass("ui-state-highlight")
                            }
                        });

                        $(element).find(".actionlist").sortable({
                            connectWith: ".boardcell, .actionlist, fc-event-container, task-container",
                            placeholder: "portlet-placeholder ui-corner-all",
                            receive: function (e, ui) {
                                if (ui) {
                                    ui.sender.sortable("cancel");
                                }
                            }
                        });
                    }

                    function moveTaskToActionList(id, completeCallBack) {
                        boardTaskService().get(id).then(function (task) {
                            var currentBoardId = task.boardId;
                            task.boardId = scope.board.id;
                            boardTaskService().moveTaskToActionItem(task).then(function (result) {
                                scope.isProcessing = false;
                                completeCallBack();
                                scope.publish('SendTaskMovedToActionList', task);
                                if (currentBoardId !== scope.board.id) {
                                    var change = {
                                        taskId: id,
                                        fromBoardId: currentBoardId,
                                        toBoardId: scope.board.id
                                    };
                                    scope.publish("SendTaskChangedBoard", change);
                                }
                            });
                        });

                    }

                    /// HANDLING OF EVENTS
                    scope.subscribe('TaskMovedToActionList', function (task) {
                        if (!task.boardId) return; // we only support boards
                        if (task.boardId !== scope.board.id) return;
                        if (!isTaskPartOfBoard(task.id)) {
                            //refreshBoardTasks(scope.board.id);
                            addTaskToActionList(task);
                        }
                    });

                    scope.subscribe('ActionItemMovedToTask', function (task) {
                        if (isTaskPartOfBoard(task.id)) {
                            removeTaskFromActionList(task.id)
                        }
                    });

                    scope.subscribe('TaskDateMoved', function (moveSettings) {
                        // CHeck if task is part of list
                        var exists = $filter('filter')(scope.tasks, { id: moveSettings.taskId });
                        if (exists.length > 0) { 
                            for(var i = 0; i < exists.length; i++) {
                                exists[i].boardDate = moveSettings.boardDate;
                                var status = {
                                    status: exists[0].status,
                                    taskId: exists[0].id
                                }
                                updateTaskStatus(status);
                            }
                        }
                    });
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        updateTaskStatus(status);
                    });
                    scope.subscribe('TaskUpdated', function (task) {
                        updateTask(task);
                    });
                    scope.subscribe('TaskCommentAdded', function (task) {
                        updateTask(task);
                    });
                    scope.subscribe('TaskCommentDeleted', function (task) {
                        updateTask(task);
                    });
                    scope.subscribe('TaskAssigned', function (task) {
                        updateTask(task);
                    });
                    scope.subscribe('TasksDeleted', function (taskIds) {
                        removeTasksFromActionList(taskIds);
                    });

                    // // Must refactor for every actiontype

                    scope.subscribe('CreateActionListItem', function (task) {
                        if (!task.boardId) return; // we only support boards
                        if (task.boardId !== scope.board.id) return;
                        if (task.responsibleUserId) return; //TaskAssign event will be triggered and all task will be relaoded (including this new task)
                        if (!isTaskPartOfBoard(task.id)) {
                            addTaskToActionList(task);
                        }
                    });
                    scope.subscribe('TaskCloned', function (task) {
                        if (!task.actionType) {
                            if (task.columnCategoryId) return; // We are not going to add task that has assigned columns, because they belong to board.
                            addTaskToActionList(task);
                        }
                    });
                    scope.subscribe('TaskDeleted', function (taskId) {
                        if (isTaskPartOfBoard(taskId)) {
                            removeTaskFromActionList(taskId)
                        }
                    });

                    // Team Board
                    /*scope.subscribe('TaskChangedBoard', function (change) {
                        if(change.toBoardId === null){
                            removeTaskFromActionList(change.taskId)
                        }
                        else if (change.toBoardId === scope.board.id || change.fromBoardId === scope.board.id) {
                            refreshBoardTasks(scope.board.id);
                        };

                    });*/
                    scope.subscribe('TaskChangedBoard', function (change) {
                        if(change.toBoardId === null){
                            if (isTaskPartOfBoard(change.taskId)) {
                                removeTaskFromActionList(change.taskId);
                            }
                        } else if (change.fromBoardId === scope.board.id && change.toBoardId !== scope.board.id) {
                            removeTaskFromActionList(change.taskId)
                        } else if (change.toBoardId === scope.board.id && change.fromBoardId !== scope.board.id) {
                            if (!isTaskPartOfBoard(change.taskId)) {
                                boardTaskService().get(change.taskId).then(function (task) {
                                    addTaskToActionList(task);
                                });
                            }
                        }
                    });
                    
                    function isTaskPartOfBoard(taskId) {
                        return $filter('filter')(scope.tasks, { id: taskId }).length > 0;
                    }

                    scope.changeStatus = function (task) {
                        task.status = taskDrawingService().getNextStatus(task.status).status;
                        var statusOptions = {
                            taskid: task.id,
                            status: task.status
                        };
                        boardTaskService().updateStatus(task.id, statusOptions).then(function (result) {
                            task.dateStyleClass = actionlistService().getTaskDateStyle(task);
                            updateTaskStatusCount();
                        });
                    };

                    scope.goToBoard = function (boardId) {
                        $state.go('board', { id: boardId }, { reload: false });
                    };

                    function updateTaskStatusCount() {
                        scope.taskCount = scope.tasks.length;
                        scope.taskOverdueCount = $filter('filter')(scope.tasks, { dateStyleClass: "overdue" }).length;
                        // Publish result
                        var status = {
                            boardId: scope.board.id,
                            total: scope.taskCount,
                            overdue: scope.taskOverdueCount
                        }
                        scope.publish("BoardActionListStatusChanged", status);
                    }

                    function updateTaskStatus(status) {
                        var tasks = $filter('filter')(scope.tasks, { id: status.taskId }, true);
                        if (tasks.length > 0) {
                            tasks[0].status = status.status;
                            tasks[0].dateStyleClass = actionlistService().getTaskDateStyle(tasks[0]);
                        }
                        updateTaskStatusCount();
                    }

                    function updateTask(task) {
                        var updatedtasks = $filter('filter')(scope.tasks, { id: task.id }, true);
                        if (updatedtasks.length > 0) {
                            refreshBoardTasks(scope.board.id);
                        }
                    }
                    function addTaskToActionList(task) {
                        scope.tasks.push(task);
                        updateTaskStatusCount();
                    }

                    function removeTasksFromActionList(taskIds) {
                        angular.forEach(taskIds, function (taskid, key) {
                            var matchingTasks = $filter('filter')(scope.tasks, { id: taskid }, true);
                            if (matchingTasks.length > 0) {
                                var i = scope.tasks.indexOf(matchingTasks[0]);
                                scope.tasks.splice(i, 1);
                            }
                        });
                        updateTaskStatusCount();
                    }
                    function removeTaskFromActionList(taskId) {
                        // remove task that is moved
                        var taskIds = [];
                        taskIds.push(taskId);
                        removeTasksFromActionList(taskIds);
                    }

                    scope.openForEdit = function (task, activeTab) {
                        if (!activeTab) activeTab = "Info";
                        boardTaskService().get(task.id).then(function (task) {
                            $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        var options = {
                                            archiveAvailable: false
                                        }
                                        return options;
                                    }
                                }
                            });
                        });
                    }

                    function compareBoardDate( a, b ) {
                        if (!a.boardDate) {
                            return -1;
                        }
                        if (!b.boardDate) {
                            return -1;
                        }
                        if ( a.boardDate < b.boardDate ){
                            return -1;
                        }
                        if ( a.boardDate > b.boardDate ){
                            return 1;
                        }
                        return 0;
                    }
                      
                     

                    scope.isLoading = true;
                    function refreshBoardTasks(boardId) {
                        scope.board.hasLoadedTasks = true;
                        scope.tasks = [];
                        scope.taskCount = 0;
                        scope.taskOverdueCount = 0;
                        var actionType = "BOARD";
                        if (scope.board.boardType == "SmartActionList") {
                            actionType = "SMARTACTIONLIST";
                        }
                        var timeperiod = timeService.getTimePeriodBeforeAfter('180', '14');
                        var actionTaskQuery = {
                            actionType: actionType,
                            // assetId: scope.assetid,
                            statuses: ['blank', 'unacceptable'],
                            timeperiod: timeperiod,
                            entityId: boardId
                        }
                        actionlistService().getActionTasks(actionTaskQuery).then(function (tasks) {
                            scope.isLoading = false;
                            for (var t = 0; t < tasks.length; t++) {
                                scope.tasks.push(tasks[t]);
                            }
                            var week = timeService.getCurrentWeek(moment().isoWeek(), moment().isoWeekYear());
                            var okTaskQuery = {
                                actionType: actionType,
                                assetId: scope.assetid,
                                statuses: ['OK'],
                                // entityId: boardId,
                                timeperiod: {
                                    from: week.startDate.toJSON(),
                                    to: week.endDate.toJSON()
                                }
                            }
                            actionlistService().getActionTasks(okTaskQuery).then(function (tasks) {
                                for (let t = 0; t < tasks.length; t++) {
                                    scope.tasks.push(tasks[t]);
                                }

                                for (let t = 0; t < scope.tasks.length; t++) {
                                    scope.tasks[t].dateStyleClass = actionlistService().getTaskDateStyle(scope.tasks[t]);
                                }
                                scope.tasks.sort( compareBoardDate );
                                alltasks = scope.tasks;

                                updateTaskStatusCount();
                            });
                        });

                    }

                }
            }
        }]);
