// Controller for the actual modal
import * as connectorAdminService from "@common/services/admin/connectorAdminService"
const DigiLean = angular.module('DigiLean')
DigiLean.controller('newJobController', ['$scope', '$uibModalInstance', 'dataSourceService', 'connectorId',
    function ($scope, $uibModalInstance, dataSourceService, connectorId) {
        
        $scope.connectorId = connectorId
        
        $scope.job = { connectorId: $scope.connectorId, cronExpression: '0/1 * * * ? *' }
        $scope.jobTypes = []
        $scope.dataSources = []

        $scope.cronOptions = {
            formInputClass: 'form-control cron-gen-input', // Form input class override
            formSelectClass: 'form-control cron-gen-select', // Select class override
            formRadioClass: 'cron-gen-radio', // Radio class override
            formCheckboxClass: 'cron-gen-checkbox', // Radio class override
            hideMinutesTab: false, // Whether to hide the minutes tab
            hideHourlyTab: false, // Whether to hide the hourly tab
            hideDailyTab: false, // Whether to hide the daily tab
            hideWeeklyTab: false, // Whether to hide the weekly tab
            hideMonthlyTab: false, // Whether to hide the monthly tab
            hideYearlyTab: false, // Whether to hide the yearly tab
            hideAdvancedTab: false, // Whether to hide the advanced tab
            use24HourTime: false, // Whether to show AM/PM on the time selectors
            hideSeconds: false // Whether to show/hide the seconds time picker
        }

        $scope.ok = async function () {
            $scope.job.enabled = true
            const newjob = await connectorAdminService.addJob($scope.job)
            $scope.publish("SendJobCreated", newjob)
            $uibModalInstance.close()
        }

        var loadData = async function () {
            const jobtypes = await connectorAdminService.getJobTypes()
            $scope.jobTypes = jobtypes


            const list = await dataSourceService().getList()
            $scope.dataSources = list
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('cancel')
        }

        loadData()
    }])