import * as staticFileService from "@common/shared/staticFileService"

angular
    .module('DigiLean')
    .directive("performanceBoardCard", 
        function () {
            return {
                templateUrl: 'performanceBoardCard.html',
                restrict: 'E',
                scope: {
                    "board" : "<",
                },
                link: function (scope, elem, attrs) {
                    scope.previewUrl = staticFileService.image("performanceboard-card.jpg");
                    
                    // scope.$watch('board', function (board) {
                    //     if (!board) return;
                    //     if (!board.name) {
                    //         board.name = $translate.instant("BOARD_SMARTACTIONLIST");
                    //     }
                    // });
                }
            };
        });
