﻿import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"

// Controller for the actual modal
var DigiLean = angular.module('DigiLean');
DigiLean.controller('newBoardFromTemplateController', ['$scope', '$uibModalInstance', 
    function ($scope, $uibModalInstance) {
        $scope.selectedTemplate = null;
        $scope.templates = [];
        
        // Default week
        $scope.weekYear = moment().isoWeekYear();
        $scope.weekNumber = moment().isoWeek();
        $scope.week = timeService.getCurrentWeek($scope.weekNumber, $scope.weekYear);
        $scope.translationData = {
            weekNumber: $scope.weekNumber
        };
        // Load templates
        boardService.getTemplates().then(function (templates) {
            $scope.templates = templates;
            // parse style
            angular.forEach(templates, function (template) {

                if (template.template.settings) {
                    var settings = JSON.parse(template.template.settings);
                    template.isWeeklyBoard = settings.IsWeeklyBoard;
                } else {
                    template.isWeeklyBoard = false;
                }
                parseStyle(template);
            });
            if (templates.length > 0) {
                $scope.selectedTemplate = templates[0];
            }
        });

        function parseStyle(template) {
            angular.forEach(template.columns, function (col) {
                col.style = {
                    "background": col.categoryStyle.backgroundColor,
                    "color": col.categoryStyle.foregroundColor
                }
            });
            angular.forEach(template.rows, function (row) {
                row.style = {
                    "background": row.categoryStyle.backgroundColor,
                    "color": row.categoryStyle.foregroundColor
                }
            });
        }

        $scope.selectedTemplateChanged = function (template) {
            $scope.selectedTemplate = template;
        }

        $scope.ok = function () {
            $uibModalInstance.close($scope.selectedTemplate);
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);