import {LitElement, css, html, TemplateResult} from "lit"
import {customElement, property} from "lit/decorators.js"
import {ref, createRef, Ref} from "lit/directives/ref.js"
import {unsafeHTML} from "lit/directives/unsafe-html.js"
import {styleMap} from "lit/directives/style-map.js"
import {convertStringToLiteralModelGen, getInputsRecursive} from "@common/services/template/templateLiteral"
import { translateLabelInstant, translateLabel, selectedLang } from "@common/services/language/DigiLeanLang"

import { cloneDeep, merge } from "lodash"
import { Subscription } from "rxjs"


interface DigiLeanDialogOptions {
    title: string
    okBtnText?: string
    cancelBtnText?: string
    hideOkBtn?: boolean
}
enum DigiLeanDialogSizes {
    Minimum,
    Medium,
    Maximum
}

const defaultOptions: DigiLeanDialogOptions = {
    title: "Digilean dialog",
    okBtnText: "Ok",
    cancelBtnText: "Cancel",
    hideOkBtn: false
}

const defaultSize: DigiLeanDialogSizes = DigiLeanDialogSizes.Minimum

@customElement('digilean-dialog')
class DigiLeanDialogElement extends LitElement {

    private dialog: Ref<HTMLDialogElement> = createRef()
    private content: Ref<HTMLElement> = createRef()
    static styles = css`
        /* :host {
            
        } */
        * {
            box-sizing: border-box;
        }
        dialog {
            border: none !important;
            border-radius: 3px;
            box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
            padding: 0;
        }
        dialog #dialog-body {
            display: flex;
            flex-direction: column;
        }
        dialog #dialog-body section {
            flex: 1 1 auto;
        }
        dialog #dialog-body header, dialog #dialog-body section{
            padding: 1rem;
        }
        dialog #dialog-body menu {
            padding: 1rem;
        }
        dialog #dialog-body header h4 {
            padding: 0;
            margin: 0;
            flex-grow: 1;
            color: var(--digilean-primary-text);
        }
        header button#cancel {
            appearance: none;
            background: 0 0;
            border: 0;
            color: var(--digilean-primary-text);
            cursor: pointer;
            opacity: 0.5;
            font-size: 1em;
        }
        header button#cancel:hover {
            opacity: 1;
        }
        dialog header {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid var(--digilean-grey-rain);
        }
        dialog section {
            border-bottom: 1px solid var(--digilean-grey-rain);
        }

        dialog menu {
            display: flex;
            gap: 1rem;
            flex-direction: row;
            justify-content: end;
            margin: 0;
        }
    `

    constructor() {
        super()
        this.addEventListener(DialogResult.dialogOkFromOutside, ((e: CustomEvent) => {
            this.ok(e.detail)
        })  as EventListener, { once: false, passive: true } )
    }
    // createRenderRoot() {
    //     return this
    // }

    keydown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            this.cancel()
        }
    }

    open() {
        this.dialog.value?.showModal()
    }

    cancel() {
        this.dispatchCustomEvent(DialogResult.dialogCancel, "cancel")
        this.dialog.value?.close()
        this.cleanup()
    }

    ok(indata?: any) {
        let data: any
        if (indata)
            data = indata
        else
            data = this.getInputValues()
        this.dispatchCustomEvent(DialogResult.dialogOk, data)
        this.dialog.value?.close()
        this.cleanup()
    }
    cleanup() {
        this.body = "<p>digilean dialog</p>"
        this.bodyTemplate = null
        this.title = defaultOptions.title
        this.hideOkBtn = defaultOptions.hideOkBtn!
        let child = this.content.value?.lastElementChild
        while (child) {
            this.content.value?.removeChild(child)
            child = this.content.value?.lastElementChild
        }
    }
    
    getInputValues() {
        let values = {}
        if (!this.content.value || !this.content.value.children || this.content.value.children.length === 0)
            return values
        
        const inputElements = getInputsRecursive(this.content.value.children)
        const elements: HTMLInputElement[] = []
        inputElements.forEach(el => {
            if (el.dataset.ref)
                elements.push(el)
        })
        
        elements.map((el, index) => {
            const name = el.dataset.ref || el.name || `value${index}`
            const value = el.value
            values[name] = value
            return { name, value }
        })
        return values
    }

    dispatchCustomEvent(name: DialogResult, detail: any) {
        const options = { detail, bubbles: true, composed: true}
        this.dispatchEvent(new CustomEvent(name, options));
    }

    _title = defaultOptions.title
    @property({attribute: true})
    set title(value: string) {
        let title = value
        if (value.indexOf(' ') < 0)
            title = translateLabelInstant(value)
        this._title = title
    }
    
    get title() {
        return this._title
    }

    @property({attribute: false})
    body = "<p>digilean dialog</p>"

    @property({attribute: false})
    bodyTemplate: TemplateResult<1> | null = null

    @property({attribute: true})
    okText = defaultOptions.okBtnText

    _cancelText = defaultOptions.cancelBtnText
    @property({attribute: true})
    set cancelText(value: string) {
        let text = value
        text = translateLabelInstant(text)
        this._cancelText = text
    }
    get cancelText() {
        return this._cancelText!
    }

    @property({attribute: false})
    hideOkBtn = defaultOptions.hideOkBtn

    getSize() {
        return { minWidth: "64rem", minHeight: "48rem" }
    }
    render() {

        const dialogStyle = this.getSize()

        return html`
            <dialog ${ref(this.dialog)} @keydown=${(e) => this.keydown(e)}>
                <div id="dialog-body" style=${styleMap(dialogStyle)}>
                    <header>
                        <h4>${unsafeHTML(this.title)}</h4>
                        <button type="button" id="cancel" @click=${() => this.cancel()}>×</button>
                    </header>
                    <section ${ref(this.content)}>
                        ${this.bodyTemplate == null ? 
                            html`${unsafeHTML(this.body)}` : 
                            this.bodyTemplate
                        }
                    </section>
                    <menu>
                        <digilean-button @click=${() => this.cancel()}>${this.cancelText}</digilean-button>
                        ${this.hideOkBtn ? html`` : 
                            html`<digilean-button-primary @click=${() => this.ok()}>${this.okText}</digilean-button-primary>`
                        }
                    </menu>
                </div>
            </dialog>
        `
    }
    appendElement(el: HTMLElement) {
        this.content.value?.appendChild(el)
    }
}

export enum DialogResult {
    dialogOk = "dialog-ok",
    dialogCancel = "dialog-cancel",
    dialogOkFromOutside = "dialog-ok-from-outside"
}

class DigiLeanDialog {
    private dialog: DigiLeanDialogElement
    private langSub: Subscription | null = null
    constructor() {
        this.dialog = document.createElement("digilean-dialog") as DigiLeanDialogElement
        document.body.appendChild(this.dialog)
        this.langSub = selectedLang.subscribe(lang => {
            if (lang.ready)
                this.setTranslatedDefaultLabels()
        })
    }

    async setTranslatedDefaultLabels () {
        const okTextTranslated = await translateLabel("COMMON_OK")
        defaultOptions.okBtnText = okTextTranslated
        this.dialog.okText = okTextTranslated
        const cancelTextTranslated = await translateLabel("COMMON_CANCEL")
        defaultOptions.cancelBtnText = cancelTextTranslated
        this.dialog.cancelText = cancelTextTranslated
    }

    waitForClose(booleanRessult = false) {
        return new Promise((resolve, reject) => {
            this.dialog.addEventListener(DialogResult.dialogOk, ((e: CustomEvent) => {
                if (booleanRessult)
                    resolve(true)
                const data = e.detail
                resolve(data)
            }) as EventListener, { once: true})

            this.dialog.addEventListener(DialogResult.dialogCancel, ((e: CustomEvent) => {
                if (booleanRessult)
                    resolve(false)
                const data = e.detail
                reject(data)
            }) as EventListener, { once: true})
        })
    }
    openForm<T>(options: DigiLeanDialogOptions, template: string, model: T): Promise<T> {
        options = this.mergeOptions(options)
        this.setOptions(options)

        const tmp = convertStringToLiteralModelGen(template, model)
        ///@ts-ignore
        this.dialog.bodyTemplate = tmp
        
        this.dialog.open()
        return this.waitForClose() as Promise<T>
    }
    openHtml(options: DigiLeanDialogOptions, template: string) {
        options = this.mergeOptions(options)
        this.setOptions(options)
        this.dialog.body = template
        this.dialog.open()
        return this.waitForClose(true)
    }
    openElement(options: DigiLeanDialogOptions, el: HTMLElement) {
        options = this.mergeOptions(options)
        this.setOptions(options)
        this.dialog.body = ""
        this.dialog.appendElement(el)
        this.dialog.open()
        return this.waitForClose(true) as Promise<boolean>
    }
    confirm(options: DigiLeanDialogOptions, template: string) {
        options = this.mergeOptions(options)
        this.setOptions(options)

        this.dialog.body = template
        
        this.dialog.open()
        return this.waitForClose(true) as Promise<boolean>
    }

    setOptions(options: DigiLeanDialogOptions) {
        const { okBtnText, cancelBtnText, title, hideOkBtn } = options
        this.dialog.okText = okBtnText
        this.dialog.cancelText = cancelBtnText!
        this.dialog.title = title
        if (hideOkBtn)
            this.dialog.hideOkBtn = hideOkBtn
    }
    mergeOptions(optionsIncoming: DigiLeanDialogOptions) {
        let options = cloneDeep(defaultOptions)
        if (optionsIncoming)
            options = merge(options, optionsIncoming)
        return options
    }
}

export default new DigiLeanDialog()