angular
    .module('DigiLean')
    .directive("tasktagSelector", ['$filter', '$translate', 'dataService',
        function ($filter, $translate, dataService) {
            return {
                templateUrl: 'tasktagselector.html',
                restrict: 'E',
                scope: {
                    'filters': '=',
                    'source': '=',
                    'filterChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {

                 
                    scope.$watch('source', function () {
                        applyFilter();
                    });

                    scope.operatorChanged = function (operator) {
                        updateSettings();
                    }

                    scope.operator1option = [
                        {
                            translatedText: $translate.instant('DASHBOARD_CONTAINS'),
                            dbCode: "Contains"
                        }
                    ];

                    scope.selectedOperator = scope.operator1option[0];
                    scope.selectedText = "";

                    
                    scope.add = function () {
                        if (!scope.filters) scope.filters = [];
                        var filterItem = {
                            sourceColumn: scope.source,
                            operator: scope.operator1option[0].dbCode,
                            selectedOperator: scope.operator1option[0],
                            text: ""
                        };
                        scope.filters.push(filterItem);
                    }

                    scope.delete = function (item) {
                        var index = scope.filters.indexOf(item);
                        if (index != -1) {
                            scope.filters.splice(index, 1);
                            updateSettings();
                        }
                    }

                    function updateSettings() {
                        var filters = [];
                        if(scope.selectedText != ""){
                            filters.push({
                                sourceColumn: scope.source,
                                operator: scope.selectedOperator.dbCode,
                                items: [scope.selectedText]
                            });
                        }
                        scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: filters });
                    }

                    function applyFilter() {
                        if (!scope.filters) return;
                        scope.selectedText = scope.filters[0].items[0];
                    }

                    scope.modifyFilter = function () {
                        updateSettings();
                    }
                }
            }
        }]);
