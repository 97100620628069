import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .directive("improvementPriorityFilter", ['$filter', 'navigationService', 'strategyService', 'suggestionService', 'projectService',
        function ($filter, navigationService, strategyService, suggestionService, projectService) {
            return {
                templateUrl: 'improvementPriorityFilter.html',
                restrict: 'E',
                scope: {
                    'filter': '<',
                    'filterChangedHandler': '&',
                    'new': '=',
                    'suggestionStatus1': '=',
                    'suggestionStatus2': '=',
                    'suggestionStatus3': '=',
                    'suggestionStatus4': '=',
                    'timePeriod': '='
                },
                link: function (scope, elem, attrs) {
                    scope.isFilterActive = false;
                    scope.likesFilterActive = false;
                    scope.assets = [];
                    scope.categories = [];
                    scope.projects = [];
                    scope.goals = [];

                    // TODO Hvorfor er dette kommentert ut? Hva omm kunden tidligere hadde prosjektmodul og nå har tatt den bort...
                    // Dessuten gjør vi et ekstra backendkall som ikke er nødvendig.
                    // scope.hasProjectModule = navigationService().hasModule("PROJECT");
                    // scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                    //     scope.hasProjectModule = navigationService().hasModule("PROJECT"); 
                    // });
                    scope.hasStrategyModule = navigationService().hasModule("STRATEGY");
                    scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                        scope.hasStrategyModule = navigationService().hasModule("STRATEGY"); 
                    });
                    scope.$watch('filter', function (filter) {
                        if (filter) {
                            scope.filterPreferences = filter;
                            filterByUserPreference();
                        }
                    });
                    scope.$watch('new', function () {
                        if (scope.new) {
                            loadAssets();
                            loadCategories();
                            loadProjects();
                            loadGoals();
                        }
                    });

                    scope.$watch('timePeriod', function (value) {
                        /*Checking if the given value is not undefined*/
                        if (value) {
                            getAllData();
                            filterByUserPreference();
                        }
                    });

                    // Events
                    scope.subscribe("NewImprovement", function (newSuggestion) {
                        var isPartOfContainer = $filter('filter')(scope.new.list, { id: newSuggestion.id });
                        if (isPartOfContainer.length === 0) {
                            suggestionService().get(newSuggestion.id).then(function (suggestion) {
                                suggestion.cssState = "animated slideInDown"; // Add animation
                                scope.new.list.unshift(suggestion);
                                removeAnmiation(suggestion);
                                if (scope.isFilterActive) {
                                    filterSuggestions();
                                }
                            });
                        }
                    });
                    function removeAnmiation(suggestion) {
                        setTimeout(function () {
                            suggestion.cssState = "";
                        }, 500);
                    }
                    scope.subscribe("ImprovementDeleted", function (improvement) {
                        // find suggestion by id
                        var id = improvement.id;
                        removeFromList(scope.suggestionStatus1, id);
                        removeFromList(scope.suggestionStatus2, id);
                        removeFromList(scope.suggestionStatus3, id);
                        removeFromList(scope.suggestionStatus4, id);

                        var existingSuggestions = $filter('filter')(scope.new.list, { id: id }, true);
                        if (existingSuggestions.length > 0) {
                            // Apply animation
                            var index = scope.new.list.indexOf(existingSuggestions[0]);
                            existingSuggestions[0].cssClass = "animated slideOutLeft"; // Add animation
                            scope.new.list[index] = existingSuggestions[0];                  

                            // Then remove
                            setTimeout(function () {
                                scope.new.list.splice(index, 1);
                                var existingfilteredSuggestions = $filter('filter')(scope.new.filteredList, { id: id }, true);
                            if (existingfilteredSuggestions.length > 0) {
                                // Apply animation
                                var indexfiltered = scope.new.filteredList.indexOf(existingfilteredSuggestions[0]);
                                existingfilteredSuggestions[0].cssClass = "animated slideOutLeft"; // Add animation
                                scope.new.filteredList[indexfiltered] = existingfilteredSuggestions[0];
                                // Then remove
                                setTimeout(function () {
                                    scope.new.filteredList.splice(indexfiltered, 1);
                                }, 500);
                            }
                            }, 500);
                        }
                    });

                    // When another has changed status, we'll sync the changes
                    scope.subscribe("ImprovementChangedStatus", function (statusData) {
                        removeFromList(scope.new, statusData.suggestionId);
                        removeFromList(scope.suggestionStatus1, statusData.suggestionId);
                        removeFromList(scope.suggestionStatus2, statusData.suggestionId);
                        removeFromList(scope.suggestionStatus3, statusData.suggestionId);
                        removeFromList(scope.suggestionStatus4, statusData.suggestionId);

                        if (statusData.status === "Suggested" || statusData.status === "Planned") {
                            addToSuggestionList(statusData);
                        }
                    });

                    function addToSuggestionList(changedSuggestion) {
                        suggestionService().get(changedSuggestion.suggestionId).then(function (suggestion) {
                            suggestion.cssState = "animated slideInDown"; // Add animation
                            if (changedSuggestion.status === "Suggested") {
                                scope.new.list.unshift(suggestion);
                            } else {
                                if (suggestion.priorityStatus === 1) {
                                    addIfNotAlreadyInList(scope.suggestionStatus1.list, suggestion);
                                } else if (suggestion.priorityStatus === 2) {
                                    addIfNotAlreadyInList(scope.suggestionStatus2.list, suggestion);
                                } else if (suggestion.priorityStatus === 3) {
                                    addIfNotAlreadyInList(scope.suggestionStatus3.list, suggestion);
                                } else if (suggestion.priorityStatus === 4) {
                                    addIfNotAlreadyInList(scope.suggestionStatus4.list, suggestion);
                                }

                            }
                            filterSuggestions();
                            removeAnmiation(suggestion);
                        });
                    }

                    function addIfNotAlreadyInList(list, suggestion) {
                        var inList = $filter('filter')(list, { id: suggestion.id }, true);
                        if (inList.length === 0) {
                            list.unshift(suggestion);
                        }
                    }

                    scope.subscribe("ImprovementPriorityChanged", function (priority) {
                        var existInSuggestedList = $filter('filter')(scope.new.list, { id: priority.suggestionId }, true);
                        if (existInSuggestedList.length === 1) {
                            removeFromList(scope.new, priority.suggestionId);
                            filterSuggestions();
                            return;
                        }

                        suggestionService().get(priority.suggestionId).then(function (suggestion) {
                            suggestion.cssState = "animated slideInDown"; // Add animation

                            // priority should only be concerned within mattrix
                            removeFromList(scope.suggestionStatus1, priority.suggestionId);
                            removeFromList(scope.suggestionStatus2, priority.suggestionId);
                            removeFromList(scope.suggestionStatus3, priority.suggestionId);
                            removeFromList(scope.suggestionStatus4, priority.suggestionId);

                            switch (priority.newPriority) {
                                case 1:
                                    addIfNotAlreadyInList(scope.suggestionStatus1.list, suggestion);
                                    break;
                                case 2:
                                    addIfNotAlreadyInList(scope.suggestionStatus2.list, suggestion);
                                    break;
                                case 3:
                                    addIfNotAlreadyInList(scope.suggestionStatus3.list, suggestion);
                                    break;
                                case 4:
                                    addIfNotAlreadyInList(scope.suggestionStatus4.list, suggestion);
                                    break;
                            }
                            removeAnmiation(suggestion);
                            filterSuggestions();
                        });


                    });

                    function removeFromList(container, id) {
                        // is part of list
                        var isPartOfContainer = $filter('filter')(container.list, { id: id }, true);
                        if (isPartOfContainer.length > 0) {
                            var currentSuggestion = isPartOfContainer[0];
                            var index = container.list.indexOf(currentSuggestion);
                            container.list.splice(index, 1);
                        }
                        // is part of list
                        var isPartOfFilteredContainer = $filter('filter')(container.filteredList, { id: id }, true);
                        if (isPartOfFilteredContainer.length > 0) {
                            var currentSuggestion = isPartOfFilteredContainer[0];
                            var index = container.filteredList.indexOf(currentSuggestion);
                            container.filteredList.splice(index, 1);
                        }
                    }

                    function filterByUserPreference() {

                        if (scope.filterPreferences) {
                            if (scope.filterPreferences.asset) {
                                setAssetFilterPreferences(scope.filterPreferences.asset);
                            }
                            if (scope.filterPreferences.project) {
                                setProjectFilterPreferences(scope.filterPreferences.project);
                            }
                            if (scope.filterPreferences.goal) {
                                setGoalFilterPreferences(scope.filterPreferences.goal);
                            }
                            if (scope.filterPreferences.category) {
                                setCategoryFilterPreferences(scope.filterPreferences.category);
                            }
                            if (scope.filterPreferences.status) {
                                setStatusFilterPreferences(scope.filterPreferences.status);
                            }
                            if (scope.filterPreferences.likes) {
                                scope.likesFilterActive = true;
                            }
                            filterSuggestions();

                        }
                    }

                    scope.filterProject = function (project) {
                        project.filteredAndInResultList = !project.filteredAndInResultList;
                        if (project.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.project) {
                                scope.filterPreferences.project.push(project.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.project = [project.id];
                                } else {
                                    scope.filterPreferences = {
                                        project: [project.id]
                                    };
                                }
                                scope.filterPreferences.project = [project.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.project) {
                                var index = scope.filterPreferences.project.indexOf(project.id);
                                scope.filterPreferences.project.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }

                    scope.filterGoal = function (goal) {
                        goal.filteredAndInResultList = !goal.filteredAndInResultList;
                        if (goal.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.goal) {
                                scope.filterPreferences.goal.push(goal.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.goal = [goal.id];
                                } else {
                                    scope.filterPreferences = {
                                        goal: [goal.id]
                                    };
                                }
                                scope.filterPreferences.goal = [goal.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.goal) {
                                var index = scope.filterPreferences.goal.indexOf(goal.id);
                                scope.filterPreferences.goal.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }

                    function setAssetFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredAssetId) {
                            for (var a = 0; a < scope.assets.length; a++) {
                                if (scope.assets[a].id === filteredAssetId) {
                                    scope.assets[a].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setProjectFilterPreferences(preferences) {
                        angular.forEach(preferences, function (projectId) {
                            for (var t = 0; t < scope.projects.length; t++) {
                                if (scope.projects[t].id === projectId) {
                                    scope.projects[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setGoalFilterPreferences(preferences) {
                        angular.forEach(preferences, function (id) {
                            for (var t = 0; t < scope.goals.length; t++) {
                                if (scope.goals[t].id === id) {
                                    scope.goals[t].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }
                    function setCategoryFilterPreferences(preferences) {
                        angular.forEach(preferences, function (filteredId) {
                            for (var i = 0; i < scope.categories.length; i++) {
                                if (scope.categories[i].id === filteredId) {
                                    scope.categories[i].filteredAndInResultList = true;
                                    break;
                                }
                            }
                        });
                    }


                    function getAllData() {
                        suggestionService().getListByStatus("Suggested", scope.timePeriod).then(function (suggestions) {
                            suggestions.forEach(function (suggestion) {
                                suggestion.activeComment = "";
                                scope.new.list.push(suggestion);
                            });
                            scope.new.filteredList = scope.new.list;

                            suggestionService().getListByStatus("Planned", scope.timePeriod).then(function (suggestions) {
                                for (var i = 0; i < suggestions.length; i++) {
                                    switch (suggestions[i].priorityStatus) {
                                        case 1:
                                            scope.suggestionStatus1.list.push(suggestions[i]);
                                            break;
                                        case 2:
                                            scope.suggestionStatus2.list.push(suggestions[i]);
                                            break;
                                        case 3:
                                            scope.suggestionStatus3.list.push(suggestions[i]);
                                            break;
                                        case 4:
                                            scope.suggestionStatus4.list.push(suggestions[i]);
                                            break;
                                    }
                                }
                                scope.suggestionStatus1.filteredList = scope.suggestionStatus1.list;
                                scope.suggestionStatus2.filteredList = scope.suggestionStatus2.list;
                                scope.suggestionStatus3.filteredList = scope.suggestionStatus3.list;
                                scope.suggestionStatus4.filteredList = scope.suggestionStatus4.list;

                                if (scope.isFilterActive) {
                                    filterSuggestions();
                                }

                            });
                        });

                    }

                                       scope.statuses = [scope.new, scope.planned, scope.inprogress, scope.implemented, scope.evaluated];


                    function loadAssets() {
                        assetService.getAllAssets().then(function (assets) {
                            scope.assets = assets;
                            resetAssetFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadProjects() {
                        projectService().getProjectListWithSuggestion().then(function (items) {
                            scope.projects = items;
                            resetProjectFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadCategories() {
                        suggestionService().getCategories().then(function (items) {
                            scope.categories = items;
                            resetCategoryFilter();
                            filterByUserPreference();
                        });
                    }
                    function loadGoals() {
                        strategyService().getBreakthroughObjectives().then(function (items) {
                            scope.goals = items;
                            resetGoalFilter();
                            filterByUserPreference();
                        });

                    }

                    scope.toggleLikesFilter = function () {
                        scope.likesFilterActive = !scope.likesFilterActive;
                        if (scope.likesFilterActive) {
                            if (scope.filterPreferences) {
                                scope.filterPreferences.likes = true;
                            } else {
                                scope.filterPreferences = {
                                    likes: true
                                };
                            }
                        } else { // remove from filter
                            scope.filterPreferences.likes = null;
                        }
                        filterSuggestions();
                        updateFilterPreferences();
                    }


                    function resetAssetFilter() {
                        angular.forEach(scope.assets, function (asset) {
                            asset.filteredAndInResultList = false;
                        });
                    }
                    function resetProjectFilter() {
                        angular.forEach(scope.projects, function (project) {
                            project.filteredAndInResultList = false;
                        });
                    }
                    function resetGoalFilter() {
                        angular.forEach(scope.goals, function (goal) {
                            goal.filteredAndInResultList = false;
                        });
                    }

                    function resetCategoryFilter() {
                        angular.forEach(scope.categories, function (category) {
                            category.filteredAndInResultList = false;
                        });
                    }

                    scope.filterAsset = function (asset) {
                        asset.filteredAndInResultList = !asset.filteredAndInResultList;
                        if (asset.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.asset) {
                                scope.filterPreferences.asset.push(asset.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.asset = [asset.id];
                                } else {
                                    scope.filterPreferences = {
                                        asset: [asset.id]
                                    };
                                }
                                scope.filterPreferences.asset = [asset.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.asset) {
                                var index = scope.filterPreferences.asset.indexOf(asset.id);
                                scope.filterPreferences.asset.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }
                    scope.filterCategory = function (category) {
                        category.filteredAndInResultList = !category.filteredAndInResultList;
                        if (category.filteredAndInResultList) {
                            if (scope.filterPreferences && scope.filterPreferences.category) {
                                scope.filterPreferences.category.push(category.id);
                            } else {
                                if (scope.filterPreferences) {
                                    scope.filterPreferences.category = [category.id];
                                } else {
                                    scope.filterPreferences = {
                                        category: [category.id]
                                    };
                                }
                                scope.filterPreferences.category = [category.id];
                            }
                        } else { // remove from filter
                            if (scope.filterPreferences.category) {
                                var index = scope.filterPreferences.category.indexOf(category.id);
                                scope.filterPreferences.category.splice(index, 1);
                            }
                        }
                        updateFilterPreferences();
                        filterSuggestions();
                    }

                    function updateFilterPreferences() {
                        scope.filterChangedHandler({ filter: scope.filterPreferences });
                    }

                    scope.removeFilter = function () {
                        //resetStatusFilter();
                        resetAssetFilter();
                        resetProjectFilter();
                        resetGoalFilter();
                        resetCategoryFilter();
                        scope.likesFilterActive = false;
                        filterSuggestions();
                        resetFilterPreferences();
                    };

                    function resetFilterPreferences() {
                        scope.filterPreferences = null;
                        updateFilterPreferences();

                    }

                    function filterSuggestions() {
                        scope.new.filteredList = scope.new.list;
                        scope.suggestionStatus1.filteredList = scope.suggestionStatus1.list;
                        scope.suggestionStatus2.filteredList = scope.suggestionStatus2.list;
                        scope.suggestionStatus3.filteredList = scope.suggestionStatus3.list;
                        scope.suggestionStatus4.filteredList = scope.suggestionStatus4.list;

                        var assetsToShow = $filter('filter')(scope.assets, { filteredAndInResultList: true }, true);
                        if (assetsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByAssets(scope.new.filteredList, assetsToShow);
                            scope.suggestionStatus1.filteredList = getFilteredListByAssets(scope.suggestionStatus1.filteredList, assetsToShow);
                            scope.suggestionStatus2.filteredList = getFilteredListByAssets(scope.suggestionStatus2.filteredList, assetsToShow);
                            scope.suggestionStatus3.filteredList = getFilteredListByAssets(scope.suggestionStatus3.filteredList, assetsToShow);
                            scope.suggestionStatus4.filteredList = getFilteredListByAssets(scope.suggestionStatus4.filteredList, assetsToShow);
                        }

                        var projectsToShow = $filter('filter')(scope.projects, { filteredAndInResultList: true }, true);
                        if (projectsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByProjects(scope.new.filteredList, projectsToShow);
                            scope.suggestionStatus1.filteredList = getFilteredListByProjects(scope.suggestionStatus1.filteredList, projectsToShow);
                            scope.suggestionStatus2.filteredList = getFilteredListByProjects(scope.suggestionStatus2.filteredList, projectsToShow);
                            scope.suggestionStatus3.filteredList = getFilteredListByProjects(scope.suggestionStatus3.filteredList, projectsToShow);
                            scope.suggestionStatus4.filteredList = getFilteredListByProjects(scope.suggestionStatus4.filteredList, projectsToShow);
                        }

                        var goalsToShow = $filter('filter')(scope.goals, { filteredAndInResultList: true }, true);
                        if (goalsToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByGoals(scope.new.filteredList, goalsToShow);
                            scope.suggestionStatus1.filteredList = getFilteredListByGoals(scope.suggestionStatus1.filteredList, goalsToShow);
                            scope.suggestionStatus2.filteredList = getFilteredListByGoals(scope.suggestionStatus2.filteredList, goalsToShow);
                            scope.suggestionStatus3.filteredList = getFilteredListByGoals(scope.suggestionStatus3.filteredList, goalsToShow);
                            scope.suggestionStatus4.filteredList = getFilteredListByGoals(scope.suggestionStatus4.filteredList, goalsToShow);
                        }

                        var categoriesToShow = $filter('filter')(scope.categories, { filteredAndInResultList: true }, true);
                        if (categoriesToShow.length > 0) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = getFilteredListByCategories(scope.new.filteredList, categoriesToShow);
                            scope.suggestionStatus1.filteredList = getFilteredListByCategories(scope.suggestionStatus1.filteredList, categoriesToShow);
                            scope.suggestionStatus2.filteredList = getFilteredListByCategories(scope.suggestionStatus2.filteredList, categoriesToShow);
                            scope.suggestionStatus3.filteredList = getFilteredListByCategories(scope.suggestionStatus3.filteredList, categoriesToShow);
                            scope.suggestionStatus4.filteredList = getFilteredListByCategories(scope.suggestionStatus4.filteredList, categoriesToShow);
                        }

                        if (scope.likesFilterActive) {
                            scope.isFilterActive = true;
                            scope.new.filteredList = $filter('filter')(scope.new.filteredList, hasValuesInListFilter('likes'));
                            scope.suggestionStatus1.filteredList = $filter('filter')(scope.suggestionStatus1.filteredList, hasValuesInListFilter('likes'));
                            scope.suggestionStatus2.filteredList = $filter('filter')(scope.suggestionStatus2.filteredList, hasValuesInListFilter('likes'));
                            scope.suggestionStatus3.filteredList = $filter('filter')(scope.suggestionStatus3.filteredList, hasValuesInListFilter('likes'));
                            scope.suggestionStatus4.filteredList = $filter('filter')(scope.suggestionStatus4.filteredList, hasValuesInListFilter('likes'));
                        }
                    }

                    function hasValuesInListFilter(property) {
                        return function (item) {
                            if (item[property] && item[property].length > 0) return true;
                            return false;
                        }
                    }
                    function getFilteredListByAssets(list, assets) {
                        if (assets.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < assets.length; j++) {
                                var asset = assets[j];
                                if (suggestion.assetId === asset.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByProjects(list, projects) {
                        if (projects.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < projects.length; j++) {
                                var project = projects[j];
                                if (suggestion.projectId === project.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByGoals(list, goals) {
                        if (goals.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < goals.length; j++) {
                                var goal = goals[j];
                                if(suggestion.goals){
                                    for (var k = 0; k < suggestion.goals.length; k++) {
                                        if (suggestion.goals[k].strategyGoalId  === goal.id) {
                                            filteredList.push(suggestion);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        return filteredList;
                    }
                    function getFilteredListByCategories(list, categories) {
                        if (categories.length === 0) return list;
                        var filteredList = [];
                        for (var i = 0; i < list.length; i++) {
                            var suggestion = list[i];
                            for (var j = 0; j < categories.length; j++) {
                                var category = categories[j];
                                if (suggestion.categoryId === category.id) {
                                    filteredList.push(suggestion);
                                }
                            }
                        }
                        return filteredList;
                    }

                }
            }
        }]);
