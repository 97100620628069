import * as customerService from "@common/services/customers/customerService"
import * as dashboardService from "@common/services/dashboardService"
import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean')
    .directive("messageDisplayer", ['$uibModal','$filter', 'debounce','$translate',
        function ($uibModal,$filter, debounce, $translate) {
            return {
                templateUrl: 'messagedisplayer.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '=',
                    'assetId': '<',
                    'settings': '=',
                    'settingsChangeHandler': '&'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = {
                        background: "transparent",
                        color: "inherit"
                    };
                    scope.postIts = dashboardService.getThemes();    

                    scope.title = "";
                    scope.messages = [];
                    scope.filters = null;
                    scope.showMetaInfo = true;
                    
                    scope.subscribe("MessageReceived", function (message) {
                        if(!message) return;

                        if(message.isPublic || !scope.filters){ //show all new messages
                            getMessages();//førte til at meldinger la seg opppå hverandre scope.messages.unshift(message);
                        } else if(scope.filters && message.assets){
                            var assetfilter = $filter('filter')(scope.filters, { sourceColumn: "assetId" });                       
                            if(assetfilter && isAssetMatch(message.assets, assetfilter[0].items)){
                                getMessages();
                            }
                        }
                    });

                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (scope.settings) {
                            scope.title = settings.title;
                            if (settings.filters) {
                                scope.filters = settings.filters;
                            }
                            if(settings.showMetaInfo){
                                scope.showMetaInfo = true;
                            }
                            if (settings.showMetaInfo === false) {
                                scope.showMetaInfo = settings.showMetaInfo;
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            getMessages();
                        } else if (scope.isAdminMode) {
                            setTimeout(function() {
                                scope.selectAssets();
                            }, 200); 
                        }
                        scope.messageoptions = {
                            showMetaInfo: scope.showMetaInfo
                        }
                    });

                    function isAssetMatch(messageassets, assetsToBeDisplayed) {
                        for (const messageasset of messageassets) {
                            for (const assetToBeDisplayed of assetsToBeDisplayed) {
                                if (messageasset.assetId === assetToBeDisplayed) {
                                    return true;
                                }
                            }
                        }          
                        return false;
                    }


                    scope.toggleShowMetaInfo = function(){
                        scope.messageoptions = {
                            showMetaInfo: scope.showMetaInfo
                        }
                        scope.updateSettings();
                    }

                    scope.selectAssets = function () {

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'assetSelectorModal.html',
                            controller: 'assetSelectorModalController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {

                                filters: function () {
                                    return scope.filters;
                                }
                            }
                        });
                
                        modalInstance.result.then(function (result) {
                            scope.filters = result;
                            getMessages();
                            scope.updateSettings();
                        }, function(res){});
                    };

                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    function getMessages() {
                        var valuesQueryOptions = {
                            filters: scope.filters
                        } 
                        customerService.getMessagesWithOptions(valuesQueryOptions).then(function (result) {
                            scope.allMessages = result;
                            scope.isLoading = false;
                            scope.allMessages.forEach(message => {
                                // replace linebreaks
                                message.text = message.text.replace(/\r\n|\r|\n/g, "<br />");
                                if (message.isPublic) {
                                    message.typeColor = "#5ab700";
                                    message.type = $translate.instant('COMMON_ALL');
                                } else if (message.projectId != null) {
                                    message.typeColor = "#0099DE"
                                    message.type = $translate.instant('COMMON_PROJECT');
                                } else if (message.assetId != null){
                                    message.typeColor = "#f0ad4e"
                                    message.type = $translate.instant('COMMON_BOARD');
                                } else {
                                    message.typeColor = "#D770AD"
                                    message.type = $translate.instant('COMMON_ASSET');
                                }
            
                            });
                            calculateTimeElapsed();
                        });
                        
                    }
                    

                    function calculateTimeElapsed() {
                        scope.allMessages.forEach(function (msg) {
                            timeService.addTimeElapsedForMsg(msg);
                        });
                    }

                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            theme: scope.theme,
                            filters: scope.filters,
                            showMetaInfo: scope.showMetaInfo
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    }

                    scope.commitChangesDebounced = debounce(scope.updateSettings, 2000, false);

                }
            }
        }]);
