
import backend, { BackendHttpError } from "@common/services/backendHttp"
import * as timeService from "@common/services/timeService"
import * as displayService from "@common/services/displayService"
import { translateLabelInstant } from "./language/DigiLeanLang"

const resourceUrl = "api/board"

export function create (board) {
    return backend.post(resourceUrl, board)
}
export function createFromTemplate(id, options) {
    const url = resourceUrl + "/createFromTemplate/" + id;
    return backend.post(url, options)
}
export function createTemplate(boardId, templateInfo) {
    const url = resourceUrl + "/createTemplate/" + boardId;
    return backend.post(url, templateInfo)
}
export function get(boardId) {
    const url = resourceUrl + "/" + boardId;
    return backend.get(url)
}
                
/*kaizen admin */
export function getBoardsByCustomer(customerId) {
    const url = resourceUrl + "/getBoardsByCustomer/" + customerId;
    return backend.get(url)
}

export function canEdit(boardId) {
    const url = resourceUrl + "/canedit/" + boardId;
    return backend.get(url)
}

export function getRequiredAccess(boardId) {
    const url = resourceUrl + "/getRequiredAccess/" + boardId;
    return backend.get(url)
}

export function checkAccess(boardId) {
    const url = resourceUrl + "/checkAccess/" + boardId;
    return backend.get(url)
}

export function moveUncompletedTasks(boardId, week) {
    const url = resourceUrl + "/moveUncompletedTasks/" + boardId;
    // var day = moment(options.boardDate).isoWeekday();
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var weekOptions = {
        fromWeek: {
            startTime: week.startDate.toJSON(),
            endTime: week.endDate.toJSON(),
        },
        numberOfDays: 7,
        timeZoneOffset: {
            offset: timeService.getTimeZoneOffset()
        },
        timeZone: timeZone
        // dayOfWeek: day
    }
    return backend.put(url, weekOptions)
}

export function getTemplates(filter) {
    if(!filter){
        filter = [];
    }
    const url = resourceUrl + "/getTemplates";
    return backend.post(url,filter)
}
export function getTemplate(id) {
    const url = resourceUrl + "/getTemplate/" + id;
    return backend.get(url)
}
export function deleteTemplate(id) {
    const url = resourceUrl + "/deleteTemplate/" + id;
    return backend.delete(url)
}

export function getAll() {
    return backend.get(resourceUrl)
}
export async function deleteBoard (id) {
    const url = resourceUrl + "/" + id;
    try {
        const res = await backend.delete(url)
        return res
    }
    catch (error: any) {
        if (error instanceof BackendHttpError && error.status === 400)
            return displayService.showSystemProjectBoardPopup()
        
        throw error
    }
}
export function getBoardsForAdmin() {
    const url = resourceUrl + "/GetBoardsForAdmin";
    return backend.get(url)
}

export function getAllBoardNames() {
    const url = resourceUrl + "/getAllBoardNames";
    return backend.get(url)
}

export function getMemberBoards() {
    const url = resourceUrl + "/getmemberboards";
    return backend.get(url)
}

export function getMemberBoardsOfType(boardType) {
    const url = resourceUrl + "/getMemberBoardsOfType/" + boardType;
    return backend.get(url)
}

export function getBoardInfo(id) {
    const url = resourceUrl + "/getboardinfo/" + id;
    return backend.get(url)
}

export function getProjectForBoard(boardId) {
    const url = resourceUrl + "/GetProjectForBoard/" + boardId;
    return backend.get(url)
}

export function getBoardsForAsset(assetId) {
    const url = resourceUrl + "/GetBoardsForAsset/" + assetId;
    return backend.get(url)
}

export function getProjectBoards(projectId) {
    const url = resourceUrl + "/GetProjectBoards/" + projectId;
    return backend.get(url)
}

export function saveBoard(boardInfo) {
    const url = resourceUrl + "/SaveBoard/";
    return backend.put(url, boardInfo)
}

export function getBoardCategories(id) {
    const url = resourceUrl + "/getBoardCategories/" + id;
    return backend.get(url)
}

export function changeSortOrder(boardId, sortOrders) {
    const url = "api/boardcategory/ChangeSortOrder/" + boardId;
    return backend.put(url, sortOrders)
}

export function rotateCategories(boardId) {
    const url = "/api/boardcategory/RotateCategories/" + boardId;
    return backend.put(url)
}


export function deleteCategory(categoryId) {
    const url = "api/boardcategory/deleteCategory/" + categoryId;
    return backend.delete(url)
}

export function updateCategory(categoryId, category) {
    const url = "api/boardcategory/updateCategory/" + categoryId;
    return backend.put(url, category)
}

export function createCategory(boardId, category) {
    const url = "api/boardcategory/createCategory/" + boardId;
    return backend.post(url, category)
}

export function getUserListForBoard(boardId) {
    const url = resourceUrl + "/getUserListForBoard/" + boardId;
    return backend.get(url)
}

export function update(id, board) {
    const url = resourceUrl + "/" + id;
    return backend.put(url, board)
}

export function updateName(id, name) {
    const url = resourceUrl + "/updateName/" + id;
    return backend.put(url, name)
}

export function updateSettings(id, settings) {
    const url = resourceUrl + "/updateSettings/" + id;
    return backend.put(url, settings)
}

export function assignResponsibleForCategory(responsibleForCategory) {
    const url = resourceUrl + "/assignResponsibleForCategory/";
    return backend.post(url, responsibleForCategory)
}


export function getDeleted() {
    const url = resourceUrl + "/deletedboards";
    return backend.get(url)
}

export function forceDelete(id) {
    const url = resourceUrl + "/forcedelete/" + id;
    return backend.delete(url)
}

export function restore(id) { // Restore deleted board
    const url = resourceUrl + "/restore/" + id;
    return backend.put(url)
}

export function getTags(id) {
    const url = resourceUrl + "/getTags/" + id;
    return backend.get(url)
}

export function getTagsForWeek(id, week) {
    const url = resourceUrl + "/getTagsForWeek/" + id;
    return backend.post(url, week)
}

// Kanban tasks
export function getTasks(id) {
    const url = resourceUrl + "/GetTasks/" + id;
    return backend.post(url, null)
}

export function getActionListTasks(id) {
    const url = resourceUrl + "/GetActionListTasks/" + id;
    return backend.post(url, null)
}

export function tasksInPeriod(id, options) {
    const url = resourceUrl + "/GetTasksInTimePeriod/" + id;
    return backend.post(url, options)
}

export function getTasksForPeriod(id, options) {
    const url = resourceUrl + "/GetTasksForPeriod/" + id;
    return backend.post(url, options)
}

//used by timeboard
export function getArchivedTimeTasksForPeriod(id, options) {
    const url = resourceUrl + "/GetArchivedTimeTasksForPeriod/" + id;
    return backend.post(url, options)
}
                
// archived date is essential
export function archivedTasksInPeriod(id, options) {
    const url = resourceUrl + "/GetArchivedTasksInTimePeriod/" + id;
    return backend.post(url, options)
}

export function addStrategyGoalToBoard(boardId, strategyGoalId) {
    const url = resourceUrl + "/" + boardId + "/addStrategyGoalToBoard";
    return backend.post(url, strategyGoalId)
}

export function getActionTasks(id) {
    const url = resourceUrl + "/GetActionTasks/" + id;
    return backend.post(url, null)
}

export function getActionTasksForWeek(boardWeek) {
    const url = resourceUrl + "/GetActionTasksForWeek";
    return backend.post(url, boardWeek)
}

export function getTaskStatusText(dbstatus) {
    switch (dbstatus) {
        case "unacceptable":
            return translateLabelInstant('BOARD_INCOMPLETED');
        case "blank":
            return translateLabelInstant('BOARD_OPEN');
        case "OK":
            return translateLabelInstant('DASHBOARD_COMPLETED');
        default:
            return translateLabelInstant('COMMON_NOT_SPECIFIED');
    }
}

export function createKanbanBoardTemplate() {
    var template = {
        template: {
            boardType: "Kanban",
            name: translateLabelInstant("BOARD_KANBAN_BOARD"),
            description: translateLabelInstant("BOARD_KANBAN_BOARD_DESCRIPTION"),
            settings: JSON.stringify({
                IsWeeklyBoard: false
            })
        },
        columns: [] as any[],
        rows: [] as any[]
    };
    template.columns.push(
        {
            name: translateLabelInstant("BOARD_TO_DO"),
            sortOrder: 0,
            categoryStyle: {
                backgroundColor: "#E3EDF8",
                foregroundColor: "#2E404F"
            }
        });
    template.columns.push(
        {
            name: translateLabelInstant("COMMON_IN_PROGRESS"),
            sortOrder: 1,
            categoryStyle: {
                backgroundColor: "#C5DBF0",
                foregroundColor: "#2E404F"
            }
        });
    template.columns.push(
        {
            name: translateLabelInstant("BOARD_WAITING"),
            sortOrder: 2,
            categoryStyle: {
                backgroundColor: "#3480BD",
                foregroundColor: "#E7E6E6"
            }
        });
    template.columns.push(
        {
            name: translateLabelInstant("BOARD_DONE"),
            sortOrder: 3,
            categoryStyle: {
                backgroundColor: "#15507f",
                foregroundColor: "#E7E6E6"
            }
        });
    template.rows.push(
        {
            name: translateLabelInstant("COMMON_HIGH"),
            sortOrder: 0,
            categoryStyle: {
                backgroundColor: "#EFA390",
                foregroundColor: "#264653"
            }
        });
    template.rows.push(
        {
            name: translateLabelInstant("COMMON_NORMAL"),
            sortOrder: 1,
            categoryStyle: {
                backgroundColor: "#8B8B8B",
                foregroundColor: "#E3EDF8"
            }
        });

    template.rows.push(
        {
            name: translateLabelInstant("COMMON_LOW"),
            sortOrder: 2,
            categoryStyle: {
                backgroundColor: "#b1b1b1",
                foregroundColor: "#E3EDF8"
            }
        });

    return template;
}

export function createWeeklyBoardTemplate() {
    var translations = {
        BOARD_WEEKLY_BOARD: translateLabelInstant("BOARD_WEEKLY_BOARD"),
        BOARD_WEEKLY_BOARD_DESCRIPTION: translateLabelInstant("BOARD_WEEKLY_BOARD_DESCRIPTION"),
        BOARD_NEW_BOARD: translateLabelInstant("BOARD_NEW_BOARD"),
        COMMON_CATEGORY: translateLabelInstant("COMMON_CATEGORY"),
        COMMON_MONDAY: translateLabelInstant("COMMON_MONDAY"),
        COMMON_TUESDAY: translateLabelInstant("COMMON_TUESDAY"),
        COMMON_WEDNESDAY: translateLabelInstant("COMMON_WEDNESDAY"),
        COMMON_THURSDAY: translateLabelInstant("COMMON_THURSDAY"),
        COMMON_FRIDAY: translateLabelInstant("COMMON_FRIDAY"),
    }

    var template = {
        template: {
            boardType: "Weekly",
            name: translations.BOARD_WEEKLY_BOARD,
            description: translations.BOARD_WEEKLY_BOARD_DESCRIPTION,
            settings: JSON.stringify({
                IsWeeklyBoard: true
            })
        },
        columns: [] as any[],
        rows: [] as any[]
    };
    template.columns.push(
        {
            name: translations.COMMON_MONDAY,
            weekdayName: "Monday",
            sortOrder: 0,
            categoryStyle: {
                backgroundColor: "#0182BD",
                foregroundColor: "#FFFFFF"
            }
        });
    template.columns.push(
        {
            name: translations.COMMON_TUESDAY,
            weekdayName: "Tuesday",
            sortOrder: 1,
            categoryStyle: {
                backgroundColor: "#0182BD",
                foregroundColor: "#FFFFFF"
            }
        });
    template.columns.push(
        {
            name: translations.COMMON_WEDNESDAY,
            weekdayName: "Wednesday",
            sortOrder: 2,
            categoryStyle: {
                backgroundColor: "#0182BD",
                foregroundColor: "#FFFFFF"
            }
        });
    template.columns.push(
        {
            name: translations.COMMON_THURSDAY,
            weekdayName: "Thursday",
            sortOrder: 3,
            categoryStyle: {
                backgroundColor: "#0182BD",
                foregroundColor: "#FFFFFF"
            }
        });
    template.columns.push(
        {
            name: translations.COMMON_FRIDAY,
            weekdayName: "Friday",
            sortOrder: 4,
            categoryStyle: {
                backgroundColor: "#0182BD",
                foregroundColor: "#FFFFFF"
            }
        });
    template.rows.push(
        {
            name: translations.COMMON_CATEGORY + " A",
            sortOrder: 0,
            categoryStyle: {
                backgroundColor: "#DBEDCD",
                foregroundColor: "#0182BD"
            }
        });
    template.rows.push(
        {
            name: translations.COMMON_CATEGORY + " B",
            sortOrder: 1,
            categoryStyle: {
                backgroundColor: "#FBEED2",
                foregroundColor: "#0182BD"
            }
        });
    template.rows.push(
        {
            name: translations.COMMON_CATEGORY + " C",
            sortOrder: 2,
            categoryStyle: {
                backgroundColor: "#EED2D7",
                foregroundColor: "#0182BD"
            }
        });


    return template;

}

export function createYearlyTemplate() {
    var translations = {
        BOARD_YEARLY_BOARD: translateLabelInstant("BOARD_YEARLY_BOARD"),
        BOARD_YEARLY_BOARD_DESCRIPTION: translateLabelInstant("BOARD_YEARLY_BOARD_DESCRIPTION")
    }

    var template = {
        template: {
            boardType: "Yearly",
            name: translations.BOARD_YEARLY_BOARD,
            description: translations.BOARD_YEARLY_BOARD_DESCRIPTION,
            settings: JSON.stringify({
                IsWeeklyBoard: false
            })
        },
        columns: [] as any[],
        rows: [] as any[]
    };
    template.rows.push(
        {
            name: translateLabelInstant("COMMON_CATEGORY") + " A",
            sortOrder: 0,
            categoryStyle: {
                backgroundColor: "#f9f9f9",
                foregroundColor: "#676a6c"
            }
        });
    template.rows.push(
        {
            name: translateLabelInstant("COMMON_CATEGORY") + " B",
            sortOrder: 1,
            categoryStyle: {
                backgroundColor: "#f9f9f9",
                foregroundColor: "#676a6c"
            }
        });

    template.rows.push(
        {
            name: translateLabelInstant("COMMON_CATEGORY") + " C",
            sortOrder: 2,
            categoryStyle: {
                backgroundColor: "#f9f9f9",
                foregroundColor: "#676a6c"
            }
        });
    return template;
}
