const defaultAppStyle = { "font-size": "1.4rem" }

export function getDefaultAppStyle() {
    return Object.assign({}, defaultAppStyle)
}

export function calcDefaultAppFontSize(factor: number) {
    let fontApp = "1.4rem"
    if (factor)
        fontApp = 1.4 * factor + "rem"
    return fontApp
}

export function getDefaultAppTheme() {
    return {
        background: "var(--digilean-primary-text)",
        fillColor: "var(--digilean-secondary-background)",
        color: "inherit"
    }
}