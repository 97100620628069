import angular from "angular"
import type { IController } from "angular"
import { FileInfo } from "@api"
import { Theme } from "@common/model/types"
import { getFileUrl } from "@common/services/documentService"
import { PdfZoomOptions } from "@common/components/viewers/pdfViewer"
import { settingsPdfApp } from "./pdfDisplayer"
import { calcDefaultAppFontSize, getDefaultAppTheme, getDefaultAppStyle } from "../../common/appStyleService"

interface pdfAppController extends IController {
    settings: settingsPdfApp
    internal: settingsPdfApp
    pdfSasUrl: string
    pdfReady: boolean
    loadingPdfUrl: boolean
    layoutFactor: number
    zoomOptionChanged: ($event: CustomEvent) => void
    updateTheme: ($event: CustomEvent) => void
    fileUploaded: ($event: CustomEvent) => void
    updateSettings: () => void
}


const DigiLean = angular.module('DigiLean')
DigiLean.component('pdfDisplayerNg', {
    templateUrl: "pdfDisplayerAppNg.html",
    bindings: {
        'settings': '<',
        'isAdminMode': '<',
        'layoutFactor': '<'
    },
    controller: ['$element', '$scope',
        function(this: pdfAppController, $element, $scope) {
            let $ctrl = this
            $ctrl.pdfReady = false
            $ctrl.loadingPdfUrl = false
            $ctrl.internal = {
                title: "",
                pdfFileInfoId: "",
                pdfFileName: "",
                theme: getDefaultAppTheme()
            }
            $ctrl.pdfSasUrl = ""
            
            $ctrl.zoomOptionChanged = function($event) {
                const option = $event.detail as PdfZoomOptions
                $ctrl.internal.pdfZoomOption = option
                updateSettings()
            }
            $ctrl.updateTheme = function($event) {
                const theme = $event.detail as Theme
                $ctrl.internal.theme = theme
                updateSettings()
            }
            $ctrl.fileUploaded = function($event) {
                const files = $event.detail as FileInfo[]
                const file = files[0]
                $ctrl.internal.pdfFileName = file.fileName!
                $ctrl.internal.pdfFileInfoId = file.id!
                setPdfSasUrl()
                updateSettings()
            }
            function updateSettings () {
                
                const options = {
                    detail: {settings: $ctrl.internal},
                    bubbles: true,
                    composed: true
                }
                $element[0].dispatchEvent(new CustomEvent("settings-changed", options))
            }
            $ctrl.updateSettings = updateSettings

            $ctrl.appStyle = getDefaultAppStyle()
            function calcStyles() {
                $ctrl.appStyle["font-size"] = calcDefaultAppFontSize($ctrl.layoutFactor)
            }
            calcStyles()

            $ctrl.titleChangedEvent = function($event) {
                $ctrl.internal.title = $event.detail
                $ctrl.updateSettings()
            }
            
            async function setPdfSasUrl () {
                if ($ctrl.internal.pdfFileInfoId && $ctrl.internal.pdfFileName && !$ctrl.loadingPdfUrl) {
                    $ctrl.loadingPdfUrl = true
                    const {pdfFileName,pdfFileInfoId} = $ctrl.internal
                    const sasUrl = await getFileUrl(pdfFileName, pdfFileInfoId)
                    $ctrl.pdfSasUrl = sasUrl
                    $ctrl.loadingPdfUrl = false
                    $ctrl.pdfReady = true
                    $scope.$apply()
                }
            }
            function getSettingsFromProp() {
                if ($ctrl.settings) {
                    const s = $ctrl.settings
                    if (s.title)
                        $ctrl.internal.title = s.title
                    if (s.theme)
                        $ctrl.internal.theme = s.theme
                    if (s.pdfFileInfoId && s.pdfFileName) {
                        $ctrl.internal.pdfFileInfoId = s.pdfFileInfoId
                        $ctrl.internal.pdfFileName = s.pdfFileName
                        setPdfSasUrl()
                    }
                    if (s.pdfZoomOption)
                        $ctrl.internal.pdfZoomOption = s.pdfZoomOption
                }
            }
            //$ctrl.prevSasUrl = ""
            //$ctrl.$doCheck = function() {
                //console.log(`SAS urls: prev: ${$ctrl.prevSasUrl}, curr: ${$ctrl.pdfSasUrl}`)
                // if ($ctrl.$ctrl.prevSasUrl !== $ctrl.pdfSasUrl)
                //     $ctrl.pdfReady = true
            //}
            $ctrl.$onInit = function() {
                getSettingsFromProp()
            }
            $ctrl.$onChanges = function (changes) {
                if (changes.settings) {
                    getSettingsFromProp()
                }
                if (changes.layoutFactor && changes.layoutFactor.currentValue)
                    calcStyles()
            }
        }
    ]}
)