import { ConnectorMonitorList, ConnectorMonitor, TableParams, TableValues, LogMessageTableValues, JobRunLog, LogMessage, JobMonitor } from "@api"
import backend from "@common/services/backendHttp"

const resourceUrl = "api/monitoring"

export const getConnector = async (id: string, count?: number, page?: number) => {
    let url = `${resourceUrl}/${id}`
    if (count)
        url = `${url}/${count}`
    if (page)
        url = `${url}/${page}`
    return await backend.get<ConnectorMonitor>(url)
}

export const getJobRuns = async (id: string, jobId: number, count?: number, page?: number) => {
    let url = `${resourceUrl}/${id}/jobRuns/${jobId}`
    if (count)
        url = `${url}/${count}`
    if (page)
        url = `${url}/${page}`
    return await backend.get<JobMonitor>(url)
}

export const getJobRunLogs = async (runId: string, jobPartId?: number | null) => {
    let url = `${resourceUrl}/jobs/logs/${runId}`
    if (jobPartId)
        url = `${url}?jobPartId=${jobPartId}`
    return await backend.get<LogMessage[]>(url)
}

export const getNextRun = async (jobId: number) => {
    const url = `${resourceUrl}/jobs/${jobId}/nextrun`
    return await backend.get<string>(url)
}

export const getConnectorsCompany = async () => {
    return await backend.get<ConnectorMonitorList[]>(resourceUrl)
}

export const getConnectorsAll = async () => {
    const url = `${resourceUrl}/allconnectors`
    return await backend.get<ConnectorMonitorList[]>(url)
}

export const getLogsPaged = async (jobId: number, count: number = 25, page: number = 1, typeFilter: string[] = []) => {
    const url = `${resourceUrl}/logs/${jobId}`
    let options: TableParams = {
        count,
        page
    }
    if (typeFilter.length > 0) {
        options.filters = [
            {
                sourceColumn: "type",
                operator: "InList",
                items: typeFilter
            }
        ]
    }
    return await backend.post<LogMessageTableValues>(url, options)
}

export function getLastErrors(connectorId: string, days?: number) {
    let url = `${resourceUrl}/${connectorId}/lastErrors`
    if (days)
        url = `${url}/${days}`
    return backend.get<LogMessage[]>(url)
}