angular
    .module('DigiLean').directive("strategyAttachments", ['strategyService',
        function (strategyService) {
            return {
                templateUrl: 'strategyAttachments.html',
                restrict: 'E',
                scope: {
                    'strategyGoalId': '<',
                    'assetId': '<',
                    'isEditMode': '<',
                },
                link: function (scope, elem, attrs) {
                    scope.update = function () {
                        
                    }
                    var currentAssetId = null;
                    scope.uploadedFiles = [];
                    scope.files = [];
                    scope.isLoading = true;
                    scope.toogleEditModeOn = true;
                    if(scope.isEditMode){
                        scope.toogleEditModeOn = false;
                    }

                    scope.$watch("strategyGoalId", function (strategyGoalId) {
                        loadData();
                    });

                    scope.$watch("assetId", function (assetId) {
                        if(currentAssetId != assetId){
                            currentAssetId = scope.assetId;
                            loadData();
                        }
                        
                    });

                    function loadData(){
                        if (!scope.strategyGoalId) return;
                        
                        scope.isLoading = true;
                        strategyService().getAttachments(scope.strategyGoalId, scope.assetId).then(function(files) {
                            scope.files = files;
                            scope.isLoading = false;
                        });
                    }


                    scope.deleteFile = function (file) {
                        var i = scope.files.indexOf(file);
                        scope.files.splice(i, 1);
                        strategyService().deleteAttachment(scope.strategyGoalId, file.id).then(function() {

                        });
                    }

                    scope.fileUploaded = function (file) {
                        strategyService().addAttachment(scope.strategyGoalId, scope.assetId,file).then(function(attachment) {
                            scope.isEditMode = false;
                            scope.files.push(attachment);
                        });
                    };
                    scope.toggleEditMode = function () {
                        scope.isEditMode = !scope.isEditMode ;
                    };

                }

            }
        }]);
