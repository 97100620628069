angular
    .module('DigiLean').directive("projectMilestoneSummary", ['projectService',
        function (projectService) {
            return {
                templateUrl: 'projectMilestoneSummary.html',
                restrict: 'E',
                scope: {
                    'project': '=',
                },
                link: function (scope, elem, attrs) {
                    scope.isLoading = true;
                    var projectId = null;

                    scope.statusStartDate = null;
                    scope.statusEndDate = null;

                    scope.$watch("project", function (project) {
                        if (!project) return;
                        projectId = scope.project.id;
                        if (scope.project.estimatedStartDate) {
                            scope.project.estimatedStartDate = moment(scope.project.estimatedStartDate).toDate();
                        }
                        if (scope.project.startDate) {
                            scope.project.startDate = moment(scope.project.startDate).toDate();
                        }
                        if (scope.project.estimatedEndDate) {
                            scope.project.estimatedEndDate = moment(scope.project.estimatedEndDate).toDate();
                        }
                        if (scope.project.endDate) {
                            scope.project.endDate = moment(scope.project.endDate).toDate();
                        }

                        scope.statusStartDate = checkDate(scope.project.estimatedStartDate, scope.project.startDate);
                        scope.statusEndDate = checkDate(scope.project.estimatedEndDate, scope.project.endDate);
                        getMilestones();
                    });

                    scope.subscribe("ProjectMilestoneUpdated", function (attribute) {
                        if (attribute.projectId == projectId) {
                            getMilestones();
                        }
                    });

                    scope.subscribe("ProjectStartDateUpdated", function (project) {
                        if (project.id == projectId) {
                            scope.project.startDate = moment(project.startDate).toDate();
                            scope.statusStartDate = checkDate(scope.project.estimatedStartDate, scope.project.startDate);
                        }
                    });
                    scope.subscribe("ProjectEstimatedStartDateUpdated", function (project) {
                        if (project.id == projectId) {
                            scope.project.estimatedStartDate = moment(project.estimatedStartDate).toDate();
                            scope.statusStartDate = checkDate(scope.project.estimatedStartDate, scope.project.startDate);
                        }
                    });

                    scope.subscribe("ProjectEndDateUpdated", function (project) {
                        if (project.id == projectId) {
                            scope.project.endDate = moment(project.endDate).toDate();
                            scope.statusEndDate = checkDate(scope.project.estimatedEndDate, scope.project.endDate);
                        }
                    });
                    scope.subscribe("ProjectEstimatedEndDateUpdated", function (project) {
                        if (project.id == projectId) {
                            scope.project.estimatedEndDate = moment(project.estimatedEndDate).toDate();
                            scope.statusEndDate = checkDate(scope.project.estimatedEndDate, scope.project.endDate);
                        }
                    });
                    
                    
                    scope.isValidDate = function (date) {
                        var result = moment(date);
                        return result._isValid;
                    };

                    scope.updateStartDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.project.startDate = date
                        projectService().updateStartDate(projectId,scope.project.startDate)
                    }

                    scope.updateEstimatedStartDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.project.estimatedStartDate = date
                        projectService().updateEstimatedStartDate(projectId, scope.project.estimatedStartDate)
                    }
                    scope.updateEndDate = function($event) {
                        const date = $event.originalEvent.detail
                        scope.project.endDate = date
                        projectService().updateEndDate(projectId,scope.project.endDate)
                    }

                    scope.updateEstimatedEndDate = function($event){
                        const date = $event.originalEvent.detail
                        scope.project.estimatedEndDate = date
                        projectService().updateEstimatedEndDate(projectId,scope.project.estimatedEndDate)
                    }

                    scope.saveMilestone = function (milestone) {
                        projectService().updateMilestone(milestone)
                    }
                    scope.savePlannedDateMilestone = function(milestone, $event) {
                        const date = $event.originalEvent.detail
                        milestone.plannedDate = date
                        scope.saveMilestone(milestone)
                    }
                    scope.saveActualDateMilestone = function(milestone, $event) {
                        const date = $event.originalEvent.detail
                        milestone.actualDate = date
                        scope.saveMilestone(milestone)
                    }

                    function checkMilestones() {
                        if (scope.milestones && scope.milestones.length > 0) {
                            scope.milestoneMissed = false;
                            for (var i = 0; i < scope.milestones.length; i++) {
                                if (scope.isValidDate(scope.milestones[i].actualDate)) {
                                    scope.milestones[i].status = "completed";
                                } else if (scope.milestones[i].plannedDate) {
                                    //check if overdue/missed
                                    var duration = moment.duration(moment().diff(moment(scope.milestones[i].plannedDate)));
                                    var days = duration.asDays().toFixed(0);
                                    if (days >= 1) {
                                        scope.milestones[i].status = "missed";
                                        scope.milestoneMissed = true;
                                    } else {
                                        scope.milestones[i].status = "future";
                                    }
                                    //addNextMilestone(project, project.milestones[i], days);
                                }
                            }
                        }
                    }

                    function checkDate(estimated, actual) {
                        var status = null;
                        if (scope.isValidDate(actual)) {
                            status = "completed";
                        } else if (estimated) {
                            //check if overdue/missed
                            var duration = moment.duration(moment().diff(moment(estimated)));
                            var days = duration.asDays().toFixed(0);
                            if (days >= 1) {
                                status = "missed";
                                scope.milestoneMissed = true;
                            } else {
                                status = "future";
                            }
                        }
                        return status;
                    }

                    function getMilestones(){
                    projectService().getMilestonesForProject(projectId).then(function (milestones) {
                        angular.forEach(milestones, function (milestone) {
                            milestone.plannedDate = moment(milestone.plannedDate).toDate();
                            milestone.actualDate = moment(milestone.actualDate).toDate();
                            milestone.datePicker = {
                                opened: false
                            },
                                milestone.estimatedDatePicker = {
                                    opened: false
                                }
                        })
                        scope.milestones = milestones;
                        scope.project.milestones = scope.milestones;
                        checkMilestones();
                    });
                }



                }

            }
        }]);
