angular
    .module('DigiLean')
    .directive("actionlistImprovement", ['$filter', 'suggestionService',
        function ($filter, suggestionService) {
            return {
                templateUrl: 'actionlistImprovement.html',
                restrict: 'E',
                scope: {
                    'assetid': '=',
                    'improvementCount': '=',
                    'improvementOverdueCount': '=',
                    'readonly': '@'
                },
                link: function (scope, element, attrs) {
                    //scope.tasks = [];
                    scope.improvements = [];

                    scope.$watch("assetid", function () {
                        if (!scope.assetid) return;
                        loadImprovements(scope.assetid);
                    });
                    scope.subscribe("ImprovementActionListStatusChanged", function(status) {
                        // current board
                        var target = $filter('filter')(scope.improvements, { "id" : status.improvementId });
                        if (target.length == 1) {
                            target[0].improvementtaskCount = status.total;
                            target[0].improvementtaskOverdueCount = status.overdue;
                         
                        }
                        calculateTaskCounts(scope.improvements);
                    });

                    function calculateTaskCounts(improvements) {
                        var total = 0;
                        var overdue = 0;
                        if(improvements){
                            for (var t=0; t<improvements.length; t++){
                                total += improvements[t].improvementtaskCount;
                                overdue += improvements[t].improvementtaskOverdueCount;
                            }
                        }
                        scope.improvementtaskCount = total;
                        scope.improvemenmttaskOverdueCount = overdue;
                        var status = {
                            total: scope.improvementtaskCount,
                            overdue: scope.improvemenmttaskOverdueCount
                        }
                        scope.publish("ImprovementActionListCountChanged", status)
                    }

                    
                    function loadImprovements(assetId) {
                        suggestionService().getImprovementsForAssetWithActionItems(assetId).then(function(improvements) {                           
                            scope.improvements = improvements;
                            calculateTaskCounts(scope.improvements);
                        });
                    }
                }
            }
        }]);
