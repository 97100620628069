
import { getUserProfile } from "@common/stores/userStore"
angular.module("DigiLean").factory('dataListService', ['$filter', '$http', 'webApiUrl',
    function ($filter, $http, webApiUrl) {
        function dataListService() {
            var resource = "/dataList";
            var resourceUrl = webApiUrl + resource;

            let lang = "en"
            const up = getUserProfile()
            if (up.preferredLanguage)
                lang = up.preferredLanguage

            return {
                get: function (id) {
                    var url = resourceUrl + "/get/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getFeatureList: function (feature, options) {
                    var url = resourceUrl + "/getFeatureList/" + feature;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                getLists: function () {
                    var url = resourceUrl + "/getLists";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getItems: function (listId, options) {
                    options.LanguageCode = lang;
                    var url = resourceUrl + "/GetItems/" + listId;
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                },

                createList: function (list) {
                    var url = resourceUrl + "/createList";
                    return $http.post(url, list).then(function (result) {
                        return result.data;
                    });
                },
                updateItemsSortOrder: function (id, listSorting) {
                    var url = resourceUrl + "/updateItemsSortOrder/" + id;
                    return $http.put(url, listSorting).then(function (result) {
                        return result.data;
                    });
                },

                updateList: function (id, list) {
                    var url = resourceUrl + "/updateList/" + id;
                    return $http.put(url, list).then(function (result) {
                        return result.data;
                    });
                },

                deleteList: function (id) {
                    var url = resourceUrl + "/deleteList/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                addItemToList: function (listId, item) {
                    var url = resourceUrl + "/addItemToList/" + listId;
                    return $http.post(url, item).then(function (result) {
                        return result.data;
                    });
                },
                updateDataListItem: function (listId, item) {
                    var url = resourceUrl + "/UpdateDataListItem/" + listId;
                    return $http.put(url, item).then(function (result) {
                        return result.data;
                    });
                },            
                deleteDataListItem: function (id) {
                    var url = resourceUrl + "/DeleteDataListItem/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                updateTranslationLabel: function (listId, item) {
                    var url = resourceUrl + "/updateTranslationLabel/" + listId;
                    return $http.put(url, item).then(function (result) {
                        return result.data;
                    });
                },
                listInUse: function (listId) {
                    var url = resourceUrl + "/ListInUse/" + listId;
                    return $http.post(url).then(function (result) {
                        return result.data;
                    });
                },
            }
        }
        return dataListService;
    }]);