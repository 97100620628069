angular
    .module('DigiLean')
    .controller("newFeedbackModalController", ['$scope', '$uibModalInstance', 'suggestion', 'authService',
        function ($scope, $uibModalInstance, suggestion, authService) {

            // the new action object
            $scope.suggestion = suggestion;
            $scope.uploadedFiles = [];

            $scope.ok = function () {
                $scope.suggestion.attachments = $scope.uploadedFiles;
                $uibModalInstance.close($scope.suggestion);
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }]);