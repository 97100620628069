import * as boardService from "@common/services/boardService"

angular
.module('DigiLean')
.directive("boardstatusSelector", ['$filter', 
    function ($filter) {
        return {
            templateUrl: 'boardstatusselector.html',
            restrict: 'E',
            scope: {
                'filters': '=',
                'source': '=',
                'filterChangeHandler': '&'
            },
            link: function (scope, elem, attrs) {
                scope.includeAll = true;
                scope.statusItems = [];

                function getStatusList() {
                    scope.statusItems = [
                        {
                            label: boardService.getTaskStatusText("OK"),
                            status: "OK"
                        },
                        {
                            label: boardService.getTaskStatusText("blank"),
                            status: "blank"
                        },
                        {
                            label: boardService.getTaskStatusText("unacceptable"),
                            status: "unacceptable"
                        }

                    ];
                    applyFilter();
                }

                scope.$watch('source', function () {
                    getStatusList();
                });


                function applyFilter(){
                    if (scope.filters && scope.statusItems) {

                        var identifiers = [];
                        var selectedFilter = scope.filters[0];
                        if (selectedFilter && selectedFilter.items.length > 0) {

                            selectedFilter.items.forEach(item => {
                                identifiers.push(parseInt(item));
                            });
                            selectedFilter.items = identifiers;
                        }
                        
                        for (var k = 0; k < scope.statusItems.length; k++) {
                            var index = selectedFilter.items.indexOf(scope.statusItems[k].status);
                            if (index > -1){
                                scope.statusItems[k].include = true;
                            } else {
                                scope.statusItems[k].include = false;
                            }                     
                        }

                        var selected = $filter('filter')(scope.statusItems, { include: true });
                        if (selected.length === scope.statusItems.length && selected.length === 0) {
                            scope.includeAll = true;
                        } else {
                            scope.includeAll = false;
                        }
                    } else if (scope.statusItems) {
                        for (var i = 0; i < scope.statusItems.length; i++) {
                            scope.statusItems[i].include = true;                     
                        }
                    }
                }

                scope.deselectAll = function (include) {
                    for (var k = 0; k < scope.statusItems.length; k++) {
                        scope.statusItems[k].include = include;                     
                    }
                    scope.modifyFilter();
                };

                scope.modifyFilter = function(){
                    var selected = $filter('filter')(scope.statusItems, { include: true });
                    if(selected.length === scope.statusItems.length){
                        scope.includeAll = true;
                        scope.filters = null;
                    } else {
                        scope.includeAll = false;
                        var selectedItems = [];
                        for(var i=0; i < selected.length; i++){
                             selectedItems.push(selected[i].status);
                        }
                        scope.filters = [{
                            sourceColumn: scope.source,
                            operator: "InList",
                            items: selectedItems
                        }];
                    }
                    scope.filterChangeHandler({ updatedSourceColumn: scope.source, filter: scope.filters });
                };

            }
        }
    }]);





