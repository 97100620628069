import * as timeService from "@common/services/timeService"

angular
    .module('DigiLean').directive("productionBoard", ['$filter', '$uibModal', 'dataService', 'dataSourceService','dataFilterService','mathService',
        function ($filter, $uibModal, dataService, dataSourceService, dataFilterService, mathService) {
            return {
                templateUrl: 'productionBoard.html',
                restrict: 'E',
                scope: {
                    model: "<",
                    editable: '=',
                    modelChangeHandler: '&?'
                },
                link: function (scope, elem, attrs) {
                    var modelRows = [];
                    var decimals = 2;
                    var noOfDaysShown = 5;

                    scope.$watch("model", function (model) {
                        if(scope.model){
                            if(scope.model.noOfDaysShown){
                                if(noOfDaysShown != scope.model.noOfDaysShown){   
                                    noOfDaysShown = scope.model.noOfDaysShown;
                                    setupTimeOptions();
                                }    
                            }
                            if(scope.model.rows){
                                if(modelRows.length != scope.model.rows.length){
                                    modelRows = scope.model.rows;          
                                    init();
                                }                      
                            }
                        }
                    })

                    scope.isLoading = true;
                    scope.isProcessing = false;

                    scope.subscribe("DataValueAdded", function (value) {
                        if(value && scope.table && scope.table.rows){
                            var selectedTimePeriod = {
                                from: moment.utc(scope.selectedWeek.startDate).format(),
                                to: moment.utc(scope.selectedWeek.endDate).format()
                            }
                            if(timeService.isWithinTimePeriod(value.valueDate, value.valueDate, selectedTimePeriod)){
                                scope.table.rows.forEach(row => {
                                    if (row.dataSourceId === value.dataSourceId && dataFilterService().matchesFilters(value, row.filters)) {
                                        for (let index = 0; index < row.days.length; index++) {
                                            const day = row.days[index];
                                            var dayDate = moment(day.day.date).date();
                                            var valueDate = moment(value.valueDate).date();
                                            if (dayDate == valueDate) {
                                                day.registrations.push(value);
                                                day.calculateTotal();
                                            }
                                        }
                                    }
                                    if (row.targetDataSourceId === value.dataSourceId && dataFilterService().matchesFilters(value, row.targetFilters)) {
                                        for (let index = 0; index < row.days.length; index++) {
                                            const day = row.days[index];
                                            var dayDate = moment(day.day.date).date();
                                            var valueDate = moment(value.valueDate).date();
                                            if (dayDate == valueDate) {
                                                day.targetRegistrations.push(value);
                                                day.calculateTotal();
                                            }
                                        }
                                    }
                                });
                            }
                        }          
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if(scope.table && scope.table.rows){
                            var selectedTimePeriod = {
                                from: scope.selectedWeek.startDate,
                                to: scope.selectedWeek.endDate
                            }
                            if(timeService.isWithinTimePeriod(value.valueDate, value.valueDate, selectedTimePeriod)){
                                scope.table.rows.forEach(row => {
                                    if (row.dataSourceId === value.dataSourceId) {
                                        for (let index = 0; index < row.days.length; index++) {
                                            const day = row.days[index];
                                            var selected = $filter('filter')(day.registrations, { id: value.id });
                                            if (selected.length > 0) {
                                                selected[0].value = value.value;
                                                day.calculateTotal();
                                                index = row.days.length; //break the for loop
                                            }
                                        }
                                    }
                                    if (row.targetDataSourceId === value.dataSourceId) {
                                        for (let index = 0; index < row.days.length; index++) {
                                            const day = row.days[index];
                                            var selected = $filter('filter')(day.targetRegistrations, { id: value.id });
                                            if (selected.length > 0) {
                                                selected[0].value = value.value;
                                                day.calculateTotal();
                                                index = row.days.length; //break the for loop
                                            }
                                        }
                                    }
                                });
                            }
                        } 
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        if(scope.table && scope.table.rows){
                            scope.table.rows.forEach(row => {
                                if (row.dataSourceId === value.dataSourceId) {
                                    for (let index = 0; index < row.days.length; index++) {
                                        const day = row.days[index];
                                        var selected = $filter('filter')(day.registrations, { id: value.valueId });
                                        if (selected.length > 0) {
                                            var valueindex = day.registrations.indexOf(selected[0]);
                                            if (valueindex > -1) {
                                                day.registrations.splice(valueindex, 1);
                                                day.calculateTotal();
                                                index = row.days.length; //break the for loop
                                            }
                                        }
                                    }
                                }
                                if (row.targetDataSourceId === value.dataSourceId) {
                                    for (let index = 0; index < row.days.length; index++) {
                                        const day = row.days[index];
                                        var selected = $filter('filter')(day.targetRegistrations, { id: value.valueId });
                                        if (selected.length > 0) {
                                            var valueindex = day.targetRegistrations.indexOf(selected[0]);
                                            if (valueindex > -1) {
                                                day.targetRegistrations.splice(valueindex, 1);
                                                day.calculateTotal();
                                                index = row.days.length; //break the for loop
                                            }
                                        }
                                    }
                                }
                            });                 
                        }  
                    });


                    function init(){
                        if(!scope.model) return;
                        dataSourceService().getList().then(function (list) {
                            scope.dataSources = list;

                            for(var i= 0; i<scope.model.rows.length; i++){
                                if (scope.model.rows[i].dataSourceId) {
                                    var id = scope.model.rows[i].dataSourceId;
                                    if (!isNaN(id) && parseInt(id) != "NaN") {
                                        id = parseInt(id);
                                    }
                                    var updatedSource = $filter('filter')(scope.dataSources, { id: id }, true);
                                    if (updatedSource.length > 0) {
                                        scope.model.rows[i].dataSource = updatedSource[0];
                                    }
                                }

                                if (scope.model.rows[i].targetDataSourceId) {
                                    var id = scope.model.rows[i].targetDataSourceId;
                                    if (!isNaN(id) && parseInt(id) != "NaN") {
                                        id = parseInt(id);
                                    }
                                    var updatedSource = $filter('filter')(scope.dataSources, { id: id }, true);
                                    if (updatedSource.length > 0) {
                                        scope.model.rows[i].targetDataSource = updatedSource[0];
                                    }
                                }
                            }
                            loadDataForWeek();
                            
                        });
                    }

                    function loadDataForWeek() {
                        if (!scope.selectedWeek) return;
                        if(!scope.dataSources){
                            init();
                        } else{
                            if(scope.table && scope.table.rows.length == modelRows.length && modelRows.length>0){
                        
                                scope.table.rows.forEach(row => {
                                    var options = {
                                        timePeriod: {
                                            from: scope.selectedWeek.startDate,
                                            to: scope.selectedWeek.endDate
                                        },
                                        filters: row.filters
                                    };
                                    var dataSourceId = row.dataSourceId;
                                    if(isNaN(dataSourceId) || parseInt(dataSourceId) == "NaN"){
                                        dataSourceId = row.dataSource.id;
                                    }
                                    dataService().getValuesForPeriod(dataSourceId, options).then(function (values) {
                                        //createRows(values, false);
                                        row.values = values;
                                        //scope.table.addRow(createRow(scope.table, row, scope.selectedWeek));
                                        if(row.targetDataSourceId){
                                            options.filters = row.targetFilters;
                                            dataService().getValuesForPeriod(row.targetDataSourceId, options).then(function (values) {
                                                row.targetValues = values;
                                                var newRow = createRow(scope.table, row, scope.selectedWeek);
                                                scope.table.rows[row.index] = newRow;
                                                scope.table.calculateTotal();
                                            });
                                        } else {
                                            var newRow = createRow(scope.table, row, scope.selectedWeek);
                                            scope.table.rows[row.index] = newRow;
                                            scope.table.calculateTotal();
                                        }                      
                                    });
                                });
                            } else {
                                if (!scope.model) return;
                                if (!scope.model.rows) return;
                                scope.model.rows.forEach(row => {
                                    var options = {
                                        timePeriod: {
                                            from: scope.selectedWeek.startDate,
                                            to: scope.selectedWeek.endDate
                                        },
                                        filters: row.filters
                                    };
                                    var dataSourceId = row.dataSourceId;
                                    if(isNaN(dataSourceId) || parseInt(dataSourceId) == "NaN"){
                                        dataSourceId = row.dataSource.id;
                                    }
                                    dataService().getValuesForPeriod(dataSourceId, options).then(function (values) {
                                        row.values = values;

                                        if(row.targetDataSourceId){
                                            options.filters = row.targetFilters;
                                            dataService().getValuesForPeriod(row.targetDataSourceId, options).then(function (values) {
                                                row.targetValues = values;
                                                var newRow = createRow(scope.table, row, scope.selectedWeek);
                                                scope.table.rows[row.index] = newRow; 
                                                scope.table.calculateTotal();
                                            });
                                        } else {
                                            var newRow = createRow(scope.table, row, scope.selectedWeek);
                                            scope.table.rows[row.index] = newRow;
                                            scope.table.calculateTotal();
                                        }                      
                                    });
                                });
                            }     
                            
                        }                
                    }

                    // load users
                    /*var profileImageUsers = [];
                    function getAllUsers() {
                        profileService.getUsersWithProfileImage().then(function (imageUsers) {
                            profileImageUsers = imageUsers;
                            customerService.getAllUsers().then(function (users) {
                            
                                for (var i = 0; i < users.length; i++) {
                                    var imageUrl = taskDrawingService().getProfileImageUrlFromImageUsers(users[i].userId, "ExtraSmall", profileImageUsers);
                                    if (imageUrl) {
                                        users[i].profileImage = true;
                                        users[i].profileImageUrl = imageUrl;
                                    } else {
                                        users[i].profileImage = false;
                                    }
                                }
                                scope.allusers = users;
                            });
                        });
                    }
                    getAllUsers();
                    scope.selectUser = function(user, row){
                        if(!row.users){
                            row.users = [];
                        }
                        row.users.push(user);
                    }*/

                    function getTargetStatus(produced, planned, operator){
                        var status = "red";
                        if(operator){
                            switch (operator.dbCode) {
                                case ">=":
                                    if (produced >= planned){
                                        status = "green";
                                    } 
                                    break;
                                case ">":
                                    if (produced > planned){
                                        status = "green";
                                    }
                                    break;
                                case "<=":
                                    if (produced <= planned){
                                        status = "green";
                                    }
                                    break;
                                case "<":
                                    if (produced < planned){
                                        status = "green";
                                    }
                                    break; 
                            } 
                        } else {
                            if (produced >= planned) {
                                status = "green";
                            }
                        }
                        return status;
                    }

                    function createRow(table, projectRow, week) {
                        if (!projectRow) return;
                        var row = {
                            name: projectRow.name,
                            id: projectRow.id,
                            index: projectRow.index,
                            plannedTotal: 0,
                            producedTotal: 0,
                            result: 0,
                            dataSourceId: projectRow.dataSourceId,
                            dataSource: projectRow.dataSource,
                            filters: projectRow.filters,
                            formula: projectRow.formula,
                            targetDataSourceId: projectRow.targetDataSourceId,
                            targetDataSource: projectRow.targetDataSource,
                            targetFilters: projectRow.targetFilters,
                            targetValue: projectRow.targetValue,
                            operator: projectRow.operator,
                            days: [],
                            calculateTotal: function () {
                                let sum = this.days.reduce((s, f) => {
                                    return s + f.producedTotal;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                if(this.formula && this.formula == 'average' && this.days.length>0){
                                    var daysWithValues = 0;
                                    this.days.forEach(dayInWeek =>{
                                        if(dayInWeek.registrations.length > 0){
                                            daysWithValues = daysWithValues + 1;
                                        }
                                    });
                                    if(daysWithValues > 0){
                                        sum = sum / daysWithValues;
                                        sum = roundNumber(sum, decimals);
                                    }                                  
                                } else if(sum.toString().includes(".")){
                                    sum = roundNumber(sum, decimals);
                                }
                                this.producedTotal = sum;
                                let plannedsum = this.days.reduce((s, f) => {
                                    return s + f.plannedTotal;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                if(this.formula && this.formula == 'average' && this.days.length>0){
                                    if(this.targetValue && this.targetValue != 0){
                                        plannedsum = this.targetValue;
                                    } else{
                                        var daysWithValues = 0;
                                        this.days.forEach(dayInWeek =>{
                                            if(dayInWeek.targetRegistrations.length > 0){
                                                daysWithValues = daysWithValues + 1;
                                            }
                                        });
                                        if(daysWithValues > 0){
                                            plannedsum = plannedsum / daysWithValues;
                                            plannedsum = roundNumber(plannedsum, decimals);
                                        } 
                                    }
                                } else if(plannedsum.toString().includes(".")){
                                    plannedsum = roundNumber(plannedsum, decimals);
                                }
                                this.plannedTotal = plannedsum;
                                this.status = getTargetStatus(this.producedTotal, this.plannedTotal, this.operator);
                                table.calculateTotal();
                            }
                        }
                        // Calcluate Days
                        week.days.forEach(dayInWeek => {
                            // add values
                            var day = {
                                day: dayInWeek,
                                planned: 0,
                                produced: 0,
                                plannedTotal: 0,
                                producedTotal: 0,
                                newRegistration: {
                                    planned: 0,
                                    produced: 0,
                                    value: 0
                                },
                                setProduced: function (value) {
                                    this.newRegistration.produced = value;
                                    this.newRegistration.value = value;
                                },
                                deleteRegistration: function (registration) {
                                    var currentDay = this;
                                    dataService().delete(registration.id).then(function () {
                                    })
                                },
                                saveRegistration: function () {
                                    var produced = this.newRegistration.produced.toString();
                                    produced = produced.replace(",", ".");
                                    produced = parseFloat(produced);
                                    // Convert to DataValue and save
                                    scope.isProcessing = true;
                                    var utcDate = moment.utc(this.day.date).format();
                                    saveDataValue(produced, utcDate, row.dataSource, row.filters);
                                    // Clear newRegistration
                                    this.newRegistration.produced = 0;
                                    this.newRegistration.value = 0;
                                },
                                setPlanned: function (value) {
                                    this.newRegistration.planned = value;
                                },
                                deletePlannedRegistration: function (registration) {
                                    var currentDay = this;
                                    dataService().delete(registration.id).then(function () {
                                    })
                                },
                                savePlannedRegistration: function () {
                                    var planned = this.newRegistration.planned.toString();
                                    planned = planned.replace(",", ".");
                                    planned = parseFloat(planned);
                                    // Convert to DataValue and save
                                    scope.isProcessing = true;
                                    var utcDate = moment.utc(this.day.date).format();
                                    saveDataValue(planned, utcDate, row.targetDataSource, row.targetFilters);
                                    // Clear newRegistration
                                    this.newRegistration.planned = 0;
                                },
                                registrations: [],
                                targetRegistrations: [],
                                calculateTotal: function () {
                                    let sum = this.registrations.reduce((s, f) => {
                                        return s + f.value;  // return the sum of the accumulator and the current time, as the the new accumulator
                                    }, 0);
                                    if(row.formula && row.formula == 'average' && this.registrations.length>0){
                                        sum = sum / this.registrations.length;   
                                        sum = roundNumber(sum, decimals);                               
                                    } else if(sum.toString().includes(".")){
                                        sum = roundNumber(sum, decimals);
                                    }
                                    this.producedTotal = sum;
                                    this.produced = sum;
                                    let plannedsum = 0;
                                    if(row.targetValue && row.targetValue != 0){
                                        plannedsum = row.targetValue;
                                    } else{
                                        plannedsum = this.targetRegistrations.reduce((s, f) => {
                                            return s + f.value;  // return the sum of the accumulator and the current time, as the the new accumulator
                                        }, 0);
                                        if(row.formula && row.formula == 'average' && this.targetRegistrations.length>0){
                                            plannedsum = plannedsum / this.targetRegistrations.length; 
                                            plannedsum = roundNumber(plannedsum, decimals);                                 
                                        } else if(plannedsum.toString().includes(".")){
                                            plannedsum = roundNumber(plannedsum, decimals);
                                        }
                                    }
                                    
                                    this.plannedTotal = plannedsum;
                                    this.result = this.producedTotal - this.plannedTotal;
                                    if (this.result > 0) this.status = "green";
                                    if (this.result < 0) this.status = "red";
                                    this.status = getTargetStatus(this.producedTotal, this.plannedTotal, row.operator);
                                    row.calculateTotal();
                                }
                            }

                            row.days.push(day);
                            // Add and calulate values to day
                            //if (projectRow.values && projectRow.values.length > 0) {
                                mapValuesToDay(day, projectRow.values);
                                mapTargetValuesToDay(day, projectRow.targetValues);
                                day.calculateTotal();
                            //}

                        });
                       

                        return row;
                    }
                    function roundNumber(value, decimals){
                        if(decimals !== null) {
                            if(decimals !== undefined) {
                                value = mathService().roundNumber(value, decimals);
                            }
                        }
                        return value;
                    }

                    function saveDataValue(newvalue, utcDate, dataSource, filters){
                        var registration = {
                            valueDate: utcDate,
                            value: newvalue
                        };
                        var missingMandatoryValues = false;
                        angular.forEach(dataSource.elements, function (element) {
                            var hasFilter = $filter('filter')(filters, { sourceColumn: element.sourceColumn }, true);
                            if (hasFilter && hasFilter.length > 0) {
                                if(hasFilter[0].items.length == 1){
                                    registration[element.sourceColumn] = hasFilter[0].items[0]; //preselect the first filtered item of this filterelement
                                } else if(element.isMandatory){
                                    missingMandatoryValues = true;
                                }
                            } else {
                                if(element.isMandatory){
                                    missingMandatoryValues = true;
                                }
                            }

                            
                        });
                        if(missingMandatoryValues){
                            var configuration = {
                                dataSource:dataSource,
                                filters:filters,
                                registration:registration
                            };
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'productionBoardMandatoryFields.html',
                                controller: 'productionBoardMandatoryFieldsController',
                                windowClass: 'newdeviation-modal-window',
                                resolve: {
                                    configuration: function () {
                                        return configuration;
                                    }
                                }
                            });
    
                            modalInstance.result.then(function (result) {    
                                dataService().add(dataSource.id, result.registration).then(function (savedValue) {
                                    scope.isProcessing = false;
                                });       
                            });
                        } else {
                            dataService().add(dataSource.id, registration).then(function (savedValue) {
                                scope.isProcessing = false;
                            });
                        }
                        
                    }

                    function mapValuesToDay(day, values) {
                        if(values){
                            for (let index = 0; index < values.length; index++) {
                                const value = values[index];
                                var dayDate = moment(day.day.date).date();
                                var valueDate = moment(value.valueDate).date();
                                if (dayDate == valueDate) {
                                    day.registrations.push(value);
                                }
                            }
                        }     
                    }

                    function mapTargetValuesToDay(day, values) {
                        if(values){
                            for (let index = 0; index < values.length; index++) {
                                const value = values[index];
                                var dayDate = moment(day.day.date).date();
                                var valueDate = moment(value.valueDate).date();
                                if (dayDate == valueDate) {
                                    day.targetRegistrations.push(value);
                                }
                            }
                        }     
                    }

                    function setupTable(week) {
                        var table = {
                            rows: [],
                            days: [],
                            addRow: function (table, row, week) {

                                var rowIndex = 0;
                                var row = createRow(table, row, week);
                                this.rows.push(row);
                                return row;

                            },
                            producedTotal: 0,
                            plannedTotal: 0,
                            calculateTotal: function () {
                                // total pr row.
                                let sum = this.rows.reduce((s, f) => {
                                    return s + f.producedTotal;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                this.producedTotal = sum;
                                let plannedsum = this.rows.reduce((s, f) => {
                                    return s + f.plannedTotal;  // return the sum of the accumulator and the current time, as the the new accumulator
                                }, 0);
                                this.plannedTotal = plannedsum;
                                // Calculate total  pr day.
                                for (let index = 0; index < this.days.length; index++) {
                                    const dayInWeek = this.days[index];
                                    var daySum = 0;
                                    var plannedSum = 0;
                                    this.rows.forEach(row => {
                                        // Get total for day in each row
                                        daySum += row.days[index].producedTotal;
                                        plannedSum += row.days[index].plannedTotal;
                                        /* TODO: how to update all statuses if operator configurator is changed? 
                                        The next two lines result in stack size exceeded
                                        row.days[index].calculateTotal();
                                        row.calculateTotal();*/
                                    });
                                    dayInWeek.producedTotal = daySum;
                                    dayInWeek.plannedTotal = plannedSum;
                                }
                            }
                        }
                        // Create days
                        week.days.forEach(dayInWeek => {
                            var day = {
                                day: dayInWeek,
                                producedTotal: 0,
                                plannedTotal: 0,
                            }
                            table.days.push(day);
                        });
                        // Create rows based on project
                        scope.table = table;
                       
                    }

                    function copyRowSettings(row){
                        var isNewRow = true;
                        if(row.newRow === false){
                            isNewRow = false;
                        }
                        var newrow = {
                            newRow: false,
                            name: row.name,
                            dataSourceId: row.dataSourceId,
                            filters: copyObject(row.filters),  /* cloneDeep can not be used in this case since it will initially not should correct values */
                            targetDataSourceId: row.targetDataSourceId,
                            targetFilters: copyObject(row.targetFilters),
                            targetValue: row.targetValue,
                            formula: row.formula,
                            operator: row.operator
                        }
                        return newrow;
                    }

                   function copyObject(object){
                        if(!object) return;
                        return JSON.parse(JSON.stringify(object));
                    }

                    function resetRowIndexes(){
                        var index = 0;
                        modelRows.forEach(row => {
                            row.index = index;
                            index += 1;
                        });
                    }

                    function resetTableRowsIndexes(){
                        var index = 0;
                        scope.table.rows.forEach(row => {
                            row.index = index;
                            index += 1;
                        });
                    }

                    scope.deleteRow = function (row) {
                        modelRows.splice(row.index, 1);
                        resetRowIndexes();
                        scope.notfiyModelChanged();

                        var i = scope.table.rows.indexOf(row);
                        scope.table.rows.splice(i, 1);
                        resetTableRowsIndexes();
                    }

                    scope.copyRow = function (row) {
                        var newrow = copyRowSettings(row)
                        newrow.index =  modelRows.length;
                        modelRows.push(newrow);
                        scope.notfiyModelChanged();
                        var rowWithValues = copyRowSettings(row);
                        rowWithValues.index =  modelRows.length - 1;
                        
                        

                        //createRow
                        var r = scope.table.addRow(scope.table, rowWithValues, scope.selectedWeek);
                        r.days = row.days;
                        r.plannedTotal = row.plannedTotal;
                        r.producedTotal = row.producedTotal;
                        r.dataSource = row.dataSource;
                        r.targetDataSource = row.targetDataSource;
                        r.status = row.status;
                        r.targetValue = row.targetValue;
                        r.calculateTotal();
                    }

                    scope.addRow = function () {
                        var row = {
                            id: null,
                            dataSource: null,
                            filters: null,
                            newRow: true
                        }
                        scope.openRowConfiguration(row);
                    }

                    function resetRowRegistrationForEachDay(row, values, targetvalues){
                        for (let index = 0; index < row.days.length; index++) {
                            const day = row.days[index];
                            day.registrations = [];
                            mapValuesToDay(day, values);
                            if(targetvalues){
                                day.targetRegistrations = [];
                                mapTargetValuesToDay(day, targetvalues);
                            }
                            day.calculateTotal();
                        }
                        row.calculateTotal();
                    }

                    scope.openRowConfiguration = function(row){      
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'productionBoardRowConfiguration.html',
                            controller: 'productionBoardRowConfigurationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                configuration: function () {
                                    return row;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            var isNewRow = false;
                            var updatedRow = {
                                    name: result.name,
                                    index: result.index,
                                    dataSourceId: result.dataSourceId,
                                    filters: result.filters,
                                    targetDataSourceId: result.targetDataSourceId,
                                    targetFilters: result.targetFilters,
                                    targetValue: result.targetValue,
                                    formula: result.formula,
                                    operator: result.operator
                                }
                            if(result.newRow){
                                isNewRow = true;
                                result.newRow = false;   
                                updatedRow.index = modelRows.length; 
                                modelRows.push(updatedRow);
                                result.index = updatedRow.index;
                            } else {
                                modelRows[row.index] = updatedRow;
                            }
                            scope.notfiyModelChanged();

                            var options = {
                                timePeriod: {
                                    from: scope.selectedWeek.startDate,
                                    to: scope.selectedWeek.endDate
                                },
                                filters: result.filters
                            };
                            dataService().getValuesForPeriod(result.dataSourceId, options).then(function (values) {                                
                                result.values = values;
                                if(result.targetDataSourceId){
                                    options.filters = result.targetFilters;
                                    dataService().getValuesForPeriod(result.targetDataSourceId, options).then(function (values) {
                                        result.targetValues = values;
                                        if(isNewRow){
                                            scope.table.addRow(scope.table, result, scope.selectedWeek);
                                        } else {
                                            row.operator = updatedRow.operator;
                                            resetRowRegistrationForEachDay(row, result.values, result.targetValues);
                                            scope.table.calculateTotal();
                                        }
                                    });
                                } else {
                                    if(isNewRow){
                                        scope.table.addRow(scope.table, result, scope.selectedWeek);
                                    } else {
                                        row.operator = updatedRow.operator;
                                        resetRowRegistrationForEachDay(row, values, null);
                                        scope.table.calculateTotal();
                                    }
                                }
                                
                            });                 
                        });
                    }

                    scope.selectDataSource = function (row) {
                        var hasDataSerie = false;
                        if (row.dataSource) {
                            hasDataSerie = true;
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return row.dataSource;
                                },
                                filters: function () {
                                    return row.filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return true;
                                }

                            }
                        });

                        modalInstance.result.then(function (result) {
                            var row = {
                                id: result.dataSource.id,
                                dataSource: result.dataSource,
                                filters: result.filters,
                                name: result.dataSource.title
                            }
                            var options = {
                                timePeriod: {
                                    from: scope.selectedWeek.startDate,
                                    to: scope.selectedWeek.endDate
                                },
                                filters: row.filters
                            };
                            dataService().getValuesForPeriod(row.dataSource.id, options).then(function (values) {
                                modelRows.push(row);
                                scope.notfiyModelChanged();
                                row.values = values;
                                scope.table.addRow(scope.table, row, scope.selectedWeek);
                                scope.notfiyModelChanged();
                            });
                            
                            
                        });
                    };

                    scope.notfiyModelChanged = function () {
                        if (!scope.modelChangeHandler) return;
                        scope.model = {
                            rows: modelRows
                        };
                        scope.modelChangeHandler({ model: scope.model });
                    }

                    function updateItemsOrder(e, ui) {
                        var items = ui.item.sortable.droptargetModel;
                        if(items){
                            modelRows = [];
                            var index = 0;
                            items.forEach(item => {
                                item.index = index;
                                var configrow = copyRowSettings(item);
                                configrow.index = index;
                                modelRows.push(configrow);
                                index += 1;
                            });
                            scope.notfiyModelChanged();
                        }                   
                    }
            
                    scope.itemsSortableOptions = {
                        connectWith: ".summaryList",
                        handle: ".drag-handle",
                        placeholder: "summary-item-sortable-placeholder",
                        stop: updateItemsOrder
                    };

                    // Week and time setup
                    scope.selectWeek = function (week) {
                        week.days = week.days.splice(0, noOfDaysShown);
                        scope.selectedWeek = week;
                        // SETUP TABLE
                        if(!scope.table){
                            setupTable(scope.selectedWeek, []);
                        }
                        
                        // Load data
                        loadDataForWeek();

                    }

                    function setupTimeOptions() {
                        scope.months = timeService.getMonths();
                        scope.selectedYear = moment().year(); // Get currentYear if not set
                        var currentMonth = moment().month();
                        var month = scope.months[currentMonth];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectedMonth = month;
                        var currentWeek = moment().isoWeek();
                        var hasWeek = $filter('filter')(scope.selectedMonth.weeks, { number: currentWeek }, true);
                        if (hasWeek.length > 0) {
                            scope.selectWeek(hasWeek[0]);
                        } else {
                            scope.selectWeek(scope.selectedMonth.weeks[0]);
                        }
                    }
                    setupTimeOptions();

                    function getPreviousWeek() {
                        var currentWeekIndex = scope.selectedMonth.weeks.indexOf(scope.selectedWeek);
                        var currentWeekNumber = scope.selectedWeek.number;
                        var previousWeekIndex = currentWeekIndex - 1;
                        if (currentWeekIndex == 0) { // First week, so we need to go back a month
                            scope.previousMonth(false);
                            previousWeekIndex = scope.selectedMonth.weeks.length - 1;
                        }
                        var previousWeek = scope.selectedMonth.weeks[previousWeekIndex];
                        if (previousWeekIndex == (scope.selectedMonth.weeks.length - 1) && previousWeek.number == currentWeekNumber) {
                            previousWeek = scope.selectedMonth.weeks[previousWeekIndex - 1];
                        }
                        return previousWeek;
                    }
                    scope.previousWeek = function () {
                        var previousWeek = getPreviousWeek();
                        scope.selectWeek(previousWeek);
                    }
                    scope.nextWeek = function () {
                        var currentWeekIndex = scope.selectedMonth.weeks.indexOf(scope.selectedWeek);
                        var currentWeekNumber = scope.selectedWeek.number;
                        var nextWeekIndex = currentWeekIndex + 1;
                        if (nextWeekIndex > (scope.selectedMonth.weeks.length - 1)) { // Last week of month, so we need to go forward a month
                            scope.nextMonth(false);
                            nextWeekIndex = 0;
                        }
                        var nextWeek = scope.selectedMonth.weeks[nextWeekIndex];
                        // If week is share between two months, we need to move it to the next
                        if (nextWeekIndex == 0 && nextWeek.number == currentWeekNumber) {
                            nextWeek = scope.selectedMonth.weeks[1];
                        }
                        scope.selectWeek(nextWeek, true);
                    }
                    var selectYear = function (year) {
                        scope.selectedYear = year;
                        // Adjust month
                        var month = scope.months[0];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectedMonth = month;
                        setMonthSelection(month);
                        scope.selectedWeek = month.weeks[0];
                        setWeekSelection(scope.selectedWeek);
                    }

                    scope.selectMonth = function (month) {
                        scope.selectedMonth = month;
                        scope.selectedMonth.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        // Also adjust week to be in correct month
                        scope.selectedWeek = month.weeks[0];
                    };

                    scope.previousYear = function () {
                        selectYear(scope.selectedYear - 1);
                    }
                    scope.nextYear = function () {
                        selectYear(scope.selectedYear + 1);
                    }
                    scope.previousMonth = function (refreshData) {
                        var previousMonth = scope.selectedMonth.id - 1;
                        if (previousMonth == 0) {
                            previousMonth = 12;
                            scope.selectedYear -= 1;
                        }
                        var previousMonthIndex = previousMonth - 1; // subtract index
                        var month = scope.months[previousMonthIndex];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectMonth(month, refreshData);
                    }
                    scope.nextMonth = function (refreshData) {
                        var nextMonth = scope.selectedMonth.id + 1;
                        if (nextMonth == 13) {
                            nextMonth = 1;
                            scope.selectedYear += 1;
                        }
                        var nextMonthIndex = nextMonth - 1; // subtract for index
                        var month = scope.months[nextMonthIndex];
                        month.weeks = timeService.getWeeksInMonth(scope.selectedYear, month.id);
                        scope.selectMonth(month, refreshData);
                    }
                }
            }
        }]);
