import angular from "angular"
import { getJob } from "@common/services/admin/connectorAdminService"
import { getLogsPaged } from "@common/services/data/monitoringService"
import { LogMessageType } from "@api"
import { cloneDeep } from "lodash"
import { NgComponent } from "@common/model/angularModel"

type LoggTypeFilter = { 
    [k in LogMessageType]: boolean
}

const DigiLean = angular.module('DigiLean')
DigiLean.component('jobLogs', {
    templateUrl: "jobLogs.html",
    controller: ['$stateParams',
        function(this: NgComponent, $stateParams) {
            let $ctrl = this

            $ctrl.loading = false
            $ctrl.jobId = $stateParams.id as string
            $ctrl.job = {}
            $ctrl.connectorId = 0
            $ctrl.connectorName = ""
            $ctrl.$onInit = function() {
                getJobData()
                getLogData()
            }
            $ctrl.totalItems = 0
            let pageNum = 1
            let pageSize = 50
            const getJobData = async () => {
                const job = await getJob($ctrl.jobId)
                $ctrl.job = job
                $ctrl.connectorId = job.connectorId
                $ctrl.connectorName = job.connectorName
            }

            $ctrl.logData = { total: 0, items: []}
            const getLogData = async () => {
                const data = await getLogsPaged($ctrl.jobId, pageSize, pageNum)
                $ctrl.logData = data
                $ctrl.totalItems = data.total
            }

            const getLogDataFiltered = async (filter: LoggTypeFilter) => {

                const typeFilter = Object.keys(filter).reduce((sum: string[], curr: string) => {
                    if (filter[curr])
                        sum.push(curr)
                    return sum
                }, [])
                
                const data = await getLogsPaged($ctrl.jobId, pageSize, pageNum, typeFilter)
                $ctrl.logData = data
                $ctrl.totalItems = data.total
            }

            $ctrl.pageNumChanged = function($event) {
                pageNum = $event.detail.pageNum ? $event.detail.pageNum : 1
                getLogData()
            }
            $ctrl.pageSizeChanged = function($event) {
                pageSize = $event.detail.pageSize ? $event.detail.pageSize : 10
                getLogData()
            }

            $ctrl.filter = {}
            $ctrl.logtypes = LogMessageType
            for (let key in $ctrl.logtypes)
                $ctrl.filter[key] = false
            
            $ctrl.filterToggle = function(key: string) {
                const filter = cloneDeep($ctrl.filter) as LoggTypeFilter
                filter[key] = !filter[key]
                getLogDataFiltered(filter)
            }
        }
    ]
})