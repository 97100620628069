
angular
    .module('DigiLean')
    .directive("defaultBoardCard",
        function () {
            return {
                templateUrl: 'defaultBoardCard.html',
                restrict: 'E',
                scope: {
                    "board" : "<",
                },
                link: function (scope, elem, attrs) {
                    scope.model = null;
                    scope.isLoading = true;
                    
                    scope.$watch('board', function (board) {
                        if (!board) return;
                    });
                }
            }
        });
