export const parseToken = (token: string) => {
    const base64Url = token.split('.')[1]
    const tokenstring = window.atob(base64Url)
    const json = JSON.parse(tokenstring)
    return json
}


export const parseDiff = (diff: number) => {
    let diffStr = ""
    const secTotal = Math.floor(diff/1000)
    const s = secTotal % 60
    diffStr = `${s}s`
    const restS = secTotal - s
    if (restS === 0)
        return diffStr

    const minTotal = restS / 60
    const m = minTotal % 60
    diffStr = `${m}m ${diffStr}`
    const restM = minTotal - m
    if (restM === 0)
        return diffStr
    const hourTotal = restM / 60
    const h = hourTotal % 24
    diffStr = `${h}h ${diffStr}`
    const restHour = hourTotal - h
    if (restHour === 0)
        return diffStr
    const d = restHour / 24
    diffStr = `${d}d ${diffStr}`
    return diffStr
}

const leftFillNum = (num) => {
    return num.toString().padStart(2, 0)
}

export const getYyyMmDdHhMmSs = (d) => {
    return `${d.getFullYear()}-${leftFillNum(d.getMonth())}-${leftFillNum(d.getDate())} ${leftFillNum(d.getHours())}:${leftFillNum(d.getMinutes())}:${leftFillNum(d.getSeconds())}`
}

export const parseExpire = (tokenExpire: number) => {
    const expTic = tokenExpire*1000
    const nowTic = new Date().getTime()
    const diff = expTic - nowTic
    const parsedDiff = parseDiff(diff)
    const expiredDate = new Date(expTic)
    return {
        parsedDiff,
        expiredDate
    }
}